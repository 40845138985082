function obtenerRespueta(status, mensajebd) {
    switch (status) {
        case 200:
            return "Se realizó exitosamente la acción";
        case 201:
            return "Se realizó exitosamente la acción";
        case 500:
            return "Error al realizar la acción, Detalle: " + mensajebd;
        case 409:
            return mensajebd;
        case 404:
            return "Servicio no encontrado";
        case 204:
            return "No se encontró la información solicitada";
        case -1:
            return "Error no controlado: " + mensajebd;
        default:
            return "Status no controlado, " + status
    }
}

function obtenerErrorSwal(mensaje) {
    let obj = {
        titulo: "Uuups",
        mensaje,
    }

    return obj;
}

module.exports = {
    obtenerRespueta,
    obtenerErrorSwal
}