import React, { useState } from "react";
import Cargo from "../../img/icons/Cargo.svg";
import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Select,
  InputNumber
} from "antd";

// import iconBorrar from "../../img/icons/borrar.svg";
import './FormularioCargos.css'
import Swal from "sweetalert2";
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';

const FormularioCargos = (props) => {

  //constante para manejar el formulario
  //   const [form] = Form.useForm();
  const [vigencia, setVigencia] = useState(true);
  const [listaEpps, setListaEpps] = useState([]);
  const [listaDocumentos, setListaDocumentos] = useState([]);

  const formRef = React.createRef();

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {

    values.Vigencia = vigencia;

    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const objAux = {
        Vigencia: values.Vigencia,
        Epps: listaEpps,
        Documentos: listaDocumentos,
        Empresa: usuario.EmpresaSeleccionada.Empresa._id,
        Nombre: values.Nombre,
      };

      props.crearCargo(objAux);
    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  const cambiarVigencia = (val) => {
    setVigencia(val);
  }

  const addEpp = () => {

    let { Epp, DiasSugeridos } = formRef.current.getFieldsValue(['Epp', 'DiasSugeridos']);

    if (!Epp || !DiasSugeridos) {

      Swal.fire({
        icon: "warning",
        title: "¡Ups!",
        text: "Ingresa la información del EPP para agregarlo a la lista.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Ok",
      });

      return;
    }

    let nuevaListaEpps = [...listaEpps, { IdEpp: Epp, DuracionSugeridaDias: DiasSugeridos }];

    setListaEpps(nuevaListaEpps);

    formRef.current.resetFields(['Epp', 'DiasSugeridos']);

  }

  const addDocument = () => {

    let { Documento } = formRef.current.getFieldsValue(['Documento']);

    if (!Documento) {

      Swal.fire({
        icon: "warning",
        title: "¡Ups!",
        text: "Ingresa la información del documento para agregarlo a la lista.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Ok",
      });

      return;
    }

    let nuevaListaDocumentos = [...listaDocumentos, { IdDocumento: Documento }];

    setListaDocumentos(nuevaListaDocumentos);

    formRef.current.resetFields(['Documento']);

  }

  const deleteEpp = (idEpp) => {

    let nuevaListaEpps = listaEpps.filter(eppIngresado => {
      return eppIngresado.IdEpp != idEpp;
    })

    setListaEpps(nuevaListaEpps);
  }

  const deleteDocument = (idDocumento) => {

    let nuevaListaDocumentos = listaDocumentos.filter(documentoIngresado => {
      return documentoIngresado.IdDocumento != idDocumento;
    })

    setListaDocumentos(nuevaListaDocumentos);
  }

  return (
    <Form
      {...formItemLayout}
      //form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      ref={formRef}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={Cargo} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Nuevo Cargo "
          />
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>

            <Form.Item
              name="Nombre"
              label="Nombre:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir un nombre",
                  min: 2,
                },
              ]}
            >
              <Input name="Nombre" className="campos" />
            </Form.Item>

            {/* TEXTOS */}
            <Row gutter={14} style={{ marginBottom: '14px' }}>

              <Col span={6} style={{ textAlign: 'right', height: '12px' }}>

                <p style={{ marginBottom: '20px' }}>EPPs sugeridos para el cargo</p>
                <span style={{ color: '#aaaaaa', fontSize: '14px' }}>(Opcional)</span>

              </Col>

              <Col span={6} style={{ textAlign: 'left' }}>
                EPP
              </Col>

              <Col span={6} style={{ textAlign: 'left' }}>
                Días de validez sugeridos
              </Col>
            </Row>

            {/* AGREGAR EPP  */}
            <Row gutter={14} style={{ marginBottom: '14px' }}>

              <Col span={6} style={{ textAlign: 'right' }}></Col>

              {/* EPP */}
              <Col span={6} style={{ textAlign: 'left' }}>
                <Form.Item
                  name="Epp"
                  className={'epps-form-item'}
                  noStyle
                >
                  <Select
                    // mode="multiple"
                    className="epps-campos"
                  >
                    {props.epps.map((epp, index) => {

                      const yaIngresado = listaEpps.some((eppLista) => epp._id == eppLista.IdEpp);

                      return (
                        <Select.Option
                          key={index}
                          value={epp._id}
                          disabled={yaIngresado}
                        >
                          {epp.Nombre}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>

              {/* VALIDEZ */}
              <Col span={6} style={{ textAlign: 'left' }}>
                <Form.Item
                  name={"DiasSugeridos"}
                  className={'epps-form-item'}
                  noStyle
                >
                  <InputNumber
                    type="number"
                    min={1}
                    className={'epps-campos'}
                  />
                </Form.Item>
              </Col>

              {/* BOTON AGREGAR */}
              <Col span={2}>
                <Button
                  className={'epp-add-btn'}
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={addEpp}
                />
              </Col>

            </Row>

            {/* HR DECORATIVO */}
            <Row gutter={14}>
              <Col flex={'auto'}></Col>
              <Col span={14}>
                <hr className={'epps-hr'} />
              </Col>
              <Col span={4}></Col>
            </Row>

            {/* LISTA INGRESADA POR EL USUARIO */}
            {listaEpps.map((eppObj, index) => {

              return (

                <div key={index}>
                  {/* ELEMENTO DE LISTA EPP */}
                  <Row gutter={14} key={index}>

                    <Col span={6} style={{ textAlign: 'right' }}></Col>

                    {/* EPP */}
                    <Col span={6} style={{ textAlign: 'left' }}>
                      <Form.Item
                        className={'epps-form-item'}
                        noStyle
                      >
                        <Select
                          disabled={true}
                          className="epps-campos"
                          placeholder="(Opcional)"
                          value={eppObj.IdEpp}
                        >
                          {props.epps.map((epp, index) => (
                            <Select.Option
                              key={index}
                              value={epp._id}
                            >
                              {epp.Nombre}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    {/* VALIDEZ */}
                    <Col span={6} style={{ textAlign: 'left' }}>
                      <Form.Item
                        className={'epps-form-item'}
                        noStyle
                      >
                        <InputNumber
                          disabled={true}
                          type="number"
                          min={1}
                          className={'epps-campos'}
                          value={eppObj.DuracionSugeridaDias}
                        />
                      </Form.Item>
                    </Col>

                    {/* BOTON AGREGAR */}
                    <Col span={2}>
                      <Button
                        className={'epp-add-btn'}
                        shape="circle"
                        icon={<DeleteFilled />}
                        onClick={() => {
                          deleteEpp(eppObj.IdEpp);
                        }}
                      />
                    </Col>

                  </Row>

                  {/* HR DECORATIVO */}
                  <Row gutter={14}>
                    <Col flex={'auto'}></Col>
                    <Col span={14}>
                      <hr className={'epps-hr'} />
                    </Col>
                    <Col span={4}></Col>
                  </Row>

                </div>
              )

            })}

            {/* TEXTOS */}
            <Row gutter={14} style={{ marginBottom: '14px' }}>

              <Col span={6} style={{ textAlign: 'right', height: '12px' }}>

                <p style={{ marginBottom: '20px' }}>Documentos sugeridos para el cargo</p>
                <span style={{ color: '#aaaaaa', fontSize: '14px' }}>(Opcional)</span>

              </Col>

              <Col span={6} style={{ textAlign: 'left' }}>
                Documentos
              </Col>

            </Row>

            {/* AGREGAR DOCUMENTOS  */}
            <Row gutter={14}>

              <Col span={6} style={{ textAlign: 'right' }}></Col>

              {/* DOCUMENTO */}
              <Col span={12} style={{ textAlign: 'left' }}>
                <Form.Item
                  name="Documento"
                  className={'epps-form-item'}
                  noStyle
                >
                  <Select
                    // mode="multiple"
                    className="epps-campos"
                  >
                    {props.documentos.map((documento, index) => {

                      const yaIngresado = listaDocumentos.some((documentosLista) => documento._id == documentosLista.IdDocumento);

                      return (
                        <Select.Option
                          key={index}
                          value={documento._id}
                          disabled={yaIngresado}
                        >
                          {documento.Nombre}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>

              {/* BOTON AGREGAR */}
              <Col span={2}>
                <Button
                  className={'epp-add-btn'}
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={addDocument}
                />
              </Col>

            </Row>

            {/* HR DECORATIVO */}
            <Row gutter={14}>
              <Col flex={'auto'}></Col>
              <Col span={14}>
                <hr className={'epps-hr'} />
              </Col>
              <Col span={4}></Col>
            </Row>

            {/* LISTA INGRESADA POR EL USUARIO */}
            {listaDocumentos.map((documentoObj, index) => {

              return (

                <div key={index}>
                  {/* ELEMENTO DE LISTA EPP */}
                  <Row gutter={14} key={index}>

                    <Col span={6} style={{ textAlign: 'right' }}></Col>

                    {/* DOCUMENTOS */}
                    <Col span={12} style={{ textAlign: 'left' }}>
                      <Form.Item
                        className={'epps-form-item'}
                        noStyle
                      >
                        <Select
                          disabled={true}
                          className="epps-campos"
                          placeholder="(Opcional)"
                          value={documentoObj.IdDocumento}
                        >
                          {props.documentos.map((documento, index) => (
                            <Select.Option
                              key={index}
                              value={documento._id}
                            >
                              {documento.Nombre}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    {/* BOTON AGREGAR */}
                    <Col span={2}>
                      <Button
                        className={'epp-add-btn'}
                        shape="circle"
                        icon={<DeleteFilled />}
                        onClick={() => {
                          deleteDocument(documentoObj.IdDocumento);
                        }}
                      />
                    </Col>

                  </Row>

                  {/* HR DECORATIVO */}
                  <Row gutter={14}>
                    <Col flex={'auto'}></Col>
                    <Col span={14}>
                      <hr className={'epps-hr'} />
                    </Col>
                    <Col span={4}></Col>
                  </Row>

                </div>
              )

            })}

            {/* SUBMIT BUTTON */}
            <Form.Item label="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                checked={vigencia}
                defaultChecked
                onChange={cambiarVigencia}
                className="switch"
              />
            </Form.Item>

          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>
      </Row>

      <span>
        <style jsx="true">
          {`
            .ant-input-number-disabled .ant-input-number-input {
              color: #555555 !important;
            }

            .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
              color: #555555 !important;
            }
            `
          }
        </style>
      </span>

    </Form>
  );
};

export default FormularioCargos;
