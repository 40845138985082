import React, { Component } from "react";
import "./MantenedorVideos.css";

import reproducir_video from "../../img/icons/reproducir.svg";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import { Layout, Table } from "antd";

const { Header, Content } = Layout;
const { Column } = Table;


const dataSource = [
    {
        key: '1',
        nombre_modulo: 'CREACIÓN DE PLANTILLA (EQUIPOS DE TRABAJO)',
        enlace: 'https://drive.google.com/file/d/1D5wJWN6DY-i1HbyQawkBdhNx8EEAA96_/view?usp=sharing',
    },
    {
        key: '2',
        nombre_modulo: 'MANTENEDOR DE USUARIOS',
        enlace: 'https://drive.google.com/file/d/1NCxq4xLQnAlsQ6F4qtGjxId_-_HcA4BC/view?usp=sharing',
    },
    {
        key: '3',
        nombre_modulo: 'MANTENEDOR DE SUCURSALES',
        enlace: 'https://drive.google.com/file/d/1_IaYsLrDSvXP30V4b01F8BUqGA1Waai0/view?usp=sharing',
    },
    {
        key: '4',
        nombre_modulo: 'MANTENEDOR DE FERIADOS',
        enlace: 'https://drive.google.com/file/d/1Lr1caCVVL-Q5bgvlDBzi0mNqR4_gh4SW/view?usp=sharing',
    },
    {
        key: '5',
        nombre_modulo: 'MANTENEDOR DE CENTROS DE COSTO',
        enlace: 'https://drive.google.com/file/d/1dhGJiewuKffLnyBIxqG5Aut-0qcL-oqS/view?usp=sharing',
    },
    {
        key: '6',
        nombre_modulo: 'GENERAR CHECK-IN',
        enlace: 'https://drive.google.com/file/d/1CxI7JDoRl7cYoC036pDWZCh_ADQkaFlg/view?usp=sharing',
    },
    {
        key: '7',
        nombre_modulo: 'CONFIRMACIÓN DE ASISTENCIA',
        enlace: 'https://drive.google.com/file/d/11fM77rODCPbZmWY_3CUZuVYVcUVEzQtd/view?usp=sharing',
    },
    {
        key: '8',
        nombre_modulo: 'CREACIÓN DE ACTIVIDADES',
        enlace: 'https://drive.google.com/file/d/1SK4KZoyJG63F-V2-11WpFVDIRw81am6V/view?usp=sharing',
    },
    {
        key: '9',
        nombre_modulo: 'GENERAR CHECK-OUT',
        enlace: 'https://drive.google.com/file/d/1SmxFlUsn3B7IJHObxaeecOONFo6xuSn4/view?usp=sharing',
    },
    {
        key: '10',
        nombre_modulo: 'MANTENEDOR PERFILES DE USUARIO',
        enlace: 'https://drive.google.com/file/d/1mv4xVrZZCz04y6TH3RkLdj85R4prNBHG/view?usp=sharing',
    },
    {
        key: '11',
        nombre_modulo: 'MANTENEDOR ACCESOS DEL SISTEMA',
        enlace: 'https://drive.google.com/file/d/1TVVtt7x9HoNN7pB2UJM1s91qeoyYFqtV/view?usp=sharing',
    },
    {
        key: '12',
        nombre_modulo: 'MANTENEDOR DE REGIMEN DE TRABAJO',
        enlace: 'https://drive.google.com/file/d/108-kDFiqVo7p2ovGnM3wDylqAkxLQc9X/view?usp=sharing',
    },
    {
        key: '13',
        nombre_modulo: 'MANTENEDOR DE TITULOS',
        enlace: 'https://drive.google.com/file/d/14f04h-6yzGxHV7ogROyK0SXqWrlPRW3C/view?usp=sharing',
    },
    {
        key: '14',
        nombre_modulo: 'MANTENEDOR DE ESPECIALIDADES',
        enlace: 'https://drive.google.com/file/d/1aB_XSST835jCTOwqND4h_DgICmx1Ak65/view?usp=sharing',
    },
    {
        key: '15',
        nombre_modulo: 'MANTENEDOR DE CARGOS',
        enlace: 'https://drive.google.com/file/d/1Z5lY5G83FZeIq8hPQ_PYbcV3Ul0jdVuy/view?usp=sharing',
    },
    {
        key: '16',
        nombre_modulo: 'MANTENEDOR DE EPP',
        enlace: 'https://drive.google.com/file/d/1ZiYTaImLG6fv6XVgHbViNwlXxu7tzq40/view',
    },
    {
        key: '17',
        nombre_modulo: 'MANTENEDOR DE DOCUMENTOS',
        enlace: 'https://drive.google.com/file/d/17L4P_v1HV0ieYnQS9l_qXrXazJlEKXHI/view?usp=sharing',
    },
    {
        key: '18',
        nombre_modulo: 'GENERAR DESCANSO',
        enlace: 'https://drive.google.com/file/d/1B0diXSZN0NUFnxve6935g3hhXsTSf4CP/view?usp=sharing',
    },
    {
        key: '19',
        nombre_modulo: 'GENERAR DÍA EN TRÁNSITO ',
        enlace: 'https://drive.google.com/file/d/1Z16WAOD7GaX4t5u5dK1626XlhA0lJVTd/view?usp=sharing',
    },
    {
        key: '20',
        nombre_modulo: 'GENERAR VACACIONES',
        enlace: 'https://drive.google.com/file/d/1ccVai3Y5FPbvXF38kr07ilhuTGRZQ-Io/view?usp=sharing',
    },
    {
        key: '21',
        nombre_modulo: 'GENERAR LICENCIA MÉDICA ',
        enlace: 'https://drive.google.com/file/d/1BS5qPHo75e-VEKiHXPIvMTLQdGF6d4uJ/view?usp=sharing',
    },
    {
        key: '22',
        nombre_modulo: 'GENERAR LICENCIA MUTUALIDAD',
        enlace: 'https://drive.google.com/file/d/1uFicIn7NFmpxJn140fR8BNP81WWWjrNa/view?usp=sharing',
    },
    {
        key: '23',
        nombre_modulo: 'GENERAR PERMISO',
        enlace: 'https://drive.google.com/file/d/1MEhiHeQOjaFNFGO74lHppUH-aHxrtcPv/view?usp=sharing',
    },
    {
        key: '24',
        nombre_modulo: 'GENERAR DÍA AUSENTE',
        enlace: 'https://drive.google.com/file/d/1ff2F04ck6KWQ82CJWK_UCLi9AZd3CJfv/view?usp=sharing',
    },
];

class MantenedorVideos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tablaCargando: false,
            size: "large",
            usuariosFiltros: null,
            videos: [],
        };
    }

    render() {

        return (
            <Layout style={{ height: "100%" }}>

                <Loading cargando={this.props.loading} />

                <Header style={{ background: "none" }}>
                    <HeaderFicha titulo="Capacitaciones" subtitulo="Videos" />
                </Header>

                <hr className="division" />

                <Content
                    className="contenedor-general cont-tabla-celeste"
                    style={{ background: "none" }}
                >
                    <Table dataSource={dataSource} style={{width: '50%'}}>

                        <Column
                            title="Nombre módulo"
                            key="nombre_modulo"
                            dataIndex="nombre_modulo"
                            className="borde-columna-mantenedorVideos"
                            // align="center"
                        />
                        <Column
                            title="Enlace"
                            key="enlace"
                            dataIndex="enlace"
                            align="center"
                            render={(value) => {
                                return (
                                    <a href={value}><img src={reproducir_video} className="imagen-tabla" alt="i" /></a>
                                );
                            }}
                        />
                    </Table>

                
                </Content>

            </Layout>
        );
    }
}

export default MantenedorVideos;
