import React, { Fragment, useEffect, useState } from "react";

import ImgPro from "../../img/icons/Carpeta.svg";
import ImgHistorico from "../../img/icons/Historico.svg";
import ImgTitulos from "../../img/icons/icon_titu.png";
import ImgEspecialidades from "../../img/icons/icon_espe.png";
import flecha from "../../img/icons/flecha.svg";

import {
  Form,
  Button,
  Collapse,
  Table,
  //Space,
  Alert,
  Row,
  Col,
  PageHeader,
  DatePicker,
  Empty,
  Upload,
  Tooltip,
  Tabs,
  Space,
  Input,
  Radio,
} from "antd";

// import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import { UploadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import moment from "moment";

import { convertirFechaString } from "../../funcionesGenericas/fechas";

import API from "../../Redux/Api/api";
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";

import "./FormularioFichaProfesional.css";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Column } = Table;
const { TabPane } = Tabs;

const FormularioFichaProfesional = (props) => {
  const [titulos, setTitulos] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [documentos, setDocumentos] = useState([]);

  const [arregloSinFecha, setArregloSinFecha] = useState([]);

  const [formularios, setFormularios] = useState(null);

  const [formularioTitulos, setFormularioTitulos] = useState(null);
  const [formularioEspecialidades, setFormularioEspecialidades] = useState(null);
  const [formularioDocumentos, setFormularioDocumentos] = useState(null);
  

  //constante para manejar el formulario
  const {
    Nombre,
    Apellido_Paterno,
    Apellido_Materno,

    Titulos_Profesionales,
    Especialidades,
    TiposDeDocumentos,

    _id: IdTrabajador,
    Empresa: { _id: IdEmpresa },
  } = props.trabajador;

  useEffect(() => {
    let titulos = [];
    let especialidades = [];
    let documentos = [];

    if (Array.isArray(props.profesionalesBitacora)) {
      props.profesionalesBitacora.forEach((bitacoraProfesional) => {
        switch (bitacoraProfesional.tipo) {
          case "Titulo":
            titulos.push(bitacoraProfesional);
            break;
          case "Especialidad":
            especialidades.push(bitacoraProfesional);
            break;
          case "Documento":
            documentos.push(bitacoraProfesional);
            break;
          default:
            break;
        }
      });
    }

    setTitulos(titulos);
    setEspecialidades(especialidades);
    setDocumentos(documentos);
  }, [props.profesionalesBitacora]);

  //FUNCIONES PARA MANJERA EL FORMULARIO
  // const onFinishTitulo = (value, form) => {
  //   let usuario = localStorage.getItem("usuario-people-manage");
  //   if (
  //     usuario !== null &&
  //     usuario !== "" &&
  //     usuario !== undefined &&
  //     usuario !== "undefined"
  //   ) {
  //     //if (value.archivo.fileList.length === ) {
  //       props.activarCargando();
  //       usuario = JSON.parse(usuario);
  //       let str = moment().format("yyyy/MM/DD");
  //       const tituloEnviar = {
  //         Nombre: value.Nombre,

  //         // FechaInicioValidez: value.Validez !== undefined ? value.Validez[0].format("YYYY-MM-DDTHH:mm:ss[Z]") : null,
  //         FechaInicioValidez:
  //           value.Validez !== undefined
  //             ? convertirFechaString(value.Validez[0], 1).fechaString
  //             : null,

  //         // FechaFinValidez: value.Validez !== undefined ? value.Validez[1].format("YYYY-MM-DDTHH:mm:ss[Z]") : null,
  //         FechaFinValidez:
  //           value.Validez !== undefined
  //             ? convertirFechaString(value.Validez[1], 2).fechaString
  //             : null,

  //         Archivo: "",

  //         // FechaSubida: moment().format("YYYY-MM-DDTHH:mm:ss[Z]"),
  //         FechaSubida: convertirFechaString(moment(str), 1).fechaString,

  //         IdUsuarioUpload: usuario._id,
  //       };

  //       let archivoNuevo = value.archivo === undefined? "": value.archivo.file.originFileObj;
  //       guardarBitacoraTitulo(tituloEnviar, value.idTitulo, form, archivoNuevo);
  //     // } else {
  //     //   Swal.fire({
  //     //     title: "Uuups...",
  //     //     text: "Solo se puede subir un archivo",
  //     //     icon: "error",
  //     //     confirmButtonColor: "#E60AA3",
  //     //     confirmButtonText: "Continuar",
  //     //   });
  //     // }
  //   }
  // };

  const onFinishTitulo = async (titulos, formularios) => {

    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
        props.activarCargando();
        usuario = JSON.parse(usuario);
        let str = moment().format("yyyy/MM/DD");
        let titulosValidos = [];
        let formData = new FormData();
        let errorEncontrado = false;
        let contadorTitulosNoIngresados = 0;
        let contadorTitulosSinValidez = 0;

        titulos.forEach((titulo, index) => {

          if(!errorEncontrado){

            const validezTitulo = titulo.Validez == null ? undefined : titulo.Validez;
            const tieneValidez = titulo.hasOwnProperty('Validez');
            const archivoTitulo = Array.isArray(titulo.archivo) && titulo.archivo.length > 0 ? titulo.archivo[0] : undefined;
   
            if(validezTitulo == undefined){
              contadorTitulosNoIngresados++;
            }

            if(tieneValidez == false && archivoTitulo){
              contadorTitulosSinValidez++;
            }

            if(tieneValidez && validezTitulo && validezTitulo.length > 0){
              titulosValidos.push({
                Nombre: titulo.Nombre,
                FechaInicioValidez: convertirFechaString(validezTitulo[0], 1).fechaString,
                FechaFinValidez: convertirFechaString(validezTitulo[1], 2).fechaString,
                FechaSubida: convertirFechaString(moment(str), 1).fechaString,
                IdUsuarioUpload: usuario._id,
                IdTitulo: titulo.idTitulo,
                IdEmpresa: IdEmpresa,
                IdTrabajador: IdTrabajador
              })
  
              if(archivoTitulo){
                formData.append(titulo.Nombre, archivoTitulo.originFileObj);
              }
            } else if(tieneValidez && archivoTitulo) {

              props.desactivarCargando();
              Swal.fire({
                title: "Uuups...",
                text: `Se debe ingresar la validez del formulario ${titulo.Nombre}`,
                icon: "error",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "Continuar",
              });
              errorEncontrado = true;
              return;
            } 

            if (!tieneValidez && archivoTitulo) {
              titulosValidos.push({
                Nombre: titulo.Nombre,
                FechaSubida: convertirFechaString(moment(str), 1).fechaString,
                IdUsuarioUpload: usuario._id,
                IdTitulo: titulo.idTitulo,
                IdEmpresa: IdEmpresa,
                IdTrabajador: IdTrabajador
              })
              
              formData.append(titulo.Nombre, archivoTitulo.originFileObj);
            } 

          }

        });

        contadorTitulosNoIngresados = contadorTitulosNoIngresados - contadorTitulosSinValidez;
        if(contadorTitulosNoIngresados == titulos.length){
          contadorTitulosNoIngresados = 0;
          contadorTitulosSinValidez = 0;
          props.desactivarCargando();
          errorEncontrado = true;
          Swal.fire({
            title: "Uuups...",
            text: `Debe ingresar a lo menos un formulario para poder guardar`,
            icon: "error",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });
          return;
        }

        if(!errorEncontrado){
          formData.append("body", JSON.stringify(titulosValidos));
          guardarBitacoraTitulo(formularios, formData);
        }
    }
  };

  //FUNCIONES PARA MANJERA EL FORMULARIO
  // const onFinishEspecialidad = (value, form) => {
  //   let usuario = localStorage.getItem("usuario-people-manage");
  //   if (
  //     usuario !== null &&
  //     usuario !== "" &&
  //     usuario !== undefined &&
  //     usuario !== "undefined"
  //   ) {

  //     console.log("Form");
  //     console.log(form);

  //     //if (value.archivo.fileList.length === 1) {
  //       props.activarCargando();
  //       usuario = JSON.parse(usuario);
  //       let str = moment().format("yyyy/MM/DD");
  //       const EspecialidadEnviar = {
  //         Nombre: value.Nombre,

  //         // FechaInicioValidez: value.Validez !== undefined ? value.Validez[0].format("YYYY-MM-DDTHH:mm:ss[Z]") : null,
  //         FechaInicioValidez:
  //           value.Validez !== undefined
  //             ? convertirFechaString(value.Validez[0], 1).fechaString
  //             : null,

  //         // FechaFinValidez: value.Validez !== undefined ? value.Validez[1].format("YYYY-MM-DDTHH:mm:ss[Z]") : null,
  //         FechaFinValidez:
  //           value.Validez !== undefined
  //             ? convertirFechaString(value.Validez[1], 2).fechaString
  //             : null,

  //         Archivo: "",

  //         // FechaSubida: moment().format("YYYY-MM-DDTHH:mm:ss[Z]"),
  //         FechaSubida: convertirFechaString(moment(str), 1).fechaString,

  //         IdUsuarioUpload: usuario._id,
  //       };

  //       let archivoNuevo = value.archivo === undefined? "": value.archivo.file.originFileObj;

  //       guardarBitacoraEspecialidades(
  //         EspecialidadEnviar,
  //         value.idEspecialidad,
  //         form,
  //         archivoNuevo
  //       );
  //     // } else {
  //     //   Swal.fire({
  //     //     title: "Uuups...",
  //     //     text: "Solo se puede subir un archivo",
  //     //     icon: "error",
  //     //     confirmButtonColor: "#E60AA3",
  //     //     confirmButtonText: "Continuar",
  //     //   });
  //     // }
  //   }
  // };

  const onFinishEspecialidades = (especialidades, formularios) => {

    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {

        props.activarCargando();
        usuario = JSON.parse(usuario);
        let str = moment().format("yyyy/MM/DD");
        let especialidadesValidas = [];

        let formData = new FormData();
        let errorEncontrado = false;
        let contadorEspecialidadesSinValidez = 0;

        especialidades.forEach((especialidad, index) => {

          if(!errorEncontrado){

            const validezEspecialidad = especialidad.Validez == null ? undefined : especialidad.Validez;
            const tieneValidez = especialidad.hasOwnProperty('Validez');
            const archivoEspecialidad = Array.isArray(especialidad.archivo) && especialidad.archivo.length > 0 ? especialidad.archivo[0] : undefined;

            if(validezEspecialidad == undefined && !tieneValidez){
              contadorEspecialidadesSinValidez++;
            }
  
            if(tieneValidez && validezEspecialidad && validezEspecialidad.length > 0){
              especialidadesValidas.push({
                Nombre: especialidad.Nombre,
                FechaInicioValidez: convertirFechaString(validezEspecialidad[0], 1).fechaString,
                FechaFinValidez: convertirFechaString(validezEspecialidad[1], 2).fechaString,
                FechaSubida: convertirFechaString(moment(str), 1).fechaString,
                IdUsuarioUpload: usuario._id,
                IdEspecialidad: especialidad.idEspecialidad,
                IdEmpresa: IdEmpresa,
                IdTrabajador: IdTrabajador
              })
  
              if(archivoEspecialidad){
                formData.append(especialidad.Nombre, archivoEspecialidad.originFileObj);
              }
            } else if(tieneValidez && archivoEspecialidad) {
              props.desactivarCargando();
              Swal.fire({
                title: "Uuups...",
                text: `Se debe ingresar la validez del formulario ${especialidad.Nombre}`,
                icon: "error",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "Continuar",
              });
              errorEncontrado = true;
              return;
            }

            if (!tieneValidez && archivoEspecialidad) {
              especialidadesValidas.push({
                Nombre: especialidad.Nombre,
                FechaSubida: convertirFechaString(moment(str), 1).fechaString,
                IdUsuarioUpload: usuario._id,
                IdEspecialidad: especialidad.idEspecialidad,
                IdEmpresa: IdEmpresa,
                IdTrabajador: IdTrabajador
              })
  
              formData.append(especialidad.Nombre, especialidad.archivo.file.originFileObj);
            } 
          }
        });

        if(contadorEspecialidadesSinValidez == especialidades.length){
          Swal.fire({
            title: "Uuups...",
            text: `Debe ingresar a lo menos un formulario para poder guardar`,
            icon: "error",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });
          errorEncontrado = true;
          return;
        }

        if(!errorEncontrado){
          formData.append("body", JSON.stringify(especialidadesValidas));
          guardarBitacoraEspecialidades(formularios, formData);
        }

    }
  };
  //NUEVA DOCUMENTOS
  //const onFinishDocumentos = (value, form) => {
  const onFinishDocumentos = (documentos, formularios) => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
        props.activarCargando();
        usuario = JSON.parse(usuario);
        let str = moment().format("yyyy/MM/DD");
        let DocumentosValidos = [];
        let formData = new FormData();
        let errorEncontrado = false;
        let contadorDocumentosNoIngresados = 0;
    
        documentos.forEach(documento => {
          if(!errorEncontrado){

            let fechaValidez = undefined;
            const archivoDocumento = Array.isArray(documento.archivo) && documento.archivo.length > 0 ? documento.archivo[0] : undefined; 
            
            switch (documento.confirmarValidez) {
              case "Si":
                const validezDocumento = documento.validez == null ? undefined : documento.validez;
                if(validezDocumento == null || validezDocumento == undefined){
                  props.desactivarCargando();
                  Swal.fire({
                    title: "Uuups...",
                    text: `Se debe ingresar la validez del formulario ${documento.Nombre}`,
                    icon: "error",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "Continuar",
                  });
                  errorEncontrado= true;
                  return;
                }
                fechaValidez = validezDocumento;
      
                break;
              case "No":
                if(archivoDocumento == undefined && archivoDocumento == null){
                  props.desactivarCargando();
                  Swal.fire({
                    title: "Uuups...",
                    text: `Se debe ingresar Documento: ${documento.Nombre}`,
                    icon: "error",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "Continuar",
                  });
                  errorEncontrado= true;
                }
                break;
            
              default:
                // se valida que escoja si o no
                if(archivoDocumento != undefined && archivoDocumento != null){
                  props.desactivarCargando();
                  Swal.fire({
                    title: "Uuups...",
                    text: `Se debe seleccinar Si o No: ${documento.Nombre}`,
                    icon: "error",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "Continuar",
                  });
                  errorEncontrado= true;
                }
                break;
            }
            if(fechaValidez == undefined && archivoDocumento == undefined){
              contadorDocumentosNoIngresados++;
              //viene sin fecha validez
            
            }else{
              let nuevoDocumento = {
                Nombre: documento.Nombre,
                FechaSubida: convertirFechaString(moment(str), 1).fechaString,
                IdUsuarioUpload: usuario._id,
                IdDocumento: documento.idDocumento,
                IdEmpresa: IdEmpresa,
                IdTrabajador: IdTrabajador
              };
              if(fechaValidez){
                nuevoDocumento.FechaInicioValidez = convertirFechaString(fechaValidez[0], 1).fechaString;
                nuevoDocumento.FechaFinValidez= convertirFechaString(fechaValidez[1], 2).fechaString;
              }
              DocumentosValidos.push(nuevoDocumento);
              if(archivoDocumento !== undefined){
                formData.append(documento.Nombre, archivoDocumento.originFileObj);             
              }
            }       
            
          }
        });
        
        
        if(contadorDocumentosNoIngresados == documentos.length){
            props.desactivarCargando();
            errorEncontrado = true;
            Swal.fire({
              title: "Uuups...",
              text: `Debe ingresar a lo menos un formulario para poder guardar \n 
                    o adjuntar documento de: ${documentos.Nombre}`,
              icon: "error",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });
            return;
        }
        if(!errorEncontrado){
          formData.append("body", JSON.stringify(DocumentosValidos));
          guardarBitacoraDocumentos(formularios, formData);
        }

    }
  };

  // ANTIGUA DOCUMENTOS RESPALDO
  // const onFinishDocumentos = (value, form) => {
  //   let usuario = localStorage.getItem("usuario-people-manage");
  //   let str = moment().format("yyyy/MM/DD");

  //   if (
  //     usuario !== null &&
  //     usuario !== "" &&
  //     usuario !== undefined &&
  //     usuario !== "undefined"
  //   ) {

      
  //     if (value.confirmarValidez === "Si") {
  //       let fechaInicioValidez = value.validez[0].format("yyyy/MM/DD");
  //       let fechaFinValidez = value.validez[1].format("yyyy/MM/DD");

  //       // if (value.archivo) {

  //       // if (value.archivo.fileList.length === 1) {
  //         props.activarCargando();
  //         usuario = JSON.parse(usuario);

  //         const documentoEnviar = {
  //           Nombre: value.Nombre,
  //           Archivo: "",

  //           // FechaSubida: moment().format("YYYY-MM-DDTHH:mm:ss[Z]"),
  //           FechaSubida: convertirFechaString(moment(str), 1).fechaString,
  //           IdUsuarioUpload: usuario._id,
  //           FechaInicioValidez: convertirFechaString(
  //             moment(fechaInicioValidez),
  //             1
  //           ).fechaString,
  //           FechaFinValidez: convertirFechaString(moment(fechaFinValidez), 2)
  //             .fechaString,
  //         };

  //         let archivoNuevo = value.archivo === undefined? "": value.archivo.file.originFileObj;

  //         guardarBitacoraDocumentos(
  //           documentoEnviar,
  //           value.idDocumento,
  //           form,
  //           archivoNuevo
  //         );
  //       // } else {
  //       //   Swal.fire({
  //       //     title: "¡Ups!",
  //       //     text: "Solo se puede subir un archivo",
  //       //     icon: "error",
  //       //     confirmButtonColor: "#E60AA3",
  //       //     confirmButtonText: "Continuar",
  //       //   });
  //       // }

  //       // } else {

  //       //   props.activarCargando();
  //       //   usuario = JSON.parse(usuario);

  //       //   const documentoEnviar = {
  //       //     Nombre: value.Nombre,
  //       //     FechaSubida: convertirFechaString(moment(str), 1).fechaString,
  //       //     IdUsuarioUpload: usuario._id,
  //       //     FechaInicioValidez: convertirFechaString(moment(fechaInicioValidez), 1).fechaString,
  //       //     FechaFinValidez: convertirFechaString(moment(fechaFinValidez), 2).fechaString,
  //       //   };

  //       //   guardarBitacoraDocumentosSinFechaValidez(
  //       //     documentoEnviar,
  //       //     value.idDocumento,
  //       //     form
  //       //   );

  //       // }
  //     } else {
  //       props.activarCargando();
  //       usuario = JSON.parse(usuario);

  //       const documentoEnviar = {
  //         Nombre: value.Nombre,
  //         Archivo: "",
  //         FechaSubida: convertirFechaString(moment(str), 1).fechaString,
  //         IdUsuarioUpload: usuario._id,
  //       };

  //       let archivoNuevo = value.archivo === undefined? "": value.archivo.file.originFileObj;

  //       guardarBitacoraDocumentos(
  //         documentoEnviar,
  //         value.idDocumento,
  //         form,
  //         archivoNuevo
  //       );
  //     }
  //   }
  // };


  const guardarBitacoraTitulo = async (
    forms,
    formData
  ) => {
    const url = `${API}/BitacoraProfesional/Titulos`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      body: formData,
    });

    const status = response.status;
  
    switch (status) {
      case 200:
      case 201:
      
        forms.forEach(form => {
          form.resetFields();
        });
        
        try {
          const botones = document.querySelectorAll(".ant-upload-list-item-card-actions-btn");
          botones.forEach(btn => {
            btn.click();
          });
        } catch (err) {
          console.log(err);
        }

        await props.actualizarInformacionUsuario();
        props.desactivarCargando();
        
        Swal.fire({
          title: "Bien",
          text: "Se guardó la entrega correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;

      case 204:
        props.desactivarCargando();
        Swal.fire({
          title: "Ups...",
          text: "Ha ocurrido un error",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
      default:
        props.desactivarCargando();
        Swal.fire({
          title: "Ups...",
          text: "Ha ocurrido un error",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
    }
  };

  // const guardarBitacoraTitulo = async (
  //   tituloEnviar,
  //   idTitulo,
  //   form,
  //   archivo
  // ) => {
  //   const BitacoraTitulo = {
  //     IdTrabajador,
  //     IdEmpresa,
  //     Titulos: [tituloEnviar],
  //     idTitulo,
  //   };

  //   let formData = new FormData();
  //   formData.append("file", archivo);
  //   formData.append("body", JSON.stringify(BitacoraTitulo));

  //   const url = `${API}/BitacoraProfesional/Titulos`;
  //   const response = await fetch(url, {
  //     method: "POST",
  //     mode: "cors",
  //     body: formData,
  //   });
  //   const status = response.status;
  //   const data = await response.json();
  //   switch (status) {
  //     case 200:
  //     case 201:
  //       form.resetFields();

  //       try {
  //         document
  //           .querySelector(".ant-upload-list-item-card-actions-btn")
  //           .click();
  //       } catch (err) {
  //         console.log(err);
  //       }
  //       await props.actualizarInformacionUsuario();

  //       props.desactivarCargando();
  //       Swal.fire({
  //         title: "Bien",
  //         text: "Se guardó la entrega correctamente",
  //         icon: "success",
  //         confirmButtonColor: "#E60AA3",
  //         confirmButtonText: "Continuar",
  //       });

  //       return;
  //     case 204:
  //       props.desactivarCargando();
  //       Swal.fire({
  //         title: "Ups...",
  //         text: "Ha ocurrido un error",
  //         icon: "error",
  //         confirmButtonColor: "#E60AA3",
  //         confirmButtonText: "Continuar",
  //       });

  //       return;
  //     default:
  //       props.desactivarCargando();
  //       Swal.fire({
  //         title: "Ups...",
  //         text: "Ha ocurrido un error",
  //         icon: "error",
  //         confirmButtonColor: "#E60AA3",
  //         confirmButtonText: "Continuar",
  //       });

  //       return;
  //   }
  // };

  const guardarBitacoraEspecialidades = async (
    forms,
    formData
  ) => {

    const url = `${API}/BitacoraProfesional/Especialidades`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      body: formData,
    });

    const status = response.status;
    
    switch (status) {
      case 200:
      case 201:

        forms.forEach(form => {
          form.resetFields();
        });
        
        try {
          const botones = document.querySelectorAll(".ant-upload-list-item-card-actions-btn");
          botones.forEach(btn => {
            btn.click();
          });
        } catch (err) {
          console.log(err);
        }

        await props.actualizarInformacionUsuario();

        props.desactivarCargando();
        Swal.fire({
          title: "Bien",
          text: "Se guardó la entrega correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
      case 204:
        props.desactivarCargando();
        Swal.fire({
          title: "Ups...",
          text: "Ha ocurrido un error",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
      default:
        props.desactivarCargando();
        Swal.fire({
          title: "Ups...",
          text: "Ha ocurrido un error",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
    }
  };

  // const guardarBitacoraEspecialidades = async (
  //   especialidadEnviar,
  //   idEspecialidad,
  //   form,
  //   archivo
  // ) => {
  //   const BitacoraTitulo = {
  //     IdTrabajador,
  //     IdEmpresa,
  //     Especialidades: [especialidadEnviar],
  //     idEspecialidad,
  //   };

  //   let formData = new FormData();
  //   formData.append("file", archivo);
  //   formData.append("body", JSON.stringify(BitacoraTitulo));

  //   const url = `${API}/BitacoraProfesional/Especialidades`;
  //   const response = await fetch(url, {
  //     method: "POST",
  //     mode: "cors",
  //     body: formData,
  //   });
  //   const status = response.status;
  //   const data = await response.json();
  //   switch (status) {
  //     case 200:
  //     case 201:
  //       form.resetFields();
  //       try {
  //         document
  //           .querySelector(".ant-btn ant-upload-list-item-card-actions-btn")
  //           .click();
  //       } catch (err) {
  //         console.log(err);
  //       }
  //       await props.actualizarInformacionUsuario();

  //       props.desactivarCargando();
  //       Swal.fire({
  //         title: "Bien",
  //         text: "Se guardó la entrega correctamente",
  //         icon: "success",
  //         confirmButtonColor: "#E60AA3",
  //         confirmButtonText: "Continuar",
  //       });

  //       return;
  //     case 204:
  //       props.desactivarCargando();
  //       Swal.fire({
  //         title: "Ups...",
  //         text: "Ha ocurrido un error",
  //         icon: "error",
  //         confirmButtonColor: "#E60AA3",
  //         confirmButtonText: "Continuar",
  //       });

  //       return;
  //     default:
  //       props.desactivarCargando();
  //       Swal.fire({
  //         title: "Ups...",
  //         text: "Ha ocurrido un error",
  //         icon: "error",
  //         confirmButtonColor: "#E60AA3",
  //         confirmButtonText: "Continuar",
  //       });

  //       return;
  //   }
  // };

  
  //CONEXION DOCUMENTOS API BACKEND NUEVA
  const guardarBitacoraDocumentos = async(
    forms,
    formData
  )=>{
  
    const url = `${API}/BitacoraProfesional/NuevoDocumento`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      body: formData,
    });

    const status = response.status;
    switch (status) {
      case 200:
      case 201:
      
        forms.forEach(form => {
          form.resetFields();
        });
        
        try {
          const botones = document.querySelectorAll(".ant-upload-list-item-card-actions-btn");
          botones.forEach(btn => {
            btn.click();
          });
        } catch (err) {
          console.log(err);
        }

        await props.actualizarInformacionUsuario();
        props.desactivarCargando();
        
        Swal.fire({
          title: "Bien",
          text: "Se guardó la entrega correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;

      case 204:
        props.desactivarCargando();
        Swal.fire({
          title: "Ups...",
          text: "Ha ocurrido un error",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
      default:
        props.desactivarCargando();
        Swal.fire({
          title: "Ups...",
          text: "Ha ocurrido un error",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
    }
  }
  
  //CONEXION DOCUMENTOS API BACKEND ANTIGUA
  // const guardarBitacoraDocumentos = async (
  //   documentoEnviar,
  //   idDocumento,
  //   form,
  //   archivo
  // ) => {
    
  //   const BitacoraDocumentos = {
  //     IdTrabajador,
  //     IdEmpresa,
  //     Documentos: [documentoEnviar],
  //     idDocumento,
  //   };

  //   let formData = new FormData();
  //   formData.append("file", archivo);
  //   formData.append("body", JSON.stringify(BitacoraDocumentos));

  //   const url = `${API}/BitacoraProfesional/Documentos`;
  //   const response = await fetch(url, {
  //     method: "POST",
  //     mode: "cors",
  //     body: formData,
  //   });
  //   const status = response.status;
  //   const data = await response.json();
  //   switch (status) {
  //     case 200:
  //     case 201:
  //       form.resetFields();

  //       try {
  //         document
  //           .querySelector(".ant-btn ant-upload-list-item-card-actions-btn")
  //           .click();
  //       } catch (err) {
  //         console.log(err);
  //       }
  //       await props.actualizarInformacionUsuario();

  //       props.desactivarCargando();
  //       Swal.fire({
  //         title: "Bien",
  //         text: "Se guardó la entrega correctamente",
  //         icon: "success",
  //         confirmButtonColor: "#E60AA3",
  //         confirmButtonText: "Continuar",
  //       });

  //       return;
  //     case 204:
  //       props.desactivarCargando();
  //       Swal.fire({
  //         title: "Ups...",
  //         text: "Ha ocurrido un error",
  //         icon: "error",
  //         confirmButtonColor: "#E60AA3",
  //         confirmButtonText: "Continuar",
  //       });

  //       return;
  //     default:
  //       props.desactivarCargando();
  //       Swal.fire({
  //         title: "Ups...",
  //         text: "Ha ocurrido un error",
  //         icon: "error",
  //         confirmButtonColor: "#E60AA3",
  //         confirmButtonText: "Continuar",
  //       });

  //       return;
  //   }
  // };

  const guardarBitacoraDocumentosSinFechaValidez = async (
    documentoEnviar,
    idDocumento,
    form
  ) => {
    const BitacoraDocumentos = {
      IdTrabajador,
      IdEmpresa,
      Documentos: [documentoEnviar],
      idDocumento,
    };

    let formData = new FormData();
    formData.append("body", JSON.stringify(BitacoraDocumentos));

    const url = `${API}/BitacoraProfesional/DocumentosSinValidez`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      body: formData,
    });
    const status = response.status;
    const data = await response.json();
    switch (status) {
      case 200:
      case 201:
        form.resetFields();

        try {
          document
            .querySelector(".ant-btn ant-upload-list-item-card-actions-btn")
            .click();
        } catch (err) {
          console.log(err);
        }
        await props.actualizarInformacionUsuario();

        props.desactivarCargando();
        Swal.fire({
          title: "Bien",
          text: "Se guardó la entrega correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
      case 204:
        props.desactivarCargando();
        Swal.fire({
          title: "Ups...",
          text: "Ha ocurrido un error",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
      default:
        props.desactivarCargando();
        Swal.fire({
          title: "Ups...",
          text: "Ha ocurrido un error",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 9,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 13,
      },
    },
  };

  const mostrarDetalleEspecialidades = (
    Expira,
    FechaVencimiento,
    cantidadDiasHabiles,
    encontrado
  ) => {
    if (encontrado === true) {
      if (Expira) {
        if (cantidadDiasHabiles > 0) {
          return (
            <>
              <Alert message="Especialidad ingresado" type="success" showIcon />
              <p>Fecha vencimiento: {FechaVencimiento}</p>
              <p>Dias para vencimiento: {cantidadDiasHabiles}</p>
            </>
          );
        } else {
          cantidadDiasHabiles = -cantidadDiasHabiles + 1;
          return (
            <>
              <Alert message="Especialidad vencida" type="error" showIcon />
              {/* <p>Fecha vencimiento: Especialidad Vencida</p> */}
              <p>Fecha vencimiento: {FechaVencimiento}</p>
              <p>Dias que lleva vencido: {cantidadDiasHabiles}</p>
            </>
          );
        }
      } else {
        return (
          <>
            <Alert message="Especialidad ingresada" type="success" showIcon />
          </>
        );
      }
    } else {
      return (
        <>
          <Alert message="Especialidad no ingresada" type="error" showIcon />
        </>
      );
    }
  };

  const mostrarDetalleTitulos = (
    Expira,
    FechaVencimiento,
    cantidadDiasHabiles,
    encontrado
  ) => {
    if (encontrado === true) {
      if (Expira) {
        if (cantidadDiasHabiles > 0) {
          return (
            <>
              <Alert message="Título ingresado" type="success" showIcon />
              <p>Fecha vencimiento: {FechaVencimiento}</p>
              <p>Dias para vencimiento: {cantidadDiasHabiles}</p>
            </>
          );
        } else {
          cantidadDiasHabiles = -cantidadDiasHabiles + 1;
          return (
            <>
              <Alert message="Título vencido" type="error" showIcon />
              {/* <p>Fecha vencimiento: Título Vencido</p> */}
              <p>Fecha vencimiento: {FechaVencimiento}</p>
              <p>Dias que lleva vencido: {cantidadDiasHabiles}</p>
            </>
          );
        }
      } else {
        return (
          <>
            <Alert message="Título ingresado" type="success" showIcon />
          </>
        );
      }
    } else {
      return (
        <>
          <Alert message="Titulo no ingresado" type="error" showIcon />
        </>
      );
    }
  };

  const mostrarDetalleDocumentos = (
    isIngresado,
    FechaVencimiento,
    cantidadDiasHabiles
  ) => {

    if (isIngresado === true) {
    
      if (FechaVencimiento) {
        
        if (cantidadDiasHabiles > 0) {
          return (
            <>
              <Alert message="Documento ingresado" type="success" showIcon />
              <p>Fecha vencimiento: {FechaVencimiento}</p>
              <p>Dias para vencimiento: {cantidadDiasHabiles}</p>
            </>
          );
        } else {
          cantidadDiasHabiles = -cantidadDiasHabiles + 1;
          return (
            <>
              <Alert message="Documento Vencido" type="error" showIcon />
              <p>Fecha vencimiento: {FechaVencimiento}</p>
              <p>Dias que lleva vencido: {cantidadDiasHabiles}</p>
            </>
          );
        }
      } else {
        return (
          <>
            <Alert message="Documento ingresado" type="success" showIcon />
          </>
        );
      }
    } else {
      return (
        <>
          <Alert message="Documento no ingresado" type="error" showIcon />
        </>
      );
    }
  };

  const mostrarFechaValidacion = (
    idDocumento,
    isIngresado,
    FechaFinValidez,
    index
  ) => {
    let cant = arregloSinFecha.filter(
      (idArray) => idArray.toString() === idDocumento.toString()
    );

    if (cant.length > 0) {
      return (
        <>
          {/* VALIDEZ */}
          <Form.Item
            name="validez"
            label="Validez:"
            rules={[
              {
                required: true,
                message: "Debes introducir el periodo de validez del documento",
              },
            ]}
          >
            <RangePicker
              locale={locale}
              format={"DD-MM-YYYY"}
              style={{ marginBottom: "6%", width: "68%" }}
            />
          </Form.Item>
          {/* Documento nuevo  */}
          <Form.Item
            label="Subir documento"
            name="archivo"
            getValueFromEvent={(e) => {
              if(Array.isArray(e)){
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload
              disabled={!props.Editar}
              accept={".pdf"}
              listType="picture"
              multiple={false}
              id={`contenedorArchivo-${index}`}
              action={"#"}
              className="upload-list-inline"
              method={"GET"}
              openFileDialogOnClick={true}
          
            >
            <Button
              //disabled={props.subirArchivoDeshabilitado}
              className="subir-archivos"
              id={`archivo-${index}`}
              disabled={!props.Editar}
            >
              <UploadOutlined name="archivoSubir" /> Subir
              Archivo
            </Button>
            </Upload>
          </Form.Item>
          {/* <Form.Item
            label="Subir documento"
            name="archivo"
            // rules={[
            //   {
            //     required: true,
            //     message: "Debes introducir el archivo del documento",
            //   },
            // ]}
          >
            <Upload
              disabled={!props.Editar}
              accept={".pdf"}
              listType="picture"
              multiple={false}
              id={`contenedorArchivo-${index}`}
              action={"#"}
              className="upload-list-inline"
              method={"GET"}
              openFileDialogOnClick={true}
            >
              <Button
                //disabled={props.subirArchivoDeshabilitado}
                className="subir-archivos"
                id={`archivo-${index}`}
                disabled={!props.Editar}
              >
                <UploadOutlined name="archivoSubir" /> Subir Archivo
              </Button>
            </Upload>
          </Form.Item> */}
        </>
      );
    } else {
      return (
        <>
        {/* //Seba cambio de archivo */}
        <Form.Item
          label="Subir documento"
          name="archivo"
          getValueFromEvent={(e) => {
            if(Array.isArray(e)){
              return e;
            }
            return e && e.fileList;
          }}
        >
          <Upload
            disabled={!props.Editar}
            accept={".pdf"}
            listType="picture"
            multiple={false}
            id={`contenedorArchivo-${index}`}
            action={"#"}
            className="upload-list-inline"
            method={"GET"}
            openFileDialogOnClick={true}
        
          >
          <Button
            //disabled={props.subirArchivoDeshabilitado}
            className="subir-archivos"
            id={`archivo-${index}`}
            disabled={!props.Editar}
          >
            <UploadOutlined name="archivoSubir" /> Subir
            Archivo
          </Button>
          </Upload>
        </Form.Item>
          {/* <Form.Item
            label="Subir documento"
            name="archivo"
            // rules={[
            //   {
            //     required: true,
            //     message: "Debes introducir el archivo del documento",
            //   },
            // ]}
          >
            <Upload
              disabled={!props.Editar}
              accept={".pdf"}
              listType="picture"
              multiple={false}
              id={`contenedorArchivo-${index}`}
              action={"#"}
              className="upload-list-inline"
              method={"GET"}
              openFileDialogOnClick={true}
            >
              <Button
                //disabled={props.subirArchivoDeshabilitado}
                className="subir-archivos"
                id={`archivo-${index}`}
                disabled={!props.Editar}
              >
                <UploadOutlined name="archivoSubir" /> Subir Archivo
              </Button>
            </Upload>
          </Form.Item> */}
        </>
      );
    }
  };


//Funcion eliminar titulo
  const eliminarTitulo = async (values, idTrabajador) =>{
    try {
      props.activarCargando();
      const url = `${API}/BitacoraProfesional/Titulos`;
      const response = await fetch(url, {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ID_TRABAJADOR: idTrabajador,
          ID_DOCUMENTO_INGRESADO: values._id,
        }),
      });
      const status = response.status;
      const data = await response.json();

      switch (status) {
        case 200:
        case 201:
          await props.actualizarInformacionUsuario();
          props.desactivarCargando();
          return;

        default:
          props.desactivarCargando();
          Swal.fire({
            title: "Ups...",
            text: "Ha ocurrido un error",
            icon: "error",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });
          return;
      }
    } catch (error) {
      console.log(error);
    }
  }
  const eliminarDocumento = async (values, idTrabajador) => {
    try {
      props.activarCargando();
      const url = `${API}/BitacoraProfesional/Documentos`;

      const response = await fetch(url, {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ID_TRABAJADOR: idTrabajador,
          ID_DOCUMENTO_INGRESADO: values._id,
        }),
      });
      const status = response.status;
      const data = await response.json();
      switch (status) {
        case 200:
        case 201:
          await props.actualizarInformacionUsuario();

          props.desactivarCargando();
          // props.desactivarCargando();
          return;
        case 204:
          // props.desactivarCargando();
          // Swal.fire({
          //   title: "Ups...",
          //   text: "Ha ocurrido un error",
          //   icon: "error",
          //   confirmButtonColor: "#E60AA3",
          //   confirmButtonText: "Continuar",
          // });

          return;
        default:
          // props.desactivarCargando();
          // Swal.fire({
          //   title: "Ups...",
          //   text: "Ha ocurrido un error",
          //   icon: "error",
          //   confirmButtonColor: "#E60AA3",
          //   confirmButtonText: "Continuar",
          // });

          return;
      }
    } catch (error) {
      console.log(error);
    }
  };

 //funcion eliminar especialidad
  const eliminarEspecialidad = async (values, idTrabajador) => {
    try {
      props.activarCargando();
      const url = `${API}/BitacoraProfesional/Especialidades`;
      const response = await fetch(url, {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ID_TRABAJADOR: idTrabajador,
          ID_DOCUMENTO_INGRESADO: values._id,
        }),
      });
      const status = response.status;
      const data = await response.json();
 
      switch (status) {
        case 200:
        case 201:
          await props.actualizarInformacionUsuario();
          props.desactivarCargando();
          return;

        default:
          props.desactivarCargando();
          Swal.fire({
            title: "Ups...",
            text: "Ha ocurrido un error",
            icon: "error",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });

          return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row style={{ padding: "5px 20px 0px 20px" }}>
      <Col span={24} style={{ display: "flex" }} className="title">
        <img src={ImgPro} style={{ width: "35px" }} alt="icono modal" />
        <PageHeader
          style={{ padding: "0px 10px 0px 10px" }}
          className="site-page-header"
          title={`Ficha Profesional de ${Nombre} ${Apellido_Paterno} ${Apellido_Materno}`}
        />
      </Col>

      <Col offset={1} span={24}>
        <Collapse
          bordered={false}
          defaultActiveKey={["0"]}
          className="formulario-trabajador site-collapse-custom-collapse cont-tabla-celeste"
        >
          {/* ---------------------------------------------TÍTULOS------------------------------------------------ */}

          <Panel
            header={
              <h4
                style={{
                  position: "absolute",
                  bottom: -5,
                  color: "#061fcb",
                  left: 35,
                }}
              >
                Títulos
              </h4>
            }
            key="titulos"
            className=" site-collapse-custom-panel"
            extra={[
              <img
                key="3"
                src={flecha}
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
                }}
                alt="icono"
              />,
              <img
                key="4"
                src={ImgTitulos}
                style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
                }}
                alt="icono"
              />,
            ]}
          >
            <Form.Provider
              onFormChange={(name, info) => {
                if(formularioTitulos === null){

                  let formularios = info.forms;
                  let arrayFormularios = Object.values(formularios);

                  setFormularioTitulos({
                    "InfoFormularios": info.forms, 
                    "Formularios": arrayFormularios
                  });
                }
              }}

            >
              {Titulos_Profesionales.length !== 0 ? (

                <>
                
                  {

                    Titulos_Profesionales.map(
                      (
                        {
                          Nombre,
                          Expira,
                          _id,
                          FechaVencimiento,
                          cantidadDiasHabiles,
                          encontrado,
                        },
                        index
                      ) => (
                        <Fragment key={index}>
                          <p className="m-fix"></p>
                          {mostrarDetalleTitulos(
                            Expira,
                            FechaVencimiento,
                            cantidadDiasHabiles,
                            encontrado
                          )}

                          <Form
                            {...formItemLayout}
                            name={`Formulario_${index}`}
                            onFinishFailed={(values) => {
                              onFinishFailed(values);
                            }}
                          >
                            {() => (
                              <>
                                <h3
                                  style={{
                                    fontFamily: "MavenPro-Bold",
                                    textAlign: "center",
                                  }}
                                >
                                  {Nombre}
                                </h3>
                                <Form.Item
                                  name="Nombre"
                                  style={{ display: "none" }}
                                  initialValue={Nombre}
                                ></Form.Item>
                                <Form.Item
                                  name="idTitulo"
                                  style={{ display: "none" }}
                                  initialValue={_id}
                                ></Form.Item>

                                {Expira ? (
                                  <Form.Item
                                    label="Validez"
                                    name="Validez"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Debes introducir el periodo de validez del título profesional",
                                      },
                                    ]}
                                  >
                                    <RangePicker
                                      locale={locale}
                                      format={"DD-MM-YYYY"}
                                    />
                                  </Form.Item>
                                ) : (
                                  <Form.Item key={index} label="Validez">
                                    Este título no expira
                                  </Form.Item>
                                )}

                                <Form.Item
                                  label="Subir documento"
                                  name="archivo"
                                  getValueFromEvent={(e) => {
                                    if(Array.isArray(e)){
                                      return e;
                                    }
                                    return e && e.fileList;
                                  }}
                                >
                                  <Upload
                                    disabled={!props.Editar}
                                    accept={".pdf"}
                                    listType="picture"
                                    multiple={false}
                                    id={`contenedorArchivo-${index}`}
                                    action={"#"}
                                    className="upload-list-inline"
                                    method={"GET"}
                                    openFileDialogOnClick={true}
                                   
                                    >
                                    <Button
                                      //disabled={props.subirArchivoDeshabilitado}
                                      className="subir-archivos"
                                      id={`archivo-${index}`}
                                      disabled={!props.Editar}
                                    >
                                      <UploadOutlined name="archivoSubir" /> Subir
                                      Archivo
                                    </Button>
                                  </Upload>
                                </Form.Item>

                              </>
                            )}

                          </Form>
                        </Fragment>
                      )
                    )

                  }

                  <Button
                    className="ant-btn crear-embarco-button"
                    style={{
                      width: "50%",
                      marginLeft: "25%",
                      marginBottom: "10%",
                    }}
                    htmlType="submit"
                    disabled={!props.Editar}
                    onClick={()=>{
                      try{

                        if(formularioTitulos == null){
                          Swal.fire({
                            title: "Uuups...",
                            text: `Debe ingresar a lo menos un formulario para poder guardar`,
                            icon: "error",
                            confirmButtonColor: "#E60AA3",
                            confirmButtonText: "Continuar",
                          });
                          return;
                        }

                        let arrayTitulos = [];
                        const forms = Object.values(formularioTitulos.InfoFormularios);
                        forms.forEach(form => {
                          arrayTitulos.push(form.getFieldsValue());
                        });
                        onFinishTitulo(arrayTitulos, formularioTitulos.Formularios);
                      }catch(error){
                        console.log(error);
                      }
                    }}
                  >
                    Guardar
                  </Button>

                </>
                

              ) : (
                <Empty
                  style={{ marginBottom: "20px" }}
                  imageStyle={{ height: 100, marginTop: "20px" }}
                  description={
                    <span className={"seleccion-trabajador-disponible"}>
                      <b>No se han requerido títulos para este trabajador</b>
                    </span>
                  }
                ></Empty>
              )}
            </Form.Provider>
          </Panel>

          {/* --------------------------------------------ESPECIALIDADES------------------------------------------- */}

          <Panel
            header={
              <h4
                style={{
                  position: "absolute",
                  bottom: -5,
                  color: "#061fcb",
                  left: 35,
                }}
              >
                Especialidades
              </h4>
            }
            key="especialidades"
            className=" site-collapse-custom-panel"
            extra={[
              <img
                key="5"
                src={flecha}
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
                }}
                alt="icono"
              />,
              <img
                key="6"
                src={ImgEspecialidades}
                style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
                }}
                alt="icono"
              />,
            ]}
          >
            <Form.Provider
              onFormChange={(name, info) => {
                if(formularioEspecialidades === null){

                  let formularios = info.forms;
                  let arrayFormularios = Object.values(formularios);

                  setFormularioEspecialidades({
                    "InfoFormularios": info.forms, 
                    "Formularios": arrayFormularios
                  });
                }
              }}

            >
              {Especialidades.length !== 0 ? (

                <>
                
                  {

                    Especialidades.map(
                      (
                        {
                          Nombre,
                          Expira,
                          _id,
                          FechaVencimiento,
                          cantidadDiasHabiles,
                          encontrado,
                        },
                        index
                      ) => (
                        <Fragment key={index}>
                          <p className="m-fix"></p>

                          <Form
                            {...formItemLayout}
                            name={`Formulario_${index}`}
                            onFinishFailed={(values) => {
                              onFinishFailed(values);
                            }}
                          >
                            {mostrarDetalleEspecialidades(
                              Expira,
                              FechaVencimiento,
                              cantidadDiasHabiles,
                              encontrado
                            )}
                            <h3
                              style={{
                                fontFamily: "MavenPro-Bold",
                                textAlign: "center",
                              }}
                            >
                              {Nombre}
                            </h3>
                            <Form.Item
                              name="Nombre"
                              style={{ display: "none" }}
                              initialValue={Nombre}
                            ></Form.Item>
                            <Form.Item
                              name="idEspecialidad"
                              style={{ display: "none" }}
                              initialValue={_id}
                            ></Form.Item>

                            {Expira ? (
                              <Form.Item
                                label="Validez"
                                name="Validez"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Debes introducir el periodo de validez del titulo profesional",
                                  },
                                ]}
                              >
                                <RangePicker
                                  locale={locale}
                                  format={"DD-MM-YYYY"}
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item key={index} label="Validez">
                                Esta especialidad no expira
                              </Form.Item>
                            )}

                            <Form.Item
                              label="Subir documento"
                              name="archivo"
                              getValueFromEvent={(e) => {
                                if(Array.isArray(e)){
                                  return e;
                                }
                                return e && e.fileList;
                              }}
                            >
                              <Upload
                                disabled={!props.Editar}
                                accept={".pdf"}
                                listType="picture"
                                multiple={false}
                                id={`contenedorArchivo-${index}`}
                                action={"#"}
                                className="upload-list-inline"
                                method={"GET"}
                                openFileDialogOnClick={true}
                              >
                                <Button
                                  //disabled={props.subirArchivoDeshabilitado}
                                  className="subir-archivos"
                                  id={`archivo-${index}`}
                                  disabled={!props.Editar}
                                >
                                  <UploadOutlined name="archivoSubir" /> Subir
                                  Archivo
                                </Button>
                              </Upload>
                            </Form.Item>

                          </Form>
                        </Fragment>
                      )
                    )

                  }

                  <Button
                    className="ant-btn crear-embarco-button"
                    style={{
                      width: "50%",
                      marginLeft: "25%",
                      marginBottom: "10%",
                    }}
                    htmlType="submit"
                    disabled={!props.Editar}
                    onClick={()=>{
                      try{

                        if(formularioEspecialidades == null){
                          Swal.fire({
                            title: "Uuups...",
                            text: `Debe ingresar a lo menos un formulario para poder guardar`,
                            icon: "error",
                            confirmButtonColor: "#E60AA3",
                            confirmButtonText: "Continuar",
                          });
                          return;
                        }

                        let arrayEspecialidades = [];
                        const forms = Object.values(formularioEspecialidades.InfoFormularios);
                        forms.forEach(form => {
                          arrayEspecialidades.push(form.getFieldsValue());
                        });
                        onFinishEspecialidades(arrayEspecialidades, formularioEspecialidades.Formularios);
                      }catch(error){
                        console.log(error);
                      }
                    }}
                  >
                    Guardar
                  </Button>

                </>


              ) : (
                <Empty
                  style={{ marginBottom: "20px" }}
                  imageStyle={{ height: 100, marginTop: "20px" }}
                  description={
                    <span className={"seleccion-trabajador-disponible"}>
                      <b>
                        No se han requerido especialidades para este trabajador
                      </b>
                    </span>
                  }
                ></Empty>
              )}
            </Form.Provider>
          </Panel>

          {/* --------------------------------------------DOCUMENTOS------------------------------------------- */}

          {/* <Panel
            header={
              <h4
                style={{
                  position: "absolute",
                  bottom: -5,
                  color: "#061fcb",
                  left: 35,
                }}
              >
                Documentos
              </h4>
            }
            key="documentos"
            className=" site-collapse-custom-panel"
            extra={[
              <img
                key="5"
                src={flecha}
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
                }}
                alt="icono"
              />,
              <img
                key="6"
                src={ImgEspecialidades}
                style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
                }}
                alt="icono"
              />,
            ]}
          >
            <Form.Provider
              onFormFinish={(name, info) => {
                let formularios = info.forms;

                let form = formularios[name];
                onFinishDocumentos(info.values, form);
              }}
            >
              {TiposDeDocumentos.length !== 0 ? (
                TiposDeDocumentos.map(
                  (
                    {
                      _id,
                      Nombre,
                      Archivo,

                      isIngresado,
                      FechaFinValidez,
                      cantidadDiasHabiles,
                    },
                    index
                  ) => (
                    <Fragment key={index}>
                      <p className="m-fix"></p>

                      <Form
                        {...formItemLayout}
                        name={`Formulario_${index}`}
                        onFinishFailed={(values) => {
                          onFinishFailed(values);
                        }}
                      >
                        <Form.Item
                          name="idDocumento"
                          type="text"
                          style={{ display: "none" }}
                          initialValue={_id}
                        ></Form.Item>

                        <Form.Item
                          name="Nombre"
                          style={{ display: "none" }}
                          initialValue={Nombre}
                        ></Form.Item>

                        <Form.Item
                          name="Archivo"
                          type="text"
                          style={{ display: "none" }}
                          initialValue={Archivo}
                        ></Form.Item>
                        
                        {
                          mostrarDetalleDocumentos(
                            isIngresado,
                            FechaFinValidez,
                            cantidadDiasHabiles
                          )                 
                        }
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "13px",
                            marginBottom: "10px",
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "MavenPro-Bold",
                              textAlign: "center",
                              display: "inline-block",
                            }}
                          >
                            {Nombre}
                          </h3>

                          <Tooltip
                            title="Descargar documento base"
                            color={"#64b4e5"}
                          >
                            <Button
                              style={{
                                display: "inline-block",
                                marginLeft: "7px",
                              }}
                              onClick={() => {
                                let url = Archivo;
                                let win = window.open(url, "_blank");
                                win.focus();
                              }}
                              className="descargar-doc-button"
                              // disabled={!this.state.Ver}
                              icon={
                                <DownloadOutlined
                                  style={{ fontSize: "15px" }}
                                />
                              }
                            />
                          </Tooltip>
                        </div>

                        

                        <Form.Item
                          label="¿El documento tiene periodo de validez?"
                          name="confirmarValidez"
                          rules={[
                            {
                              required: true,
                              message:
                                "Debe introducir si el documento tiene periodo de validez",
                            },
                          ]}
                        >
                          <Radio.Group
                            style={{ display: "flex" }}
                            onChange={(value) => {
                              if (value.target.value === "Si") {
                                let array = [...arregloSinFecha];
                                array.push(_id);
                                setArregloSinFecha(array);
                              } else {
                                let array = arregloSinFecha.filter(
                                  (idArreglo) =>
                                    idArreglo.toString() !== _id.toString()
                                );
                                setArregloSinFecha(array);
                              }
                            }}
                          >
                            <Radio value={"Si"}>Si</Radio>
                            <Radio value={"No"}>No</Radio>
                          </Radio.Group>
                        </Form.Item>

                        {mostrarFechaValidacion(
                          _id,
                          isIngresado,
                          FechaFinValidez,
                          index
                        )}

                        <Button
                          id={`Submit_${index}`}
                          className="ant-btn crear-embarco-button"
                          style={{
                            width: "50%",
                            marginLeft: "25%",
                            marginBottom: "10%",
                          }}
                          htmlType="submit"
                          disabled={!props.Editar}
                        >
                          Guardar
                        </Button>
                      </Form>
                    </Fragment>
                  )
                )
              ) : (
                <Empty
                  style={{ marginBottom: "20px" }}
                  imageStyle={{ height: 100, marginTop: "20px" }}
                  description={
                    <span className={"seleccion-trabajador-disponible"}>
                      <b>
                        No se han requerido especialidades para este trabajador
                      </b>
                    </span>
                  }
                ></Empty>
              )}
            </Form.Provider>
          </Panel> */}

          {/* --------------------------------------------DOCUMENTOS NUEVOS------------------------------------------- */}

          <Panel
            header={
              <h4
                style={{
                  position: "absolute",
                  bottom: -5,
                  color: "#061fcb",
                  left: 35,
                }}
              >
                Documentos
              </h4>
            }
            // key="documentosNuevo"
            key="documentos"
            className=" site-collapse-custom-panel"
            extra={[
              <img
                key="5"
                src={flecha}
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
                }}
                alt="icono"
              />,
              <img
                key="6"
                src={ImgEspecialidades}
                style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
                }}
                alt="icono"
              />,
            ]}
          >
            <Form.Provider
               onFormChange={(name, info) => {
                if(formularioDocumentos === null){

                  let formularios = info.forms;
                  let arrayFormularios = Object.values(formularios);
                  setFormularioDocumentos({
                    "InfoFormularios": info.forms, 
                    "Formularios": arrayFormularios
                  });
                }
              }}
            >
              {
                TiposDeDocumentos.length !== 0 ? 
                (
                  <>
                    {
                      TiposDeDocumentos.map(
                        (
                          {
                            _id,
                            Nombre,
                            Archivo,
                            isIngresado,
                            FechaFinValidez,
                            cantidadDiasHabiles,
                          },
                          index
                        ) =>
                        (
                          <Fragment>
                            <p className="m-fix"></p>
                            {
                              mostrarDetalleDocumentos(
                                isIngresado,
                                FechaFinValidez,
                                cantidadDiasHabiles
                              )
                            }
                            <Form
                              {...formItemLayout}
                              name={`Formulario_${index}`}
                              onFinishFailed={(values) => {
                                onFinishFailed(values);
                              }}
                            >
                              {
                                ()=>(
                                  <>
                                    {/* _id Documento */}
                                    <Form.Item
                                      name="idDocumento"
                                      type="text"
                                      style={{ display: "none" }}
                                      initialValue={_id}
                                    ></Form.Item>

                                    {/* Nombre Documento */}
                                    <Form.Item
                                      name="Nombre"
                                      style={{ display: "none" }}
                                      initialValue={Nombre}
                                    ></Form.Item>

                                    {/* Archivo Documento */}
                                    <Form.Item
                                      name="Archivo"
                                      type="text"
                                      style={{ display: "none" }}
                                      initialValue={Archivo}
                                    ></Form.Item>

                                    {/* Documento Nombre y boton documento base descargable */}
                                    <div
                                      style={{
                                        textAlign: "center",
                                        marginTop: "13px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      {/* Documento Nombre */}
                                      <h3
                                        style={{
                                          fontFamily: "MavenPro-Bold",
                                          textAlign: "center",
                                          display: "inline-block",
                                        }}
                                      >
                                        {Nombre}
                                      </h3>
                                      {/* Documento Base Descargable */}
                                      <Tooltip
                                        title="Descargar documento base"
                                        color={"#64b4e5"}
                                      >
                                        <Button
                                          style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                          }}
                                          onClick={() => {
                                            let url = Archivo;
                                            let win = window.open(url, "_blank");
                                            win.focus();
                                          }}
                                          className="descargar-doc-button"
                                          // disabled={!this.state.Ver}
                                          icon={
                                            <DownloadOutlined
                                              style={{ fontSize: "15px" }}
                                            />
                                          }
                                        />
                                      </Tooltip>
                                    </div>

                                    {/* Documento ¿ TIENE VALIDEZ ? */}
                                    <Form.Item
                                      label="¿El documento tiene periodo de validez?"
                                      name="confirmarValidez"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Debe introducir si el documento tiene periodo de validez",
                                        },
                                      ]}
                                    >
                                      <Radio.Group
                                        style={{ display: "flex" }}
                                        onChange={(value) => {
                                          if (value.target.value === "Si") {
                                            let array = [...arregloSinFecha];
                                            array.push(_id);
                                            setArregloSinFecha(array);
                                          } else {
                                            let array = arregloSinFecha.filter(
                                              (idArreglo) =>
                                                idArreglo.toString() !== _id.toString()
                                            );
                                            setArregloSinFecha(array);
                                          }
                                        }}
                                      >
                                        <Radio value={"Si"}>Si</Radio>
                                        <Radio value={"No"}>No</Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                    {/* Documento file archivo */}
                                    {/* <Form.Item
                                      label="Subir documentoNuevo"
                                      name="archivo"
                                      getValueFromEvent={(e) => {
                                        if(Array.isArray(e)){
                                          return e;
                                        }
                                        return e && e.fileList;
                                      }}
                                    >
                                      <Upload
                                        disabled={!props.Editar}
                                        accept={".pdf"}
                                        listType="picture"
                                        multiple={false}
                                        id={`contenedorArchivo-${index}`}
                                        action={"#"}
                                        className="upload-list-inline"
                                        method={"GET"}
                                        openFileDialogOnClick={true}
                                    
                                      >
                                      <Button
                                        //disabled={props.subirArchivoDeshabilitado}
                                        className="subir-archivos"
                                        id={`archivo-${index}`}
                                        disabled={!props.Editar}
                                      >
                                        <UploadOutlined name="archivoSubir" /> Subir
                                        Archivo
                                      </Button>
                                      </Upload>
                                    </Form.Item> */}

                                    {
                                      mostrarFechaValidacion(
                                        _id,
                                        isIngresado,
                                        FechaFinValidez,
                                        index
                                      )
                                    }
                                  </>
                                )
                              }
                            </Form>
                          </Fragment>
                        )
                      )
                    }
                    {/* guardar documentos */}
                    <Button
                      className="ant-btn crear-embarco-button"
                      style={{
                        width: "50%",
                        marginLeft: "25%",
                        marginBottom: "10%",
                      }}
                      htmlType="submit"
                      disabled={!props.Editar}
                      onClick={()=>{
                        try{

                          if(formularioDocumentos == null){
                            Swal.fire({
                              title: "Uuups...",
                              text: `Debe ingresar a lo menos un formulario para poder guardar`,
                              icon: "error",
                              confirmButtonColor: "#E60AA3",
                              confirmButtonText: "Continuar",
                            });
                            return;
                          }

                          let arrayDocumentos = [];
                          const forms = Object.values(formularioDocumentos.InfoFormularios);
                          
                          forms.forEach(form => {
                            arrayDocumentos.push(form.getFieldsValue());
                          });
                          //console.log(arrayDocumentos)
                          
                          onFinishDocumentos(arrayDocumentos, formularioDocumentos.Formularios);
                        }catch(error){
                          console.log(error);
                        }
                      }}
                    >
                      Guardar
                    </Button>
                  </>
                ):
                (
                  <Empty
                    style={{ marginBottom: "20px" }}
                    imageStyle={{ height: 100, marginTop: "20px" }}
                    description={
                      <span className={"seleccion-trabajador-disponible"}>
                        <b>No se han requerido documento para este trabajador</b>
                      </span>
                    }
                  ></Empty>
                )
              }
            </Form.Provider>
          </Panel>

          {/* ---------------------------------------------HISTÓRICO------------------------------------------------ */}

          <Panel
            header={
              <h4
                style={{
                  position: "absolute",
                  bottom: -5,
                  color: "#061fcb",
                  left: 35,
                }}
              >
                Histórico
              </h4>
            }
            key="historico"
            className="site-collapse-custom-panel"
            extra={[
              <img
                key="1"
                src={flecha}
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
                }}
                alt="icono"
              />,
              <img
                key="2"
                src={ImgHistorico}
                style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
                }}
                alt="icono"
              />,
            ]}
          >
            {/* TABS TITULOS ESPECIALIDADES DOCUMENTOS */}
            <Tabs
              type={"card"}
              defaultActiveKey="1"
              style={{ marginBottom: 32, marginTop: 16 }}
            >
              {/* TÍTULOS */}
              <TabPane tab="Títulos" key="1">
                <Table
                  rowKey="_id"
                  dataSource={titulos}
                  bordered={true}
                  showSorterTooltip={false}
                >
                  <Column
                    sorter={(a, b) => {
                      return a.Nombre.localeCompare(b.Nombre);
                    }}
                    align="left"
                    title="Título"
                    dataIndex="Nombre"
                    key="Nombre"
                  />

                  <Column
                    title="Fecha de subida"
                    key="FechaSubida"
                    align="center"
                    render={(valor) => {
                      if (valor.FechaSubida !== undefined) {
                        // return moment(valor.FechaSubida).format("DD-MM-YYYY");
                        return convertirFechaString(
                          moment.utc(valor.FechaSubida),
                          1
                        ).fechaDDMMYYYY;
                      } else {
                        return "N/A";
                      }
                    }}
                  />

                  <Column
                    title="Validez desde"
                    key="FechaInicioValidez"
                    align="center"
                    render={(valor) => {
                      if (valor.FechaInicioValidez) {
                        // return moment(valor.FechaInicioValidez).format("DD-MM-YYYY");
                        return convertirFechaString(
                          moment.utc(valor.FechaInicioValidez),
                          1
                        ).fechaDDMMYYYY;
                      } else {
                        return "N/A";
                      }
                    }}
                  />

                  <Column
                    title="Validez hasta"
                    key="FechaFinValidez"
                    align="center"
                    render={(valor) => {
                      if (valor.FechaFinValidez) {
                        // return moment(valor.FechaFinValidez).format("DD-MM-YYYY");
                        return convertirFechaString(
                          moment.utc(valor.FechaFinValidez),
                          2
                        ).fechaDDMMYYYY;
                      } else {
                        return "N/A";
                      }
                    }}
                  />

                  <Column
                    title="Descarga"
                    key="Action"
                    align="center"
                    render={(value) => {
                      return (
                        <Space size="middle">
                          <Button
                            onClick={() => {
                              let url = value.Archivo;
                              let win = window.open(url, "_blank");
                              win.focus();
                            }}
                            className="editar-button"
                            disabled={value.Archivo !== ""  ? false:true}
                            icon={
                              <DownloadOutlined style={{ fontSize: "20px" }} />
                            }
                          />
                          {/* boton eliminar titulos */}
                          <Button
                            onClick={() => {
                              eliminarTitulo(value, IdTrabajador);
                            }}
                            className="editar-button"
                            // disabled={!this.state.Ver}
                            icon={
                              <DeleteOutlined style={{ fontSize: "20px" }} />
                            }
                          />
                        </Space>
                      );
                    }}
                  />
                </Table>
              </TabPane>

              {/* ESPECIALIDADES */}
              <TabPane tab="Especialidad" key="2">
                <Table
                  rowKey="_id"
                  dataSource={especialidades}
                  bordered={true}
                  showSorterTooltip={false}
                >
                  <Column
                    sorter={(a, b) => {
                      return a.Nombre.localeCompare(b.Nombre);
                    }}
                    align="left"
                    title="Especialidad"
                    dataIndex="Nombre"
                    key="Nombre"
                  />

                  <Column
                    title="Fecha de subida"
                    key="FechaSubida"
                    align="center"
                    render={(valor) => {
                      if (valor.FechaSubida !== undefined) {
                        // return moment(valor.FechaSubida).format("DD-MM-YYYY");
                        return convertirFechaString(
                          moment.utc(valor.FechaSubida),
                          1
                        ).fechaDDMMYYYY;
                      } else {
                        return "N/A";
                      }
                    }}
                  />

                  <Column
                    title="Validez desde"
                    key="FechaInicioValidez"
                    align="center"
                    render={(valor) => {
                      if (valor.FechaInicioValidez) {
                        // return moment(valor.FechaInicioValidez).format("DD-MM-YYYY");
                        return convertirFechaString(
                          moment.utc(valor.FechaInicioValidez),
                          1
                        ).fechaDDMMYYYY;
                      } else {
                        return "N/A";
                      }
                    }}
                  />

                  <Column
                    title="Validez hasta"
                    key="FechaFinValidez"
                    align="center"
                    render={(valor) => {
                      if (valor.FechaFinValidez) {
                        // return moment(valor.FechaFinValidez).format("DD-MM-YYYY");
                        return convertirFechaString(
                          moment.utc(valor.FechaFinValidez),
                          2
                        ).fechaDDMMYYYY;
                      } else {
                        return "N/A";
                      }
                    }}
                  />

                  <Column
                    title="Descarga"
                    key="Action"
                    align="center"
                    render={(value) => {
                      return (
                        <Space size="middle">
                          <Button
                            onClick={() => {
                              let url = value.Archivo;
                              let win = window.open(url, "_blank");
                              win.focus();
                            }}
                            className="editar-button"
                            disabled={value.Archivo !== ""  ? false:true}
                            // disabled={!this.state.Ver}
                            icon={
                              <DownloadOutlined style={{ fontSize: "20px" }} />
                            }
                          />
                          {/* elminar especialidad */}
                          <Button
                            onClick={() => {
                              eliminarEspecialidad(value, IdTrabajador);
                            }}
                            className="editar-button"
                            // disabled={!this.state.Ver}
                            icon={
                              <DeleteOutlined style={{ fontSize: "20px" }} />
                            }
                          />
                        </Space>
                      );
                    }}
                  />
                </Table>
              </TabPane>

              {/* DOCUMENTOS */}
              {/* <TabPane tab="Documento" key="3">
                <Table
                  rowKey="_id"
                  dataSource={documentos}
                  bordered={true}
                  showSorterTooltip={false}
                  scroll={{ x: true }}
                >
                  <Column
                    sorter={(a, b) => {
                      return a.Nombre.localeCompare(b.Nombre);
                    }}
                    align="left"
                    title="Documento"
                    dataIndex="Nombre"
                    key="Nombre"
                  />

                  <Column
                    title="Fecha de subida"
                    key="FechaSubida"
                    align="center"
                    render={(valor) => {
                      if (valor.FechaSubida !== undefined) {
                        // return moment(valor.FechaSubida).format("DD-MM-YYYY");
                        return convertirFechaString(
                          moment.utc(valor.FechaSubida),
                          1
                        ).fechaDDMMYYYY;
                      } else {
                        return "N/A";
                      }
                    }}
                  />

                  <Column
                    title="Validez desde"
                    key="FechaInicioValidez"
                    align="center"
                    render={(valor) => {
                      if (valor.FechaInicioValidez !== undefined) {
                        // return moment(valor.FechaSubida).format("DD-MM-YYYY");
                        return convertirFechaString(
                          moment.utc(valor.FechaInicioValidez),
                          1
                        ).fechaDDMMYYYY;
                      } else {
                        return "N/A";
                      }
                    }}
                  />

                  <Column
                    title="Validez hasta"
                    key="FechaFinValidez"
                    align="center"
                    render={(valor) => {
                      if (valor.FechaFinValidez !== undefined) {
                        // return moment(valor.FechaSubida).format("DD-MM-YYYY");
                        return convertirFechaString(
                          moment.utc(valor.FechaFinValidez),
                          1
                        ).fechaDDMMYYYY;
                      } else {
                        return "N/A";
                      }
                    }}
                  />

                  <Column
                    title="Descarga"
                    key="Action"
                    align="center"
                    render={(value) => {
                      return (
                        <Space size="middle">
                          <Button
                            onClick={() => {
                              let url = value.Archivo;
                              let win = window.open(url, "_blank");
                              win.focus();
                            }}
                            className="editar-button"
                            disabled={value.Archivo !== ""  ? false:true}
                            // disabled={!this.state.Ver}
                            icon={
                              <DownloadOutlined style={{ fontSize: "20px" }} />
                            }
                          />
                          <Button
                            onClick={() => {
                              eliminarDocumento(value, IdTrabajador);
                            }}
                            className="editar-button"
                            // disabled={!this.state.Ver}
                            icon={
                              <DeleteOutlined style={{ fontSize: "20px" }} />
                            }
                          />
                        </Space>
                      );
                    }}
                  />
                </Table>
              </TabPane> */}

               {/* DOCUMENTOS NUEVO */}
               <TabPane tab="Documento" key="4">
                <Table
                  rowKey="_id"
                  dataSource={documentos}
                  bordered={true}
                  showSorterTooltip={false}
                  scroll={{ x: true }}
                >
                  <Column
                    sorter={(a, b) => {
                      return a.Nombre.localeCompare(b.Nombre);
                    }}
                    align="left"
                    title="Documento"
                    dataIndex="Nombre"
                    key="Nombre"
                  />

                  <Column
                    title="Fecha de subida"
                    key="FechaSubida"
                    align="center"
                    render={(valor) => {
                      if (valor.FechaSubida !== undefined) {
                        // return moment(valor.FechaSubida).format("DD-MM-YYYY");
                        return convertirFechaString(
                          moment.utc(valor.FechaSubida),
                          1
                        ).fechaDDMMYYYY;
                      } else {
                        return "N/A";
                      }
                    }}
                  />

                  <Column
                    title="Validez desde"
                    key="FechaInicioValidez"
                    align="center"
                    render={(valor) => {
                      if (valor.FechaInicioValidez !== undefined) {
                        // return moment(valor.FechaSubida).format("DD-MM-YYYY");
                        return convertirFechaString(
                          moment.utc(valor.FechaInicioValidez),
                          1
                        ).fechaDDMMYYYY;
                      } else {
                        return "N/A";
                      }
                    }}
                  />

                  <Column
                    title="Validez hasta"
                    key="FechaFinValidez"
                    align="center"
                    render={(valor) => {
                      if (valor.FechaFinValidez !== undefined) {
                        // return moment(valor.FechaSubida).format("DD-MM-YYYY");
                        return convertirFechaString(
                          moment.utc(valor.FechaFinValidez),
                          1
                        ).fechaDDMMYYYY;
                      } else {
                        return "N/A";
                      }
                    }}
                  />

                  <Column
                    title="Descarga"
                    key="Action"
                    align="center"
                    render={(value) => {
                      return (
                        <Space size="middle">
                          <Button
                            onClick={() => {
                              let url = value.Archivo;
                              let win = window.open(url, "_blank");
                              win.focus();
                            }}
                            className="editar-button"
                            disabled={value.Archivo !== ""  ? false:true}
                            // disabled={!this.state.Ver}
                            icon={
                              <DownloadOutlined style={{ fontSize: "20px" }} />
                            }
                          />
                          <Button
                            onClick={() => {
                              eliminarDocumento(value, IdTrabajador);
                            }}
                            className="editar-button"
                            // disabled={!this.state.Ver}
                            icon={
                              <DeleteOutlined style={{ fontSize: "20px" }} />
                            }
                          />
                        </Space>
                      );
                    }}
                  />
                </Table>
              </TabPane>
            </Tabs>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default FormularioFichaProfesional;