import React, { Component } from "react";

import { connect } from "react-redux";
import * as mantenedorSucursalesActions from "../../Redux/actions/MantenedorSucursalesActions";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import FormularioSucursales from "../../components/formularioSucursales/FormularioSucursales";
import FormularioSucursalesEditar from "../../components/formularioSucursales/FormularioSucursalesEditar";

import { Layout, Table, Space, Button, Row, Col, Input, Modal } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import Swal from "sweetalert2";
import accesosPagina from '../../funcionesGenericas/accesosUsuario';

const { Header, Content } = Layout;
const { Search } = Input;
const { Column } = Table;

class MantenedorSucursales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tablaCargando: false,
      form: {
        Empresa: "",
        Nombre: "",
        Calle: "",
        Numero_Calle: 0,
        Tipo_De_Vivienda: "",
        Codigo_Postal: 0,
        Region: "",
        Ciudad: "",
        Comuna: "",
        Telefono: 0,
        Email: "",
        Foto: null,

      },
      FotoEditar: null,
      editarCerrado: true,
      nombreEmpresa: '',
      size: "large",
      sucursalFiltros: null,
    };

    this.crearSucursal = this.crearSucursal.bind(this);
    this.EditarSucursal = this.EditarSucursal.bind(this);
    this.TomarValoresEditar = this.TomarValoresEditar.bind(this);
    this.buscarProvincias = this.buscarProvincias.bind(this);
    this.buscarComuna = this.buscarComuna.bind(this);
  }

  search = (value) => {
    let busqueda = value.target.value;
    if (value !== "") {
      const SucursalesRedux = this.props.sucursales;

      const sucursalFiltros = SucursalesRedux.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(busqueda)
        )
      );

      this.setState({ sucursalFiltros });
    } else {
      this.setState({ sucursalFiltros: null });
    }
  };

  mostrarModalCrear = () => {
    this.props.MostrarModalSucursal();
  };


  mostrarModalEditar = async () => {
    this.props.MostrarModalEditarSucursal();
  };

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      const accesos = await accesosPagina.obtenerAccesosPagina('MantenedorSucursales');

      this.setState({
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
      });

      await this.props.ObtenerSucursales();
      await this.props.ObtenerEmpresas();

      await this.props.ObtenerRegiones();
      await this.props.ObtenerComunas();
      await this.props.ObtenerProvincias();

      const empresa = await this.props.ObtenerEmpresa();
      this.setState({ nombreEmpresa: empresa.Razon_Social })
    }
  }

  modalCrearCancelar = () => {
    this.props.OcultarModalSucursal();
  };

  modalEditarCancelar = async () => {
    await this.setState({
      FotoEditar: null,
    })

    console.log(this.state.FotoEditar);
    this.props.OcultarModalSucursalEditar();
  };

  cambiarEditarCerrado = () => {
    this.setState({
      editarCerrado: true,
    })
  }

  crearSucursal = (sucursal) => {

    if (this.state.Foto === undefined || this.state.Foto === null || this.state.Foto === 'undefined') {
      sucursal.Foto = 'https://peoplemanage.blob.core.windows.net/imagenes-sistema/logoGenerico2.svg'
    } else {
      sucursal.Foto = this.state.Foto;
    }
    this.props.CrearSucursal(sucursal);
  };

  EliminarSucursal = (idSucursal) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminará la sucursal",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.EliminarSucursal(idSucursal);
      }
    });
  };

  ObtenerSucursal = async (idSucursal) => {
    await this.setState({
      editarCerrado: false,
    })

    await this.props.ObtenerSucursal(idSucursal);

    this.setState({
      FotoEditar: this.props.sucursal.Foto,
    })
  };

  EditarSucursal = (sucursal) => {

    sucursal._id = this.props.sucursal._id;

    //si no ha cambiado la imagen, envía la misma de vuelta, else, se envía el base64 nuevo
    if (this.state.FotoEditar === this.props.sucursal.Foto) {
      sucursal.Foto = this.props.sucursal.Foto;

    } else {
      sucursal.Foto = this.state.FotoEditar;
    }

    this.props.EditarSucursal(sucursal);
  };

  TomarValoresEditar = (nombreCampo, valor) => {
    this.props.TomarValoresEditar(nombreCampo, valor);
  };

  obtenerImagen = (imgCargada) => {
    this.setState({
      Foto: imgCargada,
    });
  };

  obtenerImagenEditar = (imgCargada) => {
    this.setState({
      FotoEditar: imgCargada,
    });
  };

  buscarProvincias = (idRegion) => {
    let provincias = this.props.ciudades;
    let nuevasProvincias = provincias.filter(provincia => {
      return provincia.Region === idRegion
    });
    this.props.InsertarProvincias(nuevasProvincias);
  }

  buscarComuna = (idProvincia) => {
    let comunas = this.props.comunas;

    let nuevasComunas = comunas.filter(comuna => comuna.Provincia === idProvincia);

    this.props.InsertarComunas(nuevasComunas);
  }

  render() {
    const { size, sucursalFiltros } = this.state;

    return (
      <Layout style={{ height: "100%" }}>
        <Loading cargando={this.props.loading} />
        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo={`Sucursales de: ${this.state.nombreEmpresa}`}
            subtitulo="Crea y administra el registro de sucursales de la empresa."
          />
        </Header>
        <hr className="division" />
        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row style={{ padding: "6px 0px 22px" }} align="bottom">
            <Col className="div-input-search" span={6}>
              <Search
                placeholder="Buscar"
                enterButton
                onChange={(value) => this.search(value)}
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={6}></Col>
            <Col style={{ textAlign: "right" }} span={12}>
              {/* <Button
                className="descargar-excel"
                type="primary"
                onClick={() => {
                  this.descargarExcel();
                }}
                size={size}
                style={{ marginRight: "20px" }}
              >
                Descargar Excel
              </Button> */}
              <Button
                className={!this.state.Editar ? "guardar-form-disabled" : "guardar-form"}
                disabled={!this.state.Editar}
                type="primary"
                onClick={() => {
                  this.mostrarModalCrear();
                }}
                size={size}
              >
                Crear Sucursal
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="_id"
            dataSource={
              sucursalFiltros === null ? this.props.sucursales : sucursalFiltros
            }
            loading={this.props.tablaCargando}
            bordered={true}
            showSorterTooltip={false}
          >

            <Column
              title="Imagen"
              key="Imagen"
              align="center"
              render={(value) => {
                return (
                  <img src={value.Foto} className="imagen-tabla" alt="i" />
                );
              }}
            />

            <Column
              sorter={(a, b) => {
                return a.Nombre.localeCompare(b.Nombre);
              }}
              align="left"
              title="Nombre"
              dataIndex="Nombre"
              key="Nombre"
            />

            <Column title="Email" dataIndex="Email" key="Email" />
            <Column title="Teléfono contacto comercial" dataIndex="Telefono" key="Telefono" />

            {/* <Column
              title="Flota"
              dataIndex="Flota"
              key="Flota"
              align="center"
              render={(value) => (
                <Space size="small">
                  <Button
                    className="ver-button"
                    icon={
                      <img
                        src={vista}
                        style={{ width: 30 }}
                        alt="icono editar"
                      />
                    }
                  />
                </Space>
              )}
            /> */}

            <Column
              title="Acciones"
              key="Action"
              align="center"
              render={(value) => (
                <Space size="middle">
                  <Button
                    onClick={() => {
                      this.ObtenerSucursal(value._id);
                    }}
                    className="editar-button"
                    icon={<EditFilled />}
                    disabled={!this.state.Editar}
                  />
                  <Button
                    className="eliminar-button"
                    onClick={() => {
                      this.EliminarSucursal(value._id);
                    }}
                    icon={<DeleteFilled />}
                    disabled={!this.state.Eliminar}
                  />
                </Space>
              )}
            />
          </Table>
        </Content>
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalSucursal}
          onCancel={this.modalCrearCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioSucursales
            //propiedades cambiables del formulario
            Empresas={this.props.empresas}
            Formulario={this.state.form}
            Foto={this.state.Foto}
            vigencia={this.state.form.vigencia}
            cambiarEstadoVigencia={this.cambiarEstadoVigencia}
            crearSucursal={this.crearSucursal}
            obtenerImagen={this.obtenerImagen}
            nombreEmpresa={this.state.nombreEmpresa}
            ciudadesSeleccionadas={this.props.ciudadesSeleccionadas}
            comunasSeleccionadas={this.props.comunasSeleccionadas}
            regiones={this.props.regiones}
            buscarComuna={this.buscarComuna}
            buscarProvincias={this.buscarProvincias}
          />
        </Modal>

        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalEditar"
          visible={this.props.modalSucursalEditar}
          onCancel={this.modalEditarCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioSucursalesEditar
            //propiedades cambiables del formulario
            Formulario={this.props.sucursal}
            EditarSucursal={this.EditarSucursal}
            TomarValoresEditar={this.TomarValoresEditar}

            nombreEmpresa={this.state.nombreEmpresa}
            ciudadesSeleccionadas={this.props.ciudadesSeleccionadas}
            comunasSeleccionadas={this.props.comunasSeleccionadas}
            regiones={this.props.regiones}

            buscarComuna={this.buscarComuna}
            buscarProvincias={this.buscarProvincias}

            editarCerrado={this.state.editarCerrado}
            cambiarEditarCerrado={this.cambiarEditarCerrado}

            obtenerImagen={this.obtenerImagenEditar}
            Foto={this.state.FotoEditar}

          />
        </Modal>

      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  MantenedorSucursalesReducer: {
    loading,
    empresas,
    error,
    success,
    modalSucursal,
    modalSucursalEditar,
    sucursales,
    sucursal,
    tablaCargando,
    regiones,
    ciudades,
    comunas,
    ciudadesSeleccionadas,
    comunasSeleccionadas,
  },
}) => {
  return {
    loading,
    empresas,
    error,
    success,
    modalSucursal,
    modalSucursalEditar,
    sucursales,
    sucursal,
    tablaCargando,
    regiones,
    ciudades,
    comunas,
    ciudadesSeleccionadas,
    comunasSeleccionadas,
  };
};

export default connect(
  EstadosAPropiedades,
  mantenedorSucursalesActions
)(MantenedorSucursales);
