import React, { Component } from "react";
import { Collapse, Form, Button, Upload } from "antd";
import contrato from "../../img/icons/contrato.svg";
import comprobantes from "../../img/icons/comprobantes.svg";
import reglamentos from "../../img/icons/reglamentos.svg";
import documentos from "../../img/icons/documentos.svg";
import flecha from "../../img/icons/flecha.svg";
import "./FormularioFormatos.css";
import { UploadOutlined } from "@ant-design/icons";

export default class FormularioTrabajador extends Component {
  state = {
    value: 1,
  };

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };
  render() {
    const { Panel } = Collapse;

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 8,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 12,
        },
      },
    };

    const props = {
      action: "#",
      listType: "text",
      className: "upload-list-inline",
      method: "GET",
      openFileDialogOnClick: true,
    };
    const tailLayout = {
      wrapperCol: {
        offset: 15,
        span: 16,
      },
    };

    return (
      <div className="contenedor">
        <Collapse
          key={1}
          bordered={false}
          defaultActiveKey={["0"]}
          className="formulario-trabajador site-collapse-custom-collapse "
        >
          {/* -----------------------------------CONTRATO---------------------------------------------------- */}

          <Panel
            header={
              <h4
                style={{
                  position: "absolute",
                  bottom: -5,
                  color: "#061fcb",
                }}
              >
                Contrato
              </h4>
            }
            key="1"
            className=" site-collapse-custom-panel"
            extra={[
              <h4
                key={1}
                style={{
                  position: "absolute",
                  top: 5,
                  right: 55,
                  color: "#061fcb",
                }}
              >
                0% Completado
              </h4>,
              <img
                key={2}
                src={flecha}
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
                }}
                alt="icono"
              />,
              <img
                key={3}
                src={contrato}
                style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
                }}
                alt="icono"
              />,
            ]}
          >
            <Form className="form" {...formItemLayout}>
              <Form.Item label="Ficha para contrato">
                <Upload id="subir-archivos" {...props}>
                  <Button>
                    <UploadOutlined /> Upload
                  </Button>
                </Upload>
              </Form.Item>
              <hr className="division" />
              <Form.Item label="Contrato de trabajo">
                <Upload id="subir-archivos" {...props}>
                  <Button>
                    <UploadOutlined /> Upload
                  </Button>
                </Upload>
              </Form.Item>
              <hr className="division" />
              <Form.Item label="Anexo de trabajo">
                <Upload id="subir-archivos" {...props}>
                  <Button>
                    <UploadOutlined /> Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Form>
          </Panel>

          {/* -----------------------------------COMPROBANTES Y CERTIFICADOS-------------------------------------------- */}
          <Panel
            header={
              <h4
                style={{
                  position: "absolute",
                  bottom: -5,
                  color: "#061fcb",
                }}
              >
                Comprobantes y Certificados
              </h4>
            }
            key="2"
            className=" site-collapse-custom-panel"
            extra={[
              <h4
                key={1}
                style={{
                  position: "absolute",
                  top: 5,
                  right: 55,
                  color: "#061fcb",
                }}
              >
                Opcional
              </h4>,
              <img
                key={2}
                src={flecha}
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
                }}
                alt="icono"
              />,
              <img
                key={3}
                src={comprobantes}
                style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
                }}
                alt="icono"
              />,
            ]}
          >
            <Form></Form>
          </Panel>

          {/* -----------------------------REGLAMENTOS Y PROCEDIMIENTOS------------------------------------- */}
          <Panel
            header={
              <h4
                style={{
                  position: "absolute",
                  bottom: -5,
                  color: "#061fcb",
                }}
              >
                Reglamientos y Procedimientos
              </h4>
            }
            key="3"
            className=" site-collapse-custom-panel"
            extra={[
              <h4
                key={1}
                style={{
                  position: "absolute",
                  top: 5,
                  right: 55,
                  color: "#061fcb",
                }}
              >
                0% Completado
              </h4>,
              <img
                key={2}
                src={flecha}
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
                }}
                alt="icono"
              />,
              <img
                key={3}
                src={reglamentos}
                style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
                }}
                alt="icono"
              />,
            ]}
          >
            <Form className="form" {...formItemLayout}>
              <Form.Item {...tailLayout}>
                <Button className="guardar-form" htmlType="submit">
                  Guardar
                </Button>
              </Form.Item>
            </Form>
          </Panel>

          {/* -----------------------------DOCUMENTOS----------------------------- */}
          <Panel
            header={
              <h4
                style={{
                  position: "absolute",
                  bottom: -5,
                  color: "#061fcb",
                }}
              >
                Documentos
              </h4>
            }
            key="4"
            className=" site-collapse-custom-panel"
            extra={[
              <h4
                key={1}
                style={{
                  position: "absolute",
                  top: 5,
                  right: 55,
                  color: "#061fcb",
                }}
              >
                0% Completado
              </h4>,
              <img
                key={2}
                src={flecha}
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
                }}
                alt="icono"
              />,
              <img
                key={3}
                src={documentos}
                style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
                }}
                alt="icono"
              />,
            ]}
          >
            <Form className="form" {...formItemLayout}>
              <Form.Item {...tailLayout}>
                <Button className="guardar-form" htmlType="submit">
                  Guardar
                </Button>
              </Form.Item>
            </Form>
          </Panel>
        </Collapse>
        ,
      </div>
    );
  }
}
