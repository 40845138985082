export const LOADING = "MANTENEDOR_USUARIO_LOADING";
export const PARAR_LOADING = "MANTENEDOR_USUARIO_LOADING_PARAR";
export const ERROR = "MANTENEDOR_USUARIO_ERROR";
export const OBTENER_EMPRESAS = "MANTENEDOR_SUCURSAL_EMPRESAS";
export const OBTENER_EMPRESA = "MANTENEDOR_SUCURSAL_EMPRESA";
export const INGRESAR_SUCURSAL = "MANTENEDOR_SUCURSAL_INGRESAR";
export const LIMPIAR_ERROR_SUCCESS = "MANTENEDOR_SUCURSAL_LIMPIAR_ERROR_SUCCESS";
export const MOSTRAR_MODAL_SUCURSAL = "MANTENEDOR_SUCURSAL_MOSTRAR_MODAL";
export const OCULTAR_MODAL_SUCURSAL = "MANTENEDOR_SUCURSAL_OCULTAR_MODAL";
export const OCULTAR_MODAL_SUCURSAL_EDITAR = "MANTENEDOR_SUCURSAL_OCULTAR_MODAL_EDITAR";
export const OBTENER_SUCURSALES_MANTENEDOR = "MANTENEDOR_SUCURSALES_OBTENER_SUCURSALES";
export const OBTENER_SUCURSAL_MANTENEDOR = "MANTENEDOR_SUCURSALES_OBTENER_SUCURSAL";
export const ELIMINAR_SUCURSAL_MANTENEDOR = "MANTENEDOR_SUCURSALES_ELIMINAR_SUCURSAL";
export const EDITAR_SUCURSAL_MANTENEDOR = "MANTENEDOR_SUCURSAL_EDITAR_ROL";
export const TOMAR_VALORES_EDITAR = "MANTENEDOR_SUCURSAL_TOMAR_VALORES_EDITAR";
// export const DESCARGAR_EXCEL = "MANTENEDOR_SUCURSAL_DESCARGAR_EXCEL";

export const OBTENER_REGIONES = "MANTENEDOR_SUCURSALES_ROLES";
export const OBTENER_CIUDADES = "MANTENEDOR_SUCURSALES_ROLES";
export const OBTENER_COMUNAS = "MANTENEDOR_SUCURSALES_ROLES";

export const OBTENER_NACIONALIDADES_MANTENEDOR_SUCURSALES = "OBTENER_NACIONALIDADES_MANTENEDOR_SUCURSALES";
export const OBTENER_REGIONES_MANTENEDOR_SUCURSALES = "OBTENER_REGIONES_MANTENEDOR_SUCURSALES";
export const OBTENER_PROVINCIAS_MANTENEDOR_SUCURSALES = "OBTENER_PROVINCIAS_MANTENEDOR_SUCURSALES";
export const OBTENER_COMUNAS_MANTENEDOR_SUCURSALES = "OBTENER_COMUNAS_MANTENEDOR_SUCURSALES";

export const INSERTAR_PROVINCIAS_MANTENEDOR_SUCURSALES = "INSERTAR_PROVINCIAS_MANTENEDOR_SUCURSALES";
export const INSERTAR_COMUNAS_MANTENEDOR_SUCURSALES = "INSERTAR_COMUNAS_MANTENEDOR_SUCURSALES";