import {
  ERROR,
  INGRESAR_EMPRESA,
  LOADING,
  OBTENER_TIPOEMPRESA,
  OBTENER_REGIONES,
  OBTENER_CIUDADES,
  OBTENER_COMUNAS,
  LIMPIAR_ERROR_SUCCESS,
  MOSTRAR_MODAL_EMPRESA,
  OCULTAR_MODAL_EMPRESA,
  OCULTAR_MODAL_EMPRESA_EDITAR,
  EDITAR_EMPRESA_MANTENEDOR,
  OBTENER_EMPRESAS_MANTENEDOR,
  ELIMINAR_EMPRESA_MANTENEDOR,
  OBTENER_EMPRESA_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  PARAR_LOADING,
  OBTENER_CODIGOS_ACTIVIDAD_ECONOMICA_MANTENEDOR_EMPRESA,
  OBTENER_NACIONALIDADES_MANTENEDOR_EMPRESA,
  OBTENER_REGIONES_MANTENEDOR_EMPRESA,
  OBTENER_PROVINCIAS_MANTENEDOR_EMPRESA,
  OBTENER_COMUNAS_MANTENEDOR_EMPRESA,
  INSERTAR_COMUNAS_MANTENEDOR_EMPRESA,
  INSERTAR_PROVINCIAS_MANTENEDOR_EMPRESA,
} from "../types/MantenedorEmpresaTypes";

const INITIAL_STATE = {
  loading: false,
  tipoEmpresa: [],
  regiones: [],
  ciudades: [],
  comunas: [],
  error: null,
  success: null,
  modalEmpresa: false,
  modalEmpresaEditar: false,
  empresas: [],
  empresa: null,
  tablaCargando: true,
  codigosActividadEconomica: [],
  nacionalidades: [],
  comunasSeleccionadas: [],
  ciudadesSeleccionadas: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {

    case INSERTAR_PROVINCIAS_MANTENEDOR_EMPRESA:
      return {
        ...state,
        ciudadesSeleccionadas: payload,
      }

    case INSERTAR_COMUNAS_MANTENEDOR_EMPRESA:
      return {
        ...state,
        comunasSeleccionadas: payload,
      }

    case OBTENER_COMUNAS_MANTENEDOR_EMPRESA:
      return {
        ...state,
        loading: false,
        comunas: payload,
      }

    case OBTENER_PROVINCIAS_MANTENEDOR_EMPRESA:
      return {
        ...state,
        loading: false,
        ciudades: payload,
      }

    case OBTENER_REGIONES_MANTENEDOR_EMPRESA:
      return {
        ...state,
        loading: false,
        regiones: payload,
      }
    case OBTENER_NACIONALIDADES_MANTENEDOR_EMPRESA:
      return {
        ...state,
        loading: false,
        nacionalidades: payload,
      }
    case OBTENER_CODIGOS_ACTIVIDAD_ECONOMICA_MANTENEDOR_EMPRESA:
      return {
        ...state,
        loading: false,
        codigosActividadEconomica: payload,
      }
    case INGRESAR_EMPRESA:
      return {
        ...state,
        loading: false,
        success: payload,
        modalEmpresa: false,
        tablaCargando: false,
      };
    case OBTENER_TIPOEMPRESA:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        tipoEmpresa: payload,
      };

    case OBTENER_REGIONES:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        regiones: payload,
      };
    case OBTENER_CIUDADES:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        ciudades: payload,
      };

    case OBTENER_COMUNAS:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        comunas: payload,
      };

    case EDITAR_EMPRESA_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        modalEmpresaEditar: false,
      };

    case TOMAR_VALORES_EDITAR:
      const { nombreCampo, valor } = payload;
      return {
        ...state,

        empresa: {
          ...state.empresa,
          [nombreCampo]: valor,
        },
      };
    case LOADING:
      return {
        ...state,
        loading: true,
        tablaCargando: true,
      };
    case PARAR_LOADING:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      };
    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        tablaCargando: false,
      };
    case LIMPIAR_ERROR_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };
    case MOSTRAR_MODAL_EMPRESA:
      return {
        ...state,
        modalEmpresa: true,
      };
    case OCULTAR_MODAL_EMPRESA:
      return {
        ...state,
        modalEmpresa: false,
      };

    case OBTENER_EMPRESAS_MANTENEDOR:
      return {
        ...state,
        empresas: payload,
        loading: false,
        tablaCargando: false,
      };
    case OBTENER_EMPRESA_MANTENEDOR:
      let idRegion = payload.Region;
      let idProvincia = payload.Ciudad;


      let provincias = state.ciudades;
      let comunas = state.comunas;

      let nuevasProvincias = provincias.filter(provincia => provincia.Region === idRegion);
      let nuevasComunas = comunas.filter(comuna => comuna.Provincia === idProvincia);


      return {
        ...state,
        empresa: payload,
        loading: false,
        tablaCargando: false,
        modalEmpresaEditar: true,
        comunasSeleccionadas: nuevasComunas,
        ciudadesSeleccionadas: nuevasProvincias,
      };
    case ELIMINAR_EMPRESA_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      };

    case OCULTAR_MODAL_EMPRESA_EDITAR:
      return {
        ...state,
        modalEmpresaEditar: false,
      };
    default:
      return state;
  }
};
