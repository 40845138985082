import React, { Component } from "react";

import { Card } from "antd";

import SubirFoto from "../../subirFoto/SubirFoto";
import "./Trabajador.css";

export default class Trabajador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    console.log(this.props);
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleChange(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
  }
  render() {
    return (
      <Card className="perfil" style={{ width: 400 }}>
        <SubirFoto
          ObtenerImagen={this.props.ObtenerImagen}
          avatar={this.props.avatar}
        />

        <p className="info-trabajador">
          Nombre <br />
          Nombre
        </p>
        <p className="info-trabajador">
          Cedula de identidad <br />
          xx.xxx.xxx-x
        </p>
        <p className="fecha-embarque">
          Embarcado el: <br />
          18-10-20
        </p>
      </Card>
    );
  }
}
