import React, { useEffect, useState } from "react";
import SubirFoto from "../subirFoto/SubirFoto";
import Trabajador from "../../img/icons/Trabajador.svg";
import personal from "../../img/icons/avatar.svg";
import contacto from "../../img/icons/telefono.svg";
import familiares from "../../img/icons/familiares.svg";
import flecha from "../../img/icons/flecha.svg";
import epp from "../../img/icons/epp.svg";

import especialidades_icon from "../../img/icons/icon_espe.png";
// import titulos_icon from "../../img/icons/icon_titu.png";


import Rut from "../../funcionesGenericas/rut";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import {
  Form,
  Input,
  Button,
  Card,
  Collapse,
  DatePicker,
  Row,
  Col,
  PageHeader,
  Select,
  Radio,
  InputNumber
} from "antd";
import Swal from "sweetalert2";

import './FichaTrabajador.css'
import moment from 'moment';
import { convertirFechaString } from "../../funcionesGenericas/fechas";
// import ValidadorRut from "validar-rut";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const FormularioTrabajadorVisualizar = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();
  const [validRutTrabajador, setValidRutTra] = useState('');
  const [tipoFechaContrato, setTipoFechaContrato] = useState(null);
  const [requirioProgre, setRequirioProgre] = useState(false);
  const [otroSelected, setOtroSelected] = useState(false);
  const [validateOtro, setValidateOtro] = useState('error');

  const ObtenerImagen = (imagenUrl) => {
    props.obtenerImagen(imagenUrl);
    props.TomarValoresEditar("Imagen", imagenUrl);
  };

  const condicionalFechaContrato = () => {

    if (tipoFechaContrato === 'Indefinido') {
      return (
        <DatePicker
          format="DD-MM-YYYY"
          locale={locale}
          className="campos"
          style={{
            width: "100%",
          }}
          disabled={true}
        />
      )
    } else {
      return (
        <RangePicker
          format="DD-MM-YYYY"
          locale={locale}
          className="campos"
          style={{
            width: "100%",
          }}
          disabled={true}
        />
      )
    }

  }

  const condicionalFechaProgresivas = () => {

    if (requirioProgre) {
      return (
        <Form.Item
          name={"Fecha_Progresivas"}
          label="¿Desde cuándo?"
          rules={[
            {
              required: true,
              message: "Obligatorio",
            },
          ]}
        >

          <DatePicker
            format="DD-MM-YYYY"
            locale={locale}
            className="campos"
            style={{
              width: "100%",
            }}
            disabled={true}
          />
        </Form.Item>
      )
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 9,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  let aux = props.Formulario._id;

  const {
    //datos iniciales
    Cargo,
    Rut,
    Nombre,
    Apellido_Paterno,
    Apellido_Materno,
    Fecha_De_Nacimiento,
    Calle,
    Numero_Calle,
    Tipo_De_Vivienda,
    Numero_Departamento,
    Region,
    Comuna,
    Ciudad,
    Correo,
    Nacionalidad,
    Sexo,
    RegimenDeTrabajo,
    Codigo_Base,
    Telefono,

    Titulos_Profesionales,
    Especialidades,
    TiposDeDocumentos,
    EPP,

  } = props.Formulario;

  useEffect((aux) => {
    let {
      //datos iniciales
      Cargo,
      Rut,
      Nombre,
      Apellido_Paterno,
      Apellido_Materno,
      Fecha_De_Nacimiento,
      Calle,
      Numero_Calle,
      Tipo_De_Vivienda,
      Numero_Departamento,
      Region,
      Comuna,
      Ciudad,
      Correo,
      Nacionalidad,
      Sexo,
      RegimenDeTrabajo,
      Codigo_Base,
      Telefono,
      //datos de contacto

      Contacto_Nombre,
      Contacto_Correo,
      Contacto_Fono_Fijo,
      Contacto_Fono_Celular,

      //datos familiares
      Estado_Civil,
      Cantidad_Hijos,

      Titulos_Profesionales,
      Especialidades,
      TiposDeDocumentos,
      EPP,

      //Contrato y Vacaciones
      Tipo_Contrato,
      Relevo,
      Contrato_Fecha_Inicio,
      Contrato_Fecha_Fin,
      Contrato_Fecha_Inicio_Unix,
      Contrato_Fecha_Fin_Unix,
      Requirio_Progresivas,
      Fecha_Progresivas,
      Meses_Empleadores_Anteriores,
      Regimen_Vacaciones,

    } = props.Formulario;

    setTipoFechaContrato(Tipo_Contrato);
    setRequirioProgre(Requirio_Progresivas);

    if (Requirio_Progresivas) {

      form.setFieldsValue({
        Fecha_Progresivas: moment(Fecha_Progresivas),
      });

    }

    let fechaMoment = moment(Fecha_De_Nacimiento);

    if (props.editarCerrado) {
      form.setFieldsValue({
        Rut,
      })
      props.cambiarEditarCerrado();
    }

    if (!Contrato_Fecha_Inicio_Unix) {
      form.setFieldsValue({
        Contrato_Fecha: null,
      })

    } else if (Tipo_Contrato === 'Indefinido') {
      form.setFieldsValue({
        Contrato_Fecha: moment.unix(Contrato_Fecha_Inicio_Unix),
      })

    } else {
      form.setFieldsValue({
        // Contrato_Fecha: [moment.unix(Contrato_Fecha_Inicio_Unix), moment.unix(Contrato_Fecha_Fin_Unix)],
        Contrato_Fecha: [moment.unix(Contrato_Fecha_Inicio_Unix), moment.utc(Contrato_Fecha_Fin)],
      })
    }

    if ((Regimen_Vacaciones !== 15 && Regimen_Vacaciones !== 20) && (Regimen_Vacaciones !== undefined && Regimen_Vacaciones !== null)) {

      form.setFieldsValue({
        Regimen_Vacaciones: -1,
        otro: Regimen_Vacaciones
      })

    } else {

      form.setFieldsValue({
        Regimen_Vacaciones,
      })
    }

    form.setFieldsValue({
      Cargo,
      Nombre,
      Apellido_Paterno,
      Apellido_Materno,
      Fecha_De_Nacimiento: fechaMoment,
      Calle,
      Numero_Calle,
      Tipo_De_Vivienda,
      Numero_Departamento,
      Region,
      Comuna,
      Ciudad,
      Correo,
      Nacionalidad,
      Sexo,
      RegimenDeTrabajo,
      Codigo_Base,
      Telefono,

      //datos de contacto

      Contacto_Nombre,
      Contacto_Correo,
      Contacto_Fono_Fijo,
      Contacto_Fono_Celular,

      //datos familiares
      Estado_Civil,
      Cantidad_Hijos,

      Titulos_Profesionales,
      Especialidades,
      TiposDeDocumentos,
      EPP,

      Tipo_Contrato,
      Relevo,
      Requirio_Progresivas,
      Meses_Empleadores_Anteriores,
      
    });
  }, [aux]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      initialValues={true}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>

        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={Trabajador} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Ficha del Trabajador "
          />
        </Col>

        <Col span={8}>

          <Card className="card-perfil" style={{ width: "100%" }}>

            <Row justify="center">

              <Col span={8} style={{ marginRight: '90px', marginBottom: '20px' }}>
                <SubirFoto
                  avatar={props.Formulario.Imagen}
                  ObtenerImagen={ObtenerImagen}
                />
              </Col>

              <Col span={24}>
                <p style={{ fontFamily: 'MavenPro-Regular' }}>Te recomendamos que la fotografía no exceda los 5 MB de peso</p>

                <Card className={'codigos-card'}>
                  <div className={'codigo-wrapper'} style={{ marginBottom: '15px' }}>
                    <h3 style={{ fontFamily: 'MavenPro-SemiBold', margin: 0 }}>Código de sistema:</h3>
                    <h3 style={{ fontFamily: 'MavenPro-Regular', margin: 0 }}>{props.Formulario._id}</h3>
                  </div>

                  <div className={'codigo-wrapper'}>
                    <h3 style={{ fontFamily: 'MavenPro-SemiBold', margin: 0 }}>Código base:</h3>
                    <h3 style={{ fontFamily: 'MavenPro-Regular', margin: 0 }}>{props.Formulario.Codigo_Base}</h3>
                  </div>

                </Card>

              </Col>

            </Row>

          </Card>

        </Col>

        <Col offset={1} span={15}>
          <Collapse
            bordered={false}
            defaultActiveKey={["0"]}
            className="formulario-trabajador site-collapse-custom-collapse "
          >
            {/* -----------------------------------FORMULARIO DATOS PERSONALES---------------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Datos Personales
                </h4>
              }
              key="1"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={personal}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >


              <Form.Item
                label="Seleccione Cargo Trabajador"
                style={{
                  marginTop: "20px",
                  WebkitTextDecorationStyle: 'dashed'
                }}
              >
                <Select
                  className="campos"
                  // name="Cargo"
                  placeholder="Selecciona cargo"
                  disabled={true}
                  defaultValue={Cargo.Nombre}
                >
                  <Select.Option key={'cargo'} name="Cargo">
                    {Cargo.Nombre}
                  </Select.Option>
                </Select>
              </Form.Item>


              <Form.Item
                label="RUT"
              >
                <Input
                  className="campos"
                  defaultValue={Rut}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Nombres"
              >
                <Input
                  className="campos"
                  defaultValue={Nombre}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Apellido paterno"
              >
                <Input
                  className="campos"
                  defaultValue={Apellido_Paterno}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Apellido materno"
              >
                <Input
                  className="campos"
                  defaultValue={Apellido_Materno}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Fecha de nacimiento"
              >
                <Input
                  className="campos"
                  defaultValue={moment(Fecha_De_Nacimiento).format('DD-MM-YYY')}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Calle"
              >
                <Input
                  className="campos"
                  defaultValue={Calle}
                  disabled={true}
                />
              </Form.Item>

              <Form.Item
                label="Número"
              >
                <Input
                  className="campos"
                  defaultValue={Numero_Calle}
                  disabled={true}
                />
              </Form.Item>




              <Form.Item
                label="Lugar"
              >
                <Input
                  className="campos"
                  defaultValue={Tipo_De_Vivienda}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="N° del Establecimiento"
              >
                <Input
                  className="campos"
                  defaultValue={Numero_Departamento}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Región"
              >
                <Input
                  className="campos"
                  defaultValue={Region.Nombre}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Provincia"
              >
                <Input
                  className="campos"
                  defaultValue={Ciudad.Nombre}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Comuna"
              >
                <Input
                  className="campos"
                  defaultValue={Comuna.Nombre}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Correo electrónico"
              >
                <Input
                  className="campos"
                  defaultValue={Correo}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Teléfono"
              >
                <Input
                  className="campos"
                  defaultValue={Telefono}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Nacionalidad"
              >
                <Input
                  className="campos"
                  defaultValue={Nacionalidad}
                  disabled={true}
                />
              </Form.Item>

              <Form.Item
                label="Sexo"
              >
                <Input
                  className="campos"
                  defaultValue={Sexo}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Régimen de trabajo"
              >
                <Input
                  className="campos"
                  defaultValue={RegimenDeTrabajo.Nombre}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Código base"
              >
                <Input
                  className="campos"
                  defaultValue={Codigo_Base}
                  disabled={true}
                />
              </Form.Item>


            </Panel>

            {/* -----------------------------------FORMULARIO DATOS DE CONTACTO---------------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Datos de Contacto de Emergencia
                </h4>
              }
              key="2"
              className=" site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#061fcb",
                  }}
                >
                  Opcional
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={contacto}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                name={"Contacto_Nombre"}
                label="Nombres:"
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Contacto_Nombre", valor);
                  }}
                  name="Contacto_Nombre"
                  className="campos"
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                name={"Contacto_Correo"}
                label="Correo electrónico"
                rules={[
                  {
                    type: "email",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Contacto_Correo", valor);
                  }}
                  name="Contacto_Correo"
                  className="campos"
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                name={"Contacto_Fono_Fijo"}
                label="Fono Contacto Fijo"
              // hasFeedback
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Contacto_Fono_Fijo", valor);
                  }}
                  name={"Contacto_Fono_Fijo"}
                  className="campos"
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                name="Contacto_Fono_Celular"
                label="Fono Contacto Celular"
              // hasFeedback
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Contacto_Fono_Celular", valor);
                  }}
                  name="Contacto_Fono_Celular"
                  className="campos"
                  disabled={true}
                />
              </Form.Item>
            </Panel>

            {/* -----------------------------FORMULARIO DATOS FAMILIARES Y LEGALES------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Datos Familiares y Legales
                </h4>
              }
              key="3"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={familiares}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Estado civil"
                name="Estado_Civil"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir su estado civil",
                  },
                ]}
              >
                <Radio.Group
                  // style={{ display: "flex" }}
                  onChange={(value) => {
                    props.TomarValoresEditar(
                      "Estado_Civil",
                      value.target.value
                    );
                  }}
                  initialValues={false}
                  disabled={true}
                >
                  <Radio value={"Soltero/a"}>Soltero/a</Radio>
                  <Radio value={"Casado/a"}>Casado/a</Radio>
                  <Radio value={"Viudo/a"}>Viudo/a</Radio>
                  <Radio value={"Divorciado/a"}>Divorciado/a</Radio>
                </Radio.Group>
              </Form.Item>

              <hr className="division" />
              <br />
              <h3 style={{ textAlign: "center", fontFamily: "MavenPro-Bold" }}>
                Datos Hijos
              </h3>
              <Form.Item
                name="Cantidad_Hijos"
                label="Número de hijos:"
                rules={[
                  {
                    required: true,
                    message: "Se debe ingresar cantidad de hijos",
                  },
                ]}
              >
                <Input
                  name="Cantidad_Hijos"
                  className="campos"
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Cantidad_Hijos", valor);
                  }}
                  disabled={true}
                />
              </Form.Item>
            </Panel>

            {/* ---------------------------------------------TÍTULOS------------------------------------------------ */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Titulos
                </h4>
              }
              key="4"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={familiares}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Títulos"
                style={{ marginTop: "20px" }}
                name="Titulos_Profesionales"
              >
                <Select
                  mode="multiple"
                  className="campos"
                  name="Titulos_Profesionales"
                  placeholder="Selecciona títulos"
                  disabled={true}
                >
                  {props.titulos.map(({ Nombre, _id }, index) => (
                    <Select.Option
                      name="TiposDeDocumentos"
                      key={index}
                      value={_id}
                    >
                      {Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* --------------------------------------------ESPECIALIDADES------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Especialidades
                </h4>
              }
              key="5"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={especialidades_icon}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Especialidades"
                style={{ marginTop: "20px" }}
                name="Especialidades"
              >
                <Select
                  mode="multiple"
                  className="campos"
                  // name="Especialidades"
                  placeholder="Seleccione especialidades"
                  disabled={true}
                >
                  {props.especialidades.map(({ Nombre, _id }, index) => (
                    <Select.Option
                      name="Especialidades"
                      key={index}
                      value={_id}
                    >
                      {Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* ------------------------------------------- EPPS ------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  E.P.P.
                </h4>
              }
              key="6"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={epp}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="E.P.P"
                style={{ marginTop: "20px" }}
                name="EPP"
              >
                <Select
                  mode="multiple"
                  className="campos"
                  // name="EPP"
                  placeholder="Seleccione E.P.P."
                  disabled={true}
                >
                  {props.epps.map(({ Nombre, _id }, index) => (
                    <Select.Option
                      name="EPP"
                      key={index}
                      value={_id}
                    >
                      {Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* ------------------------------------------- DOCUMENTOS ------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Documentos
                </h4>
              }
              key="777"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2000"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3000"
                  src={epp}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Documentos"
                style={{ marginTop: "20px" }}
                name="TiposDeDocumentos"
              >
                <Select
                  mode="multiple"
                  className="campos"
                  placeholder="Seleccione Documentos requeridos"
                  disabled={true}
                >
                  {props.documentos.map(({ Nombre, _id }, index) => (
                    <Select.Option
                      name="TiposDeDocumentos"
                      key={index}
                      value={_id}
                    >
                      {Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* -----------------------------------CARACTERÍSTICAS DE CONTRATO---------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Características del contrato
                </h4>
              }
              key="8"
              className="site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={contacto}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >

              <Form.Item
                name={"Tipo_Contrato"}
                label="Tipo de contrato"
                style={{ marginTop: "20px" }}
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  onChange={(e) => {
                    setTipoFechaContrato(e.target.value);

                    form.setFieldsValue({
                      Contrato_Fecha: null,
                    })
                  }}
                  disabled={true}
                >
                  <Radio value={'Plazo fijo'}>Plazo Fijo</Radio>
                  <Radio value={'Indefinido'}>Indefinido</Radio>
                </Radio.Group>
              </Form.Item>

              {/* //nuevo campo relevo/ */}
              <Form.Item
                name={"Relevo"}
                label="Relevo"
                style={{ marginTop: "20px" }}
                rules={[
                  {
                    required: true,
                    message: "Debe seleccionar relevo",
                  },
                ]}
                
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  disabled={true}
                >
                  <Radio value={true}>Si</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              {/* //nuevo campo relevo// */}

              <Form.Item
                name={"Contrato_Fecha"}
                label="Fecha de contratación"
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >

                {condicionalFechaContrato()}

              </Form.Item>

            </Panel>

            {/* -----------------------------------VACACIONES---------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Vacaciones
                </h4>
              }
              key="9"
              className="site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={contacto}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >

              <Form.Item
                name={"Requirio_Progresivas"}
                label={<p style={{ margin: '0px' }}>¿Requirió vacaciones<br />progresivas?</p>}
                style={{ marginTop: "20px" }}
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >
                <Radio.Group
                  className={'input-small'}
                  style={{ display: "flex" }}
                  onChange={(e) => {
                    setRequirioProgre(e.target.value);

                    form.setFieldsValue({
                      Fecha_Progresivas: null,
                    })
                  }}
                  disabled={true}
                >
                  <Radio value={true}>Sí</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              {condicionalFechaProgresivas()}

              <Form.Item
                name={"Meses_Empleadores_Anteriores"}
                label={<p style={{ margin: '0px' }}>Meses con empleadores<br />anteriores</p>}
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  min={0}
                  className={'campos'}
                  disabled={true}
                />
              </Form.Item>

              <Form.Item
                name={"Regimen_Vacaciones"}
                label="Régimen de vacaciones"
                // rules={[
                //   {
                //     required: true,
                //     message: "Obligatorio",
                //   },
                // ]}
                style={{ marginBottom: '5px' }}
                validateStatus={validateOtro}
                // hasFeedback={true}
                rules={[
                  {
                    required: true,
                    message: "Debe seleccionar el régimen de vacaciones.",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  onChange={(e) => {

                    if (e.target.value === -1) {
                      setOtroSelected(true);
                      setValidateOtro('error');

                    } else {
                      setOtroSelected(false);
                      setValidateOtro('success');

                      form.setFieldsValue({
                        otro: undefined,
                      })
                    }

                    // if (form.getFieldsValue(['Regimen_Vacaciones'] !== -1) && form.getFieldsValue(['otro']) !== ) {

                    // }
                  }}
                  disabled={true}
                >
                  <Radio value={15}>15 días</Radio>
                  <Radio value={20}>20 días</Radio>
                  <Radio value={-1}>Otro</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name={"otro"}
                label=' '
                className='item-otro'
              >
                <InputNumber
                  placeholder={'otra cantidad'}
                  className='input-otro'
                  type="number" min={0}
                  disabled={!otroSelected}
                  onChange={async (e) => {
                    if (e) {
                      await setValidateOtro('success');
                    } else {
                      await setValidateOtro('error');
                    }
                  }}
                />
              </Form.Item>

            </Panel>

          </Collapse>
        </Col>


      </Row>

      <br />
      <br />
    </Form>
  );
};

export default FormularioTrabajadorVisualizar;
