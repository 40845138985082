import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import ImgEPP from "../../img/icons/epp.svg";
import ImgHistorico from "../../img/icons/Historico.svg";
import flecha from "../../img/icons/flecha.svg";

import {
  Form,
  Button,
  Collapse,
  Table,
  //Space,
  Tabs,
  Alert,
  Select,
  Row,
  Col,
  Empty,
  PageHeader,
  DatePicker,
  Popover,
  Input,
  Tag,
  Space,
  Upload,
} from "antd";

import locale from "antd/es/date-picker/locale/es_ES";
// import { UploadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
// import Labels from "@antv/g2/lib/component/labels";
import API from "../../Redux/Api/api";
import {
  InfoCircleTwoTone,
  PrinterOutlined,
  DownloadOutlined,
  UploadOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import "./FormularioEntregaEPP.css";
import { convertirFechaString } from "../../funcionesGenericas/fechas";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Column } = Table;
const { TabPane } = Tabs;

const FormularioEntregaEPP = (props) => {
  //constante para manejar el formulario
  const [fechasImprimible, setFechasImprimible] = useState([]);
  const [fechasImprimibleVencidas, setFechasImprimibleVencidas] = useState([]);
  const [formularioEpps, setFormularioEpps] = useState(null);

  const {
    Nombre,
    Apellido_Paterno,
    Apellido_Materno,
    EPP,
    _id: IdTrabajador,
    Empresa: { _id: IdEmpresa },
  } = props.trabajador;

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values, idEpp, form) => {
    let usuario = localStorage.getItem("usuario-people-manage");
    let str = moment().format('yyyy/MM/DD');
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      props.activarCargando();

      const eppEnviar = {
        Nombre: values.Nombre,
        Talla: values.Talla,
        Color: values.Color,

        // FechaEntrega: values.Validez[0].format("YYYY-MM-DDTHH:mm:ss[Z]"),
        // FechaVencimiento: values.Validez[1].format("YYYY-MM-DDTHH:mm:ss[Z]"),

        FechaEntrega: convertirFechaString(values.Validez[0], 1).fechaString,
        FechaVencimiento: convertirFechaString(values.Validez[1], 2).fechaString,

        FechaSubida: convertirFechaString(moment(str), 1).fechaString,

        IdUsuarioUpload: usuario._id,
        CantidadEntregada: values.Cantidad,
        Comentario: values.Comentario === undefined ? "" : values.Comentario,
      };

      // console.log("EPP ENVIAR");
      // console.log(eppEnviar);


      guardarBitacora(eppEnviar, idEpp, form);
    }
  };

  //FUNCIONES PARA MANJERA EL FORMULARIO NUEVO DE EPPS
  const onFinishEpps = (eppsElement, formularios) => {
    let usuario = localStorage.getItem("usuario-people-manage");
    let str = moment().format('yyyy/MM/DD');
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {    
      
      const eppsValidos = [];
      usuario = JSON.parse(usuario);
      let formData = new FormData();
      if(eppsElement.length === 0){
        Swal.fire({
          title: "Uuups...",
          text: "Debe haber al menos un formulario rellenado.",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
        return
      }
      let nombreEppsGuardar ="";
      eppsElement.forEach((epps, index) => {
        console.log(epps,"epps")
        nombreEppsGuardar+=(index===0 ? epps.Nombre :`, ${epps.Nombre}`)
        eppsValidos.push({
          Nombre: epps.Nombre,
          Talla: epps.Talla,
          Color: epps.Color,

          FechaEntrega: convertirFechaString(epps.Validez[0], 1).fechaString,
          FechaVencimiento: convertirFechaString(epps.Validez[1], 2).fechaString,

          FechaSubida: convertirFechaString(moment(str), 1).fechaString,

          IdUsuarioUpload: usuario._id,
          CantidadEntregada: epps.Cantidad,
          Comentario: epps.Comentario === undefined ? "" : epps.Comentario,
          IdEmpresa: IdEmpresa,
          IdTrabajador: IdTrabajador,
          IdEpp: epps.idEpp
        })
      });
      Swal.fire({
        title: "¿Estás seguro?",
        text: `Se guardara el epp: ${nombreEppsGuardar}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1FC600",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          props.activarCargando();
          formData.append("body",JSON.stringify(eppsValidos));
          guardarBitacoraEpp(formularios,formData);
        }
      });
      
     
    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const convertBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const obtenerBase64 = (info, EPPS) => {
    props.activarCargando();

    if (info.file.status === "uploading") {
      return;
    }

    if (info.file.status === "done") {
      convertBase64(info.file.originFileObj, (imageUrl) => {
        let arrayIDsBitacoras = [];
        EPPS.eppsFecha.forEach((fEpps) => {
          arrayIDsBitacoras.push(fEpps.idBitacoraEPP);
        });

        let objEnviar = {
          Imagen: imageUrl,
          idTrabajador: IdTrabajador,
          arrayIDsBitacoras,
        };

        // props.desactivarCargando();
        guardarPDFBitacoras(objEnviar);
      });
    }
  };

  useEffect(() => {
    const eppsHistorico = props.eppsBitacora !== null ? props.eppsBitacora.EPPS : [];
    let arrFechas = [];

    // creando array con fechas únicas
    eppsHistorico.forEach((eppBit) => {
      // let formattedFecha = moment.utc(eppBit.FechaEntrega).format("DD-MM-YYYY");
      let formattedFecha = convertirFechaString(moment.utc(eppBit.FechaEntrega), 1).fechaDDMMYYYY;

      let repetidosEncontrados = arrFechas.filter((fecha) => fecha.fecha === formattedFecha);

      if (repetidosEncontrados.length === 0) { arrFechas.push({ fecha: formattedFecha }); }
    });

    //agrego 2 arreglos para diferenciar los grupos que estan vencidos y los que no
    let arregloDisponible = [];
    let arregloVencidos = [];

    // agregando epps a respectivas fechas
    arrFechas.forEach((fechaObj) => {
      let eppsEnFecha = [];

      eppsHistorico.forEach((eppBit) => {
        // let formattedFecha = moment.utc(eppBit.FechaEntrega).format("DD-MM-YYYY");
        let formattedFecha = convertirFechaString(moment.utc(eppBit.FechaEntrega), 1).fechaDDMMYYYY;

        if (fechaObj.fecha === formattedFecha) {
          //verifico si el epp esta vencido o no esta vencido
          let diasDiferencia = moment(eppBit.FechaVencimiento).diff(moment(), "days");
          let vencido = diasDiferencia <= 0 ? true : false;

          //obtengo el id de la bitacora epp
          let idEPPBitacora = eppBit._id;
          //obtengo el archivo asignado a la epp
          let urlArchivo = eppBit.urlArchivo;

          eppsEnFecha.push({
            nombre: eppBit.Nombre,
            talla: eppBit.Talla,
            vencido: vencido,
            idBitacoraEPP: idEPPBitacora,
            urlArchivo: urlArchivo,
          });
        }
      });

      //verifico si todos los epps del grupo estan vencidos. si es asi el grupo tambien estara vencido
      let cantidadEpps = eppsEnFecha.length;

      let grupoVencido = cantidadEpps === eppsEnFecha.filter((epp) => epp.vencido === true).length ? true : false;
      let tieneLink = cantidadEpps === eppsEnFecha.filter((epp) => epp.urlArchivo !== undefined).length ? true : false;

      fechaObj.eppsFecha = eppsEnFecha;
      fechaObj.grupoVencido = grupoVencido;
      fechaObj.tieneLink = tieneLink;

      if (grupoVencido) {
        arregloVencidos.push(fechaObj);
      } else {
        arregloDisponible.push(fechaObj);
      }
    });

    setFechasImprimible(arregloDisponible);
    setFechasImprimibleVencidas(arregloVencidos);
  }, [props.eppsBitacora]);

  // const onReset = () => {
  //   form.resetFields();
  // };
  const guardarPDFBitacoras = async (objEnviar) => {
    const url = `${API}/BitacoraEPP/SubirPDF`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objEnviar),
    });
    const status = response.status;
    const data = await response.json();
    switch (status) {
      case 200:
      case 201:
        await props.actualizarInformacionUsuario();

        props.desactivarCargando();
        Swal.fire({
          title: "Bien",
          text: "Se guardó la entrega correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
      case 204:
        Swal.fire({
          title: "Ups...",
          text: "Ha ocurrido un error",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
      default:
        Swal.fire({
          title: "Ups...",
          text: "Ha ocurrido un error",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
    }
  };

  //CONEXION API BACKEND NUEVA
  const guardarBitacoraEpp = async(forms, formData) =>{  
    
    const url = `${API}/BitacoraEPP/NuevaEPP`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      body: formData,
    });
    const status = response.status;
  
    switch (status) {
      case 200:
      case 201:
      
        forms.forEach(form => {
          form.resetFields();
        });
        
        try {
          const botones = document.querySelectorAll(".ant-upload-list-item-card-actions-btn");
          botones.forEach(btn => {
            btn.click();
          });
        } catch (err) {
          console.log(err);
        }

        await props.actualizarInformacionUsuario();
        props.desactivarCargando();
        
        Swal.fire({
          title: "Bien",
          text: "Se guardó la entrega correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;

      case 204:
        props.desactivarCargando();
        Swal.fire({
          title: "Ups...",
          text: "Ha ocurrido un error",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
      default:
        props.desactivarCargando();
        Swal.fire({
          title: "Ups...",
          text: "Ha ocurrido un error",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
    }
  }
  //CONEXION API BACKEND ANTIGUA
  const guardarBitacora = async (eppEnviar, idEpp, form) => {
    const BitacoraEPP = {
      IdTrabajador,
      IdEmpresa,
      EPPS: [eppEnviar],
      idEpp,
    };

    const url = `${API}/BitacoraEPP`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(BitacoraEPP),
    });
    const status = response.status;
    const data = await response.json();
    switch (status) {
      case 200:
      case 201:
        form.resetFields();

        await props.actualizarInformacionUsuario();

        props.desactivarCargando();
        Swal.fire({
          title: "Bien",
          text: "Se guardó la entrega correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
      case 204:
        Swal.fire({
          title: "Ups...",
          text: "Ha ocurrido un error",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
      default:
        Swal.fire({
          title: "Ups...",
          text: "Ha ocurrido un error",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
    }
  };

  //BTN HISTORICO ACCION ELIMINAR
  const eliminarEpp = async (values, idTrabajador) =>{
    try {
      props.activarCargando();
      const url = `${API}/BitacoraEPP`;
      const response = await fetch(url, {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ID_TRABAJADOR: idTrabajador,
          ID_EPP_INGRESADA: values._id,
        }),
      });
      
      const status = response.status;
      const data = await response.json();

      switch (status) {
        case 200:
        case 201:
          //console.log("se ejecuto bien epp eliminar");
          await props.actualizarInformacionUsuario();
          props.desactivarCargando();
          return;

        default:
          props.desactivarCargando();
          Swal.fire({
            title: "Ups...",
            text: "Ha ocurrido un error",
            icon: "error",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });
          return;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  const mostrarDetalleEpps = (
    encontrado,
    FechaVencimiento,
    cantidadDiasHabiles
  ) => {
    if (encontrado === true) {
      if (cantidadDiasHabiles > 0) {
        return (
          <>
            <Alert message="Epp ingresada" type="success" showIcon />
            <p>Fecha vencimiento: {FechaVencimiento}</p>
            <p>Dias para vencimiento: {cantidadDiasHabiles}</p>
          </>
        );
      } else {
        cantidadDiasHabiles = -cantidadDiasHabiles + 1;
        return (
          <>
            <Alert message="Epp Vencida" type="error" showIcon />
            {/* <p>Fecha vencimiento: EPP Vencida</p> */}
            <p>Fecha vencimiento: {FechaVencimiento}</p>
            <p>Dias que lleva vencido: {cantidadDiasHabiles}</p>
          </>
        );
      }
    } else {
      return (
        <>
          <Alert message="Epp no ingresada" type="error" showIcon />
        </>
      );
    }
  };

  const redirectImprimible = (eppsData) => {
    let randId = crearId(8);

    localStorage.setItem(`${randId}`, JSON.stringify(eppsData));

    var win = window.open(
      `/ImpresionEpp?eppsId=${randId}&trabId=${IdTrabajador}`,
      "_blank"
    );
  };

  const crearId = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  return (
    <Row style={{ padding: "5px 5px 0px 20px", marginBottom: "6%" }}>
      <Col span={24} style={{ display: "flex" }} className="title">
        <img src={ImgEPP} style={{ width: "35px" }} alt="icono modal" />
        <PageHeader
          style={{ padding: "0px 10px 0px 10px" }}
          className="site-page-header"
          title={`Elementos de protección asignados a ${Nombre} ${Apellido_Paterno} ${Apellido_Materno}`}
        />
      </Col>

      <Col offset={1} span={24}>
        <Collapse
          bordered={false}
          defaultActiveKey={["0"]}
          className="formulario-trabajador site-collapse-custom-collapse cont-tabla-celeste"
        >
          {/* ------------------------------------------ ENTREGAR EPP --------------------------------------------- */}
        
          {/* <Panel
            header={
              <h4
                style={{
                  position: "absolute",
                  bottom: -5,
                  color: "#061fcb",
                  left: 35,
                }}
              >
                Entregar nuevo elemento de protección
              </h4>
            }
            key="epps"
            className=" site-collapse-custom-panel"
            extra={[
              <img
                key="3"
                src={flecha}
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
                }}
                alt="icono"
              />,
              <img
                key="4"
                src={ImgEPP}
                style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
                }}
                alt="icono"
              />,
            ]}
          >
            {
              <Form.Provider
                onFormFinish={(name, info) => {
                  let formularios = info.forms;

                  let form = formularios[name];
                  onFinish(info.values, info.values.idEpp, form);
                }}
              >
                {EPP.length !== 0 ? (
                  EPP.map(
                    (
                      {
                        Nombre,
                        Tipo_Talla,
                        Colores,
                        Imagen,
                        _id,
                        encontrado,
                        FechaVencimiento,
                        cantidadDiasHabiles,
                      },
                      index
                    ) => {

                      
                      let cargoTrabajador = props.trabajador.Cargo;
                      let eppActual;

                      cargoTrabajador.Epps.forEach((epp) => {
                        if (epp.IdEpp === _id) {
                          eppActual = epp;
                        }
                      });

                      let fechasSugeridas = [];
                      fechasSugeridas.push(moment());

                      let fechaFinSugerida;

                      if (eppActual) {
                        let diasSugeridos = eppActual.DuracionSugeridaDias;
                        fechaFinSugerida = moment().add(diasSugeridos - 1, "days");
                      } else {
                        fechaFinSugerida = null;
                      }

                      fechasSugeridas.push(fechaFinSugerida);

                      return (
                        <Fragment key={index}>
                          <Row style={{ marginTop: "7%" }}>
                            <Col
                              span={9}
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <img
                                src={Imagen}
                                width="40%"
                                style={{
                                  zIndex: "100",
                                  marginTop: "10%",
                                }}
                                alt={Nombre}
                              />
                              {mostrarDetalleEpps(
                                encontrado,
                                FechaVencimiento,
                                cantidadDiasHabiles
                              )}
                            </Col>

                            <Col span={15}>
                              <h3
                                style={{
                                  fontFamily: "MavenPro-Bold",
                                  textAlign: "left",
                                  marginLeft: "25%",
                                  marginBottom: "2%",
                                }}
                              >
                                {Nombre}
                              </h3>

                              <Form
                                {...formItemLayout}
                                name={`Formulario_${index}`}
                                onFinishFailed={(values) => {
                                  onFinishFailed(values);
                                }}
                              >
                                <Form.Item
                                  name="Nombre"
                                  style={{ display: "none" }}
                                  initialValue={Nombre}
                                ></Form.Item>

                                
                                <Form.Item
                                  style={{ marginBottom: 18 }}
                                  name="Talla"
                                  label="Talla:"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Debes introducir al menos una talla",
                                    },
                                  ]}
                                >
                                  {Tipo_Talla === "Calzado" ? (
                                    <Select placeholder="Introduzca la talla">
                                      <Select.Option key="35">35</Select.Option>
                                      <Select.Option key="36">36</Select.Option>
                                      <Select.Option key="37">37</Select.Option>
                                      <Select.Option key="38">38</Select.Option>
                                      <Select.Option key="39">39</Select.Option>
                                      <Select.Option key="40">40</Select.Option>
                                      <Select.Option key="41">41</Select.Option>
                                      <Select.Option key="42">42</Select.Option>
                                      <Select.Option key="43">43</Select.Option>
                                      <Select.Option key="44">44</Select.Option>
                                      <Select.Option key="45">45</Select.Option>
                                    </Select>
                                  ) : Tipo_Talla === "Vestuario" ? (
                                    <Select placeholder="Introduzca la talla">
                                      <Select.Option key="XXS">
                                        XXS
                                      </Select.Option>
                                      <Select.Option key="XS">XS</Select.Option>
                                      <Select.Option key="S">S</Select.Option>
                                      <Select.Option key="M">M</Select.Option>
                                      <Select.Option key="L">L</Select.Option>
                                      <Select.Option key="XL">XL</Select.Option>
                                      <Select.Option key="XXL">
                                        XXL
                                      </Select.Option>
                                    </Select>
                                  ) : Tipo_Talla === "Estándar / Sin talla" ? (
                                    <Select placeholder="Introduzca la talla">
                                      <Select.Option key="Estandar">
                                        Estándar / Sin talla
                                      </Select.Option>
                                    </Select>
                                  ) : (
                                    <Select placeholder="" />
                                  )}
                                </Form.Item>

                                
                                <Form.Item
                                  style={{ marginBottom: 18 }}
                                  name="Color"
                                  label="Color:"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Debes introducir al menos un color",
                                    },
                                  ]}
                                >
                                  <Select placeholder="Introduzca un color">
                                    {Colores.map((color, index) => (
                                      <Select.Option key={color} value={color}>
                                        {color}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>

                                
                                <Form.Item
                                  name="Cantidad"
                                  label="Cantidad:"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Debes introducir la cantidad entregada",
                                    },
                                  ]}
                                >
                                  <Input
                                    type={"number"}
                                    min={0}
                                    placeholder={
                                      "Debe ingresar cantidad entregada"
                                    }
                                  ></Input>
                                </Form.Item>

                               
                                <Form.Item
                                  name="Comentario"
                                  label="Comentario:"
                                  rules={[
                                    {
                                      required: false,
                                      message:
                                        "Debes introducir la cantidad entregada",
                                    },
                                  ]}
                                >
                                  <Input
                                    type={"text"}
                                    maxLength={100}
                                    placeholder={
                                      "Debe ingresar comentario de entrega"
                                    }
                                  ></Input>
                                </Form.Item>

                                
                                <Form.Item
                                  name="Validez"
                                  label="Validez:"
                                  initialValue={fechasSugeridas}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Debes introducir el periodo de validez del elemento de protección",
                                    },
                                  ]}
                                  extra={
                                    fechasSugeridas[1] ? (
                                      <Popover
                                        title={"Información:"}
                                        placement={"right"}
                                        content={
                                          <div>
                                            <p className={"info-text"}>
                                              Se asignó una cantidad de días
                                              sugerida del E.P.P según el cargo
                                              del
                                              <br />
                                              trabajador, pero se pueden cambiar
                                              a gusto del usuario.
                                            </p>
                                          </div>
                                        }
                                      >
                                        <InfoCircleTwoTone
                                          className={"info-icon-validez"}
                                          twoToneColor={"#48b9fd"}
                                        />
                                      </Popover>
                                    ) : null
                                  }
                                >
                                  <RangePicker
                                    locale={locale}
                                    format={"DD-MM-YYYY"}
                                    style={{ marginBottom: "6%" }}
                                  // disabled={[true, false]}
                                  />
                                </Form.Item>

                               
                                <Button
                                  id={`Submit_${index}`}
                                  className="ant-btn crear-embarco-button"
                                  style={{
                                    width: "50%",
                                    marginLeft: "25%",
                                    marginBottom: "10%",
                                  }}
                                  htmlType="submit"
                                  disabled={!props.Editar}
                                >
                                  Guardar
                                </Button>

                               
                                <Form.Item
                                  name="idEpp"
                                  value={_id}
                                  initialValue={_id}
                                >
                                  <Input
                                    type={"hidden"}
                                    maxLength={100}
                                    placeholder={
                                      "Debe ingresar comentario de entrega"
                                    }
                                    value={_id}
                                  ></Input>
                                </Form.Item>
                              </Form>
                            </Col>
                          </Row>
                        </Fragment>
                      );
                    }
                  )
                ) : (
                  <Empty
                    style={{ marginBottom: "20px" }}
                    imageStyle={{ height: 100, marginTop: "20px" }}
                    description={
                      <span className={"seleccion-trabajador-disponible"}>
                        <b>No hay entregas pendientes</b>
                      </span>
                    }
                  ></Empty>
                )}
              </Form.Provider>
            }
          </Panel> */}
          {/* ------------------------------------------ ENTREGAR EPP NUEVO V2--------------------------------------------- */}

          <Panel
            header={
              <h4
                style={{
                  position: "absolute",
                  bottom: -5,
                  color: "#061fcb",
                  left: 35,
                }}
              >
                Entregar nuevo elemento de protección
              </h4>
            }
            //key="eppsNueva"
            key="epps"
            className=" site-collapse-custom-panel"
            extra={[
              <img
                key="3"
                src={flecha}
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
                }}
                alt="icono"
              />,
              <img
                key="4"
                src={ImgEPP}
                style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
                }}
                alt="icono"
              />,
            ]}
          >
           <Form.Provider
              onFormChange={(name, info) => {
                if(formularioEpps === null){

                  let formularios = info.forms;
                  let arrayFormularios = Object.values(formularios);

                  setFormularioEpps({
                    "InfoFormularios": info.forms, 
                    "Formularios": arrayFormularios
                  });
                }
              }}

            >
              {EPP.length !== 0 ? (

                <>
                
                  {

                    EPP.map(
                      (
                        {
                          Nombre,
                          Tipo_Talla,
                          Colores,
                          Imagen,
                          _id,
                          encontrado,
                          FechaVencimiento,
                          cantidadDiasHabiles,
                        },
                        index
                      ) => {
                        /* LÓGICA PARA SETEAR VALIDEZ SUGERIDA POR EL CARGO */
                        let cargoTrabajador = props.trabajador.Cargo;
                        let eppActual;

                        cargoTrabajador.Epps.forEach((epp) => {
                          if (epp.IdEpp === _id) {
                            eppActual = epp;
                          }
                        });

                        let fechasSugeridas = [];
                        fechasSugeridas.push(moment());

                        let fechaFinSugerida;

                        if (eppActual) {
                          let diasSugeridos = eppActual.DuracionSugeridaDias;
                          fechaFinSugerida = moment().add(diasSugeridos - 1, "days");
                        } else {
                          fechaFinSugerida = null;
                        }

                        fechasSugeridas.push(fechaFinSugerida);
                        return (
                          <Fragment key={index}>
                            <Row style={{ marginTop: "7%" }}>
                              {/* Epp Imagen */}
                              <Col
                                span={9}
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  src={Imagen}
                                  width="40%"
                                  style={{
                                    zIndex: "100",
                                    marginTop: "10%",
                                  }}
                                  alt={Nombre}
                                />
                                {mostrarDetalleEpps(
                                  encontrado,
                                  FechaVencimiento,
                                  cantidadDiasHabiles
                                )}
                              </Col>
                              {/* Epp Formulario */}
                              <Col span={15}>
                                <h3
                                  style={{
                                    fontFamily: "MavenPro-Bold",
                                    textAlign: "left",
                                    marginLeft: "25%",
                                    marginBottom: "2%",
                                  }}
                                >
                                  {Nombre}
                                </h3>
                                {/* Formulario */}
                                <Form
                                  {...formItemLayout}
                                  name={`Formulario_${index}`}
                                  onFinishFailed={(values) => {
                                    onFinishFailed(values);
                                  }}
                                >
                                  {/* Nombre */}
                                  <Form.Item
                                    name="Nombre"
                                    style={{ display: "none" }}
                                    initialValue={Nombre}
                                  ></Form.Item>

                                  {/* TALLA */}
                                  <Form.Item
                                    style={{ marginBottom: 18 }}
                                    name="Talla"
                                    label="Talla:"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Debes introducir al menos una talla",
                                      },
                                    ]}
                                  >
                                    {Tipo_Talla === "Calzado" ? (
                                      <Select placeholder="Introduzca la talla">
                                        <Select.Option key="35">35</Select.Option>
                                        <Select.Option key="36">36</Select.Option>
                                        <Select.Option key="37">37</Select.Option>
                                        <Select.Option key="38">38</Select.Option>
                                        <Select.Option key="39">39</Select.Option>
                                        <Select.Option key="40">40</Select.Option>
                                        <Select.Option key="41">41</Select.Option>
                                        <Select.Option key="42">42</Select.Option>
                                        <Select.Option key="43">43</Select.Option>
                                        <Select.Option key="44">44</Select.Option>
                                        <Select.Option key="45">45</Select.Option>
                                      </Select>
                                    ) : Tipo_Talla === "Vestuario" ? (
                                      <Select placeholder="Introduzca la talla">
                                        <Select.Option key="XXS">
                                          XXS
                                        </Select.Option>
                                        <Select.Option key="XS">XS</Select.Option>
                                        <Select.Option key="S">S</Select.Option>
                                        <Select.Option key="M">M</Select.Option>
                                        <Select.Option key="L">L</Select.Option>
                                        <Select.Option key="XL">XL</Select.Option>
                                        <Select.Option key="XXL">
                                          XXL
                                        </Select.Option>
                                      </Select>
                                    ) : Tipo_Talla === "Estándar / Sin talla" ? (
                                      <Select placeholder="Introduzca la talla">
                                        <Select.Option key="Estandar">
                                          Estándar / Sin talla
                                        </Select.Option>
                                      </Select>
                                    ) : (
                                      <Select placeholder="" />
                                    )}
                                  </Form.Item>

                                   {/* COLOR */}
                                  <Form.Item
                                    style={{ marginBottom: 18 }}
                                    name="Color"
                                    label="Color:"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Debes introducir al menos un color",
                                      },
                                    ]}
                                  >
                                    <Select placeholder="Introduzca un color">
                                      {Colores.map((color, index) => (
                                        <Select.Option key={color} value={color}>
                                          {color}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>

                                  {/* CANTIDAD */}
                                  <Form.Item
                                    name="Cantidad"
                                    label="Cantidad:"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Debes introducir la cantidad entregada",
                                      },
                                    ]}
                                  >
                                    <Input
                                      type={"number"}
                                      min={0}
                                      placeholder={
                                        "Debe ingresar cantidad entregada"
                                      }
                                    ></Input>
                                  </Form.Item>

                                  {/* COMENTARIO */}
                                  <Form.Item
                                    name="Comentario"
                                    label="Comentario:"
                                    rules={[
                                      {
                                        required: false,
                                        message:
                                          "Debes introducir la cantidad entregada",
                                      },
                                    ]}
                                  >
                                    <Input
                                      type={"text"}
                                      maxLength={100}
                                      placeholder={
                                        "Debe ingresar comentario de entrega"
                                      }
                                    ></Input>
                                  </Form.Item>

                                  {/* VALIDEZ */}
                                  <Form.Item
                                    name="Validez"
                                    label="Validez:"
                                    // initialValue={fechasSugeridas}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Debes introducir el periodo de validez del elemento de protección",
                                      },
                                    ]}
                                    extra={
                                      fechasSugeridas[1] ? (
                                        <Popover
                                          title={"Información:"}
                                          placement={"right"}
                                          content={
                                            <div>
                                              <p className={"info-text"}>
                                                Se asignó una cantidad de días
                                                sugerida del E.P.P según el cargo
                                                del
                                                <br />
                                                trabajador, pero se pueden cambiar
                                                a gusto del usuario.
                                              </p>
                                            </div>
                                          }
                                        >
                                          <InfoCircleTwoTone
                                            className={"info-icon-validez"}
                                            twoToneColor={"#48b9fd"}
                                          />
                                        </Popover>
                                      ) : null
                                    }
                                  >
                                    <RangePicker
                                      locale={locale}
                                      format={"DD-MM-YYYY"}
                                      style={{ marginBottom: "6%" }}
                                    // disabled={[true, false]}
                                    />
                                  </Form.Item>

                                   {/* ID EPP (?) */}
                                  <Form.Item
                                    name="idEpp"
                                    value={_id}
                                    initialValue={_id}
                                  >
                                    <Input
                                      type={"hidden"}
                                      maxLength={100}
                                      placeholder={
                                        "Debe ingresar comentario de entrega"
                                      }
                                      value={_id}
                                    ></Input>
                                  </Form.Item>

                                </Form>
                              </Col>

                            </Row>
                          </Fragment>
                        );
                      }
                      ///
                     
                    )

                  }

                  <Button
                    className="ant-btn crear-embarco-button"
                    style={{
                      width: "50%",
                      marginLeft: "25%",
                      marginBottom: "10%",
                    }}
                    htmlType="submit"
                    disabled={!props.Editar}
                    onClick={()=>{
                      try{

                        if(formularioEpps == null){
                          Swal.fire({
                            title: "Uuups...",
                            text: `Debe ingresar a lo menos un formulario para poder guardar`,
                            icon: "error",
                            confirmButtonColor: "#E60AA3",
                            confirmButtonText: "Continuar",
                          });
                          return;
                        }

                        let arrayEpps = [];
                        const forms = Object.values(formularioEpps.InfoFormularios);
                        forms.forEach(form => {
                        
                          if(  
                            form.getFieldsValue().Cantidad &&
                            form.getFieldsValue().Color &&
                            form.getFieldsValue().Talla &&
                            form.getFieldsValue().Validez &&

                            form.getFieldsValue().Cantidad !== null &&
                            form.getFieldsValue().Color !== null &&
                            form.getFieldsValue().Talla !== null &&
                            form.getFieldsValue().Validez !== null &&

                            form.getFieldsValue().Cantidad !== '' &&
                            form.getFieldsValue().Color !== ''  &&
                            form.getFieldsValue().Talla !== '' &&
                            form.getFieldsValue().Validez !== '' 
                          ){
                            arrayEpps.push(form.getFieldsValue());
                          }
                          
                        });

                        onFinishEpps(arrayEpps, formularioEpps.Formularios);
                      }catch(error){
                        console.log(error);
                      }
                    }}
                  >
                    Guardar
                  </Button>

                </>
                

              ) : (
                <Empty
                  style={{ marginBottom: "20px" }}
                  imageStyle={{ height: 100, marginTop: "20px" }}
                  description={
                    <span className={"seleccion-trabajador-disponible"}>
                      <b>No se han requerido títulos para este trabajador</b>
                    </span>
                  }
                ></Empty>
              )}
            </Form.Provider> 
          </Panel>

          {/* ---------------------------------------------HISTÓRICO------------------------------------------------ */}

          <Panel
            header={
              <h4
                style={{
                  position: "absolute",
                  bottom: -5,
                  color: "#061fcb",
                  left: 35,
                }}
              >
                Histórico
              </h4>
            }
            key="historico"
            className="site-collapse-custom-panel"
            extra={[
              <img
                key="1"
                src={flecha}
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
                }}
                alt="icono"
              />,
              <img
                key="2"
                src={ImgHistorico}
                style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
                }}
                alt="icono"
              />,
            ]}
          >
            <Table
              key={1}
              rowKey="_id"
              dataSource={
                props.eppsBitacora == null || props.eppsBitacora == undefined
                  ? []
                  : props.eppsBitacora.EPPS
              }
              bordered={true}
              showSorterTooltip={false}
              style={{ marginTop: "12px" }}
            >
              <Column
                sorter={(a, b) => {
                  return a.Nombre.localeCompare(b.Nombre);
                }}
                align="left"
                title="EPP"
                dataIndex="Nombre"
                key="Nombre"
              />

              <Column
                align="left"
                title="Fecha de subida"
                dataIndex="FechaSubida"
                // key="FechaEntrega"
                key="FechaSubida"
                render={(valor) => {
                  if (valor !== undefined) {
                    // return moment(valor.FechaSubida).format("DD-MM-YYYY");
                    return convertirFechaString(moment.utc(valor), 1).fechaDDMMYYYY;
                  } else {
                    return "N/A";
                  }

                  // return moment(fecha).format("DD-MM-YYYY HH:MM");
                  // return convertirFechaString(moment.utc(fecha), 1).fechaDDMMYYYY;
                }}
              />

              <Column
                sorter={(a, b) => {
                  return a.Vigencia - b.Vigencia;
                }}
                title="Validez desde"
                key="FechaEntregaFecha"
                dataIndex="FechaEntrega"
                align="center"
                render={(fecha) => {
                  // return moment(fecha).format("DD-MM-YYYY");
                  return convertirFechaString(moment.utc(fecha), 1).fechaDDMMYYYY;
                }}
              />

              <Column
                sorter={(a, b) => {
                  return a.Vigencia - b.Vigencia;
                }}
                title="Validez hasta"
                key="FechaEntregaFecha"
                dataIndex="FechaVencimiento"
                render={(fecha) => {
                  // return moment(fecha).format("DD-MM-YYYY");
                  return convertirFechaString(moment.utc(fecha), 2).fechaDDMMYYYY;
                }}
              />

              <Column
                title="Acción"
                key="Action"
                align="center"
                render={(value) => {
                  return (
                  <Space size="middle">
                    <Button
                      onClick={() => {
                        eliminarEpp(value, IdTrabajador);
                      }}
                      className="editar-button"
                      // disabled={!this.state.Ver}
                      icon={
                        <DeleteOutlined style={{ fontSize: "20px" }} />
                      }
                    />
                  </Space>
                )}}
              />
            </Table>
            {/* return (
                        <Space size="middle">
                          
                        </Space>
                      ); */}
          </Panel>

          {/* ---------------------------------------------IMPRIMIBLES------------------------------------------------ */}

          <Panel
            header={
              <h4
                style={{
                  position: "absolute",
                  bottom: -5,
                  color: "#061fcb",
                  left: 35,
                }}
              >
                Imprimibles de entrega de E.P.P
              </h4>
            }
            key="imprimibles"
            className="site-collapse-custom-panel"
            extra={[
              <img
                key="1"
                src={flecha}
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
                }}
                alt="icono"
              />,
              <img
                key="2"
                src={ImgHistorico}
                style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
                }}
                alt="icono"
              />,
            ]}
          >
            <Tabs
              type={"card"}
              defaultActiveKey="1"
              style={{ marginBottom: 32, marginTop: 16 }}
            >
              {/* EPPS ACTIVAS*/}
              <TabPane tab="Epps Activas" key="1">
                <Table
                  key={2}
                  rowKey="_id2"
                  dataSource={fechasImprimible}
                  bordered={true}
                  showSorterTooltip={false}
                  style={{ marginTop: "12px" }}
                  scroll={{ x: true }}
                >
                  <Column
                    sorter={(a, b) => {
                      return a.fecha.localeCompare(b.fecha);
                    }}
                    align="left"
                    title="Fecha de entrega"
                    dataIndex="fecha"
                    key="fechaEntregaEpp"
                    render={(fecha) => {
                      return (
                        <span
                          style={{
                            fontFamily: "MavenPro-Regular",
                            textAlign: "center",
                          }}
                          key={-2}
                        >
                          Entrega día
                          <br />
                          {fecha}
                        </span>
                      );
                    }}
                  />

                  <Column
                    align="left"
                    title="Elementos entregados"
                    dataIndex="eppsFecha"
                    key="eppsFecha"
                    render={(eppsFecha) => {
                      return (
                        <div style={{ textAlign: "center" }}>
                          {eppsFecha.map((epp, index) => {
                            return (
                              <Tag
                                style={{
                                  minWidth: "100px",
                                  height: "25px",
                                  marginBottom: "9px",
                                  fontFamily: "MavenPro-Regular",
                                }}
                                key={index}
                                color={epp.vencido === true ? "red" : "green"}
                              >
                                {epp.nombre.toUpperCase()}
                              </Tag>
                            );
                          })}
                        </div>
                      );
                    }}
                  />

                  <Column
                    title="Imprimir documento"
                    align="center"
                    render={(dataFecha) => {
                      return (
                        <Space size="middle" key={-1}>
                          <Button
                            onClick={() => {
                              redirectImprimible(dataFecha);
                            }}
                            className="editar-button"
                            icon={
                              <PrinterOutlined style={{ fontSize: "15px" }} />
                            }
                          />
                        </Space>
                      );
                    }}
                  />
                  <Column
                    title="Subir documento"
                    align="center"
                    render={(dataFecha) => {
                      return (
                        <Space size="middle" key={-1}>
                          <Upload
                            showUploadList={false}
                            method="GET"
                            action="#"
                            value={null}
                            onChange={(value) =>
                              obtenerBase64(value, dataFecha)
                            }
                            accept=".png, .jpg, .jpeg, .pdf, .PDF"
                          >
                            <Button
                              disabled={
                                dataFecha.tieneLink === true ? true : false
                              }
                              onClick={() => {
                                // redirectImprimible(dataFecha);
                              }}
                              className="editar-button"
                              icon={
                                <UploadOutlined style={{ fontSize: "15px" }} />
                              }
                            />
                          </Upload>
                        </Space>
                      );
                    }}
                  />
                  <Column
                    title="Descargar documento subido"
                    align="center"
                    render={(dataFecha) => {
                      return (
                        <Space size="middle" key={-1}>
                          <Button
                            disabled={
                              dataFecha.tieneLink === true ? false : true
                            }
                            onClick={() => {
                              const linkURL = dataFecha.eppsFecha[0].urlArchivo;
                              const link = document.createElement("a");
                              link.href = linkURL;
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                              // redirectImprimible(dataFecha);
                            }}
                            className="editar-button"
                            icon={
                              <DownloadOutlined style={{ fontSize: "15px" }} />
                            }
                          />
                        </Space>
                      );
                    }}
                  />
                </Table>
              </TabPane>

              {/* EPPS VENCIDAS*/}
              <TabPane tab="Epps Vencidas" key="2">
                <Table
                  key={3}
                  rowKey="_id2"
                  dataSource={fechasImprimibleVencidas}
                  bordered={true}
                  showSorterTooltip={false}
                  style={{ marginTop: "12px" }}
                  scroll={{ x: true }}
                >
                  <Column
                    sorter={(a, b) => {
                      return a.fecha.localeCompare(b.fecha);
                    }}
                    align="left"
                    title="Fecha de entrega"
                    dataIndex="fecha"
                    key="fechaEntregaEpp"
                    render={(fecha) => {
                      return (
                        <span
                          style={{
                            fontFamily: "MavenPro-Regular",
                            textAlign: "center",
                          }}
                          key={-2}
                        >
                          Entrega día
                          <br />
                          {fecha}
                        </span>
                      );
                    }}
                  />

                  <Column
                    align="left"
                    title="Elementos entregados"
                    dataIndex="eppsFecha"
                    key="eppsFecha"
                    render={(eppsFecha) => {
                      return (
                        <div style={{ textAlign: "center" }}>
                          {eppsFecha.map((epp, index) => {
                            return (
                              <Tag
                                style={{
                                  minWidth: "100px",
                                  height: "25px",
                                  marginBottom: "9px",
                                  fontFamily: "MavenPro-Regular",
                                }}
                                key={index}
                                color={epp.vencido === true ? "red" : "green"}
                              >
                                {epp.nombre.toUpperCase()}
                              </Tag>
                            );
                          })}
                        </div>
                      );
                    }}
                  />

                  <Column
                    title="Imprimir documento"
                    align="center"
                    render={(dataFecha) => {
                      return (
                        <Space size="middle" key={-1}>
                          <Button
                            onClick={() => {
                              redirectImprimible(dataFecha);
                            }}
                            className="editar-button"
                            icon={
                              <PrinterOutlined style={{ fontSize: "15px" }} />
                            }
                          />
                        </Space>
                      );
                    }}
                  />
                  <Column
                    title="Subir documento"
                    align="center"
                    render={(dataFecha) => {
                      return (
                        <Space size="middle" key={-1}>
                          <Upload
                            showUploadList={false}
                            method="GET"
                            action="#"
                            value={null}
                            onChange={(value) =>
                              obtenerBase64(value, dataFecha)
                            }
                            accept=".png, .jpg, .jpeg, .pdf, .PDF"
                          >
                            <Button
                              disabled={
                                dataFecha.tieneLink === true ? true : false
                              }
                              onClick={() => {
                                // redirectImprimible(dataFecha);
                              }}
                              className="editar-button"
                              icon={
                                <UploadOutlined style={{ fontSize: "15px" }} />
                              }
                            />
                          </Upload>
                        </Space>
                      );
                    }}
                  />
                  <Column
                    title="Descargar documento subido"
                    align="center"
                    render={(dataFecha) => {
                      return (
                        <Space size="middle" key={-1}>
                          <Button
                            disabled={
                              dataFecha.tieneLink === true ? false : true
                            }
                            onClick={() => {
                              const linkURL = dataFecha.eppsFecha[0].urlArchivo;
                              const link = document.createElement("a");
                              link.href = linkURL;
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                              // redirectImprimible(dataFecha);
                            }}
                            className="editar-button"
                            icon={
                              <DownloadOutlined style={{ fontSize: "15px" }} />
                            }
                          />
                        </Space>
                      );
                    }}
                  />
                </Table>
              </TabPane>
            </Tabs>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default FormularioEntregaEPP;
