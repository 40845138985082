import {
  BUSCAR_TRABAJADORES_VACACIONES,
  ERROR_VACACIONES,
  INSERTAR_DATOS_FORMULARIO,
  INSERTAR_FECHAS_FORM,
  INSERTAR_FECHAS_FORM2,
  INSERTAR_TRABAJADOR_FORM,
  LOADING_VACACIONES,
  ENVIAR_CORREO,
  LOADING_VACACIONES_FALSE,
  BUSCAR_CONTRATOS_TRABAJADOR
} from "../types/vacacionTypes";

import Swal from "sweetalert2";
import moment from 'moment';
import API from "../Api/api";

import {
  momentToStringFechaInicio,
  momentToStringFechaFin,
  databaseFormat,
  obtenerFechaFormateadaMismaHora,
} from "../../funcionesGenericas/fechas";

// export const insertarDatos = (
//   TipoDias,
//   DisponiblesVacaciones,
//   DisponiblesProgresivos,
//   DisponiblesTotal,
//   FeriadoLegalVacaciones,
//   FeriadoLegalProgresivos,
//   FeriadoLegalTotal,
//   SaldoVacaciones,
//   SaldoProgresivos,
//   SaldoTotal,
// ) => async (dispatch, getState) => {

//   dispatch({
//     type: LOADING_VACACIONES,
//   });

//   try {
//     const store = getState().VacacionReducer;
//     const {
//       form: { Fechas, Fechas2, Trabajador, Correo: envioCorreo },
//     } = store;

//     let fechaInicioVacaciones = moment(Fechas2[0]);
//     let fechaFinVacaciones = moment(Fechas2[1]);

//     let objVacaciones = {
//       IdTrabajador: Trabajador,
//       TipoDias,
//       FechasTrabajadas: Fechas,
//       DisponiblesVacaciones,
//       DisponiblesProgresivos,
//       DisponiblesTotal,
//       FeriadoLegalVacaciones,
//       FeriadoLegalProgresivos,
//       FeriadoLegalTotal,
//       SaldoVacaciones,
//       SaldoProgresivos,
//       SaldoTotal,
//       Fecha_Inicio: Fechas2[0],
//       Fecha_Fin: Fechas2[1],
//     };

//     console.log(objVacaciones);

//     const urlVacaciones = `${API}/Vacaciones`;
//     const requestVacaciones = await fetch(urlVacaciones, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//       body: JSON.stringify(objVacaciones),
//     });

//     const responseVacaciones = await requestVacaciones.json();
//     const statusVacaciones = await requestVacaciones.status;

//     switch (statusVacaciones) {
//       case 201:
//         let idRegistroVacaciones = responseVacaciones.data._id;

//         console.log(idRegistroVacaciones);

//         let cantidadDias =
//           fechaFinVacaciones.diff(fechaInicioVacaciones, "days") + 1;

//         let arregloDeDias = [];

//         for (let index = 0; index < cantidadDias; index++) {
//           let fecha = fechaInicioVacaciones;
//           fecha = fecha.add(index === 0 ? 0 : 1, "day");
//           const aux = {
//             Fecha: new Date(fecha),
//             IdTrabajador: Trabajador,
//             TipoRegistroBitacoraTrabajador: "5f3eebac978b284b68ebced5",
//             InicioTrabajo: index === 0 ? true : false,
//             FinTrabajo: index === cantidadDias - 1 ? true : false,
//             Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,
//             Vacaciones: idRegistroVacaciones,

//           };

//           arregloDeDias.push(aux);
//         }

//         let objetoEnviar = {
//           arregloDeDias,
//           fechaInicio: Fechas2[0],
//           fechaFin: Fechas2[1],
//         };

//         const url = `${API}/IngresoDeDias/${Trabajador}`;
//         const request = await fetch(url, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//           },
//           body: JSON.stringify(objetoEnviar),
//         });

//         const response = await request.json();
//         const status = await request.status;

//         switch (status) {
//           case 201:
//             ////////////////////////////////////////////////////////////////////////////////
//             if (envioCorreo) {
//               const url = `${API}/Enviar/Correo/Vacaciones/${idRegistroVacaciones}`;
//               await fetch(url, {
//                 method: "POST",
//                 headers: {
//                   "Content-Type": "application/json",
//                   Accept: "application/json",
//                 },
//               })
//             }
//             ////////////////////////////////////////////////////////////////////////////////

//             Swal.fire({
//               title: "Bien",
//               text: "Se ingresaron exitosamente las vacaciones",
//               icon: "success",
//               confirmButtonColor: "#E60AA3",
//               confirmButtonText: "Continuar",
//             });

//             dispatch({
//               type: INSERTAR_DATOS_FORMULARIO,
//               payload: response,
//             });

//             break;
//           case 400:
//             Swal.fire({
//               title: "Error",
//               text: response.err.message,
//               icon: "error",
//             });
//             break;
//           case 500:
//             Swal.fire({
//               title: "Error",
//               text: response.err.message,
//               icon: "error",
//             });

//             dispatch({
//               type: ERROR_VACACIONES,
//               payload: response.err,
//             });

//             break;
//           default:
//             Swal.fire({
//               title: "Error",
//               text:
//                 "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
//               icon: "error",
//             });
//             break;
//         }

//         break;
//       case 400:
//         Swal.fire({
//           title: "Error",
//           text: responseVacaciones.err.message,
//           icon: "error",
//         });
//         break;
//       case 500:
//         Swal.fire({
//           title: "Error",
//           text: responseVacaciones.err.message,
//           icon: "error",
//         });

//         dispatch({
//           type: ERROR_VACACIONES,
//           payload: responseVacaciones.err,
//         });

//         break;
//       default:
//         Swal.fire({
//           title: "Error",
//           text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
//           icon: "error",
//         });
//         break;
//     }
//   } catch (error) {
//     dispatch({
//       type: ERROR_VACACIONES,
//       payload: error.message,
//     });
//   }
// };

export const insertarDatosAvanzado = (
  TipoDias,
  DisponiblesVacaciones,
  DisponiblesProgresivos,
  DisponiblesTotal,
  FeriadoLegalVacaciones,
  FeriadoLegalProgresivos,
  FeriadoLegalTotal,
  SaldoVacaciones,
  SaldoProgresivos,
  SaldoTotal,
  FechasEfectivas,
  FechasTrabajadas,
  Observacion
) => async (dispatch, getState) => {
  
  dispatch({
    type: LOADING_VACACIONES,
  });

  try {
    const store = getState().VacacionReducer;
    const {
      form: { Fechas, Fechas2, Trabajador, Correo: envioCorreo },
    } = store;

    let fechaInicioVacaciones = moment.utc(momentToStringFechaInicio(moment(FechasEfectivas[0]), databaseFormat));
    let fechaFinVacaciones = moment.utc(momentToStringFechaInicio(moment(FechasEfectivas[1]), databaseFormat));

    let objVacaciones = {
      IdTrabajador: Trabajador,
      TipoDias,
      FechasTrabajadas: FechasTrabajadas,
      DisponiblesVacaciones,
      DisponiblesProgresivos,
      DisponiblesTotal,
      FeriadoLegalVacaciones,
      FeriadoLegalProgresivos,
      FeriadoLegalTotal,
      SaldoVacaciones,
      SaldoProgresivos,
      SaldoTotal,
      Fecha_Inicio: momentToStringFechaInicio(moment(FechasEfectivas[0]), databaseFormat),
      Fecha_Fin: momentToStringFechaFin(moment(FechasEfectivas[1]), databaseFormat),
      Observacion: Observacion
    };

    const urlVacaciones = `${API}/Vacaciones/Avanzado`;
    const requestVacaciones = await fetch(urlVacaciones, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objVacaciones),
    });

    const responseVacaciones = await requestVacaciones.json();
    const statusVacaciones = await requestVacaciones.status;

    switch (statusVacaciones) {
      case 201:
        let idRegistroVacaciones = responseVacaciones.data._id;

        let cantidadDias =
          fechaFinVacaciones.diff(fechaInicioVacaciones, "days") + 1;

        let arregloDeDias = [];

        for (let index = 0; index < cantidadDias; index++) {

          let fecha = fechaInicioVacaciones;

          fecha = fecha.add(index === 0 ? 0 : 1, "day");

          const aux = {
            Fecha: obtenerFechaFormateadaMismaHora(fecha.set({ hour: 12 }), databaseFormat),
            IdTrabajador: Trabajador,
            TipoRegistroBitacoraTrabajador: "5f3eebac978b284b68ebced5",
            InicioTrabajo: index === 0 ? true : false,
            FinTrabajo: index === cantidadDias - 1 ? true : false,
            Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,
            Vacaciones: idRegistroVacaciones,

            Fecha_Inicio: momentToStringFechaInicio(moment(FechasEfectivas[0]), databaseFormat),
            Fecha_Fin: momentToStringFechaFin(moment(FechasEfectivas[1]), databaseFormat),
            FechasTrabajadasVacaciones: FechasTrabajadas,
            Observacion: Observacion
          };

          arregloDeDias.push(aux);
        }

        let objetoEnviar = {
          arregloDeDias,
          fechaInicio: objVacaciones.Fecha_Inicio,
          fechaFin: objVacaciones.Fecha_Fin,
        };

        const url = `${API}/IngresoDeDias/${Trabajador}`;
        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objetoEnviar),
        });

        const response = await request.json();
        const status = await request.status;

        switch (status) {
          case 201:
            ////////////////////////////////////////////////////////////////////////////////
            if (envioCorreo) {
              const url = `${API}/Enviar/Correo/Vacaciones/${idRegistroVacaciones}`;
              await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              })
            }
            ////////////////////////////////////////////////////////////////////////////////

            Swal.fire({
              title: "Bien",
              text: "Se ingresaron exitosamente las vacaciones",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });

            dispatch({
              type: INSERTAR_DATOS_FORMULARIO,
              payload: response,
            });

            break;
          case 400:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            break;
          case 500:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });

            dispatch({
              type: ERROR_VACACIONES,
              payload: response.err,
            });

            break;
          default:

            dispatch({
              type: ERROR_VACACIONES,
              payload: response.err,
            });
            Swal.fire({
              title: "Error",
              text:
                "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
              icon: "error",
            });
            break;
        }

        break;
      case 400:
        Swal.fire({
          title: "Error",
          text: responseVacaciones.err.message,
          icon: "error",
        });
        break;
      case 409:

        let msg = '';

        switch (responseVacaciones.data.err) {
          case 'TRABAJO_CONFIRMADO':
            msg = '<span>No puedes registrar en días con trabajo de asistencia confirmada.</span>';
            break;
          case 'TRABAJO_DESCANSO':
            msg = `<span>No puedes registrar en días de trabajo que ya tienen un descanso asignado. Si deseas modificar esos registros, debes utilizar el <a href='/Descanso'>módulo de descansos.</a></span>`;
            break;
          case 'DESCANSOS':
            msg = `<span>No puedes registrar en días con descanso planificado. Si deseas modificar esos registros, debes utilizar el <a href='/Descanso'>módulo de descansos.</a></span>`;
            break;
          case 'VACACIONES':
            msg = `<span>No puedes registrar en días con vacaciones planificadas. Si deseas sobrescribir días, puedes eliminar el registro antiguo y crear uno nuevo.</span>`;
            break;
          default:
            msg = '<span>No puedes sobrescribir los registros de esas fechas.</span>';
            break;
        }

        Swal.fire({
          title: "¡Ups!",
          html: msg,
          icon: "warning",
        });

        dispatch({
          type: ERROR_VACACIONES,
          payload: 'No puedes registrar descansos en días con asistencia ya confirmada.',
        });

        break;
      case 500:
        Swal.fire({
          title: "Error",
          text: responseVacaciones.err.message,
          icon: "error",
        });

        dispatch({
          type: ERROR_VACACIONES,
          payload: responseVacaciones.err,
        });

        break;

      case 420:

        Swal.fire({
          title: "Uups",
          text: "No se pueden asignar vacaciones en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({ type: ERROR_VACACIONES, payload: "No se pueden asignar vacaciones en un periodo que se encuentra cerrado" });

        break;
      default:
        dispatch({
          type: ERROR_VACACIONES,
          payload: 'No puedes registrar descansos en días con asistencia ya confirmada.',
        });
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }
  } catch (error) {

    console.error(error);

    dispatch({
      type: ERROR_VACACIONES,
      payload: error.message,
    });
  }
};

export const insertarFechas = (fechas) => (dispatch) => {
  dispatch({
    type: INSERTAR_FECHAS_FORM,
    payload: fechas,
  });
};

export const insertarFechas2 = (fechas) => (dispatch) => {
  dispatch({
    type: INSERTAR_FECHAS_FORM2,
    payload: fechas,
  });
};

export const enviarCorreo = (estado) => (dispatch) => {
  dispatch({
    type: ENVIAR_CORREO,
    payload: estado,
  });
}

export const insertarTrabajador = (idTrabajador) => (dispatch) => {
  dispatch({
    type: INSERTAR_TRABAJADOR_FORM,
    payload: idTrabajador,
  });
};

export const traer_todos_las_fechas_trabajador = (idTrabajador) => async (
  dispatch
) => {
  try {
    const url = `${API}/IngresoDeDias/Actuales/Vacaciones/${idTrabajador}`;
    const request = await fetch(url);
    const { data } = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:
        return data;
      default:
        console.log(data);
    }
  } catch (error) {
    dispatch({
      type: ERROR_VACACIONES,
      payload: error.message,
    });
  }
};

export const traer_todos_los_trabajadores = (empresa) => async (dispatch) => {
  dispatch({
    type: LOADING_VACACIONES,
  });

  try {
    const url = `${API}/trabajadores?empresa=${empresa}`;
    const request = await fetch(url);
    const { Trabajadores } = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:
        dispatch({
          type: BUSCAR_TRABAJADORES_VACACIONES,
          payload: Trabajadores,
        });
        break;
      default:
        break;
    }
  } catch (error) {
    dispatch({
      type: ERROR_VACACIONES,
      payload: error.message,
    });
  }
};

export const eliminarRegistro = (fechaInicioDel, fechaFinDel, idVacaciones) => async (dispatch, getState) => {

  dispatch({
    type: LOADING_VACACIONES,
  });

  try {

    const store = getState().VacacionReducer;

    const {
      form: { Trabajador },
    } = store;

    const objEliminar = {
      Fecha_Inicio: fechaInicioDel,
      Fecha_Fin: fechaFinDel,
      IdTrabajador: Trabajador,
    }

    const urlDescanso = `${API}/Vacaciones/Eliminar/${idVacaciones}`;

    const request = await fetch(urlDescanso, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objEliminar),
    });

    const response = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:

        dispatch({
          type: LOADING_VACACIONES_FALSE,
        });

        Swal.fire({
          title: "¡Bien!",
          text: "El registro se ha eliminado exitosamente.",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        break;

      case 500:
        dispatch({
          type: LOADING_VACACIONES_FALSE,
        });

        break;

      case 420:

        Swal.fire({
          title: "Uups",
          text: "No se pueden eliminar vacaciones en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({ type: ERROR_VACACIONES, payload: "No se pueden eliminar vacaciones en un periodo que se encuentra cerrado" });

        break;
      default:
        dispatch({
          type: LOADING_VACACIONES_FALSE,
        });
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }

  } catch (e) {

    console.error(e);

    dispatch({
      type: ERROR_VACACIONES,
      payload: e.message,
    });
  }

}

/***************** CÁLCULOS AUTOMÁTICOS ********************/

export const ObtenerVacacionesHistoricas = (idTrabajador, idEmpresa) => async (dispatch) => {

  try {
    const url = `${API}/BitacoraDocumentos/${idTrabajador}?Empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();

        return data.data;

      case 204:

        let dataVacia = []
        return dataVacia;

      default:
        data = await response.json();
        dispatch({ type: ERROR_VACACIONES, payload: { message: 'error al obtener vacaciones históricas' } });
        return [];
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ERROR_VACACIONES, payload: error });
  }
};

export const ObtenerVacacionesHistoricasPorContrato = (idTrabajador, idEmpresa, idContrato) => async (dispatch) => {

  try {
    const url = `${API}/BitacoraDocumentos/${idTrabajador}/${idEmpresa}/${idContrato}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();

        return {status: status,
          data: data.data};

      case 204:
        return {status: status, data:  []};

      default:
        data = await response.json();
        dispatch({ type: ERROR_VACACIONES, payload: { message: 'error al obtener vacaciones históricas' } });
        return {status: status, data:  []};
    }
  } catch (error) {
    
    dispatch({ type: ERROR_VACACIONES, payload: error });
    return {status: 500, data:  []};
  }
};

export const ObtenerFeriados = (idEmpresa) => async (dispatch) => {

  dispatch({ type: LOADING_VACACIONES });

  try {
    const url = `${API}/Feriados?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:

        const feriados = data.feriados;
        return feriados;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR_VACACIONES, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: error,
      icon: "error",
    });
    dispatch({ type: ERROR_VACACIONES, payload: error });
  }
};

export const insertarBonificacion = (
  TipoDias,
  DisponiblesVacaciones,
  DisponiblesProgresivos,
  DisponiblesTotal,
  FeriadoLegalVacaciones,
  FeriadoLegalProgresivos,
  FeriadoLegalTotal,
  SaldoVacaciones,
  SaldoProgresivos,
  SaldoTotal,
  FechasTrabajadas,
  esBonificacion,
  montoBonificacion,
  Observacion
) => async (dispatch, getState) => {

  dispatch({
    type: LOADING_VACACIONES,
  });

  try {

    const store = getState().VacacionReducer;
    const {
      form: { Trabajador, Correo: envioCorreo },
    } = store;

    let objVacaciones = {
      IdTrabajador: Trabajador,
      TipoDias,
      FechasTrabajadas,
      DisponiblesVacaciones,
      DisponiblesProgresivos,
      DisponiblesTotal,
      FeriadoLegalVacaciones,
      FeriadoLegalProgresivos,
      FeriadoLegalTotal,
      SaldoVacaciones,
      SaldoProgresivos,
      SaldoTotal,
      Es_Compensacion: esBonificacion,
      Monto_Compensacion: montoBonificacion,
      Observacion: Observacion
    };
    
    const urlVacaciones = `${API}/Vacaciones/Bonificacion`;
    const requestVacaciones = await fetch(urlVacaciones, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objVacaciones),
    });

    const responseVacaciones = await requestVacaciones.json();
    const statusVacaciones = await requestVacaciones.status;
    
    switch (statusVacaciones) {
      case 200:
      case 201:

        dispatch({
          type: LOADING_VACACIONES_FALSE,
        });

        Swal.fire({
          title: "¡Bien!",
          text: "Se ingresó exitosamente la bonificación.",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        break;

      default:
        break;
    }

  } catch (err) {
    console.error(err);
  }
}

export const obtenerBonificacionesTrabajador = (idTrabajador) => async (dispatch) => {

  try {
    const url = `${API}/Vacaciones/Bonificacion/${idTrabajador}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        const bonificaciones = data.data;
        return bonificaciones;

      case 204:

        return [];

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR_VACACIONES, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: error,
      icon: "error",
    });
    dispatch({ type: ERROR_VACACIONES, payload: error });
  }
};

export const obtenerBonificacionesTrabajadorPorContrato = (idTrabajador,idContrato) => async (dispatch) => {

  try {
    const url = `${API}/Vacaciones/Bonificacion/${idTrabajador}/${idContrato}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        const bonificaciones = data;
        return bonificaciones;

      case 204:

        return [];

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR_VACACIONES, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: error,
      icon: "error",
    });
    dispatch({ type: ERROR_VACACIONES, payload: error });
  }
};

export const traer_todos_contratos_trabajador =
  (idTrabajador, idEmpresa) => async (dispatch) => {
    try {
      const url = `${API}/Trabajadores/Contratos?idTrabajador=${idTrabajador}&idEmpresa=${idEmpresa}`;
      const request = await fetch(url);
      const data = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          dispatch({
            type: BUSCAR_CONTRATOS_TRABAJADOR,
            payload: data.contratoDB,
          });
          break;
        default:
          console.log(status);
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          break;
      }
    } catch (error) {
      dispatch({
        type: ERROR_VACACIONES,
        payload: error.message,
      });
    }
};

export const traer_todos_las_vacaciones_historicos =
  (idTrabajador) => async (dispatch) => {
    try {
      const ID_VACACIONES = "5f3eebac978b284b68ebced5";

      const url = `${API}/IngresoDeDias/Bitacoras/TipoBitacora/${idTrabajador}/${ID_VACACIONES}`;

      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          return data;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          return null;
      }
    } catch (error) {
      dispatch({
        type: ERROR_VACACIONES,
        payload: error.message,
      });
      return null;
    }
  };

  export const traer_todos_las_fechas_trabajador_por_contrato =
  (idTrabajador, idContrato, FechaInicio, FechaFin) => async (dispatch) => {
    try {
      let url;
      if (FechaInicio !== undefined && FechaFin !== undefined) {
        url = `${API}/IngresoDeDias/Actuales/Contrato/${idTrabajador}/${idContrato}?InicioBusqueda=${FechaInicio}&FinBusqueda=${FechaFin}`;
      } else {
        url = `${API}/IngresoDeDias/Actuales/Contrato/${idTrabajador}/${idContrato}`;
      }
      const request = await fetch(url);
      const data = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          return data;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          return null;
      }
    } catch (error) {
      dispatch({
        type: ERROR_VACACIONES,
        payload: error.message,
      });
      return null;
    }
  };

  export const traer_todos_las_vacaciones_trabajador_por_contrato = (idTrabajador, idContrato) => async (dispatch) => {
    try {
      let url = `${API}/Vacaciones/PorContrato/${idContrato}/${idTrabajador}`;
      const request = await fetch(url);
      const data = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          
          return data;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          return null;
      }
    } catch (error) {
      dispatch({
        type: ERROR_VACACIONES,
        payload: error.message,
      });
      return null;
    }
  };