import React, { useState, useEffect } from "react";
import SubirFoto from "../../components/subirFoto/SubirFoto";
import Trabajador from "../../img/icons/Trabajador.svg";
import personal from "../../img/icons/avatar.svg";
import contacto from "../../img/icons/telefono.svg";
import familiares from "../../img/icons/familiares.svg";
import flecha from "../../img/icons/flecha.svg";
import avatar from "../../img/icons/avatar.svg";
import epp from "../../img/icons/epp.svg";
import contrato from "../../img/icons/contrato-panel.svg";
import vacaciones from "../../img/icons/vacaciones-panel.svg";

import especialidades_icon from "../../img/icons/icon_espe.png";
import titulos_icon from "../../img/icons/icon_titu.png";

import bancario from "../../img/icons/bancarios.svg";
import previsional from "../../img/icons/previsional.svg";
import estudios from "../../img/icons/estudios.svg";

import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import "../formularioTrabajador/FormularioTrabajador.css";
// import { PlusOutlined, DeleteFilled } from "@ant-design/icons";

import Rut from "../../funcionesGenericas/rut";
import moment from "moment";

import {
  Form,
  Input,
  Button,
  Card,
  Collapse,
  DatePicker,
  Row,
  Col,
  PageHeader,
  Select,
  Radio,
  InputNumber,
  Popover,
} from "antd";

import Swal from "sweetalert2";

import {
  momentToUnixFechaInicio,
  momentToUnixFechaFin,
  momentToStringFechaInicio,
  momentToStringFechaFin,
  databaseFormat,
} from "../../funcionesGenericas/fechas";

import { isTelefonoValid } from "../../funcionesGenericas/telefonos";

import { InfoCircleTwoTone } from "@ant-design/icons";

// import ValidadorRut from "validar-rut";
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const FormularioTrabajadorNuevo = (props) => {
  const [form] = Form.useForm();
  const [validRutTrabajador, setValidRutTra] = useState("");
  const [tipoFechaContrato, setTipoFechaContrato] = useState(null);
  const [requirioProgre, setRequirioProgre] = useState(false);
  const [otroSelected, setOtroSelected] = useState(false);
  const [validateOtro, setValidateOtro] = useState("error");

  const [openedPanels, setOpenedPanels] = useState([]);

  const formRef = React.createRef();

  const ObtenerImagen = (imagenUrl) => {
    props.obtenerImagen(imagenUrl);
  };

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = async (values) => {

    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {

      //VALIDACIÓN DE ABRIR TODOS LOS PANELES OBLIGATORIOS
      // let panelesObligatorios = ["1", "3", "8", "9", "11", "12"];
      let panelesObligatorios = ["1", "3", "8", "9"];
      let forEachFlag = true;

      values.Contacto_Fono_Celular =
        values.Contacto_Fono_Celular === undefined
          ? ""
          : values.Contacto_Fono_Celular.replace(/ /g, "");

      values.Contacto_Fono_Fijo =
        values.Contacto_Fono_Fijo === undefined
          ? ""
          : values.Contacto_Fono_Fijo.replace(/ /g, "");

      values.Telefono =
        values.Telefono === undefined ? "" : values.Telefono.replace(/ /g, "");

      panelesObligatorios.forEach((obligatorioKey) => {

        if (!openedPanels.includes(obligatorioKey) && forEachFlag) {
          let panelFaltante;
          if (obligatorioKey === "1") panelFaltante = "Datos personales";
          if (obligatorioKey === "3")
            panelFaltante = "Datos familiares y legales";
          if (obligatorioKey === "8")
            panelFaltante = "Características del contrato";
          if (obligatorioKey === "9") panelFaltante = "Vacaciones";
          // if (obligatorioKey === "11") panelFaltante = "Datos previsionales";
          // if (obligatorioKey === "12") panelFaltante = "Datos otros estudios";

          Swal.fire({
            title: "Ups...",
            text: `Debes ingresar la información de ${panelFaltante}`,
            icon: "warning",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });

          forEachFlag = false;
        }
      });

      if (!forEachFlag) {
        return;
      }

      //validacion de campos para que no se ingresen como undefined o null
      if (props.imagen === null || props.imagen === undefined) {
        values.Imagen =
          "https://peoplemanage.blob.core.windows.net/imagenes-sistema/avatar.svg";
      } else {
        values.Imagen = props.imagen;
      }

      values.Contacto_Correo =
        values.Contacto_Correo === undefined ||
          values.Contacto_Correo === "undefined"
          ? ""
          : values.Contacto_Correo;
      values.Contacto_Fono_Celular =
        values.Contacto_Fono_Celular === undefined ||
          values.Contacto_Fono_Celular === "undefined"
          ? ""
          : values.Contacto_Fono_Celular;
      values.Contacto_Fono_Fijo =
        values.Contacto_Fono_Fijo === undefined ||
          values.Contacto_Fono_Fijo === "undefined"
          ? ""
          : values.Contacto_Fono_Fijo;
      values.Contacto_Nombre =
        values.Contacto_Nombre === undefined ||
          values.Contacto_Nombre === "undefined"
          ? ""
          : values.Contacto_Nombre;

      usuario = JSON.parse(usuario);

      ///////////////////////////////////////////////////////////////////////////////////////////////

      //Si es contrato indefinido tendrá sólo fecha de inicio, al contrario fecha de inicio y fin.
      if (values.Tipo_Contrato === "Indefinido") {
        values.Contrato_Fecha_Inicio = values.Contrato_Fecha;
      } else {
        values.Contrato_Fecha_Inicio = values.Contrato_Fecha[0];
        values.Contrato_Fecha_Fin = values.Contrato_Fecha[1];
      }

      //si seleccionó régimen de vacaciones "otro" y no ingresó cuántos días son
      if (
        values.Regimen_Vacaciones === -1 &&
        (values.otro == undefined || values.otro == null)
      ) {
        Swal.fire({
          title: "Ups...",
          text:
            "Debes ingresar la cantidad de días en el régimen de vacaciones.",
          icon: "warning",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        });

        return;
      } else if (
        values.Regimen_Vacaciones === -1 &&
        !(values.otro == undefined || values.otro == null)
      ) {
        values.Regimen_Vacaciones = values.otro;
      }

      ///////////////////////////////////////////////////////////////////////////////////////////////

      //transformación de fechas a Unix timestamp
      values.Contrato_Fecha_Inicio_Unix = momentToUnixFechaInicio(
        values.Contrato_Fecha_Inicio
      );
      values.Contrato_Fecha_Inicio = momentToStringFechaInicio(
        values.Contrato_Fecha_Inicio,
        databaseFormat
      );

      if (values.Contrato_Fecha_Fin) {
        values.Contrato_Fecha_Fin_Unix = momentToUnixFechaFin(
          values.Contrato_Fecha_Fin
        );
        values.Contrato_Fecha_Fin = momentToStringFechaFin(
          values.Contrato_Fecha_Fin,
          databaseFormat
        );
      }

      if (values.Fecha_Progresivas) {
        values.Fecha_Progresivas_Unix = momentToUnixFechaInicio(
          values.Fecha_Progresivas
        );
        values.Fecha_Progresivas = momentToStringFechaInicio(
          values.Fecha_Progresivas,
          databaseFormat
        );
      }

      // VALIDACIÓNES DE VACACIONES PROGRESIVAS:
      if (values.Requirio_Progresivas) {
        let mesesEnEmpresa = moment().diff(
          values.Contrato_Fecha_Inicio,
          "months"
        );
        let mesesTotales = values.Meses_Empleadores_Anteriores + mesesEnEmpresa;

        if (mesesTotales < 120) {
          Swal.fire({
            title: "Ups...",
            text:
              "El trabajador no pudo haber requerido legalmente vacaciones progresivas si no ha trabajado 120 meses (10 años).",
            icon: "warning",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Ok",
          });

          return;
        }

        let aniosEnEmpresa = moment().diff(
          values.Contrato_Fecha_Inicio,
          "years"
        );

        if (aniosEnEmpresa < 3) {
          Swal.fire({
            title: "Ups...",
            text:
              "El trabajador debió haber trabajado al menos 3 años en la empresa para requerir vacaciones progresivas.",
            icon: "warning",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Ok",
          });

          return;
        }
      }

      values.Costo_Bruto_Diario =
        values.Costo_Bruto_Diario === undefined ? 0 : values.Costo_Bruto_Diario;
      values.Costo_Liquido_Diario =
        values.Costo_Liquido_Diario === undefined
          ? 0
          : values.Costo_Liquido_Diario;

      // LOS RUT SIEMPRE SE DEBEN ENVIAR CON UPPERCASE EN CASO DE RUT CON K
      values.Rut = values.Rut.toUpperCase();

      // El trabajador siempre se crea con dato Finiquitado false
      values.Finiquitado = false;

      values.Nombre = values.Nombre.trim();
      values.Apellido_Paterno = values.Apellido_Paterno.trim();
      values.Apellido_Materno = values.Apellido_Materno.trim();

      const objAux = {
        ...values,
        Empresa: usuario.EmpresaSeleccionada.Empresa._id,
      };
      props.crearTrabajador(objAux);
    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const condicionalFechaContrato = () => {
    if (tipoFechaContrato === "Indefinido") {
      return (
        <DatePicker
          format="DD-MM-YYYY"
          locale={locale}
          className="campos"
          style={{
            width: "100%",
          }}
        />
      );
    } else {
      return (
        <RangePicker
          format="DD-MM-YYYY"
          locale={locale}
          className="campos"
          style={{
            width: "100%",
          }}
        />
      );
    }
  };

  const condicionalFechaProgresivas = () => {
    if (requirioProgre) {
      return (
        <Form.Item
          name={"Fecha_Progresivas"}
          label="¿Desde cuándo?"
          rules={[
            {
              required: true,
              message: "Debe indicar si requirió vacaciones progresivas",
            },
          ]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            locale={locale}
            className="campos"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      );
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 9,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  const handleCargosChange = (idCargo) => {
    let cargosEmpresa = props.cargosTrabajador;

    let cargoSeleccionado = cargosEmpresa.filter(
      (cargo) => cargo._id == idCargo
    )[0];

    let idsEppsCargo = [];
    let idsDocumentosCargo = [];

    cargoSeleccionado.Epps.forEach((epp) => {
      idsEppsCargo.push(epp.IdEpp);
    });

    cargoSeleccionado.Documentos.forEach((documento) => {
      idsDocumentosCargo.push(documento.IdDocumento);
    });

    formRef.current.setFieldsValue({ EPP: idsEppsCargo, TiposDeDocumentos: idsDocumentosCargo });
  };

  const testNamesRegex = /^([a-z,A-Z,á,é,í,ó,ú,â,ê,ô,ã,õ,ç,Á,É,Í,Ó,Ú,Â,Ê,Ô,Ã,Õ,Ç,ü,ñ,Ü,Ñ," "]+)$/;

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      ref={formRef}
    >
      <Row style={{ padding: "5px 15px 5px 15px" }}>

        <Col span={24} style={{ display: "flex" }} className="title">

          <img src={Trabajador} style={{ width: "35px" }} alt="icono modal" />

          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Nuevo Trabajador"
          />

        </Col>

        <Col 
          xs={{ span: 24, offset: 0 }} 
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }} 
          lg={{ span: 8, offset: 0 }} 
          xl={{ span: 8, offset: 0 }}
          xxl={{ span: 8, offset: 0 }}
        >
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Row justify="center">
              <Col span={24} style={{ marginBottom: "20px",marginRight:"40px"}}>
                <SubirFoto avatar={avatar} ObtenerImagen={ObtenerImagen} />
              </Col>

              <Col span={24}>
                <p
                  style={{
                    fontFamily: "MavenPro-Regular",
                    width: "80%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  Te recomendamos que la fotografía no exceda los 5MB de peso
                </p>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col 
          xs={{ span: 24, offset: 0 }} 
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }} 
          lg={{ span: 15, offset: 1 }} 
          xl={{ span: 15, offset: 1 }}
          xxl={{ span: 15, offset: 1 }}
        >
          <Collapse
            bordered={false}
            className="site-collapse-custom-collapse "
            style={{ width: '100%' }}
            onChange={async (e) => {
              await e.forEach(async (key) => {
                //agregando keys únicas
                if (openedPanels.indexOf(key) === -1) {
                  await setOpenedPanels([...openedPanels, key]);
                }
              });
            }}
          >
            {/* -----------------------------------FORMULARIO DATOS PERSONALES-------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Datos Personales
                </h4>
              }
              key="1"
              className=" site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#e619a3",
                  }}
                >
                  Obligatorio
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={personal}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >

              <Form.Item
                style={{ marginTop: '20px' }}
                label="RUT"
                name={"Rut"}
                validateStatus={validRutTrabajador}
                onChange={(e) => {
                  let valor = e.target.value;

                  if (valor) {
                    let rut = Rut.formateaRut(valor);
                    let rutValidacion = Rut.procesarRut(rut);

                    setValidRutTra(rutValidacion);

                    if (rut !== undefined) {
                      form.setFieldsValue({ Rut: rut });
                    } else {
                      form.setFieldsValue({ Rut: valor });
                    }
                  }
                }}
                rules={[
                  {
                    min: 11,
                    max: 12,
                    required: true,
                    message: "Debe introducir su RUT!",
                    whitespace: false,
                  },
                ]}
                hasFeedback
                help="Ingrese el RUT válido con código verificador"
              >
                <Input
                  name="Rut"
                  className="campos"
                  placeholder="XX.XXX.XXX-X"
                  maxLength={12}
                  onBlur={() => {
                    // console.log(validRutTrabajador);

                    if (validRutTrabajador === "success") {
                      let rut = form.getFieldsValue().Rut;

                      rut = rut.toUpperCase();

                      if (rut !== undefined) {
                        props.validarRutUsuario(rut);
                      }
                    }
                  }}
                  on
                />
              </Form.Item>

              <Form.Item
                label="Cargo del trabajador"
                name={"Cargo"}
                style={{
                  marginTop: "20px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Seleccione el cargo",
                  },
                ]}
              >
                <Select
                  className="campos"
                  name="Cargo"
                  placeholder="Seleccionar cargo"
                  onChange={handleCargosChange}
                >
                  {props.cargosTrabajador.map((value, index) => (
                    <Select.Option key={index} name="Cargo" value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <p style={{ margin: "0px", lineHeight: '16px' }}>
                    Otras funciones
                    <br />
                    del trabajador
                  </p>
                }
                name={"OtrosCargos"}
                style={{
                  marginTop: "20px",
                }}
              >
                <Select
                  className="campos"
                  name="OtrosCargos"
                  placeholder="Seleccionar..."
                  mode='multiple'
                >
                  {props.cargosTrabajador.map((value, index) => (
                    <Select.Option key={index} name="OtrosCargos" value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {/* <div
                style={{
                  textAlign: "right",
                  marginRight: "7%",
                  marginBottom: "15px"
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    minWidth: "100px",
                    marginTop: "10px",
                  }}
                  className="reporte-filtrar-btn"
                  onClick={() => {
                    let rut = form.getFieldsValue().Rut;
                    if(rut !== undefined){
                      props.validarRutUsuario(rut);
                    }
                  }}
                >
                  validar rut existente
                </Button>
              </div> */}

              <Form.Item
                name={"Nombre"}
                label="Nombres:"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir su nombre completo.",
                  },
                  {
                    validator: (_, value) => {

                      const isValid = testNamesRegex.test(value);

                      if (isValid) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject('Ingrese un nombre válido sin símbolos ni números.');
                      }
                    }
                  },
                ]}
              >
                <Input name="Nombre" className="campos" />
              </Form.Item>
              <Form.Item
                name={"Apellido_Paterno"}
                label="Apellido paterno:"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir su apellido",
                  },
                  {
                    validator: (_, value) => {

                      const isValid = testNamesRegex.test(value);

                      if (isValid) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject('Ingrese un apellido válido sin símbolos ni números.');
                      }
                    }
                  },
                ]}

              >
                <Input name="Apellido_Paterno" className="campos" />
              </Form.Item>
              <Form.Item
                name={"Apellido_Materno"}
                label="Apellido materno:"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir su apellido",
                  },
                  {
                    validator: (_, value) => {

                      const isValid = testNamesRegex.test(value);

                      if (isValid) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject('Ingrese un apellido válido sin símbolos ni números.');
                      }
                    }
                  },
                ]}
              >
                <Input name="Apellido_Materno" className="campos" />
              </Form.Item>
              <Form.Item
                name={"Fecha_De_Nacimiento"}
                label="Fecha de nacimiento:"
                // hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Debe introducir su fecha de nacimiento",
                  },
                ]}
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  locale={locale}
                  name="Fecha_De_Nacimiento"
                  className="campos"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Calle"
                name={"Calle"}
                rules={[
                  {
                    required: true,
                    message: "debe ingresar su dirección",
                  },
                ]}
              // hasFeedback
              >
                <Input name="Calle" className="campos" id="warning2" />
              </Form.Item>

              <Form.Item
                name={"Numero_Calle"}
                label="Número calle"
              // hasFeedback
              >
                <Input

                  name="Numero_Calle" type="number" className="campos" />
              </Form.Item>

              <Form.Item
                name={"Tipo_De_Vivienda"}
                rules={[
                  {
                    required: true,
                    message: "¿Qué tipo de lugar es?",
                  },
                ]}
                label="Lugar"
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  key={"Tipo_De_Vivienda"}
                  value={props.Formulario.Tipo_De_Vivienda}
                  onChange={(e) => {
                    props.cambioValorVivienda(e.target.value);
                  }}
                >
                  <Radio value={"Casa"}>Casa</Radio>
                  <Radio value={"Departamento"}>Departamento</Radio>
                  <Radio value={"Oficina"}>Oficina</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name={"Numero_Departamento"}
                label="N° del Establecimiento"
              >
                <Input
                  name="Numero_Departamento"
                  //disabled={props.departamento}
                  type="number"
                  className="campos"
                />
              </Form.Item>

              <Form.Item
                label="Región"
                name={"Region"}
                rules={[
                  {
                    required: true,
                    message: "¿En que región está?",
                  },
                ]}
              >
                <Select
                  className="campos"
                  name="Region"
                  placeholder="Seleccionar región"
                  onSelect={(value) => {
                    form.resetFields(["Ciudad", "Comuna"]);
                    props.buscarProvincias(value);
                  }}
                >
                  {props.regiones.map((value, index) => (
                    <Select.Option name="Region" key={index} value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Provincia"
                name={"Ciudad"}
                rules={[
                  {
                    required: true,
                    message: "¿En qué ciudad está?",
                  },
                ]}
              >
                <Select
                  className="campos"
                  name="Ciudad"
                  placeholder="Seleccionar ciudad"
                  onSelect={(value) => {
                    form.resetFields(["Comuna"]);
                    props.buscarComuna(value);
                  }}
                >
                  {props.ciudadesSeleccionadas.map((value, index) => (
                    <Select.Option name="Ciudad" key={index} value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Comuna"
                name={"Comuna"}
                rules={[
                  {
                    required: true,
                    message: "¿Cuál es su comuna?",
                  },
                ]}
              >
                <Select className="campos" name="Comuna" placeholder="Comuna">
                  {props.comunasSeleccionadas.map((value, index) => (
                    <Select.Option name="Comuna" key={index} value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={"Correo"}
                label="Correo electrónico"
                rules={[
                  {
                    type: "email",
                    message: "Se debe ingresar un correo válido",
                  },
                ]}
              >
                <Input name="Correo" className="campos" />
              </Form.Item>
              <Form.Item
                name={"Telefono"}
                label="Teléfono"
                rules={[
                  {
                    validator: (_, value) => {

                      const { isValid, errorMsg } = isTelefonoValid(value);

                      if (isValid) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(errorMsg);
                      }
                    }
                  },
                ]}
                normalize={((value) => {
                  let noSpaceEntry = value.replace(/\s/g, ''); //quitando espacios por si acaso
                  return noSpaceEntry;
                })}
              >
                <Input
                  name={"Telefono"}
                  className="campos"
                  placeholder={"+569XXXXXXXX"}
                  maxLength={12}
                />
              </Form.Item>
              <Form.Item
                label="Nacionalidad"
                name={"Nacionalidad"}
                rules={[
                  {
                    required: true,
                    message: "¿Cuál es su nacionalidad?",
                  },
                ]}
              >
                <Select
                  className="campos"
                  name="Nacionalidad"
                  placeholder="Nacionalidad"
                >
                  <Select.Option value="Chilena" key={"Nacionalidad"} name="Nacionalidad">
                    Chilena
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Sexo"
                name={"Sexo"}
                rules={[
                  {
                    required: true,
                    message: "Debe ingresar el sexo del trabajador",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  key={"Sexo"}
                  value={props.Formulario.Sexo}
                >
                  <Radio value={"Masculino"}>Masculino</Radio>
                  <Radio value={"Femenino"}>Femenino</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Régimen de trabajo"
                name={"RegimenDeTrabajo"}
                style={{
                  marginTop: "20px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Seleccione el régimen",
                  },
                ]}
              >
                <Select
                  className="campos"
                  name="RegimenDeTrabajo"
                  placeholder="Seleccionar régimen"
                >
                  {props.regimenes.map((value, index) => (
                    <Select.Option key={index} name="Cargo" value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <p style={{ margin: "0px", lineHeight: '16px' }}>
                    Código base
                    <br />
                    sistema a conectar
                  </p>
                }
                name={"Codigo_Base"}
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input name={"Codigo_Base"} placeholder="Código Base" />
              </Form.Item>
            </Panel>

            {/* -----------------------------------FORMULARIO DATOS DE CONTACTO---------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Datos de Contacto de Emergencia
                </h4>
              }
              key="2"
              className=" site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#061fcb",
                  }}
                >
                  Opcional
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={contacto}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                name={"Contacto_Nombre"}
                label="Nombres:"
                style={{ marginTop: "20px" }}
              >
                <Input name="Contacto_Nombre" className="campos" />
              </Form.Item>

              <Form.Item
                name={"Contacto_Correo"}
                label="Correo electrónico"
                rules={[
                  {
                    type: "email",
                    message: "Se debe ingresar un correo con un formato válido",
                  },
                ]}
              >
                <Input name="Contacto_Correo" className="campos" />
              </Form.Item>
              <Form.Item name={"Contacto_Fono_Fijo"} label="Fono Contacto Fijo">
                <Input name={"Contacto_Fono_Fijo"} className="campos" />
              </Form.Item>
              <Form.Item
                name={"Contacto_Fono_Celular"}
                label="Fono Contacto Celular"
              >
                <Input name={"Contacto_Fono_Celular"} className="campos" />
              </Form.Item>

              {/*-----------------------*CONTACTOS DE EMERGENCIA---------------------------- */}
              {/* <hr className="division" />
              <br /> */}
            </Panel>

            {/* -----------------------------FORMULARIO DATOS FAMILIARES Y LEGALES------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Datos Familiares y Legales
                </h4>
              }
              key="3"
              className=" site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#e619a3",
                  }}
                >
                  Obligatorio
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={familiares}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Estado civil"
                name="Estado_Civil"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir su estado civil",
                  },
                ]}
                style={{ marginTop: "20px" }}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  onChange={(value) => {
                    props.cambiarValores("Estado_Civil", value.target.value);
                  }}
                  initialValues={false}
                >
                  <Radio value={"Soltero/a"}>Soltero/a</Radio>
                  <Radio value={"Casado/a"}>Casado/a</Radio>
                  <Radio value={"Viudo/a"}>Viudo/a</Radio>
                  <Radio value={"Divorciado/a"}>Divorciado/a</Radio>
                </Radio.Group>
              </Form.Item>

              <hr className="division" />
              <br />
              <h3 style={{ textAlign: "center", fontFamily: "MavenPro-Bold" }}>
                Datos Hijos
              </h3>
              <Form.Item
                name="Cantidad_Hijos"
                label="Número de hijos:"
                rules={[
                  {
                    required: true,
                    message: "Se debe ingresar cantidad de hijos",
                  },
                ]}
              >
                <Input name="Cantidad_Hijos" className="campos" />
              </Form.Item>
            </Panel>

            {/* ---------------------------------------------TÍTULOS------------------------------------------------ */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Títulos
                </h4>
              }
              key="4"
              className=" site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#061fcb",
                  }}
                >
                  Opcional
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={titulos_icon}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Títulos"
                style={{ marginTop: "20px" }}
                name="Titulos_Profesionales"
              >
                <Select
                  mode="multiple"
                  className="campos"
                  name="Titulos_Profesionales"
                  placeholder="Selecciona títulos"
                >
                  {props.titulos.map((value, index) => (
                    <Select.Option
                      name="Titulos_Profesionales"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* --------------------------------------------ESPECIALIDADES------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Especialidades
                </h4>
              }
              key="5"
              className=" site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#061fcb",
                  }}
                >
                  Opcional
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={especialidades_icon}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Especialidades"
                style={{ marginTop: "20px" }}
                name="Especialidades"
              >
                <Select
                  mode="multiple"
                  className="campos"
                  name="Especialidades"
                  placeholder="Seleccione especialidades"
                >
                  {props.especialidades.map((value, index) => (
                    <Select.Option
                      name="Especialidades"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* ------------------------------------------- EPPS ------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  E.P.P.
                </h4>
              }
              key="6"
              className=" site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#061fcb",
                  }}
                >
                  Opcional
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={epp}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                name="EPP"
                style={{ marginTop: "20px" }}
                label={
                  <>
                    <Popover
                      title={"Información:"}
                      placement={"left"}
                      content={
                        <div className={"popover-div"}>
                          <p className={"info-text"}>
                            Los E.P.P se pueden haber asignado automáticamente
                            según el <br />
                            cargo del trabajador.
                          </p>
                        </div>
                      }
                    >
                      <InfoCircleTwoTone
                        className={"info-epps-icon"}
                        twoToneColor={"#48b9fd"}
                      />
                    </Popover>
                    <span>E.P.P</span>
                  </>
                }
              >
                <Select
                  mode="multiple"
                  className="campos"
                  name="EPP"
                  placeholder="Seleccione E.P.P."
                >
                  {props.epps.map((value, index) => (
                    <Select.Option name="EPP" key={index} value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* ------------------------------------------- DOCUMENTOS ------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Documentos
                </h4>
              }
              key="7"
              className=" site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#061fcb",
                  }}
                >
                  Opcional
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={contrato}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label={
                  <>
                    <Popover
                      title={"Información:"}
                      placement={"left"}
                      content={
                        <div className={"popover-div"}>
                          <p className={"info-text"}>
                            Los documentos se pueden haber asignado automáticamente
                            según el <br />
                            cargo del trabajador.
                          </p>
                        </div>
                      }
                    >
                      <InfoCircleTwoTone
                        className={"info-epps-icon"}
                        twoToneColor={"#48b9fd"}
                      />
                    </Popover>
                    <span>Documentos</span>
                  </>
                }
                style={{ marginTop: "20px" }}
                name="TiposDeDocumentos"
              >
                <Select
                  mode="multiple"
                  className="campos"
                  name="TiposDeDocumentos"
                  placeholder="Seleccione Documentos requeridos"
                >
                  {props.documentos.map((value, index) => (
                    <Select.Option
                      name="TiposDeDocumentos"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* -----------------------------------CARACTERÍSTICAS DE CONTRATO---------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Características del contrato
                </h4>
              }
              key="8"
              className="site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#e619a3",
                  }}
                >
                  Obligatorio
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={contrato}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                name={"Tipo_Contrato"}
                label="Tipo de contrato"
                style={{ marginTop: "20px" }}
                rules={[
                  {
                    required: true,
                    message: "Debe ingresar el tipo de contrato",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  onChange={(e) => {
                    setTipoFechaContrato(e.target.value);

                    form.setFieldsValue({
                      Contrato_Fecha: null,
                    });
                  }}
                >
                  <Radio value={"Plazo fijo"}>Plazo Fijo</Radio>
                  <Radio value={"Indefinido"}>Indefinido</Radio>
                </Radio.Group>
              </Form.Item>

              {/* //nuevo campo relevo// */}
              <Form.Item
                name={"Relevo"}
                label="Relevo"
                style={{ marginTop: "20px" }}
                rules={[
                  {
                    required: true,
                    message: "Debe seleccionar relevo",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                >
                  <Radio value={true}>Si</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              {/* //nuevo campo relevo// */}
              <Form.Item
                name={"Contrato_Fecha"}
                label="Fecha de contratación"
                rules={[
                  {
                    required: true,
                    message: "Debe ingresar la fecha de contratación",
                  },
                ]}
              >
                {condicionalFechaContrato()}
              </Form.Item>
              <Form.Item
                name={"Costo_Bruto_Diario"}
                label={<p style={{ margin: "0px" }}>Costo Bruto Diario</p>}
                rules={[
                  {
                    required: false,
                    message: "Debe indicar el costo bruto diario",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  className="campos"
                />
              </Form.Item>

              <Form.Item
                name={"Costo_Liquido_Diario"}
                label={<p style={{ margin: "0px" }}>Costo Liquido Diario</p>}
                rules={[
                  {
                    required: false,
                    message: "Debe indicar el costo líquido diario",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  className="campos"
                />
              </Form.Item>
            </Panel>

            {/* -----------------------------------VACACIONES---------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Vacaciones
                </h4>
              }
              key="9"
              className="site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#e619a3",
                  }}
                >
                  Obligatorio
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={vacaciones}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                name={"Requirio_Progresivas"}
                label={
                  <p style={{ margin: "0px" }}>
                    ¿Requirió vacaciones
                    <br />
                    progresivas?
                  </p>
                }
                style={{ marginTop: "20px" }}
                rules={[
                  {
                    required: true,
                    message: "Debe indicar si el trabajador requirió vacaciones progresivas",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  onChange={(e) => {
                    setRequirioProgre(e.target.value);

                    // form.setFieldsValue({
                    //   Contrato_Fecha: null,
                    // })
                  }}
                >
                  <Radio value={true}>Sí</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              {condicionalFechaProgresivas()}

              <Form.Item
                name={"Meses_Empleadores_Anteriores"}
                label={
                  <p style={{ margin: "0px" }}>
                    Meses con empleadores
                    <br />
                    anteriores
                  </p>
                }
                rules={[
                  {
                    required: true,
                    message: "Debe ingresar los meses con empleadores anteriores",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  className="campos"
                />
              </Form.Item>

              <Form.Item
                name={"Regimen_Vacaciones"}
                label="Régimen de vacaciones"
                // rules={[
                //   {
                //     required: true,
                //     message: "Obligatorio",
                //   },
                // ]}
                style={{ marginBottom: "5px" }}
                validateStatus={validateOtro}
                hasFeedback={true}
                rules={[
                  {
                    required: true,
                    message: "Debe seleccionar el régimen de vacaciones.",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  onChange={(e) => {
                    if (e.target.value === -1) {
                      setOtroSelected(true);
                      setValidateOtro("error");
                    } else {
                      setOtroSelected(false);
                      setValidateOtro("success");

                      form.setFieldsValue({
                        otro: undefined,
                      });
                    }

                    // if (form.getFieldsValue(['Regimen_Vacaciones'] !== -1) && form.getFieldsValue(['otro']) !== ) {

                    // }
                  }}
                >
                  <Radio value={15}>15 días</Radio>
                  <Radio value={20}>20 días</Radio>
                  <Radio value={-1}>Otro</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item name={"otro"} label=" " className="item-otro">
                <InputNumber
                  placeholder={"otra cantidad"}
                  className="input-otro"
                  type="number"
                  min={0}
                  disabled={!otroSelected}
                  onChange={async (e) => {
                    if (e) {
                      await setValidateOtro("success");
                    } else {
                      await setValidateOtro("error");
                    }
                  }}
                />
              </Form.Item>
            </Panel>

            {/* -----------------------------------Datos bancarios NUEVO---------------------------------------- */}
            <Panel
            header={
                <h4
                style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                }}
                >
                Datos bancarios
                </h4>
            }
            key="10"
            className="site-collapse-custom-panel"
            extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#061fcb",
                  }}
                >
                  Opcional
                </h4>,
                <img
                key="2"
                src={flecha}
                style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                }}
                alt="icono"
                />,
                <img
                key="3"
                src={bancario}
                style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                }}
                alt="icono"
                />,
            ]}
            >
              <Form.Item
                label="N° de cuenta bancaria"
                name={"Numero_Cuenta_Bancaria"}
                // rules={[
                //   {
                //     required: true,
                //     message: "¿Cuál es su n° de cuenta bancaria?",
                //   },
                //   // {
                //   //   max: 16,
                //   //   message: "Debe tener como máximo 16 caracteres numéricos.",
                //   // },
                // ]}
                //hasFeedback
              >
                <InputNumber
                // <Input
                placeholder="Ej: 123456789"
                style={{ width: "100%" }}
                maxLength={16}
                type="number"
                />
              </Form.Item>

              <Form.Item
                  label="Banco"
                  name={"Banco"}
                  // rules={[
                  // {
                  //   required: true,
                  //   message: "¿Cuál es su banco?",
                  // },
                  // ]}
              >
                <Select
                  placeholder="Seleccionar banco"
                >
                  <Select.Option value="no aplica">No aplica</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Forma de pago "
                name={"Forma_Pago"}
                // rules={[
                // {
                //   required: true,
                //   message: "¿Cuál es su forma de pago?",
                // },
                // ]}
              >
                <Select
                  placeholder="Seleccionar forma de pago"
                >
                  <Select.Option value="no aplica">No aplica</Select.Option>
                </Select>
              </Form.Item>
            </Panel>

            {/* -----------------------------------Datos previsionales NUEVO---------------------------------------- */}
            <Panel
            header={
                <h4
                style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                }}
                >
                Datos previsionales
                </h4>
            }
            key="11"
            className="site-collapse-custom-panel"
            extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#061fcb",
                  }}
                >
                  Opcional
                </h4>,
                <img
                key="2"
                src={flecha}
                style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                }}
                alt="icono"
                />,
                <img
                key="3"
                src={previsional}
                style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                }}
                alt="icono"
                />,
            ]}
            >
              <Form.Item
                label="AFP"
                name={"AFP"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su AFP?",
                },
                ]}
              >
                <Select
                    placeholder="Seleccionar AFP"
                >
                  <Select.Option value="afp">Sin Definir</Select.Option>
                  <Select.Option value="capital">AFP Capital</Select.Option>
                  <Select.Option value="cuprum">AFP Cuprum</Select.Option>
                  <Select.Option value="habitat">AFP Habitat</Select.Option>
                  <Select.Option value="planvital">AFP Planvital</Select.Option>
                  <Select.Option value="provida">AFP Provida</Select.Option>
                  <Select.Option value="modelo">AFP Modelo</Select.Option>
                  <Select.Option value="uno">AFP UNO</Select.Option>
                  <Select.Option value="canaempu">Canempu</Select.Option>
                  <Select.Option value="empart">Empart</Select.Option>
                  <Select.Option value="sss">Servicio Seguro Social</Select.Option>
                  <Select.Option value="triomar">Triomar</Select.Option>
                  <Select.Option value="capremer">Capremer</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Sistema de pensiones"
                name={"Sistema_Pension"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su sistema de pensiones?",
                },
                ]}
              >
                <Select
                    placeholder="Seleccionar sistema de pensiones"
                >
                  <Select.Option value="N">Nuevo</Select.Option>
                  <Select.Option value="C">Antiguo con Isapre</Select.Option>
                  <Select.Option value="T">Antiguo con Fonasa</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Jubilado"
                name={"Jubilado"}
                rules={[
                {
                  //required: true,
                  message: "¿Es usted jubilado?",
                },
                ]}
              >
                <Select
                    placeholder="Seleccionar"
                >
                  <Select.Option value="0">Activo no pensionado</Select.Option>
                  <Select.Option value="1">Pensionado y cotiza</Select.Option>
                  <Select.Option value="2">Pensionado y no cotiza</Select.Option>
                  <Select.Option value="3">{`Activo > 60 o 65 años`}</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Isapre / Fonasa"
                name={"Isapre_Fonasa"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su Isapre / Fonasa?",
                },
                ]}
              >
                <Select
                    placeholder="Seleccionar Isapre/Fonasa"
                >
                  <Select.Option value="isapre">Sin Definir</Select.Option>
                  <Select.Option value="fonasa">FONASA</Select.Option>
                  <Select.Option value="banmedica">ISAPRE Banmedica</Select.Option>
                  <Select.Option value="consalud">ISAPRE Consalud</Select.Option>
                  <Select.Option value="vidatres">ISAPRE Vidatres</Select.Option>
                  <Select.Option value="colmena">ISAPRE Colmena</Select.Option>
                  <Select.Option value="cruzblanca">ISAPRE Cruz-Blanca</Select.Option>
                  <Select.Option value="nuevamasvida">ISAPRE Nueva Mas Vida</Select.Option>
                  <Select.Option value="bancoestado">ISAPRE Banco Estado</Select.Option>
                  <Select.Option value="fusat">ISAPRE Fusat</Select.Option>
                  <Select.Option value="chuquicamata">ISAPRE Chuquicamata</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Isapre Monetización"
                name={"Isapre_Monetizacion"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su Isapre Monetización?",
                },
                ]}
              >
                <Select
                    placeholder="Seleccionar monetización"
                >
                  <Select.Option value="%">7%</Select.Option>
                  <Select.Option value="U">U.F.</Select.Option>
                  <Select.Option value="P">Pesos</Select.Option>
                  <Select.Option value="F">7% + UF</Select.Option>
                  <Select.Option value="S">UF + Pesos</Select.Option>
                  <Select.Option value="Z">7% + UF + Pesos</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Isapre UF"
                name={"Isapre_UF"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su Isapre UF?",
                },
                ]}
              >
                <InputNumber
                    placeholder="Ej: 25"
                    style={{ width: "100%" }}
                    type="number"
                    min={0}
                    // className="campos"
                    // max={20}
                    // maxLength={20}
                />
              </Form.Item>

              <Form.Item
                label="Isapre peso"
                name={"Isapre_Peso"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su Isapre peso?",
                },
                ]}
              >
                <InputNumber
                    placeholder="Ej: 100"
                    style={{ width: "100%" }}
                    type="number"
                    min={0}
                    // className="campos"
                    // max={20}
                    // maxLength={20}
                />
              </Form.Item>

              <Form.Item
                label="Isapre cotización"
                name={"Isapre_Cotizacion"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su Isapre cotización",
                },
                ]}
              >
                <InputNumber
                    placeholder="Ej: 100"
                    style={{ width: "100%" }}
                    type="number"
                    min={0}
                    //className="campos"
                    // max={20}
                    // maxLength={20}
                />
              </Form.Item>
                
            </Panel>


            {/* -----------------------------------Datos Otros Estudios NUEVO---------------------------------------- */}
            <Panel
            header={
                <h4
                style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                }}
                >
                Datos otros estudios
                </h4>
            }
            key="12"
            className="site-collapse-custom-panel"
            extra={[
              <h4
                key="1"
                style={{
                  position: "absolute",
                  top: 5,
                  right: 55,
                  color: "#061fcb",
                }}
              >
                Opcional
              </h4>,
              <img
              key="2"
              src={flecha}
              style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
              }}
              alt="icono"
              />,
              <img
              key="3"
              src={estudios}
              style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
              }}
              alt="icono"
              />,
            ]}
            >
              <Form.Item
                label="Nivel de estudios"
                name={"Nivel_Estudio"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su nivel de estudio?",
                },
                ]}
              >
                <Select
                    placeholder="Seleccionar nivel de estudios"
                >
                  <Select.Option value="0">Ninguno</Select.Option>
                  <Select.Option value="1">Básico</Select.Option>
                  <Select.Option value="2">Medio</Select.Option>
                  <Select.Option value="3">Técnico</Select.Option>
                  <Select.Option value="4">Universitario</Select.Option>
                  <Select.Option value="5">Postgraduado</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Licencia conducir"
                name={"Licencia_Conducir"}
                rules={[
                {
                  //required: true,
                  message: "¿Tiene licencia de conducir?",
                },
                ]}
              >
                <Radio.Group>
                  <Radio value={true}>Sí</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              
              <Form.Item
                label="Tipo"
                name={"Tipo_Licencia_Conducir"}
                rules={[
                {
                  //required: true,
                  message: "¿De que tipo es su licencia de conducir?",
                },
                ]}
              >
                <Select
                    placeholder="Seleccionar tipo de licencia"
                >
                  <Select.Option value="N">Sin licencia</Select.Option>
                  <Select.Option value="A1">A1</Select.Option>
                  <Select.Option value="A2">A2</Select.Option>
                  <Select.Option value="A3">A3</Select.Option>
                  <Select.Option value="A4">A4</Select.Option>
                  <Select.Option value="B">B</Select.Option>
                  <Select.Option value="C">C</Select.Option>
                  <Select.Option value="D">D</Select.Option>
                  <Select.Option value="E">E</Select.Option>
                  <Select.Option value="F">F</Select.Option>
                </Select>
              </Form.Item>
            </Panel>
          </Collapse>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>

      </Row>
    </Form>
  );
};

export default FormularioTrabajadorNuevo;
