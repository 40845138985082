import React, { useEffect, useState } from "react";
import SubirFoto from "../../components/subirFoto/SubirFoto";
import logoGenerico from "../../img/logos/logoGenerico.svg";
import Rut from "../../funcionesGenericas/rut";
import Rut_Representante from "../../funcionesGenericas/rut";
import personal from "../../img/icons/avatar.svg";
import flecha from "../../img/icons/flecha.svg";
import './FormularioEmpresas.css'
import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Select,
  Radio,
  Collapse,
  DatePicker
} from "antd";
import Swal from "sweetalert2";
import ValidadorRut from "validar-rut";
import locale from 'antd/lib/date-picker/locale/es_ES';
import moment from 'moment';
import {
  convertirFechaString
} from '../../funcionesGenericas/fechas';
const { TextArea } = Input;

const { Panel } = Collapse;

const FormularioEmpresasEditar = (props) => {
  const [form] = Form.useForm();
  const [validRutRepresentante, setValidRutRep] = useState('');
  const [validRutContacto, setValidRutCon] = useState('');

  const ObtenerImagen = (imagenUrl) => {
    props.obtenerImagen(imagenUrl);
    props.TomarValoresEditar("Imagen", imagenUrl);
  };

  const onFinish = (values) => {
    values.Imagen = props.Formulario.Imagen;

    // values.Fecha_Inicio_Sistema_Unix = momentToUnixFechaInicio(values.Fecha_Inicio_Sistema);
    // values.Fecha_Inicio_Sistema = momentToStringFechaInicio(values.Fecha_Inicio_Sistema, databaseFormat);

    values.Fecha_Inicio_Sistema_Unix = convertirFechaString(values.Fecha_Inicio_Sistema, 1).fechaUNIX;
    values.Fecha_Inicio_Sistema = convertirFechaString(values.Fecha_Inicio_Sistema, 1).fechaString;

    props.EditarEmpresa(values);
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  let aux = props.Formulario._id;

  useEffect((aux) => {

    let {

      Calle,
      Ciudad,
      Codigo_Postal,
      Codigo_Actividad_Economica,
      Comuna,
      Nombre,
      Descripcion,
      Razon_Social,
      Rut,
      Email,
      Numero_Calle,
      Region,
      Giro,
      Telefono,
      Tipo_De_Empresa,
      Tipo_De_Vivienda,
      Nombres_Representante,
      Apellido_Paterno_Representante,
      Apellido_Materno_Representante,
      Rut_Representante,
      imagen,
      Empresa_Matriz,
      Vigencia,
      Contacto_Comercial_Rut,
      Contacto_Comercial_Nombres,
      Contacto_Comercial_Apellido_Paterno,
      Contacto_Comercial_Apellido_Materno,
      Contacto_Comercial_Telefono,
      Contacto_Comercial_Email,
      Numero_Departamento,
      Fecha_Inicio_Sistema,
      Fecha_Inicio_Sistema_Unix,

    } = props.Formulario;

    const idDelTipoEmpresa = Tipo_De_Empresa;

    // if (props.editarCerrado) {

    //   form.setFieldsValue({
    //     Region,
    //     Ciudad,
    //     Comuna,
    //     Rut_Representante,
    //     Contacto_Comercial_Rut,
    //   })

    //   props.cambiarEditarCerrado();
    // }
    let fechaInicioSistema;

    if (!Fecha_Inicio_Sistema) {
      fechaInicioSistema = null

    } else if (Fecha_Inicio_Sistema) {

      // fechaInicioSistema = moment(Fecha_Inicio_Sistema);
      let fechaSistema = convertirFechaString(moment(Fecha_Inicio_Sistema), 1).fechaString
      fechaInicioSistema = moment.utc(fechaSistema);

    } else {

      // fechaInicioSistema = moment.unix(Fecha_Inicio_Sistema_Unix);
      let fechaSistema = convertirFechaString(moment.unix(Fecha_Inicio_Sistema_Unix), 1).fechaString;
      fechaInicioSistema = moment.utc(fechaSistema);

    }

    form.setFieldsValue({
      Calle,
      Giro,
      Codigo_Postal,
      Codigo_Actividad_Economica: Codigo_Actividad_Economica,
      Nombre,
      Descripcion,
      Razon_Social,
      Rut,
      Email,
      Numero_Calle,
      Telefono,
      Tipo_De_Empresa: idDelTipoEmpresa,
      Tipo_De_Vivienda,
      Nombres_Representante,
      Apellido_Paterno_Representante,
      Apellido_Materno_Representante,
      imagen,
      Empresa_Matriz,
      Vigencia,
      Contacto_Comercial_Nombres,
      Contacto_Comercial_Apellido_Paterno,
      Contacto_Comercial_Apellido_Materno,
      Contacto_Comercial_Telefono,
      Contacto_Comercial_Email,
      Numero_Departamento,
      Fecha_Inicio_Sistema: fechaInicioSistema,

      Region,
      Ciudad,
      Comuna,
      Rut_Representante,
      Contacto_Comercial_Rut,
    });

  }, [aux]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={true}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={logoGenerico} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Editar empresa"
          />
        </Col>
        <Col span={8}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Row>
              <Col lg={10} xl={24}>
                <SubirFoto
                  avatar={props.Formulario.Imagen}
                  ObtenerImagen={ObtenerImagen}
                />
              </Col>
              <Col lg={22} xl={24}>
                <p>
                  Te recomendamos que la fotografía no exceda los 5 MB de peso
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col offset={1} span={15}>
          <Collapse
            bordered={false}
            defaultActiveKey={["0"]}
            className="formulario-trabajador site-collapse-custom-collapse "
          >
            {/* <Card className="card-perfil" style={{ width: "100%" }}> */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  SOBRE LA EMPRESA
                </h4>
              }
              key="1"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={personal}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <br />
              {/* <Form.Item>
              <p>SOBRE LA EMPRESA:</p>
            </Form.Item> */}
              <Form.Item
                name="Rut"
                label="RUT"
                rules={[
                  {
                    min: 11,
                    max: 12,
                    required: true,
                    message: "Debe introducir el RUT empresarial",
                  },
                ]}
                onChange={(e) => {
                  let valor = e.target.value;
                  let rut = Rut.formateaRut(valor);

                  //let validacion = ValidadorRut.validar(rut);
                  // if (validacion === true) {
                  //   props.TomarValoresEditar("validacionRut", "success");
                  // } else {
                  //   props.TomarValoresEditar("validacionRut", "error");
                  // }

                  if (rut !== undefined) {
                    props.TomarValoresEditar("Rut", rut);
                  } else {
                    props.TomarValoresEditar("Rut", valor);
                  }
                }}
                help="Ingresa el RUT empresarial"
              >
                <Input
                  name="Rut"
                  className="campos"
                  placeholder="xx.xxx.xxx-x"
                />
              </Form.Item>
              <Form.Item
                name={"Razon_Social"}
                label="Razon Social"
                rules={[
                  {
                    required: true,
                    message: "La razón social de la empresa es requerida",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Razon_Social", valor);
                  }}
                  name="Razon_Social"
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                name={"Calle"}
                label="Calle"
                rules={[
                  {
                    required: true,
                    message: "La dirección es obligatoria",
                    min: 2,
                    max: 50,
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Calle", valor);
                  }}
                  name="Calle"
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                name={"Numero_Calle"}
                label="Número"
                rules={[
                  {
                    required: true,
                    message: "Debe ingresar número de dirección",
                  },
                ]}
                hasFeedback
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Numero_Calle", valor);
                  }}
                  name="Numero_Calle"
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                label="Tipo de Establecimiento"
                name={"Tipo_De_Vivienda"}
                rules={[
                  {
                    required: true,
                    message: "¿Qué tipo de lugar es?",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  key={"Tipo_De_Vivienda"}
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Tipo_De_Vivienda", valor);
                  }}
                  value={props.Formulario.Tipo_De_Vivienda}
                >
                  <Radio value={"Departamento"}>Departamento</Radio>
                  <Radio value={"Oficina"}>Oficina</Radio>
                  <Radio value={"Local"}>Local</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name={"Numero_Departamento"}
                label="N° del Establecimiento"
                value={props.Formulario.Tipo_De_Vivienda}
              >
                <Input
                  name="Numero_Departamento"
                  // disabled={
                  //   props.Formulario.Tipo_De_Vivienda === "Departamento"
                  //     ? false
                  //     : true
                  // }
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                name={"Codigo_Postal"}
                label="Código Postal"
                rules={[
                  {
                    required: true,
                    message: "Código postal obligatorio",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Codigo_Postal", valor);
                  }}
                  name="Codigo_Postal"
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                label="Región"
                name={"Region"}
                rules={[
                  {
                    required: true,
                    message: "¿En que región está?",
                  },
                ]}
              >
                <Select
                  onSelect={(value) => {
                    form.resetFields(['Ciudad', 'Comuna']);
                    props.buscarProvincias(value);
                    props.TomarValoresEditar("Region", value);
                  }}
                  className="campos"
                  name="Region"
                  placeholder="Selecciona región"
                >
                  {props.regiones.map((value, index) => (
                    <Select.Option name="Region" key={index} value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Provincia"
                name={"Ciudad"}
                rules={[
                  {
                    required: true,
                    message: "¿En qué ciudad o provincia está?",
                  },
                ]}
              >
                <Select
                  onSelect={(value) => {
                    form.resetFields(['Comuna']);
                    props.buscarComuna(value);
                    props.TomarValoresEditar("Ciudad", value);
                  }}
                  className="campos"
                  name="Ciudad"
                  placeholder="Selecciona provincia"
                >
                  {props.ciudadesSeleccionadas.map((value, index) => (
                    <Select.Option
                      name="Ciudad"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Comuna"
                name={"Comuna"}
                rules={[
                  {
                    required: true,
                    message: "¿En qué comuna está?",
                  },
                ]}
              >
                <Select
                  onSelect={(value) => {
                    props.TomarValoresEditar("Comuna", value);
                  }}
                  className="campos"
                  name="Comuna"
                  placeholder="Selecciona comuna"
                >
                  {props.comunasSeleccionadas.map((value, index) => (
                    <Select.Option
                      name="Comuna"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={"Email"}
                label="Email Contacto Empresa"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Se debe ingresar un correo",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Email", valor);
                  }}
                  name="Email"
                  className="campos"
                />
              </Form.Item>

              <Form.Item
                label="Telefono"
                name="Telefono"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir teléfono de la empresa",
                  },
                ]}
                hasFeedback
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Telefono", valor);
                  }}
                  name="Telefono"
                  className="campos"
                  placeholder="+569XXXXXXXX"
                />
              </Form.Item>

              <Form.Item
                label="Fecha Inicio en Sistema"
                name="Fecha_Inicio_Sistema"
                rules={[
                  {
                    required: true,
                    message: "La fecha de inicio de sistema ",
                  },
                ]}
              >
                <DatePicker
                  name="Fecha_Inicio_Sistema"
                  locale={locale}
                  format={'DD-MM-YYYY'}
                  className="campos"
                />
              </Form.Item>
            </Panel>
            {/* </Card> */}

            {/* <Card
            className="card-perfil"
            style={{ width: "100%", marginTop: "20px" }}
          > */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  ACTIVIDAD DE LA EMPRESA
                </h4>
              }
              key="2"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={personal}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <br />
              {/* <Form.Item>
              <p>ACTIVIDAD DE LA EMPRESA:</p>
            </Form.Item> */}
              <Form.Item
                label="Giro"
                name={"Giro"}
                rules={[
                  {
                    required: true,
                    message: "Debes introducir el giro",
                  },
                ]}
              >
                <TextArea
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Giro", valor);
                  }}
                  rows={4}
                  name="Giro"
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                label="Codigo de actividad"
                name={"Codigo_Actividad_Economica"}
                rules={[
                  {
                    required: true,
                    message: "Debe introducir el código de actividad",
                  },
                ]}
              >

                <Select
                  mode="multiple"
                  //allowClear
                  className='codigos-select'
                  placeholder="Escriba para buscar códigos de actividad"
                  filterOption={(input, option) => {
                    //console.log(option.props.children);
                    let textoBusqueda = `${option.props.children[0]}${option.props.children[1]}${option.props.children[2]}`;

                    let respuesta = textoBusqueda.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    //console.log(respuesta);
                    return respuesta;
                  }}
                  onChange={(value) => {
                    console.log(value);

                    props.TomarValoresEditar(
                      "Codigo_Actividad_Economica",
                      value
                    );
                  }}
                >
                  {props.codigosActividadEconomica.map((value, index) => (
                    <Select.Option
                      key={index}
                      name="Codigo_Actividad_Economica"
                      value={value._id}
                      style={{ fontSize: '10px' }}
                    >
                      {value.Descripcion} || {value.Codigo_SII}
                    </Select.Option>
                  ))}
                </Select>


                {/* <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar(
                      "Codigo_Actividad_Economica",
                      valor
                    );
                  }}
                  name="Codigo_Actividad_Economica"
                  className="campos"
                  placeholder="Buscar Codigo De Actividad Economica"
                /> */}
              </Form.Item>
              <Form.Item
                label="Tipo de empresa"
                name={"Tipo_De_Empresa"}
                rules={[
                  {
                    required: true,
                    message: "¿Qué tipo de empresa es?",
                  },
                ]}
              >
                <Select
                  onSelect={(value) => {
                    let valor = {
                      _id: value,
                    };
                    props.TomarValoresEditar("Tipo_De_Empresa", valor);
                  }}
                  className="campos"
                  name="Tipo_De_Empresa"
                  placeholder="Selecciona tipo de empresa"
                >
                  {props.TipoEmpresa.map((value, index) => (
                    <Select.Option
                      name="Tipo_De_Empresa"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>
            {/* </Card> */}

            {/* <Card
            className="card-perfil"
            style={{ width: "100%", marginTop: "20px" }}
          > */}

            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  DATOS DE REPRESENTANTE LEGAL
                </h4>
              }
              key="3"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={personal}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <br />
              {/* <Form.Item>
              <p>DATOS DE REPRESENTANTE LEGAL:</p>
            </Form.Item> */}
              <Form.Item
                name={"Rut_Representante"}
                label="Rut"
                validateStatus={validRutRepresentante}
                onChange={(e) => {

                  let valor = e.target.value;

                  if (valor) {

                    let rut = Rut_Representante.formateaRut(valor);
                    let rutValidacion = Rut_Representante.procesarRut(rut)

                    setValidRutRep(rutValidacion);

                    if (rut !== undefined) {
                      form.setFieldsValue({ Rut_Representante: rut });
                    } else {
                      form.setFieldsValue({ Rut_Representante: valor });
                    }
                  }
                }}
                rules={[
                  {
                    min: 11,
                    max: 12,
                    required: true,
                    message: "Debe introducir su RUT!",
                  },
                ]}
                hasFeedback
                help="Ingrese el RUT válido con código verificador"
              >
                <Input
                  name="Rut_Representante"
                  className="campos"
                  placeholder="xx.xxx.xxx-x"
                  maxLength={12}
                />
              </Form.Item>
              <Form.Item
                name={"Nombres_Representante"}
                label="Nombres:"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir las nombres del representante",
                    min: 2,
                    max: 20,
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Nombres_Representante", valor);
                  }}
                  name="Nombres_Representante"
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                name={"Apellido_Paterno_Representante"}
                label="Apellido Paterno:"
                rules={[
                  {
                    required: true,
                    message:
                      "Debe introducir apellido paterno del representante",
                    min: 2,
                    max: 30,
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar(
                      "Apellido_Paterno_Representante",
                      valor
                    );
                  }}
                  name="Apellido_Paterno_Representante"
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                name={"Apellido_Materno_Representante"}
                label="Apellido Materno:"
                rules={[
                  {
                    required: true,
                    message:
                      "Debe introducir apellido materno del representante",
                    min: 2,
                    max: 30,
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar(
                      "Apellido_Materno_Representante",
                      valor
                    );
                  }}
                  name="Apellido_Materno_Representante"
                  className="campos"
                />
              </Form.Item>
            </Panel>
            {/* </Card> */}

            {/* <Card
            className="card-perfil"
            style={{ width: "100%", marginTop: "20px" }}
          > */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  CONTACTO COMERCIAL DEL SISTEMA PEOPLE MANAGEMENT
                </h4>
              }
              key="4"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={personal}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <br />
              {/* <Form.Item>
              <p>CONTACTO COMERCIAL DEL SISTEMA PEOPLE MANAGEMENT:</p>
            </Form.Item> */}
              <Form.Item
                name={"Contacto_Comercial_Rut"}
                label="RUT"
                validateStatus={validRutContacto}
                onChange={(e) => {

                  let valor = e.target.value;

                  if (valor) {

                    let rut = Rut_Representante.formateaRut(valor);
                    let rutValidacion = Rut_Representante.procesarRut(rut)

                    setValidRutCon(rutValidacion);

                    if (rut !== undefined) {
                      form.setFieldsValue({ Contacto_Comercial_Rut: rut });
                    } else {
                      form.setFieldsValue({ Contacto_Comercial_Rut: valor });
                    }
                  }
                }}
                rules={[
                  {
                    min: 11,
                    max: 12,
                    required: true,
                    message: "Debe introducir su RUT!",
                  },
                ]}
                hasFeedback
                help="Ingrese el RUT válido con código verificador"
              >
                <Input
                  // onChange={(e) => {
                  //   let valor = e.target.value;
                  //   let rut = Rut_Representante.formateaRut(valor);

                  //   let validacion = ValidadorRut.validar(rut);
                  //   if (validacion === true) {
                  //     props.TomarValoresEditar("validacionRut", "success");
                  //   } else {
                  //     props.TomarValoresEditar("validacionRut", "error");
                  //   }

                  //   if (rut !== undefined) {
                  //     props.TomarValoresEditar("Contacto_Comercial_Rut", rut);
                  //   } else {
                  //     props.TomarValoresEditar("Contacto_Comercial_Rut", valor);
                  //   }
                  // }}
                  name="Contacto_Comercial_Rut"
                  className="campos"
                  placeholder="xx.xxx.xxx-x"
                  maxLength={12}
                />
              </Form.Item>
              <Form.Item
                name={"Contacto_Comercial_Nombres"}
                label="Nombres:"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir las nombres del contacto comercial",
                    min: 2,
                    max: 20,
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar(
                      "Contacto_Comercial_Nombres",
                      valor
                    );
                  }}
                  name="Contacto_Comercial_Nombres"
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                name={"Contacto_Comercial_Apellido_Paterno"}
                label="Apellido Paterno:"
                rules={[
                  {
                    required: true,
                    message:
                      "Debe introducir apellido paterno del contacto comercial",
                    min: 2,
                    max: 30,
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar(
                      "Contacto_Comercial_Apellido_Paterno",
                      valor
                    );
                  }}
                  name="Contacto_Comercial_Apellido_Paterno"
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                name={"Contacto_Comercial_Apellido_Materno"}
                label="Apellido Materno:"
                rules={[
                  {
                    required: true,
                    message:
                      "Debe introducir apellido materno del contacto comercial",
                    min: 2,
                    max: 30,
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar(
                      "Contacto_Comercial_Apellido_Materno",
                      valor
                    );
                  }}
                  name="Contacto_Comercial_Apellido_Materno"
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                name={"Contacto_Comercial_Email"}
                label="Email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Se debe ingresar un correo",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Contacto_Comercial_Email", valor);
                  }}
                  name="Contacto_Comercial_Email"
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                label="Teléfono"
                name="Contacto_Comercial_Telefono"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir teléfono del contacto comercial",
                  },
                ]}
                hasFeedback
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar(
                      "Contacto_Comercial_Telefono",
                      valor
                    );
                  }}
                  name="Contacto_Comercial_Telefono"
                  className="campos"
                  placeholder="+569XXXXXXXX"
                />
              </Form.Item>
              <Form.Item
                name="Vigencia"
                label="Vigencia"
                style={{ textAlign: "left" }}
              >
                <Switch
                  checked={props.Formulario.Vigencia}
                  onChange={() => {
                    let nuevoValor =
                      props.Formulario.Vigencia === true ? false : true;
                    props.TomarValoresEditar("Vigencia", nuevoValor);
                  }}
                  className="switch"
                />
              </Form.Item>
            </Panel>
            {/* </Card> */}
          </Collapse>
        </Col>
        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Editar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormularioEmpresasEditar;
