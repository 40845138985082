import {
  ERROR,
  INGRESAR_TITULO,
  LOADING,
  MOSTRAR_MODAL_TITULO,
  OCULTAR_MODAL_TITULO,
  OBTENER_TITULOS_MANTENEDOR,
  ELIMINAR_TITULO_MANTENEDOR,
  OBTENER_TITULO_MANTENEDOR,
  EDITAR_TITULO_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OCULTAR_MODAL_TITULO_EDITAR,
} from "../types/MantenedorEPPTypes";
import API from "../Api/api";

import Swal from "sweetalert2";

export const CrearEPP = (EPPObj) => async (dispatch) => {

  dispatch({ type: LOADING });

  try {
    const url = `${API}/Epp`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(EPPObj),
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        Swal.fire({
          title: "Bien",
          text: "Se ingresó el E.P.P. correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",

        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: INGRESAR_TITULO,
          payload: "Se ingresó el usuario correctamente",
        });

        break;

      default:

        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {

    console.error(error);

    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerEpps = () => async (dispatch) => {

  dispatch({ type: LOADING });

  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {

      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/Epp?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:

          data = await response.json();

          const titulos = data.data;
          dispatch({ type: OBTENER_TITULOS_MANTENEDOR, payload: titulos });
          break;
        case 204:

          dispatch({ type: OBTENER_TITULOS_MANTENEDOR, payload: [] });
          break;

        default:
          data = await response.json();
          const error = data.err.message.toString();

          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });

          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {

    console.error(error);

    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerEPP = (idEPP) => async (dispatch) => {

  dispatch({ type: LOADING });

  try {
    const url = `${API}/Epp/${idEPP}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const epp = data.data;
        dispatch({ type: OBTENER_TITULO_MANTENEDOR, payload: epp });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {

    console.error(error)
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarEPP = (idEPP) => async (dispatch) => {

  dispatch({ type: LOADING });

  try {
    const url = `${API}/Epp/${idEPP}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        Swal.fire({
          title: "Bien",
          text: "El E.P.P. fue eliminado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: ELIMINAR_TITULO_MANTENEDOR });
        break;

      default:
        data = await response.json();
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {

    console.error(error)
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarEPP = (objEPP) => async (dispatch) => {

  dispatch({ type: LOADING });

  console.log(objEPP);

  try {
    const url = `${API}/Epp/${objEPP._id}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },

      body: JSON.stringify(objEPP),
    });

    const status = response.status;
    let data;
    console.log(response);

    switch (status) {
      case 200:
      case 201:

        data = await response.json();
        Swal.fire({
          title: "Bien",
          text: "El E.P.P. fue actualizado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: EDITAR_TITULO_MANTENEDOR });
        break;

      default:

        data = await response.json();
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {

    console.error(error);

    dispatch({ type: ERROR, payload: error });
  }
};

export const TomarValoresEditar = (nombreCampo, valor) => (dispatch) => {
  let objeto = {
    nombreCampo,
    valor,
  };
  dispatch({ type: TOMAR_VALORES_EDITAR, payload: objeto });
};

export const MostrarModalTitulo = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_TITULO });
};

export const OcultarModalTitulo = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_TITULO });
};

export const OcultarModalTituloEditar = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_TITULO_EDITAR });
};
