/*
//  isTelefonoValid retorna un objeto con dos valores: 
//  isValid (boolean si es o no válido el teléfono) 
//  y errMsg (null cuando el teléfono es válido, String con mensaje de error cuando no lo sea).
//
//  Incluye validación de formato (+569), de largo (12 carácteres), y que sólo puede contener signo + y números.
//
// Recordar que en Form de antd se debe utilizar en validator, dentro del atributo rules del Form.Item
*/
const isTelefonoValid = (value) => {

    if (!value) return { isValid: true, errorMsg: null };

    let entry = value.replace(/\s/g, '') //quitando espacios por si acaso

    const formato = '+569';

    const formatoEntry = entry.substring(0, 4);
    const numerosEntry = entry.substring(1);

    var regExp = /^\d+$/g; //para chequear que no contenga nada más que números

    if (!regExp.test(numerosEntry)) {
        return { isValid: false, errorMsg: 'El teléfono sólo admite números.' };

    } else if (formato !== formatoEntry) {
        return { isValid: false, errorMsg: 'El teléfono debe comenzar con formato +569.' };

    } else if (entry.length !== 12) {
        return { isValid: false, errorMsg: 'El teléfono debe ser de 12 carácteres.' };

    } else {
        return { isValid: true, errorMsg: null };
    }
}

module.exports = {
    isTelefonoValid,
}