import {
  BUSCAR_TRABAJADORES_LICENCIAS,
  ERROR_LICENCIAS,
  INSERTAR_DATOS_FORMULARIO_LICENCIAS,
  INSERTAR_FECHAS_FORM_LICENCIAS,
  INSERTAR_TRABAJADOR_FORM_LICENCIAS,
  LOADING_LICENCIAS,
  ENVIAR_CORREO,
  LOADING_LICENCIAS_FALSE,
  BUSCAR_CONTRATOS_TRABAJADOR,
} from "../types/LicenciasType";

import Swal from "sweetalert2";
import moment from "moment";
import API from "../Api/api";

import { convertirFechaString } from "../../funcionesGenericas/fechas";

// export const insertarDatos = (
//   tipoLicencia,
//   fechaTerminoOperacion,
//   archivoSubir,
//   cargo
// ) => async (dispatch, getState) => {
//   dispatch({
//     type: LOADING_LICENCIAS,
//   });

//   try {
//     const store = getState().LicenciaReducer;
//     const {
//       form: { Fechas, Trabajador, Correo: envioCorreo },
//     } = store;

//     //declaro fecha de inicio y fecha de fin

//     let fechaInicioLicencia = moment(Fechas[0]);
//     let fechaFinLicencia = moment(Fechas[1]);

//     let objLicencia = {
//       Trabajador: Trabajador,
//       Cargo: cargo,
//       Termino: fechaTerminoOperacion,
//       Fecha_Inicio: Fechas[0],
//       Fecha_Fin: Fechas[1],
//       Tipo: tipoLicencia,
//       Archivo: archivoSubir,
//       CantidadDiasDescanso: 0,
//     };

//     const urlLicencia = `${API}/Licencia`;
//     const requestLicencia = await fetch(urlLicencia, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//       body: JSON.stringify(objLicencia),
//     });

//     const responseLicencia = await requestLicencia.json();
//     const statusLicencia = await requestLicencia.status;

//     switch (statusLicencia) {
//       case 201:
//         let idRegistroLicencia = responseLicencia.data._id;

//         console.log(idRegistroLicencia);

//         let cantidadDias =
//           fechaFinLicencia.diff(fechaInicioLicencia, "days") + 1;

//         let arregloDeDias = [];

//         for (let index = 0; index < cantidadDias; index++) {
//           let fecha = fechaInicioLicencia;
//           fecha = fecha.add(index === 0 ? 0 : 1, "day");
//           const aux = {
//             Fecha: new Date(fecha),
//             IdTrabajador: Trabajador,
//             TipoRegistroBitacoraTrabajador: "5f3eebf46dd7cc57f0d3a25d",

//             InicioTrabajo: index === 0 ? true : false,
//             FinTrabajo: index === cantidadDias - 1 ? true : false,
//             Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,
//             Licencia: idRegistroLicencia,
//           };

//           arregloDeDias.push(aux);
//         }

//         let objetoEnviar = {
//           arregloDeDias,
//           fechaInicio: Fechas[0],
//           fechaFin: Fechas[1],
//         };

//         const url = `${API}/IngresoDeDias/${Trabajador}`;
//         const request = await fetch(url, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//           },
//           body: JSON.stringify(objetoEnviar),
//         });

//         const response = await request.json();
//         const status = await request.status;

//         switch (status) {
//           case 201:

//             ////////////////////////////////////////////////////////////////////////////////
//             if (envioCorreo) {
//               const url = `${API}/Enviar/Correo/Licencia/${idRegistroLicencia}`;
//               await fetch(url, {
//                 method: "POST",
//                 headers: {
//                   "Content-Type": "application/json",
//                   Accept: "application/json",
//                 },
//               })
//             }
//             ////////////////////////////////////////////////////////////////////////////////

//             Swal.fire({
//               title: "Bien",
//               text: "Se ingresaron exitosamente los días de licencia",
//               icon: "success",
//               confirmButtonColor: "#E60AA3",
//               confirmButtonText: "Continuar",
//             });

//             dispatch({
//               type: INSERTAR_DATOS_FORMULARIO_LICENCIAS,
//               payload: response,
//             });

//             break;
//           case 400:
//             Swal.fire({
//               title: "Error",
//               text: response.err.message,
//               icon: "error",
//             });
//             break;
//           case 500:
//             Swal.fire({
//               title: "Error",
//               text: response.err.message,
//               icon: "error",
//             });
//             break;
//           default:
//             Swal.fire({
//               title: "Error",
//               text:
//                 "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
//               icon: "error",
//             });
//             break;
//         }

//         break;
//       case 400:
//         Swal.fire({
//           title: "Error",
//           text: responseLicencia.err.message,
//           icon: "error",
//         });
//         break;
//       case 500:
//         console.log(responseLicencia);
//         Swal.fire({
//           title: "Error",
//           text: responseLicencia.err.message,
//           icon: "error",
//         });
//         break;
//       default:
//         Swal.fire({
//           title: "Error",
//           text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
//           icon: "error",
//         });
//         break;
//     }
//   } catch (error) {
//     console.log("se callo");
//     console.log(error);
//     dispatch({
//       type: ERROR_LICENCIAS,
//       payload: error.message,
//     });
//   }
// };

export const insertarDatosAvanzado =
  (tipoLicencia, fechaTerminoOperacion, fechaInicio, fechaFin, archivoSubir, cargo, Observacion) =>
  async (dispatch, getState) => {
    dispatch({
      type: LOADING_LICENCIAS,
    });

    try {
      const store = getState().LicenciaReducer;
      const {
        form: { Fechas, Trabajador, Correo: envioCorreo },
      } = store;

      //declaro fecha de inicio y fecha de fin

      // let fechaInicioLicencia = moment.utc(momentToStringFechaInicio(moment(Fechas[0]), databaseFormat));
      // let fechaFinLicencia = moment.utc(momentToStringFechaFin(moment(Fechas[1]), databaseFormat));

      // let fechaInicioLicencia = moment.utc(
      //   convertirFechaString(moment(Fechas[0]), 1).fechaString
      // );
      // let fechaFinLicencia = moment.utc(
      //   convertirFechaString(moment(Fechas[1]), 2).fechaString
      // );

      let objLicencia = {
        Trabajador: Trabajador,
        Cargo: cargo,
        Termino: fechaTerminoOperacion,

        Fecha_Inicio: fechaInicio,
        Fecha_Fin: fechaFin,

        Tipo: tipoLicencia,
        Archivo: archivoSubir,
        CantidadDiasDescanso: 0,
        Observacion: Observacion
      };

      const urlLicencia = `${API}/Licencia/Avanzado`;

      const requestLicencia = await fetch(urlLicencia, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objLicencia),
      });

      const responseLicencia = await requestLicencia.json();
      const statusLicencia = await requestLicencia.status;

      switch (statusLicencia) {
        case 201:
          let idRegistroLicencia = responseLicencia.data._id;

          let cantidadDias = moment.utc(fechaFin).diff(moment.utc(fechaInicio), "days") + 1;

          let arregloDeDias = [];

          for (let index = 0; index < cantidadDias; index++) {
            let fecha = moment.utc(fechaInicio).add(index, "day");
            // fecha = fecha.add(index === 0 ? 0 : 1, "day");

            const aux = {
              // Fecha: obtenerFechaFormateadaMismaHora(fecha.set({ hour: 12 }), databaseFormat),

              Fecha: convertirFechaString(fecha, 1).fechaString,

              IdTrabajador: Trabajador,
              TipoRegistroBitacoraTrabajador: "5f3eebf46dd7cc57f0d3a25d",

              InicioTrabajo: index === 0 ? true : false,
              FinTrabajo: index === cantidadDias - 1 ? true : false,
              Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,
              Licencia: idRegistroLicencia,
              Observacion: Observacion,
              // Fecha_Inicio: momentToStringFechaInicio(moment(Fechas[0]), databaseFormat),
              // Fecha_Fin: momentToStringFechaFin(moment(Fechas[1]), databaseFormat),

              Fecha_Inicio: objLicencia.Fecha_Inicio,
              Fecha_Fin: objLicencia.Fecha_Fin,
            };

            arregloDeDias.push(aux);
          }

          let objetoEnviar = {
            arregloDeDias,
            fechaInicio: objLicencia.Fecha_Inicio,
            fechaFin: objLicencia.Fecha_Fin,
          };

          const url = `${API}/IngresoDeDias/${Trabajador}`;
          const request = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(objetoEnviar),
          });

          const response = await request.json();
          const status = await request.status;

          switch (status) {
            case 201:
              ////////////////////////////////////////////////////////////////////////////////
              if (envioCorreo) {
                const url = `${API}/Enviar/Correo/Licencia/${idRegistroLicencia}`;
                await fetch(url, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
                });
              }
              ////////////////////////////////////////////////////////////////////////////////

              Swal.fire({
                title: "Bien",
                text: "Se ingresaron exitosamente los días de licencia",
                icon: "success",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "Continuar",
              });

              dispatch({
                type: INSERTAR_DATOS_FORMULARIO_LICENCIAS,
                payload: response,
              });

              break;
            case 400:
              Swal.fire({
                title: "Error",
                text: response.err.message,
                icon: "error",
              });
              break;
            case 500:
              Swal.fire({
                title: "Error",
                text: response.err.message,
                icon: "error",
              });
              break;
            default:
              Swal.fire({
                title: "Error",
                text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
                icon: "error",
              });
              break;
          }

          break;
        case 400:
          dispatch({
            type: ERROR_LICENCIAS,
            payload: responseLicencia.err.message,
          });
          Swal.fire({
            title: "Error",
            text: responseLicencia.err.message,
            icon: "error",
          });
          break;
        case 409:
          let msg = "";

          switch (responseLicencia.data.err) {
            case "TRABAJO_CONFIRMADO":
              msg =
                "<span>No puedes registrar en días con trabajo de asistencia confirmada.</span>";
              break;
            case "TRABAJO_DESCANSO":
              msg = `<span>No puedes registrar en días de trabajo que ya tienen un descanso asignado. Si deseas modificar esos registros, debes utilizar el <a href='/Descanso'>módulo de descansos.</a></span>`;
              break;
            case "DESCANSOS":
              msg = `<span>No puedes registrar en días con descanso planificado. Si deseas modificar esos registros, debes utilizar el <a href='/Descanso'>módulo de descansos.</a></span>`;
              break;
            case "VACACIONES":
              msg = `<span>No puedes registrar en días con vacaciones planificadas. Si deseas modificar esos registros, debes utilizar el <a href='/Vacaciones'>módulo de vacaciones.</a></span>`;
              break;
            default:
              msg =
                "<span>No puedes sobrescribir los registros de esas fechas.</span>";
              break;
          }

          Swal.fire({
            title: "¡Ups!",
            html: msg,
            icon: "warning",
          });

          dispatch({
            type: ERROR_LICENCIAS,
            payload:
              "No puedes registrar descansos en días con asistencia ya confirmada.",
          });

          break;
        case 500:
          console.log(responseLicencia);

          dispatch({
            type: ERROR_LICENCIAS,
            payload:
              "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          });

          Swal.fire({
            title: "Error",
            text: responseLicencia.err.message,
            icon: "error",
          });
          break;
        case 420:
          Swal.fire({
            title: "Uups",
            text: "No se pueden asignar licencias en un periodo que se encuentra cerrado",
            icon: "error",
          });

          dispatch({
            type: ERROR_LICENCIAS,
            payload:
              "No se pueden asignar licencias en un periodo que se encuentra cerrado",
          });

          break;
        default:
          dispatch({
            type: ERROR_LICENCIAS,
            payload:
              "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          });
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          break;
      }
    } catch (error) {
      console.log("se callo");
      console.log(error);
      dispatch({
        type: ERROR_LICENCIAS,
        payload: error.message,
      });
    }
  };

export const insertarFechas = (fechas) => (dispatch) => {
  dispatch({
    type: INSERTAR_FECHAS_FORM_LICENCIAS,
    payload: fechas,
  });
};

export const enviarCorreo = (estado) => (dispatch) => {
  dispatch({
    type: ENVIAR_CORREO,
    payload: estado,
  });
};

export const insertarTrabajador = (idTrabajador) => (dispatch) => {
  dispatch({
    type: INSERTAR_TRABAJADOR_FORM_LICENCIAS,
    payload: idTrabajador,
  });
};

export const traer_todos_las_fechas_trabajador =
  (idTrabajador) => async (dispatch) => {
    try {
      const url = `${API}/IngresoDeDias/Actuales/${idTrabajador}`;
      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          return data;
        default:
          console.log(data);
      }
    } catch (error) {
      dispatch({
        type: ERROR_LICENCIAS,
        payload: error.message,
      });
    }
  };

export const traer_todos_los_trabajadores = (empresa) => async (dispatch) => {
  dispatch({
    type: LOADING_LICENCIAS,
  });
  try {
    const url = `${API}/trabajadores?empresa=${empresa}`;
    const request = await fetch(url);
    const { Trabajadores } = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:
        dispatch({
          type: BUSCAR_TRABAJADORES_LICENCIAS,
          payload: Trabajadores,
        });
        break;
      default:
        break;
    }
  } catch (error) {
    dispatch({
      type: ERROR_LICENCIAS,
      payload: error.message,
    });
  }
};

export const eliminarRegistro =
  (fechaInicioDel, fechaFinDel, idLicencia) => async (dispatch, getState) => {
    dispatch({
      type: LOADING_LICENCIAS,
    });

    try {
      const store = getState().LicenciaReducer;

      const {
        form: { Trabajador },
      } = store;

      const objEliminar = {
        Fecha_Inicio: fechaInicioDel,
        Fecha_Fin: fechaFinDel,
        IdTrabajador: Trabajador,
      };

      const urlDescanso = `${API}/Licencia/Eliminar/${idLicencia}`;

      const request = await fetch(urlDescanso, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objEliminar),
      });

      const response = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          dispatch({
            type: LOADING_LICENCIAS_FALSE,
          });

          Swal.fire({
            title: "¡Bien!",
            text: "El registro se ha eliminado exitosamente.",
            icon: "success",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });

          break;

        case 500:
          dispatch({
            type: LOADING_LICENCIAS_FALSE,
          });
          Swal.fire({
            title: "Uups",
            text: "Ah ocurrido un error al realizar la petición",
            icon: "error",
          });

          break;

        case 420:
          Swal.fire({
            title: "Uups",
            text: "No se pueden eliminar licencias en un periodo que se encuentra cerrado",
            icon: "error",
          });

          dispatch({
            type: ERROR_LICENCIAS,
            payload:
              "No se pueden eliminar licencias en un periodo que se encuentra cerrado",
          });

          break;
        default:
          dispatch({
            type: LOADING_LICENCIAS_FALSE,
          });
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          break;
      }
    } catch (e) {
      console.error(e);

      dispatch({
        type: ERROR_LICENCIAS,
        payload: e.message,
      });
    }
  };

export const traer_todos_contratos_trabajador =
  (idTrabajador, idEmpresa) => async (dispatch) => {
    try {
      const url = `${API}/Trabajadores/Contratos?idTrabajador=${idTrabajador}&idEmpresa=${idEmpresa}`;
      const request = await fetch(url);
      const data = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          dispatch({
            type: BUSCAR_CONTRATOS_TRABAJADOR,
            payload: data.contratoDB,
          });
          break;
        default:
          console.log(status);
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          break;
      }
    } catch (error) {
      dispatch({
        type: ERROR_LICENCIAS,
        payload: error.message,
      });
    }
  };

export const traer_todas_las_licencias_historicas =
  (idTrabajador) => async (dispatch) => {
    try {
      const ID_LICENCIA = "5f3eebf46dd7cc57f0d3a25d";

      const url = `${API}/IngresoDeDias/Bitacoras/TipoBitacora/${idTrabajador}/${ID_LICENCIA}`;

      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          return data;
        default:
          
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          return null;
      }
    } catch (error) {
      dispatch({
        type: ERROR_LICENCIAS,
        payload: error.message,
      });
      return null;
    }
  };

  export const traer_todos_las_fechas_trabajador_por_contrato =
  (idTrabajador, idContrato, FechaInicio, FechaFin) => async (dispatch) => {
    try {
      let url;
      if (FechaInicio !== undefined && FechaFin !== undefined) {
        url = `${API}/IngresoDeDias/Actuales/Contrato/${idTrabajador}/${idContrato}?InicioBusqueda=${FechaInicio}&FinBusqueda=${FechaFin}`;
      } else {
        url = `${API}/IngresoDeDias/Actuales/Contrato/${idTrabajador}/${idContrato}`;
      }
      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          return data;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          return null;
      }
    } catch (error) {
      dispatch({
        type: ERROR_LICENCIAS,
        payload: error.message,
      });
      return null;
    }
  };