import {
  ERROR,
  INGRESAR_EMPRESA,
  LOADING,
  OBTENER_TIPOEMPRESA,
  MOSTRAR_MODAL_EMPRESA,
  EDITAR_EMPRESA_MANTENEDOR,
  OCULTAR_MODAL_EMPRESA,
  OCULTAR_MODAL_EMPRESA_EDITAR,
  OBTENER_EMPRESAS_MANTENEDOR,
  ELIMINAR_EMPRESA_MANTENEDOR,
  OBTENER_EMPRESA_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OBTENER_CODIGOS_ACTIVIDAD_ECONOMICA_MANTENEDOR_EMPRESA,
  OBTENER_NACIONALIDADES_MANTENEDOR_EMPRESA,
  OBTENER_REGIONES_MANTENEDOR_EMPRESA,
  OBTENER_PROVINCIAS_MANTENEDOR_EMPRESA,
  OBTENER_COMUNAS_MANTENEDOR_EMPRESA,
  INSERTAR_COMUNAS_MANTENEDOR_EMPRESA,
  INSERTAR_PROVINCIAS_MANTENEDOR_EMPRESA,
} from "../types/MantenedorEmpresaTypes";

import API from "../Api/api";
import { obtenerRespueta } from "../../funcionesGenericas/respuestas";
import Swal from "sweetalert2";

//controlada
export const ObtenerTipoEmpresa = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/TipoDeEmpresa`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();
        const tipoEmpresa = data.data;

        dispatch({ type: OBTENER_TIPOEMPRESA, payload: tipoEmpresa });
        break;

      case 204:
        dispatch({ type: OBTENER_TIPOEMPRESA, payload: [] });
        break;
      default:

        data = await response.json();
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const ObtenerCodigosActividadEconomica = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/ActividadEconomica`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        const giros = data.data;
        dispatch({ type: OBTENER_CODIGOS_ACTIVIDAD_ECONOMICA_MANTENEDOR_EMPRESA, payload: giros });
        break;

      case 204:

        dispatch({ type: OBTENER_CODIGOS_ACTIVIDAD_ECONOMICA_MANTENEDOR_EMPRESA, payload: [] });
        break;

      default:
        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const ObtenerNacionalidades = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Nacionalidades`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();
        dispatch({ type: OBTENER_NACIONALIDADES_MANTENEDOR_EMPRESA, payload: data.data });
        break;

      case 204:

        dispatch({ type: OBTENER_CODIGOS_ACTIVIDAD_ECONOMICA_MANTENEDOR_EMPRESA, payload: [] });
        break;

      default:

        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const ObtenerRegiones = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Regiones`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;


    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        dispatch({ type: OBTENER_REGIONES_MANTENEDOR_EMPRESA, payload: data.data });
        break;

      case 204:
        dispatch({ type: OBTENER_REGIONES_MANTENEDOR_EMPRESA, payload: [] });

        break;
      default:

        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const ObtenerProvincias = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Provincia`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;


    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        dispatch({ type: OBTENER_PROVINCIAS_MANTENEDOR_EMPRESA, payload: data.data });
        break;

      case 204:
        dispatch({ type: OBTENER_PROVINCIAS_MANTENEDOR_EMPRESA, payload: [] });

        break;
      default:

        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const ObtenerComunas = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Comunas`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;


    switch (status) {
      case 200:
      case 201:

        data = await response.json();
        dispatch({ type: OBTENER_COMUNAS_MANTENEDOR_EMPRESA, payload: data.data });
        break;

      case 204:
        dispatch({ type: OBTENER_COMUNAS_MANTENEDOR_EMPRESA, payload: [] });

        break;
      default:

        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const ObtenerEmpresas = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Empresa`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();

        const empresas = data.data;


        dispatch({ type: OBTENER_EMPRESAS_MANTENEDOR, payload: empresas });
        break;

      case 204:
        dispatch({ type: OBTENER_EMPRESAS_MANTENEDOR, payload: [] });
        break;
      default:
        data = await response.json();
        const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const CrearEmpresa = (empresa) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const url = `${API}/Empresa`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(empresa),
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        Swal.fire({
          title: "Bien",
          text: obtenerRespueta(status, ""),
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          // window.location.reload();
        });

        dispatch({
          type: INGRESAR_EMPRESA,
          payload: "Se ingresó empresa correctamente",
        });
        break;

      default:

        data = await response.json();
        const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const InsertarComunas = (data) => (dispatch) => {
  dispatch({ type: INSERTAR_COMUNAS_MANTENEDOR_EMPRESA, payload: data });
};

//controlada
export const InsertarProvincias = (data) => (dispatch) => {
  dispatch({ type: INSERTAR_PROVINCIAS_MANTENEDOR_EMPRESA, payload: data });
};






export const ObtenerEmpresa = (idEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Empresa/${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();

        let empresa = data.data;
        empresa.validacionRut = "success";
        dispatch({ type: OBTENER_EMPRESA_MANTENEDOR, payload: empresa });
        break;

      case 204:

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, "No se encontro data"),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
      default:

        data = await response.json();
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarEmpresa = (idEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Empresa/${idEmpresa}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "La empresa fue eliminada",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        // .then((value) => {
        //   window.location.reload();
        // });
        dispatch({ type: ELIMINAR_EMPRESA_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarEmpresa = (Empresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  const idEmpresa = Empresa._id;
  delete Empresa._id;
  try {
    const url = `${API}/Empresa/${idEmpresa}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(Empresa),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "La empresa fue actualizada",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: EDITAR_EMPRESA_MANTENEDOR });
        break;

      default:
        const error = data.err.message;

        console.log(error);
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR, payload: error });
  }
};





//controlada
export const TomarValoresEditar = (nombreCampo, valor) => (dispatch) => {
  let objeto = {
    nombreCampo,
    valor,
  };
  dispatch({ type: TOMAR_VALORES_EDITAR, payload: objeto });
};

//controlada
export const MostrarModalEmpresa = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_EMPRESA });
};

//controlada
export const OcultarModalEmpresa = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_EMPRESA });
};

//controlada
export const OcultarModalEmpresaEditar = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_EMPRESA_EDITAR });
};
