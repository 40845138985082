import React, { useEffect, useState } from "react";
import faena from "../../img/icons/faena.svg";
// import iconBorrar from "../../img/icons/borrar.svg";
// import { ArrowLeftOutlined } from "@ant-design/icons";
// import iconBorrar from "../../img/icons/borrar.svg";
import {
    Form,
    Input,
    Button,
    Card,
    Switch,
    Row,
    Col,
    PageHeader,
    Tag,
    //Select,
    //InputNumber,
    //Radio,
} from "antd";

import Swal from "sweetalert2";
import './FormularioPlantillaDeFaenaEditar.css';

const FormularioPlantillaDeFaenaEditar = (props) => {
    //constante para manejar el formulario
    const [form] = Form.useForm();

    const [vigencia, setVigencia] = useState(null);

    let contador = 0;

    //FUNCIONES PARA MANJERA EL FORMULARIO
    const onFinish = (values) => {

        // values.Nombre = props.Formulario.Nombre;
        values.Vigencia = vigencia;

        props.EditarFaena(values);

        contador = 0


        // let usuario = localStorage.getItem("usuario-people-manage");
        // if (
        //     usuario !== null &&
        //     usuario !== "" &&
        //     usuario !== undefined &&
        //     usuario !== "undefined"
        // ) {
        //     usuario = JSON.parse(usuario);

        //     let objAux = {
        //         ...values,
        //         Empresa: usuario.Empresas[0]._id,
        //         Vigencia: props.vigencia,
        //     };

        //     delete objAux.Cantidad;
        //     delete objAux.Cargo;
        //     delete objAux.Especialidades_Obligatorios;
        //     delete objAux.Especialidades_Opcionales;
        //     delete objAux.NombrePlaza;
        //     delete objAux.Titulos_Obligatorios;
        //     delete objAux.Titulos_Opcionales;

        //     objAux.Plazas = props.Plazas;

        //     if (objAux.Plazas.length === 0) {

        //         Swal.fire({
        //             title: "Uuups...",
        //             text: "Debes ingresar al menos una plaza",
        //             icon: "error",
        //             confirmButtonColor: "#E60AA3",
        //             confirmButtonText: "Continuar",
        //         });
        //         return false;


        //     } else {

        //         props.crearFaena(objAux);

        //     }
        // }
    };

    const onFinishFailed = (values) => {
        const error = values.errorFields[0].errors[0];
        Swal.fire({
            title: "Uuups...",
            text: error,
            icon: "error",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
        });
    };

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 6,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 12,
            },
        },
    };

    const CambiarVigencia = (value) => {
        props.Formulario.Vigencia = value
        setVigencia(value);
    }

    let aux = props.Formulario._id;

    useEffect((aux) => {

        let {
            Nombre,
            Vigencia
        } = props.Formulario;

        form.setFieldsValue({
            Nombre,
            Vigencia,
        });

        setVigencia(Vigencia);

    }, [aux]);

    return (
        <Form
            {...formItemLayout}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Row style={{ padding: "5px 20px 0px 20px" }}>

                <Col span={24} style={{ display: "flex" }} className="title">
                    <img src={faena} style={{ width: "35px" }} alt="icono modal" />
                    <PageHeader
                        style={{ padding: "0px 10px 0px 10px" }}
                        className="site-page-header"
                        title="Editar plantilla de equipo"
                    />
                </Col>

                <Col span={24}>
                    <Card className="card-perfil" style={{ width: "100%" }}>

                        <Form.Item
                            name="Nombre"
                            label="Nombre plantilla:"
                            rules={[
                                {
                                    required: true,
                                    message: "Debe introducir un nombre",
                                    min: 2,
                                    // max: 30,
                                },
                            ]}
                        >
                            <Input
                                name="Nombre"
                                className="campos" />
                        </Form.Item>

                        <Form.Item
                            label="Vigencia"
                            style={{ textAlign: "left" }}
                        >
                            <Switch
                                checked={vigencia}
                                onChange={CambiarVigencia}
                                className="switch"
                            />
                        </Form.Item>

                        <hr className="division" />
                        <br />
                        <br />

                        <Row>
                            {/* <Col span={}></Col> */}

                            <Col span={24}>
                                <h4 className="titulo-tabla">Detalle de la plantilla: {props.Formulario.Nombre}</h4>

                                <table className="plazas-table">

                                    <thead>
                                        <tr>
                                            <th>n°</th>
                                            <th>Nombre Plaza</th>
                                            <th>Cantidad puestos</th>
                                            <th>Títulos obligatorios</th>
                                            <th>Títulos opcionales</th>
                                            <th>Especialidades obligatorias</th>
                                            <th>Especialidades opcionales</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {props.Formulario.Plazas.map((value, index) => {

                                            contador = contador + value.Cantidad;

                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ textAlign: 'left' }}>{value.NombrePlaza}</td>
                                                    <td>{value.Cantidad}</td>
                                                    <td>
                                                        {value.Titulos_Obligatorios.map((titulo, index) => (
                                                            <Tag
                                                                className={'t-e-tags'}
                                                                key={index}
                                                                color="blue"
                                                            >
                                                                {titulo.Nombre}
                                                            </Tag>
                                                        ))}
                                                    </td>
                                                    <td>
                                                        {value.Titulos_Opcionales.map((titulo, index) => (
                                                            <Tag
                                                                className={'t-e-tags'}

                                                                key={index}
                                                                color="blue"
                                                            >
                                                                {titulo.Nombre}
                                                            </Tag>
                                                        ))}
                                                    </td>
                                                    <td>
                                                        {value.Especialidades_Obligatorias.map((especialidad, index) => (
                                                            <Tag
                                                                className={'t-e-tags'}
                                                                key={index}
                                                                color="blue"
                                                            >
                                                                {especialidad.Nombre}
                                                            </Tag>
                                                        ))}
                                                    </td>
                                                    <td>
                                                        {value.Especialidades_Opcionales.map((especialidad, index) => (
                                                            <Tag
                                                                className={'t-e-tags'}
                                                                key={index}
                                                                color="blue"
                                                            >
                                                                {especialidad.Nombre}
                                                            </Tag>
                                                        ))}
                                                    </td>
                                                </tr>
                                            );

                                        })}
                                    </tbody>

                                </table>

                                <h4 className="titulo-tabla">
                                    Esta plantilla cuenta con {props.Formulario.Plazas.length} plazas y para ello requiere {contador} trabajadores
                                </h4>

                            </Col>

                        </Row>

                    </Card>
                </Col>

                <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
                    <Button className="guardar-form" type="primary" htmlType="submit">
                        Guardar
                    </Button>
                </Col>

            </Row>

            {/* <span>
                <style jsx="true">
                    {`
                        .ant-tag {
                            margin-right: 0px !important;
                        }
                    `}
                </style>
            </span> */}
        </Form >
    );
};

export default FormularioPlantillaDeFaenaEditar;
