import React, { useEffect, useState } from "react";
import calendario from "../../img/dashboard/calendario.png";
import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Select,
  DatePicker,
} from "antd";
import "moment/locale/es";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import {
  convertirFechaString,
  corregirZonaHoraria,
  // ajustarZonaHoraria
} from "../../funcionesGenericas/fechas";
import Swal from "sweetalert2";
const { RangePicker } = DatePicker;
const FormularioAsignacionFaenas = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();
  const [vigencia, setVigencia] = useState(null);
  const [botonDisabled, setBotonDisabled] = useState(false);

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = async (values) => {

    if(botonDisabled === true){
      return;
    }
    setBotonDisabled(true);
    
    values.Vigencia = vigencia;

    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      let fechaInicio = values.Rango_Fechas[0];
      let fechaFin = values.Rango_Fechas[1];

      // VALIDACIÓN: si deja la fecha vacía
      if (fechaFin === null || fechaFin === undefined ||fechaInicio === null || fechaInicio === undefined) {
        Swal.fire({
          title: "Ups...",
          text: "Debes ingresar las fechas de la faena para editarla.",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
      }


      let nuevaFechaInicio = values.Rango_Fechas[0];
      let nuevaFechaFin = values.Rango_Fechas[1];

      delete values.Rango_Fechas;

      const objAux = {
        ...values,
        Empresa: usuario.EmpresaSeleccionada.Empresa._id,
        Expira: false,

        // Fecha_Inicio: momentToStringFechaInicio(nuevaFechaInicio,databaseFormat),
        Fecha_Inicio: convertirFechaString(nuevaFechaInicio, 1).fechaString,
        // Old fecha Inicio
        Fecha_Inicio_Old: props.Formulario.Fecha_Inicio,

        // Fecha_Fin: momentToStringFechaFin(nuevaFechaFin,databaseFormat),
        Fecha_Fin: convertirFechaString(nuevaFechaFin, 2).fechaString,

        // Fecha_Inicio_Unix: momentToUnixFechaInicio(nuevaFechaInicio),
        Fecha_Inicio_Unix: convertirFechaString(nuevaFechaInicio, 1).fechaUNIX,

        // Fecha_Fin_Unix: momentToUnixFechaFin(nuevaFechaFin),
        Fecha_Fin_Unix: convertirFechaString(nuevaFechaFin, 2).fechaUNIX,

        idFaena: props.Formulario._id,

        // Fecha_Fin_Old: props.Formulario.Fecha_Fin,
        Fecha_Fin_Old: props.Formulario.Fecha_Fin,

        // Fecha_Fin_Old_Unix: momentToUnixFechaFin(moment.unix(props.Formulario.Fecha_Fin_Unix)),
        Fecha_Fin_Old_Unix: props.Formulario.Fecha_Fin_Unix,
      };


      // let Fecha_Fin_Old = momentToUnixFechaFin(moment.unix(props.Formulario.Fecha_Fin_Unix));
      let Fecha_Fin_Old =  corregirZonaHoraria(moment(props.Formulario.Fecha_Fin), 2);

      // let Fecha_Fin_New = momentToUnixFechaFin(moment.unix(objAux.Fecha_Fin_Unix));
      let Fecha_Fin_New = corregirZonaHoraria(moment(objAux.Fecha_Fin), 2);

      // if (moment.unix(Fecha_Fin_New).isBefore(moment.unix(Fecha_Fin_Old), "day")) {
      //   props.acortarFaena(objAux);
      // } else {
      //   props.alargarFaena(objAux);
      // }

      // return false;
      
      let resp;
      //Nueva API
      resp = await props.alargarAcortarActividad(objAux);
      // if (Fecha_Fin_New.isBefore(Fecha_Fin_Old, "day")) {
      //   //console.log("La fecha fin nueva esta antes de la fecha fin antigua");
      //   console.log("acortar");
      //   resp = await props.acortarFaena(objAux);
      // } else {
      //   //console.log("La fecha fin antigua esta antes de la fecha fin nueva");
      //   console.log("alargar");
      //   resp = await props.alargarFaena(objAux);
      // }
     
      if(resp === true){
        form.resetFields();
      }
      setBotonDisabled(false);
    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  let aux = props.Formulario._id;

  useEffect(
    (aux) => {
      let {
        Nombre,
        Centro_De_Costo,
        Fecha_Fin,
        Fecha_Fin_Unix,
        Fecha_Inicio,
        Fecha_Inicio_Unix,
        PlantillaBase,
        Vigencia,
        
      } = props.Formulario;

      //console.log(props.Formulario);
      
      form.setFieldsValue({
        Nombre,
        Centro_De_Costo,
        Rango_Fechas: [
          moment.utc(Fecha_Inicio),
          moment.utc(Fecha_Fin)
        ],
        PlantillaBase,
      });

      props.ObtenerAsignacionesIdCentroCosto(Centro_De_Costo);

      setVigencia(Vigencia);
    },
    [aux]
  );

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  const cambiarVigencia = (val) => {
    setVigencia(val);
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={calendario} style={{ width: 50 }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Editar actividad"
          />
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Form.Item
              name="Nombre"
              label="Nombre:"
              rules={[
                {
                  required: true,
                  message: "Introduzca el nombre de la faena",
                  min: 2,
                  max: 20,
                },
              ]}
            >
              <Input name="Nombre" className="campos" />
            </Form.Item>
            <Form.Item
              label="Centro de Costos"
              name={"Centro_De_Costo"}
              style={{
                marginTop: "20px",
              }}
              rules={[
                {
                  required: true,
                  message: "Seleccione el centro de costo",
                },
              ]}
            >
              <Select
                className="campos"
                name="Centro_De_Costo"
                placeholder="Selecciona centro de costo"
                disabled={true}
                onSelect={(value) => {
                  props.ObtenerAsignacionesIdCentroCosto(value);
                }}
              >
                {props.centrosCosto.map((value, index) => (
                  <Select.Option key={index} value={value._id} name="Cargo">
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="Rango_Fechas"
              label="Fechas de definición"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Debe introducir una fecha de inicio y término",
                },
              ]}
            >
              <RangePicker
                locale={locale}
                name="Rango_Fechas"
                format={["DD-MM-YYYY"]}
                className="campos"
                disabled={[false, false]}
                disabledDate={(dia) => {
                  let validacion = false;

                  // let fechaInicioFaena = dateOrMomentToMomentTimeZoneChile(
                  //   props.Formulario.Fecha_Inicio
                  // );
                  // let fechaFinFaena = dateOrMomentToMomentTimeZoneChile(
                  //   props.Formulario.Fecha_Fin
                  // );

                  // let fechaInicioFaena = corregirZonaHoraria(moment(props.Formulario.Fecha_Inicio), 1);
                  // let fechaFinFaena = corregirZonaHoraria(moment(props.Formulario.Fecha_Fin), 2);

                  let fechaInicioFaena = convertirFechaString(moment(props.Formulario.Fecha_Inicio), 1).fechaString;
                  let fechaFinFaena = convertirFechaString(moment(props.Formulario.Fecha_Fin), 2).fechaString;

                  props.faenasFechasCentroCosto.forEach((element) => {
                    if (
                      !dia.isBetween(
                        fechaInicioFaena,
                        fechaFinFaena,
                        null, '[]'
                      )
                    ) {

                      // let fechaInicioCC = dateOrMomentToMomentTimeZoneChile(
                      //   element.Fecha_Inicio
                      // );
                      // let fechaFinCC = dateOrMomentToMomentTimeZoneChile(
                      //   element.Fecha_Fin
                      // );

                      // let fechaInicioCC = corregirZonaHoraria(moment(element.Fecha_Inicio), 1);
                      // let fechaFinCC = corregirZonaHoraria(moment(element.Fecha_Fin), 2);
                      let fechaInicioCC = convertirFechaString(moment(element.Fecha_Inicio), 1).fechaString;
                      let fechaFinCC = convertirFechaString(moment(element.Fecha_Fin), 2).fechaString;

                      if (dia.isBetween(fechaInicioCC, fechaFinCC, null, '[]')) {
                        validacion = true;
                      }
                    }
                  });

                  if (validacion === true) {
                    return true;
                  }
                }}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>

            <Form.Item
              label="Plantilla de equipo"
              name="PlantillaBase"
              style={{
                marginTop: "20px",
              }}
              rules={[
                {
                  required: true,
                  message: "Seleccione una plantilla de equipo de trabajo",
                },
              ]}
            >
              <Select
                className="campos"
                name="PlantillaBase"
                placeholder="Selecciona una plantilla"
                disabled={true}
              >
                {props.plantillas.map((value, index) => (
                  <Select.Option
                    key={index}
                    value={value._id}
                    name="PlantillaBase"
                  >
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                checked={vigencia}
                onChange={cambiarVigencia}
                className="switch"
              />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-formX" disable={botonDisabled} type="primary" htmlType="submit">
            Editar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormularioAsignacionFaenas;
