import React from "react";

import logoGenerico from "../../img/logos/logoGenerico.svg";

import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Select,
} from "antd";

import Swal from "sweetalert2";

const FormularioHoldings = (props) => {
  const [form] = Form.useForm();

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {
    props.crearHolding(values);
  };
  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };
  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={[8, 32]} style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={logoGenerico} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Crear Holding"
          />
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <h3 style={{ fontFamily: "MavenPro-Bold", marginRight: 495 }}>
              Sobre el Holding:
            </h3>
            <Form.Item
              name="Nombre"
              label="Nombre:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir sus nombres",
                  min: 2,
                  max: 20,
                },
              ]}
            >
              <Input name="Nombre" className="campos" />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <h3 style={{ fontFamily: "MavenPro-Bold", marginRight: 485 }}>
              Vincular Empresas:
            </h3>
            <Form.Item
              label="¿Qué empresas desea vincular?:"
              name={"Empresas"}
              rules={[
                {
                  required: true,
                  message: "Debes agregar empresas",
                },
              ]}
            >
              <Select
                mode="tags"
                className="campos"
                name="Empresas"
                placeholder="Selecciona empresas"
              >
                {props.Empresas.map((value, index) => (
                  <Select.Option name="Empresas" key={index} value={value._id}>
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                defaultChecked
                onChange={props.cambiarEstadoVigencia}
                className="switch"
              />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormularioHoldings;
