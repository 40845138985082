import React, { Component } from "react";

import { connect } from "react-redux";
import * as mantenedorTipoEmpresaActions from "../../Redux/actions/MantenedorTipoEmpresaActions";

import activo from "../../img/icons/activo.svg";
import inactivo from "../../img/icons/inactivo.svg";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import FormularioTipoEmpresas from "../../components/formularioTipoEmpresas/FormularioTipoEmpresas";
import FormularioTipoEmpresasEditar from "../../components/formularioTipoEmpresas/FormularioTipoEmpresasEditar";

import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { Layout, Table, Space, Button, Row, Col, Input, Modal } from "antd";

import Swal from "sweetalert2";

const { Header, Content } = Layout;
const { Search } = Input;
const { Column } = Table;

class MantenedorTipoEmpresas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tablaCargando: false,
      form: {
        Nombre: "",
        Vigencia: true,
      },

      size: "large",
      tiposEmpresaFiltros: null,
    };

    this.cambiarEstadoVigencia = this.cambiarEstadoVigencia.bind(this);
    this.crearTipoEmpresa = this.crearTipoEmpresa.bind(this);
    this.EditarTipoEmpresa = this.EditarTipoEmpresa.bind(this);
    this.TomarValoresEditar = this.TomarValoresEditar.bind(this);
  }

  // descargarExcel = () => {
  //   this.props.DescargarExcel();
  // };

  search = (value) => {
    let busqueda = value.target.value;
    if (value !== "") {
      const TiposEmpresaRedux = this.props.tiposEmpresa;

      const tiposEmpresaFiltros = TiposEmpresaRedux.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(busqueda)
        )
      );

      this.setState({ tiposEmpresaFiltros });
    } else {
      this.setState({ tiposEmpresaFiltros: null });
    }
  };

  mostrarModalCrear = () => {
    this.props.MostrarModalTipoEmpresa();
  };

  componentDidMount() {
    this.props.ObtenerTiposEmpresa();
  }

  modalCrearCancelar = () => {
    this.props.OcultarModalTipoEmpresa();
  };

  modalEditarCancelar = () => {
    this.props.OcultarModalTipoEmpresaEditar();
  };

  cambiarEstadoVigencia = () => {
    this.setState({
      form: {
        ...this.state.form,
        Vigencia: this.state.form.Vigencia === true ? false : true,
      },
    });
  };

  crearTipoEmpresa = (tipoEmpresa) => {
    tipoEmpresa.Vigencia = this.state.form.Vigencia;
    this.props.CrearTipoEmpresa(tipoEmpresa);
  };

  EliminarTipoEmpresa = (idTipoEmpresa) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminará el rol",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.EliminarTipoEmpresa(idTipoEmpresa);
      }
    });
  };

  ObtenerTipoEmpresa = (idTipoEmpresa) => {
    this.props.ObtenerTipoEmpresa(idTipoEmpresa);
  };

  EditarTipoEmpresa = (TipoEmpresa) => {
    TipoEmpresa._id = this.props.tipoEmpresa._id;
    TipoEmpresa.Vigencia = this.props.tipoEmpresa.Vigencia;
    this.props.EditarTipoEmpresa(TipoEmpresa);
  };

  TomarValoresEditar = (nombreCampo, valor) => {
    this.props.TomarValoresEditar(nombreCampo, valor);
  };

  render() {
    const { size, tiposEmpresaFiltros } = this.state;

    return (
      <Layout style={{ height: "100%" }}>
        <Loading cargando={this.props.loading} />
        <Header style={{ background: "none" }}>
          <HeaderFicha titulo="Tipos de empresas" subtitulo="¿Qué tipos hay?" />
        </Header>
        <hr className="division" />
        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row style={{ padding: "6px 0px 22px" }} align="bottom">
            <Col className="div-input-search" span={6}>
              <Search
                placeholder="Buscar"
                enterButton
                onChange={(value) => this.search(value)}
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={6}></Col>
            <Col style={{ textAlign: "right" }} span={12}>
              {/* <Button
                className="descargar-excel"
                type="primary"
                onClick={() => {
                  this.descargarExcel();
                }}
                size={size}
                style={{ marginRight: "20px" }}
              >
                Descargar Excel
              </Button> */}
              <Button
                className="guardar-form"
                type="primary"
                onClick={() => {
                  this.mostrarModalCrear();
                }}
                size={size}
              >
                Crear tipo empresa
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="_id"
            dataSource={
              tiposEmpresaFiltros === null
                ? this.props.tiposEmpresa
                : tiposEmpresaFiltros
            }
            loading={this.props.tablaCargando}
            bordered={true}
            showSorterTooltip={false}
          >
            <Column
              sorter={(a, b) => {
                return a.Nombre.localeCompare(b.Nombre);
              }}
              align="left"
              title="Nombre"
              dataIndex="Nombre"
              key="Nombre"
            />

            <Column
              sorter={(a, b) => {
                return a.Vigencia - b.Vigencia;
              }}
              title="Vigencia"
              key="Vigencia"
              align="center"
              render={(valor) => {
                return (
                  <img
                    src={valor.Vigencia === true ? activo : inactivo}
                    alt="imagen perfil de tabla"
                  />
                );
              }}
            />

            <Column
              title="Acciones"
              key="Action"
              align="center"
              render={(value) => (
                <Space size="middle">
                  <Button
                    onClick={() => {
                      this.ObtenerTipoEmpresa(value._id);
                    }}
                    className="editar-button"
                    icon={<EditFilled />}
                  />
                  <Button
                    className="eliminar-button"
                    onClick={() => {
                      this.EliminarTipoEmpresa(value._id);
                    }}
                    icon={<DeleteFilled />}
                  />
                </Space>
              )}
            />
          </Table>
        </Content>
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalTipoEmpresa}
          onCancel={this.modalCrearCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioTipoEmpresas
            //propiedades cambiables del formulario
            Formulario={this.state.form}
            vigencia={this.state.form.vigencia}
            cambiarEstadoVigencia={this.cambiarEstadoVigencia}
            crearTipoEmpresa={this.crearTipoEmpresa}
          />
        </Modal>
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalEditar"
          visible={this.props.modalTipoEmpresaEditar}
          onCancel={this.modalEditarCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioTipoEmpresasEditar
            //propiedades cambiables del formulario
            Formulario={this.props.tipoEmpresa}
            EditarTipoEmpresa={this.EditarTipoEmpresa}
            TomarValoresEditar={this.TomarValoresEditar}
          />
        </Modal>
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  MantenedorTipoEmpresaReducer: {
    loading,
    error,
    success,
    modalTipoEmpresa,
    modalTipoEmpresaEditar,
    tiposEmpresa,
    tipoEmpresa,
    tablaCargando,
  },
}) => {
  return {
    loading,
    error,
    success,
    modalTipoEmpresa,
    modalTipoEmpresaEditar,
    tiposEmpresa,
    tipoEmpresa,
    tablaCargando,
  };
};

export default connect(
  EstadosAPropiedades,
  mantenedorTipoEmpresaActions
)(MantenedorTipoEmpresas);
