import React, { Component } from "react";
import { connect } from "react-redux";
import * as FiltroTrabajadoresActions from "../../Redux/actions/FiltroTrabajadoresActions";

import checkin from "../../img/icons/checkin.svg";
import checkout from "../../img/icons/checkout.svg";
import vista from "../../img/icons/vista.svg";

// import activo from "../../img/icons/activo.svg";
// import inactivo from "../../img/icons/inactivo.svg";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import moment from "moment";
import Loading from "../../components/loading/Loading";
import './FiltroTrabajadores.css'

import {
  Layout,
  Button,
  Row,
  Select,
  Col,
  Card,
  Modal,
  PageHeader,
  Checkbox,
  DatePicker,
  Empty,
  Divider,
  Alert,
  Table,
  Space,
} from "antd";

import {
  convertirFechaString,
  corregirZonaHoraria

} from '../../funcionesGenericas/fechas';

import accesosPagina from '../../funcionesGenericas/accesosUsuario';
import { EyeFilled } from '@ant-design/icons';

import Swal from "sweetalert2";

const { RangePicker } = DatePicker;
const { Header, Content } = Layout;
const { Option } = Select;
const { Column } = Table;

class FiltroTrabajadores extends Component {
  constructor(props) {
    super(props);
    let str = moment().format('yyyy/MM/DD');
    this.state = {
      idCentroCosto: "",
      // fechaBusquedaInicio: momentToStringFechaInicio(moment(), databaseFormat),
      // fechaBusquedaFin: momentToStringFechaFin(moment(), databaseFormat),

      fechaBusquedaInicio: convertirFechaString(moment(str), 1).fechaString,
      fechaBusquedaFin: convertirFechaString(moment(str), 2).fechaString,

      nombreCentroCosto: "",
      idBitacoraCheckout: "",

      trabajadorDataRevision: null,
      empresa: null,

      modalCheckinMultiple: false,

      trabajadoresCheckinMasivo: []
    };
  }

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      const accesos = await accesosPagina.obtenerAccesosPagina('AsistenciaCheckIn', 'ProcesosCheck');



      //funcion encargada de obtener los parametros de la path
      const urlParams = new URLSearchParams(window.location.search);
      //obtengo el valor de id Balance. Si viene idBalance significa que es una edicion de algun valor
      const fechaBusqueda = urlParams.get("FechaBusqueda");
      const idCentroCosto = urlParams.get("IdCentroCosto");



      let centroCostoBusqueda = idCentroCosto ? idCentroCosto : null;
      let str = moment().format('yyyy/MM/DD');
      let momentBusqueda = fechaBusqueda ? moment.utc(fechaBusqueda) : moment.utc(convertirFechaString(moment(str), 1).fechaString);


      //console.log(momentBusqueda)


      await this.props.ObtenerCentroCostos(
        usuario.EmpresaSeleccionada.Empresa._id
      );

      this.setState({
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
        idCentroCosto: centroCostoBusqueda === null ? "" : centroCostoBusqueda,

        // fechaBusquedaInicio: momentToStringFechaInicio(momentBusqueda, databaseFormat),
        // fechaBusquedaFin: momentToStringFechaFin(momentBusqueda, databaseFormat),
        // fechaBusquedaInicioChile: momentToStringFechaInicio(momentBusqueda, chileFormat),
        // fechaBusquedaFinChile: momentToStringFechaFin(momentBusqueda, chileFormat),

        fechaBusquedaInicio: convertirFechaString(momentBusqueda, 1).fechaString,
        fechaBusquedaFin: convertirFechaString(momentBusqueda, 2).fechaString,
        fechaBusquedaInicioChile: convertirFechaString(momentBusqueda, 1).fechaDDMMYYYY,
        fechaBusquedaFinChile: convertirFechaString(momentBusqueda, 2).fechaDDMMYYYY,

        empresa: usuario.EmpresaSeleccionada.Empresa._id,
      });

      // await this.props.ObtenerTrabajadoresCentroCostoUnix(
      //   centroCostoBusqueda,
      //   this.state.fechaBusquedaInicio,
      //   this.state.fechaBusquedaFin,
      //   usuario.EmpresaSeleccionada.Empresa._id,

      //   // momentToUnixFechaInicio(momentBusqueda),
      //   // momentToUnixFechaFin(momentBusqueda),

      //   convertirFechaString(momentBusqueda, 1).fechaUNIX,
      //   convertirFechaString(momentBusqueda, 2).fechaUNIX,
      // );



      await this.props.ObtenerTrabajadoresCentroCostoNuevaForma(
        centroCostoBusqueda,
        convertirFechaString(momentBusqueda, 1).fechaString,
        convertirFechaString(momentBusqueda, 2).fechaString,
        usuario.EmpresaSeleccionada.Empresa._id
      );

    }
  }

  trabajadoresFiltrados = (
    idCentroCosto,
    fechaInicio,
    fechaFin,
    idTrabajador,
    fechaInicioUnix,
    fechaFinUnix,
  ) => {
  
    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {

      usuario = JSON.parse(usuario);

      if (
        idCentroCosto === undefined ||
        idCentroCosto === "undefined" ||
        idCentroCosto === [] ||
        idCentroCosto === ""
      ) {

        if (fechaInicio && fechaFin) {
          this.props.ObtenerTrabajadoresCentroCostoUnix(
            null,
            fechaInicio,
            fechaFin,
            usuario.EmpresaSeleccionada.Empresa._id,
            fechaInicioUnix,
            fechaFinUnix,
          );

        } else {
          let str = moment().format('yyyy/MM/DD');
          this.props.ObtenerTrabajadoresCentroCostoUnix(
            null,
            convertirFechaString(moment(str), 1).fechaString,
            convertirFechaString(moment(str), 2).fechaString,
            idTrabajador,
            usuario.EmpresaSeleccionada.Empresa._id,
            fechaInicioUnix,
            fechaFinUnix,
          );
        }

      } else {

        if (fechaInicio && fechaFin) {
          this.props.ObtenerTrabajadoresCentroCostoUnix(
            idCentroCosto,
            fechaInicio,
            fechaFin,
            usuario.EmpresaSeleccionada.Empresa._id,
            fechaInicioUnix,
            fechaFinUnix,
          );
        } else {
          let str = moment().format('yyyy/MM/DD');
          this.props.ObtenerTrabajadoresCentroCostoUnix(
            idCentroCosto,

            // momentToStringFechaInicio(moment(), databaseFormat),
            // momentToStringFechaFin(moment(), databaseFormat),

            convertirFechaString(moment(str), 1).fechaString,
            convertirFechaString(moment(str), 2).fechaString,

            usuario.EmpresaSeleccionada.Empresa._id,

            // momentToUnixFechaInicio(moment()),
            // momentToUnixFechaFin(moment()),

            convertirFechaString(moment(), 1).fechaUNIX,
            convertirFechaString(moment(), 2).fechaUNIX,
          );
        }
      }
    }
  };

  traerFaena = async (bitacora) => {

    let trabajador = await this.props.ObtenerFaenaPorId(bitacora);

    let trabajadorConDataRevision = this.obtenerDataRevision(trabajador);

    this.setState({
      trabajadorDataRevision: trabajadorConDataRevision,
    })
  };

  obtenerDataRevision = (trabajador) => {
    let str = moment().format('yyyy/MM/DD');
    let fechaHoy = convertirFechaString(moment(str), 1).fechaString;

    //EPPS
    let arregloEpps = (trabajador.EPP !== undefined ? trabajador.EPP : []);
    let arregloEppsEntregadas = (trabajador.EPP_Entregados !== undefined ? trabajador.EPP_Entregados : []);

    arregloEpps.forEach(epp => {

      epp.encontrado = false;
      arregloEppsEntregadas.forEach(eppEntregada => {

        if (epp._id.toString() === eppEntregada.idEPP.toString()) {

          let diasDiferencia = moment(eppEntregada.FechaVencimiento).diff(moment(), 'days');
          epp.cantidadDiasHabiles = diasDiferencia;

          epp.FechaVencimiento = moment(eppEntregada.FechaVencimiento).format("DD-MM-YYYY");
          epp.encontrado = true;
        }
      });
    });

    trabajador.EPP = arregloEpps;

    //TITULOS PROFESIONALES
    let arregloTitulosProfesionales = (trabajador.Titulos_Profesionales !== undefined ? trabajador.Titulos_Profesionales : []);
    let arregloTitulosProfesionalesEntregados = (trabajador.Titulos_Profesionales_Entregados !== undefined ? trabajador.Titulos_Profesionales_Entregados : []);

    arregloTitulosProfesionales.forEach(titulo => {
      titulo.encontrado = false;
      arregloTitulosProfesionalesEntregados.forEach(tituloEntregado => {

        if (titulo._id.toString() === tituloEntregado.idTituloProfesional.toString()) {
          titulo.encontrado = true;
          if (titulo.Expira) {
            let diasDiferencia = moment(tituloEntregado.FechaVencimiento).diff(moment(), 'days');
            titulo.cantidadDiasHabiles = diasDiferencia;
            titulo.FechaVencimiento = moment(tituloEntregado.FechaVencimiento).format("DD-MM-YYYY");
          }
        }
      });
    });

    trabajador.Titulos_Profesionales = arregloTitulosProfesionales;

    //ESPECIALIDADES
    let arregloEspecialidades = (trabajador.Especialidades !== undefined ? trabajador.Especialidades : []);
    let arregloEspecialidadesEntregadas = (trabajador.Especialidades_Entregadas !== undefined ? trabajador.Especialidades_Entregadas : []);

    arregloEspecialidades.forEach(especilidad => {
      especilidad.encontrado = false;
      arregloEspecialidadesEntregadas.forEach(especialidadEntregado => {
        if (especilidad._id.toString() === especialidadEntregado.idEspecialidad.toString()) {

          especilidad.encontrado = true;
          if (especilidad.Expira === true) {
            let diasDiferencia = moment(especialidadEntregado.FechaVencimiento).diff(moment(), 'days');
            especilidad.cantidadDiasHabiles = diasDiferencia;
            especilidad.FechaVencimiento = moment(especialidadEntregado.FechaVencimiento).format("DD-MM-YYYY");
          }
        }
      });
    });

    trabajador.Especialidades = arregloEspecialidades;

    // DOCUMENTOS
    let arrDocumentos = (trabajador.TiposDeDocumentos !== undefined ? trabajador.TiposDeDocumentos : []);
    let arrDocumentosIngresados = (trabajador.TiposDeDocumentosIngresados !== undefined ? trabajador.TiposDeDocumentosIngresados : []);

    // let idsDocsEntregados = [];

    // // creando arr de ids de docs entregados
    // arrDocumentosIngresados.forEach(docEntregado => {

    //   idsDocsEntregados.push(docEntregado.idDocumento);
    // })

    // // revisando cuáles docs han sido entregados
    // arrDocumentos.forEach(doc => {

    //   doc.isIngresado = false;

    //   if (idsDocsEntregados.includes(doc._id)) {
    //     doc.isIngresado = true;
    //   }

    // })

    arrDocumentos.forEach(doc => {

      doc.encontrado = false;
      arrDocumentosIngresados.forEach(docEntregado => {

        if (doc._id.toString() === docEntregado.idDocumento.toString()) {

          if (docEntregado.FechaFinValidez) {
            let diasDiferencia = moment.utc(docEntregado.FechaFinValidez).diff(moment.utc(fechaHoy), 'days');

            doc.cantidadDiasHabiles = diasDiferencia;

            doc.FechaFinValidez = convertirFechaString(moment.utc(docEntregado.FechaFinValidez), 2).fechaDDMMYYYY;
          }

          doc.isIngresado = true;
        }
      });
    });

    trabajador.TiposDeDocumentos = arrDocumentos;

    return trabajador;
  }

  mostrarModalCheckinMultiple = () => {
    this.setState({modalCheckinMultiple: true})
  };

  ocultarModalCheckinMultiple = () => {
    this.setState({modalCheckinMultiple: false})
  }

  mostrarModalEmbarco = () => {
    this.props.MostrarModalEmbarco();
  };

  ocultarModalEmbarco = () => {
    this.props.OcultarModalEmbarco();
  };

  mostrarModalDesembarco = () => {
    this.props.MostrarModalDesembarco();
  };

  ocultarModalDesembarco = () => {
    this.props.OcultarModalDesembarco();
  };

  embarcar = (bitacora) => {

    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      this.props.actualizarBitacorasParaEmbarco(
        bitacora,
        this.props.faena,
        this.state.idCentroCosto,
        this.state.fechaBusquedaInicio,
        this.state.fechaBusquedaFin,
        usuario.EmpresaSeleccionada.Empresa._id,
        this.state.fechaBusquedaInicioUnix,
        this.state.fechaBusquedaFinUnix
      );
    }
  };

  desembarcar = (bitacora) => {
    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      bitacora.SeEnvioCheckOut = true;
      this.props.enviarCorreoCheckOut(
        bitacora,
        this.props.faena,
        this.state.idCentroCosto,
        this.state.fechaBusquedaInicio,
        this.state.fechaBusquedaFin,
        usuario.EmpresaSeleccionada.Empresa._id
      );
    }
  };

  abreviarNombre = (nombres, apellidoP, apellidoM) => {

    let nCompleto = nombres + " " + apellidoP;

    // if (nCompleto.length > 20) {
    //   nCompleto = nCompleto.substring(0, 20) + "...";
    // }

    return nCompleto;
  }

  abrirCheckinMultiple = async () => {
    this.mostrarModalCheckinMultiple();
  } 

  abrirCheckIn = async (trabajador) => {
    const { empresa } = this.state;
    const objBusqueda = {
      fecha_inicio: trabajador.Fecha_Inicio,
      fecha_fin: trabajador.Fecha_Fin,
      idTrabajador: trabajador.IdTrabajador,
      idPlaza: trabajador.IdPlaza,
      idPuestoPlaza: trabajador.IdPuestoPlaza,
      IdFaena: trabajador.IdFaena,
    }
    
     //Inicio_Planificacion,Fin_Planificacion
      //Datos validos en testing
      // fecha_inicio: trabajador.Inicio_Planificacion,
      // fecha_fin: trabajador.Fin_Planificacion,
      // idTrabajador: trabajador.IdTrabajador._id,
      // idPlaza: trabajador.IdPlaza._id,
      // idPuestoPlaza: trabajador.IdPuestoPlaza,
      // IdFaena: trabajador.IdFaena._id,
    // Apellido_Materno: "BARRIA"
    // Apellido_Paterno: "SOTO"
    // Fecha_Fin: "2022-01-31T23:59:59.000Z"
    // Fecha_Inicio: "2022-01-24T00:00:00.000Z"
    // IdFaena: "6176c1f7d36bee00306f45a7"
    // IdPlaza: "6176c1f7d36bee00306f459c"
    // IdPuestoPlaza: "6176c1f7d36bee00306f459d"
    // IdTrabajador: "6176af06d36bee00306f39d4"
    // Imagen: "https://peoplemanage.blob.core.windows.net/imagenes-sistema/avatar.svg"
    // Nombre: "CESAR ISAIAS"
    // TipoRegistroBitacoraTrabajador: "5f3eedf56dd7cc57f0d3a261"
    // estadoNombre: "Reservada"
    // fechaFormateada: "24-01-2022"

    // Apellido_Materno: "Alonso"
    // Apellido_Paterno: "Dejeas"
    // Fecha_Fin: "2022-01-22T23:59:59.000Z"
    // Fecha_Inicio: "2022-01-19T00:00:00.000Z"
    // IdFaena: "61e860981a2e6a0a1c10448f"
    // IdPlaza: "61e860981a2e6a0a1c104481"
    // IdPuestoPlaza: "61e860981a2e6a0a1c104482"
    // IdTrabajador: "60132c7cc120a7002fc1da59"
    // Imagen: "https://peoplemanage.blob.core.windows.net/imagenes-sistema/avatar.svg"
    // Nombre: "Johnny Federico"
    // TipoRegistroBitacoraTrabajador: "5f3eedf56dd7cc57f0d3a261"
    // estadoNombre: "Reservada"
    // fechaFormateada: "19-01-2022"

    //const datosObtenidos = await this.props.ObtenerDatosParaCheckInTrabajador(objBusqueda,empresa);
    const datosObtenidos = await this.props.ObtenerDatosParaCheckInTrabajador(objBusqueda, empresa);

    if(datosObtenidos !== undefined && datosObtenidos instanceof Object){
      // await this.setState({
      //   nombreCentroCosto: trabajador.IdFaena.Centro_De_Costo.Nombre,
      //   nombrePlaza: trabajador.IdPlaza.NombreDePlaza,
      // });

      //this.traerFaena(datosObtenidos.trabajador);

      // let trabajador = await this.props.ObtenerFaenaPorId(bitacora);

      let trabajadorConDataRevision = this.obtenerDataRevision(datosObtenidos.trabajador);

      this.setState({
        trabajadorDataRevision: trabajadorConDataRevision,
      })

      this.mostrarModalEmbarco();
    }

  }

  statusTitulos = (titulos) => {

    let ingresados = 0;
    let noIngresados = 0;
    let vencidos = 0;

    titulos.forEach(titulo => {

      // Hay 3 outcomes: ingresado || no ingresado || vencido

      const { Expira, cantidadDiasHabiles, encontrado } = titulo

      if (encontrado === true) {

        if (Expira) {

          if (cantidadDiasHabiles > 0) {

            ingresados++;
          } else {
            vencidos++;
          }
        } else {
          ingresados++;
        }
      } else {
        noIngresados++;
      }
    });

    let alertMsg;
    let alertType;

    if (titulos.length === 0) {
      alertMsg = `No se le han requerido títulos al trabajador.`
      alertType = 'success';

    } else if (noIngresados > 0) {
      alertMsg = `Hay ${noIngresados} títulos no ingresados.`
      alertType = 'warning';

    } else if (vencidos > 0) {
      alertMsg = `Hay ${vencidos} títulos vencidos.`
      alertType = 'warning';

    } else {
      alertMsg = `Todo ingresado.`
      alertType = 'success';
    }

    return (
      <Alert message={alertMsg} type={alertType} showIcon />
    )
  }

  statusEspecialidades = (especialidades) => {

    let ingresados = 0;
    let noIngresados = 0;
    let vencidos = 0;

    especialidades.forEach(especialidad => {

      const { Expira, cantidadDiasHabiles, encontrado } = especialidad;

      if (encontrado === true) {
        if (Expira) {
          if (cantidadDiasHabiles > 0) {
            ingresados++;

          } else {
            vencidos++;

          }
        } else {
          ingresados++;

        }
      } else {
        noIngresados++;
      }
    })

    let alertMsg;
    let alertType;

    if (especialidades.length === 0) {
      alertMsg = `No se le han requerido especialidades al trabajador.`
      alertType = 'success';

    } else if (noIngresados > 0) {
      alertMsg = `Hay ${noIngresados} especialidades no ingresadas.`
      alertType = 'warning';

    } else if (vencidos > 0) {
      alertMsg = `Hay ${vencidos} especialidades vencidas.`
      alertType = 'warning';

    } else {
      alertMsg = `Todo ingresado.`
      alertType = 'success';
    }

    return (
      <Alert message={alertMsg} type={alertType} showIcon />
    )

  }

  statusDocumentos = (documentos) => {

    let ingresados = 0;
    let noIngresados = 0;
    let documentosVencidos = [];

    documentos.forEach(documento => {

      const { isIngresado } = documento;
      let { cantidadDiasHabiles } = documento;

      if (isIngresado === true) {
        ingresados++;
        if (cantidadDiasHabiles && cantidadDiasHabiles < 0) {
          cantidadDiasHabiles = -cantidadDiasHabiles + 1;
          documentosVencidos.push(cantidadDiasHabiles);
        }
      } else {
        noIngresados++;
      }
    });

    let alertMsg;
    let alertType;

    if (documentos.length === 0) {
      alertMsg = `No se le han requerido documentos al trabajador.`
      alertType = 'success';

    } else if (noIngresados > 0) {
      alertMsg = `Hay ${noIngresados} documentos no ingresados.`
      alertType = 'warning';

    } else {
      alertMsg = `Todo ingresado.`
      alertType = 'success';
    }

    if (documentosVencidos.length > 0) {
      let alertMsgVencido = `El trabajador tiene ${documentosVencidos.length} documentos vencidos por ${documentosVencidos} días`
      return (
        <>
          <Alert message={alertMsgVencido} type='warning' showIcon />
          <Alert message={alertMsg} type={alertType} showIcon />
        </>
      )
    } else {
      return (
        <Alert message={alertMsg} type={alertType} showIcon />
      )
    }
  }

  statusEpps = (epps) => {

    let ingresados = 0;
    let noIngresados = 0;
    let vencidos = 0;

    epps.forEach(epp => {

      const { encontrado, cantidadDiasHabiles } = epp;

      if (encontrado === true) {
        if (cantidadDiasHabiles > 0) {
          ingresados++;
        } else {
          vencidos++;
        }
      } else {
        noIngresados++;
      }
    })

    let alertMsg;
    let alertType;

    if (epps.length === 0) {
      alertMsg = `No se le han requerido EPP al trabajador.`
      alertType = 'success';

    } else if (noIngresados > 0) {
      alertMsg = `Hay ${noIngresados} EPP no ingresados.`
      alertType = 'warning';

    } else if (vencidos > 0) {
      alertMsg = `Hay ${vencidos} EPP vencidos.`
      alertType = 'warning';

    } else {
      alertMsg = `Todo ingresado.`
      alertType = 'success';
    }

    return (
      <Alert message={alertMsg} type={alertType} showIcon />
    )

  }

  onFinishCheckinMasiva = async () => {

    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) 
    
    {

      usuario = JSON.parse(usuario);

      const { trabajadoresCheckinMasivo } = this.state; 
      if(trabajadoresCheckinMasivo.length > 0){

        const { empresa } = this.state;

        const arregloPersonas = [];

        trabajadoresCheckinMasivo.forEach(trabajador => {
          let objBusqueda = {
            fecha_inicio: trabajador.Fecha_Inicio,
            fecha_fin: trabajador.Fecha_Fin,
            idTrabajador: trabajador.IdTrabajador,
            idPlaza: trabajador.IdPlaza,
            idPuestoPlaza: trabajador.IdPuestoPlaza,
            IdFaena: trabajador.IdFaena,
          }

          arregloPersonas.push(objBusqueda)
        });
        
        this.props.GenerarCheckInMultiple(
          arregloPersonas, 
          empresa,
          this.state.fechaBusquedaInicio,
          this.state.fechaBusquedaFin,
          this.state.fechaBusquedaInicioUnix,
          this.state.fechaBusquedaFinUnix
        );

      } else {
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "Debe seleccionar al menos un trabajador para realizar check-in",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }

    }
  }

  render() {
    let { modalCheckinMultiple } = this.state;
    return (
      <Layout style={{ height: "100%" }}>

        <Loading cargando={this.props.loading} />

        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Check-In"
            subtitulo={<div className={'header-subtitle-responsive'}>
              Selecciona a tus trabajadores y confírmalos para iniciar sus actividades
            </div>}
          />
        </Header>

        <hr className="division" />

        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >

          <p style={{ fontFamily: "MavenPro-Regular", color: "black" }}>
            Filtrar
          </p>

          <Row align="bottom" gutter={20}>

            <Col span={24}>

            </Col>

            {/* CC */}
            <Col xs={24} sm={8} md={7} lg={7} xl={5}
              className="div-input-search"
            >

              <Select
                style={{ width: 190 }}
                placeholder="Centros de costo"
                onSelect={(value, data) => {
                  if (data.value) {
                    this.setState({
                      idCentroCosto: value,
                    });
                  }
                }}
                className={'responsive-filters'}
              >
                {this.props.centroCostos.map((centroDeCostos) => (
                  <Option key={centroDeCostos._id}>
                    {centroDeCostos.Nombre}
                  </Option>
                ))}
              </Select>

            </Col>

            {/* RANGEPICKER */}
            <Col xs={24} sm={10} md={8} lg={8} xl={6}>
              <RangePicker
                locale={locale}
                format={"DD-MM-YYYY"}
                onChange={(date) => {

                  if (date != null) {

                    let fechaBusquedaI = convertirFechaString(date[0], 1).fechaString;
                    let fechaBusquedaF = convertirFechaString(date[1], 2).fechaString;

                    this.setState({
                      // fechaBusquedaInicio: momentToStringFechaInicio(date[0], databaseFormat),
                      // fechaBusquedaFin: momentToStringFechaFin(date[1], databaseFormat),

                      fechaBusquedaInicio: fechaBusquedaI,
                      fechaBusquedaFin: fechaBusquedaF,

                      // fechaBusquedaInicioUnix: momentToUnixFechaInicio(date[0]),
                      // fechaBusquedaFinUnix: momentToUnixFechaFin(date[1]),

                      fechaBusquedaInicioUnix: convertirFechaString(date[0], 1).fechaUNIX,
                      fechaBusquedaFinUnix: convertirFechaString(date[1], 2).fechaUNIX,

                      // fechaBusquedaInicioChile: momentToStringFechaInicio(date[0], chileFormat),
                      // fechaBusquedaFinChile: momentToStringFechaFin(date[1], chileFormat),

                      fechaBusquedaInicioChile: convertirFechaString(moment.utc(fechaBusquedaI), 1).fechaDDMMYYYY,
                      fechaBusquedaFinChile: convertirFechaString(moment.utc(fechaBusquedaF), 2).fechaDDMMYYYY,
                    });

                  } else {
                    this.setState({
                      fechaBusquedaInicio: "",
                      fechaBusquedaFin: "",
                      fechaBusquedaInicioUnix: null,
                      fechaBusquedaFinUnix: null,
                    });
                  }
                }}
                style={{ width: 230 }}
                className={'responsive-filters'}
              />
            </Col>

            {/* FILTRAR BUTTON */}
            <Col xs={24} sm={4} md={4} lg={4} xl={4}
              className={'filter-btn-col'}
            >

              <Button
                onClick={() => {
                  let usuario = localStorage.getItem("usuario-people-manage");

                  if (
                    usuario !== null &&
                    usuario !== "" &&
                    usuario !== undefined &&
                    usuario !== "undefined"
                  ) {

                    usuario = JSON.parse(usuario);

                    let idCentroCosto = this.state.idCentroCosto;

                    let fechaInicio = this.state.fechaBusquedaInicio;
                    let fechaFin = this.state.fechaBusquedaFin;
                    let inicioUnix = this.state.fechaBusquedaInicioUnix;
                    let finUnix = this.state.fechaBusquedaFinUnix;

                    // console.log("Fechas");

                    // console.log(fechaInicio);
                    // console.log(fechaFin);
                    // console.log(inicioUnix);
                    // console.log(finUnix);

                    this.trabajadoresFiltrados(
                      idCentroCosto,
                      fechaInicio,
                      fechaFin,
                      usuario.EmpresaSeleccionada.Empresa._id,
                      inicioUnix,
                      finUnix,
                    );
                  }
                }}
                className="filtrar-check-btn"
                type="primary"
              >
                Filtrar
              </Button>

            </Col>

            <Col xs={24} sm={4} md={4} lg={4} xl={4}
              className={'filter-btn-col'}
              style={{display: 'flex', justifyContent: 'flex-end'}}
            >

              <Button
                onClick={() => {
                  this.abrirCheckinMultiple();
                }}
                className="checkin-masivo-btn"
              >
                Check-In Masivo
              </Button>

            </Col>

          </Row>

          <p
            style={{
              fontFamily: "MavenPro-Regular",
              marginTop: "30px",
            }}
          >
            {
              `Viendo resultados de las fechas:
                ${this.state.fechaBusquedaInicioChile ? (`${this.state.fechaBusquedaInicioChile} - ${this.state.fechaBusquedaFinChile}`) : `${convertirFechaString(moment.utc(convertirFechaString(moment(), 1).fechaString), 1).fechaDDMMYYYY}`}
              `
            }
          </p>

          {this.props.checkIn.length === 0 &&
            this.props.checkOut.length === 0 ? (
            <Empty
              imageStyle={{ height: 200, marginTop: "20px" }}
              description={
                <h2 className={"seleccion-trabajador-disponible"}>
                  <b>No se han encontrado trabajadores disponibles para hacer Check-In.</b>
                  <br />
                  <b>Intenta buscar por otro rango de fechas o centro de costos.</b>
                </h2>
              }
            ></Empty>
          ) : (
            <>
              <Row style={{ marginTop: 20, marginBottom: '14px' }} align="middle">

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  {this.props.checkIn.length === 0 ? (
                    ""
                  ) : (
                    <>
                      <h3
                        style={{
                          fontFamily: "MavenPro-Bold",
                          color: "#061FCB",
                          marginTop: "0px",
                        }}
                      >
                        Trabajadores por confirmar
                      </h3>
                    </>

                  )}
                </Col>
                {/* <Col span={18}></Col> */}

              </Row>

              <Row gutter={16}>
                {this.props.checkIn.map((trabajador, index) => (
                  <Col
                    key={index}
                    xs={24} sm={12} md={12} lg={12} xl={6}
                  >
                    <Card
                      style={{ borderRadius: "16px", backgroundColor: "white", marginBottom: '14px' }}
                      className="dashboard"
                    >
                      <Row className={'card-content-row'}>

                        <Col xs={5} sm={8} md={8} lg={5} xl={7}
                          className={'trabajador-img-col'}
                        >
                          <img
                            className={'trabajador-img'}
                            style={{
                              borderRadius: "50%",
                              width: 55,
                              height: 55,
                            }}
                            //src={trabajador.IdTrabajador.Imagen}
                            src={trabajador.Imagen}
                            //src={trabajador.IdTrabajador.Imagen} funciona en testing
                            alt="avatar trabajador"
                          />
                        </Col>

                        <Col xs={19} sm={16} md={16} lg={19} xl={17}>
                          <Row align={'middle'}>

                            <Col style={{ lineHeight: "17px" }}
                              xs={13} sm={24} md={24} lg={24} xl={24}
                            >

                              <h3
                                className={'text-no-margin-mobile text-nombre-trabajador'}
                                style={{
                                  fontFamily: "MavenPro-Bold",
                                  fontSize: "12px",
                                  color: "#061FCB",
                                }}
                              >
                                {this.abreviarNombre(
                                trabajador.Nombre,
                                trabajador.Apellido_Paterno,
                                trabajador.Apellido_Materno,
                                )}
                                {/* //Funciona en testing */}
                                {/* {this.abreviarNombre(
                                  trabajador.IdTrabajador.Nombre,
                                  trabajador.IdTrabajador.Apellido_Paterno,
                                  trabajador.IdTrabajador.Apellido_Materno,
                                )} */}
                              </h3>

                              <h3
                                className={'text-no-margin-mobile'}
                                style={{
                                  fontFamily: "MavenPro-SemiBold",
                                }}
                              >
                                {/* {trabajador.Estado.Nombre} */}
                                {trabajador.estadoNombre}
                              </h3>

                              <p
                                className={'text-no-margin-mobile'}
                                style={{
                                  fontFamily: "MavenPro-Regular",
                                }}
                              >
                                {trabajador.fechaFormateada}
                              </p>

                              <p
                                className={'text-no-margin-mobile'}
                                key={index}
                                style={{
                                  fontFamily: "MavenPro-Regular",
                                }}
                              ></p>
                            </Col>

                            <Col
                              xs={11} sm={24} md={24} lg={24} xl={24}
                              style={{ height: 'fit-content' }}
                            >
                              <Button
                                onClick={async () => {

                                  this.abrirCheckIn(trabajador);
                                }}
                                className={"ver-detalle-btn"}
                              >
                                ver detalle
                              </Button>
                            </Col>

                          </Row>
                        </Col>

                      </Row>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Row style={{ marginTop: 20, marginBottom: '14px' }} align="middle">
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                  {this.props.checkOut.length === 0 ? (
                    ""
                  ) : (
                    <h3
                      style={{
                        fontFamily: "MavenPro-Bold",
                        color: "#061FCB",
                        marginTop: "30px",
                      }}
                    >
                      Trabajadores por finalizar
                    </h3>
                  )}
                </Col>
                {/* <Col span={18}></Col> */}
              </Row>

              <Row gutter={16}>
                {this.props.checkOut.map((trabajador, index) => (
                  <Col
                    key={index}
                    xs={24} sm={12} md={12} lg={12} xl={6}
                  >
                    <Card
                      style={{ borderRadius: "16px", backgroundColor: "white", marginBottom: '14px' }}
                      className="dashboard"
                    >
                      <Row className={'card-content-row'}>

                        <Col
                          xs={5} sm={8} md={8} lg={5} xl={7}
                          className={'trabajador-img-col'}
                        >
                          <img
                            className={'trabajador-img'}
                            style={{
                              borderRadius: "50%",
                              width: 55,
                              height: 55,
                            }}
                            src={trabajador.IdTrabajador.Imagen}
                            alt="avatar trabajador"
                          />
                        </Col>

                        <Col xs={19} sm={16} md={16} lg={19} xl={17}>
                          <Row align={'middle'}>

                            <Col style={{ lineHeight: "17px" }}
                              xs={13} sm={24} md={24} lg={24} xl={24}
                            >
                              <h3
                                className={'text-no-margin-mobile text-nombre-trabajador'}
                                style={{
                                  fontFamily: "MavenPro-Bold",
                                  fontSize: "12px",
                                  color: "#061FCB",
                                }}
                              >
                                {trabajador.IdTrabajador.Nombre +
                                  " " +
                                  trabajador.IdTrabajador.Apellido_Paterno +
                                  " " +
                                  trabajador.IdTrabajador.Apellido_Materno}
                              </h3>

                              <h3
                                className={'text-no-margin-mobile'}
                                style={{
                                  fontFamily: "MavenPro-SemiBold",
                                }}
                              >
                                {trabajador.Estado.Nombre}
                              </h3>

                              <p
                                className={'text-no-margin-mobile'}
                                style={{
                                  fontFamily: "MavenPro-Regular",
                                }}
                              >
                                {trabajador.fechaFormateada}
                              </p>

                              <p
                                className={'text-no-margin-mobile'}
                                style={{
                                  fontFamily: "MavenPro-Regular",
                                }}
                              >
                                {trabajador.IdFaena.Centro_De_Costo.Nombre}
                              </p>
                            </Col>

                            <Col
                              xs={11} sm={24} md={24} lg={24} xl={24}
                              style={{ height: 'fit-content' }}
                            >
                              <Button
                                disabled={trabajador.botonHabilitado}
                                onClick={async () => {

                                  await this.setState({
                                    nombreCentroCosto:
                                      trabajador.IdFaena.Centro_De_Costo.Nombre,
                                  });
                                  this.traerFaena(trabajador);

                                  this.mostrarModalDesembarco();
                                }}
                                className={"ver-detalle-btn"}
                              >
                                ver detalle
                              </Button>
                            </Col>

                          </Row>
                        </Col>

                      </Row>
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </Content>


        <Modal
          //propiedades cambiables del modal
          visible={modalCheckinMultiple}
          onCancel={this.ocultarModalCheckinMultiple}
          bodyStyle={{ borderRadius: "16px" }}
          className={'modal-container'}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
         
            <Row style={{ padding: "5px 20px 0px 20px" }}>
              
              <Col span={24} style={{ display: "flex", borderRadius:"16px" }} className="title">

                <img
                  src={checkin}
                  style={{ maxWidth: "80%" }}
                  alt="icono modal"
                />
                <PageHeader
                  className="site-page-header"
                  title={<span>Check-In Múltiple</span>}
                  subTitle={
                    `Viendo resultados de las fechas:
                      ${this.state.fechaBusquedaInicioChile ? (`${this.state.fechaBusquedaInicioChile} - ${this.state.fechaBusquedaFinChile}`) : `${convertirFechaString(moment.utc(convertirFechaString(moment(), 1).fechaString), 1).fechaDDMMYYYY}`}
                    `
                  }
                />
              </Col>

              {this.props.checkIn.length > 0 ? 
                (
                  <>
                    <Col span={24}>
                      <Checkbox.Group onChange={(value) => this.setState({trabajadoresCheckinMasivo: value}) } style={{'width': '100%'}}>
                        <Row style={{'width': '100%'}}>
                          {this.props.checkIn.map((trabajador) => (
      
                          <Col span={24}>
                            <Checkbox
                              value={trabajador}
                              defaultChecked={false}
                              style={{width: '100%'}}
                              className="checkBoxCheckinMasivo"
                            >
                              <Row>
                                <Col span={7}>
                                  {this.abreviarNombre(
                                    trabajador.Nombre,
                                    trabajador.Apellido_Paterno,
                                    trabajador.Apellido_Materno,
                                  )}
                                </Col>
                                <Col span={4}>
                                  {trabajador.fechaFormateada}
                                </Col>
                                <Col span={6}>
                                  {trabajador.CentroCostosNombre}
                                </Col>
                              </Row>

                            </Checkbox>
                          </Col>
                          ))}
                        </Row>
                      </Checkbox.Group>
                    </Col>

                    <Col span={24} style={{ textAlign: "right", marginTop: 20}}>
                      <Button
                        onClick={() => {
                          this.onFinishCheckinMasiva()
                        }}
                        className="filtrar-check-btn"
                      >
                        Aceptar
                      </Button>   
                    </Col>   
                  
                  </>
                )
                : 
                (      
                  <Col span={24}>      
                    <Empty
                      imageStyle={{ height: 200, marginTop: "20px" }}
                      description={
                        <h2 className={"seleccion-trabajador-disponible"}>
                          <b>No se han encontrado trabajadores disponibles para hacer Check-In.</b>
                          <br />
                          <b>Intenta buscar por otro rango de fechas o centro de costos.</b>
                        </h2>
                      }
                    ></Empty>
                  </Col>
                )
              }
              
            </Row>
        </Modal>

        <Modal
          //propiedades cambiables del modal
          // wrapClassName="modalCrear"
          visible={this.props.modalEmbarco}
          onCancel={this.ocultarModalEmbarco}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          className={'modal-container'}
          footer={null}
          //style={{ top: "25%" }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <Row style={{ padding: "5px 5px 5px 5px" }} gutter={20}>

            <Col span={24} style={{ display: "flex" }} className="title">
              <img
                src={checkin}
                style={{ maxWidth: "100%" }}
                alt="icono modal"
              />
              <PageHeader
                style={{ padding: "0px 10px 0px 10px" }}
                className="site-page-header"
                title="Check-In"
              // title={"Confirmar a " + this.props.trabajadorModal.Nombre + " para poder pasar asitencia"}
              />
            </Col>

            {(() => {

              if (!this.state.trabajadorDataRevision) {
                return <></>
              }

              const { Imagen, Nombre, Apellido_Paterno, Apellido_Materno, Rut,
                Titulos_Profesionales, Especialidades, TiposDeDocumentos, EPP, _id } = this.state.trabajadorDataRevision;
              let listaTabla = [
                {
                  tipo: 'Títulos profesionales',
                  elementos: Titulos_Profesionales,
                  paramRedirect: 'directFichaProfesional',
                },
                {
                  tipo: 'Especialidades',
                  elementos: Especialidades,
                  paramRedirect: 'directFichaProfesional',
                },
                {
                  tipo: 'Documentos',
                  elementos: TiposDeDocumentos,
                  paramRedirect: 'directFichaProfesional',
                },
                {
                  tipo: 'EPP',
                  elementos: EPP,
                  paramRedirect: 'directEpp',
                },
              ]

              return (
                <>
                  <Col xs={24} sm={24} md={24} lg={9} xl={7} >
                    <Card
                      style={{
                        height: "100%",
                      }}
                    >
                      <Row>

                        <Col span={24}>

                          <div className={'modal-trabajador-img-container'}>

                            <div className={'modal-trabajador-img-wrapper'}>
                              <img
                                className={'modal-trabajador-img'}
                                src={Imagen}
                                alt="foto trabajador"
                              />
                            </div>
                          </div>
                        </Col>

                        <Col span={24} align="middle">
                          <p
                            style={{ marginBottom: 0, marginTop: 10 }}
                            className="info-trabajador"
                          >
                            {`${Apellido_Paterno} ${Apellido_Materno} ${Nombre}`}
                          </p>
                          <p style={{ marginBottom: 0 }} className="info-trabajador">
                            {Rut}
                          </p>
                        </Col>


                      </Row>

                    </Card>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={15} xl={17}>
                    <Row>

                      <Col span={24}>
                        <Card className={'maven-r'}>

                          <Row style={{ marginTop: "25px", marginBottom: '20px', height: 'fit-content' }} justify={'center'}>


                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                              <table className={'check-in-table'}>
                                <tbody>

                                  <tr>
                                    <th>Centro de costos:</th>
                                    <td>{this.props.centroDeCosto}</td>
                                  </tr>

                                  <tr>
                                    <th>Desde:</th>
                                    {/* <td>{this.props.fechaInicio}</td> */}
                                    <td>{moment.utc(this.props.fechaInicio).format('DD-MM-YYYY')}</td>
                                  </tr>

                                  <tr>
                                    <th>Hasta:</th>
                                    {/* <td>{this.props.fechaFin}</td> */}
                                    <td>{moment.utc(this.props.fechaFin).format('DD-MM-YYYY')}</td>
                                    
                                  </tr>

                                  <tr>
                                    <th>Plaza a cubrir:</th>
                                    {/* <td>{this.state.nombrePlaza}</td> */}
                                    <td>{this.props.checkIn.length === 0? "N/A" :this.props.checkIn[0].puestoACubrir}</td>
                                  </tr>

                                </tbody>
                              </table>
                            </Col>

                            <Col span={24}>
                              <Divider />
                            </Col>

                            <Col span={24}>

                              <p style={{ fontWeight: '900' }}>Revisión de requisitos</p>

                              <Table
                                rowKey="_id"
                                dataSource={listaTabla}
                                bordered={true}
                                locale={{
                                  emptyText: "No se encontraron trabajadores por la búsqueda de texto.",
                                }}
                                scroll={{ x: true }}
                                showSorterTooltip={false}
                                pagination={false}
                              >
                                <Column
                                  dataIndex="tipo"
                                  key="1"
                                  title=""
                                  align='center'
                                />

                                <Column
                                  key="2"
                                  title="Status"
                                  align='center'
                                  render={(valores) => {

                                    switch (valores.tipo) {
                                      case 'Títulos profesionales':
                                        return this.statusTitulos(valores.elementos);

                                      case 'Especialidades':
                                        return this.statusEspecialidades(valores.elementos);

                                      case 'Documentos':
                                        return this.statusDocumentos(valores.elementos);

                                      case 'EPP':
                                        return this.statusEpps(valores.elementos);

                                    }
                                  }}
                                />

                                <Column
                                  dataIndex='paramRedirect'
                                  title="Revisar en ficha"
                                  key="3"
                                  align='center'
                                  render={(paramRedirect) => (
                                    <Space size="middle">
                                      <Button
                                        onClick={() => {
                                          window.open(`/CalendarioTrabajadorNew?Trabajador=${_id}&${paramRedirect}=true`);
                                        }}
                                        icon={<EyeFilled style={{ fontSize: '21px' }} />}
                                        className="detalle-trab-btn"
                                      />
                                    </Space>
                                  )}
                                />
                              </Table>

                              {/* <p style={{ fontSize: 'small', marginTop: '5px' }}>*Estos requisitos y vencimientos se registran según la fecha actual</p> */}
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginTop: "40px" }}>
                              <Button
                                style={{
                                  marginRight: "15px",
                                  //marginBottom: "120px",
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                  display: 'block'
                                }}
                                onClick={async () => {
                                  this.embarcar(this.props.bitacora);
                                }}
                                className={!this.state.Editar ? "crear-embarco-button-gris" : "crear-embarco-button"}
                                disabled={!this.state.Editar}
                              >
                                Generar check-in
                              </Button>
                            </Col>

                          </Row>

                        </Card>
                      </Col>

                    </Row>
                  </Col>
                </>
              )
            })()}
          </Row>
        </Modal>

        <Modal
          //propiedades cambiables del modal
          visible={this.props.modalDesembarco}
          onCancel={this.ocultarModalDesembarco}
          //propiedades obligatorias para modal mantenedor
          // bodyStyle={{ borderRadius: "16px" }}
          className={'modal-container'}
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <Row style={{ padding: "5px 20px 0px 20px" }} gutter={20}>

            <Col span={24} style={{ display: "flex" }} className="title">
              <img
                src={checkout}
                style={{ maxWidth: "100%" }}
                alt="icono modal"
              />
              <PageHeader
                style={{ padding: "0px 10px 0px 10px" }}
                className="site-page-header"
                title="Check-Out"
              />
            </Col>

            <Col xs={24} sm={24} md={24} lg={9} xl={7}>
              <Card
                style={{
                  height: "100%",
                }}
              >
                <Row>

                  <Col span={24} align="middle">
                    <img
                      className={'modal-trabajador-img'}
                      src={
                        this.props.trabajadorModal === null
                          ? ""
                          : this.props.trabajadorModal.Imagen
                      }
                      alt="foto trabajador"
                    />
                  </Col>

                  <Col span={24} align="middle">
                    <p
                      style={{ marginBottom: 0, marginTop: 10 }}
                      className="info-trabajador"
                    >
                      {this.props.trabajadorModal === null
                        ? ""
                        : this.props.trabajadorModal.Nombre +
                        " " +
                        this.props.trabajadorModal.Apellido_Paterno +
                        " " +
                        this.props.trabajadorModal.Apellido_Materno}
                    </p>
                    <p style={{ marginBottom: 0 }} className="info-trabajador">
                      {this.props.trabajadorModal === null
                        ? ""
                        : this.props.trabajadorModal.Rut}
                    </p>
                  </Col>

                </Row>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={24} lg={15} xl={17}>
              <Row>
                <Col span={24}>
                  <Card>
                    <Row style={{ marginTop: "50px", height: 'fit-content' }} justify={'center'}>

                      <Col xs={10} sm={10} md={12} lg={12} xl={7} style={{ fontFamily: "MavenPro-Bold" }}>
                        <Row>Centro de costos:</Row>
                        <br />
                        <Row>Desde:</Row>
                        <br />
                        <Row>Hasta:</Row>
                      </Col>

                      <Col xs={10} sm={10} md={12} lg={12} xl={9}>
                        <Row>{this.props.centroDeCosto}</Row>
                        <br />
                        <Row>{this.props.fechaInicio}</Row>
                        <br />
                        <Row>{this.props.fechaFin}</Row>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={24} xl={8} style={{ marginTop: "40px" }}>
                        <Button
                          style={{
                            marginRight: "15px",
                            //marginBottom: "120px",
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            display: 'block'
                          }}
                          onClick={async () => {
                            // let idTrabajador =
                            //   this.props.trabajadorModal === null
                            //     ? ""
                            //     : this.props.trabajadorModal._id;
                            // let idCentroCosto = this.state.idCentroCosto;
                            // let fechaInicio = this.state.fechaBusquedaInicio;
                            // let fechaFin = this.state.fechaBusquedaFin;
                            // if (fechaInicio && fechaFin) {
                            //   await this.desembarcar(
                            //     idTrabajador,
                            //     idCentroCosto,
                            //     fechaInicio,
                            //     fechaFin
                            //   );
                            // } else {
                            await this.desembarcar(this.props.bitacora);
                          }}
                          className={!this.state.Editar ? "crear-embarco-button-gris" : "crear-embarco-button"}
                          disabled={!this.state.Editar}
                        >
                          Generar check-out
                        </Button>
                      </Col>

                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>

          </Row>
        </Modal>

        <span>
          <style jsx="true">
            {`
            @media screen and (max-width: 767px) {
              .ant-layout-content {
                margin: 0px !important;
              }
            }

            @media screen and (max-width: 575px) {
              .ant-card-body {
                padding: 10px !important;
              }
            }
          `}
          </style>
        </span>

      </Layout >
    );
  }
}

const EstadosAPropiedades = ({
  FiltroTrabajadoresReducer: {
    loading,
    error,
    success,
    tablaCargando,
    trabajadores,
    centroCostos,
    centroDeCosto,
    faenas,
    checkIn,
    checkOut,
    fechaInicio,
    fechaFin,
 
    modalEmbarco,
    modalDesembarco,
    rangoFechaPlaza,
    trabajadorModal,
    bitacoraTrabajador,

    faena,
    idTrabajador,
    bitacora,
  },
}) => {
  return {
  
    modalEmbarco,
    modalDesembarco,
    loading,
    centroDeCosto,
    error,
    success,
    tablaCargando,
    trabajadores,
    centroCostos,
    faenas,
    fechaInicio,
    fechaFin,
    checkIn,
    checkOut,
    rangoFechaPlaza,
    trabajadorModal,
    bitacoraTrabajador,

    faena,
    idTrabajador,
    bitacora,
  };
};

export default connect(
  EstadosAPropiedades,
  FiltroTrabajadoresActions
)(FiltroTrabajadores);
