import {
  ERROR,
  INGRESAR_TRABAJADOR,
  LOADING,
  MOSTRAR_MODAL_TRABAJADOR,
  OCULTAR_MODAL_TRABAJADOR,
  OBTENER_TRABAJADORES_MANTENEDOR,
 
  ELIMINAR_TRABAJADOR_MANTENEDOR,
  OBTENER_TRABAJADOR_MANTENEDOR,
  OCULTAR_MODAL_TRABAJADOR_EDITAR,
  EDITAR_TRABAJADOR_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OBTENER_CARGOS_TRABAJADOR,
  OBTENER_ESTADOS_TRABAJADOR,
  OBTENER_ESPECIALIDADES_TRABAJADORES,
  OBTENER_TITULOS_TRABAJADORES,
  OBTENER_REGIMEN_TRABAJO,
  OBTENER_EPPS_TRABAJADORES,
  OBTENER_REGIONES_MANTENEDOR_TRABAJADOR,
  OBTENER_PROVINCIAS_MANTENEDOR_TRABAJADOR,
  OBTENER_COMUNAS_MANTENEDOR_TRABAJADOR,
  INSERTAR_COMUNAS_MANTENEDOR_TRABAJADOR,
  INSERTAR_PROVINCIAS_MANTENEDOR_TRABAJADOR,
  OBTENER_DOCUMENTOS_MANTENEDOR_TRABAJADOR,
  // PARAR_LOADING,
} from "../types/MantenedorTrabajadorTypes";
import API from "../Api/api";
import { obtenerRespueta } from "../../funcionesGenericas/respuestas";
import Swal from "sweetalert2";
import moment from "moment";
import { convertirFechaString } from "../../funcionesGenericas/fechas"

//controlada
export const ObtenerRegiones = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Regiones`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();

        dispatch({
          type: OBTENER_REGIONES_MANTENEDOR_TRABAJADOR,
          payload: data.data,
        });
        break;

      case 204:
        dispatch({ type: OBTENER_REGIONES_MANTENEDOR_TRABAJADOR, payload: [] });

        break;
      default:
        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const ObtenerProvincias = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Provincia`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();

        dispatch({
          type: OBTENER_PROVINCIAS_MANTENEDOR_TRABAJADOR,
          payload: data.data,
        });
        break;

      case 204:
        dispatch({
          type: OBTENER_PROVINCIAS_MANTENEDOR_TRABAJADOR,
          payload: [],
        });

        break;
      default:
        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const ObtenerComunas = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Comunas`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();
        dispatch({
          type: OBTENER_COMUNAS_MANTENEDOR_TRABAJADOR,
          payload: data.data,
        });
        break;

      case 204:
        dispatch({ type: OBTENER_COMUNAS_MANTENEDOR_TRABAJADOR, payload: [] });

        break;
      default:
        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "¡Ups!",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const InsertarComunas = (data) => (dispatch) => {
  dispatch({ type: INSERTAR_COMUNAS_MANTENEDOR_TRABAJADOR, payload: data });
};

//controlada
export const InsertarProvincias = (data) => (dispatch) => {
  dispatch({ type: INSERTAR_PROVINCIAS_MANTENEDOR_TRABAJADOR, payload: data });
};


export const validarRutUsuarioSistema = (trabajador) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const url = `${API}/Trabajadores/validarRut`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(trabajador),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Rut disponible para ingresar",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
        });

        dispatch({
          type: ERROR,
          payload: "Se ingresó el usuario correctamente",
        });
        break;
      case 409:
        Swal.fire({
          title: "Uups",
          text: "El rut del trabajador ya está registrado en esta empresa.",
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data });
        break;
      case 500:
        console.log(data);
        Swal.fire({
          title: "Uups",
          text: "Error devuelto por el servicio, status: " + status,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data });
        break;
      default:
        //const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data });
        break;
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR, payload: error });
  }
};

export const CrearTrabajador = (trabajador) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const url = `${API}/Trabajadores`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(trabajador),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Se ingresó trabajador correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: INGRESAR_TRABAJADOR,
          payload: "Se ingresó el usuario correctamente",
        });
        break;
      case 409:
        Swal.fire({
          title: "Uups",
          text: "El rut del trabajador ya está registrado en esta empresa.",
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data });
        break;
      case 500:
        console.log(data);
        Swal.fire({
          title: "Uups",
          text: "Error devuelto por el servicio, status: " + status,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data });
        break;
      default:
        //const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data });
        break;
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerTrabajadores = (estado, cargo, busqueda, limit, skip, check) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;

      let str = moment().format('YYYY-MM-DD');
      let dateIn = convertirFechaString(moment(str), 1).fechaString;
      let dateOut = convertirFechaString(moment(str), 2).fechaString;

      estado = estado === undefined || estado === 'Libre' ? 'Libre' : estado;

      const url = estado !== 'Finiquitado' ? 
      // `${API}/Trabajadores/Mantenedor/new?empresa=${id}&dateIn=${dateIn}&dateOut=${dateOut}&estado=${estado}&cargo=${cargo}&busqueda=${busqueda}&limit=${limit}&skip=${skip}`
      `${API}/Trabajadores/Mantenedor/new?empresa=${id}&dateIn=${dateIn}&dateOut=${dateOut}&estado=${estado}&cargo=${cargo}&busqueda=${busqueda}&limit=${limit}&skip=${skip}&check=${check}`  
      : 
      `${API}/TrabajadoresFiniquitados/Mantenedor/new?empresa=${id}&dateIn=${dateIn}&dateOut=${dateOut}&cargo=${cargo}&busqueda=${busqueda}&limit=${limit}&skip=${skip}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      const data = await response.json();

      switch (status) {
        case 200:
        case 201:
          let trabajadores = data.Trabajadores;
          let cantidadTrabajadores = data.CantidadTrabajadores;

          trabajadores.forEach((trabajador) => {
            trabajador.nombre_completo = `${trabajador.Nombre !== undefined
              ? trabajador.Nombre
              //.replace(/ /g, "")
              : ""
              } ${trabajador.Apellido_Paterno !== undefined
                ? trabajador.Apellido_Paterno.replace(/ /g, "")
                : ""
              } ${trabajador.Apellido_Materno !== undefined
                ? trabajador.Apellido_Materno.replace(/ /g, "")
                : ""
              }`;
          });

          dispatch({
            type: OBTENER_TRABAJADORES_MANTENEDOR,
            payload: {trabajadores, cantidadTrabajadores},
          });

          break;

        default:
          const error = data.err.message.toString();
          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerTitulos = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      const id = usuario.EmpresaSeleccionada.Empresa._id;

      const url = `${API}/Titulos/Vigentes?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;

      let data;
      switch (status) {
        case 200:
        case 201:
          data = await response.json();

          let titulos = data.data;

          dispatch({
            type: OBTENER_TITULOS_TRABAJADORES,
            payload: titulos,
          });
          break;

        default:
          data = await response.json();
          const error = data.err.message.toString();
          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerEspecialidades = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/Especialidades/Vigentes?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();

          let especialidades = data.data;

          dispatch({
            type: OBTENER_ESPECIALIDADES_TRABAJADORES,
            payload: especialidades,
          });

          break;

        default:
          data = await response.json();
          const error = data.err.message.toString();
          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerEPPs = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/Epp/VigenciaTrue?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();

          let epps = data.data;

          // console.log('epps ingresados en el sistema');
          // console.log(epps);

          dispatch({
            type: OBTENER_EPPS_TRABAJADORES,
            payload: epps,
          });

          return epps;

        default:
          data = await response.json();
          const error = data.err.message.toString();
          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerRegimenes = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/RegimenDeTrabajo/Vigentes?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();

          let regimenes = data.regimenes;

          dispatch({
            type: OBTENER_REGIMEN_TRABAJO,
            payload: regimenes,
          });
          break;

        default:
          data = await response.json();
          const error = data.err.message.toString();
          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerDocumentos = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/TiposDeDocumentos/Vigentes?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          // console.log(data);
          let documentos = data.tiposDeDocumentos;

          dispatch({
            type: OBTENER_DOCUMENTOS_MANTENEDOR_TRABAJADOR,
            payload: documentos,
          });
          break;

        default:
          data = await response.json();
          const error = data.err.message.toString();
          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerCargosTrabajador = (idEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/CargosTrabajadores/Vigentes?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let cargosTrabajadores = data.data;

        dispatch({
          type: OBTENER_CARGOS_TRABAJADOR,
          payload: cargosTrabajadores,
        });

        return (cargosTrabajadores);

      default:
        const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerEstadosTrabajador = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/TipoRegistroBitacoraTrabajador`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let estadosTrabajadores = data.data;

        dispatch({
          type: OBTENER_ESTADOS_TRABAJADOR,
          payload: estadosTrabajadores,
        });

        return (estadosTrabajadores);

      default:
        const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerTrabajador = (idTrabajador) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Trabajadores/ParaEditar/${idTrabajador}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let trabajador = data.trabajador;
        trabajador.validacionRut = "success";
        dispatch({ type: OBTENER_TRABAJADOR_MANTENEDOR, payload: trabajador });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarTrabajador = (idTrabajador) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Trabajadores/${idTrabajador}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El trabajador fue eliminado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        // .then((value) => {
        //   window.location.reload();
        // });
        dispatch({ type: ELIMINAR_TRABAJADOR_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarTrabajador = (Trabajador) => async (dispatch) => {
  dispatch({ type: LOADING });
  const idTrabajador = Trabajador._id;
  delete Trabajador._id;
  try {
    const url = `${API}/Trabajadores/${idTrabajador}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(Trabajador),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El trabajador fue actualizado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        // .then((value) => {
        //   window.location.reload();
        // });

        dispatch({ type: EDITAR_TRABAJADOR_MANTENEDOR });
        break;

      default:
        const error = data.err.message;

        console.error(error);
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ERROR, payload: error });
  }
};

export const TomarValoresEditar = (nombreCampo, valor) => (dispatch) => {
  let objeto = {
    nombreCampo,
    valor,
  };
  dispatch({ type: TOMAR_VALORES_EDITAR, payload: objeto });
};

export const MostrarModalTrabajador = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_TRABAJADOR });
};

export const OcultarModalTrabajador = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_TRABAJADOR });
};

export const OcultarModalTrabajadorEditar = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_TRABAJADOR_EDITAR });
};

//   export const DescargarExcel = () => async (dispatch) => {
//     dispatch({ type: LOADING });
//     try {
//       const url = `${API}/Usuario/Descargar/Excel`;
//       let status;
//       fetch(url)
//         .then((response) => {
//           status = response.status;
//           return response.blob();
//         })
//         .then((blob) => {
//           switch (status) {
//             case 200:
//             case 201:
//               const url = window.URL.createObjectURL(blob);
//               const a = document.createElement("a");
//               a.href = url;
//               a.download = `Usuarios_${obtenerFechaHoy()}.xlsx`;
//               document.body.appendChild(a);
//               a.click();
//               a.remove();

//               dispatch({ type: PARAR_LOADING });
//               break;
//             default:
//               Swal.fire({
//                 title: "Uups",
//                 text:
//                   "Error status: " +
//                   status +
//                   " al realizar la descarga del archivo",
//                 icon: "error",
//               });

//               dispatch({ type: PARAR_LOADING });
//               break;
//           }
//         });
//     } catch (error) {
//       dispatch({ type: ERROR, payload: error });
//     }
//   };
