import React, { useEffect } from "react";
import documento from "../../img/icons/documento.svg";
import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Upload,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";

const FormularioDocumentosEditar = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {
    props.EditarDocumento(values);
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };


  useEffect(() => {
    let { Descripcion, Nombre, Vigencia  } = props.Formulario;
    
    form.setFieldsValue({
      Descripcion,
      Nombre,
      Vigencia,
    });
  });

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={documento} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Editar Documento"
          />
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Form.Item
              name="Nombre"
              label="Nombre:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir nombre de documento",
                  min: 2,
                  max: 30,
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Nombre", valor);
                }}
                maxLength={30}
                name="Nombre"
                className="campos"
              />
            </Form.Item>

            <Form.Item
              label="Ficha del documento"
              style={{ textAlign: "left" }}
              name="Archivo"
            >
              <Upload
                fileList={props.arregloDocumentos}
                accept={"pdf"}
                listType="picture"
                multiple={false}
                beforeUpload={(archivo, listaArchivos) => {
                  var reader = new FileReader();
                  reader.readAsDataURL(archivo);
                  reader.onload = function () {
                    props.ObtenerArchivo(reader.result);

                    let arreglo = [{
                      uid: '1',
                      name: archivo.name,
                      status: 'done',
                      url: reader.result,
                    }];
                    
                    props.cambiarValorArregloArchivos(arreglo,true);
                  };
                  reader.onerror = function (error) {
                    console.log("Error: ", error);
                  };
                }}
                onRemove={(e)=>{
                  let arregloVacio =[];
                  props.cambiarValorArregloArchivos(arregloVacio,false);
                }}
                action={"#"}
                className="upload-list-inline"
                method={"GET"}
                openFileDialogOnClick={true}
              >
                <Button 
                disabled={props.subirArchivoDeshabilitado}
                className="subir-archivos">
                  <UploadOutlined /> Upload
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item
              label="Descripción"
              name="Descripcion"
              rules={[
                {
                  min: 1,
                  required: true,
                  message: "Debe introducir una descripcion",
                },
              ]}
              hasFeedback
            >
              <Input
                name="Descripcion"
                className="campos"
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Descripcion", valor);
                }}
                placeholder="Descripcion del documento"
              />
            </Form.Item>
            <Form.Item label="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                checked={props.Formulario.Vigencia}
                onChange={() => {
                  let nuevoValor =
                    props.Formulario.Vigencia === true ? false : true;
                  props.TomarValoresEditar("Vigencia", nuevoValor);
                }}
                className="switch"
              />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Editar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormularioDocumentosEditar;
