import React, { Component } from "react";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import { connect } from "react-redux";
import * as detalleFaenaActions from "../../Redux/actions/DetalleFaenaActions";
import Loading from "../../components/loading/Loading";
import ImgTitulos from "../../img/icons/icon_titu.png";
import IconRelevo from "../../img/icons/IconRelevo.svg";
import flecha from "../../img/icons/flecha.svg";

//time-line
import Timeline from "react-calendar-timeline";
import {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
  //dateOrMomentToMomentTimeZoneChile,
} from "react-calendar-timeline";

import containerResizeDetector from "react-calendar-timeline/lib/resize-detector/container";

import "../../../node_modules/react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import "../../css/calendarioLine.css";
import "./DetalleFaena.css";
import "./DetalleActividad.css";

import icon_error_equis from "../../img/icons/error-equis.svg";
import icon_arrow_green from "../../img/icons/arrow.svg";
import icon_persona_agregar from "../../img/icons/persona-agregar.svg";
import reloj from "../../img/icons/Historico.svg";
import ticket from "../../img/icons/ticket.png";
import descargarExcel from "../../img/icons/descargarExcel.svg";

import {
  detalleActividad,
  traerBitacora,
  alargarPlanificacion,
  acortarPlanificacion,
  buscarTrabajadoresEliminarHoras,
  eliminarPlanificacion,
  traerTrabajadoresAsignar,
  agregarBitacorasPlaza,
  agregarBitacorasTrabajador,
  traerCargosTrabajador,
  traerTitulosTrabajador,
  traerEspecialidadesTrabajador,
  agregarNuevaPlaza,
  agregarPuestoTrabajo,
  traerPlazasTrabajador,
  editarPuestoTrabajo,
  suprimirPlaza,
} from "./detalleActividadApi";

import {
  Layout,
  Button,
  Col,
  Row,
  Drawer,
  Input,
  DatePicker,
  Empty,
  notification,
  Timeline as TimelineAntd,
  Select,
  Modal,
  Card,
  Table,
  Badge,
  Collapse,
  Form,
  Radio,
  InputNumber,
  Tooltip
} from "antd";

import {
  EditFilled,
  DeleteFilled,
  PlusCircleOutlined,
  EyeFilled,
  BlockOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import accesosPagina from "../../funcionesGenericas/accesosUsuario";
import coloresGenerica from "../../funcionesGenericas/coloresEstadoTrabajo";
import { removeAccents } from "../../funcionesGenericas/textos";
import {
  convertirFechaStringNueva,
  convertirFechaString,
  // corregirZonaHoraria,
} from "../../funcionesGenericas/fechas";

import IconCambiarActividad from "../../img/icons/cambiarActividadModal.svg";
import Transbordo from "../../components/transbordo/transbordo";
import { P } from "@antv/g2plot";
import { map } from "@antv/util";

const queryString = require("query-string");
const { RangePicker } = DatePicker;
const { Header, Content } = Layout;
const { Search } = Input;
const { Panel } = Collapse;

const columns = [
  {
    dataIndex: "marcaPlazaNoCubierta",
    key: "marcaPlazaNoCubierta",
    className: "columna-marca-no-cubierta-tabla-actividades",
    render: (value) => (
      <>
        {value > 0 ? (
          <Badge
            status="processing"
            color="#FF0000"
            className="badge-no-asignado-detalle-actividad"
          />
        ) : (
          <Badge color="#fff" className="badge-no-asignado-detalle-actividad" />
        )}
      </>
    ),
  },
  {
    title: "Plazas",
    // dataIndex: "plazas",
    key: "plazas",
    className: "columna-plazas-tabla-actividades",
    render: (value) => (
      <>
        {!value.NuevaPlaza ? (
          <label>{value.plazas}</label>
        ) : (
          <>
            <label style={{ marginRight: "2px" }}>{value.plazas}</label>
            <span style={{ color: "red" }}>**</span>
          </>
        )}
      </>
    ),
  },
  {
    title: "Relevo",
    className: "columna-relevo-tabla-actividades",
  },
  {
    title: "Días Asignados",
    dataIndex: "diasAsignadosTotal",
    key: "diasAsignadosTotal",
    className: "columna-dias-asignados-tabla-actividades",
  },
  {
    title: "Días No Asignados",
    dataIndex: "diasNoAsignadosTotal",
    key: "diasNoAsignadosTotal",
    className: "columna-dias-no-asignados-tabla-actividades",
  },
  {
    title: "Cronograma",
    dataIndex: "cronogramaTotal",
    key: "cronogramaTotal",
    className: "columna-cronograma-tabla-actividades",
  },
  {
    title: "Acciones",
    dataIndex: "accionesTotal",
    key: "accionesTotal",
    className: "columna-acciones-tabla-actividades",
  },
];
class DetalleFaena extends Component {
  formRefAgregarNuevaPlaza = React.createRef();
  formRefAgregarPuestoTrabajo = React.createRef();
  formRefEditarPuestoTrabajo = React.createRef();
  formRefEliminarPuestoTrabajo = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      idCentroCosto: "",
      nombreCentroCosto: "",

      idActividad: "",
      nombreActividad: "",

      plazas: [],
      plazasActividad: [],
      plazasFiltro: [],
      plazasSeleccionadas: [],

      fechasSeleccionadas: [],
      detalleTrabajador: {},

      drawerAsignar: false,
      drawerEditar: false,
      drawerEliminar: false,

      trabajadoresAsignar: [],
      trabajadoresFiniquitados: [],

      modalTransbordo: false,
      nombreTrabajadorTransbordo: "",

      trabajadorTransbordo: null,

      fechasEditarPlanificacion: [],

      fechasBloqueadasEditar: [],
      fechaBloqueo: "",
      fechaBitacora: "",

      PlazaCubrir: null,

      //
      idCupoPlaza: "",
      idPlaza: "",

      //fechas para rellenar horas
      fechaInicio: "",
      fechaFin: "",

      //fechas plaza no se pueden ingresar
      arregloFechasInhabilitada: [],
      idUsuario: "",
      trabajadorSearch: "",
      dates: null,

      //arreglo con las fechas que se desean editar
      fechasEditarSelect: [],
      fechasEditarTexto: [],

      //fechas de la busqueda de la faena
      busquedaFaenaInicio: null,
      busquedaFaenaFin: null,
      busquedaIdFaena: null,

      //fechas string para mostrar visualmente
      fechaInicioStringFaena: "",
      fechaFinStringFaena: "",

      //fechas de busqueda por defecto
      defaultDates: [],

      trabajadoresFechas: [],
      trabajadoresFiltrados: [],

      gruposFiltrados: [],

      //transbordo
      mostrarModalTransbordo: false,
      trabajadorTransbordo: null,

      // centroCostoTransbordo: null,
      plazaTransbordo: null,
      puestoPlazaTransbordo: null,
      fechaTrabajoOrigenDesde: new Date(),
      fechaTrabajoOrigenHasta: new Date(),
      random: null,

      modalVerDatosTrabajador: false,
      nombreTrabajadorAsignar: "",
      titulosTrabajadorAsignar: [],
      especialidadesTrabajadorAsignar: [],
      regimenTrabajadorAsignar: "",
      contratoTrabajadorAsignar: "",
      contratosTrabajador:[],
      fechasTrabajadorAsignar: [],
      fechasTrabajadorAsignarIndex: 0,

      ///////////////////////control de meses bloqueados
      mesBloqueado: false,
      mensajeMesBloqueado: "",

      ///////////////////////cantidad de dias de busqueda
      cantidadDiasBusqueda: 0,
      ///////////////////////marcar los dias que son relevos
      diasRelevo: [],
      //////////////////////fechas habilitadas pasa seleccionar editar
      fechasHabilitadas: [],
      /////////////////////cantidad de dias de edicion disponibles (utilizados para editar)
      diasDisponibles: 0,

      ////////////////////////////////requisitos plaza
      requisitosPlaza: {},

      //para realizar los filtros
      trabajadoresAsignarFiltrados: null,
      trabajadoresFiniquitadosFiltrados: null,

      Ver: false,
      Editar: false,
      Eliminar: false,

      //agregar plaza a la actividad
      selectModificarActividad: undefined,
      modalModificarActividad: false,
      contenidoModificarActividad: "none",
      agregarPlazaNueva: false,
      agregarPuestoTrabajo: false,
      editarPuestoTrabajo: false,
      eliminarPuestoTrabajo: false,
      cargosTrabajadores: [],
      titulosObligatorios: [],
      titulosOpcionales: [],
      especialidadesObligatorias: [],
      especialidadesOpcionales: [],
      plazasTrabajador: [],
      puestoPlazaTrabajador: [],
      puestoPlazaSeleccionado: null,
      fechasDisponibilidad: [],
      fechasDisponiblesSeleccionadas: [],

      bloquearNuevoRango: true,
    };
  }

  async componentWillMount() {
    const accesos = await accesosPagina.obtenerAccesosPagina(
      "PlanificacionDetalleActividad",
      "AsignacionesDetalle"
    );

    this.setState({
      Ver: accesos.Ver,
      Editar: accesos.Editar,
      Eliminar: accesos.Eliminar,
    });
  }

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      this.setState({ loading: true });

      usuario = JSON.parse(usuario);

      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      let parametros = queryString.parse(this.props.location.search);
      let inicioMesStr = moment().startOf("month").format("YYYY-MM-DD");
      let finMesStr = moment().endOf("month").format("YYYY-MM-DD");

      let inicioMes = convertirFechaString(moment(inicioMesStr), 1).fechaString;
      let finMes = convertirFechaString(moment(finMesStr), 2).fechaString;

      if (parametros.fechaInicioBusqueda && parametros.fechaFinBusqueda) {
        inicioMes = parametros.fechaInicioBusqueda;
        finMes = parametros.fechaFinBusqueda;
      }

      const bitacoraTrabajadores = await detalleActividad(
        idEmpresa,
        parametros.idFaena,
        inicioMes,
        finMes,
        null,
        true
      );

      // console.log("bitacoraTrabajadores");
      // console.log(bitacoraTrabajadores);

      const idCentroCosto = bitacoraTrabajadores.Centro_Costo._id;
      const nombreCentroCosto = bitacoraTrabajadores.Centro_Costo.Nombre;

      const idActividad = bitacoraTrabajadores._id;
      const nombreActividad = bitacoraTrabajadores.Nombre;

      let plazasActividad = [...bitacoraTrabajadores.estructuraPlazas];

      // console.log("bitacoraTrabajadores.PlazasBuscadas");
      // console.log(bitacoraTrabajadores.PlazasBuscadas);

      let plazasFiltro = bitacoraTrabajadores.PlazasBuscadas;
      // console.log("plazasFiltro");
      // console.log(plazasFiltro);

      let fechaInicioActividad = convertirFechaString(
        moment.utc(bitacoraTrabajadores.Fecha_Inicio),
        1
      ).fechaString;
      let fechaFinActividad = convertirFechaString(
        moment.utc(bitacoraTrabajadores.Fecha_Fin),
        2
      ).fechaString;

      this.setState({
        idCentroCosto,
        nombreCentroCosto,
        idActividad,
        nombreActividad,
        plazasActividad,
        plazasFiltro,
        fechasActividad: [
          moment.utc(fechaInicioActividad),
          moment.utc(fechaFinActividad),
        ],
        fechasSeleccionadas: [
          moment.utc(bitacoraTrabajadores.fechaInicioBusquedaFaena),
          moment.utc(bitacoraTrabajadores.fechaFinBusquedaFaena),
        ],
        loading: false,
      });

      this.revisarRedireccion();
    }
  }

  traerTrabajadorEditar = async (values) => {
    const { fechasSeleccionadas } = this.state;

    let fechaInicioStr = fechasSeleccionadas[0].format("YYYY-MM-DD");
    let fechaFiStr = fechasSeleccionadas[1].format("YYYY-MM-DD");

    let inicio = convertirFechaString(moment(fechaInicioStr), 1).fechaString;
    let fin = convertirFechaString(moment(fechaFiStr), 2).fechaString;

    // console.log("VALUES EDITAR TRABAJADOR");
    // console.log(values);

    let { diasValidosPlanificar, diasRelevo, diasDisponibles } =
      await traerBitacora(
        values.idActividad,
        values.idPlaza,
        values.idPuestoPlaza,
        values.inicioPlanificacion,
        values.finPlanificacion,
        values.idTrabajador,
        inicio,
        fin,
        values.IdRangoFechas
      );

    this.setState({
      fechasEditarPlanificacion: [
        moment.utc(values.inicioPlanificacion),
        moment.utc(values.finPlanificacion),
      ],
      //fechaBloqueo: fechaBloqueo,
      fechasHabilitadas: diasValidosPlanificar,
      diasRelevo: diasRelevo,
      drawerEditar: true,
      detalleTrabajador: values,
      diasDisponibles: diasDisponibles,
      //fechasBloqueadasEditar,
    });
  };

  traerTrabajadorEliminar = (values) => {
    this.setState({ drawerEliminar: true, detalleTrabajador: values });
  };

  redireccionarDetalleTrabajador = (value) => {
    const { idTrabajador } = value;
    let ruta = `/CalendarioTrabajadorNew?Trabajador=${idTrabajador}`;
    window.open(ruta, "_blank");
  };

  asignarTrabajadorPlaza = async (values) => {
  
    this.setState({ loading: true });

    // console.log(values)
    // const strFechaInicioActividad = moment.utc(values.inicioPlanificacion).format('YYYY-MM-DD');
    // const strFechaFimActividad = moment.utc(values.finPlanificacion).format('YYYY-MM-DD');

    // const fechaInicioActividad = moment(strFechaInicioActividad);
    // const fechaFinActividad = moment(strFechaFimActividad);

    const trabajadores = await traerTrabajadoresAsignar(
      values.idActividad,
      values.idPlazaPlantilla,
      values.inicioPlanificacion,
      values.finPlanificacion,
      values.idPuestoPlaza,
      values.idPlazaEsquema,
      values.IdRangoFechas
    );

    if (trabajadores) {
      if (trabajadores.fechasBloqueadas) {
        this.setState({
          drawerAsignar: true,
          loading: false,
          mesBloqueado: true,
          mensajeMesBloqueado: trabajadores.texto,
          cantidadDiasBusqueda: 0,
        });
      } else {
        this.setState({
          drawerAsignar: true,
          trabajadoresAsignar: trabajadores.trabajadoresDisponibles,
          trabajadoresFiniquitados: trabajadores.trabajadoresFiniquitados,
          loading: false,
          mesBloqueado: false,
          mensajeMesBloqueado: "",
          cantidadDiasBusqueda: trabajadores.cantidadDiasBusqueda,
          diasRelevo: trabajadores.diasRelevo,
          requisitosPlaza: trabajadores.requisitosPlaza,
        });
      }
    } else {
      this.setState({
        loading: false,
        mesBloqueado: false,
        mensajeMesBloqueado: "",
        cantidadDiasBusqueda: 0,
      });
    }
  };

  columnsTablaExpandible = [
    {
      dataIndex: "plazaNoCubierta",
      key: "plazaNoCubierta",
      className: "columna2-marca-no-cubierta-tabla-actividades",
      render: (value) => (
        <>
          {value === "No asignado" ? (
            <Badge
              status="processing"
              color="#FF0000"
              className="badge-no-asignado-detalle-actividad"
            />
          ) : (
            <Badge
              color="#fff"
              className="badge-no-asignado-detalle-actividad"
            />
          )}
        </>
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      className: "columna2-nombre-trabajador-tabla-actividades",
    },
    {
      title: "Relevo",
      className: "columna2-relevo-actividades",
      render: (value) => (
        <>
          {
            value.acciones.isRelevo === true?
            <img
              src={IconRelevo}
              className={"iconoRelevoTable"}
              alt="i"
            />
            :
            ''
          }

        </>
      ),
    },

    {
      title: "Días Asignados",
      dataIndex: "diasAsignados",
      key: "diasAsignados",
      className: "columna2-dias-asignados-actividades",
    },
    {
      title: "Días No Asignados",
      dataIndex: "diasNoAsignados",
      key: "diasNoAsignados",
      className: "columna2-dias-no-asignados-actividades",
    },
    {
      title: "Cronograma",
      dataIndex: "cronograma",
      key: "cronograma",
      className: "columna2-cronograma-actividades",
      render: (value) => (
        <>
          <div
            style={{
              width: "100%",
              backgroundColor: value.backgroundColor,
              height: "37px",
              borderRadius: "50px",
              fontFamily: "MavenPro-SemiBold",
              textAlign: "center",
            }}
          >
            <p
              style={{
                color: "#FFFFFF",
                fontFamily: "MavenPro-SemiBold",
                fontSize: "12px",
                paddingTop: "10px",
              }}
            >
              {value.fechaFormateadaInicio} - {value.fechaFormateadaFin}
            </p>
          </div>
        </>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      className: "columna2-acciones-actividades",
      render: (value) => (
        <>
          {value.trabajador === "No asignado" ? (
            <Button
              disabled={this.state.Editar === true ? false : true}
              style={{ width: "245px" }}
              className="boton-asignar-trabajador-detalle-actividad"
              onClick={() => this.asignarTrabajadorPlaza(value)}
            >
              Asignar Trabajador
            </Button>
          ) : (
            <>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
                gutter={10}
              >
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title="Editar Trabajador" color={'geekblue'}>
                    <Button
                      className="editar-button-detalle-actividad"
                      icon={<EditFilled />}
                      onClick={() => this.traerTrabajadorEditar(value)}
                      disabled={this.state.Editar === true ? false : true}
                    />
                  </Tooltip>
                </Col>

                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title="Eliminar Trabajador" color={'geekblue'}>
                    <Button
                      className="eliminar-button-detalle-actividad"
                      icon={<DeleteFilled />}
                      onClick={() => this.traerTrabajadorEliminar(value)}
                      disabled={this.state.Eliminar === true ? false : true}
                    />
                  </Tooltip>
                </Col>

                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title="Transbordo Trabajador" color={'geekblue'}>
                    <Button
                      className="transbordo-button"
                      //disabled={this.state.Editar === true ? false : true}
                      // disabled={true}
                      onClick={() => {
                        const {
                          idTrabajador,
                          idPlaza,
                          idPuestoPlaza,
                          inicioPlanificacion,
                          finPlanificacion,
                          IdRangoFechas,
                          idPlazaEsquema,
                        } = value;

                        console.log(value);

                        this.setState({
                          mostrarModalTransbordo: true,
                          trabajadorTransbordo: idTrabajador,
                          plazaTransbordo: idPlaza,
                          puestoPlazaTransbordo: idPuestoPlaza,
                          fechaTrabajoOrigenDesde: inicioPlanificacion,
                          fechaTrabajoOrigenHasta: finPlanificacion,
                          IdRangoFechasPuestoPlazaOrigen: IdRangoFechas,
                          IdPuestoPlazaOrigen: idPlazaEsquema,
                          random: Math.random().toString(36).substring(7),
                        });
                      }}
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="28"
                          viewBox="0 0 32.17 32.17"
                        >
                          <g className="iconTransbordo">
                            <path
                              className="a"
                              d="M86.283,63.142a3.142,3.142,0,1,0-3.142,3.142A3.145,3.145,0,0,0,86.283,63.142Z"
                              transform="translate(-74.973 -56.23)"
                            />
                            <path
                              className="a"
                              d="M8.168,16.336A8.168,8.168,0,1,0,0,8.168,8.176,8.176,0,0,0,8.168,16.336Zm0-15.08A6.91,6.91,0,0,1,12.477,13.57a4.4,4.4,0,0,0-8.619,0A6.91,6.91,0,0,1,8.168,1.257Z"
                            />
                            <path
                              className="a"
                              d="M338.283,315.142a3.142,3.142,0,1,0-3.142,3.142A3.145,3.145,0,0,0,338.283,315.142Z"
                              transform="translate(-311.14 -292.397)"
                            />
                            <path
                              className="a"
                              d="M260.168,252a8.168,8.168,0,1,0,8.168,8.168A8.177,8.177,0,0,0,260.168,252Zm4.309,13.57a4.4,4.4,0,0,0-8.619,0,6.911,6.911,0,1,1,8.619,0Z"
                              transform="translate(-236.166 -236.166)"
                            />
                            <path
                              className="a"
                              d="M57.938,297.31a4.4,4.4,0,0,1-4.4-4.4v-.628h.628a.628.628,0,0,0,.5-1.005l-3.77-5.027a.628.628,0,0,0-1.005,0l-3.77,5.027a.628.628,0,0,0,.5,1.005h.628v.628a5.661,5.661,0,0,0,5.655,5.655h5.027a.628.628,0,1,0,0-1.257Z"
                              transform="translate(-43.11 -268.03)"
                            />
                            <path
                              className="a"
                              d="M57.938,297.31a4.4,4.4,0,0,1-4.4-4.4v-.628h.628a.628.628,0,0,0,.5-1.005l-3.77-5.027a.628.628,0,0,0-1.005,0l-3.77,5.027a.628.628,0,0,0,.5,1.005h.628v.628a5.661,5.661,0,0,0,5.655,5.655h5.027a.628.628,0,1,0,0-1.257Z"
                              transform="translate(75.456 300.451) rotate(180)"
                            />
                          </g>
                        </svg>
                      }
                    />
                  </Tooltip>
                </Col>

                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                    <Button
                      className="eliminar-button-detalle-actividad"
                      icon={<EyeFilled />}
                      onClick={() => this.redireccionarDetalleTrabajador(value)}
                      disabled={this.state.Ver === true ? false : true}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </>
          )}
        </>
      ),
    },
  ];

  editarCronogramaTrabajador = async (values) => {
    this.setState({ loading: true });
    const {
      fechasEditarPlanificacion,
      fechasSeleccionadas,
      diasRelevo,
      fechasHabilitadas,
    } = this.state;

    if (fechasEditarPlanificacion.length > 0) {
      let strFechaInicioEditar =
        fechasEditarPlanificacion[0].format("YYYY-MM-DD");
      let fechaInicioEditar = convertirFechaString(
        moment(strFechaInicioEditar),
        1
      ).fechaString;

      let strFechaFinEditar = fechasEditarPlanificacion[1].format("YYYY-MM-DD");
      let fechaFinEditar = convertirFechaString(
        moment(strFechaFinEditar),
        2
      ).fechaString;
      let fechaFinUnixEditar = convertirFechaString(
        moment.utc(fechaFinEditar),
        2
      ).fechaUNIX;

      if (
        moment
          .utc(values.inicioPlanificacion)
          .isSame(moment.utc(fechaInicioEditar)) &&
        moment.utc(values.finPlanificacion).isSame(moment.utc(fechaFinEditar))
      ) {
        this.setState({ loading: false });
        Swal.fire({
          title: "Uups",
          text: "Se debe seleccionar una fecha distinta a la actual de la planificación para editar la planificación",
          icon: "error",
          confirmButtonText: "Ok",
        });
        return;
      }

      //validacion para verificar si tomo fechas deshabilidatas

      const fechasEncontradas = fechasHabilitadas.filter((relevo) =>
        moment
          .utc(relevo)
          .isBetween(
            moment.utc(fechaInicioEditar),
            moment.utc(fechaFinEditar),
            null,
            "[]"
          )
      );
      const cantDiasPlanificados =
        moment.utc(fechaFinEditar).diff(moment.utc(fechaInicioEditar), "days") +
        1;

      if (fechasEncontradas.length !== cantDiasPlanificados) {
        this.setState({ loading: false });
        Swal.fire({
          title: "Uups",
          text: "No puedes seleccionar un rango que tenga fechas deshabilitadas",
          icon: "error",
          confirmButtonText: "Ok",
        });
        return;
      }

      //se verifica los dias de relevo que se estan pasando a llevar
      const relevosEncontrados = diasRelevo.filter((relevo) =>
        moment
          .utc(relevo)
          .isBetween(
            moment.utc(fechaInicioEditar),
            moment.utc(fechaFinEditar),
            null,
            "[]"
          )
      );

      if (relevosEncontrados.length > 0) {
        //se valida si el dia de relevo es el mismo dia que el fin seleccionado
        const relevosValidos = relevosEncontrados.filter(
          (relevo) =>
            moment.utc(relevo).format("YYYY-MM-DD") ===
              moment.utc(fechaInicioEditar).format("YYYY-MM-DD") ||
            moment.utc(relevo).format("YYYY-MM-DD") ===
              moment.utc(fechaFinEditar).format("YYYY-MM-DD")
        );
        //si no son la misma cantidad significa que selecciono mas dias adicionales al dia de relevo
        if (relevosEncontrados.length !== relevosValidos.length) {
          this.setState({ loading: false });
          Swal.fire({
            title: "Uups",
            text: "Recuerda, solo puede estar posicionado el mismo dia del relevo",
            icon: "error",
            confirmButtonText: "Ok",
          });
          return;
        }
      }

      let dataEnviar = {
        nuevaFechaFinPlanificacion: fechaFinEditar,
        nuevaFechaFinPlanificacionUnix: fechaFinUnixEditar,

        Inicio_Planificacion: values.inicioPlanificacion,
        Inicio_PlanificacionUnix: convertirFechaString(
          moment.utc(values.inicioPlanificacion),
          1
        ).fechaUNIX,
        Fin_Planificacion: values.finPlanificacion,
        Fin_PlanificacionUnix: convertirFechaString(
          moment.utc(values.finPlanificacion),
          2
        ).fechaUNIX,

        idTrabajador: values.idTrabajador,
        idPuestoPlaza: values.idPuestoPlaza,
        idPlaza: values.idPlaza,

        idfaena: values.idActividad,
      };

      let usuario = localStorage.getItem("usuario-people-manage");
      if (
        usuario !== null &&
        usuario !== "" &&
        usuario !== undefined &&
        usuario !== "undefined"
      ) {
        usuario = JSON.parse(usuario);
        const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
        const planificacionAcordada = !moment
          .utc(values.finPlanificacion)
          .isAfter(moment.utc(fechaFinEditar))
          ? await alargarPlanificacion(dataEnviar)
          : await acortarPlanificacion(dataEnviar);
        const tipoPlanificacion = !moment
          .utc(values.finPlanificacion)
          .isAfter(moment.utc(fechaFinEditar))
          ? "Alargar"
          : "Acortar";
        const { status, data } = planificacionAcordada;

        switch (status) {
          case 200:
          case 201:
            let InicioBusquedaNew = convertirFechaString(fechasSeleccionadas[0].clone(),1).fechaString;
            let FinBusquedaNew = convertirFechaString(fechasSeleccionadas[1].clone(), 2).fechaString;
            const bitacoraTrabajadores = await detalleActividad(
              idEmpresa,
              values.idActividad,
              // fechasSeleccionadas[0],
              // fechasSeleccionadas[1]
              InicioBusquedaNew,
              FinBusquedaNew
            );

            let plazasActividad = [...bitacoraTrabajadores.estructuraPlazas];
            let plazasFiltro = bitacoraTrabajadores.PlazasBuscadas;

            this.setState({
              plazasActividad,
              plazasFiltro,
              loading: false,
              drawerEditar: false,
            });

            Swal.fire({
              title: "¡Bien!",
              text: "Se actualizó la planificación del trabajador.",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });
            break;
          case 404:
            this.setState({ loading: false, drawerEditar: false });
            Swal.fire("Error", "Servicio no encontrado", "error");
            break;
          case 420:
            this.setState({ loading: false, drawerEditar: false });
            let textoAlerta =
              tipoPlanificacion === "Alargar"
                ? "No se pueden alargar actividades de un periodo que se encuentra cerrado."
                : "No se pueden acortar actividades de un periodo que se encuentra cerrado.";
            Swal.fire({
              title: "Uups",
              text: textoAlerta,
              icon: "error",
            });
            break;
          case 409:
            this.setState({ loading: false, drawerEditar: false });
            Swal.fire(
              "Error",
              `El trabajador ya tiene días planificados durante esas fechas. No se puede realizar planificación esos días.`,
              "warning"
            );
            break;
          case 412:
            this.setState({ loading: false, drawerEditar: false });
            Swal.fire(
              "Error",
              `La planificación de trabajo ya posee descanso asignado. Debe eliminar el descanso si desea modificar el trabajo.`,
              "warning"
            );
            break;
          default:
            this.setState({ loading: false, drawerEditar: false });
            Swal.fire(
              "Error",
              `Error al realizar la petición, Status: ${status}`,
              "error"
            );
            break;
        }
      } else {
        this.setState({ loading: false, drawerEditar: false });
        Swal.fire("Error", `Ingrese nuevamente al sistema`, "error");
      }
    } else {
      this.setState({ loading: false, drawerEditar: false });
      Swal.fire({
        title: "Uups",
        text: "Se debe seleccionar una fecha distinta a la actual de la planificación para editar la planificación",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  eliminarCronogramaTrabajador = async (values) => {
    const { fechasSeleccionadas } = this.state;
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      // const arregloDias = await buscarTrabajadoresEliminarHoras(
      //     values.idTrabajador,
      //     values.idPuestoPlaza,
      //     values.idPlaza,
      //     values.inicioPlanificacion,
      //     values.finPlanificacion,
      //     values.idActividad
      // );

      // const { status, data } = arregloDias;

      // if (status === 200) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Se eliminará esta planificación",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1FC600",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.value) {
          this.setState({ loading: true });

          const planificacionEliminada = await eliminarPlanificacion(
            values.inicioPlanificacion,
            values.finPlanificacion,
            values.idTrabajador,
            values.idPlaza,
            values.idPuestoPlaza,
            values.idActividad
          );

          const { status, data } = planificacionEliminada;

          switch (status) {
            case 200:
            case 201:
              let InicioBusquedaNew = convertirFechaString(fechasSeleccionadas[0].clone(),1).fechaString;
              let FinBusquedaNew = convertirFechaString(fechasSeleccionadas[1].clone(), 2).fechaString;
              const bitacoraTrabajadores = await detalleActividad(
                idEmpresa,
                values.idActividad,
                // fechasSeleccionadas[0],
                // fechasSeleccionadas[1]
                InicioBusquedaNew,
                FinBusquedaNew
              );
              let plazasActividad = [...bitacoraTrabajadores.estructuraPlazas];
              let plazasFiltro = bitacoraTrabajadores.PlazasBuscadas;

              this.setState({
                plazasActividad,
                plazasFiltro,
                loading: false,
                drawerEliminar: false,
              });

              Swal.fire({
                title: "Bien",
                text: "Se eliminó su planificación",
                icon: "success",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "Continuar",
              });
              break;
            case 404:
              this.setState({ loading: false, drawerEditar: false });
              Swal.fire("Error", "Servicio no encontrado", "error");
              break;

            case 420:
              this.setState({ loading: false, drawerEditar: false });
              Swal.fire({
                title: "Uups",
                text: "No se pueden eliminar actividad en un periodo que se encuentra cerrado",
                icon: "error",
              });
              break;

            case 409:
              this.setState({ loading: false, drawerEditar: false });
              Swal.fire(
                "Error",
                `El usuario ya tiene dias planificados esos dias. no se puede realizar planificacion esos dias.`,
                "warning"
              );

              break;

            case 412:
              this.setState({ loading: false, drawerEditar: false });
              Swal.fire(
                "Error",
                `La planificación de trabajo ya posee descanso asignado. Debe eliminar el descanso si desea modificar el trabajo.`,
                "warning"
              );
              break;

            default:
              this.setState({ loading: false, drawerEditar: false });
              Swal.fire(
                "Error",
                `Error al realizar la petición, Status: ${status}`,
                "error"
              );
              break;
          }
        }
      });
      // }
    }
  };

  abrirModalVerDatosTrabajador = async (
    nombreTrabajadorAsignar,
    titulosTrabajadorAsignar,
    especialidadesTrabajadorAsignar,
    regimenTrabajadorAsignar,
    contratoTrabajadorAsignar,
    contratosTrabajador
  ) => {
    this.setState({
      modalVerDatosTrabajador: true,
      nombreTrabajadorAsignar,
      titulosTrabajadorAsignar,
      especialidadesTrabajadorAsignar,
      regimenTrabajadorAsignar,
      contratoTrabajadorAsignar,
      contratosTrabajador
    });
  };

  revisarRedireccion = async () => {
    // función encargada de tratarse el caso de una redirección desde reporte de necesidades

    let parametros = queryString.parse(this.props.location.search);

    let { idPuesto, nombreTrabajador } = parametros;

    if (idPuesto) {
      nombreTrabajador = nombreTrabajador.replace(/_/g, " ");

      const { gruposFiltrados } = this.state;

      const grupos =
        gruposFiltrados.length > 0 ? gruposFiltrados : this.props.grupos;

      const grupoPuestoSeleccionado = grupos.filter(
        (grupo) =>
          grupo.TipoSeccion === "HeaderSeccion" && grupo.id === idPuesto
      );

      if (grupoPuestoSeleccionado.length === 1) {
        await this.buscarTrabajadoresParaElCargo(grupoPuestoSeleccionado[0]);

        this.setState({
          trabajadorSearch: nombreTrabajador,
        });

        this.setBusqueda(nombreTrabajador);
      } else {
        Swal.fire({
          title: "Error",
          text: "No se ha encontrado puesto de plaza seleccionado.",
          icon: "error",
        });
      }
    }
  };

  //FUNCION ENCARGADA DE BUSCAR INFORMACION DEL TRABAJADOR
  //DIAS QUE TRABAJARA SEGUN EL ARREGLO ESCOGIDO
  buscarInformacionTrabajador = async (
    idTrabajador,
    idPuestoPlaza,
    idPlaza,
    idGrupo,
    Inicio_Planificacion,
    Fin_Planificacion
  ) => {
    await this.vaciarFechasFiltros();

    let parametros = queryString.parse(this.props.location.search);
    const idFaena = parametros.idFaena;
    // let nuevo_fin = moment(Fin_Planificacion)
    //   .add("day", 1)
    //   .format("YYYY-MM-DD");

    await this.props.buscarTrabajadoresEliminarHoras(
      idTrabajador,
      idPuestoPlaza,
      idPlaza,
      idGrupo,
      Inicio_Planificacion,
      Fin_Planificacion,
      idFaena
    );
  };

  actualizarInformacionPlaza = async () => {
    try {

      let usuario = localStorage.getItem("usuario-people-manage");

      if (
        usuario !== null &&
        usuario !== "" &&
        usuario !== undefined &&
        usuario !== "undefined"
      ) {
        this.setState({ loading: true });
  
        usuario = JSON.parse(usuario);
  
        const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
  
        let parametros = queryString.parse(this.props.location.search);
        let inicioMesStr = moment().startOf("month").format("YYYY-MM-DD");
        let finMesStr = moment().endOf("month").format("YYYY-MM-DD");
  
        let inicioMes = convertirFechaString(moment(inicioMesStr), 1).fechaString;
        let finMes = convertirFechaString(moment(finMesStr), 2).fechaString;
  
        if (parametros.fechaInicioBusqueda && parametros.fechaFinBusqueda) {
          inicioMes = parametros.fechaInicioBusqueda;
          finMes = parametros.fechaFinBusqueda;
        }
  
        const bitacoraTrabajadores = await detalleActividad(
          idEmpresa,
          parametros.idFaena,
          inicioMes,
          finMes,
          null,
          true
        );
  
        // console.log("bitacoraTrabajadores");
        // console.log(bitacoraTrabajadores);
  
        const idCentroCosto = bitacoraTrabajadores.Centro_Costo._id;
        const nombreCentroCosto = bitacoraTrabajadores.Centro_Costo.Nombre;
  
        const idActividad = bitacoraTrabajadores._id;
        const nombreActividad = bitacoraTrabajadores.Nombre;
  
        let plazasActividad = [...bitacoraTrabajadores.estructuraPlazas];
  
        // console.log("bitacoraTrabajadores.PlazasBuscadas");
        // console.log(bitacoraTrabajadores.PlazasBuscadas);
  
        let plazasFiltro = bitacoraTrabajadores.PlazasBuscadas;
        // console.log("plazasFiltro");
        // console.log(plazasFiltro);
  
        let fechaInicioActividad = convertirFechaString(
          moment.utc(bitacoraTrabajadores.Fecha_Inicio),
          1
        ).fechaString;
        let fechaFinActividad = convertirFechaString(
          moment.utc(bitacoraTrabajadores.Fecha_Fin),
          2
        ).fechaString;
  
        this.setState({
          idCentroCosto,
          nombreCentroCosto,
          idActividad,
          nombreActividad,
          plazasActividad,
          plazasFiltro,
          fechasActividad: [
            moment.utc(fechaInicioActividad),
            moment.utc(fechaFinActividad),
          ],
          fechasSeleccionadas: [
            moment.utc(bitacoraTrabajadores.fechaInicioBusquedaFaena),
            moment.utc(bitacoraTrabajadores.fechaFinBusquedaFaena),
          ],
          loading: false,
        });
  
      }

      // let usuario = localStorage.getItem("usuario-people-manage");

      // if (
      //   usuario !== null &&
      //   usuario !== "" &&
      //   usuario !== undefined &&
      //   usuario !== "undefined"
      // ) {
      //   usuario = JSON.parse(usuario);
      //   const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      //   let parametros = queryString.parse(this.props.location.search);
      //   let inicioMesStr = moment().startOf("month").format("YYYY-MM-DD");
      //   let finMesStr = moment().endOf("month").format("YYYY-MM-DD");

      //   let inicioMes = convertirFechaString(
      //     moment(inicioMesStr),
      //     1
      //   ).fechaString;
      //   let finMes = convertirFechaString(moment(finMesStr), 2).fechaString;

      //   const bitacoraTrabajadores = await detalleActividad(
      //     idEmpresa,
      //     parametros.idFaena,
      //     inicioMes,
      //     finMes
      //   );

      //   let plazasActividad = [...bitacoraTrabajadores.estructuraPlazas];
      //   let plazasFiltro = bitacoraTrabajadores.PlazasBuscadas;

      //   if (bitacoraTrabajadores) {
      //     this.setState({
      //       plazasActividad,
      //       plazasFiltro,
      //       loading: false,
      //     });
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }

    } catch (error) {
      console.log(error);
      return false;
    }
  };

  cerrarModalTransbordo = async () => {
    this.setState({
      mostrarModalTransbordo: false,
    });
  };

  buscarTrabajadoresParaElCargo = async (group) => {
    let idCupoPlaza = group.id;

    let arregloDeFechasInhabilitadas = [];

    let registrosBitacora = group.plaza.BitacoraPlaza;

    registrosBitacora.forEach((bitacora) => {
      if (bitacora.IdPuestoPlaza === idCupoPlaza) {
        arregloDeFechasInhabilitadas.push(bitacora);
      }
    });

    this.setState({
      PlazaCubrir: group,
      idCupoPlaza: group.id,
      idPlaza: group.idPlaza,
      arregloFechasInhabilitada: arregloDeFechasInhabilitadas,
    });

    let objetoEnviar = {
      titulos_profesionales: group.titulos_profesionales,
      especialidades: group.especialidades,
      cargo: group.cargo,
    };

    await this.props.ObtenerTrabajadoresParaCargo(objetoEnviar);
  };

  //FUNCION PARA ASIGNAR UNA PLAZA
  asignarPlaza = (trabajador, fechas, indexSeleccionado) => {
    try {

      const { fechasTrabajadorAsignarIndex, diasRelevo, fechasSeleccionadas } =
        this.state;

      const {
        _id: IdTrabajador,
        idActividad,
        idPlaza,
        centroDeCosto,
        idPuestoPlaza,
      } = trabajador;

      if (
        indexSeleccionado !== fechasTrabajadorAsignarIndex ||
        !fechas ||
        fechas.length === 0
      ) {
        Swal.fire({
          title: "Error",
          text: "Debe seleccionar una fecha de inicio y fin",
          icon: "error",
        });
        return;
      }

      //inicio faena
      let strFechaInicioCrear = fechas[0].format("YYYY-MM-DD");
      let fechaInicioCrear = convertirFechaStringNueva(
        moment(strFechaInicioCrear),
        1
      ).fechaString;
      let fechaInicioUnixCrear = convertirFechaStringNueva(
        moment.utc(fechaInicioCrear),
        1
      ).fechaUNIX;

      //fin faena
      let strFechaFinCrear = fechas[1].format("YYYY-MM-DD");
      let fechaFinCrear = convertirFechaStringNueva(
        moment(strFechaFinCrear),
        2
      ).fechaString;
      let fechaFinUnixCrear = convertirFechaStringNueva(
        moment.utc(fechaFinCrear),
        2
      ).fechaUNIX;

      // console.log(fechaInicioUnixCrear,"ini unix")
      // console.log(fechaFinUnixCrear,"fin unix")
      //validacion para verificar si tomo fechas deshabilidatas
      const fechasEncontradas = trabajador.diasValidosPlanificar.filter(
        (valido) => 
          moment
            .utc(valido)
            .isBetween(
              moment.utc(fechaInicioCrear),
              moment.utc(fechaFinCrear),
              null,
              "[]"
            )
      );

      const cantDiasPlanificados =
        moment.utc(fechaFinCrear).diff(moment.utc(fechaInicioCrear), "days") +
        1;

      //Revisar esta parte del codigo en caso que falle la asignacion del personal

      // console.log(fechaFinCrear,"fechaFinCrear")
      // console.log(fechaInicioCrear,"fechaInicioCrear")
      // console.log(cantDiasPlanificados, "cantDiasPlanificados")
      // console.log(fechasEncontradas.length, ".length")
      // console.log(fechasEncontradas)
      
      if (fechasEncontradas.length !== cantDiasPlanificados) {
        this.setState({ loading: false });
        Swal.fire({
          title: "Uups",
          text: "No puedes seleccionar un rango que tenga fechas deshabilitadas",
          icon: "error",
          confirmButtonText: "Ok",
        });
        return;
      }

      //se verifica los dias de relevo que se estan pasando a llevar
      const relevosEncontrados = diasRelevo.filter((relevo) =>
        moment
          .utc(relevo)
          .isBetween(
            moment.utc(fechaInicioCrear),
            moment.utc(fechaFinCrear),
            null,
            "[]"
          )
      );

      if (relevosEncontrados.length > 0) {
        //se valida si el dia de relevo es el mismo dia que el fin seleccionado
        const relevosValidos = relevosEncontrados.filter(
          (relevo) =>
            moment.utc(relevo).format("YYYY-MM-DD") ===
              moment.utc(fechaInicioCrear).format("YYYY-MM-DD") ||
            moment.utc(relevo).format("YYYY-MM-DD") ===
              moment.utc(fechaFinCrear).format("YYYY-MM-DD")
        );
        //si no son la misma cantidad significa que selecciono mas dias adicionales al dia de relevo
        if (relevosEncontrados.length !== relevosValidos.length) {
          this.setState({ loading: false });
          Swal.fire({
            title: "Uups",
            text: "Recuerda, solo puede estar posicionado el mismo dia del relevo",
            icon: "error",
            confirmButtonText: "Ok",
          });
          return;
        }
      }

      Swal.fire({
        title: "¿Estás seguro?",
        text: "Se asignará esta persona a la plaza",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#1FC600",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.value) {
          let usuario = localStorage.getItem("usuario-people-manage");

          if (
            usuario !== null &&
            usuario !== "" &&
            usuario !== undefined &&
            usuario !== "undefined"
          ) {
            this.setState({
              loading: true,
            });
            //id de la empresa
            usuario = JSON.parse(usuario);
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

            ////////////////////////////////////////////
            //////////////////////////LOGICA PARA AGREGAR HORAS A LA BITACORA
            let arregloBitacoraTrabajador = [];
            let arregloBitacoraFaena = [];

            for (let index = 0; index < cantDiasPlanificados; index++) {
              //let fechaUnix = momentToUnixFechaInicio(fechaInicioFaena);

              let fechatransformar = moment
                .utc(fechaInicioCrear)
                .clone()
                .add(index, "day");
              let fechaUnixNuevoDia = convertirFechaString(
                fechatransformar.clone(),
                1
              ).fechaUNIX;
              let fechaString = convertirFechaString(
                fechatransformar.clone(),
                1
              ).fechaString;

              //ingreso bitacora para la faena
              let bitacora = {
                Fecha: fechaString,
                FechaUnix: fechaUnixNuevoDia,
                IdTrabajador: IdTrabajador,
                Estado: "5f3ef6591ecf531c1c8ab4a8",
                IdPuestoPlaza: idPuestoPlaza,
                Inicio_Planificacion: fechaInicioCrear,
                Fin_Planificacion: fechaFinCrear,
                Inicio_PlanificacionUnix: fechaInicioUnixCrear,
                Fin_PlanificacionUnix: fechaFinUnixCrear,
                Centro_Costos: centroDeCosto,
              };

              arregloBitacoraFaena.push(bitacora);

              //ingreso para la bitacora del trabajador
              let bitacoraTrabajador = {
                Fecha: fechaString,
                FechaUnix: fechaUnixNuevoDia,
                Inicio_Planificacion: fechaInicioCrear,
                Fin_Planificacion: fechaFinCrear,
                Inicio_Planificacion_Unix: fechaInicioUnixCrear,
                Fin_Planificacion_Unix: fechaFinUnixCrear,
                IdPuestoPlaza: idPuestoPlaza,
                IdPlaza: idPlaza,
                IdTrabajador: IdTrabajador,
                IdFaena: idActividad,
                Estado: "5f3ef6591ecf531c1c8ab4a8",
                TipoRegistroBitacoraTrabajador: "5f3eedf56dd7cc57f0d3a261",
                Centro_Costos: centroDeCosto,
              };

              arregloBitacoraTrabajador.push(bitacoraTrabajador);
            }

            //////////////////////se comenzara a realizar la peticion hacia las apis
            const objEnviar = {
              idPuestoPlaza: idPuestoPlaza,
              idTrabajador: IdTrabajador,
              fecha_inicio: fechaInicioCrear,
              fecha_fin: fechaFinCrear,
              nuevasBitacorasPlaza: arregloBitacoraFaena,
              idEmpresa: idEmpresa,
              // idfaena: idActividad,
            };

            const responseAgregarPlaza = await agregarBitacorasPlaza(
              objEnviar,
              idPlaza
            );

            if (responseAgregarPlaza.status !== 200) {
              this.setState({
                loading: false,
              });
              Swal.fire({
                title: "Uups",
                text: responseAgregarPlaza.mensaje + ". Contactar a soporte.",
                icon: "error",
                confirmButtonText: "Ok",
              });

              return false;
            }

            const responseBitacorasTrabajador =
              await agregarBitacorasTrabajador(arregloBitacoraTrabajador, idActividad, idEmpresa, fechaInicioCrear, fechaFinCrear);

            if (responseBitacorasTrabajador.status !== 200) {
              this.setState({
                loading: false,
              });
              Swal.fire({
                title: "Uups",
                text:
                  responseBitacorasTrabajador.mensaje +
                  ". Contactar a soporte.",
                icon: "error",
                confirmButtonText: "Ok",
              });
              return false;
            }

            let InicioBusquedaNew = convertirFechaString(fechasSeleccionadas[0].clone(),1).fechaString;
            let FinBusquedaNew = convertirFechaString(fechasSeleccionadas[1].clone(), 2).fechaString;

            const bitacoraTrabajadores = await detalleActividad(
              idEmpresa,
              idActividad,
              // fechasSeleccionadas[0],
              // fechasSeleccionadas[1]
              InicioBusquedaNew,
              FinBusquedaNew,
            );
            let plazasActividad = [...bitacoraTrabajadores.estructuraPlazas];
            let plazasFiltro = bitacoraTrabajadores.PlazasBuscadas;

            this.setState({
              plazasActividad,
              plazasFiltro,
              loading: false,
              drawerAsignar: false,
              fechasTrabajadorAsignarIndex: 0,
              fechasTrabajadorAsignar: [],
            });

            Swal.fire({
              title: "Bien",
              text: "Se ingreso su planificación",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });
          }
        }
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  //FUNCIONES DE REDIRECCIONAR A OTRA VISTA
  redireccionarADetalle = (idUsuario) => {
    let ruta = `/CalendarioTrabajadorNew?Trabajador=${idUsuario}`;
    window.open(ruta, "_blank");
  };

  RedireccionarDetalle = () => {
    if (this.state.Redireccionar) {
      let ruta = `/CalendarioTrabajadorNew?Trabajador=${this.state.idUsuario}`;

      window.open(ruta, "_blank");

      this.setState({
        Redireccionar: false,
      });
    }
  };

  vaciarFechasFiltros = () => {
    this.setState({
      fechaInicio: "",
      fechaFin: "",
    });
  };

  detalleTrabajador = (trabajador) => {
    const detalleBitacoraTrabajador = trabajador.detalleBitacoraTrabajador;

    if (detalleBitacoraTrabajador.length !== 0) {
      return (
        <div
          style={{
            width: "100%",
          }}
        >
          <TimelineAntd>
            {detalleBitacoraTrabajador.map((value, index) => {
              let hexColor =
                coloresGenerica.obtenerHexadecimalBitacoraTrabajador(
                  value.tipoBitacora
                );
              let tituloBitacora =
                coloresGenerica.obtenerTituloBitacoraTrabajador(
                  value.tipoBitacora
                );
              return (
                <TimelineAntd.Item key={index} color={hexColor}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "5px",
                      backgroundColor: "#eee",
                      border: "1px solid #ddd",
                    }}
                  >
                    <p
                      style={{ margin: "0px", fontFamily: "MavenPro-SemiBold" }}
                    >
                      {tituloBitacora}
                    </p>
                    {value.centroCosto !== undefined ? (
                      <p
                        style={{
                          margin: "0px",
                          fontSize: "12px",
                          fontFamily: "MavenPro-Regular",
                        }}
                      >
                        {" "}
                        Centro Costo: {value.centroCosto}
                      </p>
                    ) : (
                      <></>
                    )}
                    {value.faena !== undefined ? (
                      <p
                        style={{
                          margin: "0px",
                          fontSize: "12px",
                          fontFamily: "MavenPro-Regular",
                        }}
                      >
                        {" "}
                        Actividad: {value.faena}
                      </p>
                    ) : (
                      <></>
                    )}

                    <p
                      style={{ margin: "0px", fontFamily: "MavenPro-Regular" }}
                    >
                      {value.fecha_inicio} - {value.fecha_fin}
                    </p>
                  </div>
                </TimelineAntd.Item>
              );
            })}
          </TimelineAntd>
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: "100%",
          }}
        >
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <Empty
                style={{
                  marginTop: "15px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                description={
                  <p>
                    No se han encontrado registros en los
                    <br />
                    últimos 15 días. Si desea ver mas información
                    <br />
                    vea el detalle del trabajador.
                  </p>
                }
              />
            </Col>
          </Row>
        </div>
      );
    }
  };

  mostrarTrabajadoresAsignar = () => {
    const {
      trabajadorSearch,
      trabajadoresFiltrados,
      trabajadoresFechas,
      trabajadoresAsignar,
      fechasTrabajadorAsignar,
      fechasTrabajadorAsignarIndex,
      mesBloqueado,
      mensajeMesBloqueado,
      trabajadoresFiniquitados,
      diasRelevo,
      requisitosPlaza,
      trabajadoresAsignarFiltrados,
      trabajadoresFiniquitadosFiltrados,
    } = this.state;

    const listaTrabajadores =
      trabajadoresAsignarFiltrados !== null
        ? trabajadoresAsignarFiltrados
        : trabajadoresAsignar;
    const listaTrabajadoresFiniquitados =
      trabajadoresFiniquitadosFiltrados !== null
        ? trabajadoresFiniquitadosFiltrados
        : trabajadoresFiniquitados;

    //trabajadoresAsignarFiltrados, trabajadoresFiniquitadosFiltrados

    if (mesBloqueado) {
      return (
        <Row>
          <Col span={24}>
            <Empty
              style={{ marginTop: "15px" }}
              description={
                <>
                  <h2 className={"seleccion-trabajador-disponible"}>
                    Los siguientes meses no se puede planificar ya que estan
                    cerrados. Favor Dirigirse con el administrador de la
                    plataforma.
                  </h2>
                  <br />
                  <h2 className={"seleccion-trabajador-disponible"}>
                    {mensajeMesBloqueado}
                  </h2>
                </>
              }
            />
          </Col>
        </Row>
      );
    } else {
      //|| trabajadoresFiniquitados.length > 0
      if (
        listaTrabajadores.length > 0 ||
        listaTrabajadoresFiniquitados.length > 0
      ) {
        return (
          <>
            {listaTrabajadores.length === 0 ? (
              <></>
            ) : (
              <>
                <h4 style={{ color: "#262E2D", fontWeight: "700" }}>
                  Trabajadores cumplen con los requisitos
                </h4>
                <div style={{ width: "100%" }}>
                  <div style={{ width: "20%", float: "left" }}>
                    <h4 style={{ color: "#262E2D", fontWeight: "700" }}>
                      Leyenda{" "}
                    </h4>
                  </div>
                  <div style={{ width: "80%", float: "left" }}>
                    <p>
                      Dias de transbordo <Badge color="#FFBDEB" size="small" />
                    </p>
                  </div>
                </div>
              </>
            )}
            {listaTrabajadores.map((trabajador, index) => {
              const {
                Imagen,
                Nombre,
                Apellido_Paterno,
                Tipo_Contrato,
                Contrato_Fecha_Inicio,
                Contrato_Fecha_Inicio_Unix,
                Contrato_Fecha_Fin,
                Contrato_Fecha_Fin_Unix,
                contratoVencido,
                Finiquitado,
                Fecha_Finiquito_Unix,
                Contrato,
                TitulosTrabajador,
                EspecialidadesTrabajador,
                Regimen_Trabajo,
                Contrato_Vigente,
                Rut,
                diasDisponibles,
                diasValidosPlanificar,
                //Relevo = trabajador.Contrato_Vigente[0].Relevo === undefined? false:  trabajador.Contrato_Vigente[0].Relevo,
                Relevo = trabajador.isRelevo,
                TodosContratos = trabajador.Contratos
              } = trabajador;
              // console.log("contratos")
              // console.log(trabajador.Contratos)
              const nombreTrabajadorAsignar = Nombre + " " + Apellido_Paterno;
              const titulosTrabajadorAsignar = TitulosTrabajador;
              const especialidadesTrabajadorAsignar = EspecialidadesTrabajador;
              const regimenTrabajadorAsignar = Regimen_Trabajo[0];
              const contratoTrabajadorAsignar = Contrato_Vigente[0];
              const contratosTrabajador = TodosContratos;
              ///contratoALL
              return (
                <Row key={index} className="seccion-trabajador">
                   {/* xs={24} s={24} sm={24} md={24} lg={24} xl={24} */}
                  <Col span={4}>
                    <img
                      alt="imagen-trabajador"
                      src={Imagen}
                      className="seleccion-trabajador-img"
                    />
                  </Col>

                  <Col span={16}>
                    <h2
                      className="seleccion-trabajador-nombre"
                      alt="imagen trabajador"
                      style={{ paddingRight: "15px", marginLeft: "12px" }}
                    >
                      {`${Nombre} ${Apellido_Paterno} `}
                    </h2>
          
                    <p
                      style={{
                        paddingRight: "15px",
                        color: "#525252",
                        fontFamily: "MavenPro-Bold",
                        margin: "0px 0px 0px 12px",
                      }}
                    >
                      {`${Rut}`}
                    </p>
                    <p
                      style={{
                        paddingRight: "15px",
                        color: "#525252",
                        fontFamily: "MavenPro-Bold",
                        margin: "0px 0px 0px 12px",
                      }}
                    >
                      {`Dias disponibles: ${diasDisponibles}`}
                    </p>
                  </Col>
                  <Col span={4}>
                    {
                      Relevo === true?

                        <img
                          src={IconRelevo}
                          className={"iconoRelevoCard"}
                          alt="cardRelevo"
                        />
                      :
                      ''
                    }
                  </Col>

                  <Col span={24} className="seccion-datepicker maven-r">
                    <RangePicker
                      locale={locale}
                      format="DD-MM-YYYY"
                      value={
                        fechasTrabajadorAsignarIndex === index
                          ? fechasTrabajadorAsignar
                          : []
                      }
                      disabled={[false, false]}
                      disabledDate={(current) => {
                        let fechaEncontrada = diasValidosPlanificar.filter(
                          (dRelevo) =>
                            current.format("DD-MM-YYYY") ===
                            moment.utc(dRelevo).format("DD-MM-YYYY")
                        );
                        if (fechaEncontrada.length > 0) {
                          return false;
                        } else {
                          return true;
                        }
                      }}
                      dateRender={(current) => {
                        const style = {};
                        if (diasRelevo.length > 0) {
                          let fechaEncontrada = diasRelevo.filter(
                            (dRelevo) =>
                              current.format("DD-MM-YYYY") ===
                              moment.utc(dRelevo).format("DD-MM-YYYY")
                          );
                          if (fechaEncontrada.length > 0) {
                            //style.border = '1px solid #1890ff';
                            style.borderRadius = "50%";
                            style.background = "#ffbcec";
                          }
                        }
                        return (
                          <div className="ant-picker-cell-inner" style={style}>
                            {current.date()}
                          </div>
                        );
                      }}
                      onChange={(value) => {
                        this.setState({
                          fechasTrabajadorAsignar: value,
                          fechasTrabajadorAsignarIndex: index,
                        });
                      }}
                    />
                  </Col>

                  <Col
                    style={{ textAlign: "right", marginTop: "20px" }}
                    span={14}
                  >
                    <Button
                      onClick={() =>
                        this.abrirModalVerDatosTrabajador(
                          nombreTrabajadorAsignar,
                          titulosTrabajadorAsignar,
                          especialidadesTrabajadorAsignar,
                          regimenTrabajadorAsignar,
                          contratoTrabajadorAsignar,
                          contratosTrabajador
                        )
                      }
                      className="btn-ver-datos-trabajador-asignar"
                      icon={
                        <EyeFilled
                          style={{ fontSize: "20px", color: "#5c5d5f" }}
                        />
                      }
                    ></Button>
                  </Col>

                  <Col
                    style={{ textAlign: "right", marginTop: "20px" }}
                    span={10}
                  >
                    <Button
                      onClick={async () => {
                        await this.asignarPlaza(
                          trabajador,
                          fechasTrabajadorAsignar,
                          index
                        );
                      }}
                      className="btn-guardar-pequeño"
                    >
                      Asignar Plaza
                    </Button>
                  </Col>
                </Row>
              );
            })}
            {listaTrabajadoresFiniquitados.length === 0 ? (
              <></>
            ) : (
              <>
                <h4 style={{ color: "#262E2D", fontWeight: "700" }}>
                  Trabajadores finiquitados que cumplen con los requisitos
                </h4>
              </>
            )}
            {listaTrabajadoresFiniquitados.map((trabajador, index) => {
              const {
                Imagen,
                Nombre,
                Apellido_Paterno,
                TitulosTrabajador,
                EspecialidadesTrabajador,
                Regimen_Trabajo,
                Contrato_Vigente,
                Rut,
              } = trabajador;

              const nombreTrabajadorAsignar = Nombre + " " + Apellido_Paterno;
              const titulosTrabajadorAsignar = TitulosTrabajador;
              const especialidadesTrabajadorAsignar = EspecialidadesTrabajador;
              const regimenTrabajadorAsignar = Regimen_Trabajo[0];
              const contratoTrabajadorAsignar = Contrato_Vigente[0];

              return (
                <Row key={index} className="seccion-trabajador">
                  <Col span={4}>
                    <img
                      alt="imagen-trabajador"
                      src={Imagen}
                      className="seleccion-trabajador-img"
                    />
                  </Col>

                  <Col span={20}>
                    <h2
                      className="seleccion-trabajador-nombre"
                      alt="imagen trabajador"
                      style={{ paddingRight: "15px", marginLeft: "12px" }}
                    >
                      {`${Nombre} ${Apellido_Paterno} `}
                    </h2>
                    <p
                      style={{
                        paddingRight: "15px",
                        color: "#525252",
                        fontFamily: "MavenPro-Bold",
                        margin: "0px 0px 0px 12px",
                      }}
                    >
                      {`${Rut}`}
                    </p>
                  </Col>

                  <Col
                    span={24}
                    className="seccion-datepicker maven-r"
                    style={{ textAlign: "center" }}
                  >
                    <h2
                      className="seleccion-trabajador-nombre"
                      alt="imagen trabajador"
                      style={{ paddingRight: "15px", marginLeft: "12px" }}
                    >
                      {`Finiquitado`}
                    </h2>
                  </Col>
                </Row>
              );
            })}
          </>
        );
      } else {
        return (
          <Row>
            <Col span={24}>
              <Empty
                style={{ marginTop: "15px" }}
                description={
                  <h2 className={"seleccion-trabajador-disponible"}>
                    No se han encontrado resultados.
                  </h2>
                }
              />
              <br />
              <br />
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <h2
                    style={{
                      color: "#262E2D",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Para ocupar esta plaza el trabajador debe contar con:
                  </h2>
                </Col>
                <br />
                <br />
                <Col span={24} style={{ textAlign: "left" }}>
                  <h2
                    style={{
                      color: "#262E2D",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Cargo:
                  </h2>
                  <Row>
                    <Col
                      span={24}
                      style={{
                        backgroundColor: "white",
                        color: "#262E2D",
                        padding: "10px 10px 10px 10px",
                        borderRadius: "22px",
                        marginBottom: "10px",
                        borderWidth: "1.8px",
                        borderStyle: "solid",
                        borderColor: "#48B9FD",
                      }}
                    >
                      <p
                        style={{
                          width: "90%",
                          margin: "0px",
                          worBreak: "break-all",
                          whiteSpace: " nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {requisitosPlaza.cargo !== undefined
                          ? requisitosPlaza.cargo.Nombre
                          : "Sin Cargo"}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <br />
                <br />
                <Col span={24} style={{ textAlign: "left" }}>
                  <h2
                    style={{
                      color: "#262E2D",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Titulos:
                  </h2>
                  <Row>
                    {requisitosPlaza.titulos !== undefined &&
                    requisitosPlaza.titulos.length !== 0 ? (
                      <>
                        {requisitosPlaza.titulos.map((titulo) => {
                          return (
                            <Col
                              span={24}
                              style={{
                                backgroundColor: "white",
                                color: "#262E2D",
                                padding: "10px 10px 10px 10px",
                                borderRadius: "22px",
                                marginBottom: "10px",
                                borderWidth: "1.8px",
                                borderStyle: "solid",
                                borderColor: "#48B9FD",
                              }}
                            >
                              <p
                                style={{
                                  width: "90%",
                                  margin: "0px",
                                  worBreak: "break-all",
                                  whiteSpace: " nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {titulo.Nombre}
                              </p>
                            </Col>
                          );
                        })}
                      </>
                    ) : (
                      <Col
                        span={24}
                        style={{
                          backgroundColor: "white",
                          color: "#262E2D",
                          padding: "10px 10px 10px 10px",
                          borderRadius: "22px",
                          marginBottom: "10px",
                          borderWidth: "1.8px",
                          borderStyle: "solid",
                          borderColor: "#48B9FD",
                        }}
                      >
                        <p
                          style={{
                            width: "90%",
                            margin: "0px",
                            worBreak: "break-all",
                            whiteSpace: " nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          Sin titulos
                        </p>
                      </Col>
                    )}
                  </Row>
                </Col>
                <br />
                <br />
                <Col span={24} style={{ textAlign: "left" }}>
                  <h2
                    style={{
                      color: "#262E2D",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Especialidades:
                  </h2>
                  <Row>
                    {requisitosPlaza.especialidades !== undefined &&
                    requisitosPlaza.especialidades.length !== 0 ? (
                      <>
                        {requisitosPlaza.especialidades.map((especialidad) => {
                          return (
                            <Col
                              span={24}
                              style={{
                                backgroundColor: "white",
                                color: "#262E2D",
                                padding: "10px 10px 10px 10px",
                                borderRadius: "22px",
                                marginBottom: "10px",
                                borderWidth: "1.8px",
                                borderStyle: "solid",
                                borderColor: "#48B9FD",
                              }}
                            >
                              <p
                                style={{
                                  width: "90%",
                                  margin: "0px",
                                  worBreak: "break-all",
                                  whiteSpace: " nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {especialidad.Nombre}
                              </p>
                            </Col>
                          );
                        })}
                      </>
                    ) : (
                      <Col
                        span={24}
                        style={{
                          backgroundColor: "white",
                          color: "#262E2D",
                          padding: "10px 10px 10px 10px",
                          borderRadius: "22px",
                          marginBottom: "10px",
                          borderWidth: "1.8px",
                          borderStyle: "solid",
                          borderColor: "#48B9FD",
                        }}
                      >
                        <p
                          style={{
                            width: "90%",
                            margin: "0px",
                            worBreak: "break-all",
                            whiteSpace: " nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          Sin Especialidades
                        </p>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      }
    }
  };

  // Función para limitar RangePicker a sólo 2 meses
  disabledDate = (current) => {
    if (!this.state.dates || this.state.dates.length === 0) {
      return false;
    }

    const tooLate =
      this.state.dates[0] && current.diff(this.state.dates[0], "months") > 2;
    const tooEarly =
      this.state.dates[1] && this.state.dates[1].diff(current, "months") > 2;
    return tooEarly || tooLate;
  };

  transformarUnixAFechaString = (Contrato_Fecha_Fin_Unix) => {
    let horasRestarFin =
      moment.utc(moment.unix(Contrato_Fecha_Fin_Unix)).hours() + 1;
    let fechaStringFin = convertirFechaString(
      moment.utc(
        moment.unix(Contrato_Fecha_Fin_Unix).subtract(horasRestarFin, "hours")
      ),
      2
    ).fechaString;
    return fechaStringFin;
  };

  // filtrarCalendario = (fechas) => {
  //     if (fechas) {
  //         const parametros = queryString.parse(this.props.location.search);
  //         // const firstDayBdFormat = convertirFechaString(momentFirstDay.clone(),1).fechaString;
  //         // const lastDayBdFormat = convertirFechaString(momentLastDay.clone(),1).fechaString;
  //         const firstDay = convertirFechaString(fechas[0].startOf("month").clone(), 1).fechaString;
  //         const lastDay = convertirFechaString(fechas[1].endOf("month").clone(), 2).fechaString;

  //         this.setState({
  //             busquedaFaenaInicio: firstDay,
  //             busquedaFaenaFin: lastDay,
  //             defaultDates: fechas,
  //         });

  //         this.props.ObtenerDetalleFaenaPorRangoDeFecha(
  //             parametros.idFaena,
  //             firstDay,
  //             lastDay,
  //             this.state.plazasSeleccionadas,
  //         );
  //     }
  // };

  ///////////////////////////FUNCIONES ENCARGADAS DE RESTRINGIR FECHAS DE LOS DATE PICKER
  restringirFechasAsingacion = (
    dia,
    bitacoraTrabajador,
    fechasBloqueadas,
    finContratoTrabajador,
    finiquitoObj
  ) => {
    //variable para verificar si se debe bloquear el dia
    let validacion = false;

    //tomo la fecha de inicio de la faena
    //si la faena no existe se tomara la fecha de hoy
    let str = moment().format("yyyy/MM/DD");
    let inicio_faena =
      this.props.faena === null
        ? moment.utc(convertirFechaString(moment(str), 1).fechaString)
        : moment.utc(this.props.faena.Fecha_Inicio);

    // como fin disponible: si el contrato termina antes que la actividad, utilizar la fecha fin del contrato
    let fin_restringido;
    if (finContratoTrabajador) {
      //si la faena es null se tomara la fecha de hoy
      //caso contrario se valida que fecha es mejor. Si la del fin del contrato o la faena. La menor sera esocogida
      fin_restringido =
        this.props.faena === null
          ? moment.utc(convertirFechaString(moment(str), 1).fechaString)
          : finContratoTrabajador.isBefore(
              moment.utc(this.props.faena.Fecha_Fin)
            )
          ? finContratoTrabajador
          : moment.utc(this.props.faena.Fecha_Fin);
    } else {
      //se valida si existe la faena en caso contrario se convierte la fecha del fin de la faena
      fin_restringido =
        this.props.faena === null
          ? moment.utc(convertirFechaString(moment(str), 1).fechaString)
          : moment.utc(this.props.faena.Fecha_Fin);
    }

    let finEnFechaMoment;

    if (finContratoTrabajador) {
      finEnFechaMoment = finContratoTrabajador.isBefore(
        moment.utc(this.props.faena.Fecha_Fin)
      )
        ? finContratoTrabajador
        : moment.utc(this.props.faena.Fecha_Fin);
    } else {
      finEnFechaMoment = moment.utc(this.props.faena.Fecha_Fin);
    }

    //VALIDAR SI REALMENTE FUNCIONO ALGUNA VEZ ESTA VALIDACIÓN
    //SEGUN SE RECUERDA SE QUITO YA QUE NECESITABAN COLOCAR PLANIFICACIONES EN FECHAS DE RELEVO
    //buscar las fechas inhabilitadas
    // fechasBloqueadas.forEach((bitacora) => {
    //   let fechaFormatoBD = moment.utc(convertirFechaString(dia,1).fechaString);
    //   if (fechaFormatoBD.isSame(moment.utc(bitacora.Fecha))) {
    //     // if (bitacora.FinTrabajo === false
    //     //   && !moment(dia.format("DD-MM-YYYY")).isSame(convertirFechaString(finEnFechaMoment.clone(),1).fechaDDMMYYYY)) {
    //       validacion = true;
    //     //}
    //   }
    // });

    //validar fechas del trabajador
    if (validacion === false) {
      let fechaFormatoBD = moment.utc(convertirFechaString(dia, 1).fechaString);
      bitacoraTrabajador.forEach(({ Fecha }) => {
        if (fechaFormatoBD.isSame(moment.utc(Fecha))) {
          validacion = true;
        }
      });
    }

    //validar en caso de finiquito adelantado al trabajo
    if (validacion === false) {
      if (finiquitoObj.finiquitado === true) {
        let fechaFormatoBD = moment.utc(
          convertirFechaString(dia, 1).fechaString
        );
        if (
          fechaFormatoBD.isSameOrAfter(
            moment.utc(finiquitoObj.fechaFinFiniquito)
          )
        ) {
          validacion = true;
        }
      }
    }

    if (validacion === true) {
      return true;
    } else {
      if (!dia) {
        return true;
      } else {
        let fechaFormatoBD = moment.utc(
          convertirFechaString(dia, 1).fechaString
        );
        if (
          !fechaFormatoBD.isBetween(inicio_faena, fin_restringido, null, "[]")
        ) {
          return true;
        }
        return false;
      }
    }
  };

  restringirFechasEditar = (dia, bitacoraTrabajador, fechasBloqueadas) => {
    //variable para verificar si se debe bloquear el dia
    let validacion = false;

    const inicio_faena =
      this.props.faena === null
        ? moment.utc(convertirFechaString(moment(), 1).fechaString)
        : moment.utc(this.props.faena.Fecha_Inicio);
    const fin_faena =
      this.props.faena === null
        ? moment.utc(convertirFechaString(moment(), 2).fechaString)
        : moment.utc(this.props.faena.Fecha_Fin);
    if (validacion === true) {
      return true;
    } else {
      if (!dia) {
        return true;
      } else {
        let fechaFormatoBD = moment.utc(
          convertirFechaString(dia, 1).fechaString
        );
        if (!fechaFormatoBD.isBetween(inicio_faena, fin_faena, null, "[]")) {
          return true;
        }
        //|| !dia.isBetween(inicio_faena, fin_restringido,null,"[]")
        return false;
      }
    }
  };

  ///////////////////////////FUNCION ENCARGADA DE RECOMENDAR FECHAS DE DESCANSO Y TRABAJO
  // recomendarDescansosYTrabajos = (current, trabajador) => {
  //   const feriados = this.props.feriadosSistema;

  //   const style = {};
  //   if (current.date() === 1) {
  //     style.border = "1px solid #1890ff";
  //     style.borderRadius = "50%";
  //   }
  //   return (
  //     <div className="ant-picker-cell-inner" style={style}>
  //       {current.date()}
  //     </div>
  //   );
  // };

  openNotificationAsignacion = (placement, idTrabajador) => {
    notification.open({
      message: "¡Bien!",
      description: "Haz click para ver sugerencias!",

      duration: null,
      placement,
      icon: (
        <img style={{ width: 20, marginTop: "-3" }} src={ticket} alt="ticket" />
      ),

      onClose: notification.destroy(),

      onClick: () => {
        notification.destroy();

        notification.open({
          message: `¡Bien hecho!`,
          placement,
          style: { width: 500 },
          icon: (
            <img
              style={{ width: 20, marginRight: "-20" }}
              src={ticket}
              alt="ticket"
            />
          ),
          duration: null,
          description: (
            <>
              <p style={{ marginRight: 10 }}>
                Asignaste correctamente al Trabajador a la Actividad. Te
                sugerimos continuar con...
              </p>

              {/* <p style={{ marginRight: 10 }}>

                </p> */}

              {/************** SUGERIDA ***************/}

              <Row>
                <Col span={3}>
                  <a
                    href={`/ CalendarioTrabajador ? Trabajador = ${idTrabajador} `}
                  >
                    <img
                      style={{
                        width: 20,
                        cursor: "pointer",
                        justifyContent: "center",
                      }}
                      src={reloj}
                      alt="next"
                    />
                  </a>
                </Col>
                <Col span={21}>
                  <a
                    href={`/ CalendarioTrabajador ? Trabajador = ${idTrabajador} `}
                  >
                    <p
                      style={{ cursor: "pointer", fontFamily: "MavenPro-Bold" }}
                    >
                      Ver el calendario del Trabajador
                    </p>
                  </a>
                </Col>
              </Row>

              <p style={{ marginRight: 10 }}>O también puedes...</p>

              {/************** Extra 1 - Planificar Actividades para los Equipos de Trabajo ***************/}

              <Row>
                <Col span={3}>
                  <a href={`/ AsignacionFaena`}>
                    <img
                      style={{
                        width: 20,
                        cursor: "pointer",
                        justifyContent: "center",
                      }}
                      src={reloj}
                      alt="next"
                    />
                  </a>
                </Col>
                <Col span={21}>
                  <a href={`/ AsignacionFaena`}>
                    <p
                      style={{ cursor: "pointer", fontFamily: "MavenPro-Bold" }}
                    >
                      Planificar Actividades para los Equipos de Trabajo
                    </p>
                  </a>
                </Col>
              </Row>

              {/************** Extra 2 - Ver todos los trabajores ***************/}

              {/* <Row>
                  <Col span={3}>

                    <a href={`/ Trabajador`}>
                      <img
                        style={{ width: 20, cursor: 'pointer', justifyContent: 'center' }}
                        src={reloj}
                        alt="next"
                      />
                    </a>

                  </Col>
                  <Col span={21}>

                    <a href={`/ Trabajador`}>
                      <p
                        style={{ cursor: 'pointer', fontFamily: "MavenPro-Bold" }}
                      >
                        Ver todos los Trabajadores
                      </p>
                    </a>

                  </Col>
                </Row > */}

              {/************** Extra 3 - Ver las Plantillas de Equipos de Trabajo ***************/}

              <Row>
                <Col span={3}>
                  <a href={`/ Faena`}>
                    <img
                      style={{
                        width: 20,
                        cursor: "pointer",
                        justifyContent: "center",
                      }}
                      src={reloj}
                      alt="next"
                    />
                  </a>
                </Col>
                <Col span={21}>
                  <a href={`/ Faena`}>
                    <p
                      style={{ cursor: "pointer", fontFamily: "MavenPro-Bold" }}
                    >
                      Ver las Plantillas de Equipos de Trabajo
                    </p>
                  </a>
                </Col>
              </Row>
            </>
          ),
        });
      },
    });
  };

  componentWillUnmount() {
    notification.destroy();
  }

  setBusqueda = (search) => {
    let { trabajadoresAsignar, trabajadoresFiniquitados } = this.state;
    let trabajadoresAsignarFiltrados = null;
    let trabajadoresFiniquitadosFiltrados = null;

    if (search) {
      trabajadoresAsignarFiltrados = trabajadoresAsignar.filter((trabajador) =>
        removeAccents(
          `${trabajador.Nombre} ${trabajador.Apellido_Paterno} ${trabajador.Apellido_Materno} `
        )
          .toUpperCase()
          .includes(removeAccents(search).toUpperCase())
      );
      trabajadoresFiniquitadosFiltrados = trabajadoresFiniquitados.filter(
        (trabajador) =>
          removeAccents(
            `${trabajador.Nombre} ${trabajador.Apellido_Paterno} ${trabajador.Apellido_Materno} `
          )
            .toUpperCase()
            .includes(removeAccents(search).toUpperCase())
      );
    }

    this.setState({
      trabajadoresAsignarFiltrados,
      trabajadoresFiniquitadosFiltrados,
      trabajadorSearch: search,
    });
  };

  // filtrarGrupos = (grupos, plazasSeleccionadas) => {

  //     if (!(plazasSeleccionadas.length > 0)) {
  //         return grupos;
  //     }

  //     return grupos.filter(group => {

  //         const { TipoSeccion } = group;

  //         switch (TipoSeccion) {

  //             case 'Header':
  //                 if (!plazasSeleccionadas.includes(group.id)) return false;

  //             case 'HeaderSeccion':
  //             case 'Usuario':
  //                 if (!plazasSeleccionadas.includes(group.idPlantilla)) return false;

  //             default:
  //                 return true;
  //         }
  //     })

  // }

  filtrarPorPlazayFecha = async () => {
    const { fechasSeleccionadas, plazasSeleccionadas, loading } = this.state;

    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      this.setState({ loading: true });

      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      let parametros = queryString.parse(this.props.location.search);

      let fechaInicioStr = fechasSeleccionadas[0].format("YYYY-MM-DD");
      let fechaFiStr = fechasSeleccionadas[1].format("YYYY-MM-DD");

      let inicio = convertirFechaString(moment(fechaInicioStr), 1).fechaString;
      let fin = convertirFechaString(moment(fechaFiStr), 2).fechaString;

      // let plazas = JSON.parse("[" + plazasSeleccionadas + "]");
      
      const bitacoraTrabajadores = await detalleActividad(
        idEmpresa,
        parametros.idFaena,
        inicio,
        fin,
        plazasSeleccionadas
      );

      let plazasActividad = [...bitacoraTrabajadores.estructuraPlazas];
      // let plazasFiltro = bitacoraTrabajadores.PlazasBuscadas;

      this.setState({
        plazasActividad,
        // plazasFiltro,
        // fechasSeleccionadas: [moment.utc(inicioMes), moment.utc(finMes)]
        loading: false,
      });
    }
  };

  disabledDate = (current) => {
    const { fechasActividad } = this.state;

    if (
      fechasActividad[0].isSameOrBefore(current) &&
      fechasActividad[1].isSameOrAfter(current)
    ) {
      return false;
    } else {
      return true;
    }
  };

  modificarActividad = async () => {
    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      const cargosTrabajadores = await traerCargosTrabajador(idEmpresa);
      const titulosObligatorios = await traerTitulosTrabajador(idEmpresa);
      const titulosOpcionales = await traerTitulosTrabajador(idEmpresa);
      const especialidadesObligatorias = await traerEspecialidadesTrabajador(
        idEmpresa
      );
      const especialidadesOpcionales = await traerEspecialidadesTrabajador(
        idEmpresa
      );

      let parametros = queryString.parse(this.props.location.search);
      const plazasTrabajador = await traerPlazasTrabajador(
        parametros.idFaena,
        idEmpresa
      );

      this.setState({
        cargosTrabajadores,
        titulosObligatorios,
        titulosOpcionales,
        especialidadesObligatorias,
        especialidadesOpcionales,
        modalModificarActividad: true,
        plazasTrabajador,
      });
    }
  };

  mostrarContenidoModificarActividad = (value) => {
    if (value === "agregarNuevaPlaza") {
      this.setState({
        contenidoModificarActividad: "block",
        agregarPlazaNueva: true,
        agregarPuestoTrabajo: false,
        editarPuestoTrabajo: false,
        eliminarPuestoTrabajo: false,
        selectModificarActividad: value,
        bloquearNuevoRango: true,
        fechasDisponibilidad: [],
      });
    } else if (value === "agregarPuestoTrabajo") {
      this.setState({
        contenidoModificarActividad: "block",
        agregarPuestoTrabajo: true,
        agregarPlazaNueva: false,
        editarPuestoTrabajo: false,
        eliminarPuestoTrabajo: false,
        selectModificarActividad: value,
        bloquearNuevoRango: true,
        fechasDisponibilidad: [],
      });
    } else if (value === "editarPuestoTrabajo") {
      this.setState({
        contenidoModificarActividad: "block",
        editarPuestoTrabajo: true,
        agregarPuestoTrabajo: false,
        agregarPlazaNueva: false,
        eliminarPuestoTrabajo: false,
        selectModificarActividad: value,
        puestoPlazaTrabajador: [],
        bloquearNuevoRango: true,
        fechasDisponibilidad: [],
      });
    } else if (value === "eliminarPuestoTrabajo") {
      this.setState({
        contenidoModificarActividad: "block",
        eliminarPuestoTrabajo: true,
        agregarPuestoTrabajo: false,
        editarPuestoTrabajo: false,
        agregarPlazaNueva: false,
        selectModificarActividad: value,
        puestoPlazaTrabajador: [],
        bloquearNuevoRango: true,
        fechasDisponibilidad: [],
      });
    } else {
      this.setState({
        contenidoModificarActividad: "none",
        agregarPuestoTrabajo: false,
        agregarPlazaNueva: false,
        editarPuestoTrabajo: false,
        eliminarPuestoTrabajo: false,
        selectModificarActividad: undefined,
        bloquearNuevoRango: true,
        fechasDisponibilidad: [],
      });
    }
  };

  agregarNuevaPlaza = async (values) => {
    this.setState({ loading: true });
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      let parametros = queryString.parse(this.props.location.search);

      let fechaInicioStr = values.vigenciaPlaza[0].format("YYYY-MM-DD");
      let fechaFiStr = values.vigenciaPlaza[1].format("YYYY-MM-DD");

      let inicio = convertirFechaString(moment(fechaInicioStr), 1).fechaString;
      let fin = convertirFechaString(moment(fechaFiStr), 2).fechaString;

      let nuevaPlaza = {
        NombrePlaza: values.nombrePlaza,
        Cantidad: values.numeroPersonasPlaza,
        Cargo: values.cargoTrabajador,
        Titulos_Obligatorios: values.titulosObligatoriosTrabajador,
        Especialidades_Obligatorias:
          values.especialidadesObligatoriasTrabajador,
        Titulos_Opcionales: values.titulosOpcionalesTrabajador,
        Especialidades_Opcionales: values.especialidadesOpcionalesTrabajador,
        NuevaPlaza: true,
      };

      let objAgregarPlaza = {
        nuevaPlaza: nuevaPlaza,
        fechaInicio: inicio,
        fechaFin: fin,
        IdFaena: parametros.idFaena,
      };

      const agregarPlaza = await agregarNuevaPlaza(objAgregarPlaza, idEmpresa);

      let InicioBusquedaNew = convertirFechaString(values.vigenciaPlaza[0].clone(),1).fechaString;
      let FinBusquedaNew = convertirFechaString(values.vigenciaPlaza[1].clone(), 2).fechaString;
      if (agregarPlaza.status === 200) {
        const bitacoraTrabajadores = await detalleActividad(
          idEmpresa,
          parametros.idFaena,
          // values.vigenciaPlaza[0],
          // values.vigenciaPlaza[1]
          InicioBusquedaNew,
          FinBusquedaNew
        );

        let plazasActividad = [...bitacoraTrabajadores.estructuraPlazas];
        let plazasFiltro = bitacoraTrabajadores.PlazasBuscadas;

        this.setState({
          plazasActividad,
          plazasFiltro,
          loading: false,
          modalModificarActividad: false,
          contenidoModificarActividad: "none",
          selectModificarActividad: undefined,
          fechasSeleccionadas: [
            values.vigenciaPlaza[0],
            values.vigenciaPlaza[1],
          ],
        });

        this.formRefAgregarNuevaPlaza.current.setFieldsValue({
          vigenciaPlaza: undefined,
          nombrePlaza: undefined,
          numeroPersonasPlaza: undefined,
          cargoTrabajador: undefined,
          titulosObligatoriosTrabajador: undefined,
          especialidadesObligatoriasTrabajador: undefined,
          titulosOpcionalesTrabajador: undefined,
          especialidadesOpcionalesTrabajador: undefined,
        });

        Swal.fire({
          title: "Proceso exitoso",
          text: "Se agregó una nueva plaza a la actividad",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
      } else {
        this.setState({ loading: false });
        Swal.fire({
          title: "Error",
          text: agregarPlaza.mensaje,
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
      }
    }
  };

  agregarPuestoTrabajo = async (values) => {
    this.setState({ loading: true });
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      let parametros = queryString.parse(this.props.location.search);

      let fechaInicioStr = values.vigenciaPlaza[0].format("YYYY-MM-DD");
      let fechaFiStr = values.vigenciaPlaza[1].format("YYYY-MM-DD");

      let inicio = convertirFechaString(moment(fechaInicioStr), 1).fechaString;
      let fin = convertirFechaString(moment(fechaFiStr), 2).fechaString;

      let objAgregarPuestoTrabajo = {
        fechaInicio: inicio,
        fechaFin: fin,
        IdFaena: parametros.idFaena,
        IdPlaza: values.plazaTrabajador,
        Cantidad: values.numeroPersonasPlaza,
      };

      const aumentarPlazaExistente = await agregarPuestoTrabajo(
        objAgregarPuestoTrabajo,
        idEmpresa
      );

      if (aumentarPlazaExistente.status === 200) {
        let InicioBusquedaNew = convertirFechaString(values.vigenciaPlaza[0].clone(),1).fechaString;
        let FinBusquedaNew = convertirFechaString(values.vigenciaPlaza[1].clone(), 2).fechaString;
        const bitacoraTrabajadores = await detalleActividad(
          idEmpresa,
          parametros.idFaena,
          // values.vigenciaPlaza[0],
          // values.vigenciaPlaza[1]
          InicioBusquedaNew,
          FinBusquedaNew
        );

        let plazasActividad = [...bitacoraTrabajadores.estructuraPlazas];
        let plazasFiltro = bitacoraTrabajadores.PlazasBuscadas;

        this.setState({
          plazasActividad,
          plazasFiltro,
          loading: false,
          modalModificarActividad: false,
          contenidoModificarActividad: "none",
          selectModificarActividad: undefined,
          fechasSeleccionadas: [
            values.vigenciaPlaza[0],
            values.vigenciaPlaza[1],
          ],
        });

        this.formRefAgregarPuestoTrabajo.current.setFieldsValue({
          vigenciaPlaza: undefined,
          plazaTrabajador: undefined,
          numeroPersonasPlaza: undefined,
        });

        Swal.fire({
          title: "Proceso exitoso",
          text: "Se agregó un puesto plaza a la actividad",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
      } else {
        this.setState({ loading: false });
        Swal.fire({
          title: "Error",
          text: aumentarPlazaExistente.mensaje,
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
      }
    }
  };

  editarPuestoTrabajo = async (values) => {
    this.setState({ loading: true });
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      let parametros = queryString.parse(this.props.location.search);

      let fechaInicioStr =
        values.fechasEdicionPuestoPlaza[0].format("YYYY-MM-DD");
      let fechaFiStr = values.fechasEdicionPuestoPlaza[1].format("YYYY-MM-DD");

      let inicio = convertirFechaString(moment(fechaInicioStr), 1).fechaString;
      let fin = convertirFechaString(moment(fechaFiStr), 2).fechaString;

      let objEditarPuestoTrabajo = {
        IdFaena: parametros.idFaena,
        IdPlaza: values.plazaTrabajador,
        IdPuestoPlaza: values.puestoPlazaTrabajador,
        fechaInicioEdicion: inicio,
        fechaFinEdicion: fin,
      };

      if (values.fechasDisponibilidadPuestoPlaza) {
        objEditarPuestoTrabajo.IdFechasEdicion =
          values.fechasDisponibilidadPuestoPlaza;
      }

      const editarPlazaExistente = await editarPuestoTrabajo(
        objEditarPuestoTrabajo,
        idEmpresa
      );

      if (editarPlazaExistente.status === 200) {
        let InicioBusquedaNew = convertirFechaString(values.fechasEdicionPuestoPlaza[0].clone(),1).fechaString;
        let FinBusquedaNew = convertirFechaString(values.fechasEdicionPuestoPlaza[1].clone(), 2).fechaString;
        const bitacoraTrabajadores = await detalleActividad(
          idEmpresa,
          parametros.idFaena,
          // values.fechasEdicionPuestoPlaza[0],
          // values.fechasEdicionPuestoPlaza[1]
          InicioBusquedaNew,
          FinBusquedaNew,
        );

        let plazasActividad = [...bitacoraTrabajadores.estructuraPlazas];
        let plazasFiltro = bitacoraTrabajadores.PlazasBuscadas;

        this.setState({
          plazasActividad,
          plazasFiltro,
          loading: false,
          modalModificarActividad: false,
          contenidoModificarActividad: "none",
          selectModificarActividad: undefined,
          fechasSeleccionadas: [
            values.fechasEdicionPuestoPlaza[0],
            values.fechasEdicionPuestoPlaza[1],
          ],
        });

        this.formRefEditarPuestoTrabajo.current.setFieldsValue({
          plazaTrabajador: undefined,
          puestoPlazaTrabajador: undefined,
          fechasDisponibilidadPuestoPlaza: undefined,
          fechasEdicionPuestoPlaza: undefined,
        });

        Swal.fire({
          title: "Proceso exitoso",
          text: "Se edito el puesto plaza a la actividad",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
      } else {
        this.setState({ loading: false });
        Swal.fire({
          title: "Error",
          text: editarPlazaExistente.mensaje,
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
      }
    }
  };

  suprimirPlazaExistente = async (values) => {
    this.setState({ loading: true });
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      let parametros = queryString.parse(this.props.location.search);

      let objSuprimirPlaza = {
        IdFaena: parametros.idFaena,
        IdPlaza: values.plazaTrabajador,
        IdPuestoPlaza: values.puestoPlazaTrabajador,
        IdFechasEliminacion: values.fechasDisponibilidadPuestoPlaza
          ? values.fechasDisponibilidadPuestoPlaza
          : undefined,
      };

      const suprimirPlazaExistente = await suprimirPlaza(
        objSuprimirPlaza,
        idEmpresa
      );

      if (suprimirPlazaExistente.status === 200) {
        const { fechasSeleccionadas } = this.state;

        let InicioBusquedaNew = convertirFechaString(fechasSeleccionadas[0].clone(),1).fechaString;
        let FinBusquedaNew = convertirFechaString(fechasSeleccionadas[1].clone(), 2).fechaString;
        const bitacoraTrabajadores = await detalleActividad(
          idEmpresa,
          parametros.idFaena,
          // fechasSeleccionadas[0],
          // fechasSeleccionadas[1]
          InicioBusquedaNew,
          FinBusquedaNew
        );

        let plazasActividad = [...bitacoraTrabajadores.estructuraPlazas];
        let plazasFiltro = bitacoraTrabajadores.PlazasBuscadas;

        this.setState({
          plazasActividad,
          plazasFiltro,
          loading: false,
          modalModificarActividad: false,
          contenidoModificarActividad: "none",
          selectModificarActividad: undefined,
        });

        this.formRefEliminarPuestoTrabajo.current.setFieldsValue({
          plazaTrabajador: undefined,
          puestoPlazaTrabajador: undefined,
          fechasDisponibilidadPuestoPlaza: undefined,
        });

        Swal.fire({
          title: "Proceso exitoso",
          text: "Se elimino un puesto plaza de la actividad",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
      } else {
        this.setState({ loading: false });
        Swal.fire({
          title: "Error",
          text: suprimirPlazaExistente.mensaje,
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
      }
    }
  };

  buscarPuestoPlaza = async (idPlaza, funcion) => {
    this.setState({ bloquearNuevoRango: true, fechasDisponibilidad: [] });
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      let parametros = queryString.parse(this.props.location.search);

      if (funcion === "Editar") {
        this.formRefEditarPuestoTrabajo.current.setFieldsValue({
          puestoPlazaTrabajador: undefined,
          fechasDisponibilidadPuestoPlaza: undefined,
        });
      } else {
        this.formRefEliminarPuestoTrabajo.current.setFieldsValue({
          puestoPlazaTrabajador: undefined,
          fechasDisponibilidadPuestoPlaza: undefined,
        });
      }

      const puestoPlaza = await traerPlazasTrabajador(
        parametros.idFaena,
        idEmpresa,
        idPlaza
      );

      const puestoPlazaTrabajador = puestoPlaza[0].PuestosPlaza;

      this.setState({ puestoPlazaTrabajador });
    }
  };

  buscarFechasDisponibilidadPuestoPlaza = async (idPuestoPlaza, funcion) => {
    if (funcion === "Editar") {
      this.formRefEditarPuestoTrabajo.current.setFieldsValue({
        fechasDisponibilidadPuestoPlaza: undefined,
      });
    } else {
      this.formRefEliminarPuestoTrabajo.current.setFieldsValue({
        fechasDisponibilidadPuestoPlaza: undefined,
      });
    }

    const { puestoPlazaTrabajador } = this.state;

    const puestoPlaza = puestoPlazaTrabajador.filter(
      (puestoPlaza) => puestoPlaza._id.toString() === idPuestoPlaza.toString()
    );

    const fechasDisponibilidad = puestoPlaza[0].FechasDisponibilidad;

    if (fechasDisponibilidad.length > 0) {
      this.setState({ bloquearNuevoRango: true, fechasDisponibilidad });
    } else {
      this.setState({ bloquearNuevoRango: false, fechasDisponibilidad: [] });
    }
  };

  desbloquearNuevoRango = (value) => {
    const { fechasDisponibilidad } = this.state;

    const index = fechasDisponibilidad.findIndex(
      (element) => element._id.toString() === value.toString()
    );

    if (index >= 0) {
      const fecha = fechasDisponibilidad[index];
      this.formRefEditarPuestoTrabajo.current.setFieldsValue({
        fechasEdicionPuestoPlaza: [
          moment.utc(fecha.FechaInicio),
          moment.utc(fecha.FechaFin),
        ],
      });
    }

    this.setState({
      bloquearNuevoRango: false,
    });
  };

  render() {
    const {
      nombreCentroCosto,
      nombreActividad,
      plazasFiltro,
      plazasActividad,
      fechasSeleccionadas,

      drawerAsignar,
      drawerEditar,
      drawerEliminar,

      detalleTrabajador,

      fechasEditarPlanificacion,
      modalVerDatosTrabajador,

      nombreTrabajadorAsignar,
      titulosTrabajadorAsignar,
      especialidadesTrabajadorAsignar,
      regimenTrabajadorAsignar,
      contratoTrabajadorAsignar,
      contratosTrabajador,

      fechasHabilitadas,
      diasRelevo,
      diasDisponibles,

      modalModificarActividad,
      selectModificarActividad,
      contenidoModificarActividad,
      agregarPlazaNueva,
      agregarPuestoTrabajo,
      editarPuestoTrabajo,
      eliminarPuestoTrabajo,
      cargosTrabajadores,
      titulosObligatorios,
      titulosOpcionales,
      especialidadesObligatorias,
      especialidadesOpcionales,
      plazasTrabajador,
      puestoPlazaTrabajador,
      puestoPlazaSeleccionado,
      fechasDisponibilidad,
      fechasDisponiblesSeleccionadas,

      bloquearNuevoRango,
    } = this.state;

    return (
      <>
        {this.RedireccionarDetalle()}

        <Layout style={{ height: "100%" }}>
          <Loading cargando={this.state.loading} />

          <Header style={{ background: "none" }}>
            <HeaderFicha
              titulo={`Detalle De Actividad: ${nombreActividad}`}
              subtitulo="Visualiza detalles"
            />
          </Header>

          <hr className="division" style={{ marginRight: 35 }} />

          <Content
            className="contenedor-general"
            style={{ background: "none" }}
          >
            <Row style={{ padding: "6px 0px 22px" }} align="bottom">
              <Col span={24} className="menu-header-detalle-actividad">
                <Row>
                  <Col span={24}>
                    <p
                      style={{
                        fontFamily: "MavenPro-Bold",
                        marginBottom: "3px",
                        marginTop: "20px",
                      }}
                    >
                      Filtrar por:
                    </p>

                    <Row gutter={15}>
                      <Col xs={24} sm={24} md={8} lg={8} xl={4}>
                        <Select
                          mode="multiple"
                          className="select-plazas-detalle-actividad"
                          placeholder="Plazas"
                          maxTagCount={1}
                          onChange={(plazasSeleccionadas) =>
                            this.setState({ plazasSeleccionadas })
                          }
                        >
                          {plazasFiltro.map((plaza) => (
                            <Select.Option
                              value={plaza._id}
                              // value={plaza.IdPlaza ? `{"NuevaPlaza": false, "IdPlaza": "${plaza._id}"}` : `{"NuevaPlaza": true, "IdPlaza": "${plaza._id}"}`}
                              key={plaza.IdPlaza}
                            >
                              {plaza.NombreDePlaza}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>

                      <Col xs={24} sm={24} md={8} lg={8} xl={5}>
                        <RangePicker
                          locale={locale}
                          format={["DD-MM-YYYY"]}
                          className="range-picker-detalle-actividad"
                          clearIcon={false}
                          onChange={(fechas) => {
                            let strFechaInicio = fechas[0].format("YYYY-MM-DD");
                            let strFechaFin = fechas[1].format("YYYY-MM-DD");

                            this.setState({
                              fechasSeleccionadas: [
                                moment.utc(
                                  convertirFechaString(
                                    moment(strFechaInicio),
                                    1
                                  ).fechaString
                                ),
                                moment.utc(
                                  convertirFechaString(moment(strFechaFin), 2)
                                    .fechaString
                                ),
                              ],
                            });
                          }}
                          value={fechasSeleccionadas}
                          disabledDate={this.disabledDate}
                        />
                      </Col>

                      <Col xs={24} sm={24} md={8} lg={8} xl={4}>
                        <Button
                          style={{ width: "156px" }}
                          className="boton-filtrar-detalle-actividad"
                          onClick={this.filtrarPorPlazayFecha}
                        >
                          Filtrar
                        </Button>
                      </Col>

                      <Col
                        xs={24}
                        sm={24}
                        md={8}
                        lg={8}
                        xl={7}
                        style={{ textAlign: "right" }}
                      >
                        <span style={{ marginRight: "2px", color: "red" }}>
                          **
                        </span>
                        <label>
                          Cargos modificados después de la creación de la
                          actividad
                        </label>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Card className="tarjeta-contenedora-detalle-actividad">
              <Row>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <h3
                    style={{
                      textAlign: "left",
                      color: "#061FCB",
                      fontFamily: "MavenPro-Bold",
                    }}
                  >
                    {nombreCentroCosto}
                  </h3>
                </Col>
                {/* <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                                    <a>
                                        <img
                                            style={{ float: 'right' }}
                                            src={descargarExcel}
                                            alt="next"
                                        />
                                    </a>
                                </Col> */}
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Table
                    className="tabla-detalle-actividad"
                    columns={columns}
                    expandable={{
                      expandedRowRender: (record) => {
                        const trabajadores = record.bitacoraTrabajadores;
                        const data = [];

                        trabajadores.forEach((trabajador) => {
                          let strInicio = moment
                            .utc(trabajador.Inicio_Planificacion)
                            .format("YYYY-MM-DD");
                          let strFin = moment
                            .utc(trabajador.Fin_Planificacion)
                            .format("YYYY-MM-DD");

                          let fechaFormateadaInicio = convertirFechaString(
                            moment.utc(strInicio),
                            1
                          ).fechaDDMMYYYY;
                          let fechaFormateadaFin = convertirFechaString(
                            moment.utc(strFin),
                            2
                          ).fechaDDMMYYYY;

                          let cronograma = {};
                          cronograma.fechaFormateadaInicio =
                            fechaFormateadaInicio;
                          cronograma.fechaFormateadaFin = fechaFormateadaFin;
                          cronograma.backgroundColor = trabajador.Trabajadores
                            ? "#64B4E5"
                            : "#437899";

                          let acciones = {};

                          acciones.idTrabajador = trabajador.Trabajadores
                            ? trabajador.Trabajadores._id
                            : "";
                          acciones.trabajador = trabajador.Trabajadores
                            ? trabajador.Trabajadores.Nombre +
                              " " +
                              trabajador.Trabajadores.Apellido_Paterno
                            : "No asignado";
                          acciones.nombreTrabajador = trabajador.Trabajadores
                            ? trabajador.Trabajadores.Nombre
                            : "";
                          acciones.apellidoPaternoTrabajador =
                            trabajador.Trabajadores
                              ? trabajador.Trabajadores.Apellido_Paterno
                              : "";
                          acciones.apellidoMaternoTrabajador =
                            trabajador.Trabajadores
                              ? trabajador.Trabajadores.Apellido_Materno
                              : "";
                          acciones.imagenTrabajador = trabajador.Trabajadores
                            ? trabajador.Trabajadores.Imagen
                            : "";

                          acciones.inicioPlanificacion =
                            trabajador.Inicio_Planificacion;
                          acciones.finPlanificacion =
                            trabajador.Fin_Planificacion;
                          acciones.centroCosto = trabajador.Centro_Costos;
                          acciones.idActividad = trabajador.IdFaena;
                          acciones.idPlaza = trabajador.IdPlaza;
                          acciones.idPlazaPlantilla =
                            trabajador.idPlazaPlantilla;
                          acciones.idPuestoPlaza = trabajador.IdPuestoPlaza;
                          acciones.rut = trabajador.Trabajadores
                            ? trabajador.Trabajadores.Rut
                            : "";
                          acciones.idPlazaEsquema = trabajador.idPlazaEsquema;
                          acciones.IdRangoFechas = trabajador.IdRangoFechas;
                          acciones.isRelevo = trabajador.isRelevo;

                          data.push({
                            key: 0,
                            plazaNoCubierta: trabajador.Trabajadores
                              ? ""
                              : "No asignado",
                            nombre: trabajador.Trabajadores
                              ? trabajador.Trabajadores.Nombre +
                                " " +
                                trabajador.Trabajadores.Apellido_Paterno
                              : "No asignado",
                            diasAsignados: trabajador.Trabajadores
                              ? moment
                                  .utc(trabajador.Fin_Planificacion)
                                  .diff(
                                    moment.utc(trabajador.Inicio_Planificacion),
                                    "days"
                                  ) + 1
                              : 0,
                            diasNoAsignados: trabajador.Trabajadores
                              ? 0
                              : moment
                                  .utc(trabajador.Fin_Planificacion)
                                  .diff(
                                    moment.utc(trabajador.Inicio_Planificacion),
                                    "days"
                                  ) + 1,
                            cronograma: cronograma,
                            acciones: acciones,
                          });
                        });

                        return (
                          <Table
                            columns={this.columnsTablaExpandible}
                            dataSource={data}
                            pagination={false}
                            className="tabla-contenido-detalle-actividad"
                          />
                        );
                      },
                    }}
                    dataSource={plazasActividad}
                    pagination={false}
                    scroll={{ x: true }}
                  />
                </Col>
              </Row>

              <Button
                className="boton-agregar-plaza-detalle-actividad"
                onClick={this.modificarActividad}
              >
                <PlusCircleOutlined
                  style={{ fontSize: "130%", paddingTop: "2px" }}
                />
                Modificar Actividad
              </Button>
            </Card>

            {/* MODAL AGREGAR PLAZA */}
            <Modal
              visible={modalModificarActividad}
              onCancel={() => {
                this.setState({
                  modalModificarActividad: false,
                });
              }}
              bodyStyle={{ borderRadius: "16px", padding: "2rem" }}
              footer={null}
              style={{ top: "15%" }}
              className={"modalModificarActividad"}
              closable={false}
              maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
            >
              <Row gutter={10} style={{ marginBottom: "20px" }}>
                <Col
                  span={6}
                  style={{
                    textAlign: "right",
                    padding: "5px 10px 0px 0px",
                  }}
                >
                  <label>¿Qué desea realizar?</label>
                </Col>
                <Col span={15}>
                  <Select
                    className="camposAgregarPlaza"
                    name="Cargo"
                    placeholder="Selecciona Titulos"
                    onChange={(value) => {
                      this.mostrarContenidoModificarActividad(value);
                    }}
                    value={selectModificarActividad}
                  >
                    <Select.Option
                      key={"agregarNuevaPlaza"}
                      value={"agregarNuevaPlaza"}
                      disabled={this.state.Editar === true ? false : true}
                    >
                      Agregar nueva plaza
                    </Select.Option>
                    <Select.Option
                      key={"agregarPuestoTrabajo"}
                      value={"agregarPuestoTrabajo"}
                      disabled={this.state.Editar === true ? false : true}
                    >
                      Aumentar plaza existente
                    </Select.Option>
                    <Select.Option
                      key={"editarPuestoTrabajo"}
                      value={"editarPuestoTrabajo"}
                      disabled={this.state.Editar === true ? false : true}
                    >
                      Editar plaza existente
                    </Select.Option>
                    <Select.Option
                      key={"eliminarPuestoTrabajo"}
                      value={"eliminarPuestoTrabajo"}
                      disabled={this.state.Eliminar === true ? false : true}
                    >
                      Suprimir plaza existente
                    </Select.Option>
                  </Select>
                </Col>
              </Row>

              <div style={{ display: contenidoModificarActividad }}>
                {agregarPlazaNueva === true ? (
                  <>
                    <Form
                      name="formAgregarPlazaNueva"
                      onFinish={this.agregarNuevaPlaza}
                      ref={this.formRefAgregarNuevaPlaza}
                    >
                      <Row>
                        <Col
                          span={6}
                          style={{
                            textAlign: "right",
                            padding: "5px 10px 0px 0px",
                          }}
                        >
                          <label>Vigencia de la plaza:</label>
                        </Col>
                        <Col span={15}>
                          <Form.Item
                            name={"vigenciaPlaza"}
                            rules={[
                              {
                                required: true,
                                message: "Ingrese la vigencia de la plaza",
                              },
                            ]}
                          >
                            <RangePicker
                              locale={locale}
                              className="camposAgregarPlaza"
                              clearIcon={false}
                              disabledDate={this.disabledDate}
                              name={"vigenciaPlaza"}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          span={6}
                          style={{
                            textAlign: "right",
                            padding: "5px 10px 0px 0px",
                          }}
                        >
                          <label>Nombre plaza:</label>
                        </Col>

                        <Col
                          span={6}
                          style={{
                            padding: "0px 8px 0px 0px",
                          }}
                        >
                          <Form.Item
                            name={"nombrePlaza"}
                            rules={[
                              {
                                required: true,
                                message: "Ingrese nombre de la plaza",
                              },
                            ]}
                          >
                            <Input name="nombrePlaza" />
                          </Form.Item>
                        </Col>

                        <Col
                          span={3}
                          style={{
                            textAlign: "right",
                            padding: "5px 10px 0px 0px",
                          }}
                        >
                          <label>N° personas:</label>
                        </Col>

                        <Col span={6}>
                          <Form.Item
                            name={"numeroPersonasPlaza"}
                            rules={[
                              {
                                required: true,
                                message: "Ingrese n° de personas de la plaza",
                              },
                            ]}
                          >
                            <InputNumber
                              style={{
                                width: "100%",
                              }}
                              name="numeroPersonasPlaza"
                              type="number"
                              min={0}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          span={6}
                          style={{
                            textAlign: "right",
                            padding: "5px 10px 0px 0px",
                          }}
                        >
                          <label>Seleccione Cargo Trabajador:</label>
                        </Col>
                        <Col span={15}>
                          <Form.Item
                            name={"cargoTrabajador"}
                            rules={[
                              {
                                required: true,
                                message: "Ingrese el cargo del trabajador",
                              },
                            ]}
                          >
                            <Select
                              className="camposAgregarPlaza"
                              name="cargoTrabajador"
                              placeholder="Selecciona cargo"
                            >
                              {cargosTrabajadores.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  name="cargoTrabajador"
                                  value={value._id}
                                >
                                  {value.Nombre}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          span={6}
                          style={{
                            textAlign: "right",
                            padding: "5px 10px 0px 0px",
                          }}
                        >
                          <label>Titulos Obligatorios:</label>
                        </Col>
                        <Col span={15}>
                          <Form.Item
                            label=""
                            name={"titulosObligatoriosTrabajador"}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message:
                            //       "Ingrese el o los titulos del trabajador",
                            //   },
                            // ]}
                          >
                            <Select
                              className="camposAgregarPlaza"
                              name="titulosObligatoriosTrabajador"
                              placeholder="Selecciona Titulos"
                              mode="multiple"
                            >
                              {titulosObligatorios.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  name="titulosObligatoriosTrabajador"
                                  value={value._id}
                                >
                                  {value.Nombre}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          span={6}
                          style={{
                            textAlign: "right",
                            padding: "5px 10px 0px 0px",
                          }}
                        >
                          <label>Titulos opcionales:</label>
                        </Col>
                        <Col span={15}>
                          <Form.Item
                            label=""
                            name={"titulosOpcionalesTrabajador"}
                          >
                            <Select
                              className="camposAgregarPlaza"
                              name="titulosOpcionalesTrabajador"
                              placeholder="Selecciona Titulos"
                              mode="multiple"
                            >
                              {titulosOpcionales.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  name="titulosOpcionalesTrabajador"
                                  value={value._id}
                                >
                                  {value.Nombre}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          span={6}
                          style={{
                            textAlign: "right",
                            padding: "5px 10px 0px 0px",
                          }}
                        >
                          <label>Especialidades obligatorias:</label>
                        </Col>
                        <Col span={15}>
                          <Form.Item
                            name={"especialidadesObligatoriasTrabajador"}
                            //  rules={[
                            //   {
                            //     required: true,
                            //     message:
                            //       "Ingrese la o las especialidades del trabajador",
                            //   },
                            // ]}
                          >
                            <Select
                              className="camposAgregarPlaza"
                              name="especialidadesObligatoriasTrabajador"
                              placeholder="Selecciona Especialidades"
                              mode="multiple"
                            >
                              {especialidadesObligatorias.map(
                                (value, index) => (
                                  <Select.Option
                                    key={index}
                                    name="especialidadesObligatoriasTrabajador"
                                    value={value._id}
                                  >
                                    {value.Nombre}
                                  </Select.Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          span={6}
                          style={{
                            textAlign: "right",
                            padding: "5px 10px 0px 0px",
                          }}
                        >
                          <label>Especialidades opcionales: </label>
                        </Col>
                        <Col span={15}>
                          <Form.Item
                            name={"especialidadesOpcionalesTrabajador"}
                          >
                            <Select
                              className="camposAgregarPlaza"
                              name="especialidadesOpcionalesTrabajador"
                              placeholder="Selecciona Especialidades"
                              mode="multiple"
                            >
                              {especialidadesOpcionales.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  name="especialidadesOpcionalesTrabajador"
                                  value={value._id}
                                >
                                  {value.Nombre}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <div style={{ textAlign: "right" }}>
                        <Button
                          className="guardar-form"
                          type="primary"
                          htmlType="submit"
                          form="formAgregarPlazaNueva"
                          disabled={this.state.Editar === true ? false : true}
                        >
                          Agregar plaza
                        </Button>
                      </div>
                    </Form>
                  </>
                ) : (
                  <></>
                )}

                {agregarPuestoTrabajo === true ? (
                  <>
                    <Form
                      name="formAgregarPuestoTrabajo"
                      onFinish={this.agregarPuestoTrabajo}
                      ref={this.formRefAgregarPuestoTrabajo}
                    >
                      <Row>
                        <Col
                          span={6}
                          style={{
                            textAlign: "right",
                            padding: "5px 10px 0px 0px",
                          }}
                        >
                          <label>Vigencia de la plaza:</label>
                        </Col>
                        <Col span={15}>
                          <Form.Item
                            name={"vigenciaPlaza"}
                            rules={[
                              {
                                required: true,
                                message: "Ingrese la vigencia de la plaza",
                              },
                            ]}
                          >
                            <RangePicker
                              locale={locale}
                              className="camposAgregarPlaza"
                              clearIcon={false}
                              disabledDate={this.disabledDate}
                              name={"vigenciaPlaza"}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          span={6}
                          style={{
                            textAlign: "right",
                            padding: "5px 10px 0px 0px",
                          }}
                        >
                          <label>Seleccione plaza trabajador:</label>
                        </Col>

                        <Col
                          span={6}
                          style={{
                            padding: "0px 8px 0px 0px",
                          }}
                        >
                          <Form.Item
                            name={"plazaTrabajador"}
                            rules={[
                              {
                                required: true,
                                message: "Ingrese la plaza del trabajador",
                              },
                            ]}
                          >
                            <Select
                              className="camposAgregarPlaza"
                              name="plazaTrabajador"
                              placeholder="Selecciona cargo"
                            >
                              {plazasTrabajador.map((plaza, index) => (
                                <Select.Option
                                  key={index}
                                  name="plazaTrabajador"
                                  value={plaza._id}
                                >
                                  {plaza.NombreDePlaza}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col
                          span={3}
                          style={{
                            textAlign: "right",
                            padding: "5px 10px 0px 0px",
                          }}
                        >
                          <label>N° personas:</label>
                        </Col>

                        <Col span={6}>
                          <Form.Item
                            name={"numeroPersonasPlaza"}
                            rules={[
                              {
                                required: true,
                                message: "Ingrese n° de personas de la plaza",
                              },
                            ]}
                          >
                            <InputNumber
                              style={{
                                width: "100%",
                              }}
                              name="numeroPersonasPlaza"
                              type="number"
                              min={0}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <div style={{ textAlign: "right" }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          form="formAgregarPuestoTrabajo"
                          className="guardar-form"
                          disabled={this.state.Editar === true ? false : true}
                        >
                          Agregar plaza
                        </Button>
                      </div>
                    </Form>
                  </>
                ) : (
                  <></>
                )}

                {editarPuestoTrabajo === true ? (
                  <>
                    <Form
                      name="formEditarPuestoTrabajo"
                      onFinish={this.editarPuestoTrabajo}
                      ref={this.formRefEditarPuestoTrabajo}
                    >
                      <Row>
                        <Col
                          span={6}
                          style={{
                            textAlign: "right",
                            padding: "5px 10px 0px 0px",
                          }}
                        >
                          <label>Seleccione plaza trabajador:</label>
                        </Col>
                        <Col span={15}>
                          <Form.Item
                            name={"plazaTrabajador"}
                            rules={[
                              {
                                required: true,
                                message: "Ingrese la plaza del trabajador",
                              },
                            ]}
                          >
                            <Select
                              className="camposAgregarPlaza"
                              name="plazaTrabajador"
                              placeholder="Selecciona cargo"
                              onChange={(value) =>
                                this.buscarPuestoPlaza(value, "Editar")
                              }
                            >
                              {plazasTrabajador.map((plaza, index) => (
                                <Select.Option
                                  key={index}
                                  name="plazaTrabajador"
                                  value={plaza._id}
                                >
                                  {plaza.NombreDePlaza}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      {puestoPlazaTrabajador.length > 0 ? (
                        <>
                          <Row>
                            <Col
                              span={6}
                              style={{
                                textAlign: "right",
                                padding: "5px 10px 0px 0px",
                              }}
                            >
                              <label>Seleccione puesto plaza:</label>
                            </Col>
                            <Col span={15}>
                              <Form.Item
                                name={"puestoPlazaTrabajador"}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Ingrese el puesto plaza del trabajador",
                                  },
                                ]}
                              >
                                <Select
                                  className="camposAgregarPlaza"
                                  name="puestoPlazaTrabajador"
                                  placeholder="Selecciona puesto plaza"
                                  onChange={(value) =>
                                    this.buscarFechasDisponibilidadPuestoPlaza(
                                      value,
                                      "Editar"
                                    )
                                  }
                                >
                                  {puestoPlazaTrabajador.map(
                                    (puestoPlaza, index) => (
                                      <Select.Option
                                        key={index}
                                        name="puestoPlazaTrabajador"
                                        value={puestoPlaza._id}
                                      >
                                        {puestoPlaza.NombrePuestoPlaza}
                                      </Select.Option>
                                    )
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}

                      {fechasDisponibilidad.length > 0 ? (
                        <>
                          <Row>
                            <Col
                              span={6}
                              style={{
                                textAlign: "right",
                                padding: "5px 10px 0px 0px",
                              }}
                            >
                              <label>Fechas Disponibilidad:</label>
                            </Col>
                            <Col span={15}>
                              <Form.Item
                                name={"fechasDisponibilidadPuestoPlaza"}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Ingrese el puesto plaza del trabajador",
                                  },
                                ]}
                              >
                                <Select
                                  className="camposAgregarPlaza"
                                  name="fechasDisponibilidadPuestoPlaza"
                                  placeholder="Selecciona puesto plaza"
                                  onChange={(value) =>
                                    this.desbloquearNuevoRango(value)
                                  }
                                >
                                  {fechasDisponibilidad.map(
                                    (fechaDisponibilidad, index) => (
                                      <Select.Option
                                        key={index}
                                        name="fechasDisponibilidadPuestoPlaza"
                                        value={fechaDisponibilidad._id}
                                      >
                                        {moment
                                          .utc(fechaDisponibilidad.FechaInicio)
                                          .format("DD-MM-YYYY")}{" "}
                                        -{" "}
                                        {moment
                                          .utc(fechaDisponibilidad.FechaFin)
                                          .format("DD-MM-YYYY")}
                                      </Select.Option>
                                    )
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}

                      <Row>
                        <Col
                          span={6}
                          style={{
                            textAlign: "right",
                            padding: "5px 10px 0px 0px",
                          }}
                        >
                          <label>Nuevo rango plaza:</label>
                        </Col>
                        <Col span={15}>
                          <Form.Item
                            name={"fechasEdicionPuestoPlaza"}
                            rules={[
                              {
                                required: true,
                                message:
                                  "Ingrese las nuevas fechas de la plaza seleccionada",
                              },
                            ]}
                          >
                            <RangePicker
                              locale={locale}
                              className="camposAgregarPlaza"
                              clearIcon={false}
                              disabledDate={this.disabledDate}
                              name={"fechasEdicionPuestoPlaza"}
                              disabled={bloquearNuevoRango}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <div style={{ textAlign: "right" }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          form="formEditarPuestoTrabajo"
                          className="guardar-form"
                          disabled={this.state.Editar === true ? false : true}
                        >
                          Editar plaza
                        </Button>
                      </div>
                    </Form>
                  </>
                ) : (
                  <></>
                )}

                {eliminarPuestoTrabajo === true ? (
                  <>
                    <Form
                      name="formEliminarPuestoTrabajo"
                      onFinish={this.suprimirPlazaExistente}
                      ref={this.formRefEliminarPuestoTrabajo}
                    >
                      <Row>
                        <Col
                          span={6}
                          style={{
                            textAlign: "right",
                            padding: "5px 10px 0px 0px",
                          }}
                        >
                          <label>Seleccione plaza trabajador:</label>
                        </Col>
                        <Col span={15}>
                          <Form.Item
                            name={"plazaTrabajador"}
                            rules={[
                              {
                                required: true,
                                message: "Ingrese la plaza del trabajador",
                              },
                            ]}
                          >
                            <Select
                              className="camposAgregarPlaza"
                              name="plazaTrabajador"
                              placeholder="Selecciona cargo"
                              onChange={(value) =>
                                this.buscarPuestoPlaza(value, "Eliminar")
                              }
                            >
                              {plazasTrabajador.map((plaza, index) => (
                                <Select.Option
                                  key={index}
                                  name="plazaTrabajador"
                                  value={plaza._id}
                                >
                                  {plaza.NombreDePlaza}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      {puestoPlazaTrabajador.length > 0 ? (
                        <>
                          <Row>
                            <Col
                              span={6}
                              style={{
                                textAlign: "right",
                                padding: "5px 10px 0px 0px",
                              }}
                            >
                              <label>Seleccione puesto plaza:</label>
                            </Col>
                            <Col span={15}>
                              <Form.Item
                                name={"puestoPlazaTrabajador"}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Ingrese el puesto plaza del trabajador",
                                  },
                                ]}
                              >
                                <Select
                                  className="camposAgregarPlaza"
                                  name="puestoPlazaTrabajador"
                                  placeholder="Selecciona puesto plaza"
                                  onChange={(value) => {
                                    this.buscarFechasDisponibilidadPuestoPlaza(
                                      value,
                                      "Eliminar"
                                    );
                                    this.setState({ puestoPlaza: value });
                                  }}
                                >
                                  {puestoPlazaTrabajador.map(
                                    (puestoPlaza, index) => (
                                      <Select.Option
                                        key={index}
                                        name="puestoPlazaTrabajador"
                                        value={puestoPlaza._id}
                                      >
                                        {puestoPlaza.NombrePuestoPlaza}
                                      </Select.Option>
                                    )
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}

                      {fechasDisponibilidad.length > 0 ? (
                        <>
                          <Row>
                            <Col
                              span={6}
                              style={{
                                textAlign: "right",
                                padding: "5px 10px 0px 0px",
                              }}
                            >
                              <label>Fechas Disponibilidad:</label>
                            </Col>
                            <Col span={15}>
                              <Form.Item
                                name={"fechasDisponibilidadPuestoPlaza"}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Ingrese el puesto plaza del trabajador",
                                  },
                                ]}
                              >
                                <Select
                                  className="camposAgregarPlaza"
                                  name="fechasDisponibilidadPuestoPlaza"
                                  placeholder="Selecciona puesto plaza"
                                >
                                  {fechasDisponibilidad.map(
                                    (fechaDisponibilidad, index) => (
                                      <Select.Option
                                        key={index}
                                        name="fechasDisponibilidadPuestoPlaza"
                                        value={fechaDisponibilidad._id}
                                      >
                                        {moment
                                          .utc(fechaDisponibilidad.FechaInicio)
                                          .format("DD-MM-YYYY")}{" "}
                                        -{" "}
                                        {moment
                                          .utc(fechaDisponibilidad.FechaFin)
                                          .format("DD-MM-YYYY")}
                                      </Select.Option>
                                    )
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}

                      <div style={{ textAlign: "right" }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          form="formEliminarPuestoTrabajo"
                          className="guardar-form"
                          disabled={this.state.Eliminar === true ? false : true}
                        >
                          Eliminar plaza
                        </Button>
                      </div>
                    </Form>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Modal>

            {/* DRAWER ASIGNAR */}
            <Drawer
              style={{ zIndex: "1000" }}
              placement={"right"}
              closable={true}
              className={"drawerAsignarTrabajador"}
              onClose={() => {
                // this.props.ocultarTrabajdores();
                this.setState({
                  drawerAsignar: false,
                  trabajadorSearch: "",
                  trabajadoresAsignarFiltrados: null,
                  trabajadoresFiniquitadosFiltrados: null,
                });
              }}
              visible={drawerAsignar}
              key={"1"}
            >
              <Row>
                <Col span={24}>
                  <h4 className="titulos-buscar-trabajador">
                    Perfiles para la plaza{" "}
                    {this.state.cantidadDiasBusqueda === 0
                      ? ""
                      : `,cantidad de dias por asignar: ${this.state.cantidadDiasBusqueda} `}
                  </h4>
                </Col>

                <Col span={24}>
                  <Search
                    placeholder="Buscar por nombre de trabajador"
                    onChange={(e) => {
                      this.setBusqueda(e.target.value);
                    }}
                    value={this.state.trabajadorSearch}
                    enterButton
                  />
                </Col>

                <Col className="cont-trabajador" span={24}>
                  {this.mostrarTrabajadoresAsignar()}
                </Col>
              </Row>
            </Drawer>

            {/* DRAWER EDITAR */}
            <Drawer
              style={{ zIndex: "1000" }}
              placement={"right"}
              closable={true}
              onClose={() => {
                this.setState({ drawerEditar: false });
              }}
              visible={drawerEditar}
              key={"3"}
              width={"25%"}
            >
              <Row>
                <Col span={24}>
                  <h4 className="titulos-buscar-trabajador">
                    Editar tiempos del personal
                  </h4>
                </Col>

                <Col span={24}>
                  <h4
                    style={{
                      fontFamily: "MavenPro-Regular",
                      fontSize: "14px",
                      textAlign: "justify",
                      margin: "0px",
                    }}
                  >
                    Importante: Se pueden agregar o quitar días de
                    planificación. Al quitar días se borrarán los días quitados.
                    No se logrará hacer la acción si se desea borrar un día de
                    asistencia pasada.
                  </h4>
                  <br />
                  <br />
                  <div style={{ width: "100%" }}>
                    <div style={{ width: "20%", float: "left" }}>
                      <h4 style={{ color: "#262E2D", fontWeight: "700" }}>
                        Leyenda{" "}
                      </h4>
                    </div>
                    <div style={{ width: "80%", float: "left" }}>
                      <p>
                        Dias de transbordo{" "}
                        <Badge color="#FFBDEB" size="small" />
                      </p>
                    </div>
                  </div>
                </Col>

                <Col className="cont-trabajador" span={24}>
                  <Row key={"1"} className="seccion-trabajador">
                    <Col span={6}>
                      <img
                        alt="seleccionar trabajador"
                        src={detalleTrabajador.imagenTrabajador}
                        className="seleccion-trabajador-img"
                      />
                    </Col>

                    <Col span={18}>
                      <h5
                        className="seleccion-trabajador-nombre"
                        alt="imagen trabajador"
                      >
                        {`${detalleTrabajador.nombreTrabajador} ${detalleTrabajador.apellidoPaternoTrabajador} ${detalleTrabajador.apellidoMaternoTrabajador} `}
                      </h5>
                      <h5
                        className="seleccion-trabajador-disponible"
                        alt="disponibilidad"
                      >
                        {`${moment
                          .utc(detalleTrabajador.inicioPlanificacion)
                          .format("DD-MM-YYYY")} - ${moment
                          .utc(detalleTrabajador.finPlanificacion)
                          .format("DD-MM-YYYY")} `}
                      </h5>
                      <p
                        style={{
                          paddingRight: "15px",
                          color: "#525252",
                          fontFamily: "MavenPro-Bold",
                          margin: "0px 0px 0px 0px",
                        }}
                      >
                        {`${detalleTrabajador.rut}`}
                      </p>
                      <p
                        style={{
                          paddingRight: "15px",
                          color: "#525252",
                          fontFamily: "MavenPro-Bold",
                          margin: "0px 0px 0px 0px",
                        }}
                      >
                        {`Dias disponibles: ${diasDisponibles}`}
                      </p>
                      {
                      detalleTrabajador.isRelevo === true?
                      <div
                        style={{
                          paddingRight: "15px",
                          color: "#525252",
                          fontFamily: "MavenPro-Bold",
                          margin: "0px 0px 0px 0px",
                        }}
                      >
                        Relevo:
                        <img
                          src={IconRelevo}
                          className={"iconoRelevoTable"}
                          style={{ marginLeft: "10px"}}
                          alt="i"
                        />
                      </div>
                      :
                      ''
                      }
                     
                    </Col>

                    <Col span={24} className="seccion-datepicker">
                      <RangePicker
                        locale={locale}
                        format="DD-MM-YYYY"
                        value={fechasEditarPlanificacion}
                        disabled={[true, false]}
                        disabledDate={(current) => {
                          let fechaEncontrada = fechasHabilitadas.filter(
                            (dRelevo) =>
                              current.format("DD-MM-YYYY") ===
                              moment.utc(dRelevo).format("DD-MM-YYYY")
                          );
                          if (fechaEncontrada.length > 0) {
                            return false;
                          } else {
                            return true;
                          }
                        }}
                        dateRender={(current) => {
                          const style = {};
                          if (diasRelevo.length > 0) {
                            let fechaEncontrada = diasRelevo.filter(
                              (dRelevo) =>
                                current.format("DD-MM-YYYY") ===
                                moment.utc(dRelevo).format("DD-MM-YYYY")
                            );
                            if (fechaEncontrada.length > 0) {
                              //style.border = '1px solid #1890ff';
                              style.borderRadius = "50%";
                              style.background = "#ffbcec";
                            }
                          }
                          return (
                            <div
                              className="ant-picker-cell-inner"
                              style={style}
                            >
                              {current.date()}
                            </div>
                          );
                        }}
                        onChange={(value) => {
                          this.setState({
                            fechasEditarPlanificacion: value,
                          });
                        }}
                      />
                    </Col>
                    <Col
                      style={{ textAlign: "right", marginTop: "20px" }}
                      span={12}
                    >
                      <Button
                        onClick={() => {
                          let objEditarPlanificacion = {
                            idTrabajador: detalleTrabajador.idTrabajador,
                            idActividad: detalleTrabajador.idActividad,
                            idPlaza: detalleTrabajador.idPlaza,
                            idPuestoPlaza: detalleTrabajador.idPuestoPlaza,
                            inicioPlanificacion:
                              detalleTrabajador.inicioPlanificacion,
                            finPlanificacion:
                              detalleTrabajador.finPlanificacion,
                          };

                          this.editarCronogramaTrabajador(
                            objEditarPlanificacion
                          );
                        }}
                        className="btn-guardar-pequeño"
                      >
                        Editar Planificación
                      </Button>
                    </Col>

                    <Col
                      style={{ textAlign: "center", marginTop: "20px" }}
                      span={12}
                    ></Col>
                  </Row>
                </Col>
              </Row>
            </Drawer>

            {/* DRAWER ELIMINAR */}
            <Drawer
              style={{ zIndex: "1000" }}
              placement={"right"}
              closable={true}
              onClose={() => {
                this.setState({ drawerEliminar: false });
              }}
              visible={drawerEliminar}
              key={"2"}
              width={"25%"}
            >
              <Row>
                <Col span={24}>
                  <h4 className="titulos-buscar-trabajador">
                    Eliminar tiempos del personal
                  </h4>
                </Col>

                <Col span={24}>
                  <h4
                    style={{
                      fontFamily: "MavenPro-Regular",
                      fontSize: "14px",
                      textAlign: "justify",
                      margin: "0px",
                    }}
                  >
                    Importante: Se eliminarán sólo los días que no tengan
                    asistencia ya cubierta (color azul).
                  </h4>
                </Col>

                <Col className="cont-trabajador" span={24}>
                  <Row key={"1"} className="seccion-trabajador">
                    <Col span={6}>
                      <img
                        alt="seleccionar trabajador"
                        src={detalleTrabajador.imagenTrabajador}
                        className="seleccion-trabajador-img"
                      />
                    </Col>

                    <Col span={18}>
                      <h5
                        className="seleccion-trabajador-nombre"
                        alt="imagen trabajador"
                      >
                        {`${detalleTrabajador.nombreTrabajador} ${detalleTrabajador.apellidoPaternoTrabajador} ${detalleTrabajador.apellidoMaternoTrabajador} `}
                      </h5>
                      <h5
                        className="seleccion-trabajador-disponible"
                        alt="disponibilidad"
                      >
                        {`${moment
                          .utc(detalleTrabajador.inicioPlanificacion)
                          .format("DD-MM-YYYY")} - ${moment
                          .utc(detalleTrabajador.finPlanificacion)
                          .format("DD-MM-YYYY")} `}
                      </h5>
                      
                      {
                        detalleTrabajador.isRelevo === true?
                        <h5
                          className="seleccion-trabajador-relevo"
                          alt="Relevo"
                        >
                          Relevo: 
                          <img
                            src={IconRelevo}
                            className={"iconoRelevoTable"}
                            alt="i"
                          />
                        </h5>
                      
                        :
                        ''
                      }  
                          
                        
                 
                    </Col>
                    <Col
                      style={{ textAlign: "right", marginTop: "20px" }}
                      span={12}
                    >
                      <Button
                        onClick={() => {
                          let objEditarPlanificacion = {
                            idTrabajador: detalleTrabajador.idTrabajador,
                            idActividad: detalleTrabajador.idActividad,
                            idPlaza: detalleTrabajador.idPlaza,
                            idPuestoPlaza: detalleTrabajador.idPuestoPlaza,
                            inicioPlanificacion:
                              detalleTrabajador.inicioPlanificacion,
                            finPlanificacion:
                              detalleTrabajador.finPlanificacion,
                          };

                          this.eliminarCronogramaTrabajador(
                            objEditarPlanificacion
                          );
                        }}
                        className="btn-guardar-pequeño"
                        // disabled={this.props.bitacoraRangoEliminar.length === 0 ? true : false}
                      >
                        Eliminar Planificación
                      </Button>
                    </Col>

                    <Col
                      style={{ textAlign: "center", marginTop: "20px" }}
                      span={12}
                    ></Col>
                  </Row>
                </Col>
              </Row>
            </Drawer>
          </Content>

          {/* MODAL TRANSBORDO */}
          <Modal
            visible={this.state.mostrarModalTransbordo}
            onCancel={() => {
              this.setState({
                mostrarModalTransbordo: false,
              });
            }}
            //propiedades obligatorias para modal mantenedor
            bodyStyle={{ borderRadius: "16px", padding: "0px" }}
            width="35%"
            footer={null}
            // style={{ top: '15%' }}
            className={"modalTransbordo"}
            closable={false}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          >
            <Transbordo
              idTrabajador={this.state.trabajadorTransbordo}
              idCentroCostoOrigen={this.state.idCentroCosto}
              idActividadOrigen={this.state.idActividad}
              nombreActividadOrigen={nombreActividad}
              idPlazaOrigen={this.state.plazaTransbordo}
              idPuestoPlazaOrigen={this.state.puestoPlazaTransbordo}
              idRangoFechasPuestoPlazaOrigen={this.state.IdRangoFechasPuestoPlazaOrigen}
              fechaTrabajoOrigenDesde={this.state.fechaTrabajoOrigenDesde}
              fechaTrabajoOrigenHasta={this.state.fechaTrabajoOrigenHasta}
              funcionRecargaInformacion={this.actualizarInformacionPlaza}
              random={this.state.random}
              cerrarModal={this.cerrarModalTransbordo}
            />
          </Modal>

          {/* MODAL VER DATOS TRABAJADOR */}
          <Modal
            visible={modalVerDatosTrabajador}
            onCancel={() => {
              this.setState({
                modalVerDatosTrabajador: false,
                nombreTrabajadorAsignar: "",
                titulosTrabajadorAsignar: [],
                especialidadesTrabajadorAsignar: [],
                regimenTrabajadorAsignar: "",
                contratoTrabajadorAsignar: "",
                contratosTrabajador:[]
              });
            }}
            //propiedades obligatorias para modal mantenedor
            bodyStyle={{ borderRadius: "16px", padding: "2rem" }}
            width="70%"
            footer={null}
            style={{ top: "15%" }}
            className={"modalAsignarTrabajadorActividad"}
            closable={true}//false
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          >
            {/* <div style={{ paddig: '20px', width: '100%' }}> */}
            <Col>
              <h4
                style={{
                  color: "#061fcb",
                  fontFamily: "MavenPro-bold",
                  fontSize: "17px",
                }}
              >
                {nombreTrabajadorAsignar}
              </h4>
            </Col>
            <Col span={24}>
              <Collapse bordered={false}>
                <Panel
                  header={
                    <h4
                      style={{
                        position: "absolute",
                        bottom: -5,
                        color: "#061fcb",
                        left: 35,
                      }}
                    >
                      Títulos
                    </h4>
                  }
                  className="site-collapse-custom-panel"
                  extra={[
                    <img
                      key="3"
                      src={flecha}
                      style={{
                        position: "absolute",
                        bottom: 3,
                        right: 11,
                        width: 28,
                        color: "#48B9FD",
                      }}
                      alt="icono"
                    />,
                  ]}
                >
                  <div style={{ paddingTop: "5px" }}>
                    {titulosTrabajadorAsignar.length > 0 ? (
                      <>
                        {titulosTrabajadorAsignar.map((titulo) => (
                          <>
                            <p>{`- ${titulo}`}</p>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Panel>
              </Collapse>
            </Col>

            <Col span={24}>
              <Collapse bordered={false}>
                <Panel
                  header={
                    <h4
                      style={{
                        position: "absolute",
                        bottom: -5,
                        color: "#061fcb",
                        left: 35,
                      }}
                    >
                      Especialidades
                    </h4>
                  }
                  className="site-collapse-custom-panel"
                  extra={[
                    <img
                      key="3"
                      src={flecha}
                      style={{
                        position: "absolute",
                        bottom: 3,
                        right: 11,
                        width: 28,
                        color: "#48B9FD",
                      }}
                      alt="icono"
                    />,
                  ]}
                >
                  {/* <div style={{ paddingTop: '5px' }}>
                                        <h4>{`${especialidadesTrabajadorAsignar}`}</h4>
                                    </div> */}

                  <div style={{ paddingTop: "5px" }}>
                    {especialidadesTrabajadorAsignar.length > 0 ? (
                      <>
                        {especialidadesTrabajadorAsignar.map((especialidad) => (
                          <>
                            <p>{`- ${especialidad}`}</p>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Panel>
              </Collapse>
            </Col>

            <Col span={24}>
              <Collapse bordered={false}>
                <Panel
                  header={
                    <h4
                      style={{
                        position: "absolute",
                        bottom: -5,
                        color: "#061fcb",
                        left: 35,
                      }}
                    >
                      Regimen
                    </h4>
                  }
                  className="site-collapse-custom-panel"
                  extra={[
                    <img
                      key="3"
                      src={flecha}
                      style={{
                        position: "absolute",
                        bottom: 3,
                        right: 11,
                        width: 28,
                        color: "#48B9FD",
                      }}
                      alt="icono"
                    />,
                  ]}
                >
                  <div style={{ paddingTop: "5px" }}>
                    <h4>{regimenTrabajadorAsignar.Nombre}</h4>
                  </div>
                </Panel>
              </Collapse>
            </Col>

            <Col span={24}>
              <Collapse bordered={false}>
                <Panel
                  header={
                    <h4
                      style={{
                        position: "absolute",
                        bottom: -5,
                        color: "#061fcb",
                        left: 35,
                      }}
                    >
                      Contrato
                    </h4>
                  }
                  className="site-collapse-custom-panel"
                  extra={[
                    <img
                      key="3"
                      src={flecha}
                      style={{
                        position: "absolute",
                        bottom: 3,
                        right: 11,
                        width: 28,
                        color: "#48B9FD",
                      }}
                      alt="icono"
                    />,
                  ]}
                >
                  {/* change complecated? */}
                  {/* //contratosTrabajador */}
                  <div style={{ paddingTop: "5px", marginTop:"10px" }}>
                  {
                    contratosTrabajador.map((contrato,index)=>
                    contrato.Tipo_Contrato ===
                    "Plazo fijo" ? 
                    (
                      <>
                      <Row>
                        <Col span={20}>
                          <Row>
                            <h3 style={{
                              textAlign: "left",
                              fontFamily: "MavenPro-Bold",
                              letterSpacing: "0px",
                              color: "#061FCB",
                              opacity: "1"
                            }}
                            >
                                Contrato:{index+1}
                            </h3>
                          </Row>
                          <Row>
                            tipo contrato:<p style={{ fontFamily: "MavenPro-Bold", marginLeft:"10px" ,letterSpacing: "0px"}}>{contrato.Tipo_Contrato}</p>
                          </Row>
                          <Row>
                            fecha inicio: <p style={{ fontFamily: "MavenPro-Bold", marginLeft:"10px"}}>{moment.utc(contrato.Contrato_Fecha_Inicio).format("DD-MM-YYYY")}</p>
                          </Row>
                          <Row>
                            fecha termino: <p style={{ fontFamily: "MavenPro-Bold", marginLeft:"10px"}}>{moment.utc(contrato.Contrato_Fecha_Fin).format("DD-MM-YYYY")}</p>
                          </Row>

                        </Col>
                        <Col span={4} style={{marginTop:"50px"}}>
                          {contrato.relevo === true ? <img src={IconRelevo} className={"iconoRelevoDrawerVer"} />:''}
                        </Col>
                      </Row>
                     
                       
                      </>
                    )
                    :
                    (
                      <>
                      <Row>
                        <Col span={20}>
                          <Row>
                            <h3 style={{
                              textAlign: "left",
                              fontFamily: "MavenPro-Bold",
                              letterSpacing: "0px",
                              color: "#061FCB",
                              opacity: "1"
                            }}
                            >
                                Contrato:{index+1}
                            </h3>
                          </Row>
                          <Row>
                            tipo contrato:<p style={{ fontFamily: "MavenPro-Bold", marginLeft:"10px" ,letterSpacing: "0px"}}>{contrato.Tipo_Contrato}</p>
                          </Row>
                          <Row>
                            fecha inicio: <p style={{ fontFamily: "MavenPro-Bold", marginLeft:"10px"}}>{moment.utc(contrato.Contrato_Fecha_Inicio).format("DD-MM-YYYY")}</p>
                          </Row>


                        </Col>
                        <Col span={4} style={{marginTop:"50px"}}>
                          {contrato.relevo === true ? <img src={IconRelevo} className={"iconoRelevoDrawerVer"} />:''}
                        </Col>
                      </Row>
                    
                     
                      </>
                    )
                    
                      // Contrato_Fecha_Inicio: "2007-08-01T00:00:00.000Z"
                      // Tipo_Contrato: "Indefinido"
                      // contratoValido: true
                      // diasDisponible: 3
                      // finDisponibilidad: "2022-03-03T23:59:59Z"
                      // inicioDisponibilidad: "2022-03-01T00:00:00Z"
                      // relevo: false
               
                    
                    )
                  }
                  </div>
                </Panel>
              </Collapse>
            </Col>
            {/* </div> */}
          </Modal>
        </Layout>
      </>
    );
  }
}

const EstadosAPropiedades = ({
  DetalleFaenaReducer: {
    loading,
    error,
    success,
    faena,
    grupos,
    terminoCargaCalendario,
    trabajadores,
    mostrarTrabajadores,
    items,
    mostrarTrabajadorBorrarHoras,
    mostrarTrabajadorEditarHoras,
    bitacoraTrabajadores,
    bitacoraRangoEliminar,
    trabajador,
    idPuestoPlaza,
    idPlaza,
    reporteDashboard,
    Inicio_Planificacion,
    Fin_Planificacion,
    Inicio_PlanificacionUnix,
    Fin_PlanificacionUnix,
    FaenaEliminar,
    feriadosSistema,
  },
}) => {
  return {
    loading,
    error,
    success,
    faena,
    grupos,
    terminoCargaCalendario,
    trabajadores,
    mostrarTrabajadores,
    items,
    mostrarTrabajadorBorrarHoras,
    mostrarTrabajadorEditarHoras,
    bitacoraTrabajadores,
    bitacoraRangoEliminar,
    trabajador,
    idPuestoPlaza,
    idPlaza,
    reporteDashboard,
    Inicio_Planificacion,
    Fin_Planificacion,
    Inicio_PlanificacionUnix,
    Fin_PlanificacionUnix,
    FaenaEliminar,
    feriadosSistema,
  };
};

export default connect(EstadosAPropiedades, detalleFaenaActions)(DetalleFaena);
