import {
  ERROR,
  INGRESAR_REGIMEN,
  LOADING,
  LIMPIAR_ERROR_SUCCESS,
  MOSTRAR_MODAL_REGIMEN,
  OCULTAR_MODAL_REGIMEN,
  OBTENER_REGIMENES_MANTENEDOR,
  PARAR_LOADING,
  ELIMINAR_REGIMEN_MANTENEDOR,
  OBTENER_REGIMEN_MANTENEDOR,
  EDITAR_REGIMEN_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OCULTAR_MODAL_REGIMEN_EDITAR,
} from "../types/MantenedorRegimenTrabajoTypes";

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: null,
  modalRegimen: false,
  modalRegimenEditar: false,
  regimenes: [],
  regimen: null,
  tablaCargando: true,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case INGRESAR_REGIMEN:
      return {
        ...state,
        loading: false,
        success: payload,
        modalRegimen: false,
        tablaCargando: false,
      };

    case LOADING:
      return {
        ...state,
        loading: true,
        tablaCargando: true,
      };
    case PARAR_LOADING:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      };
    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        tablaCargando: false,
      };
    case LIMPIAR_ERROR_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };
    case OBTENER_REGIMENES_MANTENEDOR:
      return {
        ...state,
        regimenes: payload,
        loading: false,
        tablaCargando: false,
      };
    case OBTENER_REGIMEN_MANTENEDOR:
      return {
        ...state,
        regimen: payload,
        loading: false,
        tablaCargando: false,
        modalRegimenEditar: true,
      };
    case ELIMINAR_REGIMEN_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      };
    case EDITAR_REGIMEN_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        modalRegimenEditar: false,
      };
    case TOMAR_VALORES_EDITAR:
      const { nombreCampo, valor } = payload;
      return {
        ...state,

        regimen: {
          ...state.regimen,
          [nombreCampo]: valor,
        },
      };

    case MOSTRAR_MODAL_REGIMEN:
      return {
        ...state,
        modalRegimen: true,
      };
    case OCULTAR_MODAL_REGIMEN_EDITAR:
      return {
        ...state,
        modalRegimenEditar: false,
      };
    case OCULTAR_MODAL_REGIMEN:
      return {
        ...state,
        modalRegimen: false,
      };
    default:
      return state;
  }
};
