//const API = process.env.HOST || "http://localhost:8080";
// const API = process.env.HOST || "http://192.168.0.13:8080";

//const API = process.env.HOST || "https://apipeoplemanage.azurewebsites.net";
//Nuevo back productivo
const API = process.env.HOST || "https://pmanagementback.azurewebsites.net";

const PORT = process.env.PORT || ""
export default API;

