export const LOADING = "MANTENEDOR_CARGO_LOADING";
export const ERROR = "MANTENEDOR_CARGO_ERROR";
export const LIMPIAR_ERROR_SUCCESS = "MANTENEDOR_CARGO_LIMPIAR_ERROR_SUCCESS";
export const MOSTRAR_MODAL_CARGO = "MANTENEDOR_CARGO_MOSTRAR_MODAL";
export const OCULTAR_MODAL_CARGO = "MANTENEDOR_CARGO_OCULTAR_MODAL";
export const OCULTAR_MODAL_CARGO_EDITAR = "MANTENEDOR_CARGO_OCULTAR_MODAL_EDITAR";
export const MANTENEDOR_CARGO_CREAR_CARGO = "MANTENEDOR_CARGO_CREAR_CARGO";
export const MANTENEDOR_CARGO_OBTENER_CARGOS = "MANTENEDOR_CARGO_OBTENER_CARGOS";
export const MANTENEDOR_CARGO_OBTENER_CARGO = "MANTENEDOR_CARGO_OBTENER_CARGO";
export const MANTENEDOR_CARGO_ELIMINAR_CARGO = "MANTENEDOR_CARGO_ELIMINAR_CARGO";
export const MANTENEDOR_CARGO_EDITAR_CARGO = "MANTENEDOR_CARGO_ELIMINAR_EDITAR";
export const MANTENEDOR_CARGO_TOMAR_VALORES_EDITAR = "MANTENEDOR_CARGO_TOMAR_VALORES_EDITAR";
