import {
  ERROR,
  INGRESAR_CENTROCOSTO,
  LOADING,
  MOSTRAR_MODAL_CENTROCOSTO,
  OCULTAR_MODAL_CENTROCOSTO,
  OBTENER_CENTROCOSTOS_MANTENEDOR,
  OBTENER_EMPRESAS_MANTENEDOR,
  //   PARAR_LOADING,
  ELIMINAR_CENTROCOSTO_MANTENEDOR,
  OBTENER_CENTROCOSTO_MANTENEDOR,
  EDITAR_CENTROCOSTO_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OCULTAR_MODAL_CENTROCOSTO_EDITAR,
  MANTENEDOR_CENTROCOSTO_OBTENER_CARGOS,
} from "../types/MantenedorCentroCostosTypes";
import API from "../Api/api";
// import { obtenerFechaHoy } from "../../funcionesGenericas/fechas";

import Swal from "sweetalert2";

export const CrearCentroCosto = (centroCosto) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/CentroDeCostos`;

    console.log(centroCosto);

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(centroCosto),
    });
    const status = response.status;

    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Se ingresó el centro de costo correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: INGRESAR_CENTROCOSTO,
          payload: "Se ingreso centro de costo correctamente",
        });

        break;

      default:

        console.log(data);
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerCentroCosto = (idCentroCosto) => async (dispatch) => {

  dispatch({ type: LOADING });
  try {
    const url = `${API}/CentroDeCostos/${idCentroCosto}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const centroCosto = data.centroDeCostos;
        dispatch({
          type: OBTENER_CENTROCOSTO_MANTENEDOR,
          payload: centroCosto,
        });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerCentroCostos = (idEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/CentroDeCostos?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const centroCostos = data.centrosDeCostos;

        dispatch({
          type: OBTENER_CENTROCOSTOS_MANTENEDOR,
          payload: centroCostos,
        });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerEmpresas = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Empresa?vigencia=true`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const empresas = data.empresas;
        dispatch({ type: OBTENER_EMPRESAS_MANTENEDOR, payload: empresas });
        break;

      default:
        const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarCentroCosto = (idCentroCosto) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/CentroDeCostos/${idCentroCosto}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El centro de costo fue eliminado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: ELIMINAR_CENTROCOSTO_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarCentroCosto = (CentroCosto) => async (dispatch) => {
  dispatch({ type: LOADING });
  const idCentroCosto = CentroCosto._id;
  delete CentroCosto._id;
  try {
    const url = `${API}/CentroDeCostos/${idCentroCosto}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },

      body: JSON.stringify(CentroCosto),
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El centro de costo fue actualizado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: EDITAR_CENTROCOSTO_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const TomarValoresEditar = (nombreCampo, valor) => (dispatch) => {
  let objeto = {
    nombreCampo,
    valor,
  };
  dispatch({ type: TOMAR_VALORES_EDITAR, payload: objeto });
};

export const MostrarModalCentroCosto = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_CENTROCOSTO });
};

export const OcultarModalCentroCosto = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_CENTROCOSTO });
};

export const OcultarModalCentroCostoEditar = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_CENTROCOSTO_EDITAR });
};

export const ObtenerCargos = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/CargosTrabajadores/`;

    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;

    const data = await response.json();

    switch (status) {
      case 200:
      case 201:

        // let cargos = data.cargosTrabajadores;
        dispatch({
          type: MANTENEDOR_CENTROCOSTO_OBTENER_CARGOS,
          payload: data.cargosTrabajadores,
        });

        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

// export const DescargarExcel = () => async (dispatch) => {
//   dispatch({ type: LOADING });
//   try {
//     const url = `${API}/Rol/Descargar/Excel`;
//     let status;
//     fetch(url)
//       .then((response) => {
//         status = response.status;
//         return response.blob();
//       })
//       .then((blob) => {
//         switch (status) {
//           case 200:
//           case 201:
//             const url = window.URL.createObjectURL(blob);
//             const a = document.createElement("a");
//             a.href = url;
//             a.download = `Roles_${obtenerFechaHoy()}.xlsx`;
//             document.body.appendChild(a);
//             a.click();
//             a.remove();

//             dispatch({ type: PARAR_LOADING });
//             break;
//           default:
//             Swal.fire({
//               title: "Uups",
//               text:
//                 "Error status: " +
//                 status +
//                 " al realizar la descarga del archivo",
//               icon: "error",
//             });

//             dispatch({ type: PARAR_LOADING });
//             break;
//         }
//       });
//   } catch (error) {
//     dispatch({ type: ERROR, payload: error });
//   }
// };
