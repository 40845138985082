import {
  ERROR,
  INGRESAR_SUCURSAL,
  LOADING,
  OBTENER_EMPRESAS,
  MOSTRAR_MODAL_SUCURSAL,
  OCULTAR_MODAL_SUCURSAL,
  OBTENER_SUCURSALES_MANTENEDOR,
  ELIMINAR_SUCURSAL_MANTENEDOR,
  OBTENER_SUCURSAL_MANTENEDOR,
  OCULTAR_MODAL_SUCURSAL_EDITAR,
  EDITAR_SUCURSAL_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OBTENER_EMPRESA,
  OBTENER_REGIONES_MANTENEDOR_SUCURSALES,
  OBTENER_PROVINCIAS_MANTENEDOR_SUCURSALES,
  OBTENER_COMUNAS_MANTENEDOR_SUCURSALES,
  INSERTAR_COMUNAS_MANTENEDOR_SUCURSALES,
  INSERTAR_PROVINCIAS_MANTENEDOR_SUCURSALES,
} from "../types/MantenedorSucursalesTypes";
import API from "../Api/api";
import { obtenerRespueta } from "../../funcionesGenericas/respuestas";
// import { obtenerFechaHoy } from "../../funcionesGenericas/fechas";
import Swal from "sweetalert2";

export const ObtenerEmpresas = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Empresa?vigencia=true`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const empresas = data.empresas;
        dispatch({ type: OBTENER_EMPRESAS, payload: empresas });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + ", detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const CrearSucursal = (sucursal) => async (dispatch) => {
  dispatch({ type: LOADING });

  // const objSucursal = {
  //   Empresa: sucursal.Empresa,
  //   Nombre: sucursal.Nombre,
  //   Calle: sucursal.Calle,
  //   Numero_Calle: sucursal.Numero_Calle,
  //   Tipo_De_Vivienda: sucursal.Tipo_De_Vivienda,
  //   Codigo_Postal: sucursal.Codigo_Postal,
  //   Region: sucursal.Region,
  //   Ciudad: sucursal.Ciudad,
  //   Comuna: sucursal.Comuna,
  //   Telefono: sucursal.Telefono,
  //   Email: sucursal.Email,
  //   Foto: sucursal.Foto,
  // }

  try {
    const url = `${API}/Sucursales`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(sucursal),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Se ingresó la sucursal correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: INGRESAR_SUCURSAL,
          payload: "Se ingresó la sucursal correctamente",
        });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + ", detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerSucursales = () => async (dispatch) => {
  dispatch({ type: LOADING });

  let usuario = localStorage.getItem("usuario-people-manage");
  usuario = JSON.parse(usuario);

  const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

  try {
    const url = `${API}/Sucursales?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;

    let data;
    let err;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();
        let sucursales = data.data;

        dispatch({ type: OBTENER_SUCURSALES_MANTENEDOR, payload: sucursales });
        break;
      case 204:

        dispatch({ type: OBTENER_SUCURSALES_MANTENEDOR, payload: [] });
        break;
      default:
        err = await response.json();
        const error = err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: err });
        break;
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ERROR, payload: error });
  }
};

////////////////////////////////////////
export const ObtenerEmpresa = () => async (dispatch) => {
  dispatch({ type: LOADING });

  let usuario = localStorage.getItem("usuario-people-manage");
  let empresaId;

  if (
    usuario !== null &&
    usuario !== "" &&
    usuario !== undefined &&
    usuario !== "undefined"
  ) {
    usuario = JSON.parse(usuario);
    empresaId = usuario.EmpresaSeleccionada.Empresa._id;

  }

  try {
    const url = `${API}/Empresa/${empresaId}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const { data, err } = await response.json();

    switch (status) {
      case 200:
      case 201:
        let empresa = data;
        dispatch({ type: OBTENER_EMPRESA, payload: empresa });
        return empresa
        break;

      default:
        const error = err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};
////////////////////////////////////////

//controlada
export const ObtenerRegiones = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Regiones`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;


    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        dispatch({ type: OBTENER_REGIONES_MANTENEDOR_SUCURSALES, payload: data.data });
        break;

      case 204:
        dispatch({ type: OBTENER_REGIONES_MANTENEDOR_SUCURSALES, payload: [] });

        break;
      default:

        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const ObtenerProvincias = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Provincia`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;


    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        dispatch({ type: OBTENER_PROVINCIAS_MANTENEDOR_SUCURSALES, payload: data.data });
        break;

      case 204:
        dispatch({ type: OBTENER_PROVINCIAS_MANTENEDOR_SUCURSALES, payload: [] });

        break;
      default:

        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const ObtenerComunas = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Comunas`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;


    switch (status) {
      case 200:
      case 201:

        data = await response.json();
        dispatch({ type: OBTENER_COMUNAS_MANTENEDOR_SUCURSALES, payload: data.data });
        break;

      case 204:
        dispatch({ type: OBTENER_COMUNAS_MANTENEDOR_SUCURSALES, payload: [] });

        break;
      default:

        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

export const InsertarComunas = (data) => (dispatch) => {
  dispatch({ type: INSERTAR_COMUNAS_MANTENEDOR_SUCURSALES, payload: data });
};

export const InsertarProvincias = (data) => (dispatch) => {
  dispatch({ type: INSERTAR_PROVINCIAS_MANTENEDOR_SUCURSALES, payload: data });
};
////////////////////////////////////////

export const ObtenerSucursal = (idSucursal) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Sucursales/${idSucursal}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let sucursal = data.data;

        dispatch({ type: OBTENER_SUCURSAL_MANTENEDOR, payload: sucursal });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarSucursal = (idSucursal) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Sucursales/${idSucursal}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "La sucursal fue eliminada",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });
        dispatch({ type: ELIMINAR_SUCURSAL_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarSucursal = (sucursal) => async (dispatch) => {
  dispatch({ type: LOADING });

  const idSucursal = sucursal._id;
  delete sucursal._id;

  try {
    const url = `${API}/Sucursales/${idSucursal}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(sucursal),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "La sucursal fue actualizada",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: EDITAR_SUCURSAL_MANTENEDOR });
        break;

      default:
        const error = data.err.message;

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR, payload: error });
  }
};

export const TomarValoresEditar = (nombreCampo, valor) => (dispatch) => {
  let objeto = {
    nombreCampo,
    valor,
  };
  dispatch({ type: TOMAR_VALORES_EDITAR, payload: objeto });
};

export const MostrarModalSucursal = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_SUCURSAL });
};

export const OcultarModalSucursal = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_SUCURSAL });
};

export const OcultarModalSucursalEditar = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_SUCURSAL_EDITAR });
};

// export const DescargarExcel = () => async (dispatch) => {
//   dispatch({ type: LOADING });
//   try {
//     const url = `${API}/Usuario/Descargar/Excel`;
//     let status;
//     fetch(url)
//       .then((response) => {
//         status = response.status;
//         return response.blob();
//       })
//       .then((blob) => {
//         switch (status) {
//           case 200:
//           case 201:
//             const url = window.URL.createObjectURL(blob);
//             const a = document.createElement("a");
//             a.href = url;
//             a.download = `Usuarios_${obtenerFechaHoy()}.xlsx`;
//             document.body.appendChild(a);
//             a.click();
//             a.remove();

//             dispatch({ type: PARAR_LOADING });
//             break;
//           default:
//             Swal.fire({
//               title: "Uups",
//               text:
//                 "Error status: " +
//                 status +
//                 " al realizar la descarga del archivo",
//               icon: "error",
//             });

//             dispatch({ type: PARAR_LOADING });
//             break;
//         }
//       });
//   } catch (error) {
//     dispatch({ type: ERROR, payload: error });
//   }
// };
