import {
  ERROR,
  LOADING,
  MANTENEDOR_FERIADOS_OBTENER_FERIADOS,
  MANTENEDOR_FERIADOS_MOSTRAR_MODAL,
  MANTENEDOR_FERIADOS_OCULTAR_MODAL,
  MANTENEDOR_FERIADOS_INGRESAR_FERIADO,
  MANTENEDOR_FERIADOS_ELIMINAR_FERIADO,
  MANTENEDOR_FERIADOS_OBTENER_FERIADO,
  MANTENEDOR_FERIADOS_OCULTAR_MODAL_EDITAR,
  MANTENEDOR_FERIADOS_TOMAR_VALORES_EDITAR,
  MANTENEDOR_FERIADOS_EDITAR_FERIADO,
  MANTENEDOR_FERIADOS_PARA_LOADING_EXCEL,
  MANTENEDOR_FERIADOS_PARA_SUBIR_EXCEL
} from "../types/MantenedorFeriadosType";
import API from "../Api/api";
//import { obtenerFechaHoy } from "../../funcionesGenericas/fechas";

import Swal from "sweetalert2";

export const subirExcelFeriados = (archivo, empresa) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {

    let formData = new FormData();
    formData.append('archivo', archivo);

    const url = `${API}/Feriados/Masivos/${empresa}`;

    await fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: formData
    })
      .then(async (response) => {
        const status = response.status;
        const message = await response.text();
        const objeto = JSON.parse(message);

        // const feriados = objeto.data === undefined ? [] : objeto.data;
        // const feriadosNoIngresados = Object.keys(feriados).length > 0 && feriados.contenedorFeriadosNoIngresados.length > 0 ? objeto.data.contenedorFeriadosNoIngresados : [];
        // const posicionFilaNoIngresada = feriadosNoIngresados.length > 0 ? feriadosNoIngresados.map(feriado => feriado.posicion) : [];
      
        switch (status) {
          case 200:
          case 201:
            Swal.fire({
              title: "Bien",
              text: "Se ingresaron los feriados correctamente",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
              allowEnterKey: true,
              allowEscapeKey: true,
            });

            dispatch({ type: MANTENEDOR_FERIADOS_PARA_SUBIR_EXCEL })
            break;

          case 412:
            Swal.fire({
              title: "Uups",
              text: "Error status: " + status + ". Detalle: " + objeto.err,
              icon: "error",
            });

            dispatch({ type: ERROR, payload: message.err });
            break;

          default:
            Swal.fire({
              title: "Uups",
              text: "Error status: " + status + ". Detalle: " + response.statusText,
              icon: "error",
            });

            dispatch({ type: ERROR, payload: response.statusText });
            break;
        }

      }).catch((error) => {
        if (error.response) {
          console.log(error.response.data)
        } else {
          console.log(error.message)
        }
      })

  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.message
    });
  }
};

export const descargarFormatoExcel = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {

    const url = `${API}/Feriados/FomatoExcel`;
    const response = await fetch(url);
    const status = await response.status;
    const excel = await response.blob();

    switch (status) {
      case 200:
      case 201:
        const url = window.URL.createObjectURL(excel);
        const a = document.createElement("a");
        a.href = url;
        a.download = `formatoFeriados.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();

        dispatch({ type: MANTENEDOR_FERIADOS_PARA_LOADING_EXCEL });
        break;
      default:
        Swal.fire({
          title: "Uups",
          text:
            "Error status: " +
            status +
            " al realizar la descarga del archivo",
          icon: "error",
        });

        dispatch({ type: MANTENEDOR_FERIADOS_PARA_LOADING_EXCEL });
        break;
    }

  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: error,
      icon: "error",
    });

    dispatch({ type: ERROR, payload: error });
  }
}

export const CrearFeriado = (feriado) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Feriados`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(feriado),
    });


    const status = response.status;

    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Se ingresó feriado correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: MANTENEDOR_FERIADOS_INGRESAR_FERIADO,
          payload: "Se ingresó el usuario correctamente",
        });

        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: error,
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerFeriados = (idEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Feriados?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:

        const feriados = data.feriados;

        //console.log(feriados);
        dispatch({ type: MANTENEDOR_FERIADOS_OBTENER_FERIADOS, payload: feriados });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: error,
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerFeriado = (idFeriado) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Feriados/${idFeriado}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const rol = data.feriado;

        dispatch({ type: MANTENEDOR_FERIADOS_OBTENER_FERIADO, payload: rol });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: error,
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarFeriado = (idFeriado) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Feriados/${idFeriado}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El feriado fue eliminado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });;


        dispatch({ type: MANTENEDOR_FERIADOS_ELIMINAR_FERIADO });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {

    Swal.fire({
      title: "Uups",
      text: error,
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarFeriado = (Feriado) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Feriados/${Feriado._id}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },

      body: JSON.stringify(Feriado),
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El feriado fue actualizado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: MANTENEDOR_FERIADOS_EDITAR_FERIADO });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: error,
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

export const TomarValoresEditar = (nombreCampo, valor) => (dispatch) => {
  let objeto = {
    nombreCampo,
    valor,
  };
  dispatch({ type: MANTENEDOR_FERIADOS_TOMAR_VALORES_EDITAR, payload: objeto });
};

export const MostrarModalFeriados = () => (dispatch) => {
  dispatch({ type: MANTENEDOR_FERIADOS_MOSTRAR_MODAL });
};

export const OcultarModalFeriados = () => (dispatch) => {
  dispatch({ type: MANTENEDOR_FERIADOS_OCULTAR_MODAL });
};

export const OcultarModalFeriadoEditar = () => (dispatch) => {
  dispatch({ type: MANTENEDOR_FERIADOS_OCULTAR_MODAL_EDITAR });
};

//   export const DescargarExcel = () => async (dispatch) => {
//     dispatch({ type: LOADING });
//     try {
//       const url = `${API}/Rol/Descargar/Excel`;
//       let status;
//       fetch(url)
//         .then((response) => {
//           status = response.status;
//           return response.blob();
//         })
//         .then((blob) => {
//           switch (status) {
//             case 200:
//             case 201:
//               const url = window.URL.createObjectURL(blob);
//               const a = document.createElement("a");
//               a.href = url;
//               a.download = `Roles_${obtenerFechaHoy()}.xlsx`;
//               document.body.appendChild(a);
//               a.click();
//               a.remove();

//               dispatch({ type: PARAR_LOADING });
//               break;
//             default:
//               Swal.fire({
//                 title: "Uups",
//                 text:
//                   "Error status: " +
//                   status +
//                   " al realizar la descarga del archivo",
//                 icon: "error",
//               });

//               dispatch({ type: PARAR_LOADING });
//               break;
//           }
//         });
//     } catch (error) {
//       dispatch({ type: ERROR, payload: error });
//     }
//   };
