import React, { Component } from "react";
import avatar from "../../img/icons/avatar.svg";
import API from "../../Redux/Api/api";

// import accidente from "../../img/icons/accidente.svg";
import accidente from "../../img/icons-nuevos/LicenciaMutualidad.svg";

import {
  Row,
  Col,
  Layout,
  Card,
  Form,
  Button,
  Select,
  Input,
  Upload,
  Modal,
  PageHeader,
  Radio,
  DatePicker,
  Tooltip,
} from "antd";

import LocaleES from "antd/lib/date-picker/locale/es_ES";

import { UploadOutlined } from "@ant-design/icons";

import "./Accidente.css";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Fechas from "../../components/Calendarios/Range";
import Fecha from "../../components/Calendarios/Picker";
import Loading from "../../components/loading/Loading";
//import TimeLineTrabajador from "../../components/timeLineTrabajador/TimeLineTrabajadorPopover2";
import TimeLineTrabajador from "../../components/timeLineTrabajador/TimeLineTrabajadorPopover3";
import { traer_todos_las_fechas_trabajador_descansos_finiquitados } from "../../Redux/actions/descansoFiniquitadosActions";

import { connect } from "react-redux";
import * as accidenteActions from "../../Redux/actions/AccidenteActions";
import Swal from "sweetalert2";
import moment from "moment";
import accesosPagina from "../../funcionesGenericas/accesosUsuario";

import {
  convertirFechaString,
  //corregirZonaHoraria,
} from "../../funcionesGenericas/fechas";
const { Header, Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

class Accidente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mostrarCalendario: false,
      bitacora: [],
      modalLicencia: false,
      idEmpresa: null,
      selectedContrato: [],
      selectedDates: [],
      selectedDatesTimeline: [],
      firstDaysOfTheMonths: [],
      loading: false,
      contEstados: {
        TrabajoEfectivo: 0,
        Descanso: 0,
        Vacaciones: 0,
        EnTransito: 0,
        Licencia: 0,
        LicenciaMutualidad: 0,
        Permiso: 0,
        Ausente: 0,
        NoAsignado: 0,
        Vacio: 0,
      },

      archivoSubir: "",

      isFechasDisabled: true,
      cargo: null,
      modal: false,

      cantidadDias: 0,
      tieneFechaFin: true,
      fechaFin: null,

      //INGRESAR FECHA FIN LICENCIA
      fechaInicioLicencia: null,
      idTrabajador: "",
      idLicencia: "",
      diasLicenciaMutual: 0,
    };
  }

  formRef = React.createRef();
  formRefFechaFinLicencia = React.createRef();

  state = {
    imagen: null,
    LoadingForm: false,
    fechasBloqueadas: [],
  };

  onFinish = async (values) => {
    const {
      selectedDatesTimeline,
      fechasBloqueadas,
      idEmpresa,
      cargo,
      archivoSubir,
    } = this.state;
    let { Fechas, Trabajador } = this.props;
    const { contrato: idContrato, fechaFinLicencia } = this.formRef.current.getFieldsValue();
    let archivo = archivoSubir === "" ? "" : archivoSubir;

    if (Trabajador) {
      // if (Fechas === null || Fechas[0].toString() === "Invalid Date") {
      //   Swal.fire({
      //     title: "Ups",
      //     text: "Debes ingresar el rango de fechas",
      //     icon: "warning",
      //     confirmButtonColor: "#E60AA3",
      //     confirmButtonText: "Ok",
      //   });
      //   return;
      // }

      if (cargo === null) {
        Swal.fire({
          title: "Uups",
          text: "No se ingreso el cargo recarge la página.",
          icon: "warning",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        });
        return;
      }

      if (fechaFinLicencia === null) {
        Swal.fire({
          title: "Uups",
          text: "La fecha fin de la licencia no fue ingresada correctamente.",
          icon: "warning",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        });
        return;
      }

      //VALIDACIÓN DE WARNING
      let listaFechas = [];

      await fechasBloqueadas.forEach((bitacora) => {
        listaFechas.push(moment.utc(bitacora.Fecha));
      });

      let strFechaInicio;
      let strFechaFin;

      values.tieneFechaFin = true;

      if (values.tieneFechaFin === true) {
        strFechaInicio = moment
          .utc(values.fechaInicioLicencia)
          .format("YYYY-MM-DD");
        strFechaFin = moment.utc(values.fechaFinLicencia).format("YYYY-MM-DD");
      } else {
        strFechaInicio = moment
          .utc(values.fechaInicioLicencia)
          .format("YYYY-MM-DD");
        strFechaFin = moment.utc(values.fechaInicioLicencia).isBefore(moment())
          ? moment().format("YYYY-MM-DD")
          : moment.utc(values.fechaInicioLicencia).format("YYYY-MM-DD");
      }

      // const fecha1 = moment.utc(momentToStringFechaInicio(moment(this.props.Fechas[0]), databaseFormat));
      // const fecha2 = moment.utc(momentToStringFechaFin(moment(this.props.Fechas[1]), databaseFormat));

      const fecha1 = moment(
        convertirFechaString(moment(strFechaInicio), 1).fechaString
      );
      const fecha2 = moment(
        convertirFechaString(moment(strFechaFin), 2).fechaString
      );
      let warningFlag = false;
      let confirmFlag = false;

      await listaFechas.forEach(async (fecha) => {
        if (await fecha.isBetween(fecha1, fecha2)) {
          warningFlag = true;
          return;
        }
      });

      if (warningFlag) {
        await Swal.fire({
          title: "Advertencia",
          text: `Las fechas que seleccionaste ya tienen planificación, ¿deseas continuar?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1FC600",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (!result.value) {
            confirmFlag = true;
          }
        });

        if (confirmFlag) {
          return;
        }
      }

      await this.setState({
        LoadingForm: true,
      });
      
      //se realiza el ingreso de datos
      //en caso de fallar se mostrara un mensaje de error
      await this.props.insertarDatosAvanzado(
        values.Maniobra,
        archivo,
        cargo,
        values.fechaInicioLicencia,
        values.fechaFinLicencia,
        values.cantidadDiasLicencia,
        true,
        values.Observacion
      );

      await this.props.traer_todos_contratos_trabajador(
        Trabajador,
        idEmpresa,
        selectedDatesTimeline[0],
        selectedDatesTimeline[1]
      );
      
      let response =
        await this.props.traer_todos_las_fechas_trabajador_por_contrato(
          Trabajador,
          idContrato
        );

      if (response !== null) {
        const newBitacorasHistorico =
          await this.props.traer_todos_los_accidentes_historicos(Trabajador);

        let arrayIniciosDeMes = [];

        let fechaInicioOriginal = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda), 1).fechaString;
        let fechaInicioString = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda).startOf("month"), 1).fechaString;
        let fechaFinString = convertirFechaString(moment.utc(response.FinFechaRangoBusqueda).endOf("month"), 2).fechaString;
        let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;
    
        for (let index = 0; index < diferenciaMeses; index++) {
          let fechaAgregar;
  
          if(index === 0){
            if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
              fechaAgregar = moment.utc(fechaInicioOriginal);
            } else {
              fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
            }
          } else {
            fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
          }
  
          arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
        }

        await this.setState({
          fechasBloqueadas: response.bitNoModificar,
          bitacora: response.bitacoras,
          selectedContrato: [response.InicioContrato, response.FinContrato],
          selectedDates: [
            response.inicioFechaRangoBusqueda,
            response.FinFechaRangoBusqueda,
          ],
          selectedDatesTimeline: [
            response.inicioFechaRangoBusqueda,
            response.FinFechaRangoBusqueda,
          ],
          firstDaysOfTheMonths: arrayIniciosDeMes,
          mostrarCalendario: true,
          loading: false,
          fechasHistoricasAccidente:
            newBitacorasHistorico !== null ? newBitacorasHistorico : [],
        });
        await this.formRef.current.resetFields([
          "Maniobra",
          "fechaInicioLicencia",
          "cantidadDiasLicencia",
          "fechaFinLicencia",
          "Archivo",
          "Correo",
          "Observacion"
        ]);
      } else {
        await this.setState({
          fechasBloqueadas: [],
          bitacora: [],
          selectedContrato: [null, null],
          selectedDates: [null, null],
          selectedDatesTimeline: [null, null],
          firstDaysOfTheMonths: [],
          mostrarCalendario: false,
          loading: false,
          fechasHistoricasAccidente: [],
        });
        await this.formRef.current.resetFields([
          "contrato",
          "trabajador",
          "Maniobra",
          "fechaInicioLicencia",
          "cantidadDiasLicencia",
          "fechaFinLicencia",
          "Archivo",
          "Correo",
          "Observacion"
        ]);
      }
    }
  };

  onFinishLicenciaTrabajador = async (values) => {
    const strFechaFin = values.fechaFinLicencia.format("YYYY-MM-DD");
    values.fechaFinLicencia = convertirFechaString(
      moment(strFechaFin),
      2
    ).fechaString;

    await this.props.ingresarFechaFinLicencia(values);

    let response = await this.props.traer_todos_las_fechas_trabajador(
      values.idTrabajador
    );

    let fechas = [];

    response.forEach((element) => {
      //ingreso la fecha a un arreglo para poder validar en el calendario
      fechas.push(element.Fecha);
    });

    const responseDFiniquitados =
      await traer_todos_las_fechas_trabajador_descansos_finiquitados(
        values.idTrabajador
      );
    responseDFiniquitados.forEach((finiquito) => {
      let newFiniquito = { ...finiquito };
      newFiniquito.finiquitoDescanso = true;
      newFiniquito.TipoRegistroBitacoraTrabajador = "descansoFiniquitado";
      response.push(newFiniquito);
    });

    await this.setState({
      fechasBloqueadas: fechas,
      bitacora: response,
    });

    await this.setState({
      mostrarCalendario: true,
    });

    await this.setState({
      LoadingForm: false,
    });

    await this.setState({
      modalLicencia: false,
    });

    await this.formRef.current.resetFields([
      "Maniobra",
      "Archivo",
      "Correo",
      "fechaInicioLicencia",
      "cantidadDiasLicencia",
      "fechaFinLicencia",
    ]);
    await this.formRefFechaFinLicencia.current.resetFields([
      "fechaInicioLicencia",
      "diasLicenciaMutual",
      "fechaFinLicencia",
    ]);
  };

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;

      this.props.traer_todos_los_trabajadores(id);

      const accesos = await accesosPagina.obtenerAccesosPagina(
        "ProcesosMutual"
      );

      this.setState({
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
        idEmpresa: id,
      });
    }
  }

  handleFechas = async (fecha) => {
    let dia = new Date(fecha);
    await this.props.insertarFechas(dia);

    // if (Array.isArray(contenedorFechas)) {
    //   let fechas = [];
    //   contenedorFechas.forEach((fecha) => {
    //     let dia = new Date(fecha);
    //     fechas.push(dia);
    //   });
    //   await this.props.insertarFechas(fechas);
    // } else {
    //   let dia = new Date(contenedorFechas);
    //   await this.props.insertarFechas(dia);
    // }
  };

  handleObtenerFechaFin = (fechaInicio, cantidadDias) => {
    const { fechaInicioLicencia } = this.formRef.current.getFieldsValue(
      "fechaInicioLicencia"
    );
    const { cantidadDiasLicencia } = this.formRef.current.getFieldsValue([
      "cantidadDiasLicencia",
    ]);

    let fInicio = fechaInicio ? fechaInicio : fechaInicioLicencia;
    let cDias = cantidadDias ? parseInt(cantidadDias) : parseInt(cantidadDiasLicencia);

    if (
      !isNaN(cDias) &&
      cDias > 0 &&
      fInicio !== undefined &&
      fInicio !== null
    ) {
      const fechas = this.state.selectedDates;
      let fechaFin = moment(fInicio).add(cDias, "days").subtract(1, "day");
      let strFechaFin = moment(fechaFin).format('YYYY-MM-DD');

      if (fechas[0] !== null && fechas[1] !== null) {
        if (
          moment.utc(strFechaFin).isBetween(moment.utc(fechas[0]),moment.utc(fechas[1]),null,"[]")
        ) {
          this.formRef.current.setFieldsValue({ fechaFinLicencia: fechaFin });
        } else {
          this.formRef.current.setFields([
            {
              name: 'fechaFinLicencia',
              errors: ['No puedes sobrepasar el rango de búsquedas '],
              value: null
            },
         ]);
        }
      }
    } else {
      this.formRef.current.setFieldsValue({ fechaFinLicencia: null });
    }
  };

  handleFechaInicio = (fechaInicio, cantidadDias) => {
    this.handleFechas(fechaInicio);
    this.handleObtenerFechaFin(fechaInicio, cantidadDias);
  };

  handleChange = async (idTrabajador) => {
    let user = this.props.trabajadores.find((x) => x._id === idTrabajador);
    if (user) {
      const { idEmpresa } = this.state;
      this.setState({
        imagen: user.Imagen,
        cargo: user.Cargo,
        nombre: user.Nombre,

        fechasBloqueadas: [],
        bitacora: [],
        selectedContrato: [null, null],
        selectedDates: [null, null],
        mostrarCalendario: false,
        loading: true,
      });

      await this.props.insertarTrabajador(idTrabajador);

      await this.formRef.current.resetFields([
        "Fechas",
        "Maniobra",
        "Archivo",
        "Correo",
        "contrato",
        "fechaFinLicencia",
        "cantidadDiasLicencia",
        "fechaInicioLicencia"
      ]);

      this.props.traer_todos_contratos_trabajador(idTrabajador, idEmpresa);

      const newBitacorasHistorico =
        await this.props.traer_todos_los_accidentes_historicos(idTrabajador);

      if (newBitacorasHistorico !== null) {
        this.setState({
          fechasHistoricasAccidente: newBitacorasHistorico,
          loading: false,
        });
      } else {
        this.setState({
          fechasHistoricasAccidente: [],
          loading: false,
        });
      }
    }
  };

  handleContrato = async (idContrato) => {
    const idTrabajador = this.props.Trabajador;
    let user = this.props.trabajadores.find((x) => x._id === idTrabajador);
    if (user) {
      this.setState({
        imagen: user.Imagen,
        nombre: user.Nombre,
        loading: true,
      });

      let response =
        await this.props.traer_todos_las_fechas_trabajador_por_contrato(
          idTrabajador,
          idContrato
        );

      let arrayIniciosDeMes = [];

      let fechaInicioOriginal = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda), 1).fechaString;
      let fechaInicioString = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda).startOf("month"), 1).fechaString;
      let fechaFinString = convertirFechaString(moment.utc(response.FinFechaRangoBusqueda).endOf("month"), 2).fechaString;
      let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;
  
      for (let index = 0; index < diferenciaMeses; index++) {
        let fechaAgregar;

        if(index === 0){
          if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
            fechaAgregar = moment.utc(fechaInicioOriginal);
          } else {
            fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
          }
        } else {
          fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
        }

        arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
      }

      await this.setState({
        fechasBloqueadas: response.bitNoModificar,
        bitacora: response.bitacoras,
        selectedContrato: [response.InicioContrato, response.FinContrato],
        selectedDates: [
          response.inicioFechaRangoBusqueda,
          response.FinFechaRangoBusqueda,
        ],
        selectedDatesTimeline: [
          response.inicioFechaRangoBusqueda,
          response.FinFechaRangoBusqueda,
        ],
        firstDaysOfTheMonths: arrayIniciosDeMes,
        contEstados: response.contEstados,
        mostrarCalendario: true,
        loading: false,
      });

      await this.formRef.current.resetFields([
        "Fechas",
        "Maniobra",
        "Archivo",
        "Correo",
        "fechaFinLicencia",
        "cantidadDiasLicencia",
        "fechaInicioLicencia"
      ]);
    }
  };

  handleNewDates = async (fechaInicio, fechaFin) => {
    try {
      const idTrabajador = this.props.Trabajador;
      let user = this.props.trabajadores.find((x) => x._id === idTrabajador);
      if (user) {
        this.setState({
          loading: true,
        });

        const { contrato: idContrato } = this.formRef.current.getFieldsValue();
        let response =
          await this.props.traer_todos_las_fechas_trabajador_por_contrato(
            idTrabajador,
            idContrato,
            fechaInicio,
            fechaFin
          );

        let arrayIniciosDeMes = [];

        let fechaInicioOriginal = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda), 1).fechaString;
        let fechaInicioString = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda).startOf("month"), 1).fechaString;
        let fechaFinString = convertirFechaString(moment.utc(response.FinFechaRangoBusqueda).endOf("month"), 2).fechaString;
        let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;
    
        for (let index = 0; index < diferenciaMeses; index++) {
          let fechaAgregar;
  
          if(index === 0){
            if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
              fechaAgregar = moment.utc(fechaInicioOriginal);
            } else {
              fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
            }
          } else {
            fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
          }
  
          arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
        }

        await this.setState({
          fechasBloqueadas: response.bitNoModificar,
          bitacora: response.bitacoras,
          selectedContrato: [response.InicioContrato, response.FinContrato],
          selectedDates: [
            response.inicioFechaRangoBusqueda,
            response.FinFechaRangoBusqueda,
          ],
          selectedDatesTimeline: [
            response.inicioFechaRangoBusqueda,
            response.FinFechaRangoBusqueda,
          ],
          firstDaysOfTheMonths: arrayIniciosDeMes,
          contEstados: response.contEstados,
          mostrarCalendario: true,
          loading: false,
        });

        await this.formRef.current.resetFields([
          "Fechas",
          "Maniobra",
          "Archivo",
          "Correo",
          "fechaFinLicencia",
          "cantidadDiasLicencia",
          "fechaInicioLicencia"
        ]);
      }
    } catch (error) {
      await this.setState({
        loading: false,
      });
      console.log(error);
    }
  };

  actualizarFechaTimeline = (fechaInicio, fechaFin) => {
    let arrayIniciosDeMes = [];

    let fechaInicioOriginal = convertirFechaString(moment.utc(fechaInicio), 1).fechaString;
    let fechaInicioString = convertirFechaString(moment.utc(fechaInicio).startOf("month"), 1).fechaString;
    let fechaFinString = convertirFechaString(moment.utc(fechaFin).endOf("month"), 2).fechaString;
    let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;

    for (let index = 0; index < diferenciaMeses; index++) {
      let fechaAgregar;

      if(index === 0){
        if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
          fechaAgregar = moment.utc(fechaInicioOriginal);
        } else {
          fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
        }
      } else {
        fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
      }

      arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
    }

    this.setState({
      selectedDatesTimeline: [fechaInicio, fechaFin],
      firstDaysOfTheMonths: arrayIniciosDeMes,
    });
  };

  obtenerBase64 = (info) => {
    if (info.file.status === "done") {
      this.getBase64(info.file.originFileObj, (imageUrl) => {
        this.setState({
          archivoSubir: imageUrl,
        });
      });
    }
  };

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  modalCancelar = () => {
    this.setState({
      modal: false,
    });
  };

  dobleClickBarra = (itemSeleccionado) => {
    if (itemSeleccionado.group === "5f3eec676dd7cc57f0d3a25e") {
      this.setState({
        idElemento: itemSeleccionado.Accidentes,
        modal: true,
      });
    }
  };

  descargarTicket = (itemSeleccionado) => {
    // var win = window.open(
    //   "/ImpresionAccidente?Accidente=" + itemSeleccionado.Accidentes,
    //   "_blank"
    // );
    // win.focus();
    var win = window.open(
      "/ImpresionAccidente?Accidente=" + itemSeleccionado,
      "_blank"
    );
    win.focus();
  };

  ingresarFechaFinLicencia = async (itemSeleccionado) => {
    const fechaInicioLicencia = moment.utc(itemSeleccionado.Fecha_Inicio);
    const idTrabajador = itemSeleccionado.IdTrabajador;
    const idLicencia = itemSeleccionado.Accidentes;
    this.setState({
      idTrabajador,
      idLicencia,
      fechaInicioLicencia,
      modalLicencia: true,
    });
  };

  descargarDocumento = async (itemSeleccionado) => {
    const url = `${API}/AccidenteLaboral/${itemSeleccionado.Accidentes}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;

    if (status === 200) {
      const { data } = await response.json();
      if (data.Archivo !== "") {
        const extension = data.Archivo.split(";")[0].split(":")[1];
        const dataBase64 = data.Archivo.split(";")[1];
        const linkSource = `data:${extension};${dataBase64}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download =
          "DocumentoLincenciaMutual-" + itemSeleccionado.Accidentes;
        downloadLink.click();
      } else {
        Swal.fire({
          title: "No tiene documento",
          text: `La licencia seleccionada no tiene un documento asociado`,
          icon: "warning",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        });
      }
    }
  };

  eliminarRegistro = async (itemSeleccionado) => {

    await Swal.fire({
      title: "Confirmar",
      text: `¿Estás seguro que quieres eliminar el registro de licencia mutual?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        const idTrabajador = this.props.Trabajador;
        let user = this.props.trabajadores.find((x) => x._id === idTrabajador);
        if (user) {
          this.setState({
            loading: true,
          });
          
          let responseDelete = await this.props.eliminarRegistro(
            itemSeleccionado.Fecha_Inicio_DB,
            itemSeleccionado.Fecha_Fin_DB,
            //itemSeleccionado.Accidentes
            itemSeleccionado.IdImprimir
          );

          const { contrato: idContrato } = this.formRef.current.getFieldsValue();

        
          let response =
            await this.props.traer_todos_las_fechas_trabajador_por_contrato(
              idTrabajador,
              idContrato
            );

          let arrayIniciosDeMes = [];

          let fechaInicioOriginal = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda), 1).fechaString;
          let fechaInicioString = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda).startOf("month"), 1).fechaString;
          let fechaFinString = convertirFechaString(moment.utc(response.FinFechaRangoBusqueda).endOf("month"), 2).fechaString;
          let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;
      
          for (let index = 0; index < diferenciaMeses; index++) {
            let fechaAgregar;
      
            if(index === 0){
              if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
                fechaAgregar = moment.utc(fechaInicioOriginal);
              } else {
                fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
              }
            } else {
              fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
            }
      
            arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
          }

          await this.setState({
            fechasBloqueadas: response.bitNoModificar,
            bitacora: response.bitacoras,
            selectedContrato: [response.InicioContrato, response.FinContrato],
            selectedDates: [
              response.inicioFechaRangoBusqueda,
              response.FinFechaRangoBusqueda,
            ],
            selectedDatesTimeline: [
              response.inicioFechaRangoBusqueda,
              response.FinFechaRangoBusqueda,
            ],
            firstDaysOfTheMonths: arrayIniciosDeMes,
            contEstados: response.contEstados,
            mostrarCalendario: true,
            loading: false,
          });

          const newBitacorasHistorico =
          await this.props.traer_todos_los_accidentes_historicos(idTrabajador);

          if (newBitacorasHistorico !== null) {
            this.setState({
              fechasHistoricasAccidente: newBitacorasHistorico,
              loading: false,
            });
          } else {
            this.setState({
              fechasHistoricasAccidente: [],
              loading: false,
            });
          }

          await this.formRef.current.resetFields([
            "Fechas",
            "Maniobra",
            "Archivo",
            "Correo",
            "fechaFinLicencia",
            "cantidadDiasLicencia",
            "fechaInicioLicencia",
            "Observacion"
          ]);
        }

        // let response = await this.props.traer_todos_las_fechas_trabajador(
        //   this.props.Trabajador
        // );

        // let fechas = [];

        // await response.forEach((element) => {
        //   fechas.push(element.Fecha);
        // });

        // const responseDFiniquitados =
        //   await traer_todos_las_fechas_trabajador_descansos_finiquitados(
        //     this.props.Trabajador
        //   );
        // responseDFiniquitados.forEach((finiquito) => {
        //   let newFiniquito = { ...finiquito };
        //   newFiniquito.finiquitoDescanso = true;
        //   newFiniquito.TipoRegistroBitacoraTrabajador = "descansoFiniquitado";
        //   response.push(newFiniquito);
        // });

        // await this.setState({
        //   fechasBloqueadas: fechas,
        //   bitacora: response,
        // });

        // await this.setState({
        //   mostrarCalendario: true,
        //   modalDescanso: false,
        // });
      }
    });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 10,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 12,
        },
      },
    };

    const {
      tieneFechaFin,
      fechaFin,
      cantidadDias,
      fechaInicioLicencia,
      idTrabajador,
      idLicencia,
      diasLicenciaMutual,
    } = this.state;

    return (
      <Layout>
        <Loading cargando={this.props.loading} />
        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Licencia mutualidad"
            subtitulo="Corresponde a los días en que el trabajador debe salir de trabajo efectivo debido a lesiones"
          />
        </Header>
        <hr className="division" />
        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row gutter={25}>
            <Col
              className="gutter-row"
              span={8}
              xs={24}
              s={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
            >
              <Card
                style={{
                  borderRadius: "16px",
                  height: "100%",
                  width: "100%",
                  boxShadow: "0px 3px 6px #74747465",
                }}
              >
                <Row>
                  <Col span={22} style={{ display: "inherit" }}>
                    <img src={accidente} alt="cama arreglada para descansar" />
                    <h1 className="formDescanso_card_title">
                      Generar Licencia Mutualidad
                    </h1>
                  </Col>
                  <Col span={24} style={{ marginTop: "24px" }}>
                    <Form
                      {...formItemLayout}
                      name="accidente_trabajador"
                      onFinish={this.onFinish}
                      ref={this.formRef}
                    >
                      {/* TRABAJADOR */}
                      <Form.Item
                        name="trabajador"
                        label="Nombre Trabajador"
                        // className="inputsFormDescanso_Text"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: 'Obligatorio',
                        //   },
                        // ]}
                      >
                        <Select
                          showSearch={true}
                          placeholder="Selecciona un trabajador"
                          optionFilterProp="children"
                          size="middle"
                          loading={this.props.loading}
                          onChange={this.handleChange}
                          filterOption={(input, option) => {
                            return (
                              option.children.props.children.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {this.props.trabajadores === null ? (
                            <Option value="Cargando trabajadores...">
                              Cargando trabajadores...
                            </Option>
                          ) : (
                            this.props.trabajadores.map(
                              (
                                {
                                  Nombre,
                                  Apellido_Paterno,
                                  Apellido_Materno,
                                  _id,
                                },
                                index
                              ) => (
                                <Option key={index} value={_id}>
                                  <Tooltip title={Nombre + " " + Apellido_Paterno + " " + Apellido_Materno} color={'geekblue'}>
                                    <b>
                                      {Nombre +
                                        " " +
                                        Apellido_Paterno +
                                        " " +
                                        Apellido_Materno}
                                    </b>
                                  </Tooltip>
                                </Option>
                              )
                            )
                          )}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="contrato"
                        label="Contrato Trabajador"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                      >
                        <Select
                          // showSearch={true}
                          placeholder="Selecciona el contrato del trabajador"
                          optionFilterProp="children"
                          size="middle"
                          loading={this.props.loading}
                          onChange={this.handleContrato}
                          // filterOption={(input, option) => {
                          //   return (
                          //     option.children.props.children
                          //       .toLowerCase()
                          //       .indexOf(input.toLowerCase()) >= 0
                          //   );
                          // }}
                        >
                          {this.props.contratos === null ? (
                            <Option value="Cargando contratos...">
                              Cargando contratos...
                            </Option>
                          ) : (
                            this.props.contratos.map((contrato, index) => (
                              <>
                                {contrato.Tipo_Contrato === "Plazo fijo" ? (
                                  <Option key={index} value={contrato._id}>
                                    <b>
                                      {moment
                                        .utc(contrato.Contrato_Fecha_Inicio)
                                        .format("DD-MM-YYYY")}{" "}
                                      al{" "}
                                      {contrato.Contrato_Finiquitado === true
                                        ? moment
                                            .utc(contrato.Fecha_Finiquito)
                                            .format("DD-MM-YYYY")
                                        : moment
                                            .utc(contrato.Contrato_Fecha_Fin)
                                            .format("DD-MM-YYYY")}
                                    </b>
                                  </Option>
                                ) : (
                                  <Option key={index} value={contrato._id}>
                                    <b>
                                      {moment
                                        .utc(contrato.Contrato_Fecha_Inicio)
                                        .format("DD-MM-YYYY")}{" "}
                                      
                                      {contrato.Contrato_Finiquitado === true
                                        ? 'Al ' + moment
                                            .utc(contrato.Fecha_Finiquito)
                                            .format("DD-MM-YYYY")
                                        : "A Contrato Indefinido"}
                                    </b>
                                  </Option>
                                )}
                              </>
                            ))
                          )}
                        </Select>
                      </Form.Item>

                      {/* TÉRMINO DE OPERACIÓN */}
                      {/* <Form.Item
                        name="Termino"
                        label="Término de operación"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: 'Obligatorio'
                          },
                        ]}

                      >
                        <DatePicker
                          placeholder='Seleccionar fecha y hora'
                          disabledDate={(dia) => {

                            let fechaEncontrada = false;

                            this.state.fechasBloqueadas.forEach(fecha => {
                              if (dia.format('YYYY-MM-DD') === moment(fecha).format('YYYY-MM-DD')) {
                                fechaEncontrada = true;
                              }
                            });

                            if (fechaEncontrada === true) {
                              return true;
                            } else {
                              return false;
                            }
                          }} />
                      </Form.Item> */}

                      {/* FECHA ACCIDENTE */}
                      {/* <Form.Item
                        name="FechaAccidente"
                        label="Fecha del accidente"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: 'Obligatorio',
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder='Seleccionar fecha y hora'
                          disabledDate={(dia) => {

                            let fechaEncontrada = false;

                            this.state.fechasBloqueadas.forEach(fecha => {
                              if (dia.format('YYYY-MM-DD') === moment(fecha).format('YYYY-MM-DD')) {
                                fechaEncontrada = true;
                              }
                            });

                            if (fechaEncontrada === true) {
                              return true;
                            } else {
                              return false;
                            }
                          }} />
                      </Form.Item> */}

                      {/* ESTADO DEL TRABAJADOR */}
                      {/* <Form.Item
                        name="Estado"
                        label="¿En qué estado queda el trabajador?"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: 'Obligatorio',
                          },
                        ]}
                      >
                        <Select placeholder='Seleccionar estado' onChange={this.estadoChange}>
                          <Option value="Con Licencia">Con Licencia</Option>
                          <Option value="Sin Licencia">Sin Licencia</Option>
                        </Select>

                      </Form.Item> */}

                      {/* ¿TIENE FECHA FIN? */}
                      {/* <Form.Item
                        name="tieneFechaFin"
                        label="¿Tiene fecha fin?"
                        className="inputsFormDescanso_Text"
                        initialValue={true}
                      >
                        <Radio.Group
                          style={{ display: "flex" }}
                          onChange={(value) => {
                            if (value.target.value === true) {
                              this.setState({ tieneFechaFin: true })
                            } else {
                              this.setState({ tieneFechaFin: false })
                            }
                          }}
                          defaultValue={true}
                        >
                          <Radio value={true}>Si</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item> */}

                      {tieneFechaFin === true ? (
                        <>
                          {/* FECHAS ACCIDENTE */}
                          <Form.Item
                            name="fechaInicioLicencia"
                            label="Inicio Licencia"
                            className="inputsFormDescanso_Text"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Debe indicar la fecha de inicio de la licencia",
                              },
                            ]}
                          >
                            {/* <Fechas
                                obtenerFecha={this.handleFechas}
                                // fechasBloqueadas={[]}
                              /> */}

                            <DatePicker
                              format={"YYYY-MM-DD"}
                              onChange={(value) => {
                                this.handleFechaInicio(value);
                              }}
                              locale={LocaleES}
                              disabled={false}
                              disabledDate={(dia) => {
                                let diaString = convertirFechaString(
                                  dia,
                                  1
                                ).fechaString;
                                const fechas = this.state.selectedDates;
                                if (fechas[0] !== null && fechas[1] !== null) {
                                  if (
                                    moment
                                      .utc(diaString)
                                      .isBetween(
                                        moment.utc(fechas[0]),
                                        moment.utc(fechas[1]),
                                        null,
                                        "[]"
                                      )
                                  ) {
                                    return false;
                                  }
                                }
                                return true;
                              }}
                            />
                          </Form.Item>

                          {/* CANTIDAD DIAS LICENCIA */}
                          <Form.Item
                            name="cantidadDiasLicencia"
                            label="Cantidad días licencia"
                            className={"inputsFormDescanso_Text"}
                            onChange={(value) => {
                              this.handleObtenerFechaFin(
                                "",
                                value.target.value
                              );
                            }}
                            rules={[
                              {
                                required: true,
                                message:
                                  "Debe indicar la cantidad de días del periodo",
                              },
                            ]}
                            defaultValue={0}
                            value={0}
                            min={1}
                            max={100}
                          >
                            <Input
                              name="cantidadDias"
                              placeholder={0}
                              defaultValue={0}
                              value={0}
                            />
                          </Form.Item>

                          {/* FECHAS FIN ACCIDENTE */}
                          <Form.Item
                            name="fechaFinLicencia"
                            label="Fin Licencia"
                            className="inputsFormDescanso_Text"
                            disabled={true}
                          >
                            <DatePicker
                              format={"YYYY-MM-DD"}
                              locale={LocaleES}
                              disabled={true}
                              value={fechaFin}
                            />
                          </Form.Item>
                        </>
                      ) : (
                        <>
                          <Form.Item
                            name="fechaInicioLicencia"
                            label="Inicio Licencia"
                            className="inputsFormDescanso_Text"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Debe indicar la fecha de inicio de la licencia",
                              },
                            ]}
                          >
                            {/* <Fecha
                                obtenerFecha={this.handleFechas}
                              // fechasBloqueadas={[]}
                              /> */}

                            <DatePicker
                              format={"YYYY-MM-DD"}
                              onChange={this.handleFechas}
                              locale={LocaleES}
                              disabled={false}
                              // disabledDate={(dia) => {
                              // }}
                            />
                          </Form.Item>
                        </>
                      )}

                      {/* MANIOBRA */}
                      <Form.Item
                        name="Maniobra"
                        label="Maniobra que realizaba:"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                      >
                        <Input placeholder="Ingresar texto" />
                      </Form.Item>
                
                      {/* Observacion */}
                      <Form.Item
                        name="Observacion"
                        label="Observacion"
                        className="inputsFormDescanso_Text"
                      >
                        <TextArea rows={3} maxLength={200} placeholder="(Opcional)" />
                      </Form.Item>

                      {/* GENERÓ LICENCIA */}
                      {/* <Form.Item
                        name="Licencia"
                        label="¿Generó licencia?"
                        className="inputsFormDescanso_Text"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'Obligatorio',
                      //   },
                      // ]}
                      >
                        <Radio.Group>
                          <Radio value="si">Sí</Radio>
                          <Radio value="no">No</Radio>
                        </Radio.Group>

                      </Form.Item> */}

                      {/* ARCHIVO */}
                      <Form.Item
                        name="Archivo"
                        label="Adjuntar Archivo"
                        className="inputsFormDescanso_Text"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: 'Obligatorio',
                        //   },
                        // ]}
                      >
                        <Upload
                          showUploadList={true}
                          method="GET"
                          action="#"
                          onChange={this.obtenerBase64}
                        >
                          <Button icon={<UploadOutlined />}>
                            Subir archivo
                          </Button>
                        </Upload>
                      </Form.Item>

                      {/* /////////////////////////////////////////////////// */}

                      {/* Flag Correo */}
                      <Form.Item
                        name="Correo"
                        label="Enviar mail de notificación"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                      >
                        <Radio.Group
                          onChange={({ target: { value } }) =>
                            this.props.enviarCorreo(value)
                          }
                          // value={true}
                        >
                          <Radio value={true}>Si</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>

                      {/* /////////////////////////////////////////////////// */}

                      <Form.Item
                        wrapperCol={{ offset: 22 }}
                        style={{ display: "none" }}
                      >
                        <Button type="primary" htmlType="submit">
                          Guardar
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                  <Col
                    // className="descansoBtn_guardar"
                    span={24}
                    style={{
                      marginTop: "24px",
                      marginBottom: "10px",
                      textAlign: "right",
                    }}
                  >
                    <Button
                      style={{ marginRight: "7.5%" }}
                      type="primary"
                      htmlType="submit"
                      form="accidente_trabajador"
                      className={
                        !this.state.Editar
                          ? "guardar-form-disabled"
                          : "guardar-form"
                      }
                      disabled={!this.state.Editar}
                    >
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col
              className="gutter-row"
              span={16}
              xs={24}
              s={24}
              sm={24}
              md={16}
              lg={16}
              xl={16}
            >
              <TimeLineTrabajador
                dobleClickBarra={this.dobleClickBarra}
                mostrarCalendario={this.state.mostrarCalendario}
                bitacora={this.state.bitacora}
                selectedContrato={this.state.selectedContrato}
                selectedDates={this.state.selectedDates}
                selectedDatesTimeline={this.state.selectedDatesTimeline}
                firstDaysOfTheMonths={this.state.firstDaysOfTheMonths}
                actualizarFechaTimeline={this.actualizarFechaTimeline}
                tipo={"5f3eec676dd7cc57f0d3a25e"}
                eliminarRegistro={this.eliminarRegistro}
                descargarRegistro={this.descargarTicket}
                cambiarFechas={this.handleNewDates}
                descargarArchivo={this.descargarDocumento}
                ingresarFinLicencia={this.ingresarFechaFinLicencia}
                Eliminar={this.state.Eliminar}
                Ver={this.state.Ver}
                ArrayHistorial={this.state.fechasHistoricasAccidente}
                TituloHistorial={"Dias Licencia Mutual"}
                contEstados={this.state.contEstados}
              />
            </Col>
          </Row>
        </Content>

        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.state.modal}
          onCancel={this.modalCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="40%"
          footer={null}
          style={{ top: "30%" }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <Row>
            <Col span={24} style={{ display: "flex" }} className="title">
              <img
                src={accidente}
                style={{ width: "35px" }}
                alt="icono modal"
              />
              <PageHeader
                style={{ padding: "0px 10px 0px 10px" }}
                className="site-page-header"
                title="Acciones"
              />
            </Col>
            <Col
              style={{
                textAlign: "center",
                paddingTop: "50px",
                paddingBottom: "50px",
              }}
              span={12}
            >
              <Button
                className="descargar-excel"
                type="primary"
                //size={size}
                style={{ marginRight: "20px" }}
              >
                Eliminar registro
              </Button>
            </Col>
            <Col
              span={12}
              style={{
                textAlign: "center",
                paddingTop: "50px",
                paddingBottom: "50px",
              }}
            >
              <Button
                className="btn-verde"
                onClick={() => {
                  this.descargarTicket();
                }}
                //size={size}
                style={{ marginRight: "20px" }}
              >
                Descargar Documento
              </Button>
            </Col>
          </Row>
        </Modal>

        <Modal
          wrapClassName="modalCrear"
          visible={this.state.modalLicencia}
          bodyStyle={{ borderRadius: "16px" }}
          width="40%"
          footer={null}
          style={{ top: "30%" }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          onCancel={() => {
            this.setState({ modalLicencia: false });
          }}
        >
          <Row>
            <Col span={24} style={{ display: "flex" }} className="title">
              <img
                src={accidente}
                style={{ width: "35px", marginLeft: "15px" }}
                alt="icono modal"
              />
              <PageHeader
                style={{ padding: "0px 10px 0px 10px" }}
                className="site-page-header"
                title="Ingresar fecha fin licencia"
              />
            </Col>

            <Form
              name="licenciaConFechaFin_trabajador"
              onFinish={this.onFinishLicenciaTrabajador}
              ref={this.formRefFechaFinLicencia}
              style={{ width: "100%" }}
            >
              <Form.Item
                name="idTrabajador"
                type="text"
                style={{ display: "none" }}
                initialValue={idTrabajador}
              ></Form.Item>

              <Form.Item
                name="idLicencia"
                type="text"
                style={{ display: "none" }}
                initialValue={idLicencia}
              ></Form.Item>

              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col
                  span={6}
                  style={{
                    textAlign: "right",
                    paddingRight: "15px",
                    paddingTop: "4px",
                  }}
                >
                  <label>
                    <span style={{ color: "red" }}>*</span> Fecha inicio
                    licencia:
                  </label>
                </Col>
                <Col span={6} style={{ paddingRight: "15px" }}>
                  <Form.Item
                    name="fechaInicioLicencia"
                    className="inputsFormDescanso_Text"
                    initialValue={fechaInicioLicencia}
                    // label='Fecha inicio licencia:'
                    labelAlign="right"
                  >
                    <DatePicker
                      format={"YYYY-MM-DD"}
                      locale={LocaleES}
                      disabled={true}
                      defaultValue={fechaInicioLicencia}
                      initialValue={fechaInicioLicencia}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col
                  span={6}
                  style={{
                    textAlign: "right",
                    paddingRight: "15px",
                    paddingTop: "4px",
                  }}
                >
                  <label>
                    <span style={{ color: "red" }}>*</span> Cantidad días
                    licencia:
                  </label>
                </Col>
                <Col span={6} style={{ paddingRight: "15px" }}>
                  <Form.Item
                    name="diasLicenciaMutual"
                    className="inputsFormDescanso_Text"
                    defaultValue={diasLicenciaMutual}
                    value={diasLicenciaMutual}
                    rules={[
                      {
                        required: true,
                        message:
                          "Debe indicar la cantidad de días de la licencia",
                      },
                    ]}
                    // label="Cantidad días licencia"
                    labelAlign="right"
                  >
                    <Input
                      defaultValue={diasLicenciaMutual}
                      name={diasLicenciaMutual}
                      value={diasLicenciaMutual}
                      onChange={(value) => {
                        const { fechaInicioLicencia } =
                          this.formRefFechaFinLicencia.current.getFieldsValue(
                            "fechaInicioLicencia"
                          );
                        const diasLicenciaMutual = value.target.value;

                        let fInicio = fechaInicioLicencia;
                        let cDias = parseInt(diasLicenciaMutual);

                        if (
                          !isNaN(cDias) &&
                          cDias > 0 &&
                          fInicio !== undefined &&
                          fInicio !== null
                        ) {
                          let fechaFin = moment(fInicio)
                            .add(cDias, "days")
                            .subtract(1, "day");
                          this.formRefFechaFinLicencia.current.setFieldsValue({
                            fechaFinLicencia: fechaFin,
                          });
                        } else {
                          this.formRefFechaFinLicencia.current.setFieldsValue({
                            fechaFinLicencia: null,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col
                  span={6}
                  style={{
                    textAlign: "right",
                    paddingRight: "15px",
                    paddingTop: "4px",
                  }}
                >
                  <label>
                    <span style={{ color: "red" }}>*</span> Fecha fin licencia:
                  </label>
                </Col>
                <Col span={6} style={{ paddingRight: "15px" }}>
                  <Form.Item
                    name="fechaFinLicencia"
                    className="inputsFormDescanso_Text"
                    disabled={true}
                    rules={[
                      {
                        required: true,
                        message: "Debe indicar la fecha fin de la licencia",
                      },
                    ]}
                    // label="Fecha fin licencia"
                    labelAlign={"right"}
                  >
                    <DatePicker
                      disabled={true}
                      format={"YYYY-MM-DD"}
                      locale={LocaleES}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <Col
              // className="descansoBtn_guardar"
              span={24}
              style={{
                marginTop: "24px",
                marginBottom: "10px",
                textAlign: "right",
              }}
            >
              <Button
                style={{ marginRight: "7.5%" }}
                type="primary"
                htmlType="submit"
                form="licenciaConFechaFin_trabajador"
                className={"guardar-form-fecha-fin"}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Modal>
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  AccidenteReducer: {
    err,
    loading,
    trabajadores,
    form: { Fechas, Trabajador },
    contratos,
  },
}) => {
  return {
    loading,
    err,
    trabajadores,
    Fechas,
    Trabajador,
    contratos,
  };
};

export default connect(EstadosAPropiedades, accidenteActions)(Accidente);
