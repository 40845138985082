import React, { Component } from "react";

import {
  Layout, DatePicker, Row, Col, Select, InputNumber, Form, Button,
  Modal, PageHeader, Tabs, Input, Divider, Popover, Alert, Radio,
  Steps
} from "antd";

import {
  CheckCircleTwoTone
} from '@ant-design/icons';

// import embarcado from "../../img/icons/embarcado.svg";
// import descanso from "../../img/icons/descanso.svg";

import {
  momentToUnixFechaInicio,
  momentToUnixFechaFin,
  momentToStringFechaInicio,
  momentToStringFechaFin,
  databaseFormat,
} from "../../funcionesGenericas/fechas";

import {
  postTest,
} from './testingViewApi';

import Swal from "sweetalert2";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";

const { Header, Content } = Layout;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { TextArea, Search } = Input;
const { Step } = Steps;
class TestingView extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentWillMount = async () => {

  };

  async componentDidMount() {

  }

  datepickerChange = (val) => {

    console.log('moment', val)
    console.log('moment format', val.format('DD-MM-YYYY HH:mm'));

    console.log('moment utc', val.utc())
    console.log('moment utc format', val.utc().format('DD-MM-YYYY HH:mm'))
  }

  render() {

    return (
      <div style={{ width: '93vw', paddingTop: '40px' }}>

        <div style={{ width: '100%', height: '500px', overflowX: 'scroll' }}>
          
          <Steps
            progressDot={(index, status, title, description) => {

              console.log(
                {
                  index,
                  status,
                  title,
                  description
                }
              )

              return (
                <CheckCircleTwoTone
                  twoToneColor={'#09C45A'}
                  style={{ fontSize: '15px', position: 'relative', right: '2px', bottom: '2px', zIndex: '1000' }}
                />
              )
            }}
          >
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
            <Step status={'finish'} title="20-02-2020" description="Cubierta" />
          </Steps>

        </div >

        <span>
          <style jsx="true">
            {`
            .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
              background-color: #dddddd !important;
            }
            .ant-steps {
              position: relative !important;
              top: 10px !important;
              height: fit-content !important;
            }
          `}
          </style>
        </span>
      </div >
    );
  }
}

export default TestingView;