export const LOADING = "MANTENEDOR_ROL_LOADING";
export const PARAR_LOADING = "MANTENEDOR_ROL_LOADING_PARAR";
export const ERROR = "MANTENEDOR_ROL_ERROR";
export const INGRESAR_ROL = "MANTENEDOR_ROL_INGRESAR";
export const LIMPIAR_ERROR_SUCCESS = "MANTENEDOR_ROL_LIMPIAR_ERROR_SUCCESS";
export const MOSTRAR_MODAL_ROL = "MANTENEDOR_ROL_MOSTRAR_MODAL";
export const OCULTAR_MODAL_ROL = "MANTENEDOR_ROL_OCULTAR_MODAL";
export const OCULTAR_MODAL_ROL_EDITAR = "MANTENEDOR_ROL_OCULTAR_MODAL_EDITAR";
export const OBTENER_ROLES_MANTENEDOR = "MANTENEDOR_ROL_OBTENER_ROLES";
export const OBTENER_ROL_MANTENEDOR = "MANTENEDOR_ROL_OBTENER_ROL";
export const ELIMINAR_ROL_MANTENEDOR = "MANTENEDOR_ROL_ELIMINAR_ROL";
export const EDITAR_ROL_MANTENEDOR = "MANTENEDOR_ROL_EDITAR_ROL";
export const TOMAR_VALORES_EDITAR = "MANTENEDOR_ROL_TOMAR_VALORES_EDITAR";
export const DESCARGAR_EXCEL = "MANTENEDOR_ROL_DESCARGAR_EXCEL";
