import {
  ERROR,
  INGRESAR_CENTROCOSTO,
  LOADING,
  LIMPIAR_ERROR_SUCCESS,
  MOSTRAR_MODAL_CENTROCOSTO,
  OCULTAR_MODAL_CENTROCOSTO,
  OBTENER_CENTROCOSTOS_MANTENEDOR,
  //   PARAR_LOADING,
  OBTENER_EMPRESAS_MANTENEDOR,
  ELIMINAR_CENTROCOSTO_MANTENEDOR,
  OBTENER_CENTROCOSTO_MANTENEDOR,
  EDITAR_CENTROCOSTO_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OCULTAR_MODAL_CENTROCOSTO_EDITAR,
  MANTENEDOR_CENTROCOSTO_OBTENER_CARGOS
} from "../types/MantenedorCentroCostosTypes";

const INITIAL_STATE = {
  loading: false,
  error: null,
  empresas: [],
  success: null,
  modalCentroCosto: false,
  modalCentroCostoEditar: false,
  centroCostos: [],
  centroCosto: null,
  tablaCargando: true,
  cargos:[],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {

    case MANTENEDOR_CENTROCOSTO_OBTENER_CARGOS:
      return {
        ...state,
        loading:false,
        modalCentroCosto: false,
        tablaCargando: false,
        cargos:payload,
      }
    case INGRESAR_CENTROCOSTO:
      return {
        ...state,
        loading: false,
        success: payload,
        modalCentroCosto: false,
        tablaCargando: false,
      };

    case LOADING:
      return {
        ...state,
        loading: true,
        tablaCargando: true,
      };
    // case PARAR_LOADING:
    //   return {
    //     ...state,
    //     loading: false,
    //     tablaCargando: false,
    //   };
    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        tablaCargando: false,
      };
    case LIMPIAR_ERROR_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };
    case OBTENER_CENTROCOSTOS_MANTENEDOR:
      return {
        ...state,
        centroCostos: payload,
        loading: false,
        tablaCargando: false,
      };
    case OBTENER_CENTROCOSTO_MANTENEDOR:
      return {
        ...state,
        centroCosto: payload,
        loading: false,
        tablaCargando: false,
        modalCentroCostoEditar: true,
      };
    case OBTENER_EMPRESAS_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        empresas: payload,
      };
    case ELIMINAR_CENTROCOSTO_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      };
    case EDITAR_CENTROCOSTO_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        modalCentroCostoEditar: false,
      };
    case TOMAR_VALORES_EDITAR:
      const { nombreCampo, valor } = payload;
      return {
        ...state,

        centroCosto: {
          ...state.centroCosto,
          [nombreCampo]: valor,
        },
      };

    case MOSTRAR_MODAL_CENTROCOSTO:
      return {
        ...state,
        modalCentroCosto: true,
      };
    case OCULTAR_MODAL_CENTROCOSTO_EDITAR:
      return {
        ...state,
        modalCentroCostoEditar: false,
      };
    case OCULTAR_MODAL_CENTROCOSTO:
      return {
        ...state,
        modalCentroCosto: false,
      };
    default:
      return state;
  }
};
