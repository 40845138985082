//imagenes
import embarcado from "../img/icons/embarcado.svg";
import descanso from "../img/icons/descanso.svg";
import enTransito from "../img/icons/enTransito.svg";
import vacaciones from "../img/icons/vacaciones.svg";
// import vacaciones2 from "../img/icons/vacacionesClaro.svg";
import licencia from "../img/icons/Licencia.svg";
import licenciaMutual from "../img/icons/accidente.svg";
import permiso from "../img/icons/Permiso.svg";
import ausente from "../img/icons/Ausente.svg";

import noAsignado from "../img/icons/NoAsignado.svg";

//colores
const itemGris = '#969696';
const itemAzul = '#7c8dfc';
const itemVerde = '#52d68f';
const itemAmarillo = '#efc12b';
const itemRojo = '#e64040';

//retornara fecha formato dd-mm-yyyy
function obtenerColorEstadoTrabajo(TipoRegistroBitacoraFaena) {
    switch (TipoRegistroBitacoraFaena) {
        // Sin Cubrir
        case "5f3ef5ce1ecf531c1c8ab4a7":
            return "item-rojo";
        // Reservadas
        case "5f3ef6591ecf531c1c8ab4a8":
            return "item-amarillo";
        // En Curso
        case "5f3ef6861ecf531c1c8ab4a9":
            return "item-verde";
        // Ya Cubiertas
        case "5f3ef6981ecf531c1c8ab4aa":
            return "item-celeste";
        default:
            return "item-gris"
    }
}

//funcion encargada de obtener los colores de la bitacora del trabajador
function obtenerColorBitacoraTrabajador(idBitacoraTrabajador, borderClass) {

    switch (idBitacoraTrabajador) {
        // Trabajo efectivo
        case "5f3eedf56dd7cc57f0d3a261":
            return borderClass + " item-verde";
        // Descanso
        case "5f3eeb78978b284b68ebced4":
            return "item-celeste-claro " + borderClass;
        // En Tránsito
        case "5f8f369e8efe155a48df37fb":
            return "item-celeste " + borderClass;
        // Vacaciones
        case "5f3eebac978b284b68ebced5":
            return borderClass + " item-celeste-claro";
        // Licencia
        case "5f3eebf46dd7cc57f0d3a25d":
            return borderClass + " item-naranjo";
        // Licencia Mutual
        case "5f3eec676dd7cc57f0d3a25e":
            return borderClass + " item-naranjo";
        // Permiso
        case "5f3eec876dd7cc57f0d3a25f":
            return borderClass + " item-naranjo";
        // Ausente
        case "5f3eeca16dd7cc57f0d3a260":
            return borderClass + " item-naranjo";
        // No Asignado
        case "5f904101d9c57145a09eb058":
            return borderClass + " item-rojo";
        case "descansoFiniquitado":
            return borderClass + " item-descanso-achurado";
        case "Vacio":
                return borderClass + " item-rojo";    
        default:
            return borderClass + " item-gris"
    }
}

//funcion encargada de obtener las imagenes de la bitacora del trabajador
function obtenerImagenesBitacoraTrabajador(idBitacoraTrabajador) {

    switch (idBitacoraTrabajador) {
        // Trabajo efectivo
        case "5f3eedf56dd7cc57f0d3a261":
            return embarcado;
        // Descanso
        case "5f3eeb78978b284b68ebced4":
            return descanso;
        // En Tránsito
        case "5f8f369e8efe155a48df37fb":
            return enTransito;
        // Vacaciones
        case "5f3eebac978b284b68ebced5":
            return vacaciones;
        // Licencia
        case "5f3eebf46dd7cc57f0d3a25d":
            return licencia;
        // Licencia Mutual
        case "5f3eec676dd7cc57f0d3a25e":
            return licenciaMutual;
        // Permiso
        case "5f3eec876dd7cc57f0d3a25f":
            return permiso;
        // Ausente
        case "5f3eeca16dd7cc57f0d3a260":
            return ausente;
        // No Asignado
        case "5f904101d9c57145a09eb058":
            return noAsignado;
        default:
            return noAsignado;
    }
}


function obtenerColorEstadoTrabajoDetalleFaena(TipoRegistroBitacoraFaena, Relevo, borderClass) {
    switch (TipoRegistroBitacoraFaena) {
        // Sin Cubrir
        case "5f3ef5ce1ecf531c1c8ab4a7":
            if (Relevo === false) {
                return "item-rojo " + borderClass;
            } else {
                return "item-rojo " + borderClass;
            }
        // Reservadas
        case "5f3ef6591ecf531c1c8ab4a8":
            if (Relevo === false) {
                return "item-amarillo " + borderClass;
            } else {
                return "item-amarillo " + borderClass;
            }
        // En Curso
        case "5f3ef6861ecf531c1c8ab4a9":
            if (Relevo === false) {
                return "item-verde " + borderClass;
            } else {
                return "item-verde " + borderClass;
            }
        // Ya Cubiertas
        case "5f3ef6981ecf531c1c8ab4aa":
            if (Relevo === false) {
                return "item-celeste " + borderClass;
            } else {
                return "item-celeste " + borderClass;
            }
        default:
            return "item-gris" + ' ' + borderClass
    }
}

function obtenerHexadecimalBitacoraTrabajador(idTipoBitacora) {

    switch (idTipoBitacora) {

        // DESCANSO
        // VACACIONES
        case '5f3eeb78978b284b68ebced4':
        case '5f3eebac978b284b68ebced5':
            return '#3CADF0';

        // LICENCIA
        // MUTUAL
        // PERMISO
        // AUSENTE
        case '5f3eebf46dd7cc57f0d3a25d':
        case '5f3eec676dd7cc57f0d3a25e':
        case '5f3eec876dd7cc57f0d3a25f':
        case '5f3eeca16dd7cc57f0d3a260':
            return '#EF7720';

        // TRABAJO
        case '5f3eedf56dd7cc57f0d3a261':
            return '#52D68F';

        // EN TRÁNSITO
        case '5f8f369e8efe155a48df37fb':
            return '#7C8DFC';

        // NO ASIGNADO
        case '5f904101d9c57145a09eb058':
            return '#E64040';
    }
}

function obtenerTituloBitacoraTrabajador(idTipoBitacora) {

    switch (idTipoBitacora) {

        // DESCANSO
        case '5f3eeb78978b284b68ebced4':
            return 'Descanso';

        // VACACIONES
        case '5f3eebac978b284b68ebced5':
            return 'Vacaciones';
        // LICENCIA
        case '5f3eebf46dd7cc57f0d3a25d':
            return 'Licencia';
        // MUTUAL
        case '5f3eec676dd7cc57f0d3a25e':
            return 'Licencia Mutual';
        // PERMISO
        case '5f3eec876dd7cc57f0d3a25f':
            return 'Permiso';
        // AUSENTE
        case '5f3eeca16dd7cc57f0d3a260':
            return 'Ausente';
        // TRABAJO
        case '5f3eedf56dd7cc57f0d3a261':
            return 'Trabajo';
        // EN TRÁNSITO
        case '5f8f369e8efe155a48df37fb':
            return 'En Tránsito';
        // NO ASIGNADO
        case '5f904101d9c57145a09eb058':
            return 'No Asignado';
    }
}

export default {
    obtenerColorEstadoTrabajo,
    obtenerColorEstadoTrabajoDetalleFaena,
    obtenerColorBitacoraTrabajador,
    itemRojo,
    itemGris,
    itemAzul,
    itemVerde,
    itemAmarillo,
    obtenerHexadecimalBitacoraTrabajador,
    obtenerTituloBitacoraTrabajador,
    obtenerImagenesBitacoraTrabajador,
}