import {
  ERROR,
  LOADING,
  PARAR_LOADING,
  OBTENER_TRABAJADOR_CALENDARIO,
  OBTENER_BITACORA_TRABAJADOR,
  OBTENER_ESTADOS_TRABAJADOR,
  MOSTRAR_TABLA,
  OBTENER_BITACORAS_EPPS,
  OBTENER_BITACORAS_PROFESIONALES,
  OBTENER_VACACIONES_HISTORICAS,

  INGRESAR_TRABAJADOR,
  OBTENER_CARGOS_TRABAJADOR,
  OBTENER_ESPECIALIDADES_TRABAJADORES,
  OBTENER_TITULOS_TRABAJADORES,
  OBTENER_REGIMEN_TRABAJO,
  OBTENER_EPPS_TRABAJADORES,
  OBTENER_REGIONES_MANTENEDOR_TRABAJADOR,
  OBTENER_PROVINCIAS_MANTENEDOR_TRABAJADOR,
  OBTENER_COMUNAS_MANTENEDOR_TRABAJADOR,
  OBTENER_DOCUMENTOS_MANTENEDOR_TRABAJADOR,
  OBTENER_CONTRATOS_TRABAJADOR

} from "../types/CalendarioTrabajadorTypes";
import API from "../Api/api";
// import { obtenerFechaHoy } from "../../funcionesGenericas/fechas";
import Swal from "sweetalert2";
import moment from "moment";
import coloresEstadoTrabajo from "../../funcionesGenericas/coloresEstadoTrabajo";
// import { CloudFilled } from "@ant-design/icons";
import { obtenerRespueta } from "../../funcionesGenericas/respuestas";

import {
  convertirFechaString,
  corregirZonaHoraria

} from "../../funcionesGenericas/fechas";

export const ObtenerTrabajador = (idTrabajador) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Trabajadores/DetalleTrabajador/${idTrabajador}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();



    switch (status) {
      case 200:
      case 201:

        let trabajador = data.trabajador;

        trabajador = obtenerDataFaltante(trabajador);

        // console.log("OBTENER DATA FALTANTE");
        // console.log(trabajador);

        dispatch({ type: OBTENER_TRABAJADOR_CALENDARIO, payload: trabajador });

        return trabajador;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerTrabajadorEditar = (idTrabajador) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Trabajadores/ParaEditar/${idTrabajador}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let trabajador = data.trabajador;
        trabajador.validacionRut = "success";
        // dispatch({ type: OBTENER_TRABAJADOR_MANTENEDOR, payload: trabajador });
        return trabajador;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerEPPSbitacora = (idTrabajador, idEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/BitacoraEPP/Trabajador/${idTrabajador}/Empresa/${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let bitacorasEpps = data.data[0];
        bitacorasEpps = (bitacorasEpps.EPPS !== undefined ? bitacorasEpps : []);
        dispatch({ type: OBTENER_BITACORAS_EPPS, payload: (bitacorasEpps) });

        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerVacacionesHistoricas = (idTrabajador, idEmpresa) => async (dispatch) => {

  dispatch({ type: LOADING });
  try {
    const url = `${API}/BitacoraDocumentos/${idTrabajador}?Empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();
        dispatch({ type: OBTENER_VACACIONES_HISTORICAS, payload: data.data });

        return data.data;

      case 204:

        let dataVacia = []
        dispatch({ type: OBTENER_VACACIONES_HISTORICAS, payload: [] });
        return dataVacia;
      default:
        data = await response.json();
        dispatch({ type: ERROR, payload: { message: 'error al obtener vacaciones históricas' } });
        return [];
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerBitacoraProfesional = (idTrabajador, idEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/BitacoraProfesional/Trabajador/${idTrabajador}/Empresa/${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let bitacoraProfesional = data.data[0];

        bitacoraProfesional = (bitacoraProfesional !== undefined ? bitacoraProfesional : []);

        bitacoraProfesional = ordernarBitacoraProfesional(bitacoraProfesional);

        dispatch({ type: OBTENER_BITACORAS_PROFESIONALES, payload: bitacoraProfesional });

        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerEstados = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/TipoRegistroBitacoraTrabajador`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();
    switch (status) {
      case 200:
      case 201:
        const estadoTrabajador = data.data;

        estadoTrabajador.forEach((element) => {
          // {
          //   id: "1",
          //   title: "group 1",
          //   height: 60,
          //   stackItems: true,
          // },
          // rightTitle: "title in the right sidebar",
          // stackItems: true,
          // height: 50,
          // width: 200,
          // Header: true,
          element.Header = true;
          element.id = element._id;

          element.title = element.Nombre;
          element.height = 53;
        });

        dispatch({
          type: OBTENER_ESTADOS_TRABAJADOR,
          payload: estadoTrabajador,
        });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerBitacora = (idTrabajador, inicioMes, finMes) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    // const url = `${API}/BitacoraTrabajadores/ByDates/DetalleTrabajador/${idTrabajador}`;
    const url = `${API}/BitacoraTrabajadores/ByDates/DetalleTrabajadorNuevo/${idTrabajador}`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        Inicio_Planificacion: inicioMes,
        Fin_Planificacion: finMes,
      }),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:

        const urlFiniquitados = `${API}/BitacoraTrabajadores/ByDates/DetalleTrabajadorDescansoFiniquitado/${idTrabajador}`;

        const responseFiniquitados = await fetch(urlFiniquitados, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            Inicio_Planificacion: inicioMes,
            Fin_Planificacion: finMes,
          }),
        });

        const statusFiniquitados = responseFiniquitados.status;
        const dataFiniquitados = await responseFiniquitados.json();
        let newArray = [];
        let detalleDescansoFiniquitado = [];
        if(statusFiniquitados === 200 || statusFiniquitados === 201){
          //console.log(dataFiniquitados.data,"DescansoFiniquitados")
          detalleDescansoFiniquitado = dataFiniquitados !== undefined ? dataFiniquitados.data : [];
          //console.log(detalleDescansoFiniquitado,"detalleDescansoFiniquitado")
          
          // dataFiniquitados.data.forEach(element => {
          //   let colorBarra = coloresEstadoTrabajo.obtenerColorBitacoraTrabajador("descansoFiniquitado");
          //   let fecha_inicio = corregirZonaHoraria(moment(element.FechaInicio), 1);
          //   let fecha_fin = corregirZonaHoraria(moment(element.FechaFin), 2);

          //   element.id = element._id;
          //   element.group = "5f3eeb78978b284b68ebced4";
          //   element.TipoRegistroBitacoraTrabajador = "5f3eeb78978b284b68ebced4";
          //   element.title = "";
          //   element.canMove = false;
          //   element.canResize = false;
          //   element.canChangeGroup = false;
          //   element.colorBarra = colorBarra;
          //   element.start_time = fecha_inicio;
          //   element.end_time = fecha_fin;
          //   element.new_tipo_bitacora = true;
          //   newArray.push(element);
          // });
        }

        //console.log(newArray)

        let bitacora = data.data;

        bitacora.forEach((element) => {
          let colorBarra = coloresEstadoTrabajo.obtenerColorBitacoraTrabajador(element.TipoRegistroBitacoraTrabajador);

          // let fecha_inicio = (element.FechaUnix != undefined ? moment.unix(element.FechaUnix).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) : moment(element.Fecha).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
          // let fecha_fin = (element.FechaUnix != undefined ? moment.unix(element.FechaUnix).set({ hour: 23, minute: 59, second: 59, millisecond: 59 }) : moment(element.Fecha).set({ hour: 23, minute: 59, second: 59, millisecond: 59 }))

          let fecha_inicio = corregirZonaHoraria(moment(element.Fecha), 1);
          let fecha_fin = corregirZonaHoraria(moment(element.Fecha), 2);

          element.id = element._id;
          element.group = element.TipoRegistroBitacoraTrabajador;
          element.title = "";
          element.canMove = false;
          element.canResize = false;
          element.canChangeGroup = false;
          element.colorBarra = colorBarra;
          element.start_time = fecha_inicio;
          element.end_time = fecha_fin;
        });

        newArray.forEach(element => {
          bitacora.push(element);
        });

        //CODIGO ANTIGUIO(DESCOMENTAR PARA EL CODIGO VIEJO)
        //let itemsDashboard = obtenerItems(bitacora, inicioMes, finMes);
        //REUTILIZACION DE CODIGO PARA INDICADORES
        let itemsDashboard = data.indicadores;
        let itemsDetalleOtrasCondiciones = data.indicadoresOtrasCondiciones;
        //console.log(itemsDashboard)
        //console.log(detalleDescansoFiniquitado,"fuera del if")


        dispatch({ type: OBTENER_BITACORA_TRABAJADOR, payload: { bitacora, itemsDashboard, detalleDescansoFiniquitado, itemsDetalleOtrasCondiciones } });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerContratos = (idTrabajador, idEmpresa) => async (dispatch) => {
  try {
    const url = `${API}/Trabajadores/Contratos?idTrabajador=${idTrabajador}&idEmpresa=${idEmpresa}`;
    const request = await fetch(url);
    const data = await request.json();
    const status = await request.status;

    console.log("status contratos");
    console.log(status);

    switch (status) {
      case 200:
        dispatch({
          type: OBTENER_CONTRATOS_TRABAJADOR,
          payload: data.contratoDB,
        });
        break;
      default:
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.message,
    });
  }
};

export const MostrarTabla = () => (dispatch) => {
  dispatch({ type: MOSTRAR_TABLA });
};

export const obtenerItems = (bitacora, inicioMes, finMes) => {

  let objDiasEfectivos = {
    dias: 0,
    tipo: '5f3eedf56dd7cc57f0d3a261',
    titulo: 'Días',
    subtitulo: 'Trabajo Efectivo',
  };

  let objDiasDescanso = {
    dias: 0,
    tipo: '5f3eeb78978b284b68ebced4',
    titulo: 'Días',
    subtitulo: 'Descanso',
  };

  let objDiasVacaciones = {
    dias: 0,
    tipo: '5f3eebac978b284b68ebced5',
    titulo: 'Días',
    subtitulo: 'Vacaciones',
  };

  let objDiasLicencia = {
    dias: 0,
    tipo: '5f3eebf46dd7cc57f0d3a25d',
    titulo: 'Días',
    subtitulo: 'Licencia',
  };

  let objDiasMutual = {
    dias: 0,
    tipo: '5f3eec676dd7cc57f0d3a25e',
    titulo: 'Días',
    subtitulo: 'Licencia Mutual',
  };

  let objDiasPermiso = {
    dias: 0,
    tipo: '5f3eec876dd7cc57f0d3a25f',
    titulo: 'Días',
    subtitulo: 'Permiso',
  };

  let objDiasAusente = {
    dias: 0,
    tipo: '5f3eeca16dd7cc57f0d3a260',
    titulo: 'Días',
    subtitulo: 'Ausente',
  };

  let objDiasTransito = {
    dias: 0,
    tipo: '5f8f369e8efe155a48df37fb',
    titulo: 'Días',
    subtitulo: 'En Tránsito',
  };

  let objDiasNoAsignado = {
    dias: 0,
    tipo: 'NO_ASIGNADO',
    titulo: 'Días',
    subtitulo: 'No Asignado',
  };

  let objDiasUndefined = {
    dias: 0,
    tipo: 'UNDEFINED',
    titulo: 'Días',
    subtitulo: 'Otras Condiciones',
  };

  let arregloOrdenadoDashboard = [];

  let fechaInicio = moment(inicioMes);
  let fechaFin = moment(finMes);

  let cantidadDias = fechaFin.diff(fechaInicio, "days") + 1;

  for (let index = 0; index < cantidadDias; index++) {

    let fecha = fechaInicio;

    fecha = fecha.add(index === 0 ? 0 : 1, "day");


    // let diasEncontrados2 = bitacora.filter(bitacora => moment(bitacora.Fecha).format("YYYY-MM-DD") === fecha.format("YYYY-MM-DD"));

    let diasEncontrados = bitacora.filter(bitacora => convertirFechaString(moment(bitacora.Fecha), 1).fechaString === convertirFechaString(fecha, 1).fechaString);

    if (diasEncontrados.lenght !== 0) {
      // arregloDeDias.push(diasEncontrados);

      diasEncontrados.forEach((bitacora, index) => {
        if (index === 0) {

          switch (bitacora.TipoRegistroBitacoraTrabajador) {

            // Efectivo
            case "5f3eedf56dd7cc57f0d3a261":
              objDiasEfectivos.dias++;
              break;

            // Descanso
            case "5f3eeb78978b284b68ebced4":
              objDiasDescanso.dias++;
              break;

            // Vacaciones
            case "5f3eebac978b284b68ebced5":
              objDiasVacaciones.dias++;
              break;

            // Licencia
            case "5f3eebf46dd7cc57f0d3a25d":
              objDiasLicencia.dias++;
              break;

            // Mutual
            case "5f3eec676dd7cc57f0d3a25e":
              objDiasMutual.dias++;
              break;

            // Permiso
            case "5f3eec876dd7cc57f0d3a25f":
              objDiasPermiso.dias++;
              break;

            // Ausente
            case "5f3eeca16dd7cc57f0d3a260":
              objDiasAusente.dias++;
              break;

            // En Tránsito
            case "5f8f369e8efe155a48df37fb":
              objDiasTransito.dias++;
              break;

            default:
              objDiasUndefined.dias++;
              break;
          }
        }
      });

    } else {
      objDiasNoAsignado.dias++
    }

  }

  arregloOrdenadoDashboard.push(
    objDiasEfectivos,
    objDiasDescanso,
    objDiasVacaciones,
    objDiasLicencia,
    objDiasMutual,
    objDiasPermiso,
    objDiasAusente,
    objDiasTransito,
    objDiasNoAsignado,
    objDiasUndefined,
  );

  arregloOrdenadoDashboard.sort((objA, objB) => objB.dias - objA.dias);

  let sumaDias = arregloOrdenadoDashboard[3].dias
    + arregloOrdenadoDashboard[3].dias
    + arregloOrdenadoDashboard[4].dias
    + arregloOrdenadoDashboard[5].dias
    + arregloOrdenadoDashboard[6].dias
    + arregloOrdenadoDashboard[7].dias
    + arregloOrdenadoDashboard[8].dias
    + arregloOrdenadoDashboard[9].dias;

  let objGenerico = {
    dias: sumaDias,
    tipo: '',
    titulo: 'Días',
    subtitulo: 'Otras Condiciones',
  };

  let arregloDeDashboard = [
    arregloOrdenadoDashboard[0],
    arregloOrdenadoDashboard[1],
    arregloOrdenadoDashboard[2],
    objGenerico,
  ];

  return arregloDeDashboard;

};

export const activarCargando = () => (dispatch) => {
  dispatch({ type: LOADING });
};

export const desactivarCargando = () => (dispatch) => {
  dispatch({ type: PARAR_LOADING });
};

export const ObtenerCargosTrabajador = (idEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/CargosTrabajadores/Vigentes?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let cargosTrabajadores = data.data;

        dispatch({
          type: OBTENER_CARGOS_TRABAJADOR,
          payload: cargosTrabajadores,
        });

        return cargosTrabajadores;

      default:
        const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const ObtenerRegiones = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Regiones`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;


    switch (status) {
      case 200:
      case 201:

        data = await response.json();
        dispatch({ type: OBTENER_REGIONES_MANTENEDOR_TRABAJADOR, payload: data.data });
        return data.data;

      case 204:
        dispatch({ type: OBTENER_REGIONES_MANTENEDOR_TRABAJADOR, payload: [] });

        break;
      default:

        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const ObtenerProvincias = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Provincia`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;


    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        dispatch({ type: OBTENER_PROVINCIAS_MANTENEDOR_TRABAJADOR, payload: data.data });
        return data.data;

      case 204:
        dispatch({ type: OBTENER_PROVINCIAS_MANTENEDOR_TRABAJADOR, payload: [] });

        break;
      default:

        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

//controlada
export const ObtenerComunas = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Comunas`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;


    switch (status) {
      case 200:
      case 201:

        data = await response.json();
        dispatch({ type: OBTENER_COMUNAS_MANTENEDOR_TRABAJADOR, payload: data.data });
        return data.data;

      case 204:
        dispatch({ type: OBTENER_COMUNAS_MANTENEDOR_TRABAJADOR, payload: [] });

        break;
      default:

        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: obtenerRespueta(status, error),
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: obtenerRespueta(-1, error),
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerTitulos = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {

      usuario = JSON.parse(usuario);

      const id = usuario.EmpresaSeleccionada.Empresa._id;

      const url = `${API}/Titulos/Vigentes?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;

      let data;
      switch (status) {
        case 200:
        case 201:
          data = await response.json();

          let titulos = data.data;

          dispatch({
            type: OBTENER_TITULOS_TRABAJADORES,
            payload: titulos,
          });
          return titulos;

        default:
          data = await response.json();
          const error = data.err.message.toString();
          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerEspecialidades = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/Especialidades/Vigentes?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();

          let especialidades = data.data;

          dispatch({
            type: OBTENER_ESPECIALIDADES_TRABAJADORES,
            payload: especialidades,
          });

          return especialidades;

        default:
          data = await response.json();
          const error = data.err.message.toString();
          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerEPPs = () => async (dispatch) => {

  dispatch({ type: LOADING });

  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/Epp/VigenciaTrue?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();

          let epps = data.data;

          // console.log('epps ingresados en el sistema');
          // console.log(epps);

          dispatch({
            type: OBTENER_EPPS_TRABAJADORES,
            payload: epps,
          });

          return epps;

        default:
          data = await response.json();
          const error = data.err.message.toString();
          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerRegimenes = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/RegimenDeTrabajo/Vigentes?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();

          let regimenes = data.regimenes;

          dispatch({
            type: OBTENER_REGIMEN_TRABAJO,
            payload: regimenes,
          });
          return regimenes;

        default:
          data = await response.json();
          const error = data.err.message.toString();
          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerDocumentos = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/TiposDeDocumentos/Vigentes?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          // console.log(data);
          let documentos = data.tiposDeDocumentos;

          dispatch({
            type: OBTENER_DOCUMENTOS_MANTENEDOR_TRABAJADOR,
            payload: documentos,
          });
          return documentos;

        default:
          data = await response.json();
          const error = data.err.message.toString();
          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const obtenerBonificacionesTrabajador = (idTrabajador) => async (dispatch) => {

  try {
    const url = `${API}/Vacaciones/Bonificacion/${idTrabajador}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        const bonificaciones = data.data;
        return bonificaciones;

      case 204:

        return [];

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: error,
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

export const obtenerBonificacionesTrabajadorDescansos = (idTrabajador) => async (dispatch) => {

  try {
    const url = `${API}/Descansos/Bonificacion/${idTrabajador}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        const bonificaciones = data.data;
        return bonificaciones;

      case 204:

        return [];

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: error,
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

export const eliminarBonificacion = (idBonificacion) => async (dispatch) => {

  dispatch({ type: LOADING });

  try {
    const url = `${API}/Vacaciones/${idBonificacion}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:

        Swal.fire({
          title: "Bien",
          text: "El registro de bonificación fue eliminado exitosamente.",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        });
        break;

      default:
        const error = data.err.message;

        console.error(error);
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarTrabajador = (Trabajador) => async (dispatch) => {

  dispatch({ type: LOADING });

  const idTrabajador = Trabajador._id;
  delete Trabajador._id;
  try {
    const url = `${API}/Trabajadores/${idTrabajador}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(Trabajador),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El trabajador fue actualizado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        })
        .then((value) => {
          window.location.reload();
        });
        //SE DESCOMENTO "THEN" PORQUE AL NO REFRESCARSE LA PANTALLA NO SE ACTUALIZABAN ALGUNO VALORES DE LAS TARJETAS
        // dispatch({ type: EDITAR_TRABAJADOR_MANTENEDOR });
        break;

      default:
        const error = data.err.message;

        console.error(error);
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ERROR, payload: error });
  }
};

function ordernarBitacoraProfesional(bitacoraProfesional) {
  let bitacora = [];

  bitacoraProfesional.Especialidades.forEach(especialidad => {
    especialidad.tipo = "Especialidad"
    bitacora.push(especialidad);
  });

  bitacoraProfesional.Titulos.forEach(titulos => {
    titulos.tipo = "Titulo";
    bitacora.push(titulos);
  });

  bitacoraProfesional.Documentos.forEach(doc => {
    doc.tipo = "Documento";
    bitacora.push(doc);
  });
  return bitacora;
}

function obtenerDataFaltante(trabajador) {
  try {
    let str = moment().format('yyyy/MM/DD');
    let fechaHoy = convertirFechaString(moment(str), 1).fechaString;

    //EPPS
    let arregloEpps = (trabajador.EPP !== undefined ? trabajador.EPP : []);
    let arregloEppsEntregadas = (trabajador.EPP_Entregados !== undefined ? trabajador.EPP_Entregados : []);

    arregloEpps.forEach(epp => {

      epp.encontrado = false;
      arregloEppsEntregadas.forEach(eppEntregada => {

        if (epp._id.toString() === eppEntregada.idEPP.toString()) {

          // let diasDiferencia = moment(eppEntregada.FechaVencimiento).diff(moment(), 'days');
          let diasDiferencia = moment.utc(eppEntregada.FechaVencimiento).diff(moment.utc(fechaHoy), 'days');

          epp.cantidadDiasHabiles = diasDiferencia;

          // epp.FechaVencimiento = moment(eppEntregada.FechaVencimiento).format("DD-MM-YYYY");
          epp.FechaVencimiento = convertirFechaString(moment.utc(eppEntregada.FechaVencimiento), 2).fechaDDMMYYYY;

          epp.encontrado = true;
        }
      });
    });

    trabajador.EPP = arregloEpps;

    //TITULOS PROFESIONALES
    let arregloTitulosProfesionales = (trabajador.Titulos_Profesionales !== undefined ? trabajador.Titulos_Profesionales : []);
    let arregloTitulosProfesionalesEntregados = (trabajador.Titulos_Profesionales_Entregados !== undefined ? trabajador.Titulos_Profesionales_Entregados : []);

    arregloTitulosProfesionales.forEach(titulo => {
      titulo.encontrado = false;
      arregloTitulosProfesionalesEntregados.forEach(tituloEntregado => {

        if (titulo._id.toString() === tituloEntregado.idTituloProfesional.toString()) {
          titulo.encontrado = true;
          if (titulo.Expira) {
            let str = moment().format('yyyy/MM/DD');
            let fechaHoy = convertirFechaString(moment(str), 1).fechaString;

            // let diasDiferencia = moment.utc(tituloEntregado.FechaVencimiento).diff(moment(), 'days');
            let diasDiferencia = moment.utc(tituloEntregado.FechaVencimiento).diff(moment.utc(fechaHoy), 'days');
            titulo.cantidadDiasHabiles = diasDiferencia;

            // titulo.FechaVencimiento = moment(tituloEntregado.FechaVencimiento).format("DD-MM-YYYY");
            titulo.FechaVencimiento = convertirFechaString(moment.utc(tituloEntregado.FechaVencimiento), 2).fechaDDMMYYYY;
          }
        }
      });
    });

    trabajador.Titulos_Profesionales = arregloTitulosProfesionales;

    //ESPECIALIDADES
    let arregloEspecialidades = (trabajador.Especialidades !== undefined ? trabajador.Especialidades : []);
    let arregloEspecialidadesEntregadas = (trabajador.Especialidades_Entregadas !== undefined ? trabajador.Especialidades_Entregadas : []);

    arregloEspecialidades.forEach(especilidad => {
      especilidad.encontrado = false;
      arregloEspecialidadesEntregadas.forEach(especialidadEntregado => {
        if (especilidad._id.toString() === especialidadEntregado.idEspecialidad.toString()) {

          especilidad.encontrado = true;
          if (especilidad.Expira === true) {
            let str = moment().format('yyyy/MM/DD');
            let fechaHoy = convertirFechaString(moment(str), 1).fechaString;

            // let diasDiferencia = moment(especialidadEntregado.FechaVencimiento).diff(moment(), 'days');
            let diasDiferencia = moment.utc(especialidadEntregado.FechaVencimiento).diff(moment.utc(fechaHoy), 'days');

            especilidad.cantidadDiasHabiles = diasDiferencia;

            // especilidad.FechaVencimiento = moment(especialidadEntregado.FechaVencimiento).format("DD-MM-YYYY");
            especilidad.FechaVencimiento = convertirFechaString(moment.utc(especialidadEntregado.FechaVencimiento), 2).fechaDDMMYYYY;
          }
        }
      });
    });

    trabajador.Especialidades = arregloEspecialidades;

    // DOCUMENTOS
    let arrDocumentos = (trabajador.TiposDeDocumentos !== undefined ? trabajador.TiposDeDocumentos : []);
    let arrDocumentosIngresados = (trabajador.TiposDeDocumentosIngresados !== undefined ? trabajador.TiposDeDocumentosIngresados : []);

    arrDocumentos.forEach(doc => {
      
      doc.encontrado = false;
      arrDocumentosIngresados.forEach(docEntregado => {
        
        if ( doc._id !== undefined && docEntregado.idDocumento !== undefined && doc._id.toString() === docEntregado.idDocumento.toString()) {
          if (docEntregado.FechaFinValidez) {
            let diasDiferencia = moment.utc(docEntregado.FechaFinValidez).diff(moment.utc(fechaHoy), 'days');

            doc.cantidadDiasHabiles = diasDiferencia;

            doc.FechaFinValidez = convertirFechaString(moment.utc(docEntregado.FechaFinValidez), 2).fechaDDMMYYYY;
            
          }
          doc.isIngresado = true;
        }
      });
    });

    trabajador.TiposDeDocumentos = arrDocumentos;

    return trabajador;
    // let idsDocsEntregados = [];

    // // creando arr de ids de docs entregados
    // arrDocumentosIngresados.forEach(docEntregado => {
    //   let diasDiferencia = moment.utc(docEntregado.FechaFinValidez).diff(moment.utc(fechaHoy), 'days');
    //   docEntregado.cantidadDiasHabiles = diasDiferencia;
    //   docEntregado.FechaFinValidez = convertirFechaString(moment.utc(docEntregado.FechaFinValidez), 2).fechaDDMMYYYY;

    //   idsDocsEntregados.push(docEntregado.idDocumento);
    // })

    // // revisando cuáles docs han sido entregados
    // console.log("ARREGLO DOCUMENTOS");
    // console.log(arrDocumentosIngresados);
    // arrDocumentos.forEach(doc => {

    //   doc.isIngresado = false;

    //   if (idsDocsEntregados.includes(doc._id)) {
    //     doc.isIngresado = true;
    //   }

    // })

    
  } catch (error) {
    console.log(error)
  }

}