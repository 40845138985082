import React, { useState } from "react";
import SubirFoto from "../../components/subirFoto/SubirFoto";
import logoGenerico from "../../img/logos/logoGenerico.svg";
import avatar from "../../img/icons/avatar.svg";
import Rut_Representante from "../../funcionesGenericas/rut";
import iconBorrar from "../../img/icons/basurero-rosado.svg";

import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Select,
  //Radio,
} from "antd";

import Swal from "sweetalert2";

const Demo = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();
  const [validRutUsuario, setValidRutUsuario] = useState("");

  const ObtenerImagen = (imagenUrl) => {
    props.obtenerImagen(imagenUrl);
  };

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {

      if (values.Password !== values.PasswordConfirmar) {
        Swal.fire({
          title: "¡Ups!",
          text: "Confirmación de contraseña erronea.",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        });
        return false;
      }

      if (props.imagen === null || props.imagen === undefined) {
        values.Imagen =
          "https://peoplemanage.blob.core.windows.net/imagenes-sistema/avatar.svg";
      } else {
        values.Imagen = props.imagen;
      }

      if (values.Descripcion === null || values.Descripcion === undefined) {
        values.Descripcion = "";
      }

      if (values.Telefono === null || values.Telefono === undefined) {
        values.Telefono = "";
      }
      //let empresas = [];

      // values.EmpresaHolding.forEach((element) => {
      //   empresas.push(element);
      // });

      //values.Empresas = empresas;
      let flag = false;
      props.arregloEmpresas.forEach((empresa, index) => {
        if (empresa.Empresa === "" || empresa.Rol === "") {
          flag = true;
          let numero = parseInt(index) + parseInt(1);
          Swal.fire({
            title: "Uuups...",
            text: "Debe ingresar la información de la empresa número " + numero,
            icon: "error",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });
          return false;
        }
      });

      if (flag === false) {
        values.Empresas = props.arregloEmpresas;
        props.crearUsuario(values);
      }

    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const valoresSelect = () => {
    let arregloValoresNoIngresables = props.arregloEmpresas;

    let valoresSeleccionados = [];

    props.empresasHolding.forEach((empresa) => {
      let flag = false;

      arregloValoresNoIngresables.forEach((empresaSeleccionada) => {
        if (empresa._id.toString() === empresaSeleccionada.Empresa.toString()) {
          flag = true;
        }
      });


      empresa.disabled = flag;
      valoresSeleccionados.push(empresa);

    });


    return valoresSeleccionados.map((value, index) => (
      <Select.Option
        disabled={value.disabled}
        name="Rol"
        key={index}
        value={value._id}>
        {value.Razon_Social}
      </Select.Option>
    ));
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 9,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={logoGenerico} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Nuevo Usuario"
          />
        </Col>
        <Col span={8}>
          <Row>
            <Col span={24}>
              <Card className="card-perfil" style={{ width: "100%" }}>
                <Row>
                  <Col lg={10} xl={24}>
                    <SubirFoto avatar={avatar} ObtenerImagen={ObtenerImagen} />
                  </Col>
                  <Col lg={22} xl={24}>
                    <p>
                      Te recomendamos que la fotografía no exceda los 5 MB de
                      peso
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              {props.arregloEmpresas.map((empresa, index) => (
                <Card
                  className="card-perfil"
                  style={{ width: "100%", marginTop: "10px" }}
                  key={index}
                >
                  <Row>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <h3>Empresa {index + 1}</h3>
                      <img
                        src={iconBorrar}
                        onClick={() => {
                          props.borrarEmpresa(index);
                        }}
                        className="cursor-pointer"
                        alt="borrar-icon"
                        style={{
                          top: "0",
                          right: "14px",
                          position: "absolute",
                          width: "10px",
                        }}
                      />
                    </Col>
                    <Col span={24}>
                      <Select
                        style={{
                          maxWidth: "100%",
                        }}
                        className="campos"
                        name="EmpresaHolding"
                        placeholder="Selecciona una empresa"
                        onSelect={(value) => {
                          const escogido = props.arregloEmpresas[index];
                          props.agregarValores(index, escogido.Rol, value);
                        }}
                      >
                        {valoresSelect()}
                        {/* {props.empresasHolding.map((value, index) => (
                          <Select.Option
                            name="Rol"
                            key={index}
                            value={value._id}
                          >
                            {value.Razon_Social}
                          </Select.Option>
                        ))} */}
                      </Select>
                    </Col>
                    <Col span={24}>
                      <Select
                        className="campos"
                        name="Rol"
                        placeholder="Selecciona rol"
                        style={{
                          maxWidth: "100%",
                          marginTop: "10px",
                        }}
                        onSelect={(value) => {
                          console.log(value);
                          const escogido = props.arregloEmpresas[index];
                          props.agregarValores(index, value, escogido.Empresa);
                        }}
                      >
                        {props.Roles.map((value, index) => (
                          <Select.Option
                            name="Rol"
                            key={index}
                            value={value._id}
                          >
                            {value.Nombre}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Col>
            <Col
              span={24}
              style={{
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              <Button
                className="button-delgado-rosado"
                onClick={() => {
                  props.agregarNuevaEmpresa();
                }}
              >
                Agregar empresa
              </Button>
            </Col>
          </Row>
        </Col>
        <Col offset={1} span={15}>
          <Card className="card-perfil" style={{ width: "100%" }}>

            <Form.Item
              name={"Email"}
              label="Nombre usuario (Email)"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Se debe ingresar un correo",
                },
              ]}
            >
              <Input name="Email" className="campos" />
            </Form.Item>

            <Form.Item
              name={"Password"}
              label="Contraseña"
              rules={[
                {
                  required: true,
                  message: "Debe tener como mínimo 8 y máximo 20 caracteres alfanuméricos",
                  min: 8,
                  max: 20,
                },
              ]}
              hasFeedback
              help="Debe tener como mínimo 8 y máximo 20 caracteres alfanuméricos"
            >
              <Input
                suggested="current-password"
                name="Password"
                type="Password"
                className="campos"
              />
            </Form.Item>

            <Form.Item
              name={"PasswordConfirmar"}
              label="Confirmar Contraseña"
              rules={[
                {
                  validator: (_, value) => {

                    let password = form.getFieldsValue(['Password']).Password;

                    if (value !== password) {
                      return Promise.reject('Confirmación de contraseña incorrecta.');
                    } else {
                      return Promise.resolve();
                    }
                  }
                },
              ]}
            >
              <Input
                suggested="current-password"
                name="Password"
                type="Password"
                className="campos"
              />
            </Form.Item>

            <Form.Item
              name="Nombres"
              label="Nombres:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir sus nombres",
                  min: 2,
                  max: 20,
                },
              ]}
            >
              <Input name="Nombres" className="campos" />
            </Form.Item>

            <Form.Item
              name={"Apellido_Paterno"}
              label="Apellido Paterno:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir sus apellido paterno",
                  min: 2,
                  max: 30,
                },
              ]}
            >
              <Input name="Apellido_Paterno" className="campos" />
            </Form.Item>

            <Form.Item
              name={"Apellido_Materno"}
              label="Apellido Materno:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir sus apellido materno",
                  min: 2,
                  max: 30,
                },
              ]}
            >
              <Input name="Apellido_Materno" className="campos" />
            </Form.Item>

            <Form.Item
              name={"Rut"}
              label="RUT"
              validateStatus={validRutUsuario}
              onChange={(e) => {
                let valor = e.target.value;

                if (valor) {
                  let rut = Rut_Representante.formateaRut(valor);
                  let rutValidacion = Rut_Representante.procesarRut(rut);

                  setValidRutUsuario(rutValidacion);

                  if (rut !== undefined) {
                    form.setFieldsValue({ Rut: rut });
                  } else {
                    form.setFieldsValue({ Rut: valor });
                  }
                }
              }}
              rules={[
                {
                  min: 11,
                  max: 12,
                  required: true,
                  message: "Debe introducir su RUT!",
                },
              ]}
              hasFeedback
              help="Ingrese el RUT válido con código verificador"
            >
              <Input
                name="Rut"
                className="campos"
                placeholder="xx.xxx.xxx-x"
                maxLength={12}
              />
            </Form.Item>
            {/* <Form.Item
              label="Rol"
              name={"Rol"}
              rules={[
                {
                  required: true,
                  message: "Debes seleccionar un rol para el usuario",
                },
              ]}
            >
              <Select
                className="campos"
                name="Rol"
                placeholder="Selecciona rol"
              >
                {props.Roles.map((value, index) => (
                  <Select.Option name="Rol" key={index} value={value._id}>
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Escoja la empresa"
              name="EmpresaHolding"
              rules={[
                {
                  required: true,
                  message: "Debes seleccionar una empresa",
                },
              ]}
            >
              <Select
                className="campos"
                name="EmpresaHolding"
                placeholder="Selecciona una empresa"
                mode="multiple"
              >
                {props.empresasHolding.map((value, index) => (
                  <Select.Option name="Rol" key={index} value={value._id}>
                    {value.Razon_Social}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
            <Form.Item
              label="Teléfono"
              name="Telefono"
              rules={[
                {
                  message: "Debe introducir tu numero de telefono",
                },
              ]}
              hasFeedback
            >
              <Input
                name="Telefono"
                className="campos"
                placeholder="+569XXXXXXXX"
                maxLength={12}
              />
            </Form.Item>

            <Form.Item
              label="Descripción"
              name="Descripcion"
              rules={[
                {
                  message: "Debe introducir una introducción",
                },
              ]}
            >
              <Input
                name="Descripcion"
                className="campos"
                placeholder="Descripción del usuario"
              />
            </Form.Item>

            <Form.Item label="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                defaultChecked
                onChange={props.cambiarEstadoVigencia}
                className="switch"
              />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Demo;
