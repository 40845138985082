import React, { Component } from "react";
import "./MantenedorUsuario.css";

import { connect } from "react-redux";
import * as mantenedorUsuarioActions from "../../Redux/actions/MantenedorUsuarioActions";

import activo from "../../img/icons/activo.svg";
import inactivo from "../../img/icons/inactivo.svg";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import FormularioUsuario from "../../components/formularioUsuario/FormularioUsuario";
import FormularioUsuarioEditar from "../../components/formularioUsuario/FormularioEditarUsuario";
import Loading from "../../components/loading/Loading";

import { Layout, Table, Space, Button, Row, Col, Input, Modal } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";

import Swal from "sweetalert2";
import accesosPagina from '../../funcionesGenericas/accesosUsuario';

const { Header, Content } = Layout;
const { Search } = Input;
const { Column } = Table;

class Formatos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tablaCargando: false,
      form: {
        Apellido_Materno: "",
        Apellido_Paterno: "",
        Descripcion: "",
        Email: "",
        Nombres: "",
        Password: "",
        Rol: "",
        Rut: "",
        Telefono: "",
        imagen: null,
        Vigencia: true,
        validacionRut: "",
      },
      size: "large",
      usuariosFiltros: null,
      editarCerrado: true,
    };
    this.cambiarEstadoVigencia = this.cambiarEstadoVigencia.bind(this);
    this.TomarValoresEditar = this.TomarValoresEditar.bind(this);
    this.EditarUsuario = this.EditarUsuario.bind(this);
    this.crearUsuario = this.crearUsuario.bind(this);
    this.obtenerImagen = this.obtenerImagen.bind(this);
    this.ObtenerRolesSelect = this.ObtenerRolesSelect.bind(this);
    this.validacionRut = this.validacionRut.bind(this);
    this.buscarHoldingEmpresa = this.buscarHoldingEmpresa.bind(this);
    // this.handleChangeFormulario = this.handleChangeFormulario.bind(this);
  }

  search = (value) => {
    let busqueda = value.target.value;
    
    if (value !== "") {
      const UsuariosRedux = this.props.usuarios;

      const usuariosFiltros = UsuariosRedux.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(busqueda)
        )
      );

      this.setState({ usuariosFiltros });
    } else {
      this.setState({ usuariosFiltros: null });
    }
  };

  descargarExcel = () => {
    this.props.DescargarExcel();
  };

  mostrarModalCrear = () => {
    this.props.MostrarModalUsuario();
  };

  //FUNCIONES NATIVAS DE REACT
  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      const accesos = await accesosPagina.obtenerAccesosPagina('MantenedorUsuarios');

      this.setState({
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
        usuarioId: usuario._id,
      });

      await this.props.ObtenerUsuarios(usuario.EmpresaSeleccionada.Empresa._id);
      await this.props.ObtenerRoles();

      //await this.props.ObtenerEmpresasVigentes();
    }
  }

  ObtenerRolesSelect = () => {
    this.props.ObtenerRolesSelect();
  };

  modalCrearCancelar = () => {
    this.props.OcultarModalUsuario();
  };

  modalEditarCancelar = () => {
    this.setState({ editarCerrado: true })
    this.props.OcultarModalUsuarioEditar();
  };

  cambiarEditarCerrado = () => {
    this.setState({ editarCerrado: false })
  }

  //funcion para obtener el cambio del valor del switch
  cambiarEstadoVigencia = () => {
    this.setState({
      form: {
        ...this.state.form,
        Vigencia: this.state.form.Vigencia === true ? false : true,
      },
    });
  };

  //FUNCIONES PARA EJECUTAR APIS
  crearUsuario = async (usuario) => {
    usuario.Vigencia = this.state.form.Vigencia;
    await this.props.CrearUsuario(usuario);
    //this.props.ObtenerUsuarios();
  };

  eliminarUsuario = (idUsuario) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminará el usuario",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {

        await this.props.EliminarUsuario(idUsuario);
        let usuario = localStorage.getItem("usuario-people-manage");
        if (
          usuario !== null &&
          usuario !== "" &&
          usuario !== undefined &&
          usuario !== "undefined"
        ) {
          usuario = JSON.parse(usuario);
          await this.props.ObtenerUsuarios(usuario.Empresas[0]._id);
        }

      }
    });
  };

  ObtenerUsuario = async (idUsuario) => {
    await this.props.ObtenerUsuario(idUsuario);
    this.buscarHoldingEmpresa(2);
  };

  EditarUsuario = async (Usuario) => {
    Usuario._id = this.props.usuario._id;
    Usuario.Vigencia = this.props.usuario.Vigencia;

    await this.props.EditarUsuario(Usuario);
  };

  TomarValoresEditar = (nombreCampo, valor) => {
    this.props.TomarValoresEditar(nombreCampo, valor);
  };

  modalUsuarioCancelar = () => {
    this.props.OcultarModalUsuario();
  };

  modalUsuarioEditarCancelar = () => { };

  validacionRut = (EstadoValidacion) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        validacionRut: EstadoValidacion,
      },
    });
  };

  buscarHoldingEmpresa = (opcionSeleccionada) => {
    if (opcionSeleccionada === 1) {
      this.props.ObtenerHoldingsVigentes();
    } else {
      this.props.ObtenerEmpresasVigentes();
    }
  };

  //funcion para obtener y guardar la imagen
  obtenerImagen = (imagenNueva) => {
    this.setState({
      form: {
        ...this.state.form,
        imagen: imagenNueva,
      },
    });
  };

  render() {
    const { size, usuariosFiltros } = this.state;

    return (
      <Layout style={{ height: "100%" }}>

        <Loading cargando={this.props.loading} />

        <Header style={{ background: "none" }}>
          <HeaderFicha titulo="Mantenedor de usuarios" subtitulo="Crear y administrar los usuarios del sistema." />
        </Header>

        <hr className="division" />

        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row style={{ padding: "6px 0px 22px" }} align="bottom">
            <Col className="div-input-search" span={6}>
              <Search
                placeholder="Buscar"
                onChange={(value) => this.search(value)}
                enterButton
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={6}></Col>
            <Col style={{ textAlign: "right" }} span={12}>
              <Button
                className="descargar-excel-habilitado"
                type="primary"
                onClick={() => {
                  this.descargarExcel();
                }}
                size={size}
                style={{ marginRight: "20px" }}
              >
                Descargar Excel
              </Button>

              <Button
                type="primary"
                onClick={() => {
                  this.mostrarModalCrear();
                }}
                size={size}

                className={!this.state.Editar ? "guardar-form-disabled" : "guardar-form"}
                disabled={!this.state.Editar}
              >
                Crear usuario
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="_id"
            dataSource={
              usuariosFiltros === null ? this.props.usuarios : usuariosFiltros
            }
            loading={this.props.tablaCargando}
            bordered={true}
            showSorterTooltip={false}
          >
            <Column
              title="Imagen"
              key="Imagen"
              align="center"
              render={(value) => {
                return (
                  <img src={value.Imagen} className="imagen-tabla" alt="i" />
                );
              }}
            />

            <Column
              sorter={(a, b) => {
                return a.Apellido_Materno.localeCompare(b.Apellido_Materno);
              }}
              render={(value) => {
                return value.toUpperCase();
              }}
              title="Nombre Completo"
              dataIndex="nombre_completo"
              key="nombre_completo"
              align="left"
              ellipsis={true}
            />
            <Column title="Rut/DNI" dataIndex="Rut" key="Rut" />

            <Column
              sorter={(a, b) => {
                return a.Email.localeCompare(b.Email);
              }}
              title="Email"
              dataIndex="Email"
              key="Email"
              ellipsis={true}
            />

            {/* <Column
              title="Rol"
              dataIndex="Rol"
              key="Rol"
              render={(value) => {
                if (value !== null) {
                  const nombre = value.Nombre.toUpperCase();
                  return nombre;
                } else {
                  return "ROL BORRADO";
                }
              }}
            /> */}
            <Column
              ellipsis={true}
              sorter={(a, b) => {
                return a.Vigencia - b.Vigencia;
              }}
              title="Vigencia"
              key="Vigencia"
              align="center"
              render={(valor) => {
                return (
                  <img
                    src={valor.Vigencia === true ? activo : inactivo}
                    alt="imagen perfil de tabla"
                  />
                );
              }}
            />

            <Column
              title="Acciones"
              key="Action"
              align='center'
              render={(value) => (
                <Space size="small">

                  <Button
                    className="editar-button"
                    onClick={() => {
                      this.ObtenerUsuario(value._id);
                    }}
                    disabled={!this.state.Editar}
                    icon={<EditFilled />}
                  />

                  <Button
                    className="eliminar-button"
                    onClick={() => {
                      this.eliminarUsuario(value._id);
                    }}
                    disabled={this.state.usuarioId === value._id ? true : !this.state.Eliminar}
                    icon={<DeleteFilled />}
                  />

                </Space>
              )}
            />
          </Table>
        </Content>
        
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalUsuario}
          onCancel={this.modalCrearCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <FormularioUsuario
            //propiedades cambiables del formulario
            Roles={this.props.roles}
            Formulario={this.state.form}
            vigencia={this.state.form.vigencia}
            cambiarEstadoVigencia={this.cambiarEstadoVigencia}
            ObtenerRolesSelect={this.ObtenerRolesSelect}
            crearUsuario={this.crearUsuario}
            imagen={this.state.form.imagen}
            obtenerImagen={this.obtenerImagen}
            validacionRut={this.validacionRut}
            empresasHolding={this.props.empresasHolding}
            buscarHoldingEmpresa={this.buscarHoldingEmpresa}
          />
        </Modal>
        
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalUsuarioEditar}
          onCancel={this.modalEditarCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <FormularioUsuarioEditar
            //propiedades cambiables del formulario
            Roles={this.props.roles}
            Formulario={this.props.usuario}
            ObtenerRoles={this.props.ObtenerRoles}
            editarUsuario={this.EditarUsuario}
            TomarValoresEditar={this.TomarValoresEditar}
            imagen={this.state.form.imagen}
            obtenerImagen={this.obtenerImagen}
            empresasHolding={this.props.empresasHolding}
            buscarHoldingEmpresa={this.buscarHoldingEmpresa}

            editarCerrado={this.state.editarCerrado}
            cambiarEditarCerrado={this.cambiarEditarCerrado}
          />
        </Modal>
      
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  MantenedorUsuarioReducer: {
    error,
    loading,
    roles,
    success,
    modalUsuario,
    modalUsuarioEditar,
    usuarios,
    usuario,
    tablaCargando,
    empresasHolding,
  },
}) => {
  return {
    error,
    loading,
    roles,
    success,
    modalUsuario,
    modalUsuarioEditar,
    usuarios,
    usuario,
    tablaCargando,
    empresasHolding,
  };
};

export default connect(EstadosAPropiedades, mantenedorUsuarioActions)(Formatos);
