import { login, logout } from '../types/AuthTypes';

const INITIAL_STATE = {

}

export default (state = INITIAL_STATE, { type }) => {
    switch (type) {
        case login:
            return {
                ...state,
                uid: '32545642',
                logeado: true
            }
        case logout:
            return {
                ...state,
                logeado: false
            }
        default:
            return state
    }
}