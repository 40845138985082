import React, { Component } from "react";
import { connect } from "react-redux";
import * as FiltroTrabajadoresActions from "../../Redux/actions/CheckOutActions";
import LocaleES from "antd/lib/date-picker/locale/es_ES";
import checkout from "../../img/icons/checkout.svg";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import moment from "moment";
import Loading from "../../components/loading/Loading";
import coloresGenerica from "../../funcionesGenericas/coloresEstadoTrabajo";
import "./CheckOut.css";
import Swal from "sweetalert2";

//imagenes
// import ausente from "../../img/icons/Ausente.svg";
// import permiso from "../../img/icons/Permiso.svg";
// import enTransito from "../../img/icons/enTransito.svg";
// import licencia from "../../img/icons/Licencia.svg";
// import accidente from "../../img/icons/accidente.svg";
// import vacaciones from "../../img/icons/vacaciones.svg"

import enTransito from "../../img/icons-nuevos/EnTransito.svg";
import vacaciones from "../../img/icons-nuevos/Vacaciones.svg";
import licencia from "../../img/icons-nuevos/Licencia.svg";
import accidente from "../../img/icons-nuevos/LicenciaMutualidad.svg";
import permiso from "../../img/icons-nuevos/Permiso.svg";
import ausente from "../../img/icons-nuevos/Ausente.svg";


import {
  Layout,
  Button,
  Row,
  Select,
  Col,
  Card,
  Modal,
  PageHeader,
  DatePicker,
  Empty,
  Timeline,
  Radio,
  Form,
  Input,
  InputNumber,
  Upload,
  Popover,
  Tooltip,
  Checkbox
} from "antd";

import {
  convertirFechaString,
  corregirZonaHoraria

} from "../../funcionesGenericas/fechas";

import accesosPagina from "../../funcionesGenericas/accesosUsuario";

import { UploadOutlined } from "@ant-design/icons";

// import Swal from "sweetalert2";
const { RangePicker } = DatePicker;
const { Header, Content } = Layout;
// const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
    md: {
      span: 12,
    },
    lg: {
      span: 12,
    },
    xl: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
    md: {
      span: 12,
    },
    lg: {
      span: 12,
    },
    xl: {
      span: 16,
    },
  },
};

class FiltroTrabajadores extends Component {
  constructor(props) {
    super(props);
    let str = moment().format('yyyy/MM/DD');
    this.state = {
      tieneFechaFin: true,
      fechaFin: null,
      idCentroCosto: "",

      // fechaBusquedaInicio: momentToStringFechaInicio(moment(), databaseFormat),
      // fechaBusquedaFin: momentToStringFechaFin(moment(), databaseFormat),

      fechaBusquedaInicio: convertirFechaString(moment(str), 1).fechaString,
      fechaBusquedaFin: convertirFechaString(moment(str), 2).fechaString,

      nombreCentroCosto: "",
      idBitacoraCheckout: "",
      isCompensacion: true,

      //formulario
      numeroFormularioMostrar: 0,

      //Vacaciones

      //lógica históricas
      mostrarPantalla: 'none',

      //lógica progresivas
      disabledInputs: true,
      disabledRadios: true,
      disabledProgresivas: true,

      fechasEfectivas: null,
      periodosCondicional: [],
      diasPorPeriodo: [],
      vacacionesBitacoras: [],

      modalCheckoutMultiple: false,
      trabajadoresCheckoutMasivo: []


    };
  }

  formRefAusente = React.createRef();
  formRefPermiso = React.createRef();
  formRefEnTransito = React.createRef();
  formRefLicencia = React.createRef();
  formRefLicenciaMutual = React.createRef();
  formRefVacaciones = React.createRef();
  formRefDescansos = React.createRef();

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      const accesos = await accesosPagina.obtenerAccesosPagina('AsistenciaCheckOut', 'ProcesosCheck');


      this.setState({
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
      });

      await this.props.ObtenerCentroCostos(
        usuario.EmpresaSeleccionada.Empresa._id
      );

      await this.props.ObtenerTrabajadoresCentroCostoUnix(
        null,
        this.state.fechaBusquedaInicio,
        this.state.fechaBusquedaFin,
        usuario.EmpresaSeleccionada.Empresa._id,

        // momentToUnixFechaInicio(moment()),
        // momentToUnixFechaFin(moment())

        convertirFechaString(moment(), 1).fechaUNIX,
        convertirFechaString(moment(), 2).fechaUNIX
      );

    }
  }

  trabajadoresFiltrados = (
    idCentroCosto,
    fechaInicio,
    fechaFin,
    idTrabajador,
    fechaInicioUnix,
    fechaFinUnix
  ) => {
    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      if (
        idCentroCosto === undefined ||
        idCentroCosto === "undefined" ||
        idCentroCosto === [] ||
        idCentroCosto === ""
      ) {
        if (fechaInicio && fechaFin) {
          this.props.ObtenerTrabajadoresCentroCostoUnix(
            null,
            fechaInicio,
            fechaFin,
            usuario.EmpresaSeleccionada.Empresa._id,
            fechaInicioUnix,
            fechaFinUnix
          );
        } else {
          let str = moment().format('yyyy/MM/DD');
          this.props.ObtenerTrabajadoresCentroCostoUnix(
            null,

            // momentToStringFechaInicio(moment(), databaseFormat),
            // momentToStringFechaFin(moment(), databaseFormat),

            convertirFechaString(moment(str), 1).fechaString,
            convertirFechaString(moment(str), 2).fechaString,

            idTrabajador,
            usuario.EmpresaSeleccionada.Empresa._id,
            fechaInicioUnix,
            fechaFinUnix
          );
        }
      } else {
        if (fechaInicio && fechaFin) {
          this.props.ObtenerTrabajadoresCentroCostoUnix(
            idCentroCosto,
            fechaInicio,
            fechaFin,
            usuario.EmpresaSeleccionada.Empresa._id,
            fechaInicioUnix,
            fechaFinUnix
          );
        } else {
          let str = moment().format('yyyy/MM/DD');
          this.props.ObtenerTrabajadoresCentroCostoUnix(
            idCentroCosto,

            // momentToStringFechaInicio(moment(), databaseFormat),
            // momentToStringFechaFin(moment(), databaseFormat),

            convertirFechaString(moment(str), 1).fechaString,
            convertirFechaString(moment(str), 2).fechaString,

            usuario.EmpresaSeleccionada.Empresa._id,

            // momentToUnixFechaInicio(moment()),
            // momentToUnixFechaFin(moment()),

            convertirFechaString(moment(), 1).fechaUNIX,
            convertirFechaString(moment(), 2).fechaUNIX
          );
        }
      }
    }
  };

  traerFaena = (bitacora) => {
    this.props.ObtenerFaenaPorId(bitacora);
  };

  mostrarModalEmbarco = () => {
    this.props.MostrarModalEmbarco();
  };

  ocultarModalEmbarco = () => {
    this.props.OcultarModalEmbarco();
  };

  mostrarModalDesembarco = () => {
    this.props.MostrarModalDesembarco();
  };

  ocultarModalDesembarco = () => {
    this.props.OcultarModalDesembarco();
  };

  mostrarModalCheckoutMultiple = () => {
    this.setState({modalCheckoutMultiple: true})
  };

  ocultarModalCheckoutMultiple = () => {
    this.setState({modalCheckoutMultiple: false})
  }

  abrirCheckoutMultiple = async () => {
    //console.log(this.props.checkOut);
    this.mostrarModalCheckoutMultiple();
  } 

  embarcar = (bitacora) => {
    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      this.props.actualizarBitacorasParaEmbarco(
        bitacora,
        this.props.faena,
        this.state.idCentroCosto,
        this.state.fechaBusquedaInicio,
        this.state.fechaBusquedaFin,
        usuario.EmpresaSeleccionada.Empresa._id,
        this.state.fechaBusquedaInicioUnix,
        this.state.fechaBusquedaFinUnix
      );
    }
  };

  desembarcar = () => {
    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      let bitacora = this.props.bitacora;
      let faena = this.props.faena;
      let idCentroCosto = this.state.idCentroCosto;
      let fechaInicioBusqueda = this.state.fechaBusquedaInicio;
      let fechaFinBusqueda = this.state.fechaBusquedaFin;
      let idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      bitacora.SeEnvioCheckOut = true;

      // return false;

      let isDown = this.props.enviarCorreoCheckOut(
        bitacora,
        faena,
        idCentroCosto,
        fechaInicioBusqueda,
        fechaFinBusqueda,
        idEmpresa
      );

      console.log('Is Down al desembarcar', isDown);

      return isDown

    }
  };

  abreviarNombre = (nombres, apellidoP, apellidoM) => {
    let nCompleto = nombres + " " + apellidoP;

    // if (nCompleto.length > 20) {
    //   nCompleto = nCompleto.substring(0, 20) + "...";
    // }

    return nCompleto;
  };

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  obtenerBase64 = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.getBase64(info.file.originFileObj, (imageUrl) => {
        this.setState({
          archivoSubir: imageUrl,
        });
      });
    }
  };

  //////////////////////////////////////////////////////////////////////

  onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  ingresarDiaAusente = async (values) => {
    let usuario = localStorage.getItem("usuario-people-manage");
    let bitacora = this.props.bitacora;

    //return
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      if (values.Fechas) {
        let listaFechas = [];

        this.props.bitacoraCompletaTrabajadorCheckOut.forEach((bitacora) => {
          listaFechas.push(moment.utc(bitacora.Fecha));
        });

        // const fecha1 = moment.utc(momentToStringFechaInicio(values.Fechas[0].set({ hour: 12 }), databaseFormat));
        // const fecha2 = moment.utc(momentToStringFechaFin(values.Fechas[1].set({ hour: 12 }), databaseFormat));

        const fecha1 = moment.utc(convertirFechaString(values.Fechas[0], 1).fechaString);
        const fecha2 = moment.utc(convertirFechaString(values.Fechas[1], 2).fechaString);

        console.log("Fechas dia ausente");
        console.log(fecha1);
        console.log(fecha2);


        let warningFlag = false;
        //let confirmFlag = false;

        listaFechas.forEach((fecha) => {
          if (fecha.isBetween(fecha1, fecha2)) {
            warningFlag = true;
            return;
          }
        });

        if (warningFlag) {
          await Swal.fire({
            title: "Advertencia",
            text: `Las fechas que seleccionaste ya tienen planificación.`,
            icon: "error",
            //showCancelButton: true,
            confirmButtonColor: "#1FC600",
            //cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            //cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (!result.value) {
              //confirmFlag = true;
            }
          });
        } else {
          //const IdTrabajador = bitacora.IdTrabajador._id;
          const IdTrabajador = bitacora.IdTrabajador;
          const Descripcion = values.Descripcion || "";

          // const Fecha_Inicio = momentToStringFechaInicio(values.Fechas[0].set({ hour: 12 }), databaseFormat);
          // const Fecha_Fin = momentToStringFechaFin(values.Fechas[1].set({ hour: 12 }), databaseFormat);


          const Fecha_Inicio = convertirFechaString(values.Fechas[0], 1).fechaString;
          const Fecha_Fin = convertirFechaString(values.Fechas[1], 2).fechaString;

          console.log("Fechas 2 Dia Ausente");
          console.log(Fecha_Inicio);
          console.log(Fecha_Fin);

          const objetoAusente = {
            IdTrabajador,
            Descripcion,
            Fecha_Inicio,
            Fecha_Fin,
          };

          let flagCorreo = values.Correo;
          
          let isIngresado = await this.props.ingresarDiaAusenteActions(
            objetoAusente,
            flagCorreo
          );

          if (isIngresado) {
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
            const faena = this.props.faena;

            const {
              idCentroCosto,
              fechaBusquedaInicio,
              fechaBusquedaFin,
              // fechaBusquedaInicioUnix,
              // fechaBusquedaFinUnix
            } = this.state;

            await this.props.enviarCorreoCheckOut(
              bitacora,
              faena,
              idCentroCosto,
              fechaBusquedaInicio,
              fechaBusquedaFin,
              idEmpresa
            );
            await this.formRefAusente.current.resetFields([
              "Fechas",
              "Descripcion",
              "Correo",
            ]);
          } else {
            Swal.fire({
              title: "Uuups...",
              text:
                "Ha ocurrido un error al guardar el período ausente, por favor intenta nuevamente.",
              icon: "error",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });
          }
        }
      } else {
        Swal.fire({
          title: "Ups",
          text: "Debes ingresar el rango de fechas",
          icon: "warning",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        });
      }
    }
  };

  ingresarPermiso = async (values) => {
    let usuario = localStorage.getItem("usuario-people-manage");
    let bitacora = this.props.bitacora;

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      if (values.Fechas) {
        let listaFechas = [];

        this.props.bitacoraCompletaTrabajadorCheckOut.forEach((bitacora) => {
          listaFechas.push(moment.utc(bitacora.Fecha));
        });

        // const fecha1 = moment.utc(momentToStringFechaInicio(values.Fechas[0].set({ hour: 12 }), databaseFormat));
        // const fecha2 = moment.utc(momentToStringFechaFin(values.Fechas[1].set({ hour: 12 }), databaseFormat));

        const fecha1 = moment.utc(convertirFechaString(values.Fechas[0], 1).fechaString);
        const fecha2 = moment.utc(convertirFechaString(values.Fechas[1], 2).fechaString);

        console.log("Fechas permisos 1");
        console.log(fecha1);
        console.log(fecha2);

        let warningFlag = false;

        listaFechas.forEach((fecha) => {
          if (fecha.isBetween(fecha1, fecha2)) {
            warningFlag = true;
            return;
          }
        });

        if (warningFlag) {
          await Swal.fire({
            title: "Advertencia",
            text: `Las fechas que seleccionaste ya tienen planificación.`,
            icon: "error",
            //showCancelButton: true,
            confirmButtonColor: "#1FC600",
            //cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            //cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (!result.value) {
              //confirmFlag = true;
            }
          });
        } else {
          //const IdTrabajador = bitacora.IdTrabajador._id;
          //
          const IdTrabajador = bitacora.IdTrabajador;

          const {
            Tipo_Permiso,
            Motivo,
            Habiles,
            Inhabiles,
            Total_Dias,
          } = values;

          // const Fecha_Inicio =  momentToStringFechaInicio(values.Fechas[0].set({ hour: 12 }), databaseFormat);
          // const Fecha_Fin = momentToStringFechaFin(values.Fechas[1].set({ hour: 12 }), databaseFormat);

          const Fecha_Inicio = convertirFechaString(values.Fechas[0], 1).fechaString;
          const Fecha_Fin = convertirFechaString(values.Fechas[1], 2).fechaString;

          console.log("Fechas permisos 2");
          console.log(Fecha_Inicio);
          console.log(Fecha_Fin);

          const objPermisos = {
            IdTrabajador,
            TipoPermiso: Tipo_Permiso,
            Motivo,
            DiasHabiles: Habiles,
            DiasInhabiles: Inhabiles,
            DiasTotal: Total_Dias,
            Fecha_Inicio,
            Fecha_Fin,
          };

          let flagCorreo = values.Correo;

          let isIngresado = await this.props.ingresarPermisoActions(
            objPermisos,
            flagCorreo
          );

          if (isIngresado) {
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
            const faena = this.props.faena;

            const {
              idCentroCosto,
              fechaBusquedaInicio,
              fechaBusquedaFin,
              // fechaBusquedaInicioUnix,
              // fechaBusquedaFinUnix
            } = this.state;

            await this.props.enviarCorreoCheckOut(
              bitacora,
              faena,
              idCentroCosto,
              fechaBusquedaInicio,
              fechaBusquedaFin,
              idEmpresa
            );
          } else {
            Swal.fire({
              title: "Uuups...",
              text:
                "Ha ocurrido un error al guardar el período ausente, por favor intenta nuevamente.",
              icon: "error",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });
          }

          await this.formRefPermiso.current.resetFields([
            "Correo",
            "Fechas",
            "Habiles",
            "Inhabiles",
            "Motivo",
            "Tipo_Permiso",
            "Total_Dias",
          ]);
        }
      } else {
        Swal.fire({
          title: "Ups",
          text: "Debes ingresar el rango de fechas",
          icon: "warning",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        });
      }
    }
  };

  ingresarTransito = async (values) => {
    let usuario = localStorage.getItem("usuario-people-manage");
    let bitacora = this.props.bitacora;
    
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      if (values.Fechas) {
        let listaFechas = [];

        this.props.bitacoraCompletaTrabajadorCheckOut.forEach((bitacora) => {
          listaFechas.push(moment.utc(bitacora.Fecha));
        });

        // const fecha1 = moment.utc(momentToStringFechaInicio(values.Fechas[0].set({ hour: 12 }), databaseFormat));
        // const fecha2 = moment.utc(momentToStringFechaFin(values.Fechas[1].set({ hour: 12 }), databaseFormat));

        const fecha1 = moment.utc(convertirFechaString(values.Fechas[0], 1).fechaString);
        const fecha2 = moment.utc(convertirFechaString(values.Fechas[1], 2).fechaString); 

        let warningFlag = false;

        listaFechas.forEach((fecha) => {
          if (fecha.isBetween(fecha1, fecha2)) {
            warningFlag = true;
            return;
          }
        });

        if (warningFlag) {
          await Swal.fire({
            title: "Advertencia",
            text: `Las fechas que seleccionaste ya tienen planificación.`,
            icon: "error",
            //showCancelButton: true,
            confirmButtonColor: "#1FC600",
            //cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            //cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (!result.value) {
              //confirmFlag = true;
            }
          });
        } else {
          //const IdTrabajador = bitacora.IdTrabajador._id;
          const IdTrabajador = bitacora.IdTrabajador;

          // const Fecha_Inicio = momentToStringFechaInicio(values.Fechas[0].set({ hour: 12 }), databaseFormat);
          // const Fecha_Fin = momentToStringFechaFin(values.Fechas[1].set({ hour: 12 }), databaseFormat);

          const Fecha_Inicio = convertirFechaString(values.Fechas[0], 1).fechaString;
          const Fecha_Fin = convertirFechaString(values.Fechas[1], 2).fechaString;

          let objTransito = {
            IdTrabajador,
            Fecha_Inicio,
            Fecha_Fin,
            Descanso: values.Descanso,
          };

          let flagCorreo = values.Correo;
          
          //return
          let isIngresado = await this.props.ingresarTransitoActions(
            objTransito,
            flagCorreo
          );

          if (isIngresado) {
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
            const faena = this.props.faena;

            const {
              idCentroCosto,
              fechaBusquedaInicio,
              fechaBusquedaFin,
              // fechaBusquedaInicioUnix,
              // fechaBusquedaFinUnix
            } = this.state;

            await this.props.enviarCorreoCheckOut(
              bitacora,
              faena,
              idCentroCosto,
              fechaBusquedaInicio,
              fechaBusquedaFin,
              idEmpresa
            );
          } else {
            Swal.fire({
              title: "Uuups...",
              text:
                "Ha ocurrido un error al guardar el día en tránsito, por favor intenta nuevamente.",
              icon: "error",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });
          }

          await this.formRefEnTransito.current.resetFields([
            "Correo",
            "Fechas",
            "Descanso",
          ]);
        }
      } else {
        Swal.fire({
          title: "Ups",
          text: "Debes ingresar el rango de fechas",
          icon: "warning",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        });
      }
    }
  };

  ingresarLicencia = async (values) => {
    let usuario = localStorage.getItem("usuario-people-manage");
    let bitacora = this.props.bitacora;

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      if (values.Fechas) {
        let listaFechas = [];

        this.props.bitacoraCompletaTrabajadorCheckOut.forEach((bitacora) => {
          listaFechas.push(moment.utc(bitacora.Fecha));
        });

        // const fecha1 = moment.utc(momentToStringFechaInicio(values.Fechas[0].set({ hour: 12 }), databaseFormat));
        // const fecha2 = moment.utc(momentToStringFechaFin(values.Fechas[1].set({ hour: 12 }), databaseFormat));

        const fecha1 = moment.utc(convertirFechaString(values.Fechas[0], 1).fechaString);
        const fecha2 = moment.utc(convertirFechaString(values.Fechas[1], 2).fechaString);

        let warningFlag = false;

        listaFechas.forEach((fecha) => {
          if (fecha.isBetween(fecha1, fecha2)) {
            warningFlag = true;
            return;
          }
        });

        if (warningFlag) {
          await Swal.fire({
            title: "Advertencia",
            text: `Las fechas que seleccionaste ya tienen planificación.`,
            icon: "error",
            //showCancelButton: true,
            confirmButtonColor: "#1FC600",
            //cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            //cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (!result.value) {
              //confirmFlag = true;
            }
          });
        } else {
          //const IdTrabajador = bitacora.IdTrabajador._id;
          const IdTrabajador = bitacora.IdTrabajador;

          // const Fecha_Inicio = momentToStringFechaInicio(values.Fechas[0].set({ hour: 12 }), databaseFormat);
          // const Fecha_Fin = momentToStringFechaFin(values.Fechas[1].set({ hour: 12 }), databaseFormat);

          const Fecha_Inicio = convertirFechaString(values.Fechas[0], 1).fechaString;
          const Fecha_Fin = convertirFechaString(values.Fechas[1], 2).fechaString;
         
          const fechaTerminoOperacion = values.Fecha_Termino_Operacion;
          const tipoLicencia = values.Tipo_Licencia;
          const archivoSubir = this.state.archivoSubir;
          

          let objLicencia = {
            Trabajador: IdTrabajador,
            Termino: fechaTerminoOperacion,
            Fecha_Inicio,
            Fecha_Fin,
            Tipo: tipoLicencia,
            Archivo: archivoSubir,
            CantidadDiasDescanso: 0,
          };

          let flagCorreo = values.Correo;
          
          //return

          let isIngresado = await this.props.ingresarLicenciaActions(
            objLicencia,
            flagCorreo
          );

          if (isIngresado) {
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
            const faena = this.props.faena;

            const {
              idCentroCosto,
              fechaBusquedaInicio,
              fechaBusquedaFin,
              // fechaBusquedaInicioUnix,
              // fechaBusquedaFinUnix
            } = this.state;

            await this.props.enviarCorreoCheckOut(
              bitacora,
              faena,
              idCentroCosto,
              fechaBusquedaInicio,
              fechaBusquedaFin,
              idEmpresa
            );
          } else {
            Swal.fire({
              title: "Uuups...",
              text:
                "Ha ocurrido un error al guardar la licencia, por favor intenta nuevamente.",
              icon: "error",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });
          }

          await this.formRefLicencia.current.resetFields([
            "Archivo",
            "Correo",
            "Fecha_Termino_Operacion",
            "Fechas",
            "Tipo_Licencia",
          ]);
        }
      } else {
        Swal.fire({
          title: "Ups",
          text: "Debes ingresar el rango de fechas",
          icon: "warning",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        });
      }
    }
  };

  ingresarMutual = async (values) => {

    let usuario = localStorage.getItem("usuario-people-manage");
    let bitacora = this.props.bitacora;

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {

      usuario = JSON.parse(usuario);


      let listaFechas = [];

      this.props.bitacoraCompletaTrabajadorCheckOut.forEach((bitacora) => {
        listaFechas.push(moment.utc(bitacora.Fecha));
      });

      let strFechaInicio;
      let strFechaFin;

      values.tieneFechaFin = true;

      if (values.tieneFechaFin === true) {
        strFechaInicio = moment.utc(values.fechaInicioLicencia).format('YYYY-MM-DD');
        strFechaFin = moment.utc(values.fechaFinLicencia).format('YYYY-MM-DD');
      } else {
        strFechaInicio = moment.utc(values.fechaInicioLicencia).format('YYYY-MM-DD');
        strFechaFin = moment.utc(values.fechaInicioLicencia).isBefore(moment()) ? moment().format('YYYY-MM-DD') : moment.utc(values.fechaInicioLicencia).format('YYYY-MM-DD');
      }

      // const fecha1 = moment.utc(convertirFechaString(values.Fechas[0], 1).fechaString);
      // const fecha2 = moment.utc(convertirFechaString(values.Fechas[1], 2).fechaString);

      const fecha1 = convertirFechaString(moment(strFechaInicio), 1).fechaString;
      const fecha2 = convertirFechaString(moment(strFechaFin), 2).fechaString;

      let warningFlag = false;  

      listaFechas.forEach((fecha) => {
        if (fecha.isBetween(moment.utc(fecha1), moment.utc(fecha2))) {
          warningFlag = true;
          return;
        }
      });

      if (warningFlag) {
        await Swal.fire({
          title: "Advertencia",
          text: `Las fechas que seleccionaste ya tienen planificación.`,
          icon: "error",
          //showCancelButton: true,
          confirmButtonColor: "#1FC600",
          //cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
          //cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (!result.value) {
            //confirmFlag = true;
          }
        });
      } else {
        //const Trabajador = bitacora.IdTrabajador._id;
        const Trabajador = bitacora.IdTrabajador;

        // const Fecha_Inicio = momentToStringFechaInicio(values.Fechas[0].set({ hour: 12 }), databaseFormat);
        // const Fecha_Fin = momentToStringFechaFin(values.Fechas[1].set({ hour: 12 }), databaseFormat);

        const Fecha_Inicio = fecha1;
        const Fecha_Fin = fecha2;

        const ArchivoBase64 = this.state.archivoSubir;
        const maniobraRealizada = values.Maniobra;

        let objAccidente = {
          IdTrabajador: Trabajador,
          Maniobra: maniobraRealizada,
          Archivo: ArchivoBase64,
          Fecha_Inicio,
          Fecha_Fin,
        };

        let flagCorreo = values.Correo;

        //return
        let isIngresado = await this.props.ingresarMutualActions(
          objAccidente,
          flagCorreo
        );

        if (isIngresado) {
          const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
          const faena = this.props.faena;

          const {
            idCentroCosto,
            fechaBusquedaInicio,
            fechaBusquedaFin,
            // fechaBusquedaInicioUnix,
            // fechaBusquedaFinUnix
          } = this.state;

          await this.props.enviarCorreoCheckOut(
            bitacora,
            faena,
            idCentroCosto,
            fechaBusquedaInicio,
            fechaBusquedaFin,
            idEmpresa
          );
        } else {
          Swal.fire({
            title: "Uuups...",
            text:
              "Ha ocurrido un error al guardar el día en tránsito, por favor intenta nuevamente.",
            icon: "error",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });
        }

        await this.formRefLicenciaMutual.current.resetFields([
          "Correo",
          "Fechas",
          "Motivo",
          "Archivo",
        ]);
      }
    }
  };

  ingresarVacaciones = async () => {

    const idTrabajador = this.props.trabajadorModal._id;
    //return
    let isDown = await this.desembarcar();

    console.log('Is Down al ingresar Vacaciones', isDown);

    if (isDown) {
      Swal.fire({
        title: "Bien!",
        text: "Se ha realizado el CheckOut, a continución serás redirigido.",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#1FC600",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.value) {
          // Redireccionar
          window.location.href = `/Vacaciones?idTrabajador=${idTrabajador}`;
        }
      });
    }

    return;

  };

  ingresarDescansos = async () => {

    const idTrabajador = this.props.trabajadorModal._id;
    //return
    let isDown = await this.desembarcar();

    console.log('Is Down al ingresar Descanso', isDown);

    if (isDown) {
      Swal.fire({
        title: "Bien!",
        text: "Se ha realizado el CheckOut, a continución serás redirigido.",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#1FC600",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.value) {
          // Redireccionar
          window.location.href = `Descanso?idTrabajador=${idTrabajador}`;
        }
      });
    }

    return;

  };

  //////////////////////////////////////////////////////////////////////

  // FUNCIONES FORM VACACIONES (En desarrollo)

  inicioVacaciones = async () => {

    let trabajador = this.props.trabajadorModal;
    let idTrabajador = trabajador._id;

    if (trabajador) {

      await this.setState({
        imagen: trabajador.Imagen
      });

      await this.props.insertarTrabajador(idTrabajador);

      let response = await this.props.traer_todos_las_fechas_trabajador(idTrabajador);

      let fechas = [];

      let normalesTomadas = 0;
      let progresivasTomadas = 0;
      let hayVacaciones = false;

      let vacacionesBitacoras = [];

      // Recorriendo bitácora: para validar si es que hay vacaciones y en ese caso,
      // contar cuántas son, para descontárla al saldo después.
      response.forEach(bitacora => {

        fechas.push(bitacora.Fecha);

        //Si es bitácora de vacaciones, contarla.
        if (bitacora.TipoRegistroBitacoraTrabajador === '5f3eebac978b284b68ebced5') {

          //validación para que solo cuente los días hábiles de la bitácora
          let numeroSemanalFecha = moment(bitacora.Fecha).add(3, 'hours').isoWeekday();
          let esFeriadoFlag = false;

          vacacionesBitacoras.push(bitacora);

          if (![6, 7].includes(numeroSemanalFecha)) {

            //validación: si es feriado, no contarlo
            this.state.feriados.forEach((feriado) => {

              let feriadoMoment = moment(feriado.Fecha).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
              let bitacoraMoment = moment(bitacora.Fecha).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });

              if (feriadoMoment.isSame(bitacoraMoment)) {
                esFeriadoFlag = true;
              }
            })


            if (!esFeriadoFlag) {
              if (bitacora.Vacaciones.TipoDias === 'Normales') normalesTomadas++
              if (bitacora.Vacaciones.TipoDias === 'Progresivos') progresivasTomadas++;

              hayVacaciones = true;
            }
          }
        }
      });

      await this.setState({
        fechasBloqueadas: fechas,
        bitacora: response,
        mostrarCalendario: true,
      });

      await this.formRef.current.resetFields([
        'TipoDias',
        'FechasTrabajadas',
        'FechasEfectivas',
        'DisponiblesVacaciones',
        'DisponiblesProgresivos',
        'DisponiblesTotal',
        'FeriadoLegalVacaciones',
        'FeriadoLegalProgresivos',
        'FeriadoLegalTotal',
        'SaldoVacaciones',
        'SaldoProgresivos',
        'SaldoTotal',
        'Correo',
        'cantidadTomadas'
      ]);

      /****** INICIO LÓGICA HISTÓRICAS ******/

      let usuario = localStorage.getItem("usuario-people-manage");
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      let fechaInicioSistema = moment.unix(usuario.EmpresaSeleccionada.Empresa.Fecha_Inicio_Sistema_Unix);
      let cancelarFlag = false;

      if (!trabajador.Contrato_Fecha_Inicio) {

        await Swal.fire({
          title: "Error",
          text: `Este trabajador no tiene registro de su fecha de contrato, debe ingresarse en el registro de trabajadores antes de proseguir.`,
          icon: "warning",
          confirmButtonColor: "#1FC600",
          confirmButtonText: "Ok",

        }).then(async () => {
          cancelarFlag = true;
        })
      }

      if (cancelarFlag) {
        return;
      }

      let fechaContrato = trabajador.Contrato_Fecha_Inicio_Unix ? moment.unix(trabajador.Contrato_Fecha_Inicio_Unix) : moment(trabajador.Contrato_Fecha_Inicio);


      let historicas = await this.props.ObtenerVacacionesHistoricas(trabajador._id, idEmpresa);

      let mostrarPantalla;

      let fechaContratoClone1 = fechaContrato.clone();

      // HAY CUATRO CASOS POSIBLES:
      // LA PERSONA FUE CONTRATADA ANTES DEL SISTEMA, Y NO LE HAN INGRESADO HISTÓRICAS: PREGUNAR CONFIRMACIÓN
      // LA PERSONA FUE CONTRATADA ANTES DEL SISTEMA, Y YA SE LE INGRESARON HISTÓRICAS: DEJAR INGRESAR
      // LA PERSONA FUE CONTRATADA DESPUÉS DEL SISTEMA: DEJAR INGRESAR
      // LA PERSONA FUE CONTRATADA ANTES DEL SISTEMA, PERO YA SE LE INGRESARON VACACIONES (NO HISTÓRICAS),
      //   LO QUE SIGNIFICA QUE YA SE CONFIRMÓ ANTERIORMENTE QUE NO SE LE QUIEREN INGRESAR HISTÓRICAS: DEJAR INGRESAR
      if (fechaContratoClone1.add(1, 'years').isBefore(fechaInicioSistema)) {

        if (historicas.length > 0) {
          mostrarPantalla = 'formulario';

        } else {

          if (hayVacaciones) {
            mostrarPantalla = 'formulario';

          } else {
            await Swal.fire({
              title: "Advertencia",
              html:
                `<span>
                                Si el trabajador posee saldo de vacaciones pasadas sin utilizar,
                                deberían ingresarse en el módulo de vacaciones históricas antes de ingresar vacaciones aquí.
                                <br /><br />
                                Si el trabajador no posee vacaciones antiguas sin utilizar, continúe.
                            </span>`,
              width: 600,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#48B9FD",
              cancelButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
              cancelButtonText: "Ir a módulo de vacaciones históricas",
              allowOutsideClick: false,

            }).then(async (result) => {

              if (result.dismiss === 'cancel') {
                window.location.href = `/CalendarioTrabajadorNew?Trabajador=${trabajador._id}&directHistorico=true`
              }
            })
          }
        }

      } else {
        mostrarPantalla = 'formulario';
      }

      /****** FIN LÓGICA HISTÓRICAS ******/


      /****** INICIO LÓGICA INPUTS PROGRESIVAS ******/

      let disabledProgresivas = false;
      let progresivasValue;

      if (trabajador.Requirio_Progresivas !== undefined) {

        if (trabajador.Requirio_Progresivas) {
          disabledProgresivas = false;
          progresivasValue = null;

        } else {
          disabledProgresivas = true;
          progresivasValue = 0;
        }

      } else {
        disabledProgresivas = true;
        progresivasValue = 0;
      }

      await this.setState({
        disabledProgresivas: disabledProgresivas,
        mostrarPantalla,
      })

      this.formRef.current.setFieldsValue({
        DisponiblesProgresivos: progresivasValue,
        FeriadoLegalProgresivos: progresivasValue,
        // SaldoProgresivos: progresivasValue,
      })

      /******** FIN LÓGICA INPUTS PROGRESIVAS ********/


      /******* INICIO LÓGICA SALDOS NORMALES Y PROGRESIVOS *******/

      // EL SALDO SE CALCULA CON:
      // SALDOS HISTÓRICOS + SALDO DE DÍAS TRABAJADOS - VACACIONES EN BITÁCORA DEL TRABAJADOR
      let saldoHistoricoNormales = 0;
      let saldoHistoricoProgresivos = 0;

      // + HISTÓRICAS
      if (historicas.length > 0) {

        historicas.forEach(registro => {
          if (registro.TipoDeDias === 'Normales') saldoHistoricoNormales += registro.CantidadDiasHabiles;
          if (registro.TipoDeDias === 'Progresivos') saldoHistoricoProgresivos += registro.CantidadDiasHabiles;
        });

      }

      // + TRABAJADAS
      let fechaContratoClone = fechaContrato.clone();

      // si la fecha de contrato del trabajador está antes del sistema, utilizar como 
      // fechaInicioCalculoTrabajo el año siguiente a lo que se determina como histórico
      let fechaInicioCalculoTrabajo;

      if (fechaContratoClone.add(1, 'year').isAfter(fechaInicioSistema)) {

        fechaInicioCalculoTrabajo = fechaContrato.clone();
      } else {

        let opcionesPeriodo = [];

        let cantidadPeriodos = fechaInicioSistema.diff(fechaContrato, 'years');
        cantidadPeriodos++;

        for (let index = 0; index < cantidadPeriodos; index++) {

          let fechaInicioPeriodo = fechaContrato.clone().add(index, 'years');

          opcionesPeriodo.push(fechaInicioPeriodo);
        }

        fechaInicioCalculoTrabajo = opcionesPeriodo[opcionesPeriodo.length - 1];
      }

      let diaActual = moment();
      let ctdadMesesTrabajados = diaActual.diff(fechaInicioCalculoTrabajo, 'months');

      let cantidadRegimenVacaciones = trabajador.Regimen_Vacaciones;
      let cantidadPorMes = cantidadRegimenVacaciones / 12;

      let saldoTrabajados = Math.trunc(cantidadPorMes * ctdadMesesTrabajados);
      let progresivosTrabajados = 0;

      // - DÍAS YA TOMADOS: sigue en ctrl+F: ref1


      /******** FIN LÓGICA SALDOS NORMALES Y PROGRESIVOS ********/


      /******* INICIO LÓGICA PERIODOS *******/

      // LOS PERIODOS SE COMPONEN DE 3 PARTES:
      // PERIODOS HISTÓRICOS + PERIODOS TRABAJADOS + PERIODO VIGENTE + PERIODOS FUTUROS (PARA VACACIONES NORMALES)

      let periodosNormales = [];
      let periodosProgresivos = [];

      // preparando array de saldos y periodos ya consumidos, para posteriormente restarlos 
      let bitacorasYaTomadas = [];

      vacacionesBitacoras.forEach(bitacora => {

        let cantidadEncontrada = bitacorasYaTomadas.filter((proceso) =>

          moment(proceso.Fecha_Inicio).format('YYYY-MM-DD') ===
          moment(bitacora.Fecha_Inicio).format('YYYY-MM-DD') &&
          moment(proceso.Fecha_Fin).format('YYYY-MM-DD') ===
          moment(bitacora.Fecha_Fin).format('YYYY-MM-DD') &&
          proceso.TipoRegistroBitacoraTrabajador.toString() ===
          bitacora.TipoRegistroBitacoraTrabajador.toString(),
        );

        if (cantidadEncontrada.length === 0) {
          bitacorasYaTomadas.push(bitacora);
        }
      })

      let periodosYaTomados = [];

      // saldos consumidos en bitácora
      bitacorasYaTomadas.forEach(bitacora => {

        bitacora.FechasTrabajadasVacaciones.forEach(infoFecha => {
          periodosYaTomados.push(infoFecha);
        });
      });

      //saldos consumidos en bonificaciones: descontando al periodo y al saldo
      let bonificacionesDocs = await this.props.obtenerBonificacionesTrabajador(idTrabajador);

      bonificacionesDocs.forEach(docBonificacion => {

        docBonificacion.FechasTrabajadas.forEach(periodo => {

          if (periodo.tipoDias == 'Normales') normalesTomadas += periodo.diasNumberConsumidos;
          if (periodo.tipoDias == 'Progresivos') progresivasTomadas += periodo.diasNumberConsumidos;

          periodosYaTomados.push(periodo);
        });
      });

      // PERIODOS HISTÓRICOS
      if (historicas.length > 0) {

        historicas.forEach(periodoHistorico => {

          let inicioUnix = periodoHistorico.FechaInicioUnix;
          let finUnix = periodoHistorico.FechaFinUnix;

          // VALIDACIÓN: restar saldo ya utilizado a periodo: y si queda en cero no se agrega.
          let periodosUsados = periodosYaTomados.filter((periodo) => periodo.inicioUnix === inicioUnix && periodo.finUnix === finUnix && periodo.tipoDias === periodoHistorico.TipoDeDias);
          let saldoResto = periodoHistorico.CantidadDiasHabiles;
          let agregarFlag = true;

          if (periodosUsados.length > 0) {

            periodosUsados.forEach(periodo => {
              saldoResto -= periodo.diasNumberConsumidos;
            })

            if (saldoResto > 1) {
              periodoHistorico.CantidadDiasHabiles = saldoResto;
            } else {
              agregarFlag = false;
            }
          }

          let objPeriodo = {
            inicio: moment.unix(periodoHistorico.FechaInicioUnix),
            fin: moment.unix(periodoHistorico.FechaFinUnix),

            inicioUnix: periodoHistorico.FechaInicioUnix,
            finUnix: periodoHistorico.FechaFinUnix,

            saldo: periodoHistorico.CantidadDiasHabiles,
          }

          if (agregarFlag) {
            if (periodoHistorico.TipoDeDias === 'Normales') periodosNormales.push(objPeriodo);
            if (periodoHistorico.TipoDeDias === 'Progresivos') periodosProgresivos.push(objPeriodo);
          }
        })
      }

      //PERIODOS TRABAJADOS NORMALES + EL ÚLTIMO PERIODO ES EL VIGENTE
      let fechaActual = moment();
      let cantidadAniosTrabajados = fechaActual.diff(fechaInicioCalculoTrabajo, 'years');
      cantidadAniosTrabajados++;

      let inicioPeriodosFuturos;

      for (let index = 0; index < cantidadAniosTrabajados; index++) {

        let fechaInicioPeriodo = fechaInicioCalculoTrabajo.clone().add(index, 'years');
        let fechaFinPeriodo = fechaInicioCalculoTrabajo.clone().add(index + 1, 'years').subtract(1, 'days');

        let inicioUnix = fechaInicioPeriodo.unix();
        let finUnix = fechaFinPeriodo.unix();

        let saldoNormal;
        let esPeriodoVigente = false;

        // si es el último, es el periodo vigente, el cual tiene saldo según meses trabajados
        // si no, es un año ya trabajado, por lo que tiene todo el saldo de su régimen
        if (index === cantidadAniosTrabajados - 1) {

          inicioPeriodosFuturos = fechaInicioPeriodo.clone().add(1, 'year');

          let mesesTrabajadosEnPeriodoVigente = fechaActual.diff(fechaInicioPeriodo, 'months');
          let saldoDeVigente = Math.trunc(cantidadPorMes * mesesTrabajadosEnPeriodoVigente);

          saldoNormal = saldoDeVigente;
          esPeriodoVigente = true;

        } else {
          saldoNormal = cantidadRegimenVacaciones;
        }

        // VALIDACIÓN: restar saldo ya utilizado a periodo: y si queda en cero no se agrega.
        let periodosUsados = periodosYaTomados.filter((periodo) => periodo.inicioUnix === inicioUnix && periodo.finUnix === finUnix && periodo.tipoDias === 'Normales');
        let saldoResto = saldoNormal;

        if (periodosUsados.length > 0) {

          periodosUsados.forEach(periodo => {
            saldoResto -= periodo.diasNumberConsumidos;
          })

          if (saldoResto > 1) {
            saldoNormal = saldoResto;
          } else {
            continue;
          }
        }

        let nuevoPeriodo = {

          inicio: fechaInicioPeriodo,
          fin: fechaFinPeriodo,
          inicioUnix,
          finUnix,

          saldo: saldoNormal,
          esPeriodoVigente,
        };


        // si la fecha inicio y fin de acá están en un periodo de los periodosYaTomados = [],
        // entonces:
        //  restar saldo consumido al de acá y después
        //  si es saldo es 0, no agregar (o agregar con saldo 0)

        periodosNormales.push(nuevoPeriodo);
      }

      //PERIODOS FUTUROS (APLICAN SÓLO A VACACIONES NORMALES)
      let cantidadPeriodosFuturos = 10;

      for (let index = 0; index < cantidadPeriodosFuturos; index++) {

        let fechaInicioPeriodo = inicioPeriodosFuturos.clone().add(index, 'year');
        let fechaFinPeriodo = inicioPeriodosFuturos.clone().add(index + 1, 'years');

        let inicioUnix = fechaInicioPeriodo.unix();
        let finUnix = fechaFinPeriodo.unix();

        // VALIDACIÓN: restar saldo ya utilizado a periodo: y si queda en cero no se agrega.
        let periodosUsados = periodosYaTomados.filter((periodo) => periodo.inicioUnix === inicioUnix && periodo.finUnix === finUnix && periodo.tipoDias === 'Normales');
        let saldoResto = cantidadRegimenVacaciones;
        let saldoPeriodo;

        if (periodosUsados.length > 0) {

          periodosUsados.forEach(periodo => {
            saldoResto -= periodo.diasNumberConsumidos;
          })

          if (saldoResto > 1) {
            saldoPeriodo = saldoResto;
          } else {
            continue;
          }
        } else {
          saldoPeriodo = cantidadRegimenVacaciones;
        }

        let nuevoPeriodo = {

          inicio: fechaInicioPeriodo,
          fin: fechaFinPeriodo,
          inicioUnix,
          finUnix,

          saldo: saldoPeriodo,
          isFuturo: true,
        };

        periodosNormales.push(nuevoPeriodo);
      }

      //PERIODOS TRABAJADOS PROGRESIVOS
      let fechaInicioCalculoProgresivos;
      let fechaContratoClone2 = fechaContrato.clone();

      // Si ya cumplió 120 meses con empladores anteriores, simplemente calcular a partir de la fecha de contrato,
      // si no, utilizar la fecha del inicio del periodo en el que cumplió los 120 meses
      if (trabajador.Meses_Empleadores_Anteriores >= 120) {

        fechaInicioCalculoProgresivos = fechaContratoClone2;

      } else {

        let cantidadMesesFaltantes = 120 - trabajador.Meses_Empleadores_Anteriores;
        let fechaCumplio120Meses = fechaContratoClone2.add(cantidadMesesFaltantes, 'months');

        let fechaContratoClone3 = fechaContrato.clone();
        let ctdadPeriodosARevisar = fechaActual.diff(fechaContratoClone3, 'years');

        for (let index = 0; index < ctdadPeriodosARevisar; index++) {

          let fechaInicio = fechaContratoClone3.clone().add(index, 'years');
          let fechaFin = fechaContratoClone3.clone().add(index + 1, 'years');

          if (fechaCumplio120Meses.isBetween(fechaInicio, fechaFin)) {

            fechaInicioCalculoProgresivos = fechaInicio.add(1, 'year');
          }

        }

      }

      let cantidadAniosProgresivos = fechaActual.diff(fechaInicioCalculoProgresivos, 'years')

      for (let index = 0; index < cantidadAniosProgresivos; index++) {

        if (index >= 2) {

          // cantidadDiasProgresivos siempre saca la cantidad de días que debe sumar
          // para el periodo
          let cantidadDiasProgresivos = Math.trunc((index + 1) / 3);

          let fechaInicioPeriodo = fechaInicioCalculoProgresivos.clone().add(index, 'years');
          let fechaFinPeriodo = fechaInicioCalculoProgresivos.clone().add(index + 1, 'years').subtract(1, 'day');

          let inicioUnix = fechaInicioPeriodo.unix();
          let finUnix = fechaFinPeriodo.unix();

          let agregarFlag = false;

          if (fechaInicioPeriodo.clone().add(1, 'year').isAfter(trabajador.Fecha_Progresivas) &&
            fechaInicioPeriodo.clone().add(1, 'year').isAfter(fechaInicioCalculoTrabajo)) {

            progresivosTrabajados += cantidadDiasProgresivos;
            agregarFlag = true;
          }

          // VALIDACIÓN: restar saldo ya utilizado a periodo: y si queda en cero no se agrega.
          let periodosUsados = periodosYaTomados.filter((periodo) => periodo.inicioUnix === inicioUnix && periodo.finUnix === finUnix && periodo.tipoDias === 'Progresivos');
          let saldoResto = cantidadDiasProgresivos;

          if (periodosUsados.length > 0) {

            periodosUsados.forEach(periodo => {
              saldoResto -= periodo.diasNumberConsumidos;
            })

            if (saldoResto > 1) {
              cantidadDiasProgresivos = saldoResto;
            } else {
              continue;
            }
          }

          let nuevoPeriodo = {

            inicio: fechaInicioPeriodo,
            fin: fechaFinPeriodo,
            inicioUnix,
            finUnix,

            saldo: cantidadDiasProgresivos,
          };

          // ENTRAN SÓLO LOS PERIODOS QUE ESTÉN DESDE EL CUAL EL TRABAJADOR PIDIÓ PROGRESIVAS
          // && QUE SON DESPUÉS DE LOS HISTÓRICOS
          if (agregarFlag) {
            periodosProgresivos.push(nuevoPeriodo);
          }
        }
      }

      //ctrl+F: ref1
      let resultadoNormales = (saldoHistoricoNormales + saldoTrabajados) - normalesTomadas;
      let resultadoProgresivos = (saldoHistoricoProgresivos + progresivosTrabajados) - progresivasTomadas;

      this.formRef.current.setFieldsValue({

        cantidadTomadas: 1,

        DisponiblesVacaciones: resultadoNormales,
        DisponiblesProgresivos: resultadoProgresivos,
        esBonificacion: false,
      })

      this.setState({
        periodosNormales,
        periodosProgresivos,
        vacacionesBitacoras,
        disabledRadios: false,
        disabledInputs: true,
      })

      /******* FIN LÓGICA PERIODOS *******/
    }
  };

  handleChangeRadios = async (e) => {

    let val = e.target.value;

    let { cantidadTomadas } = this.formRefVacaciones.current.getFieldsValue(['cantidadTomadas']);

    console.log('cantidadTomadas', cantidadTomadas);

    let periodosDeSelect;

    if (val === 'Normales') {

      this.formRefVacaciones.current.setFieldsValue({
        FeriadoLegalVacaciones: cantidadTomadas,
        FeriadoLegalProgresivos: 0
      });

      periodosDeSelect = this.state.periodosNormales;

    } else if (val === 'Progresivos') {

      this.formRefVacaciones.current.setFieldsValue({
        FeriadoLegalProgresivos: cantidadTomadas,
        FeriadoLegalVacaciones: 0,
      });

      periodosDeSelect = this.state.periodosProgresivos;
    }

    console.log('periodosDeSelect', periodosDeSelect);

    await this.setState({
      periodosCondicional: periodosDeSelect,
      disabledInputs: false,
    })

    this.actualizarPeriodos();
  }

  actualizarPeriodos = () => {

    // periodosCondicional varía: se convierte en periodosNormales o en periodosProgresivos
    // dependiendo del valor del RadioButton
    let { periodosCondicional } = this.state;

    if (periodosCondicional.length > 0) {

      let { cantidadTomadas } = this.formRefVacaciones.current.getFieldsValue(['cantidadTomadas']);

      let periodosTomados = [];

      let diasPorPeriodo = {};
      let diasNumberPorPeriodo = {};
      let periodosAdelantados = [];

      //Acá se va consumiendo el saldo de cada periodo
      for (let i = 0; i < periodosCondicional.length; i++) {

        let saldoConsumidoDePeriodo;

        let trabajador = this.props.trabajadores.find(x => x._id === this.props.Trabajador);
        let regimenVacaciones = trabajador.Regimen_Vacaciones;
        // si es el periodo vigente se considera la posibilidad de que se consuman vacaciones
        // adelantadas desde aquí en adelante
        if (periodosCondicional[i].esPeriodoVigente) {

          if (cantidadTomadas > periodosCondicional[i].saldo) {

            periodosAdelantados.push(i);

            if (cantidadTomadas > regimenVacaciones) {
              saldoConsumidoDePeriodo = regimenVacaciones;
            } else {
              saldoConsumidoDePeriodo = cantidadTomadas;
            }

            //en el caso de que todavía no pase el régimen, restarle a la cantidad tomada, sino, restale a régimen.
            let excesoEnVigente = cantidadTomadas <= regimenVacaciones ? cantidadTomadas - periodosCondicional[i].saldo : regimenVacaciones - periodosCondicional[i].saldo;
            cantidadTomadas -= periodosCondicional[i].saldo + excesoEnVigente;

          } else {

            saldoConsumidoDePeriodo = cantidadTomadas;
            cantidadTomadas -= regimenVacaciones;
          }

        } else {

          //chequeando si es periodo futuro, para considerarlo como adelantado
          if (periodosCondicional[i].isFuturo) {
            periodosAdelantados.push(i);
          }

          if (cantidadTomadas > periodosCondicional[i].saldo) {

            saldoConsumidoDePeriodo = periodosCondicional[i].saldo;

          } else {
            saldoConsumidoDePeriodo = cantidadTomadas;
          }

          cantidadTomadas -= periodosCondicional[i].saldo;
        }

        periodosTomados.push(i);

        diasPorPeriodo[i] = `${saldoConsumidoDePeriodo} ${saldoConsumidoDePeriodo === 1 ? 'día' : 'días'}`;
        diasNumberPorPeriodo[i] = saldoConsumidoDePeriodo;

        if (cantidadTomadas < 1) {
          break;
        }
      }

      this.formRefVacaciones.current.setFieldsValue({
        FechasTrabajadas: periodosTomados,
      })

      this.setState({
        diasPorPeriodo,
        diasNumberPorPeriodo,
        periodosAdelantados
      })

      // validar si es el vigente y si es progresivo, restringir adelantadas
    }
  }

  handleObtenerFechaFin = (fechaInicio, cantidadDias) => {
    const { fechaInicioLicencia } = this.formRefLicenciaMutual.current.getFieldsValue('fechaInicioLicencia');
    const { cantidadDiasLicencia } = this.formRefLicenciaMutual.current.getFieldsValue(['cantidadDiasLicencia']);

    let fInicio = fechaInicio ? fechaInicio : fechaInicioLicencia;
    let cDias = cantidadDias ? parseInt(cantidadDias) : parseInt(cantidadDiasLicencia);

    if (!isNaN(cDias) && cDias > 0 && fInicio !== undefined && fInicio !== null) {
      let fechaFin = moment(fInicio).add(cDias, 'days').subtract(1, 'day');
      this.formRefLicenciaMutual.current.setFieldsValue({ fechaFinLicencia: fechaFin })
    } else {
      this.formRefLicenciaMutual.current.setFieldsValue({ fechaFinLicencia: null })
    }
  }

  handleFechaInicio = (fechaInicio, cantidadDias) => {
    this.handleObtenerFechaFin(fechaInicio, cantidadDias);
  }


  //////////////////////////////////////////////////////////////////////

  mostrarFormulario = () => {
    switch (this.state.numeroFormularioMostrar) {
      case -1:
        return <></>;
      case 0:
        return <></>;

      // Día Ausente
      case 1:
        return (
          <>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ display: "inherit", marginTop: 50 }}
            >
              <img src={ausente} alt="cama arreglada para descansar" />
              <h1 className="formDescanso_card_title">Generar Día Ausente</h1>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ fontFamily: "MavenPro-Bold" }}
            >
              <Form
                {...formItemLayout}
                onFinish={this.ingresarDiaAusente}
                onFinishFailed={this.onFinishFailed}
                name="ausencia_trabajador"
                ref={this.formRefAusente}
              >
                {/* Fechas */}
                <Form.Item
                  name="Fechas"
                  label="Días de ausencia"
                  className="inputsFormDescanso_Text"
                >
                  {/* <Fecha
                    // obtenerFecha={this.handleFechas}
                    //fechasBloqueadas={this.state.fechasBloqueadas}
                    fechasBloqueadas={[]}
                  /> */}
                  <RangePicker locale={LocaleES} format="YYYY-MM-DD HH:mm" />
                </Form.Item>

                {/* Descripción */}
                <Form.Item
                  name="Descripcion"
                  label="Motivo"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <TextArea rows={3} placeholder="(Opcional)" />
                </Form.Item>

                {/* /////////////////////////////////////////////////// */}

                {/* Flag Correo */}
                <Form.Item
                  name="Correo"
                  label="Enviar mail de notificación"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: true,
                      message: "¿Desaea enviar el correo?",
                    },
                  ]}
                >
                  <Radio.Group
                  // onChange={({ target: { value } }) =>
                  //   this.props.enviarCorreo(value)
                  // }
                  >
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginTop: "24px", textAlign: "right" }}
            >
              <Button
                style={{
                  marginRight: "15px",
                  marginLeft: "auto",
                  display: "block",
                }}
                type="primary"
                htmlType="submit"
                form="ausencia_trabajador"
                className={
                  !this.state.Editar ? "guardar-form-disabled" : "guardar-form"
                }
                disabled={!this.state.Editar}
              >
                Guardar
              </Button>
            </Col>
          </>
        );

      // Permiso
      case 2:
        return (
          <>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ display: "inherit", marginTop: 50 }}
            >
              <img src={permiso} alt="cama arreglada para descansar" />
              <h1 className="formDescanso_card_title">Generar Permiso</h1>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginTop: "24px" }}
            >
              <Form
                {...formItemLayout}
                onFinish={this.ingresarPermiso}
                ref={this.formRefPermiso}
                name="permiso_trabajador"
              >
                {/* MOTIVO */}
                <Form.Item
                  name="Motivo"
                  label="Motivo"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: true,
                      message: "Obligatorio",
                    },
                  ]}
                >
                  <Select placeholder="Seleccionar">
                    <Option value="Trámites Personales">
                      Trámites Personales
                    </Option>
                    <Option value="Fallecimiento familiar directo">
                      Fallecimiento familiar directo
                    </Option>
                    <Option value="Nacimiento hijo">Nacimiento hijo</Option>
                    <Option value="Realización curso">Realización curso</Option>
                    <Option value="Otro">Otro</Option>
                  </Select>
                </Form.Item>

                {/* FECHAS */}
                <Form.Item
                  name="Fechas"
                  label="Inicio y término del permiso"
                  className="inputsFormDescanso_Text"
                >
                  {/* <Fecha
                    obtenerFecha={this.handleFechas}
                    fechasBloqueadas={[]}
                  /> */}
                  <RangePicker locale={LocaleES} format="YYYY-MM-DD HH:mm" />
                </Form.Item>

                <p
                  style={{
                    position: "relative",
                    left: "33.5%",
                    marginBottom: "6px",
                  }}
                >
                  <strong>Detalle días</strong>
                </p>

                {/* DÍAS HÁBILES */}
                <Form.Item
                  name="Habiles"
                  label="Días hábiles"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: true,
                      message: "Obligatorio",
                    },
                  ]}
                >
                  <InputNumber min={0} />
                </Form.Item>

                {/* DÍAS INHÁBILES */}
                <Form.Item
                  name="Inhabiles"
                  label="Días inhábiles"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: true,
                      message: "Obligatorio",
                    },
                  ]}
                >
                  <InputNumber min={0} />
                </Form.Item>

                {/* DÍAS TOTALES */}
                <Form.Item
                  name="Total_Dias"
                  label="Total días"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: true,
                      message: "Obligatorio",
                    },
                  ]}
                >
                  <InputNumber min={0} />
                </Form.Item>

                {/* TIPO DE PERMISO */}
                <Form.Item
                  name="Tipo_Permiso"
                  label="Tipo de permiso"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: true,
                      message: "Obligatorio",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="5f5a870f002d3205dc7f24f2">
                      Permiso con goce de sueldo
                    </Radio>
                    <Radio value="5f5a8746002d3205dc7f24f3">
                      Permiso sin goce de sueldo
                    </Radio>
                    <Radio value="5f5a8754002d3205dc7f24f4">
                      Permiso sindical con goce de sueldo
                    </Radio>
                    <Radio value="5f5a875d002d3205dc7f24f5">
                      Permiso sindical sin goce de sueldo
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                {/* /////////////////////////////////////////////////// */}

                {/* Flag Correo */}
                <Form.Item
                  name="Correo"
                  label="Enviar mail de notificación"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: true,
                      message: "Obligatorio",
                    },
                  ]}
                >
                  <Radio.Group
                  // onChange={({ target: { value } }) =>
                  // this.props.enviarCorreo(value)
                  // }
                  >
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>

                {/* /////////////////////////////////////////////////// */}
              </Form>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginTop: "24px", textAlign: "right" }}
            >
              <Button
                style={{
                  marginRight: "15px",
                  marginLeft: "auto",
                  display: "block",
                }}
                type="primary"
                htmlType="submit"
                form="permiso_trabajador"
                className={
                  !this.state.Editar ? "guardar-form-disabled" : "guardar-form"
                }
                disabled={!this.state.Editar}
              >
                Guardar
              </Button>
            </Col>
          </>
        );

      // En tránsito
      case 3:
        return (
          <>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ display: "inherit", marginTop: 50 }}
            >
              <img src={enTransito} alt="En Tránsito" />
              <h1 className="formDescanso_card_title">
                Generar Día En tránsito
              </h1>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ fontFamily: "MavenPro-Bold" }}
            >
              <Form
                {...formItemLayout}
                onFinish={this.ingresarTransito}
                ref={this.formRefEnTransito}
                name="transito_trabajador"
              >
                {/* Fechas */}
                <Form.Item
                  name="Fechas"
                  label="Días de tránsito"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: true,
                      message: "Obligatorio",
                    },
                  ]}
                >
                  {/* <Fecha
                    obtenerFecha={this.handleFechas}
                    fechasBloqueadas={[]}
                  /> */}
                  <RangePicker locale={LocaleES} format="YYYY-MM-DD HH:mm" />
                </Form.Item>

                {/* Genera Descanso? */}
                <Form.Item
                  name="Descanso"
                  label="¿Genera descanso?"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: true,
                      message: "Obligatorio",
                    },
                  ]}
                >
                  <Radio.Group
                  // onChange={({ target: { value } }) => this.props.enviarCorreo(value)}
                  >
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>

                {/* Flag Correo */}
                <Form.Item
                  name="Correo"
                  label="Enviar mail de notificación"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: true,
                      message: "Obligatorio",
                    },
                  ]}
                >
                  <Radio.Group
                  // onChange={({ target: { value } }) => this.props.enviarCorreo(value)}
                  // value={true}
                  >
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginTop: "24px", textAlign: "right" }}
            >
              <Button
                style={{
                  marginRight: "15px",
                  marginLeft: "auto",
                  display: "block",
                }}
                type="primary"
                htmlType="submit"
                form="transito_trabajador"
                className={
                  !this.state.Editar ? "guardar-form-disabled" : "guardar-form"
                }
                disabled={!this.state.Editar}
              >
                Guardar
              </Button>
            </Col>
          </>
        );

      // Licencia Médica
      case 4:
        return (
          <>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ display: "inherit", marginTop: 50 }}
            >
              <img src={licencia} alt="cama arreglada para descansar" />
              <h1 className="formDescanso_card_title">
                Generar Licencia Médica
              </h1>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ fontFamily: "MavenPro-Bold" }}
            >
              <Form
                {...formItemLayout}
                onFinish={this.ingresarLicencia}
                ref={this.formRefLicencia}
                name="licencia_trabajador"
              >
                <Form.Item
                  name="Tipo_Licencia"
                  label="¿Qué licencia es?"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: true,
                      message: "Obligatorio",
                    },
                  ]}
                >
                  <Radio.Group
                  // onChange={this.onChange}
                  // value={this.state.value}
                  >
                    <Radio value={"Nueva"}>Nueva</Radio>
                    <Radio value={"De continuidad"}>De continuidad</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="Fecha_Termino_Operacion"
                  label="Termino operación"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: true,
                      message: "Obligatorio",
                    },
                  ]}
                >
                  <DatePicker
                    locale={LocaleES}
                    //showTime={{ format: "HH:mm" }}
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>

                <Form.Item
                  name="Fechas"
                  label="Asignar Fechas"
                  className="inputsFormDescanso_Text"
                >
                  {/* <Fecha
                    obtenerFecha={this.handleFechas}
                    //fechasBloqueadas={this.state.fechasBloqueadas}
                    fechasBloqueadas={[]}
                  /> */}
                  <RangePicker locale={LocaleES} format="YYYY-MM-DD HH:mm" />
                </Form.Item>

                <Form.Item
                  name="Archivo"
                  label="Adjuntar archivo"
                  className="inputsFormDescanso_Text"
                // rules={[
                //   {
                //     required: true,
                //     message: "Obligatorio",
                //   },
                // ]}
                >
                  <Upload
                    showUploadList={true}
                    method="GET"
                    action="#"
                    value={null}
                    onChange={this.obtenerBase64}
                  //  beforeUpload={file => {
                  //     const reader = new FileReader();

                  //     reader.onload = e => {};
                  //     reader.readAsText(file);

                  //     // Prevent upload
                  //     return false;
                  // }}
                  //accept=".doc,.docx,application/msword"
                  >
                    <Button icon={<UploadOutlined />}>Subir archivo</Button>
                  </Upload>
                </Form.Item>

                {/* /////////////////////////////////////////////////// */}

                {/* Flag Correo */}
                <Form.Item
                  name="Correo"
                  label="Enviar mail de notificación"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: true,
                      message: "Obligatorio",
                    },
                  ]}
                >
                  <Radio.Group
                  // onChange={({ target: { value } }) => this.props.enviarCorreo(value)}
                  // value={true}
                  >
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>

                {/* /////////////////////////////////////////////////// */}
              </Form>
            </Col>

            <Col span={24} style={{ marginTop: "24px", textAlign: "right" }}>
              <Button
                style={{
                  marginRight: "15px",
                  marginLeft: "auto",
                  display: "block",
                }}
                type="primary"
                htmlType="submit"
                form="licencia_trabajador"
                className={
                  !this.state.Editar ? "guardar-form-disabled" : "guardar-form"
                }
                disabled={!this.state.Editar}
              >
                Guardar
              </Button>
            </Col>
          </>
        );

      // Licencia Mutual
      case 5:
        return (
          <>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ display: "inherit", marginTop: 50 }}
            >
              <img src={accidente} alt="cama arreglada para descansar" />
              <h1 className="formDescanso_card_title">
                Generar Licencia Mutualidad
              </h1>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ fontFamily: "MavenPro-Bold" }}
            >
              <Form
                {...formItemLayout}
                onFinish={this.ingresarMutual}
                ref={this.formRefLicenciaMutual}
                name="mutual_trabajador"
              >
                {/* FECHAS ACCIDENTE */}
                {/* <Form.Item
                  name="Fechas"
                  label="Días de ausencia"
                  className="inputsFormDescanso_Text"
                > */}
                {/* <Fecha
                    obtenerFecha={this.handleFechas}
                    //fechasBloqueadas={this.state.fechasBloqueadas}
                    fechasBloqueadas={[]}
                  /> */}
                {/* <RangePicker locale={LocaleES} format="YYYY-MM-DD HH:mm" />
                </Form.Item> */}

                {/* ¿TIENE FECHA FIN? */}
                {/* <Form.Item
                        name="tieneFechaFin"
                        label="¿Tiene fecha fin?"
                        className="inputsFormDescanso_Text"
                        initialValue={true}
                      >
                        <Radio.Group
                          style={{ display: "flex" }}
                          onChange={(value) => {
                            if (value.target.value === true) {
                              this.setState({ tieneFechaFin: true })
                            } else {
                              this.setState({ tieneFechaFin: false })
                            }
                          }}
                          defaultValue={true}
                        >
                          <Radio value={true}>Si</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item> */}

                {
                  this.state.tieneFechaFin === true ?
                    (<>

                      {/* FECHAS ACCIDENTE */}
                      <Form.Item
                        name="fechaInicioLicencia"
                        label="Inicio Licencia"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Debe indicar la fecha de inicio de la licencia",
                          },
                        ]}
                      >
                        {/* <Fechas
                                obtenerFecha={this.handleFechas}
                                // fechasBloqueadas={[]}
                              /> */}

                        <DatePicker
                          format={'YYYY-MM-DD'}
                          onChange={(value) => { this.handleObtenerFechaFin(value) }}
                          locale={LocaleES}
                          disabled={false}
                        // disabledDate={(dia) => {
                        // }}
                        />

                      </Form.Item>

                      {/* CANTIDAD DIAS LICENCIA */}
                      <Form.Item
                        name="cantidadDiasLicencia"
                        label="Cantidad días licencia"
                        className={"inputsFormDescanso_Text"}
                        onChange={(value) => { this.handleObtenerFechaFin('', value.target.value) }}
                        rules={[
                          {
                            required: true,
                            message: "Debe indicar la cantidad de días del periodo",
                          },
                        ]}
                        defaultValue={0}
                        value={0}
                        min={1}
                        max={100}
                      >
                        <Input
                          name="cantidadDias"
                          placeholder={0}
                          defaultValue={0}
                          value={0}
                        />
                      </Form.Item>

                      {/* FECHAS FIN ACCIDENTE */}
                      <Form.Item
                        name="fechaFinLicencia"
                        label="Fin Licencia"
                        className="inputsFormDescanso_Text"
                        disabled={true}
                      >
                        <DatePicker
                          format={'YYYY-MM-DD'}
                          locale={LocaleES}
                          disabled={true}
                          value={this.state.fechaFin}
                        />
                      </Form.Item>
                    </>)
                    :
                    (<>
                      <Form.Item
                        name="fechaInicioLicencia"
                        label="Inicio Licencia"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Debe indicar la fecha de inicio de la licencia",
                          },
                        ]}
                      >
                        {/* <Fecha
                                obtenerFecha={this.handleFechas}
                              // fechasBloqueadas={[]}
                              /> */}

                        <DatePicker
                          format={'YYYY-MM-DD'}
                          onChange={this.handleFechas}
                          locale={LocaleES}
                          disabled={false}
                        // disabledDate={(dia) => {
                        // }}
                        />

                      </Form.Item>
                    </>)
                }


                {/* MANIOBRA */}
                <Form.Item
                  name="Maniobra"
                  label="Maniobra que realizaba:"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: true,
                      message: "Obligatorio",
                    },
                  ]}
                >
                  <Input placeholder="Ingresar texto" />
                </Form.Item>

                {/* ARCHIVO */}
                <Form.Item
                  name="Archivo"
                  label="Adjuntar Archivo"
                  className="inputsFormDescanso_Text"
                // rules={[
                //   {
                //     required: true,
                //     message: "Obligatorio",
                //   },
                // ]}
                >
                  <Upload
                    showUploadList={true}
                    method="GET"
                    action="#"
                    onChange={this.obtenerBase64}
                  >
                    <Button icon={<UploadOutlined />}>Subir archivo</Button>
                  </Upload>
                </Form.Item>

                {/* /////////////////////////////////////////////////// */}

                {/* Flag Correo */}
                <Form.Item
                  name="Correo"
                  label="Enviar mail de notificación"
                  className="inputsFormDescanso_Text"
                  rules={[
                    {
                      required: true,
                      message: "Obligatorio",
                    },
                  ]}
                >
                  <Radio.Group
                  // onChange={({ target: { value } }) => this.props.enviarCorreo(value)}
                  // value={true}
                  >
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>

                {/* /////////////////////////////////////////////////// */}
              </Form>
            </Col>

            <Col span={24} style={{ marginTop: "24px", textAlign: "right" }}>
              <Button
                style={{
                  marginRight: "15px",
                  marginLeft: "auto",
                  display: "block",
                }}
                type="primary"
                htmlType="submit"
                form="mutual_trabajador"
                className={
                  !this.state.Editar ? "guardar-form-disabled" : "guardar-form"
                }
                disabled={!this.state.Editar}
              >
                Guardar
              </Button>
            </Col>
          </>
        );

      // Vacaciones
      case 6:

        return (
          <>
            <Row gutter={4}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginTop: "24px", textAlign: "right" }}
              >
                <p>A continuación del Check Out serás redirigido al módulo de Vacaciones para realizar el registro</p>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginTop: "24px", textAlign: "right" }}
              >
                <Button
                  style={{
                    marginRight: "15px",
                    marginLeft: "auto",
                    display: "block",
                  }}
                  type="primary"
                  onClick={() => {

                    this.ingresarVacaciones();

                  }}
                  className={"guardar-form"}
                >
                  Hacer CheckOut
                </Button>
              </Col>
            </Row>

          </>
        )

        ///////////////////////////////////

        this.inicioVacaciones()

        return (
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ display: "inherit", marginTop: 50 }}
            >
              <img src={vacaciones} alt="cama arreglada para descansar" />
              <h1 className="formVacaciones_card_title">Generar Vacaciones</h1>
            </Col>


            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ fontFamily: "MavenPro-Bold" }}
            >
              <Form
                {...formItemLayout}
                onFinish={this.ingresarVacaciones}
                ref={this.formRefVacaciones}
                name="vacaciones_trabajador"
              >

                {/* CANTIDAD DE DÍAS TOMADOS */}
                <Popover
                  title={'Información:'}
                  placement={'right'}
                  content={(() => {
                    let disponiblesNormales = undefined;
                    let disponiblesProgresivos = undefined;

                    if (disponiblesNormales != undefined && disponiblesProgresivos != undefined) {
                      return (
                        <div className={'popover-div'}>

                          <table className={'popover-table'}>
                            <tr>
                              <td className={'popover-td'}>
                                Días normales disponibles:
                              </td>
                              <th className={'popover-th'}>
                                {disponiblesNormales}
                              </th>
                            </tr>

                            <tr>
                              <td className={'popover-td'}>
                                Días progresivos disponibles:
                              </td>
                              <th className={'popover-th'}>
                                {disponiblesProgresivos}
                              </th>
                            </tr>
                          </table>

                        </div>
                      )
                    } else {
                      return (
                        <>
                          Seleccione a un trabajador para ver sus vacaciones disponibles.
                        </>
                      )
                    }

                  })()}
                >
                  <Form.Item
                    name='cantidadTomadas'
                    label='Cantidad de días hábiles a tomar'
                  >
                    <InputNumber
                      // disabled={disabledRadios}
                      // disabled={true}
                      // onChange={(val) => {

                      // if (val == null || val == 'null' || val == undefined || val == '') {
                      //     val = 1;
                      // }

                      // this.calcularFechasHabiles();

                      // let { TipoDias } = this.formRef.current.getFieldsValue(['TipoDias'])

                      // if (TipoDias === 'Normales') {
                      // this.formRef.current.setFieldsValue({
                      // FeriadoLegalVacaciones: val,
                      // });

                      // } else if (TipoDias === 'Progresivos') {
                      // this.formRef.current.setFieldsValue({
                      // FeriadoLegalProgresivos: val,
                      // });

                      // }

                      // this.actualizarPeriodos();
                      // }}
                      placeholder="Número"
                      min={1}

                      style={{ width: '100%' }} />
                  </Form.Item>
                </Popover>

                {/* TIPO DE DÍAS */}
                <Form.Item
                  name='TipoDias'
                  label="Son días"
                  className="inputsFormVacaciones_Text"
                  rules={[
                    {
                      required: true,
                      message: 'Obligatorio',
                    },
                  ]}
                >
                  <Radio.Group
                    // disabled={disabledRadios}
                    onChange={(e) => {
                      this.handleChangeRadios(e)
                    }}
                  >
                    <Radio value={'Normales'}>Normales</Radio>
                    <Radio
                      value={'Progresivos'}
                    // disabled={disabledProgresivas}
                    >
                      Progresivos
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                {/* ¿ES BONIFICACIÓN? */}
                <Form.Item
                  name='esBonificacion'
                  label="¿Es bonificación?"
                  className="inputsFormVacaciones_Text"
                  rules={[
                    {
                      required: true,
                      message: 'Obligatorio',
                    },
                  ]}
                >
                  <Radio.Group
                  // disabled={disabledRadios}
                  // onChange={(e) => {

                  //   let val = e.target.value;

                  //   this.setState({
                  //     isCompensacion: val,
                  //     // disabledInputs: val,
                  //   })
                  // }}
                  >
                    <Radio value={true}>Sí</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>

                {/* MONTO BONIFICACIÓN */}
                {(() => {
                  if (this.state.isCompensacion) {
                    return (
                      <Form.Item
                        name='montoBonificacion'
                        label='Monto de bonificación ($)'
                        rules={[
                          {
                            required: true,
                            message: 'Obligatorio',
                          },
                        ]}
                      >
                        <InputNumber
                          // disabled={disabledRadios}
                          placeholder="Monto"
                          min={1}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    )
                  }
                })()}

                {/* FECHAS USO DE VACACIONES */}
                {(() => {
                  if (!this.state.isCompensacion) {

                    let style = { marginBottom: '0px', marginTop: '5px', color: 'black' }

                    return (
                      <Form.Item
                        name="FechasEfectivas"
                        label='Días Efectivos en que se hará uso del Feriado Legal'
                        labelCol={{ span: 24 }}
                        className="inputsFormVacaciones_Text"
                        rules={[
                          {
                            required: true,
                            message: 'Obligatorio',
                          },
                        ]}
                        extra={
                          this.state.fechasEfectivas
                            ? <Tooltip
                              placement={'bottom'}
                              title={'Las fechas se calculan según la cantidad de días ingresada, sin contar días inhábiles y feriados.'}
                            >
                              <p style={style}>
                                (desde {moment(this.state.fechasEfectivas[0]).format('DD-MM-YYYY')} hasta {moment(this.state.fechasEfectivas[1]).format('DD-MM-YYYY')})
                              </p>
                            </Tooltip>
                            : null
                        }
                      >
                        <DatePicker
                          locale={locale}
                        // disabled={disabledInputs}
                        // onChange={this.calcularFechasHabiles}
                        />
                      </Form.Item>
                    )
                  }
                })()}

                {/* FECHAS PERIODO(S) */}
                <Form.Item
                  name="FechasTrabajadas"
                  label="Periodo trabajado al cual corresponden las vacaciones"
                  labelCol={{ span: 24 }}
                  className="inputsFormVacaciones_Text"
                  rules={[
                    {
                      required: true,
                      message: 'Obligatorio',
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                  // disabled={true}
                  >
                    {this.state.periodosCondicional.map(({ inicio, fin }, index) => {

                      let { diasPorPeriodo, periodosAdelantados } = this.state;

                      let textClass = '';

                      if (periodosAdelantados) {
                        textClass = periodosAdelantados.includes(index) ? 'periodo-adelantado-text' : '';
                      }

                      return (
                        <Option
                          key={index}
                          value={index}
                        >
                          <span className={textClass}>
                            {inicio.format('DD-MM-YYYY')} - {fin.format('DD-MM-YYYY')} ({diasPorPeriodo ? diasPorPeriodo[index] : ''})
                          </span>

                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>

                {/* TEXTOS */}
                <Row className="inputsFormVacaciones_Text" style={{ textAlign: 'center' }}>

                  <Col span={6}>
                    <p></p>
                  </Col >

                  <Col span={6}>
                    <p>Vacaciones </p>
                  </Col >

                  <Col span={6}>
                    <p> Progresivos  </p>
                  </Col>

                  <Col span={6}>
                    <p>Total </p>
                  </Col>

                </Row>

                {/* SALDOS DISPONIBLES */}
                <Row className="inputsFormVacaciones_Text">

                  <Col span={6} style={{ paddingRight: '10px' }}>
                    <p>Saldo días disponible</p>
                  </Col >

                  <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                    <Form.Item
                      name='DisponiblesVacaciones'
                      rules={[
                        {
                          required: false, /////////////////////////////// CAMBIAR A TRUE
                          message: 'Obligatorio',
                        },
                      ]}>
                      <InputNumber
                        placeholder="Número"
                        // min={0}
                        style={{ width: '100%' }}
                        // disabled={disabledInputs}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col >

                  <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                    <Form.Item
                      name='DisponiblesProgresivos'
                      rules={[
                        {
                          required: false, /////////////////////////////// CAMBIAR A TRUE
                          message: 'Obligatorio',
                        },
                      ]}>
                      <InputNumber
                        placeholder="Número"
                        // min={0}
                        style={{ width: '100%' }}
                        // disabled={disabledInputs ? disabledInputs : disabledProgresivas}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                    <Form.Item
                      name='DisponiblesTotal'
                      rules={[
                        {
                          required: false, /////////////////////////////// CAMBIAR A TRUE
                          message: 'Obligatorio',
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Número"
                        // min={0}
                        style={{ width: '100%' }}
                        // disabled={disabledInputs}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>

                </Row>

                {/* DÍAS TOMADOS */}
                <Row className="inputsFormVacaciones_Text">

                  <Col span={6} style={{ paddingRight: '10px' }}>
                    <p>Días hábiles tomados</p>
                  </Col >

                  <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                    <Form.Item name='FeriadoLegalVacaciones'
                      rules={[
                        {
                          required: false, /////////////////////////////// CAMBIAR A TRUE
                          message: 'Obligatorio',
                        },
                      ]}>
                      <InputNumber
                        // disabled={disabledInputs}
                        disabled={true}
                        placeholder="Número"
                        // min={0}
                        style={{ width: '100%' }} />
                    </Form.Item>
                  </Col >

                  <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                    <Form.Item name='FeriadoLegalProgresivos'
                      rules={[
                        {
                          required: false, /////////////////////////////// CAMBIAR A TRUE
                          message: 'Obligatorio',
                        },
                      ]}>
                      <InputNumber
                        // disabled={disabledInputs ? disabledInputs : disabledProgresivas}
                        disabled={true}
                        placeholder="Número"
                        // min={0}
                        style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>

                  <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                    <Form.Item name='FeriadoLegalTotal'
                      rules={[
                        {
                          required: false, /////////////////////////////// CAMBIAR A TRUE
                          message: 'Obligatorio',
                        },
                      ]}>
                      <InputNumber
                        // disabled={disabledInputs}
                        disabled={true}
                        placeholder="Número"
                        // min={0}
                        style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>

                </Row>

                {/* SALDOS RESTANTE */}
                <Row className="inputsFormVacaciones_Text">

                  <Col span={6} style={{ paddingRight: '10px' }}>
                    <p>Saldo Días</p>
                  </Col >

                  <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                    <Form.Item name='SaldoVacaciones'
                      rules={[
                        {
                          required: false, /////////////////////////////// CAMBIAR A TRUE
                          message: 'Obligatorio',
                        },
                      ]} >
                      <InputNumber
                        // disabled={disabledInputs} 
                        disabled={true}
                        placeholder="Número"
                        // min={0}
                        style={{ width: '100%' }} />
                    </Form.Item>
                  </Col >

                  <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                    <Form.Item name='SaldoProgresivos'
                      rules={[
                        {
                          required: false, /////////////////////////////// CAMBIAR A TRUE
                          message: 'Obligatorio',
                        },
                      ]}>
                      <InputNumber
                        // disabled={disabledInputs ? disabledInputs : disabledProgresivas}
                        disabled={true}
                        placeholder="Número"
                        // min={0}
                        style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>

                  <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                    <Form.Item name='SaldoTotal'
                      rules={[
                        {
                          required: false, /////////////////////////////// CAMBIAR A TRUE
                          message: 'Obligatorio',
                        },
                      ]}>
                      <InputNumber
                        // disabled={disabledInputs}
                        disabled={true}
                        placeholder="Número"
                        // min={0}
                        style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>

                </Row>

                {/* Flag Correo */}
                <Form.Item
                  name="Correo"
                  label="Enviar mail de notificación"
                  className="inputsFormVacaciones_Text"
                  rules={[
                    {
                      required: true,
                      message: 'Obligatorio',
                    },
                  ]}
                >
                  <Radio.Group
                    // onChange={({ target: { value } }) => this.props.enviarCorreo(value)}
                    value={true}
                  // disabled={disabledInputs}
                  >
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>

                </Form.Item>

              </Form>
            </Col>

            <Col span={24} style={{ marginTop: "24px", textAlign: "right" }}>
              <Button
                style={{
                  marginRight: "15px",
                  marginLeft: "auto",
                  display: "block",
                }}
                type="primary"
                htmlType="submit"
                form="vacaciones_trabajador"
                className={
                  !this.state.Editar ? "guardar-form-disabled" : "guardar-form"
                }
                disabled={!this.state.Editar}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        );

      // Descansos
      case 7:

        return (
          <>
            <Row gutter={4}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginTop: "24px", textAlign: "right" }}
              >
                <p>A continuación del Check Out serás redirigido al módulo de Descanso para realizar el registro</p>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginTop: "24px", textAlign: "right" }}
              >
                <Button
                  style={{
                    marginRight: "15px",
                    marginLeft: "auto",
                    display: "block",
                  }}
                  type="primary"
                  onClick={() => {

                    this.ingresarDescansos();

                  }}
                  className={"guardar-form"}
                >
                  Hacer CheckOut
                </Button>
              </Col>
            </Row>

          </>
        )

      default:
        return <h1>No se encontró el formulario</h1>;
    }
  };

  onFinishCheckoutMasivo = async () => {

    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    )
    {
      usuario = JSON.parse(usuario);
      let idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      const { trabajadoresCheckoutMasivo } = this.state;

      if(trabajadoresCheckoutMasivo.length > 0){

        const { idCentroCosto } = this.state;

        const arregloPersonas = [];

        trabajadoresCheckoutMasivo.forEach(trabajador => {
          let objBusqueda = {
            fecha_inicio: trabajador.Inicio_Planificacion,
            fecha_fin: trabajador.Fin_Planificacion,   
            idTrabajador: trabajador.IdTrabajador,
            idPlaza: trabajador.IdPlaza,
            idPuestoPlaza: trabajador.IdPuestoPlaza,
            IdFaena: trabajador.IdFaena,
            SeEnvioCheckOut: true,
            idBitacoraCheckout: trabajador._id,
          }
          arregloPersonas.push(objBusqueda);
        });

        this.props.GenerarCheckOutMultiple(
          arregloPersonas, 
          idEmpresa,
          idCentroCosto,
          this.state.fechaBusquedaInicio,
          this.state.fechaBusquedaFin,
          this.state.fechaBusquedaInicioUnix,
          this.state.fechaBusquedaFinUnix
        );

      } else {
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "Debe seleccionar al menos un trabajador para realizar check-out",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }

    }
  }

  render() {
    let { modalCheckoutMultiple } = this.state;
    return (
      <Layout style={{ height: "100%" }}>
        <Loading cargando={this.props.loading} />

        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Check-out"
            subtitulo={
              <div className={"header-subtitle-responsive"}>
                Selecciona a tus trabajadores y genera su checkout asignandoles
                un nuevo estado.
              </div>
            }
          />
        </Header>

        <hr className="division" />

        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <p style={{ fontFamily: "MavenPro-Regular", color: "black" }}>
            Filtrar
          </p>

          <Row align="bottom" gutter={20}>
            <Col span={24}></Col>

            {/* CC */}
            <Col
              xs={24}
              sm={8}
              md={7}
              lg={7}
              xl={5}
              className="div-input-search"
            >
              <Select
                style={{ width: 190 }}
                placeholder="Centros de costo"
                onSelect={(value, data) => {
                  if (data.value) {
                    this.setState({
                      idCentroCosto: value,
                    });
                  }
                }}
                className={"responsive-filters"}
              >
                {this.props.centroCostos.map((centroDeCostos) => (
                  <Option key={centroDeCostos._id}>
                    {centroDeCostos.Nombre}
                  </Option>
                ))}
              </Select>
            </Col>

            {/* RANGEPICKER */}
            <Col xs={24} sm={10} md={8} lg={8} xl={6}>
              <RangePicker
                locale={locale}
                format={"DD-MM-YYYY"}
                onChange={(date) => {
                  if (date != null) {

                    let fechaBusquedaI = convertirFechaString(date[0], 1).fechaString;
                    let fechaBusquedaF = convertirFechaString(date[1], 2).fechaString;

                    this.setState({
                      // fechaBusquedaInicio: momentToStringFechaInicio(date[0], databaseFormat),
                      // fechaBusquedaFin: momentToStringFechaFin(date[1], databaseFormat),

                      fechaBusquedaInicio: fechaBusquedaI,
                      fechaBusquedaFin: fechaBusquedaF,

                      // fechaBusquedaInicioUnix: momentToUnixFechaInicio(date[0]),
                      // fechaBusquedaFinUnix: momentToUnixFechaFin(date[1]),

                      fechaBusquedaInicioUnix: convertirFechaString(date[0], 1).fechaUNIX,
                      fechaBusquedaFinUnix: convertirFechaString(date[1], 2).fechaUNIX,

                      // fechaBusquedaInicioChile: momentToStringFechaInicio(date[0],chileFormat),
                      // fechaBusquedaFinChile: momentToStringFechaFin(date[1],chileFormat),

                      fechaBusquedaInicioChile: convertirFechaString(moment.utc(fechaBusquedaI), 1).fechaDDMMYYYY,
                      fechaBusquedaFinChile: convertirFechaString(moment.utc(fechaBusquedaF), 2).fechaDDMMYYYY,
                    });
                  } else {
                    this.setState({
                      fechaBusquedaInicio: "",
                      fechaBusquedaFin: "",
                      fechaBusquedaInicioUnix: null,
                      fechaBusquedaFinUnix: null,
                    });
                  }
                }}
                style={{ width: 230 }}
                className={"responsive-filters"}
              />
            </Col>

            {/* FILTRAR BUTTON */}
            <Col
              xs={24}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              className={"filter-btn-col"}
            >
              <Button
                onClick={() => {
                  let usuario = localStorage.getItem("usuario-people-manage");

                  if (
                    usuario !== null &&
                    usuario !== "" &&
                    usuario !== undefined &&
                    usuario !== "undefined"
                  ) {
                    usuario = JSON.parse(usuario);

                    let idCentroCosto = this.state.idCentroCosto;

                    let fechaInicio = this.state.fechaBusquedaInicio;
                    let fechaFin = this.state.fechaBusquedaFin;
                    let inicioUnix = this.state.fechaBusquedaInicioUnix;
                    let finUnix = this.state.fechaBusquedaFinUnix;

                    this.trabajadoresFiltrados(
                      idCentroCosto,
                      fechaInicio,
                      fechaFin,
                      usuario.EmpresaSeleccionada.Empresa._id,
                      inicioUnix,
                      finUnix
                    );
                  }
                }}
                className="filtrar-check-btn"
                type="primary"
              >
                Filtrar
              </Button>
            </Col>

            <Col xs={24} sm={4} md={4} lg={4} xl={4}
              className={'filter-btn-col'}
              style={{display: 'flex', justifyContent: 'flex-end'}}
            >

              <Button
                onClick={() => {
                  this.abrirCheckoutMultiple();
                }}
                className="checkout-masivo-btn"
              >
                Check-Out Masivo
              </Button>

            </Col>

           
          </Row>

          <p
            style={{
              fontFamily: "MavenPro-Regular",
              marginTop: "30px",
            }}
          >
            {`Viendo resultados de las fechas: 
              ${this.state.fechaBusquedaInicioChile ? `${this.state.fechaBusquedaInicioChile} - ${this.state.fechaBusquedaFinChile}` : `${convertirFechaString(moment.utc(convertirFechaString(moment(), 1).fechaString), 1).fechaDDMMYYYY}`}
            `}
          </p>

          {this.props.checkOut.length === 0 ? (
            <Empty
              imageStyle={{ height: 200, marginTop: "20px" }}
              description={
                <h2 className={"seleccion-trabajador-disponible"}>
                  <b>
                    No se han encontrado trabajadores disponibles para hacer
                    Check-Out.
                  </b>
                  <br />
                  <b>
                    Intenta buscar por otro rango de fechas o centro de costos.
                  </b>
                </h2>
              }
            ></Empty>
          ) : (
            <>
              <Row
                style={{ marginTop: 20, marginBottom: "14px" }}
                align="middle"
              >
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                  {this.props.checkOut.length === 0 ? (
                    ""
                  ) : (
                    <h3
                      style={{
                        fontFamily: "MavenPro-Bold",
                        color: "#061FCB",
                        marginTop: "30px",
                      }}
                    >
                      Trabajadores por finalizar
                    </h3>
                  )}
                </Col>
              </Row>

              <Row gutter={16}>
                {this.props.checkOut.map((trabajador, index) => (
                  <Col key={index} xs={24} sm={12} md={12} lg={12} xl={6}>
                    <Card
                      style={{
                        borderRadius: "16px",
                        backgroundColor: "white",
                        marginBottom: "14px",
                      }}
                      className="dashboard"
                    >
                      <Row className={"card-content-row"}>
                        <Col
                          xs={5}
                          sm={8}
                          md={8}
                          lg={5}
                          xl={7}
                          className={"trabajador-img-col"}
                        >
                          <img
                            className={"trabajador-img"}
                            style={{
                              borderRadius: "50%",
                              width: 55,
                              height: 55,
                            }}
                            // src={trabajador.IdTrabajador.Imagen}
                            src={trabajador.Imagen}
                            alt="avatar trabajador"
                          />
                        </Col>

                        <Col xs={19} sm={16} md={16} lg={19} xl={17}>
                          <Row align={"middle"}>
                            <Col
                              style={{ lineHeight: "17px" }}
                              xs={13}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                            >
                              <h3
                                className={
                                  "text-no-margin-mobile text-nombre-trabajador"
                                }
                                style={{
                                  fontFamily: "MavenPro-Bold",
                                  fontSize: "12px",
                                  color: "#061FCB",
                                }}
                              >
                                {/* {trabajador.IdTrabajador.Nombre +
                                  " " +
                                  trabajador.IdTrabajador.Apellido_Paterno +
                                  " " +
                                  trabajador.IdTrabajador.Apellido_Materno} */}
                                {trabajador.NombreCompleto}
                                  
                              </h3>

                              <h3
                                className={"text-no-margin-mobile"}
                                style={{
                                  fontFamily: "MavenPro-SemiBold",
                                }}
                              >
                                {/* {trabajador.Estado.Nombre} */}
                                {trabajador.EstadoNombre}
                              </h3>

                              <p
                                className={"text-no-margin-mobile"}
                                style={{
                                  fontFamily: "MavenPro-Regular",
                                }}
                              >
                                {/* {trabajador.fechaFormateada} */}
                                {trabajador.FechaFormateada}
                              </p>

                              <p
                                className={"text-no-margin-mobile"}
                                style={{
                                  fontFamily: "MavenPro-Regular",
                                }}
                              >
                                {/* {trabajador.IdFaena.Centro_De_Costo.Nombre} */}
                                {trabajador.CentroCostosNombre}
                              </p>
                            </Col>
                            {/* <Tooltip
                              placement="leftTop"
                              title={"Aun no se pasa asistencia"}
                            > */}
                            <Col
                              xs={11}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              style={{ height: "fit-content" }}
                            >
                              <Button
                                disabled={trabajador.botonHabilitado}
                                onClick={async () => {
                                  // await this.setState({
                                  //   nombreCentroCosto:
                                  //     trabajador.IdFaena.Centro_De_Costo.Nombre,
                                  // });
                                  await this.setState({
                                    nombreCentroCosto:
                                      trabajador.CentroCostosNombre,
                                  });
                                  this.traerFaena(trabajador);                         
                                  // await this.props.buscarBitacorasTrabajador(
                                  //   // moment.unix(trabajador.FechaUnix),
                                  //   moment.utc(trabajador.Fecha),
                                  //   trabajador.IdTrabajador._id
                                  // );
                                  await this.props.buscarBitacorasTrabajador(
                                    moment.utc(trabajador.Fecha),
                                    trabajador.IdTrabajador
                                  );

                                  this.mostrarModalDesembarco();
                                }}
                                className={"ver-detalle-btn"}
                              >
                                ver detalle
                              </Button>
                            </Col>
                            {/* </Tooltip> */}
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </Content>

        <Modal
          //propiedades cambiables del modal
          visible={modalCheckoutMultiple}
          onCancel={this.ocultarModalCheckoutMultiple}
          bodyStyle={{ borderRadius: "16px" }}
          className={'modal-container'}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
         
            <Row style={{ padding: "5px 20px 0px 20px" }}>
              
              <Col span={24} style={{ display: "flex", borderRadius:"16px" }} className="title">

                <img
                  src={checkout}
                  style={{ maxWidth: "80%" }}
                  alt="icono modal"
                />
                <PageHeader
                  className="site-page-header"
                  title={<span>Check-Out Múltiple</span>}
                  subTitle={
                    `Viendo resultados de las fechas:
                      ${this.state.fechaBusquedaInicioChile ? (`${this.state.fechaBusquedaInicioChile} - ${this.state.fechaBusquedaFinChile}`) : `${convertirFechaString(moment.utc(convertirFechaString(moment(), 1).fechaString), 1).fechaDDMMYYYY}`}
                    `
                  }
                />
              </Col>

              {this.props.checkOut.length > 0 ? 
                (
                  <>
                    <Col span={24}>
                      <Checkbox.Group onChange={(value) => this.setState({trabajadoresCheckoutMasivo: value}) } style={{'width': '100%'}}>
                        <Row style={{'width': '100%'}}>

                          {this.props.checkOut.map((trabajador) => (

                          <Col span={24}>
                            <Checkbox
                              value={trabajador}
                              disabled={trabajador.botonHabilitado === true? true: false}
                              defaultChecked={false}
                              style={{width: '100%'}}
                              className="checkBoxCheckinMasivo"
                            >
                              <Row>
                                <Col span={7}>
                                  {this.abreviarNombre(
                                    trabajador.Nombre,
                                    trabajador.Apellido_Paterno,
                                    trabajador.Apellido_Materno,
                                  )}
                                </Col>
                                <Col span={4}>
                                  {trabajador.FechaFormateada}
                                </Col>
                                <Col span={6}>
                                  {trabajador.CentroCostosNombre}
                                </Col>
                                
                              </Row>

                            </Checkbox>
                          </Col>
                          ))}
                        </Row>
                      </Checkbox.Group>
                    </Col>

                    <Col span={24} style={{ textAlign: "right", marginTop: 20}}>
                      <Button
                        onClick={() => {
                          this.onFinishCheckoutMasivo()
                        }}
                        className="filtrar-check-btn"
                      >
                        Aceptar
                      </Button>   
                    </Col>   
                  
                  </>
                )
                : 
                (      
                  <Col span={24}>      
                    <Empty
                      imageStyle={{ height: 200, marginTop: "20px" }}
                      description={
                        <h2 className={"seleccion-trabajador-disponible"}>
                          <b>No se han encontrado trabajadores disponibles para hacer Check-In.</b>
                          <br />
                          <b>Intenta buscar por otro rango de fechas o centro de costos.</b>
                        </h2>
                      }
                    ></Empty>
                  </Col>
                )
              }
              
            </Row>
        </Modal>

        <Modal
          //propiedades cambiables del modal
          visible={this.props.modalDesembarco}
          onCancel={this.ocultarModalDesembarco}
          //propiedades obligatorias para modal mantenedor
          className={"modal-container-checkout"}
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <Row style={{ padding: "5px 20px 0px 20px" }} gutter={20}>
            <Col span={24} style={{ display: "flex" }} className="title">
              <img
                src={checkout}
                style={{ maxWidth: "100%" }}
                alt="icono modal"
              />
              <PageHeader
                style={{ padding: "0px 10px 0px 10px" }}
                className="site-page-header"
                title="Check-Out"
              />
            </Col>

            <Col xs={24} sm={24} md={24} lg={9} xl={7}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Card
                    style={{
                      height: "100%",
                    }}
                  >
                    <Row gutter={2}>
                      <Col span={4} align="middle">
                        <img
                          className={"modal-trabajador-img"}
                          src={
                            this.props.trabajadorModal === null
                              ? ""
                              : this.props.trabajadorModal.Imagen
                          }
                          alt="foto trabajador"
                        />
                      </Col>

                      <Col span={18} offset={2} align="middle">
                        <p
                          style={{
                            marginBottom: 0,
                          }}
                          className="info-trabajador fondo-gris-input"
                        >
                          {
                            this.props.trabajadorModal === null
                            ? ""
                            :
                             this.props.trabajadorModal.Nombre +
                            " " +
                            this.props.trabajadorModal.Apellido_Paterno +
                            " " +
                            this.props.trabajadorModal.Apellido_Materno
                          }
                        </p>
                        <p
                          style={{ marginTop: 5 }}
                          className="info-trabajador fondo-gris-input"
                        >
                          {this.props.trabajadorModal === null
                            ? ""
                            : this.props.trabajadorModal.Rut}
                        </p>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col
                  style={{
                    marginTop: 10,
                  }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  <Card
                    style={{
                      height: "100%",
                    }}
                  >
                    <h4>Próximos movimientos</h4>

                    {this.props.bitacoraTrabajadorCheckOut.length === 0 ? (
                      <Empty
                        style={{ marginTop: "15px" }}
                        description={
                          "No se han encontrado registros en los ultimos 10 dias. Si desea ver mas información vea el detalle del trabajador."
                        }
                      />
                    ) : (
                      <Timeline>
                        {this.props.bitacoraTrabajadorCheckOut.map(
                          (value, index) => {
                            const imgBitacora = coloresGenerica.obtenerImagenesBitacoraTrabajador(
                              value.tipoBitacora
                            );
                            let tituloBitacora = coloresGenerica.obtenerTituloBitacoraTrabajador(
                              value.tipoBitacora
                            );

                            return (
                              <Timeline.Item
                                key={index}
                                dot={
                                  <img
                                    src={imgBitacora}
                                    className="timeline-clock-icon"
                                  />
                                }
                              >
                                <p
                                  style={{
                                    margin: "0px",
                                    fontFamily: "MavenPro-Regular",
                                  }}
                                >
                                  {tituloBitacora}: {value.fecha_inicio} -{" "}
                                  {value.fecha_fin}
                                </p>
                              </Timeline.Item>
                            );
                          }
                        )}
                      </Timeline>
                    )}
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} lg={15} xl={17}>
              <Card>
                <Row style={{ height: "fit-content" }}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    style={{ fontFamily: "MavenPro-Bold" }}
                  >
                    <Row>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={8}
                        style={{
                          textAlign: "right",
                          paddingRight: 10,
                        }}
                      >
                        Centro de costos:
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <p
                          className="fondo-gris-input"
                          style={{
                            paddingLeft: 10,
                            paddingRight: 10,
                            margin: 0,
                          }}
                        >
                          {this.props.centroDeCosto}
                        </p>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={8}
                        style={{
                          textAlign: "right",
                          paddingRight: 10,
                        }}
                      >
                        Termino de operación:
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <p
                          className="fondo-gris-input"
                          style={{
                            paddingLeft: 10,
                            paddingRight: 10,
                            margin: 0,
                          }}
                        >
                          {this.props.fechaFin}
                        </p>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={8}
                        style={{
                          textAlign: "right",
                          paddingRight: 10,
                        }}
                      >
                        ¿En que estado queda el trabajador?
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Procesos"
                          onSelect={(value, data) => {
                            this.setState({
                              numeroFormularioMostrar: value,
                            });
                          }}
                          className={"responsive-filters"}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Option key={0} value={0}>
                            Seleccione Proceso
                          </Option>
                          <Option key={-1} value={-1}>
                            Generar Solo Checkout
                          </Option>
                          <Option key={1} value={1}>
                            Dia ausente
                          </Option>
                          <Option key={2} value={2}>
                            Permisos
                          </Option>
                          <Option key={3} value={3}>
                            En transito
                          </Option>
                          <Option key={4} value={4}>
                            Licencia
                          </Option>
                          <Option key={5} value={5}>
                            Licencia mutual
                          </Option>
                          <Option key={6} value={6}>
                            Vacaciones
                          </Option>
                          <Option key={7} value={7}>
                            Descanso
                          </Option>
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                  {this.state.numeroFormularioMostrar === -1 ? (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      style={{ marginTop: "24px", textAlign: "right" }}
                    >
                      <Button
                        style={{
                          marginRight: "15px",
                          marginLeft: "auto",
                          display: "block",
                        }}
                        type="primary"
                        onClick={() => {
                          this.desembarcar();
                        }}
                        className={"guardar-form"}
                      >
                        Guardar
                      </Button>
                    </Col>
                  ) : (
                    <></>
                  )}

                  {this.mostrarFormulario()}
                </Row>
              </Card>
            </Col>
          </Row>
        </Modal>

        <span>
          <style jsx="true">
            {`
              @media screen and (max-width: 767px) {
                .ant-layout-content {
                  margin: 0px !important;
                }
              }

              @media screen and (max-width: 575px) {
                .ant-card-body {
                  padding: 10px !important;
                }
              }
            `}
          </style>
        </span>
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  CheckOutReducer: {
    loading,
    error,
    success,
    tablaCargando,
    trabajadores,
    centroCostos,
    centroDeCosto,
    faenas,
    checkIn,
    checkOut,
    fechaInicio,
    fechaFin,
    modalEmbarco,
    modalDesembarco,
    rangoFechaPlaza,
    trabajadorModal,
    bitacoraTrabajador,

    faena,
    idTrabajador,
    bitacora,
    bitacoraTrabajadorCheckOut,
    bitacoraCompletaTrabajadorCheckOut,
  },
}) => {
  return {
    modalEmbarco,
    modalDesembarco,
    loading,
    centroDeCosto,
    error,
    success,
    tablaCargando,
    trabajadores,
    centroCostos,
    faenas,
    fechaInicio,
    fechaFin,
    checkIn,
    checkOut,
    rangoFechaPlaza,
    trabajadorModal,
    bitacoraTrabajador,

    faena,
    idTrabajador,
    bitacora,
    bitacoraTrabajadorCheckOut,
    bitacoraCompletaTrabajadorCheckOut,
  };
};

export default connect(
  EstadosAPropiedades,
  FiltroTrabajadoresActions
)(FiltroTrabajadores);
