// Tipos de consultas
import {
    LOGIN,
    LOADING,
    FORGOTTEN,
    ERROR
} from '../types/LoginTypes';

const INITIAL_STATE = {
    usuario: null,
    cargando: false,
    redirecionar: false,
    error: null,
}

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LOGIN:
            return {
                ...state,
                usuario: payload,
                //cargando: false,
                redirecionar: true,
            }
        case LOADING:
            return {
                ...state,
                cargando: true
            }
        case ERROR:
            return {
                ...state,
                error: payload,
                cargando: false,
            }
        default:
            return state
    }
}