import {
  ERROR,
  LOADING,
  PARAR_LOADING,
  MOSTRAR_MODAL_PERFIL_USUARIO,
  OCULTAR_MODAL_PERFIL_USUARIO,
  OBTENER_PERFILES_ACCESO,
  OBTENER_PERFIL_ACCESO,
  OCULTAR_MODAL_PERFIL_USUARIO_EDITAR
} from "../types/MantenedorPerfilesUsuarioTypes";
import API from "../Api/api";
import Swal from "sweetalert2";

export const MostrarModalPerfilUsuario = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_PERFIL_USUARIO });
};

export const OcultarModalPerfilUsuario = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_PERFIL_USUARIO });
};

export const OcultarModalPerfilEditar = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_PERFIL_USUARIO_EDITAR });
};

export const CrearPerfilUsuario = (perfilUsuario) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const url = `${API}/PlantillasAcceso`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(perfilUsuario),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Se ingresó el perfil correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: PARAR_LOADING });

        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerPerfil = (idPlantilla) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/PlantillasAcceso/${idPlantilla}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();

        dispatch({ type: OBTENER_PERFIL_ACCESO, payload: data.data });
        break;

      case 204:
        dispatch({ type: ERROR, payload: data.err });
        break;
      default:
        data = await response.json();

        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerPerfilesUsuario = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/PlantillasAcceso?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          dispatch({ type: OBTENER_PERFILES_ACCESO, payload: data.data });
          break;
        case 204:
          dispatch({ type: OBTENER_PERFILES_ACCESO, payload: [] });
          break;
        default:
          data = await response.json();
          //const error = data.err.message.toString();
          Swal.fire({
            title: "Uups",
            text: "Error status: " + status,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarPerfil = (idPerfil) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/PlantillasAcceso/${idPerfil}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Se eliminó el perfil correctamente.",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: PARAR_LOADING });

        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarPerfilUsuario = (perfilUsuario) => async (dispatch) => {
  dispatch({ type: LOADING });
  const idPerfilUsuario = perfilUsuario._id;
  delete perfilUsuario._id;
  try {
    const url = `${API}/PlantillasAcceso/${idPerfilUsuario}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(perfilUsuario),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El perfil de acceso fue actualizado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        })
          .then((value) => {
            window.location.reload();
          });

        dispatch({ type: PARAR_LOADING });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};
