import {
  ERROR,
  INGRESAR_SUCURSAL,
  LOADING,
  OBTENER_EMPRESAS,
  OBTENER_EMPRESA,
  OBTENER_REGIONES,
  OBTENER_CIUDADES,
  OBTENER_COMUNAS,
  LIMPIAR_ERROR_SUCCESS,
  MOSTRAR_MODAL_SUCURSAL,
  OCULTAR_MODAL_SUCURSAL,
  OBTENER_SUCURSALES_MANTENEDOR,
  ELIMINAR_SUCURSAL_MANTENEDOR,
  OBTENER_SUCURSAL_MANTENEDOR,
  OCULTAR_MODAL_SUCURSAL_EDITAR,
  EDITAR_SUCURSAL_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OBTENER_NACIONALIDADES_MANTENEDOR_SUCURSALES,
  OBTENER_REGIONES_MANTENEDOR_SUCURSALES,
  OBTENER_PROVINCIAS_MANTENEDOR_SUCURSALES,
  OBTENER_COMUNAS_MANTENEDOR_SUCURSALES,
  INSERTAR_COMUNAS_MANTENEDOR_SUCURSALES,
  INSERTAR_PROVINCIAS_MANTENEDOR_SUCURSALES,
  // PARAR_LOADING,
} from "../types/MantenedorSucursalesTypes";

const INITIAL_STATE = {
  loading: false,
  empresas: [],
  regiones: [],
  ciudades: [],
  comunas: [],
  error: null,
  success: null,
  modalSucursal: false,
  modalSucursalEditar: false,
  sucursales: [],
  sucursal: null,
  tablaCargando: true,
  empresa: { Razon_Social: '' },
  nacionalidades: [],
  comunasSeleccionadas: [],
  ciudadesSeleccionadas: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case INGRESAR_SUCURSAL:
      return {
        ...state,
        loading: false,
        success: payload,
        modalSucursal: false,
        tablaCargando: false,
      };
    case OBTENER_EMPRESAS:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        empresas: payload,
      };

    case OBTENER_EMPRESA:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        empresa: payload,
      };

    case EDITAR_SUCURSAL_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        modalSucursalEditar: false,
      };

    case TOMAR_VALORES_EDITAR:
      const { nombreCampo, valor } = payload;
      return {
        ...state,

        sucursal: {
          ...state.sucursal,
          [nombreCampo]: valor,
        },
      };
    case LOADING:
      return {
        ...state,
        loading: true,
        tablaCargando: true,
      };
    // case PARAR_LOADING:
    //   return {
    //     ...state,
    //     loading: false,
    //     tablaCargando: false,
    //   };
    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        tablaCargando: false,
      };
    case LIMPIAR_ERROR_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };
    case MOSTRAR_MODAL_SUCURSAL:
      return {
        ...state,
        modalSucursal: true,
      };

    case OBTENER_SUCURSALES_MANTENEDOR:
      return {
        ...state,
        sucursales: payload,
        loading: false,
        tablaCargando: false,
      };
    case OBTENER_SUCURSAL_MANTENEDOR:
      return {
        ...state,
        sucursal: payload,
        loading: false,
        tablaCargando: false,
        modalSucursalEditar: true,
      };
    case ELIMINAR_SUCURSAL_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      };
    case OCULTAR_MODAL_SUCURSAL:
      return {
        ...state,
        modalSucursal: false,
      };
    case OCULTAR_MODAL_SUCURSAL_EDITAR:
      return {
        ...state,
        modalSucursalEditar: false,
      };
    case INSERTAR_PROVINCIAS_MANTENEDOR_SUCURSALES:
      return {
        ...state,
        ciudadesSeleccionadas: payload,
      }

    case INSERTAR_COMUNAS_MANTENEDOR_SUCURSALES:
      return {
        ...state,
        comunasSeleccionadas: payload,
      }
      
    case OBTENER_COMUNAS_MANTENEDOR_SUCURSALES:
      return {
        ...state,
        loading: false,
        comunas: payload,
      }

    case OBTENER_PROVINCIAS_MANTENEDOR_SUCURSALES:
      return {
        ...state,
        loading: false,
        ciudades: payload,
      }

    case OBTENER_REGIONES_MANTENEDOR_SUCURSALES:
      return {
        ...state,
        loading: false,
        regiones: payload,
      }
    case OBTENER_NACIONALIDADES_MANTENEDOR_SUCURSALES:
      return {
        ...state,
        loading: false,
        nacionalidades: payload,
      }
    case OBTENER_REGIONES:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        regiones: payload,
      };
    case OBTENER_CIUDADES:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        ciudades: payload,
      };

    case OBTENER_COMUNAS:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        comunas: payload,
      };
    // case OBTENER_SUCURSALES_MANTENEDOR:
    //   return {
    //     ...state,
    //     empresas: payload,
    //     loading: false,
    //     tablaCargando: false,
    //   };
    // case OBTENER_SUCURSAL_MANTENEDOR:
    //   let idRegion = payload.Region;
    //   let idProvincia = payload.Ciudad;


    //   let provincias = state.ciudades;
    //   let comunas = state.comunas;

    //   let nuevasProvincias = provincias.filter(provincia => provincia.Region === idRegion);
    //   let nuevasComunas = comunas.filter(comuna => comuna.Provincia === idProvincia);

    //   return {
    //     ...state,
    //     sucursal: payload,
    //     loading: false,
    //     tablaCargando: false,
    //     modalEmpresaEditar: true,
    //     comunasSeleccionadas: nuevasComunas,
    //     ciudadesSeleccionadas: nuevasProvincias,
    //   };
    default:
      return state;
  }
};
