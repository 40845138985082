import React from 'react'
import { Row, Col } from "antd";
import { isMobile } from 'react-device-detect';
import { Column } from '@ant-design/charts';

const SemaforoBarchart = (props) => {

    let { data } = props;

    let leyenda = {
        layout: (isMobile === true ? 'vertical' : 'horizontal'),
        position: (isMobile === true ? 'bottom-left' : 'bottom'),
        flipPage: false,
        // marker: {
        //     symbol: 'diamond'
        // }
    }

    if (!isMobile) {
        leyenda.itemHeight = '100';
    }

    var config = {
        data: data,
        xField: 'tipoDesviacion',
        yField: 'Cantidad',
        seriesField: 'tipoDesviacion',
        label: {},
        color: function color(_ref) {
            let valor = _ref.tipoDesviacion;
            switch (valor) {
                case 'Sin Desviación': return '#75d154';
                case 'Desviación Tolerada': return '#ebe15e';
                case 'Desviación No Tolerada': return '#ff704d';
                case 'Sin Descansos En Periodo': case 'Sin Trabajo En Periodo': return '#b5b5b5';
                default: return 'gray';
            }
        },
        legend: leyenda,
        xAxis: false,
        isStack: true,
    };

    const levantarModal = (tipoDesviacion) => {

        props.levantarModal(tipoDesviacion);
    }

    return (
        <div>
            <Row align={'middle'}>

                <Col span={1} style={{ height: '200px' }}>
                    <span className={'axis-cantidad-trab'}>Cantidad de trabajadores</span>
                </Col>

                <Col span={23}>
                    <Column
                        {...config}
                        onEvent={(chart, event) => {

                            if (event.type === "plot:click") {

                                let nombreBarra;

                                if (event.data) {
                                    nombreBarra = event.data.data.tipoDesviacion;
                                } else {
                                    nombreBarra = 'Sin Desviación';
                                }

                                levantarModal(nombreBarra);
                            }
                        }}
                    />
                </Col>

            </Row>
        </div>
    )
}

export default SemaforoBarchart;
