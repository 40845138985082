import React, { useState } from "react";
import SubirFoto from "../../components/subirFoto/SubirFoto";
import logoGenerico from "../../img/logos/logoGenerico.svg";
import avatar from "../../img/icons/avatar.svg";
import Rut from "../../funcionesGenericas/rut";

import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Select,
  //Radio,
} from "antd";

import Swal from "sweetalert2";
import ValidadorRut from "validar-rut";

// import state from "sweetalert/typings/modules/state";
// import { CloudFilled } from "@ant-design/icons";
// import CheckboxGroup from "antd/lib/checkbox/Group";

const Demo = (props) => {

  //constante para manejar el formulario
  const [form] = Form.useForm();
  const [validRutUsuario, setValidRutUsuario] = useState('');

  const ObtenerImagen = (imagenUrl) => {
    props.obtenerImagen(imagenUrl);
  };

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {

    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {

      if (values.Password !== values.PasswordConfirmar) {
        Swal.fire({
          title: "¡Ups!",
          text: "Confirmación de contraseña erronea.",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        });
        return false;
      }

      if (props.imagen === null || props.imagen === undefined) {
        values.Imagen =
          "https://peoplemanage.blob.core.windows.net/imagenes-sistema/avatar.svg";
      } else {
        values.Imagen = props.imagen;
      }

      if (values.Descripcion === null || values.Descripcion === undefined) {
        values.Descripcion = "";
      }

      if (values.Telefono === null || values.Telefono === undefined) {
        values.Telefono = "";
      }

      let empresas = [];

      usuario = JSON.parse(usuario);


      let Empresa = {
        Empresa: usuario.EmpresaSeleccionada.Empresa._id,
        Rol: values.Rol
      }
      const objAux = {
        ...values,
        Empresas: [Empresa],

      };
      props.crearUsuario(objAux);

    }
    //validacion de campos para que no se ingresen como undefined o null
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 9,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={logoGenerico} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Nuevo Usuario"
          />
        </Col>
        <Col span={8}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Row>
              <Col lg={10} xl={24}>
                <SubirFoto avatar={avatar} ObtenerImagen={ObtenerImagen} />
              </Col>
              <Col lg={22} xl={24}>
                <p>
                  Te recomendamos que la fotografía no exceda los 5 MB de peso
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col offset={1} span={15}>
          <Card className="card-perfil" style={{ width: "100%" }}>

            <Form.Item
              name={"Email"}
              label="Nombre usuario (email)"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Se debe ingresar un correo",
                },
              ]}
            >
              <Input name="Email" className="campos" />
            </Form.Item>

            <Form.Item
              name={"Password"}
              label="Contraseña"
              rules={[
                {
                  required: true,
                  message: "La contraseña debe tener como mínimo 8 y máximo 20 caracteres alfanuméricos.",
                  min: 8,
                  max: 20,
                },
              ]}
              hasFeedback
              help="La contraseña debe tener como mínimo 8 y máximo 20 caracteres alfanuméricos."
            >
              <Input
                suggested="current-password"
                name="Password"
                type="Password"
                className="campos"
              />
            </Form.Item>

            <Form.Item
              name={"PasswordConfirmar"}
              label="Confirmar Contraseña"
              rules={[
                {
                  validator: (_, value) => {

                    let password = form.getFieldsValue(['Password']).Password;

                    if (value !== password) {
                      return Promise.reject('Confirmación de contraseña incorrecta.');
                    } else {
                      return Promise.resolve();
                    }
                  }
                },
              ]}
            >
              <Input
                suggested="current-password"
                name="Password"
                type="Password"
                className="campos"
              />
            </Form.Item>

            <Form.Item
              name="Nombres"
              label="Nombres:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir sus nombres",
                  min: 2,
                  max: 20,
                },
              ]}
            >
              <Input name="Nombres" className="campos" />
            </Form.Item>

            <Form.Item
              name={"Apellido_Paterno"}
              label="Apellido Paterno:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir su apellido paterno",
                  min: 2,
                  max: 30,
                },
              ]}
            >
              <Input name="Apellido_Paterno" className="campos" />
            </Form.Item>

            <Form.Item
              name={"Apellido_Materno"}
              label="Apellido Materno:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir su apellido materno",
                  min: 2,
                  max: 30,
                },
              ]}
            >
              <Input name="Apellido_Materno" className="campos" />
            </Form.Item>

            <Form.Item
              name={"Rut"}
              label="RUT"
              validateStatus={validRutUsuario}
              onChange={(e) => {

                let valor = e.target.value;

                if (valor) {

                  let rut = Rut.formateaRut(valor);
                  let rutValidacion = Rut.procesarRut(rut)

                  setValidRutUsuario(rutValidacion);

                  if (rut !== undefined) {
                    form.setFieldsValue({ Rut: rut });
                  } else {
                    form.setFieldsValue({ Rut: valor });
                  }
                }
              }}
              rules={[
                {
                  min: 11,
                  max: 12,
                  required: true,
                  message: "Debe introducir el RUT.",
                },
              ]}
              hasFeedback
              help="Ingrese el RUT válido con código verificador"
            >
              <Input
                name="Rut"
                // onChange={(e) => {
                //   let valor = e.target.value;
                //   let rut = Rut.formateaRut(valor);

                //   let validacion = ValidadorRut.validar(rut);
                //   if (validacion === true) {
                //     props.validacionRut("success");
                //   } else {
                //     props.validacionRut("error");
                //   }

                //   if (rut !== undefined) {
                //     form.setFieldsValue({ Rut: rut });
                //   } else {
                //     form.setFieldsValue({ Rut: valor });
                //   }
                // }}
                className="campos"
                placeholder="xx.xxx.xxx-x"
                maxLength={12}
              />
            </Form.Item>

            <Form.Item
              label="Rol"
              name={"Rol"}
              rules={[
                {
                  required: true,
                  message: "Debes seleccionar un rol para el usuario",
                },
              ]}
            >
              <Select
                className="campos"
                name="Rol"
                placeholder="Selecciona rol"
              >
                {props.Roles.filter(
                  (rol) => rol._id !== "5f21ae92d585cd003f997a6a"
                ).map((value, index) => (
                  <Select.Option name="Rol" key={index} value={value._id}>
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Teléfono"
              name="Telefono"
              rules={[
                {
                  message: "Debe introducir tu número de teléfono",
                },
              ]}
              hasFeedback
            >
              <Input
                name="Telefono"
                className="campos"
                placeholder="+569XXXXXXXX"
                maxLength={12}
              />
            </Form.Item>

            <Form.Item
              label="Descripción"
              name="Descripcion"
              rules={[
                {
                  message: "Debe introducir una introducción",
                },
              ]}
              hasFeedback
            >
              <Input
                name="Descripcion"
                className="campos"
                placeholder="Descripción del usuario"
              />
            </Form.Item>

            <Form.Item label="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                defaultChecked
                onChange={props.cambiarEstadoVigencia}
                className="switch"
              />
            </Form.Item>
          </Card>
        </Col>
        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Demo;
