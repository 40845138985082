export const LOADING = "CALENDARIO_TRABAJADOR_LOADING";
export const PARAR_LOADING = "CALENDARIO_TRABAJADOR_PARAR_LOADING";
export const ERROR = "CALENDARIO_TRABAJADOR_ERROR";
export const LIMPIAR_ERROR_SUCCESS =
  "CALENDARIO_TRABAJADOR_LIMPIAR_ERROR_SUCCESS";
export const OBTENER_TRABAJADOR_CALENDARIO =
  "CALENDARIO_TRABAJADORES_OBTENER_TRABAJADOR";
export const OBTENER_BITACORA_TRABAJADOR =
  "CALENDARIO_TRABAJADORES_OBTENER_BITACORA_TRABAJADOR";
export const OBTENER_ESTADOS_TRABAJADOR =
  "CALENDARIO_TRABAJADORES_OBTENER_ESTADOS_TRABAJADOR";
export const MOSTRAR_TABLA = "CALENDARIO_TRABAJADORES_MOSTRAR_TABLA";




export const OBTENER_BITACORAS_EPPS = "CALENDARIO_TRABAJADORES_OBTENER_BITACORAS_EPPS";
export const OBTENER_BITACORAS_PROFESIONALES = "CALENDARIO_TRABAJADORES_OBTENER_BITACORAS_PROFESIONALES";
export const OBTENER_VACACIONES_HISTORICAS = 'OBTENER_VACACIONES_HISTORICAS';

export const OBTENER_CARGOS_TRABAJADOR = "MANTENEDOR_TRABAJADOR_OBTENER_CARGOS";

export const OBTENER_CONTRATOS_TRABAJADOR = "MANTENEDOR_TRABAJADOR_OBTENER_CONTRATOS";

export const INGRESAR_TRABAJADOR = "MANTENEDOR_TRABAJADOR_INGRESAR";
export const OBTENER_TRABAJADOR_MANTENEDOR =
  "MANTENEDOR_TRABAJADORES_OBTENER_TRABAJADOR";
export const OBTENER_REGIMEN_TRABAJO = "MANTENEDOR_TRABAJADOR_REGIMEN_TRABAJO";
export const EDITAR_TRABAJADOR_MANTENEDOR = "MANTENEDOR_TRABAJADOR_EDITAR_ROL";
export const TOMAR_VALORES_EDITAR =
  "MANTENEDOR_TRABAJADOR_TOMAR_VALORES_EDITAR";

export const OBTENER_TITULOS_TRABAJADORES =
  "MANTENEDOR_TRABAJADOR_OBTENER_TITULOS_TRABAJADORES";
export const OBTENER_ESPECIALIDADES_TRABAJADORES =
  "MANTENEDOR_TRABAJADOR_OBTENER_ESPECIALIDADES_TRABAJADORES";
export const OBTENER_EPPS_TRABAJADORES =
  "OBTENER_EPPS_TRABAJADORES";


export const OBTENER_REGIONES_MANTENEDOR_TRABAJADOR =
  "OBTENER_REGIONES_MANTENEDOR_TRABAJADOR";

export const OBTENER_PROVINCIAS_MANTENEDOR_TRABAJADOR =
  "OBTENER_PROVINCIAS_MANTENEDOR_TRABAJADOR";
export const OBTENER_COMUNAS_MANTENEDOR_TRABAJADOR =
  "OBTENER_COMUNAS_MANTENEDOR_TRABAJADOR";

export const INSERTAR_PROVINCIAS_MANTENEDOR_TRABAJADOR =
  "INSERTAR_PROVINCIAS_MANTENEDOR_TRABAJADOR";

export const INSERTAR_COMUNAS_MANTENEDOR_TRABAJADOR =
  "INSERTAR_COMUNAS_MANTENEDOR_TRABAJADOR";


export const OBTENER_DOCUMENTOS_MANTENEDOR_TRABAJADOR =
  "OBTENER_DOCUMENTOS_MANTENEDOR_TRABAJADOR";