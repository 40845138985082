import React, { useEffect, useState } from "react";
import SubirFoto from "../../components/subirFoto/SubirFoto";
import Trabajador from "../../img/icons/Trabajador.svg";
import personal from "../../img/icons/avatar.svg";
import contacto from "../../img/icons/telefono.svg";
import familiares from "../../img/icons/familiares.svg";
import flecha from "../../img/icons/flecha.svg";
import epp from "../../img/icons/epp.svg";
import contrato from "../../img/icons/contrato-panel.svg";
import vacaciones from "../../img/icons/vacaciones-panel.svg";

import especialidades_icon from "../../img/icons/icon_espe.png";
import titulos_icon from "../../img/icons/icon_titu.png";

import bancario from "../../img/icons/bancarios.svg";
import previsional from "../../img/icons/previsional.svg";
import estudios from "../../img/icons/estudios.svg";

// import Moment from "moment";
import Rut from "../../funcionesGenericas/rut";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import {
  Form,
  Input,
  Button,
  Card,
  Collapse,
  DatePicker,
  Row,
  Col,
  PageHeader,
  Select,
  Radio,
  InputNumber,
  Alert,
} from "antd";
import Swal from "sweetalert2";

import "./FormularioTrabajadorEditar.css";
import moment from "moment";

import {
  convertirFechaString
} from "../../funcionesGenericas/fechas";

import { isTelefonoValid } from "../../funcionesGenericas/telefonos";

// import ValidadorRut from "validar-rut";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const FormularioTrabajadorEditarNuevo = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();
  const [validRutTrabajador, setValidRutTra] = useState("");
  const [tipoFechaContrato, setTipoFechaContrato] = useState(null);
  const [requirioProgre, setRequirioProgre] = useState(false);
  const [otroSelected, setOtroSelected] = useState(false);
  const [validateOtro, setValidateOtro] = useState("error");

  const [abrioContrato, setAbrioContrato] = useState(false);
  const [abrioVacaciones, setAbrioVacaciones] = useState(false);

  const [reiniciarImg, setReiniciarImg] = useState(false);

  const [esCargaMasiva, setEsCargaMasiva] = useState(false);
  const [esRelevo, setEsRelevo] = useState(false);


  // const [disabledContratoInputs, setDisabledContratoInputs] = useState(true);
  const disabledContratoInputs = true;

  const ObtenerImagen = (imagenUrl) => {
    props.obtenerImagen(imagenUrl);
    props.TomarValoresEditar("Imagen", imagenUrl);
  };

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {
    //return
    //EMPRESA
    values.Empresa = values.Empresa === undefined ? props.Formulario.Empresa : values.Empresa;

    //DATOS PERSONALES
    values.Rut = values.Rut === undefined ? props.Formulario.Rut : values.Rut;
    values.Cargo = values.Cargo === undefined ? props.Formulario.Cargo : values.Cargo;
    values.OtrosCargos = values.OtrosCargos === undefined ? props.Formulario.OtrosCargos : values.OtrosCargos;
    values.Nombre = values.Nombre === undefined ? props.Formulario.Nombre : values.Nombre;
    values.Apellido_Paterno = values.Apellido_Paterno === undefined ? props.Formulario.Apellido_Paterno : values.Apellido_Paterno;
    values.Apellido_Materno = values.Apellido_Materno === undefined ? props.Formulario.Apellido_Materno : values.Apellido_Materno;
    values.Fecha_De_Nacimiento = values.Fecha_De_Nacimiento === undefined ? props.Formulario.Fecha_De_Nacimiento : values.Fecha_De_Nacimiento;
    values.Calle = values.Calle === undefined ? props.Formulario.Calle : values.Calle;
    values.Numero_Calle = values.Numero_Calle === undefined ? props.Formulario.Numero_Calle : values.Numero_Calle;
    values.Tipo_De_Vivienda = values.Tipo_De_Vivienda === undefined ? props.Formulario.Tipo_De_Vivienda : values.Tipo_De_Vivienda;
    values.Numero_Departamento = values.Numero_Departamento === undefined ? props.Formulario.Numero_Departamento : values.Numero_Departamento;
    values.Region = values.Region === undefined ? props.Formulario.Region : values.Region;
    values.Ciudad = values.Ciudad === undefined ? props.Formulario.Ciudad : values.Ciudad;
    values.Comuna = values.Comuna === undefined ? props.Formulario.Comuna : values.Comuna;
    values.Correo = values.Correo === undefined ? props.Formulario.Correo : values.Correo;
    values.Telefono = values.Telefono === undefined ? props.Formulario.Telefono : values.Telefono;
    values.Nacionalidad = values.Nacionalidad === undefined ? props.Formulario.Nacionalidad : values.Nacionalidad;
    values.Sexo = values.Sexo === undefined ? props.Formulario.Sexo : values.Sexo;
    values.RegimenDeTrabajo = values.RegimenDeTrabajo === undefined ? props.Formulario.RegimenDeTrabajo : values.RegimenDeTrabajo;
    values.Codigo_Base = values.Codigo_Base === undefined ? props.Formulario.Codigo_Base : values.Codigo_Base;

    //DATOS DE CONTACTO DE EMERGENCIA
    values.Contacto_Nombre = values.Contacto_Nombre === undefined ? props.Formulario.Contacto_Nombre : values.Contacto_Nombre;
    values.Contacto_Correo = values.Contacto_Correo === undefined ? props.Formulario.Contacto_Correo : values.Contacto_Correo;
    values.Contacto_Fono_Fijo = values.Contacto_Fono_Fijo === undefined ? props.Formulario.Contacto_Fono_Fijo : values.Contacto_Fono_Fijo;
    values.Contacto_Fono_Celular = values.Contacto_Fono_Celular === undefined ? props.Formulario.Contacto_Fono_Celular : values.Contacto_Fono_Celular;

    // //DATOS FAMILIARES Y LEGALES
    values.Estado_Civil = values.Estado_Civil === undefined ? props.Formulario.Estado_Civil : values.Estado_Civil;
    values.Cantidad_Hijos = values.Cantidad_Hijos === undefined ? props.Formulario.Cantidad_Hijos : values.Cantidad_Hijos;

    //TITULOS
    values.Titulos_Profesionales = values.Titulos_Profesionales === undefined ? props.Formulario.Titulos_Profesionales : values.Titulos_Profesionales;

    //ESPECIALIDADES
    values.Especialidades = values.Especialidades === undefined ? props.Formulario.Especialidades : values.Especialidades;

    //EPP
    values.EPP = values.EPP === undefined ? props.Formulario.EPP : values.EPP;

    //DOCUMENTOS
    values.TiposDeDocumentos = values.TiposDeDocumentos === undefined ? props.Formulario.TiposDeDocumentos : values.TiposDeDocumentos;

    //CARACTERISTICAS DEL CONTRATO
    values.Tipo_Contrato = values.Tipo_Contrato === undefined ? props.Formulario.Tipo_Contrato : values.Tipo_Contrato;
    values.Relevo = values.Relevo === undefined ? props.Formulario.Relevo : values.Relevo;
    values.Contrato_Fecha_Inicio = values.Contrato_Fecha_Inicio === undefined ? props.Formulario.Contrato_Fecha_Inicio : values.Contrato_Fecha_Inicio;
    values.Contrato_Fecha_Fin = values.Contrato_Fecha_Fin === undefined ? props.Formulario.Contrato_Fecha_Fin : values.Contrato_Fecha_Fin;
    values.Contrato_Fecha_Inicio_Unix = values.Contrato_Fecha_Inicio_Unix === undefined ? props.Formulario.Contrato_Fecha_Inicio_Unix : values.Contrato_Fecha_Inicio_Unix;
    values.Contrato_Fecha_Fin_Unix = values.Contrato_Fecha_Fin_Unix === undefined ? props.Formulario.Contrato_Fecha_Fin_Unix : values.Contrato_Fecha_Fin_Unix;
    values.Costo_Bruto_Diario = values.Costo_Bruto_Diario === undefined ? props.Formulario.Costo_Bruto_Diario : values.Costo_Bruto_Diario;
    values.Costo_Liquido_Diario = values.Costo_Liquido_Diario === undefined ? props.Formulario.Costo_Liquido_Diario : values.Costo_Liquido_Diario;

    //VACACIONES 
    values.Requirio_Progresivas = values.Requirio_Progresivas === undefined ? props.Formulario.Requirio_Progresivas : values.Requirio_Progresivas;
    values.Fecha_Progresivas = values.Fecha_Progresivas === undefined ? props.Formulario.Fecha_Progresivas : values.Fecha_Progresivas;
    values.Fecha_Progresivas_Unix = values.Fecha_Progresivas_Unix === undefined ? props.Formulario.Fecha_Progresivas_Unix : values.Fecha_Progresivas_Unix;
    values.Meses_Empleadores_Anteriores = values.Meses_Empleadores_Anteriores === undefined ? props.Formulario.Meses_Empleadores_Anteriores : values.Meses_Empleadores_Anteriores;
    values.Regimen_Vacaciones = values.Regimen_Vacaciones === undefined ? props.Formulario.Regimen_Vacaciones : values.Regimen_Vacaciones;

    //INTEGRAR NUEVA DATA Y REVISAR.
    //DATOS BANCARIOS NUEVO
    values.Numero_Cuenta_Bancaria = values.Numero_Cuenta_Bancaria === undefined ? props.Formulario.Numero_Cuenta_Bancaria : values.Numero_Cuenta_Bancaria;
    values.Banco = values.Banco === undefined ? props.Formulario.Banco : values.Banco;
    values.Forma_Pago = values.Forma_Pago === undefined ? props.Formulario.Forma_Pago : values.Forma_Pago;

    //DATOS PREVISIONALES
    values.AFP = values.AFP === undefined ? props.Formulario.AFP : values.AFP;
    values.Sistema_Pension = values.Sistema_Pension === undefined ? props.Formulario.Sistema_Pension : values.Sistema_Pension;
    values.Jubilado = values.Jubilado === undefined ? props.Formulario.Jubilado : values.Jubilado;
    values.Isapre_Fonasa = values.Isapre_Fonasa === undefined ? props.Formulario.Isapre_Fonasa : values.Isapre_Fonasa;
    values.Isapre_Monetizacion = values.Isapre_Monetizacion === undefined ? props.Formulario.Isapre_Monetizacion : values.Isapre_Monetizacion;
    values.Isapre_UF = values.Isapre_UF === undefined ? props.Formulario.Isapre_UF : values.Isapre_UF;
    values.Isapre_Peso = values.Isapre_Peso === undefined ? props.Formulario.Isapre_Peso : values.Isapre_Peso;
    values.Isapre_Cotizacion = values.Isapre_Cotizacion === undefined ? props.Formulario.Isapre_Cotizacion : values.Isapre_Cotizacion;

    //DATOS OTROS ESTUDIOS
    values.Nivel_Estudio = values.Nivel_Estudio === undefined ? props.Formulario.Nivel_Estudio : values.Nivel_Estudio;
    values.Licencia_Conducir = values.Licencia_Conducir === undefined ? props.Formulario.Licencia_Conducir : values.Licencia_Conducir;
    values.Tipo_Licencia_Conducir = values.Tipo_Licencia_Conducir === undefined ? props.Formulario.Tipo_Licencia_Conducir : values.Tipo_Licencia_Conducir;

    if (props.Formulario.Imagen === null) {
      Swal.fire({
        title: "Uuups...",
        text: "Debes escoger imagen",
        icon: "error",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Continuar",
      });
    } else {

      if (values.Contacto_Fono_Celular) {
        values.Contacto_Fono_Celular = values.Contacto_Fono_Celular === undefined ? "" : values.Contacto_Fono_Celular.toString().replace(/ /g, "");
      }
      if (values.Contacto_Fono_Fijo) {
        values.Contacto_Fono_Fijo = values.Contacto_Fono_Fijo === undefined ? "" : values.Contacto_Fono_Fijo.toString().replace(/ /g, "");
      }

      if (values.Telefono) {
        values.Telefono = values.Telefono === undefined ? "" : values.Telefono.replace(/ /g, "");
      }

      values.Imagen = props.Formulario.Imagen;
      values.Empresa = values.EmpresaHolding;

      ///////////////////////////////////////////////////////////////////////////////////////////////

      //validaciones temporales para trabajadores sin info de contrato o vacaciones
      if (props.Formulario.Tipo_Contrato == undefined && !abrioContrato) {
        Swal.fire({
          title: "¡Ups!",
          text: "Debes ingresar las características del contrato.",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
        return;
      }

      if (props.Formulario.Requirio_Progresivas == undefined && !abrioVacaciones) {
        Swal.fire({
          title: "¡Ups!",
          text: "Debes ingresar la información de vacaciones.",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
        return;
      }

      //Si es contrato indefinido tendrá sólo fecha de inicio, al contrario fecha de inicio y fin.
      if (values.Contrato_Fecha) {
        if (values.Tipo_Contrato) {
          if (values.Tipo_Contrato === "Indefinido") {
            // values.Contrato_Fecha_Inicio = momentToStringFechaInicio(values.Contrato_Fecha,databaseFormat);
            values.Contrato_Fecha_Inicio = convertirFechaString(moment.utc(values.Contrato_Fecha).clone(), 1).fechaString;
            // values.Contrato_Fecha_Inicio_Unix = momentToUnixFechaInicio(values.Contrato_Fecha);
            values.Contrato_Fecha_Inicio_Unix = convertirFechaString(values.Contrato_Fecha, 1).fechaUNIX;
          } else {
            // values.Contrato_Fecha_Inicio = momentToStringFechaInicio(values.Contrato_Fecha[0],databaseFormat);
            values.Contrato_Fecha_Inicio = convertirFechaString(moment.utc(values.Contrato_Fecha[0]).clone(), 1).fechaString;

            // values.Contrato_Fecha_Fin = momentToStringFechaFin(values.Contrato_Fecha[1],databaseFormat);
            values.Contrato_Fecha_Fin = convertirFechaString(moment.utc(values.Contrato_Fecha[1]).clone(), 2).fechaString;

            // values.Contrato_Fecha_Inicio_Unix = momentToUnixFechaInicio(values.Contrato_Fecha[0]);
            values.Contrato_Fecha_Inicio_Unix = convertirFechaString(values.Contrato_Fecha[0], 1).fechaUNIX;

            // values.Contrato_Fecha_Fin_Unix = momentToUnixFechaFin(values.Contrato_Fecha[1]);
            values.Contrato_Fecha_Fin_Unix = convertirFechaString(values.Contrato_Fecha[1], 2).fechaUNIX;
          }
        }
      }


      if (values.Fecha_Progresivas && moment.isMoment(values.Fecha_Progresivas)) {
        // values.Fecha_Progresivas_Unix = momentToUnixFechaInicio(values.Fecha_Progresivas);
        values.Fecha_Progresivas_Unix = convertirFechaString(values.Fecha_Progresivas, 1).fechaUNIX;

        // values.Fecha_Progresivas = momentToStringFechaInicio(values.Fecha_Progresivas,databaseFormat);
        values.Fecha_Progresivas = convertirFechaString(values.Fecha_Progresivas, 1).fechaString;
      }

      //si seleccionó régimen de vacaciones "otro" y no ingresó cuántos días son
      if (values.Requirio_Progresivas !== undefined) {
        if (
          values.Regimen_Vacaciones === -1 &&
          (values.otro == undefined || values.otro == null)
        ) {
          Swal.fire({
            title: "Ups...",
            text:
              "Debes ingresar la cantidad de días en el régimen de vacaciones.",
            icon: "warning",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Ok",
          });

          return;
        } else if (
          values.Regimen_Vacaciones === -1 &&
          !(values.otro == undefined || values.otro == null)
        ) {
          values.Regimen_Vacaciones = values.otro;
        }
      }

      // VALIDACIÓNES DE VACACIONES PROGRESIVAS:
      if (values.Requirio_Progresivas && moment.isMoment(values.Requirio_Progresivas)) {
        let mesesEnEmpresa = moment().diff(
          values.Contrato_Fecha_Inicio,
          "months"
        );
        let mesesTotales = values.Meses_Empleadores_Anteriores + mesesEnEmpresa;

        if (mesesTotales < 120) {
          Swal.fire({
            title: "Ups...",
            text:
              "El trabajador no pudo haber requerido legalmente vacaciones progresivas si no ha trabajado al menos 120 meses (10 años).",
            icon: "warning",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Ok",
          });

          return;
        }

        let aniosEnEmpresa = moment().diff(
          values.Contrato_Fecha_Inicio,
          "years"
        );

        if (aniosEnEmpresa < 3) {
          Swal.fire({
            title: "Ups...",
            text:
              "El trabajador debió haber trabajado al menos 3 años en la empresa para requerir vacaciones progresivas.",
            icon: "warning",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Ok",
          });

          return;
        }
      }

      values.Costo_Bruto_Diario = (values.Costo_Bruto_Diario === undefined ? 0 : values.Costo_Bruto_Diario);
      values.Costo_Liquido_Diario = (values.Costo_Liquido_Diario === undefined ? 0 : values.Costo_Liquido_Diario);

      // LOS RUT SIEMPRE SE DEBEN ENVIAR CON UPPERCASE EN CASO DE RUT CON K
      if (values.Rut) {
        values.Rut = values.Rut.toUpperCase();
      }

      ///////////////////////////////////////////////////////////////////////////////////////////////

      // values.Nombre = values.Nombre.trim();
      // values.Apellido_Paterno = values.Apellido_Paterno.trim();
      // values.Apellido_Materno = values.Apellido_Materno.trim();

      values.Nombre = (values.Nombre === undefined ? "" : values.Nombre.trim());
      values.Apellido_Paterno = (values.Apellido_Paterno === undefined ? "" : values.Apellido_Paterno.trim());
      values.Apellido_Materno = (values.Apellido_Materno === undefined ? "" : values.Apellido_Materno.trim());

      if (values.Fecha_De_Nacimiento && moment.isMoment(values.Fecha_De_Nacimiento)) {
        values.Fecha_De_Nacimiento = convertirFechaString(values.Fecha_De_Nacimiento, 1).fechaString;
      }
      props.editarTrabajador(values);
      setReiniciarImg(true);
    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const condicionalFechaContrato = () => {
    if (tipoFechaContrato === "Indefinido") {
      return (
        <DatePicker
          // format="DD-MM-YYYY"
          format={["DD-MM-YYYY"]}
          locale={locale}
          className="campos"
          style={{
            width: "100%",
          }}
          disabled={esCargaMasiva === true ? false : true}
        />
      );
    } else {
      return (
        <RangePicker
          // format="DD-MM-YYYY"
          format={["DD-MM-YYYY"]}
          locale={locale}
          className="campos"
          style={{
            width: "100%",
          }}
          disabled={esCargaMasiva === true ? false : true}
        />
      );
    }
  };

  const condicionalFechaProgresivas = () => {
    if (requirioProgre) {
      return (
        <Form.Item
          name={"Fecha_Progresivas"}
          label="¿Desde cuándo?"
          rules={[
            {
              required: true,
              message: "Obligatorio",
            },
          ]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            locale={locale}
            className="campos"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      );
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 9,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  let aux = props.Formulario._id;

  useEffect(
    (aux) => {
      let {
        //datos iniciales
        Cargo,
        OtrosCargos,
        Rut,
        Nombre,
        Apellido_Paterno,
        Apellido_Materno,
        Fecha_De_Nacimiento,
        Calle,
        Numero_Calle,
        Tipo_De_Vivienda,
        Numero_Departamento,
        Region,
        Comuna,
        Ciudad,
        Correo,
        Nacionalidad,
        Sexo,
        RegimenDeTrabajo,
        Codigo_Base,
        Telefono,

        //datos de contacto
        Contacto_Nombre,
        Contacto_Correo,
        Contacto_Fono_Fijo,
        Contacto_Fono_Celular,

        //datos familiares
        Estado_Civil,
        Cantidad_Hijos,

        Titulos_Profesionales,
        Especialidades,
        TiposDeDocumentos,
        EPP,

        //Contrato y Vacaciones
        Tipo_Contrato,
        Relevo,
        Contrato_Fecha_Inicio,
        Contrato_Fecha_Inicio_Unix,
        Contrato_Fecha_Fin,
        Contrato_Fecha_Fin_Unix,
        Requirio_Progresivas,
        Fecha_Progresivas,
        Fecha_Progresivas_Unix,
        Meses_Empleadores_Anteriores,
        Regimen_Vacaciones,


        Costo_Liquido_Diario,
        Costo_Bruto_Diario,

        CargaMasiva,
        
        //________CAMPOS NUEVO___________
        //Datos bancarios
        Numero_Cuenta_Bancaria,
        Banco,
        Forma_Pago,
        //Datos previsionales
        AFP,
        Sistema_Pension,
        Jubilado,
        Isapre_Fonasa,
        Isapre_Monetizacion,
        Isapre_UF,
        Isapre_Peso,
        Isapre_Cotizacion,
        //Datos otros estudios
        Nivel_Estudio,
        Licencia_Conducir,
        Tipo_Licencia_Conducir,

      } = props.Formulario;
  
      setTipoFechaContrato(Tipo_Contrato);
      setRequirioProgre(Requirio_Progresivas);
      setEsCargaMasiva(CargaMasiva);
      setEsRelevo(Relevo);

      if (Requirio_Progresivas) {
        if (Fecha_Progresivas) {

          // form.setFieldsValue({Fecha_Progresivas: moment(Fecha_Progresivas)});
          form.setFieldsValue({ Fecha_Progresivas: moment.utc(Fecha_Progresivas) });

        } else {

          // form.setFieldsValue({Fecha_Progresivas: moment.unix(Fecha_Progresivas_Unix)});
          let fecha_progresiva = convertirFechaString(moment.unix(Fecha_Progresivas_Unix), 1).fechaString
          form.setFieldsValue({ Fecha_Progresivas: moment.utc(fecha_progresiva) });

        }
      }

      let fechaMoment = moment.utc(Fecha_De_Nacimiento);

      if (props.editarCerrado) {
        form.setFieldsValue({
          Rut,
        });
        props.cambiarEditarCerrado();
      }

      if (!Contrato_Fecha_Inicio) {
        form.setFieldsValue({
          Contrato_Fecha: null,
        });
      } else if (Tipo_Contrato === "Indefinido") {
        if (Contrato_Fecha_Inicio_Unix) {
          let horasRestarInicio = moment.utc(moment.unix(Contrato_Fecha_Inicio_Unix)).hours();

          let fechaStringInicio = convertirFechaString(moment.unix(Contrato_Fecha_Inicio_Unix).subtract(horasRestarInicio, 'hours'), 1).fechaString;

          form.setFieldsValue({ Contrato_Fecha: moment.utc(fechaStringInicio) });
        } else {
          form.setFieldsValue({ Contrato_Fecha: moment(Contrato_Fecha_Inicio) });
        }
      } else {
        if (Contrato_Fecha_Inicio_Unix) {
          let horasRestarInicio = moment.utc(moment.unix(Contrato_Fecha_Inicio_Unix)).hours();
          let horasRestarFin = moment.utc(moment.unix(Contrato_Fecha_Fin_Unix)).hours() + 1;

          let fechaStringInicio = convertirFechaString(moment.unix(Contrato_Fecha_Inicio_Unix).subtract(horasRestarInicio, 'hours'), 1).fechaString;
          let fechaStringFin = convertirFechaString(moment.utc(moment.unix(Contrato_Fecha_Fin_Unix).subtract(horasRestarFin, 'hours')), 2).fechaString;

          form.setFieldsValue({
            Contrato_Fecha: [moment.utc(fechaStringInicio), moment.utc(fechaStringFin)],
          });
        } else {
          form.setFieldsValue({
            Contrato_Fecha: [moment(Contrato_Fecha_Inicio), moment(Contrato_Fecha_Fin)],
          });
        }
      }

      if (
        Regimen_Vacaciones !== 15 &&
        Regimen_Vacaciones !== 20 &&
        Regimen_Vacaciones !== undefined &&
        Regimen_Vacaciones !== null
      ) {
        form.setFieldsValue({
          Regimen_Vacaciones: -1,
          otro: Regimen_Vacaciones,
        });
      } else {
        form.setFieldsValue({
          Regimen_Vacaciones,
        });
      }

      form.setFieldsValue({
        Rut,
        Cargo,
        OtrosCargos,
        Nombre,
        Apellido_Paterno,
        Apellido_Materno,
        Fecha_De_Nacimiento: fechaMoment,
        Calle,
        Numero_Calle,
        Tipo_De_Vivienda,
        Numero_Departamento,
        Region,
        Comuna,
        Ciudad,
        Correo,
        Nacionalidad,
        Sexo,
        RegimenDeTrabajo,
        Codigo_Base,
        Telefono,

        //datos de contacto

        Contacto_Nombre,
        Contacto_Correo,
        Contacto_Fono_Fijo,
        Contacto_Fono_Celular,

        //datos familiares
        Estado_Civil,
        Cantidad_Hijos,

        Titulos_Profesionales,
        Especialidades,
        TiposDeDocumentos,
        EPP,

        Tipo_Contrato,
        Relevo,
        Requirio_Progresivas,
        Meses_Empleadores_Anteriores,


        Costo_Liquido_Diario,
        Costo_Bruto_Diario,

        //________CAMPOS NUEVO___________
        //Datos bancarios
        Numero_Cuenta_Bancaria,
        Banco,
        Forma_Pago,
        //Datos previsionales
        AFP,
        Sistema_Pension,
        Jubilado,
        Isapre_Fonasa,
        Isapre_Monetizacion,
        Isapre_UF,
        Isapre_Peso,
        Isapre_Cotizacion,
        //Datos otros estudios
        Nivel_Estudio,
        Licencia_Conducir,
        Tipo_Licencia_Conducir,
      });

      // document.getElementById('pruebaClick').children[0].click();
      // document.getElementById('pruebaClick').children[0].click();
      // console.log("document.getElementById('pruebaClick')")
      // console.log(document.getElementById('pruebaClick').children[0]);
    },
    [aux]
  );

  const testNamesRegex = /^([a-z,A-Z,á,é,í,ó,ú,â,ê,ô,ã,õ,ç,Á,É,Í,Ó,Ú,Â,Ê,Ô,Ã,Õ,Ç,ü,ñ,Ü,Ñ," "]+)$/;

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={true}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={Trabajador} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Editar Trabajador"
          />
        </Col>

        <Col
          xs={{ span: 24, offset: 0 }} 
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }} 
          lg={{ span: 8, offset: 0 }} 
          xl={{ span: 8, offset: 0 }}
          xxl={{ span: 8, offset: 0 }}
        >
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Row justify="center">
              <Col
                span={8}
                style={{ marginRight: "90px", marginBottom: "20px" }}
              >
                <SubirFoto
                  avatar={props.Formulario.Imagen}
                  ObtenerImagen={ObtenerImagen}
                  reiniciarImg={reiniciarImg}
                  setReiniciarImg={setReiniciarImg}
                />
              </Col>

              <Col span={24}>
                <p style={{ fontFamily: "MavenPro-Regular" }}>
                  Te recomendamos que la fotografía no exceda los 5 MB de peso
                </p>

                <Card className={"codigos-card"}>
                  <div
                    className={"codigo-wrapper"}
                    style={{ marginBottom: "15px" }}
                  >
                    <h3 style={{ fontFamily: "MavenPro-SemiBold", margin: 0 }}>
                      Código de sistema:
                    </h3>
                    <h3 style={{ fontFamily: "MavenPro-Regular", margin: 0 }}>
                      {props.Formulario._id}
                    </h3>
                  </div>

                  <div className={"codigo-wrapper"}>
                    <h3 style={{ fontFamily: "MavenPro-SemiBold", margin: 0 }}>
                      Código base:
                    </h3>
                    <h3 style={{ fontFamily: "MavenPro-Regular", margin: 0 }}>
                      {!props.Formulario.Codigo_Base
                        ? "No Aplica"
                        : props.Formulario.Codigo_Base}
                    </h3>
                  </div>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col
          xs={{ span: 24, offset: 0 }} 
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }} 
          lg={{ span: 15, offset: 1 }} 
          xl={{ span: 15, offset: 1 }}
          xxl={{ span: 15, offset: 1 }}
        >

          <Collapse
            bordered={false}
            defaultActiveKey={["0"]}
            className="formulario-trabajador site-collapse-custom-collapse "
            onChange={(e) => {
              if (e.includes("8")) setAbrioContrato(true);
              if (e.includes("9")) setAbrioVacaciones(true);
            }}
          >
            {/* -----------------------------------FORMULARIO DATOS PERSONALES---------------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Datos Personales
                </h4>
              }
              key="1"
              className="site-collapse-custom-panel pruebaClickClass"
              id="pruebaClick"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#e60aa3",
                  }}
                >
                  Obligatorio
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={personal}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                style={{ marginTop: '20px' }}
                label="RUT"
                name={"Rut"}
                validateStatus={validRutTrabajador}
                onChange={(e) => {
                  let valor = e.target.value;

                  if (valor) {
                    let rut = Rut.formateaRut(valor);
                    let rutValidacion = Rut.procesarRut(rut);

                    setValidRutTra(rutValidacion);

                    if (rut !== undefined) {
                      form.setFieldsValue({ Rut: rut });
                    } else {
                      form.setFieldsValue({ Rut: valor });
                    }
                  }
                }}
                rules={[
                  {
                    min: 11,
                    max: 12,
                    required: true,
                    message: "Debe introducir el RUT!",
                  },
                ]}
                hasFeedback
                help="Ingrese el RUT válido con código verificador"
              >
                <Input
                  maxLength={12}
                  name="Rut"
                  className="campos"
                  placeholder="xx.xxx.xxx-x"
                />
              </Form.Item>

              <Form.Item
                label="Cargo del Trabajador"
                name={"Cargo"}
                style={{
                  marginTop: "20px",
                }}

                rules={[
                  {
                    required: true,
                    message: "Seleccione el cargo",
                  },
                ]}
              >
                <Select
                  className="campos"
                  name="Cargo"
                  placeholder="Seleccionar..."
                  onSelect={(valor) => {
                    props.TomarValoresEditar("Cargo", valor);
                  }}
                >
                  {props.cargosTrabajador.map((value, index) => (
                    <Select.Option key={index} name="Cargo" value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <p style={{ margin: "0px", lineHeight: '16px' }}>
                    Otras funciones
                    <br />
                    del trabajador
                  </p>
                }
                name={"OtrosCargos"}
                style={{
                  marginTop: "20px",
                }}
              >
                <Select
                  className="campos"
                  name="OtrosCargos"
                  placeholder="Selecciona cargo"
                  onSelect={(valor) => {
                    props.TomarValoresEditar("Cargo", valor);
                  }}
                  mode='multiple'
                >
                  {props.cargosTrabajador.map((value, index) => (
                    <Select.Option key={index} name="OtrosCargos" value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name={"Nombre"}
                label="Nombres:"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir el nombre completo",
                  },
                  {
                    validator: (_, value) => {

                      const isValid = testNamesRegex.test(value);

                      if (isValid) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject('Ingrese un nombre válido sin símbolos ni números.');
                      }
                    }
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Nombre", valor);
                  }}
                  name="Nombre"
                  className="campos"
                />
              </Form.Item>

              <Form.Item
                name={"Apellido_Paterno"}
                label="Apellido paterno:"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir su apellido paterno",
                  },
                  {
                    validator: (_, value) => {

                      const isValid = testNamesRegex.test(value);

                      if (isValid) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject('Ingrese un apellido válido sin símbolos ni números.');
                      }
                    }
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Apellido_Paterno", valor);
                  }}
                  name="Apellido_Paterno"
                  className="campos"
                />
              </Form.Item>

              <Form.Item
                name={"Apellido_Materno"}
                label="Apellido materno:"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir su apellido materno",
                  },
                  {
                    validator: (_, value) => {

                      const isValid = testNamesRegex.test(value);

                      if (isValid) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject('Ingrese un apellido válido sin símbolos ni números.');
                      }
                    }
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Apellido_Materno", valor);
                  }}
                  name="Apellido_Materno"
                  className="campos"
                />
              </Form.Item>

              <Form.Item
                name={"Fecha_De_Nacimiento"}
                label="Fecha de nacimiento:"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Debe introducir la fecha de nacimiento",
                  },
                ]}
              >
                <DatePicker
                  locale={locale}
                  format="DD-MM-YYYY"
                  // onPanelChange={(e, Apellido_Paterno) => {
                  //   props.TomarValoresEditar(e, Apellido_Paterno);
                  // }}
                  onChange={(value) => {
                    props.TomarValoresEditar("Fecha_De_Nacimiento", value);
                  }}
                  name="Fecha_De_Nacimiento"
                  className="campos"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Calle"
                name={"Calle"}
                rules={[
                  {
                    required: true,
                    message: "debe ingresar la dirección",
                  },
                ]}
                hasFeedback
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Calle", valor);
                  }}
                  name="Calle"
                  className="campos"
                  id="warning2"
                />
              </Form.Item>

              <Form.Item name={"Numero_Calle"} label="Número" hasFeedback>
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Numero_Calle", valor);
                  }}
                  name="Numero_Calle"
                  className="campos"
                  type="number"
                />
              </Form.Item>

              <Form.Item
                name={"Tipo_De_Vivienda"}
                rules={[
                  {
                    required: true,
                    message: "¿Qué tipo de lugar es?",
                  },
                ]}
                label="Lugar"
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  key={"Tipo_De_Vivienda"}
                  onChange={(valor) => {
                    props.TomarValoresEditar(
                      "Tipo_De_Vivienda",
                      valor.target.value
                    );
                  }}
                >
                  <Radio value={"Casa"}>Casa</Radio>
                  <Radio value={"Departamento"}>Departamento</Radio>
                  <Radio value={"Oficina"}>Oficina</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name={"Numero_Departamento"}
                label="N° del Establecimiento"
              >
                <Input
                  name="Numero_Departamento"
                  type="number"
                  //disabled={(props.Formulario.Tipo_De_Vivienda === "Departamento" ? false : true)}
                  onChange={(valor) => {
                    props.TomarValoresEditar(
                      "Numero_Departamento",
                      valor.target.value
                    );
                  }}
                  className="campos"
                />
              </Form.Item>

              <Form.Item
                label="Región"
                name={"Region"}
                rules={[
                  {
                    required: true,
                    message: "¿En que region está?",
                  },
                ]}
              >
                <Select
                  onSelect={(value) => {
                    form.resetFields(["Ciudad", "Comuna"]);
                    props.TomarValoresEditar("Region", value);
                    props.TomarValoresEditar("Ciudad", "");
                    props.TomarValoresEditar("Comuna", "");
                    props.buscarProvincias(value);
                  }}
                  className="campos"
                  name="Region"
                  placeholder="Selecciona región"
                >
                  {props.regiones.map((value, index) => (
                    <Select.Option name="Region" key={index} value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Provincia"
                name={"Ciudad"}
                rules={[
                  {
                    required: true,
                    message: "¿En qué ciudad está?",
                  },
                ]}
              >
                <Select
                  className="campos"
                  name="Ciudad"
                  placeholder="Selecciona provincia"
                  onSelect={(value) => {
                    form.resetFields(["Comuna"]);
                    props.buscarComuna(value);
                    props.TomarValoresEditar("Ciudad", value);
                  }}
                >
                  {props.ciudadesSeleccionadas.map((value, index) => (
                    <Select.Option name="Ciudad" key={index} value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Comuna"
                name={"Comuna"}
                rules={[
                  {
                    required: true,
                    message: "¿En qué comuna está?",
                  },
                ]}
              >
                <Select
                  onSelect={(value) => {
                    props.TomarValoresEditar("Comuna", value);
                  }}
                  className="campos"
                  name="Comuna"
                  placeholder="Selecciona comuna"
                >
                  {props.comunasSeleccionadas.map((value, index) => (
                    <Select.Option name="Comuna" key={index} value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name={"Correo"}
                label="Correo electrónico"
                rules={[
                  {
                    type: "email",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Correo", valor);
                  }}
                  name="Correo"
                  className="campos"
                />
              </Form.Item>

              <Form.Item
                name={"Telefono"}
                label="Teléfono"
                rules={[
                  {
                    validator: (_, value) => {

                      const { isValid, errorMsg } = isTelefonoValid(value);

                      if (isValid) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(errorMsg);
                      }
                    }
                  },
                ]}
                normalize={((value) => {
                  let noSpaceEntry = value.replace(/\s/g, ''); //quitando espacios por si acaso
                  return noSpaceEntry;
                })}
              >
                <Input
                  name={"Telefono"}
                  className="campos"
                  placeholder={"+569XXXXXXXX"}
                  maxLength={12}
                />
              </Form.Item>

              <Form.Item
                label="Nacionalidad"
                name={"Nacionalidad"}
                rules={[
                  {
                    required: true,
                    message: "¿Cuál es su nacionalidad?",
                  },
                ]}
              >
                <Select
                  onSelect={(value) => {
                    props.TomarValoresEditar("Nacionalidad", value);
                  }}
                  className="campos"
                  name="Ciudad"
                  placeholder="Nacionalidad"
                >
                  <Select.Option value="Chilena" key={"Chilena"} name="Nacionalidad">
                    Chilena
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Sexo"
                name={"Sexo"}
                rules={[
                  {
                    required: true,
                    message: "Debe ingresar el sexo del trabajador",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  key={"Sexo"}
                  value={props.Formulario.Sexo}
                >
                  <Radio value={"Masculino"}>Masculino</Radio>
                  <Radio value={"Femenino"}>Femenino</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="Régimen de trabajo"
                name={"RegimenDeTrabajo"}
                style={{
                  marginTop: "20px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Seleccione el régimen",
                  },
                ]}
              >
                <Select
                  onSelect={(value) => {
                    let valor = {
                      _id: value,
                    };
                    props.TomarValoresEditar("RegimenDeTrabajo", valor);
                  }}
                  className="campos"
                  name="RegimenDeTrabajo"
                  placeholder="Selecciona cargo"
                >
                  {props.regimenes.map((value, index) => (
                    <Select.Option key={index} name="Cargo" value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              
              <Form.Item
                label="Código base"
                name="Codigo_Base"
                rules={[
                  {
                    required: false,
                    message: "Debe introducir un código base",
                  },
                ]}
              >
                <Input
                  name="Codigo_Base"
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Codigo_Base", valor);
                  }}
                  placeholder="Codigo Base"
                />
              </Form.Item>
            </Panel>

            {/* -----------------------------------FORMULARIO DATOS DE CONTACTO---------------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Datos de Contacto de Emergencia
                </h4>
              }
              key="2"
              className=" site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#061fcb",
                  }}
                >
                  Opcional
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={contacto}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item name={"Contacto_Nombre"} label="Nombres:">
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Contacto_Nombre", valor);
                  }}
                  name="Contacto_Nombre"
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                name={"Contacto_Correo"}
                label="Correo electrónico"
                rules={[
                  {
                    type: "email",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Contacto_Correo", valor);
                  }}
                  name="Contacto_Correo"
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                name={"Contacto_Fono_Fijo"}
                label="Fono Contacto Fijo"
                hasFeedback
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Contacto_Fono_Fijo", valor);
                  }}
                  name={"Contacto_Fono_Fijo"}
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                name="Contacto_Fono_Celular"
                label="Fono Contacto Celular"
                hasFeedback
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Contacto_Fono_Celular", valor);
                  }}
                  name="Contacto_Fono_Celular"
                  className="campos"
                />
              </Form.Item>
            </Panel>

            {/* -----------------------------FORMULARIO DATOS FAMILIARES Y LEGALES------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Datos Familiares y Legales
                </h4>
              }
              key="3"
              className=" site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#e60aa3",
                  }}
                >
                  Obligatorio
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={familiares}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Estado civil"
                name="Estado_Civil"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir su estado civil",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  onChange={(value) => {
                    props.TomarValoresEditar(
                      "Estado_Civil",
                      value.target.value
                    );
                  }}
                  initialValues={false}
                >
                  <Radio value={"Soltero/a"}>Soltero/a</Radio>
                  <Radio value={"Casado/a"}>Casado/a</Radio>
                  <Radio value={"Viudo/a"}>Viudo/a</Radio>
                  <Radio value={"Divorciado/a"}>Divorciado/a</Radio>
                </Radio.Group>
              </Form.Item>

              <hr className="division" />
              <br />
              <h3 style={{ textAlign: "center", fontFamily: "MavenPro-Bold" }}>
                Datos Hijos
              </h3>
              <Form.Item
                name="Cantidad_Hijos"
                label="Número de hijos:"
                rules={[
                  {
                    required: true,
                    message: "Se debe ingresar cantidad de hijos",
                  },
                ]}
              >
                <Input
                  name="Cantidad_Hijos"
                  className="campos"
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Cantidad_Hijos", valor);
                  }}
                />
              </Form.Item>
            </Panel>
            {/* ---------------------------------------------TÍTULOS------------------------------------------------ */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Titulos
                </h4>
              }
              key="4"
              className=" site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#061fcb",
                  }}
                >
                  Opcional
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={titulos_icon}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Títulos"
                style={{ marginTop: "20px" }}
                name="Titulos_Profesionales"
              >
                <Select
                  mode="multiple"
                  className="campos"
                  name="Titulos_Profesionales"
                  placeholder="Selecciona títulos"
                >
                  {props.titulos.map((value, index) => (
                    <Select.Option
                      name="Titulos_Profesionales"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* --------------------------------------------ESPECIALIDADES------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Especialidades
                </h4>
              }
              key="5"
              className=" site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#061fcb",
                  }}
                >
                  Opcional
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={especialidades_icon}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Especialidades"
                style={{ marginTop: "20px" }}
                name="Especialidades"
              >
                <Select
                  mode="multiple"
                  className="campos"
                  name="Especialidades"
                  placeholder="Seleccione especialidades"
                >
                  {props.especialidades.map((value, index) => (
                    <Select.Option
                      name="Especialidades"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* ------------------------------------------- EPPS ------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  E.P.P.
                </h4>
              }
              key="6"
              className=" site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#061fcb",
                  }}
                >
                  Opcional
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={epp}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item label="E.P.P" style={{ marginTop: "20px" }} name="EPP">
                <Select
                  mode="multiple"
                  className="campos"
                  name="EPP"
                  placeholder="Seleccione E.P.P."
                >
                  {props.epps.map((value, index) => (
                    <Select.Option name="EPP" key={index} value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* ------------------------------------------- DOCUMENTOS ------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Documentos
                </h4>
              }
              key="7"
              className=" site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#061fcb",
                  }}
                >
                  Opcional
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={contrato}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Documentos"
                style={{ marginTop: "20px" }}
                name="TiposDeDocumentos"
              >
                <Select
                  mode="multiple"
                  className="campos"
                  name="TiposDeDocumentos"
                  placeholder="Seleccione Documentos requeridos"
                >
                  {props.documentos.map((value, index) => (
                    <Select.Option
                      name="TiposDeDocumentos"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* -----------------------------------CARACTERÍSTICAS DE CONTRATO---------------------------------------- */}

            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Características del contrato
                </h4>
              }
              key="8"
              className="site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#e60aa3",
                  }}
                >
                  Obligatorio
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={contrato}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              {
                esCargaMasiva !== true ?
                  (
                    <>
                      <Alert
                        message="Si desea modificar el contrato del trabajador, hacerlo en el módulo de contratos y finiquitos."
                        type="info"
                        showIcon
                        style={{
                          width: '85%',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          marginTop: '20px',
                          display: disabledContratoInputs ? 'block' : 'none'
                        }}
                      />
                    </>
                  )
                  :
                  (
                    <>
                    </>
                  )
              }

              <Form.Item
                name={"Tipo_Contrato"}
                label="Tipo de contrato"
                style={{ marginTop: "20px" }}
                rules={[
                  {
                    required: esCargaMasiva === true ? true : false,
                    message: "Obligatorio",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  onChange={(e) => {
                    setTipoFechaContrato(e.target.value);

                    form.setFieldsValue({
                      Contrato_Fecha: null,
                    });
                  }}
                  disabled={esCargaMasiva === true ? false : true}
                >
                  <Radio value={"Plazo fijo"}>Plazo Fijo</Radio>
                  <Radio value={"Indefinido"}>Indefinido</Radio>
                </Radio.Group>
              </Form.Item>
              {/* //nuevo campo relevo// */}
              <Form.Item
                name={"Relevo"}
                label="Relevo"
                style={{ marginTop: "20px" }}
                rules={[
                  {
                    required: esCargaMasiva === true ? true : false,
                    message: "Obligatorio",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  onChange={(e) => {
                    setEsRelevo(e.target.value);
                  }}
                  disabled={esCargaMasiva === true ? false : true}
                >
                  <Radio value={true}>Si</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              {/* //nuevo campo relevo// */}

              <Form.Item
                name={"Contrato_Fecha"}
                label="Fecha de contratación"
                rules={[
                  {
                    required: esCargaMasiva === true ? true : false,
                    message: "Obligatorio",
                  },
                ]}
              >
                {condicionalFechaContrato()}

              </Form.Item>

              <Form.Item
                name={"Costo_Bruto_Diario"}
                label={<p style={{ margin: "0px" }}>Costo Bruto Diario</p>}
                rules={[
                  {
                    required: esCargaMasiva === true ? true : false,
                    message: "Obligatorio",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  className="campos"
                  // max={20}
                  // maxLength={20}
                  onChange={(e) => {
                    let valor = e;
                    props.TomarValoresEditar("Costo_Bruto_Diario", valor);
                  }}
                  disabled={esCargaMasiva === true ? false : true}
                />
              </Form.Item>

              <Form.Item
                name={"Costo_Liquido_Diario"}
                label={<p style={{ margin: "0px" }}>Costo Liquido Diario</p>}
                rules={[
                  {
                    required: esCargaMasiva === true ? true : false,
                    message: "Obligatorio",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  className="campos"
                  // max={20}
                  // maxLength={20}
                  onChange={(e) => {
                    let valor = e;
                    props.TomarValoresEditar("Costo_Bruto_Diario", valor);
                  }}
                  disabled={esCargaMasiva === true ? false : true}
                />
              </Form.Item>



            </Panel>

            {/* -----------------------------------VACACIONES---------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Vacaciones
                </h4>
              }
              key="9"
              className="site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#e60aa3",
                  }}
                >
                  Obligatorio
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={vacaciones}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                name={"Requirio_Progresivas"}
                label={
                  <p style={{ margin: "0px" }}>
                    ¿Requirió vacaciones
                    <br />
                    progresivas?
                  </p>
                }
                style={{ marginTop: "20px" }}
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >
                <Radio.Group
                  className={"input-small"}
                  style={{ display: "flex" }}
                  onChange={(e) => {
                    setRequirioProgre(e.target.value);

                    form.setFieldsValue({
                      Fecha_Progresivas: null,
                    });
                  }}
                >
                  <Radio value={true}>Sí</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              {condicionalFechaProgresivas()}

              <Form.Item
                name={"Meses_Empleadores_Anteriores"}
                label={
                  <p style={{ margin: "0px" }}>
                    Meses con empleadores
                    <br />
                    anteriores
                  </p>
                }
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >
                <InputNumber type="number" min={0} className={"campos"} />
              </Form.Item>

              <Form.Item
                name={"Regimen_Vacaciones"}
                label="Régimen de vacaciones"
                // rules={[
                //   {
                //     required: true,
                //     message: "Obligatorio",
                //   },
                // ]}
                style={{ marginBottom: "5px" }}
                validateStatus={validateOtro}
                hasFeedback={true}
                rules={[
                  {
                    required: true,
                    message: "Debe seleccionar el régimen de vacaciones.",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  onChange={(e) => {
                    if (e.target.value === -1) {
                      setOtroSelected(true);
                      setValidateOtro("error");
                    } else {
                      setOtroSelected(false);
                      setValidateOtro("success");

                      form.setFieldsValue({
                        otro: undefined,
                      });
                    }

                    // if (form.getFieldsValue(['Regimen_Vacaciones'] !== -1) && form.getFieldsValue(['otro']) !== ) {

                    // }
                  }}
                >
                  <Radio value={15}>15 días</Radio>
                  <Radio value={20}>20 días</Radio>
                  <Radio value={-1}>Otro</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item name={"otro"} label=" " className="item-otro">
                <InputNumber
                  placeholder={"otra cantidad"}
                  className="input-otro"
                  type="number"
                  min={0}
                  disabled={!otroSelected}
                  onChange={async (e) => {
                    if (e) {
                      await setValidateOtro("success");
                    } else {
                      await setValidateOtro("error");
                    }
                  }}
                />
              </Form.Item>
            </Panel>

            {/* -----------------------------------Datos bancarios NUEVO---------------------------------------- */}
            <Panel
            header={
                <h4
                style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                }}
                >
                Datos bancarios
                </h4>
            }
            key="10"
            className="site-collapse-custom-panel"
            extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#061fcb",
                  }}
                >
                  Opcional
                </h4>,
                <img
                key="2"
                src={flecha}
                style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                }}
                alt="icono"
                />,
                <img
                key="3"
                src={bancario}
                style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                }}
                alt="icono"
                />,
            ]}
            >
              <Form.Item
                  label="N° de cuenta bancaria"
                  name={"Numero_Cuenta_Bancaria"}
                  style={{ marginTop: "20px" }}
                  // rules={[
                  // {
                  //     required: true,
                  //     message: "¿Cuál es su n° de cuenta bancaria?",
                  // },
                  // ]}
                >
                <InputNumber
                style={{ width: "100%" }}
                type="number"
                />
              </Form.Item>

              <Form.Item
                label="Banco"
                name={"Banco"}
                // rules={[
                // {
                //     required: true,
                //     message: "¿Cuál es su banco?",
                // },
                // ]}
              >
                <Select>
                  <Select.Option value="no aplica">No aplica</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Forma de pago "
                name={"Forma_Pago"}
                // rules={[
                // {
                //   required: true,
                //   message: "¿Cuál es su forma de pago?",
                // },
                // ]}
              >
                <Select>
                  <Select.Option value="no aplica">No aplica</Select.Option>
                </Select>
              </Form.Item>
            </Panel>

            {/* -----------------------------------Datos previsionales NUEVO---------------------------------------- */}
            <Panel
            header={
                <h4
                style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                }}
                >
                Datos previsionales
                </h4>
            }
            key="11"
            className="site-collapse-custom-panel"
            extra={[
              <h4
                key="1"
                style={{
                  position: "absolute",
                  top: 5,
                  right: 55,
                  color: "#061fcb",
                }}
              >
                Opcional
              </h4>,
              <img
              key="2"
              src={flecha}
              style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
              }}
              alt="icono"
              />,
              <img
              key="3"
              src={previsional}
              style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
              }}
              alt="icono"
              />,
            ]}
            >
              <Form.Item
                label="AFP"
                name={"AFP"}
                style={{ marginTop: "20px" }}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su AFP?",
                },
                ]}
              >
                <Select>
                  <Select.Option value="afp">Sin Definir</Select.Option>
                  <Select.Option value="capital">AFP Capital</Select.Option>
                  <Select.Option value="cuprum">AFP Cuprum</Select.Option>
                  <Select.Option value="habitat">AFP Habitat</Select.Option>
                  <Select.Option value="planvital">AFP Planvital</Select.Option>
                  <Select.Option value="provida">AFP Provida</Select.Option>
                  <Select.Option value="modelo">AFP Modelo</Select.Option>
                  <Select.Option value="uno">AFP UNO</Select.Option>
                  <Select.Option value="canaempu">Canempu</Select.Option>
                  <Select.Option value="empart">Empart</Select.Option>
                  <Select.Option value="sss">Servicio Seguro Social</Select.Option>
                  <Select.Option value="triomar">Triomar</Select.Option>
                  <Select.Option value="capremer">Capremer</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Sistema de pensiones"
                name={"Sistema_Pension"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su sistema de pensiones?",
                },
                ]}
              >
                <Select>
                  <Select.Option value="N">Nuevo</Select.Option>
                  <Select.Option value="C">Antiguo con Isapre</Select.Option>
                  <Select.Option value="T">Antiguo con Fonasa</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Jubilado"
                name={"Jubilado"}
                rules={[
                {
                  //required: true,
                  message: "¿Es usted jubilado?",
                },
                ]}
              >
                <Select>
                  <Select.Option value="0">Activo no pensionado</Select.Option>
                  <Select.Option value="1">Pensionado y cotiza</Select.Option>
                  <Select.Option value="2">Pensionado y no cotiza</Select.Option>
                  <Select.Option value="3">{`Activo > 60 o 65 años`}</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Isapre / Fonasa"
                name={"Isapre_Fonasa"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su Isapre / Fonasa?",
                },
                ]}
              >
                <Select>
                  <Select.Option value="isapre">Sin Definir</Select.Option>
                  <Select.Option value="fonasa">FONASA</Select.Option>
                  <Select.Option value="banmedica">ISAPRE Banmedica</Select.Option>
                  <Select.Option value="consalud">ISAPRE Consalud</Select.Option>
                  <Select.Option value="vidatres">ISAPRE Vidatres</Select.Option>
                  <Select.Option value="colmena">ISAPRE Colmena</Select.Option>
                  <Select.Option value="cruzblanca">ISAPRE Cruz-Blanca</Select.Option>
                  <Select.Option value="nuevamasvida">ISAPRE Nueva Mas Vida</Select.Option>
                  <Select.Option value="bancoestado">ISAPRE Banco Estado</Select.Option>
                  <Select.Option value="fusat">ISAPRE Fusat</Select.Option>
                  <Select.Option value="chuquicamata">ISAPRE Chuquicamata</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Isapre Monetización"
                name={"Isapre_Monetizacion"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su Isapre Monetización?",
                },
                ]}
              >
                <Select>
                  <Select.Option value="%">7%</Select.Option>
                  <Select.Option value="U">U.F.</Select.Option>
                  <Select.Option value="P">Pesos</Select.Option>
                  <Select.Option value="F">7% + UF</Select.Option>
                  <Select.Option value="S">UF + Pesos</Select.Option>
                  <Select.Option value="Z">7% + UF + Pesos</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Isapre UF"
                name={"Isapre_UF"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su Isapre UF?",
                },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  // className="campos"
                  // max={20}
                  // maxLength={20}
                />
              </Form.Item>

              <Form.Item
                label="Isapre peso"
                name={"Isapre_Peso"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su Isapre peso?",
                },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  // className="campos"
                  // max={20}
                  // maxLength={20}
                />
              </Form.Item>

              <Form.Item
                label="Isapre cotización"
                name={"Isapre_Cotizacion"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su Isapre cotización",
                },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  //className="campos"
                  // max={20}
                  // maxLength={20}
                />
              </Form.Item>
                
            </Panel>


            {/* -----------------------------------Datos Otros Estudios NUEVO---------------------------------------- */}
            <Panel
            header={
                <h4
                style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                }}
                >
                Datos otros estudios
                </h4>
            }
            key="12"
            className="site-collapse-custom-panel"
            extra={[
              <h4
                key="1"
                style={{
                  position: "absolute",
                  top: 5,
                  right: 55,
                  color: "#061fcb",
                }}
              >
                Opcional
              </h4>,
              <img
              key="2"
              src={flecha}
              style={{
                  position: "absolute",
                  bottom: 3,
                  right: 11,
                  width: 28,
                  color: "#48B9FD",
              }}
              alt="icono"
              />,   
              <img
              key="3"
              src={estudios}
              style={{
                  position: "absolute",
                  left: 5,
                  bottom: 3,
                  width: 28,
              }}
              alt="icono"
              />,
            ]}
            >
              <Form.Item
                label="Nivel de estudios"
                name={"Nivel_Estudio"}
                style={{ marginTop: "20px" }}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su nivel de estudio?",
                },
                ]}
              >
                <Select>
                  <Select.Option value="0">Ninguno</Select.Option>
                  <Select.Option value="1">Básico</Select.Option>
                  <Select.Option value="2">Medio</Select.Option>
                  <Select.Option value="3">Técnico</Select.Option>
                  <Select.Option value="4">Universitario</Select.Option>
                  <Select.Option value="5">Postgraduado</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Licencia conducir"
                name={"Licencia_Conducir"}
                // rules={[
                // {
                //   required: true,
                //   message: "¿Tiene licencia de conducir?",
                // },
                // ]}
              >
                <Radio.Group>
                  <Radio value={true}>Sí</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              
              <Form.Item
                label="Tipo"
                name={"Tipo_Licencia_Conducir"}
                rules={[
                {
                  //required: true,
                  message: "¿De que tipo es su licencia de conducir?",
                },
                ]}
              >
                <Select>
                  <Select.Option value="N">Sin licencia</Select.Option>
                  <Select.Option value="A1">A1</Select.Option>
                  <Select.Option value="A2">A2</Select.Option>
                  <Select.Option value="A3">A3</Select.Option>
                  <Select.Option value="A4">A4</Select.Option>
                  <Select.Option value="B">B</Select.Option>
                  <Select.Option value="C">C</Select.Option>
                  <Select.Option value="D">D</Select.Option>
                  <Select.Option value="E">E</Select.Option>
                  <Select.Option value="F">F</Select.Option>
                </Select>
              </Form.Item>
            </Panel>
          </Collapse>

        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>
      </Row>

      {/* <span>
        <style jsx="true">
          {`
            .ant-form-item-required {
              height: fit-content !important;
            }
          `}
        </style>
      </span> */}
    </Form>
  );
};

export default FormularioTrabajadorEditarNuevo;
