import React, { Component } from "react";
import "./reporteUtilizacionRelevo.css";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import API from "../../Redux/Api/api";
import Swal from "sweetalert2";
import { Layout, Row, Col, DatePicker, Form, Select, Button, InputNumber, Card, Modal, Table  } from "antd";
import { EyeFilled } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/es_ES";
import { convertirFechaString } from "../../funcionesGenericas/fechas";
import moment from "moment";
import { Line } from '@ant-design/charts';
import descargarExcel from "../../img/icons/descargarExcel.svg";
import Excel from "../../img/icons-sidebar/blancas/IconExcel.svg";

import {
    obtenerCargos,
    obtenerReporteUtilizacionRelevos,
    obtenerDetalleCurvaRelevos,
    descargarExcelDetalleRelevo
} from './reporteUtilizacionRelevoApis';

import Relevo from "../../img/icons/relevo.svg";
import imgAlerta from "../../img/icons/alerta-amarilla.svg";

const { Column } = Table;

const { RangePicker } = DatePicker;
const { Header, Content } = Layout;


class reporteUtilizacionRelevo extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            cargos: [],
            loading: false,
            mostrarModalReporteActividadTrabajador: false,
            dataRelevos: [],
            cargoReporte: [],
            fechaReporte: moment(),
            numeroPersonas: undefined,
            detalle: [],
            modalDetalle: false,
            promedioRelevos: 0,
            cantidadContratosRelevo: 0,
            cantidadActividades: 0,
            totalDiasRelevo: 0
        };
    }

    componentDidMount = async () => {
        this.setState({loading: true})
        let usuario = localStorage.getItem("usuario-people-manage");
        if (
          usuario !== null &&
          usuario !== "" &&
          usuario !== undefined &&
          usuario !== "undefined"
        ) {
            usuario = JSON.parse(usuario);   
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

            let strFecha = moment().format('YYYY-MM-DD');

            const startOfMonth = moment(strFecha).startOf('month').format('YYYY-MM-DD');
            const endOfMonth   = moment(strFecha).endOf('month').format('YYYY-MM-DD');

            let objetoEnviar = {
                fechaInicio: convertirFechaString(moment(startOfMonth), 1).fechaString , 
                fechaFin: convertirFechaString(moment(endOfMonth), 2).fechaString
            };

            const cargos = await obtenerCargos(idEmpresa);
            const dataReporteUtilizacionRelevos = await obtenerReporteUtilizacionRelevos(objetoEnviar, idEmpresa);

            const reporteCurvaRelevos = dataReporteUtilizacionRelevos.ReporteCurvaRelevos;
            const promedioRelevos = dataReporteUtilizacionRelevos.ResultadosTarjetas.PromedioRelevos;
            const cantidadContratosRelevo = dataReporteUtilizacionRelevos.ResultadosTarjetas.CantidadContratos;
            const cantidadActividades = dataReporteUtilizacionRelevos.ResultadosTarjetas.ActividadesRelevos;
            const totalDiasRelevo = dataReporteUtilizacionRelevos.ResultadosTarjetas.TotalDiasRelevo;
            

            let formREF = this.formRef.current;
        
            formREF.setFieldsValue({ fechaReporte: moment() });

            this.setState({ cargos, loading: false, dataRelevos: reporteCurvaRelevos, promedioRelevos, cantidadContratosRelevo, totalDiasRelevo, cantidadActividades });
        }
    };


    onFinishFiltrarDatos = async (values) => {

        this.setState({ loading: true });

        let usuario = localStorage.getItem("usuario-people-manage");
        if (
          usuario !== null &&
          usuario !== "" &&
          usuario !== undefined &&
          usuario !== "undefined"
        ) {
            usuario = JSON.parse(usuario);   

            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

            let strFecha = moment(values.fechaReporte).format('YYYY-MM-DD');

            const startOfMonth = moment(strFecha).startOf('month').format('YYYY-MM-DD');
            const endOfMonth   = moment(strFecha).endOf('month').format('YYYY-MM-DD');

            let objetoEnviar = {
                ...values, 
                fechaInicio: convertirFechaString(moment(startOfMonth), 1).fechaString , 
                fechaFin: convertirFechaString(moment(endOfMonth), 2).fechaString
            };

            const dataReporteUtilizacionRelevos = await obtenerReporteUtilizacionRelevos(objetoEnviar, idEmpresa);

            const reporteCurvaRelevos = dataReporteUtilizacionRelevos.ReporteCurvaRelevos;
            const promedioRelevos = dataReporteUtilizacionRelevos.ResultadosTarjetas.PromedioRelevos;
            const cantidadContratosRelevo = dataReporteUtilizacionRelevos.ResultadosTarjetas.CantidadContratos;
            const cantidadActividades = dataReporteUtilizacionRelevos.ResultadosTarjetas.ActividadesRelevos;
            const totalDiasRelevo = dataReporteUtilizacionRelevos.ResultadosTarjetas.TotalDiasRelevo;
            
            this.setState({ 
                loading: false, 
                dataRelevos: reporteCurvaRelevos, 
                promedioRelevos,
                cantidadContratosRelevo,
                cantidadActividades,
                cargoReporte: values.cargoReporte,
                fechaReporte: values.fechaReporte,
                totalDiasRelevo
           });
        }
    }


    detalleCurvaRelevos = async () => {

        this.setState({ loading: true });

        let usuario = localStorage.getItem("usuario-people-manage");

        if (
            usuario !== null &&
            usuario !== "" &&
            usuario !== undefined &&
            usuario !== "undefined"
        ) {

            usuario = JSON.parse(usuario);   
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

            const { cargoReporte, fechaReporte } = this.state;

            let strFecha = moment(fechaReporte).format('YYYY-MM-DD');

            const startOfMonth = moment(strFecha).startOf('month').format('YYYY-MM-DD');
            const endOfMonth   = moment(strFecha).endOf('month').format('YYYY-MM-DD');

            let objetoEnviar = {
                cargoReporte: cargoReporte,
                fechaInicio: convertirFechaString(moment(startOfMonth), 1).fechaString , 
                fechaFin: convertirFechaString(moment(endOfMonth), 2).fechaString
            };


            const detalleCurvaRelevos = await obtenerDetalleCurvaRelevos(objetoEnviar, idEmpresa);

            this.setState({ 
                loading: false, 
                detalle: detalleCurvaRelevos, 
                modalDetalle: true
            });

        }
    }

    descargarExcelDetalleRelevos = async () => {

        this.setState({ loading: true });

        let usuario = localStorage.getItem("usuario-people-manage");

        if (
            usuario !== null &&
            usuario !== "" &&
            usuario !== undefined &&
            usuario !== "undefined"
        ) {

            let { detalle } = this.state;

            if(detalle && detalle.length > 0){

                let arrayEnviar = [];

                detalle.forEach(element => {
                    let trabajador = {
                        'NombreTrabajador': element.NombreTrabajador,
                        'Rut': element.Rut,
                        'Cargo': element.Cargo,
                        'Estado': element.Trabajador_Finiquitado ? 'Finiquitado' : 'Vigente',
                        'FechaInicio': convertirFechaString(moment.utc(element.Fecha_Inicio_Contrato), 1).fechaDDMMYYYY,
                        'FechaFin': convertirFechaString(moment.utc(element.Fecha_Fin_Contrato), 1).fechaDDMMYYYY,
                        'DiasRelevo': element.Cantidad_Dias_Relevo,
                        'TipoContrato': element.Tipo_Contrato
                    }

                    arrayEnviar.push(trabajador);
                });

                let excelDescargado = await descargarExcelDetalleRelevo(arrayEnviar);

                this.setState({loading: false});

            }

        }

    }

    render() {

        const { loading, cargos, dataRelevos, detalle, modalDetalle, promedioRelevos, cantidadContratosRelevo, totalDiasRelevo, cantidadActividades } = this.state;
        
        const config = {
            data: dataRelevos,
            padding: 'auto',
            xField: 'DiaActual',
            yField: 'Personas_Contratadas',
            // annotations: [
                // {
                // type: 'regionFilter',
                // start: ['max', 'median'],
                // end: ['median', 'max'],
                // color: 'red',
                // },
                // {
                // type: 'text',
                // position: ['min', 'median'],
                // content: '中位数',
                // offsetY: -4,
                // style: {
                //     textBaseline: 'bottom',
                // },
                // },
                // {
                // type: 'line',
                // start: ['min', 'median'],
                // end: ['max', 'median'],
                // style: {
                //     stroke: 'green',
                //     lineDash: [2, 2],
                // },
                // },
            // ],
        }; 

        return (
            <Layout style={{ height: "100%" }}>

                <Loading cargando={loading} />

                <Header style={{ background: "none" }}>
                    <HeaderFicha titulo="Reporte Utilización De Relevo" subtitulo="Trabajadores que están en situación de relevo" />
                </Header>

                <hr className="division" />

                <Content
                    className="contenedor-general maven-r"
                    style={{ background: "none" }}
                >

                    {/* FILTROS */}
                    <Form
                        className={'maven-r'}
                        name="formReporteUtilizacionRelevos"
                        onFinish={this.onFinishFiltrarDatos}
                        ref={this.formRef}
                    >
                        <div>
                            <p className={'label-filtros'} style={{ marginBottom: '10px' }}>Filtrar por:</p>
                        </div>

                        <Row gutter={20} style={{ marginBottom: '20px' }}>

                            {/* FILTRO CARGO */}
                            <Col xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                                style={{ marginBottom: '15px' }}
                            >
                                <Form.Item
                                    name="cargoReporte"
                                    noStyle
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Cargo"
                                        className={'select-reporte-utilizacion-relevo'}
                                        allowClear={true}
                                        // disabled={this.state.blockPlaza}
                                        maxTagCount={2}
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            cargos.map((value, index) => (
                                                <Select.Option key={index} value={value._id}>
                                                    {value.Nombre}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>



                            {/* FILTRO FECHA */}
                            <Col xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                                style={{ marginBottom: '15px'}}
                            >
                                <Form.Item
                                    name="fechaReporte"
                                    noStyle
                                >
                                    <DatePicker
                                        format={'MMMM YYYY'}
                                        locale={locale}
                                        picker="month"
                                        className={'select-reporte-utilizacion-relevo'}
                                        allowClear={false}
                                        placeholder="Mes"
                                    />

                                </Form.Item>

                            </Col>


                            {/* <Col xs={24} s={24} sm={24} md={24} lg={8} xl={3}
                                style={{ marginBottom: '15px'}}
                            >
                                <Form.Item
                                    name="numeroPersonas"
                                    noStyle
                                >
                                    <InputNumber
                                        type="number"
                                        placeholder="Nº max. de personas"
                                        className={'select-reporte-utilizacion-relevo'}
                                        min={0}
                                        // defaultValue={7}
                                        style={{ width: '100%' }}
                                    />

                                </Form.Item>

                            </Col> */}
                            

                            {/* BTN FILTRAR */}
                            <Col xs={24} s={24} sm={5} md={5} lg={4} xl={4} xxl={2}
                                className={'filtrar-contratos-btn-col'}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    form="formReporteUtilizacionRelevos"
                                    className={'filtrar-contratos-btn'}
                                >
                                    Filtrar
                                </Button>
                            </Col>

                        </Row>
                    </Form>

                    <Card className="tarjeta-contenedora-curva-relevos">
                        <Row 

                            style={{
                                marginBottom: '20px'
                            }}
                        
                        >

                            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{paddingRight: '60px'}}>
                            
                                <img src={Relevo} className="iconoRelevo" alt="icono modal" />

                            </Col>

                            <Col xs={14} sm={15} md={16} lg={18} xl={20} xxl={20}>
                                <h3
                                    style={{
                                        textAlign: "left",
                                        color: "#061FCB",
                                        fontFamily: "MavenPro-Bold",
                                        paddingTop: '10px'
                                    }}
                                >
                                    Curva De Relevos
                                </h3>
                            </Col>

                            <Col xs={{ span: 1, offset: 0 }} sm={{ span: 1, offset: 0 }} md={{ span: 1, offset: 0 }} lg={{ span: 1, offset: 0 }} xl={{ span: 1, offset: 0 }} xxl={{ span: 1, offset: 0 }}>
                                <Button
                                    type="primary"
                                    // htmlType="submit"
                                    // form="formReporteUtilizacionRelevos"
                                    className="detalle-curva-relevos"
                                    onClick={this.detalleCurvaRelevos}
                                >
                                    <span className="textoBotonDetalleCurvaRelevos">Detalle</span>
                                    <span><EyeFilled className="iconoBotonDetalleCurvaRelevos"/></span>
                                </Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <Line
                                    {...config}
                                />
                            </Col>
                        </Row>

                    </Card>

                    <Row>

                        <Col xs={24} sm={24} md={11} lg={5} xl={5} xxl={4} style={{ marginRight: '20px', height: '170px', marginBottom: '20px'}}>
                        
                            <Card className="tarjetasReporteUtilizacionRelevo" style={{backgroundColor: 'F4F4F4', borderRadius: '17px', height: '100%'}}>

                                <div className="contenedorTitulo">
                                    <label className="tituloTarjetaReporteUtilizacionRelevo">Promedio de relevos en rango de fecha</label>
                                </div>
                                <h2 className="valoresTarjetasReporteUtilizacionRelevo">{promedioRelevos}</h2>

                            </Card>

                        </Col>

                        <Col xs={24} sm={24} md={11} lg={5} xl={5} xxl={4} style={{ marginRight: '20px', height: '170px', marginBottom: '20px'}}>
                        
                            <Card className="tarjetasReporteUtilizacionRelevo" style={{backgroundColor: 'F4F4F4', borderRadius: '17px', height: '100%'}}>

                                <div className="contenedorTitulo">
                                    <label className="tituloTarjetaReporteUtilizacionRelevo">Cantidad de contratos con relevo</label>
                                </div>
                                <h2 className="valoresTarjetasReporteUtilizacionRelevo">{cantidadContratosRelevo}</h2>

                            </Card>

                        </Col>

                        <Col xs={24} sm={24} md={11} lg={5} xl={5} xxl={4} style={{ marginRight: '20px', height: '170px', marginBottom: '20px'}}>
                        
                            <Card className="tarjetasReporteUtilizacionRelevo" style={{backgroundColor: 'F4F4F4', borderRadius: '17px', height: '100%'}}>

                                <div className="contenedorTitulo">
                                    <label className="tituloTarjetaReporteUtilizacionRelevo">Cantidad de actividades en que hubieron relevos</label>
                                </div>
                        
                                <h2 className="valoresTarjetasReporteUtilizacionRelevo">{cantidadActividades}</h2>

                            </Card>

                        </Col>

                        <Col xs={24} sm={24} md={11} lg={5} xl={5} xxl={4} style={{height: '170px'}}>
                        
                            <Card className="tarjetasReporteUtilizacionRelevo" style={{backgroundColor: 'F4F4F4', borderRadius: '17px', height: '100%'}}>
                                <div className="contenedorTitulo">
                                    <label className="tituloTarjetaReporteUtilizacionRelevo">Días del mes con relevo</label>
                                </div>
                                <h2 className="valoresTarjetasReporteUtilizacionRelevo">{totalDiasRelevo}</h2>

                            </Card>

                        </Col>

                    </Row>               


                </Content>

                <Modal
                
                    visible={modalDetalle}
                    onCancel={() => this.setState({modalDetalle: false})}
                    className={'modal-detalle-curva-relevos'}
                    footer={null}
                    closable={false}
                    maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
                >
                    <Row 

                        style={{
                            marginBottom: '20px'
                        }}

                        >

                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{paddingRight: '60px'}}>

                            <img src={Relevo} className="iconoRelevo" alt="icono modal" />

                        </Col>

                        <Col xs={13} sm={14} md={16} lg={18} xl={20} xxl={20}>
                            <h3
                                style={{
                                    textAlign: "left",
                                    color: "#061FCB",
                                    fontFamily: "MavenPro-Bold",
                                    paddingTop: '10px'
                                }}
                            >
                                Detalle Relevo
                            </h3>
                        </Col>

                        {detalle.length > 0 ? 
                        
                            (<>
                            
                            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={2}>
                                <Button
                                    type="primary"
                                    // htmlType="submit"
                                    // form="formReporteUtilizacionRelevos"
                                    className={'descargar-excel-detalle-relevo'}
                                    onClick={this.descargarExcelDetalleRelevos}
                                >
                                    <span className="textoBotonDescargarExcelDetalleCurvaRelevos">Descargar Excel</span>
                                    <span><img src={Excel} className="iconoExcelBotonDetalleCurvaRelevos" alt="Icono Excel"/></span>
                                    
                                </Button>
                            </Col>
                            
                            </>) 
                            
                            : 
                            
                            (<></>)
                        }


                    </Row>

                    <hr className="division-detalle-curva-relevos" />

                    <Table
                        rowKey="_id"
                        dataSource={detalle}
                        loading={this.props.tablaCargando}
                        bordered={true}
                        showSorterTooltip={false}
                        scroll={{ x: true }}
                        pagination={{ pageSize: 7 }}
                        className={'tabla-detalle-curva-relevos'}
                    >
                        <Column
                        title="Imagen"
                        key="Imagen"
                        align="center"
                        render={(value) => {

                            return (
                                <div>
                                <img
                                    src={value.Imagen}
                                    className="imagen-tabla"
                                    alt="i"
                                />
                                </div>
                            );
                            
                        }}
                        />

                        <Column
                            sorter={(a, b) => {
                                return a.NombreTrabajador.localeCompare(b.NombreTrabajador);
                            }}
                            render={(value) => {
                                return value;
                            }}
                            title="Nombre Completo"
                            dataIndex="NombreTrabajador"
                            key="NombreTrabajador"
                            align="left"
                            ellipsis={true}
                        />

                        <Column
                            sorter={(a, b) => {

                                const quitarDigitoVerificadorA = a.Rut !== undefined && a.Rut !== '' ? a.Rut.split('-') : '111';
                                const quitarDigitoVerificadorB = b.Rut !== undefined && b.Rut !== '' ? b.Rut.split('-') : '111';

                                const rutA = quitarDigitoVerificadorA !== '111' ? quitarDigitoVerificadorA[0].split('.').join('') : '111';
                                const rutB = quitarDigitoVerificadorB !== '111' ? quitarDigitoVerificadorB[0].split('.').join('') : '111';

                                const numberFormatoA = parseInt(rutA, 10);
                                const numberFormatoB = parseInt(rutB, 10);

                                return numberFormatoA - numberFormatoB;

                            }}
                            title="Rut/DNI"
                            dataIndex="Rut"
                            key="Rut"
                            ellipsis={true}
                            render={(value) => (
                                <p style={{ whiteSpace: 'nowrap', marginBottom: '0px' }}>{value}</p>
                            )}
                        />

                        <Column
                            sorter={(a, b) => {
                                let cargoA = a.Cargo !== undefined ? a.Cargo.Nombre : 'aaaaa';
                                let cargoB = b.Cargo !== undefined ? b.Cargo.Nombre : 'aaaaa';
                                return cargoA.localeCompare(cargoB);
                            }}
                            title="Cargo"
                            dataIndex="Cargo"
                            key="Cargo"
                            ellipsis={true}
                            render={(cargo => {
                                if (cargo) {
                                return cargo;
                                } else {
                                return 'N/A';
                                }
                            })}
                        />

                        <Column
                            // sorter={(a, b) => {
                            //     let cargoA = a.Cargo !== undefined ? a.Cargo.Nombre : 'aaaaa';
                            //     let cargoB = b.Cargo !== undefined ? b.Cargo.Nombre : 'aaaaa';
                            //     return cargoA.localeCompare(cargoB);
                            // }}
                            title="Estado"
                            dataIndex="Trabajador_Finiquitado"
                            key="Trabajador_Finiquitado"
                            ellipsis={true}
                            render={(trabajadorFiniquitado => {
                                if (trabajadorFiniquitado) {
                                return 'Finiquitado';
                                } else {
                                return 'Vigente';
                                }
                            })}
                        />

                        <Column
                            // sorter={(a, b) => {
                            //     let cargoA = a.Cargo !== undefined ? a.Cargo.Nombre : 'aaaaa';
                            //     let cargoB = b.Cargo !== undefined ? b.Cargo.Nombre : 'aaaaa';
                            //     return cargoA.localeCompare(cargoB);
                            // }}
                            title="Fecha Inicio Contrato"
                            dataIndex="Fecha_Inicio_Contrato"
                            key="Fecha_Inicio_Contrato"
                            ellipsis={true}
                            render={(fechaInicioContrato => {
                               return convertirFechaString(moment.utc(fechaInicioContrato), 1).fechaDDMMYYYY;
                            })}
                        />

                        <Column
                            // sorter={(a, b) => {
                            //     let cargoA = a.Cargo !== undefined ? a.Cargo.Nombre : 'aaaaa';
                            //     let cargoB = b.Cargo !== undefined ? b.Cargo.Nombre : 'aaaaa';
                            //     return cargoA.localeCompare(cargoB);
                            // }}
                            title="Fecha Fin Contrato"
                            dataIndex="Fecha_Fin_Contrato"
                            key="Fecha_Fin_Contrato"
                            ellipsis={true}
                            render={(fechaFinContrato => {
                               if (fechaFinContrato && fechaFinContrato !== null){
                                return convertirFechaString(moment.utc(fechaFinContrato), 1).fechaDDMMYYYY;
                               } else {
                                 return 'N/A'
                               }
                            })}
                        />

                        <Column
                            // sorter={(a, b) => {
                            //     let cargoA = a.Cargo !== undefined ? a.Cargo.Nombre : 'aaaaa';
                            //     let cargoB = b.Cargo !== undefined ? b.Cargo.Nombre : 'aaaaa';
                            //     return cargoA.localeCompare(cargoB);
                            // }}
                            title="Cantidad Días Relevo"
                            dataIndex="Cantidad_Dias_Relevo"
                            key="Cantidad_Dias_Relevo"
                            ellipsis={true}
                            render={(Cantidad_Dias_Relevo => {
                                return Cantidad_Dias_Relevo;
                            })}
                        />

                        <Column
                            // sorter={(a, b) => {
                            //     let cargoA = a.Cargo !== undefined ? a.Cargo.Nombre : 'aaaaa';
                            //     let cargoB = b.Cargo !== undefined ? b.Cargo.Nombre : 'aaaaa';
                            //     return cargoA.localeCompare(cargoB);
                            // }}
                            title="Tipo Contrato"
                            dataIndex="Tipo_Contrato"
                            key="Tipo_Contrato"
                            ellipsis={true}
                            render={(Tipo_Contrato => {
                                return Tipo_Contrato;
                            })}
                        />

                        {/* <Column 
                            title="Detalle"
                            className="columna-detalle-icono"
                            render={() => (
                                <Button
                                    onClick={() => {}}
                                    className="acciones-trabajador-button hover-azul"
                                    icon={<EyeFilled style={{ fontSize: '18px' }} />}
                                />
                            )}
                        /> */}

                    </Table>

                </Modal>

            </Layout>
        );
    }
}

export default reporteUtilizacionRelevo;