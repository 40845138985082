import React from "react";
import "../Empresion.css";
import byLogo from "../../img/logos/logo-by-people-managment.svg";
import { Row, Col, Layout, Card, Select } from "antd";
import Loading from "../../components/loading/Loading";
import API from "../../Redux/Api/api";
import './EntregaEpp.css';

const queryString = require('query-string');

class EntregaEppImpresion extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      renderDocumento: false,
      loading: true,
      infoImprimible: null,
    };
  }

  componentDidMount = async () => {

    const parsed = queryString.parse(this.props.location.search);

    // El trabajador se trae de la bd y la info de los epp de la variable de localStorage con su id único

    const IdTrabajador = parsed.trabId;

    let respuesta = await this.buscarTrabajador(IdTrabajador);

    if (respuesta.status === 200) {

      let trabajador = respuesta.json.trabajador;

      const {
        Nombre,
        Apellido_Paterno,
        Apellido_Materno,
        Rut,
        Cargo: { Nombre: Nombre_Cargo },
        Empresa: { Imagen: Imagen_Empresa },

      } = trabajador;

      const dataId = parsed.eppsId;
      let eppsData = localStorage.getItem(dataId);
      eppsData = JSON.parse(eppsData);

      const { fecha, eppsFecha } = eppsData;

      let infoImprimible = {
        nombreTrabajador: `${Apellido_Paterno} ${Apellido_Materno} ${Nombre}`.toUpperCase(),
        rut: Rut,
        cargo: `${Nombre_Cargo}`.toUpperCase(),
        imagenEmpresa: Imagen_Empresa,
        fecha,
        listaEpps: eppsFecha,
      }

      this.setState({
        infoImprimible,
        renderDocumento: true,
        loading: false,
      });

      setTimeout(function () {
        window.print();
      }, 1000);
    }
  }


  buscarTrabajador = async (IdTrabajador) => {
    let respuesta = {
      status: 0,
      json: null,
    }
    try {

      const url = `${API}/Trabajadores/${IdTrabajador}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      const data = await response.json();

      respuesta.status = status;
      respuesta.json = data;

      return respuesta;

    } catch (error) {
      respuesta.status = -1;
      respuesta.json = error;
      return respuesta;
    }
  }

  Pintar() {

    if (this.state.renderDocumento) {

      const {
        nombreTrabajador,
        rut,
        cargo,
        imagenEmpresa,
        fecha,
        listaEpps,
      } = this.state.infoImprimible;

      // si vienen más de 10 EPP, hacemos las filas más bajas para que caigan hasta 14
      let claseAlturaVariable = 'td-variable-alto';

      if (listaEpps.length > 10) {
        claseAlturaVariable = 'td-variable-bajo';
      }

      return (
        <Layout id="divEmpresion" className="layoutImpresion">

          <Row
            style={{
              paddingTop: "30px",
            }}
          >
            <Col span={18}>

              <Card className="CardMaestra">

                {/* HEADER */}
                <Card className="header-epp">

                  <Row className={'padding-0'} gutter={25}>
                    <Col span={3}>
                      <img
                        src={imagenEmpresa}
                        style={{ width: '100%' }}
                        alt="Logo empresa"
                      />
                    </Col>

                    <Col span={21}>
                      <h3 className="titulo-impresion-epp">
                        Comprobante Entrega E.P.P.
                      </h3>
                      <p className={"parr-impresion-epp"}>
                        En la fecha indicada se hace entrega de los siguientes Elementos de Protección Personal
                        (EPP) y ropa de trabajo, los que usted se compromete a usar y cuidar en el trabajo, y a
                        devolver cuando deje de prestar servicios a la empresa.
                      </p>
                    </Col>
                  </Row>


                </Card>

                {/* CONTENT */}
                <div style={{ height: '550px' }} className={'impresion-epp-content'}>

                  {/* DATOS TRABAJADOR */}
                  <Row align={'middle'}>
                    <Col span={5}>
                      <p className={'texto-plano-epp'}>Nombre trabajador: </p>
                    </Col>

                    <Col span={19}>
                      <p className={'texto-datos-epp'}>{nombreTrabajador}</p>
                    </Col>
                  </Row>

                  <Row align={'middle'} gutter={10} style={{ marginTop: '9px' }}>
                    <Col span={2}>
                      <p className={'texto-plano-epp'}>Rut: </p>
                    </Col>

                    <Col span={5}>
                      <p className={'texto-datos-epp'}>{rut}</p>
                    </Col>

                    <Col span={2}>
                      <p className={'texto-plano-epp'}>Cargo: </p>
                    </Col>

                    <Col span={6}>
                      <p className={'texto-datos-epp'}>{cargo}</p>
                    </Col>

                    <Col span={5}>
                      <p className={'texto-plano-epp'}>Fecha de entrega: </p>
                    </Col>

                    <Col span={4}>
                      <p className={'texto-datos-epp'}>{fecha}</p>
                    </Col>
                  </Row>

                  {/* TABLA */}
                  <Row style={{ marginTop: '15px' }}>
                    <Col span={24}>
                      <table className={`tabla-epps ${claseAlturaVariable}`}>

                        <thead>
                          <tr>
                            <th style={{ width: '300px' }}>EPP</th>
                            <th style={{ width: '50px' }}>Talla</th>
                            <th>Observaciones</th>
                          </tr>
                        </thead>

                        <tbody>

                          {listaEpps.map((epp, index) => (

                            <tr key={index}>

                              <td style={{ paddingLeft: '15px' }}>
                                {epp.nombre}
                              </td>

                              <td style={{ textAlign: 'center' }}>
                                {epp.talla}
                              </td>

                              <td></td>
                            </tr>
                          ))}

                        </tbody>

                      </table>
                    </Col>
                  </Row>

                </div>

                {/* OBSERVACIONES */}
                <div className={'observaciones-div'}>
                  Observaciones:
                </div>

                {/* FIRMAS */}
                <Card className="epp-firma-card">

                  <Row>
                    <Col className="contFirmas" span={12}>
                      <hr />
                      <h3 className="NombresFirma">FIRMA DEL EMPLEADOR</h3>
                    </Col>
                    <Col className="contFirmas" span={12}>
                      <hr />

                      <h3 className="NombresFirma">FIRMA DEL TRABAJADOR</h3>
                    </Col>
                  </Row>


                  <Row>
                    <Col span={24}>
                      <p className={'letra-chica-epp'}>
                        Según lo dispuesto en el Reglamento Interno de Orden, Higiene y Seguridad (RIOHS), “todo trabajador deberá usar el equipo de protección que le proporciona la empresa cuando el desempeño de sus labores así lo exija” (Art. 146, RIOHS).
                        Es obligación del trabajador “usar todos los elementos de protección personal proporcionados por la empresa dentro de las naves o faenas, los que no podrá destinarlos a otra finalidad”. (Art. 161, letra “h”, RIOHS).
                        No usar los elementos de protección personal y accesorios de seguridad que se le hayan entregado para un determinado trabajo en el cual exista riesgo de accidente o enfermedad es considerada una falta grave, por lo que podría ser
                        sancionado por este hecho (Art.155, RIOHS).
                        Es obligación del trabajador solicitar los elementos de protección que le hicieron falta cuando éste concurra a la empresa con motivo de firmar sus días libres compensados (Art. 72, letra “p”, RIOHS)
                      </p>

                    </Col>
                  </Row>
                </Card>
              </Card>

              {/* LOGO PMANAGEMENT */}
              <Row
                style={{
                  paddingTop: "20px",
                  width: "700px",
                }}
              >
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <img style={{ width: '100px', height: 'auto' }} src={byLogo} alt="by people managment" />
                </Col>
              </Row>

            </Col>
          </Row>
        </Layout >
      );
    } else {
      return false;
    }
  }

  render() {
    return (<>
      <Loading cargando={this.state.loading} />
      {this.Pintar()}
    </>);
  }
}

export default EntregaEppImpresion;