import {
  ERROR,
  INGRESAR_FAENA,
  LOADING,
  OBTENER_TITULOS,
  OBTENER_ESPECIALIDADES,
  MOSTRAR_MODAL_FAENA,
  OCULTAR_MODAL_FAENA,
  OBTENER_FAENAS_MANTENEDOR,
  ELIMINAR_FAENA_MANTENEDOR,
  OBTENER_FAENA_MANTENEDOR,
  OCULTAR_MODAL_FAENA_EDITAR,
  EDITAR_FAENA_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OBTENER_CARGOS,
  //   PARAR_LOADING,
} from "../types/MantenedorPlantillaDeFaenaTypes";
import API from "../Api/api";
// import { obtenerFechaHoy } from "../../funcionesGenericas/fechas";
import Swal from "sweetalert2";


export const ObtenerTitulos = (idEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Titulos?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const titulos = data.data;
        dispatch({ type: OBTENER_TITULOS, payload: titulos });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerEspecialidades = (idEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Especialidades?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const especialidades = data.data;

        dispatch({ type: OBTENER_ESPECIALIDADES, payload: especialidades });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerCargos = (idEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/CargosTrabajadores?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:


        const cargos = data.data;
        dispatch({ type: OBTENER_CARGOS, payload: cargos });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const CrearFaena = (faena) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const url = `${API}/PlantillaFaena`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(faena),
    });

    const status = response.status;
    const data = await response.json();
    console.log(data);
    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Se ingresó la plantilla exitosamente.",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        })

        dispatch({
          type: INGRESAR_FAENA,
          payload: "Se ingreso la faena correctamente",
        });


        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerFaenas = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/PlantillaFaena?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      const data = await response.json();

      switch (status) {
        case 200:
        case 201:
          let faenas = data.plantillasFaena;

          dispatch({ type: OBTENER_FAENAS_MANTENEDOR, payload: faenas });
          break;

        default:
          const error = data.err.message.toString();
          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerFaena = (idFaena) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/PlantillaFaena/${idFaena}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let faena = data.plantillaFaena;

        dispatch({ type: OBTENER_FAENA_MANTENEDOR, payload: faena });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarFaena = (idFaena) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/PlantillaFaena/${idFaena}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "La plantilla fue eliminada",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: ELIMINAR_FAENA_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          html: `Error status: ${status}. <br> Detalle: ${error}`,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarFaena = (Faena) => async (dispatch) => {
  dispatch({ type: LOADING });
  const idFaena = Faena._id;
  delete Faena._id;
  try {
    const url = `${API}/PlantillaFaena/${idFaena}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(Faena),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "La plantilla fue actualizada",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        })

          .then((value) => {
            window.location.reload();
          });

        dispatch({ type: EDITAR_FAENA_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const TomarValoresEditar = (nombreCampo, valor) => (dispatch) => {
  let objeto = {
    nombreCampo,
    valor,
  };
  dispatch({ type: TOMAR_VALORES_EDITAR, payload: objeto });
};

export const MostrarModalFaena = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_FAENA });
};

export const OcultarModalFaena = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_FAENA });
};
export const OcultarModalFaenaEditar = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_FAENA_EDITAR });
};

//   export const DescargarExcel = () => async (dispatch) => {
//     dispatch({ type: LOADING });
//     try {
//       const url = `${API}/Usuario/Descargar/Excel`;
//       let status;
//       fetch(url)
//         .then((response) => {
//           status = response.status;
//           return response.blob();
//         })
//         .then((blob) => {
//           switch (status) {
//             case 200:
//             case 201:
//               const url = window.URL.createObjectURL(blob);
//               const a = document.createElement("a");
//               a.href = url;
//               a.download = `Usuarios_${obtenerFechaHoy()}.xlsx`;
//               document.body.appendChild(a);
//               a.click();
//               a.remove();

//               dispatch({ type: PARAR_LOADING });
//               break;
//             default:
//               Swal.fire({
//                 title: "Uups",
//                 text:
//                   "Error status: " +
//                   status +
//                   " al realizar la descarga del archivo",
//                 icon: "error",
//               });

//               dispatch({ type: PARAR_LOADING });
//               break;
//           }
//         });
//     } catch (error) {
//       dispatch({ type: ERROR, payload: error });
//     }
//   };

////////////////////////////////////////////////////////////////////////////////////////////////////////////

