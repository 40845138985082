import {
  MOSTRAR_MODAL_CARGO,
  OCULTAR_MODAL_CARGO,
  MANTENEDOR_CARGO_CREAR_CARGO,
  MANTENEDOR_CARGO_OBTENER_CARGOS,
  MANTENEDOR_CARGO_ELIMINAR_CARGO,
  OCULTAR_MODAL_CARGO_EDITAR,
  LOADING,
  ERROR,
  MANTENEDOR_CARGO_OBTENER_CARGO,
  MANTENEDOR_CARGO_TOMAR_VALORES_EDITAR,
  MANTENEDOR_CARGO_EDITAR_CARGO,
} from "../types/MantenedorCargosTypes";
import API from "../Api/api";
import Swal from "sweetalert2";

export const ObtenerCargos = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/CargosTrabajadores?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const status = response.status;

      const data = await response.json();

      switch (status) {
        case 200:
        case 201:
          let cargos = data.data;

          dispatch({
            type: MANTENEDOR_CARGO_OBTENER_CARGOS,
            payload: cargos,
          });

          break;

        default:
          const error = data.err.message.toString();

          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });

          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerCargo = (idCargo) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/CargosTrabajadores/${idCargo}`;

    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;

    const data = await response.json();


    switch (status) {
      case 200:
      case 201:
        let cargo = data.data;

        dispatch({
          type: MANTENEDOR_CARGO_OBTENER_CARGO,
          payload: cargo,
        });

        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarCargo = (Cargo) => async (dispatch) => {

  dispatch({ type: LOADING });
  const idCargo = Cargo._id;
  delete Cargo._id;

  try {
    const url = `${API}/CargosTrabajadores/${idCargo}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },

      body: JSON.stringify(Cargo),
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El cargo fue actualizado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: MANTENEDOR_CARGO_EDITAR_CARGO });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const CrearCargoTrabajador = (cargo) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/CargosTrabajadores`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(cargo),
    });
    const status = response.status;

    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Se ingresó el cargo correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: MANTENEDOR_CARGO_CREAR_CARGO,
          payload: "Se ingreso centro de costo correctamente",
        });

        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarCargo = (idCargo) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/CargosTrabajadores/${idCargo}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El cargo fue eliminado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: MANTENEDOR_CARGO_ELIMINAR_CARGO });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const TomarValoresEditar = (nombreCampo, valor) => (dispatch) => {
  let objeto = {
    nombreCampo,
    valor,
  };
  dispatch({ type: MANTENEDOR_CARGO_TOMAR_VALORES_EDITAR, payload: objeto });
};

export const MostrarModalCargo = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_CARGO });
};

export const OcultarModalCargo = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_CARGO });
};

export const OcultarModalCargoEditar = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_CARGO_EDITAR });
};

export const ObtenerEpps = () => async (dispatch) => {

  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {

      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/Epp?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:

          data = await response.json();

          const epps = data.data;
          return epps;
        case 204:

          return [];

        default:
          data = await response.json();
          const error = data.err.message.toString();

          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });

          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {

    console.error(error);

    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerDocumentos = () => async (dispatch) => {

  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {

      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/TiposDeDocumentos?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:

          data = await response.json();

          // console.log("Documentos");
          // console.log(data.tiposDeDocumentos);

          const documentos = data.tiposDeDocumentos;
          return documentos;
        case 204:

          return [];

        default:
          data = await response.json();
          const error = data.err.message.toString();

          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });

          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {

    console.error(error);

    dispatch({ type: ERROR, payload: error });
  }
};