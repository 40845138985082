import React, { useEffect, useState } from "react";
import SubirFoto from "../../components/subirFoto/SubirFoto";
import Trabajador from "../../img/icons/Trabajador.svg";
import personal from "../../img/icons/avatar.svg";
import contacto from "../../img/icons/telefono.svg";
import familiares from "../../img/icons/familiares.svg";
import flecha from "../../img/icons/flecha.svg";
import epp from "../../img/icons/epp.svg";
import contrato from "../../img/icons/contrato-panel.svg";
import vacaciones from "../../img/icons/vacaciones-panel.svg";

import especialidades_icon from "../../img/icons/icon_espe.png";
import titulos_icon from "../../img/icons/icon_titu.png";

import Rut from "../../funcionesGenericas/rut";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import {
  Form,
  Input,
  Button,
  Card,
  Collapse,
  DatePicker,
  Row,
  Col,
  PageHeader,
  Select,
  Radio,
  InputNumber,
  Alert,
  Tooltip,
} from "antd";
import Swal from "sweetalert2";

import "./FormularioTrabajadorEditar.css";
import moment from "moment";

import {
  convertirFechaString
} from "../../funcionesGenericas/fechas";
import { isTelefonoValid } from "../../funcionesGenericas/telefonos";
import { LeftCircleTwoTone } from "@ant-design/icons";
// import ValidadorRut from "validar-rut";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const FormEditarTrabajadorContrato = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();
  const [validRutTrabajador, setValidRutTra] = useState("");
  const [tipoFechaContrato, setTipoFechaContrato] = useState(null);
  const [requirioProgre, setRequirioProgre] = useState(false);
  const [otroSelected, setOtroSelected] = useState(false);
  const [validateOtro, setValidateOtro] = useState("error");

  const [abrioContrato, setAbrioContrato] = useState(false);
  const [abrioVacaciones, setAbrioVacaciones] = useState(false);

  const [reiniciarImg, setReiniciarImg] = useState(false);

  const [provinciasSeleccionadas, setProvinciasSeleccionadas] = useState([]);
  const [comunasSeleccionadas, setComunasSeleccionadas] = useState([]);

  // const ObtenerImagen = (imagenUrl) => {
  //   props.obtenerImagen(imagenUrl);
  //   props.TomarValoresEditar("Imagen", imagenUrl);
  // };

  const onFinish = (values) => {
    //EMPRESA
    values.Empresa = values.Empresa === undefined ? props.Formulario.Empresa : values.Empresa;

    //DATOS PERSONALES
    values.Rut = values.Rut === undefined ? props.Formulario.Rut : values.Rut;
    values.Cargo = values.Cargo === undefined ? props.Formulario.Cargo._id : values.Cargo;
    values.OtrosCargos = values.OtrosCargos === undefined ? props.Formulario.OtrosCargos : values.OtrosCargos;
    values.Nombre = values.Nombre === undefined ? props.Formulario.Nombre : values.Nombre;
    values.Apellido_Paterno = values.Apellido_Paterno === undefined ? props.Formulario.Apellido_Paterno : values.Apellido_Paterno;
    values.Apellido_Materno = values.Apellido_Materno === undefined ? props.Formulario.Apellido_Materno : values.Apellido_Materno;
    values.Fecha_De_Nacimiento = values.Fecha_De_Nacimiento === undefined ? props.Formulario.Fecha_De_Nacimiento : values.Fecha_De_Nacimiento;
    values.Calle = values.Calle === undefined ? props.Formulario.Calle : values.Calle;
    values.Numero_Calle = values.Numero_Calle === undefined ? props.Formulario.Numero_Calle : values.Numero_Calle;
    values.Tipo_De_Vivienda = values.Tipo_De_Vivienda === undefined ? props.Formulario.Tipo_De_Vivienda : values.Tipo_De_Vivienda;
    values.Numero_Departamento = values.Numero_Departamento === undefined ? props.Formulario.Numero_Departamento : values.Numero_Departamento;
    values.Region = values.Region === undefined ? props.Formulario.Region : values.Region;
    values.Ciudad = values.Ciudad === undefined ? props.Formulario.Ciudad : values.Ciudad;
    values.Comuna = values.Comuna === undefined ? props.Formulario.Comuna : values.Comuna;
    values.Correo = values.Correo === undefined ? props.Formulario.Correo : values.Correo;
    values.Telefono = values.Telefono === undefined ? props.Formulario.Telefono : values.Telefono;
    values.Nacionalidad = values.Nacionalidad === undefined ? props.Formulario.Nacionalidad : values.Nacionalidad;
    values.Sexo = values.Sexo === undefined ? props.Formulario.Sexo : values.Sexo;
    values.RegimenDeTrabajo = values.RegimenDeTrabajo === undefined ? props.Formulario.RegimenDeTrabajo : values.RegimenDeTrabajo;
    values.Codigo_Base = values.Codigo_Base === undefined ? props.Formulario.Codigo_Base : values.Codigo_Base;

    //DATOS DE CONTACTO DE EMERGENCIA
    values.Contacto_Nombre = values.Contacto_Nombre === undefined ? props.Formulario.Contacto_Nombre : values.Contacto_Nombre;
    values.Contacto_Correo = values.Contacto_Correo === undefined ? props.Formulario.Contacto_Correo : values.Contacto_Correo;
    values.Contacto_Fono_Fijo = values.Contacto_Fono_Fijo === undefined ? props.Formulario.Contacto_Fono_Fijo : values.Contacto_Fono_Fijo;
    values.Contacto_Fono_Celular = values.Contacto_Fono_Celular === undefined ? props.Formulario.Contacto_Fono_Celular : values.Contacto_Fono_Celular;

    // //DATOS FAMILIARES Y LEGALES
    values.Estado_Civil = values.Estado_Civil === undefined ? props.Formulario.Estado_Civil : values.Estado_Civil;
    values.Cantidad_Hijos = values.Cantidad_Hijos === undefined ? props.Formulario.Cantidad_Hijos : values.Cantidad_Hijos;

    //TITULOS
    values.Titulos_Profesionales = values.Titulos_Profesionales === undefined ? props.Formulario.Titulos_Profesionales : values.Titulos_Profesionales;

    //ESPECIALIDADES
    values.Especialidades = values.Especialidades === undefined ? props.Formulario.Especialidades : values.Especialidades;

    //EPP
    values.EPP = values.EPP === undefined ? props.Formulario.EPP : values.EPP;

    //DOCUMENTOS
    values.TiposDeDocumentos = values.TiposDeDocumentos === undefined ? props.Formulario.TiposDeDocumentos : values.TiposDeDocumentos;

    //VACACIONES 
    values.Requirio_Progresivas = values.Requirio_Progresivas === undefined ? props.Formulario.Requirio_Progresivas : values.Requirio_Progresivas;
    values.Fecha_Progresivas = values.Fecha_Progresivas === undefined ? props.Formulario.Fecha_Progresivas : values.Fecha_Progresivas;
    values.Fecha_Progresivas_Unix = values.Fecha_Progresivas_Unix === undefined ? props.Formulario.Fecha_Progresivas_Unix : values.Fecha_Progresivas_Unix;
    values.Meses_Empleadores_Anteriores = values.Meses_Empleadores_Anteriores === undefined ? props.Formulario.Meses_Empleadores_Anteriores : values.Meses_Empleadores_Anteriores;
    values.Regimen_Vacaciones = values.Regimen_Vacaciones === undefined ? props.Formulario.Regimen_Vacaciones : values.Regimen_Vacaciones;

    if (props.Formulario.Imagen === null) {
      Swal.fire({
        title: "Uuups...",
        text: "Debes escoger imagen",
        icon: "error",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Continuar",
      });
    } else {


      if (values.Contacto_Fono_Celular) {
        values.Contacto_Fono_Celular = values.Contacto_Fono_Celular === undefined ? "" : values.Contacto_Fono_Celular.toString().replace(/ /g, "");
      }
      if (values.Contacto_Fono_Fijo) {
        values.Contacto_Fono_Fijo = values.Contacto_Fono_Fijo === undefined ? "" : values.Contacto_Fono_Fijo.toString().replace(/ /g, "");
      }

      if (values.Telefono) {
        values.Telefono = values.Telefono === undefined ? "" : values.Telefono.replace(/ /g, "");
      }


      values.Imagen = props.Formulario.Imagen;
      // values.Empresa = values.EmpresaHolding;

      ///////////////////////////////////////////////////////////////////////////////////////////////

      if (props.Formulario.Requirio_Progresivas == undefined && !abrioVacaciones) {
        Swal.fire({
          title: "¡Ups!",
          text: "Debes ingresar la información de vacaciones.",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
        return;
      }

      if (values.Fecha_Progresivas && moment.isMoment(values.Fecha_Progresivas)) {
        // values.Fecha_Progresivas_Unix = momentToUnixFechaInicio(values.Fecha_Progresivas);
        values.Fecha_Progresivas_Unix = convertirFechaString(values.Fecha_Progresivas, 1).fechaUNIX;

        // values.Fecha_Progresivas = momentToStringFechaInicio(values.Fecha_Progresivas, databaseFormat);
        values.Fecha_Progresivas = convertirFechaString(values.Fecha_Progresivas, 1).fechaString;
      }

      //si seleccionó régimen de vacaciones "otro" y no ingresó cuántos días son
      if (values.Requirio_Progresivas !== undefined) {
        if (
          values.Regimen_Vacaciones === -1 &&
          (values.otro == undefined || values.otro == null)
        ) {
          Swal.fire({
            title: "Ups...",
            text:
              "Debes ingresar la cantidad de días en el régimen de vacaciones.",
            icon: "warning",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Ok",
          });

          return;
        } else if (
          values.Regimen_Vacaciones === -1 &&
          !(values.otro == undefined || values.otro == null)
        ) {
          values.Regimen_Vacaciones = values.otro;
        }
      }

      // VALIDACIÓNES DE VACACIONES PROGRESIVAS:
      if (values.Requirio_Progresivas && moment.isMoment(values.Requirio_Progresivas)) {
        let mesesEnEmpresa = moment().diff(
          values.Contrato_Fecha_Inicio,
          "months"
        );
        let mesesTotales = values.Meses_Empleadores_Anteriores + mesesEnEmpresa;

        if (mesesTotales < 120) {
          Swal.fire({
            title: "Ups...",
            text:
              "El trabajador no pudo haber requerido legalmente vacaciones progresivas si no ha trabajado al menos 120 meses (10 años).",
            icon: "warning",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Ok",
          });

          return;
        }

        let aniosEnEmpresa = moment().diff(
          values.Contrato_Fecha_Inicio,
          "years"
        );

        if (aniosEnEmpresa < 3) {
          Swal.fire({
            title: "Ups...",
            text:
              "El trabajador debió haber trabajado al menos 3 años en la empresa para requerir vacaciones progresivas.",
            icon: "warning",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Ok",
          });

          return;
        }
      }

      // LOS RUT SIEMPRE SE DEBEN ENVIAR CON UPPERCASE EN CASO DE RUT CON K
      if (values.Rut) {
        values.Rut = values.Rut.toUpperCase();
      }

      values.Nombre = (values.Nombre === undefined ? "" : values.Nombre.trim());
      values.Apellido_Paterno = (values.Apellido_Paterno === undefined ? "" : values.Apellido_Paterno.trim());
      values.Apellido_Materno = (values.Apellido_Materno === undefined ? "" : values.Apellido_Materno.trim());

      if (values.Fecha_De_Nacimiento && moment.isMoment(values.Fecha_De_Nacimiento)) {
        values.Fecha_De_Nacimiento = convertirFechaString(values.Fecha_De_Nacimiento, 1).fechaString;
      }

      props.guardarNuevaFicha(values);
      // setReiniciarImg(true);
    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const condicionalFechaContrato = () => {
    if (tipoFechaContrato === "Indefinido") {
      return (
        <DatePicker
          format="DD-MM-YYYY"
          locale={locale}
          className="campos-formulario-contratos"
          style={{
            width: "100%",
          }}
          disabled={true}
        />
      );
    } else {
      return (
        <RangePicker
          format="DD-MM-YYYY"
          locale={locale}
          className="campos-formulario-contratos"
          style={{
            width: "100%",
          }}
          disabled={true}
        />
      );
    }
  };

  const condicionalFechaProgresivas = () => {
    if (requirioProgre) {
      return (
        <Form.Item
          name={"Fecha_Progresivas"}
          label="¿Desde cuándo?"
          rules={[
            {
              required: true,
              message: "Obligatorio",
            },
          ]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            locale={locale}
            className="campos-formulario-contratos"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      );
    }
  };

  const buscarProvincias = (regionId) => {

    let provinciasSeleccionadas = props.provincias.filter(provincia => provincia.Region === regionId);
    setProvinciasSeleccionadas(provinciasSeleccionadas);
  }

  const buscarComunas = (provinciaId) => {

    let comunasSeleccionadas = props.comunas.filter(comuna => comuna.Provincia === provinciaId);
    setComunasSeleccionadas(comunasSeleccionadas);
  }

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 9,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  let aux = props.Formulario._id;

  useEffect((aux) => {

    let {
      //datos iniciales
      Cargo,
      OtrosCargos,
      Rut,
      Nombre,
      Apellido_Paterno,
      Apellido_Materno,
      Fecha_De_Nacimiento,
      Calle,
      Numero_Calle,
      Tipo_De_Vivienda,
      Numero_Departamento,
      Region,
      Ciudad,
      Comuna,
      Correo,
      Nacionalidad,
      Sexo,
      RegimenDeTrabajo,
      Codigo_Base,
      Telefono,
      //datos de contacto

      Contacto_Nombre,
      Contacto_Correo,
      Contacto_Fono_Fijo,
      Contacto_Fono_Celular,

      //datos familiares
      Estado_Civil,
      Cantidad_Hijos,

      Titulos_Profesionales,
      Especialidades,
      TiposDeDocumentos,
      EPP,

      //Contrato y Vacaciones
      Tipo_Contrato,
      Contrato_Fecha_Inicio,
      Contrato_Fecha_Inicio_Unix,
      Contrato_Fecha_Fin,
      Contrato_Fecha_Fin_Unix,
      Requirio_Progresivas,
      Fecha_Progresivas,
      Fecha_Progresivas_Unix,
      Meses_Empleadores_Anteriores,
      Regimen_Vacaciones,


      Costo_Liquido_Diario,
      Costo_Bruto_Diario

    } = props.Formulario;

    setTipoFechaContrato(Tipo_Contrato);
    setRequirioProgre(Requirio_Progresivas);

    if (Requirio_Progresivas) {
      if (Fecha_Progresivas_Unix) {
        form.setFieldsValue({
          Fecha_Progresivas: moment.unix(Fecha_Progresivas_Unix),
        });
      } else {
        form.setFieldsValue({
          Fecha_Progresivas: moment(Fecha_Progresivas),
        });
      }
    }

    let fechaMoment = moment(Fecha_De_Nacimiento);

    if (props.editarCerrado) {
      form.setFieldsValue({
        Rut,
      });
      props.cambiarEditarCerrado();
    }

    if (!Contrato_Fecha_Inicio) {
      form.setFieldsValue({
        Contrato_Fecha: null,
      });
    } else if (Tipo_Contrato === "Indefinido") {
      if (Contrato_Fecha_Inicio_Unix) {
        form.setFieldsValue({
          Contrato_Fecha: moment.unix(Contrato_Fecha_Inicio_Unix),
        });
      } else {
        form.setFieldsValue({
          Contrato_Fecha: moment(Contrato_Fecha_Inicio),
        });
      }
    } else {
      if (Contrato_Fecha_Inicio_Unix) {
        form.setFieldsValue({
          Contrato_Fecha: [
            moment.unix(Contrato_Fecha_Inicio_Unix),
            moment.unix(Contrato_Fecha_Fin_Unix),
          ],
        });
      } else {
        form.setFieldsValue({
          Contrato_Fecha: [
            moment(Contrato_Fecha_Inicio),
            moment(Contrato_Fecha_Fin),
          ],
        });
      }
    }

    if (
      Regimen_Vacaciones !== 15 &&
      Regimen_Vacaciones !== 20 &&
      Regimen_Vacaciones !== undefined &&
      Regimen_Vacaciones !== null
    ) {
      form.setFieldsValue({
        Regimen_Vacaciones: -1,
        otro: Regimen_Vacaciones,
      });
    } else {
      form.setFieldsValue({
        Regimen_Vacaciones,
      });
    }

    let provinciasSeleccionadas = props.provincias.filter(provincia => provincia.Region === Region);
    let comunasSeleccionadas = props.comunas.filter(comuna => comuna.Provincia === Ciudad);

    setProvinciasSeleccionadas(provinciasSeleccionadas);
    setComunasSeleccionadas(comunasSeleccionadas);

    form.setFieldsValue({

      Rut,
      Cargo: Cargo._id,
      OtrosCargos,
      Nombre,
      Apellido_Paterno,
      Apellido_Materno,
      Fecha_De_Nacimiento: fechaMoment,
      Calle,
      Numero_Calle,
      Tipo_De_Vivienda,
      Numero_Departamento,
      Region,
      Comuna,
      Ciudad,
      Correo,
      Nacionalidad,
      Sexo,
      RegimenDeTrabajo,
      Codigo_Base,
      Telefono,

      //datos de contacto

      Contacto_Nombre,
      Contacto_Correo,
      Contacto_Fono_Fijo,
      Contacto_Fono_Celular,

      //datos familiares
      Estado_Civil,
      Cantidad_Hijos,

      Titulos_Profesionales,
      Especialidades,
      TiposDeDocumentos,
      EPP,

      Tipo_Contrato,
      Requirio_Progresivas,
      Meses_Empleadores_Anteriores,


      Costo_Liquido_Diario,
      Costo_Bruto_Diario
    });
  }, [aux]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={true}
    >
      <Row style={{ padding: "5px 20px 15px 20px" }} gutter={15}>

        {/* HEADER */}
        <Col span={24}
          // style={{ display: "flex" }}
          className="title"
          style={{ padding: '0px' }}
        >
          <div
            className={'modal-header-wrapper'}
            style={{ margin: '0px' }}
          >

            <PageHeader
              className="site-page-header"
              onBack={() => {
                props.cerrarModal();
              }}
              title="Modificar ficha"
              subTitle={<span>Edita la ficha del trabajador para su nuevo contrato.</span>}
              backIcon={
                <Tooltip
                  title={"Descartar cambios"}
                  color={'geekblue'}
                >
                  <LeftCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={'#0141d074'} />
                </Tooltip>
              }
            />

          </div>
        </Col>

        {/* CARD TRABAJADOR */}
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Card style={{ width: "100%" }}>

            <Row justify="center">

              <Col span={24}>
                {/* <SubirFoto
                  avatar={props.Formulario.Imagen}
                  ObtenerImagen={ObtenerImagen}
                  reiniciarImg={reiniciarImg}
                  setReiniciarImg={setReiniciarImg}
                /> */}

                <div style={{ width: '100%', position: 'relative' }}>

                  <div className={'img-desv-container'}>

                    <div className={'trab-img-desv-wrapp'}>

                      <img
                        src={props.Formulario.Imagen}
                        alt={'imagen trabajador'}
                        className={'trab-img-desv'}
                      />

                    </div>

                  </div>

                </div>

              </Col>

              <Col span={24}>
                {/* <p style={{ fontFamily: "MavenPro-Regular" }}>
                  Te recomendamos que la fotografía no exceda los 5 MB de peso
                </p> */}

                <Card className={"codigos-card"} style={{ textAlign: 'center' }}>
                  <div
                    className={"codigo-wrapper"}
                    style={{ marginBottom: "15px" }}
                  >
                    <h3 style={{ fontFamily: "MavenPro-SemiBold", margin: 0, fontSize: '14px' }}>
                      Código de sistema:
                    </h3>
                    <h3 style={{ fontFamily: "MavenPro-Regular", margin: 0, fontSize: '14px' }}>
                      {props.Formulario._id}
                    </h3>
                  </div>

                  <div className={"codigo-wrapper"}>
                    <h3 style={{ fontFamily: "MavenPro-SemiBold", margin: 0, fontSize: '14px' }}>
                      Código base:
                    </h3>
                    <h3 style={{ fontFamily: "MavenPro-Regular", margin: 0, fontSize: '14px' }}>
                      {!props.Formulario.Codigo_Base
                        ? "No Aplica"
                        : props.Formulario.Codigo_Base}
                    </h3>
                  </div>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>

        {/* FORMULARIO */}
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <Collapse
            bordered={false}
            defaultActiveKey={["0"]}
            className="formulario-trabajador site-collapse-custom-collapse"
            onChange={(e) => {
              if (e.includes("8")) setAbrioContrato(true);
              if (e.includes("9")) setAbrioVacaciones(true);
            }}
            style={{ width: '100%' }}
          >
            {/* -----------------------------------FORMULARIO DATOS PERSONALES---------------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Datos Personales
                </h4>
              }
              key="1"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={personal}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                style={{ marginTop: '20px' }}
                label="RUT"
                name={"Rut"}
                validateStatus={validRutTrabajador}
                onChange={(e) => {
                  let valor = e.target.value;

                  if (valor) {
                    let rut = Rut.formateaRut(valor);
                    let rutValidacion = Rut.procesarRut(rut);

                    setValidRutTra(rutValidacion);

                    if (rut !== undefined) {
                      form.setFieldsValue({ Rut: rut });
                    } else {
                      form.setFieldsValue({ Rut: valor });
                    }
                  }
                }}
                rules={[
                  {
                    min: 11,
                    max: 12,
                    required: true,
                    message: "Debe introducir el RUT!",
                  },
                ]}
                hasFeedback
                help="Ingrese el RUT válido con código verificador"
              >
                <Input
                  maxLength={12}
                  name="Rut"
                  className="campos-formulario-contratos"
                  placeholder="xx.xxx.xxx-x"
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Cargo del Trabajador"
                name={"Cargo"}
                style={{
                  marginTop: "20px",
                }}

                rules={[
                  {
                    required: true,
                    message: "Seleccione el cargo",
                  },
                ]}
              >
                <Select
                  className="campos-formulario-contratos"
                  name="Cargo"
                  placeholder="Seleccionar..."
                >
                  {props.cargosTrabajador.map((value, index) => (
                    <Select.Option key={index} name="Cargo" value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <p style={{ margin: "0px", lineHeight: '16px' }}>
                    Otras funciones
                    <br />
                    del trabajador
                  </p>
                }
                name={"OtrosCargos"}
                style={{
                  marginTop: "20px",
                }}
              >
                <Select
                  className="campos-formulario-contratos"
                  name="OtrosCargos"
                  placeholder="Selecciona cargo"
                  mode='multiple'
                >
                  {props.cargosTrabajador.map((value, index) => (
                    <Select.Option key={index} name="OtrosCargos" value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name={"Nombre"}
                label="Nombres:"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir el nombre completo",
                  },
                ]}
              >
                <Input
                  name="Nombre"
                  className="campos-formulario-contratos"
                  disabled={true}

                />
              </Form.Item>
              <Form.Item
                name={"Apellido_Paterno"}
                label="Apellido paterno:"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir su apellido paterno",
                  },
                ]}
              >
                <Input
                  name="Apellido_Paterno"
                  className="campos-formulario-contratos"
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                name={"Apellido_Materno"}
                label="Apellido materno:"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir su apellido materno",
                  },
                ]}
              >
                <Input
                  name="Apellido_Materno"
                  className="campos-formulario-contratos"
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                name={"Fecha_De_Nacimiento"}
                label="Fecha de nacimiento:"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Debe introducir la fecha de nacimiento",
                  },
                ]}
              >
                <DatePicker
                  locale={locale}
                  format="DD-MM-YYYY"
                  name="Fecha_De_Nacimiento"
                  className="campos-formulario-contratos"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Calle"
                name={"Calle"}
                rules={[
                  {
                    required: true,
                    message: "debe ingresar la dirección",
                  },
                ]}
                hasFeedback
              >
                <Input
                  name="Calle"
                  className="campos-formulario-contratos"
                  id="warning2"
                />
              </Form.Item>

              <Form.Item name={"Numero_Calle"} label="Número" hasFeedback>
                <Input
                  name="Numero_Calle"
                  className="campos-formulario-contratos"
                />
              </Form.Item>
              <Form.Item
                name={"Tipo_De_Vivienda"}
                rules={[
                  {
                    required: true,
                    message: "¿Qué tipo de lugar es?",
                  },
                ]}
                label="Lugar"
              >
                <Radio.Group
                  // style={{ display: "flex" }}
                  key={"Tipo_De_Vivienda"}
                >
                  <Radio value={"Casa"}>Casa</Radio>
                  <Radio value={"Departamento"}>Departamento</Radio>
                  <Radio value={"Oficina"}>Oficina</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name={"Numero_Departamento"}
                label="N° del Establecimiento"
              >
                <Input
                  name="Numero_Departamento"
                  //disabled={(props.Formulario.Tipo_De_Vivienda === "Departamento" ? false : true)}
                  className="campos-formulario-contratos"
                />
              </Form.Item>
              <Form.Item
                label="Región"
                name={"Region"}
                rules={[
                  {
                    required: true,
                    message: "¿En que region está?",
                  },
                ]}
              >
                <Select
                  onSelect={(value) => {
                    form.resetFields(["Ciudad", "Comuna"]);
                    buscarProvincias(value);
                  }}
                  className="campos-formulario-contratos"
                  name="Region"
                  placeholder="Selecciona región"
                >
                  {props.regiones.map((value, index) => (
                    <Select.Option name="Region" key={index} value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Provincia"
                name={"Ciudad"}
                rules={[
                  {
                    required: true,
                    message: "¿En qué ciudad está?",
                  },
                ]}
              >
                <Select
                  className="campos-formulario-contratos"
                  name="Ciudad"
                  placeholder="Selecciona provincia"
                  onSelect={(value) => {
                    form.resetFields(["Comuna"]);
                    buscarComunas(value);
                  }}
                >
                  {provinciasSeleccionadas.map((value, index) => (
                    <Select.Option name="Ciudad" key={index} value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Comuna"
                name={"Comuna"}
                rules={[
                  {
                    required: true,
                    message: "¿En qué comuna está?",
                  },
                ]}
              >
                <Select
                  className="campos-formulario-contratos"
                  name="Comuna"
                  placeholder="Selecciona comuna"
                >
                  {comunasSeleccionadas.map((value, index) => (
                    <Select.Option name="Comuna" key={index} value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={"Correo"}
                label="Correo electrónico"
                rules={[
                  {
                    type: "email",
                  },
                ]}
              >
                <Input
                  name="Correo"
                  className="campos-formulario-contratos"
                />
              </Form.Item>
              <Form.Item
                name={"Telefono"}
                label="Teléfono"
                rules={[
                  {
                    validator: (_, value) => {

                      const { isValid, errorMsg } = isTelefonoValid(value);

                      if (isValid) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(errorMsg);
                      }
                    }
                  },
                ]}
                normalize={((value) => {
                  let noSpaceEntry = value.replace(/\s/g, ''); //quitando espacios por si acaso
                  return noSpaceEntry;
                })}
              >
                <Input
                  name={"Telefono"}
                  className="campos-formulario-contratos"
                  placeholder={"+569XXXXXXXX"}
                  maxLength={12}
                />
              </Form.Item>
              <Form.Item
                label="Nacionalidad"
                name={"Nacionalidad"}
                rules={[
                  {
                    required: true,
                    message: "¿Cuál es su nacionalidad?",
                  },
                ]}
              >
                <Select
                  className="campos-formulario-contratos"
                  name="Ciudad"
                  placeholder="Nacionalidad"
                >
                  <Select.Option key={"Chilena"} name="Nacionalidad">
                    Chilena
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Sexo"
                name={"Sexo"}
                rules={[
                  {
                    required: true,
                    message: "Debe ingresar el sexo del trabajador",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  key={"Sexo"}
                  value={props.Formulario.Sexo}
                >
                  <Radio value={"Masculino"}>Masculino</Radio>
                  <Radio value={"Femenino"}>Femenino</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Régimen de trabajo"
                name={"RegimenDeTrabajo"}
                style={{
                  marginTop: "20px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Seleccione el régimen",
                  },
                ]}
              >
                <Select
                  className="campos-formulario-contratos"
                  name="RegimenDeTrabajo"
                  placeholder="Selecciona cargo"
                >
                  {props.regimenes.map((value, index) => (
                    <Select.Option key={index} name="Cargo" value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Código base"
                name="Codigo_Base"
                rules={[
                  {
                    required: false,
                    message: "Debe introducir un código base",
                  },
                ]}
              >
                <Input
                  name="Codigo_Base"
                  placeholder="Codigo Base"
                />
              </Form.Item>
            </Panel>

            {/* -----------------------------------FORMULARIO DATOS DE CONTACTO---------------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Contacto Emergencia
                </h4>
              }
              key="2"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="3"
                  src={contacto}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                //   <h4
                //     key="1"
                //     style={{
                //       position: "absolute",
                //       top: 5,
                //       right: 55,
                //       color: "#061fcb",
                //     }}
                //   >
                //     Opcional
                // </h4>,
              ]}
            >
              <Form.Item name={"Contacto_Nombre"} label="Nombres:">
                <Input
                  name="Contacto_Nombre"
                  className="campos-formulario-contratos"
                />
              </Form.Item>
              <Form.Item
                name={"Contacto_Correo"}
                label="Correo electrónico"
                rules={[
                  {
                    type: "email",
                  },
                ]}
              >
                <Input
                  name="Contacto_Correo"
                  className="campos-formulario-contratos"
                />
              </Form.Item>
              <Form.Item
                name={"Contacto_Fono_Fijo"}
                label="Fono Contacto Fijo"
                hasFeedback
              >
                <Input
                  name={"Contacto_Fono_Fijo"}
                  className="campos-formulario-contratos"
                />
              </Form.Item>
              <Form.Item
                name="Contacto_Fono_Celular"
                label="Fono Contacto Celular"
                hasFeedback
              >
                <Input
                  name="Contacto_Fono_Celular"
                  className="campos-formulario-contratos"
                />
              </Form.Item>
            </Panel>

            {/* -----------------------------FORMULARIO DATOS FAMILIARES Y LEGALES------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Familiares y Legales
                </h4>
              }
              key="3"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={familiares}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Estado civil"
                name="Estado_Civil"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir su estado civil",
                  },
                ]}
              >
                <Radio.Group
                  initialValues={false}
                >
                  <Radio value={"Soltero/a"}>Soltero/a</Radio>
                  <Radio value={"Casado/a"}>Casado/a</Radio>
                  <Radio value={"Viudo/a"}>Viudo/a</Radio>
                  <Radio value={"Divorciado/a"}>Divorciado/a</Radio>
                </Radio.Group>
              </Form.Item>

              <hr className="division" />
              <br />
              <h3 style={{ textAlign: "center", fontFamily: "MavenPro-Bold" }}>
                Datos Hijos
              </h3>
              <Form.Item
                name="Cantidad_Hijos"
                label="Número de hijos:"
                rules={[
                  {
                    required: true,
                    message: "Se debe ingresar cantidad de hijos",
                  },
                ]}
              >
                <Input
                  name="Cantidad_Hijos"
                  className="campos-formulario-contratos"
                />
              </Form.Item>
            </Panel>
            {/* ---------------------------------------------TÍTULOS------------------------------------------------ */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Títulos
                </h4>
              }
              key="4"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={titulos_icon}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Títulos"
                style={{ marginTop: "20px" }}
                name="Titulos_Profesionales"
              >
                <Select
                  mode="multiple"
                  className="campos-formulario-contratos"
                  name="Titulos_Profesionales"
                  placeholder="Selecciona títulos"
                >
                  {props.titulos.map((value, index) => (
                    <Select.Option
                      name="Titulos_Profesionales"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* --------------------------------------------ESPECIALIDADES------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Especialidades
                </h4>
              }
              key="5"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={especialidades_icon}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Especialidades"
                style={{ marginTop: "20px" }}
                name="Especialidades"
              >
                <Select
                  mode="multiple"
                  className="campos-formulario-contratos"
                  name="Especialidades"
                  placeholder="Seleccione especialidades"
                >
                  {props.especialidades.map((value, index) => (
                    <Select.Option
                      name="Especialidades"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* ------------------------------------------- EPPS ------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  E.P.P.
                </h4>
              }
              key="6"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={epp}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item label="E.P.P" style={{ marginTop: "20px" }} name="EPP">
                <Select
                  mode="multiple"
                  className="campos-formulario-contratos"
                  name="EPP"
                  placeholder="Seleccione E.P.P."
                >
                  {props.epps.map((value, index) => (
                    <Select.Option name="EPP" key={index} value={value._id}>
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* ------------------------------------------- DOCUMENTOS ------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Documentos
                </h4>
              }
              key="7"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={contrato}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Documentos"
                style={{ marginTop: "20px" }}
                name="TiposDeDocumentos"
              >
                <Select
                  mode="multiple"
                  className="campos-formulario-contratos"
                  name="TiposDeDocumentos"
                  placeholder="Seleccione Documentos requeridos"
                >
                  {props.documentos.map((value, index) => (
                    <Select.Option
                      name="TiposDeDocumentos"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* -----------------------------------CARACTERÍSTICAS DE CONTRATO---------------------------------------- */}
            {/* <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Características del contrato
                </h4>
              }
              key="8"
              className="site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={contrato}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Alert
                message="Si desea modificar el contrato del trabajador, hacerlo en el módulo de contratos y finiquitos."
                type="info"
                showIcon
                style={{ width: '85%', marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' }}
              />

              <Form.Item
                name={"Tipo_Contrato"}
                label="Tipo de contrato"
                style={{ marginTop: "20px" }}
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  onChange={(e) => {
                    setTipoFechaContrato(e.target.value);

                    form.setFieldsValue({
                      Contrato_Fecha: null,
                    });
                  }}
                  disabled={true}
                >
                  <Radio value={"Plazo fijo"}>Plazo Fijo</Radio>
                  <Radio value={"Indefinido"}>Indefinido</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name={"Contrato_Fecha"}
                label="Fecha de contratación"
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >
                {condicionalFechaContrato()}

              </Form.Item>

              <Form.Item
                name={"Costo_Bruto_Diario"}
                label={<p style={{ margin: "0px" }}>Costo Bruto Diario</p>}
                rules={[
                  {
                    required: false,
                    message: "Obligatorio",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  className="campos-formulario-contratos"
                  // max={20}
                  // maxLength={20}
                  disabled={true}
                />
              </Form.Item>

              <Form.Item
                name={"Costo_Liquido_Diario"}
                label={<p style={{ margin: "0px" }}>Costo Liquido Diario</p>}
                rules={[
                  {
                    required: false,
                    message: "Obligatorio",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  className="campos-formulario-contratos"
                  // max={20}
                  // maxLength={20}
                  disabled={true}
                />
              </Form.Item>

            </Panel> */}

            {/* -----------------------------------VACACIONES---------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  Vacaciones
                </h4>
              }
              key="9"
              className="site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={vacaciones}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                name={"Requirio_Progresivas"}
                label={
                  <p style={{ margin: "0px" }}>
                    ¿Requirió vacaciones
                    <br />
                    progresivas?
                  </p>
                }
                style={{ marginTop: "20px" }}
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >
                <Radio.Group
                  className={"input-small"}
                  style={{ display: "flex" }}
                  onChange={(e) => {
                    setRequirioProgre(e.target.value);

                    form.setFieldsValue({
                      Fecha_Progresivas: null,
                    });
                  }}
                >
                  <Radio value={true}>Sí</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              {condicionalFechaProgresivas()}

              <Form.Item
                name={"Meses_Empleadores_Anteriores"}
                label={
                  <p style={{ margin: "0px" }}>
                    Meses con empleadores
                    <br />
                    anteriores
                  </p>
                }
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >
                <InputNumber type="number" min={0} className={"campos-formulario-contratos"} />
              </Form.Item>

              <Form.Item
                name={"Regimen_Vacaciones"}
                label="Régimen de vacaciones"
                // rules={[
                //   {
                //     required: true,
                //     message: "Obligatorio",
                //   },
                // ]}
                style={{ marginBottom: "5px" }}
                validateStatus={validateOtro}
                hasFeedback={true}
                rules={[
                  {
                    required: true,
                    message: "Debe seleccionar el régimen de vacaciones.",
                  },
                ]}
              >
                <Radio.Group
                  // style={{ display: "flex" }}
                  onChange={(e) => {
                    if (e.target.value === -1) {
                      setOtroSelected(true);
                      setValidateOtro("error");
                    } else {
                      setOtroSelected(false);
                      setValidateOtro("success");

                      form.setFieldsValue({
                        otro: undefined,
                      });
                    }

                    // if (form.getFieldsValue(['Regimen_Vacaciones'] !== -1) && form.getFieldsValue(['otro']) !== ) {

                    // }
                  }}
                >
                  <Radio value={15}>15 días</Radio>
                  <Radio value={20}>20 días</Radio>
                  <Radio value={-1}>Otro</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item name={"otro"} label=" " className="item-otro">
                <InputNumber
                  placeholder={"otra cantidad"}
                  className="input-otro"
                  type="number"
                  min={0}
                  disabled={!otroSelected}
                  onChange={async (e) => {
                    if (e) {
                      await setValidateOtro("success");
                    } else {
                      await setValidateOtro("error");
                    }
                  }}
                />
              </Form.Item>
            </Panel>
          </Collapse>
        </Col>

        {/* SUBMIT BTN */}
        <Col span={24}
          style={{ textAlign: "right", marginTop: 20 }}
        >
          <Button className="guardar-form" type="primary" htmlType="submit">
            Guardar cambios
          </Button>
        </Col>
      </Row>

    </Form>
  );
};

export default FormEditarTrabajadorContrato;
