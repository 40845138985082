import API from "../../Redux/Api/api";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/es";

export const traerBitacora = async (
  idActividad,
  idPlaza,
  idPuestoPlaza,
  fechaInicio,
  fechaFin,
  idTrabajador,
  inicioBusqueda,
  finBusqueda,
  IdRangoFechas
) => {
  try {
    const url = `${API}/DetalleActividad/TraerBitacora?idActividad=${idActividad}&idPlaza=${idPlaza}&idPuestoPlaza=${idPuestoPlaza}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&idTrabajador=${idTrabajador}&inicioBusqueda=${inicioBusqueda}&finBusqueda=${finBusqueda}&IdRangoFechas=${IdRangoFechas}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
        data = await response.json();

        let bitacora = data.data;
        return bitacora;

      default:
        const error = data.err.message.toString();
        console.error(error);
        return [];
    }
  } catch (error) {
    console.error(error);
  }
};

export const traerTrabajadoresAsignar = async (
  idActividad,
  idPlazaPlantilla,
  fechaInicio,
  fechaFin,
  idPuestoPlaza,
  idPlazaBit,
  IdRangoFechas
) => {

  try {
    const url = `${API}/DetalleActividad/TraerTrabajadoresAsignar?idActividad=${idActividad}&idPlaza=${idPlazaPlantilla}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&idPuestoPlaza=${idPuestoPlaza}&idPlazaBit=${idPlazaBit}&IdRangoFechas=${IdRangoFechas}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
        data = await response.json();

        let trabajadores = data.data;
        return trabajadores;

      default:
        const error = data.err.message.toString();
        console.error(error);
        return [];
    }
  } catch (error) {
    console.error(error);
  }
};

export const alargarPlanificacion = async (dataEnviar) => {
  try {
    const urlBitacora = `${API}/Faena/AlargarPlanificacion/Trabajador`;
    const responseBitacora = await fetch(urlBitacora, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(dataEnviar),
    });

    const statusBitacora = responseBitacora.status;
    let dataBitacora = await responseBitacora.json();

    let respuesta = { status: statusBitacora, data: dataBitacora };

    return respuesta;
  } catch (error) {
    console.error(error);
  }
};

export const acortarPlanificacion = async (dataEnviar) => {
  try {
    const urlBitacora = `${API}/Faena/AcortarPlanificacion/Trabajador`;
    const responseBitacora = await fetch(urlBitacora, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(dataEnviar),
    });

    const statusBitacora = responseBitacora.status;
    let dataBitacora = await responseBitacora.json();

    let respuesta = { status: statusBitacora, data: dataBitacora };

    return respuesta;
  } catch (error) {
    console.error(error);
  }
};

export const buscarTrabajadoresEliminarHoras = async (
  idTrabajador,
  idPlazaCubrir,
  idPlaza,
  Inicio_Planificacion,
  Fin_Planificacion,
  idFaena
) => {
  try {
    const urlFaena = `${API}/Faena/ByDates/${idFaena}`;

    const objEnviar = {
      fecha_inicio: Inicio_Planificacion,
      fecha_fin: Fin_Planificacion,
    };

    const responseFaena = await fetch(urlFaena, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(objEnviar),
    });

    const statusFaena = responseFaena.status;
    const dataFaena = await responseFaena.json();

    switch (statusFaena) {
      case 200:
      case 201:
        const faena = dataFaena.faena;

        const url = `${API}/Trabajadores/${idTrabajador}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            mode: "no-cors",
          },
        });

        const status = response.status;
        const data = await response.json();

        switch (status) {
          case 200:
          case 201:
            let trabajador = data.trabajador;

            const urlBitacora = `${API}/BitacoraTrabajadores/ByDates/${idTrabajador}`;
            const bodyEnviar = {
              Inicio_Planificacion,
              Fin_Planificacion: Fin_Planificacion,
            };
            const responseBitacora = await fetch(urlBitacora, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                mode: "no-cors",
              },
              body: JSON.stringify(bodyEnviar),
            });

            const statusBitacora = responseBitacora.status;
            const dataBitacora = await responseBitacora.json();

            switch (statusBitacora) {
              case 200:
              case 201:
                let bitacoraTrabajador = dataBitacora.data;

                let arregloDiasPoderEliminar = [];

                bitacoraTrabajador.forEach((bitacora) => {
                  if (
                    // bitacora.IdGrupo === idGrupo &&
                    bitacora.IdPuestoPlaza === idPlazaCubrir &&
                    bitacora.IdTrabajador === idTrabajador &&
                    moment
                      .utc(Inicio_Planificacion)
                      .isSame(moment.utc(bitacora.Inicio_Planificacion)) &&
                    moment
                      .utc(Fin_Planificacion)
                      .isSame(moment.utc(bitacora.Fin_Planificacion))
                  ) {
                    arregloDiasPoderEliminar.push(bitacora);
                  }
                });

                let informacionTrabajador = {
                  trabajador,
                  bitacoraTrabajador,
                  arregloDiasPoderEliminar,
                  idPlazaCubrir,
                  idPlaza,
                  Inicio_Planificacion,
                  Fin_Planificacion,
                  FaenaEliminar: faena,
                };

                let respuesta = {
                  status: statusBitacora,
                  data: informacionTrabajador,
                };
                return respuesta;

              case 404:
                return Swal.fire(
                  "Error",
                  "No se encontró el trabajador",
                  "error"
                );

              default:
                return Swal.fire(
                  "Error",
                  `Error al realizar la petición, Status: ${status}`,
                  "error"
                );
            }

          case 404:
            return Swal.fire("Error", "No se encontró al trabajador", "error");

          default:
            return Swal.fire(
              "Error",
              `Error al realizar la petición, Status: ${status}`,
              "error"
            );
        }

      case 404:
        return Swal.fire("Error", "Actividad no encontrada", "error");
      default:
        return Swal.fire(
          "Error",
          `Error al realizar la petición, Status: ${status}`,
          "error"
        );
    }
  } catch (error) {
    console.error(error);
  }
};

export const eliminarPlanificacion = async (
  Inicio_Planificacion,
  Fin_Planificacion,
  idTrabajador,
  idPlaza,
  idPuestoPlaza,
  idFaena
) => {
  try {
    const urlBitacora = `${API}/Faena/planificacion/trabajador`;

    const objEnviar = {
      Fecha_Inicio: Inicio_Planificacion,
      Fecha_Fin: Fin_Planificacion,
      IdTrabajador: idTrabajador,
      idPlaza,
      idPuestoPlaza,
      idfaena: idFaena,
    };

    const responseBitacora = await fetch(urlBitacora, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(objEnviar),
    });

    const statusBitacora = responseBitacora.status;
    let dataBitacora = await responseBitacora.json();

    let respuesta = { status: statusBitacora, data: dataBitacora };
    return respuesta;
  } catch (error) {
    console.log(error);
  }
};

export const detalleActividad = async (
  idEmpresa,
  idFaena,
  fechaInicio,
  fechaFin,
  plazas,
  primerBusqueda
) => {
 
  try {
    const url = `${API}/DetalleActividad/BitacoraTrabajadores?idEmpresa=${idEmpresa}&idFaena=${idFaena}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`;
    let objetoEnviar = {};
    objetoEnviar.plazas = plazas && Array.isArray(plazas) && plazas.length > 0 ? plazas : [];
    objetoEnviar.primerBusqueda = primerBusqueda === undefined ? false : primerBusqueda;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objetoEnviar),
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
        data = await response.json();

        let bitacoraTrabajadores = data.data;
        return bitacoraTrabajadores;

      default:
        const error = data.err.message.toString();
        console.error(error);
        return [];
    }
  } catch (error) {
    console.error(error);
  }
};

export const agregarBitacorasPlaza = async (objEnviar, idPlaza) => {
  try {
    const url = `${API}/Plaza/${idPlaza}/Agregar/Trabajador`;
  
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(objEnviar),
    });

    const statusAgregarPlaza = response.status;
    let data;

    switch (statusAgregarPlaza) {
      case 200:
      case 201:
        return { status: 200, mensaje: "ok" };
      case 404:
        return { status: 404, mensaje: "Servicio no encontrado" };
      case 409:
        data = await response.json();
        return { status: 404, mensaje: data.err };
      case 420:
        return {
          status: 420,
          mensaje:
            "No se pueden eliminar faenas en un periodo que se encuentra cerrado",
        };
      default:
        return {
          status: statusAgregarPlaza,
          mensaje: `Error al realizar la petición, Status: ${statusAgregarPlaza}`,
        };
    }
  } catch (error) {
    return { status: 500, mensaje: `Error al realizar la peticion, catch` };
  }
};

export const agregarBitacorasTrabajador = async (BitacoraTrabajador, idfaena, idEmpresa, fecha_inicio, fecha_fin) => {
  try {
    const urlBitacoraTrabajador = `${API}/BitacoraTrabajadores?idfaena=${idfaena}&idEmpresa=${idEmpresa}&fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}`;

    const responseTrabajador = await fetch(urlBitacoraTrabajador, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(BitacoraTrabajador),
    });

    const statusTrabajador = responseTrabajador.status;

    let data;

    switch (statusTrabajador) {
      case 200:
      case 201:
        return { status: 200, mensaje: "ok" };
      case 404:
        return { status: 404, mensaje: "Servicio no encontrado" };
      case 409:
        data = await responseTrabajador.json();
        return { status: 404, mensaje: data.err };
      case 420:
        return {
          status: 420,
          mensaje:
            "No se pueden eliminar faenas en un periodo que se encuentra cerrado",
        };
      default:
        return {
          status: statusTrabajador,
          mensaje: `Error al realizar la petición, Status: ${statusTrabajador}`,
        };
    }
  } catch (error) {
    return { status: 500, mensaje: `Error al realizar la peticion, catch` };
  }
};

export const traerCargosTrabajador = async (idEmpresa) => {
  try {
    const urlCargosTrabajador = `${API}/CargosTrabajadores?empresa=${idEmpresa}`;

    const response = await fetch(urlCargosTrabajador, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
        data = await response.json();

        let bitacora = data.data;
        return bitacora;

      default:
        const error = data.err.message.toString();
        console.error(error);
        return [];
    }
  } catch (error) {
    return { status: 500, mensaje: `Error al realizar la peticion, catch` };
  }
};

export const traerTitulosTrabajador = async (idEmpresa) => {
  try {
    const urlTitulosObligatoriosTrabajador = `${API}/Titulos/Vigentes?empresa=${idEmpresa}`;

    const response = await fetch(urlTitulosObligatoriosTrabajador, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
        data = await response.json();

        let titulos = data.data;
        return titulos;

      default:
        const error = data.err.message.toString();
        console.error(error);
        return [];
    }
  } catch (error) {
    return { status: 500, mensaje: `Error al realizar la peticion, catch` };
  }
};

export const traerEspecialidadesTrabajador = async (idEmpresa) => {
  try {
    const urlEspecialidadesObligatoriasTrabajador = `${API}/Especialidades/Vigentes?empresa=${idEmpresa}`;

    const response = await fetch(urlEspecialidadesObligatoriasTrabajador, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
        data = await response.json();

        let especialidades = data.data;
        return especialidades;

      default:
        const error = data.err.message.toString();
        console.error(error);
        return [];
    }
  } catch (error) {
    return { status: 500, mensaje: `Error al realizar la peticion, catch` };
  }
};

export const traerPlazasTrabajador = async (idFaena, idEmpresa, idPlaza) => {
  try {
    let url = idPlaza
      ? `${API}/Plazas/${idFaena}?empresa=${idEmpresa}&idPlaza=${idPlaza}`
      : `${API}/Plazas/${idFaena}?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;

    console.log(status);

    let data;

    switch (status) {
      case 200:
        data = await response.json();

        let plazas = data;
        return plazas;

      default:
        const error = data.err.message.toString();
        console.error(error);
        return [];
    }
  } catch (error) {
    console.error(error);
  }
};

export const agregarNuevaPlaza = async (objEnviar, idEmpresa) => {
  try {
    const url = `${API}/Faena/Agregar/Plaza?empresa=${idEmpresa}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(objEnviar),
    });

    const statusAgregarNuevaPlaza = response.status;
    let data;

    switch (statusAgregarNuevaPlaza) {
      case 200:
      case 201:
        return { status: 200, mensaje: "ok" };
      case 404:
        return { status: 404, mensaje: "Servicio no encontrado" };
      case 409:
        data = await response.json();
        return { status: 404, mensaje: data.mensaje };
      default:
        return {
          status: statusAgregarNuevaPlaza,
          mensaje: `Error al realizar la petición, Status: ${statusAgregarNuevaPlaza}`,
        };
    }
  } catch (error) {
    return { status: 500, mensaje: `Error al realizar la peticion, catch` };
  }
};

export const agregarPuestoTrabajo = async (objEnviar, idEmpresa) => {
  try {
    const url = `${API}/Faena/Agregar/PuestoTrabajo?empresa=${idEmpresa}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(objEnviar),
    });

    const statusAgregarPuestoTrabajo = response.status;
    let data;

    switch (statusAgregarPuestoTrabajo) {
      case 200:
      case 201:
        return { status: 200, mensaje: "ok" };
      case 404:
        return { status: 404, mensaje: "Servicio no encontrado" };
      case 409:
        data = await response.json();
        return { status: 404, mensaje: data.mensaje };
      default:
        return {
          status: statusAgregarPuestoTrabajo,
          mensaje: `Error al realizar la petición, Status: ${statusAgregarPuestoTrabajo}`,
        };
    }
  } catch (error) {
    return { status: 500, mensaje: `Error al realizar la peticion, catch` };
  }
};

export const editarPuestoTrabajo = async (objEnviar, idEmpresa) => {
  try {
    const url = `${API}/Faena/Editar/PuestoTrabajo?empresa=${idEmpresa}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(objEnviar),
    });

    const statusEditarPuestoTrabajo = response.status;

    console.log("statusEditarPuestoTrabajo");
    console.log(statusEditarPuestoTrabajo);

    let data;

    switch (statusEditarPuestoTrabajo) {
      case 200:
      case 201:
        return { status: 200, mensaje: "ok" };
      case 404:
        return { status: 404, mensaje: "Servicio no encontrado" };
      case 409:
        data = await response.json();
        return { status: 404, mensaje: data.mensaje };
      default:
        return {
          status: statusEditarPuestoTrabajo,
          mensaje: `Error al realizar la petición, Status: ${statusEditarPuestoTrabajo}`,
        };
    }
  } catch (error) {
    return { status: 500, mensaje: `Error al realizar la peticion, catch` };
  }
};

export const suprimirPlaza = async (objEnviar, idEmpresa) => {
  try {
    const url = `${API}/Faena/Eliminar/PuestoTrabajo?empresa=${idEmpresa}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(objEnviar),
    });

    const statusSuprimirPlaza = response.status;
    let data;

    switch (statusSuprimirPlaza) {
      case 200:
      case 201:
        return { status: 200, mensaje: "ok" };
      case 404:
        return { status: 404, mensaje: "Servicio no encontrado" };
      case 409:
        data = await response.json();
        return { status: 404, mensaje: data.mensaje };
      default:
        return {
          status: statusSuprimirPlaza,
          mensaje: `Error al realizar la petición, Status: ${statusSuprimirPlaza}`,
        };
    }
  } catch (error) {
    return { status: 500, mensaje: `Error al realizar la peticion, catch` };
  }
};
