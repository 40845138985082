import React, { useEffect } from "react";
import logoGenerico from "../../img/logos/logoGenerico.svg";
import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  DatePicker,
} from "antd";
import locale from 'antd/es/date-picker/locale/es_ES';

import Moment from 'moment';
import Swal from "sweetalert2";

const FormularioFeriadosEditar = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {

    values.Fecha = values.Fecha.format();
    

    props.editarFeriado(values);
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  useEffect(() => {
    console.log('use effect');
    let { Descripcion, Fecha ,Vigencia } = props.Formulario;
    let fechaMoment = Moment(Fecha);
    form.setFieldsValue({
        Descripcion,
        Fecha:fechaMoment,
        Vigencia
    });
  });

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={logoGenerico} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Editar Feriado"
          />
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Form.Item
              label="Fecha"
              name="Fecha"
              rules={[
                {
                  required: true,
                  message: "Debe introducir una fecha",
                },
              ]}
            >
              <DatePicker
              locale={locale}
              format="DD-MM-YYYY"
              onChange={(e)=>{
                  console.log(e);
                // let valor = e.target.value;
                // props.TomarValoresEditar("Descripcion", valor);
            }}
              />
            </Form.Item>
            <Form.Item
              label="Descripción"
              name="Descripcion"
              rules={[
                {
                  min: 1,
                  required: true,
                  message: "Debe introducir una descripción",
                },
              ]}
              hasFeedback
            >
              <Input
                name="Descripcion"
                className="campos"
                placeholder="Descripcion del feriado"
                style={{
                  maxWidth:"100%"
                }}
                onChange={(e)=>{
                    let valor = e.target.value;
                    props.TomarValoresEditar("Descripcion", valor);
                }}
              />
            </Form.Item>
            <Form.Item label="Vigencia" name="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                checked={props.Formulario.Vigencia}
                defaultChecked
                className="switch"
                onChange={()=>{
                    let nuevoValor =
                    props.Formulario.Vigencia === true ? false : true;
                    props.TomarValoresEditar("Vigencia", nuevoValor);
                }}
              />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Editar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormularioFeriadosEditar;
