import React, { Component } from "react";
import "./desviaciones.css";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import SemaforoBarchartNew from "../../components/desviaciones/SemaforoBarchartNew";
import PeriodosBarchart from "../../components/desviaciones/PeriodosBarchart";

import {
  Layout, DatePicker, Row, Col, Select, InputNumber, Form, Button, Card,
  Modal, PageHeader, Tabs, Empty, Table, Space, Input, Divider, Popover
} from "antd";

import red from '../../img/desviaciones/red.png'
import yellow from '../../img/desviaciones/yellow.png'
import green from '../../img/desviaciones/green.png'
import grey from '../../img/desviaciones/grey.png'

// import embarcado from "../../img/icons/embarcado.svg";
// import descanso from "../../img/icons/descanso.svg";

import {
  obtenerCargos,
  obtenerCC,
  obtenerReporteDescansos,
  obtenerReporteTrabajo,
} from './desviacionesApi';

import {
  momentToStringFechaInicio,
  momentToStringFechaFin,
  databaseFormat,
} from '../../funcionesGenericas/fechas';

import Swal from "sweetalert2";
import accesosPagina from '../../funcionesGenericas/accesosUsuario';
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import {
  CheckCircleOutlined, ExclamationCircleOutlined, QuestionCircleOutlined, EyeFilled,
  LeftCircleTwoTone, InfoCircleTwoTone,
} from '@ant-design/icons';

const { Header, Content } = Layout;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Column } = Table;
const { Search } = Input;

class Desviaciones extends Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: true,

      cargos: [],
      centrosCosto: [],

      dataTrabajadoresSemaforo: [],
      dataSemaforo: [],

      modalDetalle: false,
      selectedTab: 'Sin Desviación',

      searchTrabajadores: '',
      trabajadorDetalle: null,

      variacion: 0,
      tipoReporte: 'descanso',
      fechasFiltradas: null,

      cantidadTrabajadores: 0,
    };
  }

  filtrar = async (values) => {


    // validación: deben existir fechas
    if (!values.fechas) {
      Swal.fire({
        icon: "warning",
        title: "Advertencia",
        text: "Debes seleccionar un rango de fechas para filtrar.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Ok",
      });

      return;
    }

    this.setState({ loading: true, variacion: values.variacion });

    let objFiltros = { ...values };

    objFiltros.idEmpresa = this.state.idEmpresa;

    //pasando las fechas a db format
    objFiltros.fechaInicio = momentToStringFechaInicio(values.fechas[0], databaseFormat);
    objFiltros.fechaFin = momentToStringFechaFin(values.fechas[1], databaseFormat);

    delete objFiltros.fechas;

    let data = [];

    // va a diferentes endpoints según el tipo de reporte
    if (values.tipoReporte === 'descanso') {

      data = await obtenerReporteDescansos(objFiltros);

    } else if (values.tipoReporte === 'trabajo') {

      data = await obtenerReporteTrabajo(objFiltros);

      this.setState({
        loading: false,
      });
    }

    const dataKeys = Object.keys(data);

    let cantidadTrabajadores = 0;

    dataKeys.forEach(key => {
      cantidadTrabajadores += data[key].length;
    })

    const dataSemaforo = this.ordenarDataChart(data);

    this.setState({
      loading: false,
      dataSemaforo,
      dataTrabajadoresSemaforo: data,
      tipoReporte: values.tipoReporte,
      fechasFiltradas: [values.fechas[0], values.fechas[1]],
      cantidadTrabajadores,
    });
  }

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");

    if (usuario) {

      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      const cargos = await obtenerCargos(idEmpresa);
      const centrosCosto = await obtenerCC(idEmpresa);

      const accesos = await accesosPagina.obtenerAccesosPagina('Desviaciones');
      // console.log(cargos)
      // console.log(centrosCosto[0]._id)
      // console.log(accesos)

      this.setState({
        cargos,
        centrosCosto,

        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
        idEmpresa,
      });

      const defaultFechas = [moment().subtract(30, 'days'), moment()];

      // valores por defecto
      this.formRef.current.setFieldsValue({
        fechas: defaultFechas,
        variacion: 0,
        tipoReporte: 'descanso',
        centroCosto: centrosCosto[0]._id
      });

      // Primera búsqueda
      const objFiltros = {
        fechas: defaultFechas,
        tipoReporte: 'descanso',
        variacion: 0,
        idEmpresa,
        centroCosto: centrosCosto[0]._id
      }

      this.filtrar(objFiltros);
    }
  }

  ordenarDataChart = (data) => {

    const dataChart = [
      { tipoDesviacion: 'Sin Desviación', Cantidad: data.sinDesviacion.length },
      { tipoDesviacion: 'Desviación Tolerada', Cantidad: data.desviacionTolerada.length },
      { tipoDesviacion: 'Desviación No Tolerada', Cantidad: data.desviacionNoTolerada.length },
    ];

    if (data.sinDescansos) {
      dataChart.push({ tipoDesviacion: 'Sin Descansos En Periodo', Cantidad: data.sinDescansos.length })
    }

    if (data.sinTrabajo) {
      dataChart.push({ tipoDesviacion: 'Sin Trabajo En Periodo', Cantidad: data.sinTrabajo.length })
    }

    return dataChart;
  }

  cerrarModal = () => {
    this.setState({
      modalDetalle: false,
      searchTrabajadores: '',
      trabajadorDetalle: null,
    });
  }

  levantarModal = (tipoDesviacion) => {

    this.setState({
      modalDetalle: true,
      selectedTab: tipoDesviacion,
    });
  }

  listarTrabajadores = (tipoDesviacion) => {

    // no debe intentar leer data si no se ha levantado el modal
    if (!this.state.modalDetalle) {
      return;
    }

    let { dataTrabajadoresSemaforo: data, searchTrabajadores } = this.state;

    let arrTrabajadores = [];
    let iconCard;

    switch (tipoDesviacion) {
      case 'Sin Desviación':
        arrTrabajadores = data.sinDesviacion;
        iconCard = green;
        break;

      case 'Desviación Tolerada':
        arrTrabajadores = data.desviacionTolerada;
        iconCard = yellow;
        break;

      case 'Desviación No Tolerada':
        arrTrabajadores = data.desviacionNoTolerada;
        iconCard = red;
        break;

      case 'Sin Descansos En Periodo':
        arrTrabajadores = data.sinDescansos;
        iconCard = grey;
        break;

      case 'Sin Trabajo En Periodo':
        arrTrabajadores = data.sinTrabajo;
        iconCard = grey;
        break;

      default:
        arrTrabajadores = [];
        break;
    }

    if (arrTrabajadores.length > 0) {

      let dataTabla = [];

      // Preparando data para la tabla
      arrTrabajadores.forEach(trabajador => {

        const { _id, Imagen, Rut, Nombre, Apellido_Paterno, Apellido_Materno,
          Cargo, RegimenDeTrabajo, desviacionTotal, cantidadPeriodos } = trabajador;

        let desviacionTrab = desviacionTotal || 0;

        if (desviacionTrab !== 0) {
          desviacionTrab = this.redondearDosDecimales(desviacionTrab);
        }

        let trabajadorTabla = {
          _id,
          Imagen,
          Rut,
          nombreTrabajador: `${Apellido_Paterno} ${Apellido_Materno} ${Nombre}`,
          nombreRegimen: `${RegimenDeTrabajo.Cantidad_Dias_Trabajo}x${RegimenDeTrabajo.Cantidad_Dias_Descanso}`,
          nombreCargo: Cargo.Nombre,

          desviacionTrab: `${desviacionTrab}%`,
          cantidadPeriodos: `${cantidadPeriodos} periodos`,
          iconCard,
        };


        dataTabla.push(trabajadorTabla);
      })

      return (
        <div className={'maven-r cont-tabla-celeste'}>

          <div style={{ marginBottom: '20px' }}>
            <Search
              placeholder="Buscar"
              enterButton
              style={{ width: '200px' }}
              value={searchTrabajadores}
              onChange={(e) => this.setState({ searchTrabajadores: e.target.value })}
            />
          </div>

          <Table
            rowKey="_id"
            dataSource={searchTrabajadores !== '' ? this.filtrarTabla(dataTabla) : dataTabla}
            bordered={true}
            locale={{
              emptyText: "No se encontraron trabajadores por la búsqueda de texto.",
            }}
            scroll={{ x: true }}
            showSorterTooltip={false}
          >

            <Column
              align="center"
              dataIndex="Imagen"
              title="Imagen"
              key="Imagen"
              render={(value) => {
                return <img
                  src={value}
                  alt={'img-trabajador'}
                  style={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '100%',
                  }}
                />
              }}
            />

            <Column
              dataIndex="nombreTrabajador"
              key="nombreTrabajador"
              title="Nombre"
              sorter={(a, b) => {
                return a.nombreTrabajador.localeCompare(b.nombreTrabajador);
              }}
            />

            <Column
              dataIndex="Rut"
              key="Rut"
              title="Rut"
              width={200}
              render={(text, record) => (
                <div style={{ whiteSpace: 'nowrap' }}>
                  {text}
                </div>
              )}
            />

            <Column
              dataIndex="nombreCargo"
              key="nombreCargo"
              title="Cargo"
              sorter={(a, b) => {
                return a.nombreCargo.localeCompare(b.nombreCargo);
              }}
            />

            <Column
              dataIndex="desviacionTrab"
              key="desviacionTrab"
              title="Desviación Acumulada"
              align='center'
              sorter={(a, b) => {
                return a.desviacionTrab.localeCompare(b.desviacionTrab);
              }}
            />

            <Column
              dataIndex="cantidadPeriodos"
              key="cantidadPeriodos"
              title="Cantidad De Periodos"
              align='center'
              sorter={(a, b) => {
                return a.cantidadPeriodos.localeCompare(b.cantidadPeriodos);
              }}
            />

            <Column
              key="id"
              title="Ver Detalle"
              align='center'
              render={(val) => (
                <Space size="middle">
                  <Button
                    onClick={() => {
                      this.setTrabajadorDetalle(val, arrTrabajadores);
                    }}
                    icon={<EyeFilled style={{ fontSize: '21px' }} />}
                    className="detalle-trab-btn"

                  />
                </Space>
              )}
            />

          </Table>

        </div>
      )

    } else {
      return (
        <div style={{ marginBottom: '50px' }}>
          <Empty
            imageStyle={{
              height: 200,
              marginTop: "25px",
              marginBottom: '0px',
            }}
            description={
              <span className={'maven-r'}>
                No se han encontrado trabajadores en este estado.
            </span>
            }
          ></Empty>
        </div>

      )
    }

  }

  filtrarTabla = (arrTrabajadores) => {

    const { searchTrabajadores } = this.state;

    const trabajadoresFiltrado = arrTrabajadores.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(searchTrabajadores.toLowerCase())
      )
    );

    return (trabajadoresFiltrado);
  };

  setTrabajadorDetalle = (trabajadorTabla, arrTrabajadores) => {

    let trabajadorData = arrTrabajadores.filter(trab => trab._id === trabajadorTabla._id)[0];

    this.setState({ trabajadorDetalle: { ...trabajadorData, ...trabajadorTabla } });
  }

  orderDataPeriodoDescanso = (doc, regimen) => {

    let { DiasEfectivos, DiasCompensados, IdsBitacorasTrabajo } = doc;
    let compensados = DiasCompensados || 0;

    let diasTrabajo = IdsBitacorasTrabajo.length;
    let diasDescanso = DiasEfectivos + compensados;


    let trabajoRegimen = regimen.Cantidad_Dias_Trabajo;
    let descansoRegimen = regimen.Cantidad_Dias_Descanso;

    // let { porcentajeTrabajo: porcTrabajoRegimen, porcentajeDescanso: porcDescansoRegimen } = this.calcularProporcionesRegimen(trabajoRegimen, descansoRegimen);

    // let generadoRegimen = descansoRegimen / trabajoRegimen;
    // generadoRegimen = Math.round((generadoRegimen + Number.EPSILON) * 100) / 100;

    // let generadoReal = diasDescanso / diasTrabajo;
    // generadoReal = Math.round((generadoReal + Number.EPSILON) * 100) / 100;

    // let dataChart = [
    //   {
    //     tipo: 'Descanso\nGenerado Real',
    //     value: generadoReal,
    //     type: 'Días Descanso Generado',
    //   },
    //   {
    //     tipo: 'Descanso\nGenerado Régimen',
    //     value: generadoRegimen,
    //     type: 'Días Descanso Generado',
    //   },
    // ];

    let dataChart = [
      {
        tipo: 'Descanso Real',
        value: diasDescanso,
        type: 'Descanso',
      },
      {
        tipo: 'Descanso Objetivo',
        value: descansoRegimen,
        type: 'Descanso',
      },
      {
        tipo: 'Trabajo Real',
        value: diasTrabajo,
        type: 'Trabajo',
      },
      {
        tipo: 'Trabajo Objetivo',
        value: trabajoRegimen,
        type: 'Trabajo',
      },
    ];

    let desviacionTrabajo = trabajoRegimen - diasTrabajo;
    let desviacionDescanso = descansoRegimen - diasDescanso;

    // desviacionTrabajo = Math.round((desviacionTrabajo + Number.EPSILON) * 100) / 100;
    // desviacionDescanso = Math.round((desviacionDescanso + Number.EPSILON) * 100) / 100;

    if (desviacionTrabajo > 0) {

      dataChart.push({
        tipo: 'Trabajo Real',
        value: Math.abs(desviacionTrabajo),
        type: 'Desviación',
      });

    } else if (desviacionTrabajo < 0) {
      dataChart.push({
        tipo: 'Trabajo Objetivo',
        value: Math.abs(desviacionTrabajo),
        type: 'Desviación',
      });
    }

    if (desviacionDescanso > 0) {

      dataChart.push({
        tipo: 'Descanso Real',
        value: Math.abs(desviacionDescanso),
        type: 'Desviación',
      });

    } else if (desviacionDescanso < 0) {

      dataChart.push({
        tipo: 'Descanso Objetivo',
        value: Math.abs(desviacionDescanso),
        type: 'Desviación',
      });
    }

    return dataChart;
  }

  orderDataPeriodoTrabajo = (cantidadTrabajo, regimen) => {

    let trabajoRegimen = regimen.Cantidad_Dias_Trabajo;

    let dataChart = [
      {
        tipo: 'Trabajo Real',
        value: cantidadTrabajo,
        type: 'Trabajo',
      },
      {
        tipo: 'Trabajo Objetivo',
        value: trabajoRegimen,
        type: 'Trabajo',
      },
    ];

    let desviacionTrabajo = trabajoRegimen - cantidadTrabajo;

    if (desviacionTrabajo > 0) {

      dataChart.push({
        tipo: 'Trabajo Real',
        value: Math.abs(desviacionTrabajo),
        type: 'Desviación',
      });

    } else if (desviacionTrabajo < 0) {
      dataChart.push({
        tipo: 'Trabajo Objetivo',
        value: Math.abs(desviacionTrabajo),
        type: 'Desviación',
      });
    }

    return dataChart;

  }

  calcularProporcionesRegimen = (ctdadTrabajo, ctdadDescanso) => {

    let totalDias = ctdadTrabajo + ctdadDescanso;

    // CALCULANDO PORCENTAJE A REGLA DE 3
    let porcentajeTrabajo = (100 * ctdadTrabajo) / totalDias;
    let porcentajeDescanso = (100 * ctdadDescanso) / totalDias;

    // REDONDEANDO A 2 DECIMALES
    porcentajeTrabajo = this.redondearDosDecimales(porcentajeTrabajo);
    porcentajeDescanso = this.redondearDosDecimales(porcentajeDescanso);

    return { porcentajeTrabajo, porcentajeDescanso };
  }

  redirectACalendario = (idTrabajador) => {

    const { fechasFiltradas } = this.state;

    let mesInicio = fechasFiltradas[0].format('YYYY-MM');
    let mesFin = fechasFiltradas[1].format('YYYY-MM');

    window.open(`/CalendarioTrabajadorNew?Trabajador=${idTrabajador}&inicio=${mesInicio}&fin=${mesFin}`);
  }

  redondearDosDecimales = (number) => {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }

  render() {

    const { cargos, centrosCosto, selectedTab, trabajadorDetalle, variacion, tipoReporte, cantidadTrabajadores } = this.state;

    return (
      <Layout style={{ height: "100%" }}>

        <Loading cargando={this.state.loading} />

        <Header style={{ background: "none" }}>
          <HeaderFicha titulo="Reporte de Desviaciones" />
        </Header>

        <hr className="division" style={{ bottom: '12px' }} />

        {/* CONTENIDO */}
        <Content
          className="contenedor-general"
          style={{ background: "none" }}
        >

          {/* FILTROS */}
          <Form
            onFinish={this.filtrar}
            ref={this.formRef}
            className={'maven-r'}
            name={"filtrar-form"}
          >

            <Row gutter={15}>

              {/* FECHAS */}
              <Col xs={24} sm={24} md={4} lg={4} xl={4}
                style={{ marginBottom: '8px' }}
              >
                <p className={'label-filtros'}>Fechas</p>
                <Form.Item
                  name="fechas"
                  noStyle
                >
                  <RangePicker
                    locale={locale}
                    format={"DD-MM-YYYY"}
                    className={'filtro-desviaciones'}
                  />
                </Form.Item>
              </Col>

              {/* CARGO */}
              <Col xs={12} sm={12} md={4} lg={4} xl={4}
                style={{ marginBottom: '8px' }}
              >
                <p className={'label-filtros'}>Cargo</p>

                <Form.Item
                  name="cargo"
                  noStyle
                >
                  <Select
                    placeholder="Cargo"
                    className={'filtro-desviaciones'}
                    allowClear={true}
                  >
                    {cargos.map((value, index) => (
                      <Select.Option key={index} name="Cargo" value={value._id}>
                        {value.Nombre}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

              </Col>

              {/* CENTRO DE COSTO */}
              <Col xs={12} sm={12} md={4} lg={4} xl={4}
                style={{ marginBottom: '8px' }}
              >
                <p className={'label-filtros'}>Centro de costo</p>

                <Form.Item
                  name="centroCosto"
                  noStyle
                >
                  <Select
                    placeholder="Centro de costo"
                    className={'filtro-desviaciones'}
                    allowClear={true}
                  >
                    {centrosCosto.map((value, index) => (
                      <Select.Option key={index} name="Cargo" value={value._id}>
                        {value.Nombre}
                      </Select.Option>
                    ))}
                  </Select>

                </Form.Item>

              </Col>

              {/* VARIACIÓN LIMITE */}
              <Col xs={12} sm={12} md={4} lg={4} xl={4}
                style={{ marginBottom: '8px' }}
              >

                <p className={'label-filtros'}>Desviación Tolerada (+/-)</p>

                <Form.Item
                  name="variacion"
                  noStyle
                >
                  <InputNumber
                    className={'filtro-desviaciones'}
                    placeholder="Variación límite"
                    max={100}
                    min={0}
                  />
                </Form.Item>
                <span className={'percent-fixed-char'}>%</span>
              </Col>

              {/* TIPO DE REPORTE */}
              <Col xs={12} sm={12} md={4} lg={4} xl={4}
                style={{ marginBottom: '8px' }}
              >
                <p className={'label-filtros'}>Tipo de reporte</p>

                <Form.Item
                  name="tipoReporte"
                  noStyle
                  required={true}
                >
                  <Select
                    placeholder="Tipo de reporte"
                    className={'filtro-desviaciones'}
                  >
                    <Select.Option value={'descanso'}>Desviaciones Descansos</Select.Option>
                    <Select.Option value={'trabajo'}>Desviaciones Trabajo</Select.Option>
                  </Select>
                </Form.Item>

              </Col>

              {/* FILTRAR BUTTON */}
              <Col xs={24} sm={24} md={2} lg={2} xl={2}
                style={{ textAlign: 'right' }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  form="filtrar-form"
                  className={'filtrar-reporte-btn'}
                >
                  Filtrar
                </Button>
              </Col>

            </Row>

          </Form>


          <Card className={'semadoro-card'}>

            <h3 className={'maven-b titulo-semaforo'}>Desviaciones por cantidad de trabajadores</h3>



            <div className={'ver-detalle-div maven-r'} style={{ fontSize: '12px' }}>

              <div style={{ display: 'inline-block', backgroundColor: '#40a9ff', borderRadius: '5px' }}>
                <p
                  style={{ padding: '4px 10px 4px 10px', color: 'white', margin: '0px', fontWeight: '700', cursor: 'pointer' }}
                  onClick={() => { this.levantarModal('Sin Desviación') }}
                >
                  Ver detalle
                </p>
              </div>

              <div style={{ display: 'inline-block' }}>
                <p style={{ padding: '4px 8px 4px 8px', margin: '0px' }}>Total de trabajadores: {cantidadTrabajadores}</p>
              </div>

            </div>

            {/* GRÁFICO SEMÁFORO */}
            <div className={'semaforo-container'}>
              <SemaforoBarchartNew data={this.state.dataSemaforo} levantarModal={this.levantarModal} />
            </div>
          </Card>

        </Content>

        {/* MODALES */}
        <Modal
          visible={this.state.modalDetalle}
          onCancel={this.cerrarModal}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          style={{ top: 20 }}
          width={"90%"}
          bodyStyle={{ borderRadius: "16px" }}
        >
          <div>

            {/* HEADER */}
            <div className={'modal-header-wrapper'}>
              <PageHeader
                className="site-page-header"
                onBack={() => {

                  const { trabajadorDetalle } = this.state;

                  if (!trabajadorDetalle) {
                    this.cerrarModal();
                  } else {
                    this.setState({ trabajadorDetalle: null });
                  }
                }}
                title="Detalle de Desviaciones"
                subTitle={<span>Desviaciones por cada trabajador.</span>}
                backIcon={<LeftCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={'#0141d074'} />}
              />
            </div>

            {/* CONDICIONAL: MOSTRAR TABLAS O DETALLE DE TRABAJADOR */}
            {(() => {

              if (trabajadorDetalle) {

                const { Imagen, Rut, nombreCargo, nombreTrabajador, nombreRegimen, desviacionTrab,
                  cantidadPeriodos, iconCard, docsDescansos, periodosTrabajo, RegimenDeTrabajo } = trabajadorDetalle;

                return (
                  <Row
                    gutter={14}
                    style={{ marginLeft: '12px', marginRight: '12px' }}
                  >

                    {/* CARD TRABAJADOR */}
                    <Col
                      xs={24} sm={24} md={8} lg={8} xl={8}
                      className={'pl-0-mobile'}
                    >

                      <div className={'card-desv-trabajador'}>

                        <div style={{ width: '100%', position: 'relative' }}>

                          <div className={'img-desv-container'}>

                            <div className={'trab-img-desv-wrapp'}>
                              <img
                                src={Imagen}
                                alt={'imagen trabajador'}
                                className={'trab-img-desv'}
                              />

                              <Button
                                onClick={() => {
                                  this.redirectACalendario(trabajadorDetalle._id);
                                }}
                                icon={<EyeFilled style={{ fontSize: '20px' }} />}
                                className="detalle-trab-btn"
                                style={{
                                  position: 'absolute',
                                  left: '0px',
                                  bottom: '0px',
                                }}
                              />
                            </div>

                          </div>

                        </div>

                        <Row align={'middle'} className={'trab-desv-row'}>

                          <Col
                            xs={24} sm={12} md={12} lg={12} xl={12}
                            className={'desv-trab-info-col'}
                          >

                            <p className={'trab-info-title'}>Nombre</p>
                            <p className={'trab-info-parr'}>{nombreTrabajador}</p>

                            <p className={'trab-info-title'}>RUT</p>
                            <p className={'trab-info-parr'}>{Rut}</p>

                            <p className={'trab-info-title'}>Cargo</p>
                            <p className={'trab-info-parr'}>{nombreCargo}</p>

                            <p className={'trab-info-title'}>Régimen</p>
                            <p className={'trab-info-parr'}>{nombreRegimen}</p>
                          </Col>

                          <Col
                            xs={24} sm={12} md={12} lg={12} xl={12}
                            className={'status-desv-col'}
                          >
                            <img
                              src={iconCard}
                              alt={'icon-card'}
                              className={'icon-status-desv'}
                            />
                            <h5
                              className={'desviacion-perc-grande'}
                            >
                              {desviacionTrab}
                            </h5>
                            <h4 className={'desviacion-perc'}>Desviación Acumulada</h4>
                            <p>{cantidadPeriodos}</p>
                          </Col>

                        </Row>

                      </div>

                    </Col>

                    {/* PERIODOS */}
                    <Col
                      xs={24} sm={24} md={16} lg={16} xl={16}
                      className={'pr-0-mobile'}
                    >

                      <Card className={'card-desv-trabajador maven-r'}>

                        <Divider orientation={'middle'} style={{ fontWeight: '800' }}>
                          {tipoReporte === 'descanso' ? 'Reporte de Descansos' : 'Reporte de Trabajo'}
                        </Divider>

                        <p style={{ textAlign: 'justify' }}>
                          {
                            tipoReporte === 'descanso'
                              ? `En este reporte se muestran la cantidad de días de descanso generado por cada día
                              trabajado en cada periodo. El trabajador puede haber generado más o menos descanso del permitido
                              por el régimen.`
                              : `En este reporte se muestra si el trabajador respetó la cantidad de trabajo que debería laborar
                              según su régimen.`
                          }
                        </p>

                        {/* CONDICIONAL: MOSTRAR PERIODOS DE DESCANSOS O DE TRABAJO */}
                        {(() => {
                          if (tipoReporte === 'descanso') {

                            return docsDescansos.map((doc, index) => {

                              let { desviacionPeriodo, Inicio_Trabajo_Unix, Fin_Trabajo_Unix,
                                DiasEfectivos, DiasCompensados } = doc;

                              let fechaInicio = moment.unix(Inicio_Trabajo_Unix).format('DD-MM-YYYY');
                              let fechaFin = moment.unix(Fin_Trabajo_Unix).format('DD-MM-YYYY');
                              let periodoStatusIcon;

                              desviacionPeriodo = this.redondearDosDecimales(desviacionPeriodo);

                              let absDesviacionPeriodo = Math.abs(desviacionPeriodo);

                              if (absDesviacionPeriodo > variacion) periodoStatusIcon = red;
                              if (absDesviacionPeriodo <= variacion) periodoStatusIcon = yellow;
                              if (absDesviacionPeriodo === 0) periodoStatusIcon = green;

                              let diasTrabajo = doc.IdsBitacorasTrabajo.length;
                              let diasCompensados = DiasCompensados || 0;

                              let dataChartPeriodo = this.orderDataPeriodoDescanso(doc, RegimenDeTrabajo);

                              let trabajoRegimen = RegimenDeTrabajo.Cantidad_Dias_Trabajo;
                              let descansoRegimen = RegimenDeTrabajo.Cantidad_Dias_Descanso;

                              let descansoReal = DiasEfectivos + diasCompensados;

                              let relacionRegimen = this.redondearDosDecimales(descansoRegimen / trabajoRegimen);

                              let relacionReal = this.redondearDosDecimales(descansoReal / diasTrabajo);

                              let proporcion = this.redondearDosDecimales(relacionReal / relacionRegimen);
                              let proporcionConResta = this.redondearDosDecimales(relacionReal / relacionRegimen - 1);

                              return (
                                <div key={index} style={{ marginBottom: '5px' }}>

                                  <Divider orientation={'middle'} style={{ fontWeight: '800' }}>
                                    Trabajo desde {fechaInicio} <br className={'salto-linea-responsive'} />hasta {fechaFin}
                                  </Divider>

                                  <Row gutter={15}>

                                    {/* CARD INFO PERIODO */}
                                    <Col xs={24} sm={24} md={10} lg={10} xl={7}
                                      className={'status-desv-col'}
                                    >
                                      <div className={'card-desv-trabajador'}>
                                        <img
                                          src={periodoStatusIcon}
                                          alt={'icon-card'}
                                          className={'icon-status-desv'}
                                        />

                                        <h5
                                          className={'desviacion-perc-grande'}
                                        >
                                          {desviacionPeriodo}%
                                        </h5>

                                        <h4 className={'desviacion-perc-subtext'}>
                                          de Desviación
                                          <Popover
                                            title={"Cálculo de desviación:"}
                                            placement={"top"}
                                            content={
                                              <div className={'popover-table-div maven-r'}>
                                                <table style={{ width: '100%' }}>
                                                  <tbody>

                                                    <tr>
                                                      <td>Relación Régimen: {descansoRegimen} / {trabajoRegimen} = {relacionRegimen}</td>
                                                    </tr>

                                                    <tr>
                                                      <td>Relación Real: {descansoReal} / {diasTrabajo} = {relacionReal}</td>
                                                    </tr>

                                                    <tr>
                                                      <td>{relacionReal} / {relacionRegimen} = {proporcion}</td>
                                                    </tr>

                                                    <tr>
                                                      <td>{proporcion} - 1 = {proporcionConResta}</td>
                                                    </tr>

                                                    <tr>
                                                      <td>{proporcionConResta} * 100 = {desviacionPeriodo}%</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            }
                                            trigger={'click'}
                                          >
                                            <InfoCircleTwoTone
                                              twoToneColor={"#48b9fd"}
                                              className={'info-epps-icon'}
                                              style={{ marginLeft: '5px' }}
                                            />
                                          </Popover>
                                        </h4>

                                        <div className={'desv-info-card'}>

                                          <div style={{ textAlign: 'center', marginTop: '10px' }}>

                                            <p className={'trab-info-title'}>Trabajo</p>
                                            <p className={'trab-info-parr'}>{diasTrabajo} días</p>

                                            <p className={'trab-info-title'}>Descanso Efectivo</p>
                                            <p className={'trab-info-parr'}>{DiasEfectivos} días</p>

                                            <p className={'trab-info-title'}>Descanso Compenado</p>
                                            <p className={'trab-info-parr'}>{diasCompensados} días</p>
                                          </div>
                                        </div>

                                      </div>

                                    </Col>

                                    {/* ICONOS TRABAJO/DESCANSO */}
                                    {/* <Col
          xs={0} sm={4} md={2} lg={2} xl={2}
          style={{ height: '300px', paddingRight: '0px' }}
        >
          <div
            style={{
              height: '100%',
              paddingTop: '40%',
              paddingBottom: '40%',
            }}
          >

            <div
              style={{
                height: '50%',
                verticalAlign: 'middle',
              }}
            >
              <span className="helper"></span>
              <img
                src={embarcado}
                alt={'iconotrabajo'}
                style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  verticalAlign: 'middle',
                  width: '90%',
                }}
              />
            </div>

            <div
              style={{
                height: '50%',
                verticalAlign: 'middle',
              }}
            >
              <span className="helper"></span>
              <img
                src={descanso}
                alt={'iconodescanso'}
                style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  verticalAlign: 'middle',
                  width: '90%',
                }}
              />
            </div>
          </div>


        </Col> */}

                                    <Col xs={0} sm={0} md={0} lg={0} xl={1}>
                                    </Col>

                                    {/* CHART PERIODO */}
                                    <Col xs={24} sm={20} md={14} lg={14} xl={16}

                                      style={{ height: '300px', marginBottom: '25px' }}
                                    >
                                      <PeriodosBarchart data={dataChartPeriodo} />
                                    </Col>


                                  </Row>

                                </div>
                              )
                            })
                          } else if (tipoReporte === 'trabajo') {

                            return periodosTrabajo.map((periodo, index) => {

                              let { cantidadTrabajo, desviacionPeriodo, bitacorasDelPeriodo, bitacorasAlternas } = periodo;

                              let primeraBitacora = bitacorasDelPeriodo[0];
                              let ultimaBitacora = bitacorasDelPeriodo[bitacorasDelPeriodo.length - 1];

                              let fechaInicio = moment.utc(primeraBitacora.fecha).format('DD-MM-YYYY');
                              let fechaFin = moment.utc(ultimaBitacora.fecha).format('DD-MM-YYYY');

                              let periodoStatusIcon;
                              let absDesviacionPeriodo = Math.abs(desviacionPeriodo);

                              if (absDesviacionPeriodo > variacion) periodoStatusIcon = red;
                              if (absDesviacionPeriodo <= variacion) periodoStatusIcon = yellow;
                              if (absDesviacionPeriodo === 0) periodoStatusIcon = green;

                              let dataChartPeriodo = this.orderDataPeriodoTrabajo(cantidadTrabajo, RegimenDeTrabajo);

                              // let diasDesviacion = Math.abs(cantidadTrabajo - RegimenDeTrabajo.Cantidad_Dias_Trabajo);

                              let trabajoRegimen = RegimenDeTrabajo.Cantidad_Dias_Trabajo;

                              let relacion = this.redondearDosDecimales(cantidadTrabajo / trabajoRegimen);
                              let relacionConResta = this.redondearDosDecimales(cantidadTrabajo / trabajoRegimen - 1);

                              return (
                                <div key={index} style={{ marginBottom: '5px' }}>

                                  <Divider orientation={'middle'} style={{ fontWeight: '800' }}>
                                    Trabajo desde {fechaInicio} <br className={'salto-linea-responsive'} />hasta {fechaFin}
                                  </Divider>

                                  <Row gutter={15}>

                                    {/* CARD INFO PERIODO */}
                                    <Col xs={24} sm={24} md={10} lg={10} xl={7}
                                      className={'status-desv-col'}
                                    >
                                      <div className={'card-desv-trabajador'}>
                                        <img
                                          src={periodoStatusIcon}
                                          alt={'icon-card'}
                                          className={'icon-status-desv'}
                                        />

                                        <h5
                                          className={'desviacion-perc-grande'}
                                        >
                                          {desviacionPeriodo}%
                                        </h5>

                                        <h4 className={'desviacion-perc-subtext'}>

                                          de Desviación
                                          <Popover
                                            title={"Cálculo de desviación:"}
                                            placement={"top"}
                                            content={
                                              <div className={'popover-table-div'}>
                                                <table style={{ width: '100%' }}>
                                                  <tbody>
                                                    <tr>
                                                      <td>{cantidadTrabajo} / {trabajoRegimen} = {relacion}</td>
                                                    </tr>

                                                    <tr>
                                                      <td>{relacion} - 1 = {relacionConResta}</td>
                                                    </tr>

                                                    <tr>
                                                      <td>{relacionConResta} * 100 = {desviacionPeriodo}%</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            }
                                            trigger={'click'}
                                          >
                                            <InfoCircleTwoTone
                                              twoToneColor={"#48b9fd"}
                                              className={'info-epps-icon'}
                                              style={{ marginLeft: '5px' }}
                                            />
                                          </Popover>
                                        </h4>


                                        <div className={'desv-info-card'}>

                                          <div style={{ textAlign: 'center', marginTop: '10px' }}>

                                            <p className={'trab-info-title'}>Trabajo</p>
                                            <p className={'trab-info-parr'}>{cantidadTrabajo} días</p>

                                            {/* BITÁCORAS ALTERNAS */}
                                            {(() => {
                                              if (bitacorasAlternas.length > 0) {
                                                return (
                                                  <>
                                                    <p className={'trab-info-title'}>Bitácoras Alternas</p>

                                                    {bitacorasAlternas.map((bitacora, index) => {

                                                      let tipo = bitacora.tipoBitacoraString;
                                                      let inicio = moment.utc(bitacora.Fecha_Inicio).format('DD-MM-YYYY');
                                                      let fin = moment.utc(bitacora.Fecha_Fin).format('DD-MM-YYYY');

                                                      let fechas = inicio === fin ? inicio : <><br />{inicio} - {fin}</>;

                                                      return (
                                                        <p className={'trab-info-parr'} key={index}>
                                                          {tipo}: {fechas}
                                                        </p>
                                                      )
                                                    })}
                                                  </>
                                                )
                                              }

                                            })()}

                                          </div>
                                        </div>

                                      </div>

                                    </Col>

                                    {/* CHART PERIODO */}
                                    <Col xs={24} sm={20} md={14} lg={14} xl={16}
                                      style={{ height: '250px', marginBottom: '25px' }}
                                    >
                                      <PeriodosBarchart data={dataChartPeriodo} />
                                    </Col>


                                  </Row>

                                </div>
                              )
                            })

                          }
                        })()}



                      </Card>

                    </Col>

                  </Row>
                )

              } else {

                return (
                  <Tabs
                    activeKey={selectedTab}
                    onChange={(val) => this.setState({ selectedTab: val })}
                    type={'card'}
                    style={{ marginLeft: '12px', marginRight: '12px', fontFamily: 'MavenPro-Regular' }}
                  >

                    <TabPane
                      tab={<span><CheckCircleOutlined style={{ color: '#75d154' }} /> Sin Desviación</span>}
                      key="Sin Desviación"
                    >
                      {this.listarTrabajadores('Sin Desviación')}
                    </TabPane>

                    <TabPane
                      tab={<span><ExclamationCircleOutlined style={{ color: '#ebe15e' }} /> Desviación Tolerada</span>}
                      key="Desviación Tolerada"
                    >
                      {this.listarTrabajadores('Desviación Tolerada')}
                    </TabPane>

                    <TabPane
                      tab={<span><ExclamationCircleOutlined style={{ color: '#ff704d' }} /> Desviación No Tolerada</span>}
                      key="Desviación No Tolerada"
                    >
                      {this.listarTrabajadores('Desviación No Tolerada')}
                    </TabPane>

                    {/* TAB DE SIN DESCANSOS CONDICIONAL */}
                    {(() => {

                      if (tipoReporte === 'descanso') {
                        return (
                          <TabPane
                            tab={<span><QuestionCircleOutlined style={{ color: '#b5b5b5' }} /> Sin Descansos En Periodo</span>}
                            key="Sin Descansos En Periodo"
                          >
                            {this.listarTrabajadores('Sin Descansos En Periodo')}
                          </TabPane>
                        )
                      } else if (tipoReporte === 'trabajo') {
                        return (
                          <TabPane
                            tab={<span><QuestionCircleOutlined style={{ color: '#b5b5b5' }} /> Sin Trabajo En Periodo</span>}
                            key="Sin Trabajo En Periodo"
                          >
                            {this.listarTrabajadores('Sin Trabajo En Periodo')}
                          </TabPane>
                        )
                      }
                    })()}

                  </Tabs>
                )
              }

            })()}

          </div>
        </Modal>

        {/* SCOPED STYLES */}
        <span>
          <style jsx="true">
            {`
            .ant-card-body {
              padding: 20px !important;
            }

            .ant-table-placeholder {
              color: #444444 !important;
              font-weight: 900 !important;
            }

            .ant-page-header-back {
              margin-right: 8px !important;
            }

          `}
          </style>
        </span>

      </Layout >
    );
  }
}

export default Desviaciones;