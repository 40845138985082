import React, { Component } from "react";

import { connect } from "react-redux";
import * as MantenedorEPPActions from "../../Redux/actions/MantenedorEPPActions";

import activo from "../../img/icons/activo.svg";
import inactivo from "../../img/icons/inactivo.svg";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import FormularioEPPCrear from "../../components/formularioEPP/FormularioEPPCrear";
import FormularioEPPEditar from "../../components/formularioEPP/FormularioEPPEditar";
import { EditFilled, DeleteFilled } from "@ant-design/icons";

import {
  Layout,
  Table,
  Space,
  Button,
  Row,
  Col,
  Input,
  Modal,
  Tag,
} from "antd";

//import swal from "sweetalert";
import Swal from "sweetalert2";
import accesosPagina from '../../funcionesGenericas/accesosUsuario';

const { Header, Content } = Layout;
const { Search } = Input;
const { Column } = Table;

class MantenedorEPP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tablaCargando: false,
      form: {
        Nombre: "",
        Descripcion: "",
        Expira: true,
        Vigencia: true,
      },

      size: "large",
      titulosFiltros: null,

      mostrarModalImagen: false,
      imagenModal: "",
    };

    this.cambiarEstadoVigencia = this.cambiarEstadoVigencia.bind(this);
    this.crearEPP = this.crearEPP.bind(this);
    this.EditarEPP = this.EditarEPP.bind(this);
    this.TomarValoresEditar = this.TomarValoresEditar.bind(this);
  }

  search = (value) => {
    let busqueda = value.target.value;
    if (value !== "") {
      const TitulosRedux = this.props.titulos;

      const titulosFiltros = TitulosRedux.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(busqueda)
        )
      );

      this.setState({ titulosFiltros });
    } else {
      this.setState({ titulosFiltros: null });
    }
  };

  mostrarModalCrear = () => {
    this.props.MostrarModalTitulo();
  };

  async componentDidMount() {
    const accesos = await accesosPagina.obtenerAccesosPagina('MantenedorEpp');

    this.setState({
      Ver: accesos.Ver,
      Editar: accesos.Editar,
      Eliminar: accesos.Eliminar,
    });

    await this.props.ObtenerEpps();
  }

  modalCrearCancelar = () => {
    this.props.OcultarModalTitulo();
  };

  modalEditarCancelar = () => {
    this.props.OcultarModalTituloEditar();
  };

  cambiarEstadoVigencia = () => {
    this.setState({
      form: {
        ...this.state.form,
        Vigencia: this.state.form.Vigencia === true ? false : true,
      },
    });
  };

  crearEPP = (EPPObj) => {
    this.props.CrearEPP(EPPObj);
  };

  EliminarEPP = (idEPP) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminará el E.P.P. de la lista.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.EliminarEPP(idEPP);
      }
    });
  };

  ObtenerEPP = (idEPP) => {
    this.props.ObtenerEPP(idEPP);
  };

  EditarEPP = (objEPP) => {
    objEPP._id = this.props.titulo._id;

    this.props.EditarEPP(objEPP);
  };

  TomarValoresEditar = (nombreCampo, valor) => {
    this.props.TomarValoresEditar(nombreCampo, valor);
  };

  cambiarAbriendo = () => {
    this.setState({
      abriendo: false,
    });
  };

  imagenClick = (imagen) => {
    this.setState({
      imagenModal: imagen,
      mostrarModalImagen: true,
    });
  };

  cerrarImagen = () => {
    this.setState({
      mostrarModalImagen: false,
    });
  };

  render() {
    const { size, titulosFiltros } = this.state;

    return (
      <Layout style={{ height: "100%" }}>
        <Loading cargando={this.props.loading} />
        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Elementos de Protección Personal (E.P.P.)"
            subtitulo="Administrar E.P.P. para entregar a trabajadores."
          />
        </Header>
        <hr className="division" />
        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row style={{ padding: "6px 0px 22px" }} align="bottom">
            <Col className="div-input-search" span={6}>
              <Search
                placeholder="Buscar"
                //onSearch={(value) => this.search(value)}
                enterButton
                onChange={(value) => this.search(value)}
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={6}></Col>
            <Col style={{ textAlign: "right" }} span={12}>
              <Button
                className={!this.state.Editar ? "guardar-form-disabled" : "guardar-form"}
                disabled={!this.state.Editar}
                type="primary"
                onClick={() => {
                  this.mostrarModalCrear();
                }}
                size={size}
              >
                Crear E.P.P.
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="_id"
            dataSource={
              titulosFiltros === null ? this.props.titulos : titulosFiltros
            }
            // dataSource={this.props.titulos}
            loading={this.props.tablaCargando}
            bordered={true}
            showSorterTooltip={false}
          >
            <Column
              title="Imagen"
              key="Imagen"
              align="center"
              render={(valor) => {
                return (
                  <img
                    src={valor.Imagen}
                    alt="imagen de tabla"
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.imagenClick(valor.Imagen);
                    }}
                  />
                );
              }}
            />

            <Column
              sorter={(a, b) => {
                return a.Nombre.localeCompare(b.Nombre);
              }}
              align="left"
              title="Nombre"
              dataIndex="Nombre"
              key="Nombre"
            />

            <Column
              sorter={(a, b, c) => {
                return a.Tipo_Talla.localeCompare(b.Descripcion);
              }}
              align="left"
              title="Tipo de talla"
              dataIndex="Tipo_Talla"
              key="Tipo_Talla"
            />

            <Column
              align="center"
              title="Colores"
              dataIndex="Colores"
              key="Colores"
              render={(values) => {
                if (values !== undefined) {
                  if (values.length !== 0) {
                    return (
                      <div style={{ textAlign: "center" }}>
                        {values.map((valor, index) => (
                          <Tag
                            style={{ width: "100px", height: "25px" }}
                            key={index}
                            color="blue"
                          >
                            {valor}
                          </Tag>
                        ))}
                      </div>
                    );
                  } else {
                    return (
                      <div style={{ textAlign: "center" }}>
                        <Tag
                          style={{ width: "100px", height: "25px" }}
                          key={1}
                          color="#969696"
                        >
                          Sin Colores
                        </Tag>
                      </div>
                    );
                  }
                } else {
                  return (
                    <Tag
                      style={{ width: "100px", height: "25px" }}
                      key={1}
                      color="#969696"
                    >
                      Sin Colores
                    </Tag>
                  );
                }
              }}
            />

            <Column
              sorter={(a, b) => {
                return a.Vigencia - b.Vigencia;
              }}
              title="Vigencia"
              key="Vigencia"
              align="center"
              render={(valor) => {
                return (
                  <img
                    src={valor.Vigencia === true ? activo : inactivo}
                    alt="imagen perfil de tabla"
                  />
                );
              }}
            />

            <Column
              title="Acciones"
              key="Action"
              align="center"
              render={(value) => (
                <Space size="middle">
                  <Button
                    onClick={() => {
                      this.ObtenerEPP(value._id);
                    }}
                    className="editar-button"
                    icon={<EditFilled />}
                    disabled={!this.state.Editar}
                  />
                  <Button
                    className="eliminar-button"
                    onClick={() => {
                      this.EliminarEPP(value._id);
                    }}
                    icon={<DeleteFilled />}
                    disabled={!this.state.Eliminar}
                  />
                </Space>
              )}
            />
          </Table>
        </Content>

        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalTitulo}
          onCancel={this.modalCrearCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioEPPCrear
            //propiedades cambiables del formulario
            Formulario={this.state.form}
            vigencia={this.state.form.vigencia}
            cambiarEstadoVigencia={this.cambiarEstadoVigencia}
            crearEPP={this.crearEPP}
          />
        </Modal>

        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalEditar"
          visible={this.props.modalTituloEditar}
          onCancel={this.modalEditarCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioEPPEditar
            //propiedades cambiables del formulario
            Formulario={this.props.titulo}
            EditarEPP={this.EditarEPP}
            TomarValoresEditar={this.TomarValoresEditar}
          />
        </Modal>

        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalEditar"
          visible={this.state.mostrarModalImagen}
          onCancel={this.cerrarImagen}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="fit-content"
          footer={null}
          style={{ top: 20, textAlign: "center" }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <img
            src={this.state.imagenModal}
            alt="Imagen EPP"
            style={{ maxWidth: "1000px", height: "auto" }}
          />
        </Modal>
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  MantenedorTitulosReducer: {
    loading,
    error,
    success,
    modalTitulo,
    modalTituloEditar,
    titulos,
    titulo,
    tablaCargando,
  },
}) => {
  return {
    loading,
    error,
    success,
    modalTitulo,
    modalTituloEditar,
    titulos,
    titulo,
    tablaCargando,
  };
};

export default connect(
  EstadosAPropiedades,
  MantenedorEPPActions
)(MantenedorEPP);
