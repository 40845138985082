// Tipos de consultas
import {
  ERROR,
  INGRESAR_HOLDING,
  LOADING,
  OBTENER_EMPRESAS,
  LIMPIAR_ERROR_SUCCESS,
  MOSTRAR_MODAL_HOLDING,
  OCULTAR_MODAL_HOLDING,
  OBTENER_HOLDINGS_MANTENEDOR,
  ELIMINAR_HOLDING_MANTENEDOR,
  OBTENER_HOLDING_MANTENEDOR,
  OCULTAR_MODAL_HOLDING_EDITAR,
  EDITAR_HOLDING_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  //   PARAR_LOADING,
} from "../types/MantenedorHoldingsTypes";

const INITIAL_STATE = {
  loading: false,
  empresas: [],
  error: null,
  success: null,
  modalHolding: false,
  modalHoldingEditar: false,
  holdings: [],
  holding: null,
  tablaCargando: true,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case INGRESAR_HOLDING:
      return {
        ...state,
        loading: false,
        success: payload,
        modalHolding: false,
        tablaCargando: false,
      };
    case OBTENER_EMPRESAS:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        empresas: payload,
      };

    case EDITAR_HOLDING_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        modalHoldingEditar: false,
      };

    case TOMAR_VALORES_EDITAR:
      const { nombreCampo, valor } = payload;
      return {
        ...state,

        holding: {
          ...state.holding,
          [nombreCampo]: valor,
        },
      };
    case LOADING:
      return {
        ...state,
        loading: true,
        tablaCargando: true,
      };
    // case PARAR_LOADING:
    //   return {
    //     ...state,
    //     loading: false,
    //     tablaCargando: false,
    //   };
    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        tablaCargando: false,
      };
    case LIMPIAR_ERROR_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };
    case MOSTRAR_MODAL_HOLDING:
      return {
        ...state,
        modalHolding: true,
      };

    case OBTENER_HOLDINGS_MANTENEDOR:
      return {
        ...state,
        holdings: payload,
        loading: false,
        tablaCargando: false,
      };
    case OBTENER_HOLDING_MANTENEDOR:
      return {
        ...state,
        holding: payload,
        loading: false,
        tablaCargando: false,
        modalHoldingEditar: true,
      };
    case ELIMINAR_HOLDING_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      };
    case OCULTAR_MODAL_HOLDING:
      return {
        ...state,
        modalHolding: false,
      };
    case OCULTAR_MODAL_HOLDING_EDITAR:
      return {
        ...state,
        modalHoldingEditar: false,
      };
    default:
      return state;
  }
};
