import React, { Component } from "react";

class Documentacion extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  async componentDidMount() {

  }


  render() {

    return (
      <div style={{ width: '100%', paddingTop: '40px',height:"90vh" }}>
        <iframe src="https://studio.apicur.io/preview?aid=72617"/>
      </div >
    );
  }
}

export default Documentacion;