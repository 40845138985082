import {
  LOADING,
  ERROR,
  LIMPIAR_ERROR_SUCCESS,
  OBTENER_CENTROCOSTOS,
  OBTENER_FAENAS,
  MOSTRAR_MODAL_DESEMBARCO,
  OCULTAR_MODAL_DESEMBARCO,
  OBTENER_FAENA_ID,
  OBTENER_TRABAJADOR_ID_CENTROCOSTO,
  OBTENER_TRABAJADOR_ID,
  PARAR_LOADING,
  OBTENER_BITACORA_TRABAJADOR_CHECK_OUT,
} from "../types/CheckOutTypes";

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: null,
  tablaCargando: true,
  modalEmbarco: false,
  modalDesembarco: false,
  faenaId: null,
  fechaInicio: null,
  fechaFin: null,
  centroDeCosto: null,
  rangoFechaPlaza: [],
  bitacoraTrabajador: [],
  trabajadores: [],
  checkIn: [],
  checkOut: [],
  trabajadorModal: null,
  faena: null,
  nombreFaena: null,
  idTrabajador: null,
  centroCostos: [],
  faenas: [],
  bitacora: null,
  bitacoraTrabajadorCheckOut: [],
  bitacoraCompletaTrabajadorCheckOut: [],
  proximoDiaPlanificado: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {

    case OBTENER_BITACORA_TRABAJADOR_CHECK_OUT:
      return {
        ...state,
        bitacoraTrabajadorCheckOut: payload.detalleBitacoraTrabajador,
        proximoDiaPlanificado: payload.proximoDiaPlanificado,
        bitacoraCompletaTrabajadorCheckOut: payload.bitacoraCompletaTrabajador,
        tablaCargando: false,
        loading: false,
      }

    case OBTENER_TRABAJADOR_ID_CENTROCOSTO:
      return {
        ...state,
        //checkIn: payload.arrayCheckIn,
        checkOut: payload,
        loading: false,
        tablaCargando: false,
        modalEmbarco: false,
        modalDesembarco: false,
      };
    case OBTENER_CENTROCOSTOS:
      return {
        ...state,
        centroCostos: payload,
        loading: false,
        tablaCargando: false,
      };

    case OBTENER_FAENAS:
      return {
        ...state,
        faenas: payload,
        loading: false,
        tablaCargando: false,
      };

    case PARAR_LOADING:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      };

    case LOADING:
      return {
        ...state,
        loading: true,
        tablaCargando: true,
      };

    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        tablaCargando: false,
      };
    case LIMPIAR_ERROR_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };
    case MOSTRAR_MODAL_DESEMBARCO:
      return {
        ...state,
        modalDesembarco: true,
      };
    case OCULTAR_MODAL_DESEMBARCO:
      return {
        ...state,
        modalDesembarco: false,
      };

    case OBTENER_FAENA_ID:
      return {
        ...state,
        loading: false,
        rangoFechaPlaza: payload,
        tablaCargando: false,
      };

    case OBTENER_TRABAJADOR_ID:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        trabajadorModal: payload.trabajador,

        fechaInicio: payload.fechaInicioCheckIn,
        fechaFin: payload.fechaFinCheckOut,
        faena: payload.Faena,
        centroDeCosto: payload.CentroDeCostoModal,
        bitacora: payload.bitacora,

      };

    default:
      return state;
  }
};
