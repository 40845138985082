import React from "react";
import "../Empresion.css";
import "./VacacionesImpresion.css";
import byLogo from "../../img/logos/logo-by-people-managment.svg";
import { Row, Col, Layout, Card } from "antd";
import Loading from "../../components/loading/Loading";
import API from "../../Redux/Api/api";
import moment from "moment"
import { convertirFechaString } from "../../funcionesGenericas/fechas";

const queryString = require('query-string');

class VacacionesImpresion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderDocumento: false,
            loading: true,
            informacionVacaciones: null,
        };
    }

    componentDidMount = async () => {
        const parsed = queryString.parse(this.props.location.search);
        let idVacaciones = parsed.Vacaciones;

        let respuesta = await this.buscarVacaciones(idVacaciones);

        if (respuesta.status === 200) {

            let objVacaciones = respuesta.json.data;

            this.setState({
                informacionVacaciones: objVacaciones,
                renderDocumento: true,
                loading: false,
            });


            setTimeout(function () {
                window.print();
            }, 1000);

        }
    }

    buscarVacaciones = async (idVacaciones) => {
        let respuesta = {
            status: 0,
            json: null,
        }
        try {

            const url = `${API}/Vacaciones/${idVacaciones}`;

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });

            const status = response.status;
            const data = await response.json();

            respuesta.status = status;
            respuesta.json = data;

            return respuesta;

        } catch (error) {
            respuesta.status = -1;
            respuesta.json = error;
            return respuesta;
        }
    }

    obtenerNombreDia = (fechaString) => {

        let dia = moment(fechaString).isoWeekday();
        let mes = moment(fechaString).format('M');

        let diaName;
        let mesName;

        switch (dia) {
            case 1: diaName = 'Lunes'; break;
            case 2: diaName = 'Martes'; break;
            case 3: diaName = 'Miércoles'; break;
            case 4: diaName = 'Jueves'; break;
            case 5: diaName = 'Viernes'; break;
            case 6: diaName = 'Sábado'; break;
            case 7: diaName = 'Domingo'; break;
        }

        switch (mes) {
            case '1': mesName = 'Enero'; break;
            case '2': mesName = 'Febrero'; break;
            case '3': mesName = 'Marzo'; break;
            case '4': mesName = 'Abril'; break;
            case '5': mesName = 'Mayo'; break;
            case '6': mesName = 'Junio'; break;
            case '7': mesName = 'Juliu'; break;
            case '8': mesName = 'Agosto'; break;
            case '9': mesName = 'Septiembre'; break;
            case '10': mesName = 'Octubre'; break;
            case '11': mesName = 'Noviembre'; break;
            case '12': mesName = 'Diciembre'; break;
        }

        let diaNumber = moment(fechaString).format('D');
        let anioNumber = moment(fechaString).format('YYYY');

        let fechaCompleta = `${diaName}, ${diaNumber} de ${mesName} de ${anioNumber}`

        return fechaCompleta;
    }

    Pintar() {

        if (this.state.renderDocumento === true) {

            let { informacionVacaciones } = this.state;
            console.log('INFORMACION VACACIONES:', informacionVacaciones);

            let {

                FechasTrabajadas,
                IdTrabajador: trabajador,
                Fecha_Inicio,
                Fecha_Fin,
                Es_Compensacion,
                TipoDias,

                DisponiblesVacaciones,
                DisponiblesProgresivos,
                FeriadoLegalTotal,
                SaldoTotal,

            } = informacionVacaciones;

            let nombreCompleto = `${trabajador.Apellido_Paterno} ${trabajador.Apellido_Materno} ${trabajador.Nombre}`
            nombreCompleto = nombreCompleto.toUpperCase();

            let cantidadTotalDias = moment(Fecha_Fin).diff(Fecha_Inicio, 'days') + 1;
            let cantidadInhabiles;

            if (Es_Compensacion) {
                cantidadInhabiles = 0;
            } else {
                cantidadInhabiles = cantidadTotalDias - FeriadoLegalTotal;
            }

            let fechaInicioString = this.obtenerNombreDia(Fecha_Inicio);
            let fechaFinString = this.obtenerNombreDia(Fecha_Fin);

            return (
                <Layout id="divEmpresion" className="layoutImpresion">
                    <Row
                        style={{
                            paddingTop: "30px",
                        }}
                    >
                        <Col span={18}>
                            <Card className="CardMaestra">

                                <Card className="CardHeader">
                                    <h3 className="ImpresionTitulo">
                                        <img
                                            src={informacionVacaciones.IdTrabajador.Empresa.Imagen}
                                            className="ImpresionLogoEmpresa"
                                            alt="Logo empresa"
                                            style={{ marginRight: '18px' }}
                                        />
                                           COMPROBANTE DE FERIADO (VACACIONES)
                                    </h3>
                                </Card>

                                <div className={'comprobante-content-wrapper'}>

                                    <Row>
                                        <Col span={16} className={'bordered-no-bottom'}>
                                            CORRESPONDIENTE AL PERIODO CONTRACTUAL
                                        </Col>
                                    </Row>

                                    {FechasTrabajadas.map((periodo, index) => {

                                        let clName = 'bordered';
                                        if (index !== 0) clName = 'bordered-no-top';

                                        return (
                                            <Row className={clName} key={index}>
                                                <Col span={4}>
                                                    DEL
                                                </Col>
                                                <Col span={8} className={'bold-text'}>
                                                    {/* {moment.unix(periodo.inicioUnix).format('DD-MM-YYYY')} */}
                                                    {convertirFechaString(moment.utc(periodo.inicio), 1).fechaDDMMYYYY}
                                                </Col>
                                                <Col span={4}>
                                                    HASTA
                                                </Col>
                                                <Col span={8} className={'bold-text'}>
                                                    {/* {moment.unix(periodo.finUnix).format('DD-MM-YYYY')} */}
                                                    {convertirFechaString(moment.utc(periodo.fin), 2).fechaDDMMYYYY}
                                                </Col>
                                            </Row>
                                        )
                                    })}

                                    <Row style={{ marginTop: '15px' }}>
                                        En cumplimiento a las disposiciones legales vigentes, se deja constancia que a contar de las fechas que se indican el trabajador(a):
                                    </Row>

                                    <Row style={{ marginTop: '15px' }}>

                                        <Col span={3}>
                                            DON (ÑA)
                                        </Col>
                                        <Col span={11} className={'bold-text'}>
                                            {nombreCompleto}
                                        </Col>
                                        <Col span={3}>
                                            RUT
                                        </Col>
                                        <Col span={4} className={'bold-text'}>
                                            {trabajador.Rut}
                                        </Col>

                                    </Row>

                                    <Row style={{ marginTop: '15px' }}>
                                        <span>Hará uso de</span>
                                        <span className={'bold-text'} style={{ marginLeft: '5px', marginRight: '5px' }}>{FeriadoLegalTotal}</span>
                                        <span>días de su feriado legal </span>
                                        <span className={'bold-text'} style={{ marginLeft: '5px' }}> {TipoDias == 'Normales' ? `(días hábiles)` : `(vacaciones progresivas)`}</span>.
                                    </Row>

                                    {(() => {

                                        if (Es_Compensacion) {
                                            return (
                                                <Row>
                                                    <span style={{ marginRight: '5px' }}>FERIADO COMPENSADO:</span>
                                                    <span className={'bold-text'}>{informacionVacaciones.FeriadoLegalTotal}</span>
                                                </Row>
                                            )
                                        } else {
                                            return (

                                                <>
                                                    <Row style={{ marginTop: '15px' }}>

                                                        Con remuneración íntegra de acuerdo al siguiente detalle:
                                                        <br />
                                                        DESCANSO EFECTIVO ENTRE LAS FECHAS QUE SE INDICAN:

                                                    </Row>
                                                    <Row style={{ marginTop: '15px' }}>

                                                        <Col span={3}>
                                                            DESDE EL:
                                                        </Col>
                                                        <Col span={9} className={'bold-text'}>
                                                            {fechaInicioString}
                                                        </Col>
                                                        <Col span={1}>
                                                            AL:
                                                        </Col>
                                                        <Col span={9} className={'bold-text'}>
                                                            {fechaFinString}
                                                        </Col>
                                                        <Col span={2}>
                                                            <table className={'t-border'} style={{ width: '100%' }}>
                                                                <tbody>

                                                                    <tr className={'t-border'}>
                                                                        <td className={'t-border'}>
                                                                            DÍAS
                                                                        </td>
                                                                    </tr>
                                                                    <tr className={'t-border'}>
                                                                        <td className={'t-border bold-text'}>
                                                                            {cantidadTotalDias}
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </Col>

                                                    </Row>

                                                </>
                                            )
                                        }
                                    })()}

                                    <Row style={{ marginTop: '15px', marginBottom: '15px' }}>

                                        <Col span={18}>

                                            <table className={'vacas-table'} style={{ width: '100%' }}>
                                                <tbody>


                                                    <tr className={'vacas-table'}>
                                                        <td className={'vacas-table'} style={{ paddingLeft: '15px' }}>
                                                            DETALLE DEL FERIADO
                                                        </td>
                                                        <td className={'vacas-table-dias'}>
                                                            DÍAS
                                                        </td>
                                                    </tr>

                                                    <tr className={'vacas-table'}>
                                                        <td className={'vacas-table'} style={{ paddingLeft: '15px' }}>
                                                            DÍAS HÁBILES DISPONIBLES
                                                            <span className={'signos-table'}>(=)</span>
                                                        </td>
                                                        <td className={'vacas-table-dias'}>
                                                            {DisponiblesVacaciones}
                                                        </td>
                                                    </tr>

                                                    <tr className={'vacas-table'}>
                                                        <td className={'vacas-table'} style={{ paddingLeft: '15px' }}>
                                                            VACACIONES PROGRESIVAS
                                                            <span className={'signos-table'}>(+)</span>
                                                        </td>
                                                        <td className={'vacas-table-dias'}>
                                                            {DisponiblesProgresivos}
                                                        </td>
                                                    </tr>

                                                    <tr className={'vacas-table'}>
                                                        <td className={'vacas-table'} style={{ paddingLeft: '15px' }}>
                                                            DÍAS HÁBILES SOLICITADOS
                                                            <span className={'signos-table'}>(-)</span>
                                                        </td>
                                                        <td className={'vacas-table-dias'}>
                                                            {FeriadoLegalTotal}
                                                        </td>
                                                    </tr>

                                                    <tr className={'vacas-table'}>
                                                        <td className={'vacas-table'} style={{ paddingLeft: '15px' }}>
                                                            DOMINGOS Y FESTIVOS E INHÁBILES
                                                        </td>
                                                        <td className={'vacas-table-dias'}>
                                                            {cantidadInhabiles}
                                                        </td>
                                                    </tr>

                                                    <tr className={'vacas-table'}>
                                                        <td className={'vacas-table'} style={{ paddingLeft: '15px' }}>
                                                            SALDO PENDIENTE
                                                            <span className={'signos-table'}>(=)</span>
                                                        </td>
                                                        <td className={'vacas-table-dias'}>
                                                            {SaldoTotal}
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>

                                        </Col>

                                    </Row>

                                </div>

                                <Card className="CardFirma">
                                    <Row>
                                        <Col className="contFirmas" span={12}>
                                            <hr />
                                            <h3 className="NombresFirma">FIRMA DEL EMPLEADOR</h3>
                                        </Col>
                                        <Col className="contFirmas" span={12}>
                                            <hr />

                                            <h3 className="NombresFirma">FIRMA DEL TRABAJADOR</h3>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col flex={'auto'}>
                                        </Col>

                                        <Col span={6}
                                            style={{ fontFamily: 'MavenPro-SemiBold', textAlign: 'right', paddingRight: '20px', marginTop: '10px', fontSize: '12px' }}
                                        >
                                            {/* FECHA: <span>{moment().format('DD-MM-YYYY')}</span> */}
                                            FECHA: <span>{convertirFechaString(moment.utc(moment().format('yyyy/MM/DD')), 1).fechaDDMMYYYY}</span>
                                        </Col>
                                    </Row>

                                </Card>

                            </Card>
                            <Row
                                style={{
                                    paddingTop: "20px",
                                    width: "700px",
                                }}
                            >
                                <Col
                                    span={24}
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    <img src={byLogo} alt="by people managment" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Layout>
            );
        } else {
            return false;
        }
    }

    render() {
        return (<>
            <Loading cargando={this.state.loading} />
            {this.Pintar()}
        </>);
    }
}

export default VacacionesImpresion;