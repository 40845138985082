import React from "react";
import "../Empresion.css";
import byLogo from "../../img/logos/logo-by-people-managment.svg";
import { Row, Col, Layout, Card } from "antd";
import Loading from "../../components/loading/Loading";
import API from "../../Redux/Api/api";
import moment from "moment"
import { convertirFechaString } from "../../funcionesGenericas/fechas";
const queryString = require('query-string');

class LicenciaImpresion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderDocumento: false,
      loading: true,

      infoImprimir: null,
      informacionAusente: null,
      status: null,
    };
  }

  componentDidMount = async () => {
    const parsed = queryString.parse(this.props.location.search);
    let idBitacora = parsed.idBitacora;

    await this.buscarBitacora(idBitacora);

    if (this.state.status === 200) {

      const infoImprimir = this.state.infoImprimir;

      let informacionAusente = {
        Nombre_trabajador: infoImprimir.nombreTrabajador,
        Nombre_Cargo: infoImprimir.cargo,
        //Termino_Operacion: `${moment(objLicencia.Termino).format("DD-MM-YYYY")}`,
        Fecha_Inicio_Hasta: infoImprimir.fechaInicioHasta,
        //Tipo_Licencia: objLicencia.Tipo,
        Imagen_Empresa: infoImprimir.imagen,
        FechaFiniquto: infoImprimir.FechaFiniquto === undefined || infoImprimir.FechaFiniquto === null ? null : moment.utc(infoImprimir.FechaFiniquto)
      }

      this.setState({
        informacionAusente: informacionAusente,
        renderDocumento: true,
        loading: false,
      });

      setTimeout(function () {
        window.print();
      }, 1000);

    }
  }

  buscarBitacora = async (idBitacora) => {

    let respuesta = {
      status: 0,
      json: null,
    }

    try {

      const url = `${API}/BitacoraTrabajadores/Bitacora/${idBitacora}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      const data = await response.json();

      respuesta.status = status;
      respuesta.json = data;

      const resTrabajador = await this.buscarTrabajador(respuesta.json.data.IdTrabajador);

      if (resTrabajador.status === 200) {

        const diasAusentesData = respuesta.json.data;
        const trabajadorData = resTrabajador.json.trabajador;

        const infoImprimir = {
          nombreTrabajador: `${trabajadorData.Nombre} ${trabajadorData.Apellido_Paterno} ${trabajadorData.Apellido_Materno}`,
          cargo: trabajadorData.Cargo.Nombre,
          fechaInicioHasta: `Desde: ${moment(diasAusentesData.Fecha_Inicio).format("DD-MM-YYYY")} Hasta: ${moment(diasAusentesData.Fecha_Fin).format("DD-MM-YYYY")}`,
          imagen: trabajadorData.Empresa.Imagen,
        }
        
        await this.setState({
          infoImprimir: infoImprimir,
          status: 200,
        })


        return respuesta;
      }


    } catch (error) {
      console.error(error);

      respuesta.status = -1;
      respuesta.json = error;
      
      return respuesta;
    }
  }

  buscarTrabajador = async (idTrabajador) => {

    let respuesta = {
      status: 0,
      json: null,
    }

    try {

      const url = `${API}/Trabajadores/${idTrabajador}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      const data = await response.json();

      respuesta.status = status;
      respuesta.json = data;

      return respuesta;

    } catch (error) {
      respuesta.status = -1;
      respuesta.json = error;

      return respuesta;
    }
  }

  Pintar() {
    if (this.state.renderDocumento === true) {
      return (
        <Layout id="divEmpresion" className="layoutImpresion">
          <Row
            style={{
              paddingTop: "30px",
            }}
          >
            <Col span={18}>
              <Card className="CardMaestra">
                <Card className="CardHeader">
                  <h3 className="ImpresionTitulo">
                    <img
                      src={this.state.informacionAusente.Imagen_Empresa}
                      className="ImpresionLogoEmpresa"
                      alt="Logo empresa"
                    />{" "}
                    Comprobante días de ausencia
                  </h3>
                </Card>
                <Row
                  style={{
                    marginTop: "30px",
                    padding: "30px",
                  }}
                >
                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={6}
                  >
                    <h5 className="ImprimirNombreCampo">Nombre trabajador: </h5>
                  </Col>
                  <Col span={18}>
                    <h5 className="ImprimirValorCampo">{this.state.informacionAusente.Nombre_trabajador}</h5>
                  </Col>

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={6}
                  >
                    <h5 className="ImprimirNombreCampo">Cargo: </h5>
                  </Col>

                  <Col span={18}>
                    <h5 className="ImprimirValorCampo">{this.state.informacionAusente.Nombre_Cargo}</h5>
                  </Col>

                  {/* <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={6}
                  >
                    <h5 className="ImprimirNombreCampo">Termino Operación: </h5>
                  </Col>
                  <Col span={18}>
                    <h5 className="ImprimirValorCampo">{this.state.informacionLicencia.Termino_Operacion}</h5>
                  </Col> */}

                  {/* <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={6}
                  >
                    <h5 className="ImprimirNombreCampo">¿Que licencia es?: </h5>
                  </Col>
                  <Col span={18}>
                    <h5 className="ImprimirValorCampo">{this.state.informacionLicencia.Tipo_Licencia}</h5>
                  </Col> */}
                  {this.state.informacionAusente.FechaFiniquto !== null ?
                  <>
                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo" style={{color:"red"}}>
                      <b>
                        Fecha Finiquito: {" "}
                      </b>
                    </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo" style={{color:"red"}}>
                      <b>
                        {convertirFechaString(moment.utc(this.state.infoDescanso.FechaFiniquto), 1).fechaDDMMYYYY}
                      </b>
                    </h5>
                  </Col>
                  </>
                  :
                  <></>
                  }

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={6}
                  >
                    <h5 className="ImprimirNombreCampo">
                      Inicio y Termino de ausencia:{" "}
                    </h5>
                  </Col>
                  <Col span={18}>
                    <h5 className="ImprimirValorCampo">
                      {this.state.informacionAusente.Fecha_Inicio_Hasta}
                    </h5>
                  </Col>
                </Row>
                <Card className="CardFirma">
                  <Row>
                    <Col className="contFirmas" span={12}>
                      <hr />
                      <h3 className="NombresFirma">FIRMA DEL EMPLEADOR</h3>
                    </Col>
                    <Col className="contFirmas" span={12}>
                      <hr />

                      <h3 className="NombresFirma">FIRMA DEL TRABAJADOR</h3>
                    </Col>
                  </Row>
                </Card>
              </Card>
              <Row
                style={{
                  paddingTop: "20px",
                  width: "700px",
                }}
              >
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <img src={byLogo} alt="by people managment" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return false;
    }
  }
  render() {
    return (<>
      <Loading cargando={this.state.loading} />
      {this.Pintar()}
    </>);
  }
}

export default LicenciaImpresion;
