export const LOADING = "MANTENEDOR_DOCUMENTO_LOADING";
export const PARAR_LOADING = "MANTENEDOR_DOCUMENTO_LOADING_PARAR";
export const ERROR = "MANTENEDOR_DOCUMENTO_ERROR";
export const INGRESAR_DOCUMENTO = "MANTENEDOR_DOCUMENTO_INGRESAR";
export const LIMPIAR_ERROR_SUCCESS =
  "MANTENEDOR_DOCUMENTO_LIMPIAR_ERROR_SUCCESS";
export const MOSTRAR_MODAL_DOCUMENTO = "MANTENEDOR_DOCUMENTO_MOSTRAR_MODAL";
export const OCULTAR_MODAL_DOCUMENTO = "MANTENEDOR_DOCUMENTO_OCULTAR_MODAL";
export const OCULTAR_MODAL_DOCUMENTO_EDITAR =
  "MANTENEDOR_DOCUMENTO_OCULTAR_MODAL_EDITAR";
export const OBTENER_DOCUMENTOS_MANTENEDOR =
  "MANTENEDOR_DOCUMENTO_OBTENER_DOCUMENTOS";
export const OBTENER_DOCUMENTO_MANTENEDOR =
  "MANTENEDOR_DOCUMENTO_OBTENER_DOCUMENTO";
export const ELIMINAR_DOCUMENTO_MANTENEDOR =
  "MANTENEDOR_DOCUMENTO_ELIMINAR_DOCUMENTO";
export const EDITAR_DOCUMENTO_MANTENEDOR =
  "MANTENEDOR_DOCUMENTO_EDITAR_DOCUMENTO";
export const TOMAR_VALORES_EDITAR = "MANTENEDOR_DOCUMENTO_TOMAR_VALORES_EDITAR";

export const CAMBIAR_VALOR_DESHABILITAR = "MANTENEDOR_DOCUMENTO_CAMBIAR_VALOR_DESHABILITAR";

export const CAMBIAR_VALOR_ARREGLO_ARCHIVOS = "MANTENEDOR_DOCUMENTO_CAMBIAR_VALOR_ARREGLO_ARCHIVOS";
// export const DESCARGAR_EXCEL = "MANTENEDOR_DOCUMENTO_DESCARGAR_EXCEL";
