import React from "react";
import faena from "../../img/icons/faena.svg";
// import iconBorrar from "../../img/icons/borrar.svg";
// import { ArrowLeftOutlined } from "@ant-design/icons";
import iconBorrar from "../../img/icons/borrar.svg";
import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Select,
  InputNumber,
  //Radio,
} from "antd";

import Swal from "sweetalert2";

const FormularioPlantillaDeFaena = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      let objAux = {
        ...values,
        Empresa: usuario.EmpresaSeleccionada.Empresa._id,
        Vigencia: props.vigencia,
      };

      delete objAux.Cantidad;
      delete objAux.Cargo;
      delete objAux.Especialidades_Obligatorios;
      delete objAux.Especialidades_Opcionales;
      delete objAux.NombrePlaza;
      delete objAux.Titulos_Obligatorios;
      delete objAux.Titulos_Opcionales;

      objAux.Plazas = props.Plazas;

      if (objAux.Plazas.length === 0) {

        Swal.fire({
          title: "Uuups...",
          text: "Debes ingresar al menos una plaza",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
        return false;


      } else {

        props.crearFaena(objAux);

      }
    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={faena} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Crear plantilla de equipo"
          />
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Form.Item
              name={"Nombre"}
              label="Nombre plantilla:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir un nombre",
                  min: 2,
                  // max: 30,
                },
              ]}
            >
              <Input name="Nombre" className="campos" />
            </Form.Item>

            <Form.Item label="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                defaultChecked
                onChange={props.cambiarEstadoVigencia}
                className="switch"
              />
            </Form.Item>

            <hr className="division" />
            <br />

            <Form.Item>
              <h3 style={{ fontFamily: "MavenPro-Bold", textAlign: "left", marginLeft: "50%" }}>
                Nueva Plaza
              </h3>
              <h4 style={{ textAlign: "left", marginLeft: "50%", width: "100%", fontWeight: "50%" }}>
                Una plaza corresponde a Puesto de trabajo o cargo o función que se desempeña en una empresa. Por ejemplo: <b>Nombre Plaza: Mecánico</b>
              </h4>
            </Form.Item>

            <Row style={{ width: '105%' }}>



              <Col span={3}></Col>

              <Col span={11}>

                <Form.Item name={"NombrePlaza"} label="Nombre plaza:">
                  <Input name="NombrePlaza" className="" />
                </Form.Item>


              </Col>

              <Col span={6}>

                <Form.Item
                  style={{
                    textAlign: "left",
                    marginLeft: "-20%"
                  }}
                  name={"Cantidad"}
                  label="N° personas:"
                >
                  <InputNumber
                    style={{
                      width: "100%"
                    }}
                    name="Cantidad" type="number" min={0} className="" />
                </Form.Item>

              </Col>

            </Row>

            <Form.Item
              label="Seleccione Cargo Trabajador"
              name={"Cargo"}
              style={{
                marginTop: "20px",
              }}
            >
              <Select
                className="campos"
                name="Cargo"
                placeholder="Selecciona cargo"
              >
                {props.cargos.map((value, index) => (
                  <Select.Option key={index} name="Cargo" value={value._id}>
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Titulos Obligatorios"
              name={"Titulos_Obligatorios"}
              style={{
                marginTop: "20px",
              }}
            >
              <Select
                className="campos"
                name="Titulos_Obligatorios"
                placeholder="Selecciona Titulos"
                mode="multiple"
              >
                {props.titulos.map((value, index) => (
                  <Select.Option key={index} name="Cargo" value={value._id}>
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Titulos opcionales"
              name={"Titulos_Opcionales"}
              style={{
                marginTop: "20px",
              }}
            >
              <Select
                className="campos"
                name="Titulos_Opcionales"
                placeholder="Selecciona Titulos"
                mode="multiple"
              >
                {props.titulos.map((value, index) => (
                  <Select.Option key={index} name="Cargo" value={value._id}>
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Especialidades obligatorias"
              name={"Especialidades_Obligatorios"}
              style={{
                marginTop: "20px",
              }}
            >
              <Select
                className="campos"
                name="Especialidades_Obligatorios"
                placeholder="Selecciona Especialidades"
                mode="multiple"
              >
                {props.especialidades.map((value, index) => (
                  <Select.Option key={index} name="Cargo" value={value._id}>
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Especialidades opcionales"
              name={"Especialidades_Opcionales"}
              style={{
                marginTop: "20px",
              }}
            >
              <Select
                className="campos"
                name="Especialidades_Opcionales"
                placeholder="Selecciona Especialidades"
                mode="multiple"
              >
                {props.especialidades.map((value, index) => (
                  <Select.Option key={index} name="Cargo" value={value._id}>
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div style={{ textAlign: "right", marginRight: "20%" }}>
              <Button
                className="guardar-form"




                onClick={() => {
                  let valores = form.getFieldsValue();

                  if (valores.NombrePlaza === undefined || valores.NombrePlaza === '') {
                    Swal.fire({
                      title: "Uuups...",
                      text: "Debes ingresar nombre de la plaza",
                      icon: "error",
                      confirmButtonColor: "#E60AA3",
                      confirmButtonText: "Continuar",
                    });
                    return false;
                  }

                  if (valores.Cantidad === undefined
                    || valores.Cantidad === 0
                    || valores.Cantidad === null
                    || valores.Cantidad === '') {
                    Swal.fire({
                      title: "Uuups...",
                      text: "Debes ingresar cantidad de personas para la plaza",
                      icon: "error",
                      confirmButtonColor: "#E60AA3",
                      confirmButtonText: "Continuar",
                    });
                    return false;
                  }

                  if (valores.Cargo === undefined
                    || valores.Cargo === 0
                    || valores.Cargo === null
                    || valores.Cargo === '') {
                    Swal.fire({
                      title: "Uuups...",
                      text: "Debes ingresar un cargo",
                      icon: "error",
                      confirmButtonColor: "#E60AA3",
                      confirmButtonText: "Continuar",
                    });
                    return false;
                  }

                  let plaza = {
                    NombrePlaza: valores.NombrePlaza,
                    Titulos_Obligatorios:
                      valores.Titulos_Obligatorios === undefined ||
                        valores.Titulos_Obligatorios === ""
                        ? []
                        : valores.Titulos_Obligatorios,
                    Especialidades_Obligatorias:
                      valores.Especialidades_Obligatorios === undefined ||
                        valores.Especialidades_Obligatorios === ""
                        ? []
                        : valores.Especialidades_Obligatorios,
                    Titulos_Opcionales:
                      valores.Titulos_Opcionales === undefined ||
                        valores.Titulos_Opcionales === ""
                        ? []
                        : valores.Titulos_Opcionales,
                    Especialidades_Opcionales:
                      valores.Especialidades_Opcionales === undefined ||
                        valores.Especialidades_Opcionales === ""
                        ? []
                        : valores.Especialidades_Opcionales,
                    Cantidad: valores.Cantidad,
                    Cargo: valores.Cargo,
                  };

                  let arregloDePlazas = props.Plazas;

                  arregloDePlazas.push(plaza);

                  //////////////// REPARAR ////////////////////////////

                  if (arregloDePlazas === []) {



                  } else {


                    props.cambiarValores("Plazas", arregloDePlazas);

                    valores.Especialidades_Opcionales = [];

                    form.setFieldsValue({
                      Cantidad: undefined,
                      Cargo: undefined,
                      Especialidades_Obligatorios: undefined,
                      Especialidades_Opcionales: [],
                      NombrePlaza: undefined,
                      Titulos_Obligatorios: undefined,
                      Titulos_Opcionales: undefined,
                    });

                  }

                  ////////////////////////////////////////////w

                }}
              >
                Agregar plaza
              </Button>
            </div>
            <br />
            <br />
            <hr className="division" />
            <br />
            <br />
            <Row>
              <Col span={6}></Col>
              <Col span={12}>
                {/* <h4 className="titulo-tabla">Plazas</h4> */}
                <table className="tablaCargos">
                  <thead>
                    <tr>
                      <th>Plazas ingresadas</th>
                      <th>Cantidad</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.Plazas.map((value, index) => (

                      < tr key={index} >
                        {/* <td>{index + 1}</td> */}
                        <td>{value.NombrePlaza}</td>
                        <td>{value.Cantidad}</td>
                        <td>
                          <img
                            src={iconBorrar}
                            onClick={() => {
                              let Plazas = props.Plazas;

                              Plazas.splice(index, 1);

                              props.cambiarValores("Plazas", Plazas);
                            }}
                            className="borrar-icon-table"
                            alt="borrar-icon"
                          />
                        </td>
                      </tr>

                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>
      </Row>
    </Form >
  );
};

export default FormularioPlantillaDeFaena;
