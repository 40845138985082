import React from "react";
import SubirFoto from "../../components/subirFotoSucursales/SubirFoto";
import logoGenerico2 from "../../img/logos/logoGenerico2.svg";
import sucursales from "../../img/icons/sucursales.svg";
// import Chile from "../../img/banderas/chile.svg";

import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  PageHeader,
  Select,
  Radio,
} from "antd";

import Swal from "sweetalert2";
// const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 9,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

const FormularioSucursales = (props) => {
  const [form] = Form.useForm();

  const ObtenerImagen = (imagenUrl) => {
    props.obtenerImagen(imagenUrl);
  };

  const onFinish = (values) => {

    //values.Telefono = `${values.prefix}${values.Telefono}`

    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      const objAux = {
        ...values,
        Empresa: usuario.EmpresaSeleccionada.Empresa._id,
      };

      props.crearSucursal(objAux);
      //form.resetFields();
    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={sucursales} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title={`Sucursales de: ${props.nombreEmpresa}`}
          />
        </Col>
        <Col span={8}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Row>
              <Col lg={10} xl={24}>
                <SubirFoto avatar={props.Foto === undefined ? logoGenerico2 : props.Foto} ObtenerImagen={ObtenerImagen} />
              </Col>
              <Col lg={22} xl={24}>
                <p>
                  Te recomendamos que la fotografía no exceda los 5 MB de peso
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col offset={1} span={15}>
          <Card className="card-perfil" style={{ width: "100%" }}>



            <Form.Item
              name={"Nombre"}
              label="Nombre de sucursal"
              rules={[
                {
                  required: true,
                  message: "El nombre de la sucursal es obligatorio",
                },
              ]}
            >
              <Input name="Nombre" className="campos" />
            </Form.Item>

            <Form.Item
              name={"Calle"}
              label="Calle"
              rules={[
                {
                  required: true,
                  message: "La dirección es obligatoria",
                  min: 2,
                  max: 50,
                },
              ]}
            >
              <Input name="Calle" className="campos" />
            </Form.Item>
            <Form.Item
              name={"Numero_Calle"}
              label="Número"
              rules={[
                {
                  required: true,
                  message: "Debe ingresar número de dirección",
                },
              ]}
            >
              <Input name="Numero_Calle" className="campos" />
            </Form.Item>
            <Form.Item
              label="Tipo de establecimiento"
              name={"Tipo_De_Vivienda"}
              rules={[
                {
                  required: true,
                  message: "¿Qué tipo de lugar es?",
                },
              ]}
            >
              <Radio.Group
                style={{ display: "flex" }}
                key={"Tipo_De_Vivienda"}
                // onChange={this.onChange}
                value={props.Formulario.Tipo_De_Vivienda}
              >
                <Radio value={"Departamento"}>Departamento</Radio>
                <Radio value={"Oficina"}>Oficina</Radio>
                <Radio value={"Local"}>Local</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name={"Codigo_Postal"}
              label="Código Postal"
              rules={[
                {
                  required: false,
                  message: "Codigo postal es obligatorio",
                },
              ]}
            >
              <Input name="Codigo_Postal" className="campos" />
            </Form.Item>

            <Form.Item
              label="Región"
              name={"Region"}
              rules={[
                {
                  required: true,
                  message: "¿En qué región está?",
                },
              ]}
            >
              <Select
                className="campos"
                name="Region"
                placeholder="Selecciona región"
                onSelect={(value) => {
                  form.resetFields(['Ciudad', 'Comuna']);
                  props.buscarProvincias(value);
                }}
              >

                {props.regiones.map((value, index) => (
                  <Select.Option
                    key={index}
                    name="Region"
                    value={value._id}
                  >
                    {value.Nombre}
                  </Select.Option>
                ))}

              </Select>
            </Form.Item>

            <Form.Item
              label="Provincia"
              name={"Ciudad"}
              rules={[
                {
                  required: true,
                  message: "¿En qué ciudad o provincia está?",
                },
              ]}

            >
              <Select
                className="campos"
                name="Ciudad"
                placeholder="Selecciona provincia"
                onSelect={(value) => {
                  form.resetFields(['Comuna']);
                  props.buscarComuna(value);
                }}
              >
                {props.ciudadesSeleccionadas.map((value, index) => (
                  <Select.Option
                    name="Ciudad"
                    key={index}
                    value={value._id}
                  >
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Comuna"
              name={"Comuna"}
              rules={[
                {
                  required: true,
                  message: "¿En qué comuna está?",
                },
              ]}
            >
              <Select
                className="campos"
                name="Comuna"
                placeholder="Selecciona comuna"
              >
                {props.comunasSeleccionadas.map((value, index) => (
                  <Select.Option
                    name="Comuna"
                    key={index}
                    value={value._id}
                  >
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Teléfono contacto comercial"
              name="Telefono"
              rules={[
                {
                  required: true,
                  message: "Debe introducir teléfono de la sucursal",
                },
              ]}
            >
              <Input
                name="Telefono"
                className="campos"
                placeholder="+569XXXXXXXX"
                maxLength={12}
              />
            </Form.Item>
            <Form.Item
              name={"Email"}
              label="Email Contacto Sucursal"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Se debe ingresar un correo",
                },
              ]}
            >
              <Input name="Email" className="campos" />
            </Form.Item>


            <Col span={19} style={{ textAlign: "right", marginTop: 20 }}>
              <Button className="guardar-form" type="primary" htmlType="submit">
                Guardar
              </Button>
            </Col>

          </Card>
        </Col>

      </Row>
    </Form>
  );

};

export default FormularioSucursales;