import React, { Component } from "react";
import "./MantenedorFeriados.css";

import { connect } from "react-redux";
import * as mantenedorFeriadosActions from "../../Redux/actions/MantenedorFeriadosActions";

import activo from "../../img/icons/activo.svg";
import inactivo from "../../img/icons/inactivo.svg";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import Moment from "moment";

import FormularioFeriado from "../../components/formularioFeriados/FormularioFeriados";
import FormularioFeriadoEditar from "../../components/formularioFeriados/FormularioFeriadosEditar";

import { Layout, Table, Space, Button, Row, Col, Input, Modal } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";

//import swal from "sweetalert";
import Swal from "sweetalert2";
import accesosPagina from '../../funcionesGenericas/accesosUsuario';

const { Header, Content } = Layout;
const { Search } = Input;
const { Column } = Table;

class MantenedorFeriados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        Vigencia: true,
      },
      size: "large",
      Filtros: null,
    };

    this.cambiarEstadoVigencia = this.cambiarEstadoVigencia.bind(this);
    this.crearFeriado = this.crearFeriado.bind(this);
    this.editarFeriado = this.editarFeriado.bind(this);
    this.TomarValoresEditar = this.TomarValoresEditar.bind(this);
  }

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      const accesos = await accesosPagina.obtenerAccesosPagina('MantenedorFeriados');

      this.setState({
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
      });

      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      //console.log(idEmpresa);
      this.props.ObtenerFeriados(idEmpresa);
    }
  }

  descargarExcel = () => {
    this.props.DescargarExcel();
  };

  search = (value) => {
    let busqueda = value.target.value;
    if (value !== "") {
      const RolesRedux = this.props.feriados;

      const Filtros = RolesRedux.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(busqueda)
        )
      );

      this.setState({ Filtros });
    } else {
      this.setState({ Filtros: null });
    }
  };

  mostrarModalCrear = () => {
    this.props.MostrarModalFeriados();
  };

  modalCrearCancelar = () => {
    this.props.OcultarModalFeriados();
  };

  modalEditarCancelar = () => {
    this.props.OcultarModalFeriadoEditar();
  };

  cambiarEstadoVigencia = () => {
    this.setState({
      form: {
        ...this.state.form,
        Vigencia: this.state.form.Vigencia === true ? false : true,
      },
    });
  };

  crearFeriado = (feriado) => {

    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      feriado.Vigencia = this.state.form.Vigencia;

      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      feriado.Empresa = idEmpresa;
      this.props.CrearFeriado(feriado);
    }
  };

  EliminarFeriado = (idRol) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminará el feriado",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.EliminarFeriado(idRol);
      }
    });
  };

  ObtenerFeriado = (idFeriado) => {
    this.props.ObtenerFeriado(idFeriado);
  };

  editarFeriado = (Feriado) => {
    Feriado._id = this.props.feriado._id;
    Feriado.Vigencia = this.props.feriado.Vigencia;
    console.log(Feriado);
    this.props.EditarFeriado(Feriado);
  };

  TomarValoresEditar = (nombreCampo, valor) => {
    this.props.TomarValoresEditar(nombreCampo, valor);
  };

  render() {
    const { size, Filtros } = this.state;

    return (
      <Layout style={{ height: "100%" }}>
        <Loading cargando={this.props.loading} />
        <Header style={{ background: "none" }}>
          <HeaderFicha titulo="Calendario de Feriados" subtitulo="Feriados del sistema" />
        </Header>
        <hr className="division" />
        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row style={{ padding: "6px 0px 22px" }} align="bottom">
            <Col className="div-input-search" span={6} style={{ height: '40px' }}>
              <Search
                placeholder="Buscar"
                //onSearch={(value) => this.search(value)}
                enterButton
                onChange={(value) => this.search(value)}
              />
            </Col>

            <Col style={{ textAlign: "right" }} span={18}>
              <Button
                className={!this.state.Editar ? "guardar-form-disabled" : "subir_excel_feriado"}
                disabled={!this.state.Editar}
                type="primary"
                onClick={() => {
                  this.props.descargarFormatoExcel();
                }}
                size={size}
                style={{ marginRight: "20px" }}
              >
                Descargar Formato
              </Button>

              <Button
                // className="subir_excel_feriado"
                className={!this.state.Editar ? "guardar-form-disabled" : "subir_excel_feriado"}
                disabled={!this.state.Editar}
                onClick={() => {
                  // this.subirExcel();
                  let input = document.createElement('input');
                  input.type = "file";
                  input.accept = "application/vnd.ms-excel, application/vnd.ms-excel.addin.macroEnabled.12, application/vnd.ms-excel.sheet.macroEnabled.12, application/vnd.ms-excel.template.macroEnabled.12, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  input.click();
                  input.onchange = async (e) => {
                    const archivo = input.files[0];

                    let usuario = localStorage.getItem("usuario-people-manage");
                    if (
                      usuario !== null &&
                      usuario !== "" &&
                      usuario !== undefined &&
                      usuario !== "undefined"
                    ) {
                      usuario = JSON.parse(usuario);
                      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

                      await this.props.subirExcelFeriados(archivo, idEmpresa);
                      await this.props.ObtenerFeriados(idEmpresa);
                    }
                  }

                }}
                size={size}
                style={{ marginRight: "20px" }}
              >
                Subir Excel
              </Button>
              <Button
                className={!this.state.Editar ? "guardar-form-disabled" : "guardar-form"}
                disabled={!this.state.Editar}
                type="primary"
                onClick={() => {
                  this.mostrarModalCrear();
                }}
                size={size}
              >
                Crear feriado
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="_id"
            dataSource={Filtros === null ? this.props.feriados : Filtros}
            loading={this.props.tablaCargando}
            bordered={true}
            showSorterTooltip={false}
          >
            <Column
              sorter={(a, b) => {
                return a.Fecha.localeCompare(b.Fecha);
              }}
              align="left"
              title="Fecha"
              dataIndex="Fecha"
              key="Fecha"
              render={(valor) => {
                return Moment(valor).format("DD-MM-YYYY");
              }}
            />
            <Column
              sorter={(a, b) => {
                return a.Descripcion.localeCompare(b.Descripcion);
              }}
              align="left"
              title="Descripción"
              dataIndex="Descripcion"
              key="Descripcion"
            />
            <Column
              sorter={(a, b) => {
                return a.Vigencia - b.Vigencia;
              }}
              title="Vigencia"
              key="Vigencia"
              align="center"
              render={(valor) => {
                return (
                  <img
                    src={valor.Vigencia === true ? activo : inactivo}
                    alt="imagen perfil de tabla"
                  />
                );
              }}
            />

            <Column
              title="Acciones"
              key="Action"
              align="center"
              render={(value) => (
                <Space size="middle">
                  <Button
                    onClick={() => {
                      this.ObtenerFeriado(value._id);
                    }}
                    className="editar-button"
                    icon={<EditFilled />}
                    disabled={!this.state.Editar}
                  />

                  <Button
                    className="eliminar-button"
                    disabled={!this.state.Eliminar}
                    onClick={async () => {
                      await this.EliminarFeriado(value._id);

                      if (!this.props.error) {
                        let usuario = localStorage.getItem("usuario-people-manage");

                        if (
                          usuario !== null &&
                          usuario !== "" &&
                          usuario !== undefined &&
                          usuario !== "undefined"
                        ) {
                          usuario = JSON.parse(usuario);
                          const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
                          await this.props.ObtenerFeriados(idEmpresa);
                        }
                      }
                    }}
                    icon={<DeleteFilled />}
                  />
                </Space>
              )}
            />
          </Table>
        </Content>
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalFeriado}
          onCancel={this.modalCrearCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioFeriado
            //propiedades cambiables del formulario
            Formulario={this.state.form}
            vigencia={this.state.form.vigencia}
            cambiarEstadoVigencia={this.cambiarEstadoVigencia}
            crearFeriado={this.crearFeriado}
          />
        </Modal>
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalEditar"
          visible={this.props.modalFeriadoEditar}
          onCancel={this.modalEditarCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioFeriadoEditar
            //propiedades cambiables del formulario
            Formulario={this.props.feriado}
            cambiarEstadoVigencia={this.cambiarEstadoVigencia}
            editarFeriado={this.editarFeriado}
            TomarValoresEditar={this.TomarValoresEditar}
          />
        </Modal>
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  MantenedorFeriadosReducer: {
    loading,
    error,
    success,
    tablaCargando,
    modalFeriado,
    modalFeriadoEditar,
    feriados,
    feriado,
  },
}) => {
  return {
    loading,
    error,
    success,
    tablaCargando,
    modalFeriado,
    modalFeriadoEditar,
    feriados,
    feriado,
  };
};

export default connect(
  EstadosAPropiedades,
  mantenedorFeriadosActions
)(MantenedorFeriados);
