import React from "react";
import CentroCosto from "../../img/icons/CentroCosto.svg";
import { Form, Input, Button, Card, Switch, Row, Col, PageHeader } from "antd";

import Swal from "sweetalert2";

const FormularioCentroCosto = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {
    props.crearCentroCosto(values);
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 9,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={CentroCosto} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Nuevo centro de costo "
          />
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Form.Item
              name="Nombre"
              label="Nombre centro de costo"
              rules={[
                {
                  required: true,
                  message: "Debe introducir el nombre del centro de costo",
                  min: 2,
                  max: 20,
                },
              ]}
            >
              <Input name="Nombre"  maxLength={20} className="campos" />
            </Form.Item>
            <Form.Item
              label="Código base sistema a conectar"
              name={"Codigo_Base_Sistema"}
              rules={[
                {
                  required: true,
                  message: "Debe introducir un código base",
                },
              ]}
              style={{}}
            >
              <Input placeholder="Código base" />
            </Form.Item>

            <Form.Item label="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                defaultChecked
                onChange={props.cambiarEstadoVigencia}
                className="switch"
              />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormularioCentroCosto;
