import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import "./DashboardDinamico.css";
import icon_error_equis from "../../img/icons/error-equis.svg";
import icon_arrow_green from "../../img/icons/arrow.svg";
import icon_reloj_amarillo from "../../img/icons/Reloj.svg";
import icon_ticket_celeste from "../../img/icons/ticket.png";

class DashboardDinamico extends Component {


  obtenerImagen = (tipo) => {
    switch (tipo) {
      // Sin Cubrir
      case "5f3ef5ce1ecf531c1c8ab4a7":
        return icon_error_equis;
      // Reservadas
      case "5f3ef6591ecf531c1c8ab4a8":
        return icon_reloj_amarillo;
      // En Curso
      case "5f3ef6861ecf531c1c8ab4a9":
        return icon_ticket_celeste;
      // Ya Cubiertas
      case "5f3ef6981ecf531c1c8ab4aa":
        return icon_arrow_green;

      default:
        return icon_error_equis
    }
  }

  render() {
    return (
      <>
        <Row gutter={16}>

          {this.props.reporteDashboard.map((valor, index) => (
            <Col key={index} span={6}>
              <Card className="dashboard">
                <Row>
                  <Col span={6}>
                    <img
                      style={{ width: 55 }}
                      src={this.obtenerImagen(valor.tipo)}
                      alt="icono dash1"
                    />
                  </Col>
                  <Col span={18} style={{ textAlign: "center" }}>
                    <h2 className="titulo-dashboard-dinamico">{valor.titulo}</h2>
                    <h4 className="sub-titulo-dashboard-dinamico">{valor.subTitulo}</h4>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}

        </Row>
      </>
    );
  }
}

export default DashboardDinamico;
