import React, { Component } from "react";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import FormularioAsignacionFaenas from "../../components/formularioAsignacionFaenas/FormularioAsignacionFaenas";
import FormularioAsignacionFaenasEditar from "../../components/formularioAsignacionFaenas/FormularioAsignacionFaenasEditar";
import { connect } from "react-redux";

import vista from "../../img/icons/vista.svg";
import reloj from "../../img/icons/Historico.svg";
import ticket from "../../img/icons/ticket.png";

import * as AsignacionFaenasActions from "../../Redux/actions/AsignacionFaenasActions";
import "./asignacionFaenas.css";
import Timeline, { DateHeader, TimelineHeaders } from "react-calendar-timeline";
import "../../../node_modules/react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import "moment/locale/es";
import "../../css/calendarioAsignacion.css";
import Loading from "../../components/loading/Loading";

import {

  convertirFechaString,


} from "../../funcionesGenericas/fechas";

import {
  Layout,
  Modal,
  Button,
  Col,
  Row,
  Badge,
  DatePicker,
  Popover,
  Space,
  Tooltip,
  Empty,
  notification,
  Select,
} from "antd";

import { EditFilled, DeleteFilled } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/es_ES";
import Swal from "sweetalert2";

//import genericaFechas from "../../funcionesGenericas/fechas";
import accesosPagina from "../../funcionesGenericas/accesosUsuario";

const { Header, Content } = Layout;
const { RangePicker } = DatePicker;

class asignacionFaenas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: "Juan",
      tablaCargando: false,
      form: {
        Nombre: "",
        Centro_De_Costo: "",
        Fecha_Inicio: "",
        Fecha_Fin: "",
        PlantillaBase: "",
        Vigencia: true,
        Expira: true,
      },
      Redireccionar: false,
      idFaena: "",

      dates: null,
      ccSeleccionados: [],
      valMonthPicker: moment(),

      popoverAbierto: null,
      isVisible: false,

      reporteDashboard: [],

      rangeDisabled: [false, true],

      todosCentrosCosto: [],

      RangeDatesValues: [
        moment().clone(),
        moment().clone(),
      ],
    };
    this.cambiarValores = this.cambiarValores.bind(this);
    this.CrearFaena = this.CrearFaena.bind(this);
    this.ObtenerAsignacionesIdCentroCosto = this.ObtenerAsignacionesIdCentroCosto.bind(
      this
    );

  }

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      const accesos = await accesosPagina.obtenerAccesosPagina(
        "PlanificacionActividades",
        "AsignacionesCentroDeCosto"
      );
      const accesosDetalle = await accesosPagina.obtenerAccesosPagina(
        "PlanificacionDetalleActividad",
        "AsignacionesDetalle"
      );

      this.setState({
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,

        VerDetalle: accesosDetalle.Ver,
      });

      const todosCentrosCosto = await this.props.ObtenerCentrosCosto(idEmpresa);
      await this.props.ObtenerPlantillas(idEmpresa);

      let primerCC = [];

      // seteando primer CC como predeterminado
      if (todosCentrosCosto.length > 0) primerCC.push(todosCentrosCosto[0]._id);


      //para buscar por el mes actual al abrir ésta página
      // const firstDay = genericaFechas.obtenerFechaMomentChile(
      //   moment().add(-1, "month").startOf("month").format("YYYY-MM-DD")
      // );
      // const lastDay = genericaFechas.obtenerFechaMomentChile(
      //   moment().add(1, "month").endOf("month").format("YYYY-MM-DD")
      // );

      // let fechaInicio = this.state.RangeDatesValues[0].clone().set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
      // let fechaFin = this.state.RangeDatesValues[1].clone().set({ hour: 12, minute: 0, second: 0, millisecond: 0 });

     
      let fechaInicio = convertirFechaString(moment.utc(this.state.RangeDatesValues[0].clone()).startOf("month"), 1).fechaString;
      let fechaFin = convertirFechaString(moment.utc(this.state.RangeDatesValues[1].clone()).endOf("month"), 2).fechaString;

      await this.props.ObtenerAsignacionesFiltrado(
        fechaInicio,
        fechaFin,
        this.props.centroscosto,
        primerCC
      );
  
      await this.props.MostrarTabla();

      this.setState({

        ccSeleccionados: primerCC,
        todosCentrosCosto,
        reporteDashboard: [
          {
            titulo: `${this.props.contadores.cntVerde} Días`,
            subTitulo: "Ya cubiertos",
            cantidad: this.props.contadores.cntVerde,
            tipo: "5f3ef6861ecf531c1c8ab4a9",
          },
          {
            titulo: `${this.props.contadores.cntAzul} Días`,
            subTitulo: "En curso",
            cantidad: this.props.contadores.cntAzul,
            tipo: "5f3ef6981ecf531c1c8ab4aa",
          },
          {
            titulo: `${this.props.contadores.cntAmarillo} Días`,
            subTitulo: "Reservados",
            cantidad: this.props.contadores.cntAmarillo,
            tipo: "5f3ef6591ecf531c1c8ab4a8",
          },
          {
            titulo: `${this.props.contadores.cntRojo} Días`,
            subTitulo: "Sin cubrir",
            cantidad: this.props.contadores.cntRojo,
            tipo: "5f3ef5ce1ecf531c1c8ab4a7",
          },
        ],
      });
    }
  }

  componentWillUnmount = async () => {
    await this.props.ClearData();
  };

  cambiarValores = (nombre, valor) => {
    this.setState({
      form: {
        ...this.state.form,
        [nombre]: valor,
      },
    });
  };

  mostrarModalCrear = () => {
    this.props.MostrarModalAsignacion();
  };

  modalCrearCancelar = () => {
    this.props.OcultarModalAsignacion();
  };

  redireccionarADetalle = (idFaena) => {
    this.setState({
      idFaena,
      Redireccionar: true,
    });
  };

  setPopoverAbierto = (idPopover) => {
    this.setState({
      popoverAbierto: idPopover,
    });
  };

  handleVisibleChange = (value) => {
    this.setState({
      isVisible: value,
    });
  };

  mostrarCalendario = () => {
    if (
      this.props.centroscosto.length !== 0 &&
      this.props.mostrarCalendario === true
    ) {
      let todasLasFaenas = this.props.faenas;

      todasLasFaenas.forEach((element) => {
        element.itemProps = {
          className: "en-curso",
        };
      });

      return (
        <>
          <Timeline
            groups={this.props.centroscosto}
            items={this.props.faenas}
            sidebarWidth={250}
            defaultTimeStart={moment()}
            defaultTimeEnd={moment().add(35, "day")}
            // visibleTimeStart={moment().startOf('month')}
            // visibleTimeEnd={moment().endOf('month')}

            groupRenderer={({ group }) => {
              return (
                <Row
                  className="custom-group"
                  align={"center"}
                  style={{ textAlign: "center" }}
                >
                  <Col span={6}>
                    <Badge status="processing" color={group.color} />
                  </Col>
                  <Col span={18}>
                    <h3 className="header-plaza-lighter">{group.Nombre}</h3>
                  </Col>
                </Row>
              );
            }}
            itemRenderer={({
              item,
              itemContext,
              getItemProps,
              getResizeProps,
            }) => {
              const {
                left: leftResizeProps,
                right: rightResizeProps,
              } = getResizeProps();

              return (
                <Popover
                  content={
                    <Space size="middle">
                      <Tooltip
                        title={
                          "La vigencia debe estar activada para entrar al detalle"
                        }
                        visible={!item.Vigencia}
                        placement={"left"}
                      >
                        <div>
                          <Button
                            onClick={() => {
                              this.redireccionarADetalle(item.idFaena);
                            }}
                            className="ver-button"
                            icon={
                              <img
                                src={vista}
                                style={{ width: 30 }}
                                alt="icono editar"
                              />
                            }
                            disabled={
                              this.state.VerDetalle !== undefined ||
                                this.state.VerDetalle !== null
                                ? !this.state.VerDetalle
                                : !item.Vigencia
                            }
                          />
                        </div>
                      </Tooltip>

                      <Button
                        className="editar-button"
                        onClick={() => {
                          this.ObtenerFaena(item.idFaena);
                        }}
                        icon={<EditFilled />}
                        disabled={!this.state.Editar}
                      />

                      <Button
                        className="eliminar-button"
                        onClick={() => {
                          this.eliminarFaena(item.idFaena);
                        }}
                        icon={<DeleteFilled />}
                        disabled={!this.state.Eliminar}
                      />
                    </Space>
                  }
                  title={`${item.nombreFaena}: Acciones`}
                >
                  <div
                    {...getItemProps(item.itemProps)}
                    className={`barra-color ${item.colorBarra} ${item.bordeIndicador}`}
                  >
                    {itemContext.useResizeHandle ? (
                      <div {...leftResizeProps} />
                    ) : (
                      ""
                    )}

                    <div
                      className="rct-item-content"
                      style={{ maxHeight: `${itemContext.dimensions.height}` }}
                    >
                      {/* <h4>{itemContext.title}</h4> */}
                    </div>

                    {itemContext.useResizeHandle ? (
                      <div {...rightResizeProps} />
                    ) : (
                      ""
                    )}
                  </div>
                </Popover>
              );
            }}
          >
            <TimelineHeaders
              style={{
                backgroundColor: "#f4f4f4",
              }}
              calendarHeaderClassName="calendarioHeader"
            >
              <DateHeader unit="primaryHeader" className="mesHeader" />
              <DateHeader
                style={{
                  fontFamily: "MavenPro-Regular",
                  color: "black",
                  height: 40,
                }}
              />
            </TimelineHeaders>
          </Timeline>
        </>
      );
    } else {
      return (
        <Empty
          imageStyle={{ height: 200, marginTop: "20px" }}
          description={
            <h2 className={"seleccion-trabajador-disponible"}>
              <b>No se han encontrado centros de costos para asignar faenas.</b>
              <br />
              <b>Verifica si existen centros de costos vigentes.</b>
            </h2>
          }
        ></Empty>
      );
    }
  };

  ObtenerFaena = (idFaena) => {
    this.props.ObtenerFaena(idFaena);
  };

  modalEditarCancelar = () => {
    this.props.CerrarEditar();
  };

  alargarFaena = (faenaObj) => {
    this.props.AlargarFaena(faenaObj);
  };

  acortarFaena = (faenaObj) => {
    this.props.AcortarFaena(faenaObj);
  };

  eliminarFaena = (idFaena) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text:
        "Se eliminará la actividad junto con las planificaciones de los trabajadores de la misma.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.EliminarFaena(idFaena);
      }
    });
  };

  ObtenerAsignacionesIdCentroCosto = (idCentroDeCosto) => {
    this.props.ObtenerAsignacionesIdCentroCosto(idCentroDeCosto);
  };

  CrearFaena = (faena) => {
    this.props.CrearFaena(faena);
    this.openNotificationActividades("bottomRight", faena);
  };

  RedireccionarDetalle = () => {
    if (this.state.Redireccionar) {
      let ruta = `/DetalleFaena?idFaena=${this.state.idFaena}`;

      //return <Redirect to={ruta} />;
      var win = window.open(ruta, "_blank");
      win.focus();

      this.setState({
        Redireccionar: false,
      });
    }
  };

  // Función para limitar RangePicker a sólo 2 meses
  disabledDate = (current) => {
    if (!this.state.dates || this.state.dates.length === 0) {
      return false;
    }

    const tooLate =
      this.state.dates[0] && current.diff(this.state.dates[0], "months") > 2;
    const tooEarly =
      this.state.dates[1] && this.state.dates[1].diff(current, "months") > 2;
    return tooEarly || tooLate;
  };

  filtrarCalendario = () => {

    const fechas = this.state.RangeDatesValues;

    if (fechas) {

      // let fechaInicio = fechas[0].clone().startOf("month").set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
      // let fechaFin = fechas[1].clone().endOf("month").set({ hour: 12, minute: 0, second: 0, millisecond: 0 });

      let fechaInicio = convertirFechaString(moment.utc(this.state.RangeDatesValues[0].clone()).startOf("month"), 1).fechaString;
      let fechaFin = convertirFechaString(moment.utc(this.state.RangeDatesValues[1].clone()).endOf("month"), 2).fechaString;

      this.props.ObtenerAsignacionesFiltrado(
        fechaInicio,
        fechaFin,
        this.props.centroscosto,
        this.state.ccSeleccionados,
      );
      // console.log("this.props.centroscosto")
      // console.log(this.props.centroscosto)
    }
  };

  openNotificationActividades = (placement, idFaena) => {
    notification.open({
      message: "¡Bien!",
      description: "Haz click para ver sugerencias!",

      duration: null,
      placement,
      icon: (
        <img style={{ width: 20, marginTop: "-3" }} src={ticket} alt="ticket" />
      ),

      onClose: notification.destroy(),

      onClick: () => {
        notification.destroy();

        notification.open({
          message: `¡Bien hecho!`,
          placement,
          style: { width: 500 },
          icon: (
            <img
              style={{ width: 20, marginRight: "-20" }}
              src={ticket}
              alt="ticket"
            />
          ),
          duration: null,
          description: (
            <>
              <p style={{ marginRight: 10 }}>
                Creaste exitosamente una Actividad para un Equipo de trabajo. Te
                sugerimos continuar con...
              </p>

              {/* <p style={{ marginRight: 10 }}>
                  
                </p> */}

              {/************** SUGERIDA ***************/}

              <Row>
                <Col span={3}>
                  {/* <a href={`/DetalleFaena?idFaena=${idFaena}`}> */}
                  <img
                    style={{
                      width: 20,
                      cursor: "pointer",
                      justifyContent: "center",
                      opacity: "50%",
                    }}
                    src={reloj}
                    alt="next"
                  />
                  {/* </a> */}
                </Col>
                <Col span={21}>
                  {/* <a href={`/DetalleFaena?idFaena=${idFaena}`}> */}
                  <p
                    style={{
                      cursor: "pointer",
                      fontFamily: "MavenPro-Bold",
                      color: "#7b7b7b",
                    }}
                  >
                    Asignar Trabajadores a la nueva Actividad
                  </p>
                  {/* </a> */}
                </Col>
              </Row>

              <p style={{ marginRight: 10 }}>O también puedes...</p>

              {/************** Extra 1 - Ver las plantillas de Equipos de Trabajo ***************/}

              <Row>
                <Col span={3}>
                  <a href={`/Faena`}>
                    <img
                      style={{
                        width: 20,
                        cursor: "pointer",
                        justifyContent: "center",
                      }}
                      src={reloj}
                      alt="next"
                    />
                  </a>
                </Col>
                <Col span={21}>
                  <a href={`/Faena`}>
                    <p
                      style={{ cursor: "pointer", fontFamily: "MavenPro-Bold" }}
                    >
                      Ver las Plantillas de Equipos de Trabajo
                    </p>
                  </a>
                </Col>
              </Row>

              {/************** Extra 2 - Ver todos los trabajores ***************/}

              <Row>
                <Col span={3}>
                  <a href={`/Trabajador`}>
                    <img
                      style={{
                        width: 20,
                        cursor: "pointer",
                        justifyContent: "center",
                      }}
                      src={reloj}
                      alt="next"
                    />
                  </a>
                </Col>
                <Col span={21}>
                  <a href={`/Trabajador`}>
                    <p
                      style={{ cursor: "pointer", fontFamily: "MavenPro-Bold" }}
                    >
                      Ver todos los Trabajadores
                    </p>
                  </a>
                </Col>
              </Row>
            </>
          ),
        });
      },
    });
  };

  componentWillUnmount() {
    notification.destroy();
  }

  render() {
    return (
      <>
        {this.RedireccionarDetalle()}
        <Layout style={{ height: "100%" }}>
          <Loading cargando={this.props.loading} />
          <Header style={{ background: "none" }}>
            <HeaderFicha
              titulo="Actividades"
              subtitulo="Une centro de costos y actividades para planificar a tus trabajadores"
            />
          </Header>
          <hr className="division" style={{ marginRight: 35 }} />
          <Content
            className="contenedor-general cont-tabla-celeste"
            style={{ background: "none" }}
          >
            {/* <DashboardDinamico
              reporteDashboard={this.state.reporteDashboard}
            /> */}
            <p style={{ fontFamily: "MavenPro-Bold", marginBottom: "3px" }}>
              Filtrar faenas por:
            </p>
            <Row
              style={{ padding: "6px 0px 22px", marginTop: "0px" }}
              // align="bottom"
              gutter={15}
            >
              <Col style={{ textAlign: "bottom" }} span={6}>
                <DatePicker
                  locale={locale}
                  picker="month"
                  allowClear={false}
                  onChange={(fechaMes) => {

                    if (fechaMes) {

                      // let fechaInicio = fechaMes.clone().startOf('month');
                      // let fechaFin = fechaMes.clone().endOf('month');

                      let fechaInicio = fechaMes.clone();
                      let fechaFin = fechaMes.clone();

                      let fechas = [fechaInicio, fechaFin];

                      this.setState({
                        dates: fechas,
                        RangeDatesValues: fechas,
                        valMonthPicker: fechaMes,

                      });
                    } else {
                      let defaultDate = [
                        moment().add(-1, "month").startOf("month"),
                        moment().add(1, "month").endOf("month"),
                      ];
                      this.setState({
                        dates: defaultDate,
                        RangeDatesValues: defaultDate,
                        valMonthPicker: fechaMes,
                      });
                    }
                  }}
                  // onCalendarChange={(e) => {
                  //   this.setState({
                  //     dates: e,
                  //     RangeDatesValues:e
                  //   })
                  // }}
                  // onOpenChange={((val) => {
                  //   // if (this.state.dates) {
                  //   //   if (!val && this.state.dates[1] === null) {
                  //   //     this.setState({
                  //   //       dates: null,
                  //   //     })
                  //   //   }
                  //   // }
                  //   this.setState({
                  //     dates: null,
                  //   });

                  // })}

                  value={this.state.valMonthPicker}

                //disabled={this.state.rangeDisabled}
                />
              </Col>
              <Col span={6}>

                <Select
                  mode="multiple"
                  className="campos-formulario-contratos"
                  placeholder="Centros de costo"
                  maxTagCount={2}
                  onChange={(val) => {
                    this.setState({ ccSeleccionados: val });
                  }}
                  value={this.state.ccSeleccionados}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.todosCentrosCosto.map(({ Nombre, _id }, index) => (
                    <Select.Option
                      key={index}
                      value={_id}
                    >
                      {Nombre}
                    </Select.Option>
                  ))}
                </Select>

              </Col>

              <Col span={3}>
                <Button
                  type="primary"
                  className={'filtrar-contratos-btn'}
                  onClick={() => {
                    this.filtrarCalendario();

                    // console.log(this.props)
                  }}
                >
                  Filtrar
                </Button>
              </Col>

              <Col style={{ textAlign: "right" }} span={9}>
                {/* <Button
                  className="descargar-excel"
                  type="primary"
                  // onClick={() => {
                  //   this.descargarExcel();
                  // }}
                  size="large"
                  style={{ marginRight: "20px" }}
                >
                  Descargar Excel
                </Button> */}

                <Button
                  type="primary"
                  onClick={() => {
                    this.mostrarModalCrear();
                  }}
                  size="large"
                  className={
                    !this.state.Editar
                      ? "guardar-form-disabled"
                      : "guardar-form"
                  }
                  disabled={!this.state.Editar}
                >
                  Crear actividad
                </Button>
              </Col>

              <Col span={24} style={{ marginTop: "40px" }}>
                <div
                  style={{
                    width: "fit-content",
                    display: "inline-block",
                    marginRight: "2%",
                  }}
                >
                  <Badge status="success" text="Trabajo Confirmado" />
                </div>

                <div
                  style={{
                    width: "fit-content",
                    display: "inline-block",
                    marginRight: "2%",
                  }}
                >
                  <Badge status="processing" text="Trabajo En Curso" />
                </div>

                <div
                  style={{
                    width: "fit-content",
                    display: "inline-block",
                    marginRight: "2%",
                  }}
                >
                  <Badge status="warning" text="Trabajo Reservado" />
                </div>

                <div
                  style={{
                    width: "fit-content",
                    display: "inline-block",
                    marginRight: "2%",
                  }}
                >
                  <Badge status="error" text="Trabajo Sin Planificación" />
                </div>
              </Col>

              <Col span={24} style={{ marginTop: "20px" }}>
                {this.mostrarCalendario()}
              </Col>
            </Row>
          </Content>
          <Modal
            //propiedades cambiables del modal
            wrapClassName="modalCrear"
            visible={this.props.modalFaena}
            onCancel={this.modalCrearCancelar}
            //propiedades obligatorias para modal mantenedor
            bodyStyle={{ borderRadius: "16px" }}
            width="80%"
            footer={null}
            style={{ top: 20 }}
            closable={false}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          >
            <FormularioAsignacionFaenas
              ObtenerAsignacionesIdCentroCosto={
                this.ObtenerAsignacionesIdCentroCosto
              }
              Formulario={this.state.form}
              cambiarValores={this.cambiarValores}
              CrearFaena={this.CrearFaena}
              centrosCosto={this.state.todosCentrosCosto}
              plantillas={this.props.plantillas}
              fechaDeshabilitada={this.props.fechaDeshabilitada}
              faenasFechasCentroCosto={this.props.faenasFechasCentroCosto}
            />
          </Modal> 

          <Modal
            //propiedades cambiables del modal
            wrapClassName="modalEditar"
            visible={this.props.mostrarModalEditar}
            onCancel={this.modalEditarCancelar}
            //propiedades obligatorias para modal mantenedor
            bodyStyle={{ borderRadius: "16px" }}
            width="80%"
            footer={null}
            style={{ top: 20 }}
            closable={false}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          >
            <FormularioAsignacionFaenasEditar
              ObtenerAsignacionesIdCentroCosto={
                this.ObtenerAsignacionesIdCentroCosto
              }
              cambiarValores={this.cambiarValores}
              centrosCosto={this.state.todosCentrosCosto}
              plantillas={this.props.plantillas}
              fechaDeshabilitada={this.props.fechaDeshabilitada}
              faenasFechasCentroCosto={this.props.faenasFechasCentroCosto}
              Formulario={this.props.faenaEditar}
              alargarFaena={this.alargarFaena}
              acortarFaena={this.acortarFaena}
            />
          </Modal>
        </Layout>
      </>
    );
  }
}

const EstadosAPropiedades = ({
  asignacionFaenasReducer: {
    loading,
    centroscosto,
    plantillas,
    error,
    success,
    modalFaena,
    faenas,
    faenasFechasCentroCosto,
    faena,
    tablaCargando,
    mostrarCalendario,
    fechaDeshabilitada,
    contadores,
    faenaEditar,
    mostrarModalEditar,
  },
}) => {
  return {
    loading,
    centroscosto,
    plantillas,
    error,
    success,
    modalFaena,
    faenas,
    faenasFechasCentroCosto,
    faena,
    tablaCargando,
    mostrarCalendario,
    fechaDeshabilitada,
    contadores,
    faenaEditar,
    mostrarModalEditar,
  };
};

export default connect(
  EstadosAPropiedades,
  AsignacionFaenasActions
)(asignacionFaenas);
