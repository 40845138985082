import {
  ERROR,
  INGRESAR_TITULO,
  LOADING,
  LIMPIAR_ERROR_SUCCESS,
  MOSTRAR_MODAL_TITULO,
  OCULTAR_MODAL_TITULO,
  OBTENER_TITULOS_MANTENEDOR,
  ELIMINAR_TITULO_MANTENEDOR,
  OBTENER_TITULO_MANTENEDOR,
  EDITAR_TITULO_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OCULTAR_MODAL_TITULO_EDITAR,
} from "../types/MantenedorTitulosTypes";

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: null,
  modalTitulo: false,
  modalTituloEditar: false,
  titulos: [],
  titulo: null,
  tablaCargando: true,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case INGRESAR_TITULO:
      return {
        ...state,
        loading: false,
        success: payload,
        modalTitulo: false,
        tablaCargando: false,
      };

    case LOADING:
      return {
        ...state,
        loading: true,
        tablaCargando: true,
      };

    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        tablaCargando: false,
      };
    case LIMPIAR_ERROR_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };
    case OBTENER_TITULOS_MANTENEDOR:
      return {
        ...state,
        titulos: payload,
        loading: false,
        tablaCargando: false,
      };
    case OBTENER_TITULO_MANTENEDOR:
      return {
        ...state,
        titulo: payload,
        loading: false,
        tablaCargando: false,
        modalTituloEditar: true,
      };
    case ELIMINAR_TITULO_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      };
    case EDITAR_TITULO_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        modalTituloEditar: false,
      };
    case TOMAR_VALORES_EDITAR:
      const { nombreCampo, valor } = payload;
      return {
        ...state,

        titulo: {
          ...state.titulo,
          [nombreCampo]: valor,
        },
      };

    case MOSTRAR_MODAL_TITULO:
      return {
        ...state,
        modalTitulo: true,
      };
    case OCULTAR_MODAL_TITULO_EDITAR:
      return {
        ...state,
        modalTituloEditar: false,
      };
    case OCULTAR_MODAL_TITULO:
      return {
        ...state,
        modalTitulo: false,
      };
    default:
      return state;
  }
};
