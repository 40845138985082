import React from "react";
import logoGenerico from "../../img/logos/logoGenerico.svg";
import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  DatePicker,
} from "antd";

import locale from "antd/es/date-picker/locale/es_ES";

import Swal from "sweetalert2";

const FormularioFeriados = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {
    values.Fecha = values.Fecha.format();

    props.crearFeriado(values);
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={logoGenerico} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Nuevo Feriado"
          />
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Form.Item
              label="Fecha"
              name="Fecha"
              rules={[
                {
                  required: true,
                  message: "Debe introducir una fecha",
                },
              ]}
            >
              <DatePicker locale={locale} format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item
              label="Descripción"
              name="Descripcion"
              rules={[
                {
                  min: 1,
                  required: true,
                  message: "Debe introducir una descripción",
                },
              ]}
              hasFeedback
            >
              <Input
                name="Descripcion"
                className="campos"
                placeholder="Descripción del feriado"
                style={{
                  maxWidth:"100%"
                }}
              />
            </Form.Item>
            <Form.Item label="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                defaultChecked
                onChange={props.cambiarEstadoVigencia}
                className="switch"
              />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormularioFeriados;
