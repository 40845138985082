import Swal from "sweetalert2";
// import { notification } from "antd";
import moment from 'moment';
import API from "../Api/api";

import {
  momentToStringFechaInicio,
  momentToStringFechaFin,
  databaseFormat,
  obtenerFechaFormateadaMismaHora,
} from "../../funcionesGenericas/fechas";


export const traer_todos_las_fechas_trabajador_descansos_finiquitados = async (idTrabajador) => {
  try {
    return new Promise(async (resolve,reject)=>{
      const url = `${API}/BitacoraTrabajadores/ByDates/DetalleTrabajadorDescansoFiniquitado/Todos/${idTrabajador}`;
      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;
  
      switch (status) {
        case 200:
        case 201:
          resolve(data);
        break;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido un error al buscar descansos finiquitados, algo inesperado a sucedido. Vuelve a intentarlo nuevamente",
            icon: "error",
          });
          reject([]);
          break;
      }
    })
    
  } catch (error) {
    return [];
  }
};
