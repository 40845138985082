import {
  ERROR,
  INGRESAR_REGIMEN,
  LOADING,
  MOSTRAR_MODAL_REGIMEN,
  OCULTAR_MODAL_REGIMEN,
  OBTENER_REGIMENES_MANTENEDOR,
  ELIMINAR_REGIMEN_MANTENEDOR,
  OBTENER_REGIMEN_MANTENEDOR,
  EDITAR_REGIMEN_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OCULTAR_MODAL_REGIMEN_EDITAR,
} from "../types/MantenedorRegimenTrabajoTypes";

import API from "../Api/api";
// import { obtenerFechaHoy } from "../../funcionesGenericas/fechas";

import Swal from "sweetalert2";

export const CrearRegimen = (regimen) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {


    console.log('regimen que creo');

    console.log(regimen);

    const url = `${API}/RegimenDeTrabajo`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(regimen),
    });
    const status = response.status;

    const data = await response.json();

    console.log(data);

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Se ingresó el régimen de trabajo correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: INGRESAR_REGIMEN,
          payload: "Se ingresó el usuario correctamente",
        });

        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerRegimenes = (idEmpresa) => async (dispatch) => {


  dispatch({ type: LOADING });
  try {
    const url = `${API}/RegimenDeTrabajo?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;
    //console.log(data);

    switch (status) {
      case 200:
      case 201:

        data = await response.json();
        const regimenes = data.regimenes;


        dispatch({ type: OBTENER_REGIMENES_MANTENEDOR, payload: regimenes });
        break;

      default:
        data = await response.json();
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    console.log('==================>>>error');
    console.log(error);
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerRegimen = (idRegimen) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/RegimenDeTrabajo/${idRegimen}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const regimen = data.regimen;
        dispatch({ type: OBTENER_REGIMEN_MANTENEDOR, payload: regimen });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarRegimen = (idRegimen) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/RegimenDeTrabajo/${idRegimen}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El régimen fue eliminado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: ELIMINAR_REGIMEN_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarRegimen = (Regimen) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/RegimenDeTrabajo/${Regimen._id}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },

      body: JSON.stringify(Regimen),
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El régimen fue actualizado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: EDITAR_REGIMEN_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const TomarValoresEditar = (nombreCampo, valor) => (dispatch) => {
  let objeto = {
    nombreCampo,
    valor,
  };
  dispatch({ type: TOMAR_VALORES_EDITAR, payload: objeto });
};

export const MostrarModalRegimen = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_REGIMEN });
};

export const OcultarModalRegimen = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_REGIMEN });
};

export const OcultarModalRegimenEditar = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_REGIMEN_EDITAR });
};

// export const DescargarExcel = () => async (dispatch) => {
//   dispatch({ type: LOADING });
//   try {
//     const url = `${API}/Rol/Descargar/Excel`;
//     let status;
//     fetch(url)
//       .then((response) => {
//         status = response.status;
//         return response.blob();
//       })
//       .then((blob) => {
//         switch (status) {
//           case 200:
//           case 201:
//             const url = window.URL.createObjectURL(blob);
//             const a = document.createElement("a");
//             a.href = url;
//             a.download = `Roles_${obtenerFechaHoy()}.xlsx`;
//             document.body.appendChild(a);
//             a.click();
//             a.remove();

//             dispatch({ type: PARAR_LOADING });
//             break;
//           default:
//             Swal.fire({
//               title: "Uups",
//               text:
//                 "Error status: " +
//                 status +
//                 " al realizar la descarga del archivo",
//               icon: "error",
//             });

//             dispatch({ type: PARAR_LOADING });
//             break;
//         }
//       });
//   } catch (error) {
//     dispatch({ type: ERROR, payload: error });
//   }
// };
