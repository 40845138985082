import {
  BUSCAR_TRABAJADORES_DESCANSO,
  ERROR_DESCANSO,
  LOADING_DESCANSO,
  INSERTAR_FECHAS_FORM,
  INSERTAR_TRABAJADOR_FORM,
  INSERTAR_DATOS_FORMULARIO,
  ENVIAR_CORREO,
  LOADING_DESCANSO_FALSE,
  BUSCAR_CONTRATOS_TRABAJADOR
} from "../types/descansoTypes";


import Swal from "sweetalert2";
// import { notification } from "antd";
import moment from 'moment';
import API from "../Api/api";

import {
  momentToStringFechaInicio,
  momentToStringFechaFin,
  databaseFormat,
  obtenerFechaFormateadaMismaHora,
} from "../../funcionesGenericas/fechas";

export const insertarDatos = (
  DiasLaborados,
  DiasSugeridos,
  DiasOtorgados,
  DiasHabiles,
  ExcesoEmbarque,
  DiasInhabiles,
  SaldoPendiente,
) => async (dispatch, getState) => {

  dispatch({
    type: LOADING_DESCANSO,
  });

  try {

    const store = getState().DescansoReducer;

    const {
      form: { Fechas, Trabajador, Correo: envioCorreo },
    } = store;

    let fechaInicioDescanso = moment(Fechas[0]);
    let fechaFinDescanso = moment(Fechas[1]);

    let objDescanso = {
      IdTrabajador: Trabajador,
      DiasLaborados,
      DiasSugeridos,
      DiasOtorgados,
      DiasHabiles,
      ExcesoEmbarque,
      DiasInhabiles,
      SaldoPendiente,
      Fecha_Inicio: Fechas[0],
      Fecha_Fin: Fechas[1],
    };

    const urlDescanso = `${API}/Descansos`;

    const requestDescanso = await fetch(urlDescanso, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objDescanso),
    });

    const responseDescansos = await requestDescanso.json();
    const statusDescansos = await requestDescanso.status;

    switch (statusDescansos) {
      case 201:

        let idRegistroDescansos = responseDescansos.data._id;
        
        let cantidadDias = fechaFinDescanso.diff(fechaInicioDescanso, "days") + 1;

        let arregloDeDias = [];

        for (let index = 0; index < cantidadDias; index++) {
          let fecha = fechaInicioDescanso;
          fecha = fecha.add(index === 0 ? 0 : 1, "day");
          const aux = {
            Fecha: new Date(fecha),
            IdTrabajador: Trabajador,
            TipoRegistroBitacoraTrabajador: "5f3eeb78978b284b68ebced4",

            InicioTrabajo: index === 0 ? true : false,
            FinTrabajo: index === cantidadDias - 1 ? true : false,
            Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,
            Descansos: idRegistroDescansos,
          };

          arregloDeDias.push(aux);
        }

        let objetoEnviar = {
          arregloDeDias,
          fechaInicio: Fechas[0],
          fechaFin: Fechas[1],
        };

        const url = `${API}/IngresoDeDias/${Trabajador}`;
        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objetoEnviar),
        });

        const response = await request.json();
        const status = await request.status;

        switch (status) {
          case 201:
            ////////////////////////////////////////////////////////////////////////////////
            if (envioCorreo) {
              const url = `${API}/Enviar/Correo/Descanso/${idRegistroDescansos}`;
              await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              })
            }
            ////////////////////////////////////////////////////////////////////////////////
            Swal.fire({
              title: "Bien",
              text: "Se ingresaron exitosamente los días de descanso",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });
            dispatch({
              type: INSERTAR_DATOS_FORMULARIO,
              payload: response,
            });

            break;
          case 400:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });

            dispatch({
              type: ERROR_DESCANSO,
              payload: response.err.message,
            });
            break;
          case 500:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            dispatch({
              type: ERROR_DESCANSO,
              payload: response.err.message,
            });
            break;
          default:
            Swal.fire({
              title: "Error",
              text:
                "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
              icon: "error",
            });
            dispatch({
              type: ERROR_DESCANSO,
              payload: response.err.message,
            });
            break;
        }
        break;
      case 400:

        Swal.fire({
          title: "Error",
          text: responseDescansos.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR_DESCANSO,
          payload: responseDescansos.err.message,
        });
        break;
      case 500:

        Swal.fire({
          title: "Error",
          text: responseDescansos.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR_DESCANSO,
          payload: responseDescansos.err.message,
        });
        break;
      default:

        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }

  } catch (error) {

    console.error(error);

    dispatch({
      type: ERROR_DESCANSO,
      payload: error.message,
    });
  }
};

export const insertarDatosAvanzado = (
  objDescanso,
  Fechas
) => async (dispatch, getState) => {

  dispatch({
    type: LOADING_DESCANSO,
  });

  try {

    const store = getState().DescansoReducer;

    const {
      form: { Trabajador, Correo: envioCorreo },
    } = store;

    let fechaInicioDescanso = moment.utc(Fechas[0]);
    let fechaFinDescanso = moment.utc(Fechas[1]);

    // let objDescanso = {
    //   IdTrabajador: Trabajador,
    //   DiasLaborados,
    //   DiasSugeridos,
    //   DiasOtorgados,
    //   DiasHabiles,
    //   ExcesoEmbarque,
    //   DiasInhabiles,
    //   SaldoPendiente,
    //   Fecha_Inicio: Fechas[0],
    //   Fecha_Fin: Fechas[1],
    // };

    const urlDescanso = `${API}/Descansos/Avanzado`;
    
    const requestDescanso = await fetch(urlDescanso, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objDescanso),
    });

    const responseDescansos = await requestDescanso.json();
    const statusDescansos = await requestDescanso.status;

    switch (statusDescansos) {
      case 200:
      case 201:

        let idRegistroDescansos = responseDescansos.data._id;
        let msjObservacion = responseDescansos.data.Observacion;
        let cantidadDias = fechaFinDescanso.diff(fechaInicioDescanso, "days") + 1;
      
        let arregloDeDias = [];

        for (let index = 0; index < cantidadDias; index++) {

          let fecha = fechaInicioDescanso;
          fecha = fecha.add(index === 0 ? 0 : 1, "day");

          const aux = {
            Fecha: obtenerFechaFormateadaMismaHora(fecha.set({ hour: 12 }), databaseFormat),
            IdTrabajador: Trabajador,
            TipoRegistroBitacoraTrabajador: "5f3eeb78978b284b68ebced4",

            InicioTrabajo: index === 0 ? true : false,
            FinTrabajo: index === cantidadDias - 1 ? true : false,
            Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,
            Descansos: idRegistroDescansos,

            Fecha_Inicio: momentToStringFechaInicio(moment.utc(objDescanso.Fecha_Inicio).set({ hour: 12 }), databaseFormat),
            Fecha_Fin: momentToStringFechaFin(moment.utc(objDescanso.Fecha_Fin).set({ hour: 12 }), databaseFormat),

            DescansoAsignado: true,

            Observacion: msjObservacion
          };

          arregloDeDias.push(aux);
        }

        let objetoEnviar = {
          arregloDeDias,
          fechaInicio: objDescanso.Fecha_Inicio,
          fechaFin: objDescanso.Fecha_Fin,
        };
        
        const url = `${API}/IngresoDeDias/${Trabajador}`;
        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objetoEnviar),
        });

        const response = await request.json();
        const status = await request.status;

        switch (status) {
          case 201:
            ////////////////////////////////////////////////////////////////////////////////
            if (envioCorreo) {
              const url = `${API}/Enviar/Correo/Descanso/${idRegistroDescansos}`;
              await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              })
            }
            ////////////////////////////////////////////////////////////////////////////////
            Swal.fire({
              title: "Bien",
              text: "Se ingresaron los días de descanso correctamente.",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });

            
            dispatch({
              type: INSERTAR_DATOS_FORMULARIO,
              payload: response,
            });

            break;
          case 400:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });

            dispatch({
              type: ERROR_DESCANSO,
              payload: response.err.message,
            });
            break;
          case 500:

            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            dispatch({
              type: ERROR_DESCANSO,
              payload: response.err.message,
            });
            break;
          default:
            Swal.fire({
              title: "Error",
              text:
                "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
              icon: "error",
            });
            dispatch({
              type: ERROR_DESCANSO,
              payload: response.err.message,
            });
            break;
        }
        break;

      case 400:

        Swal.fire({
          title: "Error",
          text: responseDescansos.err.message,
          icon: "error",
        });

        dispatch({
          type: ERROR_DESCANSO,
          payload: responseDescansos.err.message,
        });

        break;

      case 409:

        if (responseDescansos.data.err == 'TRABAJO') {
          Swal.fire({
            title: "¡Ups!",
            text: 'No puedes registrar en días con trabajo planificado, primero debes modificar esa planificación en la actividad.',
            icon: "error",
          });
        }

        if (responseDescansos.data.err == 'VACACIONES') {
          Swal.fire({
            title: "¡Ups!",
            html: `<span>No puedes registrar en días con vacaciones planificadas. Si deseas modificar esos registros, debes utilizar el <a href='/Vacaciones'>módulo de vacaciones.</a></span>`,
            icon: "error",
          });
        }

        if (responseDescansos.data.err == 'DESCANSOS') {
          Swal.fire({
            title: "¡Ups!",
            html: `No puedes sobrescribir planificaciones de descaso con otros registros de descanso, si deseas utilizar esos días, debes eliminar la planificación y crear una nueva.`,
            icon: "error",
          });
        }

        dispatch({
          type: ERROR_DESCANSO,
          payload: 'No puedes registrar descansos en días con asistencia ya confirmada.',
        });

        break;

      case 500:

        Swal.fire({
          title: "Error",
          text: responseDescansos.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR_DESCANSO,
          payload: responseDescansos.err.message,
        });
        break;

      case 420:

        Swal.fire({
          title: "Uups",
          text: "No se pueden generar descansos en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({ type: ERROR_DESCANSO, payload: "No se pueden generar descansos en un periodo que se encuentra cerrado" });

        break;

      default:

        dispatch({ type: ERROR_DESCANSO, payload: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente" });

        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }

  } catch (error) {

    console.error(error);

    dispatch({
      type: ERROR_DESCANSO,
      payload: error.message,
    });
  }
};

export const insertarCompensacion = (objDescanso) => async (
  dispatch
) => {
  try {

    dispatch({
      type: LOADING_DESCANSO,
    });

    const urlDescanso = `${API}/Descansos`;
    const requestDescanso = await fetch(urlDescanso, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objDescanso),
    });

    const responseDescansos = await requestDescanso.json();
    const statusDescansos = await requestDescanso.status;

    switch (statusDescansos) {
      case 201:
      case 200:
        dispatch({
          type: LOADING_DESCANSO_FALSE,
        });

        Swal.fire({
          title: "¡Bien!",
          text: "Se ha ingresado la compensación económica exitosamente, si deseas visualizar las compensaciones del trabajador, dirígete a la ficha del trabajador.",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return responseDescansos;

      default:
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }
  } catch (error) {
    dispatch({
      type: ERROR_DESCANSO,
      payload: error.message,
    });
  }
};

export const insertarFechas = (fechas) => (dispatch) => {
  dispatch({
    type: INSERTAR_FECHAS_FORM,
    payload: fechas,
  });
};

export const enviarCorreo = (estado) => (dispatch) => {
  dispatch({
    type: ENVIAR_CORREO,
    payload: estado,
  });
}

export const insertarTrabajador = (idTrabajador) => (dispatch) => {
  dispatch({
    type: INSERTAR_TRABAJADOR_FORM,
    payload: idTrabajador,
  });
};

export const traer_todos_las_fechas_trabajador = (idTrabajador) => async (
  dispatch
) => {
  try {
    const url = `${API}/IngresoDeDias/Actuales/${idTrabajador}`;
    const request = await fetch(url);
    const { data } = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:
        return data;

      default:
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }
  } catch (error) {
    dispatch({
      type: ERROR_DESCANSO,
      payload: error.message,
    });
  }
};

export const obtenerDocsDescansos = (idTrabajador) => async (
  dispatch
) => {
  try {
    const url = `${API}/Descansos/Trabajador/${idTrabajador}`;
    const request = await fetch(url);
    const { data } = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:
        return data;

      default:
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }
  } catch (error) {
    dispatch({
      type: ERROR_DESCANSO,
      payload: error.message,
    });
  }
};

export const traer_todos_los_trabajadores = (empresa) => async (dispatch) => {
  dispatch({
    type: LOADING_DESCANSO,
  });

  try {
    const url = `${API}/trabajadores?empresa=${empresa}`;
    const request = await fetch(url);
    const { Trabajadores } = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:
        dispatch({
          type: BUSCAR_TRABAJADORES_DESCANSO,
          payload: Trabajadores,
        });
        break;
      default:
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }
  } catch (error) {
    dispatch({
      type: ERROR_DESCANSO,
      payload: error.message,
    });
  }
};

export const eliminarRegistro = (fechaInicioDel, fechaFinDel, idDescanso) => async (dispatch, getState) => {

  dispatch({
    type: LOADING_DESCANSO,
  });

  try {

    const store = getState().DescansoReducer;

    const {
      form: { Trabajador },
    } = store;

    const objEliminar = {
      Fecha_Inicio: fechaInicioDel,
      Fecha_Fin: fechaFinDel,
      IdTrabajador: Trabajador,
    }

    const urlDescanso = `${API}/Descansos/Eliminar/${idDescanso}`;

    const requestDescanso = await fetch(urlDescanso, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objEliminar),
    });

    const responseDescansos = await requestDescanso.json();
    const statusDescansos = await requestDescanso.status;

    switch (statusDescansos) {
      case 200:

        dispatch({
          type: LOADING_DESCANSO_FALSE,
        });

        Swal.fire({
          title: "¡Bien!",
          text: "El registro se ha eliminado exitosamente.",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        break;

      case 500:
        dispatch({
          type: LOADING_DESCANSO_FALSE,
        });

        break;

      case 420:

        Swal.fire({
          title: "Uups",
          text: "No se pueden eliminar descansos en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({ type: ERROR_DESCANSO, payload: "No se pueden eliminar descansos en un periodo que se encuentra cerrado" });

        break;
      default:
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }

  } catch (e) {

    console.error(e);

    dispatch({
      type: ERROR_DESCANSO,
      payload: e.message,
    });
  }

}

/*************** CÁLCULOS AUTOMÁTICOS *****************/

export const obtenerFeriados = (idEmpresa) => async (dispatch) => {

  try {
    const url = `${API}/Feriados?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:

        const feriados = data.feriados;
        return feriados;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR_DESCANSO, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: error,
      icon: "error",
    });
    dispatch({ type: ERROR_DESCANSO, payload: error });
  }
};

export const traer_todos_contratos_trabajador =
  (idTrabajador, idEmpresa) => async (dispatch) => {
    try {
      const url = `${API}/Trabajadores/Contratos/Anexo_Contrato?idTrabajador=${idTrabajador}&idEmpresa=${idEmpresa}`;
      const request = await fetch(url);
      const data = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          //console.log(data)
          dispatch({
            type: BUSCAR_CONTRATOS_TRABAJADOR,
            payload: data.contratoDB,
          });
          break;
        default:
          //console.log(status);
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          break;
      }
    } catch (error) {
      dispatch({
        type: ERROR_DESCANSO,
        payload: error.message,
      });
    }
};

export const traer_todos_los_descansos_historicos =
  (idTrabajador) => async (dispatch) => {
    try {
      const ID_DESCANSO = "5f3eeb78978b284b68ebced4";

      const url = `${API}/IngresoDeDias/Bitacoras/TipoBitacora/${idTrabajador}/${ID_DESCANSO}`;

      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          return data;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          return null;
      }
    } catch (error) {
      dispatch({
        type: ERROR_DESCANSO,
        payload: error.message,
      });
      return null;
    }
  };

  export const traer_todos_las_fechas_trabajador_por_contrato =
  (idTrabajador, idContrato, FechaInicio, FechaFin) => async (dispatch) => {
    try {
      let url;
      if (FechaInicio !== undefined && FechaFin !== undefined) {
        url = `${API}/IngresoDeDias/Actuales/Contrato/Anexo_Contrato/${idTrabajador}/${idContrato}?InicioBusqueda=${FechaInicio}&FinBusqueda=${FechaFin}`;
      } else {
        url = `${API}/IngresoDeDias/Actuales/Contrato/Anexo_Contrato/${idTrabajador}/${idContrato}`;
      }
      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          return data;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          return null;
      }
    } catch (error) {
      dispatch({
        type: ERROR_DESCANSO,
        payload: error.message,
      });
      return null;
    }
  };

  export const traer_todos_las_bitacoras_trabajo_trabajador_por_contrato =
  (idTrabajador, idContrato) => async (dispatch) => {
    try {
      let url = `${API}/IngresoDeDias/Actuales/BitacorasTrabajo/${idTrabajador}/${idContrato}`;

      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;
      switch (status) {
        case 200:
          return data;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          return null;
      }
    } catch (error) {
      dispatch({
        type: ERROR_DESCANSO,
        payload: error.message,
      });
      return null;
    }
  };


  