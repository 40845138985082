//REDUCER MANTENEDOR USUARIO
export const LOADING = "MANTENEDOR_USUARIO_LOADING";
export const PARAR_LOADING = "MANTENEDOR_USUARIO_LOADING_PARAR";
export const ERROR = "MANTENEDOR_USUARIO_ERROR";
export const OBTENER_ROLES = "MANTENEDOR_USUARIO_ROLES";
export const INGRESAR_USUARIO = "MANTENEDOR_USUARIO_INGRESAR";
export const LIMPIAR_ERROR_SUCCESS = "MANTENEDOR_USUARIO_LIMPIAR_ERROR_SUCCESS";
export const MOSTRAR_MODAL_USUARIO = "MANTENEDOR_USUARIO_MOSTRAR_MODAL";
export const OCULTAR_MODAL_USUARIO = "MANTENEDOR_USUARIO_OCULTAR_MODAL";
export const OCULTAR_MODAL_USUARIO_EDITAR =
  "MANTENEDOR_USUARIO_OCULTAR_MODAL_EDITAR";
export const OBTENER_USUARIOS_MANTENEDOR =
  "MANTENEDOR_USUARIOS_OBTENER_USUARIOS";
export const OBTENER_USUARIO_MANTENEDOR = "MANTENEDOR_USUARIOS_OBTENER_USUARIO";
export const ELIMINAR_USUARIO_MANTENEDOR =
  "MANTENEDOR_USUARIOS_ELIMINAR_USUARIO";
export const OBTENER_ROLES_USUARIO_SELECT =
  "MANTENEDOR_USUARIO_OBTENER_ROLES_USUARIO_SELECT";
export const EDITAR_USUARIO_MANTENEDOR = "MANTENEDOR_USUARIO_EDITAR_ROL";
export const TOMAR_VALORES_EDITAR = "MANTENEDOR_USUARIO_TOMAR_VALORES_EDITAR";
export const DESCARGAR_EXCEL = "MANTENEDOR_USUARIO_DESCARGAR_EXCEL";

export const MANTENEDOR_USUARIO_OBTENER_EMPRESAS_VIGENTES =
  "MANTENEDOR_USUARIO_OBTENER_EMPRESAS_VIGENTES";

export const MANTENEDOR_USUARIO_OBTENER_HOLDINGS_VIGENTES =
  "MANTENEDOR_USUARIO_OBTENER_HOLDINGS_VIGENTES";
export const ACTUALIZAR_VALORES_EMPRESAS = "MANTENEDOR_USUARIO_ACTUALIZAR_VALORES_EMPRESAS"
