import React, { useEffect, useState } from "react";
import SubirFoto from "../../components/subirFoto/SubirFoto";
import logoGenerico from "../../img/logos/logoGenerico.svg";
import Rut from "../../funcionesGenericas/rut";
import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Select,
  //Radio
} from "antd";
import Swal from "sweetalert2";
// import ValidadorRut from "validar-rut";
const Demo = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();
  const [validRutUsuario, setValidRutUsuario] = useState("");

  const ObtenerImagen = (imagenUrl) => {
    props.obtenerImagen(imagenUrl);
    props.TomarValoresEditar("Imagen", imagenUrl);
  };

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {
    if (props.Formulario.Imagen === null) {
      Swal.fire({
        title: "Uuups...",
        text: "Debes escoger imagen",
        icon: "error",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Continuar",
      });
    } else {


      values.Imagen = props.Formulario.Imagen;
      values.Empresas = values.EmpresaHolding;

      // let Empresa = {
      //   Empresa: usuario.EmpresaSeleccionada.Empresa._id,
      //   Rol: values.Rol,
      // };

      let empresas = props.Formulario.Empresas;

      const objAux = {
        ...values,
        Empresas: empresas,
      };

      props.editarUsuario(objAux);
    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  useEffect(() => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      let {
        Apellido_Materno,
        Apellido_Paterno,
        Descripcion,
        Email,
        Nombres,
        Password,
        Rut,
        Telefono,
        Vigencia,
        Empresas,
      } = props.Formulario;

      usuario = JSON.parse(usuario);

      let idRol;

      Empresas.forEach((empresa) => {
        if (
          empresa.Empresa.toString() ===
          usuario.EmpresaSeleccionada.Empresa._id.toString()
        ) {
          idRol = empresa.Rol;
        }
      });

      if (props.editarCerrado) {
        form.setFieldsValue({
          Rut,
        });
        props.cambiarEditarCerrado();
      }

      form.setFieldsValue({
        Apellido_Materno,
        Apellido_Paterno,
        Descripcion,
        Email,
        Nombres,
        Password,
        Rol: idRol,
        Telefono,
        Vigencia,
      });
    }
  });

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={true}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={logoGenerico} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Nuevo Usuario"
          />
        </Col>
        <Col span={8}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Row justify="center">
              <Col span={8} style={{ marginRight: 90 }}>
                <SubirFoto
                  avatar={props.Formulario.Imagen}
                  ObtenerImagen={ObtenerImagen}
                />
              </Col>
              <Col span={24}>
                <p className="">
                  Te recomendamos que la fotografía no exceda los 5 MB de peso
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col offset={1} span={15}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Form.Item
              name={"Email"}
              label="Nombre usuario (email)"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Se debe ingresar un correo",
                },
              ]}
            >
              <Input
                name="Email"
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Email", valor);
                }}
                className="campos"
              />
            </Form.Item>
            <Form.Item
              name={"Password"}
              label="Contraseña"
              rules={[
                {
                  required: true,
                  message: "Debe ingresar una contraseña",
                  min: 8,
                  max: 16,
                },
              ]}
              hasFeedback
              help="La contraseña debe tener como mínimo 8 caracteres alfanumericos"
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Password", valor);
                }}
                suggested="current-password"
                name="Password"
                type="Password"
                className="campos"
              />
            </Form.Item>
            <Form.Item
              name="Nombres"
              label="Nombres:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir sus nombres",
                  min: 2,
                  max: 20,
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Nombres", valor);
                }}
                name="Nombres"
                className="campos"
              />
            </Form.Item>
            <Form.Item
              name={"Apellido_Paterno"}
              label="Apellido Paterno:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir su apellidos",
                  min: 2,
                  max: 30,
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Apellido_Paterno", valor);
                }}
                name="Apellido_Paterno"
                className="campos"
              />
            </Form.Item>
            <Form.Item
              name={"Apellido_Materno"}
              label="Apellido Materno:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir su apellidos",
                  min: 2,
                  max: 30,
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Apellido_Materno", valor);
                }}
                name="Apellido_Materno"
                className="campos"
              />
            </Form.Item>

            <Form.Item
              name={"Rut"}
              label="RUT"
              validateStatus={validRutUsuario}
              onChange={(e) => {
                let valor = e.target.value;

                if (valor) {
                  let rut = Rut.formateaRut(valor);
                  let rutValidacion = Rut.procesarRut(rut);

                  setValidRutUsuario(rutValidacion);

                  if (rut !== undefined) {
                    form.setFieldsValue({ Rut: rut });
                  } else {
                    form.setFieldsValue({ Rut: valor });
                  }
                }
              }}
              rules={[
                {
                  min: 11,
                  max: 12,
                  required: true,
                  message: "Debe introducir el RUT.",
                },
              ]}
              hasFeedback
              help="Ingrese el RUT válido con código verificador"
            >
              <Input
                name="Rut"
                className="campos"
                placeholder="xx.xxx.xxx-x"
                maxLength={12}
              />
            </Form.Item>
            <Form.Item
              label="Rol"
              name={"Rol"}
              rules={[
                {
                  required: true,
                  message: "Debes seleccionar un rol para el usuario",
                },
              ]}
            >
              <Select
                onSelect={(value) => {
                  let usuario = localStorage.getItem("usuario-people-manage");
                  if (
                    usuario !== null &&
                    usuario !== "" &&
                    usuario !== undefined &&
                    usuario !== "undefined"
                  ) {
                   
                    usuario = JSON.parse(usuario);
                    let empresas = props.Formulario.Empresas;

                    empresas.forEach((empresa) => {
                      if (
                        empresa.Empresa.toString() ===
                        usuario.EmpresaSeleccionada.Empresa._id.toString()
                      ) {
                        empresa.Rol = value;
                      }
                    });

                    props.TomarValoresEditar("Empresas", empresas);
                  }
                }}
                className="campos"
                placeholder="Selecciona rol"
              >
                {props.Roles.map((value, index) => {
                  if (value._id !== "5f21ae92d585cd003f997a6a") {
                    return (
                      <Select.Option name="Rol" key={index} value={value._id}>
                        {value.Nombre}
                      </Select.Option>
                    );
                  } else {
                    return (
                      <Select.Option
                        disabled
                        name="Rol"
                        key={index}
                        value={value._id}
                      >
                        {value.Nombre}
                      </Select.Option>
                    );
                  }
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Teléfono"
              name="Telefono"
              rules={[
                {
                  message: "Debe introducir tu número de teléfono",
                },
              ]}
              hasFeedback
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Telefono", valor);
                }}
                name="Telefono"
                className="campos"
                placeholder="+569XXXXXXXX"
              />
            </Form.Item>

            <Form.Item
              label="Descripción"
              name="Descripcion"
              rules={[
                {
                  message: "Debe introducir una introducción",
                },
              ]}
              hasFeedback
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Descripcion", valor);
                }}
                name="Descripcion"
                className="campos"
                placeholder="Descripción del usuario"
              />
            </Form.Item>
            <Form.Item
              name="Vigencia"
              label="Vigencia"
              style={{ textAlign: "left" }}
            >
              <Switch
                checked={props.Formulario.Vigencia}
                onChange={() => {
                  let nuevoValor =
                    props.Formulario.Vigencia === true ? false : true;
                  props.TomarValoresEditar("Vigencia", nuevoValor);
                }}
                className="switch"
              />
            </Form.Item>
          </Card>
        </Col>
        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Editar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Demo;
