import React, { Component } from "react";

import API from "../../Redux/Api/api";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import "moment/locale/es";
import { convertirFechaString } from "../../funcionesGenericas/fechas";
import Swal from "sweetalert2";
import { EyeFilled } from "@ant-design/icons";
import Excel from "../../img/icons-sidebar/blancas/IconExcel.svg";
import { Line } from "@ant-design/charts";
import Loading from "../../components/loading/Loading";

import {
  Row,
  Col,
  Card,
  Select,
  Button,
  DatePicker,
  Modal,
  Form,
  Table,
  Tooltip
} from "antd";
const { Column } = Table;

class DashboardEmbarqueDesembarque extends Component {
    formRef = React.createRef();
    constructor(props) {
    super(props);
    this.state = {
      loading: false,
      Empresa: '',
      fechaReporte: moment(),
      centroDeCostoReporte: [],
      cargoReporte: [],
      dataGrafico: [],
      dataDetalle: [],
      cantidadEmbarque: 0,
      cantidadDesembarque: 0,
      modalDetalle: false
    };
    }

    componentDidMount = async () => {
      // this.setState({ loading: true });
      let usuario = localStorage.getItem("usuario-people-manage");
      if (
        usuario !== null &&
        usuario !== "" &&
        usuario !== undefined &&
        usuario !== "undefined"
      ) {
        usuario = JSON.parse(usuario);
        const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
        let strFecha = moment().format("YYYY-MM-DD");
        const startOfMonth = moment(strFecha).startOf("month").format("YYYY-MM-DD");
        const endOfMonth = moment(strFecha).endOf("month").format("YYYY-MM-DD");

        const centroDeCostoReporte = "";
        const cargoReporte = "";

        let objetoEnviar = {
            fechaInicio: convertirFechaString(moment(startOfMonth), 1).fechaString,
            fechaFin: convertirFechaString(moment(endOfMonth), 2).fechaString,
            centroDeCostoReporte,
            cargoReporte,
        };
        
        const dataEmbarqueDesembarque = await this.obtenerDataEmbarqueDesembarque(objetoEnviar, idEmpresa);

        let formREF = this.formRef.current;
        formREF.setFieldsValue({ fechaReporte: moment() });

        this.setState({
          Empresa: idEmpresa,
          // dataGrafico: dataEmbarqueDesembarque.data,
          // cantidadEmbarque: dataEmbarqueDesembarque.cantdiasEmbarque,
          // cantidadDesembarque: dataEmbarqueDesembarque.cantdiasDesembarque
          dataGrafico: dataEmbarqueDesembarque === undefined ? [] : dataEmbarqueDesembarque.data,
          cantidadEmbarque: dataEmbarqueDesembarque === undefined ? [] : dataEmbarqueDesembarque.cantdiasEmbarque,
          cantidadDesembarque: dataEmbarqueDesembarque === undefined ? [] : dataEmbarqueDesembarque.cantdiasDesembarque
          
        });
      }
    };

    obtenerDataEmbarqueDesembarque = async (values, idEmpresa) => {
      try {
        const url = `${API}/ReporteEmbarqueDesembarque?empresa=${idEmpresa}`;
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(values),
        });

        const status = response.status;
        let data;
        switch (status) {
          case 200:
          case 201:
          data = await response.json();
          let reporte = data;
          return reporte;
          default:
          const error = data.err.message.toString();
          console.error(error);
          return [];
        }

      } catch (error) {
        console.error(error);
        // Swal.fire({
        //     icon: "error",
        //     title: "Uuups...",
        //     text: "No se pudo cargar la información.",
        //     confirmButtonColor: "#E60AA3",
        //     confirmButtonText: "OK",
        // });
      }
    };

    filtrar = async(values) => {
      this.setState({ loading: true });
      const { Empresa } = this.state;
      const idEmpresa = Empresa;

      let strFecha = moment(values.fechaReporte).format("YYYY-MM-DD");
      const startOfMonth = moment(strFecha).startOf("month").format("YYYY-MM-DD");
      const endOfMonth = moment(strFecha).endOf("month").format("YYYY-MM-DD");

      let objetoEnviar = {
        ...values,
        fechaInicio: convertirFechaString(moment(startOfMonth), 1).fechaString,
        fechaFin: convertirFechaString(moment(endOfMonth), 2).fechaString,
      };

      const dataEmbarqueDesembarque = await this.obtenerDataEmbarqueDesembarque(objetoEnviar, idEmpresa);

      this.setState({
        loading: false,
        dataGrafico: dataEmbarqueDesembarque.data,
        cantidadEmbarque: dataEmbarqueDesembarque.cantdiasEmbarque,
        cantidadDesembarque: dataEmbarqueDesembarque.cantdiasDesembarque,
        
        centroDeCostoReporte: values.centroDeCostoReporte,
        cargoReporte: values.cargoReporte,
        fechaReporte: values.fechaReporte,
      });
    };
    
    obtenerDetalleEmbarqueDesembarque = async (values,idEmpresa) => {
      try {
        const url = `${API}/ReporteEmbarqueDesembarque/Detalle?empresa=${idEmpresa}`;
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(values),
        });

        const status = response.status;
        let data;
        switch (status) {
        case 200:
        case 201:
        data = await response.json();
        let reporte = data;
        return reporte;
        default:
        const error = data.err.message.toString();
        console.error(error);
        return [];
        }

    } catch (error) {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se pudo cargar la información.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
    }
    };

    detalle = async () => {
      this.setState({ loading: true });
      const { Empresa, fechaReporte,centroDeCostoReporte,cargoReporte } = this.state;
      const idEmpresa = Empresa;

      let strFecha = moment(fechaReporte).format("YYYY-MM-DD");
      const startOfMonth = moment(strFecha).startOf("month").format("YYYY-MM-DD");
      const endOfMonth = moment(strFecha).endOf("month").format("YYYY-MM-DD");

      let objetoEnviar = {
        fechaInicio: convertirFechaString(moment(startOfMonth), 1).fechaString,
        fechaFin: convertirFechaString(moment(endOfMonth), 2).fechaString,
        centroDeCostoReporte:centroDeCostoReporte,
        cargoReporte: cargoReporte,
      };
      
      const detalleEmbarqueDesembarque = await this.obtenerDetalleEmbarqueDesembarque(objetoEnviar, idEmpresa);

      this.setState({
        loading:false,
        modalDetalle: true,
        dataDetalle: detalleEmbarqueDesembarque.dataDetalle
      });
    }

    //API OBTENER EXCEL
    obtenerExcelEmbarqueDesembarque = async (dataDetalleExcel, fechaObtenida) => {
      try {
        let obj = {
          dataDetalleExcel,
          fechaObtenida
        }
        const url = `${API}/ReporteEmbarqueDesembarque/DescargarExcel`;
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(obj),
        });
  
        const status = await response.status;
        const excel = await response.blob();
        
        switch (status) {
          case 200:
          case 201:
            const url = window.URL.createObjectURL(excel);
            const a = document.createElement("a");
            a.href = url;
            a.download = `DetalleEmbarqueDesembarque.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
   
            Swal.fire({
              title: "Bien",
              text: "Se descargó excel correctamente.",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });
            return;
  
          default:
            Swal.fire({
              icon: "error",
              title: "Uuups...",
              text: "No se pudo cargar la información.",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "OK",
            });
  
            return;
        }
          
      } catch (error) {
  
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se pudo cargar la información.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
        
        return;
      }
    }
    
    //ACTION DESCARGAR EXCEL
    descargarExcelEmbarqueDesembarque = async () => {

      this.setState({ loading: true });
      let { dataDetalle } = this.state;
  
      if(dataDetalle && dataDetalle.length > 0){
  
        let arrayEnviar = [];
        const { fechaReporte } = this.state;
        dataDetalle.forEach(element => {
          let trabajador = {
            'NombreTrabajador': element.NombreCompleto,
            'Rut': element.RutTrabajador,
            'CentroCosto': element.CentroCostoTratado,
            'Cargo': element.CargoTratado,
            'FechaEmbarque': element.FechaEmbarque,
            'FechaDesembarque': element.FechaDesembarque
          }
  
          arrayEnviar.push(trabajador);
        });
  
        let excelDescargado = await this.obtenerExcelEmbarqueDesembarque(arrayEnviar,fechaReporte);
  
        this.setState({loading: false});
  
      }
    }

    NuevaPestaña = (idUsuario) => {
      //window.open("/CalendarioTrabajador?Trabajador=" + idUsuario);
      window.open("/CalendarioTrabajadorNew?Trabajador=" + idUsuario);
    };
    
    render() {
      let { centrosCostoEmpresa, cargosEmpresa } = this.props;
      const {
        dataGrafico,
        cantidadEmbarque,
        cantidadDesembarque,
        dataDetalle,
        loading,
        modalDetalle    
      } = this.state;

      const config = {
        data: dataGrafico,
        padding: "auto",
        xField: "DiaActual",
        yField: "Personas",
        seriesField: 'name',
        legend: {
          layout: 'horizontal',
          position: 'bottom',
          marker: {
            symbol: 'circle'
          }
          // circle | square | line | diamond | triangle | triangle-down | hexagon | bowtie | cross | tick | plus | hyphen
        },

        theme: {
          colors10: [
            '#3CADF0',
            '#E64040',
          ]
        }
     
      };

      return (
      <>
        <Loading cargando={loading} />
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card
          className="card-style-dashboard card-title-dashboard"
          title={
          <>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={20}
                lg={20}
                xl={20}
              >
                Embarque y Desembarque
              </Col>
              <Col
                xs={24}
                sm={24}
                md={4}
                lg={4}
                xl={4}
                style={{ textAlign: "right" }}
              >
                <Button
                type="primary"
                className="btn-detalle-dashboard"
                onClick={() => {
                  this.detalle();
                }}
                >
                <span className="text-detalle-dashboard">Detalle</span>
                <span>
                  <EyeFilled className="iconoBotonDetalleCurvaRelevos" />
                </span>
                </Button>
              </Col>
            </Row>
          </>
          }
        >
          {/* FILTROS */}
          <Form
            className={"maven-r"}
            name="formEmbarqueDesembarque"
            onFinish={this.filtrar}
            ref={this.formRef}
          >
            <div>
              <p className={"label-filtros"} style={{ marginBottom: "10px" }}>
                Filtrar por:
              </p>
            </div>

            <Row gutter={20} style={{ marginBottom: "20px" }}>
              {/* FILTRO FECHA */}
              <Col
                xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                style={{ marginBottom: "15px" }}
              >
                <Form.Item name="fechaReporte" noStyle>
                  <DatePicker
                    format={"MMMM YYYY"}
                    locale={locale}
                    picker="month"
                    className={"select-style-dashboard"}
                    allowClear={false}
                    placeholder="Mes"
                  />
                </Form.Item>
              </Col>

              {/* FILTRO CC */}
              <Col
                xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                style={{ marginBottom: "15px" }}
              >
                <Form.Item name="centroDeCostoReporte" noStyle>
                  <Select
                    mode="multiple"
                    placeholder="Centro Costo"
                    className={"select-style-dashboard"}
                    allowClear={true}
                    maxTagCount={2}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {centrosCostoEmpresa.map((value, index) => (
                      <Select.Option key={index} value={value._id}>
                        {value.Nombre}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* FILTRO CARGO */}
              <Col
                xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                style={{ marginBottom: "15px" }}
              >
                <Form.Item name="cargoReporte" noStyle>
                  <Select
                    mode="multiple"
                    placeholder="Cargo"
                    className={"select-style-dashboard"}
                    allowClear={true}
                    maxTagCount={2}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cargosEmpresa.map((value, index) => (
                      <Select.Option key={index} value={value._id}>
                        {value.Nombre}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* BTN FILTRAR */}
              <Col
                xs={24} s={24} sm={5} md={5} lg={5} xl={5} xxl={3}
                className={"btn-col-filtrar-dashboard "}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  form="formEmbarqueDesembarque"
                  className={"btn-filtrar-dashboard"}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Form>

          {/* Grafico */}
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={4}>
              <Row>
                  <Col
                      xs={24} sm={10} md={10} lg={10} xl={24}
                      style={{
                          width: "169px",
                          height: "117px",
                          background: "#F4F4F4 0% 0% no-repeat padding-box",
                          boxShadow: "0px 3px 4px #00000029;",
                          borderRadius: "16px",
                          border: "1px solid #E2E2E2",
                          opacity: "1",
                          marginBottom:"25px"
                      }}
                  >
                      <h1 style={{textAlign:"center",fontFamily: "MavenPro-Bold",}}>Total Embarque</h1>
                      <p
                          style={{
                              textAlign: "center",
                              fontFamily: "MavenPro-Bold",
                              fontSize: "40px",
                              letterSpacing: "0px",
                              color: "#575757",
                              opacity: "1",
                          }}
                      >
                          {cantidadEmbarque}
                      </p>
                  </Col>
                  <Col xs={24} sm={2} md={2} lg={2} xl={24}></Col>
                  <Col
                      xs={24} sm={10} md={10} lg={10} xl={24}
                      style={{
                          width: "169px",
                          height: "117px",
                          background: "#F4F4F4 0% 0% no-repeat padding-box",
                          boxShadow: "0px 3px 4px #00000029;",
                          borderRadius: "16px",
                          border: "1px solid #E2E2E2",
                          opacity: "1"
                      }}
                  >
                      <h1 style={{textAlign:"center",fontFamily: "MavenPro-Bold",}}>Total Desembarque</h1>
                      <p
                          style={{
                              textAlign: "center",
                              fontFamily: "MavenPro-Bold",
                              fontSize: "40px",
                              letterSpacing: "0px",
                              color: "#575757",
                              opacity: "1",
                          }}
                      >
                        {cantidadDesembarque}
                      </p>
                  </Col>
              </Row>
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={1} style={{ marginBottom: "20px" }}></Col>
            <Col
              xs={24} sm={24} md={24} lg={24} xl={19}
              style={{
                width: "1397px",
                height: "418px",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "16px",
                border: "1px solid #E2E2E2",
                opacity: "1",
                padding: "10px"
              }}
            >
              <Line {...config} />
            </Col>
          </Row>
        </Card>
        </Col>

        <Modal
        visible={modalDetalle}
        onCancel={() => this.setState({ modalDetalle: false })}
        className={"modal-detalle-dashboard"}
        footer={null}
        closable={false}
        maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <Row  style={{ marginBottom: '20px' }}>
            <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
                <h3
                  className="modal-h3-dashboard"
                >
                  Detalle de embarque y desembarques
                </h3>
            </Col>
            {
              dataDetalle.length > 0 ? (
              <>
              <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{textAlign:"right"}}>
                <Button
                  type="primary"
                  className={'modal-btn-excel-dashboard'}
                  onClick={this.descargarExcelEmbarqueDesembarque}
                >
                  <span className="modal-btn-text-excel-dashboard">Descargar Excel</span>
                  <span><img src={Excel} className="modal-btn-icon-excel-dashboard" alt="Icono Excel"/></span>
                </Button>
                </Col>
              </>
              ) 
              : 
              (<></>)
            }
          </Row>

          <hr className="modal-division-detalle-dashboard" />

          {/* TABLE */}
          <Row>
            <Col className="modal-tabla-detalle-dashboard" span={24}>
              {/* TABLE */}
              <Table
                rowKey="_id"
                dataSource={dataDetalle}
                // loading={this.props.tablaCargando}
                bordered={true}
                showSorterTooltip={false}
                scroll={{ x: true }}
                pagination={{ pageSize: 7 }}
                className={"tabla-detalle-curva-relevos"}
              >
                <Column
                  title="Imagen"
                  key="Imagen"
                  align="center"
                  render={(value) => {
                    return (
                      <div>
                        <img src={value.ImagenTrabajador} className="imagen-tabla" alt="i" />
                      </div>
                    );
                  }}
                />

                <Column
                  sorter={(a, b) => {
                    return a.NombreCompleto.localeCompare(b.NombreCompleto);
                  }}
                  render={(value) => {
                    return value;
                  }}
                  title="Nombre Completo"
                  dataIndex="NombreCompleto"
                  key="NombreCompleto"
                  align="left"
                  ellipsis={true}
                />

                <Column
                  sorter={(a, b) => {
                    const quitarDigitoVerificadorA =
                      a.RutTrabajador !== undefined && a.RutTrabajador !== ""
                        ? a.RutTrabajador.split("-")
                        : "111";
                    const quitarDigitoVerificadorB =
                      b.RutTrabajador !== undefined && b.RutTrabajador !== ""
                        ? b.RutTrabajador.split("-")
                        : "111";

                    const rutA =
                      quitarDigitoVerificadorA !== "111"
                        ? quitarDigitoVerificadorA[0].split(".").join("")
                        : "111";
                    const rutB =
                      quitarDigitoVerificadorB !== "111"
                        ? quitarDigitoVerificadorB[0].split(".").join("")
                        : "111";

                    const numberFormatoA = parseInt(rutA, 10);
                    const numberFormatoB = parseInt(rutB, 10);

                    return numberFormatoA - numberFormatoB;
                  }}
                  title="Rut/DNI"
                  dataIndex="RutTrabajador"
                  key="Rut"
                  ellipsis={true}
                  render={(value) => (
                    <p style={{ whiteSpace: "nowrap", marginBottom: "0px" }}>
                      {value}
                    </p>
                  )}
                />
                <Column
                  sorter={(a, b) => {
                    let CentroCostoA = a.CentroCostoTratado !== undefined ? a.CentroCostoTratado : "aaaaa";
                    let CentroCostoB = b.CentroCostoTratado !== undefined ? b.CentroCostoTratado : "aaaaa";
                    return CentroCostoA.localeCompare(CentroCostoB);
                  }}

                  title="Centro Costo"
                  dataIndex="CentroCostoTratado"
                  key="CentroCostoTratado"
                  ellipsis={true}
                  render={(CentroCostoTratado) => {
                    if (CentroCostoTratado) {
                      return CentroCostoTratado;
                    } else {
                      return "N/A";
                    }
                  }}
                />
  
                <Column
                  sorter={(a, b) => {
                    let cargoA = a.CargoTratado !== undefined ? a.CargoTratado : "aaaaa";
                    let cargoB = b.CargoTratado !== undefined ? b.CargoTratado : "aaaaa";
                    return cargoA.localeCompare(cargoB);
                  }}
                  title="Cargo"
                  dataIndex="CargoTratado"
                  key="CargoTratado"
                  ellipsis={true}
                  render={(CargoTratado) => {
                    if (CargoTratado) {
                      return CargoTratado;
                    } else {
                      return "N/A";
                    }
                  }}
                />

                <Column
                  title="Fecha embarque"
                  dataIndex="FechaEmbarque"
                  key="FechaEmbarque"
                  ellipsis={true}
                  render={(FechaEmbarque) => {
                    return FechaEmbarque;
                  }}
                />

                <Column
                  title="Fecha desembarque"
                  dataIndex="FechaDesembarque"
                  key="FechaDesembarque"
                  ellipsis={true}
                  render={(FechaDesembarque) => {
                    return FechaDesembarque;
                  }}
                />

                {/* Ficha trabajador */}
                <Column
                  title="Detalle"
                  dataIndex="IdTrabajador"
                  key="FichaTrabajador"
                  align="center"
                  render={(value) => (
                    <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                      <Button
                        onClick={() => {
                          this.NuevaPestaña(value);
                        }}
                        className="acciones-trabajador-button hover-azul"
                        icon={<EyeFilled style={{ fontSize: '18px' }} />}
                      />
                    </Tooltip>
                  )}
                />

              </Table>
            </Col>
          </Row>
        </Modal>
      </>
      );
    }
}

export default DashboardEmbarqueDesembarque;
