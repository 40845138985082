import React, { Component,useState, useEffect } from "react";
import { each, groupBy } from '@antv/util';
import API from "../../Redux/Api/api";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import "moment/locale/es";
import { convertirFechaString } from "../../funcionesGenericas/fechas";
import Swal from "sweetalert2";
import { EyeFilled,CommentOutlined } from "@ant-design/icons";
import Excel from "../../img/icons-sidebar/blancas/IconExcel.svg";
import { Column } from '@ant-design/plots';
import Relevo from "../../img/icons/relevo.svg";
import Loading from "../../components/loading/Loading";

import {
  Row,
  Col,
  Card,
  Select,
  Button,
  DatePicker,
  Modal,
  Form,
  Table,
  Tooltip,
  Input
} from "antd";
const { Search } = Input;
const trabajoEfectivo = "5f3eedf56dd7cc57f0d3a261";
const licencia = "5f3eebf46dd7cc57f0d3a25d";
const licenciaMutual = "5f3eec676dd7cc57f0d3a25e";
const descanso = "5f3eeb78978b284b68ebced4";
const permiso = "5f3eec876dd7cc57f0d3a25f";
const ausente = "5f3eeca16dd7cc57f0d3a260";
const enTransito = "5f8f369e8efe155a48df37fb";
const vacaciones = "5f3eebac978b284b68ebced5";
const NoAsignado = "5f904101d9c57145a09eb058";

class DashboardReporteEstados extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      Empresa: "",
      dataRelevos: [], //DATA DEL GRAFICO
      cargoReporte: [],
      fechaReporte: moment(),
      dataPorAnio: [], //DATA DEL DETALLE DEL GRAFICO
      estados: [],
      fechaSeleccionada: moment().format("YYYY-MM-DD"),
      estadoSeleccionado: trabajoEfectivo,
      estadoSeleccionadoNombre: "trabajo efectivo",
      cargosSeleccionados: [],
      detalleEstadoMes: [],
      detalleEstadoMesCopia: [],
      modalDetallePorMes: false,
      mesAnioSeleccionado: false,
      habilitarGraficoNoAsignados: false,
      modalDetallePorMesNoAsignadoIneficiente: false,
      tituloPorMes: ""
    };
  }

  componentDidMount = async () => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      let strFecha = moment().format("YYYY-MM-DD");

      const startOfYear = moment(strFecha).startOf("year").format("YYYY-MM-DD");
      const endOfYear = moment(strFecha).endOf("year").format("YYYY-MM-DD");

      const estadoReporte = "5f3eedf56dd7cc57f0d3a261";

      let objetoEnviar = {
        fechaInicio: convertirFechaString(moment(startOfYear), 1).fechaString,
        fechaFin: convertirFechaString(moment(endOfYear), 2).fechaString,
        estadoReporte,
      };

      const dataReporteDeEstados = await this.obtenerReporteDeEstados(objetoEnviar, idEmpresa);
      const listaEstados = await this.obtenerEstadosTrabajador();
      // const estados = listaEstados.filter((estado) => estado.Nombre !== "No Asignado");
      const estados = listaEstados;
      const reporteEstadoPorAnio = dataReporteDeEstados.ReporteEstadoPorAnio;

      let formREF = this.formRef.current;
      formREF.setFieldsValue({ fechaReporte: moment() });

      this.setState({
        dataPorAnio: reporteEstadoPorAnio,
        Empresa: idEmpresa,
        estados: estados
      });
    }
  };

  obtenerEstadosTrabajador = async () => {
    try {
      const url = `${API}/TipoRegistroBitacoraTrabajador`;

      const respuesta = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then(async (response) => {
        
        const status = response.status;

        switch (status) {
          case 200:
          case 201:

            const { data } = await response.json();
            return data;
  
          default:
            const error = data.err.message.toString();
            console.error(error);
  
            return [];
        }

      });

      return respuesta;

    } catch (error) {
      console.error(error);
    }

  };

  //API DEL GRAFICO REPORTE DE ESTADOS (POR AÑO)
  obtenerReporteDeEstados = async (values, idEmpresa) => {
    try {
      const url = `${API}/ReporteDeEstados?empresa=${idEmpresa}`;

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(values),
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let reporte = data;
          return reporte;

        default:
          const error = data.err.message.toString();
          console.error(error);

          return [];
      }
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  obtenerDetalleEstadoDelMes = async (objetoEnviar, Empresa) => {
    try {
      
      const url = `${API}/ReporteDeEstados/DetallePorMes?empresa=${Empresa}`;

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objetoEnviar),
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:

          data = await response.json();
          return data;

        default:
          const error = data.err.message.toString();
          console.error(error);

          return [];
      }
      
    } catch (error) {
      console.log(error);
    }
  }

  //API REPORTE DE ESTADO DEL MES - NO ASIGNADO/POR ASIGNAR
  obtenerReporteEstadoDelMesNoAsignados = async (objetoEnviar, Empresa) => {
    try {
      
      const url = `${API}/ReporteDeEstados/Ineficiencia?empresa=${Empresa}`;

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objetoEnviar),
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:

          data = await response.json();
          return data;

        default:
          const error = data.err.message.toString();
          console.error(error);

          return [];
      }
      
    } catch (error) {
      console.log(error);
    }
  }

  //API DETALLE DE ESTADO DEL MES - NO ASIGNADO/POR ASIGNAR
  obtenerDetalleEstadoDelMesNoAsignados = async (objetoEnviar, Empresa) => {
    try {
      
      const url = `${API}/ReporteDeEstados/Ineficiencia/DetallePorMes?empresa=${Empresa}`;

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objetoEnviar),
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:

          data = await response.json();
          return data;

        default:
          const error = data.err.message.toString();
          console.error(error);

          return [];
      }
      
    } catch (error) {
      console.log(error);
    }
  }

  //BTN FILTRAR REPORTE DE ESTADO
  filtrarReporteEstados = async (values) => {
    this.setState({ loading: true, habilitarGraficoNoAsignados: false});
    const { Empresa } = this.state;
    const idEmpresa = Empresa;

    let strFecha = moment(values.fechaReporte).format("YYYY-MM-DD");
    const startOfYear = moment(strFecha).startOf("year").format("YYYY-MM-DD");
    const endOfYear = moment(strFecha).endOf("year").format("YYYY-MM-DD");

    let objetoEnviar = {
      ...values,
      fechaInicio: convertirFechaString(moment(startOfYear), 1).fechaString,
      fechaFin: convertirFechaString(moment(endOfYear), 2).fechaString,
    };

    //SI EL ESTADO ES NO ASIGNADO EL GRAFICO SE RELLENA CON OTRA API
    let dataReporteDeEstados;
    let reporteEstadoPorAnio;
    if(values.estadoReporte === NoAsignado){
      dataReporteDeEstados = await this.obtenerReporteEstadoDelMesNoAsignados(objetoEnviar, idEmpresa);
      reporteEstadoPorAnio = dataReporteDeEstados.ReporteEstadoPorAnio;
    }else{
      dataReporteDeEstados = await this.obtenerReporteDeEstados(objetoEnviar, idEmpresa);
      reporteEstadoPorAnio = dataReporteDeEstados.ReporteEstadoPorAnio;
    }
    //CODIGO ANTES DE LA LOGICA DE LOS INEFICIENTES
    // const dataReporteDeEstados = await this.obtenerReporteDeEstados(objetoEnviar, idEmpresa);
    // const reporteEstadoPorAnio = dataReporteDeEstados.ReporteEstadoPorAnio;

    let nombreEstado;

    switch (values.estadoReporte) {
      case trabajoEfectivo:
        nombreEstado = "trabajo efectivo";
        break;

      case licencia:
        nombreEstado = "licencia";
        break;  

      case licenciaMutual:
        nombreEstado = "licencia mutual";
        break;  
  
      case descanso:
        nombreEstado = "descanso";
        break;  

      case permiso:
        nombreEstado = "permiso";
        break;  
      
      case ausente:
        nombreEstado = "ausente";
        break;  

      case enTransito:
        nombreEstado = "en tránsito";
        break; 
        
      case vacaciones:
        nombreEstado = "vacaciones";
        break; 
      case NoAsignado:
        nombreEstado = "no asignados"
        this.setState({habilitarGraficoNoAsignados: true})
        break
      default:
        nombreEstado = "trabajo efectivo";
        break;
    }

    this.setState({
      loading: false,
      dataPorAnio: reporteEstadoPorAnio,
      estadoSeleccionado: values.estadoReporte ? values.estadoReporte : trabajoEfectivo,
      estadoSeleccionadoNombre: nombreEstado,
      cargosSeleccionados: values.cargoReporte,
      fechaSeleccionada: moment(values.fechaReporte).format("YYYY-MM-DD")
    });
  };

  detallePorMes = async (values) => {
    this.setState({ loading: true });
    const { estadoSeleccionado, cargosSeleccionados, Empresa } = this.state;

    const anioMesDia = values[0].data.anioMesDia;

    let fechaInicioFiltro ;
    let fechaFinFiltro;

    fechaInicioFiltro =  convertirFechaString(moment.utc(anioMesDia).startOf("month"), 1).fechaString;
    fechaFinFiltro = convertirFechaString(moment.utc(anioMesDia).endOf("month"), 2).fechaString;

    let objetoEnviar = {
      estadoReporte: estadoSeleccionado,
      cargoReporte: cargosSeleccionados,
      fechaInicio: fechaInicioFiltro,
      fechaFin: fechaFinFiltro
    };

    const { ReporteEstadoDetalleMes } = await this.obtenerDetalleEstadoDelMes(objetoEnviar, Empresa);
  
    this.setState({
      loading: false,
      detalleEstadoMes: ReporteEstadoDetalleMes,
      modalDetallePorMes: true,
      mesAnioSeleccionado: fechaInicioFiltro
    });

  }

  //DETALLE DEL GRAFICO NO ASIGNADO Y POR ASIGNAR
  detallePorMesNoAsignadoIneficiente = async (values) => {
    this.setState({ loading: true });

    const { cargosSeleccionados, Empresa } = this.state;
 
    let tituloMesDetalle = values[0].title;
    const anioMesDia = values[0].data.anioMesDia;
 
    let fechaInicioFiltro;
    let fechaFinFiltro;

    fechaInicioFiltro =  convertirFechaString(moment.utc(anioMesDia).startOf("month"), 1).fechaString;
    fechaFinFiltro = convertirFechaString(moment.utc(anioMesDia).endOf("month"), 2).fechaString;
    
    let objetoEnviar = {

      cargoReporte: cargosSeleccionados,
      fechaInicio: fechaInicioFiltro,
      fechaFin: fechaFinFiltro
    };

    const { ReporteEstadoDetalleMes } = await this.obtenerDetalleEstadoDelMesNoAsignados(objetoEnviar, Empresa);

    this.setState({
      loading: false,
      detalleEstadoMes: ReporteEstadoDetalleMes,
      detalleEstadoMesCopia: ReporteEstadoDetalleMes,
      modalDetallePorMesNoAsignadoIneficiente: true,
      mesAnioSeleccionado: fechaInicioFiltro,
      tituloPorMes: tituloMesDetalle
    });

  }
  //API DETALLE REPORTE DE ESTADOS
  obtenerDetalleReporteDeEstados = async (values, idEmpresa) => {
    try {
      const url = `${API}/ReporteDeEstados/Detalle?empresa=${idEmpresa}`;

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(values),
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let detalle = data.relevos;
          return detalle;

        default:
          const error = data.err.message.toString();
          console.error(error);

          return [];
      }
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  obtenerExcelDetalleMesEstado = async (dataExcel) => {
    try {

      const url = `${API}/ReporteDeEstados/DetalleMesEstado/DescargarExcel`;

      const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
          },
          body: JSON.stringify(dataExcel),
      });

      const status = await response.status;
      const excel = await response.blob();
      
      switch (status) {
        case 200:
        case 201:
          const url = window.URL.createObjectURL(excel);
          const a = document.createElement("a");
          a.href = url;
          a.download = `DetalleMesEstado.xlsx`;
          document.body.appendChild(a);
          a.click();
          a.remove();

          return;

        default:
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });

          return;
      }
        
    } catch (error) {

      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
      
      return;
    }
  }

  //Search BTN
  search = (value) => {
    const {detalleEstadoMes, detalleEstadoMesCopia} = this.state
    let busqueda = value.target.value;

    if (busqueda !== "") {
      busqueda = busqueda.toLowerCase();

      let datamodal = JSON.parse(JSON.stringify(detalleEstadoMes));

      let trabajadoresFiltros = datamodal.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(busqueda)
        )
      );

      this.setState({ detalleEstadoMes: trabajadoresFiltros });

    } else {

      this.setState({ detalleEstadoMes: detalleEstadoMesCopia });
      
    }
  };

  //BTN DESCARGAR EXCEL CURVA DE RELEVOS
  descargarExcelDetalleMesEstado = async () => {

    this.setState({ loading: true });
    let { detalleEstadoMes, estadoSeleccionado, mesAnioSeleccionado } = this.state;
    const arrayFechasCorregidas = [];

    if(detalleEstadoMes && detalleEstadoMes.length > 0){

      detalleEstadoMes.forEach(trabajador => {
        arrayFechasCorregidas.push({
          "NombreTrabajador": trabajador.NombreTrabajador,
          "Rut": trabajador.Rut,
          "Cargo": trabajador.Cargo,
          "Inicio_Planificacion": convertirFechaString(moment.utc(trabajador.Inicio_Planificacion), 1).fechaDDMMYYYY,
          "Fin_Planificacion": convertirFechaString(moment.utc(trabajador.Fin_Planificacion), 2).fechaDDMMYYYY,
        })
      });

      let nombreEstadoSeleccionado;

      switch (estadoSeleccionado) {
        case trabajoEfectivo:
          nombreEstadoSeleccionado = "Trabajo Efectivo";
          break;

        case licencia:
          nombreEstadoSeleccionado = "Licencia";
          break;

        case licenciaMutual:
          nombreEstadoSeleccionado = "Licencia Mutual";
          break;

        case descanso:
          nombreEstadoSeleccionado = "Descanso";
          break;

        case permiso:
          nombreEstadoSeleccionado = "Permiso";
          break;

        case ausente:
          nombreEstadoSeleccionado = "Ausente";
          break;

        case enTransito:
          nombreEstadoSeleccionado = "En tránsito";
          break;

        case vacaciones:
          nombreEstadoSeleccionado = "Vacacaciones";
          break;

        default:
          break;
      }

      let dataExcel = {
        estadoSeleccionado: nombreEstadoSeleccionado,
        detalleEstadoMes: arrayFechasCorregidas,
        mesAnioSeleccionado
      }

      let excelDescargado = await this.obtenerExcelDetalleMesEstado(dataExcel);
      this.setState({loading: false});

    }
  }

  NuevaPestaña = (idUsuario) => {
    //window.open("/CalendarioTrabajador?Trabajador=" + idUsuario);
    window.open("/CalendarioTrabajadorNew?Trabajador=" + idUsuario);
  };

  render() {
    let { cargosEmpresa } = this.props;

    const {
      loading,
      dataPorAnio,
      estados, 
      estadoSeleccionado,
      estadoSeleccionadoNombre,
      modalDetallePorMes,
      detalleEstadoMes,
      habilitarGraficoNoAsignados,
      modalDetallePorMesNoAsignadoIneficiente,
      tituloPorMes
    } = this.state;

    const config = {
      data: dataPorAnio,
      xField: 'mes',
      yField: 'cantidad',
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          // opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      color: () => {

        let colorGrafico;

        switch (estadoSeleccionado) {
          case trabajoEfectivo:
            colorGrafico = '#52D68F';
            break;

          case licencia:
            colorGrafico = '#5C8CDF';
            break;

          case licenciaMutual:
            colorGrafico = '#EFBF20';
            break;

          case descanso:
            colorGrafico = '#F296F5';
            break;

          case permiso:
            colorGrafico = '#EF7720';
            break;

          case ausente:
            colorGrafico = '#8B437D';
            break;

          case enTransito:
            colorGrafico = '#7C8DFC';
            break;

          case vacaciones:
            colorGrafico = '#3CADF0';
            break;

          default:
            colorGrafico = 'red';
            break;
        }

        return colorGrafico;
        
      },

      // meta: {
      //   type: {
      //     alias: '类别',
      //   },
      //   sales: {
      //     alias: '销售额',
      //   },
      // },
    };

    //GRAFICO NO ASIGNADO O POR ASIGNAR
    const DemoColumn = () => {

      const annotations = [];
      each(groupBy(dataPorAnio, 'mes'), (values, k) => {
        const value = values.reduce((a, b) => a + b.cantidadDias, 0);
        annotations.push({
          type: 'text',
          position: [k, value],
          content: `${value}`,
          style: {
            textAlign: 'center',
            fontSize: 14,
            fill: 'rgba(0,0,0,0.85)',
          },
          offsetY: -10,
        });
      });
     
      const config = {
        data: dataPorAnio,
        isStack: true,
        xField: 'mes',
        yField: 'cantidadDias',
        seriesField: 'type',
        label: {
          position: 'middle',
          layout: [
            {
              type: 'interval-adjust-position',
            },
            {
              type: 'interval-hide-overlap',
            },
            {
              type: 'adjust-color',
            },
          ],
          style: {
            fill: '#FFFFFF'
          },
        },
        annotations,
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },

        color: ({ type }) => {
          let colorGrafico;
          const typeNoAsigndo = "No Asignado";
          switch (type) {
            case typeNoAsigndo:
              colorGrafico = '#E64040';
              break;
            default:
              colorGrafico = 'grey';
              break;
          }
  
          return colorGrafico;
          
        },
      };

      return <Column {...config} 
        onReady={(plot)=>{
          plot.on('plot:click', (evt) => {
              const { x, y } = evt;
                const { xField } = plot.options;
                const tooltipData = plot.chart.getTooltipItems({ x, y });
                this.detallePorMesNoAsignadoIneficiente(tooltipData);
          });
        }} 
      />;
    };
    
    return (
      <>
        <Loading cargando={loading} />
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            className="card-style-dashboard card-title-dashboard"
            title={
              <>
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={20}
                    lg={20}
                    xl={20}
                  >
                    Reporte de estados
                  </Col>
                </Row>
              </>
            }
          >
            {/* FILTROS */}
            <Form
              className={"maven-r"}
              name="formReporteDeEstado"
              onFinish={this.filtrarReporteEstados}
              ref={this.formRef}
            >
              <div>
                <p className={"label-filtros"} style={{ marginBottom: "10px" }}>
                  Filtrar por:
                </p>
              </div>

              <Row gutter={20} style={{ marginBottom: "20px" }}>

                {/* FILTRO FECHA */}
                <Col
                  xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                  style={{ marginBottom: "15px" }}
                >
                  <Form.Item name="fechaReporte" noStyle>
                    <DatePicker
                      format={"YYYY"}
                      locale={locale}
                      picker="year"
                      // className={"select-reporte-utilizacion-relevo"}
                      className={"select-style-dashboard"}
                      allowClear={false}
                      placeholder="Mes"
                    />
                  </Form.Item>
                </Col>

                {/* FILTRO ESTADO */}
                <Col
                  xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                  style={{ marginBottom: "15px" }}
                >
                  <Form.Item name="estadoReporte" noStyle>
                    <Select
                      defaultValue="5f3eedf56dd7cc57f0d3a261"
                      placeholder="Estado"
                      className={"select-style-dashboard"}
                      allowClear={false}
                      maxTagCount={2}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {estados.map((value, index) => (
                        <Select.Option key={index} value={value._id}>
                          {value.Nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* FILTRO CARGO */}
                <Col
                  xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                  style={{ marginBottom: "15px" }}
                >
                  <Form.Item name="cargoReporte" noStyle>
                    <Select
                      mode="multiple"
                      placeholder="Cargo"
                      // className={"select-reporte-utilizacion-relevo"}
                      className={"select-style-dashboard"}
                      allowClear={true}
                      maxTagCount={2}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {cargosEmpresa.map((value, index) => (
                        <Select.Option key={index} value={value._id}>
                          {value.Nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* BTN FILTRAR */}
                <Col
                  xs={24} s={24} sm={5} md={5} lg={5} xl={5} xxl={3}
                  className={"btn-col-filtrar-dashboard "}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    form="formReporteDeEstado"
                    // className={"filtrar-contratos-btn"}
                    className={"btn-filtrar-dashboard"}
                  >
                    Filtrar
                  </Button>
                </Col>
              </Row>
            </Form>

            {/* GRAFICO */}
            <Card 
            // className="tarjeta-contenedora-curva-relevos"
              className={"card-curva-relevos-dashboard"}
            >
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <h3
                    style={{
                      textAlign: "left",
                      color: "#061FCB",
                      fontFamily: "MavenPro-Bold",
                      paddingTop: "10px",
                      paddingBottom: "3rem",
                    }}
                  >
                    {
                      habilitarGraficoNoAsignados === true ?
                      `Cantidad de dias ${estadoSeleccionadoNombre} por trabajador en el mes`
                      :
                      `Cantidad de trabajadores en estado ${estadoSeleccionadoNombre} por mes`
                    }
                    {/* Cantidad de trabajadores en estado {estadoSeleccionadoNombre} por mes */}
                  </h3>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  {
                    habilitarGraficoNoAsignados === true ?
                    <DemoColumn />
                    :
                    <Column {...config} onReady={(plot) => {
                      plot.on('plot:click', (evt) => {
                        const { x, y } = evt;
                        const { xField } = plot.options;
                        const tooltipData = plot.chart.getTooltipItems({ x, y });
                        this.detallePorMes(tooltipData);
                      });
                    }} />
                  }
              
                </Col>
              </Row>
            </Card>

          </Card>
        </Col>

        <Modal
          visible={modalDetallePorMes}
          onCancel={() => this.setState({ modalDetallePorMes: false })}
          className={"modal-detalle-dashboard"}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
              <h3 className="modal-h3-dashboard">
                  Detalle Por Mes
              </h3>
            </Col>

            {
              detalleEstadoMes.length > 0 ? (
              <>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{textAlign:"right"}}>
                  <Button
                    type="primary"
                    className={'modal-btn-excel-dashboard'}
                    onClick={this.descargarExcelDetalleMesEstado}
                  >
                    <span className="modal-btn-text-excel-dashboard">Descargar Excel</span>
                    <span><img src={Excel} className="modal-btn-icon-excel-dashboard" alt="Icono Excel"/></span>
                  </Button>
                </Col>
              </>
              ) 
              : 
              (<></>)
            }
          </Row>

          <hr className="modal-division-detalle-dashboard" />

          {/* TABLE */}
          <Table
            rowKey="_id"
            dataSource={detalleEstadoMes}
            loading={this.props.tablaCargando}
            bordered={true}
            showSorterTooltip={false}
            scroll={{ x: true }}
            pagination={{ pageSize: 7 }}
            className={"tabla-detalle-curva-relevos"}
          >

            <Column
              sorter={(a, b) => {
                return a.NombreTrabajador.localeCompare(b.NombreTrabajador);
              }}
              render={(value) => {
                return value;
              }}
              title="Nombre Completo"
              dataIndex="NombreTrabajador"
              key="NombreTrabajador"
              align="left"
              ellipsis={true}
            />

            <Column
              sorter={(a, b) => {
                const quitarDigitoVerificadorA =
                  a.Rut !== undefined && a.Rut !== ""
                    ? a.Rut.split("-")
                    : "111";
                const quitarDigitoVerificadorB =
                  b.Rut !== undefined && b.Rut !== ""
                    ? b.Rut.split("-")
                    : "111";

                const rutA =
                  quitarDigitoVerificadorA !== "111"
                    ? quitarDigitoVerificadorA[0].split(".").join("")
                    : "111";
                const rutB =
                  quitarDigitoVerificadorB !== "111"
                    ? quitarDigitoVerificadorB[0].split(".").join("")
                    : "111";

                const numberFormatoA = parseInt(rutA, 10);
                const numberFormatoB = parseInt(rutB, 10);

                return numberFormatoA - numberFormatoB;
              }}
              title="Rut/DNI"
              dataIndex="Rut"
              key="Rut"
              ellipsis={true}
              render={(value) => (
                <p style={{ whiteSpace: "nowrap", marginBottom: "0px" }}>
                  {value}
                </p>
              )}
            />

            <Column
              sorter={(a, b) => {
                let cargoA = a.Cargo !== undefined ? a.Cargo : "aaaaa";
                let cargoB = b.Cargo !== undefined ? b.Cargo : "aaaaa";
                return cargoA.localeCompare(cargoB);
              }}
              title="Cargo"
              dataIndex="Cargo"
              key="Cargo"
              ellipsis={true}
              render={(cargo) => {
                if (cargo) {
                  return cargo;
                } else {
                  return "N/A";
                }
              }}
            />

            <Column
              title="Fecha Inicio Planificación"
              dataIndex="Inicio_Planificacion"
              key="Inicio_Planificacion"
              ellipsis={true}
              render={(fechaInicioContrato) => {
                return convertirFechaString(moment.utc(fechaInicioContrato), 1)
                  .fechaDDMMYYYY;
              }}
            />

            <Column
              title="Fecha Fin Planificación"
              dataIndex="Fin_Planificacion"
              key="Fin_Planificacion"
              ellipsis={true}
              render={(fechaFinContrato) => {
                return convertirFechaString(moment.utc(fechaFinContrato), 1)
                  .fechaDDMMYYYY;
              }}
            />

            {/* Ficha trabajador */}
            <Column
              title="Detalle"
              dataIndex="IdTrabajador"
              key="FichaTrabajador"
              align="center"
              render={(value) => (
                <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                  <Button
                    onClick={() => {
                      this.NuevaPestaña(value);
                    }}
                    className="acciones-trabajador-button hover-azul"
                    icon={<EyeFilled style={{ fontSize: '18px' }} />}
                  />
                </Tooltip>
              )}
            />

          </Table>

        </Modal>
        
        <Modal
          visible={modalDetallePorMesNoAsignadoIneficiente}
          onCancel={() => this.setState({ modalDetallePorMesNoAsignadoIneficiente: false })}
          className={"modal-detalle-dashboard"}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
              <h3 className="modal-h3-dashboard">
                {
                  `Detalle dias no asignados o por asignar en el mes de ${tituloPorMes}`
                }
              </h3>
            </Col>
          </Row>

          <hr className="modal-division-detalle-dashboard"/>

          <Row style={{ padding: "6px 0px 22px" }} align="bottom">
            <Col className="div-input-search" xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Search
                placeholder="Buscar"
                enterButton
                onChange={(value) => this.search(value)}
              />
            </Col>
          </Row>
          {/* TABLE */}
          <Table
            rowKey="_id"
            dataSource={detalleEstadoMes}
            loading={this.props.tablaCargando}
            bordered={true}
            showSorterTooltip={false}
            scroll={{ x: true }}
            pagination={{ pageSize: 7 }}
            className={"tabla-detalle-curva-relevos"}
          >
         
            <Column
              sorter={(a, b) => {
                return a.NombreTrabajador.localeCompare(b.NombreTrabajador);
              }}
              title="Nombre Completo"
              dataIndex="NombreTrabajador"
              key="NombreTrabajador"
              align="left"
              ellipsis={true}
            />

            <Column
              sorter={(a, b) => {
                const quitarDigitoVerificadorA =
                  a.Rut !== undefined && a.Rut !== ""
                    ? a.Rut.split("-")
                    : "111";
                const quitarDigitoVerificadorB =
                  b.Rut !== undefined && b.Rut !== ""
                    ? b.Rut.split("-")
                    : "111";

                const rutA =
                  quitarDigitoVerificadorA !== "111"
                    ? quitarDigitoVerificadorA[0].split(".").join("")
                    : "111";
                const rutB =
                  quitarDigitoVerificadorB !== "111"
                    ? quitarDigitoVerificadorB[0].split(".").join("")
                    : "111";

                const numberFormatoA = parseInt(rutA, 10);
                const numberFormatoB = parseInt(rutB, 10);

                return numberFormatoA - numberFormatoB;
              }}
              title="Rut/DNI"
              dataIndex="Rut"
              key="Rut"
              ellipsis={true}
              render={(value) => (
                <p style={{ whiteSpace: "nowrap", marginBottom: "0px" }}>
                  {value}
                </p>
              )}
            />
     
            <Column
              sorter={(a, b) => {
                let cargoA = a.Cargo !== undefined ? a.Cargo : "aaaaa";
                let cargoB = b.Cargo !== undefined ? b.Cargo : "aaaaa";
                return cargoA.localeCompare(cargoB);
              }}
              title="Cargo"
              dataIndex="Cargo"
              key="Cargo"
              ellipsis={true}
              render={(cargo) => {
                if (cargo) {
                  return cargo;
                } else {
                  return "N/A";
                }
              }}
            />

            <Column
              sorter={(a, b) => {
                return new Date(b.Fecha) - new Date(a.Fecha);
              }}
              title="Fecha"
              dataIndex="Fecha"
              key="Fecha"
              ellipsis={true}
              render={(Fecha) => {
                return convertirFechaString(moment.utc(Fecha), 1)
                  .fechaDDMMYYYY;
              }}
            />

            <Column
              sorter={(a, b) => {
                return a.Estado_Necesario.localeCompare(b.Estado_Necesario);
              }}
              title="Estado por asignar"
              dataIndex="Estado_Necesario"
              key="Estado_Necesario"
              ellipsis={true}
              render={(Estado_Necesario) => {
                let colorEstado;

                switch (Estado_Necesario) {
                  case "Trabajo Efectivo":
                    colorEstado = '#52D68F';
                    break;
          
                  case "Licencia":
                      colorEstado = '#5C8CDF';
                    break;
          
                  case "Licencia Mutual":
                    colorEstado = '#EFBF20';
                    break;
          
                  case "Descanso":
                    colorEstado = '#F296F5';
                    break;
          
                  case "Permiso":
                    colorEstado = '#EF7720';
                    break;
          
                  case "Ausente":
                    colorEstado = '#8B437D';
                    break;
          
                  case "En Tránsito":
                    colorEstado = '#7C8DFC';
                    break;
          
                  case "Vacaciones":
                    colorEstado = '#3CADF0';
                    break;
          
                  default:
                    colorEstado = 'grey';
                    break;
                }
                
                return (<a style={{color: colorEstado, fontWeight:'bold'}}>{Estado_Necesario}</a>)
              }}
            />

            <Column
              title="Comentario"
              dataIndex="Comentario"
              key="Comentario"
              align="center"
              render={(value) => (

                <Tooltip title={value} color={'geekblue'}>
                  <Button
                    disabled={value === "" ? true: false}
                    style={{border: '1px', borderRadius:'12px', height:'34px',width:'fit-content'}}
                    icon={<CommentOutlined style={{ fontSize: '18px'}} />}
                  />
                 
                </Tooltip>
                
              )}
            />

            {/* Ficha trabajador */}
            <Column
              title="Detalle"
              dataIndex="IdTrabajador"
              key="FichaTrabajador"
              align="center"
              render={(value) => (
                <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                  <Button
                    onClick={() => {
                      this.NuevaPestaña(value);
                    }}
                    className="acciones-trabajador-button hover-azul"
                    icon={<EyeFilled style={{ fontSize: '18px' }} />}
                  />
                </Tooltip>
                
              )}
            />

          </Table>

        </Modal>

      </>
    );
  }
}

export default DashboardReporteEstados;
