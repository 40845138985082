//REDUCER MANTENEDOR USUARIO
export const LOADING = "ASIGNACION_FAENA_LOADING";
// export const PARAR_LOADING = "ASIGNACION_FAENA_LOADING_PARAR";
export const ERROR = "ASIGNACION_FAENA_ERROR";
export const OBTENER_CENTROSCOSTO = "ASIGNACION_FAENA_CENTROCOSTOS";
export const OBTENER_PLANTILLAS = "ASIGNACION_FAENA_PLANTILLAS";

export const INGRESAR_FAENA = "ASIGNACION_FAENA_INGRESAR";
export const LIMPIAR_ERROR_SUCCESS = "ASIGNACION_FAENA_LIMPIAR_ERROR_SUCCESS";
export const MOSTRAR_MODAL_FAENA = "ASIGNACION_FAENA_MOSTRAR_MODAL";
export const OCULTAR_MODAL_FAENA = "ASIGNACION_FAENA_OCULTAR_MODAL";
export const OBTENER_FAENAS_ID_CENTROCOSTO =
  "ASIGNACION_FAENA_OBTENER_FAENAS_ID_CENTROCOSTO";
export const OBTENER_FAENAS_ASIGNACION = "ASIGNACION_FAENAS_OBTENER_FAENAS";
export const OBTENER_ACTIVIDADES_Y_CANTIDAD_DE_REGISTROS = "OBTENER_ACTIVIDADES_Y_CANTIDAD_DE_REGISTRO";///Seba
export const MOSTRAR_TABLA = "ASIGNACION_FAENAS_MOSTRAR_TABLA";
export const TOMAR_VALORES_EDITAR = "ASIGNACION_USUARIO_TOMAR_VALORES_EDITAR";
export const FAENA_POPOVER = 'FAENA_POPOVER';
export const EDITAR_FAENAS_ASIGNACION = 'EDITAR_FAENAS_ASIGNACION';
export const CERRAR_EDITAR = 'CERRAR_EDITAR';
export const CLEAR_DATA = 'CLEAR_DATA';
// export const DESCARGAR_EXCEL = "MANTENEDOR_USUARIO_DESCARGAR_EXCEL";
