import {
  ERROR,
  INGRESAR_FAENA,
  LOADING,
  OBTENER_CENTROSCOSTO,
  OBTENER_PLANTILLAS,
  LIMPIAR_ERROR_SUCCESS,
  MOSTRAR_MODAL_FAENA,
  OCULTAR_MODAL_FAENA,
  OBTENER_FAENAS_ASIGNACION,
  TOMAR_VALORES_EDITAR,
  MOSTRAR_TABLA,
  OBTENER_FAENAS_ID_CENTROCOSTO,
  EDITAR_FAENAS_ASIGNACION,
  OBTENER_ACTIVIDADES_Y_CANTIDAD_DE_REGISTROS,//seba
  CERRAR_EDITAR,
  CLEAR_DATA
  // PARAR_LOADING,
} from "../types/asignacionFaenasTypes";

const INITIAL_STATE = {
  loading: false,
  centroscosto: [],
  plantillas: [],
  error: null,
  success: null,
  modalFaena: false,
  faenas: [],
  faena: null,
  tablaCargando: true,
  mostrarCalendario: false,
  faenasFechasCentroCosto: [],
  fechaDeshabilitada: true,
  contadores: null,
  faenaEditar: null,
  cantidadFaenas:0
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case INGRESAR_FAENA:
      return {
        ...state,
        loading: false,
        success: payload,
        modalFaena: false,
        tablaCargando: false,
      };
    case OBTENER_CENTROSCOSTO:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        centroscosto: payload,
      };
    case OBTENER_PLANTILLAS:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        plantillas: payload,
      };

    case TOMAR_VALORES_EDITAR:
      const { nombreCampo, valor } = payload;
      return {
        ...state,

        faena: {
          ...state.faena,
          [nombreCampo]: valor,
        },
      };
    case LOADING:
      return {
        ...state,
        loading: true,
        tablaCargando: true,
      };
    // case PARAR_LOADING:
    //   return {
    //     ...state,
    //     loading: false,
    //     tablaCargando: false,
    //   };
    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        tablaCargando: false,
      };
    case LIMPIAR_ERROR_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };
    case MOSTRAR_MODAL_FAENA:
      return {
        ...state,
        modalFaena: true,
      };
    case OBTENER_FAENAS_ASIGNACION:
      return {
        ...state,
        faenas: payload.nuevosItems,
        contadores: payload.contadores,
        centroscosto: payload.nuevosCC,
        loading: false,
        tablaCargando: false,
      };
      case OBTENER_ACTIVIDADES_Y_CANTIDAD_DE_REGISTROS:
        return {
          ...state,
          // faenas: payload.nuevosItems,
          // contadores: payload.contadores,
          //centroscosto: payload.nuevosCC,
          // loading: false,
          // tablaCargando: false,
          // centroscosto: payload.registros,//nf
          cantidadFaenas: payload.cantRegistros[0].myCount
        };
    case OBTENER_FAENAS_ID_CENTROCOSTO:
      return {
        ...state,
        faenasFechasCentroCosto: payload,
        loading: false,
        tablaCargando: false,
        fechaDeshabilitada: false,
      };

    case OCULTAR_MODAL_FAENA:
      return {
        ...state,
        modalFaena: false,
      };
    case MOSTRAR_TABLA:
      return {
        ...state,
        mostrarCalendario: true,
      };
    case EDITAR_FAENAS_ASIGNACION:
      return {
        ...state,
        mostrarCalendario: true,
        faenaEditar: payload,
        mostrarModalEditar: true,
        loading: false,

      };
    case CERRAR_EDITAR:
      return {
        ...state,
        mostrarModalEditar: false,
        loading: false,
      };

    case CLEAR_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};
