export const LOADING = "CHECKOUT_TRABAJADORES_LOADING";
export const PARAR_LOADING = "CHECKOUT_TRABAJADORES_LOADING_PARAR";

export const ERROR = "CHECKOUT_TRABAJADORES_ERROR";

export const LIMPIAR_ERROR_SUCCESS =
  "CHECKOUT_TRABAJADORES_LIMPIAR_ERROR_SUCCESS";

export const OBTENER_TRABAJADOR_ID_CENTROCOSTO =
  "CHECKOUT_TRABAJADORES_OBTENER_TRABAJADOR_ID_CENTROCOSTO";

export const OBTENER_TRABAJADORES = "CHECKOUT_TRABAJADORES_OBTENER_TRABAJADORES";
export const OBTENER_TRABAJADOR_ID =
  "CHECKOUT_TRABAJADORES_OBTENER_TRABAJADOR_ID";
export const OBTENER_CENTROCOSTOS = "CHECKOUT_TRABAJADORES_OBTENER_CENTROCOSTOS";
export const OBTENER_FAENA_ID = "CHECKOUT_TRABAJADORES_OBTENER_FAENA_ID";

export const MOSTRAR_MODAL_DESEMBARCO =
  "CHECKOUT_TRABAJADORES_MOSTRAR_MODAL_DESEMBARCO";

export const OCULTAR_MODAL_DESEMBARCO =
  "CHECKOUT_TRABAJADORES_OCULTAR_MODAL_DESEMBARCO";
export const OBTENER_FAENAS = "CHECKOUT_TRABAJADORES_OBTENER_FAENAS";

export const OBTENER_BITACORA_TRABAJADOR_CHECK_OUT = "OBTENER_BITACORA_TRABAJADOR_CHECK_OUT";