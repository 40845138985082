import {
    BUSCAR_TRABAJADORES_VACACIONES,
    ERROR_VACACIONES,
    INSERTAR_DATOS_FORMULARIO,
    INSERTAR_FECHAS_FORM,
    INSERTAR_FECHAS_FORM2,
    INSERTAR_TRABAJADOR_FORM,
    LOADING_VACACIONES,
    ENVIAR_CORREO,
    LOADING_VACACIONES_FALSE,
    BUSCAR_CONTRATOS_TRABAJADOR
} from '../types/vacacionTypes';

const INITIAL_VALUES = {
    trabajadores: [],
    err: null,
    loading: false,
    form: {
        Fechas: null,
        Fechas2: null,
        Trabajador: null,
        Correo: null,
    },
    contratos:[],
};

export default (state = INITIAL_VALUES, { type, payload }) => {
    switch (type) {
        case LOADING_VACACIONES:
            return {
                ...state,
                loading: true
            }
        case LOADING_VACACIONES_FALSE:
            return {
                ...state,
                loading: false
            }
        case INSERTAR_FECHAS_FORM:
            return {
                ...state,
                form: {
                    ...state.form,
                    Fechas: payload
                }
            }
        case INSERTAR_FECHAS_FORM2:
            console.log('llegue al reducer');
            console.log(payload);
            return {
                ...state,
                form: {
                    ...state.form,
                    Fechas2: payload
                }
            }
        case INSERTAR_TRABAJADOR_FORM:
            return {
                ...state,
                form: {
                    ...state.form,
                    Trabajador: payload
                }
            }
        case INSERTAR_DATOS_FORMULARIO:
            return {
                ...state,
                loading: false,
                err: null
            }
        case ERROR_VACACIONES:
            return {
                ...state,
                err: payload,
                loading: false
            }
        case BUSCAR_TRABAJADORES_VACACIONES:
            return {
                ...state,
                trabajadores: payload,
                loading: false,
                err: null
            }
        case ENVIAR_CORREO:
            return {
                ...state,
                form: {
                    ...state.form,
                    Correo: payload
                }
            }
        case BUSCAR_CONTRATOS_TRABAJADOR:
            return {
                ...state,
                contratos: payload,
                loading: false,
                err: null,
            };
        default:
            return state;
    }
};
