import API from "../../Redux/Api/api";

// GET DE PARÁMETROS SIMPLES //

export const obtenerCentroCostos = async (idEmpresa) => {

    try {
        const url = `${API}/CentroDeCostos?empresa=${idEmpresa}`;

        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });

        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let centroCostos = data.centrosDeCostos;
                return centroCostos;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }
    } catch (error) {
        console.error(error);
    }

    return [];

}

// export const obtenerPlazas = async (idEmpresa, idCentroCosto) => {
//     try {

//         const url = `${API}/Plazas?empresa_id=${idEmpresa}&centro_costo_id=${idCentroCosto}`;

//         const response = await fetch(url, {
//             method: "GET",
//             headers: {
//                 "Content-Type": "application/json",
//                 Accept: "application/json",
//             },
//         });

//         const status = response.status;
//         let data;

//         switch (status) {
//             case 200:
//             case 201:

//                 data = await response.json();
//                 let plazas = data;
//                 return plazas;

//             default:

//                 const error = data.err.message.toString();
//                 console.error(error);

//                 return [];
//         }

//     } catch (error) {
//         console.error(error);
//     }

//     return [];

// }

export const obtenerCargos = async (idEmpresa) => {
    try {

        const url = `${API}/CargosTrabajadores/ReporteDelDia?empresa=${idEmpresa}`;

        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });

        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let cargos = data.data;
                return cargos;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }

    } catch (error) {
        console.error(error);
    }

    return [];

}

export const obtenerReporteDelDia = async (idEmpresa, objFiltroReporte) => {

    try {

        const url = `${API}/Faena/ReporteDelDia?empresa=${idEmpresa}`;
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(objFiltroReporte),
        });
        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let reporteDelDia = data.faena;

                return reporteDelDia;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }

    } catch (error) {
        console.error(error);
    }

    return [];
}




export const obtenerReporteDelDiaDetalleRelevo = async (idEmpresa, objRelevo) => {
    try {
        const url = `${API}/Faena/ReporteDelDia/DetalleRelevo?empresa=${idEmpresa}`;
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(objRelevo),
        });
        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let dataDetalleRelevo = data.detalleRelevo;
                return dataDetalleRelevo;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }

    } catch (error) {
        console.error(error);
    }

    return [];
}

export const obtenerReporteDelDiaOtros = async (idEmpresa, values) => {

    try {

        const url = `${API}/Faena/ReporteDelDiaOtrosNueva?empresa=${idEmpresa}`;
        // const url = `${API}/Faena/ReporteDelDiaOtrosContratos?empresa=${idEmpresa}`;
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(values),
        });
        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let reporteDelDia = data.trabajadores;
                return reporteDelDia;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }

    } catch (error) {
        console.error(error);
    }

    return [];
}

