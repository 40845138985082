import React, { useEffect } from "react";
import CentroCosto from "../../img/icons/CentroCosto.svg";
import { Form, Input, Button, Card, Switch, Row, Col, PageHeader } from "antd";

import Swal from "sweetalert2";

const FormularioCentroCostoEditar = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {
    props.EditarCentroCosto(values);
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 9,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  useEffect(() => {
    let { Nombre, Empresa, Codigo_Base_Sistema, Vigencia } = props.Formulario;

    form.setFieldsValue({
      Nombre,
      Empresa,
      Codigo_Base_Sistema,
      Vigencia,
    });
  });

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={CentroCosto} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Editar centro de costo"
          />
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Form.Item
              name="Nombre"
              label="Nombre centro de costo"
              rules={[
                {
                  required: true,
                  message: "Debe introducir el nombre del centro de costo",
                  min: 2,
                  max: 20,
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Nombre", valor);
                }}
                maxLength={20}
                name="Nombre"
                className="campos"
              />
            </Form.Item>

            <Form.Item
              label="Código base sistema a conectar"
              name={"Codigo_Base_Sistema"}
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Codigo_Base_Sistema", valor);
                }}
                placeholder="Código base"
              />
            </Form.Item>
            <Form.Item
              style={{ textAlign: "left" }}
              label="Codigo del sistema"
              name={""}
            >
              <p style={{ fontSize: 18 }}>{props.Formulario._id}</p>
            </Form.Item>
            <Form.Item label="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                checked={props.Formulario.Vigencia}
                onChange={() => {
                  let nuevoValor =
                    props.Formulario.Vigencia === true ? false : true;
                  props.TomarValoresEditar("Vigencia", nuevoValor);
                }}
                className="switch"
              />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Editar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormularioCentroCostoEditar;
