import React, { Component } from "react";
//import avatar from "../../img/icons/avatar.svg";
// import IconDescanso from "../../img/icons/descanso.svg";

import IconDescanso from "../../img/icons-nuevos/Descanso.svg";

import {
  Row,
  Col,
  Layout,
  Card,
  Form,
  Button,
  Select,
  InputNumber,
  Modal,
  PageHeader,
  Radio,
  DatePicker,
  Popover,
  Tooltip,
  Input
} from "antd";
import "./descanso.css";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
//import TimeLineTrabajador from "../../components/timeLineTrabajador/TimeLineTrabajadorPopover2";
import TimeLineTrabajador from "../../components/timeLineTrabajador/TimeLineTrabajadorPopover3";
import embarcado from "../../img/icons/embarcado.svg";
import descanso from "../../img/icons/descanso.svg";


import { connect } from "react-redux";
import * as descansoActions from "../../Redux/actions/descansoActions";
import { traer_todos_las_fechas_trabajador_descansos_finiquitados } from "../../Redux/actions/descansoFiniquitadosActions";
import Swal from "sweetalert2";

import moment from "moment";
import accesosPagina from "../../funcionesGenericas/accesosUsuario";
import locale from "antd/lib/date-picker/locale/es_ES";

import {
  momentToStringFechaInicio,
  momentToStringFechaFin,
  databaseFormat,
} from "../../funcionesGenericas/fechas";

import { convertirFechaString, convertirFechaStringNueva } from "../../funcionesGenericas/fechas";

import { InfoCircleTwoTone } from "@ant-design/icons";

const { Header, Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;

class Descanso extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     mostrarCalendario: false,
  //     bitacora: [],
  //     idEmpresa: null,
  //     selectedContrato: [],
  //     selectedDates: [],
  //     selectedDatesTimeline: [],
  //     loading: false,
  //     contEstados: {
  //       TrabajoEfectivo: 0,
  //       Descanso: 0,
  //       Vacaciones: 0,
  //       EnTransito: 0,
  //       Licencia: 0,
  //       LicenciaMutualidad: 0,
  //       Permiso: 0,
  //       Ausente: 0,
  //       NoAsignado: 0,
  //       Vacio: 0,
  //     },
  //   };
  // }

  formRef = React.createRef();

  state = {
    bitacora:[],
    mostrarCalendario: false,
    imagen: null,
    LoadingForm: false,
    fechasBloqueadas: [],

    modalDescanso: false,
    idDescanso: null,

    Fecha_Inicio_Eliminar: null,
    Fecha_Fin_Eliminar: null,

    disabledInputs0: true,
    disabledInputs1: true,
    disabledInputs2: true,
    disabledInputs3: true,
    disabledInputs4: true,

    periodosTrabajo: [],
    periodosTrabajoBackup: [],
    periodosTrabajoJuntos: null,

    loading: true,

    fechasDescanso: null,
    selectMode: "",

    mostrarFormularioAdicional: true,
    mostrarDeseaCompensar: false,
    mostrarMontoCompensacion: false,

    docsDescansos: null,
    mostrarDetalleOtorgados: false,
    arrayDetalleOtorgados: null,

    ocultarFechasDescanso: false,
    mostrarInputsCompensacion: false,

    mostrarDetalleTrabajador: false,
    diasPendientesDescanso: null,
    diasDeTrabajo: null,

    mostrarFormularioCompensacion: false,
    mostrarDiaMontoCompensacion: false,

    mostrarInfoEfectivos: false,

    isOtorgadosValid: true,
    otorgadosMessage: "",

    ctdadPeriodosSeleccionados: 0,
  };

  onFinish = async (values) => {
  
    if (this.props.Trabajador) {
      let {
        bitacorasTrabajo,
        periodosTrabajo,
        periodosTrabajoJuntos,
        regimen,
        fechasDescanso,
      } = this.state;

      /* CÁLCULOS AUTOMÁTICOS */

      // cuando se unen periodos, se utiliza el array de periodosTrabajoJuntos
      let arrayAUtilizar = values.unirPeriodos
        ? periodosTrabajoJuntos
        : periodosTrabajo;

      let periodosSeleccionados = [];

      if (!values.unirPeriodos) values.Periodo = [values.Periodo];

      values.Periodo.forEach((indexPeriodoSeleccionado) => {
        periodosSeleccionados.push(arrayAUtilizar[indexPeriodoSeleccionado]);
      });

      let inicioTrabajoUnix =
        periodosSeleccionados[0].Inicio_Planificacion_Unix;
      let finTrabajoUnix =
        periodosSeleccionados[periodosSeleccionados.length - 1]
          .Fin_Planificacion_Unix;

      let { Compensados: compensados, Efectivos: efectivos } = values;

      // validación de otorgados
      if (!this.state.isOtorgadosValid) {
        await Swal.fire({
          title: "¡Ups!",
          text: `${this.state.otorgadosMessage}.`,
          icon: "error",
          confirmButtonColor: "#e60aa3",
          confirmButtonText: "Ok",
        });

        return;
      }

      // validación para no ingresar 0 días de descanso sin compensación
      if (efectivos === 0 && !values.DeseaCompensar) {
        await Swal.fire({
          title: "¡Ups!",
          text: `Si no va a compensar, debe ingresar al menos un día de descanso efectivo.`,
          icon: "error",
          confirmButtonColor: "#e60aa3",
          confirmButtonText: "Ok",
        });

        return;
      }

      // advertencia: está cerrando periodo a pesar de que le queda saldo pendiente
      if (values.Pendiente && values.cerrarPeriodo) {
        let cancelFlag = false;

        await Swal.fire({
          title: "Advertencia",
          text: `Vas a cerrar el periodo siendo que aún tiene saldo pendiente que utilizar, ¿desea continuar?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1FC600",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (!result.value) {
            cancelFlag = true;
          }
        });

        if (cancelFlag) {
          return;
        }
      }

      //advertencia: está dejando abierto el periodo con saldo pendiente 0
      if (values.Pendiente === 0 && !values.cerrarPeriodo) {
        let cancelFlag = false;

        await Swal.fire({
          title: "Advertencia",
          text: `Estás dejando abierto un periodo que ya no tiene saldo pendiente, ¿desea continuar?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1FC600",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (!result.value) {
            cancelFlag = true;
          }
        });

        if (cancelFlag) {
          return;
        }
      }

      let esSoloDescanso, esAmbos, esSoloCompensacion;

      // Hay 3 casos posibles:
      // - Se ingresa sólo descanso efectivo (Compensados en 0)
      // - Se ingresa descanso efectivo y compensación (Efectivos y Compensados mayores que 0)
      // - Se ingresa sólo compensación (Efectivos en 0)
      if (
        compensados === 0 &&
        compensados == undefined &&
        compensados == null
      ) {
        esSoloDescanso = true;
      } else if (compensados > 0 && efectivos > 0) {
        esAmbos = true;
      } else if (efectivos === 0) {
        esSoloCompensacion = true;
      }

      let IdsBitacorasTrabajo;
      let fecha1;
      let fecha2;

      IdsBitacorasTrabajo = [];

      bitacorasTrabajo.forEach((bitacoraTrabajo) => {
        let inicioPlanificacionBitacora =
          bitacoraTrabajo.Inicio_Planificacion_Unix;
        let finPlanificacionBitacora = bitacoraTrabajo.Fin_Planificacion_Unix;
        let fechaUnixBitacora = bitacoraTrabajo.FechaUnix;

        // si pertenecen al periodo, agregar _id a array
        periodosSeleccionados.forEach((periodo) => {
          if (
            fechaUnixBitacora >= periodo.Inicio_Planificacion_Unix &&
            fechaUnixBitacora <= periodo.Fin_Planificacion_Unix
          ) {
            IdsBitacorasTrabajo.push(bitacoraTrabajo._id);
          }
        });
      });

      if (!esSoloCompensacion) {
        fecha1 = moment.utc(
          momentToStringFechaInicio(moment(fechasDescanso[0]), databaseFormat)
        );
        fecha2 = moment.utc(
          momentToStringFechaFin(moment(fechasDescanso[1]), databaseFormat)
        );

        values.Fechas = [fecha1, fecha2];

        values.IdsBitacorasTrabajo = IdsBitacorasTrabajo;
      }

      /* FIN CÁLCULOS AUTOMÁTICOS */

      //se valida que compencación sea false o que las fechas de inicio y fin no sean undefined
      if (!esSoloCompensacion || (fecha1 !== undefined && fecha2 !== undefined)) {
        //VALIDACIÓN DE WARNING
        let listaFechas = [];


        //se valida que solo los dias de que contengan trabajo se validen
        await this.state.fechasBloqueadas.forEach((bitacora) => {
          listaFechas.push(moment.utc(bitacora.Fecha));
        });

        let warningFlag = false;
        let confirmFlag = false;

        await listaFechas.forEach(async (fecha) => {
          if (await fecha.isBetween(fecha1, fecha2)) {
            warningFlag = true;
            return;
          }
        });

        if (warningFlag) {
          await Swal.fire({
            title: "Advertencia",
            text: `Las fechas que seleccionaste ya tienen planificación, ¿deseas continuar?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1FC600",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (!result.value) {
              confirmFlag = true;
            }
          });

          if (confirmFlag) {
            return;
          }
        }
      }

      values.DiasTrabajoExceso = values.Exceso;

      if (values.Otorgados > values.Sugeridos) {
        values.DiasDescansoExceso = values.Otorgados - values.Sugeridos;
      } else {
        values.DiasDescansoExceso = 0;
      }

      values.RegimenTrabajoActual = regimen;

      // si se compensó el saldo pendiente, quedará en 0
      // if (values.compensarSaldoPendiente) {
      //   values.Pendiente = 0;
      // }

      //siempre que llegue con saldo pendiente 0, el periodo se dará por cerrado
      // if (values.Pendiente === 0) {
      //   values.cerrarPeriodo = true;
      // }

      let {
        LaboradosRegimen,
        Exceso,
        Laborados,

        Sugeridos,
        Otorgados,
        Efectivos,

        Fechas,

        DeseaCompensar,
        Compensados,
        montoCompensacion,

        Habiles,
        Inhabiles,
        Pendiente,

        cerrarPeriodo,

        DiasTrabajoExceso,
        DiasDescansoExceso,

        unirPeriodos,

        Observacion
      } = values;
      
      //desde este punto comienza a ingresarse los datos
      if (esSoloCompensacion) {
        const objDescanso = {
          IdTrabajador: this.props.Trabajador,

          LaboradosRegimen: LaboradosRegimen,
          ExcesoEmbarque: Exceso,
          DiasLaborados: Laborados,

          DiasSugeridos: Sugeridos,
          DiasOtorgados: Otorgados,
          DiasEfectivos: Efectivos,

          SeCompensa: DeseaCompensar,
          DiasCompensados: Compensados,
          MontoCompensacion: montoCompensacion,

          // en éste caso hábiles e inhábiles siempre llegarán en 0
          DiasHabiles: Habiles,
          DiasInhabiles: Inhabiles,
          SaldoPendiente: Pendiente,

          IdsBitacorasTrabajo: IdsBitacorasTrabajo,
          DiasTrabajoExceso,
          DiasDescansoExceso,

          RegimenTrabajoActual: regimen,

          PeriodoCerrado: cerrarPeriodo,

          // éste es el caso en el que DescansoSoloCompensacion va en true
          DescansoSoloCompensacion: true,
          PeriodosUnidos: unirPeriodos,

          Inicio_Trabajo_Unix: inicioTrabajoUnix,
          Fin_Trabajo_Unix: finTrabajoUnix,

          Observacion: Observacion
        };
        
        await this.props.insertarCompensacion(objDescanso);
      } else {
        const objDescanso = {
          IdTrabajador: this.props.Trabajador,

          LaboradosRegimen: LaboradosRegimen,
          ExcesoEmbarque: Exceso,
          DiasLaborados: Laborados,

          DiasSugeridos: Sugeridos,
          DiasOtorgados: Otorgados,
          DiasEfectivos: Efectivos,

          Fecha_Inicio: momentToStringFechaInicio(
            fecha1.set({ hour: 12 }),
            databaseFormat
          ),
          Fecha_Fin: momentToStringFechaFin(
            fecha2.set({ hour: 12 }),
            databaseFormat
          ),

          SeCompensa: DeseaCompensar == undefined ? false : DeseaCompensar,
          DiasCompensados: Compensados,
          MontoCompensacion: montoCompensacion,

          DiasHabiles: Habiles,
          DiasInhabiles: Inhabiles,
          SaldoPendiente: Pendiente,

          IdsBitacorasTrabajo: IdsBitacorasTrabajo,
          DiasTrabajoExceso: DiasTrabajoExceso,
          DiasDescansoExceso: DiasDescansoExceso,
          RegimenTrabajoActual: regimen,

          PeriodoCerrado: cerrarPeriodo,

          // en este caso DescansoSoloCompensacion siempre va false porque se ingresó DíasEfectivos
          DescansoSoloCompensacion: false,

          PeriodosUnidos: unirPeriodos,

          Inicio_Trabajo_Unix: inicioTrabajoUnix,
          Fin_Trabajo_Unix: finTrabajoUnix,

          Observacion: Observacion
        };
       
        await this.props.insertarDatosAvanzado(objDescanso, Fechas);
      }

      // let response = await this.props.traer_todos_las_fechas_trabajador(
      //   this.props.Trabajador
      // );

      // let fechas = [];

      // response.forEach((element) => {
      //   fechas.push(element.Fecha);
      // });

      // const responseDFiniquitados =
      //   await traer_todos_las_fechas_trabajador_descansos_finiquitados(
      //     this.props.Trabajador
      //   );

      // responseDFiniquitados.forEach((finiquito) => {
      //   let newFiniquito = { ...finiquito };
      //   newFiniquito.finiquitoDescanso = true;
      //   newFiniquito.TipoRegistroBitacoraTrabajador = "descansoFiniquitado";
      //   response.push(newFiniquito);
      // });

      this.reiniciarFormulario({
        bitacora: {},
        mostrarCalendario: true,
      });
    }
  };

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      await this.props.traer_todos_los_trabajadores(idEmpresa);
      let feriados = await this.props.obtenerFeriados(idEmpresa);

      const accesos = await accesosPagina.obtenerAccesosPagina(
        "ProcesosDescanso"
      );

      this.setState({
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
        feriados,
        loading: false,
        idEmpresa: idEmpresa,
      });
    }
  }

  //DESDE AHORA CUANDO SE ESCOGE EL CONTRATO SE TOMARAN LOS DATOS
  //PARA INGRESAR LOS DATOS
  handleContrato = async (idContrato) => {

    let contratos = this.props.contratos.filter((contrato) => contrato._id.toString() === idContrato.toString())[0];
    let busquedaContrato = idContrato;

    if(contratos.Ids_Contratos){
      busquedaContrato = contratos.Ids_Contratos.join(',');
    }

    const idTrabajador = this.props.Trabajador;

    let user = this.props.trabajadores.find((x) => x._id === idTrabajador);
    if (user) {
      this.setState({
        imagen: user.Imagen,
        nombre: user.Nombre,
        loading: true,
      });

      //SE OBTIENE LAS BITACORAS QUE SE MOSTRARAN
      let bitacorasMostrar =await this.props.traer_todos_las_fechas_trabajador_por_contrato(idTrabajador,busquedaContrato);

      //SE OBTIENE TODOS LOS PERIODOS DE TRABAJO DE LA PERSONA
      let trabajoRealizado = await this.props.traer_todos_las_bitacoras_trabajo_trabajador_por_contrato(idTrabajador,busquedaContrato);
      //documentos de descansos
      let docsDescansos = await this.props.obtenerDocsDescansos(idTrabajador);
      if (docsDescansos == undefined) docsDescansos = [];

       /*** CÁLCULO PERIODOS DE TRABAJO ***/
       let bitacorasTrabajo = [];

       // filtrando sólo bitácoras de trabajo
       trabajoRealizado.forEach((bitacora) => {
         let esTrabajo = bitacora.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261";
         // si es trabajo agregar
         if (esTrabajo) {
           bitacorasTrabajo.push(bitacora);
         }
       });
       

      let periodosTrabajo = [];
      // filtrando para que quede una bitácora por periodo
      bitacorasTrabajo.forEach((bitacora) => {
        let cantidadEncontrada = periodosTrabajo.filter(
          (proceso) =>
            moment(proceso.Inicio_Planificacion).format("YYYY-MM-DD") ===
              moment(bitacora.Inicio_Planificacion).format("YYYY-MM-DD") &&
            moment(proceso.Fin_Planificacion).format("YYYY-MM-DD") ===
              moment(bitacora.Fin_Planificacion).format("YYYY-MM-DD") &&
            proceso.TipoRegistroBitacoraTrabajador.toString() ===
              bitacora.TipoRegistroBitacoraTrabajador.toString()
        );

        if (cantidadEncontrada.length === 0) {
          periodosTrabajo.push(bitacora);
        }
      });

      let regimen = user.RegimenDeTrabajo;

      periodosTrabajo = periodosTrabajo.filter((bitacoraPeriodo) => {
        let incluir = true;

        docsDescansos.forEach((doc) => {
          if (doc.IdsBitacorasTrabajo.includes(bitacoraPeriodo._id)) {
            if (doc.PeriodoCerrado) {
              incluir = false;
            }
          }
        });

        return incluir;
      });

      // ordenando periodos: de más antiguo a más nuevo
      periodosTrabajo.sort((a, b) => {
        return a.Inicio_Planificacion_Unix - b.Inicio_Planificacion_Unix;
      });

      
      // transformando: los periodos que hayan sido unidos anteriormente, deberán ser tratados como uno,
      // para esto se modifica la fecha de Fin_Planificacion_Unix
      docsDescansos.forEach((doc) => {
        // si ctdadPeriodosEncontrados es mayor a 1, significa que dichos periodos encontrados están unidos
        let ctdadPeriodosEncontrados = 0;
        let indexesPeriodosJuntosDocumento = [];

        periodosTrabajo.forEach((bitacoraPeriodo, index) => {
          if (doc.IdsBitacorasTrabajo.includes(bitacoraPeriodo._id)) {
            ctdadPeriodosEncontrados++;
            indexesPeriodosJuntosDocumento.push(index);
          }
        });

        if (ctdadPeriodosEncontrados > 1) {
          let nuevoPeriodosTrabajo = [];

          periodosTrabajo.forEach((bitacoraPeriodo, index) => {
            if (indexesPeriodosJuntosDocumento.includes(index)) {
              // si es el primero de los que se encontraron que eran unidos
              if (index === indexesPeriodosJuntosDocumento[0]) {
                let bitacoraClone = { ...bitacoraPeriodo };

                let indexUltimoPeriodoUnion =
                  indexesPeriodosJuntosDocumento[
                    indexesPeriodosJuntosDocumento.length - 1
                  ];

                let ultimoPeriodoUnion =
                  periodosTrabajo[indexUltimoPeriodoUnion];

                bitacoraClone.Fin_Planificacion_Unix =
                  ultimoPeriodoUnion.Fin_Planificacion_Unix;

                nuevoPeriodosTrabajo.push(bitacoraClone);
              }
            } else {
              nuevoPeriodosTrabajo.push(bitacoraPeriodo);
            }
          });

          periodosTrabajo = nuevoPeriodosTrabajo;
        }
      });

      let diasPendientesDescanso = 0;
      let diasDeTrabajo = 0;

      periodosTrabajo.forEach((bitacoraPeriodo) => {

        let inicio = moment.utc(bitacoraPeriodo.Inicio_Planificacion);
        let fin = moment.utc(bitacoraPeriodo.Fin_Planificacion);
        //codigo antiguo
        // let inicio = moment.unix(bitacoraPeriodo.Inicio_Planificacion_Unix);
        // let fin = moment.unix(bitacoraPeriodo.Fin_Planificacion_Unix);

        let ctdadDiasPeriodo = fin.diff(inicio, "days") + 1;


        // diasDeTrabajo es la suma de los días de todos los periodos
        diasDeTrabajo += ctdadDiasPeriodo;

        // si el periodo tiene un doc de descanso, utilizar el régimen de ese, si no, utilizar el actual del trabajador
        let regimenUtilizar = regimen;
        let diasUtilizadosPeriodo = 0;

        // cuando se le hayan ingresado días anteriormente al periodo, utilizaremos los que se le otorgaron la primera vez
        let primerSaldoOtorgado;
        let usarSaldoFlag = true;

        docsDescansos.forEach((doc) => {
          if (doc.IdsBitacorasTrabajo.includes(bitacoraPeriodo._id)) {
            regimenUtilizar = doc.RegimenTrabajoActual;

            let efectivos = doc.DiasEfectivos || 0;
            let compensados = doc.DiasCompensados || 0;

            diasUtilizadosPeriodo += efectivos + compensados;

            // para que use el saldo otorgado de el primer doc
            if (usarSaldoFlag) {
              primerSaldoOtorgado = doc.DiasOtorgados;
              usarSaldoFlag = false;
            }
          }
        });

        let {
          Cantidad_Dias_Trabajo: regimenTrabajo,
          Cantidad_Dias_Descanso: regimenDescanso,
        } = regimenUtilizar;

        let descansoSugeridoInicial =
          (regimenDescanso * ctdadDiasPeriodo) / regimenTrabajo -
          diasUtilizadosPeriodo;

        // cuando haya primerSaldoOtorgado (lo que significa que se le ha ingresado días anteriormente al periodo)
        // se hará el cálculo según los otorgados por el usuario, no los calculados según régimen
        let descansoSugerido = primerSaldoOtorgado
          ? primerSaldoOtorgado - diasUtilizadosPeriodo
          : descansoSugeridoInicial;

        diasPendientesDescanso += descansoSugerido;
      });
      // console.log({diasPendientesDescanso,diasDeTrabajo})
      const responseDFiniquitados =
        await traer_todos_las_fechas_trabajador_descansos_finiquitados(
          idTrabajador
        );
      
      //HAY QUE PENSAR LA LOGICA NUEVAMENTE
      responseDFiniquitados.forEach((finiquito) => {
        let newFiniquito = { ...finiquito };
        newFiniquito.finiquitoDescanso = true;
        newFiniquito.TipoRegistroBitacoraTrabajador = "descansoFiniquitado";
        //bitacorasMostrar.bitacoras.push(newFiniquito);
      });

      let arrayIniciosDeMes = [];

      let fechaInicioOriginal = convertirFechaString(moment.utc(bitacorasMostrar.inicioFechaRangoBusqueda), 1).fechaString;
      let fechaInicioString = convertirFechaString(moment.utc(bitacorasMostrar.inicioFechaRangoBusqueda).startOf("month"), 1).fechaString;
      let fechaFinString = convertirFechaString(moment.utc(bitacorasMostrar.FinFechaRangoBusqueda).endOf("month"), 2).fechaString;
      let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;

      for (let index = 0; index < diferenciaMeses; index++) {
        let fechaAgregar;

        if(index === 0){
          if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
            fechaAgregar = moment.utc(fechaInicioOriginal);
          } else {
            fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
          }
        } else {
          fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
        }

        arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
      }

      this.formRef.current.resetFields([
        "Periodo",

        "LaboradosRegimen",
        "Exceso",
        "Laborados",

        "Sugeridos",
        "Otorgados",
        "Efectivos",

        "Fechas",

        "DeseaCompensar",
        "Compensados",
        "montoCompensacion",

        "Habiles",
        "Inhabiles",
        "Pendiente",

        "cerrarPeriodo",

        "Correo",

        //"unirPeriodos"
        "Observacion",
      ]);

      this.formRef.current.setFieldsValue({
        unirPeriodos: false,
      });
      
      await this.setState({
        fechasBloqueadas: bitacorasMostrar.bitNoModificar,
        bitacora: bitacorasMostrar.bitacoras,
        selectedContrato: [bitacorasMostrar.InicioContrato, bitacorasMostrar.FinContrato],
        selectedDates: [
          bitacorasMostrar.inicioFechaRangoBusqueda,
          bitacorasMostrar.FinFechaRangoBusqueda,
        ],
        selectedDatesTimeline: [
          bitacorasMostrar.inicioFechaRangoBusqueda,
          bitacorasMostrar.FinFechaRangoBusqueda,
        ],
        firstDaysOfTheMonths: arrayIniciosDeMes,
        contEstados: bitacorasMostrar.contEstados,
        mostrarCalendario: true,
        loading: false,

        regimen,
        bitacorasTrabajo,
        //NUEVOS VALORES NECESARIOS PARA LA FUNCIONALIDAD
        docsDescansos,
        diasPendientesDescanso,
        diasDeTrabajo,
        periodosTrabajo,
        periodosTrabajoBackup: periodosTrabajo,

        mostrarDetalleTrabajador: true,
        disabledInputs0: false,
        fechasDescanso: null,
        disabledInputs1: true,
        disabledInputs2: true,
        disabledInputs3: true,
        disabledInputs4: true,

        periodosJuntos: null,

        mostrarDeseaCompensar: false,
        mostrarMontoCompensacion: false,

        mostrarDetalleOtorgados: false,
        arrayDetalleOtorgados: null,

        mostrarInputsCompensacion: false,

        mostrarFormularioCompensacion: false,
        mostrarDiaMontoCompensacion: false,

        mostrarInfoEfectivos: false,

        isOtorgadosValid: true,
        otorgadosMessage: "",

        periodosTrabajoJuntos: null,
        ctdadPeriodosSeleccionados: 0,
      });

      await this.formRef.current.resetFields(["Fechas"]);
    }
  };

  //se agrega la nueva para obtener los datos
  handleChangeNueva = async (idTrabajador) => {
    let trabajador = this.props.trabajadores.find(
      (x) => x._id === idTrabajador
    );
    if (trabajador) {
      const { idEmpresa } = this.state;

      //se setean valores del trabajador seleccionado
      this.setState({
        imagen: trabajador.Imagen,
        cargo: trabajador.Cargo,
        nombre: trabajador.Nombre,
        fechasBloqueadas: [],
        bitacora: [],
        selectedContrato: [null, null],
        selectedDates: [null, null],
        mostrarCalendario: false,
        loading: true,
      });

      //se setea el id del trabajador en redux
      await this.props.insertarTrabajador(idTrabajador);

      //se resetean los valores del form
      await this.formRef.current.resetFields([
        "Periodo",
        "LaboradosRegimen",
        "Exceso",
        "Laborados",
        "Sugeridos",
        "Otorgados",
        "Efectivos",
        "Fechas",
        "DeseaCompensar",
        "Compensados",
        "montoCompensacion",
        "Habiles",
        "Inhabiles",
        "Pendiente",
        "cerrarPeriodo",
        "Correo",
        "contrato",
        "Observacion",
      ]);

      //obtenemos todos los contratos del trabajador
      await this.props.traer_todos_contratos_trabajador(idTrabajador, idEmpresa);

      //se obtienen las bitacoras historicas del trabaajdor
      const newBitacorasHistorico = await this.props.traer_todos_los_descansos_historicos(idTrabajador);


      let datosSet ={
        //datos del formulario
        mostrarDetalleTrabajador: false,
        //diasPendientesDescanso, dato que falta
       // diasDeTrabajo, dato que falta ingresar

        fechasDescanso: null,
        disabledInputs1: true,
        disabledInputs2: true,
        disabledInputs3: true,
        disabledInputs4: true,

        periodosJuntos: null,

        mostrarDeseaCompensar: false,
        mostrarMontoCompensacion: false,

        mostrarDetalleOtorgados: false,
        arrayDetalleOtorgados: null,

        mostrarInputsCompensacion: false,

        mostrarFormularioCompensacion: false,
        mostrarDiaMontoCompensacion: false,

        mostrarInfoEfectivos: false,

        isOtorgadosValid: true,
        otorgadosMessage: "",

        periodosTrabajoJuntos: null,
        ctdadPeriodosSeleccionados: 0,
      }

      //si bitacoras existen se ingresaran a bitacoras historicas
      if (newBitacorasHistorico !== null) {
        datosSet = {
          ...datosSet,
          fechasHistoricasDescanso: newBitacorasHistorico,
          loading: false,
        }
      } else {
        datosSet = {
          ...datosSet,
          fechasHistoricasDescanso: [],
          loading: false,
        }
      }

      this.setState(datosSet);
    }
  };

  // handleChangeTrabajador = async (idTrabajador) => {
  //   let trabajador = this.props.trabajadores.find(
  //     (x) => x._id === idTrabajador
  //   );

  //   if (trabajador) {
  //     // this.setState({
       
  //     // });

  //     await this.props.insertarTrabajador(idTrabajador);

  //     let response = await this.props.traer_todos_las_fechas_trabajador(
  //       idTrabajador
  //     );
  //     let docsDescansos = await this.props.obtenerDocsDescansos(idTrabajador);

  //     if (docsDescansos == undefined) docsDescansos = [];

  //     let fechas = [];

  //     /*** CÁLCULO PERIODOS DE TRABAJO ***/

  //     let bitacorasTrabajo = [];

  //     // filtrando sólo bitácoras de trabajo
  //     response.forEach((bitacora) => {
  //       fechas.push(bitacora.Fecha);

  //       let esTrabajo =
  //         bitacora.TipoRegistroBitacoraTrabajador ===
  //         "5f3eedf56dd7cc57f0d3a261";

  //       // si es trabajo agregar
  //       if (esTrabajo) {
  //         bitacorasTrabajo.push(bitacora);
  //       }
  //     });

  //     let periodosTrabajo = [];

  //     // filtrando para que quede una bitácora por periodo
  //     bitacorasTrabajo.forEach((bitacora) => {
  //       let cantidadEncontrada = periodosTrabajo.filter(
  //         (proceso) =>
  //           moment(proceso.Inicio_Planificacion).format("YYYY-MM-DD") ===
  //             moment(bitacora.Inicio_Planificacion).format("YYYY-MM-DD") &&
  //           moment(proceso.Fin_Planificacion).format("YYYY-MM-DD") ===
  //             moment(bitacora.Fin_Planificacion).format("YYYY-MM-DD") &&
  //           proceso.TipoRegistroBitacoraTrabajador.toString() ===
  //             bitacora.TipoRegistroBitacoraTrabajador.toString()
  //       );

  //       if (cantidadEncontrada.length === 0) {
  //         periodosTrabajo.push(bitacora);
  //       }
  //     });

  //     let regimen = trabajador.RegimenDeTrabajo;

  //     // filtrando: sólo los periodos que han sido cerrados explícitamente se excluyen
  //     periodosTrabajo = periodosTrabajo.filter((bitacoraPeriodo) => {
  //       let incluir = true;

  //       docsDescansos.forEach((doc) => {
  //         if (doc.IdsBitacorasTrabajo.includes(bitacoraPeriodo._id)) {
  //           if (doc.PeriodoCerrado) {
  //             incluir = false;
  //           }
  //         }
  //       });

  //       return incluir;
  //     });

  //     // ordenando periodos: de más antiguo a más nuevo
  //     periodosTrabajo.sort((a, b) => {
  //       return a.Inicio_Planificacion_Unix - b.Inicio_Planificacion_Unix;
  //     });

  //     // transformando: los periodos que hayan sido unidos anteriormente, deberán ser tratados como uno,
  //     // para esto se modifica la fecha de Fin_Planificacion_Unix
  //     docsDescansos.forEach((doc) => {
  //       // si ctdadPeriodosEncontrados es mayor a 1, significa que dichos periodos encontrados están unidos
  //       let ctdadPeriodosEncontrados = 0;
  //       let indexesPeriodosJuntosDocumento = [];

  //       periodosTrabajo.forEach((bitacoraPeriodo, index) => {
  //         if (doc.IdsBitacorasTrabajo.includes(bitacoraPeriodo._id)) {
  //           ctdadPeriodosEncontrados++;
  //           indexesPeriodosJuntosDocumento.push(index);
  //         }
  //       });

  //       if (ctdadPeriodosEncontrados > 1) {
  //         let nuevoPeriodosTrabajo = [];

  //         periodosTrabajo.forEach((bitacoraPeriodo, index) => {
  //           if (indexesPeriodosJuntosDocumento.includes(index)) {
  //             // si es el primero de los que se encontraron que eran unidos
  //             if (index === indexesPeriodosJuntosDocumento[0]) {
  //               let bitacoraClone = { ...bitacoraPeriodo };

  //               let indexUltimoPeriodoUnion =
  //                 indexesPeriodosJuntosDocumento[
  //                   indexesPeriodosJuntosDocumento.length - 1
  //                 ];

  //               let ultimoPeriodoUnion =
  //                 periodosTrabajo[indexUltimoPeriodoUnion];

  //               bitacoraClone.Fin_Planificacion_Unix =
  //                 ultimoPeriodoUnion.Fin_Planificacion_Unix;

  //               nuevoPeriodosTrabajo.push(bitacoraClone);
  //             }
  //           } else {
  //             nuevoPeriodosTrabajo.push(bitacoraPeriodo);
  //           }
  //         });

  //         periodosTrabajo = nuevoPeriodosTrabajo;
  //       }
  //     });


  //     // cálculo del detalle del trabajador: días totales de descanso pendientes y días respectivos de trabajo
  //     let diasPendientesDescanso = 0;
  //     let diasDeTrabajo = 0;

  //     periodosTrabajo.forEach((bitacoraPeriodo) => {
  //       let inicio = moment.unix(bitacoraPeriodo.Inicio_Planificacion_Unix);
  //       let fin = moment.unix(bitacoraPeriodo.Fin_Planificacion_Unix);

  //       let ctdadDiasPeriodo = fin.diff(inicio, "days") + 1;

  //       // diasDeTrabajo es la suma de los días de todos los periodos
  //       diasDeTrabajo += ctdadDiasPeriodo;

  //       // si el periodo tiene un doc de descanso, utilizar el régimen de ese, si no, utilizar el actual del trabajador
  //       let regimenUtilizar = regimen;
  //       let diasUtilizadosPeriodo = 0;

  //       // cuando se le hayan ingresado días anteriormente al periodo, utilizaremos los que se le otorgaron la primera vez
  //       let primerSaldoOtorgado;
  //       let usarSaldoFlag = true;

  //       docsDescansos.forEach((doc) => {
  //         if (doc.IdsBitacorasTrabajo.includes(bitacoraPeriodo._id)) {
  //           regimenUtilizar = doc.RegimenTrabajoActual;

  //           let efectivos = doc.DiasEfectivos || 0;
  //           let compensados = doc.DiasCompensados || 0;

  //           diasUtilizadosPeriodo += efectivos + compensados;

  //           // para que use el saldo otorgado de el primer doc
  //           if (usarSaldoFlag) {
  //             primerSaldoOtorgado = doc.DiasOtorgados;
  //             usarSaldoFlag = false;
  //           }
  //         }
  //       });

  //       let {
  //         Cantidad_Dias_Trabajo: regimenTrabajo,
  //         Cantidad_Dias_Descanso: regimenDescanso,
  //       } = regimenUtilizar;

  //       let descansoSugeridoInicial =
  //         (regimenDescanso * ctdadDiasPeriodo) / regimenTrabajo -
  //         diasUtilizadosPeriodo;

  //       // cuando haya primerSaldoOtorgado (lo que significa que se le ha ingresado días anteriormente al periodo)
  //       // se hará el cálculo según los otorgados por el usuario, no los calculados según régimen
  //       let descansoSugerido = primerSaldoOtorgado
  //         ? primerSaldoOtorgado - diasUtilizadosPeriodo
  //         : descansoSugeridoInicial;

  //       diasPendientesDescanso += descansoSugerido;
  //     });

  //     /*** FIN CÁLCULO PERIODOS DE TRABAJO ***/

  //     this.formRef.current.resetFields([
  //       "Periodo",

  //       "LaboradosRegimen",
  //       "Exceso",
  //       "Laborados",

  //       "Sugeridos",
  //       "Otorgados",
  //       "Efectivos",

  //       "Fechas",

  //       "DeseaCompensar",
  //       "Compensados",
  //       "montoCompensacion",

  //       "Habiles",
  //       "Inhabiles",
  //       "Pendiente",

  //       "cerrarPeriodo",

  //       "Correo",
  //     ]);
     
      

  //     const responseDFiniquitados =
  //       await traer_todos_las_fechas_trabajador_descansos_finiquitados(
  //         idTrabajador
  //       );

  //     responseDFiniquitados.forEach((finiquito) => {
  //       let newFiniquito = { ...finiquito };
  //       newFiniquito.finiquitoDescanso = true;
  //       newFiniquito.TipoRegistroBitacoraTrabajador = "descansoFiniquitado";
  //       response.push(newFiniquito);
  //     });

  //     this.setState({
  //       fechasBloqueadas: fechas,
  //       bitacora: response,
  //       mostrarCalendario: true,
  //       periodosTrabajo,
  //       periodosTrabajoBackup: periodosTrabajo,
  //       bitacorasTrabajo,
  //       regimen,
  //       trabajador,
  //       docsDescansos,

       

  //       mostrarDetalleTrabajador: true,
  //       diasPendientesDescanso,
  //       diasDeTrabajo,

  //       fechasDescanso: null,
  //       disabledInputs1: true,
  //       disabledInputs2: true,
  //       disabledInputs3: true,
  //       disabledInputs4: true,

  //       periodosJuntos: null,

  //       mostrarDeseaCompensar: false,
  //       mostrarMontoCompensacion: false,

  //       mostrarDetalleOtorgados: false,
  //       arrayDetalleOtorgados: null,

  //       mostrarInputsCompensacion: false,

  //       mostrarFormularioCompensacion: false,
  //       mostrarDiaMontoCompensacion: false,

  //       mostrarInfoEfectivos: false,

  //       isOtorgadosValid: true,
  //       otorgadosMessage: "",

  //       periodosTrabajoJuntos: null,
  //       ctdadPeriodosSeleccionados: 0,
  //     });
  //   }
  // };

  reiniciarFormulario = async (objState) => {
    this.formRef.current.resetFields([
      "Periodo",

      "LaboradosRegimen",
      "Exceso",
      "Laborados",

      "Sugeridos",
      "Otorgados",
      "Efectivos",

      "Fechas",

      "DeseaCompensar",
      "Compensados",
      "montoCompensacion",

      "Habiles",
      "Inhabiles",
      "Pendiente",

      "cerrarPeriodo",

      "Correo",
      "Observacion",
    ]);

    this.setState({
      fechasDescanso: null,
      disabledInputs1: true,
      disabledInputs2: true,
      disabledInputs3: true,
      disabledInputs4: true,

      periodosJuntos: null,

      mostrarDeseaCompensar: false,
      mostrarMontoCompensacion: false,

      docsDescansos: null,
      mostrarDetalleOtorgados: false,
      arrayDetalleOtorgados: null,

      mostrarInputsCompensacion: false,

      mostrarFormularioCompensacion: false,
      mostrarDiaMontoCompensacion: false,

      mostrarInfoEfectivos: false,

      isOtorgadosValid: true,
      otorgadosMessage: "",
      periodosTrabajo:[],
      periodosTrabajoJuntos: null,
      ctdadPeriodosSeleccionados: 0,

      ...objState,
    });

    //this.handleChangeTrabajador(this.props.Trabajador);
    this.handleChangeNueva(this.props.Trabajador);
  };

  calcularSugeridos = async (e) => {
    try{
      let { unirPeriodos } = this.formRef.current.getFieldsValue([
        "unirPeriodos",
      ]);
      if (!unirPeriodos) e = [e];

      if (e[0] == null) {
        this.setState({
          periodosTrabajoJuntos: null,
        });

        return;
      }

      let { periodosTrabajo, regimen, docsDescansos } = this.state;

      let indexPeriodosArr = e;
      let cantidadDeDias = 0;

      let periodosJuntos = [];

      let diasUtilizadosPeriodo = 0;
      let arrayDetalleOtorgados = [];

      let {
        Cantidad_Dias_Trabajo: regimenCtdadTrabajo,
        Cantidad_Dias_Descanso: regimenCtdadDescanso,
      } = regimen;

      let trabajoPorRegimen = 0;
      let jornadasExtra = 0;

      let saldoOtorgado;
      let usarSaldoFlag = true;

      if (unirPeriodos) {
        let { periodosTrabajoJuntos } = this.state;

        // varía cuando ya se están usando juntar periodos
        let listaDePeriodosUsar =
          periodosTrabajoJuntos != null ? periodosTrabajoJuntos : periodosTrabajo;

        indexPeriodosArr.forEach((indexPeriodo) => {
          let periodoSeleccionado = listaDePeriodosUsar[indexPeriodo];
          //LOGICA FECHA UNIX ESTABA FALLANDO Y SE AGREGABA UN DIA DE MAS
          // let inicio = moment.unix(periodoSeleccionado.Inicio_Planificacion_Unix);
          // let fin = moment.unix(periodoSeleccionado.Fin_Planificacion_Unix);
          //SE ESTA VALIDANDO ESTE CODIGO - VERIFICAR SI HAY PROBLEMAS A LARGO PLAZO
          let inicio = moment.utc(periodoSeleccionado.Inicio_Planificacion);
          let fin = moment.utc(periodoSeleccionado.Fin_Planificacion);

          cantidadDeDias += fin.diff(inicio, "days") + 1;
        });

        // días de trabajo por régimen //
        if (cantidadDeDias >= regimenCtdadTrabajo) {
          trabajoPorRegimen = regimenCtdadTrabajo;
        } else {
          trabajoPorRegimen = cantidadDeDias;
        }

        // días de jornada extra //
        jornadasExtra = cantidadDeDias - regimenCtdadTrabajo;
        if (jornadasExtra < 0) jornadasExtra = 0;

        // se hace la misma contabilización que en else pero creando nuevo arr de periodosJuntos,
        // el cual incluye sólo periodos que estén juntos

        let seguirRevisandoFlag = true;
        let incremento = 1;
        let indexPeriodoSeleccionado = e[0];

        let periodoSeleccionado = listaDePeriodosUsar[indexPeriodoSeleccionado];

        // el periodo seleccionado siempre entra
        periodosJuntos.push(periodoSeleccionado);

        while (seguirRevisandoFlag) {
          let periodoIter =
            listaDePeriodosUsar[indexPeriodoSeleccionado + incremento - 1];

          let fin = moment.unix(periodoIter.Fin_Planificacion_Unix);
          let periodoSiguiente =
            listaDePeriodosUsar[indexPeriodoSeleccionado + incremento];

          if (periodoSiguiente) {
            let fechaFinPeriodoSeleccionado = fin.clone().add(incremento, "day");
            let fechaInicioPeriodoSiguiente = moment.unix(
              periodoSiguiente.Inicio_Planificacion_Unix
            );

            // si es true, están pegados
            if (
              fechaFinPeriodoSeleccionado.isAfter(fechaInicioPeriodoSiguiente)
            ) {
              periodosJuntos.push(periodoSiguiente);
              incremento++;
            } else {
              seguirRevisandoFlag = false;
            }
          } else {
            seguirRevisandoFlag = false;
          }
        }
      } else {
        //por cada uno de los periodos seleccionados, sumar días trabajados
        indexPeriodosArr.forEach((indexPeriodo) => {
          let periodoSeleccionado = periodosTrabajo[indexPeriodo];
          //se cambio la logia de unix por fallos*
          // let inicio = moment.unix(periodoSeleccionado.Inicio_Planificacion_Unix);
          // let fin = moment.unix(periodoSeleccionado.Fin_Planificacion_Unix);
          // console.log(periodoSeleccionado,"periodoSeleccionado")
          let inicio = moment.utc(periodoSeleccionado.Inicio_Planificacion);
          let fin = moment.utc(periodoSeleccionado.Fin_Planificacion);
          cantidadDeDias += fin.diff(inicio, "days") + 1;

          // console.log({inicio, fin, cantidadDeDias})
          // días de trabajo por régimen //
          if (cantidadDeDias >= regimenCtdadTrabajo) {
            trabajoPorRegimen = regimenCtdadTrabajo;
          } else {
            trabajoPorRegimen = cantidadDeDias;
          }

          // días de jornada extra //
          jornadasExtra = cantidadDeDias - regimenCtdadTrabajo;
          if (jornadasExtra < 0) jornadasExtra = 0;

          // resta de días utilizados y cálculo de detalle de sugeridos //
          docsDescansos.forEach((doc) => {
            if (doc.IdsBitacorasTrabajo.includes(periodoSeleccionado._id)) {
              regimenCtdadTrabajo =
                doc.RegimenTrabajoActual.Cantidad_Dias_Trabajo;
              regimenCtdadDescanso =
                doc.RegimenTrabajoActual.Cantidad_Dias_Descanso;

              let dEfectivos = doc.DiasEfectivos;
              let dCompensados = doc.DiasCompensados;

              if (dEfectivos == undefined) dEfectivos = 0;
              if (dCompensados == undefined) dCompensados = 0;

              diasUtilizadosPeriodo += dEfectivos + dCompensados;

              arrayDetalleOtorgados.push({
                fechas: [doc.Fecha_Inicio, doc.Fecha_Fin],
                saldoEfectivo: doc.DiasEfectivos,
                saldoCompensado: doc.DiasCompensados,
              });

              if (usarSaldoFlag) {
                saldoOtorgado = doc.DiasOtorgados;
                usarSaldoFlag = false;
              }
            }
          });
        });

        periodosJuntos = null;
      }

      // REGLA DE 3
      let descansoSugeridoInicial =
        (regimenCtdadDescanso * cantidadDeDias) / regimenCtdadTrabajo;

      let descansoSugeridoCondicional = saldoOtorgado
        ? saldoOtorgado - diasUtilizadosPeriodo
        : descansoSugeridoInicial;

      // redondeando a 2 decimales
      descansoSugeridoInicial =
        Math.round((descansoSugeridoInicial + Number.EPSILON) * 100) / 100;
      descansoSugeridoCondicional =
        Math.round((descansoSugeridoCondicional + Number.EPSILON) * 100) / 100;

      arrayDetalleOtorgados.unshift(saldoOtorgado);
      arrayDetalleOtorgados.unshift(descansoSugeridoInicial);

      // los indexes cambian al cambiar la lista de los periodos: con la nueva lista siempre deben ir de 0 hacia arriba
      // dependiendo de la cantidad de periodos disponibles a unir.
      if (periodosJuntos != null) {
        let indexes = [];

        for (let i = 0; i < indexPeriodosArr.length; i++) {
          indexes.push(i);
        }

        this.formRef.current.setFieldsValue({
          Periodo: indexes,
        });
      }

      this.formRef.current.setFieldsValue({
        LaboradosRegimen: trabajoPorRegimen,
        Exceso: jornadasExtra,
        Laborados: cantidadDeDias,

        Sugeridos: descansoSugeridoCondicional,
      });

      this.setState({
        disabledInputs1: false,
        disabledInputs2: false,

        periodosTrabajoJuntos: periodosJuntos,
        arrayDetalleOtorgados:
          arrayDetalleOtorgados.length > 2 ? arrayDetalleOtorgados : null,
        mostrarDetalleOtorgados: arrayDetalleOtorgados.length > 2 ? true : false,
      });
    }catch(error){
      console.log(error)
    }
  };

  arraysSonIguales = (a, b) => {
    if (a === b) return true;
    if (a == null || a == undefined || b == null || b == undefined) return true;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  calcularDias = async (e) => {
    let fecha = e;
    let {
      Otorgados: cantidadOtorgados,
      Efectivos: cantidadEfectivos,
      Compensados: cantidadCompensados,
    } = this.formRef.current.getFieldsValue([
      "Otorgados",
      "Sugeridos",
      "Efectivos",
      "Compensados",
    ]);

    let fechasInicioFinDescanso = [];

    let diasHabiles = 0;
    let diasInhabiles = 0;

    // sólo cuando se llame con fecha se calcularán los días hábiles e inhábiles
    if (fecha) {
      let inicio = fecha
        .clone()
        .set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
      let fin = fecha
        .clone()
        .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
        .add(cantidadEfectivos - 1, "days");

      // push fecha inicio //
      fechasInicioFinDescanso.push(inicio);

      // push fecha fin //
      fechasInicioFinDescanso.push(fin);

      // días hábiles e inhábiles //
      for (let index = 0; index < cantidadEfectivos; index++) {
        let fechaIter = fechasInicioFinDescanso[0].clone().add(index, "days");
        let esFeriadoFlag = false;

        // validación: si es feriado es inhábil
        this.state.feriados.forEach((feriado) => {
          let feriadoMoment = moment(feriado.Fecha).set({
            hour: 12,
            minute: 0,
            second: 0,
            millisecond: 0,
          });
          let iterMoment = fechaIter.set({
            hour: 12,
            minute: 0,
            second: 0,
            millisecond: 0,
          });

          if (feriadoMoment.isSame(iterMoment)) {
            esFeriadoFlag = true;
          }
        });

        // contabilizando habiles e inhábiles
        if ([6, 7].includes(fechaIter.isoWeekday()) || esFeriadoFlag) {
          diasInhabiles++;
        } else {
          diasHabiles++;
        }
      }
    }

    if (!cantidadOtorgados) cantidadOtorgados = 0;
    if (!cantidadEfectivos) cantidadEfectivos = 0;
    if (!cantidadCompensados) cantidadCompensados = 0;

    // mostrar formulario de compensación sólo cuando cantidadOtorgados > cantidadEfectivos
    let mostrarFormularioCompensacion = false;
    if (cantidadOtorgados > cantidadEfectivos) {
      mostrarFormularioCompensacion = true;
    } else {
      cantidadCompensados = 0;
      this.formRef.current.resetFields(["Compensados", "montoCompensacion"]);
    }

    // los Otorgados no pueden ser menor que la suma de los Efectivos y Compensados
    let isOtorgadosValid = true;
    let otorgadosMessage = "";

    if (cantidadOtorgados < cantidadEfectivos + cantidadCompensados) {
      isOtorgadosValid = false;

      if (cantidadCompensados > 0) {
        otorgadosMessage =
          "La cantidad de días otorgados no puede ser menor a la suma de días efectivos y compensados";
      } else {
        otorgadosMessage =
          "La cantidad de días otorgados no puede ser menor a la cantidad de días efectivos";
      }
    } else {
      isOtorgadosValid = true;
    }

    // días de saldo pendiente //
    let saldoPendiente =
      cantidadOtorgados - cantidadEfectivos - cantidadCompensados;
    saldoPendiente = Math.round((saldoPendiente + Number.EPSILON) * 100) / 100;
    if (saldoPendiente < 0) saldoPendiente = 0;

    let ocultarFechasDescanso = false;
    if (cantidadEfectivos === 0) ocultarFechasDescanso = true;

    this.setState({
      disabledInputs4: false,
      fechasDescanso: fecha ? fechasInicioFinDescanso : null,
      // mostrarFormularioAdicional: mostrarAdicionales,
      mostrarFormularioCompensacion,
      ocultarFechasDescanso,

      mostrarInfoEfectivos: true,

      isOtorgadosValid: isOtorgadosValid,
      otorgadosMessage,
    });

    this.formRef.current.setFieldsValue({
      Habiles: fecha ? diasHabiles : 0,
      Inhabiles: fecha ? diasInhabiles : 0,
      Pendiente: saldoPendiente,
    });
  };

  modalCancelar = () => {
    this.setState({
      modalDescanso: false,
    });
  };

  dobleClickBarra = (itemSeleccionado) => {
    if (itemSeleccionado.group === "5f3eeb78978b284b68ebced4") {
      this.setState({
        idDescanso: itemSeleccionado.Descansos,
        modalDescanso: true,

        Fecha_Inicio_Eliminar: itemSeleccionado.Fecha_Inicio,
        Fecha_Fin_Eliminar: itemSeleccionado.Fecha_Fin,
      });
    }
  };

  descargarTicket = (itemSeleccionado) => {
    var win = window.open(
      "/ImpresionDescanso?Descanso=" + itemSeleccionado,
      "_blank"
    );
    win.focus();
  };

  eliminarRegistro = async (itemSeleccionado) => {
    await Swal.fire({
      title: "Confirmar",
      text: `¿Estás seguro que quieres eliminar el registro de descanso?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {

      if (result.value) {
        await this.props.eliminarRegistro(
          itemSeleccionado.Fecha_Inicio_DB,
          itemSeleccionado.Fecha_Fin_DB,
          itemSeleccionado.IdImprimir
        );

        // let response = await this.props.traer_todos_las_fechas_trabajador(
        //   this.props.Trabajador
        // );

        // let fechas = [];

        // await response.forEach((element) => {
        //   fechas.push(element.Fecha);
        // });

        // const responseDFiniquitados =
        //   await traer_todos_las_fechas_trabajador_descansos_finiquitados(
        //     this.props.Trabajador
        //   );
        // responseDFiniquitados.forEach((finiquito) => {
        //   let newFiniquito = { ...finiquito };
        //   newFiniquito.finiquitoDescanso = true;
        //   newFiniquito.TipoRegistroBitacoraTrabajador = "descansoFiniquitado";
        //   response.push(newFiniquito);
        // });

        this.reiniciarFormulario({
          bitacora: {},
          mostrarCalendario: true,
        });
      }
    });
  };

  handleChangeUnirPeriodos = (e) => {
    let val = e.target.value ? "multiple" : "";

    this.formRef.current.resetFields([
      "Periodo",

      "LaboradosRegimen",
      "Exceso",
      "Laborados",

      "Sugeridos",
      "Otorgados",
      "Efectivos",

      "Fechas",

      "DeseaCompensar",
      "Compensados",
      "montoCompensacion",

      "Habiles",
      "Inhabiles",
      "Pendiente",

      "cerrarPeriodo",

      "Correo",
      "Observacion",
    ]);

    this.setState({
      selectMode: val,
      periodosTrabajoJuntos: null,

      fechasDescanso: null,
      disabledInputs1: true,
      disabledInputs2: true,
      disabledInputs3: true,
      disabledInputs4: true,

      periodosJuntos: null,

      mostrarDeseaCompensar: false,
      mostrarMontoCompensacion: false,

      mostrarDetalleOtorgados: false,
      arrayDetalleOtorgados: null,

      mostrarInputsCompensacion: false,

      mostrarFormularioCompensacion: false,
      mostrarDiaMontoCompensacion: false,

      mostrarInfoEfectivos: false,

      isOtorgadosValid: true,
      otorgadosMessage: "",

      periodosTrabajoJuntos: null,
    });

    let {
      periodosTrabajo,
      periodosTrabajoJuntos,
      docsDescansos,
      periodosTrabajoBackup,
    } = this.state;

    let periodosTrabajoFiltrado = [];
    let periodosTrabajoUsar = periodosTrabajoJuntos
      ? periodosTrabajoJuntos
      : periodosTrabajo;

    // cuando unirPeriodos es true, se excluyen todos los periodos a los que ya se les hayan asignado cualquier tipo de descanso
    if (val) {
      periodosTrabajoFiltrado = periodosTrabajoUsar.filter(
        (bitacoraPeriodo) => {
          let incluir = true;

          docsDescansos.forEach((doc) => {
            if (doc.IdsBitacorasTrabajo.includes(bitacoraPeriodo._id)) {
              incluir = false;
            }
          });

          return incluir;
        }
      );

      this.setState({
        periodosTrabajo: periodosTrabajoFiltrado,
      });
    } else {
      this.setState({
        periodosTrabajo: periodosTrabajoBackup,
        ctdadPeriodosSeleccionados: 0,
      });
    }
  };

  handleChangeEsCompensacion = (e) => {
    let val = e.target.value;

    if (val) {
      let { Sugeridos } = this.formRef.current.getFieldsValue(["Sugeridos"]);

      this.formRef.current.setFieldsValue({
        cerrarPeriodo: val,
        compensarSaldoPendiente: val,

        Otorgados: Sugeridos,
        Habiles: 0,
        Inhabiles: 0,
        Pendiente: 0,
      });

      this.setState({
        disabledInputs2: true,
        disabledInputs3: true,
        disabledInputs4: true,
      });
    } else {
      this.formRef.current.resetFields([
        "Otorgados",
        "Habiles",
        "Inhabiles",
        "Pendiente",
        "Fechas",

        "cerrarPeriodo",
        "compensarSaldoPendiente",
        "montoCompensacion",
      ]);
    }

    this.setState({
      mostrarFormularioAdicional: val,
      mostrarDeseaCompensar: val,
      mostrarMontoCompensacion: val,

      disabledInputs2: val,
      ocultarFechasDescanso: val,
      mostrarInputsCompensacion: val,

      fechasDescanso: null,
    });
  };

  handleChangePeriodos = (e) => {

    try{
      const { disabledInputs4 } = this.state.disabledInputs4;
      let { unirPeriodos } = this.formRef.current.getFieldsValue([
        "unirPeriodos",
      ]);

      this.calcularSugeridos(e);

      if (!disabledInputs4) {
        let { Fechas } = this.formRef.current.getFieldsValue(["Fechas"]);
        
        this.calcularDias(Fechas);
      }

      if (unirPeriodos) {
        let ctdad;

        if (e.length === 0) {
          ctdad = 0;
        } else {
          ctdad = e.length + 1;
        }
        
        this.setState({ ctdadPeriodosSeleccionados: ctdad });
      } else {
        this.setState({ ctdadPeriodosSeleccionados: 0 });
      }
    }catch(error){
      console.log(error)
    }
  };

  actualizarFechaTimeline = (fechaInicio, fechaFin) => {

    let arrayIniciosDeMes = [];

    let fechaInicioOriginal = convertirFechaString(moment.utc(fechaInicio), 1).fechaString;
    let fechaInicioString = convertirFechaString(moment.utc(fechaInicio).startOf("month"), 1).fechaString;
    let fechaFinString = convertirFechaString(moment.utc(fechaFin).endOf("month"), 2).fechaString;
    let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;

    for (let index = 0; index < diferenciaMeses; index++) {
      let fechaAgregar;

      if(index === 0){
        if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
          fechaAgregar = moment.utc(fechaInicioOriginal);
        } else {
          fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
        }
      } else {
        fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
      }

      arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
    }

    this.setState({
      selectedDatesTimeline: [fechaInicio, fechaFin],
      firstDaysOfTheMonths: arrayIniciosDeMes
    });
  };

  handleNewDates = async (fechaInicio, fechaFin) => {
    try {
      const idTrabajador = this.props.Trabajador;
      let user = this.props.trabajadores.find((x) => x._id === idTrabajador);
      if (user) {

        const { contrato: idContrato } = this.formRef.current.getFieldsValue();

        let contratos = this.props.contratos.filter((contrato) => contrato._id.toString() === idContrato.toString())[0];
        let busquedaContrato = idContrato;
    
        if(contratos.Ids_Contratos){
          busquedaContrato = contratos.Ids_Contratos.join(',');
        }

        //SE OBTIENE LAS BITACORAS QUE SE MOSTRARAN
        let bitacorasMostrar =await this.props.traer_todos_las_fechas_trabajador_por_contrato(idTrabajador,busquedaContrato,fechaInicio,fechaFin);

        let arrayIniciosDeMes = [];

        let fechaInicioOriginal = convertirFechaString(moment.utc(bitacorasMostrar.inicioFechaRangoBusqueda), 1).fechaString;
        let fechaInicioString = convertirFechaString(moment.utc(bitacorasMostrar.inicioFechaRangoBusqueda).startOf("month"), 1).fechaString;
        let fechaFinString = convertirFechaString(moment.utc(bitacorasMostrar.FinFechaRangoBusqueda).endOf("month"), 2).fechaString;
        let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;
    
        for (let index = 0; index < diferenciaMeses; index++) {
          let fechaAgregar;
    
          if(index === 0){
            if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
              fechaAgregar = moment.utc(fechaInicioOriginal);
            } else {
              fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
            }
          } else {
            fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
          }
    
          arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
        }

        await this.setState({
          fechasBloqueadas: bitacorasMostrar.bitNoModificar,
          bitacora: bitacorasMostrar.bitacoras,
          selectedContrato: [bitacorasMostrar.InicioContrato, bitacorasMostrar.FinContrato],
          selectedDates: [
            bitacorasMostrar.inicioFechaRangoBusqueda,
            bitacorasMostrar.FinFechaRangoBusqueda,
          ],
          selectedDatesTimeline: [
            bitacorasMostrar.inicioFechaRangoBusqueda,
            bitacorasMostrar.FinFechaRangoBusqueda,
          ],
          firstDaysOfTheMonths: arrayIniciosDeMes,
          contEstados: bitacorasMostrar.contEstados,
          mostrarCalendario: true,
          loading: false
        });

        await this.formRef.current.resetFields(["Fechas"]);
      }
    } catch (error) {
      await this.setState({
        loading: false,
      });
      console.log(error);
    }
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 12,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 12,
        },
      },
    };

    let {
      disabledInputs0,
      disabledInputs1,
      disabledInputs2,
      disabledInputs3,
      disabledInputs4,

      selectMode,

      periodosTrabajo,
      periodosTrabajoJuntos,

      mostrarFormularioAdicional,
      mostrarDeseaCompensar,
      mostrarMontoCompensacion,

      mostrarDetalleOtorgados,
      arrayDetalleOtorgados,

      ocultarFechasDescanso,
      mostrarInputsCompensacion,

      mostrarDetalleTrabajador,
      regimen,
      diasPendientesDescanso,
      diasDeTrabajo,
      mostrarFormularioCompensacion,
      mostrarDiaMontoCompensacion,

      mostrarInfoEfectivos,

      isOtorgadosValid,
      otorgadosMessage,

      ctdadPeriodosSeleccionados,
    } = this.state;

    let classBotonSubmit;
    let disabledBotonSubmit;

    if (mostrarInputsCompensacion || !disabledInputs4) {
      classBotonSubmit = "guardar-form";
      disabledBotonSubmit = false;
    } else {
      disabledBotonSubmit = true;
      classBotonSubmit = "guardar-form-disabled";
    }
    if (!this.state.Editar) {
      classBotonSubmit = "guardar-form-disabled";
      disabledBotonSubmit = true;
    }

    let style = {
      marginBottom: "0px",
      marginTop: "5px",
      color: "black",
      fontSize: "14px",
    };
    let styleDetalle = {
      marginBottom: "0px",
      marginTop: "5px",
      color: "#e60aa3",
      fontSize: "14px",
      textDecoration: "underline",
    };
    let styleMargin0 = { margin: "0px" };

    return (
      <Layout>
        <Loading cargando={this.props.loading} />

        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Descanso"
            subtitulo="Corresponde a los días de cese de actividades de acuerdo al contrato del trabajador"
          />
        </Header>

        <hr className="division" />

        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row gutter={25}>
            <Col
              className="gutter-row"
              span={8}
              xs={24}
              s={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
            >
              <Card
                style={{
                  borderRadius: "16px",
                  height: "100%",
                  width: "100%",
                  boxShadow: "0px 3px 6px #74747465",
                }}
              >
                <Row>
                  <Col span={22} style={{ display: "inherit" }}>
                    <img
                      src={IconDescanso}
                      alt="cama arreglada para descansar"
                    />
                    <h1 className="formDescanso_card_title">
                      Generar Descanso
                    </h1>
                  </Col>

                  <Col span={22} style={{ marginTop: "24px" }}>
                    <Form
                      onFinish={this.onFinish}
                      ref={this.formRef}
                      name="descanso_trabajador"
                      {...formItemLayout}
                    >
                      {/* TRABAJADOR */}
                      <Form.Item
                        name="trabajador"
                        label="Nombre Trabajador"
                        className="inputsFormDescanso_Text"
                        // style={mostrarDetalleTrabajador ? styleMargin0 : ''}
                        extra={
                          mostrarDetalleTrabajador ? (
                            <Popover
                              title={"Información del trabajador:"}
                              placement={"right"}
                              content={
                                <div className={"popover-div"}>
                                  <table className={"pop-table"}>
                                    <tbody>
                                      <tr key={-1} className={"pop-tr"}>
                                        <td
                                          className={
                                            "pop-td pop-td-first td-left"
                                          }
                                        >
                                          Régimen del trabajador
                                        </td>
                                        <td
                                          className={
                                            "pop-td pop-td-first td-right t-bold"
                                          }
                                          style={{ textAlign: "left" }}
                                        >
                                          <div className={"short-border"}></div>
                                          <img
                                            src={embarcado}
                                            className={"popover-icon"}
                                          />{" "}
                                          {/* Trabaja:{" "}
                                          {regimen.Cantidad_Dias_Trabajo} */}
                                          <br />
                                          <img
                                            src={descanso}
                                            className={"popover-icon"}
                                          />{" "}
                                          {/* Descansa:{" "}
                                          {regimen.Cantidad_Dias_Descanso} */}
                                        </td>
                                      </tr>

                                      <tr key={2} className={"pop-tr"}>
                                        <td
                                          className={
                                            "pop-td pop-td-med td-left"
                                          }
                                        >
                                          Días laborados de periodos
                                          <br />
                                          abiertos
                                        </td>
                                        <td
                                          className={
                                            "pop-td pop-td-med td-right t-bold"
                                          }
                                        >
                                          <div className={"short-border"}></div>
                                          {diasDeTrabajo} días
                                        </td>
                                      </tr>

                                      <tr key={1} className={"pop-tr"}>
                                        <td
                                          className={
                                            "pop-td pop-td-last td-left"
                                          }
                                        >
                                          Días de descanso estimados de
                                          <br />
                                          periodos abiertos
                                        </td>
                                        <td
                                          className={
                                            "pop-td pop-td-last td-right t-bold"
                                          }
                                        >
                                          <div className={"short-border"}></div>
                                          {diasPendientesDescanso} días
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              }
                            >
                              <div className={"detalle-div"}>
                                <p
                                  style={styleDetalle}
                                  className={"detalle-trabajador"}
                                >
                                  Información
                                </p>
                              </div>
                            </Popover>
                          ) : null
                        }
                      >
                        {/* 
                        <Select

                          showSearch={true}
                          placeholder="Selecciona un trabajador"
                          optionFilterProp="children"
                          size="middle"
                          loading={this.props.loading}
                          style={{ fontWeight: 100, fontFamily: 'MavenPro-Regular' }}
                          onChange={(e) => {

                            this.formRef.current.setFieldsValue({ unirPeriodos: false });
                            this.handleChangeTrabajador(e);
                          }}
                          filterOption={(input, option) => {
                            return option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          }}
                        >
                          {this.props.trabajadores === null ? (
                            <Option
                              value="Cargando trabajadores..."
                              style={{ fontWeight: 100 }}
                            >
                              Cargando empresas...
                            </Option>
                          ) : (
                            this.props.trabajadores.map(({ Nombre, Apellido_Paterno, Apellido_Materno, _id }, index) => (
                              <Option
                                key={index}
                                value={_id}
                                style={{ fontWeight: 100, fontFamily: 'MavenPro-Regular' }}
                              >
                                <b>{Nombre + ' ' + Apellido_Paterno + ' ' + Apellido_Materno}</b>
                              </Option>
                            ))
                          )}
                        </Select> */}

                        <Select
                          showSearch={true}
                          placeholder="Selecciona un trabajador"
                          optionFilterProp="children"
                          size="middle"
                          loading={this.props.loading}
                          //onChange={this.handleChange}
                          onChange={this.handleChangeNueva}
                          filterOption={(input, option) => {
                            return (
                              option.children.props.children.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {this.props.trabajadores === null ? (
                            <Option value="Cargando trabajadores...">
                              Cargando trabajadores...
                            </Option>
                          ) : (
                            this.props.trabajadores.map(
                              (
                                {
                                  Nombre,
                                  Apellido_Paterno,
                                  Apellido_Materno,
                                  _id,
                                },
                                index
                              ) => (
                                <Option key={index} value={_id}>
                                  <b>
                                    <Tooltip title={Nombre + " " + Apellido_Paterno + " " + Apellido_Materno} color={'geekblue'}> 
                                      {Nombre +
                                        " " +
                                        Apellido_Paterno +
                                        " " +
                                        Apellido_Materno}
                                    </Tooltip> 
                                  </b>
                                </Option>
                              )
                            )
                          )}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="contrato"
                        label="Contrato Trabajador"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                      >
                        <Select
                          // showSearch={true}
                          placeholder="Selecciona el contrato del trabajador"
                          optionFilterProp="children"
                          size="middle"
                          loading={this.props.loading}
                          onChange={this.handleContrato}
                          // filterOption={(input, option) => {
                          //   return (
                          //     option.children.props.children
                          //       .toLowerCase()
                          //       .indexOf(input.toLowerCase()) >= 0
                          //   );
                          // }}
                        >
                          {this.props.contratos === null ? (
                            <Option value="Cargando contratos...">
                              Cargando contratos...
                            </Option>
                          ) : (
                            this.props.contratos.map((contrato, index) => (
                              <>
                                {contrato.Tipo_Contrato === "Plazo fijo" ? (
                                  <Option key={index} value={contrato._id}>
                                    {/* <Tooltip title={moment.utc(contrato.Contrato_Fecha_Inicio).format("DD-MM-YYYY") + " al " + (contrato.Contrato_Finiquitado === true ? moment.utc(contrato.Fecha_Finiquito).format("DD-MM-YYYY") : moment.utc(contrato.Contrato_Fecha_Fin).format("DD-MM-YYYY"))} color={'geekblue'}> */}
                                      <b>
                                        {moment
                                          .utc(contrato.Contrato_Fecha_Inicio)
                                          .format("DD-MM-YYYY")}{" "}
                                        al{" "}
                                        {contrato.Contrato_Finiquitado === true
                                          ? moment
                                              .utc(contrato.Fecha_Finiquito)
                                              .format("DD-MM-YYYY")
                                          : moment
                                              .utc(contrato.Contrato_Fecha_Fin)
                                              .format("DD-MM-YYYY")}
                                      </b>
                                      {contrato.Contrato_Anexo &&                           
                                        <Popover
                                          title={"Anexo de contrato:"}
                                          placement={"right"}
                                          content={
                                            <div className={"popover-div"}>
                                              {contrato.Informacion_Anexo && contrato.Informacion_Anexo.map((info) => (
                                                <p className={"info-text"}>
                                                  {convertirFechaString(moment.utc(info.Contrato_Fecha_Inicio), 1).fechaDDMMYYYY} / {convertirFechaString(moment.utc(info.Contrato_Fecha_Fin), 2).fechaDDMMYYYY}
                                                </p>
                                              ))}
                                            </div>
                                          }
                                        >
                                          <InfoCircleTwoTone
                                            className={"info-icon"}
                                            twoToneColor={"#48b9fd"}
                                          />
                                        </Popover>
                                      }
                                    {/* </Tooltip> */}
                                  </Option>
                                ) : (
                                  <Option key={index} value={contrato._id}>
                                    {/* <Tooltip title={moment.utc(contrato.Contrato_Fecha_Inicio).format("DD-MM-YYYY")} color={'geekblue'}> */}
                                      <b>
                                        {moment
                                          .utc(contrato.Contrato_Fecha_Inicio)
                                          .format("DD-MM-YYYY")}{" "}
                                        
                                        {contrato.Contrato_Finiquitado === true
                                          ? 'Al ' + moment
                                              .utc(contrato.Fecha_Finiquito)
                                              .format("DD-MM-YYYY")
                                          : "A Contrato Indefinido"}
                                      </b>
                                      {contrato.Contrato_Anexo &&                           
                                        <Popover
                                          title={"Anexo de contrato:"}
                                          placement={"right"}
                                          content={
                                            <div className={"popover-div"}>
                                              {contrato.Informacion_Anexo && contrato.Informacion_Anexo.map((info) => (
                                                <p className={"info-text"}>
                                                  {convertirFechaString(moment.utc(info.Contrato_Fecha_Inicio), 1).fechaDDMMYYYY} / {info.Contrato_Finiquitado === true ? moment.utc(contrato.Fecha_Finiquito).format("DD-MM-YYYY") : info.Contrato_Fecha_Fin ? convertirFechaString(moment.utc(info.Contrato_Fecha_Fin), 2).fechaDDMMYYYY  : "Contrato Indefinido"}
                                                </p>
                                              ))}
                                            </div>
                                          }
                                        >
                                          <InfoCircleTwoTone
                                            className={"info-icon"}
                                            twoToneColor={"#48b9fd"}
                                          />
                                        </Popover>
                                      }

                                    {/* </Tooltip> */}
                                  </Option>
                                )}
                              </>
                            ))
                          )}
                        </Select>
                      </Form.Item>

                      {/* ¿UNIR PERIODOS? */}
                      <Form.Item
                        name="unirPeriodos"
                        label="¿Unir periodos?"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                        style={styleMargin0}
                        extra={
                          <Popover
                            title={"Información:"}
                            placement={"right"}
                            content={
                              <div className={"popover-div"}>
                                <p className={"info-text"}>
                                  Cuando hayan periodos de distintas
                                  planificaciones pero en
                                  <br />
                                  días consecutivos, se pueden unir como periodo
                                  único.
                                </p>
                              </div>
                            }
                          >
                            <InfoCircleTwoTone
                              className={"info-icon"}
                              twoToneColor={"#48b9fd"}
                            />
                          </Popover>
                        }
                      >
                        <Radio.Group
                          disabled={disabledInputs0}
                          key={"unirPeriodos"}
                          onChange={(e) => {
                            this.handleChangeUnirPeriodos(e);
                          }}
                        >
                          <Radio value={true}>Sí</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>

                      {/* PERIODOS */}
                      <Form.Item
                        name="Periodo"
                        label="Periodo de trabajo"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                      >
                        <Select
                          mode={selectMode}
                          disabled={disabledInputs0}
                          onChange={this.handleChangePeriodos}
                          className={
                            !disabledInputs1 && disabledInputs2
                              ? "highlight"
                              : ""
                          }
                        >
                          {(periodosTrabajoJuntos
                            ? periodosTrabajoJuntos
                            : periodosTrabajo
                          ).map((periodo, index) => {
                            // let inicio = moment.unix(periodo.Inicio_Planificacion_Unix);
                            // let fin = moment.unix(periodo.Fin_Planificacion_Unix);

                            let inicio = moment.utc(
                              periodo.Inicio_Planificacion
                            );
                            let fin = moment.utc(periodo.Fin_Planificacion);

                            let disabledOption;

                            if (ctdadPeriodosSeleccionados === 0) {
                              disabledOption = false;
                            } else if (ctdadPeriodosSeleccionados > index) {
                              disabledOption = false;
                            } else {
                              disabledOption = true;
                            }

                            return (
                              <Option
                                key={index}
                                value={index}
                                disabled={disabledOption}
                              >
                                <span
                                  style={{ fontFamily: "MavenPro-Regular" }}
                                >
                                  <Tooltip title={inicio.format("DD-MM-YYYY") + " - " + fin.format("DD-MM-YYYY")} color={'geekblue'}>
                                    {inicio.format("DD-MM-YYYY")} a{" "}
                                    {fin.format("DD-MM-YYYY")}
                                  </Tooltip>
                                </span>
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>

                      {/* ES COMPENSACIÓN */}
                      {/* <Form.Item
                        name="esCompensacion"
                        label={<span style={{ lineHeight: '17px' }}>¿Es compensación económica<br />completa?</span>}
                        className="inputsFormDescanso_Text"
                        style={{ marginTop: '25px' }}
                        rules={[
                          {
                            required: true,
                            message: 'Obligatorio',
                          },
                        ]}
                      >
                        <Radio.Group
                          disabled={disabledInputs1}
                          key={"esCompensacion"}
                          onChange={this.handleChangeEsCompensacion}
                        // className={'highlight'}
                        >
                          <Radio value={true}>Sí</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item> */}

                      {/* LABORADOS POR RÉGIMEN */}
                      <Form.Item
                        name="LaboradosRegimen"
                        label="Días laborados por régimen"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                        style={styleMargin0}
                        extra={
                          <Popover
                            title={"Información:"}
                            placement={"right"}
                            content={
                              <div className={"popover-div"}>
                                <p className={"info-text"}>
                                  Cantidad de días que el trabajador laboró sin
                                  exceder su
                                  <br />
                                  régimen de trabajo actual.
                                </p>
                              </div>
                            }
                          >
                            <InfoCircleTwoTone
                              className={"info-icon"}
                              twoToneColor={"#48b9fd"}
                            />
                          </Popover>
                        }
                      >
                        <InputNumber
                          disabled={true}
                          min={0}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>

                      {/* EXCESO EMBARQUE */}
                      <Form.Item
                        name="Exceso"
                        label={
                          <span style={{ lineHeight: "16px" }}>
                            Días laborados adicionales
                            <br />
                            al régimen
                          </span>
                        }
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                        style={styleMargin0}
                        extra={
                          <Popover
                            title={"Información:"}
                            placement={"right"}
                            content={
                              <div className={"popover-div"}>
                                <p className={"info-text"}>
                                  Cantidad de días que el trabajador laboró en
                                  exceso a su
                                  <br />
                                  régimen de trabajo.
                                </p>
                              </div>
                            }
                          >
                            <InfoCircleTwoTone
                              className={"info-icon"}
                              twoToneColor={"#48b9fd"}
                            />
                          </Popover>
                        }
                      >
                        <InputNumber
                          disabled={true}
                          min={0}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>

                      {/* TOTAL DÍAS LABORADOS */}
                      <Form.Item
                        name="Laborados"
                        label="Total días laborados"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                        style={styleMargin0}
                        extra={
                          <Popover
                            title={"Información:"}
                            placement={"right"}
                            content={
                              <div className={"popover-div"}>
                                <p className={"info-text"}>
                                  Total de días laborados en el periodo
                                  (laborados por régimen + laborados
                                  adicionales)
                                </p>
                              </div>
                            }
                          >
                            <InfoCircleTwoTone
                              className={"info-icon"}
                              twoToneColor={"#48b9fd"}
                            />
                          </Popover>
                        }
                      >
                        <InputNumber
                          disabled={true}
                          min={0}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>

                      {/* DÍAS SUGERIDOS */}
                      <Form.Item
                        name="Sugeridos"
                        label="Días de descanso sugeridos"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                        style={mostrarDetalleOtorgados ? styleMargin0 : ""}
                        extra={
                          mostrarDetalleOtorgados ? (
                            <Popover
                              title={"Información:"}
                              placement={"right"}
                              content={
                                <div className={"popover-div"}>
                                  <table className={"pop-table"}>
                                    <tbody>
                                      <tr
                                        key={-1}
                                        className={"pop-tr"}
                                        style={{
                                          backgroundColor: "#48b9fd",
                                          color: "white",
                                        }}
                                      >
                                        <td
                                          className={
                                            "pop-td pop-td-first td-left"
                                          }
                                        >
                                          Sugerido inicial
                                        </td>
                                        <td
                                          className={
                                            "pop-td pop-td-first td-right t-bold"
                                          }
                                        >
                                          <div className={"short-border"}></div>
                                          {arrayDetalleOtorgados[0]}
                                        </td>
                                      </tr>

                                      {(() => {
                                        if (arrayDetalleOtorgados[1]) {
                                          return (
                                            <tr key={-2} className={"pop-tr"}>
                                              <td
                                                className={
                                                  "pop-td pop-td-first td-left"
                                                }
                                              >
                                                Descanso Otorgado
                                              </td>
                                              <td
                                                className={
                                                  "pop-td pop-td-first td-right t-bold"
                                                }
                                              >
                                                <div
                                                  className={"short-border"}
                                                ></div>
                                                {arrayDetalleOtorgados[1]}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      })()}

                                      {arrayDetalleOtorgados.map(
                                        (detalle, index) => {
                                          if (index === 0 || index === 1)
                                            return;

                                          let inicio = null;
                                          let fin = null;

                                          if (detalle.fechas[0]) {
                                            inicio = moment
                                              .utc(detalle.fechas[0])
                                              .format("DD-MM-YYYY");
                                          }

                                          if (detalle.fechas[0]) {
                                            fin = moment
                                              .utc(detalle.fechas[1])
                                              .format("DD-MM-YYYY");
                                          }

                                          return (
                                            <tr
                                              key={index}
                                              className={"pop-tr"}
                                            >
                                              <td
                                                className={
                                                  "pop-td pop-td-med td-left"
                                                }
                                              >
                                                Saldo utilizado
                                                {inicio != null &&
                                                fin != null ? (
                                                  <br />
                                                ) : null}
                                                {inicio != null && fin != null
                                                  ? `(${inicio} - ${fin})`
                                                  : null}
                                              </td>
                                              <td
                                                className={
                                                  "pop-td pop-td-med td-right t-bold"
                                                }
                                              >
                                                <div
                                                  className={"short-border"}
                                                ></div>
                                                {detalle.saldoEfectivo !=
                                                  undefined &&
                                                detalle.saldoEfectivo != 0
                                                  ? `Descanso efectivo: -${detalle.saldoEfectivo}`
                                                  : ""}

                                                {detalle.saldoEfectivo !=
                                                  undefined &&
                                                detalle.saldoEfectivo != 0 ? (
                                                  <br />
                                                ) : null}

                                                {detalle.saldoCompensado !=
                                                  undefined &&
                                                detalle.saldoCompensado != 0
                                                  ? `Días compensados: -${detalle.saldoCompensado}`
                                                  : ""}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}

                                      <tr key={-3} className={"pop-tr"}>
                                        <td
                                          className={
                                            "pop-td pop-td-last td-left"
                                          }
                                        >
                                          Resultado
                                        </td>
                                        <td
                                          className={
                                            "pop-td pop-td-last td-right t-bold"
                                          }
                                        >
                                          <div className={"short-border"}></div>
                                          {
                                            this.formRef.current.getFieldsValue(
                                              ["Sugeridos"]
                                            ).Sugeridos
                                          }
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              }
                            >
                              <p style={styleDetalle} className={"detalle"}>
                                Información
                              </p>
                            </Popover>
                          ) : null
                        }
                      >
                        <InputNumber
                          disabled={true}
                          min={0}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>

                      {/* DÍAS OTORGADOS */}
                      <Form.Item
                        name="Otorgados"
                        label="Días de descanso otorgados"
                        className={"inputsFormDescanso_Text"}
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                        style={styleMargin0}
                        extra={[
                          <Popover
                            title={"Información:"}
                            placement={"right"}
                            content={
                              <div className={"popover-div"}>
                                <p className={"info-text"}>
                                  Corresponde a la cantidad de descansos totales
                                  que se le
                                  <br />
                                  otorgan al periodo trabajado.
                                </p>
                              </div>
                            }
                          >
                            <InfoCircleTwoTone
                              className={"info-icon"}
                              twoToneColor={"#48b9fd"}
                            />
                          </Popover>,

                          !isOtorgadosValid ? (
                            <span className={"otorgados-invalid"}>
                              {otorgadosMessage}
                            </span>
                          ) : null,
                        ]}
                      >
                        <InputNumber
                          onChange={(val) => {
                            let { Fechas, Efectivos } =
                              this.formRef.current.getFieldsValue([
                                "Fechas",
                                "Efectivos",
                              ]);

                            if (Efectivos != undefined && Efectivos != null) {
                              this.calcularDias(Fechas);

                              if (disabledInputs3) {
                                this.setState({
                                  disabledInputs3: false,
                                });
                              }
                            }
                          }}
                          disabled={disabledInputs2}
                          min={1}
                          style={{ width: "100%" }}
                          className={
                            isOtorgadosValid ? "" : "otorgados-invalid-input"
                          }
                        />
                      </Form.Item>

                      {/* DÍAS DE DESCANSO EFECTIVO */}
                      <Form.Item
                        name="Efectivos"
                        label="Días de descanso efectivo"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                        style={mostrarInfoEfectivos ? styleMargin0 : ""}
                        extra={
                          <Popover
                            title={"Información:"}
                            placement={"right"}
                            content={
                              <div className={"popover-div"}>
                                <p className={"info-text"}>
                                  Corresponde a la cantidad de días que el
                                  trabajador hará efectivo su descanso
                                  <br />
                                  sin efectuar labores. Si desea compensarle la
                                  totalidad de sus días de descanso,
                                  <br />
                                  dejar este campo en 0.{" "}
                                </p>
                              </div>
                            }
                          >
                            <InfoCircleTwoTone
                              className={"info-icon"}
                              twoToneColor={"#48b9fd"}
                            />
                          </Popover>
                        }
                      >
                        <InputNumber
                          onChange={(val) => {
                            let { Fechas, Otorgados } =
                              this.formRef.current.getFieldsValue([
                                "Fechas",
                                "Otorgados",
                              ]);

                            if (Otorgados != undefined && Otorgados != null) {
                              this.calcularDias(Fechas);

                              if (disabledInputs3) {
                                this.setState({
                                  disabledInputs3: false,
                                });
                              }
                            }
                          }}
                          disabled={disabledInputs2}
                          min={0}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>

                      {/* PERIODO DESCANSO */}
                      {(() => {
                        if (!ocultarFechasDescanso) {
                          return (
                            <Form.Item
                              name="Fechas"
                              label="Fecha de descanso"
                              className="inputsFormDescanso_Text"
                              rules={[
                                {
                                  required: true,
                                  message: "Obligatorio",
                                },
                              ]}
                              
                              extra={
                                this.state.fechasDescanso ? (
                                  <p style={style}>
                                    Desde{" "}
                                    {moment(
                                      this.state.fechasDescanso[0]
                                    ).format("DD-MM-YYYY")}
                                    <br />
                                    Hasta{" "}
                                    {moment(
                                      this.state.fechasDescanso[1]
                                    ).format("DD-MM-YYYY")}
                                  </p>
                                ) : null
                              }
                            >
                              <DatePicker
                                onChange={this.calcularDias}
                                locale={locale}
                                disabled={disabledInputs3}
                                disabledDate={(dia) => {
                                  let diaString = convertirFechaString(dia,1).fechaString;
                                  const fechas = this.state.selectedDates;
                                  if (fechas[0] !== null && fechas[1] !== null) {
                                    if (
                                      moment
                                        .utc(diaString)
                                        .isBetween(
                                          moment.utc(fechas[0]),
                                          moment.utc(fechas[1]),
                                          null,
                                          "[]"
                                        )
                                    ) {
                                      return false;
                                    }
                                  }
                                  return true;
                                }}
                                className={
                                  !disabledInputs3 && disabledInputs4
                                    ? "highlight"
                                    : ""
                                }
                              />
                            </Form.Item>
                          );
                        }
                      })()}

                      {/* FORMULARIO COMPENSACIÓN */}
                      {(() => {
                        if (mostrarFormularioCompensacion) {
                          return (
                            <>
                              {/* ¿DESEA COMPENSAR? */}
                              <Form.Item
                                name="DeseaCompensar"
                                label={
                                  <span style={{ lineHeight: "17px" }}>
                                    ¿Desea compensar días
                                    <br />
                                    restantes?
                                  </span>
                                }
                                className="inputsFormDescanso_Text"
                                rules={[
                                  {
                                    required: true,
                                    message: "Obligatorio",
                                  },
                                ]}
                              >
                                <Radio.Group
                                  disabled={disabledInputs2}
                                  onChange={(e) => {
                                    let val = e.target.value;

                                    if (!val) {
                                      let { Fechas } =
                                        this.formRef.current.getFieldsValue([
                                          "Fechas",
                                        ]);
                                      this.formRef.current.resetFields([
                                        "Compensados",
                                        "montoCompensacion",
                                      ]);

                                      this.calcularDias(Fechas);
                                    }

                                    this.setState({
                                      mostrarDiaMontoCompensacion: val,
                                    });
                                  }}
                                >
                                  <Radio value={true}>Sí</Radio>
                                  <Radio value={false}>No</Radio>
                                </Radio.Group>
                              </Form.Item>

                              {/* DÍAS COMPENSADOS */}
                              <Form.Item
                                name="Compensados"
                                label="Cantidad de días compensados"
                                className={
                                  mostrarDiaMontoCompensacion
                                    ? "inputsFormDescanso_Text"
                                    : "d-none"
                                }
                                rules={[
                                  {
                                    required: mostrarDiaMontoCompensacion,
                                    message: "Obligatorio",
                                  },
                                ]}
                                style={styleMargin0}
                                extra={
                                  <Popover
                                    title={"Información:"}
                                    placement={"right"}
                                    content={
                                      <div className={"popover-div"}>
                                        <p className={"info-text"}>
                                          Cantidad de días que serán compensados
                                          pero que no serán otorgados
                                          <br />
                                          como descanso efectivo.
                                        </p>
                                      </div>
                                    }
                                  >
                                    <InfoCircleTwoTone
                                      className={"info-icon"}
                                      twoToneColor={"#48b9fd"}
                                    />
                                  </Popover>
                                }
                              >
                                <InputNumber
                                  onChange={() => {
                                    if (!disabledInputs4) {
                                      let { Fechas } =
                                        this.formRef.current.getFieldsValue([
                                          "Fechas",
                                        ]);

                                      this.calcularDias(Fechas);
                                    }
                                  }}
                                  disabled={disabledInputs2}
                                  min={1}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>

                              {/* MONTO COMPENSACIÓN */}
                              <Form.Item
                                name="montoCompensacion"
                                label="Monto de compensación"
                                className={
                                  mostrarDiaMontoCompensacion
                                    ? "inputsFormDescanso_Text"
                                    : "d-none"
                                }
                                rules={[
                                  {
                                    required: mostrarDiaMontoCompensacion,
                                    message: "Obligatorio",
                                  },
                                ]}
                                extra={
                                  <Popover
                                    title={"Información:"}
                                    placement={"right"}
                                    content={
                                      <div className={"popover-div"}>
                                        <p className={"info-text"}>
                                          Debe ingresar el monto <b>TOTAL</b> de compensación (no diario).
                                        </p>
                                      </div>
                                    }
                                  >
                                    <InfoCircleTwoTone
                                      className={"info-icon"}
                                      twoToneColor={"#48b9fd"}
                                    />
                                  </Popover>
                                }
                              >
                                <InputNumber
                                  disabled={disabledInputs2}
                                  placeholder="Monto"
                                  min={1}
                                  style={{ width: "100%" }}
                                  formatter={(value) =>
                                    `$ ${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                />
                              </Form.Item>
                            </>
                          );
                        }
                      })()}

                      {/* DÍAS HÁBILES */}
                      <Form.Item
                        name="Habiles"
                        label="Días hábiles del periodo"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                        style={styleMargin0}
                        extra={
                          <Popover
                            title={"Información:"}
                            placement={"right"}
                            content={
                              <div className={"popover-div"}>
                                <p className={"info-text"}>
                                  Cantidad de días hábiles dentro de las fechas
                                  de descanso asignadas.
                                </p>
                              </div>
                            }
                          >
                            <InfoCircleTwoTone
                              className={"info-icon"}
                              twoToneColor={"#48b9fd"}
                            />
                          </Popover>
                        }
                      >
                        <InputNumber
                          disabled={true}
                          min={0}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>

                      {/* DÍAS INHÁBILES */}
                      <Form.Item
                        name="Inhabiles"
                        label="Domingos e inhábiles"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                        style={styleMargin0}
                        extra={
                          <Popover
                            title={"Información:"}
                            placement={"right"}
                            content={
                              <div className={"popover-div"}>
                                <p className={"info-text"}>
                                  Cantidad de días inhábiles dentro de las
                                  fechas de descanso
                                  <br />
                                  asignadas, se incluyen los feriados del
                                  sistema.
                                </p>
                              </div>
                            }
                          >
                            <InfoCircleTwoTone
                              className={"info-icon"}
                              twoToneColor={"#48b9fd"}
                            />
                          </Popover>
                        }
                      >
                        <InputNumber
                          disabled={true}
                          min={0}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>

                      {/* SALDO PENDIENTE */}
                      <Form.Item
                        name="Pendiente"
                        label="Saldo pendiente"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                        style={styleMargin0}
                        extra={
                          <Popover
                            title={"Información:"}
                            placement={"right"}
                            content={
                              <div className={"popover-div"}>
                                <p className={"info-text"}>
                                  Cantidad de días restantes que quedarán para
                                  el periodo
                                  <br />
                                  de trabajo seleccionado.
                                </p>
                              </div>
                            }
                          >
                            <InfoCircleTwoTone
                              className={"info-icon"}
                              twoToneColor={"#48b9fd"}
                            />
                          </Popover>
                        }
                      >
                        <InputNumber
                          disabled={true}
                          min={0}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>

                      {/* Observacion */}
                      <Form.Item
                        name="Observacion"
                        label="Observacion"
                        className="inputsFormDescanso_Text"
                      >
                        <TextArea rows={3} maxLength={200} placeholder="(Opcional)" />
                      </Form.Item>

                      {/* FORMULARIO ADICIONAL */}
                      {(() => {
                        if (mostrarFormularioAdicional) {
                          return (
                            <>
                              {/* CERRAR PERIODO */}
                              <Form.Item
                                name="cerrarPeriodo"
                                label="¿Desea cerrar el periodo?"
                                className="inputsFormDescanso_Text"
                                style={{ marginTop: "25px" }}
                                rules={[
                                  {
                                    required: mostrarFormularioAdicional,
                                    message: "Obligatorio",
                                  },
                                ]}
                              >
                                <Radio.Group
                                  disabled={disabledInputs4}
                                  key={"cerrarPeriodo"}
                                  // onChange={(e) => {

                                  //   let val = e.target.value;

                                  //   let objState = { mostrarDeseaCompensar: val };

                                  //   if (!val) {
                                  //     objState.mostrarMontoCompensacion = false;
                                  //     this.formRef.current.resetFields(['compensarSaldoPendiente', 'montoCompensacion']);
                                  //   }

                                  //   this.setState(objState);
                                  // }}
                                >
                                  <Radio value={true}>Sí</Radio>
                                  <Radio value={false}>No</Radio>
                                </Radio.Group>
                              </Form.Item>

                              {/* DESEA COMPENSAR */}
                              {/* <Form.Item
                                name="compensarSaldoPendiente"
                                label="¿Compensar el saldo pendiente?"
                                className={mostrarDeseaCompensar ? "inputsFormDescanso_Text" : 'd-none'}
                                rules={[
                                  {
                                    required: mostrarDeseaCompensar,
                                    message: 'Obligatorio',
                                  },
                                ]}

                              >
                                <Radio.Group
                                  disabled={disabledInputs4}
                                  key={"compensarSaldoPendiente"}
                                  onChange={(e) => {

                                    let val = e.target.value;
                                    this.setState({ mostrarMontoCompensacion: val });

                                    if (!val) {
                                      this.formRef.current.resetFields(['montoCompensacion']);
                                    }
                                  }}
                                >
                                  <Radio value={true}>Sí</Radio>
                                  <Radio value={false}>No</Radio>
                                </Radio.Group>
                              </Form.Item> */}

                              {/* MONTO COMPENSACIÓN */}
                              {/* <Form.Item
                                name='montoCompensacion'
                                label='Monto de compensación ($)'
                                className={mostrarMontoCompensacion ? "inputsFormDescanso_Text" : 'd-none'}
                                rules={[
                                  {
                                    required: mostrarMontoCompensacion,
                                    message: 'Obligatorio',
                                  },
                                ]}
                              >
                                <InputNumber
                                  disabled={mostrarInputsCompensacion ? false : disabledInputs4}
                                  placeholder="Monto"
                                  min={1}
                                  style={{ width: '100%' }}
                                />
                              </Form.Item> */}
                            </>
                          );
                        }
                      })()}

                      {/* Flag Correo */}
                      <Form.Item
                        name="Correo"
                        label="Enviar mail de notificación"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                      >
                        <Radio.Group
                          onChange={({ target: { value } }) =>
                            this.props.enviarCorreo(value)
                          }
                          disabled={
                            mostrarInputsCompensacion ? false : disabledInputs4
                          }
                        >
                          <Radio value={true}>Sí</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Form>

                    <Row>
                      <Col
                        // className="descansoBtn_guardar"
                        span={24}
                        style={{ marginTop: "24px", textAlign: "right" }}
                      >
                        <Button
                          type="primary"
                          htmlType="submit"
                          form="descanso_trabajador"
                          className={classBotonSubmit}
                          disabled={disabledBotonSubmit}
                        >
                          Guardar
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col
              className="gutter-row"
              span={16}
              xs={24}
              s={24}
              sm={24}
              md={16}
              lg={16}
              xl={16}
            >
              {/* <TimeLineTrabajador
                dobleClickBarra={this.dobleClickBarra}
                mostrarCalendario={this.state.mostrarCalendario}
                bitacora={this.state.bitacora}
                tipo={"5f3eeb78978b284b68ebced4"}
                eliminarRegistro={this.eliminarRegistro}
                descargarRegistro={this.descargarTicket}
                Eliminar={this.state.Eliminar}
              /> */}

              <TimeLineTrabajador
                dobleClickBarra={this.dobleClickBarra}
                mostrarCalendario={this.state.mostrarCalendario}
                bitacora={this.state.bitacora}
                selectedContrato={this.state.selectedContrato}
                selectedDates={this.state.selectedDates}
                selectedDatesTimeline={this.state.selectedDatesTimeline}
                firstDaysOfTheMonths={this.state.firstDaysOfTheMonths}
                actualizarFechaTimeline={this.actualizarFechaTimeline}

                tipo={"5f3eeb78978b284b68ebced4"}
                eliminarRegistro={this.eliminarRegistro}
                descargarRegistro={this.descargarTicket}
                cambiarFechas={this.handleNewDates}
                Eliminar={this.state.Eliminar}
                Ver={this.state.Ver}
                ArrayHistorial={this.state.fechasHistoricasDescanso}
                TituloHistorial={"Dias Descanso"}
                contEstados={this.state.contEstados}
              />
            </Col>
          </Row>
        </Content>

        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          //visible={this.state.modalDescanso}
          onCancel={this.modalCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="40%"
          footer={null}
          style={{ top: "30%" }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <Row>
            <Col span={24} style={{ display: "flex" }} className="title">
              <img
                src={IconDescanso}
                style={{ width: "35px" }}
                alt="icono modal"
              />
              <PageHeader
                style={{ padding: "0px 10px 0px 10px" }}
                className="site-page-header"
                title="Acciones Descansos"
              />
            </Col>
            <Col
              style={{
                textAlign: "center",
                paddingTop: "50px",
                paddingBottom: "50px",
              }}
              span={12}
            >
              <Button
                className="eliminar-habilitado"
                type="primary"
                onClick={() => {
                  this.eliminarRegistro();
                }}
                style={{ marginRight: "20px" }}
              >
                Eliminar registro
              </Button>
            </Col>
            <Col
              span={12}
              style={{
                textAlign: "center",
                paddingTop: "50px",
                paddingBottom: "50px",
              }}
            >
              <Button
                className="btn-verde"
                onClick={() => {
                  this.descargarTicket();
                }}
                //size={size}
                style={{ marginRight: "20px" }}
              >
                Descargar Documento
              </Button>
            </Col>
          </Row>
        </Modal>

        <span>
          <style jsx="true">
            {`
              .ant-input-number-disabled .ant-input-number-input {
                color: #333333 !important;
              }

              .ant-popover-title {
                background-color: #061fcb !important;
                color: white !important;
              }
            `}
          </style>
        </span>
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  DescansoReducer: {
    err,
    loading,
    trabajadores,
    form: { Fechas, Trabajador },
    contratos,
  },
}) => {
  return {
    loading,
    err,
    trabajadores,
    Fechas,
    Trabajador,
    contratos,
  };
};

export default connect(EstadosAPropiedades, descansoActions)(Descanso);
