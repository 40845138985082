import React from "react";
import { Redirect } from "react-router";
import "./Login.css";
import FormularioLogin from "../../components/formularioLogin/FormularioLogin";
import Loading from "../../components/loading/Loading";
import Maintenance from "../../components/maintenance/Maintenance";

import { connect } from "react-redux";
import * as LoginActions from "../../Redux/actions/LoginActions";
import mobileTop from "../../img/login/back-top.png";
import mobileBottom from "../../img/login/back-bottom.png";

//import swal from "sweetalert";

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isVerified: false,
    };

    this.inciarSesion = this.inciarSesion.bind(this);
    this.restablecerPassword = this.restablecerPassword.bind(this);
  }

  inciarSesion = (usuario) => {
    this.props.Login(usuario);
  };

  restablecerPassword = (email) => {
    this.props.RestablecerPassword(email);
  };

  redirectCondicional = () => {

    const localStorageUser = localStorage.getItem("usuario-people-manage");
    const SUPER_ADMINISTRADOR = "5f21ae92d585cd003f997a6a";

    if (localStorageUser) {

      let usuario = JSON.parse(localStorageUser);
      //console.log(usuario.EmpresaSeleccionada.Rol._id)

      if(usuario.EmpresaSeleccionada.Rol._id === SUPER_ADMINISTRADOR){
        return <Redirect to="/Empresas" />;
      }else{
        return <Redirect to="/CentroControl" />;
      }
    }
  };

  render() {
    return (
      <div>

        {this.redirectCondicional()}
        {/* <Maintenance /> */}

        <Loading cargando={this.props.cargando} />

        <div className="fondo-mobile-1">
          <img
            src={mobileTop}
            style={{
              width: '100%',
            }}
          />
        </div>

        <div className="fondo-login">
          <FormularioLogin
            //esRobot={this.esRobot}
            inciarSesion={this.inciarSesion}
            restablecer={this.restablecerPassword}
            redireccionarLogin={this.props.redirecionar}
            verifyCallback={this.verifyCallback}
          />
        </div>

        <div className="fondo-mobile-2">
          <img
            src={mobileBottom}
            style={{
              width: '70%',
              position: 'absolute',
              right: 0,
            }}
          />
        </div>
      </div>
    );
  }
}

const EstadosAPropiedades = ({
  LoginReducer: {
    usuario,
    cargando,
    redirecionar,
  },
}) => {
  return {
    usuario,
    cargando,
    redirecionar,
  };
};

export default connect(
  EstadosAPropiedades,
  LoginActions
)(Login);



// export default Login;
