import React, { useEffect, useState } from "react";
import { Col, Row, DatePicker, Button, Input, Form, Select, Empty, Tooltip } from "antd";

import "./transbordo.css";
import IconCambiarActividad from "../../img/icons/cambiarActividadModal.svg";

import {
    validarFechasModificables,
    obtenerTrabajadorTransbordo,
    obtenerCentrosDeCostoTransbordo,
    obtenerFaenasDestinoTransbordo,
    obtenerActividadesTransbordo,
    obtenerPlazasTransbordo,
    cambioDeActividad,
    transbordoActividad,
    transbordoValidarDisponibilidad
} from './transbordoAPI';

import {
    convertirFechaString
} from "../../funcionesGenericas/fechas";

import Swal from "sweetalert2";

import Loading from "../../components/loading/Loading";

import LocaleES from 'antd/lib/date-picker/locale/es_ES';
import moment from "moment";
import { set } from "@antv/util";

const { RangePicker } = DatePicker;

const Transbordo = (props) => {
    const formRef = Form.useForm();
  
    const [hayTrabajador, setHayTrabajador] = useState(false);
    const idTrabajadorSeleccionado = props.idTrabajador;
    const idCentroCostoTrabajador = props.idCentroCostoOrigen;
    const idActividadOrigenTrabajador = props.idActividadOrigen;
    const nombreActividadOrigenTrabajador = props.nombreActividadOrigen;
    const idPlazaTrabajador = props.idPlazaOrigen;
    const idPuestoPlazaTrabajador = props.idPuestoPlazaOrigen;
    const idRangoFechasPuestoPlazaOrigen = props.idRangoFechasPuestoPlazaOrigen;
    const fechaTrabajoOrigenDesde = moment.utc(props.fechaTrabajoOrigenDesde);
    const fechaTrabajoOrigenHasta = moment.utc(props.fechaTrabajoOrigenHasta);
    const random = props.random;
    const cerrarModal = props.cerrarModal;
    const funcionRecargaInformacion = props.funcionRecargaInformacion;
    const [centroCostoOrigen, setCentroCostoOrigen] = useState({});
    const [destinos, setDestinos] = useState([]);
    const [actividadDestino, setActividadDestino] = useState([]);
    const [validacionTransbordo, setValidacionTransbordo] = useState({});
    const [plazasDestino, setPlazasDestino] = useState([]);
    const [puestoPlaza, setPuestoPlaza] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fechasDisponiblesPuestoPlaza, setFechasDisponiblesPuestoPlaza] = useState([]);
    const [fechasActividadDestinoValidar, setFechasActividadDestinoValidar] = useState([]);
    const [bloquearFechasActividadDestino, setBloquearFechasActividadDestino] = useState(true);
    const [bloquarFechasDisponibilidad, setBloquearFechasDisponibilidad] = useState(true);
    const [diasValidosPlanificar, setDiasValidosPlanificar] = useState([]);

    useEffect(() => {
        async function busquedaTrabajador() {
            let usuario = localStorage.getItem("usuario-people-manage");
            if (usuario !== null && usuario !== "" && usuario !== undefined && usuario !== "undefined") {
                usuario = JSON.parse(usuario);
                const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
                const objetoTransbordo = {};
                objetoTransbordo.idTrabajador = idTrabajadorSeleccionado;
                const infoTrabajador = await obtenerTrabajadorTransbordo(objetoTransbordo);
                const infoCentroCostos = await obtenerCentrosDeCostoTransbordo(idEmpresa);

                const origen = {};
                let destinos = [];
                
                infoCentroCostos.forEach(cc => {
                    if (cc._id.toString() === idCentroCostoTrabajador.toString()) {
                        origen.CentroCosto = cc;
                    } 
                    destinos.push(cc);
                });

                formRef.current.setFieldsValue({
                    nombreTrabajador: infoTrabajador.Nombre + ' ' + infoTrabajador.Apellido_Paterno + ' ' + infoTrabajador.Apellido_Materno,
                    centroCostoOrigen: origen.CentroCosto.Nombre,
                    actividadOrigen: nombreActividadOrigenTrabajador,
                    fechasTrabajoOrigen: [fechaTrabajoOrigenDesde, fechaTrabajoOrigenHasta],
                    destino: undefined,
                    faenaDestino: undefined,
                    plazaDestino: undefined,
                    puestoPlaza: undefined,
                    fechasDisponibles: undefined,
                    fechasRequeridasActividadDestino: undefined,
                    fechasDisponibilidadActividadDestino: undefined,
                });

                setCentroCostoOrigen(origen);
                setDestinos(destinos);
                setActividadDestino([]);
                setPlazasDestino([]);
                setPuestoPlaza([]);
                setFechasDisponiblesPuestoPlaza([]);
                setFechasActividadDestinoValidar([]);
                setBloquearFechasActividadDestino(true);
                setBloquearFechasDisponibilidad(true);
                setValidacionTransbordo({});
            }

        }
        busquedaTrabajador();
    }, [idTrabajadorSeleccionado, random])

    const traerActividades = async () => {

        let objValidacion = {};

        let formREF = formRef.current ? formRef.current : formRef[0];

        formREF.setFieldsValue({
            faenaDestino: undefined,
            plazaDestino: undefined,
            puestoPlaza: undefined,
            fechasDisponibles: undefined,
            fechasRequeridasActividadDestino: undefined,
            fechasDisponibilidadActividadDestino: null
        });

        setActividadDestino([]);
        setPlazasDestino([]);
        setPuestoPlaza([]);
        setFechasDisponiblesPuestoPlaza([]);
        setFechasActividadDestinoValidar([]);
        setBloquearFechasActividadDestino(true);
        setBloquearFechasDisponibilidad(true);
        setValidacionTransbordo({});

        let usuario = localStorage.getItem("usuario-people-manage");
        if (usuario !== null && usuario !== "" && usuario !== undefined && usuario !== "undefined") {
            usuario = JSON.parse(usuario);
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

            const { destino } = formREF.getFieldsValue(['destino']);

            if (destino !== undefined) {
               
                const objetoFaena = {};
                objetoFaena.IdTrabajador = idTrabajadorSeleccionado;
                objetoFaena.IdCentroCosto = destino;


                // const actividades = await obtenerFaenasDestinoTransbordo(idEmpresa, objetoFaena);
                const actividades = await obtenerActividadesTransbordo(idEmpresa, objetoFaena);
             
                if(actividades.length > 0){

                    let actividadesDestino = actividades.filter((actividad) => idActividadOrigenTrabajador.toString() !== actividad._id.toString());

                    actividadesDestino.sort((a,b) => {
                        return new Date(b.Fecha_Inicio) - new Date(a.Fecha_Inicio);
                    });

                    if (actividadesDestino.length > 0) {
                        setActividadDestino(actividadesDestino);
                    } else {
                        objValidacion.mensaje = 'No se encontraron actividades asociadas a los filtros aplicados';
                        objValidacion.color = '#e61958';
                        setValidacionTransbordo(objValidacion);
                    }

                } else {
                    objValidacion.mensaje = 'No se encontraron actividades asociadas a los filtros aplicados';
                    objValidacion.color = '#e61958';
                    setValidacionTransbordo(objValidacion);
                }

            } else {
                objValidacion.mensaje = 'No se ha recibido un centro de costo';
                objValidacion.color = '#e61958';
                setValidacionTransbordo(objValidacion);
            }
        }
    }

    const traerPlazas = async (idActividad) => {

        let objValidacion = {};

        let formREF = formRef.current ? formRef.current : formRef[0];

        formREF.setFieldsValue({
            plazaDestino: undefined,
            puestoPlaza: undefined,
            fechasDisponibles: undefined,
            fechasRequeridasActividadDestino: undefined,
            fechasDisponibilidadActividadDestino: null

        });
        
        setPlazasDestino([]);
        setPuestoPlaza([]);
        setFechasDisponiblesPuestoPlaza([]);
        setFechasActividadDestinoValidar([]);
        setBloquearFechasActividadDestino(true);
        setBloquearFechasDisponibilidad(true);
        setValidacionTransbordo({});

        let usuario = localStorage.getItem("usuario-people-manage");
        if (usuario !== null && usuario !== "" && usuario !== undefined && usuario !== "undefined") {

            usuario = JSON.parse(usuario);
            const { faenaDestino } = formREF.getFieldsValue(['faenaDestino']);

            if(faenaDestino !== undefined){

                if (idActividad) {

                    const actividad = actividadDestino.filter((a) => a._id.toString() === idActividad.toString())[0];
                    let fechaInicioActividad = moment.utc(actividad.Fecha_Inicio);
                    let fechaFinActividad = moment.utc(actividad.Fecha_Fin)

                    const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
                    let formREF = formRef.current ? formRef.current : formRef[0];
                    const { destino } = formREF.getFieldsValue(['destino', 'fechasRequeridasActividadDestino']);
        
                    const objetoFaena = {};
                    objetoFaena.IdTrabajador = idTrabajadorSeleccionado;
                    objetoFaena.IdCentroCosto = destino;
                    objetoFaena.IdActividad = idActividad;
    
                    const plazas = await obtenerPlazasTransbordo(idEmpresa, objetoFaena);

                    if (plazas.length > 0) {
                        setPlazasDestino(plazas);
                        setFechasActividadDestinoValidar([fechaInicioActividad, fechaFinActividad]);
                    } else {
                        objValidacion.mensaje = 'No se han encontrado plazas relacionadas en la actividad';
                        objValidacion.color = '#e61958';
                        setValidacionTransbordo(objValidacion);
                    }
        
                } else {
                    objValidacion.mensaje = 'No se ha recibido una actividad valida';
                    objValidacion.color = '#e61958';
                    setValidacionTransbordo(objValidacion);
                }
            } else {
                objValidacion.mensaje = 'No se ha recibido una actividad de destino';
                objValidacion.color = '#e61958';
                setValidacionTransbordo(objValidacion);
            }
        }
    }

    const traerPuestoPlaza = (idPlaza) => {

        let objValidacion = {};

        let formREF = formRef.current ? formRef.current : formRef[0];

        formREF.setFieldsValue({
            puestoPlaza: undefined,
            fechasDisponibles: undefined,
            fechasRequeridasActividadDestino: undefined
        });

        setPuestoPlaza([]);
        setFechasDisponiblesPuestoPlaza([]);
        setBloquearFechasActividadDestino(true);
        setValidacionTransbordo({});

        if (idPlaza) {

            if (plazasDestino.length > 0) {

                const plaza = plazasDestino.filter((plaza) => plaza._id.toString() == idPlaza.toString())[0];
                let objValidacion = {};

                if (plaza) {

                    if (plaza.TieneError) {
                        objValidacion.mensaje = plaza.MensajeError;
                        objValidacion.color = '#e61958';
                        setValidacionTransbordo(objValidacion);
                    } else {
                        const puestosPlaza = plaza.PuestosPlaza ? plaza.PuestosPlaza : [];
                        let puestos = [];
                        puestosPlaza.forEach((puestoPlaza, index) => {
                            puestos.push({ 
                                "Puesto": index + 1, 
                                "_id": puestoPlaza._id,
                                "FechasDisponibilidad": puestoPlaza.FechasDisponibilidad
                            });
                        });
                        setPuestoPlaza(puestos);
                    }
                } else {
                    objValidacion.mensaje = 'No se ha encontrado una plaza relacionada';
                    objValidacion.color = '#e61958';
                    setValidacionTransbordo(objValidacion);
                }

            } else {
                objValidacion.mensaje = 'No se ha encontrado una plaza';
                objValidacion.color = '#e61958';
                setValidacionTransbordo(objValidacion);
            }
        } else {
            objValidacion.mensaje = 'No se ha recibido una plaza valida';
            objValidacion.color = '#e61958';
            setValidacionTransbordo(objValidacion);
        }

    }

    const traerFechasDisponibles = (idPuestoPlaza) => {

        let objValidacion = {};

        let formREF = formRef.current ? formRef.current : formRef[0];

        formREF.setFieldsValue({
            fechasDisponibles: undefined,
            fechasRequeridasActividadDestino: undefined,
            fechasDisponibilidadActividadDestino:null
        });

        setFechasDisponiblesPuestoPlaza([]);
        setBloquearFechasActividadDestino(true);
        setBloquearFechasDisponibilidad(true);
        setValidacionTransbordo({});

        if (idPuestoPlaza) {
            if(puestoPlaza.length > 0){
                const puesto = puestoPlaza.filter((puesto) => puesto._id.toString() == idPuestoPlaza.toString())[0];
                if(puesto.FechasDisponibilidad && puesto.FechasDisponibilidad.length > 0){
                    setFechasDisponiblesPuestoPlaza(puesto.FechasDisponibilidad)
                    setBloquearFechasActividadDestino(true)
                } else { 
                    setBloquearFechasActividadDestino(false)
                }
            }
        } else {
            formRef.current.setFieldsValue({
                fechasDisponibles: undefined
            });
            setFechasDisponiblesPuestoPlaza([]);
        }
    }

    const cambiarFechasBloqueadasActividadDestino = (idFechasDisponiblePuestoPlaza) => {

        let objValidacion = {};

        let formREF = formRef.current ? formRef.current : formRef[0];

        formREF.setFieldsValue({
            fechasRequeridasActividadDestino: undefined
        });

        setBloquearFechasActividadDestino(true);
        setValidacionTransbordo({});

        if(idFechasDisponiblePuestoPlaza){
            let fechas = fechasDisponiblesPuestoPlaza.filter((fecha) => fecha._id.toString() === idFechasDisponiblePuestoPlaza.toString())[0];
            let fechaInicio = moment.utc(fechas.FechaInicio);
            let fechaFin = moment.utc(fechas.FechaFin);
            setBloquearFechasActividadDestino(false);
            setFechasActividadDestinoValidar([fechaInicio, fechaFin]);
        }
    }

    const validarFechasTransbordo = async () => {
        let usuario = localStorage.getItem("usuario-people-manage");
        if (usuario !== null && usuario !== "" && usuario !== undefined && usuario !== "undefined") {
            usuario = JSON.parse(usuario);
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
            const idTrabajador = idTrabajadorSeleccionado;
            const { fechasTrabajoOrigen, fechasRequeridasActividadDestino } = formRef.current.getFieldsValue(['fechasTrabajoOrigen', 'fechasRequeridasActividadDestino']);

            if (
                fechasTrabajoOrigen !== undefined ||
                fechasTrabajoOrigen !== null ||
                fechasRequeridasActividadDestino !== undefined ||
                fechasRequeridasActividadDestino !== null
            ) {
                const compareDate = moment(fechasRequeridasActividadDestino[0], "DD/MM/YYYY");
                const startDate = moment(fechasTrabajoOrigen[0], "DD/MM/YYYY");
                const endDate = moment(fechasTrabajoOrigen[1], "DD/MM/YYYY");
                const comparacion = compareDate.isBetween(startDate, endDate);

                if (comparacion) {

                    const startDateDestination = convertirFechaString(fechasRequeridasActividadDestino[0].clone(),1).fechaString;
                    const endDateOrigin = convertirFechaString(fechasTrabajoOrigen[1].clone(),2).fechaString;

                    let objetoValidarFechasModificables = {};
                    objetoValidarFechasModificables.ID_TRABAJADOR = idTrabajador;
                    objetoValidarFechasModificables.FECHAS_ACTIVIDAD_ORIGEN = [startDateDestination, endDateOrigin];

                    let objValidacion = {};

                    const validarFechas = await validarFechasModificables(idEmpresa, objetoValidarFechasModificables);
                    if (validarFechas.status === 200 || validarFechas.status === 201) {
                        return true;
                    } else if (validarFechas.status === 409) {
                        objValidacion.mensaje = validarFechas.error;
                        objValidacion.color = '#e61958';
                        return objValidacion;
                    } else if (validarFechas.status === 500) {
                        objValidacion.mensaje = 'No se logro enviar la solicitud';
                        objValidacion.color = '#e61958';
                        return objValidacion;
                    } else {
                        objValidacion.mensaje = 'Error no controlado';
                        objValidacion.color = '#e61958';
                        return objValidacion;
                    }
                } else {
                    return true;
                }
            }
        }
    }

    const generarTransbordo = async (values) => {
        setLoading(true);
        let usuario = localStorage.getItem("usuario-people-manage");
        if (usuario !== null && usuario !== "" && usuario !== undefined && usuario !== "undefined") {
            usuario = JSON.parse(usuario);
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
            if (
                values.destino !== undefined &&
                values.faenaDestino !== undefined &&
                values.fechasRequeridasActividadDestino !== undefined &&
                values.fechasRequeridasActividadDestino !== null &&
                values.fechasDisponibilidadActividadDestino !== undefined &&
                values.fechasDisponibilidadActividadDestino !== null &&
                values.plazaDestino !== undefined &&
                values.puestoPlaza !== undefined 
            ) {


                let strInicioActividadOrigen = values.fechasTrabajoOrigen[0].format('YYYY-MM-DD');
                const fechaInicioActividadOrigen = convertirFechaString(moment(strInicioActividadOrigen),1).fechaString;

                let strFinActividadOrigen = values.fechasTrabajoOrigen[1].format('YYYY-MM-DD');
                const fechaFinActividadOrigen = convertirFechaString(moment(strFinActividadOrigen),2).fechaString;

                let strInicioFechaRequeridaActividadDestino = values.fechasRequeridasActividadDestino[0].format('YYYY-MM-DD');
                const fechaRequeridaInicioActividadDestino = convertirFechaString(moment(strInicioFechaRequeridaActividadDestino),1).fechaString;

                let strFinFechaRequeridaActividadDestino = values.fechasRequeridasActividadDestino[1].format('YYYY-MM-DD');
                const fechaRequeridaFinActividadDestino = convertirFechaString(moment(strFinFechaRequeridaActividadDestino),2).fechaString;

                let strInicioFechaDisponibilidadActividadOrigen = values.fechasDisponibilidadActividadDestino[0].format('YYYY-MM-DD');
                const fechaDisponibilidadInicioActividadOrigen = convertirFechaString(moment(strInicioFechaDisponibilidadActividadOrigen), 1).fechaString;

                let strFinFechaDisponibilidadActividadDestino = values.fechasDisponibilidadActividadDestino[1].format('YYYY-MM-DD');
                const fechaDisponibilidadFinActividadDestino = convertirFechaString(moment(strFinFechaDisponibilidadActividadDestino), 2).fechaString;

                //Validacion cambio de actividad
                let objetoTransbordoActividad = {};
                objetoTransbordoActividad.idTrabajador = idTrabajadorSeleccionado;
                objetoTransbordoActividad.idFaenaOrigen = idActividadOrigenTrabajador;
                objetoTransbordoActividad.idPuestoPlazaTrabajadorOrigen = idPuestoPlazaTrabajador;
                objetoTransbordoActividad.idRangoFechasPuestoPlazaOrigen = idRangoFechasPuestoPlazaOrigen;
                objetoTransbordoActividad.idFaenaDestino = values.faenaDestino;
                objetoTransbordoActividad.idPlazaDestino = values.plazaDestino;
                objetoTransbordoActividad.idPuestoPlazaDestino = values.puestoPlaza;
                objetoTransbordoActividad.fechasActividadOrigen = [fechaInicioActividadOrigen, fechaFinActividadOrigen];
                objetoTransbordoActividad.fechasRequeridas = [fechaRequeridaInicioActividadDestino, fechaRequeridaFinActividadDestino];
                objetoTransbordoActividad.fechasDisponibilidad = [fechaDisponibilidadInicioActividadOrigen, fechaDisponibilidadFinActividadDestino];
                objetoTransbordoActividad.idPlazaOrigen = idPlazaTrabajador;

                Swal.fire({
                    title: "Confirmar",
                    text: `¿Estás seguro que quieres realizar el cambio de actividad?`,
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonColor: "#34acf3",
                    confirmButtonColor: "#e800a4",
                    cancelButtonText: "Cancelar",
                    confirmButtonText: "Continuar",
                }).then(async (result) => {
                    if (result.isConfirmed) {

                        const transbordo = await transbordoActividad(idEmpresa, objetoTransbordoActividad);
                        if (transbordo.status === 200 || transbordo.status === 201) {

                            setLoading(false);

                            // let formREF = formRef.current ? formRef.current : formRef[0];

                            // formREF.setFieldsValue({
                            //     faenaDestino: undefined,
                            //     plazaDestino: undefined,
                            //     puestoPlaza: undefined,
                            //     fechasDisponibles: undefined,
                            //     fechasRequeridasActividadDestino: undefined,
                            //     fechasDisponibilidadActividadDestino: null
                            // });
                    
                            Swal.fire({
                                title: "Proceso exitoso!",
                                icon: "success",
                                confirmButtonColor: "#e800a4",
                                confirmButtonText: "Si",
                            }).then(async (result) => {

                                if (result.isConfirmed) {

                                    cerrarModal();
                                    funcionRecargaInformacion();

                                }

                            })

                        }

                    }
                })

            } else {
                setLoading(false);
                Swal.fire({
                    title: "Ups...",
                    text: `Debe completar todo el formulario para realizar la acción`,
                    icon: "warning",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "Continuar",
                });
            }
        }
    }

    const setearValoresValidacionFecha = (validacionTransbordo) => {
        let formREF = formRef.current ? formRef.current : formRef[0];
        formREF.setFieldsValue({
            faenaDestino: undefined,
            plazaDestino: undefined,
            puestoPlaza: undefined
        });
        setActividadDestino([]);
        setPlazasDestino([]);
        setPuestoPlaza([]);
        setValidacionTransbordo(validacionTransbordo);
    }

    const busquedaDisponibilidadDelTrabajador = async (fechas) => {
        setLoading(true);
        let usuario = localStorage.getItem("usuario-people-manage");
        if (usuario !== null && usuario !== "" && usuario !== undefined && usuario !== "undefined") {
            usuario = JSON.parse(usuario);
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

            if(Array.isArray(fechas) && fechas[0] && fechas[1]){

                let formREF = formRef.current ? formRef.current : formRef[0];
                //console.log(formREF.getFieldValue());
                const { puestoPlaza , plazaDestino ,faenaDestino: actividadDestino , fechasDisponibles } = formREF.getFieldValue();

                //Generamos las fechas convertirlas en fechas string con el formato correcto
                let startDate = moment(fechas[0], "DD/MM/YYYY");
                let endDate = moment(fechas[1], "DD/MM/YYYY");
                startDate = convertirFechaString(moment.utc(startDate),1).fechaString;
                endDate = convertirFechaString(moment.utc(endDate),2).fechaString;

                const body = {
                    idActividad: actividadDestino,
                    idPlaza: plazaDestino,
                    fechaInicio: startDate,
                    fechaFin: endDate,
                    idPuestoPlaza: puestoPlaza,
                    // idPlazaBit: 61f2e16654c48537a4fb33f8 ????? 
                    IdRangoFechas: fechasDisponibles,
                    IdTrabajador: idTrabajadorSeleccionado
                }

                const response = await transbordoValidarDisponibilidad(idEmpresa,body);

                if(response.status === 200){
                    const data = response.data.data;
                    console.log(data);
                    if(data.isDisponible){
                        setLoading(false);
                        setBloquearFechasDisponibilidad(false);
                        setDiasValidosPlanificar(data.arrayDiasDisponibles);
                    }else{
                        console.log(data.texto);
                        setLoading(false);
                        setBloquearFechasDisponibilidad(true);
                        setDiasValidosPlanificar([]);
                        Swal.fire({
                            title: "Ups...",
                            text: data.texto,
                            icon: "warning",
                            confirmButtonColor: "#E60AA3",
                            confirmButtonText: "Continuar",
                        });
                    }
                }else{
                    setLoading(false);
                    Swal.fire({
                        title: "Ups...",
                        text: `Ocurrio un error al buscar la información`,
                        icon: "warning",
                        confirmButtonColor: "#E60AA3",
                        confirmButtonText: "Continuar",
                    });
                }
            }
        }else{
            setLoading(false);
            Swal.fire({
                title: "Ups...",
                text: `Se perdio la seción como usuario`,
                icon: "warning",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "Continuar",
            });
        }
    }
    return (
        <>
            <Loading cargando={loading} />
            <div style={{ paddingBottom: '5%' }}>
                <div className="contenedorTituloModalTransbordo">
                    <img src={IconCambiarActividad} style={{ marginLeft: '2%', marginRight: '2%', width: '35px' }} />
                    <label className="tituloModalTransbordo" style={{ marginTop: '60px !important' }}>Cambiar de actividad</label>
                </div>
                <div className="contenedorModalTransbordo">
                    <Form ref={formRef} onFinish={generarTransbordo} name="formCambioActividad">
                        <div className="contenidoModalTransbordo">
                            <Row gutter={[15, 15]}>
                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    Nombre Trabajador
                                </Col>
                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle name="nombreTrabajador">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    Centro De Costo De Origen
                                </Col>
                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle name="centroCostoOrigen">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    Actividad De Origen
                                </Col>
                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle name="actividadOrigen">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>

                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    Fechas de trabajo en la actividad de Origen
                                </Col>
                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle name="fechasTrabajoOrigen">
                                        <RangePicker
                                            format={"DD-MM-YYYY"}
                                            locale={LocaleES}
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    Centro de costo destino
                                </Col>
                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle name="destino">
                                        <Select
                                            placeholder="Destino"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            onChange={() => traerActividades()}
                                        >
                                            {destinos.map((value, index) => (
                                                <Select.Option key={index} value={value._id}>
                                                    {value.Nombre}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    Actividad
                                </Col>
                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle name="faenaDestino">
                                        

                                        <Select
                                            placeholder="Actividad destino"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            disabled={actividadDestino.length === 0 ? true : false}
                                            onChange={(value) => traerPlazas(value)}
                                        >
                                            {actividadDestino.map((value, index) => (
                                                <Select.Option key={index} value={value._id}>
                                                    <Tooltip title={value.Nombre + " " + convertirFechaString(moment.utc(value.Fecha_Inicio), 1).fechaDDMMYYYY + " - " + convertirFechaString(moment.utc(value.Fecha_Fin), 2).fechaDDMMYYYY}>
                                                        {value.Nombre} ({convertirFechaString(moment.utc(value.Fecha_Inicio), 1).fechaDDMMYYYY} - {convertirFechaString(moment.utc(value.Fecha_Fin), 2).fechaDDMMYYYY})
                                                    </Tooltip>
                                                </Select.Option> 
                                            ))}

                                        </Select>
                                    </Form.Item>
                                </Col>
                                
                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    Plazas
                                </Col>
                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle name="plazaDestino">
                                        <Select
                                            placeholder="Plaza destino"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            disabled={plazasDestino.length === 0 ? true : false}
                                            onChange={(value) => traerPuestoPlaza(value)}
                                        >
                                            {plazasDestino.map((value, index) => (
                                                <Select.Option key={index} value={value._id}>
                                                    {value.NombrePlaza}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    Puesto Plaza
                                </Col>
                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle name="puestoPlaza">
                                        <Select
                                            placeholder="Puesto plaza"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            disabled={puestoPlaza.length === 0 ? true : false}
                                            onChange={(value) => traerFechasDisponibles(value)}
                                        >
                                            {puestoPlaza.map((value, index) => (
                                                <Select.Option key={index} value={value._id}>
                                                    Puesto Plaza {value.Puesto}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {fechasDisponiblesPuestoPlaza.length > 0 && 
                                
                                    (<>
                                        <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                            Fechas disponibles
                                        </Col>
                                        <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item noStyle name="fechasDisponibles">
                                                <Select
                                                    placeholder="Fechas disponibles"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    disabled={fechasDisponiblesPuestoPlaza.length === 0 ? true : false}
                                                    onChange={(value) => cambiarFechasBloqueadasActividadDestino(value)}
                                                >
                                                    {fechasDisponiblesPuestoPlaza.map((value, index) => (
                                                        <Select.Option key={index} value={value._id}>
                                                            {convertirFechaString(moment.utc(value.FechaInicio), 1).fechaDDMMYYYY} - {convertirFechaString(moment.utc(value.FechaFin), 2).fechaDDMMYYYY}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </>)
                                }

                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    {/* Fechas de trabajo en la actividad de Destino */}
                                    Fechas Requeridas
                                </Col>
                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        // noStyle
                                        name="fechasRequeridasActividadDestino"
                                    >
                                        <RangePicker
                                            disabled={bloquearFechasActividadDestino}
                                            locale={LocaleES}
                                            onChange={(dates)=>busquedaDisponibilidadDelTrabajador(dates)}
                                            //defaultValue={[moment.utc(fechasActividadDestinoValidar[0]),moment.utc(fechasActividadDestinoValidar[1])]}
                                            // onChange={async (value) => {
                                            //     if (value === null || value === undefined) {
                                            //         setearValoresValidacionFecha({});
                                            //     } else {
                                            //         let validacionFecha = await validarFechasTransbordo();
                                            //         if (validacionFecha === Boolean(true)) {
                                            //             traerFaenas();
                                            //         } else {
                                            //             setearValoresValidacionFecha(validacionFecha);
                                            //         }
                                            //     }
                                            // }}
                                            disabledDate={(dia) => {

                                                let diaString = convertirFechaString(dia,1).fechaString;

                                                if(fechasActividadDestinoValidar.length > 0){
                                                    if (
                                                        moment
                                                          .utc(diaString)
                                                          .isBetween(
                                                            moment.utc(fechasActividadDestinoValidar[0]),
                                                            moment.utc(fechasActividadDestinoValidar[1]),
                                                            null,
                                                            "[]"
                                                          )
                                                      ) {
                                                        return false;
                                                      }
                                                }

                                                return true;

                                            }}
                                            format={"DD-MM-YYYY"}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    {/* Fechas de trabajo en la actividad de Destino */}
                                    Fechas Disponibles
                                </Col>
                                <Col xs={12} s={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        // noStyle
                                        name="fechasDisponibilidadActividadDestino"
                                    >
                                        <RangePicker
                                            disabled={bloquarFechasDisponibilidad}
                                            locale={LocaleES}
                                            onChange={(dates)=>{
                                                if(dates){
                                                    const fechaInicio = convertirFechaString(dates[0],1).fechaString;
                                                    const fechaFin = convertirFechaString(dates[1],2).fechaString;
                                                    const cantDias = moment.utc(fechaFin).diff(moment.utc(fechaInicio),'days')+1;
                                                    const cantCorrectos = diasValidosPlanificar.filter((dRelevo)=> moment.utc(dRelevo).isBetween(moment.utc(fechaInicio),moment.utc(fechaFin),null,"[]")).length;
                                                    let objValidacion = {};
                                                    if(cantDias === cantCorrectos){
                                                        objValidacion.mensaje = '';
                                                        objValidacion.color = '';
                                                        setValidacionTransbordo(objValidacion);
                                                        
                                                    }else{
                                                        let formREF = formRef.current ? formRef.current : formRef[0];
                                                        objValidacion.mensaje = 'Debe ingresar fechas correctas';
                                                        objValidacion.color = '#e61958';
                                                        setValidacionTransbordo(objValidacion);
                                                        formREF.setFieldsValue({
                                                            fechasDisponibilidadActividadDestino: null
                                                        });
                                                    }
                                
                                                }
                                            }}
                                            disabledDate={(current) => {
                                                let fechaEncontrada = diasValidosPlanificar.filter(
                                                    (dRelevo) =>
                                                    current.format("DD-MM-YYYY") ===
                                                    moment.utc(dRelevo).format("DD-MM-YYYY")
                                                );
                                                if (fechaEncontrada.length > 0) {
                                                return false;
                                                } else {
                                                return true;
                                                }

                                            }}
                                            format={"DD-MM-YYYY"}
                                        />
                                    </Form.Item>
                                    <div className="ant-form-item-explain" style={Object.keys(validacionTransbordo).length === 0 ? { 'color': 'black' } : { 'color': validacionTransbordo.color }}>
                                        {Object.keys(validacionTransbordo).length === 0 ? '' : validacionTransbordo.mensaje}
                                    </div>
                                </Col>

                                
                            </Row>
                        </div>
                        <div>
                            <Button type="primary" htmlType="submit" form="formCambioActividad" className="button-guardar-modalTransbordo">
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Transbordo;