import React from "react";
import "../Empresion.css";
import byLogo from "../../img/logos/logo-by-people-managment.svg";
import { Row, Col, Layout, Card } from "antd";
import Loading from "../../components/loading/Loading";
import API from "../../Redux/Api/api";
import moment from "moment"

const queryString = require('query-string');

class LicenciaImpresion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderDocumento: false,
      loading: true,
      informacionLicencia: null,
    };
  }

  componentDidMount = async () => {
    const parsed = queryString.parse(this.props.location.search);
    let idLicencia = parsed.Licencia;

    let respuesta = await this.buscarLicencia(idLicencia);

    if (respuesta.status === 200) {
      let objLicencia = respuesta.json.data;

      console.log(objLicencia);

      let informacionLicencia = {
        Nombre_trabajador: `${objLicencia.Trabajador.Nombre} ${objLicencia.Trabajador.Apellido_Paterno} ${objLicencia.Trabajador.Apellido_Materno}`,
        Nombre_Cargo: `${objLicencia.Trabajador.Cargo.Nombre}`,
        Termino_Operacion: `${moment.utc(objLicencia.Termino).format("DD-MM-YYYY")}`,
        Fecha_Inicio_Hasta: `Desde: ${moment(objLicencia.Fecha_Inicio).utc().format("DD-MM-YYYY")} Hasta: ${moment(objLicencia.Fecha_Fin).utc().format("DD-MM-YYYY")}`,
        Tipo_Licencia: objLicencia.Tipo,
        Imagen_Empresa: objLicencia.Trabajador.Empresa.Imagen,
      }

      this.setState({
        informacionLicencia: informacionLicencia,
        renderDocumento: true,
        loading: false,
      });

      setTimeout(function () {
        window.print();
      }, 1000);

    }
  }

  buscarLicencia = async (idLicencia) => {
    let respuesta = {
      status: 0,
      json: null,
    }
    try {

      const url = `${API}/Licencia/${idLicencia}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      const data = await response.json();

      respuesta.status = status;
      respuesta.json = data;

      return respuesta;

    } catch (error) {
      respuesta.status = -1;
      respuesta.json = error;
      return respuesta;
    }
  }

  Pintar() {
    if (this.state.renderDocumento === true) {
      return (
        <Layout id="divEmpresion" className="layoutImpresion">
          <Row
            style={{
              paddingTop: "30px",
            }}
          >
            <Col span={18}>
              <Card className="CardMaestra">
                <Card className="CardHeader">
                  <h3 className="ImpresionTitulo">
                    <img
                      src={this.state.informacionLicencia.Imagen_Empresa}
                      className="ImpresionLogoEmpresa"
                      alt="Logo empresa"
                    />{" "}
                    Comprobante licencia médica
                  </h3>
                </Card>
                <Row
                  style={{
                    marginTop: "30px",
                    padding: "30px",
                  }}
                >
                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={6}
                  >
                    <h5 className="ImprimirNombreCampo">Nombre trabajador: </h5>
                  </Col>
                  <Col span={18}>
                    <h5 className="ImprimirValorCampo">{this.state.informacionLicencia.Nombre_trabajador}</h5>
                  </Col>

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={6}
                  >
                    <h5 className="ImprimirNombreCampo">Cargo: </h5>
                  </Col>
                  <Col span={18}>
                    <h5 className="ImprimirValorCampo">{this.state.informacionLicencia.Nombre_Cargo}</h5>
                  </Col>

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={6}
                  >
                    <h5 className="ImprimirNombreCampo">Término Operación: </h5>
                  </Col>
                  <Col span={18}>
                    <h5 className="ImprimirValorCampo">{this.state.informacionLicencia.Termino_Operacion}</h5>
                  </Col>

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={6}
                  >
                    <h5 className="ImprimirNombreCampo">¿Qué licencia es?: </h5>
                  </Col>
                  <Col span={18}>
                    <h5 className="ImprimirValorCampo">{this.state.informacionLicencia.Tipo_Licencia}</h5>
                  </Col>

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={6}
                  >
                    <h5 className="ImprimirNombreCampo">
                      Inicio y Término de licencia:{" "}
                    </h5>
                  </Col>
                  <Col span={18}>
                    <h5 className="ImprimirValorCampo">
                      {this.state.informacionLicencia.Fecha_Inicio_Hasta}
                    </h5>
                  </Col>
                </Row>
                <Card className="CardFirma">
                  <Row>
                    <Col className="contFirmas" span={12}>
                      <hr />
                      <h3 className="NombresFirma">FIRMA DEL EMPLEADOR</h3>
                    </Col>
                    <Col className="contFirmas" span={12}>
                      <hr />

                      <h3 className="NombresFirma">FIRMA DEL TRABAJADOR</h3>
                    </Col>
                  </Row>
                </Card>
              </Card>
              <Row
                style={{
                  paddingTop: "20px",
                  width: "700px",
                }}
              >
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <img src={byLogo} alt="by people managment" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return false;
    }
  }

  render() {
    return (<>
      <Loading cargando={this.state.loading} />
      {this.Pintar()}
    </>);
  }
}

export default LicenciaImpresion;