import React, { Component } from "react";
// import embarcado from "../../img/icons/embarcado.svg";
// import descanso from "../../img/icons/descanso.svg";
// import vacaciones from "../../img/icons/vacaciones.svg";

import vacaciones2 from "../../img/icons/vacacionesClaro.svg";
import vacaciones2Finiquitado from "../../img/icons/vacacionesFiniquitado.svg";

// import licencia from "../../img/icons/Licencia.svg";
// import accidente from "../../img/icons/accidente.svg";
// import permiso from "../../img/icons/Permiso.svg";
// import ausente from "../../img/icons/Ausente.svg";
// import enTransito from "../../img/icons/enTransito.svg";

import TrabajoEfectivo from "../../img/icons-nuevos/TrabajoEfectivo.svg";
import Descanso from "../../img/icons-nuevos/Descanso.svg";
import EnTransito from "../../img/icons-nuevos/EnTransito.svg";
import Vacaciones from "../../img/icons-nuevos/Vacaciones.svg";
import Licencia from "../../img/icons-nuevos/Licencia.svg";
import LicenciaMutualidad from "../../img/icons-nuevos/LicenciaMutualidad.svg";
import Permiso from "../../img/icons-nuevos/Permiso.svg";
import Ausente from "../../img/icons-nuevos/Ausente.svg";
import NoAsignado from "../../img/icons-nuevos/NoAsignado.svg";

import Avatar from "../../img/icons/avatar.svg";
import AvatarFiniquitado from "../../img/icons/avatarFiniquitado.svg";

import Carpeta from "../../img/icons/Carpeta.svg";
import CarpetaFiniquitado from "../../img/icons/CarpetaFiniquitado.svg";

import Proteccion from "../../img/icons/Proteccion.svg";
import ProteccionFiniquitado from "../../img/icons/ProteccionFiniquitado.svg";

import noAsignado from "../../img/icons/NoAsignado.svg";
import Reloj from "../../img/icons/Reloj.svg";
import {
  Layout,
  Col,
  Row,
  Card,
  DatePicker,
  Modal,
  Badge,
  Tooltip,
  Steps,
  Popover,
  Button,
  Typography,
} from "antd";

import "./CalendarioTrabajadorNew.css";
import Loading from "../../components/loading/Loading";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";
///NUEVO DESPUES DEL MAQUETEADO
import { connect } from "react-redux";
import * as CalendarioTrabajadorActions from "../../Redux/actions/CalendarioTrabajadorActions";
import moment from "moment";
import { convertirFechaString } from "../../funcionesGenericas/fechas";
import accesosPagina from '../../funcionesGenericas/accesosUsuario';
import Swal from "sweetalert2";

// import FormularioTrabajadorVisualizar from "../../components/fichaTrabajador/FichaTrabajador";//Ficha Trabajador
// import FormularioTrabajadorEditar from "../../components/formTrabajador/FormularioTrabajadorEditar";//Ficha Trabajador
import FormularioTrabajadorVisualizar from "../../components/fichaTrabajador/FichaTrabajadorNuevo";//Ficha Trabajador
import FormularioTrabajadorEditar from "../../components/formTrabajador/FormularioTrabajadorEditarNuevo";//Ficha Trabajador
import FormularioFichaProfesional from "../../components/formularioFichaProfesional/FormularioFichaProfesional";//Ficha Profesional
import FormularioEntregaEPP from "../../components/formularioEntregaEPP/FormularioEntregaEPP";//Epp
import FormularioRegistroVacaciones from "../../components/formularioRegistroVacaciones/FormularioRegistroVacaciones";//Registro Vacaciones
import FichaBonificaciones from "../../components/bonificaciones/FichaBonificaciones";//Bonificaciones
import FichaContratos from "../../components/contratos/FichaContratos";//Contratos
const queryString = require("query-string");



const { Header, Content } = Layout;
const { RangePicker } = DatePicker;
const { Step } = Steps;
const customDot = (dot, { status, index }) => (
  <Popover
    overlayInnerStyle={{border: "2px solid #7C8DFC", borderRadius: "9px",fontFamily:"MavenPro-SemiBold",fontSize:"12px"}}
    content={
      <span>
        {status}
      </span>
    }
  >
    {dot}
  </Popover>
);

class CalendarioTrabajadorNew extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Perfil: [],
      BitacorasTimeLine: [],
      IndicadoresTimeLine: [],
      IndicadoresOtrasCondiciones: [],
      DetalleDescansoTimeLine: [],
      IdTrabajador:"",
      IdEmpresa:"",
      selectedFechaInicio: moment.utc().startOf("month"),
      selectedFechaFin: moment.utc().endOf("month"),
      //Nuevo Arriba
      Loading: true,
      modalFicha: false,
      modalProfesional: false,
      modalEntregaEpp: false,
      modalDocumentos: false,
      modalDetalleOtrasCondiciones: false,

      //detalle
      eppDetalle: false,

      contratos: [],
      cargos: [],
      regiones: [],
      comunas: [],
      provincias: [],
      titulos: [],
      especialidades: [],
      epps: [],
      regimenes: [],
      documentos: [],

      ciudadesSeleccionadas: [],
      comunasSeleccionadas: [],

      editarCerrado: true,

      //arreglo con fechas por defecto
      fechasBusqueda: [],
      fechaCalendarioTrabajador: [moment.utc().startOf('month'), moment.utc().endOf('month')],

      //descansos dados
      bonificacionesDescansos: [],
      arregloFechas: [convertirFechaString(moment.utc().startOf('month'), 1).fechaString]
    };

  }

  async componentDidMount() {

    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      let parametros = queryString.parse(this.props.location.search);

      let inicioFiltro;
      let finFiltro;
      inicioFiltro = parametros.inicio != undefined ? moment.utc(parametros.inicio).startOf("month") : moment.utc().startOf("month");
      finFiltro = parametros.fin != undefined ? moment.utc(parametros.fin).endOf("month") : moment.utc().endOf("month");

      let firstDay = convertirFechaString(inicioFiltro, 1).fechaString;
      let lastDay = convertirFechaString(finFiltro, 2).fechaString;
      let trabajador = await this.props.ObtenerTrabajador(parametros.Trabajador);

      //agregado rapido verificar
      await this.props.ObtenerEstados();
      await this.props.ObtenerBitacora(parametros.Trabajador, firstDay, lastDay);
      await this.props.ObtenerEPPSbitacora(parametros.Trabajador, idEmpresa);
      await this.props.ObtenerBitacoraProfesional(parametros.Trabajador, idEmpresa);
      await this.props.ObtenerVacacionesHistoricas(parametros.Trabajador, idEmpresa);
      let bonificaciones = await this.props.obtenerBonificacionesTrabajador(parametros.Trabajador);
      let bonificacionesDescansos = await this.props.obtenerBonificacionesTrabajadorDescansos(parametros.Trabajador);
      await this.props.ObtenerContratos(parametros.Trabajador, idEmpresa);

      const accesos = await accesosPagina.obtenerAccesosPagina('PlanificacionDetalleTrabajador', 'DetalleTrabajador');
      if (accesos.Editar) {

        let cargos = await this.props.ObtenerCargosTrabajador(idEmpresa);

        //etc
        let regiones = await this.props.ObtenerRegiones();
        let comunas = await this.props.ObtenerComunas();
        let provincias = await this.props.ObtenerProvincias();

        let titulos = await this.props.ObtenerTitulos();
        let especialidades = await this.props.ObtenerEspecialidades();
        let epps = await this.props.ObtenerEPPs();
        let regimenes = await this.props.ObtenerRegimenes();
        let documentos = await this.props.ObtenerDocumentos();
        
        let trabajadorEditar = await this.props.ObtenerTrabajadorEditar(parametros.Trabajador)
        let ciudadesSeleccionadas = provincias.filter(provincia => provincia.Region === trabajadorEditar.Region);
        let comunasSeleccionadas = comunas.filter(comuna => comuna.Provincia === trabajadorEditar.Ciudad);

        this.setState({
          trabajadorEditar,
          cargos,
          regiones,
          comunas,
          provincias,
          titulos,
          especialidades,
          epps,
          regimenes,
          documentos,
          fechasBusqueda: [inicioFiltro, finFiltro],
          ciudadesSeleccionadas,
          comunasSeleccionadas,
        })
      }
     
      let bitacoraTrabajador = this.props.bitacora;
      let indicadoresTrabajador = this.props.dashboard;
      let indicadoresOtrasCondiciones = this.props.itemsDetalleOtrasCondiciones;
      let descansoFiniquitado =  this.props.detalleDescansoFiniquitado;
      
      //OBTENER CENTRO DE COSTO ACTUAL
      const centroCostoHoy = bitacoraTrabajador.filter((bitHoy)=> moment.utc(bitHoy.Fecha).format("DD-MM-YYY") === moment.utc().format("DD-MM-YYY"));
      
      //DATOS DEL PERFIL TRABAJADORD
      let objPerfil = {
        NombreCompleto: `${trabajador.Nombre}  ${trabajador.Apellido_Paterno} ${trabajador.Apellido_Materno}`,
        Rut: trabajador.Rut,
        Cargo: trabajador.Cargo.Nombre,
        Codigo_Base: trabajador.Codigo_Base === undefined ? "(No posee código)" : trabajador.Codigo_Base,
        Regimen_Trabajo: trabajador.RegimenDeTrabajo.Nombre,
        Centro_Costo_Actual: centroCostoHoy[0].CentroCostoNombre !== undefined && centroCostoHoy[0].CentroCostoNombre !== "" ?  centroCostoHoy[0].CentroCostoNombre: "N/A"//aun se necesita sacar cc
      }
    
      this.setState({
        Loading: false,
        trabajador,
        Perfil: objPerfil,
        BitacorasTimeLine: bitacoraTrabajador,
        IndicadoresTimeLine: indicadoresTrabajador,
        IndicadoresOtrasCondiciones: indicadoresOtrasCondiciones,
        DetalleDescansoTimeLine: descansoFiniquitado,
        IdTrabajador:  parametros.Trabajador,
        IdEmpresa: idEmpresa,
        //Nuevos (se agrego junto al if accesso)
        fechaInicioSistema: usuario.EmpresaSeleccionada.Empresa.Fecha_Inicio_Sistema,

        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
        bonificaciones,
        bonificacionesDescansos,
      });

      if (parametros.directHistorico) {
        this.setState({
          modalDocumentos: true,
        })

      } else if (parametros.directFichaProfesional) {
        this.setState({
          modalProfesional: true,
        })

      } else if (parametros.directEpp) {
        this.setState({
          modalEntregaEpp: true,
        })
      }
    }
  }

  //se agrego el lunes 20-06-22
  actualizarInformacionUsuario = async () => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      let parametros = queryString.parse(this.props.location.search);

      let trabajador = await this.props.ObtenerTrabajador(
        parametros.Trabajador
      );
      await this.props.ObtenerEPPSbitacora(parametros.Trabajador, idEmpresa);
      await this.props.ObtenerBitacoraProfesional(
        parametros.Trabajador,
        idEmpresa
      );
      this.setState({ trabajador, Loading: false });
      return;
    }
  };

  activarCargando = () => {
    this.setState({
      Loading: true,
    });
  };

  desactivarCargando = () => {
    this.setState({
      Loading: false,
    });
  };
  //se agrego el lunes 20-06-22

  cerrarModal = () => {
    this.setState({
      modalFicha: false,
      modalProfesional: false,
      modalEntregaEpp: false,
      modalDocumentos: false,
      modalBonificaciones: false,
      modalDetalleOtrasCondiciones: false,
      modalContratos: false,
      editarCerrado: true,
    });
  };

  estadoFichaProfesional = () => {

    if (this.props.trabajador) {

      const TitulosProfesionales = this.props.trabajador.Titulos_Profesionales;
      const Especialidades = this.props.trabajador.Especialidades;
      const TiposDeDocumentos = this.props.trabajador.TiposDeDocumentos;

      let contador = 0;

      TitulosProfesionales.forEach((titulo) => {
        if (
          titulo.encontrado === false ||
          (titulo.cantidadDiasHabiles !== undefined
            ? titulo.cantidadDiasHabiles
            : 1) <= 0
        ) {
          contador++;
        }
      });

      Especialidades.forEach((especialidad) => {
        if (
          especialidad.encontrado === false ||
          (especialidad.cantidadDiasHabiles !== undefined
            ? especialidad.cantidadDiasHabiles
            : 1) <= 0
        ) {
          contador++;
        }
      });

      TiposDeDocumentos.forEach((doc) => {
        if (doc.isIngresado === false) {
          contador++;
        }
      });

      if (contador >= 1) {
        if (this.state.eppDetalle) {
          return (
            <Badge className="cantidad-alertas" count={contador}>
              <a href="#" className="head-example" />
            </Badge>
            // <div className="alerta-detallada">
            //   <p>holaaaaaaaa</p>
            // </div>
          );
        } else {
          return (
            <Badge className="cantidad-alertas" count={contador}>
              <a href="#" className="head-example" />
            </Badge>
            // <div className="alerta-detallada">
            //   <p>holaaaaaaaa</p>
            // </div>
          );
        }
      } else {
        return <div></div>;
      }
    } else {
      return <div></div>;
      // return (
      //   <p
      //     style={{
      //       color: "#E61958",
      //     }}
      //   >
      //     Elementos sin ingresar
      //   </p>
      // );
    }
  };

  estadoElementosProteccion = () => {
    if (this.props.trabajador) {
      const epps = this.props.trabajador.EPP;
      let contador = 0;

      epps.forEach((epp) => {
        if (epp.encontrado === false || epp.cantidadDiasHabiles <= 0) {
          contador++;
        }
      });

      if (contador >= 1) {
        if (this.state.eppDetalle) {
          return (
            <Badge className="cantidad-alertas" count={contador}>
              <a href="#" className="head-example" />
            </Badge>
          );
        } else {
          return (
            <Badge className="cantidad-alertas" count={contador}>
              <a href="#" className="head-example" />
            </Badge>
          );
        }
      }
    } else {
      return <div></div>;
    }
  };

  estadoRegistroVacaciones = () => {

    if (this.state.fechaInicioSistema === undefined || this.state.trabajador === undefined) {
      return;
    }

    if (this.props.vacaciones !== undefined && this.props.vacaciones.length === 0) {

      let fechaInicioSistema = moment(this.state.fechaInicioSistema);
      let fechaContratoTrabajador = moment(this.state.trabajador.Contrato_Fecha_Inicio);

      if (fechaInicioSistema.isAfter(fechaContratoTrabajador)) {
        return (
          <Badge className="cantidad-alertas" count={'!'}>
            <a href="#" className="head-example" />
          </Badge>
        )
      }

    }
  }

  //SET FECHAS
  filtrarCalendario = (fechas) => {
    if (fechas) {
      let firstDay = moment.utc(fechas[0]).startOf("month");
      let lastDay = moment.utc(fechas[1]).endOf("month");

      let firstDayFormat = convertirFechaString(firstDay, 1).fechaString;
      let lastDayFormat = convertirFechaString(lastDay, 2).fechaString;

      this.setState({
        selectedFechaInicio: firstDayFormat,
        selectedFechaFin: lastDayFormat,
        fechasBusqueda: fechas
      })
    }
  };

  //BOTON FILTRAR TIMELINE Y INDICADORES
  botonFiltrarTimeline = async() =>{
    this.activarCargando();
    const {IdTrabajador, selectedFechaInicio, selectedFechaFin} = this.state;

    await this.props.ObtenerBitacora(IdTrabajador, selectedFechaInicio, selectedFechaFin);
    let bitacoraTrabajador = this.props.bitacora;
    let indicadoresTrabajador = this.props.dashboard;
    let indicadoresOtrasCondiciones = this.props.itemsDetalleOtrasCondiciones;

    let arrayIniciosDeMes = [];
    let fechaInicioString = convertirFechaString(moment.utc(selectedFechaInicio).startOf("month"), 1).fechaString;
    let fechaFinString = convertirFechaString(moment.utc(selectedFechaFin).endOf("month"), 2).fechaString;
    let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;

    for (let index = 0; index < diferenciaMeses; index++) {
      let fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
      arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
    }

    this.setState({
      BitacorasTimeLine: bitacoraTrabajador,
      IndicadoresTimeLine: indicadoresTrabajador,
      IndicadoresOtrasCondiciones: indicadoresOtrasCondiciones,
      fechaCalendarioTrabajador: [moment.utc(selectedFechaInicio).startOf("month"), moment.utc(selectedFechaFin).endOf("month")],
      arregloFechas: arrayIniciosDeMes
    })

    this.desactivarCargando();
  }

  //FICHA DEL TRABAJADOR
  EditarTrabajador = async (Trabajador) => {
    this.activarCargando();
    Trabajador._id = this.props.trabajador._id;
    Trabajador.Vigencia = this.props.trabajador.Vigencia;
    let parametros = queryString.parse(this.props.location.search);
    await this.props.EditarTrabajador(Trabajador);
    await this.props.ObtenerTrabajador(this.props.trabajador._id);//this.props.ObtenerTrabajador()

    this.props.ObtenerTrabajador(parametros.Trabajador);

    this.cerrarModal();
    this.forceUpdate();

    this.desactivarCargando();
  }

  obtenerImagen = (imagenNueva) => {
    this.setState({
      form: {
        ...this.state.form,
        imagen: imagenNueva,
      },
    });
  };

  //funciones para buscar select anidados de ciudades y provincias
  buscarComuna = (idProvincia) => {
    let comunas = this.state.comunas;
    let nuevasComunas = comunas.filter(
      (comuna) => comuna.Provincia === idProvincia
    );

    this.setState({
      comunasSeleccionadas: nuevasComunas,
    })
  };

  buscarProvincias = (idRegion) => {
    let provincias = this.state.provincias;

    let nuevasProvincias = provincias.filter(
      (provincia) => provincia.Region === idRegion
    );

    this.setState({
      ciudadesSeleccionadas: nuevasProvincias,
    })
  };

  cambiarEditarCerrado = () => {
    this.setState({ editarCerrado: false })
  }

  //REGISTRO DE VACACIONES HISTORICAS
  buscarHistorico = async () => {

    let parametros = queryString.parse(this.props.location.search);
    let usuario = localStorage.getItem("usuario-people-manage");
    usuario = JSON.parse(usuario);
    const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

    let historico = await this.props.ObtenerVacacionesHistoricas(parametros.Trabajador, idEmpresa);

    return historico;

  }

  //COMPROBANTES DE BONIFICACIONES
  eliminarBonificacion = async (idBonificacion) => {

    await Swal.fire({
      title: "Confirmar",
      text: `¿Estás seguro que quieres eliminar el registro de bonificación?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",

    }).then(async (result) => {

      if (result.isConfirmed) {

        await this.props.eliminarBonificacion(idBonificacion);

        let parametros = queryString.parse(this.props.location.search);
        let bonificaciones = await this.props.obtenerBonificacionesTrabajador(parametros.Trabajador);

        this.setState({
          bonificaciones,
        })
      }
    })
  }

  render() {
    let { trabajador, Perfil, BitacorasTimeLine, IndicadoresTimeLine, IndicadoresOtrasCondiciones, DetalleDescansoTimeLine, fechaCalendarioTrabajador, arregloFechas } = this.state;
    return (
      <>
        <Layout style={{ height: "100%" }}>
          <Loading cargando={this.state.Loading} />
          <Header style={{ background: "none" }}>
            <HeaderFicha titulo="Perfil Trabajador" subtitulo="Ver detalles" />
          </Header>

          <hr className="division" style={{ marginRight: 35 }} />
          <Content
            className="contenedor-general cont-tabla-celeste"
            style={{ background: "none" }}
          >

            <Row span={24} style={{marginTop: '25px'}}>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 5, offset: 0 }}
                xl={{ span: 5, offset: 0 }}
                xxl={{ span: 5, offset: 0 }}
              >
                <p style={{ fontFamily: "MavenPro-Bold", marginBottom: "3px" }}>
                  Filtrar por:
                </p>

                <RangePicker
                  locale={locale}
                  picker="month"
                  disabledDate={this.disabledDate}
                  value={this.state.fechasBusqueda}
                  onChange={(fechas) => {
                    this.filtrarCalendario(fechas);
                  }}  
                />
              </Col>

              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 4, offset: 1 }}
                xl={{ span: 4, offset: 1 }}
                xxl={{ span: 4, offset: 1 }}
              >
                <Button
                  className={"btn-filtrar-perfil-trabajador"}
                  onClick={() => {
                    this.botonFiltrarTimeline();
                  }}
                >
                  Filtrar
                </Button>
              </Col>

              <Col
                className={"card-leyenda-perfil-trabajador"}
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 13, offset: 1 }}
                xl={{ span: 13, offset: 1 }}
                xxl={{ span: 13, offset: 1 }}
              >
                <Row>
                  <Col
                    xs={24} s={24} sm={24} md={3} lg={3} xl={3}
                    className={"title-leyenda-perfil-trabajador"}
                  >
                    <h1>Leyenda:</h1>
                  </Col>

                  <Col
                    xs={8} s={8} sm={8} md={2} lg={2} xl={2}
                    className={"col-leyenda-perfil-trabajador"}
                  >
                    <img src={TrabajoEfectivo}/>
                    <p>Trabajo Efectivo</p>
                  </Col>
                  <Col
                    xs={8} s={8} sm={8} md={2} lg={2} xl={2}
                    className={"col-leyenda-perfil-trabajador"}
                  >
                    <img src={Descanso}/>
                    <p>Descanso</p>
                  </Col>

                  <Col
                    xs={8} s={8} sm={8} md={2} lg={2} xl={2}
                    className={"col-leyenda-perfil-trabajador"}
                  >
                    <img src={EnTransito}/>
                    <p>En tránsito</p>
                  </Col>
                  <Col
                    xs={8} s={8} sm={8} md={2} lg={2} xl={2}
                    className={"col-leyenda-perfil-trabajador"}
                  >
                    <img src={Vacaciones}/>
                    <p>Vacaciones</p>
                  </Col>
                  <Col
                    xs={8} s={8} sm={8} md={2} lg={2} xl={2}
                    className={"col-leyenda-perfil-trabajador"}
                  >
                    <img src={Licencia}/>
                    <p>Licencia</p>
                  </Col>
                  <Col
                    xs={8} s={8} sm={8} md={2} lg={2} xl={2}
                    className={"col-leyenda-perfil-trabajador"}
                  >
                    <img src={LicenciaMutualidad}/>
                    <p>Licencia mutualidad</p>
                  </Col>

                  <Col
                    xs={8} s={8} sm={8} md={2} lg={2} xl={2}
                    className={"col-leyenda-perfil-trabajador"}
                  >
                    <img src={Permiso}/>
                    <p>Permiso</p>
                  </Col>
                  <Col
                    xs={8} s={8} sm={8} md={2} lg={2} xl={2}
                    className={"col-leyenda-perfil-trabajador"}
                  >
                    <img src={Ausente}/>
                    <p>Ausente</p>
                  </Col>

                  <Col
                    xs={8} s={8} sm={8} md={2} lg={2} xl={2}
                    className={"col-leyenda-perfil-trabajador"}
                  >
                    <img src={NoAsignado}/>
                    <p>No asignado </p>
                  </Col>
                </Row>
              </Col>


            </Row>

            <Row gutter={25} style={{marginTop: '25px'}}>
              <Col className="gutter-row" xs={24} s={24} sm={24} md={24} lg={6} xl={6} style={{marginTop: '20px'}}>
                <Col span={24}>
                  <p style={{ fontFamily: "MavenPro-Bold",color:"#061FCB" }}>
                    Perfil
                  </p>
                </Col>

                <Card
                  className={"card-perfil-trabajador"}
                  title={
                    <>
                    <Row className="card-title-perfil-trabajador">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <img
                          className={"card-img-perfil-trabajador"}
                          src={trabajador && trabajador.Finiquitado ? AvatarFiniquitado : Avatar}
                          alt="foto trabajador"
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {Perfil.NombreCompleto}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {Perfil.Rut}
                      </Col>
                    </Row>
                    </>
                }
                // style={{ marginTop: "25px" }}
                  style={{
                    borderRadius: "16px",
                    // height: "100%",
                    width: "100%",
                    boxShadow: "0px 3px 6px #74747465",
                  }}
                >
                 <Row>
                    {trabajador && trabajador.Finiquitado && <Col span={24} style={{textAlign:"center"}}><p style={{fontFamily: "MavenPro-Bold",color:"#E64040", fontSize:"14px"}}>FINIQUITADO</p> </Col>}
                    <Col span={24} style={{textAlign:"center"}}><p style={{fontFamily: "MavenPro-Bold",color:"#061FCB", fontSize:"14px"}}>Cargo</p> </Col>
                    <Col span={24} style={{textAlign:"center"}}><p style={{fontFamily: "MavenPro-SemiBold",color:"#262E2D", fontSize:"14px"}}>{Perfil.Cargo}</p> </Col>
                    <Col span={24} style={{textAlign:"center"}}><p style={{fontFamily: "MavenPro-Bold",color:"#061FCB", fontSize:"14px"}}>Código</p> </Col>
                    <Col span={24} style={{textAlign:"center"}}><p style={{fontFamily: "MavenPro-SemiBold",color:"#262E2D", fontSize:"14px"}}>{Perfil.Codigo_Base}</p> </Col>
                    <Col span={24} style={{textAlign:"center"}}><p style={{fontFamily: "MavenPro-Bold",color:"#061FCB", fontSize:"14px"}}>Régimen de trabajo</p> </Col>
                    <Col span={24} style={{textAlign:"center"}}><p style={{fontFamily: "MavenPro-SemiBold",color:"#262E2D", fontSize:"14px"}}>{Perfil.Regimen_Trabajo}</p> </Col>
                    <Col span={24} style={{textAlign:"center"}}><p style={{fontFamily: "MavenPro-Bold",color:"#061FCB", fontSize:"14px"}}>Centro de costo actual</p> </Col>
                    <Col span={24} style={{textAlign:"center"}}><p style={{fontFamily: "MavenPro-SemiBold",color:"#262E2D", fontSize:"14px"}}>{Perfil.Centro_Costo_Actual}</p> </Col>
                 </Row>
                </Card>
              </Col>

              <Col className="gutter-row" xs={24} s={24} sm={24} md={24} lg={18} xl={18} style={{marginTop: '20px'}}>
                <Row>
                  <Col span={24}>
                    <p style={{ fontFamily: "MavenPro-Bold",color:"#061FCB" }}>
                      Datos del trabajador
                    </p>
                  </Col>
                  {/* tarjetas */}
                  <Col
                    span={24}
                  >
                    <Row>
                
                      <Col sm={24} lg={12} xl={6} xxl={4}>
                        <Card
                        className={"card-detalle-perfil-trabajador"}
                        // onClick={() => trabajador.Finiquitado ? null : this.setState({modalFicha: true})}
                        onClick={() => this.setState({modalFicha: true}) }
                        hoverable={true}
                        // style={ trabajador && trabajador.Finiquitado ? {cursor: "default"} : {cursor: "pointer"}}
                        style={{cursor: "pointer"}}
                        >
                          <Row>
                            <Col xs={6}>
                              {/* <img style={{ width: 40 }} src={trabajador && trabajador.Finiquitado ? AvatarFiniquitado : Avatar} alt="icono" /> */}
                              <img style={{ width: 40 }} src={Avatar} alt="icono" />
                            </Col>
                            <Col xs={18}>
                              <h3
                                className={"h3-detalle-perfil-trabajador"}
                                // style={ trabajador && trabajador.Finiquitado ? {color: "gray"} : {color: "#262e2d"}}
                                style={{color: "#262e2d"}}
                              >
                                Ficha del trabajador
                              </h3>
                            </Col>
                          </Row>
                        </Card>
                      </Col>

                      <Col sm={24} lg={12} xl={6} xxl={4}>
                        <Card
                        className={"card-detalle-perfil-trabajador"}
                        onClick={() => trabajador.Finiquitado ? null : this.setState({ modalProfesional: true })}
                        hoverable={true}
                        style={ trabajador && trabajador.Finiquitado ? {cursor: "default"} : {cursor: "pointer"}}
                        >
                          {this.estadoFichaProfesional()}
                          <Row>
                            <Col xs={6}>
                              <img style={{ width: 40 }} src={trabajador && trabajador.Finiquitado ? CarpetaFiniquitado : Carpeta} alt="icono" />
                            </Col>
                            <Col xs={18}>
                              <h3
                                className={"h3-detalle-perfil-trabajador"}
                                style={ trabajador && trabajador.Finiquitado ? {color: "gray"} : {color: "#262e2d"}}
                              >
                                Ficha Profesional
                              </h3>
                            </Col>
                          </Row>
                        </Card>
                      </Col>

                      <Col sm={24} lg={12} xl={6} xxl={4}>
                        <Card
                        className={"card-detalle-perfil-trabajador"}
                        hoverable={true}
                        style={ trabajador && trabajador.Finiquitado ? {cursor: "default"} : {cursor: "pointer"}}
                        onClick={() => trabajador.Finiquitado ? null : this.setState({ modalEntregaEpp: true })}
                        onMouseEnter={() => {
                          this.setState({
                            eppDetalle: true,
                          });
                        }}
                        onMouseLeave={() => {
                          this.setState({
                            eppDetalle: false,
                          });
                        }}
                        >
                          {this.estadoElementosProteccion()}
                          <Row>
                            <Col xs={6}>
                              <img style={{ width: 40 }} src={trabajador && trabajador.Finiquitado ? ProteccionFiniquitado : Proteccion} alt="icono" />
                            </Col>
                            <Col xs={18}>
                              <h3
                                className={"h3-detalle-perfil-trabajador"}
                                style={ trabajador && trabajador.Finiquitado ? {color: "gray"} : {color: "#262e2d"}}
                              >
                                Elementos de Protección
                              </h3>
                            </Col>
                          </Row>
                        </Card>
                      </Col>

                      <Col sm={24} lg={12} xl={6} xxl={4}>
                        <Card
                        className={"card-detalle-perfil-trabajador"}
                        hoverable={true}
                        style={ trabajador && trabajador.Finiquitado ? {cursor: "default"} : {cursor: "pointer"}}
                        onClick={() => trabajador.Finiquitado ? null : this.setState({ modalDocumentos: true })}
                        >
                          {this.estadoRegistroVacaciones()}
                          <Row>
                            <Col xs={6}>
                              <img style={{ width: 40 }} src={trabajador && trabajador.Finiquitado ? vacaciones2Finiquitado : vacaciones2} alt="icono" />
                            </Col>
                            <Col xs={18}>
                              <h3
                                className={"h3-detalle-perfil-trabajador h3-padding-dpt"}
                                style={ trabajador && trabajador.Finiquitado ? {color: "gray"} : {color: "#262e2d"}}
                              >
                                Registro vacaciones históricas
                              </h3>
                            </Col>
                          </Row>
                        </Card>
                      </Col>

                      <Col sm={24} lg={12} xl={6} xxl={4}>
                        <Card
                        className={"card-detalle-perfil-trabajador"}
                        hoverable={true}
                        style={ trabajador && trabajador.Finiquitado ? {cursor: "default"} : {cursor: "pointer"}}
                        onClick={() => trabajador.Finiquitado ? null : this.setState({ modalBonificaciones: true })}
                        >
                          <Row>
                            <Col xs={6}>
                              <img style={{ width: 40 }} src={trabajador && trabajador.Finiquitado ? CarpetaFiniquitado : Carpeta} alt="icono" />
                            </Col>
                            <Col xs={18}>
                              <h3
                                className={"h3-detalle-perfil-trabajador"}
                                style={ trabajador && trabajador.Finiquitado ? {color: "gray"} : {color: "#262e2d"}}
                              >
                                Comprobantes de Bonificación
                              </h3>
                            </Col>
                          </Row>
                        </Card>
                      </Col>

                      <Col sm={24} lg={12} xl={6} xxl={4}>
                        <Card
                          className={"card-detalle-perfil-trabajador"}
                          hoverable={true}
                          style={{cursor: "pointer"}}
                          onClick={() => this.setState({ modalContratos: true })}
                          >
                            <Row>
                              <Col xs={6}>
                                <img style={{ width: 40 }} src={Carpeta} alt="icono" />
                              </Col>
                              <Col xs={18}>
                                <h3
                                  className={"h3-detalle-perfil-trabajador"}
                                  style={{color: "#262e2d"}}
                                >
                                  Contratos trabajador
                                </h3>
                              </Col>
                            </Row>
                        </Card>
                      </Col>

                    </Row>
                  </Col>
                </Row>
               {/* timeline */}
                <Card
                  span={24}
                  style={{marginTop:"10px"}}
                  className={"card-timeline-perfil-trabajador"}
                  title={
                  <>
                    <Row>
                      <Col
                        xs={24} sm={24} md={24} lg={24} xl={24}
                      >
                        <h2 className={"card-title-timeline-perfil-trabajador"}>Calendario del trabajador</h2>
                        <h3 className={"card-subtitle-timeline-perfil-trabajador"}> {`${fechaCalendarioTrabajador.length > 0 && fechaCalendarioTrabajador[0].format("DD/MM/YYYY") + " - "+ fechaCalendarioTrabajador[1].format("DD/MM/YYYY") }`}</h3>
                      </Col>

                    </Row>
                  </>
                  }
                >
                  
                  <div
                    style={{
                      width: "100%",
                      height: "100px",
                      overflowX: "scroll",
                    }}
                    className="contenedor-step-asig-act"
                  >

                    <Steps
                      current={0}
                      progressDot={customDot}
                      className="ant-icon-tamaño testContainer testTail step-fecha-inicio-fin-mes"
                      direction={"horizontal"}
                      labelPlacement={"horizontal"}
                      responsive={false}
                      style={{marginBottom: "0.5rem"}}
                    >
                      {
                        BitacorasTimeLine.length > 0 && BitacorasTimeLine.map((diasCC)=>
                        <>
                          <Step 
                            key={diasCC._id} 
                            title={arregloFechas.filter((fecha) => 
                               moment.utc(fecha).isSame(moment.utc(convertirFechaString(moment.utc(diasCC.Fecha), 1).fechaString))).length > 0 && moment.utc(diasCC.Fecha).format('MMMM').substring(0, 3).toUpperCase()
                            } 
                            className={
                              diasCC.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261" && "dot-green-timeline"  
                            }
                          />

                        </>)
                      }
                    </Steps>

                    <Steps
                      current={0}
                      progressDot={customDot}
                      className="ant-icon-tamaño testContainer testTail"
                      direction={"horizontal"}
                      labelPlacement={"horizontal"}
                      responsive={false}
                      style={{marginBottom: "1.5rem"}}
                    >
                      {
                        BitacorasTimeLine.length > 0 && BitacorasTimeLine.map((diasCC)=>
                        <>
                          <Step 
                            key={diasCC._id} 
                            title={moment.utc(diasCC.Fecha).format('DD')}
                            // title={<>
                            //   <div style={{marginBottom: "1rem !important"}}>
                            //     <label>{moment.utc(diasCC.Fecha).format('DD')}</label><br/>
                            //     <label className="ant-steps-item-title-month">{moment.utc(diasCC.Fecha).format('MMMM').substring(0, 3)}</label>
                            //   </div>
                            // </>}
                            status={
                            <>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261" ? <img src={TrabajoEfectivo}/> : "" }</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eeb78978b284b68ebced4" ? <img src={Descanso}/> : "" }</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador === "5f8f369e8efe155a48df37fb" ? <img src={EnTransito}/> : "" }</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eebac978b284b68ebced5" ? <img src={Vacaciones}/> : "" }</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eebf46dd7cc57f0d3a25d" ? <img src={Licencia}/> : "" }</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eec676dd7cc57f0d3a25e" ? <img src={LicenciaMutualidad}/> : "" }</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eec876dd7cc57f0d3a25f" ? <img src={Permiso}/> : "" }</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eeca16dd7cc57f0d3a260" ? <img src={Ausente}/> : "" }</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador === "5f904101d9c57145a09eb058" ? <img src={NoAsignado}/> : "" }</p>
           
                              <p>{ moment.utc(diasCC.Fecha).format('DD-MM-YYYY')}</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261" ? diasCC.CentroCostoNombre : "" }</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261" ? diasCC.ActidadNombre : "" }</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261" ? diasCC.PuestoNombre : "" }</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador !== "" ? diasCC.StatusNombre :"" }</p>
                              <p style={{color: "grey"}}>{ diasCC.TipoRegistroBitacoraTrabajador !== "5f3eedf56dd7cc57f0d3a261" && diasCC.TipoRegistroBitacoraTrabajador !== "Vacio" ? `Fecha Inicio:  ${moment.utc(diasCC.Fecha_Inicio).format('DD-MM-YYYY')}`: "" }</p>
                              <p style={{color: "grey"}}>{ diasCC.TipoRegistroBitacoraTrabajador !== "5f3eedf56dd7cc57f0d3a261" && diasCC.TipoRegistroBitacoraTrabajador !== "Vacio" ? `Fecha Fin: ${moment.utc(diasCC.Fecha_Fin).format('DD-MM-YYYY')}`: "" }</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador !== "5f3eedf56dd7cc57f0d3a261" && diasCC.TipoRegistroBitacoraTrabajador !== "Vacio" ?  <p>Cant. Dias: { moment.utc(diasCC.Fecha_Fin).diff(moment.utc(diasCC.Fecha_Inicio),"days") + 1}</p> : "" }</p>
                              <p style={{color: "grey"}}>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261" ? `Fecha Inicio:  ${moment.utc(diasCC.Inicio_Planificacion).format('DD-MM-YYYY')}`: "" }</p>
                              <p style={{color: "grey"}}>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261" ? `Fecha Fin: ${moment.utc(diasCC.Fin_Planificacion).format('DD-MM-YYYY')}`: "" }</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261" ?  <p>Cant. Dias: { moment.utc(diasCC.Fin_Planificacion).diff(moment.utc(diasCC.Inicio_Planificacion),"days") + 1}</p> : "" }</p>
                                      
                              <p style={{color: "red"}}>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eeb78978b284b68ebced4" && DetalleDescansoTimeLine.length > 0 ? "interrumpido antes del fin de contrato": "" }</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eeb78978b284b68ebced4" && DetalleDescansoTimeLine.length > 0 ? `Fecha Inicio:  ${moment.utc(DetalleDescansoTimeLine[0].FechaInicio).format('DD-MM-YYYY')}`: "" }</p>
                              <p>{ diasCC.TipoRegistroBitacoraTrabajador === "5f3eeb78978b284b68ebced4" && DetalleDescansoTimeLine.length > 0 ? `Fecha Fin: ${moment.utc(DetalleDescansoTimeLine[0].FechaFin).format('DD-MM-YYYY')}`: "" }</p>
                            </>}
                      
                            className={
                              diasCC.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261" && moment.utc(diasCC.Inicio_Planificacion).isSameOrBefore(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fin_Planificacion).isSameOrAfter(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") !== moment.utc(diasCC.Fin_Planificacion).format("YYYY-MM-DD") ? "dot-green-timeline tail-green-timeline" :
                              diasCC.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261" && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") === moment.utc(diasCC.Fin_Planificacion).format("YYYY-MM-DD") ? "dot-green-timeline" :

                              diasCC.TipoRegistroBitacoraTrabajador === "5f3eeb78978b284b68ebced4" && moment.utc(diasCC.Fecha_Inicio).isSameOrBefore(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fecha_Fin).isSameOrAfter(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") !== moment.utc(diasCC.Fecha_Fin).format("YYYY-MM-DD") ?  "dot-pink-timeline tail-pink-timeline"  :
                              diasCC.TipoRegistroBitacoraTrabajador === "5f3eeb78978b284b68ebced4" && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") === moment.utc(diasCC.Fecha_Fin).format("YYYY-MM-DD") ? "dot-pink-timeline" :

                              diasCC.TipoRegistroBitacoraTrabajador === "5f8f369e8efe155a48df37fb" && moment.utc(diasCC.Fecha_Inicio).isSameOrBefore(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fecha_Fin).isSameOrAfter(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") !== moment.utc(diasCC.Fecha_Fin).format("YYYY-MM-DD") ?  "dot-lightpurple-timeline tail-lightpurple-timeline" :
                              diasCC.TipoRegistroBitacoraTrabajador === "5f8f369e8efe155a48df37fb" && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") === moment.utc(diasCC.Fecha_Fin).format("YYYY-MM-DD") ? "dot-lightpurple-timeline" :

                              diasCC.TipoRegistroBitacoraTrabajador === "5f3eebac978b284b68ebced5" && moment.utc(diasCC.Fecha_Inicio).isSameOrBefore(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fecha_Fin).isSameOrAfter(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") !== moment.utc(diasCC.Fecha_Fin).format("YYYY-MM-DD") ?  "dot-cyan-timeline tail-cyan-timeline" :
                              diasCC.TipoRegistroBitacoraTrabajador === "5f3eebac978b284b68ebced5" && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") === moment.utc(diasCC.Fecha_Fin).format("YYYY-MM-DD") ? "dot-cyan-timeline" :

                              diasCC.TipoRegistroBitacoraTrabajador === "5f3eebf46dd7cc57f0d3a25d" && moment.utc(diasCC.Fecha_Inicio).isSameOrBefore(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fecha_Fin).isSameOrAfter(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") !== moment.utc(diasCC.Fecha_Fin).format("YYYY-MM-DD") ?  "dot-electric-blue-timeline tail-electric-blue-timeline"  : 
                              diasCC.TipoRegistroBitacoraTrabajador === "5f3eebf46dd7cc57f0d3a25d" && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") === moment.utc(diasCC.Fecha_Fin).format("YYYY-MM-DD") ? "dot-electric-blue-timeline" :

                              diasCC.TipoRegistroBitacoraTrabajador === "5f3eec676dd7cc57f0d3a25e" && moment.utc(diasCC.Fecha_Inicio).isSameOrBefore(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fecha_Fin).isSameOrAfter(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") !== moment.utc(diasCC.Fecha_Fin).format("YYYY-MM-DD") ?  "dot-yellow-timeline tail-yellow-timeline" :
                              diasCC.TipoRegistroBitacoraTrabajador === "5f3eec676dd7cc57f0d3a25e" && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") === moment.utc(diasCC.Fecha_Fin).format("YYYY-MM-DD") ? "dot-yellow-timeline" :

                              diasCC.TipoRegistroBitacoraTrabajador === "5f3eec876dd7cc57f0d3a25f" && moment.utc(diasCC.Fecha_Inicio).isSameOrBefore(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fecha_Fin).isSameOrAfter(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") !== moment.utc(diasCC.Fecha_Fin).format("YYYY-MM-DD") ?  "dot-orange-timeline tail-orange-timeline" :
                              diasCC.TipoRegistroBitacoraTrabajador === "5f3eec876dd7cc57f0d3a25f" && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") === moment.utc(diasCC.Fecha_Fin).format("YYYY-MM-DD") ? "dot-orange-timeline" :

                              diasCC.TipoRegistroBitacoraTrabajador === "5f3eeca16dd7cc57f0d3a260" && moment.utc(diasCC.Fecha_Inicio).isSameOrBefore(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fecha_Fin).isSameOrAfter(moment.utc(diasCC.Fecha)) && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") !== moment.utc(diasCC.Fecha_Fin).format("YYYY-MM-DD") ?  "dot-violet-timeline tail-violet-timeline" :
                              diasCC.TipoRegistroBitacoraTrabajador === "5f3eeca16dd7cc57f0d3a260" && moment.utc(diasCC.Fecha).format("YYYY-MM-DD") === moment.utc(diasCC.Fecha_Fin).format("YYYY-MM-DD") ? "dot-violet-timeline" :
                              diasCC.TipoRegistroBitacoraTrabajador === "5f904101d9c57145a09eb058"? "dot-red-timeline"   :
                              "dot-white"
                            }                                      
                          />

                        </>)
                      }
                    </Steps>

                    

                  </div>

                </Card>

                {/* indicadores */}
                <Row style={{marginTop:"10px"}}>
                  <Card
                    span={6}
                    className={"card-indicadores-perfil-trabajador"}
                  >
                    <Row>
                      <Col span={5}>
                        <img src={TrabajoEfectivo}
                          className={"card-img-indicadores-perfil-trabajador"}
                        />
                      </Col>

                      <Col span={19} style={{textAlign:"center"}}>
                        <Row style={{marginTop:"25px"}}>
                          <Col span={24}>
                            <h1 className={"card-h1-indicadores-perfil-trabajador"}>
                            {`${IndicadoresTimeLine.cantTrabajoEfectivo !== undefined ? IndicadoresTimeLine.cantTrabajoEfectivo : 0 } Días`}
                            </h1>
                          </Col>
                          <Col span={24}><p className={"card-p-indicadores-perfil-trabajador"}>Trabajo Efectivo</p></Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>

                  <Card
                    span={6}
                    className={"card-indicadores-perfil-trabajador"}
                  >
                   <Row>
                      <Col span={5}>
                        <img src={Vacaciones}
                          className={"card-img-indicadores-perfil-trabajador"}
                        />
                      </Col>

                      <Col span={19} style={{textAlign:"center"}}>
                        <Row style={{marginTop:"25px"}}>
                          <Col span={24}>
                            <h1 className={"card-h1-indicadores-perfil-trabajador"}>
                              {`${IndicadoresTimeLine.cantVacaciones !== undefined ? IndicadoresTimeLine.cantVacaciones : 0 } Días`}
                            </h1>
                          </Col>
                          <Col span={24}><p className={"card-p-indicadores-perfil-trabajador"}>Vacaciones</p></Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>

                  <Card
                    span={6}
                    className={"card-indicadores-perfil-trabajador"}
                  >
                   <Row>
                      <Col span={5}>
                        <img src={Descanso}
                          className={"card-img-indicadores-perfil-trabajador"}
                        />
                      </Col>

                      <Col span={19} style={{textAlign:"center"}}>
                        <Row style={{marginTop:"25px"}}>
                          <Col span={24}>
                            <h1 className={"card-h1-indicadores-perfil-trabajador"}>
                              {`${IndicadoresTimeLine.cantDescanto  !== undefined ? IndicadoresTimeLine.cantDescanto : 0  } Días`}
                            </h1>
                          </Col>
                          <Col span={24}><p className={"card-p-indicadores-perfil-trabajador"}>Descanso</p></Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>

                  <Card
                    span={6}
                    className={"card-indicadores-perfil-trabajador"}
                    onClick={() => this.setState({modalDetalleOtrasCondiciones: true})}
                    style={{cursor: "pointer"}}
                  >
                   <Row>
                      <Col span={5}>
                        <img src={noAsignado}
                          className={"card-img-indicadores-perfil-trabajador"}
                        />
                      </Col>

                      <Col span={19} style={{textAlign:"center"}}>
                        <Row style={{marginTop:"25px"}}>
                          <Col span={24}>
                            <h1 className={"card-h1-indicadores-perfil-trabajador"}>
                              {`${IndicadoresTimeLine.cantOtrasCondiciones !== undefined ? IndicadoresTimeLine.cantOtrasCondiciones : 0 } Días`}
                            </h1>
                          </Col>
                          <Col span={24}><p className={"card-p-indicadores-perfil-trabajador"}>Otras Condiciones</p></Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Row>

              </Col>

            </Row>

          </Content>

          {/* Modal Ficha del Trabajador */}
          <Modal
            //propiedades cambiables del modal
            visible={this.state.modalFicha}
            onCancel={this.cerrarModal}
            //propiedades obligatorias para modal mantenedor
            width={"80%"}
            className={'modal-perfil-trabajador'}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
            footer={" "}
            closable={false}
          >

            {(() => {

              // DIFERENCIA ENTRE ACCESO DE EDITAR Y SÓLO VISUALIZAR
              if (this.state.Editar) {
                if(trabajador.Finiquitado === true){
                  return (
                    <FormularioTrabajadorVisualizar
                      Formulario={this.props.trabajador}
                    />
                  )
                }
                return (
                  <FormularioTrabajadorEditar
                    Formulario={this.state.trabajadorEditar}
                    editarTrabajador={this.EditarTrabajador}
                    TomarValoresEditar={() => { }}
                    obtenerImagen={this.obtenerImagen}

                    cargosTrabajador={this.state.cargos}
                    regimenes={this.state.regimenes}

                    regiones={this.state.regiones}
                    comunas={this.state.comunas}

                    ciudadesSeleccionadas={this.state.ciudadesSeleccionadas}
                    comunasSeleccionadas={this.state.comunasSeleccionadas}

                    buscarComuna={this.buscarComuna}
                    buscarProvincias={this.buscarProvincias}

                    //select
                    epps={this.state.epps}
                    documentos={this.state.documentos}

                    //titulos y especialidades
                    titulos={this.state.titulos}
                    especialidades={this.state.especialidades}

                    editarCerrado={this.editarCerrado}
                    cambiarEditarCerrado={this.cambiarEditarCerrado}
                  />
                )

              } else {

                return (
                  <FormularioTrabajadorVisualizar
                    Formulario={this.props.trabajador}
                  />
                )
              }

            })()}

          </Modal>

          {/* Modal Ficha Profesional */}
          <Modal
            //propiedades cambiables del modal
            wrapClassName="modalProfesional"
            visible={this.state.modalProfesional}
            onCancel={this.cerrarModal}
            //propiedades obligatorias para modal mantenedor
            width="50%"
            className={'modal-perfil-trabajador'}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
            footer={" "}
            closable={false}
          >
            <FormularioFichaProfesional
              trabajador={this.state.trabajador === undefined ? [] : this.state.trabajador}
              profesionalesBitacora={this.props.profesionalesBitacora ? this.props.profesionalesBitacora : []}
              activarCargando={this.activarCargando}
              desactivarCargando={this.desactivarCargando}
              actualizarInformacionUsuario={this.actualizarInformacionUsuario}
              Ver={this.state.Ver}
              Editar={this.state.Editar}
              Eliminar={this.state.Eliminar}
            />
          </Modal>

          {/* Modal Elementos de Protección */}
          <Modal
            //propiedades cambiables del modal
            wrapClassName="modalEntregaEPP"
            visible={this.state.modalEntregaEpp}
            onCancel={this.cerrarModal}
            //propiedades obligatorias para modal mantenedor
            width="50%"
            className={'modal-perfil-trabajador'}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
            footer={" "}
            closable={false}
          >
            <FormularioEntregaEPP
              trabajador={
                this.state.trabajador === undefined ? [] : this.state.trabajador
              }
              eppsBitacora={this.props.eppsBitacora}
              activarCargando={this.activarCargando}
              desactivarCargando={this.desactivarCargando}
              actualizarInformacionUsuario={this.actualizarInformacionUsuario}
              Ver={this.state.Ver}
              Editar={this.state.Editar}
              Eliminar={this.state.Eliminar}
            />
          </Modal>

          {/* Modal Vacaciones */}
          <Modal
            //propiedades cambiables del modal
            wrapClassName="modalDocumentos"
            visible={this.state.modalDocumentos}
            onCancel={this.cerrarModal}
            //propiedades obligatorias para modal mantenedor
            width="70%"
            className={'modal-perfil-trabajador'}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
            footer={" "}
            closable={false}
          >
            <FormularioRegistroVacaciones
              historico={
                this.props.vacaciones === undefined ? [] : this.props.vacaciones
              }
              trabajador={
                this.state.trabajador === undefined ? [] : this.state.trabajador
              }

              buscarHistorico={this.buscarHistorico}

              activarCargando={this.activarCargando}
              desactivarCargando={this.desactivarCargando}

              fechaInicioSistema={this.state.fechaInicioSistema}
              Ver={this.state.Ver}
              Editar={this.state.Editar}
              Eliminar={this.state.Eliminar}
            />
          </Modal>

          {/* Modal Bonificaciones */}
          <Modal
            //propiedades cambiables del modal
            wrapClassName="modalDocumentos"
            visible={this.state.modalBonificaciones}
            onCancel={this.cerrarModal}
            //propiedades obligatorias para modal mantenedor
            width="60%"
            className={'modal-perfil-trabajador'}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
            footer={" "}
            closable={false}
          >
            <FichaBonificaciones
              bonificacionesDescansos={this.state.bonificacionesDescansos}
              bonificaciones={this.state.bonificaciones}
              eliminarBonificacion={this.eliminarBonificacion}
            />
          </Modal>

          {/* Modal Contratos */}
          <Modal
            //propiedades cambiables del modal
            wrapClassName="modalDocumentos"
            visible={this.state.modalContratos}
            onCancel={this.cerrarModal}
            //propiedades obligatorias para modal mantenedor
            width="60%"
            className={'modal-perfil-trabajador'}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
            footer={" "}
            closable={false}
          >
            <FichaContratos
              contratos={this.props.contratosTrabajador}
            />
          </Modal>

          {/* Modal Detalle Otras Condiciones */}
          <Modal
            visible={this.state.modalDetalleOtrasCondiciones}
            onCancel={this.cerrarModal}      
            width="70%"
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
            footer={" "}
            closable={false}
            className={'modal-detalle-otras-condiciones'}
          >
            <div style={{ marginBottom: "1rem" }}>
              <label style={{color: "#061FCB", fontFamily:"MavenPro-SemiBold", fontSize: "16px", paddingLeft: "1.5rem", paddingBottom: "5rem"}}>Otras Condiciones</label>
            </div>
            <Card className={'card-detalle-otras-condiciones'}>
              <div style={{ marginBottom: "1rem" }}>
                <label style={{color: "#393939", fontFamily:"MavenPro-SemiBold", fontSize: "14px"}}>Leyenda</label>
              </div>
              <Row>
                <Col
                  className={'card-contenedor-otras-condiciones'}
                  xs={24} md={12} xl={8}
                >  
                  <Card className={'card-otras-condiciones'}>
                    <Row>
                      <Col xs={24} xl={8}>
                        <img src={EnTransito} style={{width: "55px"}}/>
                      </Col>
                      <Col xs={24} xl={16}>
                        <h4 className={'h4-cantidad-dias-otras-condiciones'}>{`${IndicadoresOtrasCondiciones.cantEnTransito } Días`}</h4>
                        <label style={{color: "#061FCB", fontFamily:"MavenPro-Bold", fontSize: "12px"}}>En tránsito</label>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                
                <Col
                  className={'card-contenedor-otras-condiciones'}
                  xs={24} md={12} xl={8}
                >  
                  <Card className={'card-otras-condiciones'}>
                    <Row>
                      <Col xs={24} xl={8}>
                        <img src={Licencia} style={{width: "55px"}}/>
                      </Col>
                      <Col xs={24} xl={16}>
                        <h4 className={'h4-cantidad-dias-otras-condiciones'}>{`${IndicadoresOtrasCondiciones.cantLicencia } Días`}</h4>
                        <label style={{color: "#061FCB", fontFamily:"MavenPro-Bold", fontSize: "12px"}}>Licencia</label>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                
                <Col
                  className={'card-contenedor-otras-condiciones'}
                  xs={24} md={12} xl={8}
                >  
                  <Card className={'card-otras-condiciones'}>
                    <Row>
                      <Col xs={24} xl={8}>
                        <img src={LicenciaMutualidad} style={{width: "55px"}}/>
                      </Col>
                      <Col xs={24} xl={16}>
                        <h4 className={'h4-cantidad-dias-otras-condiciones'}>{`${IndicadoresOtrasCondiciones.cantLicenciaMutualidad } Días`}</h4>
                        <label style={{color: "#061FCB", fontFamily:"MavenPro-Bold", fontSize: "12px"}}>Licencia Mutualidad</label>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                
                <Col
                  className={'card-contenedor-otras-condiciones'}
                  xs={24} md={12} xl={8}
                >  
                  <Card className={'card-otras-condiciones'}>
                    <Row>
                      <Col xs={24} xl={8}>
                        <img src={Permiso} style={{width: "55px"}}/>
                      </Col>
                      <Col xs={24} xl={16}>
                        <h4 className={'h4-cantidad-dias-otras-condiciones'}>{`${IndicadoresOtrasCondiciones.cantPermiso } Días`}</h4>
                        <label style={{color: "#061FCB", fontFamily:"MavenPro-Bold", fontSize: "12px"}}>Permiso</label>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                
                <Col
                  className={'card-contenedor-otras-condiciones'}
                  xs={24} md={12} xl={8}
                >  
                  <Card className={'card-otras-condiciones'}>
                    <Row>
                      <Col xs={24} xl={8}>
                        <img src={Ausente} style={{width: "55px"}}/>
                      </Col>
                      <Col xs={24} xl={16}>
                        <h4 className={'h4-cantidad-dias-otras-condiciones'}>{`${IndicadoresOtrasCondiciones.cantAusente } Días`}</h4>
                        <label style={{color: "#061FCB", fontFamily:"MavenPro-Bold", fontSize: "12px"}}>Ausente</label>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              
              </Row>
            </Card>
          </Modal>

        </Layout>
      </>
    );
  }
}

// export default CalendarioTrabajadorNew;
const EstadosAPropiedades = ({
  CalendarioTrabajadorReducer: {
    error,
    loading,
    success,
    trabajador,
    bitacora,
    estadoTrabajador,
    mostrarCalendario,
    dashboard,
    eppsBitacora,
    profesionalesBitacora,
    cargosTrabajador,
    contratosTrabajador,
    vacaciones,
    detalleDescansoFiniquitado,
    itemsDetalleOtrasCondiciones
  },
}) => {
  return {
    error,
    loading,
    success,
    trabajador,
    bitacora,
    estadoTrabajador,
    mostrarCalendario,
    dashboard,
    eppsBitacora,
    profesionalesBitacora,
    cargosTrabajador,
    contratosTrabajador,
    vacaciones,
    detalleDescansoFiniquitado,
    itemsDetalleOtrasCondiciones
  };
};

export default connect(
  EstadosAPropiedades,
  CalendarioTrabajadorActions
)(CalendarioTrabajadorNew);
