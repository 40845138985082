import React from "react";

import documento from "../../img/icons/documento.svg";
import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Upload,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";

const FormularioDocumentos = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const objAux = {
        ...values,
        Empresa: usuario.EmpresaSeleccionada.Empresa._id,
      };
      props.crearDocumento(objAux);
    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  // const config = {
  //   name: "file",
  //   multiple: false,
  //   showUploadList: {
  //     showDownloadIcon: false,
  //   },
  //   onRemove: (archivo) => {
  //     this.props.Formulario((state) => {
  //       return {
  //         Archivo: [],
  //       };
  //     });
  //   },
  //   beforeUpload: (archivo) => {
  //     this.props.Formulario((state) => ({
  //       Archivo: [archivo],
  //     }));
  //     return false;
  //   },
  //   props.Formulario.Archivo,
  // };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={documento} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Nuevo Documento"
          />
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Form.Item
              name="Nombre"
              label="Nombre:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir un nombre",
                  min: 2,
                  max: 30,
                },
              ]}
            >
              <Input name="Nombre" maxLength={30} className="campos" />
            </Form.Item>

            <Form.Item
              label="Ficha del documento"
              style={{ textAlign: "left" }}
            >
              <Upload
                name="archivoSubir"
                accept={"pdf"}
                listType="picture"
                multiple={false}
                
                onRemove={()=>{
                  props.cambiarEstadoDeshabilitado(false);
                }}
                beforeUpload={(archivo, listaArchivos) => {
                  var reader = new FileReader();
                  reader.readAsDataURL(archivo);
                  reader.onload = function () {
                    props.ObtenerArchivo(reader.result);
                    props.cambiarEstadoDeshabilitado(true);
                  };
                  props.Formulario.Archivo = [archivo];
                  reader.onerror = function (error) {
                    console.log("Error: ", error);
                  };
                }}
                action={"#"}
                className="upload-list-inline"
                method={"GET"}
                openFileDialogOnClick={true}
              >
                <Button disabled={props.subirArchivoDeshabilitado} className="subir-archivos">
                  <UploadOutlined name="archivoSubir" /> Subir Archivo
                </Button>
              </Upload>
              Asegurese que su documento no supere los 200mb
            </Form.Item>

            <Form.Item
              label="Descripción"
              name="Descripcion"
              rules={[
                {
                  min: 1,
                  required: true,
                  message: "Debe introducir una descripcion",
                },
              ]}
              hasFeedback
            >
              <Input
                name="Descripcion"
                className="campos"
                placeholder="Descripcion del documento"
              />
            </Form.Item>
            <Form.Item label="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                defaultChecked
                onChange={props.cambiarEstadoVigencia}
                className="switch"
              />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormularioDocumentos;
