import React, { Component } from "react";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";

import FormularioAsignacionFaenas from "../../components/formularioAsignacionFaenas/FormularioAsignacionFaenas";
import FormularioAsignacionFaenasEditar from "../../components/formularioAsignacionFaenas/FormularioAsignacionFaenasEditar";
import { convertirFechaString } from "../../funcionesGenericas/fechas";
import { connect } from "react-redux";
import * as AsignacionFaenasActions from "../../Redux/actions/AsignacionFaenasActions";
import {
  asignacionActividad,
  traerActividadesCentrosCostos,
  detalleActividadOrigenModal,
  detalleActividadDestinoModal,
  traspasarTrabajadoresModal,
  //disponibilidadTransbordoModal
  disponibilidadTransbordoTrabajadorModal
} from "./apiAsignacionActividades";

import moment from "moment";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import "./asignacionActividades.css";
import vista from "../../img/icons/vista.svg";

import Loading from "../../components/loading/Loading";
import { EditFilled, DeleteFilled, ReloadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import accesosPagina from "../../funcionesGenericas/accesosUsuario";

import {
  Layout,
  Modal,
  Button,
  Col,
  Row,
  Badge,
  DatePicker,
  Popover,
  Empty,
  Select,
  Steps,
  Pagination,
  Tooltip
} from "antd";

const { Header, Content } = Layout;
const { Step } = Steps;
const { RangePicker } = DatePicker;

const customDot = (dot, { status, index,title }) => (
  // <Popover
  //   content={
  //     <span>
  //       {/* step {index} status: {status} */}
  //       {false}
  //     </span>
  //   }
  // >
  //   {dot}
  // </Popover>
  <span>{dot}</span>

);

class asignacionActividades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: "Juan",
      tablaCargando: false,
      form: {
        Nombre: "",
        Centro_De_Costo: "",
        Fecha_Inicio: "",
        Fecha_Fin: "",
        PlantillaBase: "",
        Vigencia: true,
        Expira: true,
      },
      centroCostoArray: [],

      idFaena: "",
      idEmpresa:"",
      ccSeleccionados: [],
      paginasCC:[],

      loading: false,

      filtroPActual: 1,
      //paginacionSize: ["3","10","20","50"],
      Ver: false,
      Editar: false,
      Eliminar: false,
      VerDetalle: false,

      rangeDisabled: [false, true],

      CentrosCosto: [],
      ActividadesCC:[],
      DetalleOrigen:[],
      DetalleDestino:[],
      DisponibilidadTransbordo:[],
      SaveTraspaso:[],
      firstDaysOfTheMonths: [],

      RangeDatesValues: [
        moment.utc().startOf("month"),
        moment.utc().endOf("month"),
      ],
    };

    this.cambiarValores = this.cambiarValores.bind(this);
    this.CrearFaena = this.CrearFaena.bind(this);
    this.ObtenerAsignacionesIdCentroCosto =
      this.ObtenerAsignacionesIdCentroCosto.bind(this);
    this.traerActividadesCentrosCostos =  this.traerActividadesCentrosCostos.bind(this);
    this.detalleActividadOrigenModal = this.detalleActividadOrigenModal.bind(this);
    this.detalleActividadDestinoModal = this.detalleActividadDestinoModal.bind(this);
    this.traspasarTrabajadoresModal = this.traspasarTrabajadoresModal.bind(this);
    this.setterTrabajador = this.setterTrabajador.bind(this);
    this.setterTrabajadorFecha = this.setterTrabajadorFecha.bind(this);
    this.cambioFechaDefinicion = this.cambioFechaDefinicion.bind(this);
    //this.disponibilidadTransbordoModal = this.disponibilidadTransbordoModal.bind(this);
    this.disponibilidadTransbordoTrabajadorModal = this.disponibilidadTransbordoTrabajadorModal.bind(this);

  }

  async componentDidMount() {
    
    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      this.setState({loading: true})
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

        const accesos = await accesosPagina.obtenerAccesosPagina(
          "PlanificacionActividades",
          "AsignacionesCentroDeCosto"
        );
     
        const accesosDetalle = await accesosPagina.obtenerAccesosPagina(
          "PlanificacionDetalleActividad",
          "AsignacionesDetalle"
        );
     

      if(accesos.Ver === true){


      let centrosCosto = await this.props.ObtenerCentrosCosto(idEmpresa);

      centrosCosto = centrosCosto === undefined ? [] : centrosCosto;

      await this.props.ObtenerPlantillas(idEmpresa);

      let primerCC = [];
      if (centrosCosto.length > 0) primerCC.push(centrosCosto[0]._id);

      let fechaInicio = convertirFechaString(
        moment.utc(this.state.RangeDatesValues[0].clone()),
        1
      ).fechaString;
      let fechaFin = convertirFechaString(
        moment.utc(this.state.RangeDatesValues[1].clone()),
        2
      ).fechaString;
      //ObtenerAsignacionesFiltrado//api antigua
      // await this.props.ObtenerAsignacionesFiltradoSeba(
      //   fechaInicio,
      //   fechaFin,
      //   this.props.centroscosto,
      //   primerCC
      // );

      let paginasCC= [];
      
      let arrayIniciosDeMes = [];
      let fechaInicioMesString = convertirFechaString(moment.utc(this.state.RangeDatesValues[0].clone()).startOf("month"), 1).fechaString;
      let fechaFinMesString = convertirFechaString(moment.utc(this.state.RangeDatesValues[1].clone()).endOf("month"), 2).fechaString;
      let diferenciaMeses = moment.utc(fechaFinMesString).diff(moment.utc(fechaInicioMesString), 'months') + 1;
  
      for (let index = 0; index < diferenciaMeses; index++) {
        let fechaAgregar;

        if(index === 0){
          if(!moment.utc(fechaInicioMesString).isSame(moment.utc(fechaInicio))){
            fechaAgregar = moment.utc(fechaInicio);
          } else {
            fechaAgregar = moment.utc(fechaInicioMesString).add(index, "months");
          }
        } else {
          fechaAgregar = moment.utc(fechaInicioMesString).add(index, "months");
        }

        arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
      };

      const obtenerDataActividad = await asignacionActividad(idEmpresa,fechaInicio,fechaFin,primerCC,3,0);
      this.setState({
        idEmpresa:  idEmpresa,
        CentrosCosto: centrosCosto,
        ccSeleccionados: primerCC,
        centroCostoArray: obtenerDataActividad.registros,
        paginasCC: obtenerDataActividad.cantRegistros,
        loading:false,
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
        VerDetalle: accesosDetalle.Ver,
        firstDaysOfTheMonths: arrayIniciosDeMes,
      });
    }else{
      this.setState({
        loading:false,
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
        VerDetalle: accesosDetalle.Ver
      });
    }

    }
  }

    ///
    disponibilidadTransbordoTrabajadorModal = async(ID_TRABAJADOR,INICIO_PLANIFICACION,FIN_PLANIFICACION,ID_EMPRESA,record)=>{
      
        if(ID_TRABAJADOR){
          const disponibilidadTrabajador= await disponibilidadTransbordoTrabajadorModal(ID_TRABAJADOR,INICIO_PLANIFICACION,FIN_PLANIFICACION,ID_EMPRESA);

          if (disponibilidadTrabajador.status === 200) {
            let { DetalleDestino } = this.state;

            DetalleDestino.forEach(objDestino => {
              if (objDestino.key === record.key) {
                objDestino.idTrabajador = ID_TRABAJADOR;
                objDestino.deshabilitarRangoFechas = false;
                objDestino.fechaDisponibilidad = disponibilidadTrabajador.data.diasValidosPlanificar;
                objDestino.rangoFechas = ["",""];
              }else{
                objDestino.TrabajadoresSeleccionados.forEach(tra => {
                  if (ID_TRABAJADOR === tra.Trabajadores._id ) {
                    tra.deshabilitado = true;
                  }
                  
                });
              }
            });

        
            this.setState({
              DisponibilidadTransbordo: disponibilidadTrabajador.data,
              DetalleDestino: DetalleDestino
            });
  
          }else{
            this.setState({
              DisponibilidadTransbordo: [],
            });
            Swal.fire({
              icon: "error",
              title: "Uuups...",
              text: "No se logro obtener informacion.",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "OK",
            });
          }
        }else{
          let { DetalleDestino } = this.state;

          DetalleDestino.forEach(objDestino => {
            if (objDestino.key === record.key) {
              objDestino.idTrabajador = "";
              objDestino.deshabilitarRangoFechas = true;
              objDestino.fechaDisponibilidad = undefined;
              objDestino.rangoFechas = ["",""];
              objDestino.fechaInvalida = undefined;
              
            }
          });

          this.setState({
            DetalleDestino: DetalleDestino
          });

        }
        
    }

    ///

   traerActividadesCentrosCostos = async(idCentroDeCosto)=>{
    const actividades = await traerActividadesCentrosCostos(idCentroDeCosto);
    
    if(actividades.ok === true){
      this.setState({
        ActividadesCC: actividades.actividades,
      });
    }else{
      this.setState({
        ActividadesCC: [],
      });
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
   }

   detalleActividadOrigenModal = async(idEmpresa, idFaena)=>{
    const detalleOrigen = await detalleActividadOrigenModal(idEmpresa, idFaena);
    
    if(detalleOrigen.ok === true){
      this.setState({
        DetalleOrigen: detalleOrigen.data,
      });
    }else{
      this.setState({
        DetalleOrigen: [],
      });
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "Se perdio por el camino la informacion",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
   }
///
    detalleActividadDestinoModal = async(idEmpresa, idPlantilla,idActividadOrigen)=>{
     
      if (idActividadOrigen !== undefined && idActividadOrigen !== '') {
        
        const detalleDestino = await detalleActividadDestinoModal(idEmpresa, idPlantilla, idActividadOrigen);
      
        if(detalleDestino.ok === true){
          this.setState({
            DetalleDestino: detalleDestino.data,
          });
        }else{
          this.setState({
            DetalleDestino: [],
          });
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "Se perdio por el camino la informacion",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
        }
      }
    }

    //
    traspasarTrabajadoresModal = async(obj)=>{

      const saveTraspaso = await traspasarTrabajadoresModal(obj)
      if(saveTraspaso.ok === true){
        this.setState({
          SaveTraspaso: saveTraspaso.data,
        });
        Swal.fire({
          title: "Bien",
          text: "Se creó la actividad correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          this.setState({
            loading: true
          });
          
          window.location.reload();
        });
      }else{
        // this.setState({
        //   SaveTraspaso: [],
        // });
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se pudo guardar la actividad traspaso",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }
    }

    setterTrabajador = (record,idTrabajador) => {
      let { DetalleDestino } = this.state;

      DetalleDestino.forEach(objDestino => {
        if (objDestino.key === record.key) {
          objDestino.idTrabajador = idTrabajador;
          objDestino.deshabilitarRangoFechas = false;
        }
      });
      this.setState({
        DetalleDestino: DetalleDestino
      })
 
    }

    setterTrabajadorFecha= (record, FechasRango) => {
    
      let { DetalleDestino } = this.state;

      DetalleDestino.forEach(objDestino => {
        
        if (objDestino.key === record.key) {
          //
          const stringInicio = convertirFechaString(FechasRango[0],1).fechaString;
          const stringFin = convertirFechaString(FechasRango[1],2).fechaString;
          const cantDias = moment.utc(stringFin).diff(moment.utc(stringInicio),"days") + 1;
          //let arrayDiasSeleccionados = [];
          let errorEnFechas = false; 
          for (let index = 0 ; index < cantDias; index++) {
            const fechaValidar = convertirFechaString(moment.utc(stringInicio).add(index, "days"), 1).fechaString;
            const cantEncotrada = objDestino.fechaDisponibilidad.filter((fecha)=> moment.utc(fecha).format("YYYY-MM-DD") === moment.utc(fechaValidar).format("YYYY-MM-DD"));
            if (cantEncotrada.length === 0) {
              errorEnFechas = true; 
            }
            
          }
       
          if (errorEnFechas) {
            objDestino.fechaInvalida = true;
            objDestino.rangoFechas = ['' , ''];
          }else{
            objDestino.fechaInvalida = false;
            objDestino.rangoFechas = [stringInicio , stringFin];
          }

        }
      });
      this.setState({
        DetalleDestino: DetalleDestino
      })
    }

    cambioFechaDefinicion = () =>{

      let { DetalleDestino } = this.state;

      DetalleDestino.forEach(objDestino => {
       
        objDestino.rangoFechas = ['' ,''];
        objDestino.idTrabajador = '';
        objDestino.deshabilitarRangoFechas = true;
        objDestino.fechaInvalida = undefined;
        objDestino.TrabajadoresSeleccionados.forEach(tra => {
          tra.deshabilitado = undefined;          
        });
  
      });
      this.setState({
        DetalleDestino: DetalleDestino
      })
    }
 

  //<--Modales Crear y Editar Actividad-->
    ObtenerAsignacionesIdCentroCosto = (idCentroDeCosto) => {
      
      this.props.ObtenerAsignacionesIdCentroCosto(idCentroDeCosto);
      let nombre = "";
      this.state.CentrosCosto.forEach(cc => {
        if (cc._id.toString() === idCentroDeCosto.toString()) {
          nombre = cc.Nombre;
        }
      });
      return nombre;
    };

    cambiarValores = (nombre, valor) => {
      this.setState({
        form: {
          ...this.state.form,
          [nombre]: valor,
        },
      });
    };
  //<--Modales Crear y Editar Actividad-->

  //<--Btn Crear Actividad-->
    CrearFaena = async (faena) => {
      this.setState({loading:true});
      const resp = await this.props.CrearFaena(faena);
      this.setState({loading:false});
      //this.openNotificationActividades("bottomRight", faena);
      return resp;
    };

    mostrarModalCrear = () => {
      this.props.MostrarModalAsignacion();
    };

    modalCrearCancelar = () => {
      this.props.OcultarModalAsignacion();
    };
  //<--Btn Crear Actividad-->

  //<--Btn Editar Actividad-->
    ObtenerFaena = (idFaena) => {
      this.props.ObtenerFaena(idFaena);
    };
    modalEditarCancelar = () => {
      this.props.CerrarEditar();
    };

    alargarAcortarActividad = async (faenaObj) => {
      this.setState({loading:true});
      const resp = await this.props.AlargarAcortarActividad(faenaObj);
      this.setState({loading:false});
      return resp;
    };

    alargarFaena = async (faenaObj) => {
      this.setState({loading:true});
      const resp = await this.props.AlargarFaena(faenaObj);
      this.setState({loading:false});
      return resp;
    };

    acortarFaena = async (faenaObj) => {
      this.setState({loading:true});
      const resp = await this.props.AcortarFaena(faenaObj);
      this.setState({loading:false});
      return resp;
    };
  //<--Btn Editar Actividad-->

  //<--Btn Detalle Actividad-->
    redireccionarADetalle = (idFaena) => {
      this.setState({
        idFaena,
        Redireccionar: true,
      });
    };


    RedireccionarDetalle = () => {
      const { idFaena , RangeDatesValues , Redireccionar } = this.state;
      if (Redireccionar) {
        const fechaInicioBusqueda = convertirFechaString(
          moment.utc(RangeDatesValues[0].clone()),
          1
        ).fechaString;
        const fechaFinBusqueda = convertirFechaString(
          moment.utc(RangeDatesValues[1].clone()),
          2
        ).fechaString;

        const ruta = `/DetalleActividad?idFaena=${idFaena}&fechaInicioBusqueda=${fechaInicioBusqueda}&fechaFinBusqueda=${fechaFinBusqueda}`;

        var win = window.open(ruta, "_blank");
        win.focus();

        this.setState({
          Redireccionar: false,
        });
      }
    };
  //<--Btn Detalle Actividad-->

  //<--Btn Eliminar Actividad-->
  eliminarFaena = (idFaena) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text:
        "Se eliminará la actividad junto con las planificaciones de los trabajadores de la misma.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async(result) => {
      if (result.value) {
        this.setState({loading:true});
        const resp = await this.props.EliminarFaena(idFaena);
        this.setState({loading:false});
        return resp;
      }
    });
  };
  //<--Btn Eliminar Actividad-->

  //<--Btn Actualizar Actividad-->
  actualizarFaena = async (idFaena) => {
    const { idEmpresa } = this.state;
    this.setState({ loading: true });
    const resp = await this.props.ActualizarFaena(idFaena, idEmpresa);
    this.setState({ loading: false });
    return resp;
  };
  //<--Btn Actualizar Actividad-->

  //<--Btn Filtrar Calendario Actividad-->
    filtrarCalendario = async() => {
     
      this.setState({loading:true});
      const fechas = this.state.RangeDatesValues;
      const idEmpresa = this.state.idEmpresa;
      const cc = this.state.ccSeleccionados;

      if (fechas) {
        const fechaInicio = convertirFechaString(moment.utc(fechas[0]), 1).fechaString;
        const fechaFin = convertirFechaString(moment.utc(fechas[1]), 2).fechaString;

        let arrayIniciosDeMes = [];
        let fechaInicioMesString = convertirFechaString(moment.utc(fechaInicio).startOf("month"), 1).fechaString;
        let fechaFinMesString = convertirFechaString(moment.utc(fechaFin).endOf("month"), 2).fechaString;
        let diferenciaMeses = moment.utc(fechaFinMesString).diff(moment.utc(fechaInicioMesString), 'months') + 1;
    
        for (let index = 0; index < diferenciaMeses; index++) {
          let fechaAgregar;
  
          if(index === 0){
            if(!moment.utc(fechaInicioMesString).isSame(moment.utc(fechaInicio))){
              fechaAgregar = moment.utc(fechaInicio);
            } else {
              fechaAgregar = moment.utc(fechaInicioMesString).add(index, "months");
            }
          } else {
            fechaAgregar = moment.utc(fechaInicioMesString).add(index, "months");
          }
  
          arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
        };

        const obternerActividad =  await asignacionActividad(idEmpresa,fechaInicio,fechaFin,cc,3,0);

        this.setState({
          centroCostoArray: obternerActividad.registros,
          paginasCC: obternerActividad.cantRegistros,
          filtroPActual: 1,
          loading:false,
          firstDaysOfTheMonths: arrayIniciosDeMes
        });

        //ObtenerAsignacionesFiltrado//api vieja
        //ObtenerAsignacionesFiltradoSeba
        // this.props.ObtenerAsignaciones(
        //   fechaInicio,
        //   fechaFin,
        //   this.props.centroscosto,
        //   this.state.ccSeleccionados,
        // );

      }
    };

  //<--Btn Filtrar Calendario Actividad-->

  //<--Btn Paginacion-->
    paginationFunc = async(page, limit)=>{
      this.setState({loading:true});
      const {RangeDatesValues: fechas,idEmpresa,ccSeleccionados:cc} = this.state;
      const skip = (( limit * page ) - limit);

      if (fechas) {
        const fechaInicio = convertirFechaString(moment.utc(fechas[0]), 1).fechaString;
        const fechaFin = convertirFechaString(moment.utc(fechas[1]), 2).fechaString;
        const obternerActividad =  await asignacionActividad(idEmpresa,fechaInicio,fechaFin,cc,limit,skip);
        this.setState({
          centroCostoArray: obternerActividad.registros,
          paginasCC: obternerActividad.cantRegistros,
          filtroPActual: page,
          loading:false
        })
      }

    }
  //<--Btn Paginacion-->

  render() {
    {this.RedireccionarDetalle()}

    const { centroCostoArray, paginasCC, loading, firstDaysOfTheMonths} = this.state;


    return (
      <Layout style={{ height: "100%" }}>
        <Loading cargando={loading} />
        <Header style={{ background: "none", fontFamily: "MavenPro-Bold" }}>
          <HeaderFicha
            titulo="Actividades"
            subtitulo="Ver actividades con sus detalles"
          />
        </Header>
        <hr className="division" style={{ marginRight: 35 }} />
        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <p className={"p-filtrar-plane-text"}>Filtra por:</p>

          <Row
              style={{ padding: "6px 0px 22px", marginTop: "0px" }}
              gutter={15}
            >
              <Col span={5} xs={24} s={24} sm={24} md={24} lg={5} xl={5}>
                <Select
                  disabled={this.state.Ver === true? false: true}
                  mode="multiple"
                  className="campos-formulario-contratos"
                  placeholder="Nave"
                  maxTagCount={2}
                  onChange={(val) => {
                    this.setState({ ccSeleccionados: val });
                  }}
                  value={this.state.ccSeleccionados}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {/* listaCentroCosto */}
                  {this.state.CentrosCosto.map(({ Nombre, _id }, index) => (
                    <Select.Option key={index} value={_id}>
                      {Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Col>

              <Col span={8} xs={24} s={24} sm={24} md={24} lg={7} xl={7}>
                <RangePicker
                  disabled={this.state.Ver === true? false: true}
                  locale={locale}
                  format={["DD-MM-YYYY"]}
                  allowClear={false}
                  value={this.state.RangeDatesValues}
                  onChange={(fechaMes) => {
                    if (fechaMes) {
                      this.setState({
                        RangeDatesValues: fechaMes,
                      });
                    } else {
                      let defaultDate = [
                        moment().add(-1, "month").startOf("month"),
                        moment().add(1, "month").endOf("month"),
                      ];
                      this.setState({
                        RangeDatesValues: defaultDate,
                      });
                    }
                  }}
                ></RangePicker>
              </Col>

              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 5, offset: 0 }}
                xl={{ span: 5, offset: 0 }}
                xxl={{ span: 5, offset: 0 }}
                className={"col-filtrarAct"}
              >
                <Button
                  disabled={this.state.Ver === true? false: true}
                  onClick={() => {
                    this.filtrarCalendario();
                  }}
                  className={"btn-filtrar-calendar"}
                >
                  Filtrar
                </Button>
              </Col>

              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 7, offset: 0 }}
                xl={{ span: 7, offset: 0 }}
                xxl={{ span: 7, offset: 0 }}
                className={"col-createAct"}
              >
                <Button
                  disabled={this.state.Ver === true? false: true}
                  onClick={() => {
                    this.mostrarModalCrear();
                  }}
                  className={"btn-crear-actividad"}
                >
                  Crear Actividad
                </Button>
              </Col>

              <Row style={{ marginTop: "40px" }} span={24}>
                <Col
                  xs={24}
                  sm={24}
                  md={4}
                  lg={4}
                  xl={4}
                  style={{ marginTop: "1%",marginLeft: "2%" }}
                >
                  <p
                    style={{
                      fontFamily: "MavenPro-Bold",
                      display: "inline-block",
                      marginRight: "1%",
                    }}
                  >
                    Leyenda:
                  </p>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={3}
                  lg={3}
                  xl={3}
                  style={{
                    width: "fit-content",
                    display: "inline-block",
                    marginRight: "2%",
                  }}
                  className="ant-leyenda ant-leyenda-dot"
                >
                  <Badge status="success" text="Trabajo Confirmado" />
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={3}
                  lg={3}
                  xl={3}
                  style={{
                    width: "fit-content",
                    display: "inline-block",
                    marginRight: "2%",
                  }}
                  className="ant-leyenda ant-leyenda-dot"
                >
                  <Badge status="processing" text="Trabajo en Curso" />
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={3}
                  lg={3}
                  xl={3}
                  style={{
                    width: "fit-content",
                    display: "inline-block",
                    marginRight: "2%",
                  }}
                  className="ant-leyenda ant-leyenda-dot"
                >
                  <Badge status="warning" text="Trabajo Reservado" />
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={3}
                  lg={3}
                  xl={3}
                  style={{
                    width: "fit-content",
                    display: "inline-block",
                    marginRight: "2%",
                  }}
                  className="ant-leyenda ant-leyenda-dot"
                >
                  <Badge status="error" text="Trabajo sin Planificación" />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={3}
                  lg={3}
                  xl={3}
                  style={{
                    width: "fit-content",
                    display: "inline-block",
                    marginRight: "2%",
                  }}
                  className="ant-leyenda ant-leyenda-dot"
                >
                  <Badge status="error" className='ant-badge-color-white' text="Trabajo sin Actividad" />
                </Col>
              </Row>
              
              {/* Card Centro de Costo Calendario Here! */}
              <Col span={24} style={{ marginTop: "20px" }}>
                {centroCostoArray.length > 0
                ?
                (
                  <>
                  {
                    centroCostoArray.map((centrosCosto) =>(
                    <>
                      <Col span={24} style={{ marginTop: "20px" }}>
                          <Row>
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 20, offset: 0 }}
                              xl={{ span: 20, offset: 0 }}
                              xxl={{ span: 20, offset: 0 }}
                              className={"col-content-actividad"}
                            >
                                <Row>
                                    <Col
                                      xs={{ span: 24, offset: 0 }}
                                      sm={{ span: 24, offset: 0 }}
                                      md={{ span: 24, offset: 0 }}
                                      lg={{ span: 4, offset: 0 }}
                                      xl={{ span: 4, offset: 0 }}
                                      xxl={{ span: 4, offset: 0 }}
                                      className={"col-content-actividad-align"}
                                    >
                                        <Col>
                                          <p className={"col-p-actividad-title-text"}>
                                            {centrosCosto.tieneFaena ? `${centrosCosto.faena.Nombre}`: "SIN ACTIVIDAD"}
                                          </p>
                                        </Col>
                                        <Col>
                                          <p className={"col-p-actividad-location-text"}>
                                            {centrosCosto.Nombre}
                                          </p>
                                        </Col>
                                        <Col>
                                          <p className={"col-p-actividad-fecha-text"}>
                                            {centrosCosto.tieneFaena ? `${moment.utc(centrosCosto.faena.Fecha_Inicio).format('DD-MM-YYYY')} ∞ ${moment.utc(centrosCosto.faena.Fecha_Fin).format('DD-MM-YYYY')}`: "SIN FECHAS"}
                                          </p>
                                        </Col>
                                        {/* botones editar y detalle */}
                                        <Row>
                                          <Col xs={12} sm={4} lg={12} xxl={6}>
                                            <Tooltip title="Editar Actividad" color={'geekblue'}>
                                              <Button
                                                className={"btn-editar-actividad"}
                                                onClick={() => {
                                                  this.ObtenerFaena(centrosCosto.faena._id);
                                                }}
                                                icon={<EditFilled />}
                                                //disabled={centrosCosto.tieneFaena ? false : true  }
                                                //disabled={this.state.Editar === true? false: true }
                                                disabled={centrosCosto.tieneFaena && this.state.Editar === true ? false : true}
                                              >
                                            </Button>
                                            </Tooltip>
                                          </Col>
                                          <Col xs={12} sm={4} lg={12} xxl={6}>
                                            <Tooltip title="Detalle Actividad" color={'geekblue'}>
                                              <Button
                                                className={"btn-detalle-actividad"}
                                                onClick={()=>{
                                                  this.redireccionarADetalle(centrosCosto.faena._id)
                                                }}
                                                //disabled={centrosCosto.tieneFaena ? false : true}
                                                //disabled={this.state.VerDetalle === true? false: true}
                                                disabled={centrosCosto.tieneFaena && this.state.VerDetalle === true ? false : true}
                                              >
                                                <img
                                                  src={vista}
                                                  className="btn-img-vista"
                                                  alt="icono vista"
                                                />
                                              </Button>
                                            </Tooltip>
                                          </Col>
                                          {/* btn eliminar */}
                                          <Col xs={12} sm={4} lg={12} xxl={6}>
                                            <Tooltip title="Eliminar Actividad" color={'geekblue'}>
                                              <Button
                                                className="btn-eliminar-actividad"
                                                onClick={() => {
                                                  this.eliminarFaena(centrosCosto.faena._id);
                                                }}
                                                icon={<DeleteFilled />}
                                                disabled={centrosCosto.tieneFaena && this.state.Eliminar === true ? false : true}
                                              />
                                            </Tooltip>
                                          </Col>

                                          {/* btn actualizar actividad */}
                                          <Col xs={12} sm={4} lg={12} xxl={6}>
                                            <Tooltip title="Actualizar Actividad" color={'geekblue'}>
                                              <Button
                                                className="btn-eliminar-actividad"
                                                onClick={() => {
                                                  this.actualizarFaena(centrosCosto.faena._id);
                                                }}
                                                icon={<ReloadOutlined />}
                                                disabled={centrosCosto.tieneFaena && this.state.Eliminar === true ? false : true}
                                              />
                                            </Tooltip>
                                          </Col>
                                        </Row>
                                    </Col>
                                    
                                        {/* grafico */}

                                        <Col
                                          xs={{ span: 24, offset: 0 }}
                                          sm={{ span: 24, offset: 0 }}
                                          md={{ span: 24, offset: 0 }}
                                          lg={{ span: 19, offset: 0 }}
                                          xl={{ span: 19, offset: 0 }}
                                          xxl={{ span: 19, offset: 0 }}
                                          className={"col-content-grafico"}
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              height: "100px",
                                              overflowX: "scroll",
                                            }}
                                            className="contenedor-step-asig-act"
                                          >

                                            <Steps
                                              current={0}
                                              progressDot={customDot}
                                              className="ant-icon-tamaño testContainer testTail step-fecha-inicio-fin-mes"
                                              direction={"horizontal"}
                                              labelPlacement={"horizontal"}
                                              responsive={false}
                                              style={{marginBottom: "0.5rem"}}
                                            >
                                              {
                                                centrosCosto.faena.EstadosDias.length > 0 && centrosCosto.faena.EstadosDias.map((diasCC)=>
                                                <>
                                                  <Step 
                                                    key={diasCC._id} 
                                                    title={firstDaysOfTheMonths.filter((fecha) => 
                                                      moment.utc(fecha).isSame(moment.utc(convertirFechaString(moment.utc(diasCC.Fecha), 1).fechaString))).length > 0 && moment.utc(diasCC.Fecha).format('MMMM').substring(0, 3).toUpperCase()
                                                    } 
                                                    className={
                                                      diasCC.Estado === "5f3eedf56dd7cc57f0d3a261" && "dot-green-timeline"  
                                                    }
                                                  />

                                                </>)
                                              }
                                            </Steps>

                                            <Steps
                                              current={0}
                                              progressDot={customDot}
                                              className="ant-icon-tamaño testContainer testTail"
                                            >
                                                {
                                                  centrosCosto.faena.EstadosDias.length > 0 && centrosCosto.faena.EstadosDias.map((diasCC)=>
                                                  <>
                                                    <Step 
                                                      key={diasCC._id} 
                                                      title={moment.utc(diasCC.Fecha).format('DD')} 
                                                      className={diasCC.Estado === "5f3ef5ce1ecf531c1c8ab4a7"? "dot-red" : diasCC.Estado ==="5f3ef6981ecf531c1c8ab4aa"? "dot-blue": diasCC.Estado === "5f3ef6861ecf531c1c8ab4a9"? "dot-green": diasCC.Estado ==="5f3ef6591ecf531c1c8ab4a8"? "dot-yellow" : "dot-white"}  
                                                    />
                                                  </>)
                                                }
                                            </Steps>
                                          </div>
                                        </Col>
                                        {/* grafico */}
                                </Row>
                            </Col>
                            {/* porcentaje */}
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 3, offset: 1 }}
                              xl={{ span: 3, offset: 1 }}
                              xxl={{ span: 3, offset: 1 }}
                              className={"col-content-porcentaje-sin-planificar"}
                            >
                              <p
                                className={"col-p-porcentaje-sin-planificar-title"}
                              >
                                Porcentaje sin Planificar
                              </p>
                              <p className={"col-p-porcentaje-sin-planificar"}>
                                {centrosCosto.tieneFaena ? `${centrosCosto.porcentaje} %`: "0,00 %"}
                              </p>
                            </Col>
                          </Row>
                      </Col>
                    </>))
                  }

                  <Col className={"col-pagination"}>
                      {/* <Pagination current={this.state.filtroPActual} onChange={this.paginationFunc} total={this.state.paginasCC[0].myCount} pageSize={3}/> */}
                      <Pagination current={this.state.filtroPActual} onChange={this.paginationFunc} total={this.state.paginasCC[0].myCount} defaultPageSize={3} showSizeChanger={true} pageSizeOptions={["3","10","20","50"]} />
                  </Col>
                  </>
                )

                : (
                  <>
                    <Empty
                      imageStyle={{ height: 200, marginTop: "20px" }}
                      description={
                        <h2 className={"seleccion-trabajador-disponible"}>
                          <b>No Hay registros.</b>
                          <br />
                          <b>Verifica si existen centros de costos vigentes.</b>
                        </h2>
                      }
                    ></Empty>
                  </>
                )}
              </Col>
          </Row>
        </Content>

        <Modal
          //propiedades cambiables del modal(CrearActividad)
          wrapClassName="modalCrear"
          visible={this.props.modalFaena}
          onCancel={this.modalCrearCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20, borderRadius: "16px" }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          className={"ant-modal-crear-actividad"}
        >
          <FormularioAsignacionFaenas
            ObtenerAsignacionesIdCentroCosto={
              this.ObtenerAsignacionesIdCentroCosto
            }
            Formulario={this.state.form}
            cambiarValores={this.cambiarValores}
            CrearFaena={this.CrearFaena}
            centrosCosto={this.state.CentrosCosto}
            plantillas={this.props.plantillas}
            fechaDeshabilitada={this.props.fechaDeshabilitada}
            faenasFechasCentroCosto={this.props.faenasFechasCentroCosto}
            traerActividadesCentrosCostos={this.traerActividadesCentrosCostos}
            actividadesCC={this.state.ActividadesCC}
            detalleActividadOrigenModal={this.detalleActividadOrigenModal}
            detalleOrigen={this.state.DetalleOrigen}
            detalleActividadDestinoModal={this.detalleActividadDestinoModal}
            detalleDestino={this.state.DetalleDestino}
            idEmpresa={this.state.idEmpresa}
            traspasarTrabajadoresModal={this.traspasarTrabajadoresModal}
            setterTrabajador={this.setterTrabajador}
            setterTrabajadorFecha={this.setterTrabajadorFecha}
            cambioFechaDefinicion={this.cambioFechaDefinicion}
            //disponibilidadTransbordoModal={this.disponibilidadTransbordoModal}
            disponibilidadTransbordoTrabajadorModal={this.disponibilidadTransbordoTrabajadorModal}
            disponibilidadTransbordo={this.state.DisponibilidadTransbordo}
          />
        </Modal>

        <Modal
            //propiedades cambiables del modal
            wrapClassName="modalEditar"
            visible={this.props.mostrarModalEditar}
            onCancel={this.modalEditarCancelar}
            //propiedades obligatorias para modal mantenedor
            bodyStyle={{ borderRadius: "16px" }}
            width="80%"
            footer={null}
            style={{ top: 20 }}
            closable={false}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
            className={"ant-modal-editar-actividad btn-switch-checked"}
          >
            <FormularioAsignacionFaenasEditar
              ObtenerAsignacionesIdCentroCosto={
                this.ObtenerAsignacionesIdCentroCosto
              }
              cambiarValores={this.cambiarValores}
              centrosCosto={this.state.CentrosCosto}
              plantillas={this.props.plantillas}
              fechaDeshabilitada={this.props.fechaDeshabilitada}
              faenasFechasCentroCosto={this.props.faenasFechasCentroCosto}
              Formulario={this.props.faenaEditar}
              alargarFaena={this.alargarFaena}
              acortarFaena={this.acortarFaena}
              alargarAcortarActividad={this.alargarAcortarActividad}
            />
        </Modal>
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  asignacionFaenasReducer: {
    loading,
    centroscosto,
    plantillas,
    error,
    success,
    modalFaena,
    faenas,
    faenasFechasCentroCosto,
    faena,
    tablaCargando,
    mostrarCalendario,
    fechaDeshabilitada,
    contadores,
    faenaEditar,
    mostrarModalEditar,
    cantidadFaenas,
  },
}) => {
  return {
    loading,
    centroscosto,
    plantillas,
    error,
    success,
    modalFaena,
    faenas,
    faenasFechasCentroCosto,
    faena,
    tablaCargando,
    mostrarCalendario,
    fechaDeshabilitada,
    contadores,
    faenaEditar,
    mostrarModalEditar,
    cantidadFaenas,
  };
};

export default connect(
  EstadosAPropiedades,
  AsignacionFaenasActions
)(asignacionActividades);

