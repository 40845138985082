import React, { Component, forwardRef } from "react";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import "./CentroControl.css";
import DashboardEstadoCargo from "../../components/dashboard/DashboardEstadoCargo";
import DashboardRegimenTrabajo from "../../components/dashboard/DashboardRegimenTrabajo";
import DashboardCostoCierreMes from "../../components/dashboard/DashboardCostoCierreMes";
import DashboardEstadoTrabajadores from "../../components/dashboard/DashboardEstadoTrabajadores";
import DashboardCurvaRelevos from "../../components/dashboard/DashboardCurvaRelevos";//Ultimo
import DashboardVencimiento from "../../components/dashboard/DashboardVencimiento";
import DashboardEmbarqueDesembarque from "../../components/dashboard/DashboardEmbarqueDesembarque";
import DashboardReporteEstados from "../../components/dashboard/DashboardReporteEstados";

import iconFechaIzq from "../../img/icons/BotonSideLeft.png";
import carga_dashboard from "../../img/dashboard/carga_dashboard.png";

import API from "../../Redux/Api/api";
import "moment/locale/es";

import Swal from "sweetalert2";

import {
  Layout,
  Row,
  Col,
  Button,
  Drawer,
  Checkbox
} from "antd";

const { Header, Content } = Layout;

class CentroControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      Empresa: '',
      Usuario: '',
      idTrabajadoresPorEstado:'6294ed6d57f7ff0774956c77',
      idTrabajadoresPorRegimenDeTrabajo:'6294ee2b57f7ff0774956c78',
      idTrabajadoresPorEstadoCargo:'6294ee4d57f7ff0774956c79',
      idCurvaDeRelevos:'6294ee5f57f7ff0774956c7a',
      idCostosCierreDeMes:'6294ee7757f7ff0774956c7b',
      idVencimiento:'6294ee8557f7ff0774956c7c',
      idEmbarqueDesembarque:'6298cb305994ccb53319bea6',
      idReporteEstado:'62f428a3296aa6bb45c6ce00',
      //EN EL CASO QUE SE AGREGUE UN DASHBOARD NUEVO SE INSERTA ACÁ.
      cardViewA:false,//Trabajadores por estado
      cardViewB:false,//Trabajadores por régimen de trabajo
      cardViewC:false,//Trabajadores por estado cargo
      cardViewD:false,//Curva de relevos
      cardViewE:false,//Costos cierre de mes
      cardViewF:false,//Vencimiento
      cargosEmpresa: [],
      centrosCostoEmpresa: [],
      dashboardSistema:[],
      dashboardTrabajador:[],
      dashboardSeleccionado:[]
    };
  
  }

  componentDidMount = async () => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      const idUsuario = usuario._id;
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      let dashboardSistema = await this.obtenerDashboardSistema();
      let dashboardTrabajador = await this.obtenerDashboardTrabajador(idUsuario);

      let cargos = await this.obtenerCargos(idEmpresa);
      let centrosCosto = await this.obtenerCentrosCosto(idEmpresa);

      if (cargos.status !== 200) {
        this.setState({
          loading: false,
        });

        // Swal.fire({
        //   title: "¡Ups!",
        //   text: "Ha ocurrido un error al obtener los cargos",
        //   icon: "warning",
        //   confirmButtonColor: "#1FC600",
        //   confirmButtonText: "Ok",
        // });
        return;
      }

      if (centrosCosto.status !== 200) {
        this.setState({
          loading: false,
        });

        // Swal.fire({
        //   title: "¡Ups!",
        //   text: "Ha ocurrido un error al obtener los centros de costo",
        //   icon: "warning",
        //   confirmButtonColor: "#1FC600",
        //   confirmButtonText: "Ok",
        // });
        return;
      }
      let dashboardSeleccionado = [];
      dashboardSistema.map((dashboard)=>{
        const encontrado = dashboardTrabajador.filter((x)=> x.IdDashboard.toString() === dashboard._id.toString()).length
        
        if(encontrado !== 0){
          dashboardSeleccionado.push(dashboard._id.toString())
        }
      }
      );

      this.setState({
        cargosEmpresa: cargos.data,
        centrosCostoEmpresa: centrosCosto.data,
        dashboardSistema,
        dashboardTrabajador,
        dashboardSeleccionado,
        Empresa: idEmpresa,
        Usuario: idUsuario
      });

    }
  };

  obtenerCargos = async (idEmpresa) => {
    try {
      const url = `${API}/CargosTrabajadores?empresa=${idEmpresa}`;

      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = await response.status;
      const data = await response.json();

      let respuesta;

      if (status === 200 || status === 201) {
        respuesta = {
          status: status,
          data: data.data,
        };
      } else {
        respuesta = {
          status: status,
          data: data.err,
        };
      }

      return respuesta;
    } catch (error) {
      let respuesta;

      respuesta = {
        status: 500,
        data: error,
      };

      return respuesta;
    }
  };

  obtenerCentrosCosto = async (idEmpresa) => {
    try {
      const url = `${API}/CentroDeCostos?empresa=${idEmpresa}`;

      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = await response.status;
      const data = await response.json();

      let respuesta;

      if (status === 200 || status === 201) {
        respuesta = {
          status: status,
          data: data.centrosDeCostos,
        };
      } else {
        respuesta = {
          status: status,
          data: data.err,
        };
      }

      return respuesta;
    } catch (error) {
      let respuesta;

      respuesta = {
        status: 500,
        data: error,
      };

      return respuesta;
    }
  };

  //////////nuevas funciones///////////////
  //AGREGAR DASHBOARD
  agregarDashboardSistema = async (objeto) => {
    try {
      const url = `${API}/AgregarDashboardUsuario`;

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });
      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let detalle = data.data;
          return {
            status,
            detalle
          };

        default:
          return {
            status,
            detalle: "paso por default"
          };
      }

    } catch (error) {
        return {
          status: 500,
          detalle: "paso por catch"
        };
    }
  };

  //QUITAR DASHBOARD
  quitarDashboardSistema = async (objeto) => {
    try {
      const url = `${API}/QuitarDashboardUsuario`;

      const response = await fetch(url, {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });
      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let detalle = data.data;
          return {
            status,
            detalle
          };

        default:
          return {
            status,
            detalle: "paso por default"
          };
      }

    } catch (error) {
      return {
        status: 500,
        detalle: "paso por catch"
      };
    }
  };

  //DASHBOARD 
  obtenerDashboardSistema = async () => {
    try {
      const url = `${API}/ObtenerDashboardSistema`;

      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let detalle = data.data;
          return detalle;

        default:
          const error = data.err.message.toString();
          console.error(error);

          return [];
      }

    } catch (error) {
      console.error(error);
    }
  };

  //DASHBOARD SELECCIONADOS POR EL USUARIO
  obtenerDashboardTrabajador = async (usuario) => {
    try {
      const url = `${API}/ObtenerDashboardTrabajador/${usuario}`;

      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let detalle = data.data;
          return detalle;

        default:
          const error = data.err.message.toString();
          console.error(error);

          return [];
      }

    } catch (error) {
      console.error(error);
    }
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onSelectDashboard = async(checkedValues) => {
    const {dashboardSeleccionado, Empresa, Usuario} = this.state;

    if(checkedValues.length > dashboardSeleccionado.length){
        //AGREGAR DASHBOARD
        let nuevoDashboard = checkedValues.filter((checkDash , pos)=>{
          return dashboardSeleccionado.indexOf(checkDash) <0;
        });
      
        if(nuevoDashboard.length === 1){
        let objEnviar ={
          usuario: Usuario,
          empresa: Empresa,
          dashboard: nuevoDashboard[0]
        }

        let respuesta = await this.agregarDashboardSistema(objEnviar)
        if(respuesta.status !== 200 && respuesta.status !== 201){
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se logro completar la accion",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
          return false;
        }

      }else{
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se agregó la selección correctamente del dashboard.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
        return false;
      }

    }else{
      //QUITAR DASHBOARD
      let eliminarDashboard = dashboardSeleccionado.filter((checkDash , pos)=>{
        return checkedValues.indexOf(checkDash) < 0;
      });

      if(eliminarDashboard.length === 1){
        let objEnviar = {
          usuario: Usuario,
          empresa: Empresa,
          dashboard: eliminarDashboard[0]
        }

        let respuesta = await this.quitarDashboardSistema(objEnviar);
        if(respuesta.status !== 200 && respuesta.status !== 201){
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se logro completar la accion",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
          return false;
        }
        
      }else{
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se quitó la selección correctamente del dashboard.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
        return false;
      }

    }

    this.setState({
      dashboardSeleccionado: checkedValues
    });
    // let cardViewA = false;
    // let cardViewB = false;
    // let cardViewC = false;
    // let cardViewD = false;
    // let cardViewE = false;
    // let cardViewF = false;

    // if(checkedValues.includes("A")){
    //   cardViewA= true;
    // }
    // if(checkedValues.includes("B")){
    //   cardViewB= true;
    // }
    // if(checkedValues.includes("C")){
    //   cardViewC= true;
    // }
    // if(checkedValues.includes("D")){
    //   cardViewD= true;
    // }
    // if(checkedValues.includes("E")){
    //   cardViewE= true;
    // }
    // if(checkedValues.includes("F")){
    //   cardViewF= true;
    // }

    // this.setState({
    //   cardViewA,
    //   cardViewB,
    //   cardViewC,
    //   cardViewD,
    //   cardViewE,
    //   cardViewF,
    // });
     
  };

  render() {
    let {
      dashboardSistema,
      dashboardSeleccionado,
      idTrabajadoresPorEstado,
      idTrabajadoresPorRegimenDeTrabajo,
      idTrabajadoresPorEstadoCargo,
      idCurvaDeRelevos,
      idReporteEstado,
      idCostosCierreDeMes,
      idVencimiento,
      idEmbarqueDesembarque,
    }= this.state;
    return (
      <Layout style={{ height: "100%" }}>
        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Dashboard"
            subtitulo="Estadísticas de tu empresa y trabajadores"
          />
        </Header>

        <hr className="division" />

        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >   
          <Row gutter={24}>
            {/* BTN SELECCIONAR DASHBOARD*/}
            <Col
              span={24}
              style={{
                textAlign:"right",
              }}
            >
              <Button
                style={{
                  width: "240px", height: "50px", fontSize:"16px",background:"#071FCB",color:"#FFFFFF",fontFamily:"MavenPro-Bold", 
                  boxShadow:"0px 3px 6px #00000029",borderRadius:"9px 0px 0px 9px", opacity:"1"
                }}

                onClick={this.showDrawer}
              >
                <img src={iconFechaIzq} style={{ width:"35px", height:"35px"}}/> Selección de dashboard
              </Button>

              <Drawer
                title="Selección de dashboard"
                closable={true}
                headerStyle={{
                  textAlign: "left",
                  fontFamily:"MavenPro-Bold",
                  color: "#071FCB",
                  fontSize:"25px"
                  // backgroundColor:"#071FCB"

                }}
                width={350}
                className={"drawer-dashboard"}
                onClose={this.onClose}
                visible={this.state.visible}
                footer="People Management"
              >
                <p className={"drawer-text-dashboard"}>Selecciona el dashboard que quieras visualizar</p>
                <Checkbox.Group 
                  style={{ width: '100%' }}
                  onChange={this.onSelectDashboard}
                  value={dashboardSeleccionado}
                >
                  <Row className={"drawer-text-dashboard"}>
                    {/* <Col span={24}>
                      <Checkbox value="A">Trabajadores por estado</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="B">Trabajadores por régimen de trabajo</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="C">Trabajadores por estado cargo</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="D">Curva de relevos</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="E" disabled={true}>Costos cierre de mes</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="F">Vencimientos</Checkbox>
                    </Col> */}
                      {  
                        dashboardSistema.map((data)=>(
                          <Col span={24}>
                            <Checkbox value={data._id} disabled={data.Vigencia === false ? true: false}  >{data.Nombre}</Checkbox>
                          </Col>
                        )
                        )
                      }
                  </Row>
                </Checkbox.Group>

                {/* <Button
                  style={{ width: "100%",background:"#64B4E5",color:"#FFFFFF",fontFamily:"MavenPro-Bold", marginTop:"25px", borderRadius:"9px" }}
                  //onClick={this.}
                >
                  Aplicar
                </Button> */}

              </Drawer>
            </Col>
        
           {
            //Para deshabilitar la imagen de presentacion de dashboard
            // cardViewA || cardViewB || cardViewC || cardViewD || cardViewE || cardViewF === true
            dashboardSeleccionado.length !== 0            ?
            <>
            </>
            :
            <>
              <Col span={24} style={{textAlign:"center"}}>
                <img src={carga_dashboard} className={"img-carga-dashboard"} />
              </Col>
              <Col span={24} style={{textAlign:"center"}}>
                <h1
                  className={"h1-carga-dashboard"}
                >
                  Selecciona los dashboard que quieras visualizar
                </h1>
              </Col>  
            </>
           }

            {/* Trabajadores por estado UNO */}
            
            {
            dashboardSeleccionado.filter((x)=> x === idTrabajadoresPorEstado).length === 0 ?
            // cardViewA === false ?
            <>
            </>
            :
            <>
              <DashboardEstadoTrabajadores
                cargosEmpresa={this.state.cargosEmpresa}
                centrosCostoEmpresa={this.state.centrosCostoEmpresa}
              />
            </>
            }

            {/* Trabajadores por régimen de trabajo  DOS */}
        
            {
              dashboardSeleccionado.filter((x)=> x === idTrabajadoresPorRegimenDeTrabajo).length === 0 ?
              // cardViewB === false ?
              <></>
              :
              <>
                <DashboardRegimenTrabajo
                  cargosEmpresa={this.state.cargosEmpresa}
                  centrosCostoEmpresa={this.state.centrosCostoEmpresa}
                />
              </>
            }

            {/* Trabajadores por estado cargo TRES*/}

            {
              dashboardSeleccionado.filter((x)=> x === idTrabajadoresPorEstadoCargo).length === 0 ?
              // cardViewC === false ?
              <>
       
              </>
              :
              <>
                <DashboardEstadoCargo
                  cargosEmpresa={this.state.cargosEmpresa}
                  centrosCostoEmpresa={this.state.centrosCostoEmpresa}
                />
              </>
            }


            {/* Curva de relevos CUATRO */}
    
            {
              dashboardSeleccionado.filter((x)=> x === idCurvaDeRelevos).length === 0 ?
              // cardViewD === false ?
              <></>
              :
              <>
                <DashboardCurvaRelevos
                  cargosEmpresa={this.state.cargosEmpresa}
                />
              </>
            }

            {/* Reporte de estados */}

            {
              dashboardSeleccionado.filter((x)=> x === idReporteEstado).length === 0 ?
              // cardViewD === false ?
              <></>
              :
              <>
                <DashboardReporteEstados
                  cargosEmpresa={this.state.cargosEmpresa}
                />
              </>
            }



            {/* Costos cierre de mes  */}
      
            {
              dashboardSeleccionado.filter((x)=> x === idCostosCierreDeMes).length === 0 ?
              // cardViewE === false ?
              <></>
              :
              <>
                <DashboardCostoCierreMes
                  cargosEmpresa={this.state.cargosEmpresa}
                  centrosCostoEmpresa={this.state.centrosCostoEmpresa}
                />
              </>
            }
            {/* DashboardEmbarqueDesembarque */}
            {
              dashboardSeleccionado.filter((x)=> x === idEmbarqueDesembarque).length === 0 ?
              <></>
              :
              <>
                <DashboardEmbarqueDesembarque
                  cargosEmpresa={this.state.cargosEmpresa}
                  centrosCostoEmpresa={this.state.centrosCostoEmpresa}
                />
              </>
            }
            {/* Vencimiento */}
      
            {
              dashboardSeleccionado.filter((x)=> x === idVencimiento).length === 0 ?
              // cardViewF === false ?
              <></>
              :
              <>
               <DashboardVencimiento 
                  cargosEmpresa={this.state.cargosEmpresa}
                  centrosCostoEmpresa={this.state.centrosCostoEmpresa}
               />
              </>
            }

          </Row>
        </Content>
      </Layout>
    );
  }
}

export default CentroControl;
