import {
  ERROR,
  INGRESAR_TIPOEMPRESA,
  LOADING,
  MOSTRAR_MODAL_TIPOEMPRESA,
  OCULTAR_MODAL_TIPOEMPRESA,
  OBTENER_TIPOSEMPRESA_MANTENEDOR,
  // PARAR_LOADING,
  OBTENER_TIPOEMPRESA_MANTENEDOR,
  ELIMINAR_TIPOEMPRESA_MANTENEDOR,
  EDITAR_TIPOEMPRESA_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OCULTAR_MODAL_TIPOEMPRESA_EDITAR,
} from "../types/MantenedorTipoEmpresaTypes";
import API from "../Api/api";
//   import { obtenerFechaHoy } from "../../funcionesGenericas/fechas";

import Swal from "sweetalert2";

export const CrearTipoEmpresa = (tipoEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/TipoDeEmpresa`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(tipoEmpresa),
    });
    const status = response.status;

    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Se ingreso tipo de empresa correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: INGRESAR_TIPOEMPRESA,
          payload: "Se ingresó el usuario correctamente",
        });

        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerTiposEmpresa = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/TipoDeEmpresa`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const tiposEmpresa = data.TiposDeEmpresa;

        dispatch({
          type: OBTENER_TIPOSEMPRESA_MANTENEDOR,
          payload: tiposEmpresa,
        });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerTipoEmpresa = (idTipoEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/TipoDeEmpresa/${idTipoEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const tipoEmpresa = data.TipoDeEmpresa;
        dispatch({
          type: OBTENER_TIPOEMPRESA_MANTENEDOR,
          payload: tipoEmpresa,
        });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarTipoEmpresa = (idTipoEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/TipoDeEmpresa/${idTipoEmpresa}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El rol fue eliminado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: ELIMINAR_TIPOEMPRESA_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarTipoEmpresa = (TipoEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/TipoDeEmpresa/${TipoEmpresa._id}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },

      body: JSON.stringify(TipoEmpresa),
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El rol fue actualizado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: EDITAR_TIPOEMPRESA_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const TomarValoresEditar = (nombreCampo, valor) => (dispatch) => {
  let objeto = {
    nombreCampo,
    valor,
  };
  dispatch({ type: TOMAR_VALORES_EDITAR, payload: objeto });
};

export const MostrarModalTipoEmpresa = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_TIPOEMPRESA });
};

export const OcultarModalTipoEmpresa = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_TIPOEMPRESA });
};

export const OcultarModalTipoEmpresaEditar = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_TIPOEMPRESA_EDITAR });
};

//   export const DescargarExcel = () => async (dispatch) => {
//     dispatch({ type: LOADING });
//     try {
//       const url = `${API}/Rol/Descargar/Excel`;
//       let status;
//       fetch(url)
//         .then((response) => {
//           status = response.status;
//           return response.blob();
//         })
//         .then((blob) => {
//           switch (status) {
//             case 200:
//             case 201:
//               const url = window.URL.createObjectURL(blob);
//               const a = document.createElement("a");
//               a.href = url;
//               a.download = `Roles_${obtenerFechaHoy()}.xlsx`;
//               document.body.appendChild(a);
//               a.click();
//               a.remove();

//               dispatch({ type: PARAR_LOADING });
//               break;
//             default:
//               Swal.fire({
//                 title: "Uups",
//                 text:
//                   "Error status: " +
//                   status +
//                   " al realizar la descarga del archivo",
//                 icon: "error",
//               });

//               dispatch({ type: PARAR_LOADING });
//               break;
//           }
//         });
//     } catch (error) {
//       dispatch({ type: ERROR, payload: error });
//     }
//   };
