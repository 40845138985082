import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import "./DashboardDinamicoTrabajador.css";


import efectivo from '../../img/icons/embarcado.svg'
import descanso from '../../img/icons/descanso.svg'
import enTransito from '../../img/icons/enTransito.svg'
import vacaciones from '../../img/icons/vacaciones.svg'
import licencia from '../../img/icons/Licencia.svg'
import mutual from '../../img/icons/accidente.svg'
import permiso from '../../img/icons/Permiso.svg'
import ausente from '../../img/icons/Ausente.svg'
import noAsignado from '../../img/icons/NoAsignado.svg'




// import otros from '../../img/icons/otrasCondiciones.svg'




class DashboardDinamico extends Component {


  obtenerImagen = (tipo) => {
    switch (tipo) {

      // Efectivo
      case "5f3eedf56dd7cc57f0d3a261":
        return efectivo;

      // Descanso
      case "5f3eeb78978b284b68ebced4":
        return descanso;

      // Vacaciones
      case "5f3eebac978b284b68ebced5":
        return vacaciones;

      // Licencia
      case "5f3eebf46dd7cc57f0d3a25d":
        return licencia;

      // Mutual
      case "5f3eec676dd7cc57f0d3a25e":
        return mutual;

      // Permiso
      case "5f3eec876dd7cc57f0d3a25f":
        return permiso;

      // Ausente
      case "5f3eeca16dd7cc57f0d3a260":
        return ausente;

      // En Tránsito
      case "5f8f369e8efe155a48df37fb":
        return enTransito;

      default:
        return noAsignado;
    }
  }

  render() {
    return (
      <>
        <Row gutter={16}>

          {this.props.reporteDashboard.map((valor, index) => (
            <Col key={index} span={6}>
              <Card className="dashboard">
                <Row>
                  <Col span={6}>
                    <img
                      style={{ width: 55 }}
                      src={this.obtenerImagen(valor.tipo)}
                      alt="icono dash1"
                    />
                  </Col>
                  <Col span={18} style={{ textAlign: "left", marginBottom: "0" }}>
                    <h2 className="titulo-dashboard-dinamico-trabajador">{valor.dias} {valor.titulo}</h2>
                    <h4 className="sub-titulo-dashboard-dinamico-trabajador">{valor.subtitulo}</h4>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}

        </Row>
      </>
    );
  }
}

export default DashboardDinamico;
