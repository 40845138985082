export const LOADING = "MANTENEDOR_TITULO_LOADING";
export const ERROR = "MANTENEDOR_TITULO_ERROR";
export const INGRESAR_TITULO = "MANTENEDOR_TITULO_INGRESAR";
export const LIMPIAR_ERROR_SUCCESS = "MANTENEDOR_TITULO_LIMPIAR_ERROR_SUCCESS";
export const MOSTRAR_MODAL_TITULO = "MANTENEDOR_TITULO_MOSTRAR_MODAL";
export const OCULTAR_MODAL_TITULO = "MANTENEDOR_TITULO_OCULTAR_MODAL";
export const OCULTAR_MODAL_TITULO_EDITAR =
  "MANTENEDOR_TITULO_OCULTAR_MODAL_EDITAR";
export const OBTENER_TITULOS_MANTENEDOR = "MANTENEDOR_TITULO_OBTENER_TITULOS";
export const OBTENER_TITULO_MANTENEDOR = "MANTENEDOR_TITULO_OBTENER_TITULO";
export const ELIMINAR_TITULO_MANTENEDOR = "MANTENEDOR_TITULO_ELIMINAR_TITULO";
export const EDITAR_TITULO_MANTENEDOR = "MANTENEDOR_TITULO_EDITAR_TITULO";
export const TOMAR_VALORES_EDITAR = "MANTENEDOR_TITULO_TOMAR_VALORES_EDITAR";
