import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import API from "../../Redux/Api/api";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import "moment/locale/es";
import { convertirFechaString} from "../../funcionesGenericas/fechas";
import Swal from "sweetalert2";
import { EyeFilled,EditFilled, DeleteFilled } from "@ant-design/icons";
import Loading from "../../components/loading/Loading";

// import TrabajoEfectivo from "../../img/dashboard/bubble/TrabajoEfectivo.svg";
// import Descanso from "../../img/dashboard/bubble/Descanso.svg";
// import EnTransito from "../../img/dashboard/bubble/EnTransito.svg";
// import Vacaciones from "../../img/dashboard/bubble/Vacaciones.svg";
// import Licencia from "../../img/dashboard/bubble/Licencia.svg";
// import LicenciaMutual from "../../img/dashboard/bubble/LicenciaMutual.svg";
// import Permiso from "../../img/dashboard/bubble/Permiso.svg";
// import Ausente from "../../img/dashboard/bubble/Ausente.svg";
// import NoAsignado from "../../img/dashboard/bubble/NoAsignado.svg";
import Excel from "../../img/icons-sidebar/blancas/IconExcel.svg";

import TrabajoEfectivo from "../../img/icons-nuevos/TrabajoEfectivo.svg";
import Descanso from "../../img/icons-nuevos/Descanso.svg";
import EnTransito from "../../img/icons-nuevos/EnTransito.svg";
import Vacaciones from "../../img/icons-nuevos/Vacaciones.svg";
import Licencia from "../../img/icons-nuevos/Licencia.svg";
import LicenciaMutual from "../../img/icons-nuevos/LicenciaMutualidad.svg";
import Permiso from "../../img/icons-nuevos/Permiso.svg";
import Ausente from "../../img/icons-nuevos/Ausente.svg";
import NoAsignado from "../../img/icons-nuevos/NoAsignado.svg";
import Ineficiente from "../../img/icons-nuevos/Ineficiente.svg";
import IconListBlueLight from "../../img/icons-nuevos/IconListBlueLight.svg";

import carga_dashboard from "../../img/dashboard/carga_dashboard.png";
import {
    Row,
    Col,
    Card,
    Select,
    Button,
    DatePicker,
    Modal,
    Table,
    Tabs,
    Empty,
    Input,
    Tooltip,
    Avatar, List, Form, Steps,
  Popover,
} from "antd";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Search } = Input;
const { Column } = Table;
const { TabPane } = Tabs;
const { Meta } = Card
const { Step } = Steps;
const customDot = (dot, { status, index }) => (
  <Popover
    overlayInnerStyle={{border: "2px solid #7C8DFC", borderRadius: "9px",fontFamily:"MavenPro-SemiBold",fontSize:"12px"}}
    content={
      <span>
        {status}
      </span>
    }
  >
    {dot}
  </Popover>
);
class DashboardEstadoTrabajadores extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          dataGrafico:[],
          totalesGrafico:[],
          fechaSelecionada: moment().format('YYYY-MM-DD'),
          cargoSeleccionado: "",
          centroDeCostoSeleccionado: "",
          modalDetalle:false,
          modalIneficiente:false,
          dataIneficiente:[],
          objIneficiente: {},
          dataListaEstados: [],
          dataContratosTra:[],
          dataDetalle:[],
          indexActual: "1",
          trabajadoresCopia: undefined,
          fechaDisabled: true,
          contratoElegido: "",
          contratoSelect:{},
          trabajadorIDSelect: "",
          bitacorasTimeline: [],
          TrabajadorSeleccionado: 0,
          textoChangeIneficiente: "",
          botonActiveEdit: false,
          botonActiveDelete: false,
          fechasBusquedaSelect: [moment(),moment()]
        };

    }

    FormularioRef = React.createRef();

    componentDidMount = async () => {
      let usuario = localStorage.getItem("usuario-people-manage");
      if (
        usuario !== null &&
        usuario !== "" &&
        usuario !== undefined &&
        usuario !== "undefined"
      ) {

        usuario = JSON.parse(usuario);
        const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
        const str = moment().format('YYYY-MM-DD');
        const fechaActual = convertirFechaString(moment(str), 1).fechaString;
        const centroDeCosto = "";
        const cargo = "";
        const dataEstadoTrabajadores  = await this.obtenerDataEstadoTrabajadores( idEmpresa, fechaActual,centroDeCosto,cargo,fechaActual);

        this.setState({
          // dataGrafico: dataEstadoTrabajadores.data,
          // totalesGrafico: dataEstadoTrabajadores.totales,
          dataGrafico: dataEstadoTrabajadores === undefined ? [] : dataEstadoTrabajadores.data,
          totalesGrafico: dataEstadoTrabajadores === undefined ? [] : dataEstadoTrabajadores.totales,
          Empresa: idEmpresa,
          indexActual: "1",
        });


      }
    };

    obtenerDataEstadoTrabajadores = async (idEmpresa, fecha, centroCosto, cargo, fechaHoy) => {
        try {
          const url = `${API}/TrabajadoresPorEstadoOPNuevo`;

          let objeto = {
            idEmpresa,
            fecha,
            centroCosto,
            cargo,
            fechaHoy
          };

          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(objeto),
          });

          const status = await response.status;
          // const data = await response.json();
          switch (status) {
            case 200:
              case 201:
                const data = await response.json();
                return data;

              default:
                //const error = data.err.message.toString();
                const error = "Error al cargar la tabla"
                console.error(error);
            return [];
          }
          // if (status === 200 || status === 201) {
          //   return data;
          // } else {
          //   Swal.fire({
          //     icon: "error",
          //     title: "Uuups...",
          //     text: "No se pudo cargar la información.",
          //     confirmButtonColor: "#E60AA3",
          //     confirmButtonText: "OK",
          //   });
          // }
        } catch (error) {
          // Swal.fire({
          //   icon: "error",
          //   title: "Uuups...",
          //   text: "No se pudo cargar la información.",
          //   confirmButtonColor: "#E60AA3",
          //   confirmButtonText: "OK",
          // });
          console.error(error);
        }
    };

    setFecha = (fecha) => {
      let str = moment(fecha).format('YYYY-MM-DD')
      let fechaFormateada = convertirFechaString(moment(str), 1).fechaString

      this.setState({
          fechaSelecionada: fechaFormateada
      });
    };

    setCentroCosto = (centroCosto) => {
        this.setState({
            centroDeCostoSeleccionado: centroCosto
        });
    };

    setCargo = (cargo) => {
        this.setState({
            cargoSeleccionado: cargo
        });
    };

    filtrar = async(objeto) => {
      try {
        this.setState({ loading: true });
        const url = `${API}/TrabajadoresPorEstadoOPNuevo`;
        const { fecha,centroCosto,cargo } = objeto;
        const { Empresa } = this.state;
        let objetoEnviar = {
          idEmpresa:Empresa,
          fecha,
          centroCosto,
          cargo,
          fechaHoy:fecha
        };

        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          },
          body: JSON.stringify(objetoEnviar),
        });

        const status = await response.status;

        switch (status) {
          case 200:
            case 201:
              const data = await response.json();
              this.setState({
                dataGrafico: data.data,
                totalesGrafico:data.totales,
                loading: false
              });
            default:
              //const error = data.err.message.toString();
              const error = "Error al cargar la tabla"
              console.error(error);
          return [];
        }

      } catch (error) {
        console.error(error);
      }
    };

    obtenerDetalleEstadoTrabajadores = async (
      idEmpresa, fecha, centroCosto, cargo, fechaHoy
    ) => {
      try {
          const url = `${API}/TrabajadoresPorEstadoOPNuevo/Detalle`;
          let objeto = {
            idEmpresa,
            fecha,
            centroCosto,
            cargo,
            fechaHoy
          };
          const response = await fetch(url, {
              method: "POST",
              mode: "cors",
              headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              },
              body: JSON.stringify(objeto),
          });

          const status = response.status;
          let data;

          switch (status) {
              case 200:
              case 201:
                  data = await response.json();
                  let detalle = data.data;

                  return detalle;
              default:
                  //const error = data.err.message.toString();
                  const error = "Error al cargar la tabla"
                  console.error(error);
              return [];
          }

      } catch (error) {
          console.error(error);
      }

      return [];
    };

    detalle = async () => {
        this.setState({ loading: true });
        const { Empresa, fechaSelecionada,centroDeCostoSeleccionado,cargoSeleccionado } = this.state;

        const idEmpresa = Empresa;
        const fechaActual = convertirFechaString(moment(fechaSelecionada), 1).fechaString;
        const centroDeCosto = centroDeCostoSeleccionado;
        const cargo = cargoSeleccionado;

        const detalleEstadoTrabajadores = await this.obtenerDetalleEstadoTrabajadores(idEmpresa,fechaActual,centroDeCosto,cargo,fechaActual);

        this.setState({
          loading:false,
          modalDetalle:true,
          dataDetalle: detalleEstadoTrabajadores,
          indexActual: "1",
        });
    }

    //INEFICIENTE ES IGUAL A COMENTAR/MARCAR DIAS NO ASIGNADOS******

    //CONTROLADOR BTN "ASIGNAR INEFICIENTE" || COMENTAR DIAS NO ASIGNADOS
    obtenerDetalleEstadoIneficiente = async (
      idEmpresa, fecha, centroCosto, cargo, fechaHoy
    ) => {
      try {
        const url = `${API}/TrabajadoresPorEstadoOPNuevo/DetalleIneficiente`;
        let objeto = {
          idEmpresa,
          fecha,
          centroCosto,
          cargo,
          fechaHoy
        };
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          },
          body: JSON.stringify(objeto),
        });

        const status = response.status;
        let data;

        switch (status) {
          case 200:
          case 201:
            data = await response.json();
            let detalle = data.data;

            return detalle;
          default:
            //const error = data.err.message.toString();
            const error = "Error al cargar la tabla"
            console.error(error);
          return [];
        }

      } catch (error) {
        console.error(error);
      }

      return [];
    };

    //BTN "ASIGNAR INEFICIENTE" || COMENTAR DIAS NO ASIGNADOS
    detalleIneficiente = async () => {
      this.setState({ loading: true, objIneficiente: 0, TrabajadorSeleccionado: "" });

      if(this.FormularioRef.current !== null){
        await this.FormularioRef.current.resetFields([
        "Contrato",
        "RangoFechasBuq",
        "RangoFechasIneficiente",
        "Estado",
        "Comentario"
        ])
      }

      const { Empresa, fechaSelecionada, centroDeCostoSeleccionado, cargoSeleccionado } = this.state;

      const idEmpresa = Empresa;
      const fechaActual = convertirFechaString(moment(fechaSelecionada), 1).fechaString;
      const centroDeCosto = centroDeCostoSeleccionado;
      const cargo = cargoSeleccionado;

      const detalleEstadoIneficiente = await this.obtenerDetalleEstadoIneficiente(idEmpresa,fechaActual,centroDeCosto,cargo,fechaActual);

      this.setState({
        loading:false,
        dataIneficiente: detalleEstadoIneficiente,
        modalIneficiente:true,
      });
    }

    //CONTROLADOR BTN "ASIGNAR" || ASIGNAR COMENTARIO A DIAS NO ASIGNADOS
    obtenerTrabajadorIneficiente = async (
      idEmpresa, codTrabajador
    ) => {
      try {
        const url = `${API}/TrabajadoresPorEstadoOPNuevo/TrabajadorIneficiente`;
        let objeto = {
          idEmpresa,
          codTrabajador
        };
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          },
          body: JSON.stringify(objeto),
        });

        const status = response.status;
        let data;

        switch (status) {
          case 200:
          case 201:
            data = await response.json();
            let detalle = data;

            return detalle;
          default:
            const error = "Error al cargar la tabla"
            console.error(error);
          return [];
        }

      } catch (error) {
        console.error(error);
      }

      return [];
    };

    //BTN INEFICIENCIA "ASIGNAR" || ASIGNAR COMENTARIO A DIAS NO ASIGNADOS
    onClickDetalleIneficiente = async (item) => {
      const { Empresa } = this.state;

      if(this.FormularioRef.current !== null){
        await this.FormularioRef.current.resetFields([
        "Contrato",
        "RangoFechasBuq",
        "RangoFechasIneficiente",
        "Estado",
        "Comentario"
        ])
      }

      this.setState({ loading: true, objIneficiente: item,fechaDisabled: true, trabajadorIDSelect: item._id, bitacorasTimeline: []});

      const idEmpresa = Empresa;
      const codTrabajador = item._id

      const obtenerInfo = await this.obtenerTrabajadorIneficiente(idEmpresa,codTrabajador);

      this.setState({
        loading: false,
        dataListaEstados: obtenerInfo.obtLista,
        dataContratosTra: obtenerInfo.obtContratos,
        TrabajadorSeleccionado: item._id
      });

    }

    //BTN INFICIENCIA "ACTUALIZAR" || ACTUALZIAR COMENTARIO A DIAS NO ASIGNADOS
    onClickDetalleIneficienteActualizar = async (item) => {
      const { Empresa } = this.state;

      const ContratoOB = item.BitacoraIneficiencia.IdContrato;
      const fechaIniSTR= moment.utc(item.BitacoraIneficiencia.Fecha_Inicio);
      const fechaFinSTR= moment.utc(item.BitacoraIneficiencia.Fecha_Fin);
      const FechasInicioFinOB = [fechaIniSTR, fechaFinSTR];
      const EstadoOB =item.BitacoraIneficiencia.Estado_Necesario;
      const ComentarioOB = item.BitacoraIneficiencia.Comentario;

      this.setState({ loading: true, objIneficiente: item,fechaDisabled: true, trabajadorIDSelect: item._id, bitacorasTimeline: [], contratoElegido: ContratoOB,botonActiveEdit: false});
      
      //SE RESETEAN LOS TODOS LOS CAMPOS, PARA LUEGO SETEAR LOS CORRECTOS
      if(this.FormularioRef.current !== null){
        await this.FormularioRef.current.resetFields([
          "Contrato",
          "RangoFechasBuq",
          "RangoFechasIneficiente",
          "Estado",
          "Comentario"
        ]);

        await this.FormularioRef.current.setFieldsValue({
          Contrato: ContratoOB,
          RangoFechasBuq: FechasInicioFinOB,
          RangoFechasIneficiente: FechasInicioFinOB,
          Estado: EstadoOB,
          Comentario: ComentarioOB
         });
      }

      const idEmpresa = Empresa;
      const codTrabajador = item._id

      const obtenerInfo = await this.obtenerTrabajadorIneficiente(idEmpresa,codTrabajador);
      this.obtenerTrabajadorTimeline(this.state.trabajadorIDSelect, this.state.contratoElegido,fechaIniSTR,fechaFinSTR)

      this.setState({
        loading: false,
        dataListaEstados: obtenerInfo.obtLista,
        dataContratosTra: obtenerInfo.obtContratos,
        TrabajadorSeleccionado: item._id,
        botonActiveEdit: true,
        botonActiveDelete: false
      });

    }

    //BTN INFICIENCIA "ELIMINAR" || ELIMINAR COMENTARIO A DIA NO ASIGNADOS
    onClickDetalleIneficienteEliminar = async (item) => {
      const { Empresa } = this.state;

      const ContratoOB = item.BitacoraIneficiencia.IdContrato;
      const fechaIniSTR= moment.utc(item.BitacoraIneficiencia.Fecha_Inicio);
      const fechaFinSTR= moment.utc(item.BitacoraIneficiencia.Fecha_Fin);
      const FechasInicioFinOB = [fechaIniSTR, fechaFinSTR];
      const EstadoOB =item.BitacoraIneficiencia.Estado_Necesario;
      const ComentarioOB = item.BitacoraIneficiencia.Comentario;

      this.setState({ loading: true, objIneficiente: item,fechaDisabled: true, trabajadorIDSelect: item._id, bitacorasTimeline: [], contratoElegido: ContratoOB,botonActiveDelete: false});
      //SE RESETEAN LOS TODOS LOS CAMPOS, PARA LUEGO SETEAR LOS CORRECTOS
      await this.FormularioRef.current.resetFields([
      "Contrato",
      "RangoFechasBuq",
      "RangoFechasIneficiente",
      "Estado",
      "Comentario"
      ])

      if(this.FormularioRef.current !== null){

        await this.FormularioRef.current.setFieldsValue({
          Contrato: ContratoOB,
          RangoFechasBuq: FechasInicioFinOB,
          RangoFechasIneficiente: FechasInicioFinOB,
          Estado: EstadoOB,
          Comentario: ComentarioOB
         });
      }

      const idEmpresa = Empresa;
      const codTrabajador = item._id

      const obtenerInfo = await this.obtenerTrabajadorIneficiente(idEmpresa,codTrabajador);
      this.obtenerTrabajadorTimeline(this.state.trabajadorIDSelect, this.state.contratoElegido,fechaIniSTR,fechaFinSTR)

      this.setState({
        loading: false,
        dataListaEstados: obtenerInfo.obtLista,
        dataContratosTra: obtenerInfo.obtContratos,
        TrabajadorSeleccionado: item._id,
        botonActiveEdit: false,
        botonActiveDelete: true
      });

    }

    //TRAE EL CALENDARIO DEL TRABAJADOR(FORM - SELECT BUSQUEDA) || COMENTAR DIAS NO ASIGNADOS
    obtenerTrabajadorTimeline = async (idTrabajador, idContrato, FechaInicio, FechaFin) => {
      try {
        FechaInicio = convertirFechaString(moment.utc(FechaInicio),1).fechaString;
        FechaFin = convertirFechaString(moment.utc(FechaFin),2).fechaString
        const url = `${API}/TrabajadoresPorEstadoOPNuevo/${idTrabajador}/${idContrato}?InicioBusqueda=${FechaInicio}&FinBusqueda=${FechaFin}`;

        const response = await fetch(url, {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        const status = response.status;
        let data;

        switch (status) {
          case 200:
          case 201:
            data = await response.json();
            // console.log(data.data.bitacoras)
            this.setState({
              bitacorasTimeline: data.data.bitacoras,
            });
            return data;
          default:
            //const error = data.err.message.toString();
            const error = "Error al cargar la informacion"
            console.error(error);
          return [];
        }
      } catch (error) {
        console.log(error)
        return null;
      }
    };

    guardarTrabajadorIneficiente = async (values) => {
      try {
        const url = `${API}/TrabajadoresPorEstadoOPNuevo/GuardarIneficiente`;
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          },
          body: JSON.stringify(values),
        });

        const status = response.status;
        let data;
        data = await response.json();
        
        switch (status) {
            case 200:
            case 201:

              let detalle = data.data;
              Swal.fire({
                title: "Bien",
                text: detalle,
                icon: "success",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "Continuar",
              });

              const { Empresa, fechaSelecionada,centroDeCostoSeleccionado,cargoSeleccionado } = this.state;
              const idEmpresa = Empresa;
              const fechaActual = convertirFechaString(moment(fechaSelecionada), 1).fechaString;
              const centroDeCosto = centroDeCostoSeleccionado;
              const cargo = cargoSeleccionado;

              if(this.FormularioRef.current !== null){
                await this.FormularioRef.current.resetFields([
                "Contrato",
                "RangoFechasBuq",
                "RangoFechasIneficiente",
                "Estado",
                "Comentario"
                ])
              }
              const detalleEstadoIneficiente = await this.obtenerDetalleEstadoIneficiente(idEmpresa,fechaActual,centroDeCosto,cargo,fechaActual);
              this.setState({ objIneficiente: 0, TrabajadorSeleccionado: "", dataIneficiente: detalleEstadoIneficiente});

              return detalle;
            default:
                //const error = data.err.message.toString();
                //const error = "Error, no se puedo guardar"
                const error = data.err
                Swal.fire({
                  title: "Ups...",
                  text: error,
                  icon: "warning",
                  confirmButtonColor: "#E60AA3",
                  confirmButtonText: "Ok",
                });
                console.error(error);
            return [];
        }

      } catch (error) {
        console.error(error);
      }
    };

    eliminarBitacorasTrabajadorIneficiente = async (values) => {
      try {
        const url = `${API}/TrabajadoresPorEstadoOPNuevo/EliminarIneficiente`;
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          },
          body: JSON.stringify(values),
        });

        const status = response.status;
        let data;
        data = await response.json();
        
        switch (status) {
            case 200:
            case 201:

              let detalle = data.data;
              Swal.fire({
                title: "Bien",
                text: detalle,
                icon: "success",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "Continuar",
              });
              const { Empresa, fechaSelecionada,centroDeCostoSeleccionado,cargoSeleccionado } = this.state;
              const idEmpresa = Empresa;
              const fechaActual = convertirFechaString(moment(fechaSelecionada), 1).fechaString;
              const centroDeCosto = centroDeCostoSeleccionado;
              const cargo = cargoSeleccionado;

              if(this.FormularioRef.current !== null){
                await this.FormularioRef.current.resetFields([
                "Contrato",
                "RangoFechasBuq",
                "RangoFechasIneficiente",
                "Estado",
                "Comentario"
                ])
              }
              const detalleEstadoIneficiente = await this.obtenerDetalleEstadoIneficiente(idEmpresa,fechaActual,centroDeCosto,cargo,fechaActual);
              this.setState({ objIneficiente: 0, TrabajadorSeleccionado: "", dataIneficiente: detalleEstadoIneficiente});
              return detalle;
            default:
                //const error = data.err.message.toString();
                //const error = "Error, no se puedo guardar"
                const error = data.err
                Swal.fire({
                  title: "Ups...",
                  text: error,
                  icon: "warning",
                  confirmButtonColor: "#E60AA3",
                  confirmButtonText: "Ok",
                });
                console.error(error);
            return [];
        }

      } catch (error) {
        console.error(error);
      }
    };

    //FORMULARIO BTN ACTUALIZAR INEFICIENTE
    actualizarBitacorasTrabajadorIneficiente = async (values) => {
      try {
        const url = `${API}/TrabajadoresPorEstadoOPNuevo/ActualizarIneficiente`;
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          },
          body: JSON.stringify(values),
        });

        const status = response.status;
        let data;
        data = await response.json();
        
        switch (status) {
            case 200:
            case 201:

              let detalle = data.data;
              Swal.fire({
                title: "Bien",
                text: detalle,
                icon: "success",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "Continuar",
              });
              const { Empresa, fechaSelecionada,centroDeCostoSeleccionado,cargoSeleccionado } = this.state;
              const idEmpresa = Empresa;
              const fechaActual = convertirFechaString(moment(fechaSelecionada), 1).fechaString;
              const centroDeCosto = centroDeCostoSeleccionado;
              const cargo = cargoSeleccionado;

              if(this.FormularioRef.current !== null){
                await this.FormularioRef.current.resetFields([
                "Contrato",
                "RangoFechasBuq",
                "RangoFechasIneficiente",
                "Estado",
                "Comentario"
                ])
              }
              const detalleEstadoIneficiente = await this.obtenerDetalleEstadoIneficiente(idEmpresa,fechaActual,centroDeCosto,cargo,fechaActual);
              this.setState({ objIneficiente: 0, TrabajadorSeleccionado: "", dataIneficiente: detalleEstadoIneficiente});
              return detalle;
            default:
                //const error = data.err.message.toString();
                //const error = "Error, no se puedo guardar"
                const error = data.err
                Swal.fire({
                  title: "Ups...",
                  text: error,
                  icon: "warning",
                  confirmButtonColor: "#E60AA3",
                  confirmButtonText: "Ok",
                });
                console.error(error);
            return [];
        }

      } catch (error) {
        console.error(error);
      }
    };

    //FORMULARIO BTN GUARDAR INEFICIENTE
    onFinish = async(values) => {

      const { bitacorasTimeline } = this.state
      const inicio = convertirFechaString(moment.utc(values.RangoFechasIneficiente[0]),1).fechaString;
      const fin = convertirFechaString(moment.utc(values.RangoFechasIneficiente[1]),2).fechaString
      const cantidadDias = moment.utc(fin).diff(moment.utc(inicio), "days") + 1;
    
      const arrayBitacora = [];
      for (let index = 0; index < cantidadDias; index++) {
        let fechatransformar = moment.utc(inicio).clone().add(index, "day");
        let fechaString = convertirFechaString(fechatransformar.clone(), 1).fechaString;

        const obj = {
          Fecha: fechaString,
          TipoRegistroBitacoraTrabajador: "Vacio"
        }

        arrayBitacora.push(obj)
      }
  
      let cantidad = bitacorasTimeline.filter((fil)=> fil.TipoRegistroBitacoraTrabajador !== "Vacio" && moment.utc(fil.Fecha).isBetween(moment.utc(inicio),moment.utc(fin), null,"[]")).length;
      
      //DETIENE EL FLUJO SI TIENE DIAS YA PLANIFICADOS
      if (cantidad>0) {
        await Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se puede generar ineficencia en dias ya planificados",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
        return
      }
      
      await Swal.fire({
        title: "Confirmar",
        // text: `¿Estás seguro que quieres guardar estado ineficiente?`,
        text: `¿Estás seguro de que quieres guardar la marca de los días?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1FC600",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",

      }).then(async (result) => {

        if (result.isConfirmed) {
          values.Trabajador = this.state.objIneficiente._id;
          values.Empresa = this.state.Empresa;
          
          await this.guardarTrabajadorIneficiente(values);
        }
      })

    };

    //FORMULARIO BTN ELIMINAR INEFICIENTE
    onFinishEliminar = async(values) => {
      await Swal.fire({
        title: "Confirmar",
        // text: `¿Estás seguro que quieres eliminar las bitacoras ineficientes de este trabajador?`,
        text: `¿Estás seguro de que quieres eliminar las bitácoras marcadas de este trabajador?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1FC600",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",

      }).then(async (result) => {

        if (result.isConfirmed) {
          values.Trabajador = this.state.objIneficiente._id;
          values.Empresa = this.state.Empresa;

          await this.eliminarBitacorasTrabajadorIneficiente(values);
        }

    })
    };

    //FORMULARIO BTN ACTUALIZAR INEFICIENTE
    onFinishActualizar = async(values) => {
     
      const { bitacorasTimeline } = this.state
      const inicio = convertirFechaString(moment.utc(values.RangoFechasIneficiente[0]),1).fechaString;
      const fin = convertirFechaString(moment.utc(values.RangoFechasIneficiente[1]),2).fechaString
      const cantidadDias = moment.utc(fin).diff(moment.utc(inicio), "days") + 1;
    
      const arrayBitacora = [];
      for (let index = 0; index < cantidadDias; index++) {
        let fechatransformar = moment.utc(inicio).clone().add(index, "day");
        let fechaString = convertirFechaString(fechatransformar.clone(), 1).fechaString;

        const obj = {
          Fecha: fechaString,
          TipoRegistroBitacoraTrabajador: "Vacio"
        }

        arrayBitacora.push(obj)
      }
  
      let cantidad = bitacorasTimeline.filter((fil)=> fil.TipoRegistroBitacoraTrabajador !== "Vacio" && moment.utc(fil.Fecha).isBetween(moment.utc(inicio),moment.utc(fin), null,"[]")).length;

      if (cantidad>0) {
        await Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se puede generar ineficencia en dias ya planificados",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
        return
      }
      await Swal.fire({
        title: "Confirmar",
        // text: `¿Estás seguro que quieres actualizar las bitacoras ineficientes de este trabajador?`,
        text: `¿Estás seguro de que quieres actualizar las bitácoras marcadas de este trabajador?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1FC600",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {

        if (result.isConfirmed) {
          values.Trabajador = this.state.objIneficiente._id;
          values.Empresa = this.state.Empresa;
          values.ObjetoAntiguo = this.state.objIneficiente.BitacoraIneficiencia;
          await this.actualizarBitacorasTrabajadorIneficiente(values);
        }

      })
    };

    onFinishFailed = (values) => {
      const error = values.errorFields[0].errors[0];
      Swal.fire({
        title: "Uuups...",
        text: error,
        icon: "error",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Continuar",
      });
    };

    //DESCARGABLE
    obtenerExcelDetalleEstadoTrabajadores = async (
      informacionDescargar,subtitulo
    ) => {
      try {

        let arrayNecesario = [];
        informacionDescargar.forEach(trabajador => {
          arrayNecesario.push({
            Nombre: trabajador.NombreCompletoTratado,
            RUT:	trabajador.RutTratado,
            Cargo: trabajador.CargoTratado,
            Centro_Costo: trabajador.CentroCostoTratado,
            Fecha_inicio: trabajador.FechaInicioTratado,
            Fecha_fin: trabajador.FechaFinTratado
          })
        });
        const url = `${API}/TrabajadoresPorEstadoOP/DescargarExcel`;


        let objeto = {
          data: arrayNecesario,
          subtitulo
        };

        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objeto),
        });

        const status = await response.status;
        const excel = await response.blob();

        if (status === 200 || status === 201) {
          const url = window.URL.createObjectURL(excel);
          const a = document.createElement("a");
          a.href = url;
          a.download = `formatoDetalleCostos.xlsx`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          Swal.fire({
            title: "Bien",
            text: "Se descargó excel correctamente.",
            icon: "success",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se pudo cargar la información.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }
    };

    //Search BTN
    search = (value, index) => {
      let busqueda = value.target.value;

      if (busqueda !== "") {
        busqueda = busqueda.toLowerCase();
        let datamodal = JSON.parse(JSON.stringify(this.state.dataDetalle));
        const trabajadores = datamodal[index].Trabajadores;

        const trabajadoresFiltros = trabajadores.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(busqueda)
          )
        );

        // datamodal[index].arregloUnificado = trabajadoresFiltros;
        let newData = {
          Trabajadores:  trabajadoresFiltros
        }

        this.setState({
          trabajadoresCopia: newData
        });

      } else {
        this.setState({ trabajadoresCopia: undefined });
      }
    };

    NuevaPestaña = (idUsuario) => {
      //window.open("/CalendarioTrabajador?Trabajador=" + idUsuario);
      window.open("/CalendarioTrabajadorNew?Trabajador=" + idUsuario);
    };


    render() {
      let {
          cargosEmpresa,
          centrosCostoEmpresa
      } = this.props;
      const {
        dataGrafico,totalesGrafico, modalDetalle, dataDetalle, indexActual, trabajadoresCopia, loading,
        modalIneficiente, dataIneficiente,objIneficiente,dataListaEstados,dataContratosTra,fechaDisabled,contratoSelect,bitacorasTimeline
      }= this.state;
      const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 9
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 8
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

      return (
        <>
          <Loading cargando={loading} />
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card
              className="card-style-dashboard card-title-dashboard"
              title={
                  <>
                    <Row>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={16}
                        xl={16}
                        xxl={19}
                        // style={{textAlign:"center"}}
                      >
                        Trabajadores por estado
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={7}
                        lg={5}
                        xl={5}
                        xxl={3}
                        style={{textAlign:"right"}}
                      >
                        <Button
                          type="primary"
                          className="btn-ineficiente-dashboard"
                          onClick={() => {
                            this.detalleIneficiente()
                          }}
                        >
                          <span><img src={Ineficiente} style={{width: "24px", height: "24px"}}/></span>
                          {/* <span className="text-detalle-dashboard">Asignar Ineficiente</span> */}
                          <span className="text-detalle-dashboard"><label style={{fontSize: "14px"}}>Comentar días no asignados</label></span>
                  
                        </Button>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={5}
                        lg={3}
                        xl={3}
                        xxl={2}
                        style={{ textAlign:"right" }}
                      >
                        <Button
                            type="primary"
                            className="btn-detalle-dashboard"
                            onClick={() => {
                              this.detalle()
                            }}
                        >
                            <span className="text-detalle-dashboard">Detalle</span>
                            <span><EyeFilled className="iconoBotonDetalleCurvaRelevos"/></span>
                        </Button>
                      </Col>
                    </Row>
                  </>
              }
            >
              {/* FILTROS */}
              <div>
                <p className={"label-filtros-dashboard"}>
                  Filtrar por:
                </p>
              </div>
              <Row gutter={20} style={{ marginBottom: "20px" }}>
                      {/* RANGEPICKER */}
                      <Col
                          xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                          style={{ marginBottom: "15px" }}
                      >
                          <DatePicker
                              format={'DD-MM-YYYY'}
                              allowClear={false}
                              className={"select-style-dashboard"}
                              locale={locale}
                              picker="day"
                              defaultValue={moment()}
                              onChange={(fecha) => {
                                  if (fecha == null) {
                                  this.setFecha(moment());
                                  } else {
                                  this.setFecha(fecha);
                                  }
                              }}
                          />
                      </Col>

                      {/* CENTROS DE COSTO */}
                      <Col
                          xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                          style={{ marginBottom: "15px" }}
                      >
                          <Select
                              allowClear
                              className={"select-style-dashboard"}
                              placeholder="Centro de costo"
                              onSelect={(idCentroCosto) => {
                                  this.setCentroCosto(idCentroCosto);
                              }}
                              onClear={() => {
                                  this.setCentroCosto("");
                              }}
                          >
                              {
                                  centrosCostoEmpresa.map((value, index) => (
                                      <Select.Option key={index} value={value._id} name="CentroCosto">
                                          {value.Nombre}
                                      </Select.Option>
                                  ))
                              }

                          </Select>
                      </Col>

                      {/* CARGOS */}
                      <Col
                          xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                          style={{ marginBottom: "15px" }}
                      >
                          <Select
                              allowClear
                              className={"select-style-dashboard"}
                              placeholder="Cargo"
                              onSelect={(cargo) => {
                                  this.setCargo(cargo);
                              }}
                              onClear={() => {
                                  this.setCargo("");
                              }}
                          >

                              {
                                  cargosEmpresa.map((value, index) => (
                                      <Select.Option key={index} value={value._id} name="Cargo">
                                          {value.Nombre}
                                      </Select.Option>
                                  ))
                              }

                          </Select>
                      </Col>

                      {/* BTN FILTRAR */}
                      <Col
                          xs={24} s={24} sm={5} md={5} lg={5} xl={5} xxl={3}
                          className={"btn-col-filtrar-dashboard "}
                      >
                          <Button
                              type="primary"
                              htmlType="submit"
                              className={"btn-filtrar-dashboard"}
                              onClick={() => {
                                  const { fechaSelecionada, cargoSeleccionado, centroDeCostoSeleccionado} = this.state;
                                  const objeto =
                                  {
                                      fecha: fechaSelecionada,
                                      cargo: cargoSeleccionado,
                                      centroCosto: centroDeCostoSeleccionado,
                                  }
                                  this.filtrar(objeto);
                              }}
                          >
                              Filtrar
                          </Button>
                      </Col>

              </Row>

              <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={true}
                autoPlay={false}
                autoPlaySpeed={5000}//poner mas seg para q este static
                infinite={true}//para que nos se quede pegado al final
              >
                {
                  dataGrafico.map((data)=>
                  <>
                    {/* <Col>{`${data.name} => ${data.value}`}</Col> */}

                      <Card
                        xs={12}
                        md={6}
                        lg={2}
                        //hoverable
                        className={"card-estado-trabajadores-dashboard"}
                        cover={
                          <img
                            src={
                              data.name === "Trabajo Efectivo" ? TrabajoEfectivo:
                              data.name === "Descanso" ? Descanso :
                              data.name === "Licencia" ? Licencia :
                              data.name === "En Tránsito" ? EnTransito:
                              data.name === "Vacaciones" ? Vacaciones:
                              data.name === "Permiso" ? Permiso:
                              data.name === "No Asignado" ? NoAsignado:
                              data.name === "Licencia Mutual" ? LicenciaMutual:
                              data.name === "Ausente" ? Ausente:
                              TrabajoEfectivo
                            }
                            style={{
                              paddingTop: "1rem",
                              width: "126px", 
                              height: "126px",
                            }}
                          />
                        }
                      >
                        <Meta title={data.value} description={data.name} />
                      </Card>
                  </>
                  )
                }
              </Carousel>
              <Row style={{
                marginTop:"20px",
                // textAlign:"right"
              }}>
                {
                  totalesGrafico.length > 0
                  ?
                    totalesGrafico.map((total)=>
                      <>
                        <Card
                          className={"card-info-dashboard"}
                        >
                        <div>
                          <p className={"label-filtros-dashboard"}>
                            Indicadores:
                          </p>
                        </div>
                          <Col span={24} style={{fontFamily:"MavenPro-SemiBold"}}> Cantidad total de estados:{total.todosEstados} </Col>
                          <Col span={24} style={{fontFamily:"MavenPro-SemiBold"}}> Total trabajadores:{total.todosTrabajadores}</Col>
                        </Card>
                      </>
                    )
                  :
                  <>
                  </>
                }
              </Row>
            </Card>
          </Col>
          
          {/* MODAL DETALLE */}
          <Modal
            visible={modalDetalle}
            onCancel={() => this.setState({modalDetalle: false})}
            className={'modal-detalle-dashboard'}
            footer={null}
            closable={false}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
          >
            <Row  style={{ marginBottom: '20px' }}>
                <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
                    <h3
                        className="modal-h3-dashboard"
                    >
                      Trabajadores por estado
                    </h3>
                </Col>

                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{textAlign:"right"}}>
                        <Button
                            type="primary"
                            className={'modal-btn-excel-dashboard'}
                            onClick={()=>{
                              let { indexActual,dataDetalle } = this.state;
                              let index = parseInt(indexActual)-1;

                              const dataDescargar =
                              dataDetalle[index].Trabajadores !== null &&
                              dataDetalle[index].Trabajadores !== 0 ?
                              dataDetalle[index].Trabajadores : [];
                              this.obtenerExcelDetalleEstadoTrabajadores(dataDescargar,dataDetalle[index].name);

                            }}
                        >
                            <span className="modal-btn-text-excel-dashboard">Descargar Excel</span>
                            <span><img src={Excel} className="modal-btn-icon-excel-dashboard" alt="Icono Excel"/></span>
                        </Button>
                </Col>
            </Row>

            <hr className="modal-division-detalle-dashboard" />

            {/* TABLE */}
            <Row>
                <Col className="modal-tabla-detalle-dashboard" span={24}>
                  {/* <Tabs key={1} defaultActiveKey="1"> */}
                  <Tabs key={indexActual} defaultActiveKey={indexActual} activeKey={indexActual}  onTabClick={(e)=>{this.setState({ indexActual: e, trabajadoresCopia: undefined})}}>
                    {dataDetalle.length > 0 ? (
                      <>
                        {dataDetalle.map(
                          (valor, index) => (
                            <TabPane
                              tab={
                                valor.name +
                                ` (${valor.Trabajadores.length})`
                              }
                              key={index + 1}
                            >
                              {valor.Trabajadores.length > 0 ? (
                                <>
                                  <Row style={{ padding: "6px 0px 22px" }} align="bottom">
                                    <Col className="div-input-search" xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                      <Search
                                        placeholder="Buscar"
                                        enterButton
                                        onChange={(value) => this.search(value, index)}
                                      />
                                    </Col>
                                  </Row>

                                  <Table
                                    key={index + 1}
                                    rowKey="_id"
                                    dataSource={
                                      // dataDetalle[index] !== undefined ?
                                      // dataDetalle[index].Trabajadores : valor.Trabajadores
                                      trabajadoresCopia === undefined ?
                                        valor  !== undefined ?
                                        valor.Trabajadores  : []
                                      :
                                      trabajadoresCopia.Trabajadores
                                    }
                                    bordered={true}
                                    showSorterTooltip={false}
                                    scroll={{ x: true }}
                                    pagination={{ pageSize: 10 }}
                                    className={'modal-tabla-detalle-dashboard'}
                                    locale={{
                                      emptyText:
                                        "No se encontraron registros de la tabla.",
                                    }}
                                  >
                                    {/* Imagen*/}
                                    <Column
                                      align="center"
                                      title="Imagen"
                                      key="Imagen"
                                      render={(value) => {
                                        return (
                                          <img
                                            src={value.Imagen}
                                            className="modal-img-tabla-dashboard"
                                            alt="i"
                                          />
                                        );
                                      }}
                                    />

                                    {/* NombreCompletoTratado*/}
                                    <Column
                                      sorter={(a, b) => {
                                        return a.Nombre.localeCompare(b.Nombre);
                                      }}
                                      title="Nombre"
                                      key="NombreCompletoTratado"
                                      render={(value) => {
                                        return `${value.NombreCompletoTratado}`;
                                      }}
                                    />

                                    {/* Rut*/}
                                    <Column
                                      sorter={(a, b) => {
                                        return a.RutTratado.localeCompare(b.RutTratado);
                                      }}
                                      title="Rut"
                                      key="RutTratado"
                                      render={(value) => {
                                        return `${value.RutTratado}`;
                                      }}
                                    />

                                    {/* Cargo */}
                                    <Column
                                      sorter={(a, b) => {
                                        return a.CargoTratado.localeCompare(b.CargoTratado);
                                      }}
                                      title="Cargo"
                                      key="CargoTratado"
                                      render={(value) => {
                                        return `${value.CargoTratado}`;
                                      }}
                                    />

                                    {/* Centro de costo */}
                                    <Column
                                      sorter={(a, b) => {
                                        return a.CentroCostoTratado.localeCompare(b.CentroCostoTratado);
                                      }}
                                      title="Centro de costo"
                                      key="CentroCostoTratado"
                                      render={(value) => {
                                        return `${value.CentroCostoTratado}`;
                                      }}
                                    />

                                    {/* Fecha inicio */}
                                    <Column
                                      sorter={(a, b) => {
                                          const fechaInicioa = a.FechaInicioTratado === 'N/A' ? new Date('1900-01-01') : new Date(a.FechaInicioTratado.split('-').reverse().join('-')) ;
                                          const fechaIniciob = b.FechaInicioTratado === 'N/A' ? new Date('1900-01-01') : new Date(b.FechaInicioTratado.split('-').reverse().join('-'));
                                          return fechaIniciob - fechaInicioa
                                      }}
                                      title="Fecha inicio"
                                      key="FechaInicioTratado"
                                      render={(value) => {
                                        return `${value.FechaInicioTratado}`
                                      }}
                                    />

                                    {/* Fecha fin */}
                                    <Column
                                      sorter={(a, b) => {
                                          const fechaFina = a.FechaFinTratado === 'N/A' ? new Date('1900-01-01') : new Date(a.FechaFinTratado.split('-').reverse().join('-'));
                                          const fechaFinb = b.FechaFinTratado === 'N/A' ? new Date('1900-01-01') : new Date(b.FechaFinTratado.split('-').reverse().join('-'));
                                          return fechaFinb - fechaFina
                                      }}
                                      title="Fecha fin"
                                      key="FechaFinTratado"
                                      render={(value) => {
                                        return `${value.FechaFinTratado}`
                                      }}
                                    />

                                    {/* Ficha trabajador */}
                                    <Column
                                      title="Detalle"
                                      key="FichaTrabajador"
                                      align="center"
                                      render={(value) => (
                                        <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                                          <Button
                                            onClick={() => {
                                              this.NuevaPestaña(value._id);
                                            }}
                                            className="acciones-trabajador-button hover-azul"
                                            icon={<EyeFilled style={{ fontSize: '18px' }} />}
                                          />
                                       </Tooltip>
                                      )}
                                    />

                                  </Table>
                                </>
                              ) : (
                                <>
                                  <Row>
                                    <Col span={24}>
                                      <Empty
                                        imageStyle={{
                                          height: 200,
                                          marginTop: "20px",
                                        }}
                                        description={
                                          <b
                                            className={
                                              "seleccion-trabajador-disponible"
                                            }
                                          >
                                            No se encontró información relacionada con los filtros.
                                          </b>
                                        }
                                      ></Empty>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </TabPane>
                          )
                        )}
                      </>
                    ) : (
                      <TabPane tab={"No se encontro información"} key={1}>
                        <>
                          <Row>
                            <Col span={24}>
                              <Empty
                                imageStyle={{
                                  height: 200,
                                  marginTop: "20px",
                                }}
                                description={
                                  <b
                                    className={
                                      "modal-empty-b-dashboard"
                                    }
                                  >
                                    No se encontró información de trabajadores.
                                  </b>
                                }
                              ></Empty>
                            </Col>
                          </Row>
                        </>
                      </TabPane>
                    )}
                  </Tabs>
                </Col>
            </Row>

          </Modal>

          {/* MODAL DE COMENTAR/MARCAR DIAS NO ASIGNADO  O INEFICIENTE */}
          <Modal
            visible={modalIneficiente}
            onCancel={() => this.setState({modalIneficiente: false})}
            className={'modal-detalle-dashboard'}
            // className={'modal-detalle-ineficiente-dashboard'}
            footer={null}
            closable={true}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
          >
            <Row  style={{ marginBottom: '20px', fontFamily:"MavenPro-Regular" }}>
              <Col
                xs={{ span: 1, offset: 0 }}
                sm={{ span: 1, offset: 0 }}
                md={{ span: 1, offset: 0 }}
                lg={{ span: 1, offset: 0 }}
                xl={{ span: 1, offset: 0 }}
                xxl={{ span: 1, offset: 0 }}
              >
                <img src={Ineficiente} />
              </Col>
                <Col
                  xs={{ span: 20, offset: 3 }}
                  sm={{ span: 20, offset: 3 }}
                  md={{ span: 20, offset: 3 }}
                  lg={{ span: 23, offset: 0 }}
                  xl={{ span: 23, offset: 0 }}
                  xxl={{ span: 23, offset: 0 }}
                >
                    <h3
                      className="modal-h3-dashboard"
                    >
                      {/* Estado ineficiente - Fecha del filtro: {convertirFechaString(moment.utc(this.state.fechaSelecionada),1).fechaDDMMYYYY} */}
                      Comentar días no asignados de un trabajador  - Fecha del filtro: {convertirFechaString(moment.utc(this.state.fechaSelecionada),1).fechaDDMMYYYY}
                    </h3>
                </Col>
            </Row>

            <hr className="modal-division-detalle-dashboard" />
            <Row>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 7, offset: 0 }}
                xl={{ span: 7, offset: 0 }}
                xxl={{ span: 7, offset: 0 }}
                style={{border:"2px solid #64B4E5", borderRadius:"9px", fontFamily:"MavenPro-Regular"}}
              >
                <div style={{display:"flex", justifyContent:"space-between",background: "#64B4E5", borderTopColor:"9px",padding:"15px"}}>
                  <h3 style={{ color:"#FFFFFF", fontWeight:"bold",fontSize:"16px",fontFamily:"MavenPro-Bold"}}>
                    Lista de no asignados en total: {dataIneficiente.length > 0 ? dataIneficiente.length - dataIneficiente.filter((data)=> data.tieneIneficienciaAsignada === true).length : 0}
                  </h3>
                  <h3 style={{ color:"#FFFFFF", fontWeight:"bold",fontSize:"16px",fontFamily:"MavenPro-Bold"}}>
                    Marcados: {dataIneficiente.filter((data)=> data.tieneIneficienciaAsignada === true).length}
                    {/* Ineficientes: {dataIneficiente.filter((data)=> data.tieneIneficienciaAsignada === true).length} */}
                  </h3>
                </div>


                <div
                  id="scrollableDiv"
                  style={{
                    height: 500,
                    overflow: 'auto',
                    padding: '0 16px',
                    border: '1px solid rgba(140, 140, 140, 0.35)',
                  }}
                >
                <List
                    size="small"
                    style={{fontFamily:"MavenPro-Regular"}}
                    itemLayout="horizontal"
                    dataSource={dataIneficiente}
                    renderItem={item => (
                      <List.Item
                        style={{cursor:"pointer"}}
                        //onClick={()=>{this.onClickDetalleIneficiente(item)}}
                        actions={[
                          item.tieneIneficienciaAsignada === true
                          ?
                          <div style={{display:"flex", justifyContent:"space-between"}}>
                          <Tooltip placement="top" title={"Editar"} color={'geekblue'}>
                            <Button
                              style={this.state.TrabajadorSeleccionado === item._id && this.state.botonActiveEdit === true
                              ?
                                {backgroundColor:"#64B4E5",color:"#FFFFFF",border:"1px solid #64B4E5", borderRadius:"9px", fontWeight:"bold",marginRight:"12px"}
                              :
                                {border:"1px solid #64B4E5", borderRadius:"9px", fontWeight:"bold",marginRight:"12px"}
                              }
                              onClick={() => {
                                //ICONO LAPIZ PARA TRAER FORMULARIO ACTUALIZAR
                                this.onClickDetalleIneficienteActualizar(item)
                              }}
                              icon={<EditFilled/>}
                            />
                          </Tooltip>

                          <Tooltip placement="top" title={"Eliminar"} color={'geekblue'}>
                            <Button
                              style={this.state.TrabajadorSeleccionado === item._id && this.state.botonActiveDelete === true
                              ?
                                {backgroundColor:"#64B4E5",color:"#FFFFFF",border:"1px solid #64B4E5", borderRadius:"9px", fontWeight:"bold"}
                              :
                                {border:"1px solid #64B4E5", borderRadius:"9px", fontWeight:"bold"}
                              }
                              onClick={() => {
                                //ICONO TARRO DE BASURA PARA TRAER FORMULARIO ELIMINAR
                                this.onClickDetalleIneficienteEliminar(item)
                              }}
                              icon={<DeleteFilled/>}
                            />
                          </Tooltip>
                          </div>

                          :
                          <Button
                            key="list-loadmore-more"
                            style={this.state.TrabajadorSeleccionado === item._id
                            ?
                              {backgroundColor:"#64B4E5",color:"#FFFFFF",border:"1px solid #64B4E5", borderRadius:"9px", fontWeight:"bold"}
                            :
                              {border:"1px solid #64B4E5", borderRadius:"9px", fontWeight:"bold"}
                            }
                            onClick={() => {
                              //BTN PARA TRAER FORMULARIO ASIGNAR INEFICIENTE
                              this.onClickDetalleIneficiente(item)
                            }}
                          >
                            {
                              this.state.TrabajadorSeleccionado === item._id
                              ?
                                <a>Asignando</a>
                              :
                                <a>Asignar</a>
                            }

                          </Button>
                        ]}
                      >
                        <List.Item.Meta
                          key={item._id}
                          avatar={<Avatar src={item.Imagen}/>}
                          title={<a>{item.NombreCompletoSTR}</a>}
                          description={<a>{item.tieneIneficienciaAsignada === true ? "Ya está asignado": "No está asignado"}</a>}
                          tieneIneficienciaAsignada

                        />

                      </List.Item>
                    )}
                />
                </div>
              </Col>

              <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 16, offset: 1 }}
              xl={{ span: 16, offset: 1 }}
              xxl={{ span: 16, offset: 1 }}
              style={{border:"2px solid #64B4E5", borderRadius:"9px"}}>
                <h1 style={{background: "#64B4E5", borderTopColor:"9px", color:"#FFFFFF", fontWeight:"bold",fontSize:"16px",fontFamily:"MavenPro-Bold", padding:"15px"}}>
                  Generar marca a dias no asignados
                </h1>
                <Form
                  //form={FormularioIneficiencia}
                  name="FormularioIneficiencia"
                  ref={this.FormularioRef}
                  onFinish={
                    this.state.objIneficiente.tieneIneficienciaAsignada === true ?
                    (
                      this.state.botonActiveEdit ?
                      this.onFinishActualizar
                      :
                      this.onFinishEliminar
                    )
                    :
                    this.onFinish
                  }
                  onFinishFailed={this.onFinishFailed}
                  initialValues={true}
                  layout="horizontal"
                >
                {
                  dataIneficiente.length > 0 ?
                  <>
                    <Row style={{margin:"10px", fontFamily:"MavenPro-Bold"}}>
                      {
                        Object.entries(objIneficiente).length === 0
                        ?
                        <h3 style={{color:"#4C4C4C", fontWeight:"bold", fontSize:"16px", fontFamily:"MavenPro-Regular", fontWeight:"bold", marginTop:"10px"}}>
                          Seleccione un trabajador
                        </h3>
                        :
                        <>

                          <Col span={12}>
                            <p style={{color: "#262E2D", fontFamily:"MavenPro-Regular"}}><strong>Nombre:</strong> : <a style={{color: "#262E2D"}}>{objIneficiente.NombreCompletoSTR}</a></p>
                          </Col>

                          <Col span={12}>
                            <p style={{color: "#262E2D", fontFamily:"MavenPro-Regular"}}><strong>Cargo:</strong> : <a style={{color: "#262E2D"}}>{objIneficiente.CargoSTR}</a></p>
                          </Col>

                          <Col span={12}>
                            <p style={{color: "#262E2D", fontFamily:"MavenPro-Regular"}}><strong>Rut:</strong> <a style={{color: "#262E2D"}}>{objIneficiente.Rut}</a></p>
                          </Col>

                          <hr className="modal-division-detalle-dashboard" />

                          <Col
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            lg={{ span: 14, offset: 0 }}
                            xl={{ span: 14, offset: 0 }}
                            xxl={{ span: 14, offset: 0 }}
                            style={{fontWeight:"bold"}}
                          >

                            <Form.Item
                              label="Contrato"
                              name={"Contrato"}
                              rules={[
                                {
                                  required: true,
                                  message: 'Debe seleccionar un contrato',
                                }
                              ]}
                            >
                              <Select
                                name="Contrato"
                                placeholder="Seleccionar contrato"
                                disabled={this.state.objIneficiente.tieneIneficienciaAsignada === true ? (this.state.botonActiveEdit ? false : true)  : false}
                                onChange={(e) =>{
                                  let contrato = dataContratosTra.filter((contra)=> contra._id === e)[0]
                                  this.setState({ fechaDisabled: false, contratoElegido: e, contratoSelect: contrato,bitacorasTimeline:[] })
                                  this.FormularioRef.current.resetFields([
                                    "RangoFechasBuq",
                                    "RangoFechasIneficiente"
                                  ])
                                } }
                              >
                                {
                                  dataContratosTra.map((value, index) => (
                                    <Select.Option key={index} value={value._id} name="Contrato" >
                                        {value.Tipo_Contrato.toString() === "Indefinido"
                                        ?
                                        `Contrato ${value.Tipo_Contrato} ${convertirFechaString(moment.utc(value.Contrato_Fecha_Inicio), 1).fechaDDMMYYYY }`
                                        :
                                        `Contrato ${value.Tipo_Contrato} ${convertirFechaString(moment.utc(value.Contrato_Fecha_Inicio), 1).fechaDDMMYYYY} Al ${convertirFechaString(moment.utc(value.Contrato_Fecha_Fin), 2).fechaDDMMYYYY}`}
                                    </Select.Option>
                                  ))
                                }
                              </Select>
                            </Form.Item>

                            <Form.Item
                              label="Fechas Busqueda"
                              name="RangoFechasBuq"
                              rules={[
                                {
                                  required: true,
                                  message: 'Debe seleccionar rango de fechas',
                                }
                              ]}
                            >
                              {
                                  this.state.objIneficiente.tieneIneficienciaAsignada === true
                                  ?
                                   <RangePicker
                                    allowClear={false}
                                    locale={locale}
                                    onChange={(fechasBuq) => {
                                      this.setState({ fechasBusquedaSelect: [fechasBuq[0],fechasBuq[1]] })
                                      this.obtenerTrabajadorTimeline(this.state.trabajadorIDSelect, this.state.contratoElegido,fechasBuq[0],fechasBuq[1])
                                      if(this.state.botonActiveEdit === true){
                                        this.FormularioRef.current.resetFields([
                                          "RangoFechasIneficiente"
                                        ])
                                      }
                                    }}
                                   />
                                  :
                                  <RangePicker
                                    allowClear={false}
                                    disabled={fechaDisabled}
                                    disabledDate={(dia) => {
                                      if(contratoSelect.Tipo_Contrato.toString() === "Indefinido"){
                                        return dia < moment.utc(contratoSelect.Contrato_Fecha_Inicio);
                                      }else{
                                        return !dia.isBetween(moment.utc(contratoSelect.Contrato_Fecha_Inicio), moment.utc(contratoSelect.Contrato_Fecha_Fin), null, '[]');
                                      }
                                    }}
                                    onChange={(fechasBuq) => {
                                      this.setState({ fechasBusquedaSelect: [fechasBuq[0],fechasBuq[1]] })
                                      this.obtenerTrabajadorTimeline(this.state.trabajadorIDSelect, this.state.contratoElegido,fechasBuq[0],fechasBuq[1])

                                      this.FormularioRef.current.resetFields([
                                        "RangoFechasIneficiente"
                                      ])
                            
                                    }}
                                    locale={locale}
                                  />
                              }

                            </Form.Item>

                            <Form.Item
                              label="Fecha de inicio y fin de marca"
                              name="RangoFechasIneficiente"
                              rules={[
                                {
                                  required: true,
                                  message: 'Debe seleccionar rango de fechas',
                                }
                              ]}
                            >
                              {
                                 this.state.objIneficiente.tieneIneficienciaAsignada === true
                                 ?

                                  <RangePicker
                                    allowClear={false}
                                    disabled={fechaDisabled && (this.state.botonActiveEdit ? false : true) }
                                    locale={locale}
                                    disabledDate={(dia) => {
                                      const { fechasBusquedaSelect } = this.state;
                                      const buqInicio = convertirFechaString(moment.utc(fechasBusquedaSelect[0]),1).fechaString;
                                      const buqFin = convertirFechaString(moment.utc(fechasBusquedaSelect[1]),2).fechaString;
                                      return !dia.isBetween(buqInicio,buqFin, null, '[]');
                                    }}
                                  />

                                 :

                                 <RangePicker
                                    allowClear={false}
                                    disabled={fechaDisabled}
                                    disabledDate={(dia) => {
                                      const { fechasBusquedaSelect } = this.state;
                                      const buqInicio = convertirFechaString(moment.utc(fechasBusquedaSelect[0]),1).fechaString;
                                      const buqFin = convertirFechaString(moment.utc(fechasBusquedaSelect[1]),2).fechaString;
                                      return !dia.isBetween(buqInicio,buqFin, null, '[]');
                                    }}
                                    locale={locale}
                                 />
                              }

                            </Form.Item>

                            <Form.Item
                              label="Estado necesario"
                              name={"Estado"}
                              rules={[
                                {
                                  required: true,
                                  message: 'Debe seleccionar un estado',
                                }
                              ]}
                            >
                              <Select
                                name="Estado"
                                placeholder="Seleccionar estado"
                                disabled={this.state.objIneficiente.tieneIneficienciaAsignada === true ? (this.state.botonActiveEdit ? false : true)  : false}
                              >
                                {
                                  dataListaEstados.map((value, index) => (
                                    <Select.Option key={index} value={value._id} name="Situación">
                                        {value.Nombre}
                                    </Select.Option>
                                  ))
                                }
                              </Select>
                            </Form.Item>

                            <Form.Item label="Comentario" name={"Comentario"}>
                              <TextArea
                                disabled={this.state.objIneficiente.tieneIneficienciaAsignada === true ? (this.state.botonActiveEdit ? false : true) : false}
                                rows={4}
                                placeholder="Maximo de caracteres 200"
                                maxLength={200}
                              />
                            </Form.Item>

                            <Form.Item  style={{textAlign: "right"}}>
                            {
                              this.state.objIneficiente.tieneIneficienciaAsignada === true ?
                              (
                                this.state.botonActiveEdit ?
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  style={{background: "#061FCB",borderRadius:"9px",color:"#FFFFFF", fontWeight:"bold",
                                  paddingTop:"10px",paddingBottom:"30px",paddingLeft:"40px",paddingRight:"40px"}}
                                  //className={"btn-ineficiente-dashboard"}
                                >
                                  Actualizar
                                </Button>
                                 :
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  style={{background: "#061FCB",borderRadius:"9px",color:"#FFFFFF", fontWeight:"bold",
                                  paddingTop:"10px",paddingBottom:"30px",paddingLeft:"40px",paddingRight:"40px"}}
                                  //className={"btn-ineficiente-dashboard"}
                                >
                                  Eliminar
                                </Button>

                              )

                              :

                              <>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  style={{background: "#061FCB",borderRadius:"9px",color:"#FFFFFF", fontWeight:"bold",
                                  paddingTop:"10px",paddingBottom:"30px",paddingLeft:"40px",paddingRight:"40px"}}
                                  //className={"btn-ineficiente-dashboard"}
                                >
                                  Guardar
                                </Button>
                              </>
                            }

                            </Form.Item>

                          </Col>

                          <Col
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            lg={{ span: 9, offset: 1 }}
                            xl={{ span: 9, offset: 1 }}
                            xxl={{ span: 9, offset: 1 }}
                            style={{borderLeft:"1px solid #9B9B9B"}}
                          >
                            <h2 style={{borderTopColor:"9px", color:"black", fontWeight:"bold",fontSize:"16px",fontFamily:"MavenPro-Regular", marginLeft:"16px", padding:"5px"}}>
                            Calendario Trabajador
                            </h2>
                            {
                              bitacorasTimeline.length > 0 ?
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "400px",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                  }}
                                >
                                  <Steps
                                  current={0}
                                  progressDot={customDot}
                                  direction={"vertical"}
                                  labelPlacement={"vertical"}
                                  responsive={true}
                                  style={{marginLeft:"20px"}}
                                >
                                  {
                                    bitacorasTimeline.length > 0 && bitacorasTimeline.map((diasCC)=>

                                    <>

                                      <Step
                                        key={diasCC._id}
                                        title={
                                          this.state.objIneficiente.tieneIneficienciaAsignada === true ?
                                          moment.utc(diasCC.Fecha).isBetween(
                                            this.state.objIneficiente.BitacoraIneficiencia.Fecha_Inicio,
                                            this.state.objIneficiente.BitacoraIneficiencia.Fecha_Fin,
                                            null, '[]'
                                          ) === true ?
                                          <a style={{color: "red"}}>{`${moment.utc(diasCC.Fecha).format('DD-MM-YYYY')}`}</a> : `${moment.utc(diasCC.Fecha).format('DD-MM-YYYY')}`
                                          :
                                          moment.utc(diasCC.Fecha).format('DD-MM-YYYY')
                                        }
                                        subTitle={
                                        diasCC.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261"? "Trabajo efectivo" :
                                        diasCC.TipoRegistroBitacoraTrabajador === "5f3eeb78978b284b68ebced4"? "Descanso"  :
                                        diasCC.TipoRegistroBitacoraTrabajador === "5f8f369e8efe155a48df37fb"? "En transito":
                                        diasCC.TipoRegistroBitacoraTrabajador === "5f3eebac978b284b68ebced5"? "Vacaciones"  :
                                        diasCC.TipoRegistroBitacoraTrabajador === "5f3eebf46dd7cc57f0d3a25d"? "Licencia":
                                        diasCC.TipoRegistroBitacoraTrabajador === "5f3eec676dd7cc57f0d3a25e"? "Licencia mutualidad":
                                        diasCC.TipoRegistroBitacoraTrabajador === "5f3eec876dd7cc57f0d3a25f"? "Permiso":
                                        diasCC.TipoRegistroBitacoraTrabajador === "5f3eeca16dd7cc57f0d3a260"? "Ausente":
                                        diasCC.TipoRegistroBitacoraTrabajador === "5f904101d9c57145a09eb058"? "No Asignado"   :
                                        "No Asignado"}
                                        status={
                                        <>
                                          {
                                            this.state.objIneficiente.tieneIneficienciaAsignada === true ?
                                            moment.utc(diasCC.Fecha).isBetween(
                                              this.state.objIneficiente.BitacoraIneficiencia.Fecha_Inicio,
                                              this.state.objIneficiente.BitacoraIneficiencia.Fecha_Fin,
                                              null, '[]'
                                            ) === true ?
                                            <>
                                              <a style={{color: "grey"}}>{moment.utc(diasCC.Fecha).format('DD-MM-YYYY')}</a>
                                              <br/>
                                              {/* <a style={{color: "red"}}>Ineficiente</a> */}
                                              <a style={{color: "red"}}>Dia marcado</a>
                                            </>

                                            : `${moment.utc(diasCC.Fecha).format('DD-MM-YYYY')}`
                                            :
                                            moment.utc(diasCC.Fecha).format('DD-MM-YYYY')
                                          }
                                        </>}

                                        className={
                                          diasCC.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261"? "dot-green-timeline" :
                                          diasCC.TipoRegistroBitacoraTrabajador === "5f3eeb78978b284b68ebced4"? "dot-pink-timeline"  :
                                          diasCC.TipoRegistroBitacoraTrabajador === "5f8f369e8efe155a48df37fb"? "dot-lightpurple-timeline":
                                          diasCC.TipoRegistroBitacoraTrabajador === "5f3eebac978b284b68ebced5"? "dot-cyan-timeline"  :
                                          diasCC.TipoRegistroBitacoraTrabajador === "5f3eebf46dd7cc57f0d3a25d"? "dot-electric-blue-timeline":
                                          diasCC.TipoRegistroBitacoraTrabajador === "5f3eec676dd7cc57f0d3a25e"? "dot-yellow-timeline":
                                          diasCC.TipoRegistroBitacoraTrabajador === "5f3eec876dd7cc57f0d3a25f"? "dot-orange-timeline":
                                          diasCC.TipoRegistroBitacoraTrabajador === "5f3eeca16dd7cc57f0d3a260"? "dot-violet-timeline":
                                          diasCC.TipoRegistroBitacoraTrabajador === "5f904101d9c57145a09eb058"? "dot-red-timeline"   :
                                          "dot-white"
                                        }
                                      />

                                    </>)
                                  }
                                  </Steps>
                                </div>
                              </>
                              :
                              <>
                              <Row style={{textAlign:"center",padding:"10px"}}>
                                <Col span={24}><img src={IconListBlueLight} style={{width:"250px",height:"200px"}}/></Col>
                                <Col span={24}><h3 style={{color:"#4C4C4C"}}>Selecciona una fecha</h3></Col>
                              </Row>


                              </>
                            }

                          </Col>

                        </>
                      }
                    </Row>

                  </>
                  :
                  <>
                    <Col span={24} style={{textAlign:"center"}}>
                      <img
                        src={carga_dashboard}
                        //className={"img-carga-dashboard"}
                        style={{width: "200px", height:"150px"}}
                      />
                    </Col>
                    <Col span={24} style={{textAlign:"center"}}>
                      <h1
                        className={"h1-carga-dashboard"}
                      >
                        No hay ningún trabajador para seleccionar.
                      </h1>
                    </Col>
                  </>
                }
              </Form>
              </Col>
            </Row>

          </Modal>
        </>
      );
    }
}

export default DashboardEstadoTrabajadores;


