import {
  LOADING,
  LOGIN,
  ERROR
} from "../types/LoginTypes";

import API from "../Api/api";
// import { CloudFilled } from "@ant-design/icons";

import Swal from "sweetalert2";

export const Login = (usuario) => async (dispatch) => {

  dispatch({ type: LOADING });

  try {
    const url = `${API}/Login`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(usuario),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:

        if (data.usuario.length !== 0) {

          let usuarioBaseDatos = data.usuario;

          usuarioBaseDatos.EmpresaSeleccionada = usuarioBaseDatos.Empresas[0];

          let usuarioId = usuarioBaseDatos._id;
          let usuario = JSON.stringify(usuarioBaseDatos);

          if(usuarioBaseDatos.Vigencia ===true){
            localStorage.clear();

            await (async () => {

              await new Promise(async (resolve, reject) => {

                await localStorage.setItem("usuario-people-manage", usuario);

                resolve();

              }).then(async () => {

                dispatch({ type: LOGIN, payload: data });
              })

            })()
          }else{
            Swal.fire("Error", "Usuario Inhabilitado", "error");
            dispatch({ type: ERROR, payload: data.err });
          }
     

        } else {
          Swal.fire("Error", "Correo y contraseña incorrectos", "error");
          dispatch({ type: ERROR, payload: data.err });
        }
        break;
      case 404:

        Swal.fire("Error", "Correo y contraseña incorrectos", "error");
        dispatch({ type: ERROR, payload: data.err });

        break;
      default:
        Swal.fire(
          "Error",
          `Error al realizar la peticion, Status: ${status}`,
          "error"
        );
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const RestablecerPassword = (email) => async (dispatch) => {

  dispatch({ type: LOADING });

  try {

    const url = `${API}/Enviar/Correo/Lost`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(email),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
      //case 404:

        dispatch({ type: ERROR });

        Swal.fire({
          title: "Bien",
          text: "Se ha enviado un correo con instrucciones para recuperar tu contraseña.",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        return;

      case 404:

        dispatch({ type: ERROR });

        Swal.fire({
          // title: "Bien",
          // text: "Se ha enviado un correo con instrucciones para recuperar tu contraseña.",
          // icon: "success",
          title: "Error",
          text: "No se encontró usuario con el email ingresado.",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        return;

      case 204:

        dispatch({ type: ERROR });

        Swal.fire({
          title: "Bien",
          text: "Se ha enviado un correo con instrucciones para recuperar tu contraseña.",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        return;

      default:
        dispatch({ type: ERROR });
        Swal.fire({
          title: "Ups...",
          text: "Ha ocurrido un error",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        return;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};