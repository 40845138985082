export const LOADING = "MANTENEDOR_HOLDING_LOADING";
// export const PARAR_LOADING = "MANTENEDOR_HOLDING_LOADING_PARAR";
export const ERROR = "MANTENEDOR_HOLDING_ERROR";
export const OBTENER_EMPRESAS = "MANTENEDOR_HOLDING_EMPRESAS";
export const INGRESAR_HOLDING = "MANTENEDOR_HOLDING_INGRESAR";
export const LIMPIAR_ERROR_SUCCESS = "MANTENEDOR_HOLDING_LIMPIAR_ERROR_SUCCESS";
export const MOSTRAR_MODAL_HOLDING = "MANTENEDOR_HOLDING_MOSTRAR_MODAL";
export const OCULTAR_MODAL_HOLDING = "MANTENEDOR_HOLDING_OCULTAR_MODAL";
export const OCULTAR_MODAL_HOLDING_EDITAR =
  "MANTENEDOR_HOLDING_OCULTAR_MODAL_EDITAR";
export const OBTENER_HOLDINGS_MANTENEDOR =
  "MANTENEDOR_HOLDINGS_OBTENER_HOLDINGS";
export const OBTENER_HOLDING_MANTENEDOR = "MANTENEDOR_HOLDINGS_OBTENER_HOLDING";
export const ELIMINAR_HOLDING_MANTENEDOR =
  "MANTENEDOR_HOLDINGS_ELIMINAR_HOLDING";
export const EDITAR_HOLDING_MANTENEDOR = "MANTENEDOR_HOLDING_EDITAR_ROL";
export const TOMAR_VALORES_EDITAR = "MANTENEDOR_HOLDING_TOMAR_VALORES_EDITAR";
export const DESCARGAR_EXCEL = "MANTENEDOR_HOLDING_DESCARGAR_EXCEL";
