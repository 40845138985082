// Tipos de consultas
import {
  ERROR,
  INGRESAR_FAENA,
  LOADING,
  OBTENER_TITULOS,
  OBTENER_ESPECIALIDADES,
  LIMPIAR_ERROR_SUCCESS,
  OBTENER_CARGOS,
  MOSTRAR_MODAL_FAENA,
  OCULTAR_MODAL_FAENA,
  OBTENER_FAENAS_MANTENEDOR,
  ELIMINAR_FAENA_MANTENEDOR,
  OBTENER_FAENA_MANTENEDOR,
  OCULTAR_MODAL_FAENA_EDITAR,
  EDITAR_FAENA_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  //   PARAR_LOADING,
} from "../types/MantenedorPlantillaDeFaenaTypes";

const INITIAL_STATE = {
  loading: false,
  titulos: [],
  especialidades: [],
  error: null,
  success: null,
  modalFaena: false,
  modalFaenaEditar: false,
  cargos: [],
  faenas: [],
  faena: null,
  tablaCargando: true,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case INGRESAR_FAENA:
      return {
        ...state,
        loading: false,
        success: payload,
        modalFaena: false,
        tablaCargando: false,
      };
    case OBTENER_TITULOS:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        titulos: payload,
      };

    case OBTENER_ESPECIALIDADES:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        especialidades: payload,
      };
    case OBTENER_CARGOS:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        cargos: payload,
      };

    case EDITAR_FAENA_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        modalFaenaEditar: false,
      };

    case TOMAR_VALORES_EDITAR:
      const { nombreCampo, valor } = payload;
      return {
        ...state,
        faena: {
          ...state.faena,
          [nombreCampo]: valor,
        },
      };

    case LOADING:
      return {
        ...state,
        loading: true,
        tablaCargando: true,
      };
    // case PARAR_LOADING:
    //   return {
    //     ...state,
    //     loading: false,
    //     tablaCargando: false,
    //   };
    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        tablaCargando: false,
      };
    case LIMPIAR_ERROR_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };
    case MOSTRAR_MODAL_FAENA:
      return {
        ...state,
        modalFaena: true,
      };

    case OBTENER_FAENAS_MANTENEDOR:
      return {
        ...state,
        faenas: payload,
        loading: false,
        tablaCargando: false,
      };
    case OBTENER_FAENA_MANTENEDOR:
      return {
        ...state,
        faena: payload,
        loading: false,
        tablaCargando: false,
        modalFaenaEditar: true,
      };
    case ELIMINAR_FAENA_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      };
    case OCULTAR_MODAL_FAENA:
      return {
        ...state,
        modalFaena: false,
      };
    case OCULTAR_MODAL_FAENA_EDITAR:
      return {
        ...state,
        modalFaenaEditar: false,
      };
    default:
      return state;
  }
};
