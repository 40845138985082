export const LOADING = "MANTENEDOR_PERFIL_USUARIO_LOADING";
export const PARAR_LOADING = "MANTENEDOR_PERFIL_USUARIO_PARAR_LOADING";
// export const PARAR_LOADING = "MANTENEDOR_FAENA_LOADING_PARAR";
export const ERROR = "MANTENEDOR_PERFIL_USUARIO_ERROR";

export const MOSTRAR_MODAL_PERFIL_USUARIO = "MANTENEDOR_PERFIL_USUARIO_MOSTRAR_MODAL";

export const OCULTAR_MODAL_PERFIL_USUARIO = "MANTENEDOR_PERFIL_USUARIO_OCULAR_MODAL";

export const OCULTAR_MODAL_PERFIL_USUARIO_EDITAR = "MANTENEDOR_PERFIL_USUARIO_OCULTAR_MODAL_EDITAR";

export const OBTENER_PERFILES_ACCESO = "MANTENEDOR_PERFIL_USUARIO_OBTENER_PERFILES_ACCESOS";

export const OBTENER_PERFIL_ACCESO = "MANTENEDOR_PERFIL_USUARIO_OBTENER_PERFIL_ACCESO";

// export const DESCARGAR_EXCEL = "MANTENEDOR_FAENA_DESCARGAR_EXCEL";
