import API from "../../Redux/Api/api";

export const obtenerTrabajadorTransbordo = async (objetoTransbordo) => {
    try {
        const url = `${API}/Trabajadores/trabajadorTransbordo`;
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(objetoTransbordo),
        });
        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let trabajador = data.trabajador;
                console.log("Trabajador:",trabajador);
                return trabajador;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }


    } catch (error) {
        console.error(error);
    }

    return [];
}

export const obtenerCentrosDeCostoTransbordo = async (idEmpresa) => {
    try {

        const url = `${API}/CentroCostoTransbordo?empresa=${idEmpresa}`;
        const response = await fetch(url, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let centros_de_costo = data.centros_de_costo;
                return centros_de_costo;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }


    } catch (error) {
        console.error(error);
    }

    return [];
}


export const obtenerActividadesTransbordo = async (idEmpresa, objPlaza) => {
    try {

        const url = `${API}/ActividadesParaTransbordo?empresa=${idEmpresa}`;
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(objPlaza),
        });
        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let actividades = data.actividades;
                return actividades;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }


    } catch (error) {
        console.error(error);
    }

    return [];
}

export const obtenerPlazasTransbordo = async (idEmpresa, objPlaza) => {
    try {
        const url = `${API}/PlazasParaTransbordo?empresa=${idEmpresa}`;
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(objPlaza),
        });
        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let plazas = data.plazas;
                return plazas;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }


    } catch (error) {
        console.error(error);
    }

    return [];
}

export const obtenerFaenasDestinoTransbordo = async (idEmpresa, objPlaza) => {
    try {

        const url = `${API}/FaenasDestinoTransbordo?empresa=${idEmpresa}`;
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(objPlaza),
        });
        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let faenas = data.faenas;
                return faenas;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }


    } catch (error) {
        console.error(error);
    }

    return [];
}


export const validarFechasModificables = async (idEmpresa, objetoValidarFechasModificables) => {
    try {

        const url = `${API}/Trabajadores/ValidarFechasModificables?empresa=${idEmpresa}`;
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(objetoValidarFechasModificables),
        });
        const status = response.status;
        let data;
        let error;
        console.log("Statuss",status);
        switch (status) {
            case 200:
            case 201:
                data = await response.json();
                return {status, data};
            case 409:
                data = await response.json();
                console.log("Data 409",data);
                error = data.err.toString();
                return {status, data: [], error};
            default:
                data = await response.json();
                error = data.err;
                return {status, data: [], error};
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const cambioDeActividad = async (idEmpresa, objetoCambioActividad) => {
    try {
        const url = `${API}/Trabajadores/CambioDeActividad?empresa=${idEmpresa}`;
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(objetoCambioActividad),
        });
        const status = response.status;
        let data;
        let error;
        switch (status) {
            case 200:
            case 201:
                data = await response.json();
                return {status, data};
            case 409:
                data = await response.json();
                console.log("Data 409",data);
                error = data.err.toString();
                return {status, data: [], error};
            default:
                data = await response.json();
                error = data.err;
                return {status, data: [], error};
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}

// export const validarCambioActividad = async (idEmpresa, objetoValidarCambioActividad) => {
//     try {
//         const url = `${API}/Trabajadores/ValidarCambioActividad?empresa=${idEmpresa}`;
//         const response = await fetch(url, {
//             method: "POST",
//             mode: "cors",
//             headers: {
//                 "Content-Type": "application/json",
//                 Accept: "application/json",
//             },
//             body: JSON.stringify(objetoValidarCambioActividad),
//         });
//         const status = response.status;
//         let data;
//         let error;
//         switch (status) {
//             case 200:
//             case 201:
//                 data = await response.json();
//                 return {status, data};
//             case 409:
//                 data = await response.json();
//                 console.log("Data 409",data);
//                 error = data.err.toString();
//                 return {status, data: [], error};
//             default:
//                 data = await response.json();
//                 error = data.err;
//                 return {status, data: [], error};
//         }
//     } catch (error) {
//         console.error(error);
//         return [];
//     }
// }

export const transbordoActividad = async (idEmpresa, objetoTransbordoActividad) => {
    try {
        const url = `${API}/Plaza/TransbordoTrabajador/mejorado?empresa=${idEmpresa}`;
        const response = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(objetoTransbordoActividad),
        });
        const status = response.status;
        let data;
        let error;
        switch (status) {
            case 200:
            case 201:
                data = await response.json();
                return {status, data};
            case 409:
                data = await response.json();
                console.log("Data 409",data);
                error = data.err.toString();
                return {status, data: [], error};
            default:
                data = await response.json();
                error = data.err;
                return {status, data: [], error};
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const transbordoValidarDisponibilidad = async (idEmpresa, objetoTransbordoActividad) => {
    try {
        const url = `${API}/Plaza/TransbordoTrabajador/validarDisponibilidad?empresa=${idEmpresa}`;
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(objetoTransbordoActividad),
        });
        const status = response.status;
        let data;
        let error;
        switch (status) {
            case 200:
            case 201:
                data = await response.json();
                return {status, data};
            case 409:
                data = await response.json();
                console.log("Data 409", data);
                error = data.err.toString();
                return {status, data: [], error};
            default:
                data = await response.json();
                error = data.err;
                return {status, data: [], error};
        }
    } catch (error) {
        console.error(error);
        return {status:500, data: [], error};
    }
}