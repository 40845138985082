import API from "../../Redux/Api/api";

export const obtenerCargos = async (idEmpresa) => {

  try {
    const url = `${API}/CargosTrabajadores/Vigentes?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        let cargosTrabajadores = data.data;
        return cargosTrabajadores;

      default:

        const error = data.err.message.toString();
        console.error(error);

        return [];
    }
  } catch (error) {
    console.error(error);
  }

  return [];
}

export const obtenerCC = async (idEmpresa) => {
  try {
    const url = `${API}/CentroDeCostos?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:

        const centrosCostos = data.centrosDeCostos;
        return centrosCostos;

      default:
        const error = data.err.message.toString();
        console.error(error);
        return [];

    }
  } catch (error) {
    console.error(error);
  }
}

export const obtenerReporteDescansos = async (objFiltros) => {

  try {
    const url = `${API}/Desviaciones/ReporteDescansos`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objFiltros),
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();
        return data.data;

      default:
        const error = data.err.message.toString();
        console.error(error);
        return [];

    }
  } catch (error) {
    console.error(error);
  }
}

export const obtenerReporteTrabajo = async (objFiltros) => {

  try {
    const url = `${API}/Desviaciones/ReporteTrabajos`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objFiltros),
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();
        return data.data;

      default:
        const error = data.err.message.toString();
        console.error(error);
        return [];

    }
  } catch (error) {
    console.error(error);
  }
}