import React, { Component } from "react";
import "./HeaderFicha.css";
import { PageHeader, Select, Button } from "antd";
import PropTypes from "prop-types";
// import mensaje from "../../img/icons/mensaje.svg";
// import notificaciones from "../../img/icons/notificaciones.svg";

import logo from "../../img/icons/logo-pmanagment.svg";
import { ImportOutlined } from "@ant-design/icons";

class HeaderFicha extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Empresas: [],
      idEmpresaActual: "",
    };
  }

  static propTypes = {
    titulo: PropTypes.string,
    subtitulo: PropTypes.string,
  };

  componentDidMount = () => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      let empresas = [];

      usuario.Empresas.forEach(empresa => {
        empresas.push(empresa.Empresa);
      });

      this.setState({
        Empresas: empresas,
        idEmpresaActual: usuario.EmpresaSeleccionada.Empresa._id
      })
    }
  };

  cambiarEmpresa = async (idEmpresa) => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      let empresaSeleccionada;


      usuario.Empresas.forEach(empresa => {
        if (empresa.Empresa._id.toString() === idEmpresa.toString()) {
          empresaSeleccionada = empresa;
        }
      });

      usuario.EmpresaSeleccionada = empresaSeleccionada;


      let usuarioNuevo = JSON.stringify(usuario);


      await (async () => {
        await new Promise(async (resolve, reject) => {
          await localStorage.setItem("usuario-people-manage", usuarioNuevo);
          resolve();

        }).then(() => {
          window.location.reload();
        })

      })()
    }
  }

  render() {
    return (
      <PageHeader
        key={1}
        style={{ paddingLeft: 21 }}
        className="site-page-header"
        title={this.props.titulo}
        subTitle={this.props.subtitulo}
        extra={[
          <h1
            className={'display-none-mobile'}
            key={1}
            style={{ display: "inline-block" }}>
            Vista de:
          </h1>,

          <Select
            className={'empresas-selector'}
            style={{
              width: "400px",
              marginRight: "26px",
              display: "inline-block"
            }}
            key={2}
            placeholder="Selecciona Empresa"
            value={this.state.idEmpresaActual}
            onSelect={(value) => {
              this.cambiarEmpresa(value);
            }}
          >
            {this.state.Empresas.map((value, index) => (
              <Select.Option name="Rol" key={index} value={value._id}>
                <b>{value.Razon_Social}</b>
              </Select.Option>
            ))}
          </Select>,
          <img
            className={'display-none-mobile'}
            key={3}
            src={logo}
            style={{
              width: "26px",
              marginRight: "50px"
            }}
            alt="icono mensaje"
          />
          ,
          // <Button
          //   className="cerrar-sesion-btn"
          //   onClick={() => {
          //     localStorage.removeItem("usuario-people-manage");
          //     localStorage.removeItem("accesos");
          //     window.location = "/";
          //   }}
          // >
          //   Cerrar sesión
          //   <ImportOutlined />
          // </Button>

        ]}
      />
    );
  }
}

export default HeaderFicha;
