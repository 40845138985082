import React from "react";
// import url from '../../config';
//import { isMobile } from "react-device-detect";
import QrReader from "react-qr-reader";
import './AsistenciaQR.css';
// var CryptoJS = require("crypto-js");
import { Row, Col, Select, Button, Layout } from "antd";
import Swal from "sweetalert2";
import Loading from "../../components/loading/Loading";
import API from "../../Redux/Api/api";
import moment from "moment";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";

const { Header } = Layout;

const { Option } = Select;

class VereficacionQR extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      centrosDeCostos: [],
      idCentroCosto: null,
      isButtonDisabled: true,
      isCentroSeleccionado: false,
      nombreCentroCosto: '',

      qrScanFlag: true,

      swalInfoFlag: true,

      resDataBitacora: null,
    };
  }

  componentWillMount = async () => {

    this.setState({
      loading: true,
    })

    await this.getCentrosDeCosto();

  }

  handleScan = async (data) => {

    if (data !== null && !this.state.isCentroSeleccionado) {

      if (this.state.swalInfoFlag) {

        this.setState({
          swalInfoFlag: false,
        })

        Swal.fire({
          title: "Uups",
          text: 'Debes seleccionar un centro de costos primero.',
          icon: "info",
        }).then(() => {
          this.setState({
            swalInfoFlag: true,
          })

        })
      }


    } else if (data !== null && this.state.isCentroSeleccionado) {

      if (this.state.qrScanFlag) {

        this.setState({
          qrScanFlag: false,
        })

        //data es el ID del trabajador
        const resDataBitacora = await this.getBitacoraTrabajadorDia(data);

        let errFlag = false;
        let errMsg = null;

        // validación si el día no existe en la planificación
        if (resDataBitacora === undefined || resDataBitacora === 'undefined') {

          Swal.fire({
            title: "Ups!",
            text: 'Su usuario aún no tiene planificación el día de hoy.',
            icon: "error",
          }).then(() => {
            this.setState({
              qrScanFlag: true,
            })
          })

          return false;

          //validación si el día no es de trabajo (es de licencia, vacaciones, etc)
        } else if (resDataBitacora.IdFaena === undefined) {
          Swal.fire({
            title: "Ups!",
            text: 'Su planificación del día de hoy no es de trabajo.',
            icon: "error",
          }).then(() => {
            this.setState({
              qrScanFlag: true,
            })
          })

          return false;
        }

        //VALIDACIÓN DE CENTRO DE COSTO
        if (resDataBitacora.IdFaena.Centro_De_Costo === this.state.idCentroCosto) {

          //VALIDACIÓN DE ESTADO
          switch (resDataBitacora.Estado) {
            // En Curso
            case "5f3ef6861ecf531c1c8ab4a9":

              const objAsistencia = {
                Fecha: resDataBitacora.Fecha,
                IdGrupo: resDataBitacora.IdGrupo,
                IdPlaza: resDataBitacora.IdPlaza,
                IdPuestoPlaza: resDataBitacora.IdPuestoPlaza,
                IdTrabajador: resDataBitacora.IdTrabajador,
              };

              let arrAsistencia = [];

              arrAsistencia.push(objAsistencia)

              let objEnviar = {
                trabajadores: arrAsistencia,
              };

              const asistenciaData = await this.postAsistencia(objEnviar);

              Swal.fire({
                title: "Bien!",
                text: 'Se ha ingresado la asistencia correctamente.',
                icon: "success",
              }).then(() => {
                this.setState({
                  qrScanFlag: true,
                })
              })

              break;

            // Sin Cubrir
            case "5f3ef5ce1ecf531c1c8ab4a7":
              errFlag = true;
              errMsg = 'La plaza está sin cubrir.'
              break;

            // Reservadas
            case "5f3ef6591ecf531c1c8ab4a8":
              errFlag = true;
              errMsg = 'La plaza está reservada pero aún no se confirma su check-in.'
              break;

            // Ya Cubiertas
            case "5f3ef6981ecf531c1c8ab4aa":
              errFlag = true;
              errMsg = 'Ya se pasó asistencia de éste día.'
              break;

            default:
              errFlag = true;
              errMsg = 'Error: contactar con administración.'

              break;
          }

          if (errFlag) {
            Swal.fire({
              title: "Uups",
              text: errMsg,
              icon: "error",
            }).then(() => {
              this.setState({
                qrScanFlag: true,
              })
            })
          }

        } else {
          Swal.fire({
            title: "Uups",
            text: 'El trabajador no se ha planificado para éste centro de costos, seleccione su centro de costos.',
            icon: "error",
          }).then(() => {
            this.setState({
              qrScanFlag: true,
            })
          })
        }

      }
    }

  };

  handleError = (err) => { };

  getCentrosDeCosto = async () => {

    try {
      let usuario = localStorage.getItem("usuario-people-manage");

      if (
        usuario !== null &&
        usuario !== "" &&
        usuario !== undefined &&
        usuario !== "undefined"
      ) {
        usuario = JSON.parse(usuario);
        const id = usuario.EmpresaSeleccionada.Empresa._id;
        const url = `${API}/CentroDeCostos?empresa=${id}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        const status = response.status;
        const data = await response.json();

        switch (status) {
          case 200:
          case 201:
            const centroCostos = data.centrosDeCostos;

            this.setState({
              loading: false,
              centrosDeCostos: centroCostos
            })

            break;

          default:
            const error = data.err.message.toString();

            Swal.fire({
              title: "¡Ups!",
              text: "Error status: " + status + " ,detalle: " + error,
              icon: "error",
            });

            this.setState({
              loading: false,
            })
            break;
        }
      } else {
        window.location = "/";
      }
    } catch (error) {
      console.error(error);

      this.setState({
        loading: false,
      })
    }


  }

  getBitacoraTrabajadorDia = async (idTrabajador) => {

    const actualDate = moment().format('YYYY-MM-DD');

    const fechaObj = {
      Fecha: actualDate
    }

    try {

      const url = `${API}/BitacoraTrabajadores/${idTrabajador}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(fechaObj)
      });

      const status = response.status;
      const data = await response.json();

      switch (status) {
        case 200:
        case 201:

          return data.data[0];

        default:
          const error = data.err.message.toString();

          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });

          this.setState({
            loading: false,
          })

          break;
      }

    } catch (error) {
      console.error(error);

      this.setState({
        loading: false,
      })
    }

  }

  postAsistencia = async (objEnviar) => {

    try {

      const url = `${API}/Asistencia/Ingreso/Manual`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objEnviar)
      });

      const status = response.status;
      let data;
      switch (status) {
        case 200:
        case 201:

          data = await response.json();
          return data;

        default:
          data = await response.json();
          const error = data.err;

          console.error(error);

          Swal.fire({
            title: "Uups",
            text: "Ha ocurrido un error.",
            icon: "error",
          });

          break;
      }

    } catch (error) {
      console.error(error);

      this.setState({
        loading: false,
      })
    }
  }

  clickSeleccionar = () => {
    this.setState({
      isCentroSeleccionado: true,
    })

    Swal.fire({
      title: "Bien!",
      text: `Has seleccionado el centro de costos: ${this.state.nombreCentroCosto}`,
      icon: "success",
    });
  }

  Pintar() {
    return (
      <div style={{ fontFamily: 'MavenPro-Medium', height: 'fit-content' }}>
        <Loading cargando={this.state.loading} />
        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Validación QR"
            subtitulo="Confirma asistencia mediante código QR."
          />
        </Header>

        <hr className={'display-none-mobile'} />

        <Row>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>

            <Row
              justify='center'
              style={{ marginTop: '20px' }}
              gutter={10}
            >

              <Col
                span={24}
                style={{ textAlign: 'center', marginTop: '3%' }}
                className={'display-none-mobile'}
              >
                <h1 style={{ fontSize: '25px', color: '#061FCB' }}>Escanear Código QR</h1>
              </Col>

              <Col
                xs={24} sm={24} md={24} lg={24} xl={24}
                style={{ marginBottom: '15px' }}
                className={'qr-col-select'}
              >
                <Select
                  className={'select-asistencia'}
                  placeholder='Seleccione Centro de costos'
                  style={{ width: '100%', textAlign: 'left' }}
                  onSelect={(value, data) => {
                    if (data.value) {
                      this.setState({
                        idCentroCosto: value,
                        nombreCentroCosto: data.children,
                        isButtonDisabled: false,
                      });
                    }
                  }}
                >
                  {this.state.centrosDeCostos.map((centroDeCostos) => (
                    <Option key={centroDeCostos._id}>
                      {centroDeCostos.Nombre}
                    </Option>
                  ))}
                </Select>

              </Col>

              <Col
                xs={24} sm={24} md={24} lg={24} xl={24}
                className={'qr-col-button'}
              >

                <Button
                  type='primary'
                  className={'reporte-filtrar-btn'}
                  style={{ marginBottom: '15px', width: '100%' }}
                  disabled={this.state.isButtonDisabled}
                  onClick={this.clickSeleccionar}
                >
                  Seleccionar
                </Button>

              </Col>

            </Row>

            <div
              className={'qr-wrapper'}
            >
              <QrReader
                delay={300}
                onError={this.handleError}
                onScan={this.handleScan}
                className='qr-block'
              />
            </div>

          </Col>
        </Row>



        <span>
          <style jsx="true">
            {`
            .ant-layout-content {
              margin: 0px !important;
            }
          `}
          </style>
        </span>

      </div >
    );
  }

  render() {
    return <>{this.Pintar()}</>;
  }
}

export default VereficacionQR;
