import React, { useEffect, useState } from "react";
import CentroCosto from "../../img/icons/CentroCosto.svg";
import {
  Form, Input, Button, Card, Switch, Row, Col, PageHeader, Radio, InputNumber, DatePicker,
  Select,
  Divider,
} from "antd";
import { LeftCircleTwoTone } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";

import {
  momentToUnixFechaInicio,
  momentToUnixFechaFin,
  momentToStringFechaInicio,
  momentToStringFechaFin,
  databaseFormat,
  convertirFechaString
} from "../../funcionesGenericas/fechas";
import {
  validarModificacionFechasContrato,
  finiquitarTrabajadorPlanificacionesAcortar
} from "../../views/contratos/contratosApi";

import Loading from "../../components/loading/Loading";

import Swal from "sweetalert2";

const FormularioEditarContrato = (props) => {

  const { RangePicker } = DatePicker;
  const { TextArea } = Input;

  const editarContratoFormRef = React.createRef();

  const [tipoFechaContrato, setTipoFechaContrato] = useState();
  const [isFiniquitado, setIsFiniquitado] = useState(false);
  const [isAnexo, setIsAnexo] = useState(false);
  const [fechaRenovacion, setFechaRenovacion] = useState(null);
  const [esRelevo, setEsRelevo] = useState(false);

  const [fechasAntiguas,setFechasAntiguas] = useState(["",""]);
  const [tipoContratoAntiguo, setTipoContratoAntiguo] = useState("");
  const [comenzoCambio,setComenzoCambio] = useState(false);
  const [statusFechaFiniquito,setStatusFechaFiniquito]= useState(null);
  const [statusFechaContrato, setStatusFechaContrato] = useState(null);
  const [textFechaFiniquito,setTextFechaFiniquito]= useState(null);

  const [loading,setLoading] = useState(false);

  const onFinish = async (values) => {

    let cancelFlag = false;

    await Swal.fire({
      title: "Confirmar",
      text: `¿Estás seguro que quieres modificar el contrato del trabajador?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {

      if (!result.value) {
        cancelFlag = true;
      }
    })

    if (cancelFlag) {
      return;
    }

    if (values.Tipo_Contrato === "Indefinido") {
      values.Contrato_Fecha_Inicio = values.Contrato_Fecha;
    } else {
      values.Contrato_Fecha_Inicio = values.Contrato_Fecha[0];
      values.Contrato_Fecha_Fin = values.Contrato_Fecha[1];
    }

    //transformación de fechas a Unix timestamp
    values.Contrato_Fecha_Inicio_Unix = momentToUnixFechaInicio(
      values.Contrato_Fecha_Inicio
    );
    values.Contrato_Fecha_Inicio = momentToStringFechaInicio(
      values.Contrato_Fecha_Inicio,
      databaseFormat
    );

    if (values.Contrato_Fecha_Fin) {
      values.Contrato_Fecha_Fin_Unix = momentToUnixFechaFin(
        values.Contrato_Fecha_Fin
      );
      values.Contrato_Fecha_Fin = momentToStringFechaFin(
        values.Contrato_Fecha_Fin,
        databaseFormat
      );
    }

    const estaFiniquitado = props.contratoSeleccionado.Contrato_Finiquitado;

    if (estaFiniquitado) {
      values.Fecha_Finiquito_Unix = momentToUnixFechaInicio(values.Fecha_Finiquito);
    }


    //validacion de fechas de contrato
    if(comenzoCambio){
      const { Empresa , IdTrabajador } = props.contratoSeleccionado;
      const { Tipo_Contrato , Contrato_Fecha} = values;
      let body = {
        ID_TRABAJADOR: IdTrabajador,
        TIPO_CONTRATO: Tipo_Contrato,
        TIPO_CONTRATO_ANTIGUO: tipoContratoAntiguo
      };
      //datos antiguos
      if(tipoContratoAntiguo === "Plazo fijo"){
        body.INICIO_PLANIFICACION = fechasAntiguas[0];
        body.FIN_PLANIFICACION = fechasAntiguas[1];
      }else{
        body.INICIO_PLANIFICACION = fechasAntiguas;
      }
      //datos nuevos
      if(Tipo_Contrato === "Plazo fijo"){
        body.NUEVO_INICIO_PLANIFICACION = convertirFechaString(Contrato_Fecha[0].clone(),1).fechaString;
        body.NUEVO_FIN_PLANIFICACION = convertirFechaString(Contrato_Fecha[1].clone(),2).fechaString;
      }else{
        body.NUEVO_INICIO_PLANIFICACION = convertirFechaString(Contrato_Fecha.clone(),1).fechaString;
      }

      const respuesta = await validarModificacionFechasContrato(body, Empresa);
      switch (respuesta.status) {
        case 200:
          setStatusFechaContrato("success");
          break;
        case 409:
          setStatusFechaContrato("error");
          Swal.fire({
            title: "Error",
            text: `No se puede editar en las fechas indicadas ya que hay planificación la cual no quedaria dentro del contrato`,
            icon: "error",
            confirmButtonColor: "#E60AA3",
          })

          return;
        default:
          setStatusFechaContrato("error");
          Swal.fire({
            title: "Error",
            text: `Error no controlado`,
            icon: "error",
            confirmButtonColor: "#E60AA3",
          });
          return;
      }

    }
    //validacion de fecha de finiquito
    if(isFiniquitado){
      const { IdTrabajador } = props.contratoSeleccionado;
      const { Fecha_Finiquito } = values;
      const fechaFinFiniquito = convertirFechaString(Fecha_Finiquito.clone(),2).fechaString;
      const planificacionesCortar = await finiquitarTrabajadorPlanificacionesAcortar(IdTrabajador,fechaFinFiniquito);

  
      if(planificacionesCortar.status !== 200){
        Swal.fire({
          title: "¡Ups!",
          text: "Error al realizar petición para identificar corte de planificaciones. Status: "+planificacionesCortar.status,
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        });
        setStatusFechaFiniquito("error");
        return;
      }else{
        
        if(planificacionesCortar.data.PuedeFiniquitarEdicionFiniquito === false){
          Swal.fire({
            title: "¡Ups!",
            text: "No podra finiquitar en la fecha indicada. Esta dejando planificación posterior a la fecha del finiquito",
            icon: "error",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Ok",
          });
          setStatusFechaFiniquito("error");
          return;
        }
        setStatusFechaFiniquito("success");
      } 
    }

    let idContrato = props.contratoSeleccionado._id;
    setLoading(true);
    await props.editarContrato(values, estaFiniquitado, idContrato);
    setLoading(false);
  };

  let idContrato = props.contratoSeleccionado._id;

  useEffect(() => {

    const { Contrato_Fecha_Inicio_Unix, Contrato_Fecha_Fin_Unix, Costo_Bruto_Diario,
      Costo_Liquido_Diario, Tipo_Contrato, Contrato_Finiquitado, Fecha_Finiquito, Causal_Finiquito,
      Observaciones_Finiquito, Se_Puede_Recontratar, Es_Anexo_De,Relevo, Contrato_Fecha_Inicio , Contrato_Fecha_Fin } = props.contratoSeleccionado;

    let valFechaContrato;
    let fechaInicioContrato = moment.unix(Contrato_Fecha_Inicio_Unix);
    let alerta= false;
    let mensajeAlerta = "Debe ingresar nueva fecha de finiquito. Fecha anterior:";
    // VALIDACIÓN: ¿Es definido o indefinido?: afecta al tipo de dato que se le entrega al formulario
    if (Tipo_Contrato === 'Indefinido') {
      valFechaContrato = fechaInicioContrato;

      const finiquitoCorrecto = moment.utc(Contrato_Fecha_Inicio).isAfter(moment.utc(Fecha_Finiquito));
      if(!finiquitoCorrecto){
        mensajeAlerta += ` ${moment.utc(Fecha_Finiquito).format("DD-MM-YYYY")}`;
        alerta= true;
      }
    } else {
      const finiquitoCorrecto = moment.utc(Fecha_Finiquito).isBetween(moment.utc(Contrato_Fecha_Inicio),moment.utc(Contrato_Fecha_Fin),null,"[]");
      if(!finiquitoCorrecto){
        mensajeAlerta += ` ${moment.utc(Fecha_Finiquito).format("DD-MM-YYYY")}`;
        alerta= true;
      }

      let fechaFinContrato = moment.unix(Contrato_Fecha_Fin_Unix);

      valFechaContrato = [fechaInicioContrato, fechaFinContrato];
    }

    let objFormulario = {
      Tipo_Contrato,
      Contrato_Fecha: valFechaContrato,
      Costo_Bruto_Diario,
      Costo_Liquido_Diario,
      Relevo
    }

    // VALIDACIÓN: ¿Es contrato finiquitado?: afecta a partes adicionales que se deben mostrar del formulario
    if (Contrato_Finiquitado) {

      if(alerta === true){ 
        setStatusFechaFiniquito("error");
        setTextFechaFiniquito(mensajeAlerta);
      }
      const objFiniquito = {
        Fecha_Finiquito: (alerta === true ? null : moment.utc(Fecha_Finiquito)),
        Causal_Finiquito,
        Observaciones_Finiquito,
        Se_Puede_Recontratar,
      }

      objFormulario = { ...objFormulario, ...objFiniquito };

      setIsFiniquitado(true);
    }

    // VALIDACIÓN: ¿Es renovación de algún otro contrato? afectará a cómo se limitan las fechas
    if (Es_Anexo_De) {

      // fechaRenovación será utilizada sólo cuando isAnexo === true
      let fechaRenovacion = fechaInicioContrato.clone();

      setIsAnexo(true);
      setFechaRenovacion(fechaRenovacion);
    }
    setTipoFechaContrato(Tipo_Contrato);
    setEsRelevo(Relevo);



    
    ///nueva funcionalidad para validar la fecha de finiquito automatico
    let newFechaStringInicio = convertirFechaString(moment.unix(Contrato_Fecha_Inicio_Unix),1).fechaString;
    let newFechaStringFin;
    if(Contrato_Fecha_Fin != undefined && Contrato_Fecha_Fin != null && Contrato_Fecha_Fin != ""){
      newFechaStringFin =   convertirFechaString(moment.unix(Contrato_Fecha_Fin_Unix),2).fechaString;
    }


    setFechasAntiguas([newFechaStringInicio,newFechaStringFin]);
    
    setTipoContratoAntiguo(Tipo_Contrato);

    editarContratoFormRef.current.setFieldsValue(objFormulario);
  }, [idContrato]);

  const cambioFechas = async (fecha) =>{
    try{
      if(editarContratoFormRef.current != undefined){
        setLoading(true);
        let { Tipo_Contrato , Fecha_Finiquito} =  editarContratoFormRef.current.getFieldsValue();
        //validamos si esta finiquitado
        if(isFiniquitado){
          if(fecha !== undefined && fecha[0] !== undefined){
            if(comenzoCambio === false){ 
              setComenzoCambio(true);
            }
          }

          if(Fecha_Finiquito != undefined){
            if(Tipo_Contrato === "Plazo fijo"){
              let fechaInicio = convertirFechaString(fecha[0].clone(),1).fechaString;
              let fechaFin = convertirFechaString(fecha[1].clone(),1).fechaString;
              let fechaFiniquito = convertirFechaString(Fecha_Finiquito.clone(),1).fechaString;

              if(!moment(fechaFiniquito).isBetween(moment.utc(fechaInicio),moment.utc(fechaFin),null,"[]")){
                setStatusFechaFiniquito("error");
                setTextFechaFiniquito("Debe ingresar nueva fecha de finiquito");
              }else{
                setStatusFechaFiniquito("success");
                setTextFechaFiniquito("Fecha correcta");
              }

            }else{
              let fechaInicio = convertirFechaString(fecha.clone(),1).fechaString;
              let fechaFiniquito = convertirFechaString(Fecha_Finiquito.clone(),1).fechaString;

              if(!moment(fechaFiniquito).isSameOrAfter(moment.utc(fechaInicio))){
                setStatusFechaFiniquito("error");
                setTextFechaFiniquito("Debe ingresar nueva fecha de finiquito");
              }else{
                setStatusFechaFiniquito("success");
              }
            }

          }else{
            setTextFechaFiniquito("Debe ingresar nueva fecha de finiquito");
          }
        }

        const { Empresa , IdTrabajador } = props.contratoSeleccionado;
        let body = {
          ID_TRABAJADOR: IdTrabajador,
          TIPO_CONTRATO: Tipo_Contrato,
          TIPO_CONTRATO_ANTIGUO: tipoContratoAntiguo
        };
        //datos antiguos
        if(tipoContratoAntiguo === "Plazo fijo"){
          body.INICIO_PLANIFICACION = fechasAntiguas[0];
          body.FIN_PLANIFICACION = fechasAntiguas[1];
        }else{
          body.INICIO_PLANIFICACION = fechasAntiguas;
        }
        //datos nuevos
        if(Tipo_Contrato === "Plazo fijo"){
          body.NUEVO_INICIO_PLANIFICACION = convertirFechaString(fecha[0].clone(),1).fechaString;
          body.NUEVO_FIN_PLANIFICACION = convertirFechaString(fecha[1].clone(),2).fechaString;
        }else{
          body.NUEVO_INICIO_PLANIFICACION = convertirFechaString(fecha.clone(),1).fechaString;
        }

        const respuesta = await validarModificacionFechasContrato(body, Empresa);
        switch (respuesta.status) {
          case 200:
            setStatusFechaContrato("success");
            break;
          case 409:
            setStatusFechaContrato("error");
            Swal.fire({
              title: "Error",
              text: `No se puede editar en las fechas indicadas ya que hay planificación la cual no quedaria dentro del contrato`,
              icon: "error",
              confirmButtonColor: "#E60AA3",
            })

            break;
          default:
            setStatusFechaContrato("error");
            Swal.fire({
              title: "Error",
              text: `Error no controlado`,
              icon: "error",
              confirmButtonColor: "#E60AA3",
            });
            break;
        }
        setLoading(false);
      }
    }catch(error){
      setStatusFechaContrato("error");
      Swal.fire({
        title: "Error",
        text: `Error no controlado`,
        icon: "error",
        confirmButtonColor: "#E60AA3",
      });
      setLoading(false);
    }
  }

  const condicionalFechaContratoEditar = () => {

    let disabled = false;

    if (tipoFechaContrato === "Indefinido") {

      if (isAnexo) disabled = true;

      return (
        <DatePicker
          format="DD-MM-YYYY"
          locale={locale}
          style={{
            width: "100%",
          }}
          disabled={disabled}
          disabledDate={disabledDateContrato}
          onChange={(e)=> cambioFechas(e)}
        />
      );

    } else {

      if (isAnexo) disabled = [true, false];

      return (
        <RangePicker
          format="DD-MM-YYYY"
          locale={locale}
          style={{
            width: "100%",
          }}
          allowClear={false}
          disabled={disabled}
          disabledDate={disabledDateContrato}
          onChange={(e)=> cambioFechas(e)}
        />
      );
    }
  };

  const disabledDateContrato = (current) => {
    const { fechaFinUltimoContrato } = props;

    if (fechaFinUltimoContrato) {
      const limiteInicio = moment(fechaFinUltimoContrato).utc();

      return current < limiteInicio;
    } else {

      return false;
    }

  }

  const disabledDateFiniquitoNewDates = (current) =>{
    try{
      if(editarContratoFormRef.current !== null){
        let { Contrato_Fecha , Tipo_Contrato } =  editarContratoFormRef.current.getFieldsValue();
        let fechaActual = convertirFechaString(current,1).fechaString;
        let fechaInicioContrato;
        let fechaFinContrato;

        switch (Tipo_Contrato) {
          case "Plazo fijo":
              if(!Array.isArray(Contrato_Fecha) || Contrato_Fecha[0] == undefined || Contrato_Fecha[1] == undefined){
                return true;
              }

              fechaInicioContrato = convertirFechaString(moment.utc(Contrato_Fecha[0].format("YYYY-MM-DD")),1).fechaString;
              fechaFinContrato = convertirFechaString(moment.utc(Contrato_Fecha[1].format("YYYY-MM-DD")),2).fechaString;
              if(moment.utc(fechaActual).isBetween(moment.utc(fechaInicioContrato),moment.utc(fechaFinContrato),null,"[]")){
                return false;
              }
              return true;
          case "Indefinido":
              if(Contrato_Fecha == undefined){
                return true;
              }
              fechaInicioContrato = convertirFechaString(moment.utc(Contrato_Fecha.format("YYYY-MM-DD")),1).fechaString;
              if(moment.utc(fechaActual).isSameOrAfter(moment.utc(fechaInicioContrato))){
                return false;
              }
              return true;
          default:
            return true;
        }
      }
      return true;
    }catch(error){
      console.log('callo al catch disabledDateFiniquitoNewDates')
      return true;
    }
  }

  const cambioDeFechaFiniquito = async (dateSelected) => {
    try{
      if(dateSelected){
        setLoading(true);
        const { IdTrabajador } = props.contratoSeleccionado;
  
        const fechaFinFiniquito = convertirFechaString(dateSelected.clone(),2).fechaString;
        const planificacionesCortar = await finiquitarTrabajadorPlanificacionesAcortar(IdTrabajador,fechaFinFiniquito);

        setLoading(false);
        console.log(planificacionesCortar)
        if(planificacionesCortar.status !== 200){
          Swal.fire({
            title: "¡Ups!",
            text: "Error al realizar petición para identificar corte de planificaciones. Status: "+planificacionesCortar.status,
            icon: "error",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Ok",
          });
        }else{
          
          if(planificacionesCortar.data.PuedeFiniquitarEdicionFiniquito === false){
            Swal.fire({
              title: "¡Ups!",
              text: "No podra finiquitar en la fecha indicada. Esta dejando planificación posterior a la fecha del finiquito",
              icon: "error",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Ok",
            });
            setStatusFechaFiniquito("error");
          }else{
            setStatusFechaFiniquito("success");
          }
          
        } 
      }
    }catch(error){
      Swal.fire({
        title: "Error",
        text: `Error no controlado`,
        icon: "error",
        confirmButtonColor: "#1FC600",
      });
      setLoading(false);
    }
  }

  const renderSeccionFiniquito = () => {

    const { causalesFiniquito, disabledDateFiniquito } = props;

    return (
      <>
        <Divider>Sección finiquito</Divider>

        <Form.Item
          name={"Fecha_Finiquito"}
          label="Fecha de finiquito"
          style={{ marginTop: "20px" }}
          rules={[
            {
              required: true,
              message: "Debe ingresar la fecha de finiquito",
            },
          ]}
          hasFeedback 
          validateStatus={statusFechaFiniquito}
          help={textFechaFiniquito}
        >
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            locale={locale}
            className="campos-formulario-contratos"
            onChange={cambioDeFechaFiniquito}
            disabledDate={comenzoCambio === false ? disabledDateFiniquito : disabledDateFiniquitoNewDates}
          />
        </Form.Item>

        <Form.Item
          name={"Causal_Finiquito"}
          label="Causal de finiquito"
          style={{ marginTop: "20px" }}
          rules={[
            {
              required: true,
              message: "Debe ingresar la causal de finiquito",
            },
          ]}
        >
          <Select
            placeholder="Seleccionar causal"
            className={'filtro-desviaciones'}
          >
            {causalesFiniquito.map((value, index) => (
              <Select.Option key={index} value={`[${value.codigo}] ${value.nombre}`}>
                [{value.codigo}] {value.nombre}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={"Se_Puede_Recontratar"}
          label={
            <p style={{ margin: "0px", lineHeight: '14px' }}>
              ¿Se recomienda volver a
                        <br />
                        contratar al trabajador?
                      </p>
          }
          style={{ marginTop: "20px" }}
          rules={[
            {
              required: true,
              message: "Obligatorio",
            },
          ]}
        >
          <Radio.Group
            // style={{ display: "flex" }}
            onChange={(e) => {
            }}
          >
            <Radio value={true}>Sí</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name={"Observaciones_Finiquito"}
          label="Observaciones"
          style={{ marginTop: "20px" }}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <TextArea
            rows={4}
            className={'campos-formulario-contratos'}
            maxLength={1000}
            showCount
            autoSize={{ minRows: 4, maxRows: 4 }}
            placeholder={'(Opcional)'}
          />
        </Form.Item>

      </>
    )
  }

  const cerrarModal = () => {
    props.cerrarModalEditarContrato()
    editarContratoFormRef.current.resetFields();
  }

  // layout y tailLayout son configuaraciones de cols para el formulario y su responsividad
  const layout = {
    labelCol: {
      xs: 24,
      sm: 24,
      md: 10,
      lg: 8,
      xl: 6,
    },
    wrapperCol: {
      xs: 24,
      sm: 24,
      md: 12,
      lg: 14,
      xl: 16,
    },
  };

  const tailLayout = {
    wrapperCol: {
      xs: {
        offset: 0,
        span: 24,
      },
      sm: {
        offset: 0,
        span: 24,
      },
      md: {
        offset: 10,
        span: 12,
      },
      lg: {
        offset: 8,
        span: 14,
      },
      xl: {
        offset: 6,
        span: 16,
      },
    },
  };

  return (
    <div className={'maven-r'}>
      <Loading cargando={loading} />
      {/* HEADER */}
      <div className={'modal-header-wrapper'}>
        <PageHeader
          className="site-page-header"
          onBack={() => {
            cerrarModal();
          }}
          title="Editar Contrato"
          subTitle={<span>Modificar la información registrada del contrato seleccionado</span>}
          backIcon={<LeftCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={'#0141d074'} />}
        />
      </div>

      {/* FORMULARIO EDITAR CONTRATO */}
      <div style={{ marginLeft: '12px', marginRight: '12px' }}>

        <Form
          {...layout}
          name="editarContratoForm"
          onFinish={onFinish}
          ref={editarContratoFormRef}
        >
          <Form.Item
            name={"Tipo_Contrato"}
            label="Tipo de contrato"
            style={{ marginTop: "20px" }}
            rules={[
              {
                required: true,
                message: "Obligatorio",
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => {

                const val = e.target.value;

                let formObj = {
                  Contrato_Fecha: null,
                }

                if (isAnexo) {
                  if (val === 'Plazo fijo') {
                    formObj.Contrato_Fecha = [fechaRenovacion, null];

                  } else {
                    formObj.Contrato_Fecha = fechaRenovacion
                  }
                }

                editarContratoFormRef.current.setFieldsValue(formObj);

                setTipoFechaContrato(val);
                setComenzoCambio(true);

                setStatusFechaContrato("success");
              }}
            >
              <Radio value={"Plazo fijo"}>Plazo Fijo</Radio>
              <Radio value={"Indefinido"}>Indefinido</Radio>
            </Radio.Group>
          </Form.Item>
          {/* //nuevo campo relevo// */}
          <Form.Item
              name={"Relevo"}
              label="Relevo"
              style={{ marginTop: "20px" }}
              rules={[
                {
                  required: true,
                  message: "Obligatorio",
                },
              ]}
            >
              <Radio.Group
                style={{ display: "flex" }}
                onChange={(e) => {
                  setEsRelevo(e.target.value);
                }}
              >
                <Radio value={true}>Si</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            {/* //nuevo campo relevo// */}

          <Form.Item
            name={"Contrato_Fecha"}
            label="Fecha de contratación"
            rules={[
              {
                required: true,
                message: "Obligatorio",
              },
            ]}
            hasFeedback
            validateStatus={statusFechaContrato}
           
          >
            {condicionalFechaContratoEditar()}

          </Form.Item>

          <Form.Item
            name={"Costo_Bruto_Diario"}
            label={<p style={{ margin: "0px" }}>Costo Bruto Diario</p>}
            rules={[
              {
                required: false,
                message: "Obligatorio",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              type="number"
              min={0}
              className="campos-formulario-contratos"
            />
          </Form.Item>

          <Form.Item
            name={"Costo_Liquido_Diario"}
            label={<p style={{ margin: "0px" }}>Costo Liquido Diario</p>}
            rules={[
              {
                required: false,
                message: "Obligatorio",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              type="number"
              min={0}
              className="campos-formulario-contratos"
            />
          </Form.Item>

          {isFiniquitado ? renderSeccionFiniquito() : <></>}

          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              form="editarContratoForm"
              className={'formulario-contratos-submit-btn'}
            >
              Editar contrato
          </Button>
          </Form.Item>

        </Form>

      </div>

    </div>
  );
};

export default FormularioEditarContrato;
