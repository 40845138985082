import React, { useEffect , useState } from "react";
import documento from "../../img/icons/documento.svg";
import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Radio,
} from "antd";

import API from "../../Redux/Api/api";

import Swal from "sweetalert2";

const FormularioTitulosEditar = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();
  const [validarNombreTitulo,setValidarNombreTitulo] = useState("");
  const [textoValidacion, setTextoValidacion] = useState("Debe ingresar un nombre con al menos 2 caracteres");

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {
    props.EditarTitulo(values);
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  useEffect(() => {
    let { Descripcion, Nombre, Vigencia, Expira } = props.Formulario;

    form.setFieldsValue({
      Descripcion,
      Nombre,
      Vigencia,
      Expira,
    });
  });

  const ejecutarValidacionNombreTitulo = async (valor) => {

    try{
      form.setFieldsValue({Nombre: valor});
      if(valor !== "" && valor !== undefined && valor.length >= 2){
        let usuario = localStorage.getItem("usuario-people-manage");
        if (
          usuario !== null &&
          usuario !== "" &&
          usuario !== undefined &&
          usuario !== "undefined"
        ) {
          usuario = JSON.parse(usuario);
          const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
          
          const url = `${API}/Titulos/Validar/Nombre?empresa=${idEmpresa}`;

          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({nombre: valor, ID_TITULO:props.Formulario._id}),
          });
          const status = response.status;
    
          const data = await response.json();
    
          switch (status) {
            case 200:
            case 201:

              if(data.encontrado === true){
                setValidarNombreTitulo("error");
                setTextoValidacion(data.mensaje);
              }else{
                setValidarNombreTitulo("success");
                setTextoValidacion("Debe ingresar un nombre con al menos 2 caracteres");
              }
    
              break;
    
            default:
              const error = data.mensaje.toString();
    
              Swal.fire({
                title: "Uups",
                text: "Error status: " + status + " ,detalle: " + error,
                icon: "error",
              });
              break;
          }
        }
      }else{
        setValidarNombreTitulo("error");
        setTextoValidacion("Debe ingresar un nombre con al menos 2 caracteres");
      }
    }catch(error){
      console.log(error)
      Swal.fire({
        title: "Uups",
        text: "Error no controlado",
        icon: "error",
      });
    }
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={documento} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Editar Título"
          />
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Form.Item
              name="Nombre"
              label="Nombre:"
              validateStatus={validarNombreTitulo}
              rules={[
                {
                  required: true,
                  message: "Debe introducir un nombre",
                  min: 2,
                },
              ]}
              hasFeedback
              help={textoValidacion}
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Nombre", valor);
                }}
                onBlur={(e) => {
                  if(e.target.value){
                    const valor = e.target.value.replace( /\s\s+/g, ' ' ).trim();

                    ejecutarValidacionNombreTitulo(valor);
                  }
                }}
                name="Nombre"
                className="campos"
              />
            </Form.Item>

            <Form.Item
              label="Descripción"
              name="Descripcion"
              rules={[
                {
                  min: 1,
                  required: true,
                  message: "Debe introducir una descripcion",
                },
              ]}
            >
              <Input
                name="Descripcion"
                className="campos"
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Descripcion", valor);
                }}
              />
            </Form.Item>
            <Form.Item
              name={"Expira"}
              rules={[
                {
                  required: true,
                  message: "Debe seleccionar si el título expira",
                },
              ]}
              label="¿El título expira?"
            >
              <Radio.Group
                style={{ display: "flex" }}
                key={"Expira"}
                value={props.Formulario.Expira}
              >
                <Radio value={true}>Si</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                checked={props.Formulario.Vigencia}
                onChange={() => {
                  let nuevoValor =
                    props.Formulario.Vigencia === true ? false : true;
                  props.TomarValoresEditar("Vigencia", nuevoValor);
                }}
                className="switch"
              />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Editar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormularioTitulosEditar;
