//REDUCER MANTENEDOR USUARIO
export const LOADING = "MANTENEDOR_TRABAJADOR_LOADING";
// export const PARAR_LOADING = "MANTENEDOR_TRABAJADOR_LOADING_PARAR";
export const ERROR = "MANTENEDOR_TRABAJADOR_ERROR";

export const LIMPIAR_ERROR_SUCCESS =
  "MANTENEDOR_TRABAJADOR_LIMPIAR_ERROR_SUCCESS";
export const INGRESAR_TRABAJADOR = "MANTENEDOR_TRABAJADOR_INGRESAR";
export const MOSTRAR_MODAL_TRABAJADOR = "MANTENEDOR_TRABAJADOR_MOSTRAR_MODAL";
export const OCULTAR_MODAL_TRABAJADOR = "MANTENEDOR_TRABAJADOR_OCULTAR_MODAL";
export const OCULTAR_MODAL_TRABAJADOR_EDITAR =
  "MANTENEDOR_TRABAJADOR_OCULTAR_MODAL_EDITAR";
  
export const OBTENER_TRABAJADORES_MANTENEDOR = "MANTENEDOR_TRABAJADORES_OBTENER_TRABAJADORES";

export const OBTENER_TRABAJADOR_MANTENEDOR =
  "MANTENEDOR_TRABAJADORES_OBTENER_TRABAJADOR";
export const ELIMINAR_TRABAJADOR_MANTENEDOR =
  "MANTENEDOR_TRABAJADORES_ELIMINAR_TRABAJADOR";
export const OBTENER_REGIMEN_TRABAJO = "MANTENEDOR_TRABAJADOR_REGIMEN_TRABAJO";
export const EDITAR_TRABAJADOR_MANTENEDOR = "MANTENEDOR_TRABAJADOR_EDITAR_ROL";
export const TOMAR_VALORES_EDITAR =
  "MANTENEDOR_TRABAJADOR_TOMAR_VALORES_EDITAR";

export const OBTENER_CARGOS_TRABAJADOR = "MANTENEDOR_TRABAJADOR_OBTENER_CARGOS";
export const OBTENER_ESTADOS_TRABAJADOR = "MANTENEDOR_TRABAJADOR_OBTENER_ESTADOS";

export const OBTENER_TITULOS_TRABAJADORES =
  "MANTENEDOR_TRABAJADOR_OBTENER_TITULOS_TRABAJADORES";
export const OBTENER_ESPECIALIDADES_TRABAJADORES =
  "MANTENEDOR_TRABAJADOR_OBTENER_ESPECIALIDADES_TRABAJADORES";
export const OBTENER_EPPS_TRABAJADORES =
  "OBTENER_EPPS_TRABAJADORES";


export const OBTENER_REGIONES_MANTENEDOR_TRABAJADOR =
  "OBTENER_REGIONES_MANTENEDOR_TRABAJADOR";

export const OBTENER_PROVINCIAS_MANTENEDOR_TRABAJADOR =
  "OBTENER_PROVINCIAS_MANTENEDOR_TRABAJADOR";
export const OBTENER_COMUNAS_MANTENEDOR_TRABAJADOR =
  "OBTENER_COMUNAS_MANTENEDOR_TRABAJADOR";

export const INSERTAR_PROVINCIAS_MANTENEDOR_TRABAJADOR =
  "INSERTAR_PROVINCIAS_MANTENEDOR_TRABAJADOR";

export const INSERTAR_COMUNAS_MANTENEDOR_TRABAJADOR =
  "INSERTAR_COMUNAS_MANTENEDOR_TRABAJADOR";


export const OBTENER_DOCUMENTOS_MANTENEDOR_TRABAJADOR =
  "OBTENER_DOCUMENTOS_MANTENEDOR_TRABAJADOR";
// export const DESCARGAR_EXCEL = "MANTENEDOR_USUARIO_DESCARGAR_EXCEL";
