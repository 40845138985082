import React, { useEffect } from "react";
import regimen from "../../img/icons/regimen.svg";
import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Checkbox,
  InputNumber
} from "antd";

import Swal from "sweetalert2";

const FormularioRegimenTrabajoEditar = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {

    console.log(values);
    if (values.Dias_De_Trabajo !== undefined && values.Dias_De_Trabajo !== "undefined" && Array.isArray(values.Dias_De_Trabajo)) {
      if (values.Dias_De_Trabajo.length !== 0) {

        let Dias_De_Trabajo_Arreglo = {
          "Lunes": values.Dias_De_Trabajo.includes('Lunes'),
          "Martes": values.Dias_De_Trabajo.includes('Martes'),
          "Miercoles": values.Dias_De_Trabajo.includes('Miercoles'),
          "Jueves": values.Dias_De_Trabajo.includes('Jueves'),
          "Viernes": values.Dias_De_Trabajo.includes('Viernes'),
          "Sabado": values.Dias_De_Trabajo.includes('Sabado'),
          "Domingo": values.Dias_De_Trabajo.includes('Domingo'),
          "Feriados": values.Dias_De_Trabajo.includes('Feriados')
        }
        values.Dias_De_Trabajo = Dias_De_Trabajo_Arreglo;

        let Dias_De_Descansos_Arreglo = {
          "Lunes": values.Dias_De_Descanso.includes('Lunes'),
          "Martes": values.Dias_De_Descanso.includes('Martes'),
          "Miercoles": values.Dias_De_Descanso.includes('Miercoles'),
          "Jueves": values.Dias_De_Descanso.includes('Jueves'),
          "Viernes": values.Dias_De_Descanso.includes('Viernes'),
          "Sabado": values.Dias_De_Descanso.includes('Sabado'),
          "Domingo": values.Dias_De_Descanso.includes('Domingo'),
          "Feriados": values.Dias_De_Descanso.includes('Feriados')
        }
        values.Dias_De_Descanso = Dias_De_Descansos_Arreglo;


        props.EditarRegimen(values);

      } else {
        Swal.fire({
          title: "Uuups...",
          text: "Se debe ingresar por lo menos un dia de trabajo",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
      }

    } else {
      Swal.fire({
        title: "Uuups...",
        text: "Se debe ingresar por lo menos un dia de trabajo",
        icon: "error",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Continuar",
      });
    }

  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  useEffect(() => {
    let {
      Descripcion,
      Nombre,
      Vigencia,
      Cantidad_Dias_Trabajo,
      Cantidad_Dias_Descanso,
    } = props.Formulario;

    console.log(props.Formulario.Dias_De_Trabajo[0]);
    let Dias_De_Trabajo = [];

    if (props.Formulario.Dias_De_Trabajo[0].Lunes) {
      Dias_De_Trabajo.push("Lunes");
    }

    if (props.Formulario.Dias_De_Trabajo[0].Martes) {
      Dias_De_Trabajo.push("Martes");
    }

    if (props.Formulario.Dias_De_Trabajo[0].Miercoles) {
      Dias_De_Trabajo.push("Miercoles");
    }

    if (props.Formulario.Dias_De_Trabajo[0].Jueves) {
      Dias_De_Trabajo.push("Jueves");
    }

    if (props.Formulario.Dias_De_Trabajo[0].Viernes) {
      Dias_De_Trabajo.push("Viernes");
    }

    if (props.Formulario.Dias_De_Trabajo[0].Sabado) {
      Dias_De_Trabajo.push("Sabado");
    }
    if (props.Formulario.Dias_De_Trabajo[0].Domingo) {
      Dias_De_Trabajo.push("Domingo");
    }
    if (props.Formulario.Dias_De_Trabajo[0].Feriados) {
      Dias_De_Trabajo.push("Feriados");
    }

    let Dias_De_Descanso = [];

    if (props.Formulario.Dias_De_Descanso[0].Lunes) {
      Dias_De_Descanso.push("Lunes");
    }

    if (props.Formulario.Dias_De_Descanso[0].Martes) {
      Dias_De_Descanso.push("Martes");
    }

    if (props.Formulario.Dias_De_Descanso[0].Miercoles) {
      Dias_De_Descanso.push("Miercoles");
    }

    if (props.Formulario.Dias_De_Descanso[0].Jueves) {
      Dias_De_Descanso.push("Jueves");
    }

    if (props.Formulario.Dias_De_Descanso[0].Viernes) {
      Dias_De_Descanso.push("Viernes");
    }

    if (props.Formulario.Dias_De_Descanso[0].Sabado) {
      Dias_De_Descanso.push("Sabado");
    }
    if (props.Formulario.Dias_De_Descanso[0].Domingo) {
      Dias_De_Descanso.push("Domingo");
    }
    if (props.Formulario.Dias_De_Descanso[0].Feriados) {
      Dias_De_Descanso.push("Feriados");
    }

    console.log(Dias_De_Descanso);

    form.setFieldsValue({
      Descripcion,
      Nombre,
      Cantidad_Dias_Trabajo: Cantidad_Dias_Trabajo.toString(),
      Cantidad_Dias_Descanso: Cantidad_Dias_Descanso.toString(),
      Dias_De_Descanso,
      Vigencia,
      Dias_De_Trabajo
    });
  });

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={regimen} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Editar Regimen de trabajo"
          />
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Form.Item
              name="Nombre"
              label="Nombre:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir un nombre con un máximo de 40 caracteres",
                  min: 1,
                  max: 40,
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Nombre", valor);
                }}
                name="Nombre"
                className="campos"
              />
            </Form.Item>
            <Form.Item
              label="Descripción"
              name="Descripcion"
              rules={[
                {
                  min: 1,
                  required: true,
                  message: "Debe introducir una descripción",
                },
              ]}
              hasFeedback
            >
              <Input
                name="Descripcion"
                className="campos"
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Descripcion", valor);
                }}
                placeholder="Descripción del régimen"
              />
            </Form.Item>
            <Form.Item
              name="Cantidad_Dias_Trabajo"
              style={{ textAlign: "left" }}
              value={props.Formulario.Cantidad_Dias_Trabajo}
              label=" Cantidad de días trabajados"
              rules={[
                {
                  //min: 1,
                  required: true,
                  message: "Debe introducir los dias de trabajo",
                },
              ]}
              hasFeedback
            >
              <InputNumber
                value={props.Formulario.Cantidad_Dias_Trabajo}
                name="Cantidad_Dias_Trabajo"
                min={0} max={100}
              // onChange={(e) => {
              //   let valor = e.target.value;
              //   const isInteger = /^[0-9]+$/;
              //   console.log(isInteger.test(e.target.value));
              //   if (e.target.value === "" || isInteger.test(e.target.value)) {
              //     props.TomarValoresEditar("Cantidad_Dias_Trabajo", valor);
              //   }
              // }}
              />
            </Form.Item>
            <Form.Item
              label="Cantidad de días de descanso"
              name="Cantidad_Dias_Descanso"
              style={{ textAlign: "left" }}
              rules={[
                {
                  //min: 1,
                  required: true,
                  message: "Debe introducir los dias de descanso",
                },
              ]}
              hasFeedback
            >
              <InputNumber
                name="Cantidad_Dias_Descanso"
                min={0} max={100}
              // onChange={(e) => {
              //   // let valor = e.target.value;
              //   //props.TomarValoresEditar("Cantidad_Dias_Descanso", valor);
              // }}
              />
            </Form.Item>


            <Form.Item style={{ textAlign: "right" }}>
              <h3>¿Qué días trabajará?</h3>
            </Form.Item>
            <Form.Item
              name="Dias_De_Trabajo"
              style={{ textAlign: "left" }} label=".">
              <Checkbox.Group
                name="Dias_De_Trabajo"
                onChange={onChange}>
                <Row>
                  <Col span={7}>
                    <Checkbox checked={props.Formulario.Dias_De_Trabajo[0].Lunes} value="Lunes">Lunes</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox checked={props.Formulario.Dias_De_Trabajo[0].Martes} value="Martes">Martes</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox checked={props.Formulario.Dias_De_Trabajo[0].Miercoles} value="Miercoles">Miércoles</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox checked={props.Formulario.Dias_De_Trabajo[0].Jueves} value="Jueves">Jueves</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox checked={props.Formulario.Dias_De_Trabajo[0].Viernes} value="Viernes">Viernes</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox checked={props.Formulario.Dias_De_Trabajo[0].Sabado} value="Sabado">Sábado</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox checked={props.Formulario.Dias_De_Trabajo[0].Domingo} value="Domingo">Domingo</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox checked={props.Formulario.Dias_De_Trabajo[0].Feriados} value="Feriados">
                      ¿Cuenta los feriados?
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <Form.Item style={{ textAlign: "right" }}>
              <h3>¿Qué días descansará?</h3>
            </Form.Item>
            <Form.Item
              name="Dias_De_Descanso"
              style={{ textAlign: "left" }} label=".">
              <Checkbox.Group
                name="Dias_De_Descanso"
                onChange={onChange}>
                <Row>
                  <Col span={7}>
                    <Checkbox value="Lunes">Lunes</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Martes">Martes</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Miercoles">Miércoles</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Jueves">Jueves</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Viernes">Viernes</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Sabado">Sábado</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Domingo">Domingo</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Feriados">
                      ¿Cuenta los feriados?
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item label="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                checked={props.Formulario.Vigencia}
                onChange={() => {
                  let nuevoValor =
                    props.Formulario.Vigencia === true ? false : true;
                  props.TomarValoresEditar("Vigencia", nuevoValor);
                }}
                className="switch"
              />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Editar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormularioRegimenTrabajoEditar;
