import React, { Component } from "react";

import API from "../../Redux/Api/api";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import "moment/locale/es";
import { convertirFechaString} from "../../funcionesGenericas/fechas";
import Swal from "sweetalert2";
import { EyeFilled } from "@ant-design/icons";
import Excel from "../../img/icons-sidebar/blancas/IconExcel.svg";
import Loading from "../../components/loading/Loading";

import {
  Row,
  Col,
  Card,
  Select,
  Button,
  DatePicker,
  Progress,
  Modal,
  Table,
  Tabs,
  Empty,
  Input,
  Tooltip
} from "antd";
const { Search } = Input;
const { Column } = Table;
const { TabPane } = Tabs;
class DashboardRegimenTrabajo extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          dataGrafico:[],
          colores:["#7C8DFC","#52D68F","#FAD868","#E64040","#EF7720","#3CADF0"],
          Empresa: '',
          fechaSelecionada: moment().format('YYYY-MM-DD'),
          cargoSeleccionado: "",
          centroDeCostoSeleccionado: "",
          modalDetalle:false,
          dataDetalle:[],
          indexActual: "1",
          trabajadoresCopia: undefined
        };

    }

    componentDidMount = async () => {
        let usuario = localStorage.getItem("usuario-people-manage");
        if (
          usuario !== null &&
          usuario !== "" &&
          usuario !== undefined &&
          usuario !== "undefined"
        ) {
          const { colores } = this.state;
          usuario = JSON.parse(usuario);
          const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
          const str = moment().format('YYYY-MM-DD');
          const fechaActual = convertirFechaString(moment(str), 1).fechaString;
          const centroDeCosto = "";
          const cargo = "";

          let dataRegimenTrabajo = await this.obtenerDataRegimenTrabajo(idEmpresa,fechaActual,centroDeCosto,cargo,fechaActual);
          
          dataRegimenTrabajo.data.forEach(data => {
            data.color = colores[Math.floor(Math.random()*(colores.length-1))];
            //this.state.colores[Math.floor(Math.random()*(this.state.colores.length-1))]
          });
          this.setState({
            //dataGrafico: dataRegimenTrabajo.data,
            dataGrafico: dataRegimenTrabajo === undefined ? [] : dataRegimenTrabajo.data,
            Empresa: idEmpresa,
            indexActual: "1",
          });
    
        }
    };


    obtenerDataRegimenTrabajo = async (
        idEmpresa,
        fecha,
        centroDeCosto,
        cargo,
        fechaHoy
      ) => {
        try {
          const url = `${API}/TrabajadoresPorRegimenNuevo`;
          let objeto = {
            idEmpresa,
            fecha,
            centroDeCosto,
            cargo,
            fechaHoy
          };
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(objeto),
          });
    
          const status = await response.status;
          const data = await response.json();
    
          if (status === 200 || status === 201) {
            return data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Uuups...",
              text: "No se pudo cargar la información.",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
        }
    };

    setFecha = (fecha) => {
      let str = moment(fecha).format('YYYY-MM-DD')
      let fechaFormateada = convertirFechaString(moment(str), 1).fechaString
      
      this.setState({
          fechaSelecionada: fechaFormateada
      });
    };
    
    setCentroCosto = (centroCosto) => {
        this.setState({
            centroDeCostoSeleccionado: centroCosto
        });
    };

    setCargo = (cargo) => {
        this.setState({
            cargoSeleccionado: cargo
        });
    };

    filtrar = async(objeto) => {
      try {
        this.setState({ loading: true });
        const url = `${API}/TrabajadoresPorRegimenNuevo`;
        const {cargo,centroCosto,fecha} = objeto;
        const { Empresa: idEmpresa } = this.state;
        let objetoBody = {
          idEmpresa: idEmpresa,
          fecha,
          centroDeCosto: centroCosto,
          cargo,
          fechaHoy:fecha
        };
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objetoBody),
        });
  
        const status = await response.status;
        const data = await response.json();
  
        if (status === 200 || status === 201) {
          const { colores } = this.state;
          data.data.forEach(data => {
            data.color = colores[Math.floor(Math.random()*(colores.length-1))];
          });
          
          this.setState({
            dataGrafico: data.data,
            loading: false,
          });
          //return data;
          
        } else {
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        // Swal.fire({
        //   icon: "error",
        //   title: "Uuups...",
        //   text: "No se pudo cargar la información.",
        //   confirmButtonColor: "#E60AA3",
        //   confirmButtonText: "OK",
        // });
      }
    
    };

    obtenerDetalleDataRegimenTrabajo = async (
        idEmpresa,
        fecha,
        centroDeCosto,
        cargo,
        fechaHoy
    ) => {
        try {
            const url = `${API}/TrabajadoresPorRegimenNuevo/Detalle`;
            let objeto = {
                idEmpresa,
                fecha,
                centroDeCosto,
                cargo,
                fechaHoy
            };
            const response = await fetch(url, {
                method: "POST",
                mode: "cors",
                headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                },
                body: JSON.stringify(objeto),
            });
    
            const status = response.status;
            let data;
    
            switch (status) {
                case 200:
                case 201:
                    data = await response.json();
                    let detalle = data.data;
                    
                    return detalle;
                default:
                    //const error = data.err.message.toString();
                    const error = "Error al cargar la tabla"
                    console.error(error);
                return [];
            }
    
        } catch (error) {
            console.error(error);
        }

        return [];
    };

    detalle = async () => {
      this.setState({ loading: true });
      const { Empresa, fechaSelecionada,centroDeCostoSeleccionado,cargoSeleccionado } = this.state;

      const idEmpresa = Empresa;
      const fechaActual = convertirFechaString(moment(fechaSelecionada), 1).fechaString;
      const centroDeCosto = centroDeCostoSeleccionado;
      const cargo = cargoSeleccionado;
      
      const detalleRegimenTrabajo  = await this.obtenerDetalleDataRegimenTrabajo(idEmpresa,fechaActual,centroDeCosto,cargo,fechaActual);

      this.setState({
        loading:false,
        modalDetalle: true,
        dataDetalle: detalleRegimenTrabajo,
        indexActual: "1",
      });
    }

    //API y EXCEL DESCARGABLE REGIMEN DE TRABAJO
    obtenerExcelDetalleRegimenTrabajo = async (
      informacionDescargar,subtitulo
     ) => {
       try {
        const url = `${API}/TrabajadoresPorRegimen/DescargarExcel`;
        let dataArray = [];
        
        informacionDescargar.forEach(dataTrabajador => {
        dataArray.push(
          {
            NombreCompletoTratado: dataTrabajador.NombreCompletoTratado,
            CentroCostoTratado:	dataTrabajador.CentroCostoTratado,
            FechaInicioTratado:	dataTrabajador.FechaInicioTratado,
            FechaFinTratado: dataTrabajador.FechaFinTratado
          }
        );
        });
    
        let objeto = {
        dataRegimenes: dataArray,
        subtitulo
        };

        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objeto),
        });
  
        const status = await response.status;
        const excel = await response.blob();
  
        if (status === 200 || status === 201) {
          const url = window.URL.createObjectURL(excel);
          const a = document.createElement("a");
          a.href = url;
          a.download = `formatoDetalleCostos.xlsx`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          Swal.fire({
            title: "Bien",
            text: "Se descargó excel correctamente.",
            icon: "success",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
        }
       } catch (error) {
         console.log(error);
         Swal.fire({
           icon: "error",
           title: "Uuups...",
           text: "No se pudo cargar la información.",
           confirmButtonColor: "#E60AA3",
           confirmButtonText: "OK",
         });
       }
    };

     //Search BTN
    search = (value, index) => {
      let busqueda = value.target.value;
  
      if (busqueda !== "") {
        busqueda = busqueda.toLowerCase();
        let datamodal = JSON.parse(JSON.stringify(this.state.dataDetalle));
        const trabajadores = datamodal[index].trabajadoresArray;
        
        const trabajadoresFiltros = trabajadores.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(busqueda)
          )
        );
  
        // datamodal[index].arregloUnificado = trabajadoresFiltros;
        let newData = {
          trabajadoresArray:  trabajadoresFiltros
        }

        this.setState({ 
          trabajadoresCopia: newData
        });

      } else {
        this.setState({ trabajadoresCopia: undefined });
      }
    };

    NuevaPestaña = (idUsuario) => {
      //window.open("/CalendarioTrabajador?Trabajador=" + idUsuario);
      window.open("/CalendarioTrabajadorNew?Trabajador=" + idUsuario);
    };
  
    render() {
        let {
            cargosEmpresa,
            centrosCostoEmpresa
        } = this.props;
        let { dataGrafico,modalDetalle,dataDetalle,indexActual,trabajadoresCopia,loading } = this.state;
      
      return (
        <>
         <Loading cargando={loading} />
            {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}> */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Card
                    className="card-style-dashboard card-title-dashboard"
                    title={
                        <>
                        <Row>
                            <Col
                            xs={24}
                            sm={24}
                            md={20}
                            lg={20}
                            xl={20}
                            // style={{textAlign:"center"}}
                            >
                            Trabajadores por régimen de trabajo
                            </Col>
                            <Col
                            xs={24}
                            sm={24}
                            md={4}
                            lg={4}
                            xl={4}
                            style={{textAlign:"right"}}
                            >
                           <Button
                                type="primary"
                                className="btn-detalle-dashboard"
                                onClick={() => {
                                    this.detalle()
                                }}
                            >
                                <span className="text-detalle-dashboard">Detalle</span>
                                <span><EyeFilled className="iconoBotonDetalleCurvaRelevos"/></span>
                            </Button>
                            </Col>
                        </Row>
                        </>
                    }
                    style={{ marginTop: "25px" }}
                >
                    {/* FILTROS */}
                    <div>
                      <p className={"label-filtros-dashboard"}>
                        Filtrar por:
                      </p>
                    </div>
                    <Row gutter={20} style={{ marginBottom: "20px" }}>
                        {/* RANGEPICKER */}
                        <Col
                            xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                            style={{ marginBottom: "15px" }}
                        >
                            <DatePicker
                                format={'DD-MM-YYYY'}
                                allowClear={false}
                                className={"select-style-dashboard"}
                                locale={locale}
                                picker="day"
                                defaultValue={moment()}
                                onChange={(fecha) => {
                                    if (fecha == null) {
                                    this.setFecha(moment());
                                    } else {
                                    this.setFecha(fecha);
                                    }
                                }}
                            />
                        </Col>

                        {/* CENTROS DE COSTO */}
                        <Col
                            xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                            style={{ marginBottom: "15px" }}
                        >
                            <Select
                                allowClear
                                className={"select-style-dashboard"}
                                placeholder="Centro de costo"
                                onSelect={(idCentroCosto) => {
                                    this.setCentroCosto(idCentroCosto);
                                }}
                                onClear={() => {
                                    this.setCentroCosto("");
                                }}
                            >
                                {   
                                    centrosCostoEmpresa.map((value, index) => (
                                        <Select.Option key={index} value={value._id} name="CentroCosto">
                                            {value.Nombre}
                                        </Select.Option>
                                    ))
                                }
                    
                            </Select>
                        </Col>

                        {/* CARGOS */}
                        <Col
                            xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                            style={{ marginBottom: "15px" }}
                        >
                            <Select
                                allowClear
                                className={"select-style-dashboard"}
                                placeholder="Cargo"
                                onSelect={(cargo) => {
                                    this.setCargo(cargo);
                                }}
                                onClear={() => {
                                    this.setCargo("");
                                }}
                            >

                                {
                                    cargosEmpresa.map((value, index) => (
                                        <Select.Option key={index} value={value._id} name="Cargo">
                                            {value.Nombre}
                                        </Select.Option>
                                    ))
                                }

                            </Select>
                        </Col>

                        {/* BTN FILTRAR */}
                        <Col
                            xs={24} s={24} sm={5} md={5} lg={5} xl={5} xxl={3}
                            className={"btn-col-filtrar-dashboard "}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                className={"btn-filtrar-dashboard"}
                                onClick={() => {
                                    const { fechaSelecionada, cargoSeleccionado, centroDeCostoSeleccionado} = this.state;
                                    const objeto =
                                    {
                                        fecha: fechaSelecionada,
                                        cargo: cargoSeleccionado,
                                        centroCosto: centroDeCostoSeleccionado,
                                    } 
                                    this.filtrar(objeto);
                                }}
                            >
                                Filtrar
                            </Button>
                        </Col>
                
                    </Row>
                  
                    {/*Grafico*/}
                    <Row>

                        <Col span={24}>
                        <div   
                            style={{
                                //width: "100%",
                                height: "250px",
                                overflowX: "hidden",
                                overflowY:"scroll"
                                
                            }}
                        >
                            {
                           
                                dataGrafico.map((data)=>

                                    <Col
                                      className="card-regimen-trabajadores-dashboard"
                                    >
                                        <Col span={23} style={{marginLeft:"10px"}}>
                                            <Row>
                                                <Col span={12}>  {`${data.type}`}</Col>
                                                <Col span={12} style={{textAlign:'right'}}> {`(${data.value})`}</Col>
                                            </Row>
                       
                                            <Progress percent={data.porcent} status="active" showInfo={false} strokeColor={data.color} />
                                        </Col>
                                        
                                    </Col>
                                   
                                )
                             
                            }
                        </div>
                        </Col>
               
                    </Row>

                </Card>
            </Col>

            <Modal
                visible={modalDetalle}
                onCancel={() => this.setState({modalDetalle: false})}
                className={'modal-detalle-dashboard'}
                footer={null}
                closable={false}
                maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
            >
                <Row  style={{ marginBottom: '20px' }}>
                    <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
                        <h3
                          className="modal-h3-dashboard"
                        >
                          Trabajadores por regimen de trabajo
                        </h3>
                    </Col>

                    <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{textAlign:"right"}}>
                      <Button
                        type="primary"
                        className={'modal-btn-excel-dashboard'}
                        onClick={()=>{
                          let { indexActual,dataDetalle } = this.state;
                          let index = parseInt(indexActual)-1;
                          
                          const dataDescargar = 
                          dataDetalle[index].trabajadoresArray !== null &&
                          dataDetalle[index].trabajadoresArray !== 0 ?
                          dataDetalle[index].trabajadoresArray : [];
                          this.obtenerExcelDetalleRegimenTrabajo(dataDescargar,dataDetalle[index].type);
              
                        }}
                      >
                        <span className="modal-btn-text-excel-dashboard">Descargar Excel</span>
                        <span><img src={Excel} className="modal-btn-icon-excel-dashboard" alt="Icono Excel"/></span>
                      </Button>
                    </Col>
                </Row>

                <hr className="modal-division-detalle-dashboard" />

                {/* TABLE */}
                <Row>
                  <Col className="modal-tabla-detalle-dashboard" span={24}>
                    <Tabs key={indexActual} defaultActiveKey={indexActual} activeKey={indexActual}  onTabClick={(e)=>{this.setState({ indexActual: e, trabajadoresCopia: undefined})}}>
                      {dataDetalle.length > 0 ? (
                        <>
                          {dataDetalle.map(
                            (valor, index) => (
                              <TabPane
                                tab={
                                  valor.type + 
                                  ` (${valor.trabajadoresArray.length})`
                                }
                                key={index + 1}
                            
                              >
                                {valor.trabajadoresArray.length > 0 ? (
                                  <>
                                    <Row style={{ padding: "6px 0px 22px" }} align="bottom">
                                      <Col className="div-input-search" xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                        <Search
                                          placeholder="Buscar"
                                          enterButton
                                          onChange={(value) => this.search(value, index)}
                                        />
                                      </Col>
                                    </Row>

                                    <Table
                                      key={index + 1}
                                      rowKey="_id"
                                      dataSource={
                                        // dataDetalle[index] !== undefined ?
                                        // dataDetalle[index].trabajadoresArray : valor.trabajadoresArray   trabajadoresCopia === undefined ?
                                        trabajadoresCopia === undefined ?
                                        valor  !== undefined ?
                                        valor.trabajadoresArray  : []
                                        :
                                        trabajadoresCopia.trabajadoresArray
                                      }
                                      bordered={true}
                                      showSorterTooltip={false}
                                      scroll={{ x: true }}
                                      pagination={{ pageSize: 10 }}
                                      className={'modal-tabla-detalle-dashboard'}
                                      locale={{
                                        emptyText:
                                          "No se encontraron registros de la tabla.",
                                      }}
                                    >
                                        <Column
                                            align="center"
                                            title="Imagen"
                                            key="Imagen"
                                            render={(value) => {
                                                return (
                                                <img
                                                    src={value.Imagen}
                                                    className="modal-img-tabla-dashboard"
                                                    alt="i"
                                                />
                                                );
                                            }}
                                        />

                                        <Column
                                            sorter={(a, b) => {
                                                return a.Nombre.localeCompare(b.Nombre);
                                            }}
                                            title="Nombre"
                                            key="NombreCompletoTratado"
                                            render={(value) => {
                                                return `${value.NombreCompletoTratado}`;
                                            }}
                                        />
                                        {/* Centro de costo */}

                                        <Column
                                            sorter={(a, b) => {
                                                return a.CentroCostoTratado.localeCompare(b.CentroCostoTratado);
                                            }}
                                            title="Centro de costo"
                                            key="CentroCostoTratado"
                                            render={(value) => {
                                                let cc =
                                                "Aun no se ingresa tipo contrato";
                                                if (
                                                value.CentroCostoTratado !== undefined &&
                                                value.CentroCostoTratado !== "undefined"
                                                ) {
                                                    cc = value.CentroCostoTratado;
                                                }

                                                return `${cc}`;
                                            }}
                                        />

                                        <Column
                                            sorter={(a, b) => {
                                                const fechaInicioa = a.FechaInicioTratado === 'N/A' ? new Date('1900-01-01') : new Date(a.FechaInicioTratado.split('-').reverse().join('-')) ;
                                                const fechaIniciob = b.FechaInicioTratado === 'N/A' ? new Date('1900-01-01') : new Date(b.FechaInicioTratado.split('-').reverse().join('-'));
                                                return fechaIniciob - fechaInicioa
                                            }}
                                            title="Fecha inicio"
                                            key="FechaInicio"
                                            render={(value) => {
                                              return `${value.FechaInicioTratado}`;
                                            }}
                                        />

                                        <Column  
                                          sorter={(a, b) => {
                                            const fechaFina = a.FechaFinTratado === 'N/A' ? new Date('1900-01-01') : new Date(a.FechaFinTratado.split('-').reverse().join('-'));
                                            const fechaFinb = b.FechaFinTratado === 'N/A' ? new Date('1900-01-01') : new Date(b.FechaFinTratado.split('-').reverse().join('-'));
                                            return fechaFinb - fechaFina
                                          }}
                                          title="Fecha fin"
                                          key="FechaFin"
                                          render={(value) => {
                                            return `${value.FechaFinTratado}`;
                                          }}
                                        />

                                        {/* Ficha trabajador */}
                                        <Column
                                          title="Detalle"
                                          key="FichaTrabajador"
                                          align="center"
                                          render={(value) => (
                                            <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                                              <Button
                                                onClick={() => {
                                                  this.NuevaPestaña(value._id);
                                                }}
                                                className="acciones-trabajador-button hover-azul"
                                                icon={<EyeFilled style={{ fontSize: '18px' }} />}
                                              />
                                            </Tooltip>
                                          )}
                                        />

                                    </Table>
                                  </>
                                ) : (
                                  <>
                                    <Row>
                                      <Col span={24}>
                                        <Empty
                                          imageStyle={{
                                            height: 200,
                                            marginTop: "20px",
                                          }}
                                          description={
                                            <b
                                              className={
                                                "seleccion-trabajador-disponible"
                                              }
                                            >
                                              No se encontro información de este
                                              trabajador con este cargo.
                                            </b>
                                          }
                                        ></Empty>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </TabPane>
                            )
                          )}
                        </>
                      ) : (
                        <TabPane tab={"No se encontro información"} key={1}>
                          <>
                            <Row>
                              <Col span={24}>
                                <Empty
                                  imageStyle={{
                                    height: 200,
                                    marginTop: "20px",
                                  }}
                                  description={
                                    <b
                                      className={
                                        "modal-empty-b-dashboard"
                                      }
                                    >
                                      No se encontró información de trabajadores.
                                    </b>
                                  }
                                ></Empty>
                              </Col>
                            </Row>
                          </>
                        </TabPane>
                      )}
                    </Tabs>
                  </Col>
                </Row>
                
            </Modal>
        </>
      );
    }
}

export default DashboardRegimenTrabajo;