import {
    ERROR_ASISTENCIA,
    LOADING_ASISTENCIA,
    TRAER_CENTROS_DE_COSTO,
    TRAER_PERSONAS_PENDIENTES,
    CONFIRMAR_ASISTENCIA,
    MOSTRA_TABLA_ASISTENCIA_TIMELINE
} from '../types/asistenciaTimeLineType';

const INITIAL_STATE = {
    loading: false,
    error: null,
    centros_de_costo: [],
    personasPendientes: [],
    grupos:[],
    items:[],
    mostrarCalendario:false,
}

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case ERROR_ASISTENCIA:
            return {
                ...state,
                error: payload,
                loading: false
            }
        case LOADING_ASISTENCIA:
            return {
                ...state,
                loading: true,
            }
        case CONFIRMAR_ASISTENCIA:
            return {
                ...state,
                loading: false,
                error: null
            }
        case TRAER_CENTROS_DE_COSTO:
            return {
                ...state,
                centros_de_costo: payload,
                error: null,
                loading: false
            }
        case TRAER_PERSONAS_PENDIENTES:
            let mostrarCalendario = false;

            if(payload.grupos.length !== 0){
                mostrarCalendario = true;
            }

            return {
                ...state,
                loading: false,
                error: null,
                grupos: payload.grupos,
                items: payload.items,
                mostrarCalendario: mostrarCalendario,
            }

        case MOSTRA_TABLA_ASISTENCIA_TIMELINE:
            return {
                ...state,
                mostrarCalendario: true,
                loading: false,
            }
        
        default:
            return state;
    }
};