import React, { useEffect, useState } from "react";
import SubirFoto from "../../components/subirFoto/SubirFoto";
import logoGenerico from "../../img/logos/logoGenerico.svg";
import Rut from "../../funcionesGenericas/rut";
import iconBorrar from "../../img/icons/basurero-rosado.svg";
import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Select,
  //Radio
} from "antd";
import Swal from "sweetalert2";
import ValidadorRut from "validar-rut";
const Demo = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();
  const [validRutUsuario, setValidRutUsuario] = useState("");

  const ObtenerImagen = (imagenUrl) => {
    props.obtenerImagen(imagenUrl);
    props.TomarValoresEditar("Imagen", imagenUrl);
  };

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {
    if (props.Formulario.Imagen === null) {
      Swal.fire({
        title: "Uuups...",
        text: "Debes escoger imagen",
        icon: "error",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Continuar",
      });
    } else {
      values.Imagen = props.Formulario.Imagen;
      // values.Empresa = values.EmpresaHolding;

      props.editarUsuario(values);
    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 9,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  useEffect(() => {
    let {
      Apellido_Materno,
      Apellido_Paterno,
      Descripcion,
      Email,
      Nombres,
      Password,
      Rut,
      Telefono,
      Vigencia,
    } = props.Formulario;

    if (props.editarCerrado) {
      form.setFieldsValue({
        Rut,
      });
      props.cambiarEditarCerrado();
    }

    form.setFieldsValue({
      Apellido_Materno,
      Apellido_Paterno,
      Descripcion,
      Email,
      Nombres,
      Password,
      Telefono,
      Vigencia,
    });
  });

  const valoresSelect = () => {
    let arregloValoresNoIngresables = props.Formulario.Empresas;

    let valoresSeleccionados = [];

    props.empresasHolding.forEach((empresa) => {
      let flag = false;

      arregloValoresNoIngresables.forEach((empresaSeleccionada) => {
        if (empresa._id.toString() === empresaSeleccionada.Empresa.toString()) {
          flag = true;
        }
      });

      
      empresa.disabled = flag;
      valoresSeleccionados.push(empresa);
      
    });


    return valoresSeleccionados.map((value, index) => (
      <Select.Option 
      disabled={value.disabled}
      name="Rol" 
      key={index} 
      value={value._id}>
        {value.Razon_Social}
      </Select.Option>
    ));
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={true}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={logoGenerico} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Editar Usuario"
          />
        </Col>
        <Col span={8}>
          <Row>
          <Col span={24}>
            <Card className="card-perfil" style={{ width: "100%" }}>
              <Row justify="center">
                <Col span={8} style={{ marginRight: 90 }}>
                  <SubirFoto
                    avatar={props.Formulario.Imagen}
                    ObtenerImagen={ObtenerImagen}
                  />
                </Col>
                <Col span={24}>
                  <p className="">
                    Te recomendamos que la fotografía no exceda los 5 MB de peso
                  </p>
                </Col>
              </Row>
            </Card>
            </Col>
            <Col span={24}>
              {props.Formulario.Empresas.map((empresa, index) => (
                <Card
                  className="card-perfil"
                  style={{ width: "100%", marginTop: "10px" }}
                  key={index}
                >
                  <Row>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <h3>Empresa {index + 1}</h3>
                      <img
                        src={iconBorrar}
                        onClick={() => {
                          props.borrarEmpresa(index);
                        }}
                        className="cursor-pointer"
                        alt="borrar-icon"
                        style={{
                          top: "0",
                          right: "14px",
                          position: "absolute",
                          width: "10px",
                        }}
                      />
                    </Col>
                    <Col span={24}>
                      <Select
                        style={{
                          maxWidth: "100%",
                        }}
                        className="campos"
                        name="EmpresaHolding"
                        placeholder="Selecciona una empresa"
                        value={empresa.Empresa}
                        onSelect={(value) => {
                          const escogido = props.Formulario.Empresas[index];
                          props.agregarValores(index, escogido.Rol, value);
                        }}
                      >
                        {valoresSelect()}
                        {/* {props.empresasHolding.map((value, index) => (
                          <Select.Option
                            name="Rol"
                            key={index}
                            value={value._id}
                          >
                            {value.Razon_Social}
                          </Select.Option>
                        ))} */}
                      </Select>
                    </Col>
                    <Col span={24}>
                      <Select
                        className="campos"
                        name="Rol"
                        placeholder="Selecciona rol"
                        style={{
                          maxWidth: "100%",
                          marginTop: "10px",
                        }}
                        value={empresa.Rol}
                        onSelect={(value) => {
                          const escogido = props.Formulario.Empresas[index];
                          props.agregarValores(index, value, escogido.Empresa);
                        }}
                      >
                        {props.Roles.map((value, index) => (
                          <Select.Option
                            name="Rol"
                            key={index}
                            value={value._id}
                          >
                            {value.Nombre}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Col>
            <Col
              span={24}
              style={{
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              <Button
                className="button-delgado-rosado"
                onClick={() => {
                  props.agregarNuevaEmpresa();
                }}
              >
                Agregar empresa
              </Button>
            </Col>
          </Row>
        </Col>
        <Col offset={1} span={15}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Form.Item
              name={"Email"}
              label="Nombre usuario (Email)"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Se debe ingresar un correo",
                },
              ]}
            >
              <Input
                name="Email"
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Email", valor);
                }}
                className="campos"
              />
            </Form.Item>
            <Form.Item
              name={"Password"}
              label="Contraseña"
              rules={[
                {
                  required: true,
                  message: "Debe ingresar una contraseña",
                  min: 8,
                  max: 16,
                },
              ]}
              hasFeedback
              help="La contraseña debe tener como mínimo 8 caracteres alfanumericos"
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Password", valor);
                }}
                suggested="current-password"
                name="Password"
                type="Password"
                className="campos"
              />
            </Form.Item>
            <Form.Item
              name="Nombres"
              label="Nombres:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir sus nombres",
                  min: 2,
                  max: 20,
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Nombres", valor);
                }}
                name="Nombres"
                className="campos"
              />
            </Form.Item>
            <Form.Item
              name={"Apellido_Paterno"}
              label="Apellido Paterno:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir sus apellidos",
                  min: 2,
                  max: 30,
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Apellido_Paterno", valor);
                }}
                name="Apellido_Paterno"
                className="campos"
              />
            </Form.Item>
            <Form.Item
              name={"Apellido_Materno"}
              label="Apellido Materno:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir sus apellidos",
                  min: 2,
                  max: 30,
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Apellido_Materno", valor);
                }}
                name="Apellido_Materno"
                className="campos"
              />
            </Form.Item>

            <Form.Item
              name={"Rut"}
              label="RUT"
              validateStatus={validRutUsuario}
              onChange={(e) => {
                let valor = e.target.value;

                if (valor) {
                  let rut = Rut.formateaRut(valor);
                  let rutValidacion = Rut.procesarRut(rut);

                  setValidRutUsuario(rutValidacion);

                  if (rut !== undefined) {
                    form.setFieldsValue({ Rut: rut });
                  } else {
                    form.setFieldsValue({ Rut: valor });
                  }
                }
              }}
              rules={[
                {
                  min: 11,
                  max: 12,
                  required: true,
                  message: "Debe introducir su RUT!",
                },
              ]}
              hasFeedback
              help="Ingrese el RUT válido con código verificador"
            >
              <Input
                name="Rut"
                className="campos"
                placeholder="xx.xxx.xxx-x"
                maxLength={12}
              />
            </Form.Item>
            <Form.Item
              label="Teléfono"
              name="Telefono"
              rules={[
                {
                  message: "Debe introducir tu numero de telefono",
                },
              ]}
              hasFeedback
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Telefono", valor);
                }}
                name="Telefono"
                className="campos"
                placeholder="+569XXXXXXXX"
                maxLength={12}
              />
            </Form.Item>

            <Form.Item
              label="Descripción"
              name="Descripcion"
              rules={[
                {
                  message: "Debe introducir una introducción",
                },
              ]}
              
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Descripcion", valor);
                }}
                name="Descripcion"
                className="campos"
                placeholder="Descripcion del usuario"
              />
            </Form.Item>
            <Form.Item
              name="Vigencia"
              label="Vigencia"
              style={{ textAlign: "left" }}
            >
              <Switch
                checked={props.Formulario.Vigencia}
                onChange={() => {
                  let nuevoValor =
                    props.Formulario.Vigencia === true ? false : true;
                  props.TomarValoresEditar("Vigencia", nuevoValor);
                }}
                className="switch"
              />
            </Form.Item>
          </Card>
        </Col>
        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Editar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Demo;
