// Tipos de consultas
import {
  ERROR,
  LOADING,
  LOADING_PARAR,
  MANTENEDOR_DETALLE_FAENA_OBTENER_FAENA,
  MANTENEDOR_DETALLE_FAENA_OBTENER_TRABAJADORES_PARA_CARGO,
  MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADORES,
  MANTENEDOR_DETALLE_FAENA_ACTUALIZAR_FAENA,
  MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADOR_BORRAR_HORAS,
  MANTENEDOR_DETALLE_FAENA_MOSTRAR_PANTALLA_TRABAJADOR_BORRAR_HORAS,
  MANTENEDOR_DETALLE_FAENA_PARA_LOADING,
  MANTENEDOR_ELIMINAR_PLANIFICACION_TRABAJADOR,
  MANTENEDOR_DETALLE_FAENA_MOSTRAR_PANTALLA_TRABAJADOR_EDITAR_HORAS,
  MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADOR_EDITAR_HORAS,

  MANTENEDOR_ALARGAR_PLANIFICACION_TRABAJADOR,
  MANTENEDOR_DETALLE_FAENA_OBTENER_FERIADOS,
  MANTENEDOR_DETALLE_FAENA_MODIFICAR_ITEMS_GRUPOS,
} from "../types/DetalleFaenaType";

const INITIAL_STATE = {
  loading: false,
  error: null,
  faena: null,
  grupos: [],
  items: [],
  terminoCargaCalendario: false,
  bitacoraTrabajadores: [],
  bitacoraRangoEliminar: [],
  reporteDashboard: [],
  trabajadores: [],
  trabajador: null,
  idPuestoPlaza: null,
  idPlaza: null,
  mostrarTrabajadores: false,
  mostrarTrabajadorBorrarHoras: false,
  mostrarTrabajadorEditarHoras: false,
  Inicio_Planificacion: null,
  Inicio_PlanificacionUnix: null,
  Fin_PlanificacionUnix: null,
  Fin_Planificacion: null,
  FaenaEliminar: null,
  feriadosSistema: [],

};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOADING_PARAR:
      return {
        ...state,
        loading: false,
      }
    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case MANTENEDOR_DETALLE_FAENA_ACTUALIZAR_FAENA:
      return {
        ...state,
        loading: false,
      };
    case MANTENEDOR_DETALLE_FAENA_OBTENER_FERIADOS:
      return {
        ...state,
        feriadosSistema: payload,
      }
    case MANTENEDOR_DETALLE_FAENA_OBTENER_FAENA:
      return {
        ...state,
        loading: false,
        faena: payload.faena,
        grupos: payload.dataOrdenada,
        items: payload.items,
        terminoCargaCalendario: true,
        reporteDashboard: payload.reporteDashboard,
        mostrarTrabajadores: false,
        mostrarTrabajadorBorrarHoras: false,
        mostrarTrabajadorEditarHoras: false,
      };
    case MANTENEDOR_DETALLE_FAENA_OBTENER_TRABAJADORES_PARA_CARGO:
      return {
        ...state,
        trabajadores: payload,
        mostrarTrabajadores: true,
        loading: false,
      };
    case MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADOR_BORRAR_HORAS:
      return {
        ...state,
        mostrarTrabajadorBorrarHoras: false
      }
    case MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADOR_EDITAR_HORAS:
      return {
        ...state,
        mostrarTrabajadorEditarHoras: false,
      }
    case MANTENEDOR_DETALLE_FAENA_MOSTRAR_PANTALLA_TRABAJADOR_BORRAR_HORAS:
      return {
        ...state,
        mostrarTrabajadorBorrarHoras: true,
        loading: false,
        bitacoraTrabajadores: payload.bitacoraTrabajador,
        bitacoraRangoEliminar: payload.arregloDiasPoderEliminar,
        trabajador: payload.trabajador,
        idPuestoPlaza: payload.idPlazaCubrir,
        idPlaza: payload.idPlaza,
        Inicio_Planificacion: payload.Inicio_Planificacion,
        Fin_Planificacion: payload.Fin_Planificacion,
        FaenaEliminar: payload.FaenaEliminar,
      }

    case MANTENEDOR_DETALLE_FAENA_MOSTRAR_PANTALLA_TRABAJADOR_EDITAR_HORAS:
      return {
        ...state,
        mostrarTrabajadorEditarHoras: true,
        loading: false,
        bitacoraTrabajadores: payload.bitacoraTrabajador,
        bitacoraRangoEliminar: payload.arregloDiasPoderEliminar,
        trabajador: payload.trabajador,
        idPuestoPlaza: payload.idPlazaCubrir,
        idPlaza: payload.idPlaza,
        Inicio_Planificacion: payload.Inicio_Planificacion,
        Fin_Planificacion: payload.Fin_Planificacion,
        FaenaEliminar: payload.FaenaEliminar,
        Inicio_PlanificacionUnix: payload.Inicio_PlanificacionUnix,
        Fin_PlanificacionUnix: payload.Fin_PlanificacionUnix,
      }
    case MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADORES:
      return {
        ...state,
        mostrarTrabajadores: false,
      };
    case MANTENEDOR_ALARGAR_PLANIFICACION_TRABAJADOR:
      return {
        ...state,
        loading: false,
      }
    case MANTENEDOR_DETALLE_FAENA_PARA_LOADING:
      return {
        ...state,
        loading: false,
      }
    case MANTENEDOR_ELIMINAR_PLANIFICACION_TRABAJADOR:
      return {
        ...state,
        loading: false,
      }
    case MANTENEDOR_DETALLE_FAENA_MODIFICAR_ITEMS_GRUPOS:
      return {
        ...state,
        grupos: payload.grupos,
        items: payload.items,
      }
    default:
      return state;
  }
};
