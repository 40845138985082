import React, { Component } from "react";
//import avatar from "../../img/icons/avatar.svg";

import ausente from "../../img/icons-nuevos/Ausente.svg";
// import ausente from "../../img/icons/Ausente.svg";

import {
  Row,
  Col,
  Layout,
  Card,
  Form,
  Button,
  Select,
  Input,
  Modal,
  PageHeader,
  Radio,
  DatePicker,
  Tooltip
} from "antd";
import "./Ausente.css";
import LocaleES from "antd/lib/date-picker/locale/es_ES";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
//import Fecha from "../../components/Calendarios/Range";
import Loading from "../../components/loading/Loading";
//import TimeLineTrabajador from "../../components/timeLineTrabajador/TimeLineTrabajadorPopover2";
import TimeLineTrabajador from "../../components/timeLineTrabajador/TimeLineTrabajadorPopover3";
import { traer_todos_las_fechas_trabajador_descansos_finiquitados } from "../../Redux/actions/descansoFiniquitadosActions";
import { connect } from "react-redux";
import * as ausenteActions from "../../Redux/actions/AusenteActions";

import Swal from "sweetalert2";
import moment from "moment";

// import TrabajoEfectivo from "../../img/dashboard/bubble/TrabajoEfectivo.svg";
// import Descanso from "../../img/dashboard/bubble/Descanso.svg";
// import EnTransito from "../../img/dashboard/bubble/EnTransito.svg";
// import Vacaciones from "../../img/dashboard/bubble/Vacaciones.svg";
// import Licencia from "../../img/dashboard/bubble/Licencia.svg";
// import LicenciaMutual from "../../img/dashboard/bubble/LicenciaMutual.svg";
// import Permiso from "../../img/dashboard/bubble/Permiso.svg";
// import AusenteLeyenda from "../../img/dashboard/bubble/Ausente.svg";
// import NoAsignado from "../../img/dashboard/bubble/NoAsignado.svg";
// import NoContratado from "../../img/icons/accidente.svg";

import accesosPagina from "../../funcionesGenericas/accesosUsuario";

import {
  // momentToStringFechaInicio,
  // momentToStringFechaFin,
  // databaseFormat,
  convertirFechaString,
} from "../../funcionesGenericas/fechas";

const { Header, Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
//const { Step } = Steps;
// const customDot = (dot, { status, index,title }) => (

//   <span>{dot}</span>

// );

class Ausente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mostrarCalendario: false,
      bitacora: [],
      modalAusente: false,
      idEmpresa: null,
      selectedContrato: [],
      selectedDates: [],
      selectedDatesTimeline: [],
      firstDaysOfTheMonths: [],
      loading: false,
      contEstados: {
        TrabajoEfectivo: 0,
        Descanso: 0,
        Vacaciones: 0,
        EnTransito: 0,
        Licencia: 0,
        LicenciaMutualidad: 0,
        Permiso: 0,
        Ausente: 0,
        NoAsignado: 0,
        Vacio: 0,
      },
    };
  }

  formRef = React.createRef();

  state = {
    imagen: null,
    LoadingForm: false,
    fechasBloqueadas: [],
  };

  onFinish = async (values) => {
    const { selectedDatesTimeline, fechasBloqueadas, idEmpresa } = this.state;
    let { Fechas, Trabajador } = this.props;
    const { contrato: idContrato } = this.formRef.current.getFieldsValue();
  
    if (Trabajador) {
      if (Fechas === null || Fechas[0].toString() === "Invalid Date") {
        Swal.fire({
          title: "Ups",
          text: "Debes ingresar el rango de fechas",
          icon: "warning",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        });

        return;
      }

      //VALIDACIÓN DE WARNING
      let listaFechas = [];

      await fechasBloqueadas.forEach((bitacora) => {
        listaFechas.push(moment.utc(bitacora.Fecha));
      });

      // const fecha1 = moment.utc(momentToStringFechaInicio(moment(this.props.Fechas[0]),databaseFormat));
      // const fecha2 = moment.utc(momentToStringFechaFin(moment(this.props.Fechas[1]),databaseFormat));

      const fecha1 = moment.utc(
        convertirFechaString(moment(Fechas[0]), 1).fechaString
      );
      const fecha2 = moment.utc(
        convertirFechaString(moment(Fechas[1]), 2).fechaString
      );

      // console.log("Fechas ausenteee");
      // console.log(fecha1);
      // console.log(fecha2);

      let warningFlag = false;
      let confirmFlag = false;

      await listaFechas.forEach(async (fecha) => {
        if (await fecha.isBetween(fecha1, fecha2)) {
          warningFlag = true;
          return;
        }
      });

      if (warningFlag) {
        await Swal.fire({
          title: "Advertencia",
          text: `Las fechas que seleccionaste ya tienen planificación, ¿deseas continuar?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1FC600",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (!result.value) {
            confirmFlag = true;
          }
        });

        if (confirmFlag) {
          return;
        }
      }

      await this.setState({
        LoadingForm: true,
      });

      //se ingresa la descripcion al formulario
      await this.props.insertarDescripcion(values.Descripcion);
      await this.props.insertarObservacion(values.Observacion);
      //se realiza el ingreso de datos
      //en caso de fallar se mostrara un mensaje de error
      await this.props.insertarDatosAvanzado();

      await this.props.traer_todos_contratos_trabajador(
        Trabajador,
        idEmpresa,
        selectedDatesTimeline[0],
        selectedDatesTimeline[1]
      );

      let response =
        await this.props.traer_todos_las_fechas_trabajador_por_contrato(
          Trabajador,
          idContrato
        );

      if (response !== null) {
        const newBitacorasHistorico =
          await this.props.traer_todos_los_dias_ausentes_historicos(Trabajador);

        let arrayIniciosDeMes = [];

        let fechaInicioOriginal = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda), 1).fechaString;
        let fechaInicioString = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda).startOf("month"), 1).fechaString;
        let fechaFinString = convertirFechaString(moment.utc(response.FinFechaRangoBusqueda).endOf("month"), 2).fechaString;
        let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;
    
        for (let index = 0; index < diferenciaMeses; index++) {
          let fechaAgregar;
    
          if(index === 0){
            if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
              fechaAgregar = moment.utc(fechaInicioOriginal);
            } else {
              fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
            }
          } else {
            fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
          }
    
          arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
        }
  
        await this.setState({
          fechasBloqueadas: response.bitNoModificar,
          bitacora: response.bitacoras,
          selectedContrato: [response.InicioContrato, response.FinContrato],
          selectedDates: [
            response.inicioFechaRangoBusqueda,
            response.FinFechaRangoBusqueda,
          ],
          selectedDatesTimeline: [
            response.inicioFechaRangoBusqueda,
            response.FinFechaRangoBusqueda,
          ],
          firstDaysOfTheMonths: arrayIniciosDeMes,
          mostrarCalendario: true,
          loading: false,
          fechasHistoricasAusente:
            newBitacorasHistorico !== null ? newBitacorasHistorico : [],
        });
        await this.formRef.current.resetFields([
          "Fechas",
          "Descripcion",
          "Observacion",
          "Correo",
        ]);
      } else {
        await this.setState({
          fechasBloqueadas: [],
          bitacora: [],
          selectedContrato: [null, null],
          selectedDates: [null, null],
          selectedDatesTimeline: [null, null],
          firstDaysOfTheMonths: [],
          mostrarCalendario: false,
          loading: false,
          fechasHistoricasAusente: [],
        });
        await this.formRef.current.resetFields([
          "contrato",
          "trabajador",
          "Fechas",
          "Descripcion",
          "Observacion",
          "Correo",
        ]);
      }

      // let response = await this.props.traer_todos_las_fechas_trabajador(
      //   this.props.Trabajador
      // );

      // let fechas = [];

      // response.forEach((element) => {
      //   //ingreso la fecha a un arreglo para poder validar en el calendario
      //   fechas.push(element.Fecha);
      // });

      // const responseDFiniquitados = await traer_todos_las_fechas_trabajador_descansos_finiquitados(this.props.Trabajador);
      // responseDFiniquitados.forEach(finiquito => {
      //     let newFiniquito = {...finiquito};
      //     newFiniquito.finiquitoDescanso = true;
      //     newFiniquito.TipoRegistroBitacoraTrabajador = 'descansoFiniquitado';
      //     response.push(newFiniquito);
      // });

      // await this.setState({
      //   fechasBloqueadas: fechas,
      //   bitacora: response,
      // });

      // await this.setState({
      //   mostrarCalendario: true,
      // });
    }
  };

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;

      this.props.traer_todos_los_trabajadores(id);

      const accesos = await accesosPagina.obtenerAccesosPagina(
        "ProcesosAusente"
      );

      this.setState({
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
        idEmpresa: id,
      });
    }
  }

  handleFechas = async (valueMoment, contenedorFechas) => {
    let fechas = [];

    contenedorFechas.forEach((fecha) => {
      let dia = new Date(fecha);
      fechas.push(dia);
    });

    await this.props.insertarFechas(fechas);
  };

  handleChange = async (idTrabajador) => {
    //se busca al trabajador para buscar sus datos
    let user = this.props.trabajadores.find((x) => x._id === idTrabajador);
    if (user) {
      const { idEmpresa } = this.state;
      this.setState({
        imagen: user.Imagen,
        nombre: user.Nombre,

        fechasBloqueadas: [],
        bitacora: [],
        selectedContrato: [null, null],
        selectedDates: [null, null],
        mostrarCalendario: false,
        loading: true,
      });

      await this.props.insertarTrabajador(idTrabajador);

      await this.formRef.current.resetFields([
        "Fechas",
        "Descripcion",
        "Correo",
        "contrato",
      ]);

      this.props.traer_todos_contratos_trabajador(idTrabajador, idEmpresa);

      const newBitacorasHistorico =
        await this.props.traer_todos_los_dias_ausentes_historicos(idTrabajador);

      if (newBitacorasHistorico !== null) {
        this.setState({
          fechasHistoricasAusente: newBitacorasHistorico,
          loading: false,
        });
      } else {
        this.setState({
          fechasHistoricasAusente: [],
          loading: false,
        });
      }
    }
  };

  handleContrato = async (idContrato) => {
    const idTrabajador = this.props.Trabajador;
    let user = this.props.trabajadores.find((x) => x._id === idTrabajador);
    if (user) {
      this.setState({
        imagen: user.Imagen,
        nombre: user.Nombre,
        loading: true,
      });

      let response =
        await this.props.traer_todos_las_fechas_trabajador_por_contrato(
          idTrabajador,
          idContrato
        );

      let arrayIniciosDeMes = [];

      let fechaInicioOriginal = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda), 1).fechaString;
      let fechaInicioString = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda).startOf("month"), 1).fechaString;
      let fechaFinString = convertirFechaString(moment.utc(response.FinFechaRangoBusqueda).endOf("month"), 2).fechaString;
      let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;
  
      for (let index = 0; index < diferenciaMeses; index++) {
        let fechaAgregar;
  
        if(index === 0){
          if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
            fechaAgregar = moment.utc(fechaInicioOriginal);
          } else {
            fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
          }
        } else {
          fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
        }
  
        arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
      }
      
      await this.setState({
        fechasBloqueadas: response.bitNoModificar,
        bitacora: response.bitacoras,
        selectedContrato: [response.InicioContrato, response.FinContrato],
        selectedDates: [
          response.inicioFechaRangoBusqueda,
          response.FinFechaRangoBusqueda,
        ],
        selectedDatesTimeline: [
          response.inicioFechaRangoBusqueda,
          response.FinFechaRangoBusqueda,
        ],
        firstDaysOfTheMonths: arrayIniciosDeMes,
        contEstados: response.contEstados,
        mostrarCalendario: true,
        loading: false,
      });

      await this.formRef.current.resetFields(["Fechas"]);
    }
  };

  handleNewDates = async (fechaInicio, fechaFin) => {
    try {
      const idTrabajador = this.props.Trabajador;
      let user = this.props.trabajadores.find((x) => x._id === idTrabajador);
      if (user) {
        this.setState({
          loading: true,
        });

        const { contrato: idContrato } = this.formRef.current.getFieldsValue();
        let response =
          await this.props.traer_todos_las_fechas_trabajador_por_contrato(
            idTrabajador,
            idContrato,
            fechaInicio,
            fechaFin
          );

        let arrayIniciosDeMes = [];

        let fechaInicioOriginal = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda), 1).fechaString;
        let fechaInicioString = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda).startOf("month"), 1).fechaString;
        let fechaFinString = convertirFechaString(moment.utc(response.FinFechaRangoBusqueda).endOf("month"), 2).fechaString;
        let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;
    
        for (let index = 0; index < diferenciaMeses; index++) {
          let fechaAgregar;
    
          if(index === 0){
            if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
              fechaAgregar = moment.utc(fechaInicioOriginal);
            } else {
              fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
            }
          } else {
            fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
          }
    
          arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
        }

        await this.setState({
          fechasBloqueadas: response.bitNoModificar,
          bitacora: response.bitacoras,
          selectedContrato: [response.InicioContrato, response.FinContrato],
          selectedDates: [
            response.inicioFechaRangoBusqueda,
            response.FinFechaRangoBusqueda,
          ],
          selectedDatesTimeline: [
            response.inicioFechaRangoBusqueda,
            response.FinFechaRangoBusqueda,
          ],
          firstDaysOfTheMonths: arrayIniciosDeMes,
          contEstados: response.contEstados,
          mostrarCalendario: true,
          loading: false,
        });

        await this.formRef.current.resetFields(["Fechas"]);
      }
    } catch (error) {
      await this.setState({
        loading: false,
      });
      console.log(error);
    }
  };

  actualizarFechaTimeline = (fechaInicio, fechaFin) => {
    let arrayIniciosDeMes = [];

    let fechaInicioOriginal = convertirFechaString(moment.utc(fechaInicio), 1).fechaString;
    let fechaInicioString = convertirFechaString(moment.utc(fechaInicio).startOf("month"), 1).fechaString;
    let fechaFinString = convertirFechaString(moment.utc(fechaFin).endOf("month"), 2).fechaString;
    let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;

    for (let index = 0; index < diferenciaMeses; index++) {
      let fechaAgregar;

      if(index === 0){
        if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
          fechaAgregar = moment.utc(fechaInicioOriginal);
        } else {
          fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
        }
      } else {
        fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
      }

      arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
    }

    this.setState({
      selectedDatesTimeline: [fechaInicio, fechaFin],
      firstDaysOfTheMonths: arrayIniciosDeMes,
    });
  };

  dobleClickBarra = (itemSeleccionado) => {
    if (itemSeleccionado.group === "5f3eeca16dd7cc57f0d3a260") {
      console.log(itemSeleccionado);

      this.setState({
        idBitacora: itemSeleccionado.id,
        modalAusente: true,
      });
    }
  };

  descargarTicket = (itemSeleccionado) => {
    // var win = window.open("/ImpresionAusente?idBitacora=" + itemSeleccionado.id, "_blank");
    // win.focus();

    // console.log("itemSeleccionado");
    // console.log(itemSeleccionado);
    // var win = window.open(
    //   "/ImpresionAusente?idBitacora=" + itemSeleccionado,
    //   "_blank"
    // );
    // win.focus();
  };

  eliminarRegistro = async (itemSeleccionado) => {
    const { selectedDatesTimeline, idEmpresa } = this.state;
    let { Trabajador } = this.props;
    const { contrato: idContrato } = this.formRef.current.getFieldsValue();

    await Swal.fire({
      title: "Confirmar",
      text: `¿Estás seguro que quieres eliminar el registro de ausente?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        await this.props.eliminarRegistro(
          itemSeleccionado.Fecha_Inicio_DB,
          itemSeleccionado.Fecha_Fin_DB,
          itemSeleccionado.id
        );

        // let response = await this.props.traer_todos_las_fechas_trabajador(this.props.Trabajador);

        // let fechas = [];

        // await response.forEach(element => {
        //   fechas.push(element.Fecha);
        // });

        // const responseDFiniquitados = await traer_todos_las_fechas_trabajador_descansos_finiquitados(this.props.Trabajador);
        // responseDFiniquitados.forEach(finiquito => {
        //     let newFiniquito = {...finiquito};
        //     newFiniquito.finiquitoDescanso = true;
        //     newFiniquito.TipoRegistroBitacoraTrabajador = 'descansoFiniquitado';
        //     response.push(newFiniquito);
        // });

        // await this.setState({
        //   fechasBloqueadas: fechas,
        //   bitacora: response,
        // });

        // await this.setState({
        //   mostrarCalendario: true,
        //   modalDescanso: false,
        // });
        await this.props.traer_todos_contratos_trabajador(
          Trabajador,
          idEmpresa,
          selectedDatesTimeline[0],
          selectedDatesTimeline[1]
        );

        if (idContrato !== null && idContrato !== undefined) {
          let response =
            await this.props.traer_todos_las_fechas_trabajador_por_contrato(
              Trabajador,
              idContrato
            );

          if (response !== null) {
            const newBitacorasHistorico =
              await this.props.traer_todos_los_dias_ausentes_historicos(Trabajador);

            let arrayIniciosDeMes = [];

            let fechaInicioOriginal = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda), 1).fechaString;
            let fechaInicioString = convertirFechaString(moment.utc(response.inicioFechaRangoBusqueda).startOf("month"), 1).fechaString;
            let fechaFinString = convertirFechaString(moment.utc(response.FinFechaRangoBusqueda).endOf("month"), 2).fechaString;
            let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;
        
            for (let index = 0; index < diferenciaMeses; index++) {
              let fechaAgregar;
        
              if(index === 0){
                if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
                  fechaAgregar = moment.utc(fechaInicioOriginal);
                } else {
                  fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
                }
              } else {
                fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
              }
        
              arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
            }
        

            await this.setState({
              fechasBloqueadas: response.bitNoModificar,
              bitacora: response.bitacoras,
              selectedContrato: [response.InicioContrato, response.FinContrato],
              selectedDates: [
                response.inicioFechaRangoBusqueda,
                response.FinFechaRangoBusqueda,
              ],
              selectedDatesTimeline: [
                response.inicioFechaRangoBusqueda,
                response.FinFechaRangoBusqueda,
              ],
              firstDaysOfTheMonths: arrayIniciosDeMes,
              mostrarCalendario: true,
              loading: false,
              fechasHistoricasAusente:
                newBitacorasHistorico !== null ? newBitacorasHistorico : [],
            });
            await this.formRef.current.resetFields([
              "Fechas",
              "Descripcion",
              "Correo",
              "Observacion"
            ]);
          } else {
            await this.setState({
              fechasBloqueadas: [],
              bitacora: [],
              selectedContrato: [null, null],
              selectedDates: [null, null],
              selectedDatesTimeline: [null, null],
              firstDaysOfTheMonths: [],
              mostrarCalendario: false,
              loading: false,
              fechasHistoricasAusente: [],
            });
            await this.formRef.current.resetFields([
              "contrato",
              "trabajador",
              "Fechas",
              "Descripcion",
              "Correo",
              "Observacion"
            ]);
          }
        } else {
          const newBitacorasHistorico =
            await this.props.traer_todos_los_dias_ausentes_historicos(Trabajador);

          await this.setState({
            fechasHistoricasAusente:
              newBitacorasHistorico !== null ? newBitacorasHistorico : [],
          });
        }
      }
    });
  };

  modalCancelar = () => {
    this.setState({
      modalAusente: false,
    });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 10,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 12,
        },
      },
    };

    return (
      <Layout>
        <Loading cargando={this.props.loading} />
        <Loading cargando={this.state.loading} />
        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Día Ausente"
            subtitulo="Corresponde a los días planificados en los que el trabajador no se presentó a sus labores"
          />
        </Header>
        <hr className="division" />
        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row gutter={25}>
            <Col
              className="gutter-row"
              span={8}
              xs={24}
              s={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
            >
              {/* desde aqui cambie a mobile */}
              {/* xs={24} s={24} sm={24} md={8} lg={8} xl={8} */}
              <Card
                style={{
                  borderRadius: "16px",
                  height: "100%",
                  width: "100%",
                  boxShadow: "0px 3px 6px #74747465",
                }}
              >
                <Row>
                  <Col span={22} style={{ display: "inherit" }}>
                    <img src={ausente} alt="cama arreglada para descansar" />
                    <h1 className="formDescanso_card_title">
                      Generar Día Ausente
                    </h1>
                  </Col>
                  <Col span={24} style={{ marginTop: "24px" }}>
                    <Form
                      {...formItemLayout}
                      onFinish={this.onFinish}
                      ref={this.formRef}
                      name="descanso_trabajador"
                    >
                      {/* Trabajador */}
                      <Form.Item
                        name="trabajador"
                        label="Nombre Trabajador"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                      >
                        <Select
                          showSearch={true}
                          placeholder="Selecciona un trabajador"
                          optionFilterProp="children"
                          size="middle"
                          loading={this.props.loading}
                          onChange={this.handleChange}
                          filterOption={(input, option) => {
                            return (
                              option.children.props.children.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {this.props.trabajadores === null ? (
                            <Option value="Cargando trabajadores...">
                              Cargando trabajador...
                            </Option>
                          ) : (
                            this.props.trabajadores.map(
                              (
                                {
                                  Nombre,
                                  Apellido_Paterno,
                                  Apellido_Materno,
                                  _id,
                                },
                                index
                              ) => (
                                <Option key={index} value={_id}>
                                  <Tooltip title={Nombre + " " + Apellido_Paterno + " " + Apellido_Materno} color={'geekblue'}>
                                    <b>
                                      {Nombre +
                                        " " +
                                        Apellido_Paterno +
                                        " " +
                                        Apellido_Materno}
                                    </b>
                                  </Tooltip>
                                </Option>
                              )
                            )
                          )}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="contrato"
                        label="Contrato Trabajador"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                      >
                        <Select
                          // showSearch={true}
                          placeholder="Selecciona el contrato del trabajador"
                          optionFilterProp="children"
                          size="middle"
                          loading={this.props.loading}
                          onChange={this.handleContrato}
                          // filterOption={(input, option) => {
                          //   return (
                          //     option.children.props.children
                          //       .toLowerCase()
                          //       .indexOf(input.toLowerCase()) >= 0
                          //   );
                          // }}
                        >
                          {this.props.contratos === null ? (
                            <Option value="Cargando contratos...">
                              Cargando contratos...
                            </Option>
                          ) : (
                            this.props.contratos.map((contrato, index) => (
                              <>
                                {contrato.Tipo_Contrato === "Plazo fijo" ? (
                                  <Option key={index} value={contrato._id}>
                                    <b>
                                      {moment
                                        .utc(contrato.Contrato_Fecha_Inicio)
                                        .format("DD-MM-YYYY")}{" "}
                                      al{" "}
                                      {contrato.Contrato_Finiquitado === true
                                        ? moment
                                            .utc(contrato.Fecha_Finiquito)
                                            .format("DD-MM-YYYY")
                                        : moment
                                            .utc(contrato.Contrato_Fecha_Fin)
                                            .format("DD-MM-YYYY")}
                                    </b>
                                  </Option>
                                ) : (
                                  <Option key={index} value={contrato._id}>
                                    <b>
                                      {moment
                                        .utc(contrato.Contrato_Fecha_Inicio)
                                        .format("DD-MM-YYYY")}{" "}
                                      
                                      {contrato.Contrato_Finiquitado === true
                                        ? 'Al ' + moment
                                            .utc(contrato.Fecha_Finiquito)
                                            .format("DD-MM-YYYY")
                                        : "A Contrato Indefinido"}
                                    </b>
                                  </Option>
                                )}
                              </>
                            ))
                          )}
                        </Select>
                      </Form.Item>

                      {/* Fechas */}
                      <Form.Item
                        name="Fechas"
                        label="Periodo de Ausencia"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                      >
                        {/* <Fecha
                          obtenerFecha={this.handleFechas}
                          //fechasBloqueadas={this.state.fechasBloqueadas}
                          fechasBloqueadas={[]}
                        /> */}
                        <RangePicker
                          format={"YYYY-MM-DD"}
                          onChange={this.handleFechas}
                          locale={LocaleES}
                          disabled={false}
                          disabledDate={(dia) => {
                            let diaString = convertirFechaString(
                              dia,
                              1
                            ).fechaString;
                            const fechas = this.state.selectedDates;
                            if (fechas[0] !== null && fechas[1] !== null) {
                              if (
                                moment
                                  .utc(diaString)
                                  .isBetween(
                                    moment.utc(fechas[0]),
                                    moment.utc(fechas[1]),
                                    null,
                                    "[]"
                                  )
                              ) {
                                return false;
                              }
                            }
                            return true;
                          }}
                          defaultPickerValue={
                            this.state.selectedDates[0] !== undefined &&
                            this.state.selectedDates[0] !== null
                              ? [
                                  moment.utc(this.state.selectedDates[0]),
                                  moment.utc(this.state.selectedDates[1]),
                                ]
                              : [moment(), moment()]
                          }
                        />
                      </Form.Item>

                      {/* Descripción */}
                      <Form.Item
                        name="Descripcion"
                        label="Motivo"
                        className="inputsFormDescanso_Text"
                      >
                        <TextArea rows={3} placeholder="(Opcional)" />
                      </Form.Item>

                      {/* Observacion */}
                      <Form.Item
                        name="Observacion"
                        label="Observacion"
                        className="inputsFormDescanso_Text"
                      >
                        <TextArea rows={3} maxLength={200} placeholder="(Opcional)" />
                      </Form.Item>
                      {/* /////////////////////////////////////////////////// */}

                      {/* Flag Correo */}
                      <Form.Item
                        name="Correo"
                        label="Enviar mail de notificación"
                        className="inputsFormDescanso_Text"
                        rules={[
                          {
                            required: true,
                            message: "Obligatorio",
                          },
                        ]}
                      >
                        <Radio.Group
                          onChange={({ target: { value } }) =>
                            this.props.enviarCorreo(value)
                          }
                          // value={true}
                        >
                          <Radio value={true}>Si</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>

                      {/* /////////////////////////////////////////////////// */}

                      <Form.Item
                        wrapperCol={{ offset: 22 }}
                        style={{ display: "none" }}
                      >
                        <Button type="primary" htmlType="submit">
                          Guardar
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                  <Col
                    //className="descansoBtn_guardar"
                    span={24}
                    style={{ marginTop: "24px", textAlign: "right" }}
                  >
                    <Button
                      style={{ marginRight: "8%" }}
                      type="primary"
                      htmlType="submit"
                      form="descanso_trabajador"
                      //className={!this.state.Editar ? "guardar-form-disabled" : "guardar-form"}
                      className={
                        !this.state.Editar
                          ? "btnGuardarDisabledT3"
                          : "btnGuardarT3"
                      }
                      disabled={!this.state.Editar}
                    >
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col
              className="gutter-row"
              span={16}
              xs={24}
              s={24}
              sm={24}
              md={16}
              lg={16}
              xl={16}
            >
              {/* xs={24} s={24} sm={24} md={16} lg={16} xl={16} */}

              <TimeLineTrabajador
                dobleClickBarra={this.dobleClickBarra}
                mostrarCalendario={this.state.mostrarCalendario}
                bitacora={this.state.bitacora}
                selectedContrato={this.state.selectedContrato}
                selectedDates={this.state.selectedDates}
                selectedDatesTimeline={this.state.selectedDatesTimeline}
                firstDaysOfTheMonths={this.state.firstDaysOfTheMonths}
                actualizarFechaTimeline={this.actualizarFechaTimeline}
                tipo={"5f3eeca16dd7cc57f0d3a260"}
                eliminarRegistro={this.eliminarRegistro}
                descargarRegistro={this.descargarTicket}
                cambiarFechas={this.handleNewDates}
                Eliminar={this.state.Eliminar}
                Ver={this.state.Ver}
                ArrayHistorial={this.state.fechasHistoricasAusente}
                TituloHistorial={"Dias Ausentes"}
                contEstados={this.state.contEstados}
              />
            </Col>
          </Row>
        </Content>

        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.state.modalAusente}
          onCancel={this.modalCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="40%"
          footer={null}
          style={{ top: "30%" }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <Row>
            <Col span={24} style={{ display: "flex" }} className="title">
              <img src={ausente} style={{ width: "35px" }} alt="icono modal" />
              <PageHeader
                style={{ padding: "0px 10px 0px 10px" }}
                className="site-page-header"
                title="Acciones días ausentes"
              />
            </Col>
            <Col
              style={{
                textAlign: "center",
                paddingTop: "50px",
                paddingBottom: "50px",
              }}
              span={12}
            >
              <Button
                className="descargar-excel"
                type="primary"
                //size={size}
                style={{ marginRight: "20px" }}
              >
                Eliminar registro
              </Button>
            </Col>

            <Col
              span={12}
              style={{
                textAlign: "center",
                paddingTop: "50px",
                paddingBottom: "50px",
              }}
            >
              <Button
                className="btn-verde"
                onClick={() => {
                  this.descargarTicket();
                }}
                //size={size}
                style={{ marginRight: "20px" }}
              >
                Descargar Documento
              </Button>
            </Col>
          </Row>
        </Modal>
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  AusenteReducer: {
    err,
    loading,
    trabajadores,
    form: { Fechas, Trabajador },
    contratos,
  },
}) => {
  return {
    loading,
    err,
    trabajadores,
    Fechas,
    Trabajador,
    contratos,
  };
};

export default connect(EstadosAPropiedades, ausenteActions)(Ausente);
