export const LOADING = "MANTENEDOR_FAENA_LOADING";
// export const PARAR_LOADING = "MANTENEDOR_FAENA_LOADING_PARAR";
export const ERROR = "MANTENEDOR_FAENA_ERROR";
export const INGRESAR_FAENA = "MANTENEDOR_FAENA_INGRESAR";
export const LIMPIAR_ERROR_SUCCESS = "MANTENEDOR_FAENA_LIMPIAR_ERROR_SUCCESS";
export const MOSTRAR_MODAL_FAENA = "MANTENEDOR_FAENA_MOSTRAR_MODAL";
export const OCULTAR_MODAL_FAENA = "MANTENEDOR_FAENA_OCULTAR_MODAL";
export const OCULTAR_MODAL_FAENA_EDITAR =
  "MANTENEDOR_FAENA_OCULTAR_MODAL_EDITAR";
export const OBTENER_FAENAS_MANTENEDOR = "MANTENEDOR_FAENA_OBTENER_FAENAS";
export const OBTENER_FAENA_MANTENEDOR = "MANTENEDOR_FAENA_OBTENER_FAENA";
export const ELIMINAR_FAENA_MANTENEDOR = "MANTENEDOR_FAENA_ELIMINAR_FAENA";
export const EDITAR_FAENA_MANTENEDOR = "MANTENEDOR_FAENA_EDITAR_FAENA";
export const TOMAR_VALORES_EDITAR = "MANTENEDOR_FAENA_TOMAR_VALORES_EDITAR";
export const OBTENER_TITULOS = "MANTENEDOR_FAENA_TITULOS";
export const OBTENER_ESPECIALIDADES = "MANTENEDOR_FAENA_ESPECIALIDADES";
export const OBTENER_CARGOS = "MANTENEDOR_FAENA_CARGOS";

// export const DESCARGAR_EXCEL = "MANTENEDOR_FAENA_DESCARGAR_EXCEL";
