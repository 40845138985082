import React, { Component } from "react";
import { connect } from "react-redux";
import * as MantenedorPerfilesUsuarioActions from "../../Redux/actions/MantenedorPerfilesUsuarioActions";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import FormularioPerfilUsuario from "../../components/formularioPerfilUsuario/FormularioPerfilUsuario";
import FormularioPerfilUsuarioEditar from "../../components/formularioPerfilUsuario/FormularioPerfilUsuarioEditar";

import {
  Layout,
  Table,
  Space,
  Button,
  Row,
  Col,
  Input,
  Modal,
} from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";

import Swal from "sweetalert2";
import accesosPagina from '../../funcionesGenericas/accesosUsuario';

const { Header, Content } = Layout;
const { Search } = Input;
const { Column } = Table;

class MantenedorPerfilesUsuario extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      size: "large",
      perfilesFiltros: null,
    };

    this.CrearPerfilUsuario = this.CrearPerfilUsuario.bind(this);
    this.EditarPerfilUsuario = this.EditarPerfilUsuario.bind(this);
  }

  async componentDidMount() {
    // let usuario = localStorage.getItem("usuario-people-manage");
    // if (
    //   usuario !== null &&
    //   usuario !== "" &&
    //   usuario !== undefined &&
    //   usuario !== "undefined"
    // ) {
    //   usuario = JSON.parse(usuario);
    //   const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
    // }
    const accesos = await accesosPagina.obtenerAccesosPagina('MantenedorPerfilesUsuario', 'MantenedorPerfilesPermiso');

    this.setState({
      Ver: accesos.Ver,
      Editar: accesos.Editar,
      Eliminar: accesos.Eliminar,
    });

    await this.props.ObtenerPerfilesUsuario();
  }

  search = (value) => {

    let busqueda = value.target.value;

    if (value !== "") {
      const perfilesRedux = this.props.permisosAccesos;

      const perfilesFiltros = perfilesRedux.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(busqueda)
        )
      );

      this.setState({ perfilesFiltros: perfilesFiltros });

    } else {
      this.setState({ perfilesFiltros: null });
    }
  };

  mostrarModalCrear = () => {
    this.props.MostrarModalPerfilUsuario();
  };

  modalCrearCancelar = () => {
    this.props.OcultarModalPerfilUsuario();
  };

  modalEditarCancelar = () => {
    this.props.OcultarModalPerfilEditar();
  };

  cambiarEstadoVigencia = () => {
    this.setState({
      form: {
        ...this.state.form,
        Vigencia: this.state.form.Vigencia === true ? false : true,
      },
    });
  };

  cambiarValores = (nombre, valor) => {
    this.setState({
      form: {
        ...this.state.form,
        [nombre]: valor,
      },
    });
  };

  agregarTitulo = () => {
    
    let titulos = this.state.form.arregloTitulos;
    let valorSeleccionado = this.state.form.TituloSeleccionado;
    let nombre = "";

    this.props.titulos.forEach((element) => {
      if (element._id === valorSeleccionado) {
        nombre = element.Nombre;
      }
    });

    let objeto = {
      Titulo: valorSeleccionado,
      URL: "",
      Nombre: nombre,
    };

    titulos.push(objeto);
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        arregloTitulos: titulos,
      },
    });
  };

  crearFaena = (faena) => {
    this.props.CrearFaena(faena);
  };

  EliminarPerfil = (idPerfil) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminará el perfil",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.EliminarPerfil(idPerfil);
      }
    });
  };

  ObtenerPerfil = (idFaena) => {
    this.props.ObtenerPerfil(idFaena);
  };

  EditarFaena = (faena) => {
    faena._id = this.props.faena._id;
    // faena.Nombre = this.props.faena.Nombre;
    // faena.Vigencia = this.props.faena.Vigencia;
    this.props.EditarFaena(faena);
  };

  TomarValoresEditar = (nombreCampo, valor) => {
    this.props.TomarValoresEditar(nombreCampo, valor);
  };

  ///////////////////////////////////////////

  CrearPerfilUsuario = (valores) => {
    this.props.CrearPerfilUsuario(valores);
  }

  EditarPerfilUsuario = (valores) => {

    valores._id = this.props.permisoAcceso._id;
    this.props.EditarPerfilUsuario(valores);
  }

  render() {
    const { size, perfilesFiltros } = this.state;

    return (
      <Layout style={{ height: "100%" }}>

        <Loading cargando={this.props.loading} />

        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Perfiles de Usuario"
            subtitulo="Crea permisos bases para asignar accesos a los usuarios"
          />
        </Header>

        <hr className="division" />

        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row style={{ padding: "6px 0px 22px" }} align="bottom">
            <Col className="div-input-search" span={6}>
              <Search
                placeholder="Buscar"
                enterButton
                onChange={(value) => this.search(value)}
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={6}></Col>
            <Col style={{ textAlign: "right" }} span={12}>
              <Button
                type="primary"
                onClick={() => {
                  this.mostrarModalCrear();
                }}
                size={size}
                className={!this.state.Editar ? "guardar-form-disabled" : "guardar-form"}
                disabled={!this.state.Editar}
              >
                Crear Perfil
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="_id"
            dataSource={
              perfilesFiltros === null ? this.props.permisosAccesos : perfilesFiltros
            }
            loading={this.props.tablaCargando}
            bordered={true}
            showSorterTooltip={false}
          >
            <Column
              sorter={(a, b) => {
                return a.Nombre.localeCompare(b.Nombre);
              }}
              align="left"
              title="Nombre"
              dataIndex="Nombre"
              key="Nombre"
              render={(values) => {
                return values.toUpperCase();
              }}
            />
            <Column
              title="Acciones"
              key="Action"
              align="center"
              render={(value) => (
                <Space size="middle">
                  <Button
                    onClick={() => {
                      this.ObtenerPerfil(value._id);
                    }}
                    className="editar-button"
                    icon={<EditFilled />}
                    disabled={!this.state.Editar}
                  />

                  <Button
                    className="eliminar-button"
                    onClick={() => {
                      this.EliminarPerfil(value._id);
                    }}
                    disabled={!this.state.Eliminar}
                    icon={<DeleteFilled />}
                  />
                </Space>
              )}
            />
          </Table>
        </Content>
        
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalPerfilUsuario}
          onCancel={this.modalCrearCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioPerfilUsuario
            crearPerfilPermiso={this.CrearPerfilUsuario}
          />
        </Modal>

        <Modal
          wrapClassName="modalEditar"
          visible={this.props.modalPerfilUsuarioEditar}
          onCancel={this.modalEditarCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >

          <FormularioPerfilUsuarioEditar
            Formulario={this.props.permisoAcceso}
            editarPerfilPermiso={this.EditarPerfilUsuario}
          />
        </Modal>
      
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  MantenedorPerfilesUsuarioReducer: {
    loading,
    modalPerfilUsuario,
    modalPerfilUsuarioEditar,
    permisosAccesos,
    permisoAcceso,
  },
}) => {
  return {
    loading,
    modalPerfilUsuario,
    modalPerfilUsuarioEditar,
    permisosAccesos,
    permisoAcceso,
  };
};

export default connect(
  EstadosAPropiedades,
  MantenedorPerfilesUsuarioActions
)(MantenedorPerfilesUsuario);
