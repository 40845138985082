import {
  ERROR,
  LOADING,
  MOSTRAR_MODAL_PERFIL_USUARIO,
  OCULTAR_MODAL_PERFIL_USUARIO,
  PARAR_LOADING,
  OBTENER_PERFILES_ACCESO,
  OBTENER_PERFIL_ACCESO,
  OCULTAR_MODAL_PERFIL_USUARIO_EDITAR,
} from "../types/MantenedorPerfilesUsuarioTypes";

const INITIAL_STATE = {
  loading: false,
  modalPerfilUsuario: false,
  modalPerfilUsuarioEditar: false,
  permisosAccesos: [],
  permisoAcceso: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {

    case OCULTAR_MODAL_PERFIL_USUARIO_EDITAR:
      return {
        ...state,
        modalPerfilUsuarioEditar: false,
      };

    case OBTENER_PERFIL_ACCESO:
      return {
        ...state,
        loading: false,
        permisoAcceso: payload,
        modalPerfilUsuarioEditar: true,
      };

    case OBTENER_PERFILES_ACCESO:
      return {
        ...state,
        loading: false,
        permisosAccesos: payload,
      };

    case OCULTAR_MODAL_PERFIL_USUARIO:
      return {
        ...state,
        modalPerfilUsuario: false,
      };

    case MOSTRAR_MODAL_PERFIL_USUARIO:
      return {
        ...state,
        modalPerfilUsuario: true,
      };

    case LOADING:
      return {
        ...state,
        loading: true,
      };

    case PARAR_LOADING:
      return {
        ...state,
        loading: false,
      };

    case ERROR:
      return {
        ...state,
      };

    default:
      return state;
  }
};
