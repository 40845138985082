import {
  LOADING,
  ERROR,
  //OBTENER_TRABAJADORES,
  OBTENER_CENTROCOSTOS,
  OBTENER_FAENAS,
  MOSTRAR_MODAL_EMBARCO,
  OCULTAR_MODAL_EMBARCO,
  MOSTRAR_MODAL_DESEMBARCO,
  OCULTAR_MODAL_DESEMBARCO,
  OBTENER_TRABAJADOR_ID,
  //PARAR_LOADING,
  OBTENER_TRABAJADOR_ID_CENTROCOSTO,
} from "../types/FiltroTrabajadoresTypes";
import API from "../Api/api";
import Swal from "sweetalert2";
import moment from "moment";

import {
  convertirFechaString,
  // convertirFechaUnixAMoment

} from '../../funcionesGenericas/fechas';

export const ObtenerTrabajadoresCentroCosto = (
  idCentroCosto,
  fechaInicio,
  fechaFin,
  idEmpresa
) => async (dispatch) => {

  dispatch({ type: LOADING });

  try {
    let url;

    if (idCentroCosto != null && idCentroCosto !== "") {
      url = `${API}/CheckIn_CheckOut/Trabajadores?centroDeCosto=${idCentroCosto}&idEmpresa=${idEmpresa}`;
    } else {
      url = `${API}/CheckIn_CheckOut/Trabajadores?idEmpresa=${idEmpresa}`;
    }

    const objRangoFecha = {
      fecha_inicio: moment(fechaInicio).add(-1, "day").set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }),
      fecha_fin: moment(fechaFin).add(1, "day").set({
        hour: 23,
        minute: 60,
        second: 0,
        millisecond: 0,
      }),
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objRangoFecha),
    });

    const status = await response.status;

    let data;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();
        let dataOrdenar = data.data;

        const objCheckInCheckOut = generarOrdenCheckInCheckOut(
          dataOrdenar,
          fechaInicio,
          fechaFin
        );

        dispatch({
          type: OBTENER_TRABAJADOR_ID_CENTROCOSTO,
          payload: objCheckInCheckOut,
        });
        break;
      default:
        // const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "No hay trabajadores planificados para las fechas seleccionadas.",
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerTrabajadoresCentroCostoUnix = (
  idCentroCosto,
  fechaInicio,
  fechaFin,
  idEmpresa,
  inicioUnix,
  finUnix
) => async (dispatch) => {
  // console.log(idCentroCosto,
  //   fechaInicio,
  //   fechaFin,
  //   idEmpresa,
  //   inicioUnix,
  //   finUnix,"enviados");
  dispatch({ type: LOADING });

  try {
    let url;

    // if (idCentroCosto != null && idCentroCosto !== "") {
    //   url = `${API}/CheckIn_CheckOut/Trabajadores?centroDeCosto=${idCentroCosto}&idEmpresa=${idEmpresa}`;
    // } else {
    //   url = `${API}/CheckIn_CheckOut/Trabajadores?idEmpresa=${idEmpresa}`;
    // }
    
    // /Ckeck-in/Trabajadores Api nueva
    if (idCentroCosto != null && idCentroCosto !== "") {
      //url = `${API}/Ckeck-in/Trabajadores?centroDeCosto=${idCentroCosto}&idEmpresa=${idEmpresa}`;
      url = `${API}/Ckeck-in/Trabajadores?idEmpresa=${idEmpresa}&centroDeCosto=${idCentroCosto}`;
    } else {
      url = `${API}/Ckeck-in/Trabajadores?idEmpresa=${idEmpresa}`;
    }

    const objRangoFecha = {
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objRangoFecha),
    });

    const status = await response.status;

    let data;
    switch (status) {
      case 200:
      case 201:
        data = await response.json();
        //let dataOrdenar = data.data;
        let dataOrdenar = data;
        const objCheckInCheckOut = generarOrdenCheckInCheckOutUnix(
          dataOrdenar,
          fechaInicio,
          fechaFin,
          inicioUnix,
          finUnix
        );

        dispatch({
          type: OBTENER_TRABAJADOR_ID_CENTROCOSTO,
          payload: objCheckInCheckOut,
        });
        break;
      default:
        // const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "No hay trabajadores planificados para las fechas seleccionadas.",
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerCentroCostos = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/CentroDeCostos?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      const data = await response.json();

      switch (status) {
        case 200:
        case 201:
          const centroCostos = data.centrosDeCostos;

          dispatch({
            type: OBTENER_CENTROCOSTOS,
            payload: centroCostos,
          });
          break;

        default:
          const error = data.err.message.toString();

          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });

          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerFaenas = (idCentroCosto) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Faena/CentroDeCosto/${idCentroCosto}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let faenas = data.faena;

        dispatch({ type: OBTENER_FAENAS, payload: faenas });
        break;

      default:
        const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const enviarCorreoCheckOut = (
  bitacora,
  faena,
  idCentroCosto_E,
  fechaInicio_E,
  fechaFin_E,
  idEmpresa
) => async (dispatch) => {
  dispatch({ type: LOADING });

  let urlBitacora = `${API}/BitacoraTrabajadores`;
  let dataBitacora;
  const responseBitacora = await fetch(urlBitacora, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      mode: "no-cors",
    },
    body: JSON.stringify([bitacora]),
  });

  const statusBitacora = responseBitacora.status;

  switch (statusBitacora) {
    case 200:
    case 201:
      const url = `${API}/Enviar/Correo/CheckOut/${bitacora.IdTrabajador._id}`;

      const checkOutCorreo = {
        centroCosto: faena.Centro_De_Costo.Nombre,
        faena: faena.Nombre,
      };

      const responseCorreo = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          mode: "no-cors",
        },
        body: JSON.stringify(checkOutCorreo),
      });

      const statusCorreo = responseCorreo.status;
      let dataCorreo;

      switch (statusCorreo) {
        case 200:
        case 201:
          let urlBuscarTrabajadores;

          if (idCentroCosto_E != null && idCentroCosto_E !== "") {
            urlBuscarTrabajadores = `${API}/CheckIn_CheckOut/Trabajadores?centroDeCosto=${idCentroCosto_E}&idEmpresa=${idEmpresa}`;
          } else {
            urlBuscarTrabajadores = `${API}/CheckIn_CheckOut/Trabajadores?idEmpresa=${idEmpresa}`;
          }

          let fecha_ini;
          let fecha_fin;
          let str = moment().format('yyyy/MM/DD');
          
          if (fechaInicio_E !== "" && fechaFin_E !== "") {
            // fecha_ini = fechaInicio_E == "" || fechaInicio_E == null ? moment() : fechaInicio_E;
            // fecha_fin = fechaFin_E == "" || fechaFin_E == null ? moment() : fechaFin_E;

            fecha_ini = fechaInicio_E == "" || fechaInicio_E == null ? convertirFechaString(moment(str), 1).fechaString : fechaInicio_E;
            fecha_fin = fechaFin_E == "" || fechaFin_E == null ? convertirFechaString(moment(str), 2).fechaString : fechaFin_E;
          }

          // const objRangoFecha = {
          //   fecha_inicio: moment(fecha_ini).add(-1, "day").set({
          //     hour: 0,
          //     minute: 0,
          //     second: 0,
          //     millisecond: 0,
          //   }),
          //   fecha_fin: moment(fecha_fin).add(1, "day").set({
          //     hour: 23,
          //     minute: 60,
          //     second: 0,
          //     millisecond: 0,
          //   }),
          // };

          const objRangoFecha = {
            fecha_inicio: moment.utc(fecha_ini),
            fecha_fin: moment.utc(fecha_fin),
          };

          const responseBuscarTrabajadores = await fetch(
            urlBuscarTrabajadores,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                mode: "no-cors",
              },
              body: JSON.stringify(objRangoFecha),
            }
          );

          const statusBuscarTrabajadores = responseBuscarTrabajadores.status;
          let BuscarTrabajadores = null;

          switch (statusBuscarTrabajadores) {
            case 200:
            case 201:
              BuscarTrabajadores = await responseBuscarTrabajadores.json();

              let dataOrdenar = BuscarTrabajadores.data;

              const objCheckInCheckOut = generarOrdenCheckInCheckOut(
                dataOrdenar,
                fechaInicio_E,
                fechaFin_E
              );

              dispatch({
                type: OBTENER_TRABAJADOR_ID_CENTROCOSTO,
                payload: objCheckInCheckOut,
              });

              Swal.fire({
                title: "Bien",
                text: "Se realizó el Check-Out",
                icon: "success",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "Continuar",
              });

              break;
            case 404:
              dataCorreo = await responseBuscarTrabajadores.json();

              Swal.fire("Error", "Servicio no encontrado", "error");
              dispatch({ type: ERROR, payload: dataCorreo.err });

              break;
            default:
              dataCorreo = await responseBuscarTrabajadores.json();

              Swal.fire(
                "Error",
                `Error al realizar la petición, Status: ${statusBuscarTrabajadores}`,
                "error"
              );
              dispatch({ type: ERROR, payload: dataCorreo.err });
              break;
          }

          break;
        case 404:
          dataCorreo = await responseCorreo.json();

          Swal.fire("Error", "Servicio no encontrado", "error");
          dispatch({ type: ERROR, payload: dataCorreo.err });

          break;
        default:
          dataCorreo = await responseCorreo.json();

          Swal.fire(
            "Error",
            `Error al realizar la petición, Status: ${statusCorreo}`,
            "error"
          );
          dispatch({ type: ERROR, payload: dataCorreo.err });
          break;
      }
      break;
    case 404:
      dataBitacora = await responseBitacora.json();

      Swal.fire("Error", "Servicio no encontrado", "error");
      dispatch({ type: ERROR, payload: dataBitacora.err });

      break;
    default:
      dataBitacora = await responseBitacora.json();

      Swal.fire(
        "Error",
        `Error al realizar la petición, Status: ${statusBitacora}`,
        "error"
      );
      dispatch({ type: ERROR, payload: dataBitacora.err });
      break;
  }
};

export const actualizarBitacorasParaEmbarco = (
  bitacora,
  faena,
  idCentroCosto_E,
  fechaInicio_E,
  fechaFin_E,
  idEmpresa,
  inicioUnix,
  finUnix
) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {

    const url = `${API}/Faena/checkIn/Trabajador`;
    
    //se le agrega a la bitacora el id de la empresa para poder hacer la actualizacion de colores en las faenas
    //revisar logica en la api antes de devolver response con status 201
    bitacora.idEmpresa = idEmpresa;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(bitacora),
    });

    const status = response.status;
    let data = null;

    //Switch Nuevo (Sin Correo)
    switch (status) {
      case 200:
      case 201:
            let urlBuscarTrabajadoresReady;
            if (idCentroCosto_E != null && idCentroCosto_E !== "") {
              urlBuscarTrabajadoresReady = `${API}/Ckeck-in/Trabajadores?centroDeCosto=${idCentroCosto_E}&idEmpresa=${idEmpresa}`;
            } else {
              urlBuscarTrabajadoresReady = `${API}/Ckeck-in/Trabajadores?idEmpresa=${idEmpresa}`;
            }
            let fecha_ini;
            let fecha_fin;
            let strReady = moment().format('yyyy/MM/DD');
            if (fechaInicio_E !== "" && fechaFin_E !== "") {
              fecha_ini = fechaInicio_E == "" || fechaInicio_E == null ? convertirFechaString(moment(strReady), 1).fechaString : fechaInicio_E;
              fecha_fin = fechaFin_E == "" || fechaFin_E == null ? convertirFechaString(moment(strReady), 2).fechaString : fechaFin_E;
            }
            const objRangoFechaReady = {
              fecha_inicio: fechaInicio_E,
              fecha_fin: fechaFin_E
            };

            const responseBuscarTrabajadoresReady = await fetch(urlBuscarTrabajadoresReady, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                mode: "no-cors",
              },
              body: JSON.stringify(objRangoFechaReady),
            });

            const statusBuscarTrabajadoresReady = responseBuscarTrabajadoresReady.status;
            let BuscarTrabajadoresReady = null;
            switch (statusBuscarTrabajadoresReady) {
              case 200:
              case 201:
                BuscarTrabajadoresReady = await responseBuscarTrabajadoresReady.json();
                //let dataOrdenar = BuscarTrabajadoresReady.data;
                let dataOrdenar = BuscarTrabajadoresReady;

                const objCheckInCheckOut = generarOrdenCheckInCheckOutUnix(
                  dataOrdenar,
                  fecha_ini,
                  fecha_fin,
                  inicioUnix,
                  finUnix
                );

                if (statusBuscarTrabajadoresReady === 200 || statusBuscarTrabajadoresReady === 201) {

                  Swal.fire({
                    title: "Bien",
                    text: "Se realizó el Check-in: el trabajador está listo para marcar asistencia.",
                    icon: "success",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "Continuar",
                  });
                  dispatch({
                        type: OBTENER_TRABAJADOR_ID_CENTROCOSTO,
                        payload: objCheckInCheckOut,
                  });
        
                } 
             
                break;
              default:
                //dataCorreo = await response.json();

                Swal.fire(
                  "Error",
                  `Error al realizar la petición. Al buscar trabajadores, Status: ${statusBuscarTrabajadoresReady}`,
                  "error"
                );
                dispatch({ type: ERROR, payload: data.err });
                break;
            }
            break;
      default:
             Swal.fire(
              "Error",
              `Error al realizar el checkin, Status: ${status}`,
              "error"
            );
            dispatch({ type: ERROR, payload: data.err });
        break;
    }


    //Switch Antiguo con correo
    // switch (status) {
    //   case 200:
    //   case 201:
    //     //Funcionalidad Mala Correo
    // //     const urlCorreo = `${API}/Enviar/Correo/CheckIn/${bitacora.IdTrabajador._id}`;
    // //     let Plaza = {};
    // //     Plaza.centroCosto = bitacora.IdFaena.Centro_De_Costo.Nombre;
    // //     Plaza.faena = faena.Nombre;
    // //     Plaza.codigo = bitacora.IdTrabajador.Codigo_Base;
    // //     Plaza.fechaInicio = bitacora.Inicio_Planificacion;
    // //     Plaza.fechaFin = bitacora.Fin_Planificacion;
    // //     Funcion buena
    //     const urlCorreo = `${API}/Enviar/Correo/CheckIn/${bitacora.IdTrabajadorx}`;
    //     let Plaza = {};
    //     Plaza.centroCosto = bitacora.Centro_Costos;
    //     Plaza.faena = faena.Nombre;//valor malo
    //     Plaza.codigo = bitacora.IdTrabajador.Codigo_Base;//valor malo
    //     Plaza.fechaInicio = bitacora.Inicio_Planificacion;
    //     Plaza.fechaFin = bitacora.Fin_Planificacion;

    //     const responseCorreo = await fetch(urlCorreo, {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Accept: "application/json",
    //         mode: "no-cors",
    //       },
    //       body: JSON.stringify(Plaza),
    //     });

    //     const statusCorreo = responseCorreo.status;
    //     console.log(statusCorreo,'statusCorreo');
    //     let dataCorreo = null;

    //     switch (statusCorreo) {
    //       case 200:
    //       case 201: 
    //       console.log(statusCorreo,"switch 200/201");
    //       default:
    //         console.log(statusCorreo,"default");
    //         let urlBuscarTrabajadores;
    //         console.log(idCentroCosto_E,idEmpresa,'idEmpresa',"idCentroCosto_ECheking antiguo apis");
    // //         Apis antiguas
    // //         if (idCentroCosto_E != null && idCentroCosto_E !== "") {
    // //           urlBuscarTrabajadores = `${API}/CheckIn_CheckOut/Trabajadores?centroDeCosto=${idCentroCosto_E}&idEmpresa=${idEmpresa}`;
    // //         } else {
    // //           urlBuscarTrabajadores = `${API}/CheckIn_CheckOut/Trabajadores?idEmpresa=${idEmpresa}`;
    // //         }
    //         if (idCentroCosto_E != null && idCentroCosto_E !== "") {
    //           urlBuscarTrabajadores = `${API}/Ckeck-in/Trabajadores?centroDeCosto=${idCentroCosto_E}&idEmpresa=${idEmpresa}`;
    //         } else {
    //           urlBuscarTrabajadores = `${API}/Ckeck-in/Trabajadores?idEmpresa=${idEmpresa}`;
    //         }

    //         let fecha_ini;
    //         let fecha_fin;
    //         let str = moment().format('yyyy/MM/DD');

    //         if (fechaInicio_E !== "" && fechaFin_E !== "") {

    //           fecha_ini = fechaInicio_E == "" || fechaInicio_E == null ? momentToStringFechaInicio(moment(), databaseFormat) : fechaInicio_E;
    //           fecha_fin = fechaFin_E == "" || fechaFin_E == null ? momentToStringFechaFin(moment(), databaseFormat) : fechaFin_E;

    //           fecha_ini = fechaInicio_E == "" || fechaInicio_E == null ? convertirFechaString(moment(str), 1).fechaString : fechaInicio_E;
    //           fecha_fin = fechaFin_E == "" || fechaFin_E == null ? convertirFechaString(moment(str), 2).fechaString : fechaFin_E;
    //         }

    //         const objRangoFecha = {
    //           fecha_inicio: fechaInicio_E,
    //           fecha_fin: fechaFin_E
    //         };

    //         const responseBuscarTrabajadores = await fetch(
    //           urlBuscarTrabajadores,
    //           {
    //             method: "POST",
    //             headers: {
    //               "Content-Type": "application/json",
    //               Accept: "application/json",
    //               mode: "no-cors",
    //             },
    //             body: JSON.stringify(objRangoFecha),
    //           }
    //         );
    //         const statusBuscarTrabajadores = responseBuscarTrabajadores.status;
    //         console.log(statusBuscarTrabajadores,"statusBuscarTrabajadores");
    //         let BuscarTrabajadores = null;

    //         switch (statusBuscarTrabajadores) {
    //           case 200:
    //           case 201:
    //             BuscarTrabajadores = await responseBuscarTrabajadores.json();

    //             let dataOrdenar = BuscarTrabajadores.data;

    //             const objCheckInCheckOut = generarOrdenCheckInCheckOutUnix(
    //               dataOrdenar,
    //               fecha_ini,
    //               fecha_fin,
    //               inicioUnix,
    //               finUnix
    //             );

    //             dispatch({
    //               type: OBTENER_TRABAJADOR_ID_CENTROCOSTO,
    //               payload: objCheckInCheckOut,
    //             });


    //             if (statusCorreo === 200 || statusCorreo === 201) {

    //               Swal.fire({
    //                 title: "Bien",
    //                 text: "Se realizó el Check-in: el trabajador está listo para marcar asistencia.",
    //                 icon: "success",
    //                 confirmButtonColor: "#E60AA3",
    //                 confirmButtonText: "Continuar",
    //               });
    //             } else {
    //               Swal.fire({
    //                 title: "Bien",
    //                 text: "Se realizó el Check-in: el trabajador está listo para marcar asistencia.Pero no se logro enviar el correo. Si el trabajador no posee correo no le tome importancia a este mensaje",
    //                 icon: "success",
    //                 confirmButtonColor: "#E60AA3",
    //                 confirmButtonText: "Continuar",
    //               });
    //             }

    //             break;
    //           case 404:
    //             dataCorreo = await response.json();

    //             Swal.fire("Error", "Servicio no encontrado", "error");
    //             dispatch({ type: ERROR, payload: dataCorreo.err });

    //             break;
    //           default:
    //             dataCorreo = await response.json();

    //             Swal.fire(
    //               "Error",
    //               `Error al realizar la petición. Al buscar trabajadores, Status: ${statusBuscarTrabajadores}`,
    //               "error"
    //             );
    //             dispatch({ type: ERROR, payload: dataCorreo.err });
    //             break;
    //         }
      
    //         break;
    //       case 404:
    //         dataCorreo = await response.json();

    //         Swal.fire("Error", "Servicio no encontrado", "error");
    //         dispatch({ type: ERROR, payload: dataCorreo.err });

    //         break;
    //       default:
    //         dataCorreo = await response.json();

    //         Swal.fire(
    //           "Error",
    //           `Error al realizar el envio del correo, Status: ${statusCorreo}`,
    //           "error"
    //         );
    //         dispatch({ type: ERROR, payload: dataCorreo.err });
    //         break;
    //     }
        
    //     break;
    //   case 404:
    //     data = await response.json();

    //     Swal.fire("Error", "Servicio no encontrado", "error");
    //     dispatch({ type: ERROR, payload: data.err });
    //     break;
    //   default:
    //     data = await response.json();

    //     Swal.fire(
    //       "Error",
    //       `Error al realizar la petición, Status: ${status}`,
    //       "error"
    //     );
    //     dispatch({ type: ERROR, payload: data.err });
    //     break;
    // }

  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerFaenaPorId = (bitacora) => async (dispatch) => {

  dispatch({ type: LOADING });

  try {
    const url = `${API}/Faena/${bitacora.IdFaena._id}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = await response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const FaenaCompleta = data.faena;

        let objetoEmbarcar = {
          trabajador: bitacora.IdTrabajador,
          CentroDeCostoModal: bitacora.IdFaena.Centro_De_Costo.Nombre,

          // fechaInicioCheckIn: moment.unix(bitacora.Inicio_Planificacion_Unix).format('DD-MM-YYYY'),
          // fechaFinCheckOut: moment.unix(bitacora.Fin_Planificacion_Unix).format('DD-MM-YYYY'),

          fechaInicioCheckIn: convertirFechaString(moment.utc(bitacora.Inicio_Planificacion), 1).fechaDDMMYYYY,
          fechaFinCheckOut: convertirFechaString(moment.utc(bitacora.Fin_Planificacion), 2).fechaDDMMYYYY,

          bitacora: bitacora,
          Faena: FaenaCompleta,
        };

        dispatch({
          type: OBTENER_TRABAJADOR_ID,
          payload: objetoEmbarcar,
        });

        return bitacora.IdTrabajador;
        break;

      default:
        const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const MostrarModalEmbarco = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_EMBARCO });
};

export const OcultarModalEmbarco = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_EMBARCO });
};

export const MostrarModalDesembarco = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_DESEMBARCO });
};

export const OcultarModalDesembarco = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_DESEMBARCO });
};

const generarOrdenCheckInCheckOut = (checkin, fecha_inicio, fecha_fin) => {
  const yaCubiertas = "5f3ef6981ecf531c1c8ab4aa";
  const enCurso = "5f3ef6861ecf531c1c8ab4a9";
  const reservada = "5f3ef6591ecf531c1c8ab4a8";
  //const sinCubrir = "5f3ef5ce1ecf531c1c8ab4a7";

  const fecha_inicio_p = moment(fecha_inicio).format("YYYY-MM-DD");
  const fecha_fin_p = moment(fecha_fin).format("YYYY-MM-DD");

  let arrayCheckIn = [];
  let arrayCheckOut = [];

  let listadosCheks = checkin;

  listadosCheks.forEach((check) => {

    if (check.Estado !== undefined && check.Estado !== null) {

      let fecha = moment(check.Fecha).format("YYYY-MM-DD");

      if (moment(fecha).isBetween(fecha_inicio_p, fecha_fin_p, null, "[]")) {
        if (
          check.Inicio_Planificacion !== undefined &&
          check.Inicio_Planificacion !== "undefined" &&
          check.Fin_Planificacion !== undefined &&
          check.Fin_Planificacion !== "undefined"
        ) {
          //let cantidad de entradas

          if (check.Estado._id === reservada) {
            if (
              moment(check.Inicio_Planificacion).utc().format("YYYY-MM-DD") ===
              moment(check.Fecha).utc().format("YYYY-MM-DD")
            ) {
              check.fechaFormateada = moment(check.Fecha).format("DD-MM-YYYY");
              check.FechaBusqueda = moment(check.Fecha).format("YYYY-MM-DD");
              arrayCheckIn.push(check);
            }
          }

          // if (
          //   check.Estado._id === yaCubiertas ||
          //   check.Estado._id === enCurso
          // ) {
          //   if (
          //     moment(check.Fin_Planificacion).utc().format("YYYY-MM-DD") ===
          //     moment(check.Fecha).utc().format("YYYY-MM-DD") &&
          //     check.SeEnvioCheckOut !== true
          //   ) {
          //     let botonHabilitado =
          //       check.Estado._id === "5f3ef6861ecf531c1c8ab4a9" ? true : false;
          //     check.botonHabilitado = botonHabilitado;
          //     let fecha = moment(check.Fecha).format("DD-MM-YYYY HH:MM");
          //     check.fechaFormateada = fecha;
          //     check.FechaBusqueda = moment(check.Fecha).format("YYYY-MM-DD");
          //     arrayCheckOut.push(check);
          //   }
          // }
        }
      }
    }
  });

  const objCheckInCheckOut = {
    arrayCheckIn,
    arrayCheckOut,
  };
  return objCheckInCheckOut;
};

const generarOrdenCheckInCheckOutUnix = (checkin, fecha_inicio, fecha_fin, inicioUnix, finUnix) => {

  const yaCubiertas = "5f3ef6981ecf531c1c8ab4aa";
  const enCurso = "5f3ef6861ecf531c1c8ab4a9";
  const reservada = "5f3ef6591ecf531c1c8ab4a8";

  const fecha_inicio_p = moment.utc(fecha_inicio);
  const fecha_fin_p = moment.utc(fecha_fin);

  let arrayCheckIn = [];
  let arrayCheckOut = [];
  let listadosCheks = checkin;


  //ES IGUAL A BITACORAS
  listadosCheks.forEach((check) => {

    if (check.Estado !== undefined && check.Estado !== null) {

      let fechaDesde = moment.utc(check.Fecha);

      if (fechaDesde.isBetween(fecha_inicio_p, fecha_fin_p, null, "[]")) {
        if (
          check.Inicio_Planificacion !== undefined &&
          check.Inicio_Planificacion !== "undefined" &&
          check.Fin_Planificacion !== undefined &&
          check.Fin_Planificacion !== "undefined"
        ) {

          //let cantidad de entradas

          if (check.Estado._id === reservada) {
            if (moment.utc(check.Inicio_Planificacion).isSame(fechaDesde)) {

              check.fechaFormateada = moment.utc(check.Fecha).format("DD-MM-YYYY");
              check.FechaBusqueda = moment.utc(check.Fecha).format("YYYY-MM-DD");

              arrayCheckIn.push(check);
            }
          }
        }
      }
    }
    //24-01-2022 se agrego
    arrayCheckIn.push(check);
  });


  // const fecha_inicio_p = moment.unix(inicioUnix);
  // const fecha_fin_p = moment.unix(finUnix);

  // console.log("Fechas generar orden check in check out unix");
  // console.log(inicioUnix);
  // console.log(finUnix);
  // console.log("Fechas generar orden check in check out unix 2");
  // console.log(fecha_inicio_p);
  // console.log(fecha_fin_p);

  // let arrayCheckIn = [];
  // let arrayCheckOut = [];

  // let listadosCheks = checkin;

  // listadosCheks.forEach((check) => {

  //   if (check.Estado !== undefined && check.Estado !== null) {

  //     let fecha = moment(check.Fecha).format("YYYY-MM-DD");
  //     let fechaDesdeUnix = moment.unix(check.FechaUnix);

  //     if (fechaDesdeUnix.isBetween(fecha_inicio_p, fecha_fin_p, null, "[]")) {

  //       if (
  //         check.Inicio_Planificacion !== undefined &&
  //         check.Inicio_Planificacion !== "undefined" &&
  //         check.Fin_Planificacion !== undefined &&
  //         check.Fin_Planificacion !== "undefined"
  //       ) {
  //         //let cantidad de entradas

  //         if (check.Estado._id === reservada) {
  //           if (
  //             moment.unix(check.Inicio_Planificacion_Unix).format("YYYY-MM-DD") === fechaDesdeUnix.format("YYYY-MM-DD")) {

  //             check.fechaFormateada = moment.unix(check.FechaUnix).format("DD-MM-YYYY");
  //             check.FechaBusqueda = moment.unix(check.FechaUnix).format("YYYY-MM-DD");

  //             arrayCheckIn.push(check);
  //           }
  //         }






  //         // if (
  //         //   check.Estado._id === yaCubiertas ||
  //         //   check.Estado._id === enCurso
  //         // ) {
  //         //   if (
  //         //     moment.unix(check.Fin_Planificacion_Unix).format("YYYY-MM-DD") ===
  //         //     moment.unix(check.FechaUnix).format("YYYY-MM-DD") &&
  //         //     (check.SeEnvioCheckOut !== true)
  //         //   ) {

  //         //     let botonHabilitado = check.Estado._id === enCurso ? true : false;
  //         //     check.botonHabilitado = botonHabilitado;

  //         //     let fecha = moment.unix(check.FechaUnix).format("DD-MM-YYYY HH:MM");
  //         //     check.fechaFormateada = fecha;

  //         //     check.FechaBusqueda = moment.unix(check.FechaUnix).format("YYYY-MM-DD");
  //         //     arrayCheckOut.push(check);
  //         //   }
  //         // }
  //       }
  //     }
  //   }
  // });

  const objCheckInCheckOut = {
    //arrayCheckIn:listadosCheks,
    arrayCheckIn,
    arrayCheckOut,
  };

  return objCheckInCheckOut;
};
/////////////////nueva logica para bustrar check in

export const ObtenerTrabajadoresCentroCostoNuevaForma = (
  idCentroCosto,
  fechaInicio,
  fechaFin,
  idEmpresa,
  // inicioUnix,
  // finUnix
) => async (dispatch) => {

  dispatch({ type: LOADING });

  try {
    let url;

    if (idCentroCosto != null && idCentroCosto !== "") {
      url = `${API}/Ckeck-in/Trabajadores?centroDeCosto=${idCentroCosto}&idEmpresa=${idEmpresa}`;
    } else {
      url = `${API}/Ckeck-in/Trabajadores?idEmpresa=${idEmpresa}`;
    }

    const objRangoFecha = {
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objRangoFecha),
    });

    const status = await response.status;

    let data;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();
        dispatch({
          type: OBTENER_TRABAJADOR_ID_CENTROCOSTO,
          payload: {
            arrayCheckIn:data,
            arrayCheckOut:[],
          },
        });
        break;
      default:
        // const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "No hay trabajadores planificados para las fechas seleccionadas.",
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: "Error no validado. No hay trabajadores planificados para las fechas seleccionadas.",
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerDatosParaCheckInTrabajador = (objBusqueda, idEmpresa) => async (dispatch) => {

  dispatch({ type: LOADING });

  try {

    const url = `${API}/Ckeck-in/Busqueda/Datos?idEmpresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objBusqueda),
    });

    const status = await response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        //const FaenaCompleta = data.faena;

        // let objetoEmbarcar = {
        //   trabajador: bitacora.IdTrabajador,
        //   CentroDeCostoModal: bitacora.IdFaena.Centro_De_Costo.Nombre,

        //   // fechaInicioCheckIn: moment.unix(bitacora.Inicio_Planificacion_Unix).format('DD-MM-YYYY'),
        //   // fechaFinCheckOut: moment.unix(bitacora.Fin_Planificacion_Unix).format('DD-MM-YYYY'),

        //   fechaInicioCheckIn: convertirFechaString(moment.utc(bitacora.Inicio_Planificacion), 1).fechaDDMMYYYY,
        //   fechaFinCheckOut: convertirFechaString(moment.utc(bitacora.Fin_Planificacion), 2).fechaDDMMYYYY,

        //   bitacora: bitacora,
        //   Faena: FaenaCompleta,
        // };

        dispatch({
          type: OBTENER_TRABAJADOR_ID,
          payload: data,
        });

        //dispatch({ type: ERROR, payload:"error por ahora" });

        return data;
      default:
        const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: "Error status: " + 500 + " ,detalle: Error no controlado",
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

export const GenerarCheckInMultiple = (
  arregloPersonas, 
  idEmpresa, 
  fechaInicio_E,
  fechaFin_E,
  inicioUnix,
  finUnix
  ) => async (dispatch) => {

    dispatch({ type: LOADING });

    try {

      let err;
      let objetoEnviar = {
        arregloPersonas
      }

      const urlObtenerDatos = `${API}/Check-in-Multiple/Busqueda/Datos?idEmpresa=${idEmpresa}`;

      const responseObtenerDatos = await fetch(urlObtenerDatos, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objetoEnviar),
      });

      const statusObtenerDatos = await responseObtenerDatos.status;
      const datosTrabajadoresCheckIn = await responseObtenerDatos.json();
      
      switch (statusObtenerDatos) {
        case 200:
        case 201:

          let arrayBitacoras = [];

          datosTrabajadoresCheckIn.data.forEach(infoTrabajador => {
            let objeto = infoTrabajador.bitacora;
            objeto.idEmpresa = idEmpresa;
            arrayBitacoras.push(objeto);
          });

          const urlCambiarColorFaenas = `${API}/Faena/Check-in-Multiple/Trabajador`;

          //se le agrega a la bitacora el id de la empresa para poder hacer la actualizacion de colores en las faenas
          //revisar logica en la api antes de devolver response con status 201

          const responseColorFaenas = await fetch(urlCambiarColorFaenas, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              mode: "no-cors",
            },
            body: JSON.stringify(arrayBitacoras),
          });

        
          const statusColorFaenas = await responseColorFaenas.status;
        

          switch (statusColorFaenas) {
            case 200:
            case 201:

                  let urlBuscarTrabajadoresReady = `${API}/Ckeck-in/Trabajadores?idEmpresa=${idEmpresa}`;

                  let fecha_ini;
                  let fecha_fin;
                  let strReady = moment().format('yyyy/MM/DD');

                  if (fechaInicio_E !== "" && fechaFin_E !== "") {
                    fecha_ini = fechaInicio_E == "" || fechaInicio_E == null ? convertirFechaString(moment(strReady), 1).fechaString : fechaInicio_E;
                    fecha_fin = fechaFin_E == "" || fechaFin_E == null ? convertirFechaString(moment(strReady), 2).fechaString : fechaFin_E;
                  }

                  const objRangoFechaReady = {
                    fecha_inicio: fechaInicio_E,
                    fecha_fin: fechaFin_E
                  };

                  const responseBuscarTrabajadoresReady = await fetch(urlBuscarTrabajadoresReady, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      mode: "no-cors",
                    },
                    body: JSON.stringify(objRangoFechaReady),
                  });

                 

                  const statusBuscarTrabajadoresReady = await responseBuscarTrabajadoresReady.status;
                  let BuscarTrabajadoresReady = await responseBuscarTrabajadoresReady.json();

                  switch (statusBuscarTrabajadoresReady) {
                    case 200:
                    case 201:

                      const objCheckInCheckOut = generarOrdenCheckInCheckOutUnix(
                        BuscarTrabajadoresReady,
                        fecha_ini,
                        fecha_fin,
                        inicioUnix,
                        finUnix
                      );

                      if (statusBuscarTrabajadoresReady === 200 || statusBuscarTrabajadoresReady === 201) {

                        Swal.fire({
                          title: "Bien",
                          text: "Se realizó el Check-in: el trabajador está listo para marcar asistencia.",
                          icon: "success",
                          confirmButtonColor: "#E60AA3",
                          confirmButtonText: "Continuar",
                        });
                        dispatch({
                              type: OBTENER_TRABAJADOR_ID_CENTROCOSTO,
                              payload: objCheckInCheckOut,
                        });
                        
                        break;
              
                      } 
                  
                    

                    default:

                      Swal.fire({
                        title: "Error",
                        text: "Error al realizar la petición. Al buscar trabajadores, Status: " + statusBuscarTrabajadoresReady,
                        icon: "error"
                      });
                      dispatch({ type: ERROR, payload: BuscarTrabajadoresReady.err });
                      break;
                  }

            break;

            case 404:
              const dataCheckin1 = await responseColorFaenas.json();
              err = dataCheckin1.mensaje.toString;
              Swal.fire({
                title: "Uups",
                text: "Error al cambiar color status: " + statusColorFaenas + " ,detalle: " + err,
                icon: "error",
              });
              dispatch({ type: ERROR, payload: dataCheckin1.err });
            break;

            default:
              const dataCheckin2 = await responseColorFaenas.json();
              err = dataCheckin2.mensaje.toString;
              Swal.fire({
                title: "Error",
                text: "Error al realizar el checkin, Status: " + statusColorFaenas + " , detalle: " + err,
                icon: "error"
              });
              dispatch({ type: ERROR, payload: dataCheckin2.err });
            break;
          }

        break;

        case 404:

          err = datosTrabajadoresCheckIn.mensaje;
          Swal.fire({
            title: "Uups",
            text: "Error en datos de los trabajadores del checkin status: " + statusObtenerDatos + " ,detalle: " + err,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: datosTrabajadoresCheckIn.err });

        break;

        default:
          err = datosTrabajadoresCheckIn.err.message;
          Swal.fire({
            title: "Uups",
            text: "Error al obtener los datos de los trabajadores status: " + statusObtenerDatos + " ,detalle: " + err,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: datosTrabajadoresCheckIn.err });
        break;
      }

    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Uups",
        text: "Error status: " + 500 + " ,detalle: Error no controlado",
        icon: "error",
      });
      dispatch({ type: ERROR, payload: error });
    }
};