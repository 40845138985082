import React, { useEffect, useState } from "react";
import SubirFoto from "../subirFoto/SubirFoto";
import Trabajador from "../../img/icons/Trabajador.svg";
import personal from "../../img/icons/avatar.svg";
import contacto from "../../img/icons/telefono.svg";
import familiares from "../../img/icons/familiares.svg";
import flecha from "../../img/icons/flecha.svg";
import epp from "../../img/icons/epp.svg";
import especialidades_icon from "../../img/icons/icon_espe.png";
import bancario from "../../img/icons/bancarios.svg";
import previsional from "../../img/icons/previsional.svg";
import estudios from "../../img/icons/estudios.svg";
import Moment from "moment";
import Rut from "../../funcionesGenericas/rut";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import {
  Form,
  Input,
  Button,
  Card,
  Collapse,
  DatePicker,
  Row,
  Col,
  PageHeader,
  Select,
  Radio,
  InputNumber,
  Typography
} from "antd";
import Swal from "sweetalert2";

import './FichaTrabajador.css'
import moment from 'moment';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const FichaTrabajadorNuevo = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();
  const [validRutTrabajador, setValidRutTra] = useState('');
  const [tipoFechaContrato, setTipoFechaContrato] = useState(null);
  const [requirioProgre, setRequirioProgre] = useState(false);
  const [otroSelected, setOtroSelected] = useState(false);
  const [validateOtro, setValidateOtro] = useState('error');
  const [esRelevo, setEsRelevo] = useState(false);

  const ObtenerImagen = (imagenUrl) => {
    props.obtenerImagen(imagenUrl);
    props.TomarValoresEditar("Imagen", imagenUrl);
  };


  const condicionalFechaContrato = () => {

    if (tipoFechaContrato === 'Indefinido') {
      return (
        <DatePicker
          format="DD-MM-YYYY"
          locale={locale}
          className="campos"
          style={{
            width: "100%",
          }}
          disabled={true}
        />
      )
    } else {
      return (
        <RangePicker
          format="DD-MM-YYYY"
          locale={locale}
          className="campos"
          style={{
            width: "100%",
          }}
          disabled={true}
        />
      )
    }

  }

  const condicionalFechaProgresivas = () => {

    if (requirioProgre) {
      return (
        <Form.Item
          name={"Fecha_Progresivas"}
          label="¿Desde cuándo?"
          rules={[
            {
              required: true,
              message: "Obligatorio",
            },
          ]}
        >

          <DatePicker
            format="DD-MM-YYYY"
            locale={locale}
            className="campos"
            style={{
              width: "100%",
            }}
            disabled={true}
          />
        </Form.Item>
      )
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 9,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  let aux = props.Formulario._id;



  const {
    //datos iniciales
    Cargo,
    Rut,
    Nombre,
    Apellido_Paterno,
    Apellido_Materno,
    Fecha_De_Nacimiento,
    Calle,
    Numero_Calle,
    Tipo_De_Vivienda,
    Numero_Departamento,
    Region,
    Comuna,
    Ciudad,
    Correo,
    Nacionalidad,
    Sexo,
    RegimenDeTrabajo,
    Codigo_Base,
    Telefono,

    Titulos_Profesionales,
    Especialidades,
    TiposDeDocumentos,
    EPP,
    Relevo,
        //________CAMPOS NUEVO___________
    //Datos bancarios
    Numero_Cuenta_Bancaria,
    Banco,
    Forma_Pago,
    //Datos previsionales
    AFP,
    Sistema_Pension,
    Jubilado,
    Isapre_Fonasa,
    Isapre_Monetizacion,
    Isapre_UF,
    Isapre_Peso,
    Isapre_Cotizacion,
    //Datos otros estudios
    Nivel_Estudio,
    Licencia_Conducir,
    Tipo_Licencia_Conducir,

  } = props.Formulario;
 
  useEffect((aux) => {
    let {
      //datos iniciales
      Cargo,
      Rut,
      Nombre,
      Apellido_Paterno,
      Apellido_Materno,
      Fecha_De_Nacimiento,
      Calle,
      Numero_Calle,
      Tipo_De_Vivienda,
      Numero_Departamento,
      Region,
      Comuna,
      Ciudad,
      Correo,
      Nacionalidad,
      Sexo,
      RegimenDeTrabajo,
      Codigo_Base,
      Telefono,
      //datos de contacto

      Contacto_Nombre,
      Contacto_Correo,
      Contacto_Fono_Fijo,
      Contacto_Fono_Celular,

      //datos familiares
      Estado_Civil,
      Cantidad_Hijos,

      Titulos_Profesionales,
      Especialidades,
      TiposDeDocumentos,
      EPP,

      //Contrato y Vacaciones
      Tipo_Contrato,
      Relevo,
      Contrato_Fecha_Inicio,
      Contrato_Fecha_Fin,
      Requirio_Progresivas,
      Fecha_Progresivas,
      Meses_Empleadores_Anteriores,
      Regimen_Vacaciones,

        //________CAMPOS NUEVO___________
      //Datos bancarios
      Numero_Cuenta_Bancaria,
      Banco,
      Forma_Pago,
      //Datos previsionales
      AFP,
      Sistema_Pension,
      Jubilado,
      Isapre_Fonasa,
      Isapre_Monetizacion,
      Isapre_UF,
      Isapre_Peso,
      Isapre_Cotizacion,
      //Datos otros estudios
      Nivel_Estudio,
      Licencia_Conducir,
      Tipo_Licencia_Conducir,

    } = props.Formulario;
  
    setTipoFechaContrato(Tipo_Contrato);
    setRequirioProgre(Requirio_Progresivas);
    setEsRelevo(Relevo);

    if (Requirio_Progresivas) {

      form.setFieldsValue({
        Fecha_Progresivas: moment(Fecha_Progresivas),
      });

    }

    let fechaMoment = moment.utc(Fecha_De_Nacimiento);

    if (props.editarCerrado) {
      form.setFieldsValue({
        Rut,
      })
      props.cambiarEditarCerrado();
    }

    if (!Contrato_Fecha_Inicio) {
      form.setFieldsValue({
        Contrato_Fecha: null,
      })
    } else if (Tipo_Contrato === 'Indefinido') {
      form.setFieldsValue({
        Contrato_Fecha: moment.utc(Contrato_Fecha_Inicio),
      })

    } else {
      form.setFieldsValue({
        Contrato_Fecha: [moment.utc(Contrato_Fecha_Inicio), moment.utc(Contrato_Fecha_Fin)],
      })
    }

    if ((Regimen_Vacaciones !== 15 && Regimen_Vacaciones !== 20) && (Regimen_Vacaciones !== undefined && Regimen_Vacaciones !== null)) {

      form.setFieldsValue({
        Regimen_Vacaciones: -1,
        otro: Regimen_Vacaciones
      })

    } else {

      form.setFieldsValue({
        Regimen_Vacaciones,
      })
    }


    form.setFieldsValue({
      Cargo,
      Nombre,
      Apellido_Paterno,
      Apellido_Materno,
      Fecha_De_Nacimiento: fechaMoment,
      Calle,
      Numero_Calle,
      Tipo_De_Vivienda,
      Numero_Departamento,
      Region,
      Comuna,
      Ciudad,
      Correo,
      Nacionalidad,
      Sexo,
      RegimenDeTrabajo,
      Codigo_Base,
      Telefono,

      //datos de contacto

      Contacto_Nombre,
      Contacto_Correo,
      Contacto_Fono_Fijo,
      Contacto_Fono_Celular,

      //datos familiares
      Estado_Civil,
      Cantidad_Hijos,

      Titulos_Profesionales,
      Especialidades,
      TiposDeDocumentos,
      EPP,

      Tipo_Contrato,
      Relevo,
      Requirio_Progresivas,
      Meses_Empleadores_Anteriores,

        //________CAMPOS NUEVO___________
      //Datos bancarios
      Numero_Cuenta_Bancaria,
      Banco,
      Forma_Pago,
      //Datos previsionales
      AFP,
      Sistema_Pension,
      Jubilado,
      Isapre_Fonasa,
      Isapre_Monetizacion,
      Isapre_UF,
      Isapre_Peso,
      Isapre_Cotizacion,
      //Datos otros estudios
      Nivel_Estudio,
      Licencia_Conducir,
      Tipo_Licencia_Conducir,
    });
  }, [aux]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      initialValues={true}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>

        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={Trabajador} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Ficha del Trabajador "
          />
        </Col>

        <Col 
          xs={{ span: 24, offset: 0 }} 
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }} 
          lg={{ span: 8, offset: 0 }} 
          xl={{ span: 8, offset: 0 }}
          xxl={{ span: 8, offset: 0 }}
        >
          <Card className="card-perfil" style={{ width: "100%" }}>

            <Row justify="center">

              <Col span={8} style={{ marginRight: '90px', marginBottom: '20px' }}>
                <SubirFoto
                  avatar={props.Formulario.Imagen}
                  ObtenerImagen={ObtenerImagen}
                />
              </Col>

              <Col span={24}>
                <p style={{ fontFamily: 'MavenPro-Regular' }}>Te recomendamos que la fotografía no exceda los 5 MB de peso</p>

                <Card className={'codigos-card'}>
                  <div className={'codigo-wrapper'} style={{ marginBottom: '15px' }}>
                    <h3 style={{ fontFamily: 'MavenPro-SemiBold', margin: 0 }}>Código de sistema:</h3>
                    <h3 style={{ fontFamily: 'MavenPro-Regular', margin: 0 }}>{props.Formulario._id}</h3>
                  </div>

                  <div className={'codigo-wrapper'}>
                    <h3 style={{ fontFamily: 'MavenPro-SemiBold', margin: 0 }}>Código base:</h3>
                    <h3 style={{ fontFamily: 'MavenPro-Regular', margin: 0 }}>{props.Formulario.Codigo_Base}</h3>
                  </div>

                </Card>

              </Col>

            </Row>

          </Card>

        </Col>

        <Col
          xs={{ span: 24, offset: 0 }} 
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }} 
          lg={{ span: 15, offset: 1 }} 
          xl={{ span: 15, offset: 1 }}
          xxl={{ span: 15, offset: 1 }}
        >
          <Collapse
            bordered={false}
            defaultActiveKey={["0"]}
            className="formulario-trabajador site-collapse-custom-collapse "
          >

            {/* -----------------------------------FORMULARIO DATOS PERSONALES---------------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Datos Personales
                </h4>
              }
              key="1"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={personal}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >


              <Form.Item
                label="Seleccione Cargo Trabajador"
                style={{
                  marginTop: "20px",
                  WebkitTextDecorationStyle: 'dashed'
                }}
              >
                <Select
                  className="campos"
                  // name="Cargo"
                  placeholder="Selecciona cargo"
                  disabled={true}
                  defaultValue={Cargo.Nombre}
                >
                  <Select.Option key={'cargo'} name="Cargo">
                    {Cargo.Nombre}
                  </Select.Option>
                </Select>
              </Form.Item>


              <Form.Item
                label="RUT"
              >
                <Input
                  className="campos"
                  defaultValue={Rut}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Nombres"
              >
                <Input
                  className="campos"
                  defaultValue={Nombre}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Apellido paterno"
              >
                <Input
                  className="campos"
                  defaultValue={Apellido_Paterno}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Apellido materno"
              >
                <Input
                  className="campos"
                  defaultValue={Apellido_Materno}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Fecha de nacimiento"
              >
                <Input
                  className="campos"
                  defaultValue={moment.utc(Fecha_De_Nacimiento).format('DD-MM-YYYY')}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Calle"
              >
                <Input
                  className="campos"
                  defaultValue={Calle}
                  disabled={true}
                />
              </Form.Item>

              <Form.Item
                label="Número"
              >
                <Input
                  className="campos"
                  defaultValue={Numero_Calle}
                  disabled={true}
                />
              </Form.Item>




              <Form.Item
                label="Lugar"
              >
                <Input
                  className="campos"
                  defaultValue={Tipo_De_Vivienda}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="N° del Establecimiento"
              >
                <Input
                  className="campos"
                  defaultValue={Numero_Departamento}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Región"
              >
                <Input
                  className="campos"
                  defaultValue={Region.Nombre}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Provincia"
              >
                <Input
                  className="campos"
                  defaultValue={Ciudad.Nombre}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Comuna"
              >
                <Input
                  className="campos"
                  defaultValue={Comuna.Nombre}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Correo electrónico"
              >
                <Input
                  className="campos"
                  defaultValue={Correo}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Teléfono"
              >
                <Input
                  className="campos"
                  defaultValue={Telefono}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Nacionalidad"
              >
                <Input
                  className="campos"
                  defaultValue={Nacionalidad}
                  disabled={true}
                />
              </Form.Item>

              <Form.Item
                label="Sexo"
              >
                <Input
                  className="campos"
                  defaultValue={Sexo}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Régimen de trabajo"
              >
                <Input
                  className="campos"
                  defaultValue={RegimenDeTrabajo.Nombre}
                  disabled={true}
                />
              </Form.Item>


              <Form.Item
                label="Código base"
              >
                <Input
                  className="campos"
                  defaultValue={Codigo_Base}
                  disabled={true}
                />
              </Form.Item>


            </Panel>

            {/* -----------------------------------FORMULARIO DATOS DE CONTACTO---------------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Datos de Contacto de Emergencia
                </h4>
              }
              key="2"
              className=" site-collapse-custom-panel"
              extra={[
                <h4
                  key="1"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 55,
                    color: "#061fcb",
                  }}
                >
                  Opcional
                </h4>,
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={contacto}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                name={"Contacto_Nombre"}
                label="Nombres:"
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Contacto_Nombre", valor);
                  }}
                  name="Contacto_Nombre"
                  className="campos"
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                name={"Contacto_Correo"}
                label="Correo electrónico"
                rules={[
                  {
                    type: "email",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Contacto_Correo", valor);
                  }}
                  name="Contacto_Correo"
                  className="campos"
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                name={"Contacto_Fono_Fijo"}
                label="Fono Contacto Fijo"
              // hasFeedback
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Contacto_Fono_Fijo", valor);
                  }}
                  name={"Contacto_Fono_Fijo"}
                  className="campos"
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                name="Contacto_Fono_Celular"
                label="Fono Contacto Celular"
              // hasFeedback
              >
                <Input
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Contacto_Fono_Celular", valor);
                  }}
                  name="Contacto_Fono_Celular"
                  className="campos"
                  disabled={true}
                />
              </Form.Item>
            </Panel>

            {/* -----------------------------FORMULARIO DATOS FAMILIARES Y LEGALES------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Datos Familiares y Legales
                </h4>
              }
              key="3"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={familiares}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Estado civil"
                name="Estado_Civil"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir su estado civil",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  onChange={(value) => {
                    props.TomarValoresEditar(
                      "Estado_Civil",
                      value.target.value
                    );
                  }}
                  initialValues={false}
                  disabled={true}
                >
                  <Radio value={"Soltero/a"}>Soltero/a</Radio>
                  <Radio value={"Casado/a"}>Casado/a</Radio>
                  <Radio value={"Viudo/a"}>Viudo/a</Radio>
                  <Radio value={"Divorciado/a"}>Divorciado/a</Radio>
                </Radio.Group>
              </Form.Item>

              <hr className="division" />
              <br />
              <h3 style={{ textAlign: "center", fontFamily: "MavenPro-Bold" }}>
                Datos Hijos
              </h3>
              <Form.Item
                name="Cantidad_Hijos"
                label="Número de hijos:"
                rules={[
                  {
                    required: true,
                    message: "Se debe ingresar cantidad de hijos",
                  },
                ]}
              >
                <Input
                  name="Cantidad_Hijos"
                  className="campos"
                  onChange={(e) => {
                    let valor = e.target.value;
                    props.TomarValoresEditar("Cantidad_Hijos", valor);
                  }}
                  disabled={true}
                />
              </Form.Item>
            </Panel>

            {/* ---------------------------------------------TÍTULOS------------------------------------------------ */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Titulos
                </h4>
              }
              key="4"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={familiares}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Títulos"
                style={{ marginTop: "20px" }}
              // name="Titulos_Profesionales"
              >
                <Select
                  mode="multiple"
                  className="campos"
                  name="Titulos_Profesionales"
                  placeholder="Selecciona títulos"
                  disabled={true}
                  defaultValue={Titulos_Profesionales.map(Documento => Documento.Nombre)}
                >
                  {Titulos_Profesionales.map(({ Nombre }, index) => (
                    <Select.Option
                      name="TiposDeDocumentos"
                      key={index}
                      value={Nombre}
                    >
                      {Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* --------------------------------------------ESPECIALIDADES------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Especialidades
                </h4>
              }
              key="5"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={especialidades_icon}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Especialidades"
                style={{ marginTop: "20px" }}
              // name="Especialidades"
              >
                <Select
                  mode="multiple"
                  className="campos"
                  // name="Especialidades"
                  placeholder="Seleccione especialidades"
                  disabled={true}
                  defaultValue={Especialidades.map(Documento => Documento.Nombre)}
                >
                  {Especialidades.map(({ Nombre }, index) => (
                    <Select.Option
                      name="Especialidades"
                      key={index}
                      value={Nombre}
                    >
                      {Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* ------------------------------------------- EPPS ------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  E.P.P.
                </h4>
              }
              key="6"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={epp}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="E.P.P"
                style={{ marginTop: "20px" }}
              // name="EPP"
              >
                <Select
                  mode="multiple"
                  className="campos"
                  // name="EPP"
                  placeholder="Seleccione E.P.P."
                  disabled={true}
                  defaultValue={EPP.map(Documento => Documento.Nombre)}
                >
                  {EPP.map(({ Nombre }, index) => (
                    <Select.Option
                      name="EPP"
                      key={index}
                      value={Nombre}
                    >
                      {Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* ------------------------------------------- DOCUMENTOS ------------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Documentos
                </h4>
              }
              key="777"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2000"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3000"
                  src={epp}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              <Form.Item
                label="Documentos"
                style={{ marginTop: "20px" }}
              // name="TiposDeDocumentos"
              >
                <Select
                  mode="multiple"
                  className="campos"
                  // name="TiposDeDocumentos"
                  placeholder="Seleccione Documentos requeridos"
                  disabled={true}
                  defaultValue={TiposDeDocumentos.map(Documento => Documento.Nombre)}
                >
                  {TiposDeDocumentos.map(({ Nombre }, index) => (
                    <Select.Option
                      name="TiposDeDocumentos"
                      key={index}
                      value={Nombre}
                    >
                      {Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>

            {/* -----------------------------------CARACTERÍSTICAS DE CONTRATO---------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Características del contrato
                </h4>
              }
              key="8"
              className="site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={contacto}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >

              <Form.Item
                name={"Tipo_Contrato"}
                label="Tipo de contrato"
                style={{ marginTop: "20px" }}
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  onChange={(e) => {
                    setTipoFechaContrato(e.target.value);

                    form.setFieldsValue({
                      Contrato_Fecha: null,
                    })
                  }}
                  disabled={true}
                >
                  <Radio value={'Plazo fijo'}>Plazo Fijo</Radio>
                  <Radio value={'Indefinido'}>Indefinido</Radio>
                </Radio.Group>
              </Form.Item>

              {/* //nuevo campo relevo (solo visual)// */}
              <Form.Item
                name={"Relevo"}
                label="Relevo"
                style={{ marginTop: "20px" }}
                rules={[
                  {
                    required: true,
                    message: "Debe seleccionar relevo",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  defaultValue={esRelevo}
                  disabled={true}
                >
                  <Radio value={true}>Si</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name={"Contrato_Fecha"}
                label="Fecha de contratación"
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >

                {condicionalFechaContrato()}

              </Form.Item>

              <Typography style={{textAlign:'center',color:'red'}}>Ultimo contrato Finiquitado</Typography>
            </Panel>

            {/* -----------------------------------VACACIONES---------------------------------------- */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                  }}
                >
                  Vacaciones
                </h4>
              }
              key="9"
              className="site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={contacto}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >

              <Form.Item
                name={"Requirio_Progresivas"}
                label={<p style={{ margin: '0px' }}>¿Requirió vacaciones<br />progresivas?</p>}
                style={{ marginTop: "20px" }}
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >
                <Radio.Group
                  className={'input-small'}
                  style={{ display: "flex" }}
                  onChange={(e) => {
                    setRequirioProgre(e.target.value);

                    form.setFieldsValue({
                      Fecha_Progresivas: null,
                    })
                  }}
                  disabled={true}
                >
                  <Radio value={true}>Sí</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              {condicionalFechaProgresivas()}

              <Form.Item
                name={"Meses_Empleadores_Anteriores"}
                label={<p style={{ margin: '0px' }}>Meses con empleadores<br />anteriores</p>}
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  min={0}
                  className={'campos'}
                  disabled={true}
                />
              </Form.Item>

              <Form.Item
                name={"Regimen_Vacaciones"}
                label="Régimen de vacaciones"
                // rules={[
                //   {
                //     required: true,
                //     message: "Obligatorio",
                //   },
                // ]}
                style={{ marginBottom: '5px' }}
                validateStatus={validateOtro}
                // hasFeedback={true}
                rules={[
                  {
                    required: true,
                    message: "Debe seleccionar el régimen de vacaciones.",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  onChange={(e) => {

                    if (e.target.value === -1) {
                      setOtroSelected(true);
                      setValidateOtro('error');

                    } else {
                      setOtroSelected(false);
                      setValidateOtro('success');

                      form.setFieldsValue({
                        otro: undefined,
                      })
                    }

                    // if (form.getFieldsValue(['Regimen_Vacaciones'] !== -1) && form.getFieldsValue(['otro']) !== ) {

                    // }
                  }}
                  disabled={true}
                >
                  <Radio value={15}>15 días</Radio>
                  <Radio value={20}>20 días</Radio>
                  <Radio value={-1}>Otro</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name={"otro"}
                label=' '
                className='item-otro'
              >
                <InputNumber
                  placeholder={'otra cantidad'}
                  className='input-otro'
                  type="number" min={0}
                  disabled={!otroSelected}
                  onChange={async (e) => {
                    if (e) {
                      await setValidateOtro('success');
                    } else {
                      await setValidateOtro('error');
                    }
                  }}
                />
              </Form.Item>

            </Panel>

            {/* -----------------------------------Datos bancarios NUEVO---------------------------------------- */}
            <Panel
            header={
                <h4
                style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                }}
                >
                Datos bancarios
                </h4>
            }
            key="10"
            className="site-collapse-custom-panel"
            extra={[
                <img
                key="2"
                src={flecha}
                style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                }}
                alt="icono"
                />,
                <img
                key="3"
                src={bancario}
                style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                }}
                alt="icono"
                />,
            ]}
            >
              <Form.Item
                label="N° de cuenta bancaria"
                name={"Numero_Cuenta_Bancaria"}
                style={{ marginTop: "20px" }}
                // rules={[
                // {
                //     required: true,
                //     message: "¿Cuál es su n° de cuenta bancaria?",
                // },
                // ]}
              >
                <InputNumber
                disabled={true}
                style={{ width: "100%" }}
                type="number"
                />
              </Form.Item>

              <Form.Item
                  label="Banco"
                  name={"Banco"}
                  // rules={[
                  // {
                  //     required: true,
                  //     message: "¿Cuál es su banco?",
                  // },
                  // ]}
              >
                <Select
                  disabled={true}
                >
                  <Select.Option value="no aplica">No aplica</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Forma de pago "
                name={"Forma_Pago"}
                // rules={[
                // {
                //   required: true,
                //   message: "¿Cuál es su forma de pago?",
                // },
                // ]}
              >
                <Select
                  disabled={true}
                >
                  <Select.Option value="no aplica">No aplica</Select.Option>
                </Select>
              </Form.Item>
            </Panel>

            {/* -----------------------------------Datos previsionales NUEVO---------------------------------------- */}
            <Panel
            header={
                <h4
                style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                }}
                >
                Datos previsionales
                </h4>
            }
            key="11"
            className="site-collapse-custom-panel"
            extra={[
                <img
                key="2"
                src={flecha}
                style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                }}
                alt="icono"
                />,
                <img
                key="3"
                src={previsional}
                style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                }}
                alt="icono"
                />,
            ]}
            >
              <Form.Item
                label="AFP"
                name={"AFP"}
                style={{ marginTop: "20px" }}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su AFP?",
                },
                ]}
              >
                <Select
                    disabled={true}
                >
                  <Select.Option value="afp">Sin Definir</Select.Option>
                  <Select.Option value="capital">AFP Capital</Select.Option>
                  <Select.Option value="cuprum">AFP Cuprum</Select.Option>
                  <Select.Option value="habitat">AFP Habitat</Select.Option>
                  <Select.Option value="planvital">AFP Planvital</Select.Option>
                  <Select.Option value="provida">AFP Provida</Select.Option>
                  <Select.Option value="modelo">AFP Modelo</Select.Option>
                  <Select.Option value="uno">AFP UNO</Select.Option>
                  <Select.Option value="canaempu">Canempu</Select.Option>
                  <Select.Option value="empart">Empart</Select.Option>
                  <Select.Option value="sss">Servicio Seguro Social</Select.Option>
                  <Select.Option value="triomar">Triomar</Select.Option>
                  <Select.Option value="capremer">Capremer</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Sistema de pensiones"
                name={"Sistema_Pension"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su sistema de pensiones?",
                },
                ]}
              >
                <Select
                    disabled={true}
                >
                  <Select.Option value="N">Nuevo</Select.Option>
                  <Select.Option value="C">Antiguo con Isapre</Select.Option>
                  <Select.Option value="T">Antiguo con Fonasa</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Jubilado"
                name={"Jubilado"}
                rules={[
                {
                  //required: true,
                  message: "¿Es usted jubilado?",
                },
                ]}
              >
                <Select
                    disabled={true}
                >
                  <Select.Option value="0">Activo no pensionado</Select.Option>
                  <Select.Option value="1">Pensionado y cotiza</Select.Option>
                  <Select.Option value="2">Pensionado y no cotiza</Select.Option>
                  <Select.Option value="3">{`Activo > 60 o 65 años`}</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Isapre / Fonasa"
                name={"Isapre_Fonasa"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su Isapre / Fonasa?",
                },
                ]}
              >
                <Select
                    disabled={true}
                >
                  <Select.Option value="isapre">Sin Definir</Select.Option>
                  <Select.Option value="fonasa">FONASA</Select.Option>
                  <Select.Option value="banmedica">ISAPRE Banmedica</Select.Option>
                  <Select.Option value="consalud">ISAPRE Consalud</Select.Option>
                  <Select.Option value="vidatres">ISAPRE Vidatres</Select.Option>
                  <Select.Option value="colmena">ISAPRE Colmena</Select.Option>
                  <Select.Option value="cruzblanca">ISAPRE Cruz-Blanca</Select.Option>
                  <Select.Option value="nuevamasvida">ISAPRE Nueva Mas Vida</Select.Option>
                  <Select.Option value="bancoestado">ISAPRE Banco Estado</Select.Option>
                  <Select.Option value="fusat">ISAPRE Fusat</Select.Option>
                  <Select.Option value="chuquicamata">ISAPRE Chuquicamata</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Isapre Monetización"
                name={"Isapre_Monetizacion"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su Isapre Monetización?",
                },
                ]}
              >
                <Select
                    disabled={true}
                >
                  <Select.Option value="%">7%</Select.Option>
                  <Select.Option value="U">U.F.</Select.Option>
                  <Select.Option value="P">Pesos</Select.Option>
                  <Select.Option value="F">7% + UF</Select.Option>
                  <Select.Option value="S">UF + Pesos</Select.Option>
                  <Select.Option value="Z">7% + UF + Pesos</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Isapre UF"
                name={"Isapre_UF"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su Isapre UF?",
                },
                ]}
              >
                <InputNumber
                  disabled={true}
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  // className="campos"
                  // max={20}
                  // maxLength={20}
                />
              </Form.Item>

              <Form.Item
                label="Isapre peso"
                name={"Isapre_Peso"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su Isapre peso?",
                },
                ]}
              >
                <InputNumber
                  disabled={true}
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  // className="campos"
                  // max={20}
                  // maxLength={20}
                />
              </Form.Item>

              <Form.Item
                label="Isapre cotización"
                name={"Isapre_Cotizacion"}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su Isapre cotización",
                },
                ]}
              >
                <InputNumber
                  disabled={true}
                  style={{ width: "100%" }}
                  type="number"
                  min={0}
                  //className="campos"
                  // max={20}
                  // maxLength={20}
                />
              </Form.Item>
                
            </Panel>

            {/* -----------------------------------Datos Otros Estudios NUEVO---------------------------------------- */}
            <Panel
            header={
                <h4
                style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                    left: 35,
                }}
                >
                Datos otros estudios
                </h4>
            }
            key="12"
            className="site-collapse-custom-panel"
            extra={[
                <img
                key="2"
                src={flecha}
                style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                }}
                alt="icono"
                />,
                <img
                key="3"
                src={estudios}
                style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                }}
                alt="icono"
                />,
            ]}
            >
              <Form.Item
                label="Nivel de estudios"
                name={"Nivel_Estudio"}
                style={{ marginTop: "20px" }}
                rules={[
                {
                  //required: true,
                  message: "¿Cuál es su nivel de estudio?",
                },
                ]}
              >
                <Select
                    disabled={true}
                >
                  <Select.Option value="0">Ninguno</Select.Option>
                  <Select.Option value="1">Básico</Select.Option>
                  <Select.Option value="2">Medio</Select.Option>
                  <Select.Option value="3">Técnico</Select.Option>
                  <Select.Option value="4">Universitario</Select.Option>
                  <Select.Option value="5">Postgraduado</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Licencia conducir"
                name={"Licencia_Conducir"}
                rules={[
                {
                  //required: true,
                  message: "¿Tiene licencia de conducir?",
                },
                ]}
              >
                <Radio.Group
                    disabled={true}
                >
                  <Radio value={true}>Sí</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              
              <Form.Item
                label="Tipo"
                name={"Tipo_Licencia_Conducir"}
                rules={[
                {
                  //required: true,
                  message: "¿De que tipo es su licencia de conducir?",
                },
                ]}
              >
                <Select
                    disabled={true}
                >
                  <Select.Option value="N">Sin licencia</Select.Option>
                  <Select.Option value="A1">A1</Select.Option>
                  <Select.Option value="A2">A2</Select.Option>
                  <Select.Option value="A3">A3</Select.Option>
                  <Select.Option value="A4">A4</Select.Option>
                  <Select.Option value="B">B</Select.Option>
                  <Select.Option value="C">C</Select.Option>
                  <Select.Option value="D">D</Select.Option>
                  <Select.Option value="E">E</Select.Option>
                  <Select.Option value="F">F</Select.Option>
                </Select>
              </Form.Item>
            </Panel>

          </Collapse>
        </Col>



      </Row>

      <br />
      <br />

    </Form>
  );
};

export default FichaTrabajadorNuevo;

