import React, { Component } from "react";
import { Layout, Row, Col, Button } from "antd";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Trabajador from "../../components/perfil/trabajador/Trabajador";
import avatar from "../../img/icons/avatar.svg";
import FormularioFormatos from "../../components/formularioFormatos/FormularioFormatos";
import "./Formatos.css";
const { Header, Footer, Content } = Layout;

class Formatos extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ObtenerImagen = this.ObtenerImagen.bind(this);
  }

  ObtenerImagen = (imagenObtenida) => {
    console.log(imagenObtenida);
  };
  render() {
    return (
      <Layout style={{ height: "100%" }}>
        <Header style={{ background: "none" }}>
          <HeaderFicha titulo="Formatos" subtitulo="Nuevo Trabajador" />
        </Header>

        <hr className="division" />
        <Content style={{ background: "none" }}>
          <Row>
            <Col span={9} style={{ paddingTop: "39px", paddingLeft: "20px" }}>
              <Trabajador ObtenerImagen={this.ObtenerImagen} avatar={avatar} />
            </Col>
            <Col span={15} push={0.5}>
              <FormularioFormatos />
            </Col>
          </Row>
        </Content>

        <Footer style={{ background: "none", textAlign: "right" }}>
          <Button className="siguiente" disabled>
            Volver a ficha del trabajador
          </Button>
          <Button className="siguiente" disabled>
            Elementos de proteccion
          </Button>
        </Footer>
      </Layout>
    );
  }
}

export default Formatos;
