import React, { Component } from "react";

import API from "../../Redux/Api/api";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import "moment/locale/es";
import { convertirFechaString } from "../../funcionesGenericas/fechas";
import Swal from "sweetalert2";
import Loading from "../../components/loading/Loading";
import { EyeFilled } from "@ant-design/icons";
import Excel from "../../img/icons-sidebar/blancas/IconExcel.svg";

import { Bar } from "@ant-design/charts";
import {
  Row,
  Col,
  Card,
  Button,
  DatePicker,
  Modal,
  Table,
  Tabs,
  Empty,
  Input,
  Tooltip
} from "antd";
const { Search } = Input;
const { Column } = Table;
const { TabPane } = Tabs;

class DashboardVencimiento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataGraficoTitulosPorExpirar: [],
      dataGraficoTitulosExpirados: [],

      dataGraficoEspecialidadesPorExpirar: [],
      dataGraficoEspecialidadesExpiradas: [],

      dataGraficoEppsPorExpirar: [],
      dataGraficoEppsExpiradas: [],
      //nuevos de documentos
      dataGraficoDocumentosPorExpirar: [],
      dataGraficoDocumentosExpiradas: [],

      fechaSelecionada: moment().format("YYYY-MM-DD"),
      cargoSeleccionado: "",
      centroDeCostoSeleccionado: "",
      config: {
        data: [],
        xField: "value",
        yField: "type",
        // seriesField: "flag",
        autoFit: true,
        height: 250,
        legend: false,
        scrollbar: {
          type: "vertical",
        },
      },

      modalDetalleTitulosPorExpirar: false,
      modalDetalleTitulosExpirados: false,

      modalDetalleEspecialidadesPorExpirar: false,
      modalDetalleEspecialidadesExpiradas: false,

      modalDetalleEppsPorExpirar: false,
      modalDetalleEppsExpiradas: false,

      modalDetalleDocumentosPorExpirar: false,
      modalDetalleDocumentosExpiradas: false,

      dataDetalleTitulosPorExpirar: [],
      dataDetalleTitulosExpirados: [],

      dataDetalleEspecialidadesPorExpirar: [],
      dataDetalleEspecialidadesExpiradas: [],

      dataDetalleEppsPorExpira: [],
      dataDetalleEppsExpiradas: [],

      dataDetalleDocumentosPorExpirar: [],
      dataDetalleDocumentosExpiradas: [],
      
      indexActual: "1",
      trabajadoresCopia: undefined,
    };
  }

  componentDidMount = async () => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      const str = moment().format("YYYY-MM-DD");
      const fechaActual = convertirFechaString(moment(str), 1).fechaString;

      //TITULOS
      let dataTitulosPorExpirar = await this.obtenerTitulosPorExpirar(
        idEmpresa,
        fechaActual
      );
      let dataTitulosExpirados = await this.obtenerTitulosExpirados(
        idEmpresa,
        fechaActual
      );
      //ESPECIALIDADES
      let dataEspecialidadesPorExpirar =
        await this.obtenerEspecialidadesPorExpirar(idEmpresa, fechaActual);
      let dataEspecialidadesExpiradas =
        await this.obtenerEspecialidadesExpiradas(idEmpresa, fechaActual);
      //EPPS
      let dataEppsPorExpirar = await this.obtenerEppsPorExpirar(
        idEmpresa,
        fechaActual
      );
      let dataEppsExpiradas = await this.obtenerEppsExpiradas(
        idEmpresa,
        fechaActual
      );
      //Documentos
      let dataDocumentosPorExpirar =
       await this.obtenerDocumentosPorExpirar(idEmpresa, fechaActual);
      let dataDocumentosExpiradas =
       await this.obtenerDocumentosExpiradas(idEmpresa, fechaActual);

      this.setState({
        // dataGraficoTitulosPorExpirar: dataTitulosPorExpirar.data,
        // dataGraficoTitulosExpirados: dataTitulosExpirados.data,
        // dataGraficoEspecialidadesPorExpirar: dataEspecialidadesPorExpirar.data,
        // dataGraficoEspecialidadesExpiradas: dataEspecialidadesExpiradas.data,
        // dataGraficoEppsPorExpirar: dataEppsPorExpirar.data,
        // dataGraficoEppsExpiradas: dataEppsExpiradas.data,
        dataGraficoTitulosPorExpirar: dataTitulosPorExpirar === undefined ? [] : dataTitulosPorExpirar.data,
        dataGraficoTitulosExpirados: dataTitulosExpirados === undefined ? [] : dataTitulosExpirados.data,
        dataGraficoEspecialidadesPorExpirar: dataEspecialidadesPorExpirar === undefined ? [] : dataEspecialidadesPorExpirar.data,
        dataGraficoEspecialidadesExpiradas: dataEspecialidadesExpiradas === undefined ? [] : dataEspecialidadesExpiradas.data,
        dataGraficoEppsPorExpirar: dataEppsPorExpirar === undefined ? [] : dataEppsPorExpirar.data,
        dataGraficoEppsExpiradas: dataEppsExpiradas=== undefined ? [] : dataEppsExpiradas.data,
        dataGraficoDocumentosPorExpirar: dataDocumentosPorExpirar === undefined ? [] : dataDocumentosPorExpirar.data,
        dataGraficoDocumentosExpiradas: dataDocumentosExpiradas=== undefined ? [] : dataDocumentosExpiradas.data,
        Empresa: idEmpresa,
        indexActual: "1",
      });
    }
  };

  //API INICIO TITULOS
  obtenerTitulosPorExpirar = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/TitulosPorExpirarNuevo`;

      let objeto = {
        idEmpresa,
        fecha,
      };

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = await response.status;
      const data = await response.json();

      if (status === 200 || status === 201) {
        return data;
      } else {
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se pudo cargar la información.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
  };

  obtenerTitulosExpirados = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/TitulosExpiradosNuevo`;

      let objeto = {
        idEmpresa,
        fecha,
      };

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = await response.status;
      const data = await response.json();

      if (status === 200 || status === 201) {
        return data;
      } else {
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se pudo cargar la información.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
  };

  //API INICIO ESPECIALIDADES
  obtenerEspecialidadesPorExpirar = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/EspecialidadesPorExpirarNuevo`;

      let objeto = {
        idEmpresa,
        fecha,
      };

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = await response.status;
      const data = await response.json();

      if (status === 200 || status === 201) {
        return data;
      } else {
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se pudo cargar la información.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
  };

  obtenerEspecialidadesExpiradas = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/EspecialidadesExpiradasNuevo`;

      let objeto = {
        idEmpresa,
        fecha,
      };

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = await response.status;
      const data = await response.json();

      if (status === 200 || status === 201) {
        return data;
      } else {
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se pudo cargar la información.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
  };

  //API INICIO EPPS
  obtenerEppsPorExpirar = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/EppsPorExpirarNuevo`;

      let objeto = {
        idEmpresa,
        fecha,
      };

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = await response.status;
      const data = await response.json();

      if (status === 200 || status === 201) {
        return data;
      } else {
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se pudo cargar la información.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
  };

  obtenerEppsExpiradas = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/EppsExpiradasNuevo`;

      let objeto = {
        idEmpresa,
        fecha,
      };

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = await response.status;
      const data = await response.json();

      if (status === 200 || status === 201) {
        return data;
      } else {
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se pudo cargar la información.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
  };

  //Documentos APIS
  obtenerDocumentosPorExpirar = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/DocumentosPorExpirarNuevo`;

      let objeto = {
        idEmpresa,
        fecha,
      };

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = await response.status;
      const data = await response.json();

      if (status === 200 || status === 201) {
        return data;
      } else {
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se pudo cargar la información.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
  };
  obtenerDocumentosExpiradas = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/DocumentosExpiradasNuevo`;

      let objeto = {
        idEmpresa,
        fecha,
      };

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = await response.status;
      const data = await response.json();

      if (status === 200 || status === 201) {
        return data;
      } else {
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se pudo cargar la información.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
  };

  //FUNCIONES GENERALES
  setFecha = (fecha) => {
    let str = moment(fecha).format("YYYY-MM-DD");
    let fechaFormateada = convertirFechaString(moment(str), 1).fechaString;

    this.setState({
      fechaSelecionada: fechaFormateada,
    });
  };

  setCentroCosto = (centroCosto) => {
    this.setState({
      centroDeCostoSeleccionado: centroCosto,
    });
  };

  setCargo = (cargo) => {
    this.setState({
      cargoSeleccionado: cargo,
    });
  };

  filtrar = async (objeto) => {
    try {
      this.setState({ loading: true });
      const { fecha: fechaActual } = objeto;
      const { Empresa: idEmpresa } = this.state;

      //TITULOS
      let dataTitulosPorExpirar = await this.obtenerTitulosPorExpirar(
        idEmpresa,
        fechaActual
      );
      let dataTitulosExpirados = await this.obtenerTitulosExpirados(
        idEmpresa,
        fechaActual
      );
      //ESPECIALIDADES
      let dataEspecialidadesPorExpirar =
        await this.obtenerEspecialidadesPorExpirar(idEmpresa, fechaActual);
      let dataEspecialidadesExpiradas =
        await this.obtenerEspecialidadesExpiradas(idEmpresa, fechaActual);
      //EPPS
      let dataEppsPorExpirar = await this.obtenerEppsPorExpirar(
        idEmpresa,
        fechaActual
      );
      let dataEppsExpiradas = await this.obtenerEppsExpiradas(
        idEmpresa,
        fechaActual
      );

      let dataDocumentosPorExpirar = await this.obtenerDocumentosPorExpirar(
        idEmpresa,
        fechaActual
      );

      let dataDocumentosExpiradas = await this.obtenerDocumentosExpiradas(
        idEmpresa,
        fechaActual
      );

      this.setState({
        dataGraficoTitulosPorExpirar: dataTitulosPorExpirar.data,
        dataGraficoTitulosExpirados: dataTitulosExpirados.data,
        dataGraficoEspecialidadesPorExpirar: dataEspecialidadesPorExpirar.data,
        dataGraficoEspecialidadesExpiradas: dataEspecialidadesExpiradas.data,
        dataGraficoEppsPorExpirar: dataEppsPorExpirar.data,
        dataGraficoEppsExpiradas: dataEppsExpiradas.data,
        dataGraficoDocumentosPorExpirar: dataDocumentosPorExpirar.data,
        dataGraficoDocumentosExpiradas: dataDocumentosExpiradas.data,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
  };

  //TITULOS API DETALLE
  obtenerDetalleTitulosPorExpirar = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/TitulosPorExpirarNuevo/Detalle`;
      let objeto = {
        idEmpresa,
        fecha,
      };
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let detalle = data.data;
          return detalle;
        default:
          //const error = data.err.message.toString();
          const error = "Error al cargar la tabla";
          console.error(error);
          return [];
      }
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  obtenerDetalleTitulosExpirados = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/TitulosExpiradosNuevo/Detalle`;
      let objeto = {
        idEmpresa,
        fecha,
      };
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let detalle = data.data;
          return detalle;
        default:
          //const error = data.err.message.toString();
          const error = "Error al cargar la tabla";
          console.error(error);
          return [];
      }
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  //BTN DETALLE DE TITULOS + FUNC API
  detalleTitulosPorExpirar = async () => {
    this.setState({ loading: true });

    const { Empresa, fechaSelecionada } = this.state;
    const idEmpresa = Empresa;
    const fechaActual = convertirFechaString(
      moment(fechaSelecionada),
      1
    ).fechaString;

    const detalleTitulosPorExpirar = await this.obtenerDetalleTitulosPorExpirar(
      idEmpresa,
      fechaActual
    );

    this.setState({
      loading:false,
      modalDetalleTitulosPorExpirar: true,
      dataDetalleTitulosPorExpirar: detalleTitulosPorExpirar,
      trabajadoresCopia: undefined,
      indexActual: "1",
      dataDetalleTitulosExpirados: [],
      dataDetalleEspecialidadesPorExpirar: [],
      dataDetalleEspecialidadesExpiradas: [],
      dataDetalleEppsPorExpira: [],
      dataDetalleEppsExpiradas: [],
      dataDetalleDocumentosPorExpirar: [],
      dataDetalleDocumentosExpiradas: []
    });
  };

  detalleTitulosExpirados = async () => {
    this.setState({ loading: true });

    const { Empresa, fechaSelecionada } = this.state;
    const idEmpresa = Empresa;
    const fechaActual = convertirFechaString(
      moment(fechaSelecionada),
      1
    ).fechaString;

    const detalleTitulosExpirados = await this.obtenerDetalleTitulosExpirados(
      idEmpresa,
      fechaActual
    );

    this.setState({
      loading:false,
      modalDetalleTitulosExpirados: true,
      dataDetalleTitulosExpirados: detalleTitulosExpirados,
      trabajadoresCopia: undefined,
      indexActual: "1",
      dataDetalleTitulosPorExpirar: [],
      dataDetalleEspecialidadesPorExpirar: [],
      dataDetalleEspecialidadesExpiradas: [],
      dataDetalleEppsPorExpira: [],
      dataDetalleEppsExpiradas: [],
      dataDetalleDocumentosPorExpirar: [],
      dataDetalleDocumentosExpiradas: []
    });
  };

  //ESPECIALIDADES API DETALLE
  obtenerDetalleEspecialidadesPorExpirar = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/EspecialidadesPorExpirarNuevo/Detalle`;
      let objeto = {
        idEmpresa,
        fecha,
      };
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let detalle = data.data;
          return detalle;
        default:
          //const error = data.err.message.toString();
          const error = "Error al cargar la tabla";
          console.error(error);
          return [];
      }
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  obtenerDetalleEspecialidadesExpiradas = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/EspecialidadesExpiradasNuevo/Detalle`;
      let objeto = {
        idEmpresa,
        fecha,
      };
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let detalle = data.data;
          return detalle;
        default:
          //const error = data.err.message.toString();
          const error = "Error al cargar la tabla";
          console.error(error);
          return [];
      }
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  //BTN DETALLE DE ESPECIALIDADES + FUNC API
  detalleEspecialidadesPorExpirar = async () => {
    this.setState({ loading: true });
    
    const { Empresa, fechaSelecionada } = this.state;
    const idEmpresa = Empresa;
    const fechaActual = convertirFechaString(
      moment(fechaSelecionada),
      1
    ).fechaString;

    const detalleEspecialidadesPorExpirar =
      await this.obtenerDetalleEspecialidadesPorExpirar(idEmpresa, fechaActual);

    this.setState({
      loading:false,
      modalDetalleEspecialidadesPorExpirar: true,
      dataDetalleEspecialidadesPorExpirar: detalleEspecialidadesPorExpirar,
      trabajadoresCopia: undefined,
      indexActual: "1",
      dataDetalleTitulosPorExpirar: [],
      dataDetalleTitulosExpirados: [],
      dataDetalleEspecialidadesExpiradas: [],
      dataDetalleEppsPorExpira: [],
      dataDetalleEppsExpiradas: [],
      dataDetalleDocumentosPorExpirar: [],
      dataDetalleDocumentosExpiradas: []
    });
  };

  detalleEspecialidadesExpiradas = async () => {
    this.setState({ loading: true });
    
    const { Empresa, fechaSelecionada } = this.state;
    const idEmpresa = Empresa;
    const fechaActual = convertirFechaString(
      moment(fechaSelecionada),
      1
    ).fechaString;

    const detalleEspecialidadesExpiradas =
      await this.obtenerDetalleEspecialidadesExpiradas(idEmpresa, fechaActual);

    this.setState({
      loading:false,
      modalDetalleEspecialidadesExpiradas: true,
      dataDetalleEspecialidadesExpiradas: detalleEspecialidadesExpiradas,
      trabajadoresCopia: undefined,
      indexActual: "1",
      dataDetalleTitulosPorExpirar: [],
      dataDetalleTitulosExpirados: [],
      dataDetalleEspecialidadesPorExpirar: [],
      dataDetalleEppsPorExpira: [],
      dataDetalleEppsExpiradas: [],
      dataDetalleDocumentosPorExpirar: [],
      dataDetalleDocumentosExpiradas: []
    });
  };

  //EPPS API DETALLE
  obtenerDetalleEppsPorExpirar = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/EppsPorExpirarNuevo/Detalle`;
      let objeto = {
        idEmpresa,
        fecha,
      };
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let detalle = data.data;
          return detalle;
        default:
          //const error = data.err.message.toString();
          const error = "Error al cargar la tabla";
          console.error(error);
          return [];
      }
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  obtenerDetalleEppsExpiradas = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/EppsExpiradasNuevo/Detalle`;
      let objeto = {
        idEmpresa,
        fecha,
      };
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let detalle = data.data;
          return detalle;
        default:
          //const error = data.err.message.toString();
          const error = "Error al cargar la tabla";
          console.error(error);
          return [];
      }
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  //BTN DETALLE DE EPPS + FUNC API
  detalleEppsPorExpirar = async () => {
    this.setState({ loading: true });
    const { Empresa, fechaSelecionada } = this.state;
    const idEmpresa = Empresa;
    const fechaActual = convertirFechaString(
      moment(fechaSelecionada),
      1
    ).fechaString;

    const detalleDetalleEppsPorExpirar =
      await this.obtenerDetalleEppsPorExpirar(idEmpresa, fechaActual);

    this.setState({
      loading:false,
      modalDetalleEppsPorExpirar: true,
      dataDetalleEppsPorExpira: detalleDetalleEppsPorExpirar,
      trabajadoresCopia: undefined,
      indexActual: "1",
      dataDetalleTitulosPorExpirar: [],
      dataDetalleTitulosExpirados: [],
      dataDetalleEspecialidadesPorExpirar: [],
      dataDetalleEspecialidadesExpiradas: [],
      dataDetalleEppsExpiradas: [],
      dataDetalleDocumentosPorExpirar: [],
      dataDetalleDocumentosExpiradas: []
    });
  };

  detalleEppsExpiradas = async () => {
    this.setState({ loading: true });
    const { Empresa, fechaSelecionada } = this.state;
    const idEmpresa = Empresa;
    const fechaActual = convertirFechaString(
      moment(fechaSelecionada),
      1
    ).fechaString;

    const detalleEppsExpiradas = await this.obtenerDetalleEppsExpiradas(
      idEmpresa,
      fechaActual
    );

    this.setState({
      loading:false,
      modalDetalleEppsExpiradas: true,
      dataDetalleEppsExpiradas: detalleEppsExpiradas,
      trabajadoresCopia: undefined,
      indexActual: "1",
      dataDetalleTitulosPorExpirar: [],
      dataDetalleTitulosExpirados: [],
      dataDetalleEspecialidadesPorExpirar: [],
      dataDetalleEspecialidadesExpiradas: [],
      dataDetalleEppsPorExpira: [],
      dataDetalleDocumentosPorExpirar: [],
      dataDetalleDocumentosExpiradas: []
    });
  };

  //DOCUMENTOS API DETALLE
  obtenerDetalleDocumentosPorExpirar = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/DocumentosPorExpirarNuevo/Detalle`;
      let objeto = {
        idEmpresa,
        fecha,
      };
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let detalle = data.data;
          return detalle;
        default:
          //const error = data.err.message.toString();
          const error = "Error al cargar la tabla";
          console.error(error);
          return [];
      }
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  obtenerDetalleDocumentosExpiradas = async (idEmpresa, fecha) => {
    try {
      const url = `${API}/DocumentosExpiradasNuevo/Detalle`;
      let objeto = {
        idEmpresa,
        fecha,
      };
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let detalle = data.data;
          return detalle;
        default:
          //const error = data.err.message.toString();
          const error = "Error al cargar la tabla";
          console.error(error);
          return [];
      }
    } catch (error) {
      console.error(error);
    }

    return [];
  };
  //BTN DETALLE DE DOCUMENTOS + FUNC API
  detalleDocumentosPorExpirar = async () => {
    this.setState({ loading: true });
    const { Empresa, fechaSelecionada } = this.state;
    const idEmpresa = Empresa;
    const fechaActual = convertirFechaString(
      moment(fechaSelecionada),
      1
    ).fechaString;

    const detalleDetalleDocumentosPorExpirar =
      await this.obtenerDetalleDocumentosPorExpirar(idEmpresa, fechaActual);

    this.setState({
      loading:false,
      modalDetalleDocumentosPorExpirar: true,
      dataDetalleDocumentosPorExpirar: detalleDetalleDocumentosPorExpirar,
      trabajadoresCopia: undefined,
      indexActual: "1",
      dataDetalleTitulosPorExpirar: [],
      dataDetalleTitulosExpirados: [],
      dataDetalleEspecialidadesPorExpirar: [],
      dataDetalleEspecialidadesExpiradas: [],
      dataDetalleEppsPorExpira: [],
      dataDetalleEppsExpiradas: [],
      dataDetalleDocumentosExpiradas: []
  
    });
  };

  detalleDocumentosExpiradas = async () => {
    this.setState({ loading: true });
    const { Empresa, fechaSelecionada } = this.state;
    const idEmpresa = Empresa;
    const fechaActual = convertirFechaString(
      moment(fechaSelecionada),
      1
    ).fechaString;

    const detalleDocumentosExpiradas = await this.obtenerDetalleDocumentosExpiradas(
      idEmpresa,
      fechaActual
    );

    this.setState({
      loading:false,
      modalDetalleDocumentosExpiradas: true,
      dataDetalleDocumentosExpiradas: detalleDocumentosExpiradas,
      trabajadoresCopia: undefined,
      indexActual: "1",
      dataDetalleTitulosPorExpirar: [],
      dataDetalleTitulosExpirados: [],
      dataDetalleEspecialidadesPorExpirar: [],
      dataDetalleEspecialidadesExpiradas: [],
      dataDetalleEppsPorExpira: [],
      dataDetalleEppsExpiradas: [],
      dataDetalleDocumentosPorExpirar: []
    });
  };

  //API EXCEL DESCARGABLE GENERICO
  obtenerExcelDetalleVencimiento = async (informacionDescargar, subtitulo) => {
    try {
      const url = `${API}/TrabajadoresExpirarGenerico/DescargarExcel`;
      let datosNecesarios = [];
      informacionDescargar.forEach((trabajador) => {
        datosNecesarios.push({
          NombreCompletoTratado: trabajador.NombreCompletoTratado,
          NombreArticulo: trabajador.NombreArticulo,
          FechaVencimiento:
            trabajador.FechaVencimiento !== "N/A"
              ? trabajador.FechaVencimiento
              : "No Asignada",
        });
      });
      let objeto = {
        // data: informacionDescargar,
        data: datosNecesarios,
        subtitulo,
      };

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objeto),
      });

      const status = await response.status;
      const excel = await response.blob();
      switch (status) {
        case 200:
        case 201:
          const url = window.URL.createObjectURL(excel);
          const a = document.createElement("a");
          a.href = url;
          a.download = `formatoDetalleCostos.xlsx`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          Swal.fire({
            title: "Bien",
            text: "Se descargó excel correctamente.",
            icon: "success",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });
          
        default:
          // Swal.fire({
          //   icon: "error",
          //   title: "Uuups...",
          //   text: "No se pudo cargar la información.",
          //   confirmButtonColor: "#E60AA3",
          //   confirmButtonText: "OK",
          // });
          return [];
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
  };

  //BTN EXCEL DESCARGABLE GENERICO
  descargarExcelDetalleVencimiento = async () => {
    let { dataDetalleTitulosPorExpirar } = this.state;

    if (
      dataDetalleTitulosPorExpirar &&
      dataDetalleTitulosPorExpirar.length > 0
    ) {
      let arrayEnviar = [];
      const subtituloGrafico = "Títulos por expirar";

      dataDetalleTitulosPorExpirar.forEach((dataExcel) => {
        dataExcel.trabajadores.forEach((dataTrabajador) => {
          let trabajador = {
            NombreCompletoTratado: dataTrabajador.NombreCompletoTratado,
            NombreArticulo: dataTrabajador.NombreArticulo,
            FechaVencimiento:
              dataTrabajador.FechaVencimiento !== "N/A"
                ? dataTrabajador.FechaVencimiento
                : "No Asignada",
          };
          arrayEnviar.push(trabajador);
        });
      });
      let excelDescargado = await this.obtenerExcelDetalleVencimiento(
        arrayEnviar,
        subtituloGrafico
      );
    }
  };

  //Search BTN
  search = (value, index) => {
    let dataEnviada = undefined;

    let {
      dataDetalleTitulosPorExpirar,
      dataDetalleTitulosExpirados,
      dataDetalleEspecialidadesPorExpirar,
      dataDetalleEspecialidadesExpiradas,
      dataDetalleEppsPorExpira,
      dataDetalleEppsExpiradas,
      dataDetalleDocumentosPorExpirar,
      dataDetalleDocumentosExpiradas
    } = this.state;

    //titulos
    if (
      dataDetalleTitulosPorExpirar !== [] &&
      dataDetalleTitulosPorExpirar.length > 0
    ) {
      dataEnviada = dataDetalleTitulosPorExpirar;
    }
    if (
      dataDetalleTitulosExpirados !== [] &&
      dataDetalleTitulosExpirados.length > 0
    ) {
      dataEnviada = dataDetalleTitulosExpirados;
    }

    //especialidades
    if (
      dataDetalleEspecialidadesPorExpirar !== [] &&
      dataDetalleEspecialidadesPorExpirar.length > 0
    ) {
      dataEnviada = dataDetalleEspecialidadesPorExpirar;
    }
    if (
      dataDetalleEspecialidadesExpiradas !== [] &&
      dataDetalleEspecialidadesExpiradas.length > 0
    ) {
      dataEnviada = dataDetalleEspecialidadesExpiradas;
    }
    //epps
    if (
      dataDetalleEppsPorExpira !== [] &&
      dataDetalleEppsPorExpira.length > 0
    ) {
      dataEnviada = dataDetalleEppsPorExpira;
    }
    if (
      dataDetalleEppsExpiradas !== [] &&
      dataDetalleEppsExpiradas.length > 0
    ) {
      dataEnviada = dataDetalleEppsExpiradas;
    }

    //documentos
    if (
      dataDetalleDocumentosPorExpirar !== [] &&
      dataDetalleDocumentosPorExpirar.length > 0
    ) {
      dataEnviada = dataDetalleDocumentosPorExpirar;
    }
    if (
      dataDetalleDocumentosExpiradas !== [] &&
      dataDetalleDocumentosExpiradas.length > 0
    ) {
      dataEnviada = dataDetalleDocumentosExpiradas;
    }

    let busqueda = value.target.value;

    if (busqueda !== "") {
      busqueda = busqueda.toLowerCase();
      let datamodal = JSON.parse(JSON.stringify(dataEnviada));
      const trabajadores = datamodal[index].trabajadores;

      const trabajadoresFiltros = trabajadores.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(busqueda)
        )
      );

      let newData = {
        trabajadores: trabajadoresFiltros,
      };

      this.setState({
        trabajadoresCopia: newData,
      });
    } else {
      this.setState({ trabajadoresCopia: undefined });
    }
  };

  NuevaPestaña = (idUsuario) => {
    //window.open("/CalendarioTrabajador?Trabajador=" + idUsuario);
    window.open("/CalendarioTrabajadorNew?Trabajador=" + idUsuario);
  };

  render() {
    const {
      loading,
      dataGraficoTitulosPorExpirar,
      dataGraficoTitulosExpirados,
      dataGraficoEspecialidadesPorExpirar,
      dataGraficoEspecialidadesExpiradas,
      dataGraficoEppsPorExpirar,
      dataGraficoEppsExpiradas,
      dataGraficoDocumentosPorExpirar,
      dataGraficoDocumentosExpiradas,

      modalDetalleTitulosPorExpirar,
      modalDetalleTitulosExpirados,
      modalDetalleEspecialidadesPorExpirar,
      modalDetalleEspecialidadesExpiradas,
      modalDetalleEppsPorExpirar,
      modalDetalleEppsExpiradas,
      modalDetalleDocumentosPorExpirar,
      modalDetalleDocumentosExpiradas,

      dataDetalleTitulosPorExpirar,
      dataDetalleTitulosExpirados,
      dataDetalleEspecialidadesPorExpirar,
      dataDetalleEspecialidadesExpiradas,
      dataDetalleEppsPorExpira,
      dataDetalleEppsExpiradas,
      dataDetalleDocumentosPorExpirar,
      dataDetalleDocumentosExpiradas,
      indexActual,
      trabajadoresCopia,
    } = this.state;

    const DemoBar = (info) => {
      let { config } = this.state;
      config.data = info.data;
      return <Bar {...config} />;
    };
    return (
      <>
        <Loading cargando={loading} />
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            className="card-style-dashboard card-title-dashboard"
            title={
              <>
                <Row>
                  <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                    Vencimiento
                  </Col>
                </Row>
              </>
            }
          >
            {/* FILTROS */}
            <div>
              <p className={"label-filtros-dashboard"}>Filtrar por:</p>
            </div>

            <Row
              gutter={20}
              // style={{ marginBottom: "20px" }}
            >
              {/* RANGEPICKER */}
              <Col
                xs={24}
                s={24}
                sm={7}
                md={7}
                lg={5}
                xl={5}
                xxl={3}
                style={{ marginBottom: "15px" }}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  allowClear={false}
                  className={"select-style-dashboard"}
                  locale={locale}
                  picker="day"
                  defaultValue={moment()}
                  onChange={(fecha) => {
                    if (fecha == null) {
                      this.setFecha(moment());
                    } else {
                      this.setFecha(fecha);
                    }
                  }}
                />
              </Col>

              {/* BTN FILTRAR */}
              <Col
                xs={24}
                s={24}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                xxl={3}
                className={"btn-col-filtrar-dashboard "}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  className={"btn-filtrar-dashboard"}
                  onClick={() => {
                    const { fechaSelecionada } = this.state;
                    const objeto = {
                      fecha: fechaSelecionada,
                    };
                    this.filtrar(objeto);
                  }}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>

            {/*Grafico*/}
            <Row>
              {/* Titulos por expirar */}
              <Card
                xs={24}
                s={24}
                sm={24}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                className="card-chart-vencimiento-dashboard"
              >
                <Row style={{ marginBottom: "10px" }}>
                  <Col span={12}>
                    <h1
                      className={"h1-title-vencimiento-dashboard"}
                    >
                      Títulos por expirar
                    </h1>
                  </Col>

                  <Col span={12} style={{textAlign: "right"}}>
                    <Button
                      className={"btn-detalle-vencimiento-dashboard"}
                      onClick={() => {
                        this.detalleTitulosPorExpirar();
                      }}
                    >
                      <span className="text-detalle-dashboard">Detalle</span>
                      <span><EyeFilled className="iconoBotonDetalleCurvaRelevos"/></span>
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <DemoBar data={dataGraficoTitulosPorExpirar} />
                  </Col>
                </Row>
              </Card>

              {/* Titulos expirados */}
              <Card
                xs={24}
                s={24}
                sm={24}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                className="card-chart-vencimiento-dashboard"
              >
                <Row style={{ marginBottom: "10px" }}>
                  <Col span={12}>
                    <h1
                      className={"h1-title-vencimiento-dashboard"}
                    >
                      Títulos expirados
                    </h1>
                  </Col>

                  <Col span={12} style={{textAlign: "right"}}>
                    <Button
                      className={"btn-detalle-vencimiento-dashboard"}
                      onClick={() => {
                        this.detalleTitulosExpirados();
                      }}
                    >
                      <span className="text-detalle-dashboard">Detalle</span>
                      <span><EyeFilled className="iconoBotonDetalleCurvaRelevos"/></span>
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <DemoBar data={dataGraficoTitulosExpirados} />
                  </Col>
                </Row>
              </Card>

              {/* Especialidades por expirar */}
              <Card
                xs={24}
                s={24}
                sm={24}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                className="card-chart-vencimiento-dashboard"
              >
                <Row style={{ marginBottom: "10px" }}>
                  <Col span={12}>
                    <h1
                      className={"h1-title-vencimiento-dashboard"}
                    >
                      Especialidades por expirar
                    </h1>
                  </Col>

                  <Col span={12} style={{textAlign: "right"}}>
                    <Button
                      className={"btn-detalle-vencimiento-dashboard"}
                      onClick={() => {
                        this.detalleEspecialidadesPorExpirar();
                      }}
                    >
                      <span className="text-detalle-dashboard">Detalle</span>
                      <span><EyeFilled className="iconoBotonDetalleCurvaRelevos"/></span>
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <DemoBar data={dataGraficoEspecialidadesPorExpirar} />
                  </Col>
                </Row>
              </Card>

              {/*  Especialidades expiradas */}
              <Card
                xs={24}
                s={24}
                sm={24}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                className="card-chart-vencimiento-dashboard"
              >
                <Row style={{ marginBottom: "10px" }}>
                  <Col span={12}>
                    <h1
                      className={"h1-title-vencimiento-dashboard"}
                    >
                      Especialidades expiradas
                    </h1>
                  </Col>

                  <Col span={12} style={{textAlign: "right"}}>
                    <Button
                      className={"btn-detalle-vencimiento-dashboard"}
                      onClick={() => {
                        this.detalleEspecialidadesExpiradas();
                      }}
                    >
                      <span className="text-detalle-dashboard">Detalle</span>
                      <span><EyeFilled className="iconoBotonDetalleCurvaRelevos"/></span>
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <DemoBar data={dataGraficoEspecialidadesExpiradas} />
                  </Col>
                </Row>
              </Card>

              {/* Epps por expirar */}
              <Card
                xs={24}
                s={24}
                sm={24}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                className="card-chart-vencimiento-dashboard"
              >
                <Row style={{ marginBottom: "10px" }}>
                  <Col span={12}>
                    <h1
                      className={"h1-title-vencimiento-dashboard"}
                    >
                      Epps por expirar
                    </h1>
                  </Col>

                  <Col span={12} style={{textAlign: "right"}}>
                    <Button
                      className={"btn-detalle-vencimiento-dashboard"}
                      onClick={() => {
                        this.detalleEppsPorExpirar();
                      }}
                    >
                      <span className="text-detalle-dashboard">Detalle</span>
                      <span><EyeFilled className="iconoBotonDetalleCurvaRelevos"/></span>
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <DemoBar data={dataGraficoEppsPorExpirar} />
                  </Col>
                </Row>
              </Card>

              {/* Epps expiradas */}
              <Card
                xs={24}
                s={24}
                sm={24}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                className="card-chart-vencimiento-dashboard"
              >
                <Row style={{ marginBottom: "10px" }}>
                  <Col span={12}>
                    <h1
                      className={"h1-title-vencimiento-dashboard"}
                    >
                      Epps expiradas
                    </h1>
                  </Col>

                  <Col span={12} style={{textAlign: "right"}}>
                    <Button
                      className={"btn-detalle-vencimiento-dashboard"}
                      onClick={() => {
                        this.detalleEppsExpiradas();
                      }}
                    >
                      <span className="text-detalle-dashboard">Detalle</span>
                      <span><EyeFilled className="iconoBotonDetalleCurvaRelevos"/></span>
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <DemoBar data={dataGraficoEppsExpiradas} />
                  </Col>
                </Row>
              </Card>

               {/* Documentos por expirar */}
               <Card
                xs={24}
                s={24}
                sm={24}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                className="card-chart-vencimiento-dashboard"
              >
                <Row style={{ marginBottom: "10px" }}>
                  <Col span={12}>
                    <h1
                      className={"h1-title-vencimiento-dashboard"}
                    >
                      Documentos por expirar
                    </h1>
                  </Col>

                  <Col span={12} style={{textAlign: "right"}}>
                    <Button
                      className={"btn-detalle-vencimiento-dashboard"}
                      onClick={() => {
                        this.detalleDocumentosPorExpirar();
                      }}
                    >
                      <span className="text-detalle-dashboard">Detalle</span>
                      <span><EyeFilled className="iconoBotonDetalleCurvaRelevos"/></span>
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <DemoBar data={dataGraficoDocumentosPorExpirar} />
                  </Col>
                </Row>
              </Card>

               {/* Documentos expiradas */}
               <Card
                xs={24}
                s={24}
                sm={24}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                className="card-chart-vencimiento-dashboard"
              >
                <Row style={{ marginBottom: "10px" }}>
                  <Col span={12}>
                    <h1
                      className={"h1-title-vencimiento-dashboard"}
                    >
                      Documentos expiradas
                    </h1>
                  </Col>

                  <Col span={12} style={{textAlign: "right"}}>
                    <Button
                      className={"btn-detalle-vencimiento-dashboard"}
                      onClick={() => {
                        this.detalleDocumentosExpiradas();
                      }}
                    >
                      <span className="text-detalle-dashboard">Detalle</span>
                      <span><EyeFilled className="iconoBotonDetalleCurvaRelevos"/></span>
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <DemoBar data={dataGraficoDocumentosExpiradas} />
                  </Col>
                </Row>
              </Card>
            </Row>
          </Card>
        </Col>

        {/* Modal Titulos Por Expirar */}
        <Modal
          visible={modalDetalleTitulosPorExpirar}
          onCancel={() =>
            this.setState({ modalDetalleTitulosPorExpirar: false })
          }
          className={"modal-detalle-dashboard"}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
              <h3 className="modal-h3-dashboard">
                Trabajadores por Títulos por expirar
              </h3>
            </Col>

            <Col
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              xxl={4}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                className={"modal-btn-excel-dashboard"}
                onClick={() => {
                  let { indexActual, dataDetalleTitulosPorExpirar } =
                    this.state;
                  let index = parseInt(indexActual) - 1;

                  const dataDescargar =
                    dataDetalleTitulosPorExpirar[index].trabajadores !== null &&
                    dataDetalleTitulosPorExpirar[index].trabajadores !== 0
                      ? dataDetalleTitulosPorExpirar[index].trabajadores
                      : [];
                  this.obtenerExcelDetalleVencimiento(
                    dataDescargar,
                    dataDetalleTitulosPorExpirar[index].type
                  );
                }}
                // onClick={this.descargarExcelDetalleVencimiento}
              >
                <span className="modal-btn-text-excel-dashboard">
                  Descargar Excel
                </span>
                <span>
                  <img
                    src={Excel}
                    className="modal-btn-icon-excel-dashboard"
                    alt="Icono Excel"
                  />
                </span>
              </Button>
            </Col>
          </Row>
          <hr className="modal-division-detalle-dashboard" />
          {/* TABLE */}
          <Row>
            <Col className="modal-tabla-detalle-dashboard" span={24}>
              {/* <Tabs key={1} defaultActiveKey="1"> */}
              <Tabs
                key={indexActual}
                defaultActiveKey={indexActual}
                activeKey={indexActual}
                onTabClick={(e) => {
                  this.setState({
                    indexActual: e,
                    trabajadoresCopia: undefined,
                  });
                }}
              >
                {dataDetalleTitulosPorExpirar.length > 0 ? (
                  <>
                    {dataDetalleTitulosPorExpirar.map((valor, index) => (
                      <TabPane
                        tab={valor.type + ` (${valor.trabajadores.length})`}
                        key={index + 1}
                      >
                        {valor.trabajadores.length > 0 ? (
                          <>
                            <Row
                              style={{ padding: "6px 0px 22px" }}
                              align="bottom"
                            >
                              <Col
                                className="div-input-search"
                                xs={24}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                xxl={6}
                              >
                                <Search
                                  placeholder="Buscar"
                                  enterButton
                                  onChange={(value) =>
                                    this.search(value, index)
                                  }
                                />
                              </Col>
                            </Row>

                            <Table
                              key={index + 1}
                              // rowKey="Identificador"
                              dataSource={
                                // dataDetalleTitulosPorExpirar[index] !== undefined ?
                                // dataDetalleTitulosPorExpirar[index].trabajadores : valor.trabajadores
                                trabajadoresCopia === undefined
                                  ? valor !== undefined
                                    ? valor.trabajadores
                                    : []
                                  : trabajadoresCopia.trabajadores
                              }
                              bordered={true}
                              showSorterTooltip={false}
                              scroll={{ x: true }}
                              pagination={{ pageSize: 10 }}
                              className={"modal-tabla-detalle-dashboard"}
                              locale={{
                                emptyText:
                                  "No se encontraron registros de la tabla.",
                              }}
                            >
                              {/* Imagen */}
                              <Column
                                align="center"
                                title="Imagen"
                                key="Imagen"
                                render={(value) => {
                                  return (
                                    <img
                                      src={value.Imagen}
                                      className="modal-img-tabla-dashboard"
                                      alt="i"
                                    />
                                  );
                                }}
                              />

                              {/* Nombre Completo */}
                              <Column
                                sorter={(a, b) => {
                                  return a.Nombre.localeCompare(b.Nombre);
                                }}
                                title="Nombre"
                                key="NombreCompletoTratado"
                                render={(value) => {
                                  return `${value.NombreCompletoTratado}`;
                                }}
                              />

                              {/* Nombre Titulos */}
                              <Column
                                sorter={(a, b) => {
                                  return a.NombreArticulo.localeCompare(
                                    b.NombreArticulo
                                  );
                                }}
                                title="Nombre Titulos"
                                key="NombreArticulo"
                                render={(value) => {
                                  return `${value.NombreArticulo}`;
                                }}
                              />

                              {/* Fecha Vencimiento */}
                              <Column
                                sorter={(a, b) => {
                                  const fechaInicioa =
                                    a.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          a.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  const fechaIniciob =
                                    b.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          b.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  return fechaIniciob - fechaInicioa;
                                }}
                                title="Fecha Vencimiento"
                                key="FechaVencimiento"
                                render={(value) => {
                                  return `${value.FechaVencimiento}`;
                                }}
                              />

                              {/* Ficha trabajador */}
                              <Column
                                title="Detalle"
                                key="FichaTrabajador"
                                align="center"
                                render={(value) => (
                                  <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                                    <Button
                                      onClick={() => {
                                        this.NuevaPestaña(value._id);
                                      }}
                                      className="acciones-trabajador-button hover-azul"
                                      icon={<EyeFilled style={{ fontSize: '18px' }} />}
                                    />
                                  </Tooltip>
                                )}
                              />

                            </Table>
                          </>
                        ) : (
                          <>
                            <Row>
                              <Col span={24}>
                                <Empty
                                  imageStyle={{
                                    height: 200,
                                    marginTop: "20px",
                                  }}
                                  description={
                                    <b
                                      className={
                                        "seleccion-trabajador-disponible"
                                      }
                                    >
                                      No se encontró información relacionada con
                                      este filtro.
                                    </b>
                                  }
                                ></Empty>
                              </Col>
                            </Row>
                          </>
                        )}
                      </TabPane>
                    ))}
                  </>
                ) : (
                  <TabPane tab={"No se encontro información"} key={1}>
                    <>
                      <Row>
                        <Col span={24}>
                          <Empty
                            imageStyle={{
                              height: 200,
                              marginTop: "20px",
                            }}
                            description={
                              <b className={"modal-empty-b-dashboard"}>
                                No se encontró información de los trabajadores.
                              </b>
                            }
                          ></Empty>
                        </Col>
                      </Row>
                    </>
                  </TabPane>
                )}
              </Tabs>
            </Col>
          </Row>
        </Modal>

        {/* Modal Titulos Expirados */}
        <Modal
          visible={modalDetalleTitulosExpirados}
          onCancel={() =>
            this.setState({ modalDetalleTitulosExpirados: false })
          }
          className={"modal-detalle-dashboard"}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
              <h3 className="modal-h3-dashboard">
                Trabajadores por Títulos expirados
              </h3>
            </Col>

            <Col
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              xxl={4}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                className={"modal-btn-excel-dashboard"}
                onClick={() => {
                  let { indexActual, dataDetalleTitulosExpirados } = this.state;
                  let index = parseInt(indexActual) - 1;

                  const dataDescargar =
                    dataDetalleTitulosExpirados[index].trabajadores !== null &&
                    dataDetalleTitulosExpirados[index].trabajadores !== 0
                      ? dataDetalleTitulosExpirados[index].trabajadores
                      : [];
                  this.obtenerExcelDetalleVencimiento(
                    dataDescargar,
                    dataDetalleTitulosExpirados[index].type
                  );
                }}
                //onClick={this.obtenerExcelDetalleVencimiento}
              >
                <span className="modal-btn-text-excel-dashboard">
                  Descargar Excel
                </span>
                <span>
                  <img
                    src={Excel}
                    className="modal-btn-icon-excel-dashboard"
                    alt="Icono Excel"
                  />
                </span>
              </Button>
            </Col>
          </Row>
          <hr className="modal-division-detalle-dashboard" />
          {/* TABLE */}
          <Row>
            <Col className="modal-tabla-detalle-dashboard" span={24}>
              {/* <Tabs key={1} defaultActiveKey="1"> */}
              <Tabs
                key={indexActual}
                defaultActiveKey={indexActual}
                activeKey={indexActual}
                onTabClick={(e) => {
                  this.setState({
                    indexActual: e,
                    trabajadoresCopia: undefined,
                  });
                }}
              >
                {dataDetalleTitulosExpirados.length > 0 ? (
                  <>
                    {dataDetalleTitulosExpirados.map((valor, index) => (
                      <TabPane
                        tab={valor.type + ` (${valor.trabajadores.length})`}
                        key={index + 1}
                      >
                        {valor.trabajadores.length > 0 ? (
                          <>
                            <Row
                              style={{ padding: "6px 0px 22px" }}
                              align="bottom"
                            >
                              <Col
                                className="div-input-search"
                                xs={24}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                xxl={6}
                              >
                                <Search
                                  placeholder="Buscar"
                                  enterButton
                                  onChange={(value) =>
                                    this.search(value, index)
                                  }
                                />
                              </Col>
                            </Row>

                            <Table
                              key={index + 1}
                              // rowKey="Identificador"
                              dataSource={
                                // dataDetalleTitulosExpirados[index] !== undefined ?
                                // dataDetalleTitulosExpirados[index].trabajadores : valor.trabajadores
                                trabajadoresCopia === undefined
                                  ? valor !== undefined
                                    ? valor.trabajadores
                                    : []
                                  : trabajadoresCopia.trabajadores
                              }
                              bordered={true}
                              showSorterTooltip={false}
                              scroll={{ x: true }}
                              pagination={{ pageSize: 10 }}
                              className={"modal-tabla-detalle-dashboard"}
                              locale={{
                                emptyText:
                                  "No se encontraron registros de la tabla.",
                              }}
                            >
                              {/* Imagen */}
                              <Column
                                align="center"
                                title="Imagen"
                                key="Imagen"
                                render={(value) => {
                                  return (
                                    <img
                                      src={value.Imagen}
                                      className="modal-img-tabla-dashboard"
                                      alt="i"
                                    />
                                  );
                                }}
                              />

                              {/* Nombre Completo */}
                              <Column
                                sorter={(a, b) => {
                                  return a.Nombre.localeCompare(b.Nombre);
                                }}
                                title="Nombre"
                                key="NombreCompletoTratado"
                                render={(value) => {
                                  return `${value.NombreCompletoTratado}`;
                                }}
                              />

                              {/* Nombre Titulos */}
                              <Column
                                sorter={(a, b) => {
                                  return a.NombreArticulo.localeCompare(
                                    b.NombreArticulo
                                  );
                                }}
                                title="Nombre Titulos"
                                key="NombreArticulo"
                                render={(value) => {
                                  return `${value.NombreArticulo}`;
                                }}
                              />

                              {/* Fecha Vencimiento */}
                              <Column
                                sorter={(a, b) => {
                                  const fechaInicioa =
                                    a.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          a.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  const fechaIniciob =
                                    b.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          b.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  return fechaIniciob - fechaInicioa;
                                }}
                                title="Fecha Vencimiento"
                                key="FechaVencimiento"
                                render={(value) => {
                                  return `${value.FechaVencimiento}`;
                                }}
                              />

                              {/* Ficha trabajador */}
                              <Column
                                title="Detalle"
                                key="FichaTrabajador"
                                align="center"
                                render={(value) => (
                                  <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                                    <Button
                                      onClick={() => {
                                        this.NuevaPestaña(value._id);
                                      }}
                                      className="acciones-trabajador-button hover-azul"
                                      icon={<EyeFilled style={{ fontSize: '18px' }} />}
                                    />
                                  </Tooltip>
                                )}
                              />

                            </Table>
                          </>
                        ) : (
                          <>
                            <Row>
                              <Col span={24}>
                                <Empty
                                  imageStyle={{
                                    height: 200,
                                    marginTop: "20px",
                                  }}
                                  description={
                                    <b
                                      className={
                                        "seleccion-trabajador-disponible"
                                      }
                                    >
                                      No se encontró información relacionada con
                                      este filtro.
                                    </b>
                                  }
                                ></Empty>
                              </Col>
                            </Row>
                          </>
                        )}
                      </TabPane>
                    ))}
                  </>
                ) : (
                  <TabPane tab={"No se encontro información"} key={1}>
                    <>
                      <Row>
                        <Col span={24}>
                          <Empty
                            imageStyle={{
                              height: 200,
                              marginTop: "20px",
                            }}
                            description={
                              <b className={"modal-empty-b-dashboard"}>
                                No se encontró información de los trabajadores.
                              </b>
                            }
                          ></Empty>
                        </Col>
                      </Row>
                    </>
                  </TabPane>
                )}
              </Tabs>
            </Col>
          </Row>
        </Modal>

        {/* Modal Especialidades Por Expirar */}
        <Modal
          visible={modalDetalleEspecialidadesPorExpirar}
          onCancel={() =>
            this.setState({ modalDetalleEspecialidadesPorExpirar: false })
          }
          className={"modal-detalle-dashboard"}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
              <h3 className="modal-h3-dashboard">
                Trabajadores por Especialidades Por Expirar
              </h3>
            </Col>

            <Col
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              xxl={4}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                className={"modal-btn-excel-dashboard"}
                onClick={() => {
                  let { indexActual, dataDetalleEspecialidadesPorExpirar } =
                    this.state;
                  let index = parseInt(indexActual) - 1;

                  const dataDescargar =
                    dataDetalleEspecialidadesPorExpirar[index].trabajadores !==
                      null &&
                    dataDetalleEspecialidadesPorExpirar[index].trabajadores !==
                      0
                      ? dataDetalleEspecialidadesPorExpirar[index].trabajadores
                      : [];
                  this.obtenerExcelDetalleVencimiento(
                    dataDescargar,
                    dataDetalleEspecialidadesPorExpirar[index].type
                  );
                }}
                //onClick={this.descargarExcel}
              >
                <span className="modal-btn-text-excel-dashboard">
                  Descargar Excel
                </span>
                <span>
                  <img
                    src={Excel}
                    className="modal-btn-icon-excel-dashboard"
                    alt="Icono Excel"
                  />
                </span>
              </Button>
            </Col>
          </Row>
          <hr className="modal-division-detalle-dashboard" />
          {/* TABLE */}
          <Row>
            <Col className="modal-tabla-detalle-dashboard" span={24}>
              {/* <Tabs key={1} defaultActiveKey="1"> */}
              <Tabs
                key={indexActual}
                defaultActiveKey={indexActual}
                activeKey={indexActual}
                onTabClick={(e) => {
                  this.setState({
                    indexActual: e,
                    trabajadoresCopia: undefined,
                  });
                }}
              >
                {dataDetalleEspecialidadesPorExpirar.length > 0 ? (
                  <>
                    {dataDetalleEspecialidadesPorExpirar.map((valor, index) => (
                      <TabPane
                        tab={valor.type + ` (${valor.trabajadores.length})`}
                        key={index + 1}
                      >
                        {valor.trabajadores.length > 0 ? (
                          <>
                            <Row
                              style={{ padding: "6px 0px 22px" }}
                              align="bottom"
                            >
                              <Col
                                className="div-input-search"
                                xs={24}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                xxl={6}
                              >
                                <Search
                                  placeholder="Buscar"
                                  enterButton
                                  onChange={(value) =>
                                    this.search(value, index)
                                  }
                                />
                              </Col>
                            </Row>

                            <Table
                              key={index + 1}
                              // rowKey="Identificador"
                              dataSource={
                                // dataDetalleEspecialidadesPorExpirar[index] !== undefined ?
                                // dataDetalleEspecialidadesPorExpirar[index].trabajadores : valor.trabajadores
                                trabajadoresCopia === undefined
                                  ? valor !== undefined
                                    ? valor.trabajadores
                                    : []
                                  : trabajadoresCopia.trabajadores
                              }
                              bordered={true}
                              showSorterTooltip={false}
                              scroll={{ x: true }}
                              pagination={{ pageSize: 10 }}
                              className={"modal-tabla-detalle-dashboard"}
                              locale={{
                                emptyText:
                                  "No se encontraron registros de la tabla.",
                              }}
                            >
                              {/* Imagen */}
                              <Column
                                align="center"
                                title="Imagen"
                                key="Imagen"
                                render={(value) => {
                                  return (
                                    <img
                                      src={value.Imagen}
                                      className="modal-img-tabla-dashboard"
                                      alt="i"
                                    />
                                  );
                                }}
                              />

                              {/* Nombre Completo */}
                              <Column
                                sorter={(a, b) => {
                                  return a.Nombre.localeCompare(b.Nombre);
                                }}
                                title="Nombre"
                                key="NombreCompletoTratado"
                                render={(value) => {
                                  return `${value.NombreCompletoTratado}`;
                                }}
                              />

                              {/* Nombre Especialidades */}
                              <Column
                                sorter={(a, b) => {
                                  return a.NombreArticulo.localeCompare(
                                    b.NombreArticulo
                                  );
                                }}
                                title="Nombre Especialidades"
                                key="NombreArticulo"
                                render={(value) => {
                                  return `${value.NombreArticulo}`;
                                }}
                              />

                              {/* Fecha Vencimiento */}
                              <Column
                                sorter={(a, b) => {
                                  const fechaInicioa =
                                    a.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          a.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  const fechaIniciob =
                                    b.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          b.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  return fechaIniciob - fechaInicioa;
                                }}
                                title="Fecha Vencimiento"
                                key="FechaVencimiento"
                                render={(value) => {
                                  return `${value.FechaVencimiento}`;
                                }}
                              />

                              {/* Ficha trabajador */}
                              <Column
                                title="Detalle"
                                key="FichaTrabajador"
                                align="center"
                                render={(value) => (
                                  <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                                    <Button
                                      onClick={() => {
                                        this.NuevaPestaña(value._id);
                                      }}
                                      className="acciones-trabajador-button hover-azul"
                                      icon={<EyeFilled style={{ fontSize: '18px' }} />}
                                    />
                                  </Tooltip>
                                )}
                              />

                            </Table>
                          </>
                        ) : (
                          <>
                            <Row>
                              <Col span={24}>
                                <Empty
                                  imageStyle={{
                                    height: 200,
                                    marginTop: "20px",
                                  }}
                                  description={
                                    <b
                                      className={
                                        "seleccion-trabajador-disponible"
                                      }
                                    >
                                      No se encontró información relacionada con
                                      este filtro.
                                    </b>
                                  }
                                ></Empty>
                              </Col>
                            </Row>
                          </>
                        )}
                      </TabPane>
                    ))}
                  </>
                ) : (
                  <TabPane tab={"No se encontro información"} key={1}>
                    <>
                      <Row>
                        <Col span={24}>
                          <Empty
                            imageStyle={{
                              height: 200,
                              marginTop: "20px",
                            }}
                            description={
                              <b className={"modal-empty-b-dashboard"}>
                                No se encontró información de los trabajadores.
                              </b>
                            }
                          ></Empty>
                        </Col>
                      </Row>
                    </>
                  </TabPane>
                )}
              </Tabs>
            </Col>
          </Row>
        </Modal>

        {/* Modal Especialidades Expiradas */}
        <Modal
          visible={modalDetalleEspecialidadesExpiradas}
          onCancel={() =>
            this.setState({ modalDetalleEspecialidadesExpiradas: false })
          }
          className={"modal-detalle-dashboard"}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
              <h3 className="modal-h3-dashboard">
                Trabajadores por Especialidades Expiradas
              </h3>
            </Col>

            <Col
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              xxl={4}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                className={"modal-btn-excel-dashboard"}
                onClick={() => {
                  let { indexActual, dataDetalleEspecialidadesExpiradas } =
                    this.state;
                  let index = parseInt(indexActual) - 1;

                  const dataDescargar =
                    dataDetalleEspecialidadesExpiradas[index].trabajadores !==
                      null &&
                    dataDetalleEspecialidadesExpiradas[index].trabajadores !== 0
                      ? dataDetalleEspecialidadesExpiradas[index].trabajadores
                      : [];
                  this.obtenerExcelDetalleVencimiento(
                    dataDescargar,
                    dataDetalleEspecialidadesExpiradas[index].type
                  );
                }}
                //onClick={this.descargarExcel}
              >
                <span className="modal-btn-text-excel-dashboard">
                  Descargar Excel
                </span>
                <span>
                  <img
                    src={Excel}
                    className="modal-btn-icon-excel-dashboard"
                    alt="Icono Excel"
                  />
                </span>
              </Button>
            </Col>
          </Row>
          <hr className="modal-division-detalle-dashboard" />
          {/* TABLE */}
          <Row>
            <Col className="modal-tabla-detalle-dashboard" span={24}>
              {/* <Tabs key={1} defaultActiveKey="1"> */}
              <Tabs
                key={indexActual}
                defaultActiveKey={indexActual}
                activeKey={indexActual}
                onTabClick={(e) => {
                  this.setState({
                    indexActual: e,
                    trabajadoresCopia: undefined,
                  });
                }}
              >
                {dataDetalleEspecialidadesExpiradas.length > 0 ? (
                  <>
                    {dataDetalleEspecialidadesExpiradas.map((valor, index) => (
                      <TabPane
                        tab={valor.type + ` (${valor.trabajadores.length})`}
                        key={index + 1}
                      >
                        {valor.trabajadores.length > 0 ? (
                          <>
                            <Row
                              style={{ padding: "6px 0px 22px" }}
                              align="bottom"
                            >
                              <Col
                                className="div-input-search"
                                xs={24}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                xxl={6}
                              >
                                <Search
                                  placeholder="Buscar"
                                  enterButton
                                  onChange={(value) =>
                                    this.search(value, index)
                                  }
                                />
                              </Col>
                            </Row>

                            <Table
                              key={index + 1}
                              // rowKey="Identificador"
                              dataSource={
                                // dataDetalleEspecialidadesExpiradas[index] !== undefined ?
                                // dataDetalleEspecialidadesExpiradas[index].trabajadores : valor.trabajadores
                                trabajadoresCopia === undefined
                                  ? valor !== undefined
                                    ? valor.trabajadores
                                    : []
                                  : trabajadoresCopia.trabajadores
                              }
                              bordered={true}
                              showSorterTooltip={false}
                              scroll={{ x: true }}
                              pagination={{ pageSize: 10 }}
                              className={"modal-tabla-detalle-dashboard"}
                              locale={{
                                emptyText:
                                  "No se encontraron registros de la tabla.",
                              }}
                            >
                              {/* Imagen */}
                              <Column
                                align="center"
                                title="Imagen"
                                key="Imagen"
                                render={(value) => {
                                  return (
                                    <img
                                      src={value.Imagen}
                                      className="modal-img-tabla-dashboard"
                                      alt="i"
                                    />
                                  );
                                }}
                              />

                              {/* Nombre Completo */}
                              <Column
                                sorter={(a, b) => {
                                  return a.Nombre.localeCompare(b.Nombre);
                                }}
                                title="Nombre"
                                key="NombreCompletoTratado"
                                render={(value) => {
                                  return `${value.NombreCompletoTratado}`;
                                }}
                              />

                              {/* Nombre Especialidades */}
                              <Column
                                sorter={(a, b) => {
                                  return a.NombreArticulo.localeCompare(
                                    b.NombreArticulo
                                  );
                                }}
                                title="Nombre Especialidades"
                                key="NombreArticulo"
                                render={(value) => {
                                  return `${value.NombreArticulo}`;
                                }}
                              />

                              {/* Fecha Vencimiento */}
                              <Column
                                sorter={(a, b) => {
                                  const fechaInicioa =
                                    a.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          a.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  const fechaIniciob =
                                    b.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          b.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  return fechaIniciob - fechaInicioa;
                                }}
                                title="Fecha Vencimiento"
                                key="FechaVencimiento"
                                render={(value) => {
                                  return `${value.FechaVencimiento}`;
                                }}
                              />

                              {/* Ficha trabajador */}
                              <Column
                                title="Detalle"
                                key="FichaTrabajador"
                                align="center"
                                render={(value) => (
                                  <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                                    <Button
                                      onClick={() => {
                                        this.NuevaPestaña(value._id);
                                      }}
                                      className="acciones-trabajador-button hover-azul"
                                      icon={<EyeFilled style={{ fontSize: '18px' }} />}
                                    />
                                  </Tooltip>
                                )}
                              />

                            </Table>
                          </>
                        ) : (
                          <>
                            <Row>
                              <Col span={24}>
                                <Empty
                                  imageStyle={{
                                    height: 200,
                                    marginTop: "20px",
                                  }}
                                  description={
                                    <b
                                      className={
                                        "seleccion-trabajador-disponible"
                                      }
                                    >
                                      No se encontró información relacionada con
                                      este filtro.
                                    </b>
                                  }
                                ></Empty>
                              </Col>
                            </Row>
                          </>
                        )}
                      </TabPane>
                    ))}
                  </>
                ) : (
                  <TabPane tab={"No se encontro información"} key={1}>
                    <>
                      <Row>
                        <Col span={24}>
                          <Empty
                            imageStyle={{
                              height: 200,
                              marginTop: "20px",
                            }}
                            description={
                              <b className={"modal-empty-b-dashboard"}>
                                No se encontró información de los trabajadores.
                              </b>
                            }
                          ></Empty>
                        </Col>
                      </Row>
                    </>
                  </TabPane>
                )}
              </Tabs>
            </Col>
          </Row>
        </Modal>

        {/* Modal Epps Por Expirar */}
        <Modal
          visible={modalDetalleEppsPorExpirar}
          onCancel={() => this.setState({ modalDetalleEppsPorExpirar: false })}
          className={"modal-detalle-dashboard"}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
              <h3 className="modal-h3-dashboard">
                Trabajadores por Epps Por Expirar
              </h3>
            </Col>

            <Col
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              xxl={4}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                className={"modal-btn-excel-dashboard"}
                onClick={() => {
                  let { indexActual, dataDetalleEppsPorExpira } = this.state;
                  let index = parseInt(indexActual) - 1;

                  const dataDescargar =
                    dataDetalleEppsPorExpira[index].trabajadores !== null &&
                    dataDetalleEppsPorExpira[index].trabajadores !== 0
                      ? dataDetalleEppsPorExpira[index].trabajadores
                      : [];
                  this.obtenerExcelDetalleVencimiento(
                    dataDescargar,
                    dataDetalleEppsPorExpira[index].type
                  );
                }}
                //onClick={this.descargarExcel}
              >
                <span className="modal-btn-text-excel-dashboard">
                  Descargar Excel
                </span>
                <span>
                  <img
                    src={Excel}
                    className="modal-btn-icon-excel-dashboard"
                    alt="Icono Excel"
                  />
                </span>
              </Button>
            </Col>
          </Row>
          <hr className="modal-division-detalle-dashboard" />
          {/* TABLE */}
          <Row>
            <Col className="modal-tabla-detalle-dashboard" span={24}>
              {/* <Tabs key={1} defaultActiveKey="1"> */}
              <Tabs
                key={indexActual}
                defaultActiveKey={indexActual}
                activeKey={indexActual}
                onTabClick={(e) => {
                  this.setState({
                    indexActual: e,
                    trabajadoresCopia: undefined,
                  });
                }}
              >
                {dataDetalleEppsPorExpira.length > 0 ? (
                  <>
                    {dataDetalleEppsPorExpira.map((valor, index) => (
                      <TabPane
                        tab={valor.type + ` (${valor.trabajadores.length})`}
                        key={index + 1}
                      >
                        {valor.trabajadores.length > 0 ? (
                          <>
                            <Row
                              style={{ padding: "6px 0px 22px" }}
                              align="bottom"
                            >
                              <Col
                                className="div-input-search"
                                xs={24}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                xxl={6}
                              >
                                <Search
                                  placeholder="Buscar"
                                  enterButton
                                  onChange={(value) =>
                                    this.search(value, index)
                                  }
                                />
                              </Col>
                            </Row>

                            <Table
                              key={index + 1}
                              // rowKey="Identificador"
                              dataSource={
                                // dataDetalleEppsPorExpira[index] !== undefined ?
                                // dataDetalleEppsPorExpira[index].trabajadores : valor.trabajadores
                                trabajadoresCopia === undefined
                                  ? valor !== undefined
                                    ? valor.trabajadores
                                    : []
                                  : trabajadoresCopia.trabajadores
                              }
                              bordered={true}
                              showSorterTooltip={false}
                              scroll={{ x: true }}
                              pagination={{ pageSize: 10 }}
                              className={"modal-tabla-detalle-dashboard"}
                              locale={{
                                emptyText:
                                  "No se encontraron registros de la tabla.",
                              }}
                            >
                              {/* Imagen */}
                              <Column
                                align="center"
                                title="Imagen"
                                key="Imagen"
                                render={(value) => {
                                  return (
                                    <img
                                      src={value.Imagen}
                                      className="modal-img-tabla-dashboard"
                                      alt="i"
                                    />
                                  );
                                }}
                              />

                              {/* Nombre Completo */}
                              <Column
                                sorter={(a, b) => {
                                  return a.Nombre.localeCompare(b.Nombre);
                                }}
                                title="Nombre"
                                key="NombreCompletoTratado"
                                render={(value) => {
                                  return `${value.NombreCompletoTratado}`;
                                }}
                              />

                              {/* Nombre  Epps */}
                              <Column
                                sorter={(a, b) => {
                                  return a.NombreArticulo.localeCompare(
                                    b.NombreArticulo
                                  );
                                }}
                                title="Nombre Epps"
                                key="NombreArticulo"
                                render={(value) => {
                                  return `${value.NombreArticulo}`;
                                }}
                              />

                              {/* Fecha Vencimiento */}
                              <Column
                                sorter={(a, b) => {
                                  const fechaInicioa =
                                    a.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          a.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  const fechaIniciob =
                                    b.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          b.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  return fechaIniciob - fechaInicioa;
                                }}
                                title="Fecha Vencimiento"
                                key="FechaVencimiento"
                                render={(value) => {
                                  return `${value.FechaVencimiento}`;
                                }}
                              />

                              {/* Ficha trabajador */}
                              <Column
                                title="Detalle"
                                key="FichaTrabajador"
                                align="center"
                                render={(value) => (
                                  <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                                    <Button
                                      onClick={() => {
                                        this.NuevaPestaña(value._id);
                                      }}
                                      className="acciones-trabajador-button hover-azul"
                                      icon={<EyeFilled style={{ fontSize: '18px' }} />}
                                    />
                                  </Tooltip>
                                )}
                              />

                            </Table>
                          </>
                        ) : (
                          <>
                            <Row>
                              <Col span={24}>
                                <Empty
                                  imageStyle={{
                                    height: 200,
                                    marginTop: "20px",
                                  }}
                                  description={
                                    <b
                                      className={
                                        "seleccion-trabajador-disponible"
                                      }
                                    >
                                      No se encontró información relacionada con
                                      este filtro.
                                    </b>
                                  }
                                ></Empty>
                              </Col>
                            </Row>
                          </>
                        )}
                      </TabPane>
                    ))}
                  </>
                ) : (
                  <TabPane tab={"No se encontro información"} key={1}>
                    <>
                      <Row>
                        <Col span={24}>
                          <Empty
                            imageStyle={{
                              height: 200,
                              marginTop: "20px",
                            }}
                            description={
                              <b className={"modal-empty-b-dashboard"}>
                                No se encontró información de los trabajadores.
                              </b>
                            }
                          ></Empty>
                        </Col>
                      </Row>
                    </>
                  </TabPane>
                )}
              </Tabs>
            </Col>
          </Row>
        </Modal>

        {/* Modal Epps Expiradas */}
        <Modal
          visible={modalDetalleEppsExpiradas}
          onCancel={() => this.setState({ modalDetalleEppsExpiradas: false })}
          className={"modal-detalle-dashboard"}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
              <h3 className="modal-h3-dashboard">
                Trabajadores por Epps Expiradas
              </h3>
            </Col>

            <Col
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              xxl={4}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                className={"modal-btn-excel-dashboard"}
                onClick={() => {
                  let { indexActual, dataDetalleEppsExpiradas } = this.state;
                  let index = parseInt(indexActual) - 1;

                  const dataDescargar =
                    dataDetalleEppsExpiradas[index].trabajadores !== null &&
                    dataDetalleEppsExpiradas[index].trabajadores !== 0
                      ? dataDetalleEppsExpiradas[index].trabajadores
                      : [];
                  this.obtenerExcelDetalleVencimiento(
                    dataDescargar,
                    dataDetalleEppsExpiradas[index].type
                  );
                }}
                //onClick={this.descargarExcel}
              >
                <span className="modal-btn-text-excel-dashboard">
                  Descargar Excel
                </span>
                <span>
                  <img
                    src={Excel}
                    className="modal-btn-icon-excel-dashboard"
                    alt="Icono Excel"
                  />
                </span>
              </Button>
            </Col>
          </Row>
          <hr className="modal-division-detalle-dashboard" />
          {/* TABLE */}
          <Row>
            <Col className="modal-tabla-detalle-dashboard" span={24}>
              {/* <Tabs key={1} defaultActiveKey="1"> */}
              <Tabs
                key={indexActual}
                defaultActiveKey={indexActual}
                activeKey={indexActual}
                onTabClick={(e) => {
                  this.setState({
                    indexActual: e,
                    trabajadoresCopia: undefined,
                  });
                }}
              >
                {dataDetalleEppsExpiradas.length > 0 ? (
                  <>
                    {dataDetalleEppsExpiradas.map((valor, index) => (
                      <TabPane
                        tab={valor.type + ` (${valor.trabajadores.length})`}
                        key={index + 1}
                      >
                        {valor.trabajadores.length > 0 ? (
                          <>
                            <Row
                              style={{ padding: "6px 0px 22px" }}
                              align="bottom"
                            >
                              <Col
                                className="div-input-search"
                                xs={24}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                xxl={6}
                              >
                                <Search
                                  placeholder="Buscar"
                                  enterButton
                                  onChange={(value) =>
                                    this.search(value, index)
                                  }
                                />
                              </Col>
                            </Row>

                            <Table
                              key={index + 1}
                              // rowKey="Identificador"
                              dataSource={
                                // dataDetalleEppsExpiradas[index] !== undefined ?
                                // dataDetalleEppsExpiradas[index].trabajadores : valor.trabajadores
                                trabajadoresCopia === undefined
                                  ? valor !== undefined
                                    ? valor.trabajadores
                                    : []
                                  : trabajadoresCopia.trabajadores
                              }
                              bordered={true}
                              showSorterTooltip={false}
                              scroll={{ x: true }}
                              pagination={{ pageSize: 10 }}
                              className={"modal-tabla-detalle-dashboard"}
                              locale={{
                                emptyText:
                                  "No se encontraron registros de la tabla.",
                              }}
                            >
                              {/* Imagen */}
                              <Column
                                align="center"
                                title="Imagen"
                                key="Imagen"
                                render={(value) => {
                                  return (
                                    <img
                                      src={value.Imagen}
                                      className="modal-img-tabla-dashboard"
                                      alt="i"
                                    />
                                  );
                                }}
                              />

                              {/* Nombre Completo */}
                              <Column
                                sorter={(a, b) => {
                                  return a.Nombre.localeCompare(b.Nombre);
                                }}
                                title="Nombre"
                                key="NombreCompletoTratado"
                                render={(value) => {
                                  return `${value.NombreCompletoTratado}`;
                                }}
                              />

                              {/* Nombre Epps */}
                              <Column
                                sorter={(a, b) => {
                                  return a.NombreArticulo.localeCompare(
                                    b.NombreArticulo
                                  );
                                }}
                                title="Nombre Epps"
                                key="NombreArticulo"
                                render={(value) => {
                                  return `${value.NombreArticulo}`;
                                }}
                              />

                              {/* Fecha Vencimiento */}
                              <Column
                                sorter={(a, b) => {
                                  const fechaInicioa =
                                    a.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          a.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  const fechaIniciob =
                                    b.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          b.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  return fechaIniciob - fechaInicioa;
                                }}
                                title="Fecha Vencimiento"
                                key="FechaVencimiento"
                                render={(value) => {
                                  return `${value.FechaVencimiento}`;
                                }}
                              />

                              {/* Ficha trabajador */}
                              <Column
                                title="Detalle"
                                key="FichaTrabajador"
                                align="center"
                                render={(value) => (
                                  <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                                    <Button
                                      onClick={() => {
                                        this.NuevaPestaña(value._id);
                                      }}
                                      className="acciones-trabajador-button hover-azul"
                                      icon={<EyeFilled style={{ fontSize: '18px' }} />}
                                    />
                                  </Tooltip>
                                )}
                              />

                            </Table>
                          </>
                        ) : (
                          <>
                            <Row>
                              <Col span={24}>
                                <Empty
                                  imageStyle={{
                                    height: 200,
                                    marginTop: "20px",
                                  }}
                                  description={
                                    <b
                                      className={
                                        "seleccion-trabajador-disponible"
                                      }
                                    >
                                      No se encontró información relacionada con
                                      este filtro.
                                    </b>
                                  }
                                ></Empty>
                              </Col>
                            </Row>
                          </>
                        )}
                      </TabPane>
                    ))}
                  </>
                ) : (
                  <TabPane tab={"No se encontro información"} key={1}>
                    <>
                      <Row>
                        <Col span={24}>
                          <Empty
                            imageStyle={{
                              height: 200,
                              marginTop: "20px",
                            }}
                            description={
                              <b className={"modal-empty-b-dashboard"}>
                                No se encontró información de los trabajadores.
                              </b>
                            }
                          ></Empty>
                        </Col>
                      </Row>
                    </>
                  </TabPane>
                )}
              </Tabs>
            </Col>
          </Row>
        </Modal>

        {/* Modal Documentos Por Expirar */}
        <Modal
          visible={modalDetalleDocumentosPorExpirar}
          onCancel={() =>
            this.setState({ modalDetalleDocumentosPorExpirar: false })
          }
          className={"modal-detalle-dashboard"}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
              <h3 className="modal-h3-dashboard">
                Trabajadores por Documentos por expirar
              </h3>
            </Col>

            <Col
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              xxl={4}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                className={"modal-btn-excel-dashboard"}
                onClick={() => {
                  let { indexActual, dataDetalleDocumentosPorExpirar } = this.state;
                  let index = parseInt(indexActual) - 1;

                  const dataDescargar =
                    dataDetalleDocumentosPorExpirar[index].trabajadores !== null &&
                    dataDetalleDocumentosPorExpirar[index].trabajadores !== 0
                      ? dataDetalleDocumentosPorExpirar[index].trabajadores
                      : [];
                  this.obtenerExcelDetalleVencimiento(
                    dataDescargar,
                    dataDetalleDocumentosPorExpirar[index].type
                  );
                }}
              >
                <span className="modal-btn-text-excel-dashboard">
                  Descargar Excel
                </span>
                <span>
                  <img
                    src={Excel}
                    className="modal-btn-icon-excel-dashboard"
                    alt="Icono Excel"
                  />
                </span>
              </Button>
            </Col>
          </Row>
          <hr className="modal-division-detalle-dashboard" />
          {/* TABLE */}
          <Row>
            <Col className="modal-tabla-detalle-dashboard" span={24}>
              <Tabs
                key={indexActual}
                defaultActiveKey={indexActual}
                activeKey={indexActual}
                onTabClick={(e) => {
                  this.setState({
                    indexActual: e,
                    trabajadoresCopia: undefined,
                  });
                }}
              >
                {dataDetalleDocumentosPorExpirar.length > 0 ? (
                  <>
                    {dataDetalleDocumentosPorExpirar.map((valor, index) => (
                      <TabPane
                        tab={valor.type + ` (${valor.trabajadores.length})`}
                        key={index + 1}
                      >
                        {valor.trabajadores.length > 0 ? (
                          <>
                            <Row
                              style={{ padding: "6px 0px 22px" }}
                              align="bottom"
                            >
                              <Col
                                className="div-input-search"
                                xs={24}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                xxl={6}
                              >
                                <Search
                                  placeholder="Buscar"
                                  enterButton
                                  onChange={(value) =>
                                    this.search(value, index)
                                  }
                                />
                              </Col>
                            </Row>

                            <Table
                              key={index + 1}
                              dataSource={
                                trabajadoresCopia === undefined
                                  ? valor !== undefined
                                    ? valor.trabajadores
                                    : []
                                  : trabajadoresCopia.trabajadores
                              }
                              bordered={true}
                              showSorterTooltip={false}
                              scroll={{ x: true }}
                              pagination={{ pageSize: 10 }}
                              className={"modal-tabla-detalle-dashboard"}
                              locale={{
                                emptyText:
                                  "No se encontraron registros de la tabla.",
                              }}
                            >
                              {/* Imagen */}
                              <Column
                                align="center"
                                title="Imagen"
                                key="Imagen"
                                render={(value) => {
                                  return (
                                    <img
                                      src={value.Imagen}
                                      className="modal-img-tabla-dashboard"
                                      alt="i"
                                    />
                                  );
                                }}
                              />

                              {/* Nombre Completo */}
                              <Column
                                sorter={(a, b) => {
                                  return a.Nombre.localeCompare(b.Nombre);
                                }}
                                title="Nombre"
                                key="NombreCompletoTratado"
                                render={(value) => {
                                  return `${value.NombreCompletoTratado}`;
                                }}
                              />

                              {/* Nombre Documentos */}
                              <Column
                                sorter={(a, b) => {
                                  return a.NombreArticulo.localeCompare(
                                    b.NombreArticulo
                                  );
                                }}
                                title="Nombre Documentos"
                                key="NombreArticulo"
                                render={(value) => {
                                  return `${value.NombreArticulo}`;
                                }}
                              />

                              {/* Fecha Vencimiento */}
                              <Column
                                sorter={(a, b) => {
                                  const fechaInicioa =
                                    a.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          a.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  const fechaIniciob =
                                    b.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          b.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  return fechaIniciob - fechaInicioa;
                                }}
                                title="Fecha Vencimiento"
                                key="FechaVencimiento"
                                render={(value) => {
                                  return `${value.FechaVencimiento}`;
                                }}
                              />

                              {/* Ficha trabajador */}
                              <Column
                                title="Detalle"
                                key="FichaTrabajador"
                                align="center"
                                render={(value) => (
                                  <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                                    <Button
                                      onClick={() => {
                                        this.NuevaPestaña(value._id);
                                      }}
                                      className="acciones-trabajador-button hover-azul"
                                      icon={<EyeFilled style={{ fontSize: '18px' }} />}
                                    />
                                  </Tooltip>
                                )}
                              />

                            </Table>
                          </>
                        ) : (
                          <>
                            <Row>
                              <Col span={24}>
                                <Empty
                                  imageStyle={{
                                    height: 200,
                                    marginTop: "20px",
                                  }}
                                  description={
                                    <b
                                      className={
                                        "seleccion-trabajador-disponible"
                                      }
                                    >
                                      No se encontró información relacionada con
                                      este filtro.
                                    </b>
                                  }
                                ></Empty>
                              </Col>
                            </Row>
                          </>
                        )}
                      </TabPane>
                    ))}
                  </>
                ) : (
                  <TabPane tab={"No se encontro información"} key={1}>
                    <>
                      <Row>
                        <Col span={24}>
                          <Empty
                            imageStyle={{
                              height: 200,
                              marginTop: "20px",
                            }}
                            description={
                              <b className={"modal-empty-b-dashboard"}>
                                No se encontró información de los trabajadores.
                              </b>
                            }
                          ></Empty>
                        </Col>
                      </Row>
                    </>
                  </TabPane>
                )}
              </Tabs>
            </Col>
          </Row>
        </Modal>

         {/* Modal Documentos Expiradas */}
         <Modal
          visible={modalDetalleDocumentosExpiradas}
          onCancel={() => this.setState({ modalDetalleDocumentosExpiradas: false })}
          className={"modal-detalle-dashboard"}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
              <h3 className="modal-h3-dashboard">
                Trabajadores por Documentos Expiradas
              </h3>
            </Col>

            <Col
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              xxl={4}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                className={"modal-btn-excel-dashboard"}
                onClick={() => {
                  let { indexActual, dataDetalleDocumentosExpiradas } = this.state;
                  let index = parseInt(indexActual) - 1;

                  const dataDescargar =
                  dataDetalleDocumentosExpiradas[index].trabajadores !== null &&
                  dataDetalleDocumentosExpiradas[index].trabajadores !== 0
                      ? dataDetalleDocumentosExpiradas[index].trabajadores
                      : [];
                  this.obtenerExcelDetalleVencimiento(
                    dataDescargar,
                    dataDetalleDocumentosExpiradas[index].type
                  );
                }}
                //onClick={this.descargarExcel}
              >
                <span className="modal-btn-text-excel-dashboard">
                  Descargar Excel
                </span>
                <span>
                  <img
                    src={Excel}
                    className="modal-btn-icon-excel-dashboard"
                    alt="Icono Excel"
                  />
                </span>
              </Button>
            </Col>
          </Row>
          <hr className="modal-division-detalle-dashboard" />
          {/* TABLE */}
          <Row>
            <Col className="modal-tabla-detalle-dashboard" span={24}>
              {/* <Tabs key={1} defaultActiveKey="1"> */}
              <Tabs
                key={indexActual}
                defaultActiveKey={indexActual}
                activeKey={indexActual}
                onTabClick={(e) => {
                  this.setState({
                    indexActual: e,
                    trabajadoresCopia: undefined,
                  });
                }}
              >
                {dataDetalleDocumentosExpiradas.length > 0 ? (
                  <>
                    {dataDetalleDocumentosExpiradas.map((valor, index) => (
                      <TabPane
                        tab={valor.type + ` (${valor.trabajadores.length})`}
                        key={index + 1}
                      >
                        {valor.trabajadores.length > 0 ? (
                          <>
                            <Row
                              style={{ padding: "6px 0px 22px" }}
                              align="bottom"
                            >
                              <Col
                                className="div-input-search"
                                xs={24}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                xxl={6}
                              >
                                <Search
                                  placeholder="Buscar"
                                  enterButton
                                  onChange={(value) =>
                                    this.search(value, index)
                                  }
                                />
                              </Col>
                            </Row>

                            <Table
                              key={index + 1}
                              dataSource={
                                trabajadoresCopia === undefined
                                  ? valor !== undefined
                                    ? valor.trabajadores
                                    : []
                                  : trabajadoresCopia.trabajadores
                              }
                              bordered={true}
                              showSorterTooltip={false}
                              scroll={{ x: true }}
                              pagination={{ pageSize: 10 }}
                              className={"modal-tabla-detalle-dashboard"}
                              locale={{
                                emptyText:
                                  "No se encontraron registros de la tabla.",
                              }}
                            >
                              {/* Imagen */}
                              <Column
                                align="center"
                                title="Imagen"
                                key="Imagen"
                                render={(value) => {
                                  return (
                                    <img
                                      src={value.Imagen}
                                      className="modal-img-tabla-dashboard"
                                      alt="i"
                                    />
                                  );
                                }}
                              />

                              {/* Nombre Completo */}
                              <Column
                                sorter={(a, b) => {
                                  return a.Nombre.localeCompare(b.Nombre);
                                }}
                                title="Nombre"
                                key="NombreCompletoTratado"
                                render={(value) => {
                                  return `${value.NombreCompletoTratado}`;
                                }}
                              />

                              {/* Nombre Documento */}
                              <Column
                                sorter={(a, b) => {
                                  return a.NombreArticulo.localeCompare(
                                    b.NombreArticulo
                                  );
                                }}
                                title="Nombre Documento"
                                key="NombreArticulo"
                                render={(value) => {
                                  return `${value.NombreArticulo}`;
                                }}
                              />

                              {/* Fecha Vencimiento */}
                              <Column
                                sorter={(a, b) => {
                                  const fechaInicioa =
                                    a.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          a.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  const fechaIniciob =
                                    b.FechaVencimiento === "N/A"
                                      ? new Date("1900-01-01")
                                      : new Date(
                                          b.FechaVencimiento.split("-")
                                            .reverse()
                                            .join("-")
                                        );
                                  return fechaIniciob - fechaInicioa;
                                }}
                                title="Fecha Vencimiento"
                                key="FechaVencimiento"
                                render={(value) => {
                                  return `${value.FechaVencimiento}`;
                                }}
                              />

                              {/* Ficha trabajador */}
                              <Column
                                title="Detalle"
                                key="FichaTrabajador"
                                align="center"
                                render={(value) => (
                                  <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                                    <Button
                                      onClick={() => {
                                        this.NuevaPestaña(value._id);
                                      }}
                                      className="acciones-trabajador-button hover-azul"
                                      icon={<EyeFilled style={{ fontSize: '18px' }} />}
                                    />
                                  </Tooltip>
                                )}
                              />

                            </Table>
                          </>
                        ) : (
                          <>
                            <Row>
                              <Col span={24}>
                                <Empty
                                  imageStyle={{
                                    height: 200,
                                    marginTop: "20px",
                                  }}
                                  description={
                                    <b
                                      className={
                                        "seleccion-trabajador-disponible"
                                      }
                                    >
                                      No se encontró información relacionada con
                                      este filtro.
                                    </b>
                                  }
                                ></Empty>
                              </Col>
                            </Row>
                          </>
                        )}
                      </TabPane>
                    ))}
                  </>
                ) : (
                  <TabPane tab={"No se encontro información"} key={1}>
                    <>
                      <Row>
                        <Col span={24}>
                          <Empty
                            imageStyle={{
                              height: 200,
                              marginTop: "20px",
                            }}
                            description={
                              <b className={"modal-empty-b-dashboard"}>
                                No se encontró información de los trabajadores.
                              </b>
                            }
                          ></Empty>
                        </Col>
                      </Row>
                    </>
                  </TabPane>
                )}
              </Tabs>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default DashboardVencimiento;
