const moment_timezone = require("moment-timezone");
const moment = require("moment");

const chinaFormat = 'YYYY-MM-DD';
const chinaHoursFormat = 'YYYY-MM-DD HH:mm';

const chileFormat = 'DD-MM-YYYY';
const chileHoursFormat = 'DD-MM-YYYY HH:mm';

const databaseFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';

//retornara fecha formato dd-mm-yyyy
function obtenerFechaHoy() {
    let date = new Date()

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    const fecha = month < 10 ? `${day}-0${month}-${year}` : `${day}-${month}-${year}`;
    return fecha;
}

function obtenerFechaMomentChile(fecha_con_hora) {
    //America/Santiago
    const nuevaFechaConHora = moment_timezone(fecha_con_hora).tz('America/Santiago').format('YYYY-MM-DDTHH:mm:ss[Z]');
    return nuevaFechaConHora;
}

function obtenerFechaStringSinQuitarFecha(fecha_con_hora) {
    //America/Santiago
    const nuevaFechaConHora = moment_timezone(fecha_con_hora).utc().format('DD-MM-YYYY');
    return nuevaFechaConHora;
}

/*
funciones encargada de formatear y colocar las horas en 00:00 sin moverse del dia
*/
//La fecha debe venir como moment obligatoriamente
function momentToStringFechaInicio(fechaInicio, format) {

    if (format === undefined) {
        console.warn('Debes entregar un formato como parámetro.')
    }

    try {

        if (!moment.isMoment(fechaInicio)) {
            throw new Error('¡Debe enviarse un objeto moment!');
        } else {

            let nuevaFecha = moment_timezone(fechaInicio).tz('America/Santiago').set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
            }).format(format);

            return nuevaFecha;
        }

    } catch (err) {

        console.error(err)
        return err;
    }
}


function momentToUnixFechaInicio(fechaInicio) {

    try {

        if (!moment.isMoment(fechaInicio)) {
            throw new Error('¡Debe enviarse un objeto moment!');
        } else {

            let nuevaFecha = moment_timezone(fechaInicio).tz('America/Santiago').set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
            }).unix();

            return nuevaFecha;
        }

    } catch (err) {

        console.error(err)
        return err;
    }
}
/*
funciones encargada de formatear y colocar las horas en 23:59 sin moverse del dia
*/
//La fecha debe venir como moment obligatoriamente
function momentToStringFechaFin(fechaFin, format) {

    if (format === undefined) {
        console.warn('Debes entregar un formato como parámetro.')
    }

    try {

        if (!moment.isMoment(fechaFin)) {
            throw new Error('¡Debe enviarse un objeto moment!');
        } else {

            let nuevaFecha = moment_timezone(fechaFin).tz('America/Santiago').set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 59,
            }).format(format);

            return nuevaFecha;
        }

    } catch (err) {

        console.error(err)
        return err;
    }
}

function momentToUnixFecha(fechaFin) {
    try {

        if (!moment.isMoment(fechaFin)) {
            throw new Error('¡Debe enviarse un objeto moment!');
        } else {

            let nuevaFecha = moment_timezone(fechaFin).tz('America/Santiago').unix();

            return nuevaFecha;
        }

    } catch (err) {

        console.error(err)
        return err;
    }
}

function momentToUnixFechaFin(fechaFin) {

    try {

        if (!moment.isMoment(fechaFin)) {
            throw new Error('¡Debe enviarse un objeto moment!');
        } else {

            let nuevaFecha = moment_timezone(fechaFin).tz('America/Santiago').set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 59,
            }).unix();

            return nuevaFecha;
        }

    } catch (err) {

        console.error(err)
        return err;
    }
}

/*
funciones encargada de formatear y colocar las horas con la misma hora ingresada sin moverse el dia
*/
//La fecha debe venir como moment obligatoriamente
function obtenerFechaFormateadaMismaHora(fecha, format) {
    if (format === undefined) {
        console.warn('Debes entregar un formato como parámetro.')
    }

    try {

        if (!moment.isMoment(fecha)) {
            throw new Error('¡Debe enviarse un objeto moment!');
        } else {

            let nuevaFecha = moment_timezone(fecha).tz('America/Santiago').format(format);

            return nuevaFecha;
        }

    } catch (err) {

        console.error(err)
        return err;
    }
}

//funciones encargada de mostrar la fecha con un formato correcto al formatearla
//la fecha siempre debe venir tipo moment
function momentToString(fecha, tipoFormateo) {
    switch (tipoFormateo) {
        case "DD-MM-YYYY HH:MM":
            let dia = fecha.format('D');
            console.log(dia);
            return `25/11/2021`;
        case "DD-MM-YYYY":
            return `25/11/2021`;
        default:
            return "FORMATO NO ENCOTRADO"
    }
}

function dateOrMomentToMomentTimeZoneChile(fecha) {

    if (moment.isMoment(fecha)) {
        fecha = moment_timezone(fecha).tz('America/Santiago').add(4, 'hours');
    } else {
        fecha = moment_timezone(fecha).tz('America/Santiago').add(4, 'hours');
    }

    return fecha;
}

//FECHA: FORMATO MOMENT
//TIPO: SI ES FECHA DE INICIO (1) O FIN (2)
//ESTA FUNCIÓN DEBE RECIBIR UNA MOMENT CASTEADO COMO UTC
function convertirFechaString(fecha, tipo) {
    const zonaHoraria = (fecha.clone().utcOffset());
    const esNegativo = zonaHoraria < 0 ? true : false;

    let fechas = {};

    if (tipo === 1) {
        //FUNCIONA EN CASO DE SER ZONA HORARIA NEGATIVA Y POSITIVA
        fechas.fechaString = esNegativo ? fecha.clone().add(zonaHoraria * -1, 'minutes').startOf("day").format('YYYY-MM-DDTHH:mm:ss[Z]') : fecha.clone().startOf("day").format('YYYY-MM-DDTHH:mm:ss[Z]');
        fechas.fechaUNIX = esNegativo ? fecha.clone().startOf("day").add(zonaHoraria, 'minutes').add(4, 'hours').unix() : fecha.clone().startOf("day").add(zonaHoraria, 'minutes').add(4, 'hours').unix();

        //ENVIAR FECHA EN UTC
        fechas.fechaDDMMYYYY = esNegativo ? fecha.clone().add(zonaHoraria, 'minutes').startOf("day").format('DD-MM-YYYY') : fecha.clone().subtract(zonaHoraria, 'minutes').startOf("day").format('DD-MM-YYYY');

    } else {
        //FUNCIONA EN CASO DE SER ZONA HORARIA NEGATIVA Y POSITIVA
        fechas.fechaString = esNegativo ? fecha.clone().add(zonaHoraria * -1, 'minutes').endOf("day").format('YYYY-MM-DDTHH:mm:ss[Z]') : fecha.clone().endOf("day").format('YYYY-MM-DDTHH:mm:ss[Z]');
        fechas.fechaUNIX = esNegativo ? fecha.clone().endOf("day").add(zonaHoraria, 'minutes').add(4, 'hours').unix() : fecha.clone().endOf("day").add(zonaHoraria, 'minutes').add(4, 'hours').unix();

        //ENVIAR FECHA EN UTC
        fechas.fechaDDMMYYYY = esNegativo ? fecha.clone().add(zonaHoraria, 'minutes').endOf("day").format('DD-MM-YYYY') : fecha.clone().subtract(zonaHoraria, 'minutes').endOf("day").format('DD-MM-YYYY');
    }
    return fechas;
}


//SE CAMBIO LOGICA DEBIDO QUE LA FUNCION ANTERIOR ESTABA OCASIONANDO PROBLEMAS CON LA ZONA HORARIA
function convertirFechaStringNueva(fecha, tipo) {

    let fechas = {};
    let m1 = moment(fecha);
    let offsetInMinutes = m1.utcOffset();
    m1.utc().add(offsetInMinutes, 'm');
    if (tipo === 1) {
        //FUNCIONA EN CASO DE SER ZONA HORARIA NEGATIVA Y POSITIVA
        fechas.fechaString = m1.clone().startOf("day").format('YYYY-MM-DDTHH:mm:ss[Z]')
        fechas.fechaUNIX = m1.clone().startOf("day").unix()
        //ENVIAR FECHA EN UTC
        fechas.fechaDDMMYYYY = m1.clone().startOf("day").format('DD-MM-YYYY')
    } else {
        fechas.fechaString =  m1.clone().endOf("day").format('YYYY-MM-DDTHH:mm:ss[Z]')
        fechas.fechaUNIX = m1.clone().endOf("day").unix()
        fechas.fechaDDMMYYYY = m1.clone().endOf("day").format('DD-MM-YYYY')

    }
    return fechas;
}
//FECHA: FORMATO MOMENT
//TIPO: SI ES FECHA DE INICIO (1) O FIN (2)
//ESTA FUNCIÓN NO PUEDE RECIBIR UN MOMENT CASTEADO COMO UTC
function corregirZonaHoraria(fecha, tipo) {
    const zonaHoraria = (fecha.clone().utcOffset());
    const esNegativo = zonaHoraria < 0 ? true : false;
    let fechaMoment;
    if (tipo === 1) {
        fechaMoment = esNegativo ? fecha.clone().add(zonaHoraria * -1, 'minutes') : fecha.clone().subtract(zonaHoraria, 'minutes');
        const zonaHorariaNueva = (fechaMoment.clone().utcOffset());
        if (zonaHoraria !== zonaHorariaNueva) {
            const newEsNegativo = zonaHorariaNueva < 0 ? true : false;
            let zona1 = (esNegativo ? (zonaHoraria * -1) : zonaHoraria);
            let zona2 = (newEsNegativo ? (zonaHorariaNueva * -1) : zonaHorariaNueva);
            let diferencia = Math.abs(zona1 - zona2);
            fechaMoment = newEsNegativo ? fechaMoment.clone().add(diferencia, 'minutes') : fechaMoment.clone().subtract(diferencia, 'minutes');
        }

        fechaMoment = fechaMoment.clone().startOf("day");
    } else {
        fechaMoment = esNegativo ? fecha.clone().add(zonaHoraria * -1, 'minutes') : fecha.clone().subtract(zonaHoraria, 'minutes');
        const zonaHorariaNueva = (fechaMoment.clone().utcOffset());
        if (zonaHoraria !== zonaHorariaNueva) {
            const newEsNegativo = zonaHorariaNueva < 0 ? true : false;
            let zona1 = (esNegativo ? (zonaHoraria * -1) : zonaHoraria);
            let zona2 = (newEsNegativo ? (zonaHorariaNueva * -1) : zonaHorariaNueva);
            let diferencia = Math.abs(zona1 - zona2);
            fechaMoment = newEsNegativo ? fechaMoment.clone().add(diferencia, 'minutes') : fecha.clone().subtract(diferencia, 'minutes');
        }
        fechaMoment = fechaMoment.clone().endOf("day");
    }
    return fechaMoment;
}

// function ajustarZonaHoraria(fecha){
//     const fechaMoment = moment.utc(fecha)
//     const zonaHoraria = (fechaMoment.clone().utcOffset());
//     const esNegativo = zonaHoraria < 0 ? true : false;
//     const zonaHorariaHour = zonaHoraria < 0 ? (zonaHoraria / -60) : (zonaHoraria / 60); 
//     let fechaAjustada = esNegativo ? fechaMoment.clone().add(zonaHorariaHour, 'hours') : fechaMoment.clone().subtract(zonaHorariaHour, 'hours');
//     return fechaAjustada;
// }

//funcion encargada de obtener una fecha para parsear a moment sin perder horas o dias
function dateToMommetFecha(fecha) {
    let fechaMoment = moment_timezone(fecha).tz('America/Santiago');
}

function returnTimeZoneAdd(momentDateTimeZone) {
    //zona horaria usuario
    let zonaHoraria = 0;
    // if(momentDateTimeZone.isDST() === true){
    //     zonaHoraria = zonaHoraria+60;
    // }
    zonaHoraria = (momentDateTimeZone.clone().utcOffset()) * -1;
    return zonaHoraria;
}

module.exports = {
    obtenerFechaHoy,
    obtenerFechaMomentChile,
    obtenerFechaStringSinQuitarFecha,

    //funciones encargadas de setiar horas en los extremos y 
    //parsearlas a string

    momentToString,
    momentToStringFechaInicio,
    momentToStringFechaFin,
    dateOrMomentToMomentTimeZoneChile,
    //pasar funciones a unix
    momentToUnixFechaInicio,
    momentToUnixFechaFin,
    momentToUnixFecha,


    dateToMommetFecha,

    obtenerFechaFormateadaMismaHora,
    chinaFormat,
    chinaHoursFormat,
    chileFormat,
    chileHoursFormat,
    databaseFormat,

    returnTimeZoneAdd,

    convertirFechaStringNueva,
    convertirFechaString,
    // convertirFechaUnixAMoment,
    // ajustarZonaHoraria,
    corregirZonaHoraria
}

