import React, { Component } from "react";
import { Col, Popover, Row, Button, Card,DatePicker,Steps,Table,Space,Tooltip } from "antd";
import { UsergroupAddOutlined,PrinterOutlined ,DeleteFilled, CommentOutlined} from "@ant-design/icons";

// import TrabajoEfectivo from "../../img/dashboard/bubble/TrabajoEfectivo.svg";
// import Descanso from "../../img/dashboard/bubble/Descanso.svg";
// import EnTransito from "../../img/dashboard/bubble/EnTransito.svg";
// import Vacaciones from "../../img/dashboard/bubble/Vacaciones.svg";
// import Licencia from "../../img/dashboard/bubble/Licencia.svg";
// import LicenciaMutual from "../../img/dashboard/bubble/LicenciaMutual.svg";
// import Permiso from "../../img/dashboard/bubble/Permiso.svg";
// import AusenteLeyenda from "../../img/dashboard/bubble/Ausente.svg";
// import NoAsignado from "../../img/dashboard/bubble/NoAsignado.svg";

import TrabajoEfectivo from "../../img/icons-nuevos/TrabajoEfectivo.svg";
import Descanso from "../../img/icons-nuevos/Descanso.svg";
import EnTransito from "../../img/icons-nuevos/EnTransito.svg";
import Vacaciones from "../../img/icons-nuevos/Vacaciones.svg";
import Licencia from "../../img/icons-nuevos/Licencia.svg";
import LicenciaMutual from "../../img/icons-nuevos/LicenciaMutualidad.svg";
import Permiso from "../../img/icons-nuevos/Permiso.svg";
import AusenteLeyenda from "../../img/icons-nuevos/Ausente.svg";
import NoAsignado from "../../img/icons-nuevos/NoAsignado.svg";

import { convertirFechaString } from "../../funcionesGenericas/fechas";
import "../../../node_modules/react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
//import Swal from "sweetalert2";
import "./Timeline.css";
import "./Timeline3.css";

const { RangePicker } = DatePicker;
const { Step } = Steps;
const { Column } = Table;
const customDot = (dot, { status, index }) => (
  <Popover
    content={  
      <span>
        Estado: {<>{status}</>}
        {/* {this.props.bitacora.map((bit)=>(<>{bit.Fecha}</>))} */}
      </span>
    }
  >
    {dot}
  </Popover>
);
///
class TimeLineTrabajador extends Component {
    constructor(props) {
        super(props);
    }

   
  render() {
    const { bitacora, selectedDatesTimeline, firstDaysOfTheMonths } = this.props;
    let { mostrarCalendario } = this.props;
    let fechaBusquedaIni,fechaBusquedaFin = null; 

    if(selectedDatesTimeline !== undefined && selectedDatesTimeline[0] !== undefined && selectedDatesTimeline[0]!==null){
      fechaBusquedaIni=moment.utc(selectedDatesTimeline[0])
    }
    if(selectedDatesTimeline !== undefined && selectedDatesTimeline[1] !== undefined && selectedDatesTimeline[1]!==null){
      fechaBusquedaFin=moment.utc(selectedDatesTimeline[1])
    }

    if(bitacora && bitacora.length>0 ){
      mostrarCalendario=true;
    }else{
      mostrarCalendario=false;
    }

    return (
      <>
      
        <Row style={{marginBottom:'10px'}}>
                {/* DATEPICKER */}
                <Col span={12} xs={24} s={24} sm={24} md={12} lg={12} xl={12}>
                  <RangePicker
                    value={[fechaBusquedaIni,fechaBusquedaFin]}
                    onChange={(fechas) => {
                      let inicio = convertirFechaString(moment.utc(fechas[0]),1).fechaString;
                      let fin = convertirFechaString(moment.utc(fechas[1]),2).fechaString;
                      this.props.actualizarFechaTimeline(inicio, fin);
                    }}
                    className={"selectRangepickerT3"}
                    locale={locale}
                    format={["DD-MM-YYYY"]}
                    disabled={bitacora? mostrarCalendario===false : mostrarCalendario===true}
                    disabledDate={(dia) => {
                      let validacion = false;

                      if(this.props.selectedContrato === null || this.props.selectedContrato === undefined){
                        return true;
                      }
                      
                      let fechaInicio = this.props.selectedContrato[0];
                      let fechaFin = this.props.selectedContrato[1];

                      if(fechaFin !== null && fechaFin !==undefined ){
                        if (!dia.isBetween(moment.utc(fechaInicio), moment.utc(fechaFin), null, '[]')) {
                          validacion = true;
                        }
                
                      }
                      else{
                        if(dia.isBefore(moment.utc(fechaInicio))){
                          validacion = true;
                        }
                      }
             
                      if (validacion === true) {
                        return true;
                      }
                    }}
                    allowClear={false}
                   
                  />
                </Col>
                {/* BTN FILTRAR */}
                <Col 
                  span={11} offset={1} 
                  xs={{ span: 24, offset: 0}} 
                  s={{ span: 24, offset: 0}} 
                  sm={{ span: 24, offset: 0}} 
                  md={{ span: 11, offset: 1}} 
                  lg={{ span: 11, offset: 1}} 
                  xl={{ span: 11, offset: 1}}
                >
                  <Button
                    disabled={bitacora? mostrarCalendario===false : mostrarCalendario===true}
                    className={bitacora ? "btnFiltrarT3" : "btnFiltrarBloqT3"}        
                    onClick={() => {
                      let inicio = selectedDatesTimeline[0];
                      let fin = selectedDatesTimeline[1];
                      this.props.cambiarFechas(inicio,fin);
                    }}
                  >
                    Filtrar
                  </Button>
                </Col>
              </Row>

                <Card className={"cardLeyendaT3"}>
                  <Row>
                    <Col className={"titleLenyendaT3"}>Leyenda</Col>
                    <Row>
                      <Col span={24}><img src={TrabajoEfectivo} className={"imgLeyendasAllT3"}></img></Col>
                      <Col span={24} className={"titleImgLenyendaT3"}>Trabajo efectivo </Col>
                    </Row>
                    <Row>
                      <Col span={24}><img src={Descanso} className={"imgLeyendasAllT3"}></img></Col>   
                      <Col span={24} className={"titleImgLenyendaT3"}>Descanso </Col>
                    </Row>
                    <Row>
                      <Col span={24}><img src={Vacaciones} className={"imgLeyendasAllT3"}></img></Col>
                      <Col span={24} className={"titleImgLenyendaT3"}>Vacaciones </Col>
                    </Row>
                    <Row>
                      <Col span={24}><img src={EnTransito} className={"imgLeyendasAllT3"}></img></Col>
                      <Col span={24} className={"titleImgLenyendaT3"}>En tránsito </Col>
                    </Row>
                    <Row>
                      <Col span={24}><img src={Licencia} className={"imgLeyendasAllT3"}></img></Col>
                      <Col span={24} className={"titleImgLenyendaT3"}>Licencia </Col>
                    </Row>
                    <Row>
                      <Col span={24}><img src={LicenciaMutual} className={"imgLeyendasAllT3"}></img></Col>
                      <Col span={24} className={"titleImgLenyendaT3"}>Licencia mutualidad </Col>
                    </Row>
                    <Row>
                      <Col span={24}><img src={Permiso} className={"imgLeyendasAllT3"}></img></Col>
                      <Col span={24} className={"titleImgLenyendaT3"}>Permiso </Col>
                    </Row>
                    <Row>
                      <Col span={24}><img src={AusenteLeyenda} className={"imgLeyendasAllT3"}></img></Col>
                      <Col span={24} className={"titleImgLenyendaT3"}>Ausente </Col>
                    </Row>

                    <Row>
                      <Col span={24}><img src={NoAsignado} className={"imgLeyendasAllT3"}></img></Col>
                      <Col span={24} className={"titleImgLenyendaT3"}>No asignado </Col>
                    </Row>

                    {/* <Row>
                      <Col span={24}><Col className={"imgLeyendasVacioT3"}></Col></Col>
                      <Col span={24} className={"titleImgLenyendaT3"}>Vacio </Col> 
                    </Row> */}
                    {/* /// */}
                  </Row>

                </Card> 

                <Col style={{ textAlign: 'left',marginTop:'10px' }}>
                  <p style={{ fontFamily: 'MavenPro-Regular', marginBottom: '5px' }}>
                    <p style={{fontFamily: 'MavenPro-Bold'}}>Actualmente mostrando reporte de:
                      <b style={{fontFamily: 'MavenPro-Regular'}}>
                        {
                          mostrarCalendario
                          ?
                            `${moment.utc(this.props.selectedDatesTimeline[0]).format('DD-MM-YYYY')} hasta ${moment.utc(this.props.selectedDatesTimeline[1]).format('DD-MM-YYYY')}`
                          : 
                          " "
                        }
                      </b>
                    </p>
                  </p>
                </Col>

                <Card className={"cardCalendarioT3"}>

                  <Row>
                    <Col span={5}>
                        <h1 style={{color:'#061FCB',fontFamily:'MavenPro-Bold'}}>Calendario del trabajador:</h1>
                        <h1 style={{fontFamily: 'MavenPro-Regular',color:'#000000'}}>
                            {/* Jorgiño Almonaciño / Capitán */}
                        </h1>
                    </Col>

                    <Col span={13} offset={6}>
                      <Row>
                        <Row span={24}>
                          <Col className={"colTitleRecuentoT3"}>Recuento de días filtrados:</Col>
                        </Row>
                        <Row>
                          <Col span={24}><img src={TrabajoEfectivo} className={"imgRecLeyendasAllT3"}></img></Col>
                          <Col span={24} className={"colCantDiasT3"}>{ this.props.contEstados ? (this.props.contEstados.TrabajoEfectivo ? this.props.contEstados.TrabajoEfectivo: 0) : 0 }</Col>
                        </Row>

                        <Row>
                          <Col span={24}><img src={Descanso} className={"imgRecLeyendasAllT3"}></img></Col>   
                          <Col span={24} className={"colCantDiasT3"}>{ this.props.contEstados ? (this.props.contEstados.Descanso) : 0 }</Col>
                        </Row>

                        <Row>
                          <Col span={24}><img src={Vacaciones} className={"imgRecLeyendasAllT3"}></img></Col>
                          <Col span={24} className={"colCantDiasT3"}>{ this.props.contEstados ? (this.props.contEstados.Vacaciones) : 0 }</Col>
                        </Row>

                        <Row>
                          <Col span={24}><img src={EnTransito} className={"imgRecLeyendasAllT3"}></img></Col>
                          <Col span={24} className={"colCantDiasT3"}>{ this.props.contEstados ? (this.props.contEstados.EnTransito) : 0 }</Col>
                        </Row>

                        <Row>
                          <Col span={24}><img src={Licencia} className={"imgRecLeyendasAllT3"}></img></Col>
                          <Col span={24} className={"colCantDiasT3"}>{ this.props.contEstados ? (this.props.contEstados.Licencia) : 0 }</Col>
                        </Row>
                          
                        <Row>
                          <Col span={24}><img src={LicenciaMutual} className={"imgRecLeyendasAllT3"}></img></Col>
                          <Col span={24} className={"colCantDiasT3"}>{ this.props.contEstados ? (this.props.contEstados.LicenciaMutualidad) : 0 }</Col>
                        </Row>

                        <Row>
                          <Col span={24}><img src={Permiso} className={"imgRecLeyendasAllT3"}></img></Col>
                          <Col span={24} className={"colCantDiasT3"}>{ this.props.contEstados ? (this.props.contEstados.Permiso) : 0 }</Col>
                        </Row>

                        <Row>
                          <Col span={24}><img src={AusenteLeyenda} className={"imgRecLeyendasAllT3"}></img></Col>
                          <Col span={24} className={"colCantDiasT3"}>{ this.props.contEstados ? (this.props.contEstados.Ausente) : 0 }</Col>
                        </Row>
                        {/* //contador vacio toma valores bit(NoAsignado) */}
                        <Row>
                          <Col span={24}><img src={NoAsignado} className={"imgRecLeyendasAllT3"}></img></Col>
                          <Col span={24} className={"colCantDiasT3"}>{ this.props.contEstados ? (this.props.contEstados.Vacio) : 0 }</Col>
                        </Row>

                        {/* <Row> */}
                          {/* <Col span={24}>
                            <Col className={"colCircleLeyendaT3"}></Col>
                          </Col> */}
                          {/* //vacio */}
                          {/* <Col span={24} className={"colCantDiasT3"}>0</Col>  */}
                        {/* </Row> */}
                      </Row>
                      
                    </Col>
                  </Row>

                  {
                    mostrarCalendario?
                    <>
                      <Card className={"cardCalendarioBitT3"}>
                        <div
                          style={{
                            width: "100%",
                            height: "100px",
                            overflowX: "scroll",
                          }}
                          className="contenedor-step-asig-act"
                        >

                          <Steps
                            current={0}
                            progressDot={customDot}
                            className="ant-icon-tamaño testContainer testTail step-fecha-inicio-fin-mes"
                            direction={"horizontal"}
                            labelPlacement={"horizontal"}
                            responsive={false}
                            style={{marginBottom: "0.5rem"}}
                          >
                            {
                              bitacora.length > 0 && bitacora.map((bit)=>
                              <>
                                <Step 
                                  key={bit._id} 
                                  title={firstDaysOfTheMonths.filter((fecha) => 
                                    moment.utc(fecha).isSame(moment.utc(convertirFechaString(moment.utc(bit.Fecha), 1).fechaString))).length > 0 && moment.utc(bit.Fecha).format('MMMM').substring(0, 3).toUpperCase()
                                  } 
                                  className={
                                    bit.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261" && "dot-green-timeline"  
                                  }
                                />

                              </>)
                            }
                          </Steps>
                  
                          <Steps 
                            current={0}
                            progressDot={customDot}
                            className="ant-icon-tamaño testContainer testTail"
                          >
                            
                            {bitacora
                              // bitacora.length > 0
                            ?
                            (
                            <>
                              {
                                bitacora.map((bit)=>(
                                <>
                                <Step 
                                  key={bit._id}
                                  // 5f3eedf56dd7cc57f0d3a261 = Trabajo Efectivo
                                  // 5f3eeb78978b284b68ebced4 = Descanso,
                                  // 5f8f369e8efe155a48df37fb = En Transito,
                                  // 5f3eebac978b284b68ebced5 = Vacaciones,
                                  // 5f3eebf46dd7cc57f0d3a25d = Licencia,
                                  // 5f3eec676dd7cc57f0d3a25e = Licencia Mutual,
                                  // 5f3eec876dd7cc57f0d3a25f = Permiso,
                                  // 5f3eeca16dd7cc57f0d3a260 = Ausente,
                                  //size="small"
                                  status={
                                  bit.TipoRegistroBitacoraTrabajador=== "5f3eedf56dd7cc57f0d3a261"? "Trabajo Efectivo":
                                  bit.TipoRegistroBitacoraTrabajador=== "5f3eeb78978b284b68ebced4"? "Descanso":
                                  bit.TipoRegistroBitacoraTrabajador=== "5f8f369e8efe155a48df37fb"? "En tránsito":
                                  bit.TipoRegistroBitacoraTrabajador=== "5f3eebac978b284b68ebced5"? "Vacaciones":
                                  bit.TipoRegistroBitacoraTrabajador=== "5f3eebf46dd7cc57f0d3a25d"? "Licencia":
                                  bit.TipoRegistroBitacoraTrabajador=== "5f3eec676dd7cc57f0d3a25e"? "Licencia mutualidad":
                                  bit.TipoRegistroBitacoraTrabajador=== "5f3eec876dd7cc57f0d3a25f"? "Permiso":
                                  bit.TipoRegistroBitacoraTrabajador=== "5f3eeca16dd7cc57f0d3a260"? "Ausente":
                                  "No Asignado"} 
                                  title={moment.utc(bit.Fecha).format('DD')} 
                                  className={
                                    bit.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261"? "dot-green-timeline" :
                                    bit.TipoRegistroBitacoraTrabajador === "5f3eeb78978b284b68ebced4"? "dot-pink-timeline"  :
                                    bit.TipoRegistroBitacoraTrabajador === "5f8f369e8efe155a48df37fb"? "dot-lightpurple-timeline":
                                    bit.TipoRegistroBitacoraTrabajador === "5f3eebac978b284b68ebced5"? "dot-cyan-timeline"  :
                                    bit.TipoRegistroBitacoraTrabajador === "5f3eebf46dd7cc57f0d3a25d"? "dot-electric-blue-timeline":
                                    bit.TipoRegistroBitacoraTrabajador === "5f3eec676dd7cc57f0d3a25e"? "dot-yellow-timeline":
                                    bit.TipoRegistroBitacoraTrabajador === "5f3eec876dd7cc57f0d3a25f"? "dot-orange-timeline":
                                    bit.TipoRegistroBitacoraTrabajador === "5f3eeca16dd7cc57f0d3a260"? "dot-violet-timeline":
                                    // bit.TipoRegistroBitacoraTrabajador === "5f904101d9c57145a09eb058"? "dot-red-timeline"   :
                                    "dot-red-timeline"
                                  }

                                  // className={bit.TipoRegistroBitacoraTrabajador=== "5f3eedf56dd7cc57f0d3a261"? "dot-green" :bit.TipoRegistroBitacoraTrabajador=== "5f3eeb78978b284b68ebced4"? "dot-cyan" :bit.TipoRegistroBitacoraTrabajador=== "5f3eebac978b284b68ebced5"? "dot-cyan" :bit.TipoRegistroBitacoraTrabajador=== "5f8f369e8efe155a48df37fb"? "dot-violet":bit.TipoRegistroBitacoraTrabajador=== "5f3eebf46dd7cc57f0d3a25d"?"dot-orange":bit.TipoRegistroBitacoraTrabajador=== "5f3eec676dd7cc57f0d3a25e"?"dot-orange":bit.TipoRegistroBitacoraTrabajador=== "5f3eec876dd7cc57f0d3a25f"?"dot-orange":bit.TipoRegistroBitacoraTrabajador=== "5f3eeca16dd7cc57f0d3a260"?"dot-orange" : bit.TipoRegistroBitacoraTrabajador=== "No Contratado"? "dot-grey"  : "dot-red"}  
                                  />
                                </>))
                              }
                            </>
                            )
                            :
                            <>
                              {
                                <h1>No hay nadie seleccionado</h1>
                              }
                            </>
                            
                          }
                          </Steps>
                        </div>
                      </Card>
                    </>
                    :
                    
                      <>
                        <Col className={"colMsjSelecionarT3"}>
                          <UsergroupAddOutlined style={{color:'#061FCB'}}/>
                          Debe seleccionar un trabajador y su contrato.
                        </Col>
                      </>
                  }
                </Card>

                {/* historico */}
                <Card className={"cardHistorialT3"}>
                  {/* Historial de licencias médicas */}
                  <Row>
                      <Col span={24} className={"colTitleHistorialT3"}>Historial de {this.props.TituloHistorial? this.props.TituloHistorial:"No hay"} </Col>
                  </Row>
                  <Card className={"cardArrayHistorialT3"}>
                 
                    <Table
                      rowKey="_id"
                      dataSource={this.props.ArrayHistorial}
                      // bordered={true}
                      showSorterTooltip={false}
                      scroll={{ x: true }}
                    >
                      <Column
                        sorter={(a, b) => {
                          return a.Fecha_Inicio.localeCompare(b.Fecha_Inicio);
                        }}
                        render={(value) => {
                          return value;
                        }}
                        title="Fecha inicio"
                        dataIndex="Fecha_Inicio"
                        key="Fecha_Inicio"
                        align="left"
                        ellipsis={true}
                      />
                      <Column
                        sorter={(a, b) => {
                          return a.Fecha_Fin.localeCompare(b.Fecha_Fin);
                        }}
                        render={(value) => {
                          return value;
                        }}
                        title="Fecha fin"
                        dataIndex="Fecha_Fin"
                        key="Fecha_Fin"
                        align="left"
                        ellipsis={true}
                      />
                      <Column
                        title="Cantidad de días"
                        dataIndex="CantidadDias"
                        key="CantidadDias"
                        align="left"
                        ellipsis={true}
                      />
                      {/* Nueva columna de Observacion */}
                      <Column
                        title="Observacion"
                        dataIndex="Observacion"
                        key="Observacion"
                        align="center"
                        render={(value) => (
                          
                          <Tooltip title={value} color={'geekblue'}>
                            <Button
                              disabled={value === undefined ? true: false}
                              style={{border: '1px', borderRadius:'12px', height:'34px',width:'fit-content'}}
                              icon={<CommentOutlined style={{ fontSize: '18px'}} />}
                            />
                          </Tooltip>
                          
                        )}
                      />

                      <Column
                        title="Acciones"
                        key="Action"
                        align="center"
                        render={(value) => (
                          <Space size="middle">
                            {
                              value.Imprimir === true?
                              <Button
                              className="btnActionT3 hover-celeste"
                              onClick={() => {
                                this.props.descargarRegistro(value.IdImprimir);
                              }}
                              icon={<PrinterOutlined style={{ fontSize: '18px' }} />}
                              disabled={value.Imprimir === true? (this.props.Ver === true? false: true) :true}
                              // 
                            />
                            :
                             "" 
                            }
                          
                            <Button
                              className="btnActionT3 hover-rojo"
                              onClick={() => {
                                this.props.eliminarRegistro(value);
                              }}
                              icon={<DeleteFilled style={{ fontSize: '18px' }} />}
                              disabled={!this.props.Eliminar}
                            />
                          </Space>
                        )}
                      />
                    </Table>
                  </Card>
                </Card>

      </>
    )
  }
}

export default TimeLineTrabajador;
