import React from 'react';
import './Loading.css';
import imagen from "../../img/logos/pm.gif";

class Loading extends React.Component{

    Cargando = () =>{
        if(this.props.cargando){
            return(
                <div className="cCargando" >
                    <div className="cCargandoImg">
                        <img src={imagen} alt="gif pantalla de carga" className="GifPantallaCarga"  />
                    </div>
                </div>
            )
        }else{
            return(
                <div className="cCargando oculto">
                    <div className="cCargandoImg">
                        <img src={imagen} alt="gif pantalla de carga" className="GifPantallaCarga"  />
                    </div>
                </div>
            )
        }
    }

    render(){
        return(
            <div>
                {this.Cargando()}
            </div>
        )
    }

}

export default Loading;