export const LOADING = "MANTENEDOR_EMPRESA_LOADING";
export const PARAR_LOADING = "MANTENEDOR_EMPRESA_LOADING_PARAR";
export const ERROR = "MANTENEDOR_EMPRESA_ERROR";
export const LIMPIAR_ERROR_SUCCESS = "MANTENEDOR_EMPRESA_LIMPIAR_ERROR_SUCCESS";
export const MOSTRAR_MODAL_EMPRESA = "MANTENEDOR_EMPRESA_MOSTRAR_MODAL";
export const OCULTAR_MODAL_EMPRESA = "MANTENEDOR_EMPRESA_OCULTAR_MODAL";
export const OCULTAR_MODAL_EMPRESA_EDITAR =
  "MANTENEDOR_EMPRESA_OCULTAR_MODAL_EDITAR";
export const TOMAR_VALORES_EDITAR = "MANTENEDOR_EMPRESA_TOMAR_VALORES_EDITAR";
export const OBTENER_REGIONES = "MANTENEDOR_EMPRESA_ROLES";
export const OBTENER_CIUDADES = "MANTENEDOR_EMPRESA_ROLES";
export const OBTENER_COMUNAS = "MANTENEDOR_EMPRESA_ROLES";
export const OBTENER_TIPOEMPRESA = "MANTENEDOR_EMPRESA_ROLES";
export const INGRESAR_EMPRESA = "MANTENEDOR_EMPRESA_INGRESAR";
export const OBTENER_EMPRESAS_MANTENEDOR =
  "MANTENEDOR_EMPRESAS_OBTENER_EMPRESAS";
export const EDITAR_EMPRESA_MANTENEDOR = "MANTENEDOR_EMPRESA_EDITAR_EMPRESA";
export const OBTENER_EMPRESA_MANTENEDOR = "MANTENEDOR_EMPRESAS_OBTENER_EMPRESA";
export const ELIMINAR_EMPRESA_MANTENEDOR =
  "MANTENEDOR_EMPRESAS_ELIMINAR_EMPRESA";
export const OBTENER_CODIGOS_ACTIVIDAD_ECONOMICA_MANTENEDOR_EMPRESA = "OBTENER_CODIGOS_ACTIVIDAD_ECONOMICA_MANTENEDOR_EMPRESA";
export const OBTENER_NACIONALIDADES_MANTENEDOR_EMPRESA = "OBTENER_NACIONALIDADES_MANTENEDOR_EMPRESA";
export const OBTENER_REGIONES_MANTENEDOR_EMPRESA = "OBTENER_REGIONES_MANTENEDOR_EMPRESA";
export const OBTENER_PROVINCIAS_MANTENEDOR_EMPRESA = "OBTENER_PROVINCIAS_MANTENEDOR_EMPRESA";
export const OBTENER_COMUNAS_MANTENEDOR_EMPRESA = "OBTENER_COMUNAS_MANTENEDOR_EMPRESA";

export const INSERTAR_PROVINCIAS_MANTENEDOR_EMPRESA = "INSERTAR_PROVINCIAS_MANTENEDOR_EMPRESA";
export const INSERTAR_COMUNAS_MANTENEDOR_EMPRESA = "INSERTAR_COMUNAS_MANTENEDOR_EMPRESA";