import React, { useState, useEffect } from "react";
import ImgSol from "../../img/icons/Sol.svg";
import ImgVacaciones from "../../img/icons/vacacionesRosa.svg";
import ImgHistorico from "../../img/icons/Historico.svg";
import flecha from "../../img/icons/flecha.svg";

import moment from "moment";
import {
    convertirFechaString,
} from '../../funcionesGenericas/fechas';
import "./FormularioRegistroVacaciones.css"

import {
    Form,
    Button,
    Collapse,
    DatePicker,
    Table,
    Row,
    Col,
    PageHeader,
    Space,
    Radio,
    InputNumber,
    Empty,
    Select,
    Tooltip,

} from "antd";

// import locale from "antd/es/date-picker/locale/es_ES";
import Swal from "sweetalert2";
import API from "../../Redux/Api/api"
import { EditFilled, DeleteFilled } from "@ant-design/icons";
// import { activarCargando } from "../../Redux/actions/CalendarioTrabajadorActions";
import { Link } from "react-router-dom";

import {

} from '../../funcionesGenericas/fechas';

const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Column } = Table;

const FormularioSubirDocumentos = (props) => {

    //constante para manejar el formulario
    const [form] = Form.useForm();
    const [registroEditar, setRegistroEditar] = useState(null);
    const [opcionesPeriodo, setOpcionesPeriodo] = useState([]);
    const [selectedPeriodo, setSelectedPeriodo] = useState(null);
    const [selectedPeriodoTipoDia, setSelectedPeriodoTipoDia] = useState(null);
    const [periodosYaIngresados, setPeriodosYaIngresados] = useState([]);

    const [totalArray, setTotalArray] = useState([]);

    const { Nombre, Apellido_Paterno, Apellido_Materno, Empresa, _id } = props.trabajador;

    // let fechaInicioSistema = moment.unix(props.fechaInicioSistema);
    let fechaInicioSistema = moment(props.fechaInicioSistema);

    // const fechaContrato = moment.unix(props.trabajador.Contrato_Fecha_Inicio_Unix);
    let fechaContrato = moment(props.trabajador.Contrato_Fecha_Inicio);

    //funcionará como un componentWillMount
    useEffect(() => {

        // Lógica para generar periodos para el select
        let opcionesPeriodo = [];

        let cantidadPeriodos = fechaInicioSistema.diff(fechaContrato, 'years');

        for (let index = 0; index < cantidadPeriodos; index++) {

            let fechaInicioPeriodo = fechaContrato.clone().add(index, 'years');
            let fechaFinPeriodo = fechaContrato.clone().add(index + 1, 'years').subtract(1, 'days');

            let inicioUnix = fechaInicioPeriodo.unix();
            let finUnix = fechaFinPeriodo.unix();

            let nuevoPeriodo = {
                inicio: fechaInicioPeriodo,
                fin: fechaFinPeriodo,
                inicioUnix,
                finUnix,
            };

            opcionesPeriodo.push(nuevoPeriodo);
        }

        // Lógica para dejar disabled opciones ya ingresadas en histórico
        let indexesPeriodosYaIngresados = [];

        opcionesPeriodo.forEach((periodo, index) => {

            props.historico.forEach((periodoHistorico) => {

                if (periodo.inicioUnix === periodoHistorico.FechaInicioUnix && periodo.finUnix === periodoHistorico.FechaFinUnix) {

                    indexesPeriodosYaIngresados.push({ index, TipoDeDias: periodoHistorico.TipoDeDias });
                }
            })
        });

        setPeriodosYaIngresados(indexesPeriodosYaIngresados);
        setOpcionesPeriodo(opcionesPeriodo);
        actualizarSaldosTotales(props.historico);
    }, [])

    const onFinish = () => {

        if (registroEditar) {
            editarBitacora();

        } else {
            guardarBitacora();
        }

    };

    const onFinishFailed = (values) => {
        const error = values.errorFields[0].errors[0];
        Swal.fire({
            title: "Ups!",
            text: error,
            icon: "error",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
        });
    };

    const onReset = () => {
        form.resetFields();
        setSelectedPeriodo(null);
        setSelectedPeriodoTipoDia(null);
        setRegistroEditar(null);
    };

    const guardarBitacora = async () => {

        props.activarCargando();

        let usuario = localStorage.getItem("usuario-people-manage");
        usuario = JSON.parse(usuario);

        const datos = form.getFieldsValue();

        let { Vacaciones: { Habiles, TipoDeDias } } = datos;

        const { inicio, fin, inicioUnix, finUnix } = selectedPeriodo;

        // let fechaInicio = momentToStringFechaInicio(inicio, databaseFormat);
        // let fechaFin = momentToStringFechaFin(fin, databaseFormat);

        let fechaInicio = convertirFechaString(inicio, 1).fechaString;
        let fechaFin = convertirFechaString(fin, 2).fechaString;
        let str = moment().format('yyyy/MM/DD');

        const vacacionesHistoricas = {
            IdTrabajador: _id,
            IdEmpresa: Empresa,
            TipoDeDias,
            FechaInicio: fechaInicio,
            FechaFin: fechaFin,
            FechaInicioUnix: inicioUnix,
            FechaFinUnix: finUnix,
            CantidadDiasHabiles: Habiles,
            // FechaSubida: moment().format(databaseFormat),
            FechaSubida: convertirFechaString(moment(str), 1).fechaString,
            IdUsuarioPost: usuario._id,
        }

        const url = `${API}/BitacoraDocumentos`;

        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(vacacionesHistoricas),
        });

        const status = response.status;

        const data = await response.json();

        switch (status) {

            case 200:
            case 201:
                let historico = await props.buscarHistorico();
                props.desactivarCargando();
                onReset();

                Swal.fire({
                    title: "¡Bien!",
                    text: "Se guardó la entrega correctamente",
                    icon: "success",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "Continuar",

                }).then(() => {

                    actualizarPeriodosIngresados(historico);
                })

                return;

            default:
                props.buscarHistorico();
                props.desactivarCargando();
                onReset()

                Swal.fire({
                    title: "Ups...",
                    text: "Ha ocurrido un error al guardar la entrega.",
                    icon: "error",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "Continuar",
                });

                return console.log('ERROR AL GUARDAR ENTREGA');

        }

    };

    const eliminarRegistro = async (idRegistro) => {

        let confirmFlag = false;

        await Swal.fire({
            title: "Confirmar",
            text: `¿Estás seguro que quieres eliminar el registro?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1FC600",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",

        }).then(async (result) => {

            if (result.isConfirmed) {
                confirmFlag = true;
            }
        })

        if (!confirmFlag) {
            return;
        }

        props.activarCargando();


        const url = `${API}/BitacoraDocumentos/${idRegistro}`;

        const response = await fetch(url, {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });

        const status = response.status;
        let historico;

        switch (status) {

            case 200:
            case 201:
                historico = await props.buscarHistorico();
                props.desactivarCargando();
                onReset();

                Swal.fire({
                    title: "Bien",
                    text: "Se ha eliminado el registro correctamente.",
                    icon: "success",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "Continuar",
                }).then(() => {
                    actualizarPeriodosIngresados(historico);
                });
                break;

            default:
                historico = await props.buscarHistorico();
                props.desactivarCargando();
                onReset();

                Swal.fire({
                    title: "Ups...",
                    text: "Ha ocurrido un error al eliminar el registro.",
                    icon: "error",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "Continuar",
                }).then(() => {
                    actualizarPeriodosIngresados(historico);
                });
                break;
        }
    }

    const seleccionarEditarRegistro = async (registro) => {

        onReset();
        setRegistroEditar(registro);

        const { TipoDeDias, CantidadDiasHabiles, FechaInicio, FechaFin, FechaInicioUnix, FechaFinUnix } = registro;

        // let fechaInicio = moment.unix(FechaInicioUnix).format('DD-MM-YYYY HH:mm');
        // let fechaFin = moment.unix(FechaFinUnix).format('DD-MM-YYYY HH:mm');

        let indexDeOption;

        opcionesPeriodo.forEach((periodo, index) => {


            if (FechaInicioUnix === periodo.inicioUnix && FechaFinUnix === periodo.finUnix) {

                indexDeOption = index;
                setSelectedPeriodo(periodo);
            }
        });

        form.setFieldsValue({
            Vacaciones: {
                TipoDeDias,
                Periodo: indexDeOption,
                Habiles: CantidadDiasHabiles,
            }
        });
    }

    const editarBitacora = async () => {

        props.activarCargando();

        let usuario = localStorage.getItem("usuario-people-manage");
        usuario = JSON.parse(usuario);

        const datos = form.getFieldsValue();

        const { Vacaciones: { Habiles, TipoDeDias } } = datos;

        const { inicio, fin, inicioUnix, finUnix } = selectedPeriodo;

        // let fechaInicio = momentToStringFechaInicio(inicio, databaseFormat);
        // let fechaFin = momentToStringFechaFin(fin, databaseFormat);

        let fechaInicio = convertirFechaString(inicio, 1).fechaString;
        let fechaFin = convertirFechaString(fin, 2).fechaString;

        const registroId = registroEditar._id;

        const vacacionesHistoricas = {
            TipoDeDias,
            FechaInicio: fechaInicio,
            FechaFin: fechaFin,
            FechaInicioUnix: inicioUnix,
            FechaFinUnix: finUnix,
            CantidadDiasHabiles: Habiles,
        }

        const url = `${API}/BitacoraDocumentos/${registroId}`;

        const response = await fetch(url, {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(vacacionesHistoricas),
        });

        const status = response.status;

        const data = await response.json();

        switch (status) {

            case 200:
            case 201:
                props.buscarHistorico();
                props.desactivarCargando();
                onReset();

                Swal.fire({
                    title: "Bien",
                    text: "Se actualizó el registro correctamente.",
                    icon: "success",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "Continuar",
                });

                break;
            default:
                props.buscarHistorico();
                props.desactivarCargando();
                onReset()

                Swal.fire({
                    title: "Ups...",
                    text: "Ha ocurrido un error al actualizar el registro.",
                    icon: "error",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "Continuar",
                });

                break;
        }

        setRegistroEditar(null);
    }

    const actualizarPeriodosIngresados = (historico) => {

        let indexesPeriodosYaIngresados = [];

        opcionesPeriodo.forEach((periodo, index) => {

            historico.forEach((periodoHistorico) => {

                if (periodo.inicioUnix === periodoHistorico.FechaInicioUnix &&
                    periodo.finUnix === periodoHistorico.FechaFinUnix) {

                    indexesPeriodosYaIngresados.push({ index, TipoDeDias: periodoHistorico.TipoDeDias });
                }
            })
        });

        setPeriodosYaIngresados(indexesPeriodosYaIngresados);
        actualizarSaldosTotales(historico);
    }

    const actualizarSaldosTotales = (historico) => {

        if (historico == undefined || historico == null) {
            return;
        }

        let totalNormales = 0;
        let totalProgresivos = 0;

        historico.forEach((periodoHistorico) => {

            switch (periodoHistorico.TipoDeDias) {

                case 'Normales':
                    totalNormales += periodoHistorico.CantidadDiasHabiles;
                    break;

                case 'Progresivos':
                    totalProgresivos += periodoHistorico.CantidadDiasHabiles;
                    break;
                default:
                    console.error('error: el día no es normal ni progresivo.');
            }
        })

        setTotalArray([{ totalNormales, totalProgresivos }]);
    }

    const regresarACrear = () => {

        onReset();
        setRegistroEditar(null);
    }

    const validarRadioButtons = (tipoDia) => {

        if (selectedPeriodoTipoDia) {
            return selectedPeriodoTipoDia === tipoDia;

        } else {
            return true;
        }
    }

    const validarTooltip = () => {

        return selectedPeriodoTipoDia === 'Normales' || selectedPeriodoTipoDia === 'Progresivos';

    }

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 12,
            },
        },
    };

    // Cuando el trabajador fue contratado después del inicio del sistema con la empresa,
    // no se ingresarán datos históricos, se ingresarán desde el proceso de vacaciones.

    console.log("FECHAS DEL SISTEMA");
    console.log(fechaInicioSistema);
    console.log(fechaContrato);

    if (fechaInicioSistema.toString() === 'Invalid date' || fechaContrato.toString() === 'Invalid date') {

        return (
            <Empty
                description={
                    <div>
                        <span>
                            Faltan los datos necesarios para ingresar la vacaciones históricas:<br />
                            Asegúrate de haberle ingresado la fecha de contrato al trabajador y que la empresa
                            tenga fecha de inicio en el sistema.
                        </span>
                    </div>
                }
                style={{ marginTop: '100px', marginBottom: '100px' }}
            />
        )

    } else if (fechaInicioSistema.isBefore(fechaContrato.clone().add(1, 'years'))) {

        return (
            <Empty
                description={
                    <div>
                        <span>
                            Debido a la fecha de contrato del trabajador, no corresponde ingresarle vacaciones históricas,
                            <br />
                            Si desea ingresarle o planificarle vacaciones, por favor entre al <Link to="/Vacaciones">proceso de vacaciones.</Link>
                        </span>
                    </div>
                }
                style={{ marginTop: '100px', marginBottom: '100px' }}
            />
        )

    } else {
        return (
            <Form
                {...formItemLayout}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Row style={{ padding: "5px 20px 0px 20px" }}>

                    <Col span={24} style={{ display: "flex" }} className="title">
                        <img
                            src={ImgVacaciones}
                            style={{ width: "35px" }}
                            alt="icono modal"
                        />
                        <PageHeader
                            style={{ padding: "0px 10px 0px 10px" }}
                            className="site-page-header"
                            title={`Vacaciones históricas de ${Nombre} ${Apellido_Paterno} ${Apellido_Materno}`}
                        />
                    </Col>

                    <Col offset={1} span={24}>
                        {/* <Card className="card-perfil" style={{ width: "100%" }}> */}
                        <Collapse
                            bordered={false}
                            defaultActiveKey={["titulos", "historico"]}
                            className="formulario-trabajador site-collapse-custom-collapse cont-tabla-celeste"
                            style={{ marginBottom: "10%" }}
                        >

                            {/* ------------------------------------------ INGRESAR VACACIONES HISTÓRICAS --------------------------------------------- */}

                            <Panel
                                key="titulos"
                                className=" site-collapse-custom-panel"
                                header={
                                    <h4
                                        style={{
                                            position: "absolute",
                                            bottom: -5,
                                            color: "#061fcb",
                                            left: 35,
                                        }}
                                    >
                                        Ingresar vacaciones históricas
                                    </h4>
                                }
                                extra={[
                                    <img
                                        key="3"
                                        src={flecha}
                                        style={{
                                            position: "absolute",
                                            bottom: 3,
                                            right: 11,
                                            width: 28,
                                            color: "#48B9FD",
                                        }}
                                        alt="icono"
                                    />,
                                    <img
                                        key="4"
                                        src={ImgSol}
                                        style={{
                                            position: "absolute",
                                            left: 5,
                                            bottom: 3,
                                            width: 28,
                                        }}
                                        alt="icono"
                                    />,
                                ]}
                            >
                                {
                                    <>
                                        <p
                                            style={{
                                                fontFamily: "MavenPro-Bold",
                                                textAlign: "left",
                                                marginLeft: "5%",
                                                marginBottom: "2%",
                                                marginTop: "3%"
                                            }}>
                                            Ingresar solo periodos de vacaciones con saldo de días pendientes
                                        </p>

                                        <Form.List name="Vacaciones">
                                            {
                                                () => (
                                                    <>

                                                        <Form.Item
                                                            style={{ marginBottom: 18 }}
                                                            name="Periodo"
                                                            label={<span style={{ margin: 0 }}>Periodo trabajado al cual<br />corresponden las vacaciones</span>}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Debe introducir el periodo.",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                name="Periodo"
                                                                placeholder="Seleccionar periodo"
                                                                onSelect={(index) => {
                                                                    let periodo = opcionesPeriodo[index];

                                                                    let timesIngresadoArr = periodosYaIngresados.filter(periodoIngresado => {
                                                                        return periodoIngresado.index === index;
                                                                    });

                                                                    let tipoDiasSelected = timesIngresadoArr.length > 0 ? timesIngresadoArr[0].TipoDeDias : 'none';

                                                                    setSelectedPeriodoTipoDia(tipoDiasSelected)
                                                                    setSelectedPeriodo(periodo);
                                                                }}
                                                                disabled={registroEditar ? true : false}
                                                            >
                                                                {opcionesPeriodo.map(({ inicio, fin, inicioUnix, finUnix }, index) => {

                                                                    let timesIngresadoArr = periodosYaIngresados.filter(periodoIngresado => {
                                                                        return periodoIngresado.index === index;
                                                                    });

                                                                    let yaIngresado = timesIngresadoArr.length > 1;

                                                                    return (
                                                                        <Select.Option
                                                                            key={index}
                                                                            value={index}
                                                                            disabled={yaIngresado}
                                                                        >
                                                                            {/* {momentToStringFechaInicio(inicio, chileFormat)} - {momentToStringFechaFin(fin, chileFormat)} */}
                                                                            {convertirFechaString(moment.utc(inicio), 1).fechaDDMMYYYY} - {convertirFechaString(moment.utc(fin), 2).fechaDDMMYYYY}
                                                                        </Select.Option>
                                                                    )
                                                                })}
                                                            </Select>

                                                        </Form.Item>


                                                        <Tooltip
                                                            // title="No se pueden ingresar datos de un periodo ya ingresado."
                                                            // color={'blue'}
                                                            // visible={validarTooltip()}
                                                            // placement={'top-left'}
                                                        >
                                                            <Form.Item
                                                                label="Tipo de días"
                                                                name="TipoDeDias"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Seleccione un tipo de día",
                                                                    },
                                                                ]}
                                                            >

                                                                <Radio.Group
                                                                    // style={{ display: "flex" }}
                                                                    key={"TipoDeDias"}
                                                                >
                                                                    <Radio
                                                                        value={"Normales"}
                                                                        disabled={validarRadioButtons('Normales')}
                                                                    >
                                                                        Normales
                                                                    </Radio>

                                                                    <Radio
                                                                        value={"Progresivos"}
                                                                        disabled={validarRadioButtons('Progresivos')}
                                                                    >
                                                                        Progresivos
                                                                    </Radio>
                                                                </Radio.Group>

                                                            </Form.Item>
                                                        </Tooltip>
                                                        <Form.Item
                                                            name="Habiles"
                                                            label="Saldo días hábiles"
                                                            className="inputsFormDescanso_Text"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Obligatorio',
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber min={0} />
                                                        </Form.Item>

                                                    </>
                                                )
                                            }
                                        </Form.List>

                                        <Row gutter={15}>
                                            <Col span={5}>
                                                <div
                                                    className={
                                                        registroEditar
                                                            ? "editando-block"
                                                            : "set-invisible"
                                                    }
                                                    style={{ width: "100%", cursor: 'default' }}


                                                >
                                                    {'EDITANDO'}
                                                </div>
                                            </Col>

                                            <Col flex={'auto'}></Col>
                                            <Col span={5}>
                                                <div
                                                    className={
                                                        registroEditar
                                                            ? "ant-btn guardar-vacaciones-button"
                                                            : "set-invisible"
                                                    }
                                                    style={{ width: "100%" }}
                                                    onClick={() => {
                                                        regresarACrear();
                                                    }}

                                                >
                                                    {'Regresar a crear'}
                                                </div>
                                            </Col>
                                            <Col span={5}>
                                                <button
                                                    className={
                                                        registroEditar
                                                            ? "ant-btn editar-vacaciones-button"
                                                            : "ant-btn guardar-vacaciones-button"
                                                    }
                                                    style={{ width: "100%" }}
                                                    disabled={!props.Editar}
                                                >
                                                    {registroEditar ? 'Editar' : 'Guardar'}
                                                </button>
                                            </Col>
                                        </Row>

                                    </>
                                }

                            </Panel>

                            {/* ---------------------------------------------HISTÓRICO------------------------------------------------ */}

                            <Panel
                                key="historico"
                                className="site-collapse-custom-panel"
                                header={
                                    <h4
                                        style={{
                                            position: "absolute",
                                            bottom: -5,
                                            color: "#061fcb",
                                            left: 35,
                                        }}
                                    >
                                        Histórico
                                    </h4>
                                }
                                extra={[
                                    <img
                                        key="1"
                                        src={flecha}
                                        style={{
                                            position: "absolute",
                                            bottom: 3,
                                            right: 11,
                                            width: 28,
                                            color: "#48B9FD",
                                        }}
                                        alt="icono"
                                    />,
                                    <img
                                        key="2"
                                        src={ImgHistorico}
                                        style={{
                                            position: "absolute",
                                            left: 5,
                                            bottom: 3,
                                            width: 28,
                                        }}
                                        alt="icono"
                                    />,
                                ]}
                            >

                                {/* TABLA HISTÓRICOS */}
                                <Table
                                    rowKey="_id"
                                    dataSource={props.historico}
                                    // loading={this.props.tablaCargando}
                                    bordered={true}
                                    showSorterTooltip={false}
                                    style={{ fontFamily: 'MavenPro-Regular' }}
                                    locale={{ emptyText: 'Todavía no se ha ingresado registro de vacaciones del trabajador.' }}
                                >

                                    <Column
                                        align="center"
                                        title="Tipo de días"
                                        dataIndex="TipoDeDias"
                                        key="Nombre"
                                    />

                                    <Column
                                        align="center"
                                        title="Saldo de días hábiles"
                                        dataIndex="CantidadDiasHabiles"
                                        key="Descripcion"
                                    />

                                    <Column
                                        title="Fecha desde"
                                        key="FechaInicio"
                                        align="center"
                                        render={(valor, e, index) => {
                                            return (
                                                // <span>{momentToStringFechaInicio(moment.unix(valor.FechaInicioUnix), chileFormat)}</span>
                                                <span>{convertirFechaString(moment.utc(valor.FechaInicio), 1).fechaDDMMYYYY}</span>
                                            );
                                        }}
                                    />

                                    <Column
                                        title="Fecha hasta"
                                        key="FechaFin"
                                        align="center"
                                        render={(valor) => {
                                            return (
                                                // <span>{momentToStringFechaFin(moment.unix(valor.FechaFinUnix), chileFormat)}</span>
                                                <span>{convertirFechaString(moment.utc(valor.FechaFin), 2).fechaDDMMYYYY}</span>
                                            );
                                        }}
                                    />

                                    <Column
                                        title="Acciones"
                                        align="center"
                                        render={(value) => (
                                            <Space size="middle">
                                                <Button
                                                    onClick={() => {
                                                        seleccionarEditarRegistro(value);
                                                    }}
                                                    className="editar-button"
                                                    icon={<EditFilled />}
                                                    disabled={!props.Editar}
                                                />
                                                <Button
                                                    className="eliminar-button"
                                                    onClick={() => {
                                                        eliminarRegistro(value._id);
                                                    }}
                                                    icon={<DeleteFilled />}
                                                    disabled={!props.Eliminar}
                                                />
                                            </Space>
                                        )}
                                    />

                                </Table>


                                {/* TABLA SALDOS TOTALES */}

                                {(() => {
                                    if (props.historico.length > 0) {
                                        return (
                                            <Table
                                                rowKey="_id1"
                                                dataSource={totalArray}
                                                bordered={true}
                                                style={{ fontFamily: 'MavenPro-Regular', marginTop: '15px', width: '60%', marginLeft: 'auto', marginRight: 'auto' }}
                                                locale={{ emptyText: 'Todavía no se ha ingresado registro de vacaciones del trabajador.' }}
                                                pagination={false}
                                            >
                                                <Column
                                                    align="center"
                                                    title="Total días normales"
                                                    dataIndex="totalNormales"
                                                    key="totalNormales"
                                                />

                                                <Column
                                                    align="center"
                                                    title="Total días progresivos"
                                                    dataIndex="totalProgresivos"
                                                    key="totalProgresivos"
                                                />
                                            </Table>
                                        )
                                    }
                                })()}

                            </Panel>

                        </Collapse>
                    </Col>

                </Row>

            </Form >
        );

    }
};

export default FormularioSubirDocumentos;
