import React, { useState } from "react";
import epp from "../../img/icons/epp.svg";
import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Radio,
  Select,
  Tooltip,
  Upload
} from "antd";

import Swal from "sweetalert2";
import { UploadOutlined } from "@ant-design/icons";

const FormularioEPPCrear = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();
  const [imagen64, setImagen] = useState('');

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = async (values) => {

    //imagen predeterminada en caso de que no se suba una
    if (imagen64 === '') {
      values.Imagen = 'https://peoplemanage.blob.core.windows.net/imagenes-sistema/epp.svg';
    } else {
      values.Imagen = imagen64;
    }

    if (values.Vigencia === undefined) values.Vigencia = true;

    //para obtener ID de su empresa
    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      const objAux = {
        ...values,
        Empresa: usuario.EmpresaSeleccionada.Empresa._id,
      };

      props.crearEPP(objAux);
    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const convertBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const obtenerBase64 = (info) => {
    if (info.file.status === "uploading") {
      return;
    }

    if (info.file.status === "done") {
      convertBase64(info.file.originFileObj, (imageUrl) => {
        setImagen(imageUrl);
      });
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={epp} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Nuevo E.P.P."
          />
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>

            <Form.Item
              name="Nombre"
              label="Nombre:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir un nombre",
                  min: 2,
                },
              ]}
            >
              <Input name="Nombre" className="campos" />
            </Form.Item>

            <Form.Item
              label="Tipo de talla"
              name="Tipo_Talla"
              style={{ textAlign: 'left' }}
              rules={[
                {
                  required: true,
                  message: "Debe seleccionar el tipo de talla",
                },
              ]}
            >
              <Radio.Group name="Tipo_Talla" style={{ marginLeft: '5px' }}>
                <Tooltip title="XXS - XS - S - M - L - XL - XXL">
                  <Radio value={'Vestuario'}>Vestuario</Radio>
                </Tooltip>
                <Tooltip title=" 35 - 36 - 37 - 38 - ... - 50">
                  <Radio value={'Calzado'}>Calzado</Radio>
                </Tooltip>
                <Tooltip title="Para E.P.P. con talla estándar o sin talla">
                  <Radio value={'Estándar / Sin talla'}>Estándar / Sin talla</Radio>
                </Tooltip>

              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="Colores"
              label="Colores:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir al menos un color",
                },
              ]}
            >
              <Select mode="tags" placeholder="Introducir otro color con tecla Enter...">
                <Select.Option key='Rojo'>Rojo</Select.Option>
                <Select.Option key='Amarillo'>Amarillo</Select.Option>
                <Select.Option key='Blanco'>Blanco</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="Vigencia"
              label="Vigencia"
              style={{ textAlign: "left" }}
            >
              <Switch
                defaultChecked
                className="switch"
              />
            </Form.Item>

            <Form.Item
              name="Imagen"
              label="Imagen"
              className="inputsFormDescanso_Text"
              style={{ textAlign: 'left' }}
            >
              <Upload
                showUploadList={true}
                method="GET"
                action="#"
                value={null}
                onChange={obtenerBase64}
                accept=".png, .jpg, .jpeg"
              >
                <Button icon={<UploadOutlined />}>
                  Seleccionar (opcional)
                </Button>
              </Upload>
            </Form.Item>

          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormularioEPPCrear;
