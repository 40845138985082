import React from 'react';
import './maintenance.css';
import gif from "../../img/logos/pm.gif";
import logo from "../../img/icons/logo-pmanagment.svg";
import { Row, Col } from "antd";

class Loading extends React.Component {

    // Cargando = () => {
    //     if (this.props.cargando) {
    //         return (
    //             <div className="cCargando" >
    //                 <div className="cCargandoImg">
    //                     <img src={imagen} alt="gif pantalla de carga" className="GifPantallaCarga" />
    //                 </div>
    //             </div>
    //         )
    //     } else {
    //         return (
    //             <div className="cCargando oculto">
    //                 <div className="cCargandoImg">
    //                     <img src={imagen} alt="gif pantalla de carga" className="GifPantallaCarga" />
    //                 </div>
    //             </div>
    //         )
    //     }
    // }

    render() {
        return (
            <div className={'maint-screen-wrapper'}>
                <div className={'maint-card'}>

                    <Row style={{ height: '100%' }} align={'middle'}>

                        <Col style={{ height: 'fit-content', width: '100%' }}>
                            <div className={'maint-content-container'}>

                                <img src={logo} className={'maint-pm-logo'} />

                                <p style={{ fontSize: '25px', marginBottom: '10px' }}>PManagement se encuentra bajo mantenimiento actualmente.</p>
                                <p style={{ fontSize: '15px', color: '#999999', marginBottom: '0px' }}>Estaremos de vuelta en las próximas horas, gracias por su paciencia.</p>

                            </div>
                        </Col>


                    </Row>
                </div>
            </div>
        )
    }

}

export default Loading;