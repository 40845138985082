import {
  LOADING_ASISTENCIA,
  ERROR_ASISTENCIA,
  TRAER_CENTROS_DE_COSTO,
  TRAER_PERSONAS_PENDIENTES,
  MOSTRA_TABLA_ASISTENCIA_TIMELINE,
  //CONFIRMAR_ASISTENCIA,
} from "../types/asistenciaTimeLineType";
import Swal from "sweetalert2";
import API from "../Api/api";
import moment from "moment";
import coloresEstadoTrabajo from "../../funcionesGenericas/coloresEstadoTrabajo";

import {
  convertirFechaString,
  corregirZonaHoraria
} from "../../funcionesGenericas/fechas";

export const traer_centros_de_costo = (empresa) => async (dispatch) => {
  dispatch({
    type: LOADING_ASISTENCIA,
  });

  try {
    const url = `${API}/CentroDeCostos?empresa=${empresa}`;

    const request = await fetch(url);
    const { centrosDeCostos, err } = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:
        dispatch({
          type: TRAER_CENTROS_DE_COSTO,
          payload: centrosDeCostos,
        });

        return centrosDeCostos;
        break;
      case 500:
        dispatch({
          type: ERROR_ASISTENCIA,
          payload: err.message,
        });
        break;
      default:
        break;
    }
  } catch (error) {
    dispatch({
      type: ERROR_ASISTENCIA,
      payload: error,
    });
  }
};

export const confirmarAsistenciaManual = (
  objEnviar,
  centroDeCosto,
  fechaInicio,
  fechaFin,
  idEmpresa,
) => async (dispatch) => {
  dispatch({
    type: LOADING_ASISTENCIA,
  });

  try {
    const url = `${API}/Asistencia/Ingreso/Manual`;
    const request = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objEnviar),
    });

    //const { err } = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:
        const urlRecarga =
          centroDeCosto != null && centroDeCosto !== ""
            ? `${API}/IngresoDeDias/Trabajadores/Embarcados/New?centroDeCosto=${centroDeCosto}&idEmpresa=${idEmpresa}`
            : `${API}/IngresoDeDias/Trabajadores/Embarcados/New?idEmpresa=${idEmpresa}`;

        const parametros = {
          fecha_inicio: fechaInicio,
          fecha_fin: fechaFin,
        };

        const requestRecarga = await fetch(urlRecarga, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(parametros),
        });
        const statusRecarga = await requestRecarga.status;
        const { data, err } = await requestRecarga.json();

        switch (statusRecarga) {
          case 200:
            // let dataEnviar = ordenarUsuariosUnix(data);
            data.items = transformarFechasItems(data.items);

            Swal.fire({
              title: "Bien",
              text: "¡Confirmación de asistencia exitoso!",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });

            dispatch({
              type: TRAER_PERSONAS_PENDIENTES,
              payload: data,
            });
            break;
          case 500:

            console.log(status);
            dispatch({
              type: ERROR_ASISTENCIA,
              payload: err.message,
            });
            break;
          default:
            break;
        }

        break;
      case 404:
        dispatch({
          type: ERROR_ASISTENCIA,
          payload: err.message,
        });
        break;
      case 500:
        dispatch({
          type: ERROR_ASISTENCIA,
          payload: err.message,
        });
        break;
      default:
        break;
    }
  } catch (error) {
    dispatch({
      type: ERROR_ASISTENCIA,
      payload: error,
    });
  }
};

export const quitarAsistenciaManual = (
  objEnviar,
  centroDeCosto,
  fechaInicio,
  fechaFin,
  idEmpresa,
) => async (dispatch) => {
  dispatch({
    type: LOADING_ASISTENCIA,
  });

  try {
    const url = `${API}/Asistencia/QuitarAsistencia/Manual`;
    const request = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objEnviar),
    });

    //const { err } = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:
        const urlRecarga =
          centroDeCosto != null && centroDeCosto !== ""
            ? `${API}/IngresoDeDias/Trabajadores/Embarcados/New?centroDeCosto=${centroDeCosto}&idEmpresa=${idEmpresa}`
            : `${API}/IngresoDeDias/Trabajadores/Embarcados/New?idEmpresa=${idEmpresa}`;

        const parametros = {
          fecha_inicio: fechaInicio,
          fecha_fin: fechaFin,
        };

        const requestRecarga = await fetch(urlRecarga, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(parametros),
        });
        const { data, err } = await requestRecarga.json();
        const statusRecarga = await requestRecarga.status;

        switch (statusRecarga) {
          case 200:
            // let dataEnviar = ordenarUsuariosUnix(data);
            data.items = transformarFechasItems(data.items);

            Swal.fire({
              title: "Bien",
              text: "¡La eliminación de asistencia fue exitoso!",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });

            dispatch({
              type: TRAER_PERSONAS_PENDIENTES,
              payload: data,
            });
            break;
          case 500:

            console.log(data);
            console.log(status);
            dispatch({
              type: ERROR_ASISTENCIA,
              payload: err.message,
            });
            break;
          default:
            break;
        }

        break;
      case 404:
        dispatch({
          type: ERROR_ASISTENCIA,
          payload: err.message,
        });
        break;
      case 500:
        dispatch({
          type: ERROR_ASISTENCIA,
          payload: err.message,
        });
        break;
      default:
        break;
    }
  } catch (error) {
    dispatch({
      type: ERROR_ASISTENCIA,
      payload: error,
    });
  }
};


////API's Nuevas Start
//Nueva quitarAsistenciaMasivaPersonal
export const quitarAsistenciaMasivaPersonal = (
  objEnviar,
  fechaInicio,
  fechaFin,
  centroDeCosto,
  idEmpresa,
  pageSize,
  skip
) => async () => {
  
  try {
    const datosNecesarios = {
      data: objEnviar,
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin,
    };
    
    const url = `${API}/IngresoDeDias/Trabajadores/quitarAsistenciaPersonal?empresa=${idEmpresa}`;
    const request = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(datosNecesarios),
    });

    const status = await request.status;

    switch (status) {
      case 200:
        Swal.fire({
          title: "Bien",
          //text: "¡Confirmación de quitar asistencia masiva exitosa!",
          text: "¡Se cambió la asistencia masiva del trabajador exitosamente!",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        const url =
          centroDeCosto != null && centroDeCosto !== ""
            ? `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia?centroDeCosto=${centroDeCosto}`
            : `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia`;
    
        const parametros = {
          fecha_inicio: fechaInicio,
          fecha_fin: fechaFin,
          pageSize,
          skip
        };

        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(parametros),
        });
        const status = await request.status;

        switch (status) {
          case 200:
            let { data } = await request.json();
            return  {status , data};
          case 500:
            return  {status , data:[] };
    
          default:
            return  {status , data:[] };

        }

      case 500:
         Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se realizó de forma correcta la petición.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
        return  {status , data:[] };
 
      default:
        return  {status , data:[] };

    }
  } catch (error) {
    console.log(error);
    return  {status:500, data:[] };
  }
};

//Nueva pasarAsistenciaMasivaPersonal
export const pasarAsistenciaMasivaPersonal = (
  objEnviar,
  fechaInicio,
  fechaFin,
  centroDeCosto,
  idEmpresa,
  pageSize,
  skip
) => async () => {
  try {
    const datosNecesarios = {
      data: objEnviar,
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin,
    };

    const url = `${API}/IngresoDeDias/Trabajadores/pasarAsistenciaPersonal?empresa=${idEmpresa}`;
    const request = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(datosNecesarios),
    });

    const status = await request.status;

    switch (status) {
      case 200:

        Swal.fire({
          title: "Bien",
          //text: "¡Confirmación de pasar asistencia masiva exitosa!",
          text:"¡Se cambió la asistencia masiva del trabajador exitosamente!",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        const url =
          centroDeCosto != null && centroDeCosto !== ""
            ? `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia?centroDeCosto=${centroDeCosto}`
            : `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia`;
    
        const parametros = {
          fecha_inicio: fechaInicio,
          fecha_fin: fechaFin,
          pageSize,
          skip
        };

        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(parametros),
        });
        const status = await request.status;

        switch (status) {
          case 200:
            let { data } = await request.json();
            return  {status , data};
          case 500:
            return  {status , data:[] };
    
          default:
            return  {status , data:[] };

        }

      case 500:
         Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se realizó de forma correcta la petición.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
        return  {status , data:[] };
 
      default:
        return  {status , data:[] };

    }
  } catch (error) {
    console.log(error);
    return  {status:500, data:[] };
  }
};

//Nueva quitar asistencia por dia
export const quitarAsistenciaPorDia = (
  id,
  fechaInicio,
  fechaFin,
  centroDeCosto,
  idEmpresa,
  pageSize,
  skip
  ) => async () =>{
  try {

    const datosNecesarios = {
      idBitacora: id,
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin,
    };
    const url = `${API}/IngresoDeDias/Trabajadores/quitarAsistenciaPorDia?empresa=${idEmpresa}`;
    const request = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(datosNecesarios),
    });

    const status = await request.status;

    switch (status) {
      case 200:

        Swal.fire({
          title: "Bien",
          //text: "¡Confirmación de pasar asistencia exitosa!",
          text: "¡Se cambió la asistencia exitosamente!",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        const url =
          centroDeCosto != null && centroDeCosto !== ""
            ? `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia?centroDeCosto=${centroDeCosto}`
            : `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia`;
    
        const parametros = {
          fecha_inicio: fechaInicio,
          fecha_fin: fechaFin,
          pageSize,
          skip
        };

        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(parametros),
        });
        const status = await request.status;

        switch (status) {
          case 200:
            let { data } = await request.json();
            //console.log(data)
            return  {status , data};
          case 500:
            return  {status , data:[] };
    
          default:
            return  {status , data:[] };

        }

      case 500:
         Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se realizó de forma correcta la petición.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
        return  {status , data:[] };
 
      default:
        return  {status , data:[] };

    }
  } catch (error) {
    return  {status:500, data:[] };
  }
};

//Nueva pasar asistencia por dia
export const pasarAsistenciaPorDia = (
  id,
  fechaInicio,
  fechaFin,
  centroDeCosto,
  idEmpresa,
  pageSize,
  skip
) => async () =>{
  try {

    const datosNecesarios = {
      idBitacora: id,
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin,
    };
    const url = `${API}/IngresoDeDias/Trabajadores/pasarAsistenciaPorDia?empresa=${idEmpresa}`;
    const request = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(datosNecesarios),
    });

    const status = await request.status;

    switch (status) {
      case 200:

        Swal.fire({
          title: "Bien",
          //text: "¡Confirmación de pasar asistencia exitosa!",
          text: "¡Se cambió la asistencia exitosamente!",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        const url =
          centroDeCosto != null && centroDeCosto !== ""
            ? `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia?centroDeCosto=${centroDeCosto}`
            : `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia`;
    
        const parametros = {
          fecha_inicio: fechaInicio,
          fecha_fin: fechaFin,
          pageSize,
          skip
        };

        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(parametros),
        });
        const status = await request.status;

        switch (status) {
          case 200:
            let { data } = await request.json();
            return  {status , data};
          case 500:
            return  {status , data:[] };
    
          default:
            return  {status , data:[] };

        }

      case 500:
         Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se realizó de forma correcta la petición.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
        return  {status , data:[] };
 
      default:
        return  {status , data:[] };

    }
  } catch (error) {
    return  {status:500, data:[] };
  }

};

//Nuevo pasarAsistenciaMasivaPersonal All(todos los que se muestran por pantalla)
export const pasarAsistenciaMasivaPersonalAll = (
  bitacoras,
  fechaInicio,
  fechaFin,
  centroDeCosto,
  idEmpresa,
  pageSize,
  skip
) => async() =>{
  try {
    const datosNecesarios = {
      bitacoras,
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin,
    };

    const url = `${API}/IngresoDeDias/Trabajadores/pasarAsistenciaPersonalAll?empresa=${idEmpresa}`;
    const request = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(datosNecesarios),
    });

    const status = await request.status;

    switch (status) {
      case 200:

        Swal.fire({
          title: "Bien",
          //text: "¡Confirmación de PASAR Asistencia Masiva Exitosa!",
          text: "¡Se cambió la asistencia masiva de los trabajadores exitosamente!",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        const url =
          centroDeCosto != null && centroDeCosto !== ""
            ? `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia?centroDeCosto=${centroDeCosto}`
            : `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia`;
    
        const parametros = {
          fecha_inicio: fechaInicio,
          fecha_fin: fechaFin,
          pageSize,
          skip
        };

        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(parametros),
        });
        const status = await request.status;

        switch (status) {
          case 200:
            let { data } = await request.json();
            return  {status , data};
          case 500:
            return  {status , data:[] };
    
          default:
            return  {status , data:[] };

        }

      case 500:
         Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se realizó de forma correcta la petición.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
        return  {status , data:[] };
 
      default:
        return  {status , data:[] };

    }
  } catch (error) {
    console.log(error);
    return  {status:500, data:[] };
  }
};

export const quitarAsistenciaMasivaPersonalAll = (
  bitacoras,
  fechaInicio,
  fechaFin,
  centroDeCosto,
  idEmpresa,
  pageSize,
  skip
) => async() =>{

  try {
    const datosNecesarios = {
      bitacoras,
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin,
    };

    const url = `${API}/IngresoDeDias/Trabajadores/quitarAsistenciaPersonalAll?empresa=${idEmpresa}`;
    const request = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(datosNecesarios),
    });

    const status = await request.status;

    switch (status) {
      case 200:

        Swal.fire({
          title: "Bien",
          //text: "¡Confirmación de QUITAR Asistencia Masiva Exitosa!",
          text: "¡Se cambió la asistencia masiva de los trabajadores exitosamente!",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        const url =
          centroDeCosto != null && centroDeCosto !== ""
            ? `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia?centroDeCosto=${centroDeCosto}`
            : `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia`;
    
        const parametros = {
          fecha_inicio: fechaInicio,
          fecha_fin: fechaFin,
          pageSize,
          skip
        };

        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(parametros),
        });
        const status = await request.status;

        switch (status) {
          case 200:
            let { data } = await request.json();
            return  {status , data};
          case 500:
            return  {status , data:[] };
    
          default:
            return  {status , data:[] };

        }

      case 500:
         Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se realizó de forma correcta la petición.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
        return  {status , data:[] };
 
      default:
        return  {status , data:[] };

    }

  } catch (error) {
    console.log(error);
    return  {status:500, data:[] };
  }
};

export const traer_personas = (
  centroDeCosto,
  fechaInicio,
  fechaFin,
  idEmpresa,
) => async (dispatch) => {
  dispatch({ type: LOADING_ASISTENCIA });

  try {
    const url =
      centroDeCosto != null && centroDeCosto !== ""
        ? `${API}/IngresoDeDias/Trabajadores/Embarcados?centroDeCosto=${centroDeCosto}&idEmpresa=${idEmpresa}`
        : `${API}/IngresoDeDias/Trabajadores/Embarcados?idEmpresa=${idEmpresa}`;

    const parametros = {
      // fecha_inicio: moment(fechaInicio).set({ hour: 0, minute: 0, second: 0, millisecond: 0, }),
      // fecha_fin: moment(fechaFin).set({ hour: 23, minute: 59, second: 59, millisecond: 59, }),

      fecha_inicio: corregirZonaHoraria(moment(fechaInicio), 1),
      fecha_fin: corregirZonaHoraria(moment(fechaFin), 2),
    };

    const request = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(parametros),
    });
    const { data, err } = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:
        let dataEnviar = ordenarUsuarios(data);

        dispatch({
          type: TRAER_PERSONAS_PENDIENTES,
          payload: dataEnviar,
        });
        break;
      case 500:
        dispatch({
          type: ERROR_ASISTENCIA,
          payload: err.message,
        });
        break;
      default:
        break;
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_ASISTENCIA,
      payload: error,
    });
  }
};

// export const traer_personas_unix = (
//   centroDeCosto,
//   fechaInicio,
//   fechaFin,
//   idEmpresa
// ) => async (dispatch) => {
//   dispatch({ type: LOADING_ASISTENCIA });

//   try {
//     const url =
//       centroDeCosto != null && centroDeCosto !== ""
//         ? `${API}/IngresoDeDias/Trabajadores/Embarcados?centroDeCosto=${centroDeCosto}&idEmpresa=${idEmpresa}`
//         : `${API}/IngresoDeDias/Trabajadores/Embarcados?idEmpresa=${idEmpresa}`;

//     const parametros = {
//       fecha_inicio: fechaInicio,
//       fecha_fin: fechaFin,
//     };

//     const request = await fetch(url, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//       body: JSON.stringify(parametros),
//     });
//     const { data, err } = await request.json();
//     const status = await request.status;

//     switch (status) {
//       case 200:
//         let dataEnviar = ordenarUsuariosUnix(data);

//         dispatch({
//           type: TRAER_PERSONAS_PENDIENTES,
//           payload: dataEnviar,
//         });
//         break;
//       case 500:
//         dispatch({
//           type: ERROR_ASISTENCIA,
//           payload: err.message,
//         });
//         break;
//       default:
//         break;
//     }
//   } catch (error) {
//     console.log(error);
//     dispatch({
//       type: ERROR_ASISTENCIA,
//       payload: error,
//     });
//   }
// };

/////Nueva traer data necesaria asistencia manual
export const traer_personas_asistencia_manual = (
  centroDeCosto,
  fechaInicio,
  fechaFin,
  skip,
  pageSize
  //idEmpresa
) => async () => {
 
  try {
    const url =
      centroDeCosto != null && centroDeCosto !== ""
        ? `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia?centroDeCosto=${centroDeCosto}`
        : `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia`;
    
    const parametros = {
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin,
      skip,
      pageSize
    };

    const request = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(parametros),
    });
    const status = await request.status;

    switch (status) {
      case 200:
        let { data,myCountTrabajadores } = await request.json();

        return  {status , data, myCountTrabajadores};
      case 500:
        return  {status , data:[] };
 
      default:
        return  {status , data:[] };

    }
  } catch (error) {
    console.log(error);
    return  {status:500, data:[] };
  }
};

///
export const traer_personas_unix_new = (
  centroDeCosto,
  fechaInicio,
  fechaFin,
  idEmpresa,
  pageSize
) => async (dispatch) => {
  dispatch({ type: LOADING_ASISTENCIA });

  try {
    // const url =
    //   centroDeCosto != null && centroDeCosto !== ""
    //     ? `${API}/IngresoDeDias/Trabajadores/Embarcados/New?centroDeCosto=${centroDeCosto}&idEmpresa=${idEmpresa}`
    //     : `${API}/IngresoDeDias/Trabajadores/Embarcados/New?idEmpresa=${idEmpresa}`;


    const url =
      centroDeCosto != null && centroDeCosto !== ""
        ? `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia?centroDeCosto=${centroDeCosto}&idEmpresa=${idEmpresa}`
        : `${API}/IngresoDeDias/Trabajadores/FiltrarAsistencia?idEmpresa=${idEmpresa}`;
    

    const parametros = {
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin,
      pageSize
    };

    const request = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(parametros),
    });
    const status = await request.status;

    switch (status) {
      case 200:
        let { data, err } = await request.json();

        //data.items = transformarFechasItems(data.items);
      
        dispatch({
          type: TRAER_PERSONAS_PENDIENTES,
          payload: data,
        });

        break;
      case 500:
        dispatch({
          type: ERROR_ASISTENCIA,
          payload: err.message,
        });
        break;
      default:
        break;
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_ASISTENCIA,
      payload: error,
    });
  }
};

export const ingresar_asistencia_traer_personas = (
  centroDeCosto,
  fechaInicio,
  fechaFin,
) => async (dispatch) => {
  dispatch({
    type: LOADING_ASISTENCIA,
  });

  try {
    const url =
      centroDeCosto != null && centroDeCosto !== ""
        ? `${API}/IngresoDeDias/Trabajadores/Embarcados?centroDeCosto=${centroDeCosto}`
        : `${API}/IngresoDeDias/Trabajadores/Embarcados`;

    const parametros = {
      // fecha_inicio: moment(fechaInicio).set({ hour: 0, minute: 0, second: 0, millisecond: 0, }),
      // fecha_fin: moment(fechaFin).set({ hour: 23, minute: 59, second: 59, millisecond: 59, }),

      fecha_inicio: convertirFechaString(moment(fechaInicio), 1).fechaString,
      fecha_fin: convertirFechaString(moment(fechaFin), 2).fechaString,
    };

    const request = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(parametros),
    });
    const { data, err } = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:
        let dataEnviar = ordenarUsuarios(data);

        dispatch({
          type: TRAER_PERSONAS_PENDIENTES,
          payload: dataEnviar,
        });
        break;
      case 500:
        dispatch({
          type: ERROR_ASISTENCIA,
          payload: err.message,
        });
        break;
      default:
        break;
    }
  } catch (error) {
    dispatch({
      type: ERROR_ASISTENCIA,
      payload: error,
    });
  }
};

//controlada
export const MostrarTimeLine = () => (dispatch) => {
  dispatch({ type: MOSTRA_TABLA_ASISTENCIA_TIMELINE });
};

const ordenarUsuarios = (data) => {
  let gruposDeUsuarios = [];
  let itemsDiasUsuarios = [];

  data.forEach((bitacora, index) => {
    if (
      bitacora.IdFaena !== null &&
      bitacora.IdFaena !== undefined &&
      bitacora.IdFaena !== "null" &&
      bitacora.IdTrabajador !== null &&
      bitacora.IdTrabajador !== "null"
    ) {
      let nuevoGrupo = {
        title: "Usuario Cargado",
        img: "",
        stackItems: true,
        height: 100,
        width: 250,
        id: bitacora.IdTrabajador._id,
        bitacora: bitacora,
      };

      bitacora.Bitacora.forEach((fecha, index) => {
        if (index === 0) {
          let time = new Date(fecha).getTime();

          // let fechaInicio = moment(fecha).set({ hour: 0, minute: 0, second: 0, millisecond: 0, });
          // let fechaFin = moment(fecha).set({ hour: 23, minute: 59, second: 59, millisecond: 59, });

          let fechaInicio = corregirZonaHoraria(moment(fecha), 1);
          let fechaFin = corregirZonaHoraria(moment(fecha), 2);


          let item = {
            id: index + time + Math.floor(Math.random() * 10000 + 1),
            group: bitacora.IdTrabajador._id,
            title: "",
            start_time: fechaInicio,
            end_time: fechaFin,
            canMove: false,
            canResize: false,
            canChangeGroup: false,
            colorBarra: coloresEstadoTrabajo.obtenerColorEstadoTrabajo(
              bitacora.Estado._id
            ),
            bitacora: bitacora,
          };

          itemsDiasUsuarios.push(item);
        } else {
          let time = new Date(fecha.Fecha).getTime();

          // let fechaInicio = moment(fecha.Fecha).set({ hour: 0, minute: 0, second: 0, millisecond: 0, });
          // let fechaFin = moment(fecha.Fecha).set({ hour: 23, minute: 59, second: 59, millisecond: 59, });

          let fechaInicio = corregirZonaHoraria(moment(fecha.Fecha), 1);
          let fechaFin = corregirZonaHoraria(moment(fecha.Fecha), 2);

          let item = {
            id: index + time + Math.floor(Math.random() * 10000 + 1),
            group: fecha.IdTrabajador._id,
            title: "",
            start_time: fechaInicio,
            end_time: fechaFin,
            canMove: false,
            canResize: false,
            canChangeGroup: false,
            colorBarra: coloresEstadoTrabajo.obtenerColorEstadoTrabajo(
              fecha.Estado._id
            ),
            bitacora: fecha,
          };

          itemsDiasUsuarios.push(item);
        }
      });

      gruposDeUsuarios.push(nuevoGrupo);
    }
  });

  let dataEnviar = {
    grupos: gruposDeUsuarios,
    items: itemsDiasUsuarios,
  };

  return dataEnviar;
};

// const ordenarUsuariosUnix = (data) => {

//   let gruposDeUsuarios = [];
//   let itemsDiasUsuarios = [];

//   data.forEach((bitacora, index) => {
//     if (
//       bitacora.IdFaena !== null &&
//       bitacora.IdFaena !== undefined &&
//       bitacora.IdFaena !== "null" &&
//       bitacora.IdTrabajador !== null &&
//       bitacora.IdTrabajador !== "null"
//     ) {
//       let nuevoGrupo = {
//         title: "Usuario Cargado",
//         img: "",
//         stackItems: true,
//         height: 100,
//         width: 250,
//         id: bitacora.IdTrabajador._id,
//         bitacora: bitacora,
//       };

//       bitacora.Bitacora.forEach((fecha, index) => {
//         if (index === 0) {
//           let time = new Date(fecha).getTime();


//           let fechaUnix = momentToUnixFechaInicio(moment(fecha).utc().add(3, 'hours'));
//           console.log(fechaUnix);

//           let fechaInicio = moment.unix(fechaUnix).set({
//             hour: 0,
//             minute: 0,
//             second: 0,
//             millisecond: 0,
//           });

//           let fechaFin = moment.unix(fechaUnix).set({
//             hour: 23,
//             minute: 59,
//             second: 59,
//             millisecond: 59,
//           });

//           let item = {
//             id: index + time + Math.floor(Math.random() * 10000 + 1),
//             group: bitacora.IdTrabajador._id,
//             title: "",
//             start_time: fechaInicio,
//             end_time: fechaFin,
//             canMove: false,
//             canResize: false,
//             canChangeGroup: false,
//             colorBarra: coloresEstadoTrabajo.obtenerColorEstadoTrabajo(
//               bitacora.Estado._id
//             ),
//             bitacora: bitacora,
//           };

//           itemsDiasUsuarios.push(item);
//         } else {
//           let time = new Date(fecha.Fecha).getTime();

//           let fechaInicio = moment.unix(fecha.FechaUnix).set({
//             hour: 0,
//             minute: 0,
//             second: 0,
//             millisecond: 0,
//           });

//           let fechaFin = moment.unix(fecha.FechaUnix).set({
//             hour: 23,
//             minute: 59,
//             second: 59,
//             millisecond: 59,
//           });

//           let item = {
//             id: index + time + Math.floor(Math.random() * 10000 + 1),
//             group: fecha.IdTrabajador._id,
//             title: "",
//             start_time: fechaInicio,
//             end_time: fechaFin,
//             canMove: false,
//             canResize: false,
//             canChangeGroup: false,
//             colorBarra: coloresEstadoTrabajo.obtenerColorEstadoTrabajo(
//               fecha.Estado._id
//             ),
//             bitacora: fecha,
//           };

//           itemsDiasUsuarios.push(item);
//         }
//       });
//       gruposDeUsuarios.push(nuevoGrupo);
//     }
//   });

//   let dataEnviar = {
//     grupos: gruposDeUsuarios,
//     items: itemsDiasUsuarios,
//   };

//   return dataEnviar;
// };

const transformarFechasItems = (items) => {
  items.forEach(item => {
    const fechaInicio = corregirZonaHoraria(moment(item.start_time_string), 1);
    const fechaFin = corregirZonaHoraria(moment(item.start_time_string), 2);

    item.start_time = fechaInicio;
    item.end_time = fechaFin;
  });

  return items;
}