import {
  LOADING,
  ERROR,
  OBTENER_CENTROCOSTOS,
  OBTENER_FAENAS,
  MOSTRAR_MODAL_DESEMBARCO,
  OCULTAR_MODAL_DESEMBARCO,
  OBTENER_TRABAJADOR_ID,
  OBTENER_TRABAJADOR_ID_CENTROCOSTO,
  OBTENER_BITACORA_TRABAJADOR_CHECK_OUT,
} from "../types/CheckOutTypes";

import API from "../Api/api";
import Swal from "sweetalert2";
import moment from "moment";

import {
  convertirFechaString
} from "../../funcionesGenericas/fechas";

export const ObtenerTrabajadoresCentroCosto = (
  idCentroCosto,
  fechaInicio,
  fechaFin,
  idEmpresa
) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    let url;

    // if (idCentroCosto != null && idCentroCosto !== "") {
    //   url = `${API}/CheckIn_CheckOut/Trabajadores?centroDeCosto=${idCentroCosto}&idEmpresa=${idEmpresa}`;
    // } else {
    //   url = `${API}/CheckIn_CheckOut/Trabajadores?idEmpresa=${idEmpresa}`;
    // }
    if (idCentroCosto != null && idCentroCosto !== "") {
      url = `${API}/Ckeck-out/Trabajadores?centroDeCosto=${idCentroCosto}&idEmpresa=${idEmpresa}`;
    } else {
      url = `${API}/Ckeck-out/Trabajadores?idEmpresa=${idEmpresa}`;
    }


    const objRangoFecha = {
      fecha_inicio: moment(fechaInicio).add(-1, "day").set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }),
      fecha_fin: moment(fechaFin).add(1, "day").set({
        hour: 23,
        minute: 60,
        second: 0,
        millisecond: 0,
      }),
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objRangoFecha),
    });

    const status = await response.status;

    let data;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();
        let dataOrdenar = data.data;

        const objCheckInCheckOut = generarOrdenCheckInCheckOut(
          dataOrdenar,
          fechaInicio,
          fechaFin
        );

        dispatch({
          type: OBTENER_TRABAJADOR_ID_CENTROCOSTO,
          payload: objCheckInCheckOut,
        });
        break;
      default:
        // const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text:
            "No hay trabajadores planificados para las fechas seleccionadas.",
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerTrabajadoresCentroCostoUnix = (
  idCentroCosto,
  fechaInicio,
  fechaFin,
  idEmpresa,
  inicioUnix,
  finUnix
) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let url;

    // if (idCentroCosto != null && idCentroCosto !== "") {
    //   url = `${API}/CheckIn_CheckOut/Trabajadores?centroDeCosto=${idCentroCosto}&idEmpresa=${idEmpresa}`;
    // } else {
    //   url = `${API}/CheckIn_CheckOut/Trabajadores?idEmpresa=${idEmpresa}`;
    // }
    if (idCentroCosto != null && idCentroCosto !== "") {
      url = `${API}/Ckeck-out/Trabajadores?centroDeCosto=${idCentroCosto}&idEmpresa=${idEmpresa}`;
    } else {
      url = `${API}/Ckeck-out/Trabajadores?idEmpresa=${idEmpresa}`;
    }

    const objRangoFecha = {
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objRangoFecha),
    });

    const status = await response.status;
    let data;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();
        let dataOrdenar = data.data;

        const objCheckOut = generarOrdenCheckInCheckOutUnix(
          dataOrdenar,
          fechaInicio,
          fechaFin,
          inicioUnix,
          finUnix
        );

        dispatch({
          type: OBTENER_TRABAJADOR_ID_CENTROCOSTO,
          payload: objCheckOut,
        });


        break;
      default:
        Swal.fire({
          title: "Uups",
          text:
            "No hay trabajadores planificados para las fechas seleccionadas.",
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerCentroCostos = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/CentroDeCostos?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      const data = await response.json();

      switch (status) {
        case 200:
        case 201:
          const centroCostos = data.centrosDeCostos;

          dispatch({
            type: OBTENER_CENTROCOSTOS,
            payload: centroCostos,
          });
          break;

        default:
          const error = data.err.message.toString();

          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });

          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerFaenas = (idCentroCosto) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Faena/CentroDeCosto/${idCentroCosto}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let faenas = data.faena;

        dispatch({ type: OBTENER_FAENAS, payload: faenas });
        break;

      default:
        const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const enviarCorreoCheckOut = (
  bitacora,
  faena,
  idCentroCosto_E,
  fechaInicio_E,
  fechaFin_E,
  idEmpresa,
) => async (dispatch) => {
  dispatch({ type: LOADING });
  bitacora.SeEnvioCheckOut = true;

  let urlBitacora = `${API}/BitacoraTrabajadores`;
  let dataBitacora;
  const responseBitacora = await fetch(urlBitacora, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      mode: "no-cors",
    },
    body: JSON.stringify(bitacora),
  });

  const statusBitacora = responseBitacora.status;

  //return
  //switch sin correo API NUEVA
  switch (statusBitacora) {
    case 200:
    case 201:
          let urlBuscarTrabajadoresReady;
          if (idCentroCosto_E != null && idCentroCosto_E !== "") {
            urlBuscarTrabajadoresReady = `${API}/Ckeck-out/Trabajadores?centroDeCosto=${idCentroCosto_E}&idEmpresa=${idEmpresa}`;
          } else {
            urlBuscarTrabajadoresReady = `${API}/Ckeck-out/Trabajadores?idEmpresa=${idEmpresa}`;
          }
          let fecha_ini;
          let fecha_fin;
          let strReady = moment().format('yyyy/MM/DD');
          if (fechaInicio_E !== "" && fechaFin_E !== "") {
            fecha_ini = fechaInicio_E == "" || fechaInicio_E == null ? convertirFechaString(moment(strReady), 1).fechaString : fechaInicio_E;
            fecha_fin = fechaFin_E == "" || fechaFin_E == null ? convertirFechaString(moment(strReady), 2).fechaString : fechaFin_E;
          }
          const objRangoFechaReady = {
            fecha_inicio: fechaInicio_E,
            fecha_fin: fechaFin_E
          };

          const responseBuscarTrabajadoresReady = await fetch(urlBuscarTrabajadoresReady, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              mode: "no-cors",
            },
            body: JSON.stringify(objRangoFechaReady),
          });

          const statusBuscarTrabajadoresReady = responseBuscarTrabajadoresReady.status;

          let BuscarTrabajadoresReady = null;
          switch (statusBuscarTrabajadoresReady) {
            case 200:
            case 201:

              BuscarTrabajadoresReady = await responseBuscarTrabajadoresReady.json();

              let dataOrdenar = BuscarTrabajadoresReady;

              const objCheckInCheckOut = generarOrdenCheckInCheckOut(
                dataOrdenar,
                fechaInicio_E,
                fechaFin_E
              );              

              if (statusBuscarTrabajadoresReady === 200 || statusBuscarTrabajadoresReady === 201) {

                Swal.fire({
                  title: "Bien",
                  text: "Se realizó el Check-Out",
                  icon: "success",
                  confirmButtonColor: "#E60AA3",
                  confirmButtonText: "Continuar",
                });
                dispatch({
                  type: OBTENER_TRABAJADOR_ID_CENTROCOSTO,
                  payload: objCheckInCheckOut.arrayCheckOut,
                });       
      
              } 
           
              break;
            default:
              Swal.fire(
                "Error",
                `Error al realizar la petición. Al buscar trabajadores, Status: ${statusBuscarTrabajadoresReady}`,
                "error"
              );
              dispatch({ type: ERROR, payload: dataBitacora.err });
              break;
          }
          break;
    default:
           Swal.fire(
            "Error",
            `Error al realizar el checkout, Status: ${statusBitacora}`,
            "error"
          );
          dispatch({ type: ERROR, payload: dataBitacora.err });
      break;
  }
  
  //switch con correo API ANTIGUA
  // switch (statusBitacora) {
  //   case 200:
  //   case 201:

  //     const url = `${API}/Enviar/Correo/CheckOut/${bitacora.IdTrabajador._id}`;

  //     const checkOutCorreo = {
  //       centroCosto: faena.Centro_De_Costo.Nombre,
  //       faena: faena.Nombre,
  //     };

  //     const responseCorreo = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         mode: "no-cors",
  //       },
  //       body: JSON.stringify(checkOutCorreo),
  //     });

  //     const statusCorreo = responseCorreo.status;
  //     let dataCorreo;

  //     switch (statusCorreo) {
  //       case 200:
  //       case 201:
  //       default:

  //         let urlBuscarTrabajadores;

  //         if (idCentroCosto_E != null && idCentroCosto_E !== "") {
  //           urlBuscarTrabajadores = `${API}/CheckIn_CheckOut/Trabajadores?centroDeCosto=${idCentroCosto_E}&idEmpresa=${idEmpresa}`;
  //         } else {
  //           urlBuscarTrabajadores = `${API}/CheckIn_CheckOut/Trabajadores?idEmpresa=${idEmpresa}`;
  //         }

  //         let fecha_ini;
  //         let fecha_fin;
  //         let str = moment().format('yyyy/MM/DD');

  //         if (fechaInicio_E !== "" && fechaFin_E !== "") {

  //           // fecha_ini = fechaInicio_E == "" || fechaInicio_E == null ? moment() : fechaInicio_E;
  //           // fecha_fin = fechaFin_E == "" || fechaFin_E == null ? moment() : fechaFin_E;

  //           fecha_ini = fechaInicio_E == "" || fechaInicio_E == null ? convertirFechaString(moment(str), 1).fechaString : fechaInicio_E;
  //           fecha_fin = fechaFin_E == "" || fechaFin_E == null ? convertirFechaString(moment(str), 2).fechaString : fechaFin_E;


  //         }

  //         // const objRangoFecha = {
  //         //   fecha_inicio: moment(fecha_ini).add(-1, "day").set({
  //         //     hour: 0,
  //         //     minute: 0,
  //         //     second: 0,
  //         //     millisecond: 0,
  //         //   }),
  //         //   fecha_fin: moment(fecha_fin).add(1, "day").set({
  //         //     hour: 23,
  //         //     minute: 60,
  //         //     second: 0,
  //         //     millisecond: 0,
  //         //   }),
  //         // };

  //         const objRangoFecha = {
  //           fecha_inicio: moment.utc(fecha_ini),
  //           fecha_fin: moment.utc(fecha_fin),
  //         };

  //         const responseBuscarTrabajadores = await fetch(
  //           urlBuscarTrabajadores,
  //           {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Accept: "application/json",
  //               mode: "no-cors",
  //             },
  //             body: JSON.stringify(objRangoFecha),
  //           }
  //         );

  //         const statusBuscarTrabajadores = responseBuscarTrabajadores.status;
  //         let BuscarTrabajadores = null;

  //         switch (statusBuscarTrabajadores) {
  //           case 200:
  //           case 201:

  //             BuscarTrabajadores = await responseBuscarTrabajadores.json();

  //             let dataOrdenar = BuscarTrabajadores.data;

  //             const objCheckInCheckOut = generarOrdenCheckInCheckOut(
  //               dataOrdenar,
  //               fechaInicio_E,
  //               fechaFin_E
  //             );

  //             dispatch({
  //               type: OBTENER_TRABAJADOR_ID_CENTROCOSTO,
  //               payload: objCheckInCheckOut.arrayCheckOut,
  //             });

  //             if (statusCorreo === 200 || statusCorreo === 201) {
  //               Swal.fire({
  //                 title: "Bien",
  //                 text: "Se realizó el Check-Out",
  //                 icon: "success",
  //                 confirmButtonColor: "#E60AA3",
  //                 confirmButtonText: "Continuar",
  //               });
  //             } else {
  //               Swal.fire({
  //                 title: "Bien",
  //                 text: "Se realizó el Check-Out.Pero no se logro enviar el correo. Si el trabajador no posee correo no le tome importancia a este mensaje",
  //                 icon: "success",
  //                 confirmButtonColor: "#E60AA3",
  //                 confirmButtonText: "Continuar",
  //               });
  //             }

  //             break;
  //           case 404:
  //             dataCorreo = await responseBuscarTrabajadores.json();

  //             Swal.fire("Error", "Servicio no encontrado", "error");
  //             dispatch({ type: ERROR, payload: dataCorreo.err });

  //             break;
  //           default:
  //             dataCorreo = await responseBuscarTrabajadores.json();

  //             Swal.fire(
  //               "Error",
  //               `Error al realizar la petición, Status: ${statusBuscarTrabajadores}`,
  //               "error"
  //             );
  //             dispatch({ type: ERROR, payload: dataCorreo.err });
  //             break;
  //         }

  //         return true;
  //       // case 404:
  //       //   dataCorreo = await responseCorreo.json();

  //       //   Swal.fire("Error", "Servicio no encontrado", "error");
  //       //   dispatch({ type: ERROR, payload: dataCorreo.err });

  //       //   break;
  //       // default:
  //       //   dataCorreo = await responseCorreo.json();

  //       //   Swal.fire(
  //       //     "Error",
  //       //     `Error al realizar la petición, Status: ${statusCorreo}`,
  //       //     "error"
  //       //   );
  //       //   dispatch({ type: ERROR, payload: dataCorreo.err });
  //       //   break;
  //     }
  //     break;
  //   case 404:
  //     dataBitacora = await responseBitacora.json();

  //     Swal.fire("Error", "Servicio no encontrado", "error");
  //     dispatch({ type: ERROR, payload: dataBitacora.err });

  //     break;
  //   default:
  //     dataBitacora = await responseBitacora.json();

  //     Swal.fire(
  //       "Error",
  //       `Error al realizar la petición, Status: ${statusBitacora}`,
  //       "error"
  //     );
  //     dispatch({ type: ERROR, payload: dataBitacora.err });
  //     break;
  // }
};

export const ObtenerFaenaPorId = (bitacora) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    // const url = `${API}/Faena/${bitacora.IdFaena._id}`;
    const url = `${API}/Faena/${bitacora.IdFaena}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = await response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        
        const FaenaCompleta = data.faena;
        //con la nueva api
        let objetoEmbarcar = {
          trabajador: bitacora,
          CentroDeCostoModal: bitacora.CentroCostosNombre,
          fechaInicioCheckIn: convertirFechaString(moment.utc(bitacora.Inicio_Planificacion), 1).fechaDDMMYYYY,
          fechaFinCheckOut: convertirFechaString(moment.utc(bitacora.Fin_Planificacion), 2).fechaDDMMYYYY,
          bitacora: bitacora,
          Faena: FaenaCompleta,
        };
        //objeto de la API ANTIGUA
        // let objetoEmbarcar = {
        //   trabajador: bitacora.IdTrabajador,
        //   CentroDeCostoModal: bitacora.IdFaena.Centro_De_Costo.Nombre,

        //   // fechaInicioCheckIn: moment.unix(bitacora.Inicio_Planificacion_Unix).format("DD-MM-YYYY"),
        //   // fechaFinCheckOut: moment.unix(bitacora.Fin_Planificacion_Unix).format("DD-MM-YYYY"),

        //   fechaInicioCheckIn: convertirFechaString(moment.utc(bitacora.Inicio_Planificacion), 1).fechaDDMMYYYY,
        //   fechaFinCheckOut: convertirFechaString(moment.utc(bitacora.Fin_Planificacion), 2).fechaDDMMYYYY,

        //   bitacora: bitacora,
        //   Faena: FaenaCompleta,
        // };

        dispatch({
          type: OBTENER_TRABAJADOR_ID,
          payload: objetoEmbarcar,
        });

        break;

      default:
        const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const MostrarModalDesembarco = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_DESEMBARCO });
};

export const OcultarModalDesembarco = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_DESEMBARCO });
};

export const buscarBitacorasTrabajador = (
  momentFechaInicio,
  idTrabajador
) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    
    const url = `${API}/BitacoraTrabajadores/ByDates/${idTrabajador}`;
    const fechaPlanificacion = momentFechaInicio.clone();

    const datesData = {
      // Inicio_Planificacion: momentToStringFechaInicio(momentFechaInicio.add(1, "days"), databaseFormat),
      // Fin_Planificacion: momentToStringFechaInicio(momentFechaInicio.add(10, "days"), databaseFormat),

      Inicio_Planificacion: convertirFechaString(momentFechaInicio.add(1, "days"), 1).fechaString,
      Fin_Planificacion: convertirFechaString(momentFechaInicio.add(10, "days"), 2).fechaString,

    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(datesData),
    });

    const status = await response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const bitacoraTrabajador = ordernarPeriodosTrabajo(data.data, fechaPlanificacion);
        dispatch({
          type: OBTENER_BITACORA_TRABAJADOR_CHECK_OUT,
          payload: bitacoraTrabajador,
        });

        break;

      default:
        const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

/////////////////////////////////////////////////////////////////////////////////////////////////

export const ingresarDiaAusenteActions = (objAusencia, flagCorreo) => async (dispatch) => {

  dispatch({
    type: LOADING,
  });

  try {

    const urlAusencia = `${API}/Ausentes/Avanzado`
    const requestAusencia = await fetch(urlAusencia, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objAusencia),
    });

    const responseAusencia = await requestAusencia.json();
    const statusAusencia = await requestAusencia.status;

    switch (statusAusencia) {


      case 201:

        let idRegistroAusencia = responseAusencia.data._id;
        let fechaInicioAusencia = moment.utc(objAusencia.Fecha_Inicio);
        let fechaFinAusencia = moment.utc(objAusencia.Fecha_Fin);

        // console.log("Fechas Dia Ausente 3");
        // console.log(fechaInicioAusencia);
        // console.log(fechaFinAusencia);

        let cantidadDias = fechaFinAusencia.diff(fechaInicioAusencia, "days") + 1;

        let arregloDeDias = [];

        for (let index = 0; index < cantidadDias; index++) {

          let fecha = fechaInicioAusencia;
          fecha = fecha.add(index === 0 ? 0 : 1, "day");

          const aux = {
            // Fecha: new Date(fecha), //////////////////////////// aplicar las funciones 

            // Fecha: obtenerFechaFormateadaMismaHora(fecha.set({ hour: 12 }), databaseFormat),

            Fecha: convertirFechaString(fecha, 1).fechaString,

            // FechaUnix: momentToUnixFecha(fecha),
            FechaUnix: convertirFechaString(fecha, 1).fechaUNIX,
            IdTrabajador: objAusencia.IdTrabajador,
            TipoRegistroBitacoraTrabajador: "5f3eeca16dd7cc57f0d3a260",
            DescDiaAusente: objAusencia.Descripcion,

            InicioTrabajo: index === 0 ? true : false,
            FinTrabajo: index === cantidadDias - 1 ? true : false,
            Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,

            // Fecha_Inicio: momentToStringFechaInicio(moment.utc(objAusencia.Fecha_Inicio).set({ hour: 12 }), databaseFormat),
            // Fecha_Fin: momentToStringFechaFin(moment.utc(objAusencia.Fecha_Fin).set({ hour: 12 }), databaseFormat),

            Fecha_Inicio: objAusencia.Fecha_Inicio,
            Fecha_Fin: objAusencia.Fecha_Fin,

            Ausencias: idRegistroAusencia,
          };
          arregloDeDias.push(aux);
        }

        // console.log("Arreglo dia ausente 4");
        // console.log(arregloDeDias);


        const objIngresoDias = {
          fechaInicio: objAusencia.Fecha_Inicio,
          fechaFin: objAusencia.Fecha_Fin,
          arregloDeDias,
         
        };

        const url = `${API}/IngresoDeDias/${objAusencia.IdTrabajador}`;
        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objIngresoDias),
        });

        const response = await request.json();
        const status = await request.status;
        const envioCorreo = flagCorreo;

        switch (status) {

          case 201:

            if (envioCorreo) {
              const url = `${API}/Enviar/Correo/Ausente/${idRegistroAusencia}`;
              await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              })
            }


            /////////////////////////////

            // Swal.fire({
            //   title: 'Exitoso',
            //   text: 'Se ingresaron exitosamente los días de ausencia',
            //   icon: 'success',
            //   confirmButtonColor: "#E60AA3",
            //   confirmButtonText: "Continuar",
            // });

            return true;

          case 400:
            dispatch({
              type: ERROR,
              payload: '',
            });
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            return false;

          case 500:
            dispatch({
              type: ERROR,
              payload: '',
            });
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            return false;

          default:
            dispatch({
              type: ERROR,
              payload: '',
            });
            Swal.fire({
              title: "Error",
              text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
              icon: "error",
            });
            return false;
        }


      ////////////////////

      case 400:
        Swal.fire({
          title: "Error",
          text: responseAusencia.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR,
          payload: responseAusencia.err.message,
        });
        return false;
      case 409:
        Swal.fire({
          title: "Ups!",
          text: 'No puedes registrar ausencias en días con asistencia ya confirmada.',
          icon: "error",
        });

        dispatch({
          type: ERROR,
          payload: 'No puedes registrar ausencias en días con asistencia ya confirmada.',
        });


        return false;
      case 500:

        Swal.fire({
          title: "Error",
          text: responseAusencia.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR,
          payload: responseAusencia.err.message,
        });
        return false;
      case 420:

        Swal.fire({
          title: "Uups",
          text: "No se pueden asignar días de ausencia en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({ type: ERROR, payload: "No se pueden asignar días de ausencia en un periodo que se encuentra cerrado" });

        return false;
      default:
        dispatch({
          type: ERROR,
          payload: 'No puedes registrar ausencias en días con asistencia ya confirmada.',
        });
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        return false;
    }

  } catch (error) {

    dispatch({
      type: ERROR,
      payload: error.message,
    });
  }
};

export const ingresarPermisoActions = (objPermiso, flagCorreo) => async (dispatch) => {
  dispatch({
    type: LOADING,
  });

  try {
    const urlPermisos = `${API}/Permisos/Avanzado`
    const requestPermiso = await fetch(urlPermisos, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objPermiso),
    });

    const responsePermiso = await requestPermiso.json();
    const statusPermiso = await requestPermiso.status;

    switch (statusPermiso) {
      case 201:

        let idRegistroPermiso = responsePermiso.data._id;

        let fechaInicioPermiso = moment.utc(objPermiso.Fecha_Inicio);
        let fechaFinPermiso = moment.utc(objPermiso.Fecha_Fin);

        let cantidadDias = fechaFinPermiso.diff(fechaInicioPermiso, "days") + 1;

        let arregloDeDias = [];

        for (let index = 0; index < cantidadDias; index++) {

          let fecha = fechaInicioPermiso;
          fecha = fecha.add(index === 0 ? 0 : 1, "day");

          const aux = {

            // Fecha: obtenerFechaFormateadaMismaHora(fecha.set({ hour: 12 }), databaseFormat),
            // FechaUnix: momentToUnixFecha(fecha, databaseFormat),

            Fecha: convertirFechaString(fecha, 1).fechaString,
            FechaUnix: convertirFechaString(fecha, 1).fechaUNIX,

            IdTrabajador: objPermiso.IdTrabajador,
            TipoRegistroBitacoraTrabajador: "5f3eec876dd7cc57f0d3a25f",

            InicioTrabajo: index === 0 ? true : false,
            FinTrabajo: index === cantidadDias - 1 ? true : false,
            Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,

            // Fecha_Inicio: momentToStringFechaInicio(moment.utc(objPermiso.Fecha_Inicio).set({ hour: 12 }), databaseFormat),
            // Fecha_Fin: momentToStringFechaFin(moment.utc(objPermiso.Fecha_Fin).set({ hour: 12 }), databaseFormat),

            Fecha_Inicio: objPermiso.Fecha_Inicio,
            Fecha_Fin: objPermiso.Fecha_Fin,

            Permiso: idRegistroPermiso,
          }

          arregloDeDias.push(aux);
        }

        // console.log("PERMISO ARREGLO DIAS");
        // console.log(arregloDeDias);

        ////////////////////////////////////

        const objIngresoDias = {
          fechaInicio: objPermiso.Fecha_Inicio,
          fechaFin: objPermiso.Fecha_Fin,
          arregloDeDias,
        };

        console.log("OBJETO INGRESO DIAS");
        console.log(objIngresoDias);

        const url = `${API}/IngresoDeDias/${objPermiso.IdTrabajador}`;
        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objIngresoDias),
        });

        const response = await request.json();
        const status = await request.status;
        const envioCorreo = flagCorreo;

        switch (status) {

          case 201:

            if (envioCorreo) {
              const url = `${API}/Enviar/Correo/Permiso/${idRegistroPermiso}`;
              await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              })
            }


            /////////////////////////////

            // Swal.fire({
            //   title: 'Exitoso',
            //   text: 'Se ingresaron exitosamente los días de ausencia',
            //   icon: 'success',
            //   confirmButtonColor: "#E60AA3",
            //   confirmButtonText: "Continuar",
            // });

            return true;
          case 400:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            return false;
          case 500:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            return false;
          default:
            Swal.fire({
              title: "Error",
              text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
              icon: "error",
            });
            return false;
        }

      /////////////////////////////

      case 400:
        Swal.fire({
          title: "Error",
          text: responsePermiso.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR,
          payload: responsePermiso.err.message,
        });
        return false;
      case 409:
        Swal.fire({
          title: "Ups!",
          text: 'No puedes registrar permisos en días con asistencia ya confirmada.',
          icon: "error",
        });

        dispatch({
          type: ERROR,
          payload: 'No puedes registrar permisos en días con asistencia ya confirmada.',
        });

        return false;
      case 500:

        Swal.fire({
          title: "Error",
          text: responsePermiso.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR,
          payload: responsePermiso.err.message,
        });
        return false;
      case 420:

        Swal.fire({
          title: "Uups",
          text: "No se pueden asignar días de permiso en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({ type: ERROR, payload: "No se pueden asignar días de permiso en un periodo que se encuentra cerrado" });

        return false;
      default:
        dispatch({
          type: ERROR,
          payload: 'No puedes registra permisos en días con asistencia ya confirmada.',
        });
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        return false;
    }

  } catch (error) {

    dispatch({
      type: ERROR,
      payload: error.message,
    });

  }

};

export const ingresarTransitoActions = (objTransito, flagCorreo) => async (dispatch) => {

  dispatch({
    type: LOADING,
  });

  try {

    const urlTransito = `${API}/EnTransito/Avanzado`
    const requestTransito = await fetch(urlTransito, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objTransito),
    });

    const responseTransito = await requestTransito.json();
    const statusTransito = await requestTransito.status;

    switch (statusTransito) {

      case 201:

        let idRegistroTransito = responseTransito.data._id;
        let fechaInicioTransito = moment.utc(objTransito.Fecha_Inicio);
        let fechaFinTransito = moment.utc(objTransito.Fecha_Fin);

        let cantidadDias = fechaFinTransito.diff(fechaInicioTransito, "days") + 1;

        let arregloDeDias = [];

        for (let index = 0; index < cantidadDias; index++) {

          let fecha = fechaInicioTransito;
          fecha = fecha.add(index === 0 ? 0 : 1, "day");

          const aux = {

            // Fecha: obtenerFechaFormateadaMismaHora(fecha.set({ hour: 12 }), databaseFormat),
            Fecha: convertirFechaString(fecha, 1).fechaString,

            // FechaUnix: momentToUnixFecha(fecha, databaseFormat),
            FechaUnix: convertirFechaString(fecha, 1).fechaUNIX,

            IdTrabajador: objTransito.IdTrabajador,
            TipoRegistroBitacoraTrabajador: "5f8f369e8efe155a48df37fb",

            InicioTrabajo: index === 0 ? true : false,
            FinTrabajo: index === cantidadDias - 1 ? true : false,
            Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,

            // Fecha_Inicio: momentToStringFechaInicio(moment.utc(objTransito.Fecha_Inicio).set({ hour: 12 }), databaseFormat),
            // Fecha_Fin: momentToStringFechaFin(moment.utc(objTransito.Fecha_Fin).set({ hour: 12 }), databaseFormat),

            Fecha_Inicio: objTransito.Fecha_Inicio,
            Fecha_Fin: objTransito.Fecha_Fin,

            EnTransito: idRegistroTransito,
          }

          arregloDeDias.push(aux);
        }

        // console.log("Arreglo de dias");
        // console.log(arregloDeDias);

        ////////////////////////////////////

        const objIngresoDias = {
          fechaInicio: objTransito.Fecha_Inicio,
          fechaFin: objTransito.Fecha_Fin,
          arregloDeDias,
        };

        // console.log("OBJETO INGRESO DE DIAS");
        // console.log(objIngresoDias);

        const url = `${API}/IngresoDeDias/${objTransito.IdTrabajador}`;
        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objIngresoDias),
        });

        const response = await request.json();
        const status = await request.status;
        const envioCorreo = flagCorreo;


        switch (status) {

          case 201:

            if (envioCorreo) {
              const url = `${API}/Enviar/Correo/EnTransito/${idRegistroTransito}`;
              await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              })
            }


            /////////////////////////////

            // Swal.fire({
            //   title: 'Exitoso',
            //   text: 'Se ingresaron exitosamente los días de ausencia',
            //   icon: 'success',
            //   confirmButtonColor: "#E60AA3",
            //   confirmButtonText: "Continuar",
            // });

            return true;
          case 400:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            return false;
          case 500:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            return false;
          default:
            Swal.fire({
              title: "Error",
              text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
              icon: "error",
            });
            return false;
        }

      /////////////////////////////

      case 400:
        Swal.fire({
          title: "Error",
          text: responseTransito.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR,
          payload: responseTransito.err.message,
        });
        return false;
      case 409:
        Swal.fire({
          title: "Ups!",
          text: 'No puedes registrar tránsitos en días con asistencia ya confirmada.',
          icon: "error",
        });

        dispatch({
          type: ERROR,
          payload: 'No puedes registrar tránsitos en días con asistencia ya confirmada.',
        });

        return false;
      case 500:

        Swal.fire({
          title: "Error",
          text: responseTransito.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR,
          payload: responseTransito.err.message,
        });
        return false;
      case 420:

        Swal.fire({
          title: "Uups",
          text: "No se pueden asignar días de tránsito en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({ type: ERROR, payload: "No se pueden asignar días de tránsito en un periodo que se encuentra cerrado" });

        return false;
      default:
        dispatch({
          type: ERROR,
          payload: 'No puedes registrar tránsito en días con asistencia ya confirmada.',
        });
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        return false;
    }

  } catch (error) {

    dispatch({
      type: ERROR,
      payload: error.message,
    });

  }

};

export const ingresarLicenciaActions = (objLicencia, flagCorreo) => async (dispatch) => {

  dispatch({
    type: LOADING,
  });

  try {

    const urlLicencia = `${API}/Licencia/Avanzado`
    const requestLicencia = await fetch(urlLicencia, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objLicencia),
    });

    const responseLicencia = await requestLicencia.json();
    const statusLicencia = await requestLicencia.status;

    switch (statusLicencia) {

      case 201:

        let idRegistroLicencia = responseLicencia.data._id;
        let fechaInicioLicencia = moment.utc(objLicencia.Fecha_Inicio);
        let fechaFinLicencia = moment.utc(objLicencia.Fecha_Fin);

        let cantidadDias = fechaFinLicencia.diff(fechaInicioLicencia, "days") + 1;

        let arregloDeDias = [];

        for (let index = 0; index < cantidadDias; index++) {

          let fecha = fechaInicioLicencia;
          fecha = fecha.add(index === 0 ? 0 : 1, "day");

          const aux = {

            // Fecha: obtenerFechaFormateadaMismaHora(fecha.set({ hour: 12 }), databaseFormat),
            // FechaUnix: momentToUnixFecha(fecha, databaseFormat),

            Fecha: convertirFechaString(fecha, 1).fechaString,
            FechaUnix: convertirFechaString(fecha, 1).fechaUNIX,

            IdTrabajador: objLicencia.Trabajador,
            TipoRegistroBitacoraTrabajador: "5f3eebf46dd7cc57f0d3a25d",

            InicioTrabajo: index === 0 ? true : false,
            FinTrabajo: index === cantidadDias - 1 ? true : false,
            Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,

            // Fecha_Inicio: momentToStringFechaInicio(moment.utc(objLicencia.Fecha_Inicio).set({ hour: 12 }), databaseFormat),
            // Fecha_Fin: momentToStringFechaFin(moment.utc(objLicencia.Fecha_Fin).set({ hour: 12 }), databaseFormat),

            Fecha_Inicio: objLicencia.Fecha_Inicio,
            Fecha_Fin: objLicencia.Fecha_Fin,

            Licencia: idRegistroLicencia,
          }

          arregloDeDias.push(aux);
        }

        ////////////////////////////////////

        const objIngresoDias = {
          fechaInicio: objLicencia.Fecha_Inicio,
          fechaFin: objLicencia.Fecha_Fin,
          arregloDeDias,
        };

        const url = `${API}/IngresoDeDias/${objLicencia.Trabajador}`;
        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objIngresoDias),
        });

        const response = await request.json();
        const status = await request.status;
        const envioCorreo = flagCorreo;


        switch (status) {

          case 201:

            if (envioCorreo) {
              const url = `${API}/Enviar/Correo/Licencia/${idRegistroLicencia}`;
              await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              })
            }


            /////////////////////////////

            // Swal.fire({
            //   title: 'Exitoso',
            //   text: 'Se ingresaron exitosamente los días de ausencia',
            //   icon: 'success',
            //   confirmButtonColor: "#E60AA3",
            //   confirmButtonText: "Continuar",
            // });

            return true;
          case 400:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            return false;
          case 500:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            return false;
          default:
            Swal.fire({
              title: "Error",
              text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
              icon: "error",
            });
            return false;
        }

      /////////////////////////////

      case 400:
        Swal.fire({
          title: "Error",
          text: responseLicencia.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR,
          payload: responseLicencia.err.message,
        });
        return false;
      case 409:
        Swal.fire({
          title: "Ups!",
          text: 'No puedes registrar licencias en días con asistencia ya confirmada.',
          icon: "error",
        });

        dispatch({
          type: ERROR,
          payload: 'No puedes registrar licencias en días con asistencia ya confirmada.',
        });

        return false;
      case 500:
        Swal.fire({
          title: "Error",
          text: responseLicencia.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR,
          payload: responseLicencia.err.message,
        });
        return false;
      case 420:

        Swal.fire({
          title: "Uups",
          text: "No se pueden asignar días de licencia en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({ type: ERROR, payload: "No se pueden asignar días de licencia en un periodo que se encuentra cerrado" });

        return false;
      default:
        dispatch({
          type: ERROR,
          payload: 'No puedes registrar licencias en días con asistencia ya confirmada.',
        });
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        return false;
    }

  } catch (error) {

    dispatch({
      type: ERROR,
      payload: error.message,
    });

  }

};

export const ingresarMutualActions = (objMutual, flagCorreo) => async (dispatch) => {

  dispatch({
    type: LOADING,
  });

  try {

    const urlMutual = `${API}/AccidenteLaboral/Avanzado`
    const requestMutual = await fetch(urlMutual, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objMutual),
    });

    const responseMutual = await requestMutual.json();
    const statusMutual = await requestMutual.status;

    switch (statusMutual) {


      case 201:

        let idRegistroMutual = responseMutual.data._id;
        let fechaInicioMutual = moment.utc(objMutual.Fecha_Inicio);
        let fechaFinMutual = moment.utc(objMutual.Fecha_Fin);

        let cantidadDias = fechaFinMutual.diff(fechaInicioMutual, "days") + 1;

        let arregloDeDias = [];

        for (let index = 0; index < cantidadDias; index++) {

          let fecha = fechaInicioMutual;
          fecha = fecha.add(index === 0 ? 0 : 1, "day");

          const aux = {

            // Fecha: obtenerFechaFormateadaMismaHora(fecha.set({ hour: 12 }), databaseFormat),
            // FechaUnix: momentToUnixFecha(fecha, databaseFormat),

            Fecha: convertirFechaString(fecha, 1).fechaString,
            FechaUnix: convertirFechaString(fecha, 1).fechaUNIX,

            IdTrabajador: objMutual.IdTrabajador,
            TipoRegistroBitacoraTrabajador: "5f3eec676dd7cc57f0d3a25e",

            InicioTrabajo: index === 0 ? true : false,
            FinTrabajo: index === cantidadDias - 1 ? true : false,
            Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,

            // Fecha_Inicio: momentToStringFechaInicio(moment.utc(objMutual.Fecha_Inicio).set({ hour: 12 }), databaseFormat),
            // Fecha_Fin: momentToStringFechaFin(moment.utc(objMutual.Fecha_Fin).set({ hour: 12 }), databaseFormat),

            Fecha_Inicio: objMutual.Fecha_Inicio,
            Fecha_Fin: objMutual.Fecha_Fin,

            Accidentes: idRegistroMutual,
          }

          arregloDeDias.push(aux);
        }

        ////////////////////////////////////

        // console.log("Arreglo de dias mutual");
        // console.log(arregloDeDias);

        const objIngresoDias = {
          fechaInicio: objMutual.Fecha_Inicio,
          fechaFin: objMutual.Fecha_Fin,
          arregloDeDias,
        };

        const url = `${API}/IngresoDeDias/${objMutual.IdTrabajador}`;
        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objIngresoDias),
        });

        const response = await request.json();
        const status = await request.status;
        const envioCorreo = flagCorreo;


        switch (status) {

          case 201:

            if (envioCorreo) {
              const url = `${API}/Enviar/Correo/Accidente/${idRegistroMutual}`;
              await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              })
            }


            /////////////////////////////

            // Swal.fire({
            //   title: 'Exitoso',
            //   text: 'Se ingresaron exitosamente los días de ausencia',
            //   icon: 'success',
            //   confirmButtonColor: "#E60AA3",
            //   confirmButtonText: "Continuar",
            // });

            return true;
          case 400:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            return false;
          case 500:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            return false;
          default:
            Swal.fire({
              title: "Error",
              text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
              icon: "error",
            });
            return false;
        }

      /////////////////////////////

      case 400:
        Swal.fire({
          title: "Error",
          text: responseMutual.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR,
          payload: responseMutual.err.message,
        });
        return false;
      case 409:
        Swal.fire({
          title: "Ups!",
          text: 'No puedes registrar licencias de la mutual en días con asistencia ya confirmada.',
          icon: "error",
        });

        dispatch({
          type: ERROR,
          payload: 'No puedes registrar licencias de la mutual en días con asistencia ya confirmada.',
        });

        return false;
      case 500:

        Swal.fire({
          title: "Error",
          text: responseMutual.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR,
          payload: responseMutual.err.message,
        });
        return false;
      case 420:

        Swal.fire({
          title: "Uups",
          text: "No se pueden asignar días de licencia mutual en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({ type: ERROR, payload: "No se pueden asignar días de licencia mutual en un periodo que se encuentra cerrado" });

        return false;
      default:
        dispatch({
          type: ERROR,
          payload: 'No puedes registrar licencias de la mutual en días con asistencia ya confirmada.',
        });
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        return false;
    }

  } catch (error) {

    dispatch({
      type: ERROR,
      payload: error.message,
    });

  }

};

//
export const GenerarCheckOutMultiple = (
  arregloPersonas, 
  idEmpresa,
  idCentroCosto_E,
  fechaInicio_E,
  fechaFin_E,
  inicioUnix,
  finUnix,
  ) => async (dispatch) =>{
    dispatch({ type: LOADING });
    try {

      let urlBitacora = `${API}/BitacoraTrabajadoresCheckOutMasivo`;
      let dataBitacora;
      const responseBitacora = await fetch(urlBitacora, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          mode: "no-cors",
        },
        body: JSON.stringify(arregloPersonas),
      });

      const statusBitacora = responseBitacora.status;
      //return
      switch (statusBitacora) {
        case 200:
        case 201:
              let urlBuscarTrabajadoresReady;
              if (idCentroCosto_E != null && idCentroCosto_E !== "") {
                urlBuscarTrabajadoresReady = `${API}/Ckeck-out/Trabajadores?centroDeCosto=${idCentroCosto_E}&idEmpresa=${idEmpresa}`;
              } else {
                urlBuscarTrabajadoresReady = `${API}/Ckeck-out/Trabajadores?idEmpresa=${idEmpresa}`;
              }
              let fecha_ini;
              let fecha_fin;
              let strReady = moment().format('yyyy/MM/DD');
              if (fechaInicio_E !== "" && fechaFin_E !== "") {
                fecha_ini = fechaInicio_E == "" || fechaInicio_E == null ? convertirFechaString(moment(strReady), 1).fechaString : fechaInicio_E;
                fecha_fin = fechaFin_E == "" || fechaFin_E == null ? convertirFechaString(moment(strReady), 2).fechaString : fechaFin_E;
              }
              const objRangoFechaReady = {
                fecha_inicio: fechaInicio_E,
                fecha_fin: fechaFin_E
              };
    
              const responseBuscarTrabajadoresReady = await fetch(urlBuscarTrabajadoresReady, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  mode: "no-cors",
                },
                body: JSON.stringify(objRangoFechaReady),
              });
    
              const statusBuscarTrabajadoresReady = responseBuscarTrabajadoresReady.status;
    
              let BuscarTrabajadoresReady = null;
              switch (statusBuscarTrabajadoresReady) {
                case 200:
                case 201:
    
                  BuscarTrabajadoresReady = await responseBuscarTrabajadoresReady.json();
    
                  let dataOrdenar = BuscarTrabajadoresReady;
    
                  const objCheckInCheckOut = generarOrdenCheckInCheckOut(
                    dataOrdenar,
                    fechaInicio_E,
                    fechaFin_E
                  );
                  
                  if (statusBuscarTrabajadoresReady === 200 || statusBuscarTrabajadoresReady === 201) {
    
                    Swal.fire({
                      title: "Bien",
                      text: "Se realizó el Check-Out Masivo",
                      icon: "success",
                      confirmButtonColor: "#E60AA3",
                      confirmButtonText: "Continuar",
                    });
                    dispatch({
                      type: OBTENER_TRABAJADOR_ID_CENTROCOSTO,
                      payload: objCheckInCheckOut.arrayCheckOut,
                    });
          
                  } 
               
                  break;
                default:
                  Swal.fire(
                    "Error",
                    `Error al realizar la petición. Al buscar trabajadores, Status: ${statusBuscarTrabajadoresReady}`,
                    "error"
                  );
                  dispatch({ type: ERROR, payload: dataBitacora.err });
                  break;
              }
              break;
        default:
               Swal.fire(
                "Error",
                `Error al realizar el checkout, Status: ${statusBitacora}`,
                "error"
              );
              dispatch({ type: ERROR, payload: dataBitacora.err });
          break;
      }

      
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: error.message,
      });
    }
};

//

/////////////////////////////////////////////////////////////////////////////////////////////////



const generarOrdenCheckInCheckOut = (checkin, fecha_inicio, fecha_fin) => {
  const yaCubiertas = "5f3ef6981ecf531c1c8ab4aa";
  const enCurso = "5f3ef6861ecf531c1c8ab4a9";
  const reservada = "5f3ef6591ecf531c1c8ab4a8";
  //const sinCubrir = "5f3ef5ce1ecf531c1c8ab4a7";

  // const fecha_inicio_p = moment(fecha_inicio).format("YYYY-MM-DD");
  // const fecha_fin_p = moment(fecha_fin).format("YYYY-MM-DD");

  const fecha_inicio_p = moment.utc(fecha_inicio);
  const fecha_fin_p = moment.utc(fecha_fin);

  let arrayCheckIn = [];
  let arrayCheckOut = [];
  
  //let listadosCheks = checkin;
  let listadosCheks = checkin.data;

   ///api nueva
   listadosCheks.forEach((check) => {
    if (check.Estado !== undefined && check.Estado !== null) {
      let fechaDesde = moment.utc(check.Fecha);
      if (fechaDesde.isBetween(fecha_inicio_p, fecha_fin_p, null, "[]")) {
        if (
          check.Inicio_Planificacion !== undefined &&
          check.Inicio_Planificacion !== "undefined" &&
          check.Fin_Planificacion !== undefined &&
          check.Fin_Planificacion !== "undefined"
        ) {
          if (
            check.Estado === yaCubiertas ||
            check.Estado === enCurso
          ) {
            let botonHabilitado = check.Estado === enCurso ? true : false;
            check.botonHabilitado = botonHabilitado;
            if (check.SeEnvioCheckOut === null ) {
              arrayCheckOut.push(check);
            }
          }
        }
      }
    }
  });
  ////
  ///vieja API se comento el 31-01-22
  // listadosCheks.forEach((check) => {
  //   if (check.Estado !== undefined && check.Estado !== null) {

  //     // let fecha = moment(check.Fecha).format("YYYY-MM-DD");
  //     let fecha = moment.utc(check.Fecha);

  //     // if (moment(fecha).isBetween(fecha_inicio_p, fecha_fin_p, null, "[]")) {
  //     if (fecha.isBetween(fecha_inicio_p, fecha_fin_p, null, "[]")) {
  //       if (
  //         check.Inicio_Planificacion !== undefined &&
  //         check.Inicio_Planificacion !== "undefined" &&
  //         check.Fin_Planificacion !== undefined &&
  //         check.Fin_Planificacion !== "undefined"
  //       ) {
  //         //let cantidad de entradas

  //         if (check.Estado._id === reservada) {
  //           // if (moment(check.Inicio_Planificacion).utc().format("YYYY-MM-DD") === moment(check.Fecha).utc().format("YYYY-MM-DD")) {
  //           if (moment.utc(check.Inicio_Planificacion).isSame(moment.utc(check.Fecha))) {
  //             check.fechaFormateada = moment.utc(check.Fecha).format("DD-MM-YYYY");
  //             check.FechaBusqueda = moment.utc(check.Fecha).format("YYYY-MM-DD");
  //             arrayCheckIn.push(check);
  //           }
  //         }

  //         if (check.Estado._id === yaCubiertas || check.Estado._id === enCurso) {
  //           // if (moment(check.Fin_Planificacion).utc().format("YYYY-MM-DD") === moment(check.Fecha).utc().format("YYYY-MM-DD") && check.SeEnvioCheckOut !== true) {
  //           if (moment.utc(check.Fin_Planificacion).isSame(moment.utc(check.Fecha)) && check.SeEnvioCheckOut !== true) {
  //             let botonHabilitado = check.Estado._id === "5f3ef6861ecf531c1c8ab4a9" ? true : false;
  //             check.botonHabilitado = botonHabilitado;

  //             // POSIBLEMENTE EN ESTE IF SE CORRE LA HORA
  //             // let fecha = moment(check.Fecha).format("DD-MM-YYYY");

  //             check.fechaFormateada = moment.utc(check.Fecha).format("DD-MM-YYYY");
  //             check.FechaBusqueda = moment.utc(check.Fecha).format("YYYY-MM-DD");
  //             arrayCheckOut.push(check);
  //           }
  //         }
  //       }

  //       // //validar si es un check in
  //       // if (check.Estado._id === reservada) {
  //       //   let fechaAteriorBuscar = moment(fecha)
  //       //     .add(-1, "day")
  //       //     .format("YYYY-MM-DD");
  //       //   if (check.IdFaena !== null && check.IdTrabajador !== null) {
  //       //     let idFaenaBuscar = check.IdFaena._id;
  //       //     let idPlazaBuscar = check.IdPlaza;
  //       //     let idTrabajadorBuscar = check.IdTrabajador._id;

  //       //     try {
  //       //       let resultadoBusqueda = listadosCheks.filter(
  //       //         (checkBusqueda) =>
  //       //           moment(checkBusqueda.Fecha).format("YYYY-MM-DD") ===
  //       //             fechaAteriorBuscar &&
  //       //           checkBusqueda.IdFaena !== undefined &&
  //       //           checkBusqueda.IdFaena !== "undefined" &&
  //       //           checkBusqueda.IdFaena !== null &&
  //       //           checkBusqueda.IdFaena !== "null" &&
  //       //           checkBusqueda.IdTrabajador._id === idTrabajadorBuscar &&
  //       //           checkBusqueda.IdFaena._id === idFaenaBuscar &&
  //       //           checkBusqueda.IdPlaza === idPlazaBuscar &&
  //       //           checkBusqueda.Estado !== undefined &&
  //       //           checkBusqueda.Estado !== "undefined" &&
  //       //           checkBusqueda.Estado._id !== yaCubiertas
  //       //       );
  //       //       //no se encontraron registros anteriores por lo tanto es el primer registro para checkin
  //       //       if (resultadoBusqueda.length === 0) {
  //       // check.fechaFormateada = moment(check.Fecha).format(
  //       //   "DD-MM-YYYY"
  //       // );
  //       // check.FechaBusqueda = moment(check.Fecha).format("YYYY-MM-DD");
  //       //         arrayCheckIn.push(check);
  //       //       }
  //       //     } catch (err) {}
  //       //   }
  //       // }

  //       // //validar si es checkout
  //       // if (check.Estado._id === yaCubiertas || check.Estado._id === enCurso) {
  //       //   if (check.SeEnvioCheckOut !== true) {
  //       //     let fechaPosteriorBuscar = moment(fecha)
  //       //       .add(1, "day")
  //       //       .format("YYYY-MM-DD");
  //       //     if (check.IdFaena !== null && check.IdTrabajador !== null) {
  //       //       let idFaenaBuscar = check.IdFaena._id;
  //       //       let idPlazaBuscar = check.IdPlaza;
  //       //       let idTrabajadorBuscar = check.IdTrabajador._id;

  //       //       let resultadoBusqueda = listadosCheks.filter(
  //       //         (checkBusqueda) =>
  //       //           moment(checkBusqueda.Fecha).format("YYYY-MM-DD") ===
  //       //             fechaPosteriorBuscar &&
  //       //           checkBusqueda.IdFaena !== undefined &&
  //       //           checkBusqueda.IdFaena !== "undefined" &&
  //       //           checkBusqueda.IdFaena !== null &&
  //       //           checkBusqueda.IdFaena !== "null" &&
  //       //           checkBusqueda.IdFaena._id === idFaenaBuscar &&
  //       //           checkBusqueda.IdPlaza === idPlazaBuscar &&
  //       //           checkBusqueda.IdTrabajador._id === idTrabajadorBuscar &&
  //       //           checkBusqueda.Estado !== undefined &&
  //       //           checkBusqueda.Estado._id !== reservada
  //       //       );

  //       //no se encontraron registros anteriores por lo tanto es el primer registro para checkin
  //       //   if (resultadoBusqueda.length === 0) {
  //       //     let botonHabilitado =
  //       //       check.Estado._id === "5f3ef6861ecf531c1c8ab4a9"
  //       //         ? true
  //       //         : false;
  //       //     check.botonHabilitado = botonHabilitado;
  //       //     let fecha = moment(check.Fecha).format("DD-MM-YYYY HH:MM");
  //       //     check.fechaFormateada = fecha;
  //       //     check.FechaBusqueda = moment(check.Fecha).format("YYYY-MM-DD");
  //       //     arrayCheckOut.push(check);
  //       //   }
  //       // }
  //       //   }
  //       // }
  //     }
  //   }
  // });
  const objCheckInCheckOut = {
    arrayCheckIn,
    arrayCheckOut,
  };
  return objCheckInCheckOut;
};

const generarOrdenCheckInCheckOutUnix = (
  checkin,
  fecha_inicio,
  fecha_fin,
  inicioUnix,
  finUnix
) => {
  const yaCubiertas = "5f3ef6981ecf531c1c8ab4aa";
  const enCurso = "5f3ef6861ecf531c1c8ab4a9";

  // const fecha_inicio_p = moment.unix(inicioUnix);
  // const fecha_fin_p = moment.unix(finUnix);

  const fecha_inicio_p = moment.utc(fecha_inicio);
  const fecha_fin_p = moment.utc(fecha_fin);

  let arrayCheckOut = [];

  let listadosCheks = checkin;
  /////////////////////////////////////////
  ///api nueva
  listadosCheks.forEach((check) => {
    if (check.Estado !== undefined && check.Estado !== null) {
      
      let fechaDesde = moment.utc(check.Fecha);

      if (fechaDesde.isBetween(fecha_inicio_p, fecha_fin_p, null, "[]")) {
        if (
          check.Inicio_Planificacion !== undefined &&
          check.Inicio_Planificacion !== "undefined" &&
          check.Fin_Planificacion !== undefined &&
          check.Fin_Planificacion !== "undefined"
        ) {
          if (
            check.Estado === yaCubiertas ||
            check.Estado === enCurso
          ) {
            
            let botonHabilitado = check.Estado === enCurso ? true : false;
            check.botonHabilitado = botonHabilitado;

            if (check.SeEnvioCheckOut === null ) {
              arrayCheckOut.push(check);
            }

          }
        }
      }
    }
  });



  ////////////////////////////////////////
  //api antigua
  // listadosCheks.forEach((check) => {
  //   if (check.Estado !== undefined && check.Estado !== null) {
  //     let fechaDesde = moment.utc(check.Fecha);

  //     if (fechaDesde.isBetween(fecha_inicio_p, fecha_fin_p, null, "[]")) {
  //       if (
  //         check.Inicio_Planificacion !== undefined &&
  //         check.Inicio_Planificacion !== "undefined" &&
  //         check.Fin_Planificacion !== undefined &&
  //         check.Fin_Planificacion !== "undefined"
  //       ) {
  //         if (
  //           check.Estado._id === yaCubiertas ||
  //           check.Estado._id === enCurso
  //         ) {
  //           // if (moment.unix(check.Fin_Planificacion_Unix).format("YYYY-MM-DD") === moment.unix(check.FechaUnix).format("YYYY-MM-DD") && check.SeEnvioCheckOut !== true) {
  //           if (moment.utc(check.Fin_Planificacion).startOf('day').isSame(fechaDesde) && check.SeEnvioCheckOut !== true) {
  //             let botonHabilitado = check.Estado._id === enCurso ? true : false;
  //             check.botonHabilitado = botonHabilitado;

  //             // check.fechaFormateada = moment.unix(check.FechaUnix).set({ hour: 23, minute: 59, second: 59, millisecond: 59 }).format("DD-MM-YYYY");
  //             // check.FechaBusqueda = moment.unix(check.FechaUnix).format("YYYY-MM-DD");

  //             check.fechaFormateada = moment.utc(check.Fecha).format("DD-MM-YYYY");
  //             check.FechaBusqueda = moment.utc(check.Fecha).format("YYYY-MM-DD");

  //             arrayCheckOut.push(check);
  //           }
  //         }
  //       }
  //     }
  //   }
  // });

  //console.log(arrayCheckOut,"fin fin fin");
  return arrayCheckOut;
};

const ordernarPeriodosTrabajo = (data, fechaPlanificacion) => {
  let detalleBitacoraTrabajador = [];
  let bitacorasTrabajo = [];
  let bitacorasProcesos = [];

  let proximoDiaPlanificado = false;

  data.forEach((bitacora) => {
    if (
      bitacora.Fecha_Inicio !== undefined &&
      bitacora.Fecha_Inicio !== "undefined"
    ) {
      // let cantidadEncontrada = bitacorasProcesos.filter(
      //   (proceso) =>
      //     moment(proceso.Fecha_Inicio).format("YYYY-MM-DD") ===
      //     moment(bitacora.Fecha_Inicio).format("YYYY-MM-DD") &&
      //     moment(proceso.Fecha_Fin).format("YYYY-MM-DD") ===
      //     moment(bitacora.Fecha_Fin).format("YYYY-MM-DD") &&
      //     proceso.TipoRegistroBitacoraTrabajador.toString() ===
      //     bitacora.TipoRegistroBitacoraTrabajador.toString()
      // );

      let cantidadEncontrada = bitacorasProcesos.filter(
        (proceso) =>
          moment.utc(proceso.Fecha_Inicio).format("YYYY-MM-DD").isSame(moment.utc(bitacora.Fecha_Inicio).format("YYYY-MM-DD")) &&
          moment.utc(proceso.Fecha_Fin).format("YYYY-MM-DD").isSame(moment.utc(bitacora.Fecha_Fin).format("YYYY-MM-DD")) &&
          proceso.TipoRegistroBitacoraTrabajador.toString() === bitacora.TipoRegistroBitacoraTrabajador.toString()
      );
      if (cantidadEncontrada.length === 0) {
        const dataVisualizar = {
          fecha_inicio: convertirFechaString(moment.utc(bitacora.Fecha_Inicio), 1).fechaDDMMYYYY,
          fecha_fin: convertirFechaString(moment.utc(bitacora.Fecha_Fin), 2).fechaDDMMYYYY,
          tipoBitacora: bitacora.TipoRegistroBitacoraTrabajador,
        };

        detalleBitacoraTrabajador.push(dataVisualizar);
        bitacorasProcesos.push(bitacora);
      }
    } else {
      // let cantidadEncontrada = bitacorasTrabajo.filter(
      //   (proceso) =>
      //     moment(proceso.Inicio_Planificacion).format("YYYY-MM-DD") ===
      //     moment(bitacora.Inicio_Planificacion).format("YYYY-MM-DD") &&
      //     moment(proceso.Fin_Planificacion).format("YYYY-MM-DD") ===
      //     moment(bitacora.Fin_Planificacion).format("YYYY-MM-DD") &&
      //     proceso.IdPlaza.toString() === bitacora.IdPlaza.toString() &&
      //     proceso.IdPuestoPlaza.toString() === bitacora.IdPuestoPlaza.toString()
      // );

      let cantidadEncontrada = bitacorasTrabajo.filter(
        (proceso) =>
          moment.utc(proceso.Inicio_Planificacion).format("YYYY-MM-DD").isSame(moment.utc(bitacora.Inicio_Planificacion).format("YYYY-MM-DD")) &&
          moment.utc(proceso.Fin_Planificacion).format("YYYY-MM-DD").isSame(moment.utc(bitacora.Fin_Planificacion).format("YYYY-MM-DD")) &&
          proceso.IdPlaza.toString() === bitacora.IdPlaza.toString() &&
          proceso.IdPuestoPlaza.toString() === bitacora.IdPuestoPlaza.toString()
      );

      if (cantidadEncontrada.length === 0) {
        const dataVisualizar = {
          fecha_inicio: convertirFechaString(moment.utc(bitacora.Inicio_Planificacion), 1).fechaDDMMYYYY,
          fecha_fin: convertirFechaString(moment.utc(bitacora.Fin_Planificacion), 2).fechaDDMMYYYY,
          tipoBitacora: bitacora.TipoRegistroBitacoraTrabajador,
        };
        detalleBitacoraTrabajador.push(dataVisualizar);
        bitacorasTrabajo.push(bitacora);
      }
    }
  });


  const objetoRetornar = {
    proximoDiaPlanificado,
    detalleBitacoraTrabajador,
    bitacoraCompletaTrabajador: data,
  }
  
  return objetoRetornar;
};



// NO DISPONIBLE
// export const actualizarBitacorasParaEmbarco = (
//   bitacora,
//   faena,
//   idCentroCosto_E,
//   fechaInicio_E,
//   fechaFin_E,
//   idEmpresa,
//   inicioUnix,
//   finUnix
// ) => async (dispatch) => {
//   dispatch({ type: LOADING });

//   try {
//     const url = `${API}/Faena/checkIn/Trabajador`;

//     const response = await fetch(url, {
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         mode: "no-cors",
//       },
//       body: JSON.stringify(bitacora),
//     });

//     const status = response.status;
//     let data = null;

//     switch (status) {
//       case 200:
//       case 201:
//         const urlCorreo = `${API}/Enviar/Correo/CheckIn/${bitacora.IdTrabajador._id}`;

//         let Plaza = {};

//         Plaza.centroCosto = bitacora.IdFaena.Centro_De_Costo.Nombre;
//         Plaza.faena = faena.Nombre;
//         Plaza.codigo = bitacora.IdTrabajador.Codigo_Base;

//         Plaza.fechaInicio = bitacora.Inicio_Planificacion;
//         Plaza.fechaFin = bitacora.Fin_Planificacion;

//         const responseCorreo = await fetch(urlCorreo, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             mode: "no-cors",
//           },
//           body: JSON.stringify(Plaza),
//         });

//         const statusCorreo = responseCorreo.status;
//         let dataCorreo = null;

//         switch (statusCorreo) {
//           case 200:
//           case 201:
//             let urlBuscarTrabajadores;

//             if (idCentroCosto_E != null && idCentroCosto_E !== "") {
//               urlBuscarTrabajadores = `${API}/CheckIn_CheckOut/Trabajadores?centroDeCosto=${idCentroCosto_E}&idEmpresa=${idEmpresa}`;
//             } else {
//               urlBuscarTrabajadores = `${API}/CheckIn_CheckOut/Trabajadores?idEmpresa=${idEmpresa}`;
//             }

//             let fecha_ini;
//             let fecha_fin;

//             if (fechaInicio_E !== "" && fechaFin_E !== "") {
//               fecha_ini =
//                 fechaInicio_E == "" || fechaInicio_E == null
//                   ? momentToStringFechaInicio(moment(), databaseFormat)
//                   : fechaInicio_E;

//               fecha_fin =
//                 fechaFin_E == "" || fechaFin_E == null
//                   ? momentToStringFechaFin(moment(), databaseFormat)
//                   : fechaFin_E;
//             }

//             const objRangoFecha = {
//               fecha_inicio: fechaInicio_E,
//               fecha_fin: fechaFin_E,
//             };

//             const responseBuscarTrabajadores = await fetch(
//               urlBuscarTrabajadores,
//               {
//                 method: "POST",
//                 headers: {
//                   "Content-Type": "application/json",
//                   Accept: "application/json",
//                   mode: "no-cors",
//                 },
//                 body: JSON.stringify(objRangoFecha),
//               }
//             );

//             const statusBuscarTrabajadores = responseBuscarTrabajadores.status;
//             let BuscarTrabajadores = null;

//             switch (statusBuscarTrabajadores) {
//               case 200:
//               case 201:
//                 BuscarTrabajadores = await responseBuscarTrabajadores.json();

//                 let dataOrdenar = BuscarTrabajadores.data;

//                 const objCheckInCheckOut = generarOrdenCheckInCheckOutUnix(
//                   dataOrdenar,
//                   fecha_ini,
//                   fecha_fin,
//                   inicioUnix,
//                   finUnix
//                 );

//                 dispatch({
//                   type: OBTENER_TRABAJADOR_ID_CENTROCOSTO,
//                   payload: objCheckInCheckOut,
//                 });

//                 Swal.fire({
//                   title: "Bien",
//                   text:
//                     "Se realizó el Check-in: el trabajador está listo para marcar asistencia.",
//                   icon: "success",
//                   confirmButtonColor: "#E60AA3",
//                   confirmButtonText: "Continuar",
//                 });

//                 break;
//               case 404:
//                 dataCorreo = await response.json();

//                 Swal.fire("Error", "Servicio no encontrado", "error");
//                 dispatch({ type: ERROR, payload: dataCorreo.err });

//                 break;
//               default:
//                 dataCorreo = await response.json();

//                 Swal.fire(
//                   "Error",
//                   `Error al realizar la petición, Status: ${status}`,
//                   "error"
//                 );
//                 dispatch({ type: ERROR, payload: dataCorreo.err });
//                 break;
//             }

//             break;
//           case 404:
//             dataCorreo = await response.json();

//             Swal.fire("Error", "Servicio no encontrado", "error");
//             dispatch({ type: ERROR, payload: dataCorreo.err });

//             break;
//           default:
//             dataCorreo = await response.json();

//             Swal.fire(
//               "Error",
//               `Error al realizar la petición, Status: ${status}`,
//               "error"
//             );
//             dispatch({ type: ERROR, payload: dataCorreo.err });
//             break;
//         }

//         break;
//       case 404:
//         data = await response.json();

//         Swal.fire("Error", "Servicio no encontrado", "error");
//         dispatch({ type: ERROR, payload: data.err });
//         break;
//       default:
//         data = await response.json();

//         Swal.fire(
//           "Error",
//           `Error al realizar la petición, Status: ${status}`,
//           "error"
//         );
//         dispatch({ type: ERROR, payload: data.err });
//         break;
//     }
//   } catch (error) {
//     dispatch({ type: ERROR, payload: error });
//   }
// };