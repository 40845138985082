import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import "../../views/centroControl/CentroControl.css";
import API from "../../Redux/Api/api";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import "moment/locale/es";
import { convertirFechaString} from "../../funcionesGenericas/fechas";
import Swal from "sweetalert2";
import Loading from "../../components/loading/Loading";
import { EyeFilled } from "@ant-design/icons";
import Excel from "../../img/icons-sidebar/blancas/IconExcel.svg";

import {
    Row,
    Col,
    Card,
    Button,
    DatePicker,
    Badge,
    Modal,
    Table,
    Tabs,
    Empty,
    Input,
    AutoComplete,
    Select,
    Tooltip
} from "antd";

import { Pie } from '@ant-design/plots';
const { Search } = Input;
const { Column } = Table;
const { TabPane } = Tabs;
class DashboardEstadoCargo extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          dataGrafico:[],
          totalesGrafico: [],
          fechaSelecionada: moment().format('YYYY-MM-DD'),
          Empresa: '',
          modalDetalle:false,
          dataDetalle:[],
        //   cargoSeleccionado: "",
          centroDeCostoSeleccionado: "",
          config:  {
            padding: 10,
            appendPadding: 10,
            data:[],
            angleField: 'value',
            colorField: 'type',
            radius: 1,
            innerRadius: 0.6,
            //label: false,
            label: {
              type: 'inner',
              offset: '-50%',
              content: '{value}',
              style: {
                textAlign: 'center',
                fontSize: 14,
              },
            },
            legend:false,

            // colorField: 'type', // or seriesField in some cases
            // color: ({ type }) => {
            //     if(type === 'Contratos Plazo fijo'){
            //     return 'green';
            //     }
            //     if(type === 'Contratos Indefinido'){
            //         return 'blue';
            //     }
            //     if(type === 'Contratos Plazo fijo Relevo'){
            //         return 'purple';
            //     }
            //     if(type === 'Contratos Indefinido Relevo'){
            //         return 'purple';
            //     }

            //     return 'red';

            // }

            // label: {
            //   type: 'inner',
            //   offset: '-50%',
            //   content: '{value}',
            //   style: {
            //     textAlign: 'center',
            //     fontSize: 14,
            //   },
            // },
            // interactions: [
            //   {
            //     type: 'element-selected',
            //   },
            //   {
            //     type: 'element-active',
            //   },
            // ],
            // statistic: {
            //   title: false,
            //   content: {
            //     style: {
            //       whiteSpace: 'pre-wrap',
            //       overflow: 'hidden',
            //       textOverflow: 'ellipsis',
            //     },
            //     content: 'AntV\nG2Plot',
            //   },
            // },
          },
          trabajadoresCopia: undefined,
          indexActual: "1",
        };

    }

    componentDidMount = async () => {
      let usuario = localStorage.getItem("usuario-people-manage");
      if (
        usuario !== null &&
        usuario !== "" &&
        usuario !== undefined &&
        usuario !== "undefined"
      ) {

        usuario = JSON.parse(usuario);
        const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
        const str = moment().format('YYYY-MM-DD');
        const fechaActual = convertirFechaString(moment(str), 1).fechaString;
        const centroDeCosto = "";
        let dataEstadoCargo = await this.obtenerTrabajadoresContratos(idEmpresa,fechaActual,centroDeCosto);

        this.setState({
          // dataGrafico: dataEstadoCargo.data,
          // totalesGrafico: dataEstadoCargo.totales,
          dataGrafico: dataEstadoCargo === undefined ? [] : dataEstadoCargo.data,
          totalesGrafico: dataEstadoCargo === undefined ? [] : dataEstadoCargo.totales,
          Empresa: idEmpresa,
          indexActual: "1",
        });

      }
    };

    obtenerTrabajadoresContratos = async (idEmpresa, fechaHoy,centroDeCosto) => {
        try {
          const url = `${API}/ContratosPorTrabajadorNuevo`;

          let informacion = {
            idEmpresa,
            fechaHoy,
            centroDeCosto
          };
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(informacion),
          });

          const status = await response.status;
          const data = await response.json();

          // let respuesta;

          if (status === 200 || status === 201) {
              
            return data;

          } else {

            Swal.fire({
              icon: "error",
              title: "Uuups...",
              text: "No se pudo cargar la información.",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "OK",
            });
          }

          return;
        } catch (error) {
          let respuesta;

          respuesta = {
            status: 500,
            data: error,
          };

          return respuesta;
        }
    };

    setFecha = (fecha) => {
      let str = moment(fecha).format('YYYY-MM-DD')
      let fechaFormateada = convertirFechaString(moment(str), 1).fechaString
      
      this.setState({
          fechaSelecionada: fechaFormateada
      });
    };

    setCentroCosto = (centroCosto) => {
        this.setState({
            centroDeCostoSeleccionado: centroCosto
        });
    };

    setCargo = (cargo) => {
        this.setState({
            cargoSeleccionado: cargo
        });
    };

    filtrar = async(objeto) => {

      try {
        this.setState({ loading: true });
        const url = `${API}/ContratosPorTrabajadorNuevo`;
        const { Empresa } = this.state;
        const {fecha,centroCosto} = objeto;
        let objetoBody = {
          idEmpresa: Empresa,
          fechaHoy:fecha,
          centroDeCosto: centroCosto,
        };
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objetoBody),
        });

        const status = await response.status;
        const data = await response.json();

        if (status === 200 || status === 201) {
            
          this.setState({
              dataGrafico: data.data,
              totalesGrafico:data.totales,
              loading: false
          });
          //return data;

        } else {
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        // Swal.fire({
        //   icon: "error",
        //   title: "Uuups...",
        //   text: "No se pudo cargar la información.",
        //   confirmButtonColor: "#E60AA3",
        //   confirmButtonText: "OK",
        // });
      }
    };

    obtenerDetalleTrabajadoresContratos = async (idEmpresa, fechaHoy,centroDeCosto) => {
        try {
            const url = `${API}/ContratosPorTrabajadorNuevo/Detalle`;
            let informacion = {
              idEmpresa,
              fechaHoy,
              centroDeCosto
            };

            const response = await fetch(url, {
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify(informacion),
            });

            const status = response.status;
            let data;

            switch (status) {
                case 200:
                case 201:
                    data = await response.json();
                    let detalle = data.data;
                    
                    return detalle;
                default:
                    //const error = data.err.message.toString();
                    const error = "Error al cargar la tabla"
                    console.error(error);
                return [];
            }

        } catch (error) {
            console.error(error);
        }

        return [];

    };

    detalle = async () => {
      this.setState({ loading: true });
      const { Empresa, fechaSelecionada,centroDeCostoSeleccionado } = this.state;
      const idEmpresa = Empresa;
      const fechaActual = convertirFechaString(moment(fechaSelecionada), 1).fechaString;
      const centroDeCosto = centroDeCostoSeleccionado;

      const detalleEstadoCargo = await this.obtenerDetalleTrabajadoresContratos(idEmpresa,fechaActual,centroDeCosto);

      this.setState({
        loading:false,
        modalDetalle:true,
        dataDetalle: detalleEstadoCargo,
        indexActual: "1",
      });
    }

    //DESCARGABLE BUENO
    obtenerExcelDetalleEstadoCargo = async (
      informacionDescargar, subtitulo
    ) => {
      try {
        const url = `${API}/TrabajadoresPorCargo/DescargarExcel`;
        let dataArray = [];

        informacionDescargar.forEach(dataTrabajador => {
          dataArray.push(
            {
              NombreTratadoCompleto: dataTrabajador.Nombre_Completo,
              Tipo_Contrato: dataTrabajador.Tipo_Contrato_Esp
            }
          );
        });
        let objeto = {
          dataCargo: dataArray,
          subtitulo
        };

        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objeto),
        });

        const status = await response.status;
        const excel = await response.blob();

        if (status === 200 || status === 201) {
          const url = window.URL.createObjectURL(excel);
          const a = document.createElement("a");
          a.href = url;
          a.download = `formatoDetalleCostos.xlsx`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          Swal.fire({
            title: "Bien",
            text: "Se descargó excel correctamente.",
            icon: "success",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se pudo cargar la información.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }
    };

    //Search BTN
    search = (value, index) => {
      let busqueda = value.target.value;

      if (busqueda !== "") {
        busqueda = busqueda.toLowerCase();
        let datamodal = JSON.parse(JSON.stringify(this.state.dataDetalle));
        const trabajadores = datamodal[index].arregloUnificado;

        const trabajadoresFiltros = trabajadores.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(busqueda)
          )
        );

        // datamodal[index].arregloUnificado = trabajadoresFiltros;
        let newData = {
          arregloUnificado:  trabajadoresFiltros
        }

        this.setState({
          trabajadoresCopia: newData
        });

      } else {
        this.setState({ trabajadoresCopia: undefined });
      }
    };

    NuevaPestaña = (idUsuario) => {
      //window.open("/CalendarioTrabajador?Trabajador=" + idUsuario);
      window.open("/CalendarioTrabajadorNew?Trabajador=" + idUsuario);
    };
  

    render() {
        let {
            cargosEmpresa,
            centrosCostoEmpresa
        } = this.props;
        let { dataGrafico, totalesGrafico, modalDetalle, dataDetalle, indexActual,trabajadoresCopia,loading} = this.state;

        const DemoPie = ({dataEspecifica}) => {

            let config = {...this.state.config};

            let newData = [];

            newData.push(
                {
                    type: 'Contratos Plazo fijo',
                    value: dataEspecifica.cantPlazofijo,
                },

                {
                    type: 'Contratos Indefinido',
                    value: dataEspecifica.cantIndefinidos,
                },
                {
                    type: 'Contratos Plazo fijo Relevo',
                    value: dataEspecifica.cantPlazofijoRelevos,
                },
                {
                    type: 'Contratos Indefinido Relevo',
                    value: dataEspecifica.cantIndefinidosRelevos,
                },
            )
            config.data = newData;

            return <Pie {...config} />;
        };

        const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 3000 },
              items: 6
            },
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 6
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1
            }
        };

      return (
        <>
          <Loading cargando={loading} />
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card
                  className="card-style-dashboard card-title-dashboard"
                  title={
                      <>
                      <Row>
                          <Col
                          xs={24}
                          sm={24}
                          md={20}
                          lg={20}
                          xl={20}
                          // style={{textAlign:"center"}}
                          >
                          Trabajadores por estado cargo
                          </Col>
                          <Col
                          xs={24}
                          sm={24}
                          md={4}
                          lg={4}
                          xl={4}
                          style={{textAlign:"right"}}
                          >
                          <Button
                              type="primary"
                              className="btn-detalle-dashboard"
                              onClick={() => {
                                  this.detalle()
                              }}
                          >
                              <span className="text-detalle-dashboard">Detalle</span>
                              <span><EyeFilled className="iconoBotonDetalleCurvaRelevos"/></span>
                          </Button>

                          </Col>
                      </Row>
                      </>
                  }
              >
                  {/* FILTROS */}
                  <div>
                    <p className={"label-filtros-dashboard"}>
                      Filtrar por:
                    </p>
                  </div>
                  <Row gutter={20} style={{ marginBottom: "20px" }}>
                      {/* RANGEPICKER */}
                      <Col
                        xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                        style={{ marginBottom: "15px" }}
                      >
                          <DatePicker
                            format={'DD-MM-YYYY'}
                            allowClear={false}
                            className={"select-style-dashboard"}
                            locale={locale}
                            picker="day"
                            defaultValue={moment()}
                            onChange={(fecha) => {
                                if (fecha == null) {
                                this.setFecha(moment());
                                } else {
                                this.setFecha(fecha);
                                }
                            }}
                          />
                      </Col>
                      {/* CENTROS DE COSTO */}
                      <Col
                          xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                          style={{ marginBottom: "15px" }}
                      >
                          <Select
                              allowClear
                              className={"select-style-dashboard"}
                              placeholder="Centro de costo"
                              onSelect={(idCentroCosto) => {
                                  this.setCentroCosto(idCentroCosto);
                              }}
                              onClear={() => {
                                  this.setCentroCosto("");
                              }}
                          >
                              {   
                                  centrosCostoEmpresa.map((value, index) => (
                                      <Select.Option key={index} value={value._id} name="CentroCosto">
                                          {value.Nombre}
                                      </Select.Option>
                                  ))
                              }
                  
                          </Select>
                      </Col>
                      {/* BTN FILTRAR */}
                      <Col
                          xs={24} s={24} sm={5} md={5} lg={5} xl={5} xxl={3}
                          className={"btn-col-filtrar-dashboard "}
                      >
                          <Button
                              type="primary"
                              htmlType="submit"
                              className={"btn-filtrar-dashboard"}
                              onClick={() => {
                                
                                const {
                                  fechaSelecionada,
                                  centroDeCostoSeleccionado,
                                } = this.state;
                                const objeto =
                                {
                                  fecha: fechaSelecionada,
                                  centroCosto: centroDeCostoSeleccionado,
                                }
                                this.filtrar(objeto);
                            }}
                          >
                              Filtrar
                          </Button>
                      </Col>

                  </Row>


                  {/* borrar algunas propiedades que no se van a usar */}
                  <Carousel
                      responsive={responsive}
                      // swipeable={false}
                      // draggable={false}
                      // showDots={true}
                      // ssr={true} // means to render carousel on server-side.
                      // infinite={true}
                      // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                      // autoPlaySpeed={1000}
                      // keyBoardControl={true}
                      // customTransition="all .5"
                      // transitionDuration={500}
                      // containerClass="carousel-container"
                      // removeArrowOnDeviceType={["tablet", "mobile"]}
                      // deviceType={this.props.deviceType}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                  >
                      {
                          dataGrafico.map((data)=>
                              // <div>
                                  <Col
                                    className={"col-chart-estado-cargo-dashboard"}
                                  >
                                      <h2
                                        className={"h2-title-estado-cargo-dashboard"}
                                      >
                                        <Tooltip title={data.nombre}>
                                          <span>{data.nombre}</span>
                                        </Tooltip>
                                        
                                      </h2>
                                      <DemoPie
                                        dataEspecifica ={data}
                                      />
                                  </Col>
                              // </div>
                          )
                      }

                  </Carousel>
          
                  <Row style={{marginTop:"30px"}}>
                  
                    <Card
                      // xs={24} sm={24} md={10} lg={10} xl={10}
                      className={"card-info-dashboard"}
                    >
                      <div>
                        <p className={"label-filtros-dashboard"}>
                          lenyenda:
                        </p>
                      </div>
                  
                      <Col span={24} style={{fontFamily:"MavenPro-SemiBold"}}> <Badge color={"#7C8DFC"} />Contratos Plazo Fijo</Col>
                      <Col span={24} style={{fontFamily:"MavenPro-SemiBold"}}> <Badge color={"#52D68F"} />Contratos Indefinido</Col>
                      <Col span={24} style={{fontFamily:"MavenPro-SemiBold"}}> <Badge color={"#595959"} />Contratos Plazo Fijo Relevo</Col>
                      <Col span={24} style={{fontFamily:"MavenPro-SemiBold"}}> <Badge color={"#FAD868"} />Contratos Indefinido Relevo</Col>
                  
                    </Card>
        
                    {/* <Col xs={24} sm={24} md={4} lg={4} xl={4}></Col> */}
                    <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
                    {
                      totalesGrafico.map((total)=>
                        <>
                          <Card
                            // xs={24} sm={24} md={10} lg={10} xl={10}
                            className={"card-info-dashboard"}
                          >
                            <div>
                              <p className={"label-filtros-dashboard"}>
                                Indicadores:
                              </p>
                            </div>
                            <Col span={24} style={{fontFamily:"MavenPro-SemiBold"}}> Total Cargo:{total.todosCargo} </Col>
                            <Col span={24} style={{fontFamily:"MavenPro-SemiBold"}}> Total Trabajadores:{total.todosTrabajadores} </Col>
                            <Col span={24} style={{fontFamily:"MavenPro-SemiBold"}}> Total Plazo Fijo: {total.todosTrabajadoresPlazofijo}</Col>
                            <Col span={24} style={{fontFamily:"MavenPro-SemiBold"}}> Total Indefinidos: {total.todosTrabajadoresIndefinidos}</Col>
                            <Col span={24} style={{fontFamily:"MavenPro-SemiBold"}}> Total Plazo Fijo Relevo: {total.todosTrabajadoresPlazofijoRelevos}</Col>
                            <Col span={24} style={{fontFamily:"MavenPro-SemiBold"}}> Total Indefinidos Relevo: {total.todosTrabajadoresIndefinidosRelevos}</Col>
                    
                          </Card>
                        </>
                      )
                    }
                  </Row>

              </Card>
          </Col>

          <Modal
              visible={modalDetalle}
              onCancel={() => this.setState({modalDetalle: false})}
              className={'modal-detalle-dashboard'}
              footer={null}
              closable={false}
              maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
          >
              <Row  style={{ marginBottom: '20px' }}>
                  <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
                      <h3
                          className="modal-h3-dashboard"
                      >
                          Trabajadores por estado cargo
                      </h3>
                  </Col>

                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{textAlign:"right"}}>
                          <Button
                              type="primary"
                              className={'modal-btn-excel-dashboard'}
                              onClick={()=>{
                                let { indexActual,dataDetalle } = this.state;
                                let index = parseInt(indexActual)-1;
                                
                                const dataDescargar =
                                dataDetalle[index].arregloUnificado !== null &&
                                dataDetalle[index].arregloUnificado !== 0 ?
                                dataDetalle[index].arregloUnificado : [];
                                this.obtenerExcelDetalleEstadoCargo(dataDescargar,dataDetalle[index].nombre);

                              }}
                          >
                              <span className="modal-btn-text-excel-dashboard">Descargar Excel</span>
                              <span><img src={Excel} className="modal-btn-icon-excel-dashboard" alt="Icono Excel"/></span>
                          </Button>
                  </Col>
              </Row>

              <hr className="modal-division-detalle-dashboard" />

              {/* TABLE */}
              <Row>
                <Col className="modal-tabla-detalle-dashboard" span={24}>
                  {/* <Tabs key={1} defaultActiveKey="1"> */}
                  <Tabs key={indexActual} defaultActiveKey={indexActual} activeKey={indexActual}  onTabClick={(e)=>{this.setState({ indexActual: e, trabajadoresCopia: undefined})}}>
                    {dataDetalle.length > 0 ? (
                      <>
                        {dataDetalle.map(
                          (valor, index) => (
                            <TabPane
                              tab={
                                valor.nombre +
                                ` (${valor.arregloUnificado.length})`
                              }
                              key={index + 1}
                            >
                              {valor.arregloUnificado.length > 0 ? (
                                <>
                                  <Row style={{ padding: "6px 0px 22px" }} align="bottom">
                                    <Col className="div-input-search" xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                      <Search
                                        placeholder="Buscar"
                                        enterButton
                                        onChange={(value) => this.search(value, index)}
                                      />
                                    </Col>
                                  </Row>

                                  <Table
                                    key={index + 1}
                                    rowKey="_id"
                                    dataSource={
                                      // dataDetalle[index] !== undefined ?
                                      // dataDetalle[index].arregloUnificado : valor.arregloUnificado
                                      trabajadoresCopia === undefined ?
                                        valor  !== undefined ?
                                        valor.arregloUnificado  : []
                                      :
                                      trabajadoresCopia.arregloUnificado
                                    }
                                    bordered={true}
                                    showSorterTooltip={false}
                                    scroll={{ x: true }}
                                    pagination={{ pageSize: 10 }}
                                    className={'modal-tabla-detalle-dashboard'}
                                    locale={{
                                      emptyText:
                                        "No se encontraron registros de la tabla.",
                                    }}
                                  >
                                      <Column
                                          align="center"
                                          title="Imagen"
                                          key="Imagen"
                                          render={(value) => {
                                              return (
                                              <img
                                                  src={value.Imagen}
                                                  className="modal-img-tabla-dashboard"
                                                  alt="i"
                                              />
                                              );
                                          }}
                                      />

                                      <Column
                                          sorter={(a, b) => {
                                              return a.Nombre.localeCompare(b.Nombre);
                                          }}
                                          title="Nombre"
                                          key="Nombre_Completo"
                                          render={(value) => {
                                              return `${value.Nombre_Completo}`;
                                          }}
                                      />
                                      {/* Tipo_Contrato */}

                                      <Column
                                          sorter={(a, b) => {
                                              return a.Tipo_Contrato.localeCompare(b.Tipo_Contrato);
                                          }}
                                          title="Tipo de contrato"
                                          key="Tipo_Contrato_Esp"
                                          render={(value) => {
                                              let tipoContrato =
                                              "Aun no se ingresa tipo contrato";
                                              if (
                                              value.Tipo_Contrato !== undefined &&
                                              value.Tipo_Contrato !== "undefined"
                                              ) {
                                                  tipoContrato = value.Tipo_Contrato_Esp;
                                              }

                                              return `${tipoContrato}`;
                                          }}
                                      />

                                      {/* Ficha trabajador */}
                                      <Column
                                        title="Detalle"
                                        key="FichaTrabajador"
                                        align="center"
                                        render={(value) => (
                                          <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                                            <Button
                                              onClick={() => {
                                                this.NuevaPestaña(value._id);
                                              }}
                                              className="acciones-trabajador-button hover-azul"
                                              icon={<EyeFilled style={{ fontSize: '18px' }} />}
                                            />
                                          </Tooltip>
                                        )}
                                      />

                                  </Table>
                                </>
                              ) : (
                                <>
                                  <Row>
                                    <Col span={24}>
                                      <Empty
                                        imageStyle={{
                                          height: 200,
                                          marginTop: "20px",
                                        }}
                                        description={
                                          <b
                                            className={
                                              "seleccion-trabajador-disponible"
                                            }
                                          >
                                            No se encontro información de este
                                            trabajador con este cargo.
                                          </b>
                                        }
                                      ></Empty>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </TabPane>
                          )
                        )}
                      </>
                    ) : (
                      <TabPane tab={"No se encontro información"} key={1}>
                        <>
                          <Row>
                            <Col span={24}>
                              <Empty
                                imageStyle={{
                                  height: 200,
                                  marginTop: "20px",
                                }}
                                description={
                                  <b
                                    className={
                                      "modal-empty-b-dashboard"
                                    }
                                  >
                                    No se encontró información de trabajadores.
                                  </b>
                                }
                              ></Empty>
                            </Col>
                          </Row>
                        </>
                      </TabPane>
                    )}
                  </Tabs>
                </Col>
              </Row>
          </Modal>
        </>
      );
    }
}

export default DashboardEstadoCargo;

