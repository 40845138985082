import React, { Component } from "react";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import cloneDeep from "lodash/cloneDeep";
import "../../../node_modules/react-calendar-timeline/lib/Timeline.css";
import "moment/locale/es";
import "./DetalleCostos.css";
import locale from "antd/es/date-picker/locale/es_ES";
import API from "../../Redux/Api/api";
import moment from "moment";
import {
  Layout,
  DatePicker,
  Col,
  Row,
  Button,
  Collapse,
  Modal,
  Table,
  PageHeader,
} from "antd";
import { Chart } from "@antv/g2";
import Swal from "sweetalert2";

//imagenes
import error_icon from "../../img/icons/error-equis.svg";
import check_icon from "../../img/icons/check-verde.svg";
import alerta_icon from "../../img/icons/alerta-amarilla.svg";
import vista from "../../img/icons/ojo-gris.png";

import { databaseFormat, momentToStringFechaInicio } from "../../funcionesGenericas/fechas"

//const { RangePicker } = DatePicker;
const { Header, Content } = Layout;
//const { Search } = Input;
const { Panel } = Collapse;
//const dateFormat = "DD/MM/YYYY HH:MM";
const { Column } = Table;

class DetalleCostos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //loading
      loading: false,

      //filtros de busqueda
      month: null,
      year: null,

      //sin data
      sinData: true,

      //renderiar vista
      vistaRender: false,

      //datos para guardar
      // incongruencias: [],
      // valoresCC: [],
      centrosDeCosto: [],
      //informacion de los graficos
      graficoCC: [],

      //Arreglo de registros para ser visualizados
      arregloRegistrosConError: [],
      arregloRegistrosNoEncontrados: [],
      arregloRegistrosOk: [],

      //modales
      ModalErrores: false,
      ModalNoData: false,
      ModalDataOk: false,

      dataFechasEmpresa: {},
      fechaSeleccionada: null
    };
  }

  async componentDidMount() {


    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;

      let dataFechasEmpresa = await this.obtenerDataEmpresaSeleccionada(id);

      this.setState({
        dataFechasEmpresa
      })
    }

    let fechaActual = moment();

    let month = fechaActual.format("M");
    let year = fechaActual.format("YYYY");

    this.setState({
      month,
      year,
      fechaSeleccionada: momentToStringFechaInicio(fechaActual, databaseFormat)
    });

    this.ejecutarbusquedaMes(month, year);
  }

  obtenerDataEmpresaSeleccionada = async (idEmpresa) => {
    try {
      const url = `${API}/Empresa/${idEmpresa}`;;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = await response.status;
      const { data: { Fecha_Inicio_Sistema, Fecha_Inicio_Sistema_Unix } } = await response.json();
      // const data = await response.json();

      let objFecha = {
        Normal: Fecha_Inicio_Sistema,
        Unix: Fecha_Inicio_Sistema_Unix,
      }

      if (status === 200 || status === 201) {
        // return data;
        return objFecha;
      } else {
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se pudo cargar la información.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });

        return [];
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
  };

  procesarInformacionGraficos = (dataProcesar) => {

    let centrosCosto = this.state.centrosDeCosto;
    let arregloRegistrosConError = [];
    let arregloRegistrosNoEncontrados = [];
    let arregloRegistrosOk = [];

    centrosCosto.forEach((cc) => {
      cc.cantidadDiasTrabajados = 0;
      cc.TotalInvertido = 0;
    });

    const cantidadDias = 30;

    const permisoConGose = "5f5a870f002d3205dc7f24f2";
    const permisoSindicalConGose = "5f5a8754002d3205dc7f24f4";

    dataProcesar.Registros.forEach((registro) => {

      if (registro.Trabajador !== null) {

        const costoTrabajador = parseInt(registro.Costo);

        let copiaCC = cloneDeep(centrosCosto);

        registro.valida_diasAusentes = true;
        registro.valida_diasConLicencia = true;
        registro.valida_diasNoContratados = true;
        registro.valida_diasTrabajados = true;

        let diasAusentes = 0;
        let diasConLicencia = 0;
        let diasNoContratados = 0;
        let diasTrabajados = 0;
        //varaible especial
        //let noContabilizado = 0;

        registro.Bitacora.forEach((bitacora) => {
          //verificar primero las que tienen estados
          if (bitacora.Estado) {

            let centroDeCostoBitacora = bitacora.IdFaena.Centro_De_Costo;

            // encontrado.cantidadDiasTrabajados++;
            copiaCC.forEach((cc) => {
              if (cc._id === centroDeCostoBitacora) {

                let valor = cc.cantidadDiasTrabajados + 1;
                cc.cantidadDiasTrabajados = valor;
              }
            });

            diasTrabajados++;

          } else {

            if (bitacora.Vacaciones !== undefined) {
              diasTrabajados++;
            }

            if (bitacora.Permiso !== undefined) {
              if (
                bitacora.Permiso.TipoPermiso === permisoConGose ||
                bitacora.Permiso.TipoPermiso === permisoSindicalConGose
              ) {
                diasTrabajados++;
              } else {
                diasAusentes++;
              }
            }

            if (bitacora.Descansos !== undefined) {
              diasTrabajados++;
            }

            if (bitacora.DescDiaAusente !== undefined) {
              diasAusentes++;
            }

            if (bitacora.Licencia !== undefined) {
              diasConLicencia++;
            }
          }
        });

        let flagValidado = false;

        //valido informancion de ingresada con la del sistema
        if (registro.Dias_Ausentes !== diasAusentes) {
          registro.valida_diasAusentes = false;
          flagValidado = true;
        }

        if (registro.Dias_Con_Licencia !== diasConLicencia) {
          registro.valida_diasConLicencia = false;
          flagValidado = true;
        }

        if (registro.Dias_Trabajados !== diasTrabajados) {
          registro.valida_diasTrabajados = true;
          flagValidado = true;
        }

        if (registro.Dias_No_Contratados !== diasNoContratados) {
          registro.valida_diasNoContratados = false;
          flagValidado = true;
        }

        //asignacion de valores finales al trabajador
        registro.Trabajador.diasAusentes = diasAusentes;
        registro.Trabajador.diasConLicencia = diasConLicencia;
        registro.Trabajador.diasNoContratados = diasNoContratados;
        registro.Trabajador.diasTrabajados = diasTrabajados;

        if (flagValidado) {
          arregloRegistrosConError.push(registro);

        } else {
          arregloRegistrosOk.push(registro);

          //Saco reportes de centros de costos
          let sueldoTrabajado =
            (diasTrabajados * costoTrabajador) / cantidadDias;

          copiaCC.forEach((cc) => {
            if (cc.cantidadDiasTrabajados !== 0 && diasTrabajados !== 0) {

              let totalInvertido = (sueldoTrabajado * cc.cantidadDiasTrabajados) / diasTrabajados;

              centrosCosto.forEach((centroCosto) => {

                if (centroCosto._id === cc._id) {

                  centroCosto.cantidadDiasTrabajados =
                    centroCosto.cantidadDiasTrabajados +
                    cc.cantidadDiasTrabajados;

                  centroCosto.TotalInvertido =
                    centroCosto.TotalInvertido + totalInvertido;
                }
              });
            }
          });
        }
      } else {
        arregloRegistrosNoEncontrados.push(registro);
      }

    });

    centrosCosto.forEach((cc) => {
      cc.mes = "Dec.";
    });

    this.setState({
      graficoCC: centrosCosto,
      sinData: false,
      vistaRender: true,
      arregloRegistrosConError,
      arregloRegistrosNoEncontrados,
      arregloRegistrosOk,
    });

    this.vistaDetalleGraficos();
    this.vistaDetalleGraficos2();
  };

  //pantalla de carga
  activarLoading = () => {
    this.setState({
      loading: true,
    });
  };

  desactivarLoading = () => {
    this.setState({
      loading: false,
    });
  };

  //manejo de vistas
  vistaInformacion = () => {

    if (this.state.sinData) {
      return (
        <Row>
          <Col
            style={{
              textAlign: "center",
            }}
            span={6}
          >
            <Collapse
              bordered={false}
              className="site-collapse-custom-collapse"
              accordion={false}
              defaultActiveKey={["2"]}
            >
              <Panel
                disabled={false}
                key="2"
                header={
                  <h4
                    style={{
                      position: "absolute",
                      bottom: -5,
                      color: "#061fcb",
                    }}
                  >
                    Unir sistema contable
                  </h4>
                }
                className=" site-collapse-custom-panel"
              >
                <Row>
                  <Col span={24}>
                    <p
                      style={{
                        marginTop: "20px",
                        textAlign: "left",
                        marginBottom: "100px",
                      }}
                    >
                      Para unir tu sistema contable debes presionar descargar
                      excel base, rellenar la información y luego presionar
                      “Subir excel”, de esta manera podrás visualizar la data
                      combinada con People Management.
                    </p>
                  </Col>
                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={24}
                  >
                    <button
                      className="ant-btn crear-embarco-button"
                      onClick={() => {
                        this.descargarFormato();
                      }}
                    >
                      Descargar Excel base
                    </button>
                    <button
                      className="ant-btn crear-embarco-button"
                      onClick={() => {
                        let input = document.createElement("input");
                        input.type = "file";
                        input.accept =
                          "application/vnd.ms-excel, application/vnd.ms-excel.addin.macroEnabled.12, application/vnd.ms-excel.sheet.macroEnabled.12, application/vnd.ms-excel.template.macroEnabled.12, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        input.click();
                        input.onchange = async (e) => {
                          const archivo = input.files[0];

                          let usuario = localStorage.getItem(
                            "usuario-people-manage"
                          );
                          if (
                            usuario !== null &&
                            usuario !== "" &&
                            usuario !== undefined &&
                            usuario !== "undefined"
                          ) {
                            usuario = JSON.parse(usuario);
                            const idEmpresa =
                              usuario.EmpresaSeleccionada.Empresa._id;
                            this.procesarSubirExcel(idEmpresa, archivo);
                          }
                        };
                      }}
                    >
                      Subir Excel
                    </button>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      );
    } else {

      const cantidadErrores = this.state.arregloRegistrosConError.length;
      const cantidadNoEncontrados = this.state.arregloRegistrosNoEncontrados.length;
      const cantidadRegistrosOk = this.state.arregloRegistrosOk.length;

      const total =
        this.state.arregloRegistrosConError.length +
        this.state.arregloRegistrosNoEncontrados.length +
        this.state.arregloRegistrosOk.length;

      return (
        <Row>
          <Col
            style={{
              textAlign: "center",
            }}
            span={6}
          >
            <Collapse
              bordered={false}
              className="site-collapse-custom-collapse"
              accordion={false}
              defaultActiveKey={["2"]}
            >
              <Panel
                disabled={false}
                key="2"
                header={
                  <h4
                    style={{
                      position: "absolute",
                      bottom: -5,
                      color: "#061fcb",
                    }}
                  >
                    Unir sistema contable
                  </h4>
                }
                className=" site-collapse-custom-panel"
              >

                <Row
                  style={{
                    marginTop: "20px",
                  }}
                >
                  {/* TÍTULO */}
                  <Col
                    style={{
                      textAlign: "left",
                      marginBottom: "20px",
                    }}
                    span={24}
                  >
                    <h4 className="texto-resultados-detalle-costos">
                      Resultados:
                    </h4>
                  </Col>

                  {/* NO CALZA LA DATA */}
                  <Col
                    style={{
                      textAlign: "center",
                      paddingBottom: "20px",
                    }}
                    span={8}
                  >
                    <img
                      style={{
                        width: "40px",
                      }}
                      src={error_icon}
                    />
                  </Col>
                  <Col
                    style={{
                      textAlign: "left",
                      paddingBottom: "20px",
                    }}
                    span={8}
                  >
                    <h5 className="texto-resultados-detalle-costos">
                      No calza la data
                    </h5>
                    <h5>{cantidadErrores} trabajadores</h5>
                  </Col>
                  <Col
                    style={{
                      textAlign: "center",
                      paddingBottom: "20px",
                    }}
                    span={8}
                  >
                    <Button
                      onClick={() => {
                        this.setState({
                          ModalErrores: true,
                        });
                      }}
                      className="ver-button"
                      icon={
                        <img
                          src={vista}
                          style={{ width: 25 }}
                          alt="icono editar"
                        />
                      }
                    />
                  </Col>

                  {/* NO EXISTE DATA */}
                  <Col
                    style={{
                      textAlign: "center",
                      paddingBottom: "20px",
                    }}
                    span={8}
                  >
                    <img
                      style={{
                        width: "40px",
                      }}
                      src={alerta_icon}
                    />
                  </Col>
                  <Col
                    style={{
                      textAlign: "left",
                      paddingBottom: "20px",
                    }}
                    span={8}
                  >
                    <h5 className="texto-resultados-detalle-costos">
                      No existe data
                    </h5>
                    <h5>{cantidadNoEncontrados} trabajadores</h5>
                  </Col>
                  <Col
                    style={{
                      textAlign: "center",
                      paddingBottom: "20px",
                    }}
                    span={8}
                  >
                    <Button
                      onClick={() => {
                        this.setState({
                          ModalNoData: true,
                        });
                      }}
                      className="ver-button"
                      icon={
                        <img
                          src={vista}
                          style={{ width: 25 }}
                          alt="icono editar"
                        />
                      }
                    />
                  </Col>

                  {/* DATA FALTANTE EN EXCEL */}
                  {/* <Col
                    style={{
                      textAlign: "center",
                      paddingBottom: "20px",
                    }}
                    span={8}
                  >
                    <img
                      style={{
                        width: "40px",
                      }}
                      src={alerta_icon}
                    />
                  </Col>
                  <Col
                    style={{
                      textAlign: "left",
                      paddingBottom: "20px",
                    }}
                    span={8}
                  >
                    <h5 className="texto-resultados-detalle-costos">
                      Data faltante en Excel
                    </h5>
                    <h5>{cantidadNoEncontrados} trabajadores</h5>
                  </Col>
                  <Col
                    style={{
                      textAlign: "center",
                      paddingBottom: "20px",
                    }}
                    span={8}
                  >
                    <Button
                      onClick={() => {
                        // this.setState({
                        //   ModalNoData: true,
                        // });
                      }}
                      className="ver-button"
                      icon={
                        <img
                          src={vista}
                          style={{ width: 25 }}
                          alt="icono editar"
                        />
                      }
                    />
                  </Col> */}

                  {/* OK */}
                  <Col
                    style={{
                      textAlign: "center",
                      paddingBottom: "20px",
                    }}
                    span={8}
                  >
                    <img
                      style={{
                        width: "40px",
                      }}
                      src={check_icon}
                    />
                  </Col>
                  <Col
                    style={{
                      textAlign: "left",
                      paddingBottom: "20px",
                    }}
                    span={8}
                  >
                    <h5 className="texto-resultados-detalle-costos">Ok</h5>
                    <h5>{cantidadRegistrosOk} trabajadores</h5>
                  </Col>
                  <Col
                    style={{
                      textAlign: "center",
                      paddingBottom: "20px",
                    }}
                    span={8}
                  >
                    <Button
                      onClick={() => {
                        this.setState({
                          ModalDataOk: true,
                        })
                      }}
                      className="ver-button"
                      icon={
                        <img
                          src={vista}
                          style={{ width: 25 }}
                          alt="icono editar"
                        />
                      }
                    />
                  </Col>

                  <hr className="division" style={{ marginRight: 35 }} />

                  {/* TOTAL */}
                  <Col
                    style={{
                      textAlign: "center",
                      paddingBottom: "20px",
                    }}
                    span={8}
                  ></Col>
                  <Col
                    style={{
                      textAlign: "left",
                      paddingBottom: "20px",
                    }}
                    span={8}
                  >
                    <h4 className="texto-resultados-detalle-costos">
                      Total: {total}
                    </h4>
                  </Col>

                </Row>

                <Row>
                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={24}
                  >
                    <button
                      className="ant-btn crear-embarco-button"
                      onClick={() => {
                        this.descargarFormato();
                      }}
                    >
                      Descargar Excel base
                    </button>
                    <button
                      className="ant-btn crear-embarco-button"
                      onClick={() => {
                        let input = document.createElement("input");
                        input.type = "file";
                        input.accept =
                          "application/vnd.ms-excel, application/vnd.ms-excel.addin.macroEnabled.12, application/vnd.ms-excel.sheet.macroEnabled.12, application/vnd.ms-excel.template.macroEnabled.12, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        input.click();
                        input.onchange = async (e) => {
                          const archivo = input.files[0];

                          let usuario = localStorage.getItem(
                            "usuario-people-manage"
                          );
                          if (
                            usuario !== null &&
                            usuario !== "" &&
                            usuario !== undefined &&
                            usuario !== "undefined"
                          ) {
                            usuario = JSON.parse(usuario);

                            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

                            this.procesarSubirExcel(idEmpresa, archivo);
                          }
                        };
                      }}
                    >
                      Subir Excel
                    </button>
                  </Col>
                </Row>

              </Panel>

            </Collapse>
          </Col>
          <Col push={2} span={15}>
            <Row>
              <Col span={24}>
                <div className="contenedor" id="contenedor"></div>
              </Col>
              <Col span={24}>
                <div className="contenedor2" id="contenedor2"></div>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }

  };

  //vistas de los graficos
  vistaDetalleGraficos2 = () => {
    if (
      this.state.vistaRender === true &&
      this.state.arregloRegistrosOk.length !== 0
    ) {
      let data = this.state.graficoCC;
      let total_costo = 0;
      let total_dias = 0;
      data.forEach((cc) => {
        total_costo = total_costo + cc.TotalInvertido;
        total_dias = total_dias + cc.cantidadDiasTrabajados;
        cc.item = cc.Nombre;
      });
      data.forEach((cc) => {
        if (cc.TotalInvertido !== 0) {
          cc.count = ((cc.TotalInvertido * 100) / total_costo).toFixed(0);
          cc.percent =
            ((cc.TotalInvertido * 100) / total_costo).toFixed(2) / 100;
        } else {
          cc.count = 0;
          cc.percent = 0;
        }
      });
      const chart = new Chart({
        container: "contenedor2",
        autoFit: true,
        height: 500,
      });
      chart.coordinate("theta", {
        radius: 0.75,
      });
      chart.data(data);
      chart.scale("percent", {
        formatter: (val) => {
          val = val * 100 + "%";
          return val;
        },
      });
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
      });
      chart
        .interval()
        .position("percent")
        .color("item")
        .label("percent", {
          content: (data) => {
            return `${data.item}: ${data.percent * 100}%`;
          },
        })
        .adjust("stack");
      chart.interaction("element-active");
      chart.render();
    }
  };

  vistaDetalleGraficos = () => {

    if (
      this.state.vistaRender === true &&
      this.state.arregloRegistrosOk.length !== 0
    ) {
      let data = this.state.graficoCC;
      const chart = new Chart({
        container: "contenedor",
        autoFit: true,
        height: 500,
      });
      chart.data(data);
      chart.scale("TotalInvertido", {
        nice: true,
      });
      chart.tooltip({
        showMarkers: false,
        shared: true,
      });
      chart
        .interval()
        .position("mes*TotalInvertido")
        .color("Nombre")
        .adjust([
          {
            type: "dodge",
            marginRatio: 0,
          },
        ]);
      chart.interaction("element-highlight-by-x");
      chart.render();
    }
  };

  limitarDatePicker = (fechamoment) => {

    let fechasInicioSistema = this.state.dataFechasEmpresa;
    let { Normal } = fechasInicioSistema;
    //console.log('Fecha inicio sistema: ', Normal);

    let fechaInicioMoment = moment(Normal);

    return fechaInicioMoment.isSameOrAfter(fechamoment);
  }

  ejecutarbusquedaMes = async (month, year) => {

    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {

      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      this.activarLoading();

      let respuesta = await this.buscarInfomacionDetalleCosto(
        month,
        year,
        idEmpresa
      );

      let centrosCosto = await this.buscarCentrosDeCosto(idEmpresa);

      this.desactivarLoading();

      if (
        respuesta.status === 200 ||
        (respuesta.status === 201 && centrosCosto.status === 200) ||
        centrosCosto.status === 201
      ) {

        this.setState({
          centrosDeCosto: centrosCosto.data,
        });

        if (
          respuesta.data !== undefined &&
          respuesta.data !== "undefined" &&
          respuesta.data !== null &&
          respuesta.data !== "null"
        ) {
          console.log(respuesta);

          this.procesarInformacionGraficos(respuesta.data);
        } else {
          this.setState({
            sinData: true,
          });
        }
      } else {
        Swal.fire({
          title: "Uups",
          text:
            "No se logro obtener información del mes, Status: " +
            respuesta.status,
          icon: "error",
        });
      }
    }
  };

  //obtener informacion del mes que se envie informacion
  buscarInfomacionDetalleCosto = async (mes, ano, empresa) => {
    try {
      const url = `${API}/Costo/Trabajador/Mes/${mes}/Anio/${ano}/Empresa/${empresa}`;
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
      });

      const status = await response.status;
      const { error, data } = await response.json();

      let respuesta;
      if (status === 200 || status === 201) {
        respuesta = {
          status: status,
          data: data,
        };
      } else {
        respuesta = {
          status: status,
          data: error,
        };
      }

      return respuesta;
    } catch (error) {
      let respuesta;
      respuesta = {
        status: 500,
        data: error,
      };
      return respuesta;
    }
  };

  buscarCentrosDeCosto = async (empresa) => {
    try {
      const url = `${API}/CentroDeCostos?empresa=${empresa}`;
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
      });

      const status = await response.status;
      const resp = await response.json();

      let respuesta;
      if (status === 200 || status === 201) {
        respuesta = {
          status: status,
          data: resp.centrosDeCostos,
        };
      } else {
        respuesta = {
          status: status,
          data: resp,
        };
      }

      return respuesta;
    } catch (error) {
      let respuesta;
      respuesta = {
        status: 500,
        data: error,
      };
      return respuesta;
    }
  };

  //subir excel
  subirExcel = async (idEmpresa, archivo, objFecha) => {

    try {
      let formData = new FormData();
      formData.append("archivo", archivo);
      formData.append("body", JSON.stringify(objFecha));

      const url = `${API}/Costo/Trabajador/Excel/${idEmpresa}`;

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        body: formData,
      });

      const status = await response.status;
      const { err, data } = await response.json();

      let respuesta;
      if (status === 200 || status === 201) {
        respuesta = {
          status: status,
          data: data,
        };
      } else {
        respuesta = {
          status: status,
          data: err,
        };
      }

      return respuesta;
    } catch (error) {
      let respuesta;
      respuesta = {
        status: 500,
        data: error,
      };
      return respuesta;
    }
  };

  procesarSubirExcel = async (idEmpresa, archivo) => {

    try {
      //let fechaActual = moment();
      let month = this.state.month;
      let year = this.state.year;
      let objFecha = {
        Mes: month,
        Ano: year,
        Fecha: this.state.fechaSeleccionada
      };

      this.activarLoading();

      let respuestaExcel = await this.subirExcel(idEmpresa, archivo, objFecha);

      this.desactivarLoading();

      if (respuestaExcel.status === 200 || respuestaExcel.status === 201) {
        Swal.fire({
          title: "Bien!",
          text: "Se ingresó el excel correctamente",
          icon: "success",
        }).then((value) => {
          window.location.reload();
        });
      } else if (respuestaExcel.status === 409) {

        //RUT REPETIDOS 
        Swal.fire({
          title: "¡Ups!",
          text: 'Hay uno o más RUT repetidos en el archivo que has seleccionado, utiliza el botón para descargar un excel con la lista de los RUT repetidos.',
          icon: "warning",

          confirmButtonText: 'Descargar RUT Repetidos',
          confirmButtonColor: '#37a537',

          showCancelButton: true,
          cancelButtonText: 'Cerrar',

        }).then(res => {

          if (res.isConfirmed) this.descargarRutRepetidos();
        })

      } else {

        Swal.fire({
          title: "Uups",
          text: respuestaExcel.data.message || 'Ocurrió un error al subir el archivo excel, intenta nuevamente.',
          icon: "error",
        })
      }

    } catch (error) {
      Swal.fire({
        title: "Uups",
        text: "Error no controlado, Error: " + error,
        icon: "error",
      });
    }
  };

  //descargar Excel
  descargarFormato = async () => {
    try {
      const url = `${API}/Costo/Trabajador/FomatoExcel`;
      const response = await fetch(url);
      const status = await response.status;
      const excel = await response.blob();

      switch (status) {
        case 200:
        case 201:
          const url = window.URL.createObjectURL(excel);
          const a = document.createElement("a");
          a.href = url;
          a.download = `formatoDetalleCostos.xlsx`;
          document.body.appendChild(a);
          a.click();
          a.remove();

          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
      // Swal.fire({
      //   title: "Uups",
      //   text: error,
      //   icon: "error",
      // });
    }
  };

  descargarRutRepetidos = async () => {
    try {

      let usuario = localStorage.getItem("usuario-people-manage");
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      const url = `${API}/Costo/Trabajador/RutRepetidos/${idEmpresa}`;

      const response = await fetch(url);
      const status = await response.status;
      const excel = await response.blob();

      switch (status) {
        case 200:
        case 201:
          const url = window.URL.createObjectURL(excel);
          const a = document.createElement("a");
          a.href = url;
          a.download = `Rut_Repetidos.xlsx`;
          document.body.appendChild(a);
          a.click();
          a.remove();

          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <>
        <Layout style={{ height: "100%" }}>
          <Loading cargando={this.state.loading} />

          <Header style={{ background: "none" }}>
            <HeaderFicha
              titulo={`Detalle de costos`}
              subtitulo="Une tu sistema contable y analiza la data"
            />
          </Header>
          <hr className="division" style={{ marginRight: 35 }} />
          <Content
            className="contenedor-general cont-tabla-celeste"
            style={{ background: "none" }}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <Row gutter={12} align="bottom">
                  <Col span={6} className="div-input-search">
                    <label style={{ display: "flex" }}>Filtrar</label>
                    <DatePicker
                      picker="month"
                      locale={locale}
                      disabledDate={this.limitarDatePicker}
                      onChange={(date) => {
                        if (date === null) {
                          return "";
                        } else {
                          let month = date.format("M");
                          let year = date.format("YYYY");
                          this.setState({
                            month,
                            year,
                            fechaSeleccionada: momentToStringFechaInicio(date, databaseFormat)
                          });
                        }
                      }}
                    />
                  </Col>
                  <Col span={6}>
                    <Button
                      className="btn-filtrar-celeste"
                      type="primary"
                      onClick={() => {
                        if (
                          this.state.month !== null &&
                          this.state.year !== null
                        ) {
                          this.ejecutarbusquedaMes(
                            this.state.month,
                            this.state.year
                          );
                        } else {
                          Swal.fire({
                            title: "Uups",
                            text: "Se debe seleccionar mes y año",
                            icon: "error",
                          });
                        }
                      }}
                      size={"large"}
                    >
                      Filtrar
                    </Button>
                  </Col>
                </Row>
              </Col>
              <h1 className="title_resultado_filtro">Resultados filtro</h1>
            </Row>
            {this.vistaInformacion()}
          </Content>

          <Modal
            wrapClassName="ModalErrores"
            visible={this.state.ModalErrores}
            onCancel={() => {
              this.setState({
                ModalErrores: false,
              });
            }}
            //propiedades obligatorias para modal mantenedor
            bodyStyle={{ borderRadius: "16px" }}
            width="80%"
            footer={null}
            style={{ top: 20 }}
            closable={false}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          >
            <Row>
              <Col span={24} style={{ display: "flex" }} className="title">
                <img
                  src={error_icon}
                  style={{ width: "35px" }}
                  alt="icono modal"
                />
                <PageHeader
                  style={{ padding: "0px 10px 0px 10px" }}
                  className="site-page-header"
                  title="Detalle no calza la data"
                />
              </Col>
              <Col span={24}>
                <p>
                  Comparativa entre datos del Sistema People Management y los
                  ingresados vía Excel.
                </p>
              </Col>
              <Col span={24}>
                <div className="cont-tabla-celeste">
                  <Table
                    rowKey="_id"
                    dataSource={this.state.arregloRegistrosConError}
                    loading={false}
                    bordered={true}
                    showSorterTooltip={false}
                  >
                    <Column
                      title="Foto"
                      key="Foto"
                      align="center"
                      width={100}
                      render={(valor) => {
                        return (
                          <img
                            src={valor.Trabajador.Imagen}
                            alt="imagen de tabla"
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.imagenClick(valor.Imagen);
                            }}
                          />
                        );
                      }}
                    />
                    <Column
                      title="Rut"
                      key="Rut"
                      align="center"
                      width={110}
                      render={(valor) => {
                        return <h4>{valor.Trabajador.Rut}</h4>;
                      }}
                    />
                    <Column
                      title="Trabajados Sistema"
                      key="TrabajoSistema"
                      align="center"
                      width={60}
                      className="columna-trabajados-sistema"
                      render={(valor) => {
                        console.log(valor);
                        return <h4>{valor.Trabajador.diasTrabajados}</h4>;
                      }}
                    />
                    <Column
                      title="Trabajados Excel"
                      key="TrabajoExcel"
                      align="center"
                      width={60}
                      className="columna-trabajados-excel"
                      render={(valor) => {
                        return <h4>{valor.Dias_Trabajados}</h4>;
                      }}
                    />

                    <Column
                      title="No Contratados Sistema"
                      key="NoContratadosSistema"
                      align="center"
                      width={60}
                      className="columna-trabajados-sistema"
                      render={(valor) => {
                        console.log(valor);
                        return <h4>{valor.Trabajador.diasNoContratados}</h4>;
                      }}
                    />
                    <Column
                      title="No Contratados Excel"
                      key="NoContratadosExcel"
                      align="center"
                      width={60}
                      className="columna-trabajados-excel"
                      render={(valor) => {
                        return <h4>{valor.Dias_No_Contratados}</h4>;
                      }}
                    />

                    <Column
                      title="Licencia Sistema"
                      key="TrabajoSistema"
                      align="center"
                      width={60}
                      className="columna-trabajados-sistema"
                      render={(valor) => {
                        return <h4>{valor.Trabajador.diasConLicencia}</h4>;
                      }}
                    />
                    <Column
                      title="Licencia Excel"
                      key="TrabajoSistema"
                      align="center"
                      width={60}
                      className="columna-trabajados-excel"
                      render={(valor) => {
                        return <h4>{valor.Dias_Con_Licencia}</h4>;
                      }}
                    />

                    <Column
                      title="Ausentes Sistema"
                      key="TrabajoSistema"
                      align="center"
                      width={60}
                      className="columna-trabajados-sistema"
                      render={(valor) => {
                        console.log(valor);
                        return <h4>{valor.Trabajador.diasAusentes}</h4>;
                      }}
                    />
                    <Column
                      title="Ausentes Excel"
                      key="TrabajoSistema"
                      align="center"
                      width={60}
                      className="columna-trabajados-excel"
                      render={(valor) => {
                        return <h4>{valor.Dias_Ausentes}</h4>;
                      }}
                    />
                  </Table>
                </div>
              </Col>
            </Row>
          </Modal>



          <Modal
            wrapClassName="ModalErrores"
            visible={this.state.ModalNoData}
            onCancel={() => {
              this.setState({
                ModalNoData: false,
              });
            }}
            //propiedades obligatorias para modal mantenedor
            bodyStyle={{ borderRadius: "16px" }}
            width="80%"
            footer={null}
            style={{ top: 20 }}
            closable={false}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          >
            <Row>
              <Col span={24} style={{ display: "flex" }} className="title">
                <img
                  src={alerta_icon}
                  style={{ width: "35px" }}
                  alt="icono modal"
                />
                <PageHeader
                  style={{ padding: "0px 10px 0px 10px" }}
                  className="site-page-header"
                  title="Detalle no existe en el sistema"
                />
              </Col>
              <Col span={24}>
                <p>
                  Comparativa entre datos del Sistema People Management y los
                  ingresados vía Excel.
                </p>
              </Col>
              <Col span={24}>
                <div className="cont-tabla-celeste">
                  <Table
                    rowKey="_id"
                    dataSource={this.state.arregloRegistrosNoEncontrados}
                    loading={false}
                    bordered={true}
                    showSorterTooltip={false}
                  >

                    <Column
                      title="Rut"
                      key="Rut"
                      align="center"
                      width={110}
                      render={(valor) => {
                        return <h4>{valor.Rut}</h4>;
                      }}
                    />

                    <Column
                      title="Trabajados Excel"
                      key="TrabajoExcel"
                      align="center"
                      width={60}
                      className="columna-trabajados-excel"
                      render={(valor) => {
                        return <h4>{valor.Dias_Trabajados}</h4>;
                      }}
                    />


                    <Column
                      title="No Contratados Excel"
                      key="NoContratadosExcel"
                      align="center"
                      width={60}
                      className="columna-trabajados-excel"
                      render={(valor) => {
                        return <h4>{valor.Dias_No_Contratados}</h4>;
                      }}
                    />
                    <Column
                      title="Licencia Excel"
                      key="TrabajoSistema"
                      align="center"
                      width={60}
                      className="columna-trabajados-excel"
                      render={(valor) => {
                        return <h4>{valor.Dias_Con_Licencia}</h4>;
                      }}
                    />
                    <Column
                      title="Ausentes Excel"
                      key="TrabajoSistema"
                      align="center"
                      width={60}
                      className="columna-trabajados-excel"
                      render={(valor) => {
                        return <h4>{valor.Dias_Ausentes}</h4>;
                      }}
                    />
                  </Table>
                </div>
              </Col>
            </Row>
          </Modal>


          <Modal
            wrapClassName="ModalErrores"
            visible={this.state.ModalDataOk}
            onCancel={() => {
              this.setState({
                ModalDataOk: false,
              });
            }}
            //propiedades obligatorias para modal mantenedor
            bodyStyle={{ borderRadius: "16px" }}
            width="80%"
            footer={null}
            style={{ top: 20 }}
            closable={false}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          >
            <Row>
              <Col span={24} style={{ display: "flex" }} className="title">
                <img
                  src={check_icon}
                  style={{ width: "35px" }}
                  alt="icono modal"
                />
                <PageHeader
                  style={{ padding: "0px 10px 0px 10px" }}
                  className="site-page-header"
                  title="Detalle con infomación que calza en el sistema"
                />
              </Col>
              <Col span={24}>
                <p>
                  Comparativa entre datos del Sistema People Management y los
                  ingresados vía Excel.
                </p>
              </Col>
              <Col span={24}>
                <div className="cont-tabla-celeste">
                  <Table
                    rowKey="_id"
                    dataSource={this.state.arregloRegistrosOk}
                    loading={false}
                    bordered={true}
                    showSorterTooltip={false}
                  >
                    <Column
                      title="Foto"
                      key="Foto"
                      align="center"
                      width={100}
                      render={(valor) => {
                        return (
                          <img
                            src={valor.Trabajador.Imagen}
                            alt="imagen de tabla"
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.imagenClick(valor.Imagen);
                            }}
                          />
                        );
                      }}
                    />
                    <Column
                      title="Rut"
                      key="Rut"
                      align="center"
                      width={110}
                      render={(valor) => {
                        return <h4>{valor.Trabajador.Rut}</h4>;
                      }}
                    />
                    <Column
                      title="Trabajados Sistema"
                      key="TrabajoSistema"
                      align="center"
                      width={60}
                      className="columna-trabajados-sistema"
                      render={(valor) => {
                        console.log(valor);
                        return <h4>{valor.Trabajador.diasTrabajados}</h4>;
                      }}
                    />
                    <Column
                      title="Trabajados Excel"
                      key="TrabajoExcel"
                      align="center"
                      width={60}
                      className="columna-trabajados-excel"
                      render={(valor) => {
                        return <h4>{valor.Dias_Trabajados}</h4>;
                      }}
                    />

                    <Column
                      title="No Contratados Sistema"
                      key="NoContratadosSistema"
                      align="center"
                      width={60}
                      className="columna-trabajados-sistema"
                      render={(valor) => {
                        console.log(valor);
                        return <h4>{valor.Trabajador.diasNoContratados}</h4>;
                      }}
                    />
                    <Column
                      title="No Contratados Excel"
                      key="NoContratadosExcel"
                      align="center"
                      width={60}
                      className="columna-trabajados-excel"
                      render={(valor) => {
                        return <h4>{valor.Dias_No_Contratados}</h4>;
                      }}
                    />

                    <Column
                      title="Licencia Sistema"
                      key="TrabajoSistema"
                      align="center"
                      width={60}
                      className="columna-trabajados-sistema"
                      render={(valor) => {
                        return <h4>{valor.Trabajador.diasConLicencia}</h4>;
                      }}
                    />
                    <Column
                      title="Licencia Excel"
                      key="TrabajoSistema"
                      align="center"
                      width={60}
                      className="columna-trabajados-excel"
                      render={(valor) => {
                        return <h4>{valor.Dias_Con_Licencia}</h4>;
                      }}
                    />

                    <Column
                      title="Ausentes Sistema"
                      key="TrabajoSistema"
                      align="center"
                      width={60}
                      className="columna-trabajados-sistema"
                      render={(valor) => {
                        console.log(valor);
                        return <h4>{valor.Trabajador.diasAusentes}</h4>;
                      }}
                    />
                    <Column
                      title="Ausentes Excel"
                      key="TrabajoSistema"
                      align="center"
                      width={60}
                      className="columna-trabajados-excel"
                      render={(valor) => {
                        return <h4>{valor.Dias_Ausentes}</h4>;
                      }}
                    />

                  </Table>
                </div>
              </Col>
            </Row>
          </Modal>
        </Layout>
      </>
    );
  }
}
// export default asignacionFaenas;
export default DetalleCostos;
