import React, { Component } from "react";
import "./MantenedorDocumentos.css";

import { connect } from "react-redux";
import * as mantenedorDocumentosActions from "../../Redux/actions/MantenedorDocumentosActions";

import activo from "../../img/icons/activo.svg";
import inactivo from "../../img/icons/inactivo.svg";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import { Layout, Table, Space, Button, Row, Col, Input, Modal } from "antd";

import Swal from "sweetalert2";
import FormularioDocumentos from "../../components/formularioDocumentos/FormularioDocumentos";
import FormularioDocumentosEditar from "../../components/formularioDocumentos/FormularioDocumentosEditar";
import { DownloadOutlined, EditFilled, DeleteFilled } from "@ant-design/icons";
import accesosPagina from '../../funcionesGenericas/accesosUsuario';

const { Header, Content } = Layout;
const { Search } = Input;
const { Column } = Table;
class MantenedorDocumentos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tablaCargando: false,
      form: {
        Archivo: "",
        Descripcion: "",
        Nombre: "",
        Vigencia: true,
      },
      documentosFiltros: null,
      size: "large",
      subirArchivoDeshabilitado: false,
    };
    this.cambiarEstadoVigencia = this.cambiarEstadoVigencia.bind(this);
    this.crearDocumento = this.crearDocumento.bind(this);
    this.EditarDocumento = this.EditarDocumento.bind(this);
    this.TomarValoresEditar = this.TomarValoresEditar.bind(this);
    this.ObtenerArchivo = this.ObtenerArchivo.bind(this);

    this.cambiarEstadoDeshabilitado = this.cambiarEstadoDeshabilitado.bind(this);
    this.cambiarValorArregloArchivos = this.cambiarValorArregloArchivos.bind(this);
  }

  async componentDidMount() {

    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      const accesos = await accesosPagina.obtenerAccesosPagina('MantenedorDocumentos');

      this.setState({
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
      });

      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      this.props.ObtenerDocumentos(idEmpresa);
    }

  }

  ObtenerArchivo = (base64) => {
    this.setState({
      form: {
        ...this.state.form,
        Archivo: base64,
      },
    });
  };

  search = (value) => {
    let busqueda = value.target.value;
    if (value !== "") {
      const DocumentosRedux = this.props.documentos;

      const documentosFiltros = DocumentosRedux.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(busqueda)
        )
      );

      this.setState({ documentosFiltros });
    } else {
      this.setState({ documentosFiltros: null });
    }
  };

  mostrarModalCrear = () => {
    this.props.MostrarModalDocumento();
  };

  modalCrearCancelar = () => {
    this.props.OcultarModalDocumento();
  };

  modalEditarCancelar = () => {
    this.props.OcultarModalDocumentoEditar();
  };

  cambiarEstadoVigencia = () => {
    this.setState({
      form: {
        ...this.state.form,
        Vigencia: this.state.form.Vigencia === true ? false : true,
      },
    });
  };

  crearDocumento = (documento) => {
    documento.Vigencia = this.state.form.Vigencia;
    documento.Archivo = this.state.form.Archivo;

    if (documento.Archivo === "" || this.state.subirArchivoDeshabilitado === false) {
      Swal.fire({
        title: "Uuups...",
        text: "Se debe ingresar un archivo",
        icon: "error",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Continuar",
      });
    } else {
      this.props.CrearDocumento(documento);
    }

  };

  EliminarDocumento = (idDocumento) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminará el documento",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.EliminarDocumento(idDocumento);
      }
    });
  };

  ObtenerDocumento = (idDocumento) => {
    this.props.ObtenerDocumento(idDocumento);
  };

  EditarDocumento = (Documento) => {
    if (this.state.form.Archivo !== "") {
      Documento.Archivo = this.state.form.Archivo;
    } else {
      Documento.Archivo = this.props.documento.Archivo;
    }

    if (this.props.subirArchivoDeshabilitado === false) {
      Swal.fire({
        title: "Uuups...",
        text: "Se debe ingresar un archivo",
        icon: "error",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Continuar",
      });
    } else {
      Documento._id = this.props.documento._id;
      Documento.Vigencia = this.props.documento.Vigencia;
      this.props.EditarDocumento(Documento);
    }


  };

  TomarValoresEditar = (nombreCampo, valor) => {
    this.props.TomarValoresEditar(nombreCampo, valor);
  };

  cambiarEstadoDeshabilitado = (valor) => {
    this.setState({
      subirArchivoDeshabilitado: valor,
    })
  }

  cambiarValorArregloArchivos = (nuevoArreglo, btnDeshabilitado) => {
    this.props.CambiarValorArregloDocumentos(nuevoArreglo, btnDeshabilitado);
  }

  render() {
    const { size, documentosFiltros } = this.state;

    return (
      <Layout style={{ height: "100%" }}>
        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Tipos de documentos"
            subtitulo="documentos disponibles"
          />
        </Header>

        <hr className="division" />

        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row style={{ padding: "6px 0px 22px" }} align="bottom">
            <Col className="div-input-search" span={6}>
              <Search
                placeholder="Buscar"
                //onSearch={(value) => this.search(value)}
                enterButton
                onChange={(value) => this.search(value)}
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={6}></Col>
            <Col style={{ textAlign: "right" }} span={12}>
              <Button
                className={!this.state.Editar ? "guardar-form-disabled" : "guardar-form"}
                disabled={!this.state.Editar}
                type="primary"
                onClick={() => {
                  this.mostrarModalCrear();
                }}
                size={size}
              >
                Crear documento
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="_id"
            dataSource={
              documentosFiltros === null
                ? this.props.documentos
                : documentosFiltros
            }
            loading={this.state.tablaCargando}
            bordered={true}
          >
            <Column
              sorter={(a, b) => {
                return a.Nombre.localeCompare(b.Nombre);
              }}
              title="Nombre"
              dataIndex="Nombre"
              key="Nombre"
            />

            <Column
              sorter={(a, b) => {
                return a.Descripcion.localeCompare(b.Descripcion);
              }}
              title="Descripcion"
              dataIndex="Descripcion"
              key="Descripcion"
            />
            <Column
              sorter={(a, b) => {
                return a.Vigencia - b.Vigencia;
              }}
              title="Vigencia"
              key="Vigencia"
              align="center"
              render={(valor) => {
                return (
                  <img
                    src={valor.Vigencia === true ? activo : inactivo}
                    alt="imagen perfil de tabla"
                  />
                );
              }}
            />

            <Column
              title="Acciones"
              key="Action"
              align="center"
              render={(value) => (
                <Space size="middle">
                  <Button
                    onClick={() => {
                      this.ObtenerDocumento(value._id);
                    }}
                    className="editar-button"
                    icon={<EditFilled />}
                    disabled={!this.state.Editar}
                  />
                  <Button
                    className="eliminar-button"
                    onClick={() => {
                      this.EliminarDocumento(value._id);
                    }}
                    icon={<DeleteFilled />}
                    disabled={!this.state.Eliminar}

                  />
                  <Button
                    onClick={() => {
                      let url = value.Archivo;
                      let win = window.open(url, "_blank");
                      win.focus();
                    }}
                    className="editar-button"
                    disabled={!this.state.Ver}
                    icon={<DownloadOutlined style={{ fontSize: "20px" }} />}
                  />
                </Space>
              )}
            />
          </Table>
        </Content>
        
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalDocumento}
          onCancel={this.modalCrearCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioDocumentos
            //propiedades cambiables del formulario
            ObtenerArchivo={this.ObtenerArchivo}
            Formulario={this.state.form}
            vigencia={this.state.form.vigencia}
            cambiarEstadoVigencia={this.cambiarEstadoVigencia}
            crearDocumento={this.crearDocumento}
            subirArchivoDeshabilitado={this.state.subirArchivoDeshabilitado}
            cambiarEstadoDeshabilitado={this.cambiarEstadoDeshabilitado}
          />
        </Modal>
        
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalEditar"
          visible={this.props.modalDocumentoEditar}
          onCancel={this.modalEditarCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioDocumentosEditar
            //propiedades cambiables del formulario
            ObtenerArchivo={this.ObtenerArchivo}
            Formulario={this.props.documento}
            EditarDocumento={this.EditarDocumento}
            TomarValoresEditar={this.TomarValoresEditar}
            arregloDocumentos={this.props.arregloDocumentos}
            subirArchivoDeshabilitado={this.props.subirArchivoDeshabilitado}
            cambiarValorArregloArchivos={this.cambiarValorArregloArchivos}
          />
        </Modal>
      
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  MantenedorDocumentosReducer: {
    loading,
    error,
    success,
    modalDocumento,
    modalDocumentoEditar,
    documentos,
    documento,
    tablaCargando,
    arregloDocumentos,
    subirArchivoDeshabilitado,
  },
}) => {
  return {
    loading,
    error,
    success,
    modalDocumento,
    modalDocumentoEditar,
    documentos,
    documento,
    tablaCargando,
    arregloDocumentos,
    subirArchivoDeshabilitado
  };
};

export default connect(
  EstadosAPropiedades,
  mantenedorDocumentosActions
)(MantenedorDocumentos);
