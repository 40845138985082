import React, { useState, useEffect } from "react";
import Trabajador from "../../img/icons/Trabajador.svg";
import "moment/locale/es";
import { Row, Col, PageHeader, Table } from "antd";
import "./FichaBonificaciones.css";
import moment from "moment";
import { convertirFechaString } from "../../funcionesGenericas/fechas";
import { Content } from "antd/lib/layout/layout";

const { Column } = Table;

const FichaContratos = (props) => {
  const [ dataContratos, setDataContratos ] = useState([]);

  useEffect(() => {
    const { contratos } = props;
    if (contratos.length > 0) {

      const arrayContratos = [];

      contratos.forEach((contrato) => {
        let str = moment().format("YYYY-MM-DD");
        const fechaHoy = convertirFechaString(moment(str), 1).fechaString;

        let validarEstado = contrato.Contrato_Finiquitado ? "Finiquitado" : "Vigente";

        if(moment.utc(fechaHoy).isSameOrAfter(moment.utc(contrato.Contrato_Fecha_Inicio))){
          if (
            contrato.Tipo_Contrato === "Plazo fijo" &&
            !moment
              .utc(fechaHoy)
              .isBetween(
                moment.utc(contrato.Contrato_Fecha_Inicio),
                moment.utc(contrato.Contrato_Fecha_Fin),
                null,
                "[]"
              )
          ) {
            validarEstado = "Finiquitado";
          }
        } else {
            validarEstado = "Aún no comienza";
        }

        arrayContratos.push({
          tipoContrato: contrato.Tipo_Contrato,
          fechaInicio: convertirFechaString(
            moment.utc(contrato.Contrato_Fecha_Inicio),
            1
          ).fechaDDMMYYYY,
          fechaFin: contrato.Contrato_Fecha_Fin
            ? convertirFechaString(moment.utc(contrato.Contrato_Fecha_Fin), 2)
                .fechaDDMMYYYY
            : "",
          fechaFiniquito: contrato.Fecha_Finiquito
            ? convertirFechaString(moment.utc(contrato.Fecha_Finiquito), 1)
                .fechaDDMMYYYY
            : "",
          estado: validarEstado,
          anexoContrato: contrato.Es_Anexo_De ? convertirFechaString(moment.utc(contrato.Es_Anexo_De.Contrato_Fecha_Inicio), 1).fechaDDMMYYYY + " / " + convertirFechaString(moment.utc(contrato.Es_Anexo_De.Contrato_Fecha_Fin), 2).fechaDDMMYYYY : "",
        });
      });

      setDataContratos(arrayContratos);
    }
  }, []);


  return (
    <Row style={{ padding: "5px 20px 0px 20px" }}>
      <Col span={24} style={{ display: "flex" }} className="title">
        <img src={Trabajador} style={{ width: "35px" }} alt="icono modal" />
        <PageHeader
          style={{ padding: "0px 10px 0px 10px" }}
          className="site-page-header"
          title="Contratos del trabajador"
        />
      </Col>

      <Content className="cont-tabla-celeste">
        <Table
          style={{ width: "100%", fontFamily: "MavenPro-Regular" }}
          locale={{
            emptyText: "No se encontraron registros de la tabla.",
          }}
          dataSource={dataContratos}
          bordered={true}
          scroll={{ x: true }}
        >
          <Column
          title="Tipo"
          key="tipoContrato"
          dataIndex="tipoContrato"
          align="left"
          ellipsis={true}
          />

          <Column
          title="Fecha Inicio"
          key="fechaInicio"
          dataIndex="fechaInicio"
          align="left"
          ellipsis={true}
          />

          <Column
          title="Fecha Termino"
          key="fechaFin"
          dataIndex="fechaFin"
          align="left"
          ellipsis={true}
          />

          <Column
          title="Fecha Finiquito"
          key="fechaFiniquito"
          dataIndex="fechaFiniquito"
          align="left"
          ellipsis={true}
          />

          <Column
          title="Estado"
          key="estado"
          dataIndex="estado"
          align="left"
          ellipsis={true}
          />

          <Column
          title="Anexo Contrato"
          key="anexoContrato"
          dataIndex="anexoContrato"
          align="left"
          ellipsis={true}
          />

        </Table>
      </Content>

    </Row>
  );
};

export default FichaContratos;
