import React, { Component } from "react";

import { connect } from "react-redux";
import * as mantenedorRegimenTrabajoActions from "../../Redux/actions/MantenedorRegimenTrabajoActions";

import activo from "../../img/icons/activo.svg";
import inactivo from "../../img/icons/inactivo.svg";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import FormularioRegimenTrabajo from "../../components/formularioRegimenTrabajo/FormularioRegimenTrabajo";
import FormularioRegimenTrabajoEditar from "../../components/formularioRegimenTrabajo/FormularioRegimenTrabajoEditar";

import { Layout, Table, Space, Button, Row, Col, Input, Modal } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import Swal from "sweetalert2";
import accesosPagina from '../../funcionesGenericas/accesosUsuario';

const { Header, Content } = Layout;
const { Search } = Input;
const { Column } = Table;

class MantenedorRegimenTrabajo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tablaCargando: false,
      form: {
        Descripcion: "",
        Nombre: "",
        Cantidad_Dias_Trabajo: 0,
        Cantidad_Dias_Descanso: 0,
        Vigencia: true,
      },

      size: "large",
      regimenFiltros: null,
    };

    this.cambiarEstadoVigencia = this.cambiarEstadoVigencia.bind(this);
    this.crearRegimen = this.crearRegimen.bind(this);
    this.EditarRegimen = this.EditarRegimen.bind(this);
    this.TomarValoresEditar = this.TomarValoresEditar.bind(this);
  }

  descargarExcel = () => {
    this.props.DescargarExcel();
  };

  search = (value) => {
    let busqueda = value.target.value;
    if (value !== "") {
      const RegimenesRedux = this.props.regimenes;

      const regimenFiltros = RegimenesRedux.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(busqueda)
        )
      );

      this.setState({ regimenFiltros });
    } else {
      this.setState({ regimenFiltros: null });
    }
  };

  mostrarModalCrear = () => {
    this.props.MostrarModalRegimen();
  };

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      const accesos = await accesosPagina.obtenerAccesosPagina('MantenedorRegimenDeTrabajo');

      this.setState({
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
      });

      let idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      this.props.ObtenerRegimenes(idEmpresa);
    }
  }

  modalCrearCancelar = () => {
    this.props.OcultarModalRegimen();
  };

  modalEditarCancelar = () => {
    this.props.OcultarModalRegimenEditar();
  };

  cambiarEstadoVigencia = () => {
    this.setState({
      form: {
        ...this.state.form,
        Vigencia: this.state.form.Vigencia === true ? false : true,
      },
    });
  };

  crearRegimen = (regimen) => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      regimen.Empresa = usuario.EmpresaSeleccionada.Empresa._id;
      regimen.Vigencia = this.state.form.Vigencia;
      this.props.CrearRegimen(regimen);
    } else {
      Swal.fire({
        title: "Error!",
        text: "Se perdio la sesión",
        icon: "error",
      });
    }
  };

  EliminarRegimen = (idRegimen) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminará el regimen",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.EliminarRegimen(idRegimen);
      }
    });
  };

  ObtenerRegimen = (idRegimen) => {
    this.props.ObtenerRegimen(idRegimen);
  };

  EditarRegimen = (Regimen) => {
    Regimen._id = this.props.regimen._id;
    Regimen.Vigencia = this.props.regimen.Vigencia;
    this.props.EditarRegimen(Regimen);
  };

  TomarValoresEditar = (nombreCampo, valor) => {
    this.props.TomarValoresEditar(nombreCampo, valor);
  };

  render() {
    const { size, regimenFiltros } = this.state;

    return (
      <Layout style={{ height: "100%" }}>
        <Loading cargando={this.props.loading} />
        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Régimen de trabajo"
            subtitulo="Ingrese los régimenes de trabajo"
          />
        </Header>
        <hr className="division" />
        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row style={{ padding: "6px 0px 22px" }} align="bottom">
            <Col className="div-input-search" span={6}>
              <Search
                placeholder="Buscar"
                //onSearch={(value) => this.search(value)}
                enterButton
                onChange={(value) => this.search(value)}
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={6}></Col>
            <Col style={{ textAlign: "right" }} span={12}>
              {/* <Button
                className="descargar-excel"
                type="primary"
                onClick={() => {
                  this.descargarExcel();
                }}
                size={size}
                style={{ marginRight: "20px" }}
              >
                Descargar Excel
              </Button> */}
              <Button
                className={!this.state.Editar ? "guardar-form-disabled" : "guardar-form"}
                disabled={!this.state.Editar}
                type="primary"
                onClick={() => {
                  this.mostrarModalCrear();
                }}
                size={size}
              >
                Crear régimen
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="_id"
            dataSource={
              regimenFiltros === null ? this.props.regimenes : regimenFiltros
            }
            loading={this.props.tablaCargando}
            bordered={true}
            showSorterTooltip={false}
          >
            <Column
              sorter={(a, b) => {
                return a.Nombre.localeCompare(b.Nombre);
              }}
              align="left"
              title="Nombre"
              dataIndex="Nombre"
              key="Nombre"
            />
            <Column
              sorter={(a, b) => {
                return a.Descripcion.localeCompare(b.Descripcion);
              }}
              align="left"
              title="Descripción"
              dataIndex="Descripcion"
              key="Descripcion"
            />
            <Column
              // sorter={(a, b) => {
              //   return a.Cantidad_Dias_Trabajo.localeCompare(
              //     b.Cantidad_Dias_Trabajo
              //   );
              // }}
              align="left"
              title="Días de trabajo"
              dataIndex="Cantidad_Dias_Trabajo"
              key="Cantidad_Dias_Trabajo"
            />
            <Column
              // sorter={(a, b) => {
              //   return a.Cantidad_Dias_Descanso.localeCompare(
              //     b.Cantidad_Dias_Descanso
              //   );
              // }}
              align="left"
              title="Días de descanso"
              dataIndex="Cantidad_Dias_Descanso"
              key="Cantidad_Dias_Descanso"
            />
            <Column
              sorter={(a, b) => {
                return a.Vigencia - b.Vigencia;
              }}
              title="Vigencia"
              key="Vigencia"
              align="center"
              render={(valor) => {
                return (
                  <img
                    src={valor.Vigencia === true ? activo : inactivo}
                    alt="imagen perfil de tabla"
                  />
                );
              }}
            />

            <Column
              title="Acciones"
              key="Action"
              align="center"
              render={(value) => (
                <Space size="middle">
                  <Button
                    onClick={() => {
                      this.ObtenerRegimen(value._id);
                    }}
                    className="editar-button"
                    icon={<EditFilled />}
                    disabled={!this.state.Editar}
                  />
                  <Button
                    className="eliminar-button"
                    onClick={() => {
                      this.EliminarRegimen(value._id);
                    }}
                    icon={<DeleteFilled />}
                    disabled={!this.state.Eliminar}
                  />
                </Space>
              )}
            />
          </Table>
        </Content>
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalRegimen}
          onCancel={this.modalCrearCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioRegimenTrabajo
            //propiedades cambiables del formulario
            Formulario={this.state.form}
            vigencia={this.state.form.vigencia}
            cambiarEstadoVigencia={this.cambiarEstadoVigencia}
            crearRegimen={this.crearRegimen}
          />
        </Modal>
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalEditar"
          visible={this.props.modalRegimenEditar}
          onCancel={this.modalEditarCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioRegimenTrabajoEditar
            //propiedades cambiables del formulario
            Formulario={this.props.regimen}
            EditarRegimen={this.EditarRegimen}
            cambiarEstadoVigencia={this.cambiarEstadoVigencia}
            TomarValoresEditar={this.TomarValoresEditar}
          />
        </Modal>
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  MantenedorRegimenTrabajoReducer: {
    loading,
    error,
    success,
    modalRegimen,
    modalRegimenEditar,
    regimenes,
    regimen,
    tablaCargando,
  },
}) => {
  return {
    loading,
    error,
    success,
    modalRegimen,
    modalRegimenEditar,
    regimenes,
    regimen,
    tablaCargando,
  };
};

export default connect(
  EstadosAPropiedades,
  mantenedorRegimenTrabajoActions
)(MantenedorRegimenTrabajo);
