export const LOADING_EN_TRANSITO = "LOADING_EN_TRANSITO";
export const ERROR_EN_TRANSITO = "ERROR_EN_TRANSITO";
export const BUSCAR_TRABAJADORES_EN_TRANSITO = "BUSCAR_TRABAJADORES_EN_TRANSITO";
export const INSERTAR_DATOS_FORMULARIO = "INSETAR_DATOS_FORMULARIO_EN_TRANSITO";
export const INSERTAR_TRABAJADOR_FORM = "INSERTAR_TRABAJADOR_FORM_EN_TRANSITO";
export const INSERTAR_FECHAS_FORM = "INSERTAR_FECHAS_FORM_EN_TRANSITO";
// export const INSERTAR_DESCRIPCION_FORM = "INSERTAR_DESCRIPCION_FORM_EN_TRANSITO";
export const INSERTAR_DESCANSO_FORM = "INSERTAR_DESCANSO_FORM_EN_TRANSITO";
export const ENVIAR_CORREO = "ENVIAR_CORREO_EN_TRANSITO";
export const LOADING_EN_TRANSITO_FALSE = 'LOADING_EN_TRANSITO_FALSE';
export const BUSCAR_CONTRATOS_TRABAJADOR = 'BUSCAR_CONTRATOS_TRABAJADOR';
export const INSERTAR_OBSERVACION_FORM = 'INSERTAR_OBSERVACION_FORM';