import {
  LOADING_EN_TRANSITO,
  ERROR_EN_TRANSITO,
  BUSCAR_TRABAJADORES_EN_TRANSITO,
  INSERTAR_DATOS_FORMULARIO,
  INSERTAR_TRABAJADOR_FORM,
  INSERTAR_FECHAS_FORM,
  // INSERTAR_DESCRIPCION_FORM,
  INSERTAR_DESCANSO_FORM,
  ENVIAR_CORREO,
  LOADING_EN_TRANSITO_FALSE,
  BUSCAR_CONTRATOS_TRABAJADOR,
  INSERTAR_OBSERVACION_FORM
} from "../types/enTransitoTypes";

import Swal from "sweetalert2";
// import { notification } from "antd";
import API from "../Api/api";
import moment from "moment";
import { convertirFechaString,convertirFechaStringNueva } from "../../funcionesGenericas/fechas";

// export const insertarDatos = () => async (dispatch, getState) => {

//     dispatch({
//         type: LOADING_EN_TRANSITO,
//     });

//     try {

//         const store = getState().EnTransitoReducer;

//         const {
//             form: { Fechas, Trabajador, Descanso, Correo: envioCorreo },
//         } = store;

//         let fechaInicioTransito = moment(Fechas[0]);
//         let fechaFinTransito = moment(Fechas[1]);

//         let objTransito = {
//             IdTrabajador: Trabajador,
//             Fecha_Inicio: Fechas[0],
//             Fecha_Fin: Fechas[1],
//             Descanso: Descanso,
//         }

//         const urlTransito = `${API}/EnTransito`
//         const requestTransito = await fetch(urlTransito, {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//                 Accept: "application/json",
//             },
//             body: JSON.stringify(objTransito),
//         });

//         const responseTransito = await requestTransito.json();
//         const statusTransito = await requestTransito.status;

//         switch (statusTransito) {
//             case 201:

//                 let idRegistroTransito = responseTransito.data._id;

//                 let cantidadDias = fechaFinTransito.diff(fechaInicioTransito, "days") + 1;

//                 let arregloDeDias = [];

//                 for (let index = 0; index < cantidadDias; index++) {

//                     let fecha = fechaInicioTransito;

//                     fecha = fecha.add(index === 0 ? 0 : 1, "day");

//                     const aux = {
//                         Fecha: new Date(fecha),
//                         IdTrabajador: Trabajador,
//                         TipoRegistroBitacoraTrabajador: "5f8f369e8efe155a48df37fb",
//                         // DescDiaAusente: Descripcion,

//                         InicioTrabajo: index === 0 ? true : false,
//                         FinTrabajo: index === cantidadDias - 1 ? true : false,
//                         Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,

//                         Fecha_Inicio: Fechas[0],
//                         Fecha_Fin: Fechas[1],
//                         EnTransito: idRegistroTransito,
//                     };
//                     arregloDeDias.push(aux);
//                 }

//                 let objIngresoDias = {
//                     fechaInicio: Fechas[0],
//                     fechaFin: Fechas[1],
//                     arregloDeDias,
//                 };

//                 const url = `${API}/IngresoDeDias/${Trabajador}`;
//                 const request = await fetch(url, {
//                     method: "POST",
//                     headers: {
//                         "Content-Type": "application/json",
//                         Accept: "application/json",
//                     },
//                     body: JSON.stringify(objIngresoDias),
//                 });

//                 const response = await request.json();
//                 const status = await request.status;

//                 switch (status) {

//                     case 201:

//                         if (envioCorreo) {
//                             const url = `${API}/Enviar/Correo/EnTransito/${idRegistroTransito}`;
//                             await fetch(url, {
//                                 method: "POST",
//                                 headers: {
//                                     "Content-Type": "application/json",
//                                     Accept: "application/json",
//                                 },
//                             })
//                         }

//                         Swal.fire({
//                             title: 'Exitoso',
//                             text: 'Se ingresaron exitosamente los días de tránsito',
//                             icon: 'success',
//                             confirmButtonColor: "#E60AA3",
//                             confirmButtonText: "Continuar",
//                         });

//                         dispatch({
//                             type: INSERTAR_DATOS_FORMULARIO,
//                             payload: response,
//                         });

//                         break;

//                     case 400:
//                         Swal.fire({
//                             title: "Error",
//                             text: response.err.message,
//                             icon: "error",
//                         });
//                         break;

//                     case 500:
//                         Swal.fire({
//                             title: "Error",
//                             text: response.err.message,
//                             icon: "error",
//                         });
//                         break;

//                     default:
//                         Swal.fire({
//                             title: "Error",
//                             text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
//                             icon: "error",
//                         });
//                         break;
//                 }
//                 break;
//             case 400:
//                 Swal.fire({
//                     title: "Error",
//                     text: responseTransito.err.message,
//                     icon: "error",
//                 });
//                 dispatch({
//                     type: ERROR_EN_TRANSITO,
//                     payload: responseTransito.err.message,
//                 });
//                 break;

//             case 500:

//                 Swal.fire({
//                     title: "Error",
//                     text: responseTransito.err.message,
//                     icon: "error",
//                 });
//                 dispatch({
//                     type: ERROR_EN_TRANSITO,
//                     payload: responseTransito.err.message,
//                 });
//                 break;

//             default:

//                 Swal.fire({
//                     title: "Error",
//                     text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
//                     icon: "error",
//                 });
//                 break;
//         }

//     } catch (error) {

//         dispatch({
//             type: ERROR_EN_TRANSITO,
//             payload: error.message,
//         });
//     }
// };

export const insertarDatosAvanzado = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_EN_TRANSITO,
  });

  try {
    const store = getState().EnTransitoReducer;

    const {
      form: { Fechas, Trabajador, Descanso, Correo: envioCorreo,Observacion },
    } = store;

    // let fechaInicioTransito = moment.utc(momentToStringFechaInicio(moment(Fechas[0]), databaseFormat));
    // let fechaFinTransito = moment.utc(momentToStringFechaFin(moment(Fechas[1]), databaseFormat));

    ///Antigua
    // let fechaInicioTransito = moment.utc(
    //   convertirFechaString(moment(Fechas[0]), 1).fechaString
    // );
    // let fechaFinTransito = moment.utc(
    //   convertirFechaString(moment(Fechas[1]), 2).fechaString
    // );

    //Con la funcion nueva.
    let fechaInicioTransito = moment.utc(convertirFechaStringNueva(moment(Fechas[0]), 1).fechaString);
    let fechaFinTransito = moment.utc(convertirFechaStringNueva(moment(Fechas[1]), 2).fechaString);

    let objTransito = {
      IdTrabajador: Trabajador,

      // Fecha_Inicio: momentToStringFechaInicio(moment(Fechas[0]), databaseFormat),
      // Fecha_Fin: momentToStringFechaFin(moment(Fechas[1]), databaseFormat),

      // Fecha_Inicio: convertirFechaString(moment(Fechas[0]), 1).fechaString,
      // Fecha_Fin: convertirFechaString(moment(Fechas[1]), 2).fechaString,
      Fecha_Inicio: convertirFechaStringNueva(Fechas[0], 1).fechaString,
      Fecha_Fin: convertirFechaStringNueva(Fechas[1], 2).fechaString,

      Descanso: Descanso,
      Observacion: Observacion
    };

    const urlTransito = `${API}/EnTransito/Avanzado`;
    const requestTransito = await fetch(urlTransito, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objTransito),
    });

    const responseTransito = await requestTransito.json();
    const statusTransito = await requestTransito.status;

    switch (statusTransito) {
      case 201:
        let idRegistroTransito = responseTransito.data._id;

        let cantidadDias =
          fechaFinTransito.diff(fechaInicioTransito, "days") + 1;

        let arregloDeDias = [];

        for (let index = 0; index < cantidadDias; index++) {
          let fecha = fechaInicioTransito;

          fecha = fecha.add(index === 0 ? 0 : 1, "day");

          const aux = {
            // Fecha: obtenerFechaFormateadaMismaHora(fecha.set({ hour: 12 }), databaseFormat),

            Fecha: convertirFechaString(fecha, 1).fechaString,

            IdTrabajador: Trabajador,
            TipoRegistroBitacoraTrabajador: "5f8f369e8efe155a48df37fb",
            // DescDiaAusente: Descripcion,

            InicioTrabajo: index === 0 ? true : false,
            FinTrabajo: index === cantidadDias - 1 ? true : false,
            Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,

            // Fecha_Inicio: momentToStringFechaInicio(moment(Fechas[0]), databaseFormat),
            // Fecha_Fin: momentToStringFechaFin(moment(Fechas[1]), databaseFormat),

            Fecha_Inicio: objTransito.Fecha_Inicio,
            Fecha_Fin: objTransito.Fecha_Fin,

            EnTransito: idRegistroTransito,
            Observacion:Observacion
          };
          arregloDeDias.push(aux);
        }

        let objIngresoDias = {
          fechaInicio: objTransito.Fecha_Inicio,
          fechaFin: objTransito.Fecha_Fin,
          arregloDeDias,
        };

        const url = `${API}/IngresoDeDias/${Trabajador}`;
        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objIngresoDias),
        });

        const response = await request.json();
        const status = await request.status;

        switch (status) {
          case 201:
            if (envioCorreo) {
              const url = `${API}/Enviar/Correo/EnTransito/${idRegistroTransito}`;
              await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              });
            }

            Swal.fire({
              title: "Exitoso",
              text: "Se ingresaron exitosamente los días de tránsito",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });

            dispatch({
              type: INSERTAR_DATOS_FORMULARIO,
              payload: response,
            });

            break;

          case 400:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            break;

          case 500:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            break;

          default:
            Swal.fire({
              title: "Error",
              text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
              icon: "error",
            });
            break;
        }
        break;
      case 400:
        Swal.fire({
          title: "Error",
          text: responseTransito.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR_EN_TRANSITO,
          payload: responseTransito.err.message,
        });
        break;
      case 409:
        let msg = "";

        switch (responseTransito.data.err) {
          case "TRABAJO_CONFIRMADO":
            msg =
              "<span>No puedes registrar en días con trabajo de asistencia confirmada.</span>";
            break;
          case "TRABAJO_DESCANSO":
            msg = `<span>No puedes registrar en días de trabajo que ya tienen un descanso asignado. Si deseas modificar esos registros, debes utilizar el <a href='/Descanso'>módulo de descansos.</a></span>`;
            break;
          case "DESCANSOS":
            msg = `<span>No puedes registrar en días con descanso planificado. Si deseas modificar esos registros, debes utilizar el <a href='/Descanso'>módulo de descansos.</a></span>`;
            break;
          case "VACACIONES":
            msg = `<span>No puedes registrar en días con vacaciones planificadas. Si deseas modificar esos registros, debes utilizar el <a href='/Vacaciones'>módulo de vacaciones.</a></span>`;
            break;
          default:
            msg =
              "<span>No puedes sobrescribir los registros de esas fechas.</span>";
            break;
        }

        Swal.fire({
          title: "¡Ups!",
          html: msg,
          icon: "warning",
        });

        dispatch({
          type: ERROR_EN_TRANSITO,
          payload: "No puedes registrar en días con asistencia ya confirmada.",
        });

        break;

      case 500:
        Swal.fire({
          title: "Error",
          text: responseTransito.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR_EN_TRANSITO,
          payload: responseTransito.err.message,
        });
        break;
      case 420:
        Swal.fire({
          title: "Uups",
          text: "No se pueden asignar tránsitos en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({
          type: ERROR_EN_TRANSITO,
          payload:
            "No se pueden asignar tránsitos en un periodo que se encuentra cerrado",
        });

        break;

      default:
        dispatch({
          type: ERROR_EN_TRANSITO,
          payload: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
        });
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }
  } catch (error) {
    //console.log(error);
    dispatch({
      type: ERROR_EN_TRANSITO,
      payload: error.message,
    });
  }
};

export const insertarFechas = (fechas) => (dispatch) => {
  dispatch({
    type: INSERTAR_FECHAS_FORM,
    payload: fechas,
  });
};

// export const insertarDescripcion = (descripcion) => (dispatch) => {
//   dispatch({
//     type: INSERTAR_DESCRIPCION_FORM,
//     payload: descripcion,
//   });
// };

export const insertarDescanso = (descanso) => (dispatch) => {
  dispatch({
    type: INSERTAR_DESCANSO_FORM,
    payload: descanso,
  });
};
export const insertarObservacion = (observacion) => (dispatch) => {
  dispatch({
    type: INSERTAR_OBSERVACION_FORM,
    payload: observacion,
  });
};

export const enviarCorreo = (estado) => (dispatch) => {
  dispatch({
    type: ENVIAR_CORREO,
    payload: estado,
  });
};

export const insertarTrabajador = (idTrabajador) => (dispatch) => {
  dispatch({
    type: INSERTAR_TRABAJADOR_FORM,
    payload: idTrabajador,
  });
};

export const traer_todos_las_fechas_trabajador =
  (idTrabajador) => async (dispatch) => {
    try {
      const url = `${API}/IngresoDeDias/Actuales/${idTrabajador}`;
      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          return data;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          break;
      }
    } catch (error) {
      dispatch({
        type: ERROR_EN_TRANSITO,
        payload: error.message,
      });
    }
  };

export const traer_todos_los_trabajadores = (empresa) => async (dispatch) => {
  dispatch({
    type: LOADING_EN_TRANSITO,
  });

  try {
    const url = `${API}/trabajadores?empresa=${empresa}`;
    const request = await fetch(url);
    const { Trabajadores } = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:
        dispatch({
          type: BUSCAR_TRABAJADORES_EN_TRANSITO,
          payload: Trabajadores,
        });
        break;
      default:
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }
  } catch (error) {
    dispatch({
      type: ERROR_EN_TRANSITO,
      payload: error.message,
    });
  }
};

export const eliminarRegistro =
  (fechaInicioDel, fechaFinDel, idEnTransito) => async (dispatch, getState) => {
    dispatch({
      type: LOADING_EN_TRANSITO,
    });

    try {
      const store = getState().EnTransitoReducer;

      const {
        form: { Trabajador },
      } = store;

      const objEliminar = {
        Fecha_Inicio: fechaInicioDel,
        Fecha_Fin: fechaFinDel,
        IdTrabajador: Trabajador,
      };

      const urlDescanso = `${API}/EnTransito/Eliminar/${idEnTransito}`;

      const requestDescanso = await fetch(urlDescanso, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objEliminar),
      });

      const responseDescansos = await requestDescanso.json();
      const statusDescansos = await requestDescanso.status;

      switch (statusDescansos) {
        case 200:
          dispatch({
            type: LOADING_EN_TRANSITO_FALSE,
          });

          Swal.fire({
            title: "¡Bien!",
            text: "El registro se ha eliminado exitosamente.",
            icon: "success",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });

          break;

        case 500:
          dispatch({
            type: LOADING_EN_TRANSITO_FALSE,
          });
          console.log(requestDescanso);

          break;

        case 420:
          Swal.fire({
            title: "Uups",
            text: "No se pueden eliminar tránsitos en un periodo que se encuentra cerrado",
            icon: "error",
          });

          dispatch({
            type: ERROR_EN_TRANSITO,
            payload:
              "No se pueden eliminar tránsitos en un periodo que se encuentra cerrado",
          });

          break;
        default:
          dispatch({
            type: ERROR_EN_TRANSITO,
            payload:
              "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          });
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          break;
      }
    } catch (e) {
      console.error(e);

      dispatch({
        type: ERROR_EN_TRANSITO,
        payload: e.message,
      });
    }
  };

export const traer_todos_contratos_trabajador =
  (idTrabajador, idEmpresa) => async (dispatch) => {
    try {
      const url = `${API}/Trabajadores/Contratos?idTrabajador=${idTrabajador}&idEmpresa=${idEmpresa}`;
      const request = await fetch(url);
      const data = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          dispatch({
            type: BUSCAR_CONTRATOS_TRABAJADOR,
            payload: data.contratoDB,
          });
          break;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          break;
      }
    } catch (error) {
      dispatch({
        type: ERROR_EN_TRANSITO,
        payload: error.message,
      });
    }
  };

export const traer_todos_los_periodos_en_transito =
  (idTrabajador) => async (dispatch) => {
    try {
      const ID_EN_TRANSITO = "5f8f369e8efe155a48df37fb";

      const url = `${API}/IngresoDeDias/Bitacoras/TipoBitacora/${idTrabajador}/${ID_EN_TRANSITO}`;

      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          return data;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          return null;
      }
    } catch (error) {
      dispatch({
        type: ERROR_EN_TRANSITO,
        payload: error.message,
      });
      return null;
    }
  };

export const traer_todos_las_fechas_trabajador_por_contrato =
  (idTrabajador, idContrato, FechaInicio, FechaFin) => async (dispatch) => {
    try {
      let url;
      if (FechaInicio !== undefined && FechaFin !== undefined) {
        url = `${API}/IngresoDeDias/Actuales/Contrato/${idTrabajador}/${idContrato}?InicioBusqueda=${FechaInicio}&FinBusqueda=${FechaFin}`;
      } else {
        url = `${API}/IngresoDeDias/Actuales/Contrato/${idTrabajador}/${idContrato}`;
      }
      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          return data;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          return null;
      }
    } catch (error) {
      dispatch({
        type: ERROR_EN_TRANSITO,
        payload: error.message,
      });
      return null;
    }
  };
