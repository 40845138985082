import React from "react";
import "./RecuperarPassword.css";
import FormularioReset from '../../components/FormularioReset/FormularioReset';

// import Loading from "../../components/loading/Loading";

import { Row, Col } from "antd";
import ImagenTop from '../../img/login/ImgTop.png';
import ImagenBottom from '../../img/login/ImgBottom.png';

class RecuperarPassword extends React.Component {

    state = {
        idUsuario: null,
    }

    componentDidMount() {
        const idUsuario = window.location.search.slice(4);
        this.setState({ idUsuario });
    }

    render() {
        return (
            <>
                {/* <Loading cargando={this.props.cargando} /> */}

                <Row justify={'center'} align={'middle'}>
                    <Col span={24} style={{ textAlign: 'right', height: '30vh' }}>
                        <img
                            className={'imgTop'}
                            src={ImagenTop}
                            style={{
                                width: '40vmax',
                                height: '80%'
                            }}
                        />
                    </Col>
                </Row>



                <Row justify={'center'} >

                    <Col span={24} style={{ height: '40vh' }}>
                        <FormularioReset
                            idUsuario={this.state.idUsuario}
                        />
                    </Col>
                </Row>



                <Row>
                    <Col span={24} style={{ textAlign: 'left', height: '30vh' }}>
                        <img
                            className={'imgBottom'}
                            src={ImagenBottom}
                            style={{
                                width: '40vmax',
                                height: '100%',
                            }}
                        />
                    </Col>
                </Row>
            </>
        );
    }



}

export default RecuperarPassword;