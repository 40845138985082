import {
  ERROR,
  INGRESAR_FAENA,
  LOADING,
  OBTENER_CENTROSCOSTO,
  OBTENER_PLANTILLAS,
  MOSTRAR_MODAL_FAENA,
  OCULTAR_MODAL_FAENA,
  OBTENER_FAENAS_ASIGNACION,
  OBTENER_ACTIVIDADES_Y_CANTIDAD_DE_REGISTROS,//Seba
  TOMAR_VALORES_EDITAR,
  MOSTRAR_TABLA,
  OBTENER_FAENAS_ID_CENTROCOSTO,
  EDITAR_FAENAS_ASIGNACION,
  CERRAR_EDITAR,
  CLEAR_DATA,

  //   PARAR_LOADING,
} from "../types/asignacionFaenasTypes";
import API from "../Api/api";
// import { obtenerFechaHoy } from "../../funcionesGenericas/fechas";
import Swal from "sweetalert2";
import moment from "moment";
import buscarColor from "../../funcionesGenericas/coloresEstadoTrabajo";
import {
  dateToMommetFecha,
  momentToString,
  dateOrMomentToMomentTimeZoneChile,
  corregirZonaHoraria,
} from "../../funcionesGenericas/fechas";

export const ObtenerCentrosCosto = (idEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/CentroDeCostos?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let centroscosto = data.centrosDeCostos;

        centroscosto.forEach((element) => {
          element.Header = true;
          element.id = element._id;
          element.title = element.Nombre;

          element.height = 45;
        });
        dispatch({ type: OBTENER_CENTROSCOSTO, payload: centroscosto });
        return centroscosto;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerPlantillas = (idEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/PlantillaFaena?empresa=${idEmpresa}`;
    // ?empresa=${idEmpresa}
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const plantillas = data.plantillasFaena;

        dispatch({ type: OBTENER_PLANTILLAS, payload: plantillas });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerAsignaciones = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/Faena?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      const data = await response.json();

      switch (status) {
        case 200:
        case 201:
          let faenas = data.faena;

          faenas.forEach((element) => {
            //   id: "1",
            //   group: "1",
            //   title: "item 1",
            //   start_time: moment(),
            //   end_time: moment().add(1, "hour"),
            element.id = element._id;
            element.group = element.Centro_De_Costo;
            element.title = "";
            element.canMove = false;
            element.canResize = false;
            element.canChangeGroup = false;
            element.colorBarra = "item-amarillo";
            element.start_time = moment(element.Fecha_Inicio).set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            });
            element.end_time = moment(element.Fecha_Fin).set({
              hour: 23,
              minute: 60,
              second: 0,
              millisecond: 0,
            });
            // .add(1, "day");
          });

          dispatch({ type: OBTENER_FAENAS_ASIGNACION, payload: faenas });
          break;

        default:
          const error = data.err.message.toString();
          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerAsignacionesFiltrado =
  (fechaInicio, fechaFin, centrosCosto, ccSeleccionados) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    const objFechas = {
      fechaInicio,
      fechaFin,
      ccSeleccionados,
    };

    try {
      let usuario = localStorage.getItem("usuario-people-manage");
      if (
        usuario !== null &&
        usuario !== "" &&
        usuario !== undefined &&
        usuario !== "undefined"
      ) {
        usuario = JSON.parse(usuario);
        const id = usuario.EmpresaSeleccionada.Empresa._id;
        const url = `${API}/Faena/FiltradoNewData?empresa=${id}`;
        //const url = `${API}/Faena/FiltradoNew?empresa=${id}`;
        //const url = `${API}/Faena/Filtrado?empresa=${id}`;

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objFechas),
        });

        const status = response.status;
        const data = await response.json();

        switch (status) {
          case 200:
          case 201:
            let faenas = data.faena;

            if (
              faenas.nuevosItems == undefined ||
              faenas.nuevosItems == "undefined"
            ) {
              let respuesta = ordenarAsignacionesFaena(faenas);

              respuesta.nuevosCC = asignarColorCC(
                respuesta.nuevosItems,
                centrosCosto
              );

              dispatch({ type: OBTENER_FAENAS_ASIGNACION, payload: respuesta });
            } else {
              faenas.nuevosItems = convertirFechasMoment(faenas.nuevosItems);
              dispatch({ type: OBTENER_FAENAS_ASIGNACION, payload: faenas });
            }

            break;

          default:
            const error = data.err.message.toString();

            Swal.fire({
              title: "Uups",
              text: "Error status: " + status + " ,detalle: " + error,
              icon: "error",
            });
            dispatch({ type: ERROR, payload: data.err });
            break;
        }
      } else {
        window.location = "/";
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: ERROR, payload: error });
    }
  };
///Nueva Funcion seba
export const ObtenerAsignacionesFiltradoSeba =
  (fechaInicio, fechaFin, centrosCosto, ccSeleccionados) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    const objFechas = {
      fechaInicio,
      fechaFin,
      ccSeleccionados,
    };

    try {
      let usuario = localStorage.getItem("usuario-people-manage");
      if (
        usuario !== null &&
        usuario !== "" &&
        usuario !== undefined &&
        usuario !== "undefined"
      ) {
        usuario = JSON.parse(usuario);
        const id = usuario.EmpresaSeleccionada.Empresa._id;
        const url = `${API}/Faena/FiltradoNewDataDesarrolloSeba?empresa=${id}`;

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objFechas),
        });

        const status = response.status;
        const data = await response.json();

        switch (status) {
          case 200:
            //let faenas = data.registros;
            if (
              data.cantRegistros == undefined ||
              data.cantRegistros == "undefined" ||
              data.cantRegistros == null ||
              data.cantRegistros.length == 0 
              &&
              data.registros == undefined ||
              data.registros == "undefined" ||
              data.registros == null ||
              data.registros.length == 0

            ) {
              // let respuesta = ordenarAsignacionesFaena(faenas);

              // respuesta.nuevosCC = asignarColorCC(
              //   respuesta.nuevosItems,
              //   centrosCosto
              // );
              dispatch({ type: OBTENER_ACTIVIDADES_Y_CANTIDAD_DE_REGISTROS, payload: data });
            } else {
              dispatch({
                type: OBTENER_ACTIVIDADES_Y_CANTIDAD_DE_REGISTROS,
                payload: data
              });

            }
            break;
          default:
            const error = data.err.message.toString();

            Swal.fire({
              title: "Uups",
              text: "Error status: " + status + " ,detalle: " + error,
              icon: "error",
            });
            dispatch({ type: ERROR, payload: data.err });
            return [];
        }
      } else {
        window.location = "/";
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: ERROR, payload: error });
    }
  };

///
export const ClearData = () => (dispatch) => {
  dispatch({ type: CLEAR_DATA });
};

// función encargada de separar faenas por días
const ordenarAsignacionesFaena = (faenasArr) => {
  let nuevosItems = [];

  let cntRojo = 0;
  let cntAmarillo = 0;
  let cntAzul = 0;
  let cntVerde = 0;

  //recorriendo faenas recibidas
  faenasArr.forEach((faena) => {
    let inicioFaena = moment.unix(faena.Fecha_Inicio_Unix); //dateOrMomentToMomentTimeZoneChile(faena.Fecha_Inicio);
    let finFaena = moment.unix(faena.Fecha_Fin_Unix); //dateOrMomentToMomentTimeZoneChile(faena.Fecha_Fin);

    //sacando cantidad de días que tiene la faena
    let cantidadDias = finFaena.diff(inicioFaena, "days") + 1;

    for (let index = 0; index < cantidadDias; index++) {
      let nuevoItem = crearItem(inicioFaena, faena, index, cantidadDias);

      switch (nuevoItem.colorBarra) {
        case "item-rojo":
          cntRojo++;
          break;
        case "item-amarillo":
          cntAmarillo++;
          break;
        case "item-celeste":
          cntAzul++;
          break;
        case "item-verde":
          cntVerde++;
          break;
        default:
          cntRojo++;
      }
      nuevosItems.push(nuevoItem);
    }
  });

  let objReturn = {
    nuevosItems,
    contadores: {
      cntRojo,
      cntAmarillo,
      cntAzul,
      cntVerde,
    },
  };

  return objReturn;
};

const crearItem = (inicioFaena, faena, index, cantidadDias) => {
  let fecha = inicioFaena;

  fecha = fecha.add(index === 0 ? 0 : 1, "day");

  let fechaString = fecha.format("YYYY-MM-DD HH:MM");
  let bordeIndicador = "";

  if (index === 0) {
    bordeIndicador = "borde-inicio";
  } else if (index === cantidadDias - 1) {
    bordeIndicador = "borde-fin";
  }

  let nuevoItem = {
    id: faena._id + index,
    idFaena: faena._id,
    group: faena.Centro_De_Costo,
    title: "",
    canMove: false,
    canResize: false,
    canChangeGroup: false,
    colorBarra: validarDia(fechaString, faena),
    start_time: moment(fechaString).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    }),
    end_time: moment(fechaString).set({
      hour: 23,
      minute: 59,
      second: 0,
      millisecond: 0,
    }),
    itemVisible: false,
    Vigencia: faena.Vigencia,
    nombreFaena: faena.Nombre,
    bordeIndicador,
  };

  return nuevoItem;
};

const validarDia = (diaFaena, faena) => {
  let ctdadPuestosPlazas = 0;

  let plazasSinCubrir = 0;
  let plazasReservadas = 0;
  let plazasEnCurso = 0;
  let plazasCubiertas = 0;

  let resultado = [];

  faena.Plazas.forEach((plaza) => {
    ctdadPuestosPlazas = ctdadPuestosPlazas + plaza.PuestosPlaza.length;

    plaza.BitacoraPlaza.forEach((bitacora) => {
      if (
        moment.unix(bitacora.FechaUnix).format("YYYY-MM-DD") ===
        moment(diaFaena).format("YYYY-MM-DD")
      ) {
        resultado.push(bitacora);
      }
    });
  });

  if (resultado.length === 0) {
    plazasSinCubrir++;
  } else {
    resultado.forEach((diaEncontrado) => {
      switch (diaEncontrado.Estado) {
        // Sin Cubrir
        case "5f3ef5ce1ecf531c1c8ab4a7":
          plazasSinCubrir++;
          break;
        // Reservadas
        case "5f3ef6591ecf531c1c8ab4a8":
          plazasReservadas++;
          break;
        // En Curso
        case "5f3ef6861ecf531c1c8ab4a9":
          plazasEnCurso++;
          break;
        // Ya Cubiertas
        case "5f3ef6981ecf531c1c8ab4aa":
          plazasCubiertas++;
          break;
        default:
          plazasSinCubrir++;
          break;
      }
    });
  }

  if (
    plazasSinCubrir > 0 ||
    plazasReservadas + plazasEnCurso + plazasCubiertas < ctdadPuestosPlazas
  ) {
    return "item-rojo";
  } else if (plazasReservadas > 0) {
    return "item-amarillo";
  } else if (plazasEnCurso > 0) {
    return "item-verde";
  } else if (plazasCubiertas > 0) {
    return "item-celeste";
  }
};

const convertirFechasMoment = (items) => {
  let newsItems = [...items];
  newsItems.forEach((item) => {
    if (!moment.isMoment(item.start_time)) {
      item.start_time = corregirZonaHoraria(moment(item.start_time), 1);
    }
    if (!moment.isMoment(item.end_time)) {
      item.end_time = corregirZonaHoraria(moment(item.end_time), 2);
    }
  });
  return newsItems;
};

const asignarColorCC = (items, centrosCosto) => {
  centrosCosto.forEach((CC) => {
    let itemsEncontrados = items.filter((item) => item.group === CC._id);

    if (itemsEncontrados.length === 0) {
      CC.color = buscarColor.itemGris;
    } else {
      let ctdRojo = 0;
      let ctdAmarillo = 0;
      let ctdAzul = 0;
      let ctdVerde = 0;

      ctdRojo = items.filter(
        (item) => item.colorBarra === "item-rojo" && item.group === CC._id
      ).length;
      ctdAmarillo = items.filter(
        (item) => item.colorBarra === "item-amarillo" && item.group === CC._id
      ).length;
      ctdAzul = items.filter(
        (item) => item.colorBarra === "item-celeste" && item.group === CC._id
      ).length;
      ctdVerde = items.filter(
        (item) => item.colorBarra === "item-verde" && item.group === CC._id
      ).length;

      if (ctdRojo > 0) {
        CC.color = buscarColor.itemRojo;
      } else if (ctdAmarillo > 0) {
        CC.color = buscarColor.itemAmarillo;
      } else if (ctdAzul > 0) {
        CC.color = buscarColor.itemAzul;
      } else if (ctdVerde > 0) {
        CC.color = buscarColor.itemVerde;
      }
    }
  });
  return centrosCosto;
};

export const ObtenerAsignacionesIdCentroCosto =
  (idCentroDeCosto) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const url = `${API}/Faena/CentroDeCosto/${idCentroDeCosto}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      const data = await response.json();

      switch (status) {
        case 200:
        case 201:
          let faenas = data.faena;
          dispatch({ type: OBTENER_FAENAS_ID_CENTROCOSTO, payload: faenas });
          break;

        default:
          const error = data.err.message.toString();
          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: ERROR, payload: error });
    }
  };

export const CrearFaena = (faena) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const url = `${API}/Faena`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(faena),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Se creó la actividad correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: INGRESAR_FAENA,
          payload: "Se asignó faena correctamente",
        });
        break;
      case 420:
        Swal.fire({
          title: "Uups",
          text: "No se pueden crear actividades en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({
          type: ERROR,
          payload:
            "No se pueden crear actividades en un periodo que se encuentra cerrado",
        });

        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
    console.error(error);
  }
};

export const TomarValoresEditar = (nombreCampo, valor) => (dispatch) => {
  let objeto = {
    nombreCampo,
    valor,
  };
  dispatch({ type: TOMAR_VALORES_EDITAR, payload: objeto });
};

export const MostrarModalAsignacion = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_FAENA });
};

export const OcultarModalAsignacion = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_FAENA });
};

export const MostrarTabla = () => (dispatch) => {
  dispatch({ type: MOSTRAR_TABLA });
};

export const ObtenerFaena = (idFaena) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Faena/Buscar?faena=${idFaena}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let faenaEditar = data.faena;

        dispatch({ type: EDITAR_FAENAS_ASIGNACION, payload: faenaEditar });
        break;

      default:
        const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const CerrarEditar = () => (dispatch) => {
  dispatch({ type: CERRAR_EDITAR });
};

export const EliminarFaena = (idFaena) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const url = `${API}/Faena/${idFaena}`;

    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;
    let err;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();
        Swal.fire({
          title: "Bien",
          text: "Se eliminó actividad correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: INGRESAR_FAENA,
          payload: "Se eliminó faena correctamente",
        });
        break;

      case 409:
        data = await response.json();

        Swal.fire({
          title: "Uups",
          text: "No puedes eliminar una actividad que ya tiene asistencias confirmadas",
          icon: "error",
        });

        dispatch({
          type: INGRESAR_FAENA,
          payload: "Se eliminó faena correctamente",
        });

        break;

      case 420:
        Swal.fire({
          title: "Uups",
          text: "No se pueden eliminar actividades en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({
          type: ERROR,
          payload:
            "No se pueden eliminar actividades en un periodo que se encuentra cerrado",
        });

        break;

      default:
        err = data.err.message.toString();

        console.error(err);

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ActualizarFaena = (idFaena, idEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const url = `${API}/Faena/Actualizar/Estado/Completo/${idFaena}?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;
    let err;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();
        Swal.fire({
          title: "Bien",
          text: "Se actualizo la actividad correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: INGRESAR_FAENA,
          payload: "Se actualizo la actividad correctamente",
        });
        break;

      case 404:
        data = await response.json();
  
        Swal.fire({
          title: "Uups",
          text: `Error: ${data.mensaje}`,
          icon: "error",
        });

        dispatch({
          type: INGRESAR_FAENA,
          payload: "Se eliminó faena correctamente",
        });

        break;

      default:
        err = data.err.message.toString();

        console.error(err);

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

///////
// -eliminar dias (si se acorto el inicio o el fin)3
// -agregar dias (si se alargo el inicio o el fin)3
// -editar el registro de la faena1
// -editar las fechas de las  plazas1
// -validaciones(REGISTROS,MES NO CERRADO,)
//////
export const  AlargarAcortarActividad = (faena) => async (dispatch) => {
  dispatch({ type: LOADING });

  const id = faena.idFaena;
  delete faena.idFaena;

  try {
    const url = `${API}/Faena/Fechas/Actividad/${id}`;

    const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(faena),
    });

    const status = response.status;
    let data = await response.json();

    switch (status) {

      case 200:
      case 201:
        // data = await response.json();
        Swal.fire({
          title: "Bien",
          text: "Se actualizó la actividad correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: INGRESAR_FAENA,
          payload: "Se actualizó faena correctamente",
        });
        //break;
        return true;

      case 420:
        Swal.fire({
          title: "Uups",
          text: "No se pueden alargar actividades de un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({
          type: ERROR,
          payload:
            "No se pueden alargar faenas de un periodo que se encuentra cerrado",
        });

        return false;

      default:
        // data = await response.json();
        // const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: `Error ${status}: ${data.err}`,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        return false;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
    return false;
  }
};


export const AlargarFaena = (faena) => async (dispatch) => {
  dispatch({ type: LOADING });

  const id = faena.idFaena;
  delete faena.idFaena;

  try {
    const url = `${API}/Faena/Alargar/${id}`;

    const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(faena),
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();
        Swal.fire({
          title: "Bien",
          text: "Se actualizó la actividad correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: INGRESAR_FAENA,
          payload: "Se actualizó faena correctamente",
        });
        //break;
        return true;

      case 420:
        Swal.fire({
          title: "Uups",
          text: "No se pueden alargar actividades de un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({
          type: ERROR,
          payload:
            "No se pueden alargar faenas de un periodo que se encuentra cerrado",
        });

        return false;

      default:
        // data = await response.json();
        // const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status ,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        return false;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
    return false;
  }
};

export const AcortarFaena = (faena) => async (dispatch) => {
  dispatch({ type: LOADING });

  const id = faena.idFaena;
  delete faena.idFaena;

  try {
    const url = `${API}/Faena/Acortar/${id}`;

    const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(faena),
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Se actualizó la actividad correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: INGRESAR_FAENA,
          payload: "Se actualizó faena correctamente",
        });
        return true;

      case 409:
        data = await response.json();

        if(data.err.vieneTexto){
          Swal.fire({
            title: "Uups",
            html: `<div style="width: 100%;height: auto;white-space: pre-wrap;font-size: 16px;background-color: #dddddd;padding: 15 px;font-weight: 700;">${data.err.message.trim()}</div>`,
            icon: "error",
          });
        }else{
          Swal.fire({
            title: "Uups",
            text: "No puedes editar fechas que ya tengan asistencia registrada.",
            icon: "error",
          });
        }
        

        dispatch({
          type: INGRESAR_FAENA,
          payload: "Se eliminó faena correctamente",
        });

        return false;

      case 420:
        Swal.fire({
          title: "Uups",
          text: "No se pueden asignar actividades en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({
          type: ERROR,
          payload:
            "No se pueden asignar actividades en un periodo que se encuentra cerrado",
        });

        return false;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        return false;
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ERROR, payload: error });
    return false;
  }
};

// export const DescargarExcel = () => async (dispatch) => {
//   dispatch({ type: LOADING });
//   try {
//     const url = `${API}/Usuario/Descargar/Excel`;
//     let status;
//     fetch(url)
//       .then((response) => {
//         status = response.status;
//         return response.blob();
//       })
//       .then((blob) => {
//         switch (status) {
//           case 200:
//           case 201:
//             const url = window.URL.createObjectURL(blob);
//             const a = document.createElement("a");
//             a.href = url;
//             a.download = `Usuarios_${obtenerFechaHoy()}.xlsx`;
//             document.body.appendChild(a);
//             a.click();
//             a.remove();

//             dispatch({ type: PARAR_LOADING });
//             break;
//           default:
//             Swal.fire({
//               title: "Uups",
//               text:
//                 "Error status: " +
//                 status +
//                 " al realizar la descarga del archivo",
//               icon: "error",
//             });

//             dispatch({ type: PARAR_LOADING });
//             break;
//         }
//       });
//   } catch (error) {
//     dispatch({ type: ERROR, payload: error });
//   }
// };
