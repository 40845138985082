import {
  ERROR,
  INGRESAR_TRABAJADOR,
  LOADING,
  LIMPIAR_ERROR_SUCCESS,
  MOSTRAR_MODAL_TRABAJADOR,
  OCULTAR_MODAL_TRABAJADOR,
  OBTENER_TRABAJADORES_MANTENEDOR,
 
  ELIMINAR_TRABAJADOR_MANTENEDOR,
  OBTENER_TRABAJADOR_MANTENEDOR,
  OCULTAR_MODAL_TRABAJADOR_EDITAR,
  EDITAR_TRABAJADOR_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OBTENER_CARGOS_TRABAJADOR,
  OBTENER_ESTADOS_TRABAJADOR,
  OBTENER_TITULOS_TRABAJADORES,
  OBTENER_ESPECIALIDADES_TRABAJADORES,
  OBTENER_REGIMEN_TRABAJO,
  OBTENER_EPPS_TRABAJADORES,
  OBTENER_REGIONES_MANTENEDOR_TRABAJADOR,
  OBTENER_PROVINCIAS_MANTENEDOR_TRABAJADOR,
  OBTENER_COMUNAS_MANTENEDOR_TRABAJADOR,
  INSERTAR_PROVINCIAS_MANTENEDOR_TRABAJADOR,
  INSERTAR_COMUNAS_MANTENEDOR_TRABAJADOR,
  OBTENER_DOCUMENTOS_MANTENEDOR_TRABAJADOR
} from "../types/MantenedorTrabajadorTypes";

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: null,
  modalTrabajador: false,
  modalTrabajadorEditar: false,
  Trabajadores: [],
  Trabajador: null,
  tablaCargando: true,
  cargosTrabajador: [],
  titulos: [],
  especialidades: [],
  regimenes: [],
  epps: [],
  regiones: [],
  ciudades: [],
  comunas: [],
  ciudadesSeleccionadas: [],
  comunasSeleccionadas: [],
  documentos: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {

    case OBTENER_DOCUMENTOS_MANTENEDOR_TRABAJADOR:
      return {
        ...state,
        documentos: payload,
        loading: false,
        tablaCargando: false
      }

    case INSERTAR_PROVINCIAS_MANTENEDOR_TRABAJADOR:
      return {
        ...state,
        ciudadesSeleccionadas: payload,
      }

    case INSERTAR_COMUNAS_MANTENEDOR_TRABAJADOR:
      return {
        ...state,
        comunasSeleccionadas: payload,
      }

    case OBTENER_COMUNAS_MANTENEDOR_TRABAJADOR:


      return {
        ...state,
        loading: false,
        comunas: payload,
      }


    case OBTENER_PROVINCIAS_MANTENEDOR_TRABAJADOR:
      return {
        ...state,
        loading: false,
        ciudades: payload,
      }

    case OBTENER_REGIONES_MANTENEDOR_TRABAJADOR:
      return {
        ...state,
        loading: false,
        regiones: payload,
      }
    case INGRESAR_TRABAJADOR:
      return {
        ...state,
        loading: false,
        success: payload,
        modalTrabajador: false,
        tablaCargando: false,
      };
    case OBTENER_TITULOS_TRABAJADORES:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        titulos: payload,
      };
    case OBTENER_REGIMEN_TRABAJO:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        regimenes: payload,
      };
    case OBTENER_ESPECIALIDADES_TRABAJADORES:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        especialidades: payload,
      };

    case OBTENER_EPPS_TRABAJADORES:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        epps: payload,
      };

    case OBTENER_CARGOS_TRABAJADOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        modalTrabajadorEditar: false,
        cargosTrabajador: payload,
      };

    case OBTENER_ESTADOS_TRABAJADOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        modalTrabajadorEditar: false,
        estadosTrabajador: payload,
      };

    case EDITAR_TRABAJADOR_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        modalTrabajadorEditar: false,
      };

    case TOMAR_VALORES_EDITAR:
      const { nombreCampo, valor } = payload;
      return {
        ...state,

        trabajador: {
          ...state.trabajador,
          [nombreCampo]: valor,
        },
      };
    case LOADING:
      return {
        ...state,
        loading: true,
        tablaCargando: true,
      };
    // case PARAR_LOADING:
    //   return {
    //     ...state,
    //     loading: false,
    //     tablaCargando: false,
    //   };
    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        tablaCargando: false,
      };
    case LIMPIAR_ERROR_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };
    case MOSTRAR_MODAL_TRABAJADOR:
      return {
        ...state,
        modalTrabajador: true,
      };

    case OBTENER_TRABAJADORES_MANTENEDOR:

      return {
        ...state,
        trabajadores: payload.trabajadores,
        cantidadTrabajadores: payload.cantidadTrabajadores,
        loading: false,
        tablaCargando: false,
      };

    case OBTENER_TRABAJADOR_MANTENEDOR:


      let idRegion = payload.Region;
      let idProvincia = payload.Ciudad;


      let provincias = state.ciudades;
      let comunas = state.comunas;

      let nuevasProvincias = provincias.filter(provincia => provincia.Region === idRegion);
      let nuevasComunas = comunas.filter(comuna => comuna.Provincia === idProvincia);

      return {
        ...state,
        trabajador: payload,
        loading: false,
        tablaCargando: false,
        modalTrabajadorEditar: true,


        comunasSeleccionadas: nuevasComunas,
        ciudadesSeleccionadas: nuevasProvincias,
      };
    case ELIMINAR_TRABAJADOR_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      };
    case OCULTAR_MODAL_TRABAJADOR:
      return {
        ...state,
        modalTrabajador: false,
      };
    case OCULTAR_MODAL_TRABAJADOR_EDITAR:
      return {
        ...state,
        modalTrabajadorEditar: false,
      };
    default:
      return state;
  }
};
