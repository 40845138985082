import React, { Component } from "react";
import "./AccesosSistema.css";

import Swal from "sweetalert2";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import iconLapiz from "../../img/icons/lapiz-gris.png";
import iconBasurero from "../../img/icons/basurero-gris.png";
import iconOjoGris from "../../img/icons/ojo-gris.png";

//iconos de los dashboard
import iconDashboard from "../../img/icons/dashboard-azul.png";
import iconAsignaciones from "../../img/icons/asignaciones-azul.png";
import iconProcesosDelTrabajador from "../../img/icons/procesos-del-trabajador-azul.png";
import iconAsistencia from "../../img/icons/asistencia-azul.png";
import iconValidacionQR from "../../img/icons/validacion-qr-azul.png";
import iconMantenedores from "../../img/icons/mantenedores.png";
import iconTrabajadores from "../../img/icons-sidebar/azules/Trabajadores.png";
import iconExcel from "../../img/icons-sidebar/azules/IconExcel.svg";

import accesosPagina from '../../funcionesGenericas/accesosUsuario';
import API from "../../Redux/Api/api";

import {
  Layout,
  Row,
  Col,
  Button,
  Select,
  Form,
  Collapse,
  Checkbox,
} from "antd";

import {
  FileDoneOutlined,
} from "@ant-design/icons";


const { Header, Content } = Layout;
const { Panel } = Collapse;

class AccesosSistema extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sinData: false,
      loading: true,
      panelBloqueado: true,
      usuarioSeleccionado: "",
      usuarios: [],
      perfilesUsuarios: [],
      activeKey: [],
      valorSelect: "",

      usuarioIdEmpresa: "",

      formularioRellenar: {
        Dashboard: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorTrabajadores: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        PlanificacionEquiposDeTrabajo: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        PlanificacionActividades: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        PlanificacionDetalleActividad: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        PlanificacionDetalleTrabajador: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        AsistenciaCheckIn: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        AsistenciaManual: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        AsistenciaQR: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        AsistenciaCheckOut: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosDescanso: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosEnTransito: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosVacaciones: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosLicencia: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosMutual: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosPermisos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosAusente: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaDesviaciones: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaAsistenciaPersonas: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaAsistenciaCC: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaAuditoria: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaCierreDeMes: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaCosteo: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorUsuarios: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorPerfilesUsuario: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorAccesosSistema: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorSucursales: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorCentroDeCostos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorRegimenDeTrabajo: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorFeriados: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorTitulos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorEspecialidades: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorCargos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorEpp: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorDocumentos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaExcel: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

      },
    };
  }

  async componentDidMount() {

    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      const accesos = await accesosPagina.obtenerAccesosPagina('MantenedorAccesosSistema', 'MantenedorAccesosPermiso');

      this.setState({
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
        usuarioId: usuario._id,
        usuarioRol: usuario.EmpresaSeleccionada.Rol.Nombre,
        usuarioIdEmpresa: idEmpresa,
      });

      let respuesta = await this.buscarUsuarios(idEmpresa);
      let resPlantillas = await this.buscarTodasPlantillas(idEmpresa);

      if (respuesta.status === 200 && resPlantillas.status === 200) {
        this.setState({
          usuarios: respuesta.data,
          perfilesUsuarios: resPlantillas.data,
          loading: false,
        });

      } else if (respuesta.status === 200 && resPlantillas.status === 204) {
        this.setState({
          usuarios: respuesta.data,
          perfilesUsuarios: [],
          loading: false,
        });
      }
    }
  }

  buscarUsuarios = async (idEmpresaSelecionada) => {
    try {
      const url = `${API}/Usuario?empresas=${idEmpresaSelecionada}`;
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
      });

      const status = await response.status;
      const data = await response.json();

      let respuesta;
      if (status === 200 || status === 201) {
        respuesta = {
          status: status,
          data: data.usuarios,
        };
      } else {
        respuesta = {
          status: status,
          data: data.err,
        };
      }

      return respuesta;
    } catch (error) {
      let respuesta;
      respuesta = {
        status: 500,
        data: error,
      };
      return respuesta;
    }
  };

  buscarTodasPlantillas = async (idEmpresaSelecionada) => {
    try {

      const url = `${API}/PlantillasAcceso?empresa=${idEmpresaSelecionada}`;
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
      });
      const status = await response.status;
      let data;
      let respuesta;

      if (status === 200 || status === 201) {
        data = await response.json();

        respuesta = {
          status: status,
          data: data.data,
        };

      } else if (status === 204) {
        respuesta = {
          status: status,
          data: [],
        };

      } else {

        respuesta = {
          status: status,
          data: data.err,
        };
      }

      return respuesta;
    } catch (error) {

      console.error(error);

      let respuesta;

      respuesta = {
        status: 500,
        data: error,
      };

      return respuesta;
    }
  };

  buscarUsuarioAccesos = async (idUsuario) => {

    let usuario = await localStorage.getItem('usuario-people-manage');
    usuario = JSON.parse(usuario);

    let idEmpresaSelecionada = usuario.EmpresaSeleccionada.Empresa._id;

    try {
      const url = `${API}/AccesosSistema/PorEmpresa?usuario=${idUsuario}&empresa=${idEmpresaSelecionada}`;
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
      });

      const status = await response.status;

      let respuesta;

      if (status === 200 || status === 201) {

        const data = await response.json();

        respuesta = {
          status: status,
          data: data,
        };

      } else {
        const datamala = await response;

        respuesta = {
          status: status,
          data: datamala,
        };
      }

      return respuesta;
    } catch (error) {
      console.log(error);
      let respuesta;
      respuesta = {
        status: 500,
        data: error,
      };
      return respuesta;
    }
  };

  buscarPermisosDelUsuario = async (idUsuario) => {

    this.setState({
      loading: true,
      usuarioSeleccionado: idUsuario,
    });

    let respuesta = await this.buscarUsuarioAccesos(idUsuario);

    if (respuesta.status === 200) {

      let valores = respuesta.data.data[0];

      let permisos = {
        Ver: false,
        Editar: false,
        Eliminar: false
      };

      if (!valores.Dashboard) valores.Dashboard = permisos;

      if (!valores.MantenedorTrabajadores) {
        valores.MantenedorTrabajadores = valores.MantenedorFichaTrabajador != undefined ? valores.MantenedorFichaTrabajador : permisos;
      }
      if (!valores.Contratos) {
        valores.Contratos = permisos;
      }
      if (!valores.PlanificacionEquiposDeTrabajo) {
        valores.PlanificacionEquiposDeTrabajo = valores.AsignacionesPlantilla != undefined ? valores.AsignacionesPlantilla : permisos;
      }
      if (!valores.PlanificacionActividades) {
        valores.PlanificacionActividades = valores.AsignacionesCentroDeCosto != undefined ? valores.AsignacionesCentroDeCosto : permisos;
      }
      if (!valores.PlanificacionDetalleActividad) {
        valores.PlanificacionDetalleActividad = valores.AsignacionesDetalle != undefined ? valores.AsignacionesDetalle : permisos;
      }
      if (!valores.PlanificacionDetalleTrabajador) {
        valores.PlanificacionDetalleTrabajador = valores.DetalleTrabajador != undefined ? valores.DetalleTrabajador : permisos;
      }

      if (!valores.AsistenciaCheckIn) {
        valores.AsistenciaCheckIn = valores.ProcesosCheck != undefined ? valores.ProcesosCheck : permisos;
      }
      if (!valores.AsistenciaManual) {
        valores.AsistenciaManual = valores.Asistencia != undefined ? valores.Asistencia : permisos;
      }
      if (!valores.AsistenciaQR) {
        valores.AsistenciaQR = valores.ValidacionQR != undefined ? valores.ValidacionQR : permisos;
      }
      if (!valores.AsistenciaCheckOut) {
        valores.AsistenciaCheckOut = valores.ProcesosCheck != undefined ? valores.ProcesosCheck : permisos;
      }
      if (!valores.ReporteriaDesviaciones) {
        valores.ReporteriaDesviaciones = permisos;
      }
      if (!valores.ReporteriaDelDia) {
        valores.ReporteriaDelDia = permisos;
      }
      if (!valores.ReporteriaNecesidades) {
        valores.ReporteriaNecesidades = permisos;
      }
      if (!valores.ReporteriaUtilizacionRelevos) {
        valores.ReporteriaUtilizacionRelevos = permisos;
      }
      if (!valores.ReporteriaAsistenciaPersonas) {
        valores.ReporteriaAsistenciaPersonas = permisos;
      }
      if (!valores.ReporteriaAsistenciaCC) {
        valores.ReporteriaAsistenciaCC = permisos;
      }
      if (!valores.ReporteriaAuditoria) {
        valores.ReporteriaAuditoria = valores.Auditoria != undefined ? valores.Auditoria : permisos;
      }
      if (!valores.ReporteriaCierreDeMes) {
        valores.ReporteriaCierreDeMes = valores.CierreDeMes != undefined ? valores.CierreDeMes : permisos;
      }
      if (!valores.ReporteriaCosteo) {
        valores.ReporteriaCosteo = valores.Costeo != undefined ? valores.Costeo : permisos;
      }

      if (!valores.MantenedorPerfilesUsuario) {
        valores.MantenedorPerfilesUsuario = valores.MantenedorPerfilesPermiso != undefined ? valores.MantenedorPerfilesPermiso : permisos;
      }
      if (!valores.MantenedorAccesosSistema) {
        valores.MantenedorAccesosSistema = valores.MantenedorAccesosPermiso != undefined ? valores.MantenedorAccesosPermiso : permisos;
      }

      if (!valores.ReporteriaExcel) valores.ReporteriaExcel = permisos;

      this.setState({
        sinData: false,
        loading: false,
        activeKey: ["1"],
        panelBloqueado: false,
        formularioRellenar: valores,
        idAccesoUsuario: valores._id,
      });
    }

    if (respuesta.status === 204) {

      console.warn('No se encontraron permisos');

      let formularioVacio = {
        Dashboard: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorTrabajadores: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        Contratos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        PlanificacionEquiposDeTrabajo: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        PlanificacionActividades: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        PlanificacionDetalleActividad: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        PlanificacionDetalleTrabajador: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        AsistenciaCheckIn: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        AsistenciaManual: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        AsistenciaQR: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        AsistenciaCheckOut: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosDescanso: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosEnTransito: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosVacaciones: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosLicencia: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosMutual: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosPermisos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosAusente: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaDesviaciones: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaDelDia: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaNecesidades: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaUtilizacionRelevos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaAsistenciaPersonas: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaAsistenciaCC: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaAuditoria: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaCierreDeMes: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaCosteo: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorUsuarios: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorPerfilesUsuario: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorAccesosSistema: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorSucursales: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorCentroDeCostos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorRegimenDeTrabajo: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorFeriados: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorTitulos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorEspecialidades: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorCargos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorEpp: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorDocumentos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaExcel: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },
      };

      this.setState({
        sinData: true,
        loading: false,
        activeKey: ["1"],
        panelBloqueado: false,
        formularioRellenar: formularioVacio,
      });
    }
  };

  darPermisos = async () => {

    this.setState({
      loading: true,
    });

    let respuesta;

    if (this.state.sinData === true) {

      respuesta = await this.crearPermisos();

      if (respuesta.status === 200 || respuesta.status === 201) {
        this.setState({
          loading: false,
          panelBloqueado: true,
          activeKey: [],
          valorSelect: "",
        });

        this.resetearFormularioRellenar();

        Swal.fire({
          title: "Bien",
          text: "Se asignaron permisos correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
      } else {

        this.setState({
          loading: false,
        });
        Swal.fire({
          title: "Error",
          text: "Ocurrio un problema al realizar la peticion, status: " + respuesta.status,
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });
      }

    } else {

      respuesta = await this.editarPermisos();

      this.setState({
        loading: false,
        panelBloqueado: true,
        activeKey: [],
        valorSelect: "",
      });

      this.resetearFormularioRellenar();

      Swal.fire({
        title: "Bien",
        text: "Se editaron los permisos correctamente",
        icon: "success",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Continuar",
      });
    }
  };

  crearPermisos = async () => {
    try {

      let datos = this.state.formularioRellenar;
      
      datos.Usuario = this.state.usuarioSeleccionado;
      datos.Empresa = this.state.usuarioIdEmpresa;

      const url = `${API}/AccesosSistema`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(datos),
      });

      const status = await response.status;

      let respuesta;

      if (status === 200 || status === 201) {
        const data = await response.json();
        respuesta = {
          status: status,
          data: data,
        };
      } else {
        const datamala = await response;
        respuesta = {
          status: status,
          data: datamala,
        };
      }

      return respuesta;

    } catch (error) {
      let respuesta;
      respuesta = {
        status: 500,
        data: error,
      };
      return respuesta;
    }
  };

  editarPermisos = async () => {

    try {

      let datos = this.state.formularioRellenar;

      datos.Usuario = this.state.usuarioSeleccionado;
      let idPermisos = this.state.idAccesoUsuario;

      delete datos._id;

      const url = `${API}/AccesosSistema/${idPermisos}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(datos),
      });
      const status = await response.status;

      let respuesta;
      if (status === 200 || status === 201) {
        const data = await response.json();
        respuesta = {
          status: status,
          data: data,
        };
      } else {
        const datamala = await response;
        respuesta = {
          status: status,
          data: datamala,
        };   
      }

      return respuesta;
    } catch (error) {
      let respuesta;
      respuesta = {
        status: 500,
        data: error,
      };
      return respuesta;
    }
  };

  resetearFormularioRellenar = () => {
    this.setState({
      ...this.state,
      formularioRellenar: {
        Dashboard: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorTrabajadores: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        Contratos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        PlanificacionEquiposDeTrabajo: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        PlanificacionActividades: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        PlanificacionDetalleActividad: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        PlanificacionDetalleTrabajador: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        AsistenciaCheckIn: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        AsistenciaManual: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        AsistenciaQR: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        AsistenciaCheckOut: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosDescanso: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosEnTransito: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosVacaciones: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosLicencia: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosMutual: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosPermisos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ProcesosAusente: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaDesviaciones: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaDelDia: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaNecesidades: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaUtilizacionRelevos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaAsistenciaPersonas: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaAsistenciaCC: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaAuditoria: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaCierreDeMes: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaCosteo: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorUsuarios: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorPerfilesUsuario: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorAccesosSistema: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorSucursales: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorCentroDeCostos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorRegimenDeTrabajo: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorFeriados: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorTitulos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorEspecialidades: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorCargos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorEpp: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        MantenedorDocumentos: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },

        ReporteriaExcel: {
          Ver: false,
          Editar: false,
          Eliminar: false,
        },
      },
    });
  };

  render() {
    return (
      <Layout style={{ height: "100%" }}>

        <Loading cargando={this.state.loading} />

        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Accesos al Sistema"
            subtitulo="Selecciona los permisos que tendrán tus usuarios"
          />
        </Header>

        <hr className="division" />

        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >

          <Row style={{ padding: "6px 0px 22px" }} align="bottom">
            <Col span={24}>
              <Collapse
                bordered={false}
                className="site-collapse-custom-collapse"
                accordion={true}
                defaultActiveKey={["2"]}
              >
                <Panel
                  disabled={false}
                  key="2"
                  header={
                    <h4
                      style={{
                        position: "absolute",
                        bottom: -5,
                        color: "#061fcb",
                      }}
                    >
                      Accesos
                    </h4>
                  }
                  className=" site-collapse-custom-panel"
                >
                  <Row>

                    <Col
                      span={24}
                      style={{
                        paddingTop: "20px", paddingLeft: '26px'
                      }}
                    >
                      <Form.Item
                        label="Usuario"
                      >
                        <Select
                          className="campos"
                          name="Rol"
                          style={{
                            maxWidth: "30%",
                          }}
                          value={this.state.valorSelect}
                          onSelect={(idUsuario) => {
                            this.setState({
                              valorSelect: idUsuario,
                            })
                            this.buscarPermisosDelUsuario(idUsuario);
                          }}
                        >
                          {this.state.usuarios.map((value, index) => {

                            if (value._id !== this.state.usuarioId) {
                              return (
                                <Select.Option
                                  name="Rol"
                                  key={index}
                                  value={value._id}
                                >
                                  {value.Nombres} {value.Apellido_Paterno}
                                </Select.Option>
                              )
                            } else {
                              if (this.state.usuarioRol === "Administrador") {
                                return (
                                  <Select.Option
                                    name="Rol"
                                    key={index}
                                    value={value._id}
                                  >
                                    {value.Nombres} {value.Apellido_Paterno}
                                  </Select.Option>
                                )
                              }
                            }
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Row style={{ padding: "6px 0px 22px" }} align="bottom">
            <Col span={24}>
              <Collapse
                bordered={false}
                className="site-collapse-custom-collapse"
                accordion={true}
                activeKey={this.state.activeKey}
              >
                <Panel
                  disabled={this.state.panelBloqueado}
                  key="1"
                  header={
                    <h4
                      style={{
                        position: "absolute",
                        bottom: -5,
                        color: "#061fcb",
                      }}
                    >
                      Accesos
                    </h4>
                  }
                  className=" site-collapse-custom-panel"
                >
                  <Row>

                    <Col
                      span={24}
                      style={{ paddingTop: "20px", paddingLeft: '26px' }}>

                      <Form.Item label="Perfil de acceso">

                        <Select
                          className="campos"
                          style={{
                            maxWidth: "30%",
                          }}
                          value={this.state.permisoSelected}
                          onSelect={(idPerfil) => {

                            let selectedPerfil;

                            this.state.perfilesUsuarios.forEach((perfil) => {
                              if (perfil._id === idPerfil) selectedPerfil = perfil;
                            })

                            //desvinculándolo de las variables de estado
                            selectedPerfil = JSON.parse(JSON.stringify(selectedPerfil));

                            let perfilName = selectedPerfil.Nombre;

                            delete selectedPerfil.Nombre;
                            delete selectedPerfil.Empresa;
                            delete selectedPerfil._id;
                            delete selectedPerfil.__v;

                            this.setState({
                              formularioRellenar: selectedPerfil,
                              permisoSelected: perfilName,
                            })
                          }}
                        >
                          {this.state.perfilesUsuarios.map((value, index) => (
                            <Select.Option
                              key={index}
                              value={value._id}
                            >
                              {value.Nombre}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                    </Col>

                  </Row>

                  <Row>
                    <Col
                      span={12}
                      style={{
                        padding: "50px",
                        textAlign: "center",
                      }}
                    >
                      <table
                        style={{
                          width: "65%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                textAlign: "center",
                                padding: "5px",
                                borderRadius: "5px 0px 0px 5px",
                                paddingLeft: "30px",
                              }}
                            >
                              Categoría
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                padding: "5px",
                                borderRadius: "0px 5px 5px 0px",
                              }}
                            >
                              Permisos
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td></td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <img
                                    alt="imagen"
                                    style={{
                                      width: "18px",
                                    }}
                                    src={iconOjoGris}
                                  />
                                </Col>
                                <Col span={8}>
                                  <img
                                    alt="imagen"
                                    style={{
                                      width: "15px",
                                    }}
                                    src={iconLapiz}
                                  />
                                </Col>
                                <Col span={8}>
                                  <img
                                    alt="imagen"
                                    style={{
                                      width: "12px",
                                    }}
                                    src={iconBasurero}
                                  />
                                </Col>
                              </Row>
                            </td>
                          </tr>

                          {/* DASHBOARD */}
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos"
                                  src={iconDashboard}
                                />
                                Dashboard
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.Dashboard.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;
                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          Dashboard: {
                                            ...this.state.formularioRellenar.Dashboard,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.Dashboard
                                        .Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;
                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          Dashboard: {
                                            ...this.state.formularioRellenar
                                              .Dashboard,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.Dashboard
                                        .Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          Dashboard: {
                                            ...this.state.formularioRellenar
                                              .Dashboard,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>

                          {/* TRABAJADORES */}
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos"
                                  src={iconTrabajadores}
                                />
                                Trabajadores
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorTrabajadores === undefined ? false :
                                        this.state.formularioRellenar.MantenedorTrabajadores.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;
                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorTrabajadores: {
                                            ...this.state.formularioRellenar.MantenedorTrabajadores,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorTrabajadores === undefined ? false :
                                        this.state.formularioRellenar.MantenedorTrabajadores.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;
                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorTrabajadores: {
                                            ...this.state.formularioRellenar
                                              .MantenedorTrabajadores,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorTrabajadores === undefined ? false :
                                        this.state.formularioRellenar.MantenedorTrabajadores
                                          .Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorTrabajadores: {
                                            ...this.state.formularioRellenar
                                              .MantenedorTrabajadores,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>

                          {/* CONTRATOS */}
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                {/* <img
                                  alt="imagen"
                                  className="icons-sistema-accesos"
                                  src={iconTrabajadores}
                                /> */}
                                <FileDoneOutlined
                                  style={{ color: 'blue', fontSize: '15px', position: 'relative' }}
                                  className="icons-sistema-accesos"
                                />
                                Contratos
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.Contratos === undefined ? false :
                                        this.state.formularioRellenar.Contratos.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;
                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          Contratos: {
                                            ...this.state.formularioRellenar.Contratos,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.Contratos === undefined ? false :
                                        this.state.formularioRellenar.Contratos.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;
                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          Contratos: {
                                            ...this.state.formularioRellenar
                                              .Contratos,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.Contratos === undefined ? false :
                                        this.state.formularioRellenar.Contratos
                                          .Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          Contratos: {
                                            ...this.state.formularioRellenar
                                              .Contratos,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>

                          {/* PLANIFICACION */}
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos"
                                  src={iconAsignaciones}
                                />
                                Planificación
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox onChange={(e) => {

                                    let val = e.target.checked;

                                    this.setState({
                                      formularioRellenar: {
                                        ...this.state.formularioRellenar,
                                        PlanificacionEquiposDeTrabajo: {
                                          ...this.state.formularioRellenar.PlanificacionEquiposDeTrabajo,
                                          Ver: val,
                                        },

                                        PlanificacionActividades: {
                                          ...this.state.formularioRellenar.PlanificacionActividades,
                                          Ver: val,
                                        },

                                        PlanificacionDetalleActividad: {
                                          ...this.state.formularioRellenar.PlanificacionDetalleActividad,
                                          Ver: val,
                                        },

                                        PlanificacionDetalleTrabajador: {
                                          ...this.state.formularioRellenar.PlanificacionDetalleTrabajador,
                                          Ver: val,
                                        }
                                      }
                                    })
                                  }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox onChange={(e) => {

                                    let val = e.target.checked;

                                    this.setState({
                                      formularioRellenar: {
                                        ...this.state.formularioRellenar,

                                        PlanificacionEquiposDeTrabajo: {
                                          ...this.state.formularioRellenar.PlanificacionEquiposDeTrabajo,
                                          Editar: val,
                                        },

                                        PlanificacionActividades: {
                                          ...this.state.formularioRellenar.PlanificacionActividades,
                                          Editar: val,
                                        },

                                        PlanificacionDetalleActividad: {
                                          ...this.state.formularioRellenar.PlanificacionDetalleActividad,
                                          Editar: val,
                                        },

                                        PlanificacionDetalleTrabajador: {
                                          ...this.state.formularioRellenar.PlanificacionDetalleTrabajador,
                                          Editar: val,
                                        }
                                      }
                                    })
                                  }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox onChange={(e) => {

                                    let val = e.target.checked;

                                    this.setState({
                                      formularioRellenar: {
                                        ...this.state.formularioRellenar,

                                        PlanificacionEquiposDeTrabajo: {
                                          ...this.state.formularioRellenar.PlanificacionEquiposDeTrabajo,
                                          Eliminar: val,
                                        },

                                        PlanificacionActividades: {
                                          ...this.state.formularioRellenar.PlanificacionActividades,
                                          Eliminar: val,
                                        },

                                        PlanificacionDetalleActividad: {
                                          ...this.state.formularioRellenar.PlanificacionDetalleActividad,
                                          Eliminar: val,
                                        },

                                        PlanificacionDetalleTrabajador: {
                                          ...this.state.formularioRellenar.PlanificacionDetalleTrabajador,
                                          Eliminar: val,
                                        }
                                      }
                                    })
                                  }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconAsignaciones}
                                />
                                Equipos de Trabajo
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={

                                      this.state.formularioRellenar.PlanificacionEquiposDeTrabajo === undefined ? false :
                                        this.state.formularioRellenar
                                          .PlanificacionEquiposDeTrabajo.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          PlanificacionEquiposDeTrabajo: {
                                            ...this.state.formularioRellenar
                                              .PlanificacionEquiposDeTrabajo,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.PlanificacionEquiposDeTrabajo === undefined ? false :
                                        this.state.formularioRellenar
                                          .PlanificacionEquiposDeTrabajo.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          PlanificacionEquiposDeTrabajo: {
                                            ...this.state.formularioRellenar
                                              .PlanificacionEquiposDeTrabajo,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.PlanificacionEquiposDeTrabajo === undefined ? false :
                                        this.state.formularioRellenar
                                          .PlanificacionEquiposDeTrabajo.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          PlanificacionEquiposDeTrabajo: {
                                            ...this.state.formularioRellenar
                                              .PlanificacionEquiposDeTrabajo,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconAsignaciones}
                                />
                                Actividades
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.PlanificacionActividades === undefined ? false :
                                        this.state.formularioRellenar
                                          .PlanificacionActividades.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          PlanificacionActividades: {
                                            ...this.state.formularioRellenar
                                              .PlanificacionActividades,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.PlanificacionActividades === undefined ? false :
                                        this.state.formularioRellenar
                                          .PlanificacionActividades.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          PlanificacionActividades: {
                                            ...this.state.formularioRellenar
                                              .PlanificacionActividades,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.PlanificacionActividades === undefined ? false :
                                        this.state.formularioRellenar
                                          .PlanificacionActividades.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          PlanificacionActividades: {
                                            ...this.state.formularioRellenar
                                              .PlanificacionActividades,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconAsignaciones}
                                />
                                Detalle de Actividad
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.PlanificacionDetalleActividad === undefined ? false :
                                        this.state.formularioRellenar
                                          .PlanificacionDetalleActividad.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          PlanificacionDetalleActividad: {
                                            ...this.state.formularioRellenar
                                              .PlanificacionDetalleActividad,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.PlanificacionDetalleActividad === undefined ? false :
                                        this.state.formularioRellenar
                                          .PlanificacionDetalleActividad.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          PlanificacionDetalleActividad: {
                                            ...this.state.formularioRellenar
                                              .PlanificacionDetalleActividad,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.PlanificacionDetalleActividad === undefined ? false :
                                        this.state.formularioRellenar
                                          .PlanificacionDetalleActividad.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          PlanificacionDetalleActividad: {
                                            ...this.state.formularioRellenar
                                              .PlanificacionDetalleActividad,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconAsignaciones}
                                />
                                Detalle de Trabajador
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.PlanificacionDetalleTrabajador === undefined ? false :
                                        this.state.formularioRellenar
                                          .PlanificacionDetalleTrabajador.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          PlanificacionDetalleTrabajador: {
                                            ...this.state.formularioRellenar
                                              .PlanificacionDetalleTrabajador,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.PlanificacionDetalleTrabajador === undefined ? false :
                                        this.state.formularioRellenar
                                          .PlanificacionDetalleTrabajador.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          PlanificacionDetalleTrabajador: {
                                            ...this.state.formularioRellenar
                                              .PlanificacionDetalleTrabajador,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.PlanificacionDetalleTrabajador === undefined ? false :
                                        this.state.formularioRellenar
                                          .PlanificacionDetalleTrabajador.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          PlanificacionDetalleTrabajador: {
                                            ...this.state.formularioRellenar
                                              .PlanificacionDetalleTrabajador,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>

                          {/* PROCESOS */}
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos"
                                  src={iconProcesosDelTrabajador}
                                />
                                Procesos del trabajador
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox onChange={(e) => {

                                    let val = e.target.checked;

                                    this.setState({
                                      formularioRellenar: {
                                        ...this.state.formularioRellenar,
                                        ProcesosDescanso: {
                                          ...this.state.formularioRellenar.ProcesosDescanso,
                                          Ver: val,
                                        },

                                        ProcesosEnTransito: {
                                          ...this.state.formularioRellenar.ProcesosEnTransito,
                                          Ver: val,
                                        },

                                        ProcesosVacaciones: {
                                          ...this.state.formularioRellenar.ProcesosVacaciones,
                                          Ver: val,
                                        },

                                        ProcesosLicencia: {
                                          ...this.state.formularioRellenar.ProcesosLicencia,
                                          Ver: val,
                                        },

                                        ProcesosMutual: {
                                          ...this.state.formularioRellenar.ProcesosMutual,
                                          Ver: val,
                                        },

                                        ProcesosPermisos: {
                                          ...this.state.formularioRellenar.ProcesosPermisos,
                                          Ver: val,
                                        },

                                        ProcesosAusente: {
                                          ...this.state.formularioRellenar.ProcesosAusente,
                                          Ver: val,
                                        }
                                      }
                                    })
                                  }}></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox onChange={(e) => {

                                    let val = e.target.checked;

                                    this.setState({
                                      formularioRellenar: {
                                        ...this.state.formularioRellenar,

                                        ProcesosDescanso: {
                                          ...this.state.formularioRellenar.ProcesosDescanso,
                                          Editar: val,
                                        },

                                        ProcesosEnTransito: {
                                          ...this.state.formularioRellenar.ProcesosEnTransito,
                                          Editar: val,
                                        },

                                        ProcesosVacaciones: {
                                          ...this.state.formularioRellenar.ProcesosVacaciones,
                                          Editar: val,
                                        },

                                        ProcesosLicencia: {
                                          ...this.state.formularioRellenar.ProcesosLicencia,
                                          Editar: val,
                                        },

                                        ProcesosMutual: {
                                          ...this.state.formularioRellenar.ProcesosMutual,
                                          Editar: val,
                                        },

                                        ProcesosPermisos: {
                                          ...this.state.formularioRellenar.ProcesosPermisos,
                                          Editar: val,
                                        },

                                        ProcesosAusente: {
                                          ...this.state.formularioRellenar.ProcesosAusente,
                                          Editar: val,
                                        }
                                      }
                                    })
                                  }}></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox onChange={(e) => {

                                    let val = e.target.checked;

                                    this.setState({
                                      formularioRellenar: {
                                        ...this.state.formularioRellenar,

                                        ProcesosDescanso: {
                                          ...this.state.formularioRellenar.ProcesosDescanso,
                                          Eliminar: val,
                                        },

                                        ProcesosEnTransito: {
                                          ...this.state.formularioRellenar.ProcesosDescanso,
                                          Eliminar: val,
                                        },

                                        ProcesosVacaciones: {
                                          ...this.state.formularioRellenar.ProcesosVacaciones,
                                          Eliminar: val,
                                        },

                                        ProcesosLicencia: {
                                          ...this.state.formularioRellenar.ProcesosLicencia,
                                          Eliminar: val,
                                        },

                                        ProcesosMutual: {
                                          ...this.state.formularioRellenar.ProcesosMutual,
                                          Eliminar: val,
                                        },

                                        ProcesosPermisos: {
                                          ...this.state.formularioRellenar.ProcesosPermisos,
                                          Eliminar: val,
                                        },

                                        ProcesosAusente: {
                                          ...this.state.formularioRellenar.ProcesosAusente,
                                          Eliminar: val,
                                        }
                                      }
                                    })
                                  }}></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                Descansos
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosDescanso === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosDescanso.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosDescanso: {
                                            ...this.state.formularioRellenar
                                              .ProcesosDescanso,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosDescanso === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosDescanso.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosDescanso: {
                                            ...this.state.formularioRellenar
                                              .ProcesosDescanso,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosDescanso === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosDescanso.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosDescanso: {
                                            ...this.state.formularioRellenar
                                              .ProcesosDescanso,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                En Tránsito
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosEnTransito === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosEnTransito.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosEnTransito: {
                                            ...this.state.formularioRellenar
                                              .ProcesosEnTransito,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosEnTransito === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosEnTransito.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosEnTransito: {
                                            ...this.state.formularioRellenar
                                              .ProcesosEnTransito,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosEnTransito === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosEnTransito.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosEnTransito: {
                                            ...this.state.formularioRellenar
                                              .ProcesosEnTransito,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                Vacaciones
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosVacaciones === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosVacaciones.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosVacaciones: {
                                            ...this.state.formularioRellenar
                                              .ProcesosVacaciones,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosVacaciones === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosVacaciones.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosVacaciones: {
                                            ...this.state.formularioRellenar
                                              .ProcesosVacaciones,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosVacaciones === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosVacaciones.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosVacaciones: {
                                            ...this.state.formularioRellenar
                                              .ProcesosVacaciones,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                Licencias
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosLicencia === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosLicencia.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosLicencia: {
                                            ...this.state.formularioRellenar
                                              .ProcesosLicencia,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosLicencia === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosLicencia.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosLicencia: {
                                            ...this.state.formularioRellenar
                                              .ProcesosLicencia,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosLicencia === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosLicencia.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosLicencia: {
                                            ...this.state.formularioRellenar
                                              .ProcesosLicencia,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                Licencia mutual
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosMutual === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosMutual.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosMutual: {
                                            ...this.state.formularioRellenar
                                              .ProcesosMutual,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosMutual === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosMutual.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosMutual: {
                                            ...this.state.formularioRellenar
                                              .ProcesosMutual,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosMutual === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosMutual.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosMutual: {
                                            ...this.state.formularioRellenar
                                              .ProcesosMutual,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                Permisos
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosPermisos === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosPermisos.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosPermisos: {
                                            ...this.state.formularioRellenar
                                              .ProcesosPermisos,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosPermisos === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosPermisos.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosPermisos: {
                                            ...this.state.formularioRellenar
                                              .ProcesosPermisos,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosPermisos === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosPermisos.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosPermisos: {
                                            ...this.state.formularioRellenar
                                              .ProcesosPermisos,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                Día Ausente
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosAusente === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosAusente.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosAusente: {
                                            ...this.state.formularioRellenar
                                              .ProcesosAusente,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosAusente === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosAusente.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosAusente: {
                                            ...this.state.formularioRellenar
                                              .ProcesosAusente,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ProcesosAusente === undefined ? false :
                                        this.state.formularioRellenar
                                          .ProcesosAusente.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ProcesosAusente: {
                                            ...this.state.formularioRellenar
                                              .ProcesosAusente,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>

                          {/* ASISTENCIA */}
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos"
                                  src={iconAsistencia}
                                />
                                Asistencia
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox onChange={(e) => {

                                    let val = e.target.checked;

                                    this.setState({
                                      formularioRellenar: {
                                        ...this.state.formularioRellenar,
                                        AsistenciaCheckIn: {
                                          ...this.state.formularioRellenar.AsistenciaCheckIn,
                                          Ver: val,
                                        },

                                        AsistenciaManual: {
                                          ...this.state.formularioRellenar.AsistenciaManual,
                                          Ver: val,
                                        },

                                        AsistenciaQR: {
                                          ...this.state.formularioRellenar.AsistenciaQR,
                                          Ver: val,
                                        },

                                        AsistenciaCheckOut: {
                                          ...this.state.formularioRellenar.AsistenciaCheckOut,
                                          Ver: val,
                                        }
                                      }
                                    })
                                  }}></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox onChange={(e) => {

                                    let val = e.target.checked;

                                    this.setState({
                                      formularioRellenar: {
                                        ...this.state.formularioRellenar,
                                        AsistenciaCheckIn: {
                                          ...this.state.formularioRellenar.AsistenciaCheckIn,
                                          Editar: val,
                                        },

                                        AsistenciaManual: {
                                          ...this.state.formularioRellenar.AsistenciaManual,
                                          Editar: val,
                                        },

                                        AsistenciaQR: {
                                          ...this.state.formularioRellenar.AsistenciaQR,
                                          Editar: val,
                                        },

                                        AsistenciaCheckOut: {
                                          ...this.state.formularioRellenar.AsistenciaCheckOut,
                                          Editar: val,
                                        }
                                      }
                                    })
                                  }}></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox onChange={(e) => {

                                    let val = e.target.checked;

                                    this.setState({
                                      formularioRellenar: {
                                        ...this.state.formularioRellenar,
                                        AsistenciaCheckIn: {
                                          ...this.state.formularioRellenar.AsistenciaCheckIn,
                                          Eliminar: val,
                                        },

                                        AsistenciaManual: {
                                          ...this.state.formularioRellenar.AsistenciaManual,
                                          Eliminar: val,
                                        },

                                        AsistenciaQR: {
                                          ...this.state.formularioRellenar.AsistenciaQR,
                                          Eliminar: val,
                                        },

                                        AsistenciaCheckOut: {
                                          ...this.state.formularioRellenar.AsistenciaCheckOut,
                                          Eliminar: val,
                                        }
                                      }
                                    })
                                  }}></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconValidacionQR}
                                />
                                CheckIn
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.AsistenciaCheckIn === undefined ? false :
                                        this.state.formularioRellenar
                                          .AsistenciaCheckIn.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          AsistenciaCheckIn: {
                                            ...this.state.formularioRellenar
                                              .AsistenciaCheckIn,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.AsistenciaCheckIn === undefined ? false :
                                        this.state.formularioRellenar
                                          .AsistenciaCheckIn.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          AsistenciaCheckIn: {
                                            ...this.state.formularioRellenar
                                              .AsistenciaCheckIn,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.AsistenciaCheckIn === undefined ? false :
                                        this.state.formularioRellenar
                                          .AsistenciaCheckIn.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          AsistenciaCheckIn: {
                                            ...this.state.formularioRellenar
                                              .AsistenciaCheckIn,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconValidacionQR}
                                />
                                Asistencia Manual
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.AsistenciaManual === undefined ? false :
                                        this.state.formularioRellenar
                                          .AsistenciaManual.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          AsistenciaManual: {
                                            ...this.state.formularioRellenar
                                              .AsistenciaManual,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.AsistenciaManual === undefined ? false :
                                        this.state.formularioRellenar
                                          .AsistenciaManual.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          AsistenciaManual: {
                                            ...this.state.formularioRellenar
                                              .AsistenciaManual,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.AsistenciaManual === undefined ? false :
                                        this.state.formularioRellenar
                                          .AsistenciaManual.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          AsistenciaManual: {
                                            ...this.state.formularioRellenar
                                              .AsistenciaManual,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconValidacionQR}
                                />
                                Asistencia QR
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.AsistenciaQR === undefined ? false :
                                        this.state.formularioRellenar
                                          .AsistenciaQR.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          AsistenciaQR: {
                                            ...this.state.formularioRellenar
                                              .AsistenciaQR,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.AsistenciaQR === undefined ? false :
                                        this.state.formularioRellenar
                                          .AsistenciaQR.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          AsistenciaQR: {
                                            ...this.state.formularioRellenar
                                              .AsistenciaQR,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.AsistenciaQR === undefined ? false :
                                        this.state.formularioRellenar
                                          .AsistenciaQR.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          AsistenciaQR: {
                                            ...this.state.formularioRellenar
                                              .AsistenciaQR,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconValidacionQR}
                                />
                                CheckOut
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.AsistenciaCheckOut === undefined ? false :
                                        this.state.formularioRellenar
                                          .AsistenciaCheckOut.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          AsistenciaCheckOut: {
                                            ...this.state.formularioRellenar
                                              .AsistenciaCheckOut,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.AsistenciaCheckOut === undefined ? false :
                                        this.state.formularioRellenar
                                          .AsistenciaCheckOut.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          AsistenciaCheckOut: {
                                            ...this.state.formularioRellenar
                                              .AsistenciaCheckOut,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.AsistenciaCheckOut === undefined ? false :
                                        this.state.formularioRellenar
                                          .AsistenciaCheckOut.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          AsistenciaCheckOut: {
                                            ...this.state.formularioRellenar
                                              .AsistenciaCheckOut,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>

                        </tbody>

                      </table>

                    </Col>
                    <Col
                      style={{
                        padding: "50px",
                        textAlign: "center",
                      }}
                      span={12}
                    >
                      <table
                        style={{
                          width: "65%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                textAlign: "left",
                                padding: "5px",
                                borderRadius: "5px 0px 0px 5px",
                                paddingLeft: "30px",
                              }}
                            >
                              Categoría
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                padding: "5px",
                                borderRadius: "0px 5px 5px 0px",
                              }}
                            >
                              Permisos
                            </th>
                          </tr>
                        </thead>

                        <tbody>

                          <tr>
                            <td></td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <img
                                    alt="imagen"
                                    style={{
                                      width: "18px",
                                    }}
                                    src={iconOjoGris}
                                  />
                                </Col>
                                <Col span={8}>
                                  <img
                                    alt="imagen"
                                    style={{
                                      width: "15px",
                                    }}
                                    src={iconLapiz}
                                  />
                                </Col>
                                <Col span={8}>
                                  <img
                                    alt="imagen"
                                    style={{
                                      width: "12px",
                                    }}
                                    src={iconBasurero}
                                  />
                                </Col>
                              </Row>
                            </td>
                          </tr>

                          {/* REPORTERÍA*/}
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos"
                                  src={iconProcesosDelTrabajador}
                                />
                                Reportería
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox onChange={(e) => {

                                    let val = e.target.checked;

                                    this.setState({
                                      formularioRellenar: {
                                        ...this.state.formularioRellenar,

                                        ReporteriaDesviaciones: {
                                          ...this.state.formularioRellenar.ReporteriaDesviaciones,
                                          Ver: val,
                                        },

                                        ReporteriaDelDia: {
                                          ...this.state.formularioRellenar.ReporteriaDelDia,
                                          Ver: val,
                                        },

                                        ReporteriaNecesidades: {
                                          ...this.state.formularioRellenar.ReporteriaNecesidades,
                                          Ver: val,
                                        },

                                        
                                        ReporteriaUtilizacionRelevos: {
                                          ...this.state.formularioRellenar.ReporteriaUtilizacionRelevos,
                                          Ver: val,
                                        },

                                        ReporteriaAsistenciaPersonas: {
                                          ...this.state.formularioRellenar.ReporteriaAsistenciaPersonas,
                                          Ver: val,
                                        },

                                        ReporteriaAsistenciaCC: {
                                          ...this.state.formularioRellenar.ReporteriaAsistenciaCC,
                                          Ver: val,
                                        },

                                        ReporteriaAuditoria: {
                                          ...this.state.formularioRellenar.ReporteriaAuditoria,
                                          Ver: val,
                                        },

                                        ReporteriaCierreDeMes: {
                                          ...this.state.formularioRellenar.ReporteriaCierreDeMes,
                                          Ver: val,
                                        },

                                        ReporteriaCosteo: {
                                          ...this.state.formularioRellenar.ReporteriaCosteo,
                                          Ver: val,
                                        },
                                      }
                                    })
                                  }}></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox onChange={(e) => {

                                    let val = e.target.checked;

                                    this.setState({
                                      formularioRellenar: {
                                        ...this.state.formularioRellenar,

                                        ReporteriaDesviaciones: {
                                          ...this.state.formularioRellenar.ReporteriaDesviaciones,
                                          Editar: val,
                                        },

                                        ReporteriaDelDia: {
                                          ...this.state.formularioRellenar.ReporteriaDelDia,
                                          Editar: val,
                                        },

                                        ReporteriaNecesidades: {
                                          ...this.state.formularioRellenar.ReporteriaNecesidades,
                                          Editar: val,
                                        },

                                        ReporteriaUtilizacionRelevos: {
                                          ...this.state.formularioRellenar.ReporteriaUtilizacionRelevos,
                                          Editar: val,
                                        },

                                        ReporteriaAsistenciaPersonas: {
                                          ...this.state.formularioRellenar.ReporteriaAsistenciaPersonas,
                                          Editar: val,
                                        },

                                        ReporteriaAsistenciaCC: {
                                          ...this.state.formularioRellenar.ReporteriaAsistenciaCC,
                                          Editar: val,
                                        },

                                        ReporteriaAuditoria: {
                                          ...this.state.formularioRellenar.ReporteriaAuditoria,
                                          Editar: val,
                                        },

                                        ReporteriaCierreDeMes: {
                                          ...this.state.formularioRellenar.ReporteriaCierreDeMes,
                                          Editar: val,
                                        },

                                        ReporteriaCosteo: {
                                          ...this.state.formularioRellenar.ReporteriaCosteo,
                                          Editar: val,
                                        },
                                      }
                                    })
                                  }}></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox onChange={(e) => {

                                    let val = e.target.checked;

                                    this.setState({
                                      formularioRellenar: {
                                        ...this.state.formularioRellenar,

                                        ReporteriaDesviaciones: {
                                          ...this.state.formularioRellenar.ReporteriaDesviaciones,
                                          Eliminar: val,
                                        },

                                        ReporteriaDelDia: {
                                          ...this.state.formularioRellenar.ReporteriaDelDia,
                                          Eliminar: val,
                                        },

                                        ReporteriaNecesidades: {
                                          ...this.state.formularioRellenar.ReporteriaNecesidades,
                                          Eliminar: val,
                                        },

                                        ReporteriaUtilizacionRelevos: {
                                          ...this.state.formularioRellenar.ReporteriaUtilizacionRelevos,
                                          Eliminar: val,
                                        },

                                        ReporteriaAsistenciaPersonas: {
                                          ...this.state.formularioRellenar.ReporteriaAsistenciaPersonas,
                                          Eliminar: val,
                                        },

                                        ReporteriaAsistenciaCC: {
                                          ...this.state.formularioRellenar.ReporteriaAsistenciaCC,
                                          Eliminar: val,
                                        },

                                        ReporteriaAuditoria: {
                                          ...this.state.formularioRellenar.ReporteriaAuditoria,
                                          Eliminar: val,
                                        },

                                        ReporteriaCierreDeMes: {
                                          ...this.state.formularioRellenar.ReporteriaCierreDeMes,
                                          Eliminar: val,
                                        },

                                        ReporteriaCosteo: {
                                          ...this.state.formularioRellenar.ReporteriaCosteo,
                                          Eliminar: val,
                                        },
                                      }
                                    })
                                  }}></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                Desviaciones
                              </h4>
                            </td>

                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaDesviaciones === undefined ? false :
                                        this.state.formularioRellenar.ReporteriaDesviaciones.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaDesviaciones: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaDesviaciones,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaDesviaciones === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaDesviaciones.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaDesviaciones: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaDesviaciones,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaDesviaciones === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaDesviaciones.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaDesviaciones: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaDesviaciones,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                Reporte del día
                              </h4>
                            </td>

                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaDelDia === undefined ? false :
                                        this.state.formularioRellenar.ReporteriaDelDia.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaDelDia: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaDelDia,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaDelDia === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaDelDia.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaDelDia: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaDelDia,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaDelDia === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaDelDia.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaDelDia: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaDelDia,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                Reporte de Necesidades
                              </h4>
                            </td>

                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaNecesidades === undefined ? false :
                                        this.state.formularioRellenar.ReporteriaNecesidades.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaNecesidades: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaNecesidades,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaNecesidades === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaNecesidades.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaNecesidades: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaNecesidades,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaNecesidades === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaNecesidades.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaNecesidades: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaNecesidades,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                Reporte de Utilización Relevos
                              </h4>
                            </td>

                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaUtilizacionRelevos === undefined ? false :
                                        this.state.formularioRellenar.ReporteriaUtilizacionRelevos.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaUtilizacionRelevos: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaUtilizacionRelevos,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaUtilizacionRelevos === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaUtilizacionRelevos.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaUtilizacionRelevos: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaUtilizacionRelevos,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaUtilizacionRelevos === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaUtilizacionRelevos.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaUtilizacionRelevos: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaUtilizacionRelevos,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                Asistencia Personas
                              </h4>
                            </td>

                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaAsistenciaPersonas === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaAsistenciaPersonas.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaAsistenciaPersonas: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaAsistenciaPersonas,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaAsistenciaPersonas === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaAsistenciaPersonas.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaAsistenciaPersonas: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaAsistenciaPersonas,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaAsistenciaPersonas === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaAsistenciaPersonas.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaAsistenciaPersonas: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaAsistenciaPersonas,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                Asistencia por CC
                              </h4>
                            </td>

                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaAsistenciaCC === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaAsistenciaCC.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaAsistenciaCC: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaAsistenciaCC,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaAsistenciaCC === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaAsistenciaCC.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaAsistenciaCC: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaAsistenciaCC,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaAsistenciaCC === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaAsistenciaCC.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaAsistenciaCC: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaAsistenciaCC,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                Auditoría
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaAuditoria === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaAuditoria.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaAuditoria: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaAuditoria,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaAuditoria === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaAuditoria.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaAuditoria: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaAuditoria,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaAuditoria === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaAuditoria.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaAuditoria: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaAuditoria,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                Cierre De Mes
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaCierreDeMes === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaCierreDeMes.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaCierreDeMes: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaCierreDeMes,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaCierreDeMes === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaCierreDeMes.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaCierreDeMes: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaCierreDeMes,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaCierreDeMes === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaCierreDeMes.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaCierreDeMes: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaCierreDeMes,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconProcesosDelTrabajador}
                                />
                                Costeo
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaCosteo === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaCosteo.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaCosteo: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaCosteo,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaCosteo === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaCosteo.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaCosteo: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaCosteo,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaCosteo === undefined ? false :
                                        this.state.formularioRellenar
                                          .ReporteriaCosteo.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaCosteo: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaCosteo,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>

                          {/* MANTENEDORES */}
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos"
                                  src={iconMantenedores}
                                />
                                Mantenedores
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox onChange={(e) => {

                                    let val = e.target.checked;

                                    this.setState({
                                      formularioRellenar: {
                                        ...this.state.formularioRellenar,
                                        MantenedorUsuarios: {
                                          ...this.state.formularioRellenar.MantenedorUsuarios,
                                          Ver: val,
                                        },

                                        MantenedorPerfilesUsuario: {
                                          ...this.state.formularioRellenar.MantenedorPerfilesUsuario,
                                          Ver: val,
                                        },

                                        MantenedorAccesosSistema: {
                                          ...this.state.formularioRellenar.MantenedorAccesosSistema,
                                          Ver: val,
                                        },

                                        MantenedorSucursales: {
                                          ...this.state.formularioRellenar.MantenedorSucursales,
                                          Ver: val,
                                        },

                                        MantenedorCentroDeCostos: {
                                          ...this.state.formularioRellenar.MantenedorCentroDeCostos,
                                          Ver: val,
                                        },

                                        MantenedorRegimenDeTrabajo: {
                                          ...this.state.formularioRellenar.MantenedorRegimenDeTrabajo,
                                          Ver: val,
                                        },

                                        MantenedorFeriados: {
                                          ...this.state.formularioRellenar.MantenedorFeriados,
                                          Ver: val,
                                        },

                                        MantenedorTitulos: {
                                          ...this.state.formularioRellenar.MantenedorTitulos,
                                          Ver: val,
                                        },

                                        MantenedorEspecialidades: {
                                          ...this.state.formularioRellenar.MantenedorEspecialidades,
                                          Ver: val,
                                        },

                                        MantenedorCargos: {
                                          ...this.state.formularioRellenar.MantenedorCargos,
                                          Ver: val,
                                        },

                                        MantenedorEpp: {
                                          ...this.state.formularioRellenar.MantenedorEpp,
                                          Ver: val,
                                        },

                                        MantenedorDocumentos: {
                                          ...this.state.formularioRellenar.MantenedorDocumentos,
                                          Ver: val,
                                        }
                                      }
                                    })
                                  }}></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox onChange={(e) => {

                                    let val = e.target.checked;

                                    this.setState({
                                      formularioRellenar: {
                                        ...this.state.formularioRellenar,
                                        MantenedorUsuarios: {
                                          ...this.state.formularioRellenar.MantenedorUsuarios,
                                          Editar: val,
                                        },

                                        MantenedorPerfilesUsuario: {
                                          ...this.state.formularioRellenar.MantenedorPerfilesUsuario,
                                          Editar: val,
                                        },

                                        MantenedorAccesosSistema: {
                                          ...this.state.formularioRellenar.MantenedorAccesosSistema,
                                          Editar: val,
                                        },

                                        MantenedorSucursales: {
                                          ...this.state.formularioRellenar.MantenedorSucursales,
                                          Editar: val,
                                        },

                                        MantenedorCentroDeCostos: {
                                          ...this.state.formularioRellenar.MantenedorCentroDeCostos,
                                          Editar: val,
                                        },

                                        MantenedorRegimenDeTrabajo: {
                                          ...this.state.formularioRellenar.MantenedorRegimenDeTrabajo,
                                          Editar: val,
                                        },

                                        MantenedorFeriados: {
                                          ...this.state.formularioRellenar.MantenedorFeriados,
                                          Editar: val,
                                        },

                                        MantenedorTitulos: {
                                          ...this.state.formularioRellenar.MantenedorTitulos,
                                          Editar: val,
                                        },

                                        MantenedorEspecialidades: {
                                          ...this.state.formularioRellenar.MantenedorEspecialidades,
                                          Editar: val,
                                        },

                                        MantenedorCargos: {
                                          ...this.state.formularioRellenar.MantenedorCargos,
                                          Editar: val,
                                        },

                                        MantenedorEpp: {
                                          ...this.state.formularioRellenar.MantenedorEpp,
                                          Editar: val,
                                        },

                                        MantenedorDocumentos: {
                                          ...this.state.formularioRellenar.MantenedorDocumentos,
                                          Editar: val,
                                        },
                                      }
                                    })
                                  }}></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox onChange={(e) => {

                                    let val = e.target.checked;

                                    this.setState({
                                      formularioRellenar: {
                                        ...this.state.formularioRellenar,
                                        MantenedorUsuarios: {
                                          ...this.state.formularioRellenar.MantenedorUsuarios,
                                          Eliminar: val,
                                        },

                                        MantenedorPerfilesUsuario: {
                                          ...this.state.formularioRellenar.MantenedorPerfilesUsuario,
                                          Eliminar: val,
                                        },

                                        MantenedorAccesosSistema: {
                                          ...this.state.formularioRellenar.MantenedorAccesosSistema,
                                          Eliminar: val,
                                        },

                                        MantenedorSucursales: {
                                          ...this.state.formularioRellenar.MantenedorSucursales,
                                          Eliminar: val,
                                        },

                                        MantenedorCentroDeCostos: {
                                          ...this.state.formularioRellenar.MantenedorCentroDeCostos,
                                          Eliminar: val,
                                        },

                                        MantenedorRegimenDeTrabajo: {
                                          ...this.state.formularioRellenar.MantenedorRegimenDeTrabajo,
                                          Eliminar: val,
                                        },

                                        MantenedorFeriados: {
                                          ...this.state.formularioRellenar.MantenedorFeriados,
                                          Eliminar: val,
                                        },

                                        MantenedorTitulos: {
                                          ...this.state.formularioRellenar.MantenedorTitulos,
                                          Eliminar: val,
                                        },

                                        MantenedorEspecialidades: {
                                          ...this.state.formularioRellenar.MantenedorEspecialidades,
                                          Eliminar: val,
                                        },

                                        MantenedorCargos: {
                                          ...this.state.formularioRellenar.MantenedorCargos,
                                          Eliminar: val,
                                        },

                                        MantenedorEpp: {
                                          ...this.state.formularioRellenar.MantenedorEpp,
                                          Eliminar: val,
                                        },

                                        MantenedorDocumentos: {
                                          ...this.state.formularioRellenar.MantenedorDocumentos,
                                          Eliminar: val,
                                        },
                                      }
                                    })
                                  }}></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconDashboard}
                                />
                                Usuarios
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorUsuarios === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorUsuarios.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorUsuarios: {
                                            ...this.state.formularioRellenar
                                              .MantenedorUsuarios,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorUsuarios === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorUsuarios.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorUsuarios: {
                                            ...this.state.formularioRellenar
                                              .MantenedorUsuarios,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorUsuarios === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorUsuarios.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorUsuarios: {
                                            ...this.state.formularioRellenar
                                              .MantenedorUsuarios,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconDashboard}
                                />
                                Perfiles de Usuario
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorPerfilesUsuario === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorPerfilesUsuario.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorPerfilesUsuario: {
                                            ...this.state.formularioRellenar
                                              .MantenedorPerfilesUsuario,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorPerfilesUsuario === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorPerfilesUsuario.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorPerfilesUsuario: {
                                            ...this.state.formularioRellenar
                                              .MantenedorPerfilesUsuario,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorPerfilesUsuario === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorPerfilesUsuario.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorPerfilesUsuario: {
                                            ...this.state.formularioRellenar
                                              .MantenedorPerfilesUsuario,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconDashboard}
                                />
                                Accesos al Sistema
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorAccesosSistema === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorAccesosSistema.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorAccesosSistema: {
                                            ...this.state.formularioRellenar
                                              .MantenedorAccesosSistema,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorAccesosSistema === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorAccesosSistema.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorAccesosSistema: {
                                            ...this.state.formularioRellenar
                                              .MantenedorAccesosSistema,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorAccesosSistema === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorAccesosSistema.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorAccesosSistema: {
                                            ...this.state.formularioRellenar
                                              .MantenedorAccesosSistema,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconDashboard}
                                />
                                Sucursales
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorSucursales === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorSucursales.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorSucursales: {
                                            ...this.state.formularioRellenar
                                              .MantenedorSucursales,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorSucursales === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorSucursales.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorSucursales: {
                                            ...this.state.formularioRellenar
                                              .MantenedorSucursales,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorSucursales === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorSucursales.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorSucursales: {
                                            ...this.state.formularioRellenar
                                              .MantenedorSucursales,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconDashboard}
                                />
                                Centros de costo
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorCentroDeCostos === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorCentroDeCostos.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorCentroDeCostos: {
                                            ...this.state.formularioRellenar
                                              .MantenedorCentroDeCostos,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorCentroDeCostos === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorCentroDeCostos.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorCentroDeCostos: {
                                            ...this.state.formularioRellenar
                                              .MantenedorCentroDeCostos,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorCentroDeCostos === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorCentroDeCostos.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorCentroDeCostos: {
                                            ...this.state.formularioRellenar
                                              .MantenedorCentroDeCostos,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconDashboard}
                                />
                                Régimen
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorRegimenDeTrabajo === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorRegimenDeTrabajo.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorRegimenDeTrabajo: {
                                            ...this.state.formularioRellenar
                                              .MantenedorRegimenDeTrabajo,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorRegimenDeTrabajo === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorRegimenDeTrabajo.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorRegimenDeTrabajo: {
                                            ...this.state.formularioRellenar
                                              .MantenedorRegimenDeTrabajo,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorRegimenDeTrabajo === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorRegimenDeTrabajo.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorRegimenDeTrabajo: {
                                            ...this.state.formularioRellenar
                                              .MantenedorRegimenDeTrabajo,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconDashboard}
                                />
                                Feriados
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorFeriados === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorFeriados.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorFeriados: {
                                            ...this.state.formularioRellenar
                                              .MantenedorFeriados,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorFeriados === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorFeriados.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorFeriados: {
                                            ...this.state.formularioRellenar
                                              .MantenedorFeriados,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorFeriados === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorFeriados.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorFeriados: {
                                            ...this.state.formularioRellenar
                                              .MantenedorFeriados,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconDashboard}
                                />
                                Títulos
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorTitulos === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorTitulos.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorTitulos: {
                                            ...this.state.formularioRellenar
                                              .MantenedorTitulos,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorTitulos === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorTitulos.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorTitulos: {
                                            ...this.state.formularioRellenar
                                              .MantenedorTitulos,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorTitulos === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorTitulos.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorTitulos: {
                                            ...this.state.formularioRellenar
                                              .MantenedorTitulos,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconDashboard}
                                />
                                Especialidades
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorEspecialidades === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorEspecialidades.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorEspecialidades: {
                                            ...this.state.formularioRellenar
                                              .MantenedorEspecialidades,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorEspecialidades === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorEspecialidades.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorEspecialidades: {
                                            ...this.state.formularioRellenar
                                              .MantenedorEspecialidades,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorEspecialidades === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorEspecialidades.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorEspecialidades: {
                                            ...this.state.formularioRellenar
                                              .MantenedorEspecialidades,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconDashboard}
                                />
                                Cargos
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorCargos === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorCargos.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorCargos: {
                                            ...this.state.formularioRellenar
                                              .MantenedorCargos,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorCargos === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorCargos.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorCargos: {
                                            ...this.state.formularioRellenar
                                              .MantenedorCargos,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorCargos === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorCargos.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorCargos: {
                                            ...this.state.formularioRellenar
                                              .MantenedorCargos,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconDashboard}
                                />
                                Elementos de protección
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorEpp === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorEpp.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorEpp: {
                                            ...this.state.formularioRellenar
                                              .MantenedorEpp,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorEpp === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorEpp.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorEpp: {
                                            ...this.state.formularioRellenar
                                              .MantenedorEpp,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorEpp === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorEpp.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorEpp: {
                                            ...this.state.formularioRellenar
                                              .MantenedorEpp,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos icon-no-visible"
                                  src={iconDashboard}
                                />
                                Documentos
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorDocumentos === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorDocumentos.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorDocumentos: {
                                            ...this.state.formularioRellenar
                                              .MantenedorDocumentos,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorDocumentos === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorDocumentos.Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorDocumentos: {
                                            ...this.state.formularioRellenar
                                              .MantenedorDocumentos,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.MantenedorDocumentos === undefined ? false :
                                        this.state.formularioRellenar
                                          .MantenedorDocumentos.Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          MantenedorDocumentos: {
                                            ...this.state.formularioRellenar
                                              .MantenedorDocumentos,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>

                            {/* Reporteria Excel */}
                            <tr>
                            <td>
                              {" "}
                              <h4>
                                <img
                                  alt="imagen"
                                  className="icons-sistema-accesos"
                                  src={iconExcel}
                                />
                                Reporteria Excel
                              </h4>
                            </td>
                            <td>
                              <Row>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaExcel.Ver
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;
                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaExcel: {
                                            ...this.state.formularioRellenar.ReporteriaExcel,
                                            Ver: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaExcel
                                        .Editar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;
                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaExcel: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaExcel,
                                            Editar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                                <Col span={8}>
                                  <Checkbox
                                    checked={
                                      this.state.formularioRellenar.ReporteriaExcel
                                        .Eliminar
                                    }
                                    onChange={(e) => {
                                      let valor = e.target.checked;

                                      this.setState({
                                        ...this.state,
                                        formularioRellenar: {
                                          ...this.state.formularioRellenar,
                                          ReporteriaExcel: {
                                            ...this.state.formularioRellenar
                                              .ReporteriaExcel,
                                            Eliminar: valor,
                                          },
                                        },
                                      });
                                    }}
                                  ></Checkbox>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      span={24}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <Button
                        onClick={() => {
                          this.darPermisos();
                        }}
                        //size={size}
                        style={{ marginRight: "20px" }}

                        className={!this.state.Editar ? "guardar-form-disabled" : "btn-verde"}
                        disabled={!this.state.Editar}
                      >
                        Dar Permisos
                      </Button>
                    </Col>
                  </Row>

                </Panel>
              </Collapse>
            </Col>
          </Row>

        </Content>

      </Layout>
    );
  }
}

export default AccesosSistema;
