import {
  BUSCAR_TRABAJADORES_PERMISO,
  ERROR_PERMISO,
  LOADING_PERMISO,
  INSERTAR_FECHAS_FORM,
  INSERTAR_TRABAJADOR_FORM,
  INSERTAR_DATOS_FORMULARIO,
  ENVIAR_CORREO,
  LOADING_PERMISO_FALSE,
  BUSCAR_CONTRATOS_TRABAJADOR
} from "../types/permisoTypes";


import Swal from "sweetalert2";
// import { notification } from "antd";
import moment from 'moment';
import API from "../Api/api";

import {
  momentToStringFechaInicio,
  momentToStringFechaFin,
  databaseFormat,
  obtenerFechaFormateadaMismaHora,

  convertirFechaString
} from "../../funcionesGenericas/fechas";

// export const insertarDatos = (
//   TipoPermiso,
//   Motivo,
//   DiasHabiles,
//   DiasInhabiles,
//   DiasTotal
// ) => async (dispatch, getState) => {

//   dispatch({
//     type: LOADING_PERMISO,
//   });

//   try {

//     const store = getState().PermisoReducer;
//     const {
//       form: { Fechas, Trabajador, Correo: envioCorreo },
//     } = store;

//     let fechaInicioPermiso = moment(Fechas[0]);
//     let fechaFinPermiso = moment(Fechas[1]);

//     let objPermisos = {
//       IdTrabajador: Trabajador,
//       TipoPermiso,
//       Motivo,
//       DiasHabiles,
//       DiasInhabiles,
//       DiasTotal,
//       Fecha_Inicio: Fechas[0],
//       Fecha_Fin: Fechas[1],
//     };

//     console.log(objPermisos);

//     const urlPermisos = `${API}/Permisos`;
//     const requestPermiso = await fetch(urlPermisos, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//       body: JSON.stringify(objPermisos),
//     });

//     const responsePermiso = await requestPermiso.json();
//     const statusPermiso = await requestPermiso.status;

//     switch (statusPermiso) {
//       case 201:
//         let idRegistroPermiso = responsePermiso.data._id;

//         console.log(idRegistroPermiso);

//         let cantidadDias =
//           fechaFinPermiso.diff(fechaInicioPermiso, "days") + 1;

//         let arregloDeDias = [];

//         for (let index = 0; index < cantidadDias; index++) {
//           let fecha = fechaInicioPermiso;
//           fecha = fecha.add(index === 0 ? 0 : 1, "day");

//           const aux = {
//             Fecha: new Date(fecha),
//             IdTrabajador: Trabajador,
//             TipoRegistroBitacoraTrabajador: "5f3eec876dd7cc57f0d3a25f",
//             InicioTrabajo: index === 0 ? true : false,
//             FinTrabajo: index === cantidadDias - 1 ? true : false,
//             Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,
//             Permiso: idRegistroPermiso,
//           };

//           arregloDeDias.push(aux);
//         }

//         let objetoEnviar = {
//           arregloDeDias,
//           fechaInicio: Fechas[0],
//           fechaFin: Fechas[1],
//         };

//         const url = `${API}/IngresoDeDias/${Trabajador}`;
//         const request = await fetch(url, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//           },
//           body: JSON.stringify(objetoEnviar),
//         });

//         const response = await request.json();
//         const status = await request.status;

//         switch (status) {
//           case 201:

//             ////////////////////////////////////////////////////////////////////////////////

//             if (envioCorreo) {
//               const url = `${API}/Enviar/Correo/Permiso/${idRegistroPermiso}`;
//               await fetch(url, {
//                 method: "POST",
//                 headers: {
//                   "Content-Type": "application/json",
//                   Accept: "application/json",
//                 },
//               })
//             }

//             ////////////////////////////////////////////////////////////////////////////////

//             Swal.fire({
//               title: "Bien",
//               text: "Se ingresaron exitosamente los días de permiso",
//               icon: "success",
//               confirmButtonColor: "#E60AA3",
//               confirmButtonText: "Continuar",
//             });

//             dispatch({
//               type: INSERTAR_DATOS_FORMULARIO,
//               payload: response,
//             });

//             break;
//           case 400:
//             Swal.fire({
//               title: "Error",
//               text: response.err.message,
//               icon: "error",
//             });
//             break;
//           case 500:
//             Swal.fire({
//               title: "Error",
//               text: response.err.message,
//               icon: "error",
//             });

//             dispatch({
//               type: ERROR_PERMISO,
//               payload: response.err,
//             });

//             break;
//           default:
//             Swal.fire({
//               title: "Error",
//               text:
//                 "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
//               icon: "error",
//             });
//             break;
//         }

//         break;
//       case 400:
//         Swal.fire({
//           title: "Error",
//           text: responsePermiso.err.message,
//           icon: "error",
//         });
//         dispatch({
//           type: ERROR_PERMISO,
//           payload: response.err,
//         });
//         break;
//       case 500:
//         Swal.fire({
//           title: "Error",
//           text: responsePermiso.err.message,
//           icon: "error",
//         });
//         dispatch({
//           type: ERROR_PERMISO,
//           payload: response.err,
//         });
//         break;
//       default:
//         Swal.fire({
//           title: "Error",
//           text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
//           icon: "error",
//         });
//         dispatch({
//           type: ERROR_PERMISO,
//           payload: response.err,
//         });
//     }



//     // let cantidadDias = fechaFinLicencia.diff(fechaInicioLicencia, "days") + 1;

//     // for (let index = 0; index < cantidadDias; index++) {

//     //   let fecha = fechaInicioLicencia;

//     //   fecha = fecha.add(index === 0 ? 0 : 1, "day");

//     //   const aux = {
//     //     Fecha: new Date(fecha),
//     //     IdTrabajador: Trabajador,
//     //     TipoRegistroBitacoraTrabajador: "5f3eec876dd7cc57f0d3a25f",
//     //   };

//     //   arregloDeDias.push(aux);
//     // }

//     // let objIngresoDias = {
//     //   fechaInicio: Fechas[0],
//     //   fechaFin: Fechas[1],
//     //   arregloDeDias
//     // };

//     // const url = `${API}/IngresoDeDias/${Trabajador._id}`;

//     // const request = await fetch(url, {
//     //   method: "POST",
//     //   headers: {
//     //     "Content-Type": "application/json",
//     //     Accept: "application/json",
//     //   },
//     //   body: JSON.stringify(objIngresoDias),
//     // });

//     // const response = await request.json();
//     // const status = await request.status;

//     // switch (status) {
//     //   case 201:

//     //     Swal.fire({
//     //       title: 'Exitoso',
//     //       text: 'Se han ingresado exitosamente las vacaciones',
//     //       icon: 'success'
//     //     });

//     //     dispatch({
//     //       type: INSERTAR_DATOS_FORMULARIO,
//     //       payload: response,
//     //     });

//     //     break;
//     //   case 400:

//     //     Swal.fire({
//     //       title: "Error",
//     //       text: response.err.message,
//     //       icon: "error",
//     //     });
//     //     break;
//     //   case 500:
//     //     Swal.fire({
//     //       title: "Error",
//     //       text: response.err.message,
//     //       icon: "error",
//     //     });
//     //     break;
//     //   default:
//     //     Swal.fire({
//     //       title: "Error",
//     //       text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
//     //       icon: "error",
//     //     });
//     //     break;
//     // }
//   } catch (error) {

//     dispatch({
//       type: ERROR_PERMISO,
//       payload: error.message,
//     });
//   }
// };

export const insertarDatosAvanzado = (
  TipoPermiso,
  Motivo,
  DiasHabiles,
  DiasInhabiles,
  DiasTotal,
  Observacion
) => async (dispatch, getState) => {

  dispatch({
    type: LOADING_PERMISO,
  });

  try {
   
    const store = getState().PermisoReducer;
    const {
      form: { Fechas, Trabajador, Correo: envioCorreo },
    } = store;

    // let fechaInicioPermiso = moment.utc(momentToStringFechaInicio(moment(Fechas[0]), databaseFormat));
    // let fechaFinPermiso = moment.utc(momentToStringFechaFin(moment(Fechas[1]), databaseFormat));

    let fechaInicioPermiso = moment.utc(convertirFechaString(moment(Fechas[0]), 1).fechaString);
    let fechaFinPermiso = moment.utc(convertirFechaString(moment(Fechas[1]), 2).fechaString);

    let objPermisos = {
      IdTrabajador: Trabajador,
      TipoPermiso,
      Motivo,
      DiasHabiles,
      DiasInhabiles,
      DiasTotal,
      Observacion,

      // Fecha_Inicio: momentToStringFechaInicio(moment(Fechas[0]), databaseFormat),
      // Fecha_Fin: momentToStringFechaFin(moment(Fechas[1]), databaseFormat),

      Fecha_Inicio: convertirFechaString(moment(Fechas[0]), 1).fechaString,
      Fecha_Fin: convertirFechaString(moment(Fechas[1]), 2).fechaString,
    };

    const urlPermisos = `${API}/Permisos/Avanzado`;
    const requestPermiso = await fetch(urlPermisos, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objPermisos),
    });

    const responsePermiso = await requestPermiso.json();
    const statusPermiso = await requestPermiso.status;

    switch (statusPermiso) {
      case 201:
        let idRegistroPermiso = responsePermiso.data._id;

        let cantidadDias =
          fechaFinPermiso.diff(fechaInicioPermiso, "days") + 1;

        let arregloDeDias = [];

        for (let index = 0; index < cantidadDias; index++) {
          let fecha = fechaInicioPermiso;
          fecha = fecha.add(index === 0 ? 0 : 1, "day");

          const aux = {
            // Fecha: obtenerFechaFormateadaMismaHora(fecha.set({ hour: 12 }), databaseFormat),

            Fecha: convertirFechaString(fecha, 1).fechaString,
            IdTrabajador: Trabajador,
            TipoRegistroBitacoraTrabajador: "5f3eec876dd7cc57f0d3a25f",
            InicioTrabajo: index === 0 ? true : false,
            FinTrabajo: index === cantidadDias - 1 ? true : false,
            Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,
            Permiso: idRegistroPermiso,
            Observacion: Observacion,
            // Fecha_Inicio: momentToStringFechaInicio(moment(Fechas[0]), databaseFormat),
            // Fecha_Fin: momentToStringFechaFin(moment(Fechas[1]), databaseFormat),

            Fecha_Inicio: objPermisos.Fecha_Inicio,
            Fecha_Fin: objPermisos.Fecha_Fin,
          };

          arregloDeDias.push(aux);
        }

        let objetoEnviar = {
          arregloDeDias,
          fechaInicio: objPermisos.Fecha_Inicio,
          fechaFin: objPermisos.Fecha_Fin,
        };

        const url = `${API}/IngresoDeDias/${Trabajador}`;
        const request = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objetoEnviar),
        });

        const response = await request.json();
        const status = await request.status;

        switch (status) {
          case 201:

            ////////////////////////////////////////////////////////////////////////////////

            if (envioCorreo) {
              const url = `${API}/Enviar/Correo/Permiso/${idRegistroPermiso}`;
              await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              })
            }

            ////////////////////////////////////////////////////////////////////////////////

            Swal.fire({
              title: "Bien",
              text: "Se ingresaron exitosamente los días de permiso",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });

            dispatch({
              type: INSERTAR_DATOS_FORMULARIO,
              payload: response,
            });

            break;
          case 400:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });
            break;
          case 500:
            Swal.fire({
              title: "Error",
              text: response.err.message,
              icon: "error",
            });

            dispatch({
              type: ERROR_PERMISO,
              payload: response.err,
            });

            break;
          default:
            Swal.fire({
              title: "Error",
              text:
                "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
              icon: "error",
            });
            break;
        }

        break;
      case 400:
        Swal.fire({
          title: "Error",
          text: responsePermiso.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR_PERMISO,
          payload: response.err,
        });
        break;
      case 409:

        let msg = '';

        switch (responsePermiso.data.err) {
          case 'TRABAJO_CONFIRMADO':
            msg = '<span>No puedes registrar en días con trabajo de asistencia confirmada.</span>';
            break;
          case 'TRABAJO_DESCANSO':
            msg = `<span>No puedes registrar en días de trabajo que ya tienen un descanso asignado. Si deseas modificar esos registros, debes utilizar el <a href='/Descanso'>módulo de descansos.</a></span>`;
            break;
          case 'DESCANSOS':
            msg = `<span>No puedes registrar en días con descanso planificado. Si deseas modificar esos registros, debes utilizar el <a href='/Descanso'>módulo de descansos.</a></span>`;
            break;
          case 'VACACIONES':
            msg = `<span>No puedes registrar en días con vacaciones planificadas. Si deseas modificar esos registros, debes utilizar el <a href='/Vacaciones'>módulo de vacaciones.</a></span>`;
            break;
          default:
            msg = '<span>No puedes sobrescribir los registros de esas fechas.</span>';
            break;
        }

        Swal.fire({
          title: "¡Ups!",
          html: msg,
          icon: "warning",
        });

        dispatch({
          type: ERROR_PERMISO,
          payload: 'No puedes registrar permisos en días con asistencia ya confirmada.',
        });

        break;
      case 500:
        Swal.fire({
          title: "Error",
          text: responsePermiso.err.message,
          icon: "error",
        });
        dispatch({
          type: ERROR_PERMISO,
          payload: response.err,
        });
        break;
      case 420:

        Swal.fire({
          title: "Uups",
          text: "No se pueden asignar permisos en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({ type: ERROR_PERMISO, payload: "No se pueden asignar permisos en un periodo que se encuentra cerrado" });

        break;
      default:
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        dispatch({
          type: ERROR_PERMISO,
          payload: response.err,
        });
    }

    // let cantidadDias = fechaFinLicencia.diff(fechaInicioLicencia, "days") + 1;

    // for (let index = 0; index < cantidadDias; index++) {

    //   let fecha = fechaInicioLicencia;

    //   fecha = fecha.add(index === 0 ? 0 : 1, "day");

    //   const aux = {
    //     Fecha: new Date(fecha),
    //     IdTrabajador: Trabajador,
    //     TipoRegistroBitacoraTrabajador: "5f3eec876dd7cc57f0d3a25f",
    //   };

    //   arregloDeDias.push(aux);
    // }

    // let objIngresoDias = {
    //   fechaInicio: Fechas[0],
    //   fechaFin: Fechas[1],
    //   arregloDeDias
    // };

    // const url = `${API}/IngresoDeDias/${Trabajador._id}`;

    // const request = await fetch(url, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    //   body: JSON.stringify(objIngresoDias),
    // });

    // const response = await request.json();
    // const status = await request.status;

    // switch (status) {
    //   case 201:

    //     Swal.fire({
    //       title: 'Exitoso',
    //       text: 'Se han ingresado exitosamente las vacaciones',
    //       icon: 'success'
    //     });

    //     dispatch({
    //       type: INSERTAR_DATOS_FORMULARIO,
    //       payload: response,
    //     });

    //     break;
    //   case 400:

    //     Swal.fire({
    //       title: "Error",
    //       text: response.err.message,
    //       icon: "error",
    //     });
    //     break;
    //   case 500:
    //     Swal.fire({
    //       title: "Error",
    //       text: response.err.message,
    //       icon: "error",
    //     });
    //     break;
    //   default:
    //     Swal.fire({
    //       title: "Error",
    //       text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
    //       icon: "error",
    //     });
    //     break;
    // }
  } catch (error) {

    dispatch({
      type: ERROR_PERMISO,
      payload: error.message,
    });
  }
};

export const insertarFechas = (fechas) => (dispatch) => {
  dispatch({
    type: INSERTAR_FECHAS_FORM,
    payload: fechas,
  });
};

export const enviarCorreo = (estado) => (dispatch) => {
  dispatch({
    type: ENVIAR_CORREO,
    payload: estado,
  });
}

export const insertarTrabajador = (idTrabajador) => (dispatch) => {
  dispatch({
    type: INSERTAR_TRABAJADOR_FORM,
    payload: idTrabajador,
  });
};

export const traer_todos_las_fechas_trabajador = (idTrabajador) => async (
  dispatch
) => {
  try {
    const url = `${API}/IngresoDeDias/Actuales/${idTrabajador}`;
    const request = await fetch(url);
    const { data } = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:
        return data;
      default:
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }
  } catch (error) {
    dispatch({
      type: ERROR_PERMISO,
      payload: error.message,
    });
  }
};

export const traer_todos_los_trabajadores = (empresa) => async (dispatch) => {
  dispatch({
    type: LOADING_PERMISO,
  });

  try {
    const url = `${API}/trabajadores?empresa=${empresa}`;
    const request = await fetch(url);
    const { Trabajadores } = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:
        dispatch({
          type: BUSCAR_TRABAJADORES_PERMISO,
          payload: Trabajadores,
        });
        break;
      default:
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }
  } catch (error) {
    dispatch({
      type: ERROR_PERMISO,
      payload: error.message,
    });
  }
};

export const eliminarRegistro = (fechaInicioDel, fechaFinDel, idPermiso) => async (dispatch, getState) => {

  dispatch({
    type: LOADING_PERMISO,
  });

  try {

    const store = getState().PermisoReducer;

    const {
      form: { Trabajador },
    } = store;

    const objEliminar = {
      Fecha_Inicio: fechaInicioDel,
      Fecha_Fin: fechaFinDel,
      IdTrabajador: Trabajador,
    }

    const urlDescanso = `${API}/Permisos/Eliminar/${idPermiso}`;

    const requestDescanso = await fetch(urlDescanso, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objEliminar),
    });

    const responseDescansos = await requestDescanso.json();
    const statusDescansos = await requestDescanso.status;

    switch (statusDescansos) {
      case 200:

        dispatch({
          type: LOADING_PERMISO_FALSE,
        });

        Swal.fire({
          title: "¡Bien!",
          text: "El permiso se ha eliminado exitosamente.",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        });

        break;

      case 500:
        dispatch({
          type: LOADING_PERMISO_FALSE,
        });

        break;

      case 420:

        Swal.fire({
          title: "Uups",
          text: "No se pueden eliminar permisos en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({ type: ERROR_PERMISO, payload: "No se pueden eliminar permisos en un periodo que se encuentra cerrado" });

        break;
      default:
        dispatch({
          type: LOADING_PERMISO_FALSE,
        });
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }

  } catch (e) {

    console.error(e);

    dispatch({
      type: ERROR_PERMISO,
      payload: e.message,
    });
  }

}

export const traer_todos_contratos_trabajador =
  (idTrabajador, idEmpresa) => async (dispatch) => {
    try {
      const url = `${API}/Trabajadores/Contratos?idTrabajador=${idTrabajador}&idEmpresa=${idEmpresa}`;
      const request = await fetch(url);
      const data = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          dispatch({
            type: BUSCAR_CONTRATOS_TRABAJADOR,
            payload: data.contratoDB,
          });
          break;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          break;
      }
    } catch (error) {
      dispatch({
        type: ERROR_PERMISO,
        payload: error.message,
      });
    }
  };

  export const traer_todos_los_permisos_historicos =
  (idTrabajador) => async (dispatch) => {
    try {
      const ID_PERMISO = "5f3eec876dd7cc57f0d3a25f";

      const url = `${API}/IngresoDeDias/Bitacoras/TipoBitacora/${idTrabajador}/${ID_PERMISO}`;

      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          return data;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          return null;
      }
    } catch (error) {
      dispatch({
        type: ERROR_PERMISO,
        payload: error.message,
      });
      return null;
    }
  };

  export const traer_todos_las_fechas_trabajador_por_contrato =
  (idTrabajador, idContrato, FechaInicio, FechaFin) => async (dispatch) => {
    try {
      let url;
      if (FechaInicio !== undefined && FechaFin !== undefined) {
        url = `${API}/IngresoDeDias/Actuales/Contrato/${idTrabajador}/${idContrato}?InicioBusqueda=${FechaInicio}&FinBusqueda=${FechaFin}`;
      } else {
        url = `${API}/IngresoDeDias/Actuales/Contrato/${idTrabajador}/${idContrato}`;
      }
      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          return data;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          return null;
      }
    } catch (error) {
      dispatch({
        type: ERROR_PERMISO,
        payload: error.message,
      });
      return null;
    }
  };
