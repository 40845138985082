import {
  BUSCAR_TRABAJADORES_LICENCIAS,
  ERROR_LICENCIAS,
  INSERTAR_DATOS_FORMULARIO_LICENCIAS,
  INSERTAR_FECHAS_FORM_LICENCIAS,
  INSERTAR_TRABAJADOR_FORM_LICENCIAS,
  LOADING_LICENCIAS,
  ENVIAR_CORREO,
  LOADING_LICENCIAS_FALSE,
  BUSCAR_CONTRATOS_TRABAJADOR,
} from "../types/LicenciasType";

const INITIAL_VALUES = {
  trabajadores: [],
  err: null,
  loading: false,
  form: {
    Fechas: null,
    Trabajador: null,
    Correo: null,
  },
  contratos: [],
};

export default (state = INITIAL_VALUES, { type, payload }) => {
  switch (type) {
    case LOADING_LICENCIAS:
      return {
        ...state,
        loading: true,
      };
    case LOADING_LICENCIAS_FALSE:
      return {
        ...state,
        loading: false,
      };
    case INSERTAR_FECHAS_FORM_LICENCIAS:
      return {
        ...state,
        form: {
          ...state.form,
          Fechas: payload,
        },
      };
    case INSERTAR_TRABAJADOR_FORM_LICENCIAS:
      return {
        ...state,
        form: {
          ...state.form,
          Trabajador: payload,
        },
      };
    case INSERTAR_DATOS_FORMULARIO_LICENCIAS:
      return {
        ...state,
        loading: false,
        err: null,
      };
    case ERROR_LICENCIAS:
      return {
        ...state,
        err: payload,
        loading: false,
      };
    case BUSCAR_TRABAJADORES_LICENCIAS:
      return {
        ...state,
        trabajadores: payload,
        loading: false,
        err: null,
      };
    case ENVIAR_CORREO:
      return {
        ...state,
        form: {
          ...state.form,
          Correo: payload,
        },
      };
    case BUSCAR_CONTRATOS_TRABAJADOR:
      return {
        ...state,
        contratos: payload,
        loading: false,
        err: null,
      };
    default:
      return state;
  }
};
