export const LOADING = "MANTENEDOR_REGIMEN_LOADING";
export const PARAR_LOADING = "MANTENEDOR_REGIMEN_LOADING_PARAR";
export const ERROR = "MANTENEDOR_REGIMEN_ERROR";
export const INGRESAR_REGIMEN = "MANTENEDOR_REGIMEN_INGRESAR";
export const LIMPIAR_ERROR_SUCCESS = "MANTENEDOR_REGIMEN_LIMPIAR_ERROR_SUCCESS";
export const MOSTRAR_MODAL_REGIMEN = "MANTENEDOR_REGIMEN_MOSTRAR_MODAL";
export const OCULTAR_MODAL_REGIMEN = "MANTENEDOR_REGIMEN_OCULTAR_MODAL";
export const OCULTAR_MODAL_REGIMEN_EDITAR =
  "MANTENEDOR_REGIMEN_OCULTAR_MODAL_EDITAR";
export const OBTENER_REGIMENES_MANTENEDOR =
  "MANTENEDOR_REGIMEN_OBTENER_REGIMENES";
export const OBTENER_REGIMEN_MANTENEDOR = "MANTENEDOR_REGIMEN_OBTENER_REGIMEN";
export const ELIMINAR_REGIMEN_MANTENEDOR =
  "MANTENEDOR_REGIMEN_ELIMINAR_REGIMEN";
export const EDITAR_REGIMEN_MANTENEDOR = "MANTENEDOR_REGIMEN_EDITAR_REGIMEN";
export const TOMAR_VALORES_EDITAR = "MANTENEDOR_REGIMEN_TOMAR_VALORES_EDITAR";
export const DESCARGAR_EXCEL = "MANTENEDOR_REGIMEN_DESCARGAR_EXCEL";
