export const LOADING = "FILTRO_TRABAJADORES_LOADING";
export const PARAR_LOADING = "FILTRO_TRABAJADORES_LOADING_PARAR";

export const ERROR = "FILTRO_TRABAJADORES_ERROR";

export const LIMPIAR_ERROR_SUCCESS =
  "FILTRO_TRABAJADORES_LIMPIAR_ERROR_SUCCESS";

export const OBTENER_TRABAJADOR_ID_CENTROCOSTO =
  "FILTRO_TRABAJADORES_OBTENER_TRABAJADOR_ID_CENTROCOSTO";

export const OBTENER_TRABAJADORES = "FILTRO_TRABAJADORES_OBTENER_TRABAJADORES";
export const OBTENER_TRABAJADOR_ID =
  "FILTRO_TRABAJADORES_OBTENER_TRABAJADOR_ID";
export const OBTENER_CENTROCOSTOS = "FILTRO_TRABAJADORES_OBTENER_CENTROCOSTOS";
export const OBTENER_FAENA_ID = "FILTRO_TRABAJADORES_OBTENER_FAENA_ID";
export const MOSTRAR_MODAL_EMBARCO = "FILTRO_TRABAJADORES_MOSTRAR_MODAL";
export const OCULTAR_MODAL_EMBARCO = "FILTRO_TRABAJADORES_OCULTAR_MODAL";
export const MOSTRAR_MODAL_DESEMBARCO =
  "FILTRO_TRABAJADORES_MOSTRAR_MODAL_DESEMBARCO";

export const OCULTAR_MODAL_DESEMBARCO =
  "FILTRO_TRABAJADORES_OCULTAR_MODAL_DESEMBARCO";
export const OBTENER_FAENAS = "FILTRO_TRABAJADORES_OBTENER_FAENAS";
