import React, { Component } from "react";

import { connect } from "react-redux";
import * as MantenedorPlantillaDeFaenasActions from "../../Redux/actions/MantenedorPlantillaDeFaenasActions";

import activo from "../../img/icons/activo.svg";
import inactivo from "../../img/icons/inactivo.svg";
import reloj from "../../img/icons/Historico.svg";
import ticket from "../../img/icons/ticket.png";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import FormularioPlantillaDeFaena from "../../components/formularioPlantillaDeFaena/FormularioPlantillaDeFaena";
import FormularioPlantillaDeFaenaEditar from "../../components/formularioPlantillaDeFaena/FormularioPlantillaDeFaenaEditar";

import accesosPagina from '../../funcionesGenericas/accesosUsuario';

import {
  Layout,
  Table,
  Space,
  Button,
  Row,
  Col,
  Input,
  Modal,
  Tag,
  notification,
} from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";

import Swal from "sweetalert2";

const { Header, Content } = Layout;
const { Search } = Input;
const { Column } = Table;

class MantenedorPlantillaDeFaena extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tablaCargando: false,
      form: {
        Nombre: "",
        Plazas: [],
        Vigencia: true,
      },

      size: "large",
      faenaFiltros: null,
    };

    this.cambiarEstadoVigencia = this.cambiarEstadoVigencia.bind(this);
    this.crearFaena = this.crearFaena.bind(this);
    this.EditarFaena = this.EditarFaena.bind(this);
    this.TomarValoresEditar = this.TomarValoresEditar.bind(this);
    this.cambiarValores = this.cambiarValores.bind(this);
  }

  // descargarExcel = () => {
  //   this.props.DescargarExcel();
  // };

  search = (value) => {
    let busqueda = value.target.value;
    if (value !== "") {
      const FaenasRedux = this.props.faenas;

      const faenaFiltros = FaenasRedux.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(busqueda)
        )
      );

      this.setState({ faenaFiltros });
    } else {
      this.setState({ faenaFiltros: null });
    }
  };

  mostrarModalCrear = () => {
    this.props.MostrarModalFaena();
  };

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      await this.props.ObtenerFaenas();
      await this.props.ObtenerTitulos(idEmpresa);
      await this.props.ObtenerEspecialidades(idEmpresa);
      await this.props.ObtenerCargos(idEmpresa);

      const accesos = await accesosPagina.obtenerAccesosPagina('PlanificacionEquiposDeTrabajo', 'AsignacionesPlantilla');

      this.setState({
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
      })
    }
  }

  modalCrearCancelar = () => {
    this.props.OcultarModalFaena();
  };

  modalEditarCancelar = () => {
    this.props.OcultarModalFaenaEditar();
  };

  cambiarEstadoVigencia = () => {
    this.setState({
      form: {
        ...this.state.form,
        Vigencia: this.state.form.Vigencia === true ? false : true,
      },
    });
  };

  cambiarValores = (nombre, valor) => {
    this.setState({
      form: {
        ...this.state.form,
        [nombre]: valor,
      },
    });
  };

  agregarTitulo = () => {
    let titulos = this.state.form.arregloTitulos;
    let valorSeleccionado = this.state.form.TituloSeleccionado;
    let nombre = "";

    this.props.titulos.forEach((element) => {
      if (element._id === valorSeleccionado) {
        nombre = element.Nombre;
      }
    });

    let objeto = {
      Titulo: valorSeleccionado,
      URL: "",
      Nombre: nombre,
    };

    titulos.push(objeto);
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        arregloTitulos: titulos,
      },
    });
  };

  crearFaena = async (faena) => {
    await this.props.CrearFaena(faena);
    await this.props.ObtenerFaenas();
    // this.openNotificationPlantilla('bottomRight');
  }

  EliminarFaena = (idFaena) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminará la plantilla de equipo",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.EliminarFaena(idFaena);
      }
    });
  };

  ObtenerFaena = (idFaena) => {
    this.props.ObtenerFaena(idFaena);
  };

  EditarFaena = (faena) => {
    faena._id = this.props.faena._id;
    // faena.Nombre = this.props.faena.Nombre;
    // faena.Vigencia = this.props.faena.Vigencia;
    this.props.EditarFaena(faena);
  };

  TomarValoresEditar = (nombreCampo, valor) => {
    this.props.TomarValoresEditar(nombreCampo, valor);
  };

  openNotificationPlantilla = (placement) => {

    notification.open(
      {

        message: '¡Bien!',
        description: 'Haz click para ver sugerencias!',

        duration: null,
        placement,
        icon: (
          <img
            style={{ width: 20, marginTop: '-3' }}
            src={ticket}
            alt="ticket"
          />
        ),

        onClose: notification.destroy(),

        onClick: () => {

          notification.destroy()

          notification.open({
            message: `¡Bien hecho!`,
            placement,
            style: { width: 500 },
            icon: (
              <img
                style={{ width: 20, marginRight: '-20' }}
                src={ticket}
                alt="ticket"
              />
            ),
            duration: null,
            description: (
              <>

                <p style={{ marginRight: 10 }}>
                  Creaste exitosamente una nueva Plantilla de Equipos de trabajo. Te sugerimos continuar con...
                </p>

                {/* <p style={{ marginRight: 10 }}>
                  
                </p> */}


                {/************** SUGERIDA ***************/}

                <Row>
                  <Col span={3}>
                    <a href={`/AsignacionFaena`}>
                      <img
                        style={{ width: 20, cursor: 'pointer', justifyContent: 'center' }}
                        src={reloj}
                        alt="next"
                        onClick={
                          () => console.log("NEXT")
                        }
                      />
                    </a>

                  </Col>
                  <Col span={21}>

                    <a href={`/AsignacionFaena`}>
                      <p
                        style={{ cursor: 'pointer', fontFamily: "MavenPro-Bold" }}
                      >
                        Planificar Actividades para los Equipos de Trabajo
                      </p>
                    </a>

                  </Col>
                </Row>

                <p style={{ marginRight: 10 }}>
                  O también puedes...
                </p>


                {/************** Extra 1 - Ver reporte de Actividades ***************/}

                <Row>
                  <Col span={3}>

                    {/* <a href={`/DetalleFaena`}> */}
                    {/* <a href={`/Faena`}> */}
                    <img
                      style={{ width: 20, cursor: 'pointer', justifyContent: 'center', opacity: '50%' }}
                      src={reloj}
                      alt="next"
                    />
                    {/* </a> */}

                  </Col>
                  <Col span={21}>

                    {/* <a href={`/DetalleFaena`}> */}
                    {/* <a href={`/Faena`}> */}
                    <p
                      style={{ cursor: 'pointer', fontFamily: "MavenPro-Bold", color: '#7b7b7b' }}
                    >
                      Asignar Trabajadores a una Actividad
                      </p>
                    {/* </a> */}

                  </Col>
                </Row>


                {/************** Extra 2 - Ver todos los trabajadores ***************/}

                <Row>
                  <Col span={3}>

                    <a href={`/Trabajador`}>
                      <img
                        style={{ width: 20, cursor: 'pointer', justifyContent: 'center' }}
                        src={reloj}
                        alt="next"
                        onClick={
                          () => console.log("NEXT")
                        }
                      />
                    </a>

                  </Col>
                  <Col span={21}>

                    <a href={`/Trabajador`}>
                      <p
                        style={{ cursor: 'pointer', fontFamily: "MavenPro-Bold" }}
                      >
                        Ver todos los Trabajadores
                      </p>
                    </a>

                  </Col>
                </Row>


              </ >
            ),
          })

        },
      },

    );
  };

  componentWillUnmount() {
    notification.destroy();
  }

  render() {
    const { size, faenaFiltros } = this.state;

    return (
      <Layout style={{ height: "100%" }}>
        <Loading cargando={this.props.loading} />
        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Plantillas de Equipos de Trabajo"
            subtitulo="Crea plantillas de equipos, configura sus cargos y personal"
          />
        </Header>
        <hr className="division" />
        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row style={{ padding: "6px 0px 22px" }} align="bottom">
            <Col className="div-input-search" span={6}>
              <Search
                placeholder="Buscar"
                //onSearch={(value) => this.search(value)}
                enterButton
                onChange={(value) => this.search(value)}
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={6}></Col>
            <Col style={{ textAlign: "right" }} span={12}>
              {/* <Button
                className="descargar-excel"
                type="primary"
                onClick={() => {
                  this.descargarExcel();
                }}
                size={size}
                style={{ marginRight: "20px" }}
              >
                Descargar Excel
              </Button> */}
              <Button
                type="primary"
                onClick={() => {
                  this.mostrarModalCrear();
                }}
                size={size}
                className={!this.state.Editar ? "guardar-form-disabled" : "guardar-form"}
                disabled={!this.state.Editar}
              >
                Crear Plantilla
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="_id"
            dataSource={
              faenaFiltros === null ? this.props.faenas : faenaFiltros
            }
            loading={this.props.tablaCargando}
            bordered={true}
            showSorterTooltip={false}
          >
            <Column
              sorter={(a, b) => {
                return a.Nombre.localeCompare(b.Nombre);
              }}
              align="left"
              title="Nombre"
              dataIndex="Nombre"
              key="Nombre"
              render={(values) => {
                return values.toUpperCase();
              }}
            />
            <Column
              // sorter={(a, b) => {
              //   return a.Descripcion.localeCompare(b.Descripcion);
              // }}
              align="left"
              title="Plazas"
              key="Plazas"
              // align="center"
              render={(values) => {
                if (values.Plazas.length !== 0) {
                  return (
                    <div style={{ textAlign: "center" }}>
                      {values.Plazas.map((valor, index) => (
                        <Tag
                          style={{ minWidth: "100px", height: "25px", marginBottom: "9px" }}
                          key={index}
                          color="blue"
                        >
                          {valor.NombrePlaza.toUpperCase()}
                        </Tag>
                      ))}
                    </div>
                  );
                } else {
                  return (
                    <div style={{ textAlign: "center" }}>
                      <Tag
                        style={{ width: "100px", height: "25px" }}
                        key={1}
                        color="#969696"
                      >
                        Sin Plazas
                      </Tag>
                    </div>
                  );
                }
              }}
            />
            <Column
              // sorter={(a, b) => {
              //   return a.Cantidad.localeCompare(b.Cantidad);
              // }}
              align="left"
              title="Cantidad trabajadores"
              key="Plazas"
              // align="center"
              render={(value) => {
                let totalPersonal = 0;
                value.Plazas.forEach((plaza) => {
                  totalPersonal = plaza.Cantidad + totalPersonal;
                });
                return totalPersonal;
              }}
            />

            <Column
              sorter={(a, b) => {
                return a.Vigencia - b.Vigencia;
              }}
              title="Vigencia"
              key="Vigencia"
              align="center"
              render={(valor) => {
                return (
                  <img
                    src={valor.Vigencia === true ? activo : inactivo}
                    alt="imagen perfil de tabla"
                  />
                );
              }}
            />

            <Column
              title="Acciones"
              key="Action"
              align="center"
              render={(value) => (
                <Space size="middle">

                  <Button
                    onClick={() => {
                      this.ObtenerFaena(value._id);
                    }}
                    className="editar-button"
                    icon={<EditFilled />}
                    disabled={!this.state.Editar}
                  />

                  <Button
                    className="eliminar-button"
                    onClick={() => {
                      this.EliminarFaena(value._id);
                    }}
                    icon={<DeleteFilled />}
                    disabled={!this.state.Eliminar}
                  />

                </Space>
              )}
            />

          </Table>
        </Content>

        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalFaena}
          onCancel={this.modalCrearCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioPlantillaDeFaena
            //propiedades cambiables del formulario
            cambiarValores={this.cambiarValores}
            titulos={this.props.titulos}
            especialidades={this.props.especialidades}
            cargos={this.props.cargos}
            Formulario={this.state.form}
            vigencia={this.state.form.Vigencia}
            cambiarEstadoVigencia={this.cambiarEstadoVigencia}
            crearFaena={this.crearFaena}
            Plazas={this.state.form.Plazas}
          />
        </Modal>

        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalEditar"
          visible={this.props.modalFaenaEditar}
          onCancel={this.modalEditarCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioPlantillaDeFaenaEditar
            //propiedades cambiables del formulario
            cambiarValores={this.cambiarValores}
            Formulario={this.props.faena}
            EditarFaena={this.EditarFaena}
            cambiarEstadoVigencia={this.cambiarEstadoVigencia}
            TomarValoresEditar={this.TomarValoresEditar}
          />
        </Modal>

      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  MantenedorPlantillaDeFaenaReducer: {
    loading,
    error,
    titulos,
    especialidades,
    cargos,
    success,
    modalFaena,
    modalFaenaEditar,
    faenas,
    faena,
    tablaCargando,
  },
}) => {
  return {
    loading,
    error,
    titulos,
    especialidades,
    cargos,
    success,
    modalFaena,
    modalFaenaEditar,
    faenas,
    faena,
    tablaCargando,
  };
};

export default connect(
  EstadosAPropiedades,
  MantenedorPlantillaDeFaenasActions
)(MantenedorPlantillaDeFaena);
