import {
  ERROR,
  INGRESAR_DOCUMENTO,
  LOADING,
  MOSTRAR_MODAL_DOCUMENTO,
  OCULTAR_MODAL_DOCUMENTO,
  OBTENER_DOCUMENTOS_MANTENEDOR,
  //   PARAR_LOADING,
  OBTENER_DOCUMENTO_MANTENEDOR,
  ELIMINAR_DOCUMENTO_MANTENEDOR,
  EDITAR_DOCUMENTO_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OCULTAR_MODAL_DOCUMENTO_EDITAR,
  CAMBIAR_VALOR_DESHABILITAR,
  CAMBIAR_VALOR_ARREGLO_ARCHIVOS
} from "../types/MantenedorDocumentosTypes";
import API from "../Api/api";
// import { obtenerFechaHoy } from "../../funcionesGenericas/fechas";

import Swal from "sweetalert2";

export const CrearDocumento = (rol) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/TiposDeDocumentos`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(rol),
    });
    const status = response.status;

    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Se ingreso documento correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: INGRESAR_DOCUMENTO,
          payload: "Se ingreso documento correctamente",
        });

        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerDocumentos = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/TiposDeDocumentos?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      const data = await response.json();

      switch (status) {
        case 200:
        case 201:
          const documentos = data.tiposDeDocumentos;

          dispatch({
            type: OBTENER_DOCUMENTOS_MANTENEDOR,
            payload: documentos,
          });
          break;

        default:
          const error = data.err.message.toString();

          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });

          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerDocumento = (idDocumento) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/TiposDeDocumentos/${idDocumento}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const documento = data.tipoDeDocumento;
        dispatch({ type: OBTENER_DOCUMENTO_MANTENEDOR, payload: documento });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarDocumento = (idDocumento) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/TiposDeDocumentos/${idDocumento}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El documento fue eliminado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: ELIMINAR_DOCUMENTO_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarDocumento = (Documento) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/TiposDeDocumentos/${Documento._id}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },

      body: JSON.stringify(Documento),
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El documento fue actualizado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: EDITAR_DOCUMENTO_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const TomarValoresEditar = (nombreCampo, valor) => (dispatch) => {
  let objeto = {
    nombreCampo,
    valor,
  };
  dispatch({ type: TOMAR_VALORES_EDITAR, payload: objeto });
};

export const MostrarModalDocumento = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_DOCUMENTO });
};

export const OcultarModalDocumento = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_DOCUMENTO });
};

export const OcultarModalDocumentoEditar = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_DOCUMENTO_EDITAR });
};

export const CambiarEstadoDeshabilitado = (valor) => (dispatch) => {
  dispatch({ type: CAMBIAR_VALOR_DESHABILITAR, payload:valor });
};

export const CambiarValorArregloDocumentos = (valor,btnDeshabilitado) => (dispatch) => {
  dispatch({ type: CAMBIAR_VALOR_ARREGLO_ARCHIVOS, payload:{valor,btnDeshabilitado} });
};

// export const DescargarExcel = () => async (dispatch) => {
//   dispatch({ type: LOADING });
//   try {
//     const url = `${API}/TiposDeDocumentos/Descargar/Excel`;
//     let status;
//     fetch(url)
//       .then((response) => {
//         status = response.status;
//         return response.blob();
//       })
//       .then((blob) => {
//         switch (status) {
//           case 200:
//           case 201:
//             const url = window.URL.createObjectURL(blob);
//             const a = document.createElement("a");
//             a.href = url;
//             a.download = `Documentos_${obtenerFechaHoy()}.xlsx`;
//             document.body.appendChild(a);
//             a.click();
//             a.remove();

//             dispatch({ type: PARAR_LOADING });
//             break;
//           default:
//             Swal.fire({
//               title: "Uups",
//               text:
//                 "Error status: " +
//                 status +
//                 " al realizar la descarga del archivo",
//               icon: "error",
//             });

//             dispatch({ type: PARAR_LOADING });
//             break;
//         }
//       });
//   } catch (error) {
//     dispatch({ type: ERROR, payload: error });
//   }
// };
