import React, { useState } from "react";
import "../formularioLogin/FormularioLogin.css";
import Swal from "sweetalert2";
import API from "../../Redux/Api/api";

import { Card, Form, Input, Button, Row, Col } from "antd";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};



const FormularioReset = (props) => {

    const idUsuario = props.idUsuario
    const [form] = Form.useForm();

    const onFinish = ({ Password1: Password }) => {

        if (Password != undefined && Password != '') {

            RestablecerPassword(Password, idUsuario)
                .then(EnviarCorreoAdvertencia(idUsuario))
                .catch((err) => {
                    Swal.fire({
                        title: "Ups...",
                        text: "Ha ocurrido un error",
                        icon: "error",
                        confirmButtonColor: "#E60AA3",
                        confirmButtonText: "Continuar",
                    });
                })

        } else {

            Swal.fire({
                title: "Ups...",
                text: "Ha ocurrido un error",
                icon: "error",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "Continuar",
            });

        }



    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    // const redireccionar = () => {
    //     if (props.redireccionarLogin === true) {
    //         window.location.href = '/CentroControl'; //relative to domain
    //         //return <Redirect to="/CentroControl" />;
    //     }
    // };

    const RestablecerPassword = async (Password, idUsuario) => {

        try {

            const url = `${API}/RecuperarPassword/${idUsuario}`;
            const response = await fetch(url, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({ Password }),
            });

            const status = response.status;
            const data = await response.json();

            switch (status) {
                case 200:
                case 201:
                case 204:

                    Swal.fire({
                        title: "Bien",
                        text: "Se ha cambiado la contraseña correctamente, ya puede iniciar sesión.",
                        icon: "success",
                        confirmButtonColor: "#E60AA3",
                        confirmButtonText: "Continuar",
                    }).then((value) => {
                        window.location.reload();
                    });
                    // })
                    // .then((value) => {
                    //     window.location.reload();
                    // });

                    return;
                case 404:
                    Swal.fire({
                        title: "Error",
                        // text: "Ha ocurrido un error",
                        text: "No se le puede restablecer la contraseña al usuario.",
                        icon: "error",
                        confirmButtonColor: "#E60AA3",
                        confirmButtonText: "Continuar",
                    });

                    return;
                default:
                    Swal.fire({
                        title: "Ups...",
                        text: "Ha ocurrido un error",
                        icon: "error",
                        confirmButtonColor: "#E60AA3",
                        confirmButtonText: "Continuar",
                    });

                    return;
            }


        } catch (error) {
            Swal.fire({
                title: "Ups...",
                text: "Ha ocurrido un error",
                icon: "error",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "Continuar",
            });
        }

    };

    const EnviarCorreoAdvertencia = async (idUsuario) => {

        try {

            const url = `${API}/Enviar/Correo/AlertaCambioPass/${idUsuario}`;
            const response = await fetch(url, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                // body: JSON.stringify(email),

            });

            const status = response.status;
            const data = await response.json();

            switch (status) {
                case 200:
                case 201:

                    Swal.fire({
                        title: "Bien",
                        text: "Se cambió la contraseña correctamente",
                        icon: "success",
                        confirmButtonColor: "#E60AA3",
                        confirmButtonText: "Continuar",
                    }).then((value) => {
                        // window.location.reload();
                        window.location.href = '/'
                    });

                    return;
                //case 204:
                case 404:
                    Swal.fire({
                        title: "Ups...",
                        text: "Ha ocurrido un error",
                        icon: "error",
                        confirmButtonColor: "#E60AA3",
                        confirmButtonText: "Continuar",
                    });

                    return;
                default:
                    Swal.fire({
                        title: "Ups...",
                        text: "Ha ocurrido un error",
                        icon: "error",
                        confirmButtonColor: "#E60AA3",
                        confirmButtonText: "Continuar",
                    });

                    return;
            }


        } catch (error) {
            Swal.fire({
                title: "Ups...",
                text: error,
                icon: "error",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "Continuar",
            });
        }

    };

    return (

        <Row
            align="middle"
            className={'login-row'}
        >


            <Col
                span={24}
                align={'center'}
                className={'login-col'}
            >

                <div className="componente">

                    {/* {redireccionar()} */}

                    <Card
                        title="Restablecer contraseña"
                        className="login"
                        style={{ marginButton: '-30 !important' }}
                    >

                        <Form
                            form={form}
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                name="Password1"
                                rules={[
                                    {
                                        required: true,
                                        message: "La contraseña debe tener como mínimo 8 y máximo 20 carácteres alfanuméricos.",
                                        min: 8,
                                        max: 20,
                                    },
                                ]}
                            >
                                <Input.Password
                                    id="basic_Password"
                                    placeholder="Contraseña"
                                    className="login-campos"
                                />
                            </Form.Item>

                            <Form.Item
                                name="Password2"
                                rules={[
                                    {
                                        validator: (_, value) => {

                                            let password1 = form.getFieldsValue(['Password1']).Password1;

                                            if (value !== password1) {
                                                return Promise.reject('Las contraseñas no coinciden.');
                                            } else {
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}
                            >
                                <Input.Password
                                    id="basic_Password"
                                    className="login-campos"
                                    placeholder="Confirmar"
                                />
                            </Form.Item>


                            <Button
                                htmlType="submit"
                                className="login-btn"
                            >
                                Ingresar
                            </Button>


                        </Form>

                    </Card>
                </div>

            </Col>

            <span>
                <style jsx="true">
                    {`
            .ant-layout-sider,
            .ant-layout-footer {
              display: none;
            }

            .ant-layout-content {
              margin: 0px !important;
            }

            .ant-input-suffix {
              position: absolute;
              top: 10px;
              right: 25px;
            }

            #basic_Password.ant-input {
              width: 100% !important;
            }
          `}
                </style>
            </span>

        </Row>

    );
};

export default FormularioReset;
