import {
  BUSCAR_TRABAJADORES_ACCIDENTE,
  ERROR_ACCIDENTE,
  LOADING_ACCIDENTE,
  INSERTAR_FECHAS_FORM,
  INSERTAR_TRABAJADOR_FORM,
  INSERTAR_DATOS_FORMULARIO,
  ENVIAR_CORREO,
  LOADING_ACCIDENTES_FALSE,
  BUSCAR_CONTRATOS_TRABAJADOR,
} from "../types/accidenteTypes";

import Swal from "sweetalert2";
// import { notification } from "antd";
import API from "../Api/api";
import moment from "moment";

import { convertirFechaString } from "../../funcionesGenericas/fechas";

// export const insertarDatos = (
//   TerminoOperacion,
//   Maniobra,
//   ArchivoBase64,
//   Cargo
// ) => async (dispatch, getState) => {

//   dispatch({
//     type: LOADING_ACCIDENTE,
//   });

//   console.log({
//     TerminoOperacion,
//     Maniobra,
//     ArchivoBase64,
//     Cargo
//   });

//   try {

//     const store = getState().AccidenteReducer;

//     const {
//       form: { Fechas, Trabajador, Correo: envioCorreo },
//     } = store;

//     const fechaInicioAccidente = moment(Fechas[0]);
//     const fechaFinAccidente = moment(Fechas[1]);

//     let objAccidente = {
//       IdTrabajador: Trabajador,
//       Maniobra,
//       Archivo: ArchivoBase64,
//       Cargo,
//       Fecha_Inicio: Fechas[0],
//       Fecha_Fin: Fechas[1],
//     };

//     const urlAccidente = `${API}/AccidenteLaboral`;
//     const requestAccidente = await fetch(urlAccidente, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//       body: JSON.stringify(objAccidente),
//     });

//     const responseAccidentes = await requestAccidente.json();
//     const statusAccidentes = await requestAccidente.status;

//     switch (statusAccidentes) {
//       case 201:
//         let idRegistroAccidentes = responseAccidentes.data._id;

//         let cantidadDias =
//           fechaFinAccidente.diff(fechaInicioAccidente, "days") + 1;

//         let arregloDeDias = [];

//         for (let index = 0; index < cantidadDias; index++) {
//           let fecha = fechaInicioAccidente;
//           fecha = fecha.add(index === 0 ? 0 : 1, "day");

//           const aux = {
//             Fecha: new Date(fecha),
//             IdTrabajador: Trabajador,
//             TipoRegistroBitacoraTrabajador: "5f3eec676dd7cc57f0d3a25e",

//             InicioTrabajo: index === 0 ? true : false,
//             FinTrabajo: index === cantidadDias - 1 ? true : false,
//             Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,
//             Accidentes: idRegistroAccidentes,
//           };
//           arregloDeDias.push(aux);
//         }

//         let objetoEnviar = {
//           arregloDeDias,
//           fechaInicio: Fechas[0],
//           fechaFin: Fechas[1],
//         };

//         const url = `${API}/IngresoDeDias/${Trabajador}`;
//         const request = await fetch(url, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//           },
//           body: JSON.stringify(objetoEnviar),
//         });

//         const response = await request.json();
//         const status = await request.status;

//         switch (status) {
//           case 201:

//             ////////////////////////////////////////////////////////////////////////////////
//             if (envioCorreo) {
//               const url = `${API}/Enviar/Correo/Accidente/${idRegistroAccidentes}`;
//               await fetch(url, {
//                 method: "POST",
//                 headers: {
//                   "Content-Type": "application/json",
//                   Accept: "application/json",
//                 },
//               })
//             }
//             ////////////////////////////////////////////////////////////////////////////////

//             Swal.fire({
//               title: "Bien",
//               text: "Se ingresaron exitosamente los días de licencia",
//               icon: "success",
//               confirmButtonColor: "#E60AA3",
//               confirmButtonText: "Continuar",
//             });
//             dispatch({
//               type: INSERTAR_DATOS_FORMULARIO,
//               payload: response,
//             });

//             break;
//           case 400:
//             Swal.fire({
//               title: "Error",
//               text: response.err.message,
//               icon: "error",
//             });

//             dispatch({
//               type: ERROR_ACCIDENTE,
//               payload: response.err.message,
//             });
//             break;
//           case 500:
//             Swal.fire({
//               title: "Error",
//               text: response.err.message,
//               icon: "error",
//             });
//             dispatch({
//               type: ERROR_ACCIDENTE,
//               payload: response.err.message,
//             });
//             break;
//           default:
//             Swal.fire({
//               title: "Error",
//               text:
//                 "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
//               icon: "error",
//             });
//             dispatch({
//               type: ERROR_ACCIDENTE,
//               payload: response.err.message,
//             });

//             break;
//         }
//         break;
//       case 400:

//         Swal.fire({
//           title: "Error",
//           text: responseAccidentes.err.message,
//           icon: "error",
//         });
//         dispatch({
//           type: ERROR_ACCIDENTE,
//           payload: responseAccidentes.err.message,
//         });
//         console.error(responseAccidentes.err.message)

//         break;
//       case 500:

//         Swal.fire({
//           title: "Error",
//           text: responseAccidentes.err.message,
//           icon: "error",
//         });
//         dispatch({
//           type: ERROR_ACCIDENTE,
//           payload: responseAccidentes.err.message,
//         });

//         console.error(responseAccidentes.err.message)
//         break;
//       default:

//         console.error(responseAccidentes.err.message)
//         Swal.fire({
//           title: "Error",
//           text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
//           icon: "error",
//         });
//         break;
//     }

//   } catch (error) {

//     console.error(error)

//     dispatch({
//       type: ERROR_ACCIDENTE,
//       payload: error.message,
//     });
//   }
// };

export const insertarDatosAvanzado =
  (
    Maniobra,
    ArchivoBase64,
    Cargo,
    FechaInicio,
    FechaFin,
    CantidadDiasLicencia,
    TieneFechaFin,
    Observacion
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: LOADING_ACCIDENTE,
    });

    try {
      const store = getState().AccidenteReducer;

      const {
        form: { Fechas, Trabajador, Correo: envioCorreo },
      } = store;
      
      // const fechaInicioAccidente = moment.utc(momentToStringFechaInicio(moment(Fechas[0]), databaseFormat));
      // const fechaFinAccidente = moment.utc(momentToStringFechaFin(moment(Fechas[1]), databaseFormat));

      // const strFechaInicio = TieneFechaFin === true ? moment.utc(Fechas[0]).format('YYYY-MM-DD') : moment.utc(Fechas).format('YYYY-MM-DD');
      // const strFechaFin = TieneFechaFin === true ? moment.utc(Fechas[1]).format('YYYY-MM-DD') : '';

      let strFechaInicio;
      let strFechaFin;

      if (TieneFechaFin === true) {
        strFechaInicio = moment.utc(FechaInicio).format("YYYY-MM-DD");
        strFechaFin = moment.utc(FechaFin).format("YYYY-MM-DD");
      } else {
        strFechaInicio = moment.utc(FechaInicio).format("YYYY-MM-DD");
        strFechaFin = moment.utc(FechaInicio).isBefore(moment())
          ? moment().format("YYYY-MM-DD")
          : moment.utc(FechaInicio).format("YYYY-MM-DD");
      }

      const fechaInicioAccidente = moment(
        convertirFechaString(moment(strFechaInicio), 1).fechaString
      );
      const fechaFinAccidente =
        strFechaFin.length !== 0
          ? moment(convertirFechaString(moment(strFechaFin), 2).fechaString)
          : "";

      let objAccidente = {
        IdTrabajador: Trabajador,
        Maniobra,
        Archivo: ArchivoBase64,
        Cargo,
        Observacion,
        // Fecha_Inicio: momentToStringFechaInicio(moment(Fechas[0]), databaseFormat),
        // Fecha_Fin: momentToStringFechaFin(moment(Fechas[1]), databaseFormat),

        Fecha_Inicio: convertirFechaString(moment(strFechaInicio), 1)
          .fechaString,
        Fecha_Fin: convertirFechaString(moment(strFechaFin), 2).fechaString,
        CantidadDiasLicencia,
        TieneFechaFin,
      };
      
      const urlAccidente = `${API}/AccidenteLaboral/Avanzado`;
      const requestAccidente = await fetch(urlAccidente, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objAccidente),
      });

      const responseAccidentes = await requestAccidente.json();
      const statusAccidentes = await requestAccidente.status;

      switch (statusAccidentes) {
        case 201:
          let idRegistroAccidentes = responseAccidentes.data._id;

          let cantidadDias =
            TieneFechaFin === true
              ? fechaFinAccidente.diff(fechaInicioAccidente, "days") + 1
              : fechaInicioAccidente.diff(fechaInicioAccidente, "days") + 1;

          let arregloDeDias = [];

          for (let index = 0; index < cantidadDias; index++) {
            let fecha = fechaInicioAccidente;
            fecha = fecha.add(index === 0 ? 0 : 1, "day");

            const aux = {
              // Fecha: obtenerFechaFormateadaMismaHora(fecha.set({ hour: 12 }), databaseFormat),

              Fecha: convertirFechaString(fecha, 1).fechaString,

              IdTrabajador: Trabajador,
              TipoRegistroBitacoraTrabajador: "5f3eec676dd7cc57f0d3a25e",

              InicioTrabajo: index === 0 ? true : false,
              FinTrabajo: index === cantidadDias - 1 ? true : false,
              Trabajo: index > 0 && index !== cantidadDias - 1 ? true : false,
              Accidentes: idRegistroAccidentes,
              Observacion: Observacion,
              // Fecha_Inicio: momentToStringFechaInicio(moment(Fechas[0]), databaseFormat),
              // Fecha_Fin: momentToStringFechaFin(moment(Fechas[1]), databaseFormat),

              Fecha_Inicio: objAccidente.Fecha_Inicio,
              Fecha_Fin: TieneFechaFin === true ? objAccidente.Fecha_Fin : "",
              Sin_Fecha_Fin: TieneFechaFin === true ? false : true,
            };

            arregloDeDias.push(aux);
          }

          let objetoEnviar = {
            arregloDeDias,
            fechaInicio: objAccidente.Fecha_Inicio,
            fechaFin: objAccidente.Fecha_Fin,
          };

          const url = `${API}/IngresoDeDias/${Trabajador}`;
          const request = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(objetoEnviar),
          });

          const response = await request.json();
          const status = await request.status;

          switch (status) {
            case 201:
              ////////////////////////////////////////////////////////////////////////////////
              if (envioCorreo) {
                const url = `${API}/Enviar/Correo/Accidente/${idRegistroAccidentes}`;
                await fetch(url, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
                });
              }
              ////////////////////////////////////////////////////////////////////////////////

              if (!TieneFechaFin) {
                let objetoLicencia = {};
                objetoLicencia.IdTrabajador = Trabajador;
                objetoLicencia.Sin_Fecha_Fin = true;
                objetoLicencia.Fecha_Inicio = objAccidente.Fecha_Inicio;

                const url = `${API}/IngresoLicenciaSinFin`;
                const responseLicenciaSinFin = await fetch(url, {
                  method: "PUT",
                  mode: "cors",
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
                  body: JSON.stringify(objetoLicencia),
                });

                const status = await responseLicenciaSinFin.status;

                if (status !== 200) {
                  Swal.fire({
                    title: "Error",
                    text: "Error al ingresar licencia sin fin en trabajador",
                    icon: "error",
                  });

                  dispatch({
                    type: ERROR_ACCIDENTE,
                    payload: response.err.message,
                  });

                  return false;
                }
              }

              //INGRESAR CÓDIGO PARA BUSCAR LOS DATOS NUEVAMENTE
              Swal.fire({
                title: "Bien",
                text: "Se ingresaron exitosamente los días de licencia",
                icon: "success",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "Continuar",
              });
              dispatch({
                type: INSERTAR_DATOS_FORMULARIO,
                payload: response,
              });

              break;

            case 400:
              Swal.fire({
                title: "Error",
                text: response.err.message,
                icon: "error",
              });

              dispatch({
                type: ERROR_ACCIDENTE,
                payload: response.err.message,
              });
              break;
            case 500:
              Swal.fire({
                title: "Error",
                text: response.err.message,
                icon: "error",
              });
              dispatch({
                type: ERROR_ACCIDENTE,
                payload: response.err.message,
              });
              break;
            default:
              Swal.fire({
                title: "Error",
                text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
                icon: "error",
              });
              dispatch({
                type: ERROR_ACCIDENTE,
                payload: response.err.message,
              });

              break;
          }

          break;
        case 400:
          Swal.fire({
            title: "Error",
            text: responseAccidentes.err.message,
            icon: "error",
          });
          dispatch({
            type: ERROR_ACCIDENTE,
            payload: responseAccidentes.err.message,
          });
          console.error(responseAccidentes.err.message);

          break;
        case 409:
          let msg = "";

          switch (responseAccidentes.data.err) {
            case "TRABAJO_CONFIRMADO":
              msg =
                "<span>No puedes registrar en días con trabajo de asistencia confirmada.</span>";
              break;
            case "TRABAJO_DESCANSO":
              msg = `<span>No puedes registrar en días de trabajo que ya tienen un descanso asignado. Si deseas modificar esos registros, debes utilizar el <a href='/Descanso'>módulo de descansos.</a></span>`;
              break;
            case "DESCANSOS":
              msg = `<span>No puedes registrar en días con descanso planificado. Si deseas modificar esos registros, debes utilizar el <a href='/Descanso'>módulo de descansos.</a></span>`;
              break;
            case "VACACIONES":
              msg = `<span>No puedes registrar en días con vacaciones planificadas. Si deseas modificar esos registros, debes utilizar el <a href='/Vacaciones'>módulo de vacaciones.</a></span>`;
              break;
            default:
              msg =
                "<span>No puedes sobrescribir los registros de esas fechas.</span>";
              break;
          }

          Swal.fire({
            title: "¡Ups!",
            html: msg,
            icon: "warning",
          });

          dispatch({
            type: ERROR_ACCIDENTE,
            payload:
              "No puedes registrar descansos en días con asistencia ya confirmada.",
          });

          break;
        case 500:
          Swal.fire({
            title: "Error",
            text: responseAccidentes.err.message,
            icon: "error",
          });
          dispatch({
            type: ERROR_ACCIDENTE,
            payload: responseAccidentes.err.message,
          });

          console.error(responseAccidentes.err.message);
          break;
        case 420:
          Swal.fire({
            title: "Uups",
            text: "No se pueden asignar licencias de la mutual en un periodo que se encuentra cerrado",
            icon: "error",
          });

          dispatch({
            type: ERROR_ACCIDENTE,
            payload:
              "No se pueden asignar licencias de la mutual en un periodo que se encuentra cerrado",
          });

          break;
        default:
          console.error(responseAccidentes.err.message);
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          break;
      }
    } catch (error) {
      console.error(error);

      dispatch({
        type: ERROR_ACCIDENTE,
        payload: error.message,
      });
    }
  };

export const insertarFechas = (fechas) => (dispatch) => {
  dispatch({
    type: INSERTAR_FECHAS_FORM,
    payload: fechas,
  });
};

export const insertarTrabajador = (idTrabajador) => (dispatch) => {
  dispatch({
    type: INSERTAR_TRABAJADOR_FORM,
    payload: idTrabajador,
  });
};

export const enviarCorreo = (estado) => (dispatch) => {
  dispatch({
    type: ENVIAR_CORREO,
    payload: estado,
  });
};

export const traer_todos_las_fechas_trabajador =
  (idTrabajador) => async (dispatch) => {
    try {
      const url = `${API}/IngresoDeDias/Actuales/${idTrabajador}`;
      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          return data;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          break;
      }
    } catch (error) {
      dispatch({
        type: ERROR_ACCIDENTE,
        payload: error.message,
      });
    }
  };

export const traer_todos_los_trabajadores = (empresa) => async (dispatch) => {
  dispatch({
    type: LOADING_ACCIDENTE,
  });

  try {
    const url = `${API}/trabajadores?empresa=${empresa}`;
    const request = await fetch(url);
    const { Trabajadores } = await request.json();
    const status = await request.status;

    switch (status) {
      case 200:
        dispatch({
          type: BUSCAR_TRABAJADORES_ACCIDENTE,
          payload: Trabajadores,
        });
        break;
      default:
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
          icon: "error",
        });
        break;
    }
  } catch (error) {
    dispatch({
      type: ERROR_ACCIDENTE,
      payload: error.message,
    });
  }
};

export const eliminarRegistro =
  (fechaInicioDel, fechaFinDel, idAccidente) => async (dispatch, getState) => {
    dispatch({
      type: LOADING_ACCIDENTE,
    });

    try {
      const store = getState().AccidenteReducer;

      const {
        form: { Trabajador },
      } = store;

      const objEliminar = {
        Fecha_Inicio: fechaInicioDel,
        Fecha_Fin: fechaFinDel,
        IdTrabajador: Trabajador,
      };

      const urlDescanso = `${API}/AccidenteLaboral/Eliminar/${idAccidente}`;

      const request = await fetch(urlDescanso, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objEliminar),
      });

      const response = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          dispatch({
            type: LOADING_ACCIDENTES_FALSE,
          });

          Swal.fire({
            title: "¡Bien!",
            text: "El registro se ha eliminado exitosamente.",
            icon: "success",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });

          return true;

        case 500:
          dispatch({
            type: LOADING_ACCIDENTES_FALSE,
          });

          return false;

        case 420:
          Swal.fire({
            title: "Uups",
            text: "No se pueden eliminar licencias de la mutual en un periodo que se encuentra cerrado",
            icon: "error",
          });

          dispatch({
            type: ERROR_ACCIDENTE,
            payload:
              "No se pueden eliminar licencias de la mutual en un periodo que se encuentra cerrado",
          });

          return false;
        default:
          dispatch({
            type: LOADING_ACCIDENTES_FALSE,
          });
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          return false;
      }
    } catch (e) {
      dispatch({
        type: ERROR_ACCIDENTE,
        payload: e.message,
      });
      return false;
    }
  };

export const ingresarFechaFinLicencia =
  (values) => async (dispatch, getState) => {
    dispatch({
      type: LOADING_ACCIDENTE,
    });

    try {
      let objetoFechaFinLicencia = {};
      objetoFechaFinLicencia.IdTrabajador = values.idTrabajador;
      objetoFechaFinLicencia.IdLicencia = values.idLicencia;
      objetoFechaFinLicencia.FechaInicio = values.fechaInicioLicencia;
      objetoFechaFinLicencia.FechaFin = values.fechaFinLicencia;
      objetoFechaFinLicencia.CantidadDias = values.diasLicenciaMutual;

      const url = `${API}/IngresarFechaFinLicencia`;
      const request = await fetch(url, {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objetoFechaFinLicencia),
      });

      const response = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          dispatch({
            type: LOADING_ACCIDENTES_FALSE,
          });

          Swal.fire({
            title: "¡Bien!",
            text: "Se ingreso la fecha fin de la licencia",
            icon: "success",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });

          break;

        default:
          dispatch({
            type: LOADING_ACCIDENTES_FALSE,
          });

          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          break;
      }
    } catch (err) {
      dispatch({
        type: ERROR_ACCIDENTE,
        payload: err.message,
      });
    }
  };

export const traer_todos_contratos_trabajador =
  (idTrabajador, idEmpresa) => async (dispatch) => {
    try {
      const url = `${API}/Trabajadores/Contratos?idTrabajador=${idTrabajador}&idEmpresa=${idEmpresa}`;
      const request = await fetch(url);
      const data = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          dispatch({
            type: BUSCAR_CONTRATOS_TRABAJADOR,
            payload: data.contratoDB,
          });
          break;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          break;
      }
    } catch (error) {
      dispatch({
        type: ERROR_ACCIDENTE,
        payload: error.message,
      });
    }
  };

export const traer_todos_los_accidentes_historicos =
  (idTrabajador) => async (dispatch) => {
    try {
      const ID_ACCIDENTE = "5f3eec676dd7cc57f0d3a25e";

      const url = `${API}/IngresoDeDias/Bitacoras/TipoBitacora/${idTrabajador}/${ID_ACCIDENTE}`;

      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          return data;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          return null;
      }
    } catch (error) {
      dispatch({
        type: ERROR_ACCIDENTE,
        payload: error.message,
      });
      return null;
    }
  };

export const traer_todos_las_fechas_trabajador_por_contrato =
  (idTrabajador, idContrato, FechaInicio, FechaFin) => async (dispatch) => {
    try {
      
      let url;
      if (FechaInicio !== undefined && FechaFin !== undefined) {
        url = `${API}/IngresoDeDias/Actuales/Contrato/${idTrabajador}/${idContrato}?InicioBusqueda=${FechaInicio}&FinBusqueda=${FechaFin}`;
      } else {
        url = `${API}/IngresoDeDias/Actuales/Contrato/${idTrabajador}/${idContrato}`;
      }
      const request = await fetch(url);
      const { data } = await request.json();
      const status = await request.status;

      switch (status) {
        case 200:
          return data;
        default:
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido algo inesperado vuelve a intentarlo nuevamente",
            icon: "error",
          });
          return null;
      }
    } catch (error) {
      dispatch({
        type: ERROR_ACCIDENTE,
        payload: error.message,
      });
      return null;
    }
  };
