import React, { Component } from "react";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import {
  Row,
  Col,
  Layout,
  Empty,
  Button,
  Select,
  DatePicker,
  Card,
  Popover,
  Space,
  Tooltip,
  Steps,
  Pagination,
} from "antd";

import Timeline, {
  DateHeader,
  TimelineHeaders,
  TodayMarker,
  CustomMarker,
} from "react-calendar-timeline";
import { connect } from "react-redux";
import * as asistenciaTimeLineActions from "../../Redux/actions/asistenciaTimeLineActions";
import moment from "moment";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import "./Asistencia.css";
import Swal from "sweetalert2";
import accesosPagina from '../../funcionesGenericas/accesosUsuario';
import Informacion from "../../img/icons/boton_informacion.svg";

// import checkin from "../../img/icons/arrow.svg";
// import validado from "../../img/icons/ticket.png";

import {
  convertirFechaString,
  corregirZonaHoraria
} from '../../funcionesGenericas/fechas';

const { Header, Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Step } = Steps;


export class Asistencia extends Component {

  state = {
    LoadingForm: false,
    trabajadoresFiltros: null,
    fechaBusquedaInicio: null,
    fechaBusquedaFin: null,

    centro: null,
    nombreCCSelect: null,
    nombreCCFiltrado: null,

    mostrarCalendario: false,
    llegaronAccesos: false,

    personasFiltradas:[],
    loading: false,
    // limit:3,
    // skip:1,
    filtroPActual: 1,
    paginasCC:0,
    pageSizeDefault: 3,
    skip: 0
  };

  componentWillMount = async () => {

    const { pageSizeDefault } = this.state;

    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {

      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      const centrosCosto = await this.props.traer_centros_de_costo(idEmpresa);
    

      let ccSeleccionado;
      let nombrePrimerCC;

      // si encuentra centros de costo, usar el primero
      if (centrosCosto.length > 0) {
        ccSeleccionado = centrosCosto[0]._id;
        nombrePrimerCC = centrosCosto[0].Nombre;
      } else {
        ccSeleccionado = null;
        nombrePrimerCC = centrosCosto[0].Nombre;
      }

      let str = moment().format('yyyy/MM/DD');

      //await this.props.traer_personas_unix_new(
      let personas = await this.props.traer_personas_asistencia_manual(
        ccSeleccionado,
        convertirFechaString(moment(str), 1).fechaString,
        convertirFechaString(moment(str), 2).fechaString,
        0,
        pageSizeDefault
      );

      const accesos = await accesosPagina.obtenerAccesosPagina('AsistenciaManual', 'Asistencia');
      
      this.setState({
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
        llegaronAccesos: true,
        centro: ccSeleccionado,
        nombreCCSelect: nombrePrimerCC,
        nombreCCFiltrado: nombrePrimerCC,
        personasFiltradas: personas.data,
        paginasCC: personas.myCountTrabajadores,
        filtroPActual: 1,
      });
    }
  }

  // handleChangeCentro = async (centro, fechaInicio, fechaFin) => {

  //   await this.setState({
  //     LoadingForm: true,
  //   });

  //   await this.props.traer_personas(centro, fechaInicio, fechaFin);

  //   await this.setState({
  //     LoadingForm: false,
  //   });
  // };


  ///Nueva customDot controla api de pasar y quitar asistencia por dia
  customDot = (dot, {status} ) =>(

    <span onClick={() => {

      const { pageSizeDefault, skip, personasFiltradas } = this.state;

      if(dot._owner.memoizedProps){
        
        const newDot = { ...dot._owner.memoizedProps }
        let idSeleccionado = newDot.children;
        let diaSeleccionado = newDot.title;

        let trabajador;
        let objetoPlaza = {};

        personasFiltradas.forEach(persona => {
          persona.DataDias.map((dia) => { 
            if(dia._id.toString() === idSeleccionado.toString()){
              trabajador = {
                "Dia": dia,
                "DetallePlazas": persona.DetallePlazas
              } 
            }
          });
        });

        trabajador.DetallePlazas.map((plaza) => {

          const fecha =  trabajador.Dia.Fecha;
          const fechaI = moment(plaza.Fecha_Inicio, 'DD-MM-YYYY').format('YYYY-MM-DD');
          const fechaF = moment(plaza.Fecha_Fin, 'DD-MM-YYYY').format('YYYY-MM-DD');

          if(moment.utc(fecha).isBetween(moment.utc(fechaI), moment.utc(fechaF), null, "[]")){
            objetoPlaza.Fecha_Inicio = plaza.Fecha_Inicio;
            objetoPlaza.Fecha_Fin = plaza.Fecha_Fin;
            objetoPlaza.Nombre_Plaza = plaza.Nombre_Plaza;
          }

        });

        if(this.state.Editar){
          if(newDot.className === "dot-blue-special"){
            Swal.fire({
              title: "¿Estás seguro?",
              text: `Se quitará la asistencia del día seleccionado.`,
              footer: trabajador ? "Dia "+diaSeleccionado+" <br> Plaza: "+ objetoPlaza.Nombre_Plaza +" ("+ objetoPlaza.Fecha_Inicio +" hasta "+ objetoPlaza.Fecha_Fin +")" : `Dia ${diaSeleccionado}`,
              //"Se quitará asistencia del dia seleccionado.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#1FC600",
              cancelButtonColor: "#d33",
              confirmButtonText: "Sí",
              cancelButtonText: "No",
            }).then(async (result) => {
              if (result.value) {
                let usuario = localStorage.getItem("usuario-people-manage");
                /////
                if (
                  usuario !== null &&
                  usuario !== "" &&
                  usuario !== undefined &&
                  usuario !== "undefined"
                ) {
      
                  this.setState({
                    loading: true
                  });
      
                  usuario = JSON.parse(usuario);
                  let centro = this.state.centro;
                  let fechaInicio = this.state.fechaBusquedaInicio;
                  let fechaFin = this.state.fechaBusquedaFin;
                  const empresa = usuario.EmpresaSeleccionada.Empresa._id;
                  if (fechaInicio && fechaFin) {

                    let personas = await this.props.quitarAsistenciaPorDia(
                      idSeleccionado,
                      fechaInicio,
                      fechaFin,
                      centro,
                      empresa,
                      pageSizeDefault,
                      skip
                    );
      
                    this.setState({
                      loading: false,
                      personasFiltradas: personas.data,
                    });
      
                  } else {
                    let personas = await this.props.quitarAsistenciaPorDia(
                      idSeleccionado,
                      moment().format("YYYY-MM-DD"),
                      moment().format("YYYY-MM-DD"),
                      centro,
                      empresa,
                      pageSizeDefault,
                      skip
                    );
      
                    this.setState({
                      loading: false,
                      personasFiltradas: personas.data,
                    });
                  }
                }
                //// 
              }
            });
          
          }else if(newDot.className === "dot-green-special"){
            Swal.fire({
              title: "¿Estás seguro?",
              text: `Se pasará la asistencia del día seleccionado.`,
              footer: trabajador ? "Dia "+diaSeleccionado+" <br> Plaza: "+ objetoPlaza.Nombre_Plaza +" ("+ objetoPlaza.Fecha_Inicio +" hasta "+ objetoPlaza.Fecha_Fin +")" : `Dia ${diaSeleccionado}`,
              //"Se pasará asistencia del dia seleccionado.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#1FC600",
              cancelButtonColor: "#d33",
              confirmButtonText: "Sí",
              cancelButtonText: "No",
            }).then(async (result) => {
              if (result.value) {
                let usuario = localStorage.getItem("usuario-people-manage");
                /////
                if (
                  usuario !== null &&
                  usuario !== "" &&
                  usuario !== undefined &&
                  usuario !== "undefined"
                ) {
      
                  this.setState({
                    loading: true
                  });
      
                  usuario = JSON.parse(usuario);
                  let centro = this.state.centro;
                  let fechaInicio = this.state.fechaBusquedaInicio;
                  let fechaFin = this.state.fechaBusquedaFin;
              
                  const empresa = usuario.EmpresaSeleccionada.Empresa._id;

                  if (fechaInicio && fechaFin) {
                    let personas = await this.props.pasarAsistenciaPorDia(
                      idSeleccionado,
                      fechaInicio,
                      fechaFin,
                      centro,
                      empresa,
                      pageSizeDefault,
                      skip
                    );
      
                    this.setState({
                      loading: false,
                      personasFiltradas: personas.data,
                    });
      
                  } else {
                    let personas = await this.props.pasarAsistenciaPorDia(
                      idSeleccionado,
                      moment().format("YYYY-MM-DD"),
                      moment().format("YYYY-MM-DD"),
                      centro,
                      empresa,
                      pageSizeDefault,
                      skip
                    );
      
                    this.setState({
                      loading: false,
                      personasFiltradas: personas.data,
                    });
                  }
                }
                //// 
              }
            });
          
          }else{
            Swal.fire({
              title: "Advertencia",
              text: "¡No se puede quitar o pasar asistencia al día seleccionado!",
              icon: "warning",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });
          }
        }else{
          Swal.fire({
            title: "Advertencia",
            text: "¡No tienes permisos para realizar esta acción!",
            icon: "warning",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });
        }
      }
    }}>{dot}</span>
  );

  //Nueva quitarAsistenciaPersonal 
  quitarAsistenciaPersonal = async(objEnviar) =>{

    const { pageSizeDefault, skip } = this.state;

    if (!this.state.Editar) {

      Swal.fire({
        title: "¡Ups!",
        text: "No tienes los permisos para quitar asistencia, comunícate con el administrador del sistema.",
        icon: "warning",
        confirmButtonColor: "#e60aa3",
        confirmButtonText: "Ok",
      })

    }else{
      Swal.fire({
        title: "¿Estás seguro?",
        //text: "Se quitará asistencia masiva del trabajador seleccionado.",
        text:"Se quitará asistencia masiva del trabajador, en el rango de fechas seleccionadas.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1FC600",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {

        if (result.value) {
        
          let usuario = localStorage.getItem("usuario-people-manage");

          if (
            usuario !== null &&
            usuario !== "" &&
            usuario !== undefined &&
            usuario !== "undefined"
          ) {

            this.setState({
              loading: true
            });

            usuario = JSON.parse(usuario);
            let centro = this.state.centro;
            let fechaInicio = this.state.fechaBusquedaInicio;
            let fechaFin = this.state.fechaBusquedaFin;
            const empresa = usuario.EmpresaSeleccionada.Empresa._id;
            if (fechaInicio && fechaFin) {
              let personas = await this.props.quitarAsistenciaMasivaPersonal(
                objEnviar,
                fechaInicio,
                fechaFin,
                centro,
                empresa,
                pageSizeDefault,
                skip
              );

              this.setState({
                loading: false,
                personasFiltradas: personas.data,
              });

            } else {
              let personas = await this.props.quitarAsistenciaMasivaPersonal(
                objEnviar,
                moment().format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
                centro,
                empresa,
                pageSizeDefault,
                skip
              );
 
              this.setState({
                loading: false,
                personasFiltradas: personas.data,
              });
            }
          }
        }
      });

    }

  };
  
  //Nueva pasarAsistenciaPersonal
  pasarAsistenciaPersonal = async(objEnviar) =>{

    const { pageSizeDefault, skip } = this.state;

    if (!this.state.Editar) {

      Swal.fire({
        title: "¡Ups!",
        text: "No tienes los permisos para quitar asistencia, comunícate con el administrador del sistema.",
        icon: "warning",
        confirmButtonColor: "#e60aa3",
        confirmButtonText: "Ok",
      })

    }else{
      Swal.fire({
        title: "¿Estás seguro?",
        //text: "Se pasará asistencia masiva del trabajador seleccionado:",
        text:"Se pasará asistencia masiva del trabajador, en el rango de fechas seleccionadas.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1FC600",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.value) {

          let usuario = localStorage.getItem("usuario-people-manage");

          if (
            usuario !== null &&
            usuario !== "" &&
            usuario !== undefined &&
            usuario !== "undefined"
          ) {
            this.setState({
              loading: true
            });
            usuario = JSON.parse(usuario);
            let centro = this.state.centro;
            let fechaInicio = this.state.fechaBusquedaInicio;
            let fechaFin = this.state.fechaBusquedaFin;
            const empresa = usuario.EmpresaSeleccionada.Empresa._id;
            if (fechaInicio && fechaFin) {
              let personas = await this.props.pasarAsistenciaMasivaPersonal(
                objEnviar,
                fechaInicio,
                fechaFin,
                centro,
                empresa,
                pageSizeDefault,
                skip
              );
              this.setState({
                loading: false,
                personasFiltradas: personas.data,
              });

            } else {
              let personas = await this.props.pasarAsistenciaMasivaPersonal(
                objEnviar,
                moment().format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
                centro,
                empresa,
                pageSizeDefault,
                skip
              );
              this.setState({
                loading: false,
                personasFiltradas: personas.data,
              });
            }
          }
        }
      });

    }
  };

  //Nuevo boton paginacion
  paginationFunc = async(page, limit)=>{

    this.setState({loading:true});
    
    const { fechaBusquedaInicio, fechaBusquedaFin, centro } = this.state;

    const skip = (( limit * page ) - limit);
  
    const fechaInicio = fechaBusquedaInicio !== null ? convertirFechaString(moment.utc(fechaBusquedaInicio), 1).fechaString : moment().format("YYYY-MM-DD");
    const fechaFin = fechaBusquedaFin !== null ? convertirFechaString(moment.utc(fechaBusquedaFin), 2).fechaString : moment().format("YYYY-MM-DD");

    let personas = await this.props.traer_personas_asistencia_manual(
      centro,
      fechaInicio,
      fechaFin,
      skip,
      limit
    );
   
    this.setState({
      personasFiltradas: personas.data,
      paginasCC: personas.myCountTrabajadores,
      filtroPActual: page,
      loading:false,
      pageSizeDefault: limit,
      skip
    })


  };

  //Nuevo MostrarCalendarioNuevo
  mostrarCalendarioNuevo = () => {
    let str = moment().format('yyyy/MM/DD');
    let today = convertirFechaString(moment(str), 1).fechaString;
    let fecha = new Date(today);
    fecha = fecha.getTime();
    const AsitenciaPersonaArray = this.state.personasFiltradas;

    return (
      <>
       {
         AsitenciaPersonaArray.length > 0
         ?
         (
          <>
            {
               AsitenciaPersonaArray.map((personaAsis)=>(
                <>
                    <Col span={24} style={{ marginTop: "20px" }}> 
                      <Row>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 24, offset: 0 }}
                          xl={{ span: 24, offset: 0 }}
                          xxl={{ span: 24, offset: 0 }}
                          className={"col-content-asistencia-first"}
                        >
                          <Row>
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 19, offset: 0 }}
                              xl={{ span: 19, offset: 0 }}
                              xxl={{ span: 19, offset: 0 }}
                            >
                              <p className={"col-p-asistencia-title-text"}> {personaAsis.Nombre} 
                                <Popover
                                title={"Información de la plaza:"}
                                placement={"top"}
                                content={
                                  
                                    personaAsis.DetallePlazas.length > 0 ? 
                                    personaAsis.DetallePlazas.map((detalle) => 
                                    (<div>
                                      <p>{detalle.Nombre_Plaza} ({detalle.Fecha_Inicio} hasta {detalle.Fecha_Fin})</p>
                                    </div>)) 
                                    : "Sin info"

                                }
                                style={{borderRadius:'20px !important'}}>
                                  <img src={Informacion} style={{marginTop: '0px', marginLeft: '5px', width: '20px'}}/> 
                                </Popover>
                                <br/>
                                <label className={"col-p-asistencia-subtitle-text"}>Centro de costos: {personaAsis.Centro_Costos}</label><br/>

                                { personaAsis.DetallePlazas.length > 0 && 
                                  (
                                    <label className={"col-p-asistencia-subtitle-text"}>Plaza: {personaAsis.DetallePlazas[0].Nombre_Plaza}</label>
                                  )
                                }
                               
                              </p>
                            </Col>
                          

                            {/* Botones de asistencia Personal */}
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 5, offset: 0 }}
                              xl={{ span: 5, offset: 0 }}
                              xxl={{ span: 5, offset: 0 }}
                            > 
                              {/* Quitar Asistencia */} 
                              <Button
                                type="primary"
                                //className={"btn-quitar-asistencia"}
                                className={!this.state.Editar ? "btn-quitar-asistencia-disabled" : "btn-quitar-asistencia"}
                                disabled={!this.state.Editar}
                                id={personaAsis.idTrabajador}
                                onClick={() => {
                                    this.quitarAsistenciaPersonal(personaAsis);
                                }}
                              >
                                Quitar asistencia
                              </Button>
                              {/* Pasar Asistencia */} 
                              <Button
                                type="primary"
                                //className={"btn-pasar-asistencia"}
                                className={!this.state.Editar ? "btn-pasar-asistencia-disabled" : "btn-pasar-asistencia"}
                                disabled={!this.state.Editar}
                                onClick={() => {
                                  this.pasarAsistenciaPersonal(personaAsis);
                                }}
                              >
                                Pasar asistencia
                              </Button>
                            </Col>
                          </Row>

                          <Row>
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 24, offset: 0 }}
                              xl={{ span: 24, offset: 0 }}
                              xxl={{ span: 24, offset: 0 }}
                              className={"col-content-asistencia-second"}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  overflowX: "scroll",
                                  marginBottom: "10px",
                                  textAlign:"center",
                                }}
                                className="contenedor-step-asistencia"
                              > 
                                <Steps current={0} progressDot={this.customDot} 
                                  className="ant-icon-tamaño-asistencia container-step-asistencia tail-step-asistencia"
                                >
                                  {
                                    personaAsis.DataDias.map((diasCC)=>
                                    <>
                                      <Step key={diasCC._id} children={diasCC._id} title={moment.utc(diasCC.Fecha).format('DD')} className={diasCC.Estado ==="5f3ef6981ecf531c1c8ab4aa"? "dot-blue-special": diasCC.Estado === "5f3ef6861ecf531c1c8ab4a9"? "dot-green-special": "dot-white"}  />
                                    </>)
                                  }                      
                                </Steps>,
                              </div>
                            </Col>
                          </Row>
                          
                        </Col>
                      </Row>
                    </Col>
                </>
               ))
            }
    
            <Col className={"col-pagination"}>
              <Pagination current={this.state.filtroPActual} onChange={this.paginationFunc} total={this.state.paginasCC} defaultPageSize={this.state.pageSizeDefault} pageSize={this.state.pageSizeDefault} showSizeChanger={true} pageSizeOptions={["3","10","20","50"]} />
            </Col>
          </>
         )
         :
         (
          <>
            <Empty
            imageStyle={{ height: 200, marginTop: "20px" }}
            description={
              <h2 className={"seleccion-trabajador-disponible"}>
                <b>No se han encontrado trabajadores para pasar asistencia.</b>
                <br />
                <b>Intenta buscar por otro centro de costos o rango de fechas.</b>
              </h2>
            }
          >
          </Empty>
          </>
         )
       }
      </>
    )

  };

  //Nueva controla que los dias esten en curso para
  //despues pasar la asistencia masiva de las pesonas que se visualizan validacionMasivaAll(ANTES)
  validacionPasarMasivaAll = () => {

    const { pageSizeDefault, skip } = this.state;

    const estadoEnCurso = "5f3ef6861ecf531c1c8ab4a9";

    Swal.fire({
      title: "¿Estás seguro?",
      //text: "Se validará asistencia de todos los trabajadores que se ven en el calendario.",
      text:"Se pasará la asistencia de todos los trabajadores mostrados por pantalla, en el rango de fechas seleccionadas.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async (result) => {

      if (result.value) {

        let usuario = localStorage.getItem("usuario-people-manage");
        if (
          usuario !== null &&
          usuario !== "" &&
          usuario !== undefined &&
          usuario !== "undefined"
        ) {
          usuario = JSON.parse(usuario);
          const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
          let arregloTrabajadores = [];

          let itemsAsistenciaMasiva =  this.state.personasFiltradas;
          itemsAsistenciaMasiva.forEach((item) => {
            item.DataDias.forEach(bitacoraDia => {
              if (bitacoraDia.Estado.toString() === estadoEnCurso) {

                let ingresoBitacora = {
                  IdTrabajador: bitacoraDia.TrabajadorBuscado[0]._id.toString(),
                  IdPlaza: bitacoraDia.IdPlaza,
                  IdPuestoPlaza: bitacoraDia.IdPuestoPlaza,
                  Fecha: bitacoraDia.Fecha,
                  IdGrupo: bitacoraDia.TrabajadorBuscado[0]._id.toString(),
                  Estado: bitacoraDia.Estado,
                  IdBitacora: bitacoraDia._id,
                  IdFaena: bitacoraDia.IdFaena,
                  Inicio_Planificacion: bitacoraDia.Inicio_Planificacion,
                  Fin_Planificacion: bitacoraDia.Fin_Planificacion
                };

                arregloTrabajadores.push(ingresoBitacora);
              }
            });
            
          });

          if (arregloTrabajadores.length === 0) {
            Swal.fire({
              title: "Error",
              text: "¡No hay ningún usuario para agregar asistencia!",
              icon: "error",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });
          }else {
            this.setState({
              loading: true,
            });

            // let objEnviar = {
            //   data: arregloTrabajadores,
            // };

            const bitacoras = arregloTrabajadores;

            let centro = this.state.centro;
            let fechaInicio = this.state.fechaBusquedaInicio;
            let fechaFin = this.state.fechaBusquedaFin;

            if (fechaInicio && fechaFin) { 
              
              let personas = await this.props.pasarAsistenciaMasivaPersonalAll(
                bitacoras,
                fechaInicio,
                fechaFin,
                centro,
                idEmpresa,
                pageSizeDefault,
                skip
              );

              this.setState({
                loading: false,
                personasFiltradas: personas.data,
                // filtroPActual: 1,
              });
              
            } else {

              let personas = await this.props.pasarAsistenciaMasivaPersonalAll(
                bitacoras,
                moment().format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
                centro,
                idEmpresa,
                pageSizeDefault,
                skip
              );

              this.setState({
                loading: false,
                personasFiltradas: personas.data,
                // filtroPActual: 1,
              });
            }
          }

        }
      }
    });

  };

  //Nueva controla que los dias esten en curso para
  //despues pasar la asistencia masiva de las pesonas que se visualizan(EN Proceso)
  validacionQuitarMasivaAll = () =>{

    const { pageSizeDefault, skip } = this.state;

    //bitacoras ya cubiertas
    const estadoYaCubierto = "5f3ef6981ecf531c1c8ab4aa";///azul

    Swal.fire({
      title: "¿Estás seguro?",
      //text: "Se quitará la asistencia de todos los trabajadores que se ven en el calendario.",
      text:"Se quitará la asistencia de todos los trabajadores mostrados por pantalla, en el rango de fechas seleccionadas.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        let usuario = localStorage.getItem("usuario-people-manage");
        if (
          usuario !== null &&
          usuario !== "" &&
          usuario !== undefined &&
          usuario !== "undefined"
        ){
          usuario = JSON.parse(usuario);
          const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
          let arregloTrabajadores = [];
          let itemsAsistenciaMasiva =  this.state.personasFiltradas;

          itemsAsistenciaMasiva.forEach((item) => {
            item.DataDias.forEach(bitacoraDia => {
              if (bitacoraDia.Estado.toString() === estadoYaCubierto) {

                let ingresoBitacora = {
                  IdTrabajador: bitacoraDia.TrabajadorBuscado[0]._id.toString(),
                  IdPlaza: bitacoraDia.IdPlaza,
                  IdPuestoPlaza: bitacoraDia.IdPuestoPlaza,
                  Fecha: bitacoraDia.Fecha,
                  IdGrupo: bitacoraDia.TrabajadorBuscado[0]._id.toString(),
                  Estado: bitacoraDia.Estado,
                  IdBitacora: bitacoraDia._id,
                  IdFaena: bitacoraDia.IdFaena,
                  Inicio_Planificacion: bitacoraDia.Inicio_Planificacion,
                  Fin_Planificacion: bitacoraDia.Fin_Planificacion
                };
                
                arregloTrabajadores.push(ingresoBitacora);
              }
            });
            
          });
     
          if (arregloTrabajadores.length === 0) {
            Swal.fire({
              title: "Error",
              text: "¡No hay ningún usuario para quitar asistencia!",
              icon: "error",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            });
          }else {
            this.setState({
              loading: true,
            });

            // let objEnviar = {
            //   data: arregloTrabajadores,
            // };

            const bitacoras = arregloTrabajadores;

            let centro = this.state.centro;
            let fechaInicio = this.state.fechaBusquedaInicio;
            let fechaFin = this.state.fechaBusquedaFin;
            const empresa = usuario.EmpresaSeleccionada.Empresa._id;
            if (fechaInicio && fechaFin) { 
              let personas = await this.props.quitarAsistenciaMasivaPersonalAll(
                bitacoras,
                fechaInicio,
                fechaFin,
                centro,
                empresa,
                pageSizeDefault,
                skip
              );
              this.setState({
                loading: false,
                personasFiltradas: personas.data,
                // filtroPActual: 1,
              });
            } else {
              let personas = await this.props.quitarAsistenciaMasivaPersonalAll(
                bitacoras,
                moment().format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
                centro,
                empresa,
                pageSizeDefault,
                skip
              );
              this.setState({
                loading: false,
                personasFiltradas: personas.data,
                // filtroPActual: 1,
              });
            }
          
          }

        }
      }
    });
  };
  /////////////////////////////////////////////////////////////////
  
  dobleClickBarra = (bitacoraSeleccionada) => {

    const estadoEnCurso = "5f3ef6861ecf531c1c8ab4a9";

    if (!this.state.Editar) {

      Swal.fire({
        title: "¡Ups!",
        text: "No tienes los permisos para confirmar asistencia, comunícate con el administrador del sistema.",
        icon: "warning",
        confirmButtonColor: "#e60aa3",
        confirmButtonText: "Ok",
      })

    } else if (bitacoraSeleccionada.Estado.toString() === estadoEnCurso) {

      Swal.fire({
        title: "¿Estás seguro?",
        text: "Se validará asistencia del trabajador seleccionado",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1FC600",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {

        if (result.value) {

          let usuario = localStorage.getItem("usuario-people-manage");

          if (
            usuario !== null &&
            usuario !== "" &&
            usuario !== undefined &&
            usuario !== "undefined"
          ) {

            usuario = JSON.parse(usuario);

            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

            let ingresoBitacora = {
              IdTrabajador: bitacoraSeleccionada.TrabajadorBuscado[0]._id.toString(),
              IdPlaza: bitacoraSeleccionada.IdPlaza,
              IdPuestoPlaza: bitacoraSeleccionada.IdPuestoPlaza,
              Fecha: bitacoraSeleccionada.Fecha,
              IdGrupo: bitacoraSeleccionada.TrabajadorBuscado[0]._id.toString(),
            };

            let arregloTrabajadores = [];
            arregloTrabajadores.push(ingresoBitacora);

            let objEnviar = {
              trabajadores: arregloTrabajadores,
              idEmpresa
            };

            let centro = this.state.centro;
            let fechaInicio = this.state.fechaBusquedaInicio;
            let fechaFin = this.state.fechaBusquedaFin;

            if (fechaInicio && fechaFin) {

              this.props.confirmarAsistenciaManual(
                objEnviar,
                centro,
                fechaInicio,
                fechaFin,
                idEmpresa,
              );

            } else {

              this.props.confirmarAsistenciaManual(
                objEnviar,
                centro,
                moment().format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
                idEmpresa,
              );
            }
          }
        }
      });
    } else {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Se quitará la asistencia del trabajador seleccionado.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1FC600",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {

        if (result.value) {

          let usuario = localStorage.getItem("usuario-people-manage");

          if (
            usuario !== null &&
            usuario !== "" &&
            usuario !== undefined &&
            usuario !== "undefined"
          ) {

            usuario = JSON.parse(usuario);

            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

            let ingresoBitacora = {
              IdTrabajador: bitacoraSeleccionada.TrabajadorBuscado[0]._id.toString(),
              IdPlaza: bitacoraSeleccionada.IdPlaza,
              IdPuestoPlaza: bitacoraSeleccionada.IdPuestoPlaza,
              Fecha: bitacoraSeleccionada.Fecha,
              IdGrupo: bitacoraSeleccionada.TrabajadorBuscado[0]._id.toString(),
            };

            let arregloTrabajadores = [];
            arregloTrabajadores.push(ingresoBitacora);

            let objEnviar = {
              trabajadores: arregloTrabajadores,
            };

            let centro = this.state.centro;
            let fechaInicio = this.state.fechaBusquedaInicio;
            let fechaFin = this.state.fechaBusquedaFin;

            if (fechaInicio && fechaFin) {

              this.props.quitarAsistenciaManual(
                objEnviar,
                centro,
                fechaInicio,
                fechaFin,
                idEmpresa
              );

            } else {

              this.props.quitarAsistenciaManual(
                objEnviar,
                centro,
                moment().format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
                idEmpresa
              );
            }
          }
        }
      });
    }

  };

  mostrarCalendario = () => {
    if (this.props.mostrarCalendario === true && this.state.llegaronAccesos) {

      let str = moment().format('yyyy/MM/DD');

      // let today = moment().format("YYYY-MM-DD HH:MM");
      let today = convertirFechaString(moment(str), 1).fechaString;

      let fecha = new Date(today);

      fecha = fecha.getTime();

      return (
        <>
          <Timeline
            groups={this.props.grupos}
            items={this.props.items}
            sidebarWidth={300}
            defaultTimeStart={moment()}
            defaultTimeEnd={moment().add(35, "day")}

            groupRenderer={({ group }) => {
              return (
                <Row className="custom-group">

                  <Col span={6}>
                    <img
                      src={group.img}
                      style={{
                        width: 45,
                        height: 45,
                        borderRadius: "26px",
                      }}
                      alt="icono"
                    />
                  </Col>

                  <Col span={18}>
                    <h1 className="nombre-asistencia-timeline">
                      {group.title}
                    </h1>
                    <h2 className="centro-costo-asistencia-timeline">
                      Centro de Costo:{" "}
                      {/* {group.bitacora.IdFaena.Centro_De_Costo.Nombre} */}
                      {this.state.nombreCCFiltrado}
                    </h2>
                  </Col>

                </Row>
              );
            }}

            itemRenderer={({
              item,
              itemContext,
              getItemProps,
              getResizeProps,
            }) => {

              const {
                left: leftResizeProps,
                right: rightResizeProps,
              } = getResizeProps();

              return (
                <Popover
                  title={`Acciones:`}
                  content={
                    <Space className={"popover-contenido"}>
                      <Button
                        // se utiliza acceso editar para pasar asistencia y eliminar para quitarla
                        disabled={item.bitacora.Estado.toString() === "5f3ef6981ecf531c1c8ab4aa" ? !this.state.Eliminar : !this.state.Editar}
                        style={{
                          borderRadius: "20px"
                        }}
                        onClick={() => {
                          this.dobleClickBarra(item.bitacora, item.groups);
                        }}
                      >{item.bitacora.Estado.toString() === "5f3ef6981ecf531c1c8ab4aa" ? 'Quitar Asistencia' : 'Pasar asistencia'}</Button>
                    </Space>
                  }
                >
                  <div
                    {...getItemProps(item.itemProps)}
                    className={`barra-color ${item.colorBarra}`}

                  // onDoubleClick={() => {
                  //   this.dobleClickBarra(item.bitacora);
                  // }}
                  >
                    {itemContext.useResizeHandle ? (
                      <div {...leftResizeProps} />
                    ) : (
                      ""
                    )}

                    <div
                      className="rct-item-content"
                      style={{
                        maxHeight: `${itemContext.dimensions.height}`,
                      }}
                    ></div>

                    {itemContext.useResizeHandle ? (
                      <div {...rightResizeProps} />
                    ) : (
                      ""
                    )}
                  </div>

                </Popover>

              );
            }}

          >
            <TodayMarker />
            <CustomMarker date={fecha}>
              {({ styles, date }) => {
                const customStyles = {
                  ...styles,
                  backgroundColor: "#74c9fc",
                  width: "4px",
                };
                return <div style={customStyles} />;
              }}
            </CustomMarker>
            <TimelineHeaders
              style={{
                backgroundColor: "#f4f4f4",
              }}
              calendarHeaderClassName="calendarioHeader"
            >
              <DateHeader unit="primaryHeader" className="mesHeader" />
              <DateHeader
                style={{
                  fontFamily: "MavenPro-SemiBold",
                  color: "black",
                  height: 40,
                }}
              />
            </TimelineHeaders>
          </Timeline>
        </>
      );
    } else {
      return (
        <Empty
          imageStyle={{ height: 200, marginTop: "20px" }}
          description={
            <h2 className={"seleccion-trabajador-disponible"}>
              <b>No se han encontrado trabajadores para pasar asistencia.</b>
              <br />
              <b>Intenta buscar por otro centro de costos o rango de fechas.</b>
            </h2>
          }
        >
        </Empty>
      );
    }
  };

  // mostrarTarjetas = () => {

  //   if (this.props.mostrarCalendario === true) {

  //     return (
  //       <>
  //         <h3 style={{ fontFamily: 'MavenPro-SemiBold', color: '#061fcb' }}>
  //           {/* Resultado de filtro día: {moment(this.state.fechaBusquedaInicio).format('DD-MM-YYYY')} */}
  //           Resultado de filtro día: {convertirFechaString(moment.utc(this.state.fechaBusquedaInicio), 1).fechaDDMMYYYY}
  //         </h3>

  //         <Row gutter={15}>
  //           {this.props.items.map((item, index) => (

  //             <Col xs={24} sm={12} md={12} lg={12} xl={6} key={index}>

  //               <Card className={'asistencia-card'}>

  //                 <Row align={'middle'} gutter={15}>

  //                   <Col xs={7} sm={7} md={6} lg={6} xl={6}
  //                     align={'center'} style={{ height: 'fit-content' }}>

  //                     <div style={{ width: '55px', position: 'relative' }}>
  //                       <img
  //                         src={item.bitacora.TrabajadorBuscado[0].Imagen}
  //                         alt='img_trabajador'
  //                         style={{ width: '100%', borderRadius: '100%' }}
  //                       />

  //                       <img
  //                         src={item.bitacora.Estado.toString() === '5f3ef6861ecf531c1c8ab4a9' ? checkin : validado}
  //                         alt={'estado_icon'}
  //                         style={{ width: '24px', position: 'absolute', bottom: '0px', right: '-5px' }}
  //                       />
  //                     </div>


  //                   </Col>

  //                   <Col xs={17} sm={17} md={18} lg={18} xl={18}>

  //                     <p className={'texto-card-asistencia'} style={{ fontFamily: 'MavenPro-SemiBold', color: '#061fcb' }}>
  //                       {item.bitacora.TrabajadorBuscado[0].Nombre} {item.bitacora.TrabajadorBuscado[0].Apellido_Paterno}
  //                     </p>

  //                     <p className={'texto-card-asistencia'} style={{ fontFamily: 'MavenPro-Regular' }}>
  //                       {item.bitacora.CentroDeCostoBuscado
  //                         ? item.bitacora.CentroDeCostoBuscado[0].Nombre
  //                         : ''}
  //                     </p>

  //                     <Button
  //                       className="validar-asistencia-btn"
  //                       type="primary"
  //                       onClick={() => {
  //                         this.dobleClickBarra(item.bitacora);
  //                       }}
  //                       disabled={item.bitacora.Estado.toString() !== '5f3ef6861ecf531c1c8ab4a9'}
  //                     >
  //                       Validar asistencia
  //                     </Button>

  //                   </Col>

  //                 </Row>

  //               </Card>

  //             </Col>

  //           ))}
  //         </Row>

  //       </>
  //     )

  //   } else {
  //     return (
  //       <Empty
  //         imageStyle={{ height: 200, marginTop: "20px" }}
  //         description={
  //           <h2 className={"seleccion-trabajador-disponible"}>
  //             <b>No se han encontrado trabajadores para pasar asistencia.</b>
  //             <br />
  //             <b>Intenta buscar por otro centro de costos o rango de fechas.</b>
  //           </h2>
  //         }
  //       >
  //       </Empty>
  //     );
  //   }

  // }
 
  render() {
    
    return (
      <Layout style={{ height: "100%" }}>

        <Loading cargando={this.state.loading} />

        <Header style={{ background: "none", fontFamily: "MavenPro-Bold" }}>
          <HeaderFicha
            titulo="Confirmación De Asistencia Personas"
            subtitulo="Trabajadores que están en trabajo efectivo"
          />
        </Header>

        <hr className="division" style={{ marginRight: 35 }}  />

        <Content className="contenedor-general" style={{ background: "none" }}>

          <p className={"p-filtrar-plane-text"}>Filtra por:</p>
          <Row
              style={{ padding: "6px 0px 22px", marginTop: "0px" }}
              gutter={15}
              //gutter={20} className={'asistencia-desktop'}
          >
            <Col span={5} xs={24} s={24} sm={24} md={24} lg={5} xl={5}>
                <Select
                  showSearch={true}
                  placeholder="Selecciona un centro de costo"
                  optionFilterProp="children"
                  size="middle"
                  loading={this.props.loading}
                  onSelect={(value, insideValue) => {
                    let nombreCC = insideValue.children;
                    this.setState({
                      centro: value,
                      nombreCCSelect: nombreCC,
                    });
                  }}
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={this.state.centro}
                >
                  {this.props.centros_de_costo === null ? (
                    <Option value="Cargando trabajadores...">
                      Cargando empresas...
                    </Option>
                  ) : (
                    this.props.centros_de_costo.map(
                      ({ Nombre, _id }, index) => (
                        <Option key={index} value={_id}>
                          {Nombre}
                        </Option>
                      )
                    )
                  )}
                </Select>
            </Col>
            
            <Tooltip
                title={"Se recomienda filtrar por un máximo de 30 días."}
                color={'geekblue'}
                placement={"right"}
              >
              <Col span={8} xs={24} s={24} sm={24} md={24} lg={7} xl={7}>
                <RangePicker
                  format={"DD-MM-YYYY"}
                  locale={locale}
                  onChange={(date) => {
                    if (date === null) {
                      return "";
                    } else {
                      this.setState({
                        fechaBusquedaInicio: convertirFechaString(date[0], 1).fechaString,
                        fechaBusquedaFin: convertirFechaString(date[1], 2).fechaString,

                        fechaBusquedaInicioUnix: convertirFechaString(date[0], 1).fechaUNIX,
                        fechaBusquedaFinUnix: convertirFechaString(date[1], 2).fechaUNIX,

                        fechaBusquedaInicioChile: convertirFechaString(moment.utc(date[0]), 1).fechaDDMMYYYY,
                        fechaBusquedaFinChile: convertirFechaString(moment.utc(date[1]), 2).fechaDDMMYYYY,
                      });
                    }
                  }}
                />         
              </Col>
            </Tooltip>

            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 5, offset: 0 }}
              xxl={{ span: 5, offset: 0 }}
              className={"col-filtrarAct"}
            >
              <Button
              type="primary"
                className="btn-asistencia-filtrar"
                onClick={async () => {
                  let usuario = localStorage.getItem(
                    "usuario-people-manage"
                  );
                  if (
                    usuario !== null &&
                    usuario !== "" &&
                    usuario !== undefined &&
                    usuario !== "undefined"
                  ) {
                    this.setState({
                      LoadingForm: true,
                      loading: true
                    })
                    usuario = JSON.parse(usuario);
                    const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
                    let centro = this.state.centro;
                    let str = moment().format('yyyy/MM/DD');
                    let fechaInicio = this.state.fechaBusquedaInicio || convertirFechaString(moment(str), 1).fechaString;
                    let fechaFin = this.state.fechaBusquedaFin || convertirFechaString(moment(str), 2).fechaString;
                    let paginacionNow = this.state.pageSizeDefault;

                    if (fechaInicio && fechaFin) {
                      //await this.props.traer_personas_unix_new(
                        let personas = await this.props.traer_personas_asistencia_manual(
                        centro,
                        fechaInicio,
                        fechaFin,
                        0,
                        paginacionNow,
                      );
                      
                      await this.setState({
                        LoadingForm: false,
                        personasFiltradas: personas.data,
                        nombreCCFiltrado: this.state.nombreCCSelect,
                        loading: false,
                        paginasCC: personas.myCountTrabajadores,
                        filtroPActual: 1,
                        pageSizeDefault: paginacionNow
                      });

                    }
                  }
                }}
              >
                Filtrar
              </Button>
            </Col>


            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 7, offset: 0 }}
              xl={{ span: 7, offset: 0 }}
              xxl={{ span: 7, offset: 0 }}
              className={"col-createAct"}
            >
              <Button
                type="primary"
                onClick={() => {
                  this.validacionQuitarMasivaAll();
                }}
                className={!this.state.Editar ? "selec-form-asistencia-quitar-disabled" : "selec-form-asistencia-quitar"}
                disabled={!this.state.Editar}
              >
                Quitar asistencia masiva
              </Button>

              <Button
                type="primary"
                onClick={() => {
                  this.validacionPasarMasivaAll();
                }}
                className={!this.state.Editar ? "selec-form-asistencia-pasar-disabled" : "selec-form-asistencia-pasar"}
                disabled={!this.state.Editar}
              >
                Pasar asistencia masiva
              </Button>
            </Col>

            <h1 className="title_resultado_filtro">Resultados filtro</h1>
            {/* <Col span={24}>{this.mostrarCalendario()}</Col> */}
            <Col span={24}>{this.mostrarCalendarioNuevo()}</Col>
          </Row>
        
 
        </Content>

        <span>
          <style jsx="true">
            {`
            @media screen and (max-width: 767px) {
              .ant-layout-content {
                margin: 0px !important;
              }
            }

            @media screen and (max-width: 575px) {
              .ant-card-body {
                padding: 10px !important;
              }
            }

            .ant-popover-inner {
              border-radius: 10px;
            }

            .ant-popover-title {
              background-color: #061fcb !important;
              color: white !important;
              border-top-left-radius: 10px !important;
              border-top-right-radius: 10px !important;
            }
          `}
          </style>
        </span>
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  AsistenciaTimeLineReducer: {
    loading,
    error,
    centros_de_costo,
    personasPendientes,
    mostrarCalendario,
    grupos,
    items,
  },
}) => {
  return {
    loading,
    error,
    centros_de_costo,
    personasPendientes,
    mostrarCalendario,
    grupos,
    items,
  };
};

export default connect(
  EstadosAPropiedades,
  asistenciaTimeLineActions
)(Asistencia);
