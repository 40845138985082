import React, { Component } from "react";

import API from "../../Redux/Api/api";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import "moment/locale/es";
import { convertirFechaString} from "../../funcionesGenericas/fechas";
import Swal from "sweetalert2";
// import { Column } from '@ant-design/plots';
import { EyeFilled } from "@ant-design/icons";
import Excel from "../../img/icons-sidebar/blancas/IconExcel.svg";

import {
    Row,
    Col,
    Card,
    Select,
    Button,
    DatePicker,
    Modal,
    Tooltip
} from "antd";

class DashboardCostoCierreMes extends Component {
    constructor(props) {
        super(props);
        this.state = {
          dataGrafico:[],
          fechaSelecionada: moment().format('YYYY-MM-DD'),
          cargoSeleccionado: "",
          centroDeCostoSeleccionado: "",
          modalDetalle:false,
        };

    }

    componentDidMount = async () => {
      let usuario = localStorage.getItem("usuario-people-manage");
      if (
        usuario !== null &&
        usuario !== "" &&
        usuario !== undefined &&
        usuario !== "undefined"
      ) {
          
        usuario = JSON.parse(usuario);
        const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
        const str = moment().format('YYYY-MM-DD');
        const fechaActual = convertirFechaString(moment(str), 1).fechaString;
        const centroDeCosto = "";
        const cargo = "";

        let dataCostoCierreMes = await this.obtenerDataCostoCierreMes(idEmpresa,fechaActual,centroDeCosto,cargo);

        this.setState({
            //dataGrafico: dataCostoCierreMes.data
            dataGrafico: dataCostoCierreMes === undefined ? [] : dataCostoCierreMes.data
        });
  
      }
    };

    obtenerDataCostoCierreMes = async (idEmpresa, fecha, centroCosto , cargo) => {
        try {
            const url = `${API}/CostosCierreDeMes`;
      
            let objeto = {
              idEmpresa,
              fecha,
              centroCosto,
              cargo
            };
      
            const response = await fetch(url, {
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify(objeto),
            });
      
            const status = await response.status;
            const data = await response.json();
      
            if (status === 200 || status === 201) {
              return data;
            } else {
              Swal.fire({
                icon: "error",
                title: "Uuups...",
                text: "No se pudo cargar la información.",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "OK",
              });
      
              return [];
            }
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Uuups...",
              text: "No se pudo cargar la información.",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "OK",
            });
          }
    };

    setFecha = (fecha) => {
      let str = moment(fecha).format('YYYY-MM-DD')
      let fechaFormateada = convertirFechaString(moment(str), 1).fechaString
      
      this.setState({
          fechaSelecionada: fechaFormateada
      });
    };
  
    setCentroCosto = (centroCosto) => {
        this.setState({
            centroDeCostoSeleccionado: centroCosto
        });
    };

    setCargo = (cargo) => {
        this.setState({
            cargoSeleccionado: cargo
        });
    };

    filtrar = async(objeto) => {
      console.log(objeto,"objvalues")
      //llamar a la api
    };

    detalle = async () => {
        this.setState({ 
            modalDetalle:true,
        });
    }

 
    render() {
        let {
            cargosEmpresa,
            centrosCostoEmpresa
        } = this.props;
        const {modalDetalle} = this.state;
      return (
        <>
            {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}> */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Card
                    className="card-style-dashboard card-title-dashboard"
                    title={
                        <>
                        <Row>
                            <Col
                            xs={24}
                            sm={24}
                            md={20}
                            lg={20}
                            xl={20}
                            //style={{textAlign:"center"}}
                            >
                            Costos cierre de mes
                            </Col>
                            <Col
                            xs={24}
                            sm={24}
                            md={4}
                            lg={4}
                            xl={4}
                            style={{textAlign:"right"}}
                            >
                                <Button
                                    type="primary"
                                    className="btn-detalle-dashboard"
                                    onClick={() => {
                                        this.detalle()
                                    }}
                                >
                                    <span className="text-detalle-dashboard">Detalle</span>
                                    <span><EyeFilled className="iconoBotonDetalleCurvaRelevos"/></span>
                                </Button>
                            </Col>
                        </Row>
                        </>
                    }
                    style={{ marginTop: "25px" }}
                >
                    {/* FILTROS */}
       
                    <div>
                        <p className={"label-filtros-dashboard"}>
                            Filtrar por:
                        </p>
                    </div>
                    <Row gutter={20} style={{ marginBottom: "20px" }}>
                        {/* RANGEPICKER */}
                        <Col
                            xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                            style={{ marginBottom: "15px" }}
                        >
                            <DatePicker
                                format={'DD-MM-YYYY'}
                                allowClear={false}
                                className={"select-style-dashboard"}
                                locale={locale}
                                picker="day"
                                defaultValue={moment()}
                                onChange={(fecha) => {
                                    if (fecha == null) {
                                    this.setFecha(moment());
                                    } else {
                                    this.setFecha(fecha);
                                    }
                                }}
                            />
                        </Col>

                        {/* CENTROS DE COSTO */}
                        <Col
                            xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                            style={{ marginBottom: "15px" }}
                        >
                            <Select
                                allowClear
                                className={"select-style-dashboard"}
                                placeholder="Centro de costo"
                                onSelect={(idCentroCosto) => {
                                    this.setCentroCosto(idCentroCosto);
                                }}
                                onClear={() => {
                                    this.setCentroCosto("");
                                }}
                            >
                                {   
                                    centrosCostoEmpresa.map((value, index) => (
                                        <Select.Option key={index} value={value._id} name="CentroCosto">
                                            {value.Nombre}
                                        </Select.Option>
                                    ))
                                }
                    
                            </Select>
                        </Col>

                        {/* CARGOS */}
                        <Col
                            xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                            style={{ marginBottom: "15px" }}
                        >
                            <Select
                                allowClear
                                className={"select-style-dashboard"}
                                placeholder="Cargo"
                                onSelect={(cargo) => {
                                    this.setCargo(cargo);
                                }}
                                onClear={() => {
                                    this.setCargo("");
                                }}
                            >

                                {
                                    cargosEmpresa.map((value, index) => (
                                        <Select.Option key={index} value={value._id} name="Cargo">
                                            {value.Nombre}
                                        </Select.Option>
                                    ))
                                }

                            </Select>
                        </Col>

                        {/* BTN FILTRAR */}
                        <Col
                            xs={24} s={24} sm={5} md={5} lg={5} xl={5} xxl={3}
                            className={"btn-col-filtrar-dashboard "}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                form="formCostoCierreMesDashboard"
                                className={"btn-filtrar-dashboard"}
                                onClick={() => {
                                    const { fechaSelecionada, cargoSeleccionado, centroDeCostoSeleccionado} = this.state;
                                    const objeto =
                                    {
                                        fecha: fechaSelecionada,
                                        cargo: cargoSeleccionado,
                                        centroCosto: centroDeCostoSeleccionado,
                                    } 
                                    this.filtrar(objeto);
                                }}
                            >
                                Filtrar
                            </Button>
                        </Col>
                
                    </Row>
             
                    <h1>FALTA GRAFICO</h1>
                    {/* este grafico queda en stand by por motivos de libreria */}

                </Card>
            </Col>

            <Modal
                visible={modalDetalle}
                onCancel={() => this.setState({modalDetalle: false})}
                className={'modal-detalle-dashboard'}
                footer={null}
                closable={false}
                maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
            >
                <Row  style={{ marginBottom: '20px' }}>
                    <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
                        <h3
                            className="modal-h3-dashboard"
                        >
                            Trabajadores por costos cierre de mes
                        </h3>
                    </Col>

                    <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{textAlign:"right"}}>
                            <Button
                                type="primary"
                                className={'modal-btn-excel-dashboard'}
                                //onClick={this.descargarExcel}
                            >
                                <span className="modal-btn-text-excel-dashboard">Descargar Excel</span>
                                <span><img src={Excel} className="modal-btn-icon-excel-dashboard" alt="Icono Excel"/></span>
                            </Button>
                    </Col>
                </Row>

                <hr className="modal-division-detalle-dashboard" />

                {/* TABLE */}
                
            </Modal>
        </>
      );
    }
}

export default DashboardCostoCierreMes;

