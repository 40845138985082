import {
  ERROR,
  INGRESAR_ESPECIALIDAD,
  LOADING,
  LIMPIAR_ERROR_SUCCESS,
  MOSTRAR_MODAL_ESPECIALIDAD,
  OCULTAR_MODAL_ESPECIALIDAD,
  OBTENER_ESPECIALIDADES_MANTENEDOR,
  ELIMINAR_ESPECIALIDAD_MANTENEDOR,
  OBTENER_ESPECIALIDAD_MANTENEDOR,
  EDITAR_ESPECIALIDAD_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OCULTAR_MODAL_ESPECIALIDAD_EDITAR,
} from "../types/MantenedorEspecialidadesTypes";

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: null,
  modalEspecialidad: false,
  modalEspecialidadEditar: false,
  especialidades: [],
  especialidad: null,
  tablaCargando: true,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case INGRESAR_ESPECIALIDAD:
      return {
        ...state,
        loading: false,
        success: payload,
        modalEspecialidad: false,
        tablaCargando: false,
      };

    case LOADING:
      return {
        ...state,
        loading: true,
        tablaCargando: true,
      };

    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        tablaCargando: false,
      };
    case LIMPIAR_ERROR_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };
    case OBTENER_ESPECIALIDADES_MANTENEDOR:
      return {
        ...state,
        especialidades: payload,
        loading: false,
        tablaCargando: false,
      };
    case OBTENER_ESPECIALIDAD_MANTENEDOR:
      return {
        ...state,
        especialidad: payload,
        loading: false,
        tablaCargando: false,
        modalEspecialidadEditar: true,
      };
    case ELIMINAR_ESPECIALIDAD_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      };
    case EDITAR_ESPECIALIDAD_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        modalEspecialidadEditar: false,
      };
    case TOMAR_VALORES_EDITAR:
      const { nombreCampo, valor } = payload;
      return {
        ...state,

        especialidad: {
          ...state.especialidad,
          [nombreCampo]: valor,
        },
      };

    case MOSTRAR_MODAL_ESPECIALIDAD:
      return {
        ...state,
        modalEspecialidad: true,
      };
    case OCULTAR_MODAL_ESPECIALIDAD_EDITAR:
      return {
        ...state,
        modalEspecialidadEditar: false,
      };
    case OCULTAR_MODAL_ESPECIALIDAD:
      return {
        ...state,
        modalEspecialidad: false,
      };
    default:
      return state;
  }
};
