import React from "react";
import Trabajador from "../../img/icons/Trabajador.svg";

import "moment/locale/es";
import { Button, Row, Col, PageHeader, Table, Space, Tabs } from "antd";
import "./FichaBonificaciones.css";
import moment from "moment";
import { DeleteOutlined, PrinterOutlined } from "@ant-design/icons";
import { convertirFechaString } from "../../funcionesGenericas/fechas";

const { Column } = Table;
const { TabPane } = Tabs;
const FichaTrabajador = (props) => {
  let bonificaciones = props.bonificaciones ? props.bonificaciones : [];
  let bonificacionesDescansos = props.bonificacionesDescansos ? props.bonificacionesDescansos : [];

  return (
    <Row style={{ padding: "5px 20px 0px 20px" }}>
      <Col span={24} style={{ display: "flex" }} className="title">
        <img src={Trabajador} style={{ width: "35px" }} alt="icono modal" />
        <PageHeader
          style={{ padding: "0px 10px 0px 10px" }}
          className="site-page-header"
          title="Bonificaciones del trabajador"
        />
      </Col>
      <Col span={24} style={{ display: "flex" }} className="title">
        <Tabs key={1} defaultActiveKey="1" style={{ width: "100%" }}>
          <TabPane tab="Bonificación Descansos" key="1">
            <Col span={24} className={"cont-tabla-celeste"}>
              <Table
                rowKey="_id"
                dataSource={bonificacionesDescansos}
                bordered={true}
                style={{ fontFamily: "MavenPro-Regular" }}
                locale={{
                  emptyText:
                    "Todavía no se ha ingresado registros de bonificaciones por descansos al trabajador. Para ingresar uno utilice el módulo de vacaciones.",
                }}
              >
                <Column
                  title="Bonificación"
                  key="Nombre"
                  render={(value) => {
                    // console.log(value);
                    // let fechaInicio = moment.utc(value.Fecha_Inicio).format("DD-MM-YYYY");
                    // let fechaFin = moment.utc(value.Fecha_Fin).format("DD-MM-YYYY");

                    let fechaInicio = convertirFechaString(moment.utc(value.Fecha_Inicio), 1).fechaDDMMYYYY;
                    let fechaFin = convertirFechaString(moment.utc(value.Fecha_Fin), 2).fechaDDMMYYYY;

                    return (
                      <p style={{ margin: "0px" }}>
                        {`Comprobante ${fechaInicio} - ${fechaFin}`}
                      </p>
                    );
                  }}
                />

                <Column
                  title="Comprobante imprimible"
                  dataIndex="_id"
                  key="_id"
                  width={200}
                  align={"center"}
                  render={(value) => (
                    <Space size="middle">
                      <Button
                        onClick={() => {
                          var win = window.open(
                            "/ImpresionDescanso?Descanso=" + value,
                            "_blank"
                          );
                        }}
                        className="editar-button button-center"
                        icon={<PrinterOutlined />}
                      // disabled={!this.state.Ver}
                      />
                      {/* <Button
                        onClick={() => {
                          props.eliminarBonificacion(value);
                        }}
                        className="editar-button button-center"
                        icon={<DeleteOutlined />}
                        // disabled={!this.state.Ver}
                      /> */}
                    </Space>
                  )}
                />
              </Table>
            </Col>
          </TabPane>
          <TabPane tab="Bonificación Vacaciones" key="2">
            <Col span={24} className={"cont-tabla-celeste"}>
              <Table
                rowKey="_id"
                dataSource={bonificaciones}
                bordered={true}
                style={{ fontFamily: "MavenPro-Regular" }}
                locale={{
                  emptyText:
                    "Todavía no se ha ingresado registros de bonificaciones por vacaciones al trabajador. Para ingresar uno utilice el módulo de vacaciones.",
                }}
              >
                <Column
                  title="Bonificación"
                  dataIndex="FechasTrabajadas"
                  key="Nombre"
                  render={(value) => {
                    // let fechaInicio = moment.unix(value[0].inicioUnix).format("DD-MM-YYYY");
                    // let fechaFin = moment.unix(value[value.length - 1].finUnix).format("DD-MM-YYYY");

                    let fechaInicio = convertirFechaString(moment.utc(value[0].inicio), 1).fechaDDMMYYYY;
                    let fechaFin = convertirFechaString(moment.utc(value[value.length - 1].fin), 2).fechaDDMMYYYY;
                    return (
                      <p style={{ margin: "0px" }}>
                        {`Comprobante ${fechaInicio} - ${fechaFin}`}
                      </p>
                    );
                  }}
                />

                <Column
                  title="Comprobante imprimible"
                  dataIndex="_id"
                  key="_id"
                  width={200}
                  align={"center"}
                  render={(value) => (
                    <Space size="middle">
                      <Button
                        onClick={() => {
                          var win = window.open(
                            "/ImpresionVacaciones?Vacaciones=" + value,
                            "_blank"
                          );
                        }}
                        className="editar-button button-center"
                        icon={<PrinterOutlined />}
                      // disabled={!this.state.Ver}
                      />
                      <Button
                        onClick={() => {
                          props.eliminarBonificacion(value);
                        }}
                        className="editar-button button-center"
                        icon={<DeleteOutlined />}
                      // disabled={!this.state.Ver}
                      />
                    </Space>
                  )}
                />
              </Table>
            </Col>
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default FichaTrabajador;
