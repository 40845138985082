import React, { useState } from "react";
import SubirFoto from "../../components/subirFoto/SubirFoto";
import logoGenerico from "../../img/logos/logoGenerico.svg";
import Rut from "../../funcionesGenericas/rut";
import Rut_Representante from "../../funcionesGenericas/rut";
//import Chile from "../../img/banderas/chile.svg";
import personal from "../../img/icons/avatar.svg";
import flecha from "../../img/icons/flecha.svg";
import './FormularioEmpresas.css'
import locale from 'antd/lib/date-picker/locale/es_ES';

import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Select,
  Radio,
  Collapse,
  DatePicker,

} from "antd";
import logoGenerico2 from "../../img/logos/logoGenerico2.svg";
import ValidadorRut from "validar-rut";
import Swal from "sweetalert2";
import { obtenerErrorSwal } from "../../funcionesGenericas/respuestas"

import chileanRut from 'chilean-rut'

import {
  convertirFechaString

} from '../../funcionesGenericas/fechas';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

const { Panel } = Collapse;

const FormularioEmpresas = (props) => {

  const [validRutRepresentante, setValidRutRep] = useState('');
  const [validRutContacto, setValidRutCon] = useState('');

  const [statusTelEmpresa, setStatusTelEmpresa] = useState('');
  const [statusTelLegal, setStatusTelLegal] = useState('');
  const [statusTelComercial, setStatusTelComercial] = useState('');

  const [form] = Form.useForm();

  const ObtenerImagen = (imagenUrl) => {
    props.obtenerImagen(imagenUrl);
  };

  const onFinish = (values) => {

    if (Object.keys(values).length === 0) {

      Swal.fire({
        title: "Ups...",
        text: 'Debes completar el formulario primero.',
        icon: "warning",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Continuar",
      });

      return;
    }

    //validacion de campos para que no se ingresen como undefined o null

    if (props.imagen === null || props.imagen === undefined) {
      values.Imagen =
        "https://peoplemanage.blob.core.windows.net/imagenes-sistema/logo_empresa_default.svg";
    } else {
      values.Imagen = props.imagen;
    }

    if (values.Descripcion === null || values.Descripcion === undefined) {
      values.Descripcion = "";
    }

    if (values.Telefono === null || values.Telefono === undefined) {
      values.Telefono = "";
    }

    if (values.Numero_Departamento === null || values.Numero_Departamento === undefined) {
      values.Numero_Departamento = "";
    }

    // values.Fecha_Inicio_Sistema_Unix = momentToUnixFechaInicio(values.Fecha_Inicio_Sistema);
    // values.Fecha_Inicio_Sistema = momentToStringFechaInicio(values.Fecha_Inicio_Sistema, databaseFormat);

    values.Fecha_Inicio_Sistema_Unix = convertirFechaString(values.Fecha_Inicio_Sistema, 1).fechaUNIX;
    values.Fecha_Inicio_Sistema = convertirFechaString(values.Fecha_Inicio_Sistema, 1).fechaString;


    // if (props.departamento === false) {
    //   if (parseInt(values.Numero_Departamento) === 0) {
    //     let objeto = obtenerErrorSwal("Se debe ingresar el número de departamento");
    //     Swal.fire({
    //       title: objeto.titulo,
    //       text: objeto.mensaje,
    //       icon: "error",
    //       confirmButtonColor: "#E60AA3",
    //       confirmButtonText: "Continuar",
    //     });
    //     return false;
    //   }
    // } else {
    //   values.Numero_Departamento = 0;
    // }
    props.crearEmpresa(values);
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const handleTelefonoEmpresa = (e) => {

    let val = e.target.value;

    let isValid = val[0] === '+' && val[1] === '5' && val[2] === '6' && val.length === 12;

    for (let i = 0; i < val.length; i++) {

      if (i !== 0) {

        if (isNaN(val[i])) {
          isValid = false;
        }
      }
    }

    if (isValid) {
      setStatusTelEmpresa('success');
    } else {
      setStatusTelEmpresa('error');
    }

  }

  const handleTelefonoLegal = (e) => {

    let val = e.target.value;

    let isValid = val[0] === '+' && val[1] === '5' && val[2] === '6' && val.length === 12;

    for (let i = 0; i < val.length; i++) {

      if (i !== 0) {

        if (isNaN(val[i])) {
          isValid = false;
        }
      }
    }

    if (isValid) {
      setStatusTelLegal('success');
    } else {
      setStatusTelLegal('error');
    }

  }

  const handleTelefonoComercial = (e) => {

    let val = e.target.value;

    let isValid = val[0] === '+' && val[1] === '5' && val[2] === '6' && val.length === 12;

    for (let i = 0; i < val.length; i++) {

      if (i !== 0) {

        if (isNaN(val[i])) {
          isValid = false;
        }
      }
    }

    if (isValid) {
      setStatusTelComercial('success');
    } else {
      setStatusTelComercial('error');
    }

  }

  // const prefixSelector = (
  //   <Form.Item name="prefix" noStyle>
  //     <Select
  //       style={{
  //         width: 70,
  //       }}
  //     >
  //       {props.nacionalidades.map((value, index) => (
  //         <Option key={index} value={value.Codigo}>
  //           <img src={value.FotoBandera} alt="chile" />  
  //         </Option>
  //       ))}

  //     </Select>
  //   </Form.Item>
  // );
  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>

        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={logoGenerico} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Nueva empresa"
          />
        </Col>

        <Col span={8}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Row>
              <Col lg={10} xl={24}>
                <SubirFoto
                  avatar={logoGenerico2}
                  ObtenerImagen={ObtenerImagen}
                />
              </Col>
              <Col lg={22} xl={24}>
                <p>
                  Te recomendamos que la fotografía no exceda los 5 MB de peso
                </p>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col offset={1} span={15}>
          {/* <Card className="card-perfil" style={{ width: "100%" }}> */}
          <Collapse
            bordered={false}
            defaultActiveKey={["0"]}
            className="formulario-trabajador site-collapse-custom-collapse "
          >
            {/* ----------------------------------- Sobre la empresa ---------------------------------------- */}

            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  SOBRE LA EMPRESA
                </h4>
              }
              key="1"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={personal}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              {/* <Form.Item>
                <p>SOBRE LA EMPRESA:</p>
              </Form.Item> */}
              <br />
              <Form.Item
                name="Rut"
                label="RUT"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir el RUT empresarial",
                  },
                ]}
                onChange={(e) => {
                  let valor = e.target.value;
                  let rut = Rut.formateaRut(valor);

                  // let validacion = ValidadorRut.validar(rut);
                  // if (validacion === true) {
                  //   props.validacionRutFunc("success");
                  // } else {
                  //   props.validacionRutFunc("error");
                  // }

                  if (rut !== undefined) {
                    form.setFieldsValue({ Rut: rut });
                  } else {
                    form.setFieldsValue({ Rut: valor });
                  }
                }}
                help="Ingresa el RUT con puntos y código verificador"
              >
                <Input name="Rut" className="campos" placeholder="xx.xxx.xxx-x" />
              </Form.Item>

              <Form.Item
                name={"Razon_Social"}
                label="Razón Social"
                rules={[
                  {
                    required: true,
                    message: "La razón social de la empresa es requerida",
                  },
                ]}
              >
                <Input name="Razon_Social" className="campos" />
              </Form.Item>

              <Form.Item
                name={"Calle"}
                label="Calle"
                rules={[
                  {
                    required: true,
                    message: "La dirección es obligatoria",
                    min: 2,
                    max: 50,
                  },
                ]}
              >
                <Input name="Calle" className="campos" />
              </Form.Item>

              <Form.Item
                name={"Numero_Calle"}
                label="Número"
                rules={[
                  {
                    required: true,
                    message: "Debe ingresar el número de dirección",
                  },
                ]}
                hasFeedback
              >
                <Input name="Numero_Calle" className="campos" />
              </Form.Item>

              <Form.Item
                label="Tipo de Establecimiento"
                name={"Tipo_De_Vivienda"}
                rules={[
                  {
                    required: true,
                    message: "¿Qué tipo de lugar es?",
                  },
                ]}
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  key={"Tipo_De_Vivienda"}
                  onChange={(value) => {
                    props.cambiarEstadoNumeroDepartamento(value.target.value);
                  }}
                  value={props.Formulario.Tipo_De_Vivienda}
                >
                  <Radio value={"Departamento"}>Departamento</Radio>
                  <Radio value={"Oficina"}>Oficina</Radio>
                  <Radio value={"Local"}>Local</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name={"Numero_Departamento"}
                label="N° del Establecimiento"
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >
                <Input name="Numero_Departamento" className="campos" />
                {/* <Input name="Numero_Departamento" disabled={props.departamento} className="campos" /> */}
              </Form.Item>

              <Form.Item
                name={"Codigo_Postal"}
                label="Código Postal"
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}
              >
                <Input name="Codigo_Postal" className="campos" />
              </Form.Item>

              <Form.Item
                label="Región"
                name={"Region"}
                rules={[
                  {
                    required: true,
                    message: "¿En que región está?",
                  },
                ]}
              >
                <Select
                  className="campos"
                  name="Region"
                  placeholder="Selecciona región"
                  onSelect={(value) => {
                    form.resetFields(['Ciudad', 'Comuna']);
                    props.buscarProvincias(value);
                  }}
                >

                  {props.regiones.map((value, index) => (
                    <Select.Option
                      name="Region"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}

                </Select>
              </Form.Item>

              <Form.Item
                label="Provincia"
                name={"Ciudad"}
                rules={[
                  {
                    required: true,
                    message: "¿En qué ciudad o provincia está?",
                  },
                ]}

              >
                <Select
                  className="campos"
                  name="Ciudad"
                  placeholder="Selecciona provincia"
                  onSelect={(value) => {
                    form.resetFields(['Comuna']);
                    props.buscarComuna(value);
                  }}
                >
                  {props.ciudadesSeleccionadas.map((value, index) => (
                    <Select.Option
                      name="Ciudad"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Comuna"
                name={"Comuna"}
                rules={[
                  {
                    required: true,
                    message: "¿En qué comuna está?",
                  },
                ]}
              >
                <Select
                  className="campos"
                  name="Comuna"
                  placeholder="Selecciona comuna"
                >
                  {props.comunasSeleccionadas.map((value, index) => (
                    <Select.Option
                      name="Comuna"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name={"Email"}
                label="Email Contacto Empresa"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Se debe ingresar un correo",
                  },
                ]}
              >
                <Input name="Email" className="campos" />
              </Form.Item>

              <Form.Item
                label="Teléfono"
                name="Telefono"
                rules={[
                  {
                    required: true,
                    max: 12,
                    message: "Debe introducir teléfono de la empresa",
                  },
                ]}
                hasFeedback
                validateStatus={statusTelEmpresa}
              >
                <Input
                  maxLength={12}
                  name="Telefono"
                  className="campos"
                  placeholder="+569XXXXXXXX"
                  onChange={handleTelefonoEmpresa}
                />
              </Form.Item>

              <Form.Item
                label="Fecha Inicio en Sistema"
                name="Fecha_Inicio_Sistema"
                rules={[
                  {
                    required: true,
                    message: "Obigatorio",
                  },
                ]}
              >
                <DatePicker
                  name="Fecha_Inicio_Sistema"
                  locale={locale}
                  format={'DD-MM-YYYY'}
                  className="campos"
                />
              </Form.Item>

            </Panel>
            {/* </Card> */}
            {/* <Card
            className="card-perfil"
            style={{ width: "100%", marginTop: "20px" }}
          > */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  ACTIVIDAD DE LA EMPRESA
                </h4>
              }
              key="2"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={personal}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              {/* <Form.Item>
              <p>ACTIVIDAD DE LA EMPRESA:</p>
            </Form.Item> */}
              <br />
              <Form.Item
                label="Giro"
                name={"Giro"}
                rules={[
                  {
                    required: true,
                    message: "Debes introducir el giro",
                  },
                ]}
              >
                <TextArea rows={4} name="Giro" className="campos" />
              </Form.Item>
              <Form.Item
                label="Código de actividad"
                name="Codigo_Actividad_Economica"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir el código de actividad",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  //allowClear
                  className='codigos-select'
                  placeholder="Escriba para buscar código"
                  filterOption={(input, option) => {
                    //console.log(option.props.children);
                    let textoBusqueda = `${option.props.children[0]}${option.props.children[1]}${option.props.children[2]}`;

                    let respuesta = textoBusqueda.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    //console.log(respuesta);
                    return respuesta;
                  }}
                >
                  {props.codigosActividadEconomica.map((value, index) => (
                    <Select.Option
                      key={index}
                      name="Cargo"
                      value={value._id}
                      style={{ fontSize: '10px' }}>
                      {value.Descripcion} || {value.Codigo_SII}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Tipo de empresa"
                name={"Tipo_De_Empresa"}
                rules={[
                  {
                    required: true,
                    message: "¿Qué tipo de empresa es?",
                  },
                ]}
              >
                <Select
                  className="campos"
                  name="Tipo_De_Empresa"
                  placeholder="Selecciona tipo de empresa"
                >
                  {props.TipoEmpresa.map((value, index) => (
                    <Select.Option
                      name="Tipo_De_Empresa"
                      key={index}
                      value={value._id}
                    >
                      {value.Nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Panel>
            {/* </Card> */}

            {/* <Card
            className="card-perfil"
            style={{ width: "100%", marginTop: "20px" }}
          > */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  DATOS DE REPRESENTANTE LEGAL
                </h4>
              }
              key="3"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={personal}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              {/* <Form.Item>
              <p>DATOS DE REPRESENTANTE LEGAL:</p>
            </Form.Item> */}
              <br />
              <Form.Item
                name={"Rut_Representante"}
                label="Rut"
                validateStatus={validRutRepresentante}
                onChange={(e) => {

                  let valor = e.target.value;
                  let rut = '';

                  if (valor) {
                    rut = Rut_Representante.formateaRut(valor);
                    let rutValidacion = Rut_Representante.procesarRut(rut)

                    setValidRutRep(rutValidacion);
                  }

                  if (rut !== undefined) {
                    form.setFieldsValue({ Rut_Representante: rut });
                  } else {
                    form.setFieldsValue({ Rut_Representante: valor });
                  }

                }}
                rules={[
                  {
                    min: 11,
                    max: 12,
                    required: true,
                    message: "Debe introducir el RUT del representante",
                  },
                ]}
                hasFeedback
                help="Ingrese un RUT válido con código verificador"
              >
                <Input
                  name="Rut_Representante"
                  className="campos"
                  placeholder="xx.xxx.xxx-x"
                  maxLength={12}
                />
              </Form.Item>
              <Form.Item
                name={"Nombres_Representante"}
                label="Nombres:"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir las nombres del representante",
                    min: 2,
                    max: 20,
                  },
                ]}
              >
                <Input name="Nombres_Representante" className="campos" />
              </Form.Item>
              <Form.Item
                name={"Apellido_Paterno_Representante"}
                label="Apellido Paterno:"
                rules={[
                  {
                    required: true,
                    message:
                      "Debe introducir apellido paterno del representante",
                    min: 2,
                    max: 30,
                  },
                ]}
              >
                <Input name="Apellido_Paterno_Representante" className="campos" />
              </Form.Item>
              <Form.Item
                name={"Apellido_Materno_Representante"}
                label="Apellido Materno:"
                rules={[
                  {
                    required: true,
                    message:
                      "Debe introducir apellido materno del represenatante",
                    min: 2,
                    max: 30,
                  },
                ]}
              >
                <Input name="Apellido_Materno_Representante" className="campos" />
              </Form.Item>
              <Form.Item
                label="Teléfono"
                name="Telefono_Representante"
                rules={[
                  {
                    max: 12,
                    required: true,
                    message: "Debe introducir teléfono del representante legal",
                  },
                ]}
                hasFeedback
                validateStatus={statusTelLegal}
              >
                <Input
                  // addonBefore={(
                  //   <Form.Item name="Telefono_Representante_prefix" noStyle>
                  //     <Select
                  //       style={{
                  //         width: 70,
                  //       }}
                  //     >
                  //       {props.nacionalidades.map((value, index) => (
                  //         <Option key={index} value={value.Codigo}>
                  //           <img src={value.FotoBandera} alt="chile" />
                  //         </Option>
                  //       ))}

                  //     </Select>
                  //   </Form.Item>
                  // )}
                  name="Telefono_Representante"
                  className="campos"
                  placeholder="+569XXXXXXXX"
                  maxLength={12}
                  onChange={handleTelefonoLegal}
                />
              </Form.Item>
            </Panel>
            {/* </Card> */}
            {/* <Card
            className="card-perfil"
            style={{ width: "100%", marginTop: "20px" }}
          > */}
            <Panel
              header={
                <h4
                  style={{
                    position: "absolute",
                    bottom: -5,
                    color: "#061fcb",
                  }}
                >
                  CONTACTO COMERCIAL DEL SISTEMA PEOPLE MANAGEMENT
                </h4>
              }
              key="4"
              className=" site-collapse-custom-panel"
              extra={[
                <img
                  key="2"
                  src={flecha}
                  style={{
                    position: "absolute",
                    bottom: 3,
                    right: 11,
                    width: 28,
                    color: "#48B9FD",
                  }}
                  alt="icono"
                />,
                <img
                  key="3"
                  src={personal}
                  style={{
                    position: "absolute",
                    left: 5,
                    bottom: 3,
                    width: 28,
                  }}
                  alt="icono"
                />,
              ]}
            >
              {/* <Form.Item>
              <p>CONTACTO COMERCIAL DEL SISTEMA PEOPLE MANAGEMENT:</p>
            </Form.Item> */}
              <br />
              <Form.Item
                name={"Contacto_Comercial_Rut"}
                label="RUT"
                validateStatus={validRutContacto}
                onChange={(e) => {

                  // console.log(e.target.value);
                  // console.log(ValidadorRut.validar('19462400-K'));

                  let valor = e.target.value;
                  let rut = '';

                  if (valor) {
                    rut = Rut_Representante.formateaRut(valor);
                    let rutValidacion = Rut_Representante.procesarRut(rut)

                    setValidRutCon(rutValidacion);
                  }

                  if (rut !== undefined) {
                    form.setFieldsValue({ Contacto_Comercial_Rut: rut });
                  } else {
                    form.setFieldsValue({ Contacto_Comercial_Rut: valor });
                  }

                }}
                rules={[
                  {
                    min: 11,
                    max: 12,
                    required: true,
                    message: "Debe introducir el RUT del contacto",
                  },
                ]}
                hasFeedback
                help="Ingrese un RUT válido con código verificador"
              >
                <Input
                  name="Contacto_Comercial_Rut"
                  className="campos"
                  placeholder="xx.xxx.xxx-x"
                  maxLength={12}
                />
              </Form.Item>
              <Form.Item
                name={"Contacto_Comercial_Nombres"}
                label="Nombres:"
                rules={[
                  {
                    required: true,
                    message: "Debe introducir las nombres del contacto comercial",
                    min: 2,
                    max: 20,
                  },
                ]}
              >
                <Input name="Contacto_Comercial_Nombres" className="campos" />
              </Form.Item>
              <Form.Item
                name={"Contacto_Comercial_Apellido_Paterno"}
                label="Apellido Paterno:"
                rules={[
                  {
                    required: true,
                    message:
                      "Debe introducir apellido paterno del contacto comercial",
                    min: 2,
                    max: 30,
                  },
                ]}
              >
                <Input
                  name="Contacto_Comercial_Apellido_Paterno"
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                name={"Contacto_Comercial_Apellido_Materno"}
                label="Apellido Materno:"
                rules={[
                  {
                    required: true,
                    message:
                      "Debe introducir apellido materno del contacto comercial",
                    min: 2,
                    max: 30,
                  },
                ]}
              >
                <Input
                  name="Contacto_Comercial_Apellido_Materno"
                  className="campos"
                />
              </Form.Item>
              <Form.Item
                label="Teléfono"
                name="Contacto_Comercial_Telefono"
                rules={[
                  {
                    max: 12,
                    required: true,
                    message: "Debe introducir teléfono del contacto comercial",
                  },
                ]}
                hasFeedback
                validateStatus={statusTelComercial}
              >
                <Input
                  // addonBefore={(
                  //   <Form.Item name="Contacto_Comercial_Telefono_prefix" noStyle>
                  //     <Select
                  //       style={{
                  //         width: 70,
                  //       }}
                  //     >
                  //       {props.nacionalidades.map((value, index) => (
                  //         <Option key={index} value={value.Codigo}>
                  //           <img src={value.FotoBandera} alt="chile" />
                  //         </Option>
                  //       ))}

                  //     </Select>
                  //   </Form.Item>
                  // )}
                  name="Contacto_Comercial_Telefono"
                  className="campos"
                  placeholder="+569XXXXXXXX"
                  maxLength={12}
                  onChange={handleTelefonoComercial}
                />
              </Form.Item>
              <Form.Item
                name={"Contacto_Comercial_Email"}
                label="Email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Se debe ingresar un correo",
                  },
                ]}
              >
                <Input name="Contacto_Comercial_Email" className="campos" />
              </Form.Item>
              <Form.Item
                label="Vigencia"
                style={{ textAlign: "left" }}
                rules={[
                  {
                    required: true,
                    message: "Obligatorio",
                  },
                ]}>
                <Switch
                  defaultChecked
                  onChange={props.cambiarEstadoVigencia}
                  className="switch"
                />
              </Form.Item>
            </Panel>
            {/* </Card> */}
          </Collapse>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>

      </Row>
    </Form>
  );
};

export default FormularioEmpresas;
