import {
  BUSCAR_TRABAJADORES_DESCANSO,
  ERROR_DESCANSO,
  LOADING_DESCANSO,
  INSERTAR_FECHAS_FORM,
  INSERTAR_TRABAJADOR_FORM,
  INSERTAR_DATOS_FORMULARIO,
  ENVIAR_CORREO,
  LOADING_DESCANSO_FALSE,
  BUSCAR_CONTRATOS_TRABAJADOR,
} from "../types/descansoTypes";

const INITIAL_VALUES = {
  trabajadores: [],
  err: null,
  loading: false,
  form: {
    Fechas: null,
    Trabajador: null,
    Correo: null,
  },
  contratos: [],
};

export default (state = INITIAL_VALUES, { type, payload }) => {
  switch (type) {
    case LOADING_DESCANSO:
      return {
        ...state,
        loading: true,
      };
    case LOADING_DESCANSO_FALSE:
      return {
        ...state,
        loading: false,
      };
    case INSERTAR_FECHAS_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          Fechas: payload,
        },
      };
    case INSERTAR_TRABAJADOR_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          Trabajador: payload,
        },
      };
    case INSERTAR_DATOS_FORMULARIO:
      return {
        ...state,
        loading: false,
        err: null,
      };
    case ERROR_DESCANSO:
      return {
        ...state,
        err: payload,
        loading: false,
      };
    case BUSCAR_TRABAJADORES_DESCANSO:
      return {
        ...state,
        trabajadores: payload,
        loading: false,
        err: null,
      };
    case ENVIAR_CORREO:
      return {
        ...state,
        form: {
          ...state.form,
          Correo: payload,
        },
      };
    case BUSCAR_CONTRATOS_TRABAJADOR:
      return {
        ...state,
        contratos: payload,
        loading: false,
        err: null,
      };
    default:
      return state;
  }
};
