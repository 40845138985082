import API from "../../Redux/Api/api";

// GET DE PARÁMETROS SIMPLES //

export const obtenerCargos = async (idEmpresa) => {

  try {
    const url = `${API}/CargosTrabajadores/Vigentes?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        let cargosTrabajadores = data.data;
        return cargosTrabajadores;

      default:

        const error = data.err.message.toString();
        console.error(error);

        return [];
    }
  } catch (error) {
    console.error(error);
  }

  return [];
}

export const obtenerRegimenes = async (idEmpresa) => {

  try {
    const url = `${API}/RegimenDeTrabajo/Vigentes?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        let regimenes = data.regimenes;

        return regimenes;

      default:
        console.error(response);

        return [];
    }
  } catch (error) {
    console.error(error);
  }

  return [];
}

export const obtenerEpps = async (idEmpresa) => {

  try {
    const url = `${API}/Epp/VigenciaTrue?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        let epps = data.data;


        return epps;

      default:

        console.error(response);

        return [];
    }
  } catch (error) {
    console.error(error);
  }

  return [];
}

export const obtenerDocumentos = async (idEmpresa) => {

  try {
    const url = `${API}/TiposDeDocumentos/Vigentes?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        let documentos = data.tiposDeDocumentos;

        return documentos;

      default:

        console.error(response);

        return [];
    }
  } catch (error) {
    console.error(error);
  }

  return [];
}

export const obtenerTitulos = async (idEmpresa) => {

  try {
    const url = `${API}/Titulos/Vigentes?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        let titulos = data.data;

        return titulos;

      default:

        console.error(response);
        return [];
    }
  } catch (error) {
    console.error(error);
  }

  return [];
}

export const obtenerEspecialidades = async (idEmpresa) => {

  try {
    const url = `${API}/Especialidades/Vigentes?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        let especialidades = data.data;

        return especialidades;

      default:

        console.error(response);
        return [];
    }
  } catch (error) {
    console.error(error);
  }

  return [];
}

export const obtenerRegiones = async () => {

  try {
    const url = `${API}/Regiones`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        let regiones = data.data;

        return regiones;

      default:
        console.error(response);
        return [];
    }
  } catch (error) {
    console.error(error);
  }

  return [];
}

export const obtenerProvincias = async () => {

  try {
    const url = `${API}/Provincia`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        let provincias = data.data;

        return provincias;

      default:
        console.error(response);
        return [];
    }
  } catch (error) {
    console.error(error);
  }

  return [];
}

export const obtenerComunas = async () => {

  try {
    const url = `${API}/Comunas`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        let comunas = data.data;

        return comunas;

      default:
        console.error(response);
        return [];
    }
  } catch (error) {
    console.error(error);
  }

  return [];
}

export const obtenerCC = async (idEmpresa) => {
  try {
    const url = `${API}/CentroDeCostos?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:

        const centrosCostos = data.centrosDeCostos;
        return centrosCostos;

      default:
        const error = data.err.message.toString();
        console.error(error);
        return [];

    }
  } catch (error) {
    console.error(error);
  }
}

// OBTENER TRABAJADORES, CON FILTRO DE CARGOS //

export const obtenerTrabajadores = async (idEmpresa, objQuery) => {
  try {
    //const url = `${API}/Trabajadores/Contratos?empresa=${idEmpresa}`;
    const url = `${API}/Trabajadores/ContratosNew?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objQuery),
    });

    const status = response.status;

    switch (status) {
      case 200:
      case 201:

        let data = await response.json();
        return data.Trabajadores;

      default:
        console.error(response);
        return [];

    }
  } catch (error) {
    console.error(error);
  }
}

// FUNCIÓN MASIVA DE UN SOLO USO //

export const masivoContratos = async (trabajadores, idEmpresa) => {
  try {
    const url = `${API}/Trabajadores/Contratos/CrearMasivo?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(trabajadores),
    });

    const status = response.status;

    switch (status) {
      case 200:
      case 201:

        let data = await response.json();

        return data.Trabajadores;

      default:
        console.error(response);
        return [];

    }
  } catch (error) {
    console.error(error);
  }
}

// FUNCIONES DOCUMENTOS CONTRATO DE TRABAJADOR //

export const obtenerContratosTrabajador = async (idTrabajador, idEmpresa) => {

  try {
    const url = `${API}/Trabajadores/Contratos?idTrabajador=${idTrabajador}&idEmpresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();
        return data.contratoDB;

      default:
        console.error(response);
        return [];
    }
  } catch (error) {
    console.error(error);
  }

  return [];
}

export const obtenerContratoActualTrabajador = async (idTrabajador, idEmpresa) => {

  try {
    const url = `${API}/Trabajadores/ContratoActual?idTrabajador=${idTrabajador}&idEmpresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        return data.contratoDB;

      default:
        console.error(response);
        return [];
    }
  } catch (error) {
    console.error(error);
  }

  return [];
}

export const crearContratoTrabajador = async (objContrato) => {

  try {
    const url = `${API}/Trabajadores/Contratos/Crear`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objContrato),
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        return data.contratoDB;

      default:
        console.error(response);
        return [];
    }
  } catch (error) {
    console.error(error);
  }

  return [];
}

export const actualizarContrato = async (objActualizarContrato, idContrato) => {

  try {
    const url = `${API}/Trabajadores/Contratos/Actualizar?idContrato=${idContrato}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objActualizarContrato),
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        return data;

      default:
        console.error(response);
        return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const actualizarContratoSoloContrato = async (objActualizarContrato, idContrato) => {
  console.log("objActualizarContratoAPI-Put")
  console.log(objActualizarContrato)
  try {
    const url = `${API}/Trabajadores/Contratos/ActualizarTrabajadorContrato?idContrato=${idContrato}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objActualizarContrato),
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        return data;

      default:
        console.error(response);
        return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}

// FUNCIÓN PARA ACTUALIZAR TRABAJADOR EXCLUSIVAMENTE DESDE MÓDULO DE CONTRATOS //

export const actualizarTrabajador = async (objTrabajador, idTrabajador) => {

  try {
    const url = `${API}/Trabajadores/Contratos/ActualizarTrabajador/${idTrabajador}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objTrabajador),
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        const { trabajador, categoriaTrabajador } = data;

        return { trabajador, categoriaTrabajador };

      default:
        console.error(response);
        return [];
    }
  } catch (error) {
    console.error(error);
  }

  return {};
}

export const revisarTienePlanificacion = async (fechaFiniquitoUnix, idTrabajador) => {
  try {
    const url = `${API}/Trabajadores/Contratos/PlanificacionFiniquito?idTrabajador=${idTrabajador}`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ fechaFiniquitoUnix }),
    });

    const status = response.status;

    switch (status) {
      case 200:
      case 201:

        let data = await response.json();
        return data.tienePlanificacion;

      default:
        console.error(response);
        return [];

    }
  } catch (error) {
    console.error(error);
  }
}

export const finiquitarTrabajadorPlanificacionesAcortar = async (ID_TRABAJADOR, FECHA_FINIQUITO) => {

  try {
    const url = `${API}/FiniquitarContrato/Planificaciones?ID_TRABAJADOR=${ID_TRABAJADOR}&FECHA_FINIQUITO=${FECHA_FINIQUITO}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        return {
          status:200,
          data,
        };

      default:
        console.error(response);
        return {
          status:status,
          data:{},
        };
    }
  } catch (error) {
    return {
      status:500,
      data:{},
    };
  }
}

export const validarEliminacionContrato= async (ID_TRABAJADOR, ID_CONTRATO,ID_EMPRESA) => {

  try {
    
    const url = `${API}/Contrato/Validar/Eliminacion/${ID_TRABAJADOR}/${ID_CONTRATO}?empresa=${ID_EMPRESA}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        return {
          status:200,
          data,
        };

      default:
        data = await response.json();
        return {
          status:status,
          data:data,
        };
    }
  } catch (error) {
    return {
      status:500,
      data:{},
    };
  }
}

export const eliminacionContratoCompleto= async (ID_TRABAJADOR, ID_CONTRATO,ID_EMPRESA,BODY) => {

  try {
    
    const url = `${API}/Contrato/Eliminacion/${ID_TRABAJADOR}/${ID_CONTRATO}?empresa=${ID_EMPRESA}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(BODY),
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        return {
          status:200,
          data,
        };

      default:
        data = await response.json();
        return {
          status:status,
          data:data,
        };
    }
  } catch (error) {
    return {
      status:500,
      data:{},
    };
  }
}


export const verEstadoTrabajador = async (idTrabajador) => {

  try {
    const url = `${API}/Trabajadores/Contratos/EstadoTrabajador/${idTrabajador}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();

        const { trabajador, categoriaTrabajador } = data;

        return { trabajador, categoriaTrabajador };

      default:
        console.error(response);
        return { trabajador: undefined, categoriaTrabajador:undefined };
    }
  } catch (error) {
    console.log(error);
    return { trabajador: undefined, categoriaTrabajador:undefined };
  }
}


export const validarModificacionFechasContrato = async (body, idEmpresa) => {

  try {
    const url = `${API}/BitacoraTrabajador/Validacion/Modificacion/Contrato?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();
        return {status: 200, mensaje: data};
      case 409:
        return {status: 409, mensaje: "no se puede editar"};
      default:
        return {status: 500, mensaje: "Error no controlado"};
    }
  } catch (error) {
    return {status: 500, mensaje: "Error no controlado"};
  }
}