import React from "react";
import "./Layout.css";

import PropTypes from "prop-types";
import { Layout, Menu, Button, Typography, Row, Col } from "antd";
import { Link } from "react-router-dom";

import avatar from "../../img/icons/avatar.svg";

import Dashboard from "../../img/icons-sidebar/blancas/Dashboard.png";
import Asignaciones from "../../img/icons-sidebar/blancas/Asignaciones.png";
import Procesos from "../../img/icons-sidebar/blancas/Procesos.png";
import Asistencia from "../../img/icons-sidebar/blancas/Asistencia.png";
import Validacion from "../../img/icons-sidebar/blancas/ValidacionQR.png";
import Mantenedores from "../../img/icons-sidebar/blancas/Mantenedores.png";
import Reporteria from "../../img/icons-sidebar/blancas/Reporteria.png";
import Ayuda from "../../img/icons-sidebar/blancas/Ayuda.svg";

import Trabajadores from "../../img/icons-sidebar/blancas/Trabajadores.png";
import LogoutIcon from "../../img/icons-sidebar/blancas/salir.png";

import DashboardAzul from "../../img/icons-sidebar/azules/Dashboard.png";
import ProcesosAzul from "../../img/icons-sidebar/azules/Procesos.png";
import Excel from "../../img/icons-sidebar/blancas/IconExcel.svg";
import ExcelAzul from "../../img/icons-sidebar/azules/IconExcel.svg";
// import AsistenciaAzul from "../../img/icons-sidebar/azules/Asistencia.png";
// import ValidacionAzul from "../../img/icons-sidebar/azules/ValidacionQR.png";
import TrabajadoresAzul from "../../img/icons-sidebar/azules/Trabajadores.png";

import iconFlechaIzq from "../../img/icons/BotonSideLeft.png";
import iconFlechaDer from "../../img/icons/BotonSideRight.png";



import {
  //SettingOutlined, 
  //ImportOutlined, 
  MenuOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

import { Redirect } from "react-router";
import isMobile from "ismobilejs";
// import MantenedorUsuario from "../../views/mantenedorUsuarios/MantenedorUsuario";
import API from "../../Redux/Api/api";

import accesosGenerica from '../../funcionesGenericas/accesosUsuario';

const { Content, Sider, Header } = Layout;
const { SubMenu } = Menu;

class SideMenu extends React.Component {

  static propTypes = {
    avatar: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      avatar: avatar,
      ocultar: true,
      nombre: "",
      cargo: "",
      isInvisible: false,
      isRelative: false,
      subMenuKeylist: [],
      flagSelected: null,
      flagSelectedAdmin: "Empresas",

      isMuestraMenu: false,
      menuKeys: [],
    };
  }

  componentWillMount = async () => {

    const ruta = await window.location.pathname;

    //función genérica para redireccionar según permisos por rutas
    const accesoRuta = await accesosGenerica.obtenerAccesosRuta(ruta);

    this.setState({
      accesoRuta,
    });

    if (window.location.pathname === "/AsistenciaQR") {
      if (isMobile().any) {
        this.setState({ isInvisible: true });
      }
    } else {
      this.setState({ isInvisible: false });
    }
  };

  componentDidMount = async () => {
    let usuario = await this.obtenerInformacionUsuario();

    if (usuario) {
      let idUsuario = usuario._id;
      let empresa = usuario.EmpresaSeleccionada.Empresa._id;

      await this.obtenerAccesosUsuario(empresa, idUsuario);
      await this.obtenerUsuarioVigente(idUsuario);
      this.forceUpdate();
    }

    let isRutaBase = window.location.pathname === '/';
    let isRutaDocumentacion = window.location.pathname === '/Documentacion';
    let isRecuperarPassword = window.location.pathname === '/RecuperarPassword';
    let MuestraMenu = false;

    if (this.state.isMuestraMenu === false) {
      if (isRutaBase || isRecuperarPassword || isRutaDocumentacion) {
        MuestraMenu = true;
      }
    } else {
      MuestraMenu = false;
    }

    this.setState({
      isMuestraMenu: MuestraMenu,
    });

  }

  obtenerInformacionUsuario = () => {
    try {
      let usuario = localStorage.getItem("usuario-people-manage");

      if (
        usuario !== null &&
        usuario !== "" &&
        usuario !== undefined &&
        usuario !== "undefined"
      ) {
        usuario = JSON.parse(usuario);

        let imagen = usuario.Imagen;

        let nombresUsuario = usuario.Nombres;

        let primerNombre = nombresUsuario.split(' ')[0];
        let apellido = usuario.Apellido_Paterno[0];

        let nombre = `${primerNombre} ${apellido}`
        nombre = nombre.toUpperCase();

        let cargo = `${usuario.EmpresaSeleccionada.Rol.Nombre}`;
        this.setState({
          avatar: imagen,
          nombre: nombre,
          cargo: cargo,
        });

        return usuario;
      }
    } catch (error) {
      console.log("error al obtener informacion del usuario");
    }
  };

  obtenerUsuarioVigente = async(idUsuario) =>{
    try {
      const url = `${API}/Usuario/${idUsuario}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          mode: "no-cors",
        },
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:

          data = await response.json();
          
          if(!data.usuario || data.usuario.Vigencia === false){
            localStorage.removeItem("usuario-people-manage");
            localStorage.removeItem("accesos");
            window.location = "/";
          }

          break;

        case 204:
          localStorage.removeItem("usuario-people-manage");
          localStorage.removeItem("accesos");
          window.location = "/";
          break;

        default:
          localStorage.removeItem("usuario-people-manage");
          localStorage.removeItem("accesos");
          window.location = "/";

      }
    } catch (error) {
      localStorage.removeItem("usuario-people-manage");
      localStorage.removeItem("accesos");
      window.location = "/";
      console.log(error);
    }
    
  }
  obtenerAccesosUsuario = async (idEmpresa, idUsuario) => {

    const url = `${API}/AccesosSistema/PorEmpresa?usuario=${idUsuario}&empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:

        data = await response.json();
        const accesosEnEmpresa = JSON.stringify(data.data[0]);
        localStorage.setItem('accesos', accesosEnEmpresa);
        break;

      case 204:
        localStorage.removeItem('accesos');

        break;

      default:
        console.log('ERROR EN LOS ACCESOS');

    }

  }

  componentWillReceiveProps = () => {
    this.obtenerInformacionUsuario();
  };

  ocultar = (e) => {
    e.preventDefault();
    this.props.ocultar === true
      ? this.props.mostrarMenu()
      : this.props.ocultarMenu();
  };

  perfilUsuario = () => {
    if (this.state.ocultar === false) {
      return (
        <div className="logo">
          <Row
            style={{ height: '80px' }}
          >
            <Col span={8}
              style={{
                zIndex: 999
              }}>
              <img
                style={{
                  width: 44,
                  height: 44,
                  marginBottom: "60px",
                  borderRadius: "30px",
                  zIndex: 999,
                }}
                src={this.state.avatar}
                alt="avatar de perfil"
              />
            </Col>
            <Col
              span={16}
              style={{
                color: "white",
              }}
            >
              <h2
                style={{
                  width: 'fit-content',
                  color: "white",
                  fontSize: "15px",
                  marginTop: '3px',
                  marginBottom: '0px',
                  zIndex: 0,
                  fontFamily: 'MavenPro-SemiBold'
                }}
              >
                {this.state.nombre}
              </h2>
              <h5
                style={{
                  color: "white",
                  fontSize: "12px",
                  zIndex: 0,
                  fontFamily: 'MavenPro-Regular'
                }}
              >
                {this.state.cargo}
              </h5>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div className="logo" style={{ height: '80px' }}>
          <img
            style={{
              width: 44,
              height: 44,
              marginBottom: "60px",
              borderRadius: "30px",
              zIndex: 999,
            }}
            src={this.state.avatar}
            alt="avatar de perfil"
          />
        </div>
      );
    }
  };

  mostrarMenu = () => {
    this.setState({
      ocultar: false,
      isRelative: this.state.isRelativeNext,
    });
  };

  ocultarMenu = () => {
    this.setState({
      ocultar: true,
      isRelativeNext: this.state.isRelative,
    });
  };

  handleOpenSubmenu = (keylist) => {
    //si hay algún submenú abierto
    if (keylist[0]) {
      this.setState({
        isRelative: true,
      });
    } else {
      this.setState({
        isRelative: false,
      });
    }
  };

  cambiarOpcionesMenu = (nombreMenu) => {

    this.setState({
      flagSelected: nombreMenu,
      flagSelectedAdmin: nombreMenu,
    });
  };

  opcionesDelMenu = (dispositivo) => {

    try {

      let usuario = localStorage.getItem("usuario-people-manage");
      let accesos = localStorage.getItem("accesos");

      if (
        usuario !== null &&
        usuario !== "" &&
        usuario !== undefined &&
        usuario !== "undefined"
        //&& accesos
      ) {
        
        usuario = JSON.parse(usuario);
        accesos = accesos ? JSON.parse(accesos) : null;
        

        //DASHBOARD
        let DashboardAcceso = accesos ? !accesos.Dashboard.Ver : false;

        //TRABAJADORES
        let MantenedorTrabajadores = accesos ? (accesos.MantenedorTrabajadores != undefined ? !accesos.MantenedorTrabajadores.Ver : !accesos.MantenedorFichaTrabajador.Ver) : false;

        //CONTRATOS
        let Contratos = accesos ? (accesos.Contratos ? !accesos.Contratos.Ver : true) : false;

        //PLANIFICACION
        let PlanificacionEquiposDeTrabajo = accesos ? (accesos.PlanificacionEquiposDeTrabajo != undefined ? !accesos.PlanificacionEquiposDeTrabajo.Ver : !accesos.AsignacionesPlantilla.Ver) : false;
        let PlanificacionActividades = accesos ? (accesos.PlanificacionActividades != undefined ? !accesos.PlanificacionActividades.Ver : !accesos.AsignacionesCentroDeCosto.Ver) : false;
        // let PlanificacionDetalleActividad = accesos ? (accesos.PlanificacionDetalleActividad != undefined ? !accesos.PlanificacionDetalleActividad.Ver : !accesos.AsignacionesDetalle.Ver) : false;
        // let PlanificacionDetalleTrabajador = accesos ? (accesos.PlanificacionDetalleTrabajador != undefined ? !accesos.PlanificacionDetalleTrabajador.Ver : !accesos.DetalleTrabajador.Ver) : false;

        //ASISTENCIA
        let AsistenciaCheckIn = accesos ? (accesos.AsistenciaCheckIn != undefined ? !accesos.AsistenciaCheckIn.Ver : !accesos.ProcesosCheck.Ver) : false;
        let AsistenciaManual = accesos ? (accesos.AsistenciaManual != undefined ? !accesos.AsistenciaManual.Ver : !accesos.Asistencia.Ver) : false;
        let AsistenciaQR = accesos ? (accesos.AsistenciaQR != undefined ? !accesos.AsistenciaQR : !accesos.ValidacionQR.Ver) : false;
        let AsistenciaCheckOut = accesos ? (accesos.AsistenciaCheckOut != undefined ? !accesos.AsistenciaCheckOut.Ver : !accesos.ProcesosCheck.Ver) : false;

        //PROCESOS
        let ProcesosDescanso = accesos ? !accesos.ProcesosDescanso.Ver : false;
        let ProcesosEnTransito = accesos ? !accesos.ProcesosEnTransito.Ver : false;
        let ProcesosVacaciones = accesos ? !accesos.ProcesosVacaciones.Ver : false;
        let ProcesosLicencia = accesos ? !accesos.ProcesosLicencia.Ver : false;
        let ProcesosMutual = accesos ? !accesos.ProcesosMutual.Ver : false;
        let ProcesosPermisos = accesos ? !accesos.ProcesosPermisos.Ver : false;
        let ProcesosAusente = accesos ? !accesos.ProcesosAusente.Ver : false;

        //REPORTERÍA
        let ReporteriaDesviaciones = accesos ? (accesos.ReporteriaDesviaciones ? !accesos.ReporteriaDesviaciones.Ver : true) : false;
        let ReporteriaDelDia = accesos ? (accesos.ReporteriaDelDia ? !accesos.ReporteriaDelDia.Ver : true) : false;
        let ReporteriaNecesidades = accesos ? (accesos.ReporteriaNecesidades ? !accesos.ReporteriaNecesidades.Ver : true) : false;
        let ReporteriaAsistenciaCC = accesos ? (accesos.ReporteriaAsistenciaCC ? !accesos.ReporteriaAsistenciaCC.Ver : true) : false;
        let ReporteriaAsistenciaPersonas = accesos ? !accesos.ReporteriaAsistenciaPersonas.Ver : false;
        // let ReporteriaAuditoria = accesos ? (accesos.ReporteriaAuditoria != undefined ? !accesos.ReporteriaAuditoria.Ver : !accesos.Auditoria.Ver) : false;
        let ReporteriaCierreDeMes = accesos ? (accesos.ReporteriaCierreDeMes != undefined ? !accesos.ReporteriaCierreDeMes.Ver : !accesos.CierreDeMes.Ver) : false;
        let ReporteriaCosteo = accesos ? (accesos.ReporteriaCosteo != undefined ? !accesos.ReporteriaCosteo.Ver : !accesos.Costeo.Ver) : false;

        //REPORTERIA EXCEL
        let ReporteriaExcelAcceso = accesos ? (accesos.ReporteriaExcel ? !accesos.ReporteriaExcel.Ver : true) : false;
        let ReporteriaUtilizacionRelevos = accesos ? (accesos.ReporteriaUtilizacionRelevos ? !accesos.ReporteriaUtilizacionRelevos.Ver : true) : false;

        //MANTENEDORES
        let MantenedorUsuarios = accesos ? !accesos.MantenedorUsuarios.Ver : false;
        let MantenedorPerfilesUsuario = accesos ? (accesos.MantenedorPerfilesUsuario != undefined ? !accesos.MantenedorPerfilesUsuario.Ver : !accesos.MantenedorPerfilesPermiso.Ver) : false;
        let MantenedorAccesosSistema = accesos ? (accesos.MantenedorAccesosSistema != undefined ? !accesos.MantenedorAccesosSistema : !accesos.MantenedorAccesosPermiso.Ver) : false;
        let MantenedorSucursales = accesos ? !accesos.MantenedorSucursales.Ver : false;
        let MantenedorCentroDeCostos = accesos ? !accesos.MantenedorCentroDeCostos.Ver : false;
        let MantenedorRegimenDeTrabajo = accesos ? !accesos.MantenedorRegimenDeTrabajo.Ver : false;
        let MantenedorFeriados = accesos ? !accesos.MantenedorFeriados.Ver : false;
        let MantenedorTitulos = accesos ? !accesos.MantenedorTitulos.Ver : false;
        let MantenedorEspecialidades = accesos ? !accesos.MantenedorEspecialidades.Ver : false;
        let MantenedorCargos = accesos ? !accesos.MantenedorCargos.Ver : false;
        let MantenedorEpp = accesos ? !accesos.MantenedorEpp.Ver : false;
        let MantenedorDocumentos = accesos ? !accesos.MantenedorDocumentos.Ver : false;

        let rol = usuario.EmpresaSeleccionada.Rol._id;
        //let rol = "5f21ae92d585cd003f997a6a";
        //   "_id": "5f21ae92d585cd003f997a6a",
        //   "Nombre": "Admin General",

        //   "_id": "5f21aebad585cd003f997a6b",
        //   "Nombre": "Administrador",

        //   "_id": "5f21aeecd585cd003f997a6c",
        //   "Nombre": "Operador",

        //   "_id": "5f21e98bd585cd003f997a76",
        //   "Nombre": "Consulta",

        switch (rol) {

          //administrador General
          case "5f21ae92d585cd003f997a6a":
            return (
              <Menu
                theme="dark"
                defaultSelectedKeys={["3"]}
                mode="inline"
                disabled={false}
                openKeys={this.state.menuKeys}
              >
                <p className="m-fix"></p>
                {/* EMPRESAS  */}
                <Menu.Item
                  key="3"
                  onClick={() => {
                    this.cambiarOpcionesMenu("Empresas");
                  }}
                  icon={
                    this.state.flagSelectedAdmin === "Empresas" ? (
                      <img src={ProcesosAzul} className="imgSidebar anticon" alt="imagen" />
                    ) : (
                      <img src={Procesos} className="imgSidebar anticon" alt="imagen" />
                    )
                  }
                  className="fix"
                >
                  <Link
                    to="/Empresas"
                    className="fs-icon-side titulos-sidebar"
                  >
                    Empresas
                    </Link>
                </Menu.Item>

                <p className="m-fix"></p>

                {/* USUARIOS SUPER*/}
                <Menu.Item
                  key="2"
                  onClick={() => {
                    this.cambiarOpcionesMenu("Trabajadores");
                  }}
                  icon={
                    this.state.flagSelectedAdmin === "Trabajadores" ? (
                      <img src={TrabajadoresAzul} className="imgSidebar anticon" alt="imagen" />
                    ) : (
                      <img src={Trabajadores} className="imgSidebar anticon" alt="imagen" />
                    )
                  }
                  className="fix"
                >
                  <Link
                    to="/SuperUsuarios"
                    className="fs-icon-side titulos-sidebar"
                  >
                    Usuarios
                    </Link>
                </Menu.Item>
                <p className="m-fix"></p>
                <Menu.Item
                  key="10"
                  onClick={() => {
                    localStorage.removeItem("usuario-people-manage");
                    localStorage.removeItem("accesos");
                    window.location = "/";
                  }}
                  icon={<img src={LogoutIcon} className="imgSidebar anticon" alt="imagen" />}
                  className="fix"
                >
                  <Link
                    to="/"
                    className="fs-icon-side titulos-sidebar"
                  >
                    Cerrar Sesión
                  </Link>
                </Menu.Item>


              </Menu>
            );
          //administrador
          case "5f21aebad585cd003f997a6b":
          //operador
          // eslint-disable-next-line
          case "5f21aeecd585cd003f997a6c":
          //consulta
          // eslint-disable-next-line
          case "5f21e98bd585cd003f997a76":

            if (dispositivo === 'DESKTOP') {

              return (
                <Menu
                  theme="dark"
                  // defaultSelectedKeys={["1"]}
                  mode="inline"
                  className="menu-sidebar"
                  openKeys={this.state.menuKeys}
                >

                  {/* DASHBOARD */}
                  <Menu.Item
                    disabled={DashboardAcceso}
                    key="1"
                    onClick={() => {
                      this.cambiarOpcionesMenu("Dashboard");
                    }}
                    icon={
                      this.state.flagSelected === "Dashboard" ? (
                        <img src={DashboardAzul} className="imgSidebar anticon"
                          alt="imagen" />
                      ) : (
                        <img src={Dashboard} className="imgSidebar anticon"
                          alt="imagen" />
                      )
                    }
                    className="fix"
                  >
                    <Link
                      to="/CentroControl"
                      className="fs-icon-side titulos-sidebar"
                    >
                      Dashboard
                    </Link>
                  </Menu.Item>

                  <p className="m-fix"></p>

                  {/* TRABAJADORES */}
                  <Menu.Item
                    disabled={MantenedorTrabajadores}
                    key="2"
                    onClick={() => {
                      this.cambiarOpcionesMenu("Trabajadores");
                    }}
                    icon={
                      this.state.flagSelected === "Trabajadores" ? (
                        <img src={TrabajadoresAzul} className="imgSidebar anticon" alt="imagen" />
                      ) : (
                        <img src={Trabajadores} className="imgSidebar anticon" alt="imagen" />
                      )
                    }
                    className="fix"
                  >
                    <Link
                      to="/Trabajador"
                      className="fs-icon-side titulos-sidebar"
                    >
                      Trabajadores
                    </Link>
                  </Menu.Item>

                  <p className="m-fix"></p>

                  {/* CONTRATOS */}
                  <Menu.Item
                    disabled={Contratos}
                    key="-0"
                    onClick={() => {
                      this.cambiarOpcionesMenu("Contratos");
                    }}
                    icon={
                      this.state.flagSelected === "Contratos" ? (
                        <FileDoneOutlined style={{ color: 'blue', fontSize: '23px', position: 'relative', top: '5px', left: '1px' }} />
                      ) : (
                        <FileDoneOutlined style={{ color: 'white', fontSize: '23px', position: 'relative', top: '5px', left: '1px' }} />
                      )
                    }
                    className="fix"
                  >
                    <Link
                      to="/Contratos"
                      className="fs-icon-side titulos-sidebar"
                    >
                      Contratos
                    </Link>
                  </Menu.Item>

                  <p className="m-fix"></p>

                  {/* PLANIFICAR */}
                  <SubMenu
                    key="3"
                    icon={
                      <img src={Asignaciones}
                        className="imgSidebar anticon"
                        alt="imagen" />
                    }
                    title={
                      <a className="fs-icon-side titulos-sidebar">
                        Planificación
                      </a>
                    }
                    onTitleClick={(e) => {

                      if (this.state.ocultar) {
                        this.setState({
                          menuKeys: [e.key]
                        })
                        this.mostrarMenu();

                      } else if (this.state.menuKeys[0] === e.key) {
                        this.setState({
                          menuKeys: []
                        })

                      } else {
                        this.setState({
                          menuKeys: [e.key]
                        })
                      }

                    }}
                  >
                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={PlanificacionEquiposDeTrabajo}
                      key="sub_1"

                    >
                      <Link to="/Faena">Equipos de Trabajo</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={PlanificacionActividades}
                      key="sub_2"
                    >
                      <Link to="/AsignacionActividades">Actividades</Link>
                    </Menu.Item>

                    {/* <Menu.Item
                      key="sub_3"
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      //cuando se tenga listo lo de seleccionar faena para ver su detalle,
                      //reemplazar éste disabled true por el comentado
                      // disabled={AsignacionesDetalle}
                      disabled={true}
                    >
                      <Link to="/AsignacionFaena">Asignar Personas</Link>
                    </Menu.Item> */}

                  </SubMenu>

                  <p className="m-fix"></p>

                  {/* CHECK-IN */}
                  {/* <Menu.Item
                    disabled={ProcesosCheck}
                    key="4"
                    onClick={() => {
                      this.cambiarOpcionesMenu("CheckIn");
                    }}
                    icon={
                      this.state.flagSelected === "CheckIn" ? (
                        <img src={AsistenciaAzul} className="imgSidebar anticon" alt="imagen" />
                      ) : (
                          <img src={Asistencia} className="imgSidebar anticon" alt="imagen" />
                        )
                    }
                    className="fix"
                  >
                    <Link
                      to="/FiltroTrabajadores"
                      className="fs-icon-side titulos-sidebar"
                    >
                      Check-In
                    </Link>
                  </Menu.Item> */}

                  {/* <p className="m-fix"></p> */}

                  {/* ASISTENCIA */}
                  <SubMenu
                    key="5"
                    icon={<img src={Asistencia} className="imgSidebar anticon" alt="imagen" />}
                    title={
                      <a className="fs-icon-side titulos-sidebar">
                        Asistencia
                      </a>
                    }
                    onTitleClick={(e) => {

                      if (this.state.ocultar) {
                        this.setState({
                          menuKeys: [e.key]
                        })
                        this.mostrarMenu();

                      } else if (this.state.menuKeys[0] === e.key) {
                        this.setState({
                          menuKeys: []
                        })

                      } else {
                        this.setState({
                          menuKeys: [e.key]
                        })
                      }

                    }}
                  >

                    <Menu.Item
                      disabled={AsistenciaCheckIn}
                      key="50"
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                    >
                      <Link
                        to="/FiltroTrabajadores"
                      >
                        Check-In
                      </Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={AsistenciaManual}
                      key="sub_4"

                    >
                      <Link to="/AsistenciaTimeLine">Asistencia Manual</Link>
                    </Menu.Item>

                    {/* <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={AsistenciaQR}
                      key="sub_5"

                    >
                      <Link to="/AsistenciaQR">Asistencia QR</Link>
                    </Menu.Item> */}

                    <Menu.Item
                      disabled={false}
                      key="51"
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                    >
                      <Link
                        to="/CheckOut"
                      >
                        Check-Out
                      </Link>
                    </Menu.Item>

                  </SubMenu>

                  <p className="m-fix"></p>

                  {/* CHECK-OUT */}
                  {/* <Menu.Item
                    disabled={true}
                    key="6"
                    onClick={() => {
                      this.cambiarOpcionesMenu("CheckOut");
                    }}
                    icon={
                      this.state.flagSelected === "CheckOut" ? (
                        <img src={AsistenciaAzul} className="imgSidebar anticon" alt="imagen" />
                      ) : (
                          <img src={Asistencia} className="imgSidebar anticon" alt="imagen" />
                        )
                    }
                    className="fix"
                  >
                    <Link
                      to="/FiltroTrabajadores"
                      className="fs-icon-side titulos-sidebar"
                    >
                      Check-Out
                    </Link>
                  </Menu.Item> */}

                  {/* <p className="m-fix"></p> */}

                  {/* PROCESOS */}
                  <SubMenu
                    key="7"
                    icon={<img src={Procesos} className="imgSidebar anticon" alt="imagen" />}
                    title={
                      <a className="fs-icon-side titulos-sidebar">
                        Procesos
                      </a>
                    }
                    onTitleClick={(e) => {

                      if (this.state.ocultar) {
                        this.setState({
                          menuKeys: [e.key]
                        })
                        this.mostrarMenu();

                      } else if (this.state.menuKeys[0] === e.key) {
                        this.setState({
                          menuKeys: []
                        })

                      } else {
                        this.setState({
                          menuKeys: [e.key]
                        })
                      }

                    }}
                  >

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ProcesosDescanso}
                      key="sub_6"
                    >
                      <Link to="/Descanso">Descanso</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ProcesosEnTransito}
                      key="sub_7"
                    >
                      <Link to="/EnTransito">En Tránsito</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ProcesosVacaciones}
                      key="sub_8"
                    >
                      <Link to="/Vacaciones">Vacaciones</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ProcesosLicencia}
                      key="sub_9"
                    >
                      <Link to="/Licencias">Licencias</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ProcesosMutual}
                      key="sub_10"
                    >
                      <Link to="/Mutual">Licencia Mutualidad</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ProcesosPermisos}
                      key="sub_11"
                    >
                      <Link to="/Permisos">Permisos</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ProcesosAusente}
                      key="sub_12"
                    >
                      <Link to="/Ausentes">Dia Ausente</Link>
                    </Menu.Item>

                  </SubMenu>

                  <p className="m-fix"></p>

                  {/* REPORTERÍA */}
                  <SubMenu
                    key="8"
                    icon={<img src={Reporteria} className="imgSidebar anticon" alt="imagen" />}
                    title={
                      <a className="fs-icon-side titulos-sidebar">Reportería</a>
                    }
                    onTitleClick={(e) => {

                      if (this.state.ocultar) {
                        this.setState({
                          menuKeys: [e.key]
                        })
                        this.mostrarMenu();

                      } else if (this.state.menuKeys[0] === e.key) {
                        this.setState({
                          menuKeys: []
                        })

                      } else {
                        this.setState({
                          menuKeys: [e.key]
                        })
                      }

                    }}
                  >
                    <Menu.Item
                      disabled={ReporteriaDelDia}
                      key="sub_-3"
                    >
                      <Link to="/ReporteDelDia">Reporte del día</Link>
                    </Menu.Item>

                    <Menu.Item
                      disabled={ReporteriaNecesidades}
                      key="sub_-10"
                    >
                      <Link to="/ReporteNecesidades">Reporte de Necesidades</Link>
                    </Menu.Item>

                    <Menu.Item
                      // disabled={}
                      disabled={ReporteriaUtilizacionRelevos}
                      key="sub_-20"
                    >
                      <Link to="/ReporteUtilizacionRelevo">Reporte Utilización de Relevo</Link>
                    </Menu.Item>


                    <Menu.Item
                      // disabled={}
                      disabled={ReporteriaDesviaciones}
                      key="sub_-1"
                    >
                      <Link to="/Desviaciones">Desviaciones</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ReporteriaAsistenciaPersonas}
                      key="sub_13"
                    >
                      <Link to="/ReporteriaAsistenciaPersonas">Asistencia Personas</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ReporteriaAsistenciaCC}
                      key="sub_-2"
                    >
                      <Link to="/ReporteriaAsistenciaCC">Asistencia por CC</Link>
                    </Menu.Item>

                    {/* <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ReporteriaAuditoria}
                      key="sub_14"
                    >
                      <Link to="/Auditoria">Auditoría</Link>
                    </Menu.Item> */}

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ReporteriaCierreDeMes}
                      key="sub_15"
                    >
                      <Link to="/CierreDeMes">Cierre de Mes</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ReporteriaCosteo}
                      key="sub_16"
                    >
                      <Link to="/DetalleCostos">Costeo Sistema Contable</Link>
                    </Menu.Item>

                  </SubMenu>

                  <p className="m-fix"></p>

                  {/* MANTENEDORES */}
                  <SubMenu
                    key="9"
                    icon={<img src={Mantenedores} className="imgSidebar anticon" alt="imagen" />}
                    title={
                      <a className="fs-icon-side titulos-sidebar">Mantenedores</a>
                    }
                    onTitleClick={(e) => {

                      if (this.state.ocultar) {
                        this.setState({
                          menuKeys: [e.key]
                        })
                        this.mostrarMenu();

                      } else if (this.state.menuKeys[0] === e.key) {
                        this.setState({
                          menuKeys: []
                        })

                      } else {
                        this.setState({
                          menuKeys: [e.key]
                        })
                      }

                    }}
                  >
                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      key="sub_17"
                      disabled={MantenedorUsuarios}
                    >
                      <Link to="/Usuarios">Usuarios</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={MantenedorPerfilesUsuario}
                      key="sub_18"
                    >
                      <Link to="/PerfilesPermiso">Perfiles de Usuario</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={MantenedorAccesosSistema}
                      key="sub_19"
                    >
                      <Link to="/AccesosSistema">Accesos al Sistema</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={MantenedorSucursales}
                      key="sub_20"
                    >
                      <Link to="/Sucursales">Sucursales</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={MantenedorCentroDeCostos}
                      key="sub_21"
                    >
                      <Link to="/CentroDeCosto">Centros de Costo</Link>
                      {/* <Link to="/Trabajador">Ficha Trabajador</Link> */}
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={MantenedorRegimenDeTrabajo}
                      key="sub_22"
                    >
                      <Link to="/Regimen">Régimen de Trabajo</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={MantenedorFeriados}
                      key="sub_23"
                    >
                      <Link to="/Feriados">Feriados</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={MantenedorTitulos}
                      key="sub_24"
                    >
                      <Link to="/Titulos">Títulos</Link>
                      {/* <Link to="/Sucursales">Sucursales</Link> */}
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={MantenedorEspecialidades}
                      key="sub_25"
                    >
                      <Link to="/Especialidades">Especialidades</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={MantenedorCargos}
                      key="sub_26"
                    >
                      <Link to="/Cargos">Cargos</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={MantenedorEpp}
                      key="sub_27"
                    >
                      <Link to="/EPP">Elementos de Protección</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={MantenedorDocumentos}
                      key="sub_28"
                    >
                      <Link to="/Documentos">Documentos</Link>
                    </Menu.Item>

                  </SubMenu>
                  
                  {/* Reporteria Excel */}
                  <p className="m-fix"></p>
                  <Menu.Item
                    disabled={ReporteriaExcelAcceso}
                    key="4"
                    onClick={() => {
                      this.cambiarOpcionesMenu("Reporteria Excel");
                    }}
                    icon={
                      this.state.flagSelected === "Reporteria Excel" ? (
                        <img src={ExcelAzul} className="imgSidebar anticon"
                          alt="imagen" />
                      ) : (
                        <img src={Excel} className="imgSidebar anticon"
                          alt="imagen" />
                      )
                    }
                    className="fix"
                  >
                    <Link
                      to="/Reporteria/Excel"
                      className="fs-icon-side titulos-sidebar"
                    >
                      Reporteria Excel
                    </Link>
                  </Menu.Item>
                  
                  <p className="m-fix"></p>

                  {/* AYUDA */}
                  <SubMenu
                    key="11"
                    icon={<img src={Ayuda} className="imgSidebar anticon" alt="imagen" />}
                    title={
                      <a className="fs-icon-side titulos-sidebar">Ayuda</a>
                    }
                    onTitleClick={(e) => {

                      if (this.state.ocultar) {
                        this.setState({
                          menuKeys: [e.key]
                        })
                        this.mostrarMenu();

                      } else if (this.state.menuKeys[0] === e.key) {
                        this.setState({
                          menuKeys: []
                        })

                      } else {
                        this.setState({
                          menuKeys: [e.key]
                        })
                      }

                    }}
                  >
                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      key="sub_30"
                      // disabled={MantenedorUsuarios}
                    >
                      <Link to="/Videos">Videos</Link>
                    </Menu.Item>

                  </SubMenu>

                  <p className="m-fix"></p>

                  {/* LOGOUT */}
                  <Menu.Item
                    key="10"
                    onClick={() => {
                      localStorage.removeItem("usuario-people-manage");
                      localStorage.removeItem("accesos");
                      window.location = "/";
                    }}
                    icon={<img src={LogoutIcon} className="imgSidebar anticon" alt="imagen" />}
                    className="fix"
                  >
                    <Link
                      to="/"
                      className="fs-icon-side titulos-sidebar"
                    >
                      Cerrar Sesión
                    </Link>
                  </Menu.Item>

                </Menu >
              );

            } else if (dispositivo === 'MOBILE') {

              return (
                <Menu
                  theme="dark"
                  // defaultSelectedKeys={["1"]}
                  mode="inline"
                  className="menu-sidebar"
                  openKeys={this.state.menuKeys}
                >

                  {/* DASHBOARD */}
                  <Menu.Item
                    disabled={DashboardAcceso}
                    key="1"
                    onClick={() => {
                      this.cambiarOpcionesMenu("Dashboard");
                    }}
                    icon={
                      this.state.flagSelected === "Dashboard" ? (
                        <img src={DashboardAzul} className="imgSidebar anticon"
                          alt="imagen" />
                      ) : (
                        <img src={Dashboard} className="imgSidebar anticon"
                          alt="imagen" />
                      )
                    }
                    className="fix"
                  >
                    <Link
                      to="/CentroControl"
                      className="fs-icon-side titulos-sidebar"
                    >
                      Dashboard
                    </Link>
                  </Menu.Item>

                  <p className="m-fix"></p>

                  {/* TRABAJADORES */}
                  <Menu.Item
                    disabled={MantenedorTrabajadores}
                    key="2"
                    onClick={() => {
                      this.cambiarOpcionesMenu("Trabajadores");
                    }}
                    icon={
                      this.state.flagSelected === "Trabajadores" ? (
                        <img src={TrabajadoresAzul} className="imgSidebar anticon" alt="imagen" />
                      ) : (
                        <img src={Trabajadores} className="imgSidebar anticon" alt="imagen" />
                      )
                    }
                    className="fix"
                  >
                    <Link
                      to="/Trabajador"
                      className="fs-icon-side titulos-sidebar"
                    >
                      Trabajadores
                    </Link>
                  </Menu.Item>

                  <p className="m-fix"></p>

                  {/* PLANIFICAR */}
                  <SubMenu
                    key="3"
                    icon={
                      <img src={Asignaciones}
                        className="imgSidebar anticon"
                        alt="imagen" />
                    }
                    title={
                      <span className="fs-icon-side titulos-sidebar">
                        Planificación
                      </span>
                    }
                    onTitleClick={(e) => {

                      if (this.state.ocultar) {
                        this.setState({
                          menuKeys: [e.key]
                        })
                        this.mostrarMenu();

                      } else if (this.state.menuKeys[0] === e.key) {
                        this.setState({
                          menuKeys: []
                        })

                      } else {
                        this.setState({
                          menuKeys: [e.key]
                        })
                      }

                    }}
                  >
                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={PlanificacionEquiposDeTrabajo}
                      key="sub_1"

                    >
                      <Link to="/Faena">Equipos de Trabajo</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={PlanificacionActividades}
                      key="sub_2"
                    >
                      <Link to="/AsignacionFaena">Actividades</Link>
                    </Menu.Item>

                    {/* <Menu.Item
                      key="sub_3"
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      //cuando se tenga listo lo de seleccionar faena para ver su detalle,
                      //reemplazar éste disabled true por el comentado
                      // disabled={AsignacionesDetalle}
                      disabled={true}
                    >
                      <Link to="/AsignacionFaena">Asignar Personas</Link>
                    </Menu.Item> */}

                  </SubMenu>

                  <p className="m-fix"></p>

                  {/* CHECK-IN */}
                  {/* <Menu.Item
                    disabled={ProcesosCheck}
                    key="4"
                    onClick={() => {
                      this.cambiarOpcionesMenu("CheckIn");
                    }}
                    icon={
                      this.state.flagSelected === "CheckIn" ? (
                        <img src={AsistenciaAzul} className="imgSidebar anticon" alt="imagen" />
                      ) : (
                          <img src={Asistencia} className="imgSidebar anticon" alt="imagen" />
                        )
                    }
                    className="fix"
                  >
                    <Link
                      to="/FiltroTrabajadores"
                      className="fs-icon-side titulos-sidebar"
                    >
                      Check-In
                    </Link>
                  </Menu.Item> */}

                  {/* <p className="m-fix"></p> */}

                  {/* ASISTENCIA */}
                  <SubMenu
                    key="5"
                    icon={<img src={Asistencia} className="imgSidebar anticon" alt="imagen" />}
                    title={
                      <span className="fs-icon-side titulos-sidebar">
                        Asistencia
                      </span>
                    }
                    onTitleClick={(e) => {

                      if (this.state.ocultar) {
                        this.setState({
                          menuKeys: [e.key]
                        })
                        this.mostrarMenu();

                      } else if (this.state.menuKeys[0] === e.key) {
                        this.setState({
                          menuKeys: []
                        })

                      } else {
                        this.setState({
                          menuKeys: [e.key]
                        })
                      }

                    }}
                  >

                    <Menu.Item
                      disabled={AsistenciaCheckIn}
                      key="50"
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                    >
                      <Link
                        to="/FiltroTrabajadores"
                      >
                        Check-In
                      </Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={AsistenciaManual}
                      key="sub_4"

                    >
                      <Link to="/AsistenciaTimeLine">Asistencia Manual</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={AsistenciaQR}
                      key="sub_5"

                    >
                      <Link to="/AsistenciaQR">Asistencia QR</Link>
                    </Menu.Item>

                    <Menu.Item
                      disabled={false}
                      key="51"
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                    >
                      <Link
                        to="/CheckOut"
                      >
                        Check-Out
                      </Link>
                    </Menu.Item>

                    {/* <Menu.Item
                      disabled={true}
                      key="51"
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                    >
                      <Link
                        to="/FiltroTrabajadores"
                      >
                        Check-Out
                      </Link>
                    </Menu.Item> */}

                  </SubMenu>

                  <p className="m-fix"></p>

                  {/* CHECK-OUT */}
                  {/* <Menu.Item
                    disabled={true}
                    key="6"
                    onClick={() => {
                      this.cambiarOpcionesMenu("CheckOut");
                    }}
                    icon={
                      this.state.flagSelected === "CheckOut" ? (
                        <img src={AsistenciaAzul} className="imgSidebar anticon" alt="imagen" />
                      ) : (
                          <img src={Asistencia} className="imgSidebar anticon" alt="imagen" />
                        )
                    }
                    className="fix"
                  >
                    <Link
                      to="/FiltroTrabajadores"
                      className="fs-icon-side titulos-sidebar"
                    >
                      Check-Out
                    </Link>
                  </Menu.Item> */}

                  {/* <p className="m-fix"></p> */}

                  {/* PROCESOS */}
                  <SubMenu
                    key="7"
                    icon={<img src={Procesos} className="imgSidebar anticon" alt="imagen" />}
                    title={
                      <span className="fs-icon-side titulos-sidebar">
                        Procesos
                      </span>
                    }
                    onTitleClick={(e) => {

                      if (this.state.ocultar) {
                        this.setState({
                          menuKeys: [e.key]
                        })
                        this.mostrarMenu();

                      } else if (this.state.menuKeys[0] === e.key) {
                        this.setState({
                          menuKeys: []
                        })

                      } else {
                        this.setState({
                          menuKeys: [e.key]
                        })
                      }

                    }}
                  >

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ProcesosDescanso}
                      key="sub_6"
                    >
                      <Link to="/Descanso">Descanso</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ProcesosEnTransito}
                      key="sub_7"
                    >
                      <Link to="/EnTransito">En Tránsito</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ProcesosVacaciones}
                      key="sub_8"
                    >
                      <Link to="/Vacaciones">Vacaciones</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ProcesosLicencia}
                      key="sub_9"
                    >
                      <Link to="/Licencias">Licencias</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ProcesosMutual}
                      key="sub_10"
                    >
                      <Link to="/Mutual">Licencia Mutualidad</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ProcesosPermisos}
                      key="sub_11"
                    >
                      <Link to="/Permisos">Permisos</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ProcesosAusente}
                      key="sub_12"
                    >
                      <Link to="/Ausentes">Dia Ausente</Link>
                    </Menu.Item>

                  </SubMenu>

                  <p className="m-fix"></p>

                  {/* REPORTERÍA */}
                  <SubMenu
                    key="8"
                    icon={<img src={Reporteria} className="imgSidebar anticon" alt="imagen" />}
                    title={
                      <a className="fs-icon-side titulos-sidebar">Reportería</a>
                    }
                    onTitleClick={(e) => {

                      if (this.state.ocultar) {
                        this.setState({
                          menuKeys: [e.key]
                        })
                        this.mostrarMenu();

                      } else if (this.state.menuKeys[0] === e.key) {
                        this.setState({
                          menuKeys: []
                        })

                      } else {
                        this.setState({
                          menuKeys: [e.key]
                        })
                      }

                    }}
                  >
                    <Menu.Item
                      disabled={ReporteriaDelDia}
                      key="sub_-3"
                    >
                      <Link to="/ReporteDelDia">Reporte del día</Link>
                    </Menu.Item>

                    <Menu.Item
                      disabled={ReporteriaNecesidades}
                      key="sub_-10"
                    >
                      <Link to="/ReporteNecesidades">Reporte de Necesidades</Link>
                    </Menu.Item>

                    <Menu.Item
                      key="sub_-1222"
                      disabled={ReporteriaUtilizacionRelevos}
                    >
                      <Link to="/ReporteUtilizacionRelevo">Reporte Utilización de Relevo</Link>
                    </Menu.Item>

                    <Menu.Item
                      key="sub_-1333"
                      disabled={ReporteriaDesviaciones}
                    >
                      <Link to="/Desviaciones">Desviaciones</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ReporteriaAsistenciaPersonas}
                      key="sub_13"
                    >
                      <Link to="/ReporteriaAsistenciaPersonas">Asistencia Personas</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ReporteriaAsistenciaCC}
                      key="sub_-2"
                    >
                      <Link to="/ReporteriaAsistenciaCC">Asistencia por CC</Link>
                    </Menu.Item>

                    {/* <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ReporteriaAuditoria}
                      key="sub_14"
                    >
                      <Link to="/Auditoria">Auditoría</Link>
                    </Menu.Item> */}

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ReporteriaCierreDeMes}
                      key="sub_15"
                    >
                      <Link to="/CierreDeMes">Cierre de Mes</Link>
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => {
                        this.cambiarOpcionesMenu("");
                      }}
                      disabled={ReporteriaCosteo}
                      key="sub_16"
                    >
                      <Link to="/DetalleCostos">Costeo Sistema Contable</Link>
                    </Menu.Item>

                  </SubMenu>

                  <p className="m-fix"></p>

                  {/* LOGOUT */}
                  <Menu.Item
                    key="10"
                    onClick={() => {
                      localStorage.removeItem("usuario-people-manage");
                      localStorage.removeItem("accesos");
                      window.location = "/";
                    }}
                    icon={<img src={LogoutIcon} className="imgSidebar anticon" alt="imagen" />}
                    className="fix"
                  >
                    <Link
                      to="/"
                      className="fs-icon-side titulos-sidebar"
                    >
                      Cerrar Sesión
                    </Link>
                  </Menu.Item>

                </Menu >
              );

            }

          default:
            return (
              <Menu
                theme="dark"
                //onClick={this.props.mostrarMenu}
                defaultSelectedKeys={["1"]}
                mode="inline"
                disabled={true}
              ></Menu>
            );
        }

      } else {
        if(window.location.pathname !== '/Documentacion'){
          return (
            <Redirect to="/" />
          );
        }
      }

    } catch (error) {
      console.error(error);
      console.log("no se encontro usuario");
    }
  };

  redireccionar = () => {

    const ruta = window.location.pathname;
    if(ruta !== '/Documentacion'){
      if (this.state.accesoRuta === false && ruta !== '/' ) {
        return <Redirect to="/" />;
      }
    }
  };

  render() {

    return (
      <Layout
        style={{ minHeight: "100vh" }}
      >
        {this.redireccionar()}

        {/* SIDER DESKTOP */}
        <Sider
          collapsible
          collapsed={this.state.ocultar}
          // onMouseEnter={this.mostrarMenu}
          // onMouseLeave={this.ocultarMenu}
          // onClick={this.state.ocultar ? this.mostrarMenu : this.ocultarMenu}
          //className={this.state.isInvisible ? "invisible" : null}

          className={'invisible-desktop' + ' ' + (this.state.isMuestraMenu ? 'full-invisible' : '')}
          width={210}
        >

          <img
            alt="imagen"
            onClick={(e) => {

              if (this.state.ocultar) {
                this.mostrarMenu(e);
              } else {
                this.ocultarMenu(e);
              }

              if (window.location.pathname === '/ReporteriaAsistenciaPersonas' || window.location.pathname === '/ReporteriaAsistenciaCC') {
                setTimeout(() => {
                  window.dispatchEvent(new Event('resize'));
                }, 1000)
              }

            }}
            src={this.state.ocultar ? iconFlechaDer : iconFlechaIzq}
            style={{
              width: "30px",
              height: "30px",
              position: "absolute",
              right: "0",
              marginRight: "-15px",
              marginTop: "70px",
              cursor: "pointer",
            }}
          />

          {this.perfilUsuario()}
          {this.opcionesDelMenu('DESKTOP')}

          {/* <div style={{ display: 'block', height: '100px' }}>
          </div> */}

          {/* <div
            className={
              this.state.isRelative
                ? "menu-icons-relative"
                : "menu-icons-absolute"
            }
          >
            <div style={{ width: "100%" }}>
              <Button
                onClick={() => {
                  localStorage.removeItem("usuario-people-manage");
                  localStorage.removeItem("accesos");
                  window.location = "/";
                }}
                type="primary"
                className="boton-logout"
                icon={<ImportOutlined />}
              />
            </div>
          </div> */}

        </Sider>

        {/* SIDER MOBILE */}
        <Sider
          collapsible
          collapsed={this.state.ocultar}
          collapsedWidth={0}
          zeroWidthTriggerStyle={{ display: 'none' }}
          // onMouseEnter={this.mostrarMenu}
          // onMouseLeave={this.ocultarMenu}
          // onClick={this.state.ocultar ? this.mostrarMenu : this.ocultarMenu}
          //className={this.state.isInvisible ? "invisible" : null}

          className={'invisible-mobile menu-mobile' + ' ' + (this.state.isMuestraMenu ? 'full-invisible' : '')}
          width={240}
        >

          {/* <img
            alt="imagen"
            onClick={(e) => {

              if (this.state.ocultar) {
                this.mostrarMenu(e);
              } else {
                this.ocultarMenu(e);
              }

              if (window.location.pathname === '/ReporteriaAsistenciaPersonas') {
                setTimeout(() => {
                  window.dispatchEvent(new Event('resize'));
                }, 400)
              }

            }}
            src={this.state.ocultar ? iconFlechaDer : iconFlechaIzq}
            style={{
              width: "30px",
              height: "30px",
              position: "absolute",
              right: "0",
              marginRight: "-15px",
              marginTop: "70px",
              cursor: "pointer",
            }}
          /> */}

          {this.perfilUsuario()}
          {this.opcionesDelMenu('MOBILE')}

          {/* <div style={{ display: 'block', height: '100px' }}>
          </div> */}

          {/* <div
            // className={
            //   this.state.isRelative
            //     ? "menu-icons-relative"
            //     : "menu-icons-absolute"
            // }
            className={'boton-logout-mobile-wrapper'}
          >
            <div style={{ width: "100%" }}>
              <Button
                onClick={() => {
                  localStorage.removeItem("usuario-people-manage");
                  localStorage.removeItem("accesos");
                  window.location = "/";
                }}
                type="primary"
                className={this.state.ocultar ? "full-invisible" : "boton-logout-mobile"}
                icon={<ImportOutlined />}
              />
            </div>
          </div> */}

        </Sider>

        <Layout className="site-layout">

          <Header
            className={'mobile-header' + ' ' + (this.state.isMuestraMenu ? 'full-invisible' : '')}
          >
            <Row>

              <Col span={6} style={{ textAlign: 'center' }}>
                <MenuOutlined
                  style={{ color: 'white', fontSize: '25px', position: 'relative', top: '5px' }}
                  onClick={() => {
                    this.mostrarMenu();
                  }}
                />
              </Col>

              <Col>
              </Col>

            </Row>

          </Header>

          <Content
            onClick={() => {
              this.ocultarMenu();

              if ((window.location.pathname === '/ReporteriaAsistenciaPersonas' || window.location.pathname === '/ReporteriaAsistenciaCC')
                && this.state.ocultar === false) {
                setTimeout(() => {
                  window.dispatchEvent(new Event('resize'));
                }, 1000)
              }
            }}
          // ORIGINAL
          // onClick={this.props.monstrar}
          // style={{ margin: "0 16px" }}
          >

            {this.props.children}
          </Content>

        </Layout>

        <span>
          <style jsx="true">
            {`
            .ant-menu-submenu-popup > .ant-menu {
              display: none !important;
            }
          `}
          </style>
        </span>

      </Layout>
    );
  }
}

export default SideMenu;
