import React, { useState, useEffect } from "react";
import Cargo from "../../img/icons/Cargo.svg";
import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  Select,
  InputNumber,
} from "antd";
import './FormularioCargos.css'
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';

import Swal from "sweetalert2";

const FormularioCargosEditar = (props) => {

  const [vigencia, setVigencia] = useState(null);
  const [listaEpps, setListaEpps] = useState([]);
  const [listaDocumentos, setListaDocumentos] = useState([]);

  const [listaEppsOld, setListaEppsOld] = useState([]);
  const [listaDocumentosOld, setListaDocumentosOld] = useState([]);

  const [form] = Form.useForm();

  const formRef = React.createRef();

  //   //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = async (values) => {

    let listaIdsAgregados = [];
    let listaIdsEliminados = [];

    let listaIdsDocumentosAgregados = [];
    let listaIdsDocumentosEliminados = [];

    // detectando los EPP agregados
    listaEpps.forEach(epp => {

      if (!listaEppsOld.includes(epp)) {
        listaIdsAgregados.push(epp.IdEpp);
      }
    });

    // detectando los documentos agregados
    listaDocumentos.forEach(document => {
      if (!listaDocumentosOld.includes(document)) {
        listaIdsDocumentosAgregados.push(document.IdDocumento);
      }
    });

    // detectando los EPP eliminados
    listaEppsOld.forEach(epp => {

      if (!listaEpps.includes(epp)) {
        listaIdsEliminados.push(epp.IdEpp);
      }
    });

    // detectando los documentos eliminados
    listaDocumentosOld.forEach(document => {
      if (!listaDocumentos.includes(document)) {
        listaIdsDocumentosEliminados.push(document.IdDocumento);
      }
    });


    let epps = props.epps;
    let documentos = props.documentos;

    let nombresAgregados = [];
    let nombresEliminados = [];

    let nombresDocumentosAgregados = [];
    let nombresDocumentosEliminados = [];

    // obteniendo los nombres de los epps modificados para mostrar al usuario
    epps.forEach(epp => {

      if (listaIdsAgregados.includes(epp._id)) {
        nombresAgregados.push(epp.Nombre);
      }

      if (listaIdsEliminados.includes(epp._id)) {
        nombresEliminados.push(epp.Nombre);
      }
    });

    documentos.forEach(documento => {

      if (listaIdsDocumentosAgregados.includes(documento._id)) {
        nombresDocumentosAgregados.push(documento.Nombre);
      }

      if (listaIdsDocumentosEliminados.includes(documento._id)) {
        nombresDocumentosEliminados.push(documento.Nombre);
      }
    });

    let editarTrabajadores = false;
    let returnFlag = false;

    // preguntar se desean quitar/agregar los epp para trabajadores con el cargo
    if (listaIdsAgregados.length > 0 || listaIdsEliminados.length > 0 || listaIdsDocumentosAgregados.length > 0 || listaIdsDocumentosEliminados.length > 0) {

      await Swal.fire({
        title: "¿Aplicar a trabajadores?",
        html: generarPreguntaEpp(nombresAgregados, nombresDocumentosAgregados, nombresEliminados, nombresDocumentosEliminados),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#e60aa3",
        cancelButtonColor: "#e60aa3",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {

        if (result.isConfirmed) {
          editarTrabajadores = true;
        }

        // if false
        if (result.dismiss == 'cancel') {
          editarTrabajadores = false;
        }

        // if dismissed
        if (result.dismiss == 'backdrop') {
          returnFlag = true;
        }

      });

      if (returnFlag || !editarTrabajadores) {
        return;
      }
    }

    values.Epps = listaEpps;
    values.Documento = listaDocumentos;

    const objEditar = {
      Vigencia: values.Vigencia,
      Epps: listaEpps,
      Documentos: listaDocumentos,
      Nombre: values.Nombre,
      listaIdsAgregados,
      listaIdsEliminados,
      editarTrabajadores,
    };

    // return false;
    props.EditarCargo(objEditar);
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const generarPreguntaEpp = (nombresEPPAgregados, nombresDocumentosAgregados, nombresEPPEliminados, nombresDocumentosEliminados) => {

    let ulistEPPAgregados = '<p>Se han agregado los E.P.P:</p><ul>';
    let ulistEPPEliminados = '<p>Se han eliminado los E.P.P:</p><ul>';

    let ulistDocumentosAgregados = '<p>Se han agregado los documentos:</p><ul>';
    let ulistDocumentosEliminados = '<p>Se han eliminado los documentos:</p><ul>';

    if (nombresEPPAgregados.length > 0) {

      nombresEPPAgregados.forEach((nombre, index) => {

        ulistEPPAgregados += `<li>${nombre}</li>`;

        if (index == nombresEPPAgregados.length - 1) {
          ulistEPPAgregados += `</ul>`;
        }
      })
    } else {
      ulistEPPAgregados = '';
    }

    if (nombresDocumentosAgregados.length > 0) {

      nombresDocumentosAgregados.forEach((nombre, index) => {

        ulistDocumentosAgregados += `<li>${nombre}</li>`;

        if (index == nombresDocumentosAgregados.length - 1) {
          ulistDocumentosAgregados += `</ul>`;
        }
      })
    } else {
      ulistDocumentosAgregados = '';
    }

    if (nombresEPPEliminados.length > 0) {

      nombresEPPEliminados.forEach((nombre, index) => {

        ulistEPPEliminados += `<li>${nombre}</li>`;

        if (index == nombresEPPEliminados.length - 1) {
          ulistEPPEliminados += `</ul>`;
        }
      });
    } else {
      ulistEPPEliminados = '';
    }

    if (nombresDocumentosEliminados.length > 0) {

      nombresDocumentosEliminados.forEach((nombre, index) => {

        ulistDocumentosEliminados += `<li>${nombre}</li>`;

        if (index == nombresDocumentosEliminados.length - 1) {
          ulistDocumentosEliminados += `</ul>`;
        }
      });
    } else {
      ulistDocumentosEliminados = '';
    }

    

    let lastText = `<p>¿Desea aplicar estos cambios para todos los trabajadores que tengan este cargo asignado?
    <strong>Estos cambios asignarán automáticamente los E.P.P. y documentos que se le requieren a los trabajadores con este cargo.</strong></p>`;

    let resultText =
      `
      <div class='epp-swal-div'>
        ${ulistEPPAgregados}

        ${ulistEPPEliminados}

        ${ulistDocumentosAgregados}

        ${ulistDocumentosEliminados}

        ${lastText}
      </div>
    `;

    return resultText;
  }

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  // este id cambiará sólo al editar otro elemento
  let idDinamico = props.Formulario._id;

  useEffect(() => {
    let {
      Descripcion,
      Nombre,
      Vigencia,
      Epps,
      Documentos
    } = props.Formulario;

    form.setFieldsValue({
      Descripcion,
      Nombre,
      Vigencia,
    });

    setVigencia(Vigencia);

    setListaEpps(Epps || []);
    setListaEppsOld(Epps || []);

    setListaDocumentos(Documentos || []);
    setListaDocumentosOld(Documentos || []);

  }, [idDinamico]);

  const addEpp = () => {

    let { Epp, DiasSugeridos } = formRef.current.getFieldsValue(['Epp', 'DiasSugeridos']);

    if (!Epp || !DiasSugeridos) {

      Swal.fire({
        icon: "warning",
        title: "¡Ups!",
        text: "Ingresa la información del EPP para agregarlo a la lista.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Ok",
      });

      return;
    }

    let nuevaListaEpps = [...listaEpps, { IdEpp: Epp, DuracionSugeridaDias: DiasSugeridos }];

    setListaEpps(nuevaListaEpps);

    formRef.current.resetFields(['Epp', 'DiasSugeridos']);

  }

  const addDocument = () => {

    let { Documento } = formRef.current.getFieldsValue(['Documento']);

    if (!Documento) {

      Swal.fire({
        icon: "warning",
        title: "¡Ups!",
        text: "Ingresa la información del documento para agregarlo a la lista.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Ok",
      });

      return;
    }

    let nuevaListaDocumentos = [...listaDocumentos, { IdDocumento: Documento }];

    setListaDocumentos(nuevaListaDocumentos);

    formRef.current.resetFields(['Documento']);

  }

  const deleteEpp = (idEpp) => {

    let nuevaListaEpps = listaEpps.filter(eppIngresado => {
      return eppIngresado.IdEpp != idEpp;
    })

    setListaEpps(nuevaListaEpps);
  }

  const deleteDocument = (idDocumento) => {

    let nuevaListaDocumentos = listaDocumentos.filter(documentoIngresado => {
      return documentoIngresado.IdDocumento != idDocumento;
    })

    setListaDocumentos(nuevaListaDocumentos);
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      ref={formRef}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={Cargo} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Editar Cargo "
          />
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>

            <Form.Item
              name="Nombre"
              label="Nombre:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir un nombre",
                  min: 2,
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  let valor = e.target.value;
                  props.TomarValoresEditar("Nombre", valor);
                }}
                name="Nombre"
                className="campos" />
            </Form.Item>

            {/* TEXTOS */}
            <Row gutter={14} style={{ marginBottom: '14px' }}>

              <Col span={6} style={{ textAlign: 'right', height: '12px' }}>

                <p style={{ marginBottom: '20px' }}>EPPs sugeridos para el cargo</p>
                <span style={{ color: '#aaaaaa', fontSize: '14px' }}>(Opcional)</span>

              </Col>

              <Col span={6} style={{ textAlign: 'left' }}>
                EPP
              </Col>

              <Col span={6} style={{ textAlign: 'left' }}>
                Días de validez sugeridos
              </Col>
            </Row>

            {/* AGREGAR EPP  */}
            <Row gutter={14}>

              <Col span={6} style={{ textAlign: 'right' }}></Col>

              {/* EPP */}
              <Col span={6} style={{ textAlign: 'left' }}>
                <Form.Item
                  name="Epp"
                  className={'epps-form-item'}
                  noStyle
                >
                  <Select
                    // mode="multiple"
                    className="epps-campos"
                  >
                    {props.epps.map((epp, index) => {

                      const yaIngresado = listaEpps.some((eppLista) => epp._id == eppLista.IdEpp);

                      return (
                        <Select.Option
                          key={index}
                          value={epp._id}
                          disabled={yaIngresado}
                        >
                          {epp.Nombre}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>

              {/* VALIDEZ */}
              <Col span={6} style={{ textAlign: 'left' }}>
                <Form.Item
                  name={"DiasSugeridos"}
                  className={'epps-form-item'}
                  noStyle
                >
                  <InputNumber
                    type="number"
                    min={1}
                    className={'epps-campos'}
                  />
                </Form.Item>
              </Col>

              {/* BOTON AGREGAR */}
              <Col span={2}>
                <Button
                  className={'epp-add-btn'}
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={addEpp}
                />
              </Col>

            </Row>

            {/* HR DECORATIVO */}
            <Row gutter={14}>
              <Col flex={'auto'}></Col>
              <Col span={14}>
                <hr className={'epps-hr'} />
              </Col>
              <Col span={4}></Col>
            </Row>

            {/* LISTA INGRESADA POR EL USUARIO */}
            {listaEpps.map((eppObj, index) => {

              return (

                <div key={index}>
                  {/* ELEMENTO DE LISTA EPP */}
                  <Row gutter={14} key={index}>

                    <Col span={6} style={{ textAlign: 'right' }}></Col>

                    {/* EPP */}
                    <Col span={6} style={{ textAlign: 'left' }}>
                      <Form.Item
                        className={'epps-form-item'}
                        noStyle
                      >
                        <Select
                          disabled={true}
                          className="epps-campos"
                          placeholder="(Opcional)"
                          value={eppObj.IdEpp}
                        >
                          {props.epps.map((epp, index) => (
                            <Select.Option
                              key={index}
                              value={epp._id}
                            >
                              {epp.Nombre}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    {/* VALIDEZ */}
                    <Col span={6} style={{ textAlign: 'left' }}>
                      <Form.Item
                        className={'epps-form-item'}
                        noStyle
                      >
                        <InputNumber
                          disabled={true}
                          type="number"
                          min={1}
                          className={'epps-campos'}
                          value={eppObj.DuracionSugeridaDias}
                        />
                      </Form.Item>
                    </Col>

                    {/* BOTON AGREGAR */}
                    <Col span={2}>
                      <Button
                        className={'epp-add-btn'}
                        shape="circle"
                        icon={<DeleteFilled />}
                        onClick={() => {
                          deleteEpp(eppObj.IdEpp);
                        }}
                      />
                    </Col>

                  </Row>

                  {/* HR DECORATIVO */}
                  <Row gutter={14}>
                    <Col flex={'auto'}></Col>
                    <Col span={14}>
                      <hr className={'epps-hr'} />
                    </Col>
                    <Col span={4}></Col>
                  </Row>

                </div>
              )

            })}

            {/* TEXTOS */}
            <Row gutter={14} style={{ marginBottom: '14px' }}>

              <Col span={6} style={{ textAlign: 'right', height: '12px' }}>

                <p style={{ marginBottom: '20px' }}>Documentos sugeridos para el cargo</p>
                <span style={{ color: '#aaaaaa', fontSize: '14px' }}>(Opcional)</span>

              </Col>

              <Col span={6} style={{ textAlign: 'left' }}>
                Documentos
              </Col>

            </Row>

            {/* AGREGAR DOCUMENTOS  */}
            <Row gutter={14}>

              <Col span={6} style={{ textAlign: 'right' }}></Col>

              {/* DOCUMENTO */}
              <Col span={12} style={{ textAlign: 'left' }}>
                <Form.Item
                  name="Documento"
                  className={'epps-form-item'}
                  noStyle
                >
                  <Select
                    // mode="multiple"
                    className="epps-campos"
                  >
                    {props.documentos.map((documento, index) => {

                      const yaIngresado = listaDocumentos.some((documentosLista) => documento._id == documentosLista.IdDocumento);

                      return (
                        <Select.Option
                          key={index}
                          value={documento._id}
                          disabled={yaIngresado}
                        >
                          {documento.Nombre}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>


              {/* BOTON AGREGAR */}
              <Col span={2}>
                <Button
                  className={'epp-add-btn'}
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={addDocument}
                />
              </Col>

            </Row>

            {/* HR DECORATIVO */}
            <Row gutter={14}>
              <Col flex={'auto'}></Col>
              <Col span={14}>
                <hr className={'epps-hr'} />
              </Col>
              <Col span={4}></Col>
            </Row>

            {/* LISTA INGRESADA POR EL USUARIO */}
            {listaDocumentos.map((documentoObj, index) => {

              return (

                <div key={index}>
                  {/* ELEMENTO DE LISTA EPP */}
                  <Row gutter={14} key={index}>

                    <Col span={6} style={{ textAlign: 'right' }}></Col>

                    {/* DOCUMENTO */}
                    <Col span={12} style={{ textAlign: 'left' }}>
                      <Form.Item
                        className={'epps-form-item'}
                        noStyle
                      >
                        <Select
                          disabled={true}
                          className="epps-campos"
                          placeholder="(Opcional)"
                          value={documentoObj.IdDocumento}
                        >
                          {props.documentos.map((documento, index) => (
                            <Select.Option
                              key={index}
                              value={documento._id}
                            >
                              {documento.Nombre}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    {/* BOTON AGREGAR */}
                    <Col span={2}>
                      <Button
                        className={'epp-add-btn'}
                        shape="circle"
                        icon={<DeleteFilled />}
                        onClick={() => {
                          deleteDocument(documentoObj.IdDocumento);
                        }}
                      />
                    </Col>

                  </Row>

                  {/* HR DECORATIVO */}
                  <Row gutter={14}>
                    <Col flex={'auto'}></Col>
                    <Col span={14}>
                      <hr className={'epps-hr'} />
                    </Col>
                    <Col span={4}></Col>
                  </Row>

                </div>
              )

            })}

            <Form.Item
              name="Vigencia"
              label="Vigencia"
              style={{ textAlign: "left" }}
            >
              <Switch
                checked={vigencia}
                onChange={(e) => {
                  setVigencia(e);
                }}
                className="switch"
              />
            </Form.Item>

          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Editar
          </Button>
        </Col>
      </Row>





      <span>
        <style jsx="true">
          {`
            .ant-input-number-disabled .ant-input-number-input {
              color: #555555 !important;
            }

            .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
              color: #555555 !important;
            }
            `
          }
        </style>
      </span>
    </Form>
  );
};

export default FormularioCargosEditar;
