// SE LE ENTREGA EL NOMBRE DEL OBJETO DE ACCESO DEL SCHEMA DE ACCESOS
const obtenerAccesosPagina = async (nombreAcceso, nombreAccesoAntiguo) => {

    let accesos = await localStorage.getItem('accesos');
    let usuario = localStorage.getItem("usuario-people-manage");

    accesos = JSON.parse(accesos);
    usuario = JSON.parse(usuario);

    let accesosPagina;

    if (accesos) {

        //validación por si entra a una ruta que no está en los accesos, por ejemplo empresas o súper usuario
        if (Object.keys(accesos).includes(nombreAcceso)) {
            accesosPagina = accesos[nombreAcceso];
        } else if (Object.keys(accesos).includes(nombreAccesoAntiguo)) {
            accesosPagina = accesos[nombreAccesoAntiguo];
        } else {
            accesosPagina = {
                Ver: false,
                Editar: false,
                Eliminar: false,
            }
        }


        //para usuarios antiguos sin objeto de acceso
    } else if (usuario) {
        accesosPagina = {
            Ver: true,
            Editar: true,
            Eliminar: true,
        }

        //cuando no está logueado 
    } else {
        accesosPagina = {
            Ver: false,
            Editar: false,
            Eliminar: false,
        }
    }

    return accesosPagina;
}

// RESPONDE UN BOOLEAN DE SI EL USUARIO TIENE ACCESO A LA RUTA QUE SE LE ENTREGA:
// HECHO PARA REDIRECCIONAR EN CASO DE QUE NO TENGA ACCESO A ESA RUTA.
const obtenerAccesosRuta = async (ruta) => {

    //Agregar acá rutas públicas del sistema (excepto /Recuperarpassword, que requiere validación adicional)
    const rutasPublicas = [
        '/',
        '/ImpresionLicencia',
        '/ImpresionAccidente',
        '/ImpresionAusente',
        '/ImpresionDescanso',
        '/ImpresionVacaciones',
        '/ImpresionEpp',
        '/AsignacionActividades',
        '/DetalleActividad',
        '/CalendarioTrabajadorNew',

        // AGREGAR ACÁ PÁGINAS QUE TODAVÍA NO TIENEN ACCESOS IMPLEMENTADOS
        '/TestingView',
        '/Videos',
        // '/Contratos',
        // '/ReporteDelDia'
        // '/ReporteNecesidades'
        //'/Reporteria/Excel',

        // '/ReporteUtilizacionRelevo'
    ];

    if (rutasPublicas.includes(ruta)) {
        return true;

    } else if (ruta.toUpperCase() === '/RECUPERARPASSWORD') {

        const params = new URLSearchParams(window.location.search)

        if (params.has('id')) {
            return true;
        } else {
            return false;
        }
    }

    let nombreAcceso;
    let nombreAccesoAntiguo;

    switch (ruta) {
        case '/CentroControl':
            nombreAcceso = 'Dashboard';
            break;
        case '/Trabajador':
            nombreAcceso = 'MantenedorTrabajadores';
            nombreAccesoAntiguo = 'MantenedorFichaTrabajador';
            break;
        case '/Contratos':
            nombreAcceso = 'Contratos';
            break;
        case '/AsignacionFaena':
            nombreAcceso = 'PlanificacionActividades';
            nombreAccesoAntiguo = 'AsignacionesCentroDeCosto';
            break;
        case '/Faena':
            nombreAcceso = 'PlanificacionEquiposDeTrabajo';
            nombreAccesoAntiguo = 'AsignacionesPlantilla';
            break;
        case '/DetalleFaena':
            nombreAcceso = 'PlanificacionDetalleActividad';
            nombreAccesoAntiguo = 'AsignacionesDetalle';
            break;
        case '/CalendarioTrabajador':
            nombreAcceso = 'PlanificacionDetalleTrabajador';
            nombreAccesoAntiguo = 'DetalleTrabajador';
            break;
        case '/FiltroTrabajadores':
            nombreAcceso = 'AsistenciaCheckIn';
            nombreAccesoAntiguo = 'ProcesosCheck';
            break;
        case '/Descanso':
            nombreAcceso = 'ProcesosDescanso';
            break;
        case '/EnTransito':
            nombreAcceso = 'ProcesosEnTransito';
            break;
        case '/Vacaciones':
            nombreAcceso = 'ProcesosVacaciones';
            break;
        case '/Licencias':
            nombreAcceso = 'ProcesosLicencia';
            break;
        case '/Mutual':
            nombreAcceso = 'ProcesosMutual';
            break;
        case '/Permisos':
            nombreAcceso = 'ProcesosPermisos';
            break;
        case '/Ausentes':
            nombreAcceso = 'ProcesosAusente';
            break;
        case '/AsistenciaTimeLine':
            nombreAcceso = 'AsistenciaManual';
            nombreAccesoAntiguo = 'Asistencia';
            break;
        case '/AsistenciaQR':
            nombreAcceso = 'AsistenciaQR';
            nombreAccesoAntiguo = 'ValidacionQR';
            break;

        case '/Desviaciones':
            nombreAcceso = 'ReporteriaDesviaciones';
            break;

        case '/ReporteUtilizacionRelevo':
            nombreAcceso = 'ReporteriaUtilizacionRelevos';
            break;

        case '/ReporteDelDia':
            nombreAcceso = 'ReporteriaDelDia';
            break;

        case '/ReporteNecesidades':
            nombreAcceso = 'ReporteriaNecesidades';
            break;

        case '/ReporteriaAsistenciaPersonas':
            nombreAcceso = 'ReporteriaAsistenciaPersonas';
            break;

        case '/ReporteriaAsistenciaCC':
            nombreAcceso = 'ReporteriaAsistenciaCC';
            break;
        case '/Auditoria':
            nombreAcceso = 'ReporteriaAuditoria';
            nombreAccesoAntiguo = 'Auditoria';
            break;
        case '/CierreDeMes':
            nombreAcceso = 'ReporteriaCierreDeMes';
            nombreAccesoAntiguo = 'CierreDeMes';
            break;
        case '/DetalleCostos':
            nombreAcceso = 'ReporteriaCosteo';
            nombreAccesoAntiguo = 'Costeo';
            break;
        case '/Reporteria/Excel':
            nombreAcceso = 'ReporteriaExcel';
            break;
            

        case '/Usuarios':
            nombreAcceso = 'MantenedorUsuarios';
            break;
        case '/PerfilesPermiso':
            nombreAcceso = 'MantenedorPerfilesUsuario';
            nombreAccesoAntiguo = 'MantenedorPerfilesPermiso';
            break;
        case '/AccesosSistema':
            nombreAcceso = 'MantenedorAccesosSistema';
            nombreAccesoAntiguo = 'MantenedorAccesosPermiso';
            break;
        case '/Sucursales':
            nombreAcceso = 'MantenedorSucursales';
            break;
        case '/CentroDeCosto':
            nombreAcceso = 'MantenedorCentroDeCostos';
            break;
        case '/Regimen':
            nombreAcceso = 'MantenedorRegimenDeTrabajo';
            break;
        case '/Feriados':
            nombreAcceso = 'MantenedorFeriados';
            break;
        case '/Documentos':
            nombreAcceso = 'MantenedorDocumentos';
            break;
        case '/Titulos':
            nombreAcceso = 'MantenedorTitulos';
            break;
        case '/Especialidades':
            nombreAcceso = 'MantenedorEspecialidades';
            break;
        case '/Cargos':
            nombreAcceso = 'MantenedorCargos';
            break;
        case '/EPP':
            nombreAcceso = 'MantenedorEpp';
            break;
        case '/CheckOut':
            nombreAcceso = 'AsistenciaCheckOut';
            nombreAccesoAntiguo = 'ProcesosCheck';
            break;
    }

    let accesos = await obtenerAccesosPagina(nombreAcceso, nombreAccesoAntiguo);
    return accesos.Ver;
}

export default {
    obtenerAccesosPagina,
    obtenerAccesosRuta,
}