import React from "react";
import regimen from "../../img/icons/regimen.svg";
import {
  Form,
  Input,
  Button,
  Card,
  Switch,
  Row,
  Col,
  PageHeader,
  InputNumber,
  Checkbox,
} from "antd";

import Swal from "sweetalert2";

const FormularioRegimenTrabajo = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();

  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {
    try{
      let Dias_De_Trabajo_Arreglo = {
        Lunes: values.Dias_De_Trabajo == undefined ? false : values.Dias_De_Trabajo.includes("Lunes"),
        Martes: values.Dias_De_Trabajo == undefined ? false : values.Dias_De_Trabajo.includes("Martes"),
        Miercoles: values.Dias_De_Trabajo == undefined ? false : values.Dias_De_Trabajo.includes("Miercoles"),
        Jueves: values.Dias_De_Trabajo == undefined ? false : values.Dias_De_Trabajo.includes("Jueves"),
        Viernes: values.Dias_De_Trabajo == undefined ? false : values.Dias_De_Trabajo.includes("Viernes"),
        Sabado: values.Dias_De_Trabajo == undefined ? false : values.Dias_De_Trabajo.includes("Sabado"),
        Domingo: values.Dias_De_Trabajo == undefined ? false : values.Dias_De_Trabajo.includes("Domingo"),
        Feriados: values.Dias_De_Trabajo == undefined ? false : values.Dias_De_Trabajo.includes("Feriados"),
      };
      values.Dias_De_Trabajo = Dias_De_Trabajo_Arreglo;

      let Dias_De_Descansos_Arreglo = {
        Lunes: values.Dias_De_Descanso == undefined ? false : values.Dias_De_Descanso.includes("Lunes"),
        Martes: values.Dias_De_Descanso == undefined ? false : values.Dias_De_Descanso.includes("Martes"),
        Miercoles: values.Dias_De_Descanso == undefined ? false : values.Dias_De_Descanso.includes("Miercoles"),
        Jueves: values.Dias_De_Descanso == undefined ? false : values.Dias_De_Descanso.includes("Jueves"),
        Viernes: values.Dias_De_Descanso == undefined ? false : values.Dias_De_Descanso.includes("Viernes"),
        Sabado: values.Dias_De_Descanso == undefined ? false : values.Dias_De_Descanso.includes("Sabado"),
        Domingo: values.Dias_De_Descanso == undefined ? false : values.Dias_De_Descanso.includes("Domingo"),
        Feriados: values.Dias_De_Descanso == undefined ? false : values.Dias_De_Descanso.includes("Feriados"),
      };

      values.Dias_De_Descanso = Dias_De_Descansos_Arreglo;

      props.crearRegimen(values);
    }catch(error){
      Swal.fire({
        title: "Error",
        text: "Error al enviar formulario de regimen de trabajo.",
        icon: "error",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Continuar",
      });
    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={regimen} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Nuevo régimen de trabajo"
          />
        </Col>

        <Col span={24}>
          <Card className="card-perfil" style={{ width: "100%" }}>
            <Form.Item
              name="Nombre"
              label="Nombre:"
              rules={[
                {
                  required: true,
                  message: "Debe introducir un nombre con un máximo de 40 caracteres",
                  min: 1,
                  max: 40,
                },
              ]}
            >
              <Input name="Nombre" className="campos" />
            </Form.Item>
            <Form.Item
              label="Descripción"
              name="Descripcion"
              rules={[
                {
                  min: 1,
                  required: true,
                  message: "Debe ingresar una descripción del régimen",
                },
              ]}
              hasFeedback
            >
              <Input
                name="Descripcion"
                className="campos"
                placeholder="Descripción del régimen"
              />
            </Form.Item>
            <Form.Item
              style={{ textAlign: "left" }}
              label="Días de trabajo efectivos"
              name="Cantidad_Dias_Trabajo"
              rules={[
                {
                  required: true,
                  message: "Debe introducir los días de trabajo",
                },
              ]}
              hasFeedback
            >
              <InputNumber min={0} max={100} />
            </Form.Item>
            <Form.Item
              style={{ textAlign: "left" }}
              label="Días de descanso"
              name="Cantidad_Dias_Descanso"
              rules={[
                {
                  required: true,
                  message: "Debe introducir los días de descanso",
                },
              ]}
              hasFeedback
            >
              <InputNumber min={0} max={100} />
            </Form.Item>
            <Form.Item style={{ textAlign: "right" }}>
              <h3>¿Qué días trabajará?</h3>
            </Form.Item>
            <Form.Item
              name="Dias_De_Trabajo"
              style={{ textAlign: "left" }}
              label="."
              wrapperCol={{
                
                xs: {
                  span: 24,
                },
                sm: {
                  span: 24,
                },
              }
              }
            >
              <Checkbox.Group name="Dias_De_Trabajo" onChange={onChange}>
                <Row>
                  <Col span={7}>
                    <Checkbox value="Lunes">Lunes</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Martes">Martes</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Miercoles">Miércoles</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Jueves">Jueves</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Viernes">Viernes</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Sabado">Sábado</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Domingo">Domingo</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Feriados">¿Cuenta los feriados?</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item style={{ textAlign: "right" }}>
              <h3>¿Qué días descansará?</h3>
            </Form.Item>
            <Form.Item
              name="Dias_De_Descanso"
              style={{ textAlign: "left" }}
              label="."
              wrapperCol={{
                xs: {
                  span: 24,
                },
                sm: {
                  span: 24,
                },
              }
              }
            >
              <Checkbox.Group name="Dias_De_Descanso" onChange={onChange}>
                <Row>
                  <Col span={7}>
                    <Checkbox value="Lunes">Lunes</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Martes">Martes</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Miercoles">Miércoles</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Jueves">Jueves</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Viernes">Viernes</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Sabado">Sábado</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Domingo">Domingo</Checkbox>
                  </Col>
                  <Col span={7}>
                    <Checkbox value="Feriados">¿Cuenta los feriados?</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item label="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                defaultChecked
                onChange={props.cambiarEstadoVigencia}
                className="switch"
              />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FormularioRegimenTrabajo;
