import React, { Component } from "react";
import "./contratos.css";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import FormEditarTrabajadorContrato from "../../components/formTrabajadorContrato/FormEditarTrabajadorContrato";
import FormularioEditarContrato from '../../components/formularioEditarContrato/formularioEditarContrato';
import FormularioTrabajadorVisualizar from "../../components/fichaTrabajadorContratos/FichaTrabajador";

import {
  Layout, DatePicker, Row, Col, Select, InputNumber, Form, Button,
  Modal, PageHeader, Tabs, Input, Divider, Popover, Alert, Radio,
  Collapse,
} from "antd";

// import embarcado from "../../img/icons/embarcado.svg";
// import descanso from "../../img/icons/descanso.svg";

import {
  obtenerCargos,
  obtenerTrabajadores,
  masivoContratos,
  obtenerContratosTrabajador,
  obtenerContratoActualTrabajador,
  obtenerRegimenes,
  obtenerEpps,
  obtenerDocumentos,
  obtenerTitulos,
  obtenerEspecialidades,
  obtenerRegiones,
  obtenerProvincias,
  obtenerComunas,
  crearContratoTrabajador,
  actualizarContrato,
  actualizarTrabajador,
  revisarTienePlanificacion,
  finiquitarTrabajadorPlanificacionesAcortar,
  actualizarContratoSoloContrato,
  validarEliminacionContrato,
  eliminacionContratoCompleto,
  verEstadoTrabajador
} from './contratosApi';

import {
  momentToUnixFechaInicio,
  momentToUnixFechaFin,
  momentToStringFechaInicio,
  momentToStringFechaFin,
  databaseFormat,
  convertirFechaString,
} from "../../funcionesGenericas/fechas";

import Swal from "sweetalert2";
import accesosPagina from '../../funcionesGenericas/accesosUsuario';
import locale from "antd/es/date-picker/locale/es_ES";
import moment, { unix } from "moment";
import {
  LeftCircleTwoTone, EyeFilled, FileProtectOutlined, RetweetOutlined,
  UserDeleteOutlined, FileAddOutlined, DiffOutlined, InfoCircleTwoTone,
  EditFilled, FileTextFilled,DeleteFilled
} from "@ant-design/icons";

const { Header, Content } = Layout;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { TextArea, Search } = Input;
const { Panel } = Collapse;

class Contratos extends Component {

  filtrosFormRef = React.createRef();

  renovacionFormRef = React.createRef();
  eliminarContratoFormRef = React.createRef();
  finiquitarFormRef = React.createRef();
  nuevoContratoFormRef = React.createRef();

  // editarContratoFormRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      idEmpresa: null,
      usuarioLogeado: null,
      trabajadores: [],
      cargos: [],
      titulos: [],

      categoriasTrabajadores: [],
      todosLosTrabajadores: [],

      trabajadorSeleccionado: null,
      contratosTrabajador: null,
      contratoActualTrabajador: null,

      modalDetalle: false,
      modalFichaTrabajador: false,
      modalEditarContrato: false,
      modalVisualizarFicha: false,

      fichaVisualizar: null,

      estadoSeleccionado: null,
      tipoFechaContratoRenovar: undefined,
      tipoFechaContratoNuevo: null,
      tipoFechaEditarContrato: null,

      accionesBloqueadas: null,

      regiones: [],
      provincias: [],
      comunas: [],

      editoFichaRenovar: false,
      editoFichaNuevo: false,

      fichaEditadaRenovar: {},
      fichaEditadaNuevo: {},

      formularioOrigen: null,

      contratoSeleccionado: null,

      estadosContractualesSeleccionados: [],
      estadosContractuales: [
        "Con contrato cercano a terminar",
        "Finiquitados",
        "Con contrato terminado",
        "Contrato que aún no ha comenzado",
        "Con contrato en curso",
      ],
      causalesFiniquito: [
        { codigo: '161-1', nombre: 'Necesidades de la empresa' },
        { codigo: '161-2', nombre: 'Desahucio' },

        { codigo: '160-1', nombre: 'Falta de probidad, vías de hecho, injurias o conducta grave' },
        { codigo: '160-2', nombre: 'Negociaciones del trabajador dentro del giro del negocio.' },
        { codigo: '160-3', nombre: 'No concurrencia a labores sin causa justificada.' },
        { codigo: '160-4', nombre: 'Abandono del trabajo por parte del trabajador.' },
        { codigo: '160-5', nombre: 'Actos que afectan a la seguridad' },
        { codigo: '160-6', nombre: 'Perjuicio material causado intencionalmente.' },
        { codigo: '160-7', nombre: 'Incumplimiento grave de las obligaciones.' },


        { codigo: '159-1', nombre: 'Mutuo acuerdo' },
        { codigo: '159-2', nombre: 'Renuncia del trabajador' },
        { codigo: '159-3', nombre: 'Muerte del trabajador' },
        { codigo: '159-4', nombre: 'Vencimiento plazo convenido (contratos plazo fijo)' },
        { codigo: '159-5', nombre: 'Conclusión del trabajo que dio origen al contrato (obra o faena)' },
        { codigo: '159-6', nombre: 'Caso fortuito o fuerza mayor' },
      ],

      primeraBusqueda: true,
      busquedaTrabajador: null,
      /////////////////////////////
      textoValidacionFiniquito:"Aun no realizada la busqueda",
      validacionFiniquito: true,
      PuedeFiniquitar: false,
      arrayPlanificacionesFiniquito: [],
      busquedaCorrecta: false,

      //borrar contratos
      borrarContrato: false,
      idTrabajadorBorrar: null,
      idContratatoBorrar: null,
      datosEliminacion: null,
      puedeBorrar:false,
      mostrarTextoEliminacion:'',
      textoEliminacion:'',
      alertaEliminacion: ''

    };
  }

  componentWillMount = async () => {
    const accesos = await accesosPagina.obtenerAccesosPagina("Contratos");

    // if (!accesos.Ver) window.location.pathname = '/';

    this.setState({
      Ver: !accesos.Ver,
      Editar: !accesos.Editar,
      Eliminar: !accesos.Eliminar,
    });
  };

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");

    if (usuario) {

      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      this.setState({ idEmpresa });

      const cargos = await obtenerCargos(idEmpresa);
      const regimenes = await obtenerRegimenes(idEmpresa);
      const epps = await obtenerEpps(idEmpresa);
      const documentos = await obtenerDocumentos(idEmpresa);
      const titulos = await obtenerTitulos(idEmpresa);
      const especialidades = await obtenerEspecialidades(idEmpresa);

      const regiones = await obtenerRegiones(idEmpresa);
      const provincias = await obtenerProvincias(idEmpresa);
      const comunas = await obtenerComunas(idEmpresa);

      // primera búsqueda
      await this.filtrarTrabajadores();

      // const accesos = await accesosPagina.obtenerAccesosPagina('Contratos');

      this.setState({
        loading: false,

        cargos,
        regimenes,
        epps,
        documentos,
        titulos,
        especialidades,
        usuarioLogeado: usuario,
        regiones,
        provincias,
        comunas,
        textoValidacionFiniquito: "Aun no realizada la busqueda",
        validacionFiniquito: true,
        PuedeFiniquitar: false,

        // Ver: accesos.Ver,
        // Editar: accesos.Editar,
        // Eliminar: accesos.Eliminar,

      });
    }
  }

  buscarTrabajadores = async (idEmpresa, objQuery = {}) => {

    const trabajadores = await obtenerTrabajadores(idEmpresa, objQuery);
    // NO DESCOMENTAR FUNCIÓN: CREA MASIVAMENTE DOCUMENTOS CONTRATO A TRABAJADORES,
    // SE UTILIZARÁ SÓLO UNA VEZ
    // await masivoContratos(trabajadores, idEmpresa);

    return trabajadores;
  }

  filtrarTrabajadores = async (values = {}) => {

    const { primeraBusqueda } = this.state;

    this.setState({ loading: true });

    const { idEmpresa } = this.state;

    let objQuery = {};

    if (values.cargo) objQuery.Cargo = values.cargo;
    if (values.titulos) objQuery.Titulo = values.titulos;
    if (values.tipoContrato) objQuery.Tipo_Contrato = values.tipoContrato;

    const categoriasTrabajadores = await this.buscarTrabajadores(idEmpresa, objQuery);

    let stateObj = {
      loading: false,
      categoriasTrabajadores,
      estadosContractualesSeleccionados: values.estadosContractuales ? values.estadosContractuales : [],
      busquedaTrabajador: null,
    }

    if (primeraBusqueda) {

      let todosLosTrabajadores = [];

      categoriasTrabajadores.forEach(categoria => {
        todosLosTrabajadores = [...todosLosTrabajadores, ...categoria.listaTrabajadores];
      });

      stateObj.todosLosTrabajadores = todosLosTrabajadores;
      stateObj.primeraBusqueda = false;
    }

    this.setState(stateObj);

    return categoriasTrabajadores;
  }

  categorizarTrabajadores = (trabajadores) => {

    // estados posibles del trabajador
    // considerar que si no trae los datos de contrato (Fecha_Inicio, etc) no entrará en ninguna de las listas
    const finiquitados = [];
    const terminados = [];
    const porTerminar = [];
    const enCurso = [];
    const noHaComenzado = [];

    // lógica para categorizar
    trabajadores.forEach((trabajador) => {

      let { Tipo_Contrato, Contrato_Fecha_Inicio, Contrato_Fecha_Inicio_Unix,
        Contrato_Fecha_Fin, Contrato_Fecha_Fin_Unix, Finiquitado } = trabajador;

      // si no hay alguno de los datos obligatlorios, no se considerará al trabajador
      if (!Tipo_Contrato || !Contrato_Fecha_Inicio || !Contrato_Fecha_Inicio_Unix) return;

      let { Anexo_Fecha_Inicio_Unix } = trabajador.Contrato_Actual;

      let fechaActual = moment();
      let fechaInicioContrato;

      // si tiene renovado el contrato, tendrá Anexo_Fecha_Inicio, y se debe utilizar éste como fecha inicio,
      // si no, se categorizará como contrato noHaComenzado cuando se renueve el contrato para, por ejemplo el próximo mes.
      if (Anexo_Fecha_Inicio_Unix) {
        fechaInicioContrato = moment.unix(Anexo_Fecha_Inicio_Unix);

      } else {
        fechaInicioContrato = moment.unix(Contrato_Fecha_Inicio_Unix);
      }

      // 5 posibilidades: 
      let estaFiniquitado = false;
      let estaTerminado = false;
      let estaProntoATerminar = false;
      let estaEnCurso = false;
      let aunNoComienza = false;

      if (Finiquitado) {
        estaFiniquitado = true

      } else if (Contrato_Fecha_Fin_Unix) {

        // Es a plazo fijo, puede caer en alguno de los 2 primeros casos
        let fechaFinContrato = moment.unix(Contrato_Fecha_Fin_Unix);
        let ultimoMesContrato = fechaFinContrato.clone().subtract(1, 'month');

        if (fechaActual.isBetween(ultimoMesContrato, fechaFinContrato)) {
          estaProntoATerminar = true;

        } else if (fechaActual.isAfter(fechaFinContrato)) {
          estaTerminado = true;

        } else if (fechaActual.isBefore(fechaInicioContrato)) {
          aunNoComienza = true;

        } else {
          estaEnCurso = true;
        }

      } else {
        // Es indefinido

        if (fechaActual.isAfter(fechaInicioContrato)) {
          estaEnCurso = true;
        } else {
          aunNoComienza = true;
        }

      }

      if (estaFiniquitado) {
        finiquitados.push(trabajador);

      } else if (estaTerminado) {
        terminados.push(trabajador);

      } else if (estaProntoATerminar) {
        porTerminar.push(trabajador);

      } else if (estaEnCurso) {
        enCurso.push(trabajador);

      } else if (aunNoComienza) {
        noHaComenzado.push(trabajador);
      }
    })

    // ordenando como array para que sea más fácil de renderear
    const listaCategorias = [
      {
        titulo: "Con contrato cercano a terminar",
        listaTrabajadores: porTerminar,
        alertType: 'warning',
      },
      {
        titulo: "Finiquitados",
        listaTrabajadores: finiquitados,
        alertType: 'info'
      },
      {
        titulo: "Con contrato terminado",
        listaTrabajadores: terminados,
        alertType: 'info'
      },
      {
        titulo: "Contrato que aún no ha comenzado",
        listaTrabajadores: noHaComenzado,
        alertType: 'info'
      },
      {
        titulo: "Con contrato en curso",
        listaTrabajadores: enCurso,
        alertType: 'success'
      },
    ];

    return listaCategorias;
  }

  // ABRIR MODAL DE CONTRATOS
  seleccionarTrabajador = async (trabajador, estadoSeleccionado) => {

    this.setState({ loading: true });

    const idTrabajador = trabajador._id.toString();
    const tipoContrato = trabajador.Tipo_Contrato;
    const { idEmpresa } = this.state;

    const contratosTrabajador = await obtenerContratosTrabajador(idTrabajador, idEmpresa);
    const contratoActual = await obtenerContratoActualTrabajador(idTrabajador, idEmpresa);

    // según el estado del contrato del trabajador (en curso, finiquitado, terminado, etc)
    // se bloquearán algunas acciones (renovar contrato, nuevo contrato y/o finiquitar)
    let accionesBloqueadas = this.obtenerAccionesBloqueadas(estadoSeleccionado, tipoContrato);
    if(contratoActual.Contrato_Finiquitado){
      //condicional se agrego porque a se podia renovar un contrato siendo que estaba finiquitado (24-04-23)
      // if(contratoActual.Contrato_Finiquitado === true){
      //   accionesBloqueadas.renovarBloqueado = true;
      // }else{
      //   accionesBloqueadas.renovarBloqueado = false;
      // }

      //La "renovacion de contratos" se deja "habilitado" cuando el contrato anterior esta finiquitado.
      accionesBloqueadas.renovarBloqueado = false;
    }else{
      let str = moment().format('YYYY-MM-DD');
      const fechaHoy = convertirFechaString(moment(str),1).fechaString;

      if(!moment.utc(fechaHoy).isBefore(moment.utc(contratosTrabajador[0].Contrato_Fecha_Inicio))){
        if(contratosTrabajador[0].Tipo_Contrato === "Plazo fijo"){
          accionesBloqueadas.renovarBloqueado = false;
        }
      }
      //accionesBloqueadas.renovarBloqueado = false;
    }

    this.setState({
      trabajadorSeleccionado: trabajador,
      modalDetalle: true,
      estadoSeleccionado,
      contratosTrabajador,
      contratoActualTrabajador:contratoActual,
      accionesBloqueadas:accionesBloqueadas,
      loading: false,

      //acciones para el finiquito
      validacionFiniquito:false,
      PuedeFiniquitar: false,
      arrayPlanificacionesFiniquito: [],
      busquedaCorrecta: false,

      //no se puede borrar contrato
      borrarContrato:false

    })
  }

  // CERRAR MODAL DE CONTRATOS
  cerrarModal = () => {

    if(this.state.borrarContrato === true){
      this.setState({
        borrarContrato:false,
        datosEliminacion: null
      });
    }else{
      this.setState({
        trabajadorSeleccionado: null,
        modalDetalle: false,
        estadoSeleccionado: null,
        contratosTrabajador: null,
        accionesBloqueadas: null,
  
        // tipoFechaContratoRenovar: undefined,
        tipoFechaContratoRenovar: null,
        tipoFechaContratoNuevo: null,
  
        fichaEditadaRenovar: {},
        fichaEditadaNuevo: {},
  
        editoFichaRenovar: false,
        editoFichaNuevo: false,
  
        formularioOrigen: null,
        selectedTab: 'Contratos',
        borrarContrato: false,
        datosEliminacion: null
      });
    }
    
  }

  // ABRIR MODAL DE FICHA TRABAJADOR
  abrirModalFichaTrabajador = (formularioOrigen) => {

    // formularioOrigen puede ser desde formulario de renovar contrato o del formulario nuevo contrato
    // puede ser valor "renovar" o "nuevo"

    this.setState({
      modalFichaTrabajador: true,
      formularioOrigen,
    })
  }

  // CERRAR MODAL DE FICHA TRABAJADOR
  cerrarModalFichaTrabajador = () => {

    this.setState({
      modalFichaTrabajador: false,

      fichaEditadaRenovar: {},
      fichaEditadaNuevo: {},

      editoFichaRenovar: false,
      editoFichaNuevo: false,
    })
  }

  abrirModalEditarContrato = (contratoSeleccionado) => {
    this.setState({
      modalEditarContrato: true,
      contratoSeleccionado,
    })
  }

  cerrarModalEditarContrato = () => {
    this.setState({
      modalEditarContrato: false,
      contratoSeleccionado: null,
    })
  }

  abrirModalVisualizarFicha = (ficha) => {

    if (!ficha) return;

    this.setState({
      modalVisualizarFicha: true,
      fichaVisualizar: ficha,
    })
  }

  cerrarModalVisualizarFicha = () => {

    this.setState({
      modalVisualizarFicha: false,
      fichaVisualizar: null,
    })
  }

  obtenerAccionesBloqueadas = (estadoSeleccionado, tipoContrato) => {
    // función hecha para detectar cuáles tabs estarán bloquadas

    let renovarBloqueado = false;
    let nuevoContratoBloqueado = false;
    let finiquitarBloqueado = false;

    switch (estadoSeleccionado) {
      case "Con contrato cercano a terminar":
        nuevoContratoBloqueado = true;
        break;

      case "Finiquitados":
        finiquitarBloqueado = true;
        break;

      case "Con contrato terminado":
        break;

      case "Contrato que aún no ha comenzado":
        renovarBloqueado = true;
        nuevoContratoBloqueado = true;
        finiquitarBloqueado = true;
        break;

      case "Con contrato en curso":
        nuevoContratoBloqueado = true;
        if (tipoContrato === 'Indefinido') renovarBloqueado = true;
        //if (tipoContrato === 'Plazo fijo') renovarBloqueado = true;//
        break;

      default:
        console.error('ERROR AL DETECTAR VARIABLE estadoSeleccionado, revisar títulos de categorías.');
        break;
    }

    let accionesBloqueadas = {
      renovarBloqueado,
      nuevoContratoBloqueado,
      finiquitarBloqueado,
    };

    return accionesBloqueadas;
  }

  renderCategorias = (categoriasTrabajadores) => {

    const { estadosContractualesSeleccionados } = this.state;

    return categoriasTrabajadores.map((categoria, index) => {

      const { titulo, listaTrabajadores, alertType } = categoria;

      // FILTRO: si el filtro de estados no incluye la misma, no renderear
      if (estadosContractualesSeleccionados.length > 0) {
        if (!estadosContractualesSeleccionados.includes(titulo)) {
          return;
        }
      }
      let numeroRandomDos = Math.floor(Math.random() * 999999999);
      if (listaTrabajadores.length > 0) {
        return (
          <div key={index+numeroRandomDos} >

            <Divider orientation={'center'} style={{ fontWeight: '900' }}>
              <Alert
                message={<span>{titulo}</span>}
                type={alertType}
              // showIcon
              />
            </Divider>

            <Row gutter={20}>
              {listaTrabajadores.map((trabajador, index) => {
                const { busquedaTrabajador } = this.state;

                let { Nombre, Apellido_Paterno, Apellido_Materno, Cargo, Contrato_Fecha_Inicio,
                  Contrato_Fecha_Fin, Contrato_Fecha_Fin_Unix, Imagen, Tipo_Contrato } = trabajador;

                let nombreCompleto = `${Apellido_Paterno} ${Nombre}`.toUpperCase();
                let cargo = Cargo.Nombre;
                let inicioContratoString = moment.utc(Contrato_Fecha_Inicio).format('DD-MM-YYYY');
                let finContratoString;

                // if (Contrato_Fecha_Fin_Unix) finContratoString = moment.unix(Contrato_Fecha_Fin_Unix).format('DD-MM-YYYY');
                if (Contrato_Fecha_Fin_Unix) finContratoString = convertirFechaString(moment.utc(Contrato_Fecha_Fin), 2).fechaDDMMYYYY
                
                if (busquedaTrabajador) {
                  if (!nombreCompleto.includes(busquedaTrabajador.toUpperCase())) return;
                }


                let numeroRandom = Math.floor(Math.random() * 999999999);
                return (
                  <Col xs={24} sm={12} md={12} lg={8} xl={6} key={index+numeroRandom}>
                    <div className={'tarjeta-cont-trabajador'}>

                      <Row gutter={20} align={'top'} style={{ height: '100%' }}>

                        <Col span={24} className={{ height: 'fit-content', padding: '0px' }}>
                          <Row gutter={20} >

                            <Col span={8}>
                              <div className={'img-perfil-cont-wrapper'}>
                                <img
                                  className={'img-perfil-cont'}
                                  src={Imagen}
                                  alt='ImagenPerfilTrabajador'
                                />
                              </div>

                            </Col>

                            <Col span={16} className={'trab-contrato-info-col'}>

                              <p className={'nombre-completo-trab'} style={{ lineHeight: '18px' }}>{nombreCompleto}</p>
                              <p style={{ fontSize: 'smaller', fontWeight: '800', lineHeight: '18px' }}>{cargo}</p>

                              <div className={'fechas-contrato-div'}>

                                <p>Contrato {Tipo_Contrato}</p>
                                Desde {inicioContratoString}
                                {/* <span> - </span> */}
                                {
                                  Tipo_Contrato === 'Plazo fijo'
                                    ? <><br />Hasta {finContratoString}</>
                                    : ''
                                }
                              </div>

                              <div className={'ver-opciones-btn-wrapper'}>

                                <Button
                                  type={'primary'}
                                  className={'ver-opciones-cont-btn'}
                                  onClick={() => {
                                    this.seleccionarTrabajador(trabajador, titulo)
                                  }}
                                >
                                  Ver opciones
                                </Button>

                              </div>
                            </Col>

                          </Row>
                        </Col>

                      </Row>

                    </div>
                  </Col>

                )
              })}

            </Row>

          </div>
        )
      }
    })
  }

  redirectACalendario = (idTrabajador) => {
    window.open(`/CalendarioTrabajadorNew?Trabajador=${idTrabajador}`);
  }

  condicionalFechaContrato = (tipoFechaContrato = 'Indefinido', nombreFormulario) => {

    // variable disabled se utiliza para dejar disabled sólo primer datepicker en caso
    // de que sea RangePicker por contrato a plazo fijo
    let disabled = false;

    if (tipoFechaContrato === "Indefinido") {

      if (nombreFormulario === 'renovar') disabled = true;

      return (
        <DatePicker
          disabled={disabled}
          format="DD-MM-YYYY"
          locale={locale}
          className="campos-formulario-contratos"
          style={{
            width: "100%",
          }}
          disabledDate={this.disabledDateNuevoContrato}
        />
      );
    } else {

      if (nombreFormulario === 'renovar') disabled = [true, false];

      return (
        <RangePicker
          disabled={disabled}
          format="DD-MM-YYYY"
          locale={locale}
          className="campos-formulario-contratos"
          style={{
            width: "100%",
          }}
          disabledDate={this.disabledDateNuevoContrato}
        />
      );
    }
  };

  eliminarContrato = async (values) =>{
    try{
      this.setState({
        loading:true,
      });

      const { idTrabajadorBorrar , idContratatoBorrar , idEmpresa , usuarioLogeado } = this.state;

      const body = {
        ID_USUARIO_ELIMINACION: usuarioLogeado._id,
        MENSAJE: values.RazonEliminarContrato,
      }

      const respuesta = await eliminacionContratoCompleto(idTrabajadorBorrar,idContratatoBorrar,idEmpresa,body);

      if(respuesta.status === 200){

        const objNuevoTrabajador = await verEstadoTrabajador(idTrabajadorBorrar);

        const { trabajador: trabajadorActualizado, categoriaTrabajador: nuevaCategoriaTrabajador } = objNuevoTrabajador;

        //this.nuevoContratoFormRef.current.resetFields();
        this.setState({
          loading: false,
          // fichaEditadaNuevo: {},
          // editoFichaNuevo: false,
          // selectedTab: 'Contratos',
          //borrarContrato: false,
        });

        Swal.fire({
          title: "¡Bien!",
          text: "Se ha eliminado el contrato del trabajador.",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        }).then(async (result) => {
          await this.refreshContratos(trabajadorActualizado, nuevaCategoriaTrabajador);
        });
    
      
      }else{
        Swal.fire({
          title: "Error",
          text: "No se logro realizar la eliminacion del contrato",
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        });
      }
    }catch(error){
      console.log(error);
    }
  } 

  renovarContratoFinish = async (infoNuevoContrato) => {
  
    let cancelFlag = false;
  
    await Swal.fire({
      title: "Confirmar",
      text: `¿Estás seguro que quieres renovar el contrato del trabajador?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",

    }).then(async (result) => {

      if (!result.value) {
        cancelFlag = true;
      }
    })

    if (cancelFlag) {
      return;
    }

    this.setState({ loading: true });

    const { idEmpresa, trabajadorSeleccionado, editoFichaRenovar, fichaEditadaRenovar } = this.state;

    const { _id, Contrato_Actual } = trabajadorSeleccionado;

    //Si es contrato indefinido tendrá sólo fecha de inicio, al contrario fecha de inicio y fin.
    if (infoNuevoContrato.Tipo_Contrato === "Indefinido") {
      infoNuevoContrato.Contrato_Fecha_Inicio = infoNuevoContrato.Contrato_Fecha;
    } else {
      infoNuevoContrato.Contrato_Fecha_Inicio = infoNuevoContrato.Contrato_Fecha[0];
      infoNuevoContrato.Contrato_Fecha_Fin = infoNuevoContrato.Contrato_Fecha[1];
    }

    //transformación de fechas a Unix timestamp
    infoNuevoContrato.Contrato_Fecha_Inicio_Unix = momentToUnixFechaInicio(
      infoNuevoContrato.Contrato_Fecha_Inicio
    );
    infoNuevoContrato.Contrato_Fecha_Inicio = momentToStringFechaInicio(
      infoNuevoContrato.Contrato_Fecha_Inicio,
      databaseFormat
    );

    if (infoNuevoContrato.Contrato_Fecha_Fin) {

      infoNuevoContrato.Contrato_Fecha_Fin_Unix = momentToUnixFechaFin(
        infoNuevoContrato.Contrato_Fecha_Fin
      );

      infoNuevoContrato.Contrato_Fecha_Fin = momentToStringFechaFin(
        infoNuevoContrato.Contrato_Fecha_Fin,
        databaseFormat
      );
    }

    let anularFiniquito = false;

    // CREACIÓN DE NUEVO CONTRATO //

    let objContrato = {
      ...infoNuevoContrato,

      IdTrabajador: _id,
      Empresa: idEmpresa,
    }

    let idContratoAntiguo = Contrato_Actual._id.toString();

    // cuando el contrato pasa a indefinido ya no es anexo
    // if (infoNuevoContrato.Tipo_Contrato !== "Indefinido") {

      let { Contrato_Fecha_Inicio: inicioContratoAntiguo, Contrato_Fecha_Inicio_Unix: inicioContratoAntiguoUnix } = Contrato_Actual;

      objContrato.Es_Anexo_De = idContratoAntiguo;
      objContrato.Anexo_Fecha_Inicio = inicioContratoAntiguo;
      objContrato.Anexo_Fecha_Inicio_Unix = inicioContratoAntiguoUnix;

      // si el nuevo contrato es a plazo fijo y el actual está finiquitado, se entiende una anulación de finiquito del actual.
      if (Contrato_Actual.Contrato_Finiquitado) {

        let cancelFlag = false;

        this.setState({ loading: false });

        const swalObj = {
          title: "Confirmar",
          text: `Ya que el nuevo contrato será anexo del anterior, y dicho contrato anterior se encuentra finiquitado, se anulará el finiquito, ¿deseas continuar?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1FC600",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        };


        const result = await Swal.fire(swalObj);

        if (!result.value) {
          cancelFlag = true;
        }

        if (cancelFlag) {
          return;
        }

        // si se confirma, se anula el finiquito del contrato actual, si no, se cancela operación
        anularFiniquito = true;

        this.setState({ loading: true });
      }

    // }

    const nuevoContrato = await crearContratoTrabajador(objContrato);

    // GUARDAR FICHA ANTIGUA DEL TRABAJADOR EN CONTRATO ANTIGUO (y darle fecha de finiquito?) //

    let objActualizarContrato = {
      Ficha_Trabajador: trabajadorSeleccionado,
    }

    if (anularFiniquito) {

      const anularFiniquitoObj = {
        Contrato_Finiquitado: false,
        Fecha_Finiquito: null,
        Fecha_Finiquito_Unix: null,
        Causal_Finiquito: null,
        Observaciones_Finiquito: null,
      }

      objActualizarContrato = { ...objActualizarContrato, ...anularFiniquitoObj };
    }

    await actualizarContratoSoloContrato(objActualizarContrato, idContratoAntiguo);

    // ACTUALIZAR FICHA DEL TRABAJADOR CON NUEVA INFO DE CONTRATO Y FICHA //

    let objTrabajador = infoNuevoContrato;

    objTrabajador.Contrato_Actual = nuevoContrato._id.toString();
    objTrabajador.Finiquitado = false;

    if (editoFichaRenovar) {
      objTrabajador = { ...objTrabajador, ...fichaEditadaRenovar };
    }

    let idTrabajador = trabajadorSeleccionado._id;

    // la función de actualizarTrabajador trae de vuelta el trabajador actualizado y su nueva categoría
    const objNuevoTrabajador = await actualizarTrabajador(objTrabajador, idTrabajador);

    const { trabajador: trabajadorActualizado, categoriaTrabajador: nuevaCategoriaTrabajador } = objNuevoTrabajador;

    await this.refreshContratos(trabajadorActualizado, nuevaCategoriaTrabajador);

    Swal.fire({
      title: "¡Bien!",
      text: "Se ha renovado el contrato del trabajador.",
      icon: "success",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Ok",
    });

    this.renovacionFormRef.current.resetFields();

    this.setState({
      loading: false,
      fichaEditadaRenovar: {},
      editoFichaRenovar: false,
      selectedTab: 'Contratos',
    });
  }

  nuevoContratoFinish = async (infoNuevoContrato) => {

    let cancelFlag = false;

    await Swal.fire({
      title: "Confirmar",
      text: `¿Estás seguro que quieres generar un nuevo contrato contrato al trabajador?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",

    }).then(async (result) => {

      if (!result.value) {
        cancelFlag = true;
      }
    })

    if (cancelFlag) {
      return;
    }

    this.setState({ loading: true });

    const { idEmpresa, trabajadorSeleccionado, editoFichaNuevo, fichaEditadaNuevo } = this.state;

    const { _id, Contrato_Actual } = trabajadorSeleccionado;

    //Si es contrato indefinido tendrá sólo fecha de inicio, al contrario fecha de inicio y fin.
    if (infoNuevoContrato.Tipo_Contrato === "Indefinido") {
      infoNuevoContrato.Contrato_Fecha_Inicio = infoNuevoContrato.Contrato_Fecha;
    } else {
      infoNuevoContrato.Contrato_Fecha_Inicio = infoNuevoContrato.Contrato_Fecha[0];
      infoNuevoContrato.Contrato_Fecha_Fin = infoNuevoContrato.Contrato_Fecha[1];
    }

    //transformación de fechas a Unix timestamp
    infoNuevoContrato.Contrato_Fecha_Inicio_Unix = momentToUnixFechaInicio(
      infoNuevoContrato.Contrato_Fecha_Inicio
    );
    infoNuevoContrato.Contrato_Fecha_Inicio = momentToStringFechaInicio(
      infoNuevoContrato.Contrato_Fecha_Inicio,
      databaseFormat
    );

    if (infoNuevoContrato.Contrato_Fecha_Fin) {
      infoNuevoContrato.Contrato_Fecha_Fin_Unix = momentToUnixFechaFin(
        infoNuevoContrato.Contrato_Fecha_Fin
      );
      infoNuevoContrato.Contrato_Fecha_Fin = momentToStringFechaFin(
        infoNuevoContrato.Contrato_Fecha_Fin,
        databaseFormat
      );
    }

    // CREACIÓN DE NUEVO CONTRATO //

    let objContrato = {
      ...infoNuevoContrato,

      IdTrabajador: _id,
      Empresa: idEmpresa,
    }

    let idContratoAntiguo = Contrato_Actual._id.toString();
    
    const nuevoContrato = await crearContratoTrabajador(objContrato);
    
    // GUARDAR FICHA ANTIGUA DEL TRABAJADOR EN CONTRATO ANTIGUO (y darle fecha de finiquito?) //

    // let objActualizarContrato = {
    //   Ficha_Trabajador: trabajadorSeleccionado,
    // }

    // await actualizarContrato(objActualizarContrato, idContratoAntiguo);

    // ACTUALIZAR FICHA DEL TRABAJADOR CON NUEVA INFO DE CONTRATO Y FICHA //

    let objTrabajador = infoNuevoContrato;

    objTrabajador.Contrato_Actual = nuevoContrato._id.toString();
    objTrabajador.Finiquitado = false;

    if (editoFichaNuevo) {
      objTrabajador = { ...objTrabajador, ...fichaEditadaNuevo };
    }

    let idTrabajador = trabajadorSeleccionado._id;

    const objNuevoTrabajador = await actualizarTrabajador(objTrabajador, idTrabajador);

    const { trabajador: trabajadorActualizado, categoriaTrabajador: nuevaCategoriaTrabajador } = objNuevoTrabajador;

    await this.refreshContratos(trabajadorActualizado, nuevaCategoriaTrabajador);

    Swal.fire({
      title: "¡Bien!",
      text: "Se ha registrado un nuevo contrato para el trabajador.",
      icon: "success",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Ok",
    });

    this.nuevoContratoFormRef.current.resetFields();
    this.setState({
      loading: false,
      fichaEditadaNuevo: {},
      editoFichaNuevo: false,
      selectedTab: 'Contratos',
    });
  }

  finiquitarFinish = async (infoFiniquito) => {

    let cancelFlag = false;

  
    await Swal.fire({
      title: "Confirmar",
      text: `¿Estás seguro que quieres finiquitar al trabajador?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",

    }).then(async (result) => {

      if (!result.value) {
        cancelFlag = true;
      }
    })

    if (cancelFlag) {
      return;
    }

    this.setState({ loading: true });

    const { Fecha_Finiquito, Causal_Finiquito, Se_Puede_Recontratar, Observaciones_Finiquito } = infoFiniquito;
    const { trabajadorSeleccionado , busquedaCorrecta, usuarioLogeado ,PuedeFiniquitar} = this.state;
    const { Contrato_Actual, _id: idTrabajador } = trabajadorSeleccionado;
    const fechaFiniquitoUnix = momentToUnixFechaInicio(Fecha_Finiquito);


    if(!PuedeFiniquitar){
      this.setState({ loading: false });
      Swal.fire({
        title: "¡Ups!",
        text: "No se puede realizar un finiquito ya que se muestra una alerta por fechas",
        icon: "error",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Ok",
      });
      return;
    }

    if(!busquedaCorrecta){
      this.setState({ loading: false });
      Swal.fire({
        title: "¡Ups!",
        text: "Al parecer no se busco bien si se puede generar el finiquito, favor intentar seleccionar nuevamente la fecha de finiquito",
        icon: "error",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "Ok",
      });
      return;
    }

    // VALIDACIÓN A PREGUNTAR: PUEDE INTERFERIR CON CREACIÓN DE NUEVOS CONTRATOS
    // Revisando si tiene planificación después de la fecha de finiquito, en ese caso no hacer el finiquito
    // const tienePlanificacion = await revisarTienePlanificacion(fechaFiniquitoUnix, idTrabajador);

    // console.log('tienePlanificacion', tienePlanificacion)

    // if (tienePlanificacion) {

    //   this.setState({ loading: false });

    //   Swal.fire({
    //     title: "¡Ups!",
    //     text: "No se puede finiquitar a este trabajador porque ya tiene planificaciones después de la fecha de finiquito seleccionada.",
    //     icon: "error",
    //     confirmButtonColor: "#E60AA3",
    //     confirmButtonText: "Ok",
    //   });

    //   return;
    // }

    const idContratoFiniquitar = Contrato_Actual._id.toString();

    const fechaFiniquito = momentToStringFechaInicio(Fecha_Finiquito.clone(), databaseFormat);
    const fechaFinFiniquito = convertirFechaString(Fecha_Finiquito.clone(),2).fechaString;
    const fechaFinFiniquitoUnix = convertirFechaString(Fecha_Finiquito.clone(),2).fechaUNIX;

    const fechaEliminacionFiniquito = convertirFechaString(Fecha_Finiquito.clone().add(1, 'day'),1).fechaString;

    const objActualizarContrato = {
      Contrato_Finiquitado: true,
      Fecha_Finiquito: fechaFiniquito,
      Fecha_Finiquito_Unix: fechaFiniquitoUnix,
      Causal_Finiquito,
      Observaciones_Finiquito,
      fechaEliminacionFiniquito,
      fechaFinFiniquito,
      fechaFinFiniquitoUnix,
      Ficha_Trabajador: trabajadorSeleccionado,

      nombreUsuarioFiniquitador: usuarioLogeado.Nombres,
      idUsuarioFiniquitador: usuarioLogeado._id,
    }

    await actualizarContrato(objActualizarContrato, idContratoFiniquitar);

    const objActualizarTrabajador = {
      Se_Puede_Recontratar,
      Finiquitado: true,
      Fecha_Finiquito: fechaFiniquito,
      Fecha_Finiquito_Unix: fechaFiniquitoUnix,
      Imagen: trabajadorSeleccionado.Imagen,
    }

    const objNuevoTrabajador = await actualizarTrabajador(objActualizarTrabajador, idTrabajador);

    const { trabajador: trabajadorActualizado, categoriaTrabajador: nuevaCategoriaTrabajador } = objNuevoTrabajador;

    await this.refreshContratos(trabajadorActualizado, nuevaCategoriaTrabajador);

    Swal.fire({
      title: "¡Bien!",
      text: "Se ha finiquitado exitosamente al trabajador.",
      icon: "success",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Ok",
    });

    this.finiquitarFormRef.current.resetFields();
    this.setState({
      loading: false,
      selectedTab: 'Contratos',
    });
  }


  buscarDatosFiniquito = async (fechaFiniquito) => {
    try{
      this.setState({
        loading:true
      });
      const { trabajadorSeleccionado } = this.state;
      const { _id: idTrabajador } = trabajadorSeleccionado;

      const fechaFinFiniquito = convertirFechaString(fechaFiniquito.clone(),2).fechaString;
      const planificacionesCortar = await finiquitarTrabajadorPlanificacionesAcortar(idTrabajador,fechaFinFiniquito);

      if(planificacionesCortar.status !== 200){
        this.setState({ loading: false,busquedaCorrecta: false ,PuedeFiniquitar: false});
        Swal.fire({
          title: "¡Ups!",
          text: "Error al realizar petición para identificar corte de planificaciones. Status: "+planificacionesCortar.status,
          icon: "error",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        });
        
        return;
      }else{
        const { PuedeFiniquitar , respuesta} = planificacionesCortar.data;

        let texto = PuedeFiniquitar === true ? `Se puede finiquitar al trabajador, Se eliminaran o editaran ${respuesta.length} planificaciones` : 'No se puede finiquitar por la planificacion marcada en rojo';
        this.setState({ 
          loading: false,
          textoValidacionFiniquito: texto, 
          validacionFiniquito:respuesta.length === 0 ? true : false,
          arrayPlanificacionesFiniquito: respuesta,
          busquedaCorrecta: true,
          PuedeFiniquitar: PuedeFiniquitar,
        });
        return;
      }
    }catch(error){
      this.setState({ loading: false });
      console.log(error);
    }
  }

  guardarNuevaFicha = async (trabajador) => {
    // función de guardar trabajador ya editado, nueva ficha

    const { formularioOrigen } = this.state;

    let stateObj = {
      modalFichaTrabajador: false,
      formularioOrigen: null,
    };

    if (formularioOrigen === 'renovar') {
      stateObj.fichaEditadaRenovar = trabajador;
      stateObj.editoFichaRenovar = true;
    }

    if (formularioOrigen === 'nuevo') {
      stateObj.fichaEditadaNuevo = trabajador;
      stateObj.editoFichaNuevo = true;
    }

    this.setState(stateObj);
  };

  borrarContrato = async (idTrabajador,idContrato) => {

    this.setState({
      loading:true
    });

    const datosContrato = await validarEliminacionContrato(idTrabajador,idContrato,this.state.idEmpresa);

    const {  mensaje , eliminacion } = datosContrato.data;
    let  textoEliminacion , alertaEliminacion;

    textoEliminacion = mensaje;
    alertaEliminacion = eliminacion === true ? "success" : "warning";
    
    this.setState({
      loading:false,
      borrarContrato: true,
      datosEliminacion: datosContrato.data,
      idTrabajadorBorrar: idTrabajador,
      idContratatoBorrar: idContrato,
      puedeBorrar: (eliminacion === true ? true : false),
      mostrarTextoEliminacion: true,
      textoEliminacion: mensaje,
      alertaEliminacion: alertaEliminacion
    });
    
  }

  contenidoModal = (trabajador) => {

    let { estadoSeleccionado, contratosTrabajador, accionesBloqueadas, editoFichaRenovar,
      editoFichaNuevo, causalesFiniquito, selectedTab, Editar , contratoActualTrabajador } = this.state;

    const { renovarBloqueado, finiquitarBloqueado, nuevoContratoBloqueado } = accionesBloqueadas;

    let { Nombre, Apellido_Paterno, Apellido_Materno, Cargo,
      Contrato_Fecha_Inicio, Contrato_Fecha_Fin, Contrato_Fecha_Fin_Unix, Imagen, Tipo_Contrato,
      Rut, Se_Puede_Recontratar, Contrato_Actual,_id:ID_TRABAJADOR } = trabajador;

    let { Observaciones_Finiquito } = Contrato_Actual;

    let nombreCompleto = `${Apellido_Paterno} ${Apellido_Materno} ${Nombre}`.toUpperCase();
    let cargo = Cargo.Nombre;

    //info contrato actual
    let inicioContratoString = moment.utc(Contrato_Fecha_Inicio).format('DD-MM-YYYY');
    let finContratoString;

    let fechaRenovacion;

    //console.log(Contrato_Fecha_Fin_Unix);
    // if (Contrato_Fecha_Fin_Unix) {
    //   finContratoString = moment.utc(Contrato_Fecha_Fin_Unix).format('DD-MM-YYYY')
    //   fechaRenovacion = moment.utc(Contrato_Fecha_Fin_Unix).add(1, 'day');
    // };
    if(contratoActualTrabajador.Contrato_Fecha_Fin){
        finContratoString = moment.utc(contratoActualTrabajador.Contrato_Fecha_Fin).format('DD-MM-YYYY')
        fechaRenovacion = moment.utc(contratoActualTrabajador.Contrato_Fecha_Fin).add(1, 'day');
    }
    
    if(contratoActualTrabajador.Fecha_Finiquito){
      finContratoString = moment.utc(contratoActualTrabajador.Fecha_Finiquito).format('DD-MM-YYYY')
      fechaRenovacion = moment.utc(contratoActualTrabajador.Fecha_Finiquito).add(1, 'day');
    }

    // layout y tailLayout son configuaraciones de cols para el formulario y su responsividad
    const layout = {
      labelCol: {
        xs: 24,
        sm: 24,
        md: 10,
        lg: 8,
        xl: 6,
      },
      wrapperCol: {
        xs: 24,
        sm: 24,
        md: 12,
        lg: 14,
        xl: 16,
      },
    };

    const tailLayout = {
      wrapperCol: {
        xs: {
          offset: 0,
          span: 24,
        },
        sm: {
          offset: 0,
          span: 24,
        },
        md: {
          offset: 10,
          span: 12,
        },
        lg: {
          offset: 8,
          span: 14,
        },
        xl: {
          offset: 6,
          span: 16,
        },
      },
    };

    return (
      <div>

        {/* HEADER */}
        <div className={'modal-header-wrapper'}>

          <PageHeader
            className="site-page-header"
            onBack={() => {
              this.cerrarModal();
            }}
            title="Contratos del trabajador"
            subTitle={<span>Detalle y opciones sobre el contrato del trabajador</span>}
            backIcon={<LeftCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={'#0141d074'} />}
          />

        </div>

        {/* CONTENIDO */}
        <Row
          gutter={20}
          style={{ marginLeft: '12px', marginRight: '12px' }}
        >

          {/* CARD TRABAJADOR */}
          <Col xs={24} sm={24} md={8} lg={8} xl={8}
            className={'pl-0-mobile'}
          >

            <div className={'card-desv-trabajador'}>

              <div style={{ width: '100%', position: 'relative' }}>

                <div className={'img-desv-container'}>

                  <div className={'trab-img-desv-wrapp'}>
                    <img
                      src={Imagen}
                      alt={'imagen trabajador'}
                      className={'trab-img-desv'}
                    />

                    <Button
                      onClick={() => {
                        this.redirectACalendario(trabajador._id.toString());
                      }}
                      icon={<EyeFilled style={{ fontSize: '20px' }} />}
                      className="detalle-trab-btn"
                      style={{
                        position: 'absolute',
                        left: '0px',
                        bottom: '0px',
                      }}
                    />
                  </div>

                </div>

              </div>

              <Row align={'middle'} className={'trab-desv-row'}>

                <Col
                  xs={24} sm={24} md={24} lg={24} xl={24}
                  className={'desv-trab-info-col'}
                >

                  <p className={'trab-info-title'}>Nombre</p>
                  <p className={'trab-info-parr'}>{nombreCompleto}</p>

                  <p className={'trab-info-title'}>RUT</p>
                  <p className={'trab-info-parr'}>{Rut}</p>

                  <p className={'trab-info-title'}>Cargo</p>
                  <p className={'trab-info-parr'}>{cargo}</p>

                  <p className={'trab-info-title'}>Tipo Contrato</p>
                  <p className={'trab-info-parr'}>{Tipo_Contrato}</p>

                  <p className={'trab-info-title'}>Situación Contractual</p>
                  <p className={'trab-info-parr'}>{estadoSeleccionado}</p>
                </Col>

              </Row>

            </div>

          </Col>

          {/* TABS */}


          <Col xs={24} sm={24} md={16} lg={16} xl={16}
            className={'pr-0-mobile'}
            style={{ marginBottom: '15px' }}
          >
            {this.state.borrarContrato === true 
            
            ? 
            <>
              <Form
                {...layout}
                name="eliminarContrato"
                onFinish={this.eliminarContrato}
                ref={this.eliminarContratoFormRef}
              >
                <Row>
                  <Col
                    xs={{span:24, offset:0}}
                    sm={{span:24, offset:0}}
                    md={{span:12, offset:10}}
                    lg={{span:14, offset:8}}
                    xl={{span:16, offset:6}}
                    style={{marginBottom:10}}
                  >
                    {
                      this.state.mostrarTextoEliminacion === true ? 
                      <>
                      <Alert
                        message={
                          <>
                            <span>{this.state.textoEliminacion}</span>
                          </>
                        }
                        type={this.state.alertaEliminacion}
                        showIcon
                        style={{ marginTop: '10px' }}
                      />
                      </>
                    :
                      <>
                      </>
                    }
                    
                  </Col>
                </Row>
                

                {/* SECCIÓN FORMULARIO */}
                <Form.Item
                  name={"RazonEliminarContrato"}
                  label={<p style={{ margin: "0px" }}>Razon eliminar contrato</p>}
                  rules={[
                    {
                      required: true,
                      message: 'Debe ingresar la razón por la que se eliminara el contrato'
                    },
                    {
                      min:10,
                      message: 'Se deben ingresar mínimo 10 caracteres'
                    }
                  ]}
                >
                   <TextArea
                      rows={4}
                      className={'campos-formulario-contratos'}
                      maxLength={1000}
                      showCount
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      placeholder={'Ingresar mínimo 10 caracteres'}
                      disabled={this.state.puedeBorrar === true ? false: true}
                    />
                </Form.Item>
                <Form.Item {...tailLayout}>
                   <Button
                      htmlType="submit"
                      type="primary"
                      className={'formulario-contratos-modificar-ficha'}
                      disabled={this.state.puedeBorrar === true ? false: true}
                    >
                      Eliminar Contrato <DiffOutlined  style={{ color: 'white' }} />
                    </Button>
                </Form.Item>
              </Form>
            </>
            
            :
            
            <Tabs
              onChange={(val) => {
                this.onTabsChange(val, Se_Puede_Recontratar, Observaciones_Finiquito);
              }}
              activeKey={selectedTab}
              type={'card'}
              style={{ marginLeft: '12px', marginRight: '12px', fontFamily: 'MavenPro-Regular' }}
            >

              {/* TAB CONTRATOS */}
              <TabPane
                tab={<span><FileProtectOutlined style={{ color: '#75d154' }} />Contratos</span>}
                key="Contratos"
              >
                <Divider orientation={'left'}>Registro de contratos del trabajador</Divider>

                {contratosTrabajador.map((contrato, index) => {

                  let { Contrato_Fecha_Inicio, Contrato_Fecha_Fin, Contrato_Fecha_Fin_Unix, Tipo_Contrato,
                    Costo_Bruto_Diario, Costo_Liquido_Diario, Es_Anexo_De, Contrato_Finiquitado,
                    Fecha_Finiquito, Fecha_Finiquito_Unix, Causal_Finiquito, Observaciones_Finiquito,
                    Ficha_Trabajador,_id:ID_CONTRATO, Relevo } = contrato;

                  let inicioContratoAnexo;
                  let finContratoAnexo;
                  
                  if (Es_Anexo_De) {
                    inicioContratoAnexo = moment.unix(Es_Anexo_De.Contrato_Fecha_Inicio_Unix).format('DD-MM-YYYY');
                    //finContratoAnexo = moment.unix(Es_Anexo_De.Contrato_Fecha_Fin_Unix).format('DD-MM-YYYY');
                    finContratoAnexo = convertirFechaString(moment.utc(Es_Anexo_De.Contrato_Fecha_Fin),2).fechaDDMMYYYY;
                  }

                  if (!Costo_Bruto_Diario) Costo_Bruto_Diario = 'No especificado';
                  if (!Costo_Liquido_Diario) Costo_Liquido_Diario = 'No especificado';

                  let fechaFiniquitoString;

                  let inicioContratoString = moment.utc(Contrato_Fecha_Inicio).format('DD-MM-YYYY');

                  if (Contrato_Finiquitado) {
                    fechaFiniquitoString = moment.utc(Fecha_Finiquito).format('DD-MM-YYYY');
                  }

                  let finContratoString;
                  //con unix le suma un dias(NO ES CORRECTO EL RESULTADO)
                  // if (Contrato_Fecha_Fin_Unix) finContratoString = moment.unix(Contrato_Fecha_Fin_Unix).format('DD-MM-YYYY');
                  if (Contrato_Fecha_Fin_Unix) finContratoString = convertirFechaString(moment.utc(Contrato_Fecha_Fin), 2).fechaDDMMYYYY;
                  let numeroRandom = Math.floor(Math.random() * 999999999);
                  return (
                    <div className={'contrato-div-container'} key={index+numeroRandom}>

                      <Row gutter={10} align={'middle'} style={{ height: '100%' }}>

                        {/* INFO CONTRATO */}
                        <Col xs={24} sm={24} md={24} lg={15} xl={16}>
                          <div style={{ fontWeight: '600' }}>

                            <span >
                              <FileProtectOutlined style={{ color: '#555555', fontSize: '20px', display: 'inline-block', marginRight: '10px' }} />
                              Contrato {inicioContratoString} {finContratoString ? ` - ${finContratoString}` : ''}
                            </span>

                            <span style={{ color: '#061fcb' }}>
                              {Contrato_Finiquitado ? ' (Contrato Finiquitado)' : ''}
                            </span>

                          </div>

                          <br/>

                          <Row>
                            <Col span={10}>
                              <span style={{ fontWeight: '600' }}>Tipo De Contrato:</span>
                            </Col>
                            <Col span={14}>
                              <span>{Tipo_Contrato}</span>
                            </Col>
                          </Row>
                          {
                            Relevo?
                              <Row>
                                <Col span={10}>
                                  <span style={{ fontWeight: '600' }}>Contrato de Relevo:</span>
                                </Col>
                                <Col span={14}>
                                  <span>{Relevo === true? 'Si':'No'}</span>
                                </Col>
                              </Row>
                            :
                            <></>
                          }
                
                          <Row>
                            <Col span={10}>
                              <span style={{ fontWeight: '600' }}>Costo Bruto Diario:</span>
                            </Col>
                            <Col span={14}>
                              <span>{Costo_Bruto_Diario}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={10}>
                              <span style={{ fontWeight: '600' }}>Costo Líquido Diario</span>
                            </Col>
                            <Col span={14}>
                              <span>{Costo_Liquido_Diario}</span>
                            </Col>
                          </Row>

                          {Es_Anexo_De
                            ?
                            <>
                              <Row>
                                <Col span={10}>
                                  <span style={{ fontWeight: '600' }}>Es Anexo De:</span>
                                </Col>
                                <Col span={14}>
                                  <span>contrato del {inicioContratoAnexo} al {finContratoAnexo}</span>
                                </Col>
                              </Row>
                            </>
                            : <></>}

                          {Contrato_Finiquitado
                            ?
                            <>

                              <Row>
                                <Col span={10}>
                                  <span style={{ fontWeight: '600' }}>Fecha Finiquito:</span>
                                </Col>
                                <Col span={14}>
                                  <span>{fechaFiniquitoString}</span>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={10}>
                                  <span style={{ fontWeight: '600' }}>Causal Finiquito:</span>
                                </Col>
                                <Col span={14}>
                                  <span>{Causal_Finiquito}</span>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={10}>
                                  <span style={{ fontWeight: '600' }}>Recomendado:</span>
                                </Col>
                                <Col span={14}>
                                  <span>{Se_Puede_Recontratar = Se_Puede_Recontratar === false ? 'No' : 'Si'}</span>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={10}>
                                  <span style={{ fontWeight: '600' }}>Observación Finiquito:</span>
                                </Col>
                                <Col span={14}>
                                  <span>{Observaciones_Finiquito = Observaciones_Finiquito ? Observaciones_Finiquito : 'No tiene observaciones'}</span>
                                </Col>
                              </Row>

                            </>
                            :
                            <></>
                          }

                        </Col>

                        {/* OPCIONES */}
                        <Col xs={24} sm={24} md={24} lg={9} xl={8}
                          style={{ height: 'fit-content' }}
                        >

                          <p style={{ marginBottom: '6px', fontWeight: '900' }}>Opciones</p>

                          {
                            Ficha_Trabajador
                              ?
                              <Button
                                type="primary"
                                className={'opciones-contrato-btn'}
                                onClick={() => {

                                  const fichaTrabajador = contrato.Ficha_Trabajador;

                                  this.abrirModalVisualizarFicha(fichaTrabajador);
                                }}
                              >
                                <FileTextFilled /> Ver ficha de contrato
                              </Button>
                              : <></>
                          }

                          {
                            index === 0
                              ?
                              <Button
                                type="primary"
                                className={'opciones-contrato-btn'}
                                onClick={() => {
                                  this.abrirModalEditarContrato(contrato);
                                }}
                                disabled={Editar}
                              >
                                <EditFilled /> Editar contrato
                              </Button>
                              : <></>
                          }

                          <Button
                            type="primary"
                            className={'opciones-contrato-btn-eliminar'}
                            onClick={()=>{this.borrarContrato(ID_TRABAJADOR,ID_CONTRATO)}} 
                          >
                            <DeleteFilled /> Eliminar Contrato
                          </Button>

                        </Col>

                      </Row>

                    </div>
                  )
                })}
              </TabPane>

              {/* TAB RENOVAR CONTRATO */}
              <TabPane
                tab={<span><RetweetOutlined style={{ color: '#75d154' }} />Renovar Contrato</span>}
                key="Renovar Contrato"
                disabled={renovarBloqueado}
                style={{ position: 'relative' }}
              >

                {/* {Se_Puede_Recontratar
                  ? <></>
                  :
                  <div className={'recontratar-warning-wrapper'}>
                    <Row align={'middle'} style={{ height: '100%' }}>
                      <Col span={24} style={{ height: 'fit-content', textAlign: 'center' }}>

                        <Avatar
                          icon={<ExclamationCircleTwoTone twoToneColor={'#ffcc66'} />}
                          size={150}
                          style={{ backgroundColor: '#ffe6b3', marginLeft: 'auto', marginRight: 'auto' }}
                          gap={0}
                        />

                        <p></p>



                      </Col>
                    </Row>

                  </div>
                } */}

                <Divider orientation={'left'}>Renovación de contrato</Divider>

                <Form
                  {...layout}
                  name="renovarContratoForm"
                  onFinish={this.renovarContratoFinish}
                  ref={this.renovacionFormRef}
                >

                  {/* SECCIÓN FORMULARIO */}
                  <Form.Item
                    name={"Tipo_Contrato"}
                    label="Tipo de contrato"
                    style={{ marginTop: "20px" }}
                    rules={[
                      {
                        required: true,
                        message: "Debe ingresar el tipo de contrato",
                      },
                    ]}
                  >
                    <Radio.Group
                      style={{ display: "flex" }}
                      onChange={(e) => {
                        //cuando cambio de tipo de contrato se resetean los campos
                        this.renovacionFormRef.current.resetFields([
                          "Relevo",
                          "Contrato_Fecha"
                        ])
                       
                        let valor = e.target.value;

                        this.setState({
                          tipoFechaContratoRenovar: valor,
                        });

                        if (valor === 'Plazo fijo') {
                       
                          this.renovacionFormRef.current.setFieldsValue({
                            Contrato_Fecha: [fechaRenovacion, null],
                          });

                        } else {
                 
                          this.renovacionFormRef.current.setFieldsValue({
                            Contrato_Fecha: moment(fechaRenovacion),
                          });
                        }


                      }}
                    >
                      <Radio value={"Plazo fijo"}>Plazo Fijo</Radio>
                      <Radio value={"Indefinido"}>Indefinido</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {/* //nuevo campo relevo// */}
                  {/* falta ckeckear estado que relevo */}
                  <Form.Item
                      name={"Relevo"}
                      label="Relevo"
                      style={{ marginTop: "20px" }}
                      rules={[
                        {
                          required: true,
                          message: "Obligatorio",
                        },
                      ]}
                    >
                      <Radio.Group
                        style={{ display: "flex" }}
                        // onChange={(e) => {
                        //   setEsRelevo(e.target.value);
                        // }}
                      >
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {/* //nuevo campo relevo// */}

                  <Form.Item
                    name={"Contrato_Fecha"}
                    label="Fecha de contratación"
                    rules={[
                      {
                        required: true,
                        message: "Debe ingresar la fecha de contratación",
                      },
                    ]}
                  >
                    {this.condicionalFechaContrato(this.state.tipoFechaContratoRenovar, 'renovar')}
                  </Form.Item>

                  <Form.Item
                    name={"Costo_Bruto_Diario"}
                    label={<p style={{ margin: "0px" }}>Costo Bruto Diario</p>}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      className="campos-formulario-contratos"
                    />
                  </Form.Item>

                  <Form.Item
                    name={"Costo_Liquido_Diario"}
                    label={<p style={{ margin: "0px" }}>Costo Liquido Diario</p>}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      className="campos-formulario-contratos"
                    />
                  </Form.Item>

                  {/* SECCIÓN FICHA DEL TRABAJADOR */}
                  <Form.Item
                    label={
                      <p style={{ margin: "0px", lineHeight: '14px' }}>
                        Modificar ficha actual
                        <br />
                        del trabajador
                      </p>
                    }
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                    extra={
                      editoFichaRenovar
                        ? <Alert
                          message={
                            <>
                              <span>Cambios de ficha guardados, terminar formulario para confirmar. </span>
                              <span
                                style={{ fontWeight: '900', cursor: 'pointer' }}
                                onClick={this.cerrarModalFichaTrabajador}
                              >
                                Click aquí para descartar cambios de ficha
                              </span>
                            </>
                          }
                          type="success"
                          showIcon
                          style={{ marginTop: '10px' }}
                        />
                        : <Alert
                          message={
                            <>
                              <span>No se han hecho cambios en la ficha del trabajador.</span>
                            </>
                          }
                          type="info"
                          showIcon
                          style={{ marginTop: '10px' }}
                        />
                    }
                  >
                    <Button
                      type="primary"
                      className={'formulario-contratos-modificar-ficha'}
                      onClick={() => {
                        this.abrirModalFichaTrabajador('renovar');
                      }}
                      disabled={Editar}
                    >
                      Modificar <DiffOutlined style={{ color: 'white' }} />
                    </Button>

                    <Popover
                      title={"Modificar ficha:"}
                      placement={"top"}
                      trigger={'click'}
                      content={
                        <div className={"popover-div"}>
                          <p className={"info-text"}>
                            (OPCIONAL) Si se necesita modificar información de la ficha del<br />
                            trabajador (por ej. cargo, régimen, etc) para el nuevo contrato,<br />
                            se puede utilizar este formulario.
                          </p>
                        </div>
                      }
                    >
                      <InfoCircleTwoTone
                        style={{ marginLeft: '15px' }}
                        className={"info-epps-icon"}
                        twoToneColor={"#48b9fd"}
                      />
                    </Popover>
                  </Form.Item>

                  {/* BTN SUBMIT */}
                  <Form.Item {...tailLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      form="renovarContratoForm"
                      className={'formulario-contratos-submit-btn'}
                      disabled={Editar}
                    >
                      Renovar contrato
                    </Button>
                  </Form.Item>

                </Form>

              </TabPane>

              {/* TAB FINIQUITAR */}
              <TabPane
                tab={<span><UserDeleteOutlined style={{ color: '#ff704d' }} />Finiquitar</span>}
                key="Finiquitar"
                disabled={finiquitarBloqueado}
              >
                <Divider orientation={'left'}>Finiquitar</Divider>

                <Form
                  {...layout}
                  name="finiquitarForm"
                  onFinish={this.finiquitarFinish}
                  ref={this.finiquitarFormRef}
                >
                  <Form.Item
                    name={"Fecha_Finiquito"}
                    label="Fecha de finiquito"
                    style={{ marginTop: "20px" }}
                    rules={[
                      {
                        required: true,
                        message: "Debe ingresar la fecha de finiquito",
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD-MM-YYYY"
                      locale={locale}
                      className="campos-formulario-contratos"
                      disabledDate={this.disabledDateFiniquito}
                      onChange={(date)=>{
                        this.buscarDatosFiniquito(date);
                      }}
                    />
                  </Form.Item>
                  <Row>
                    <Col
                     xs={{span:24,offset:0}} 
                     sm={{span:24,offset:0}} 
                     md={{span:16,offset:6}} 
                     lg={{span:16,offset:6}} 
                     xl={{span:16,offset:6}}
                    >
                      <Collapse
                        defaultActiveKey={['1']}
                        className={"collapse-informacion"}
                      >
                      <Panel 
                        header={this.state.textoValidacionFiniquito} 
                        key="1" 
                        //disabled={this.state.validacionFiniquito}
                        disabled={true}
                      >
                        <Row>
                          {
                            this.state.arrayPlanificacionesFiniquito.map((planificacion,index) => {
                              return(
                                <Col
                                xs={{span:24,offset:0}} sm={{span:24,offset:0}} md={{span:24,offset:0}} lg={{span:10,offset:1}} xl={{span:10,offset:1}}
                                
                                style={{
                                  textAlign:"center",
                                  backgroundColor: "white",
                                  color:  "#262E2D",
                                  padding: "10px 10px 10px 10px",
                                  borderRadius: "22px",
                                  marginBottom:"10px",
                                  borderWidth: "1.8px",
                                  borderStyle: "solid",
                                  borderColor: planificacion.Error === true ? "red": "#48B9FD",
                                }}
                                >
                                <p 
                                  style={{
                                      width:"90%", 
                                      margin:"0px",
                                      worBreak: "break-all",
                                      whiteSpace: " nowrap",
                                      textOverflow: "ellipsis",
                                      overflow:"hidden"
                                  }}>
                                      {planificacion.NombreFaena === undefined ? planificacion.NombreRegistroBitacoraTrabajador : planificacion.NombreFaena}
                                  </p>
                                  <p 
                                  style={{
                                      width:"90%", 
                                      margin:"0px",
                                      worBreak: "break-all",
                                      whiteSpace: " nowrap",
                                      textOverflow: "ellipsis",
                                      overflow:"hidden"
                                  }}>
                                      {` ${moment.utc(planificacion.Fecha_Inicio).format("DD-MM-YYYY")} - ${moment.utc(planificacion.Fecha_Fin).format("DD-MM-YYYY")}`}
                                  </p>
                                </Col>
                              )
                            })
                          }
                        </Row>
                      </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                 

                  <Form.Item
                    name={"Causal_Finiquito"}
                    label="Causal de finiquito"
                    style={{ marginTop: "20px" }}
                    rules={[
                      {
                        required: true,
                        message: "Debe ingresar la causal de finiquito",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Seleccionar causal"
                      className={'filtro-desviaciones'}
                    >
                      {/* PENDIENTE CON SERGIO */}
                      {causalesFiniquito.map((value, index) => (
                        <Select.Option key={index} value={`[${value.codigo}] ${value.nombre}`}>
                          [{value.codigo}] {value.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name={"Se_Puede_Recontratar"}
                    label={
                      <p style={{ margin: "0px", lineHeight: '14px' }}>
                        ¿Se recomienda volver a
                        <br />
                        contratar al trabajador?
                      </p>
                    }
                    style={{ marginTop: "20px" }}
                    rules={[
                      {
                        required: true,
                        message: "Obligatorio",
                      },
                    ]}
                  >
                    <Radio.Group
                      // style={{ display: "flex" }}
                      onChange={(e) => {
                      }}
                    >
                      <Radio value={true}>Sí</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    name={"Observaciones_Finiquito"}
                    label="Observaciones"
                    style={{ marginTop: "20px" }}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      className={'campos-formulario-contratos'}
                      maxLength={1000}
                      showCount
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      placeholder={'(Opcional)'}
                    />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      form="finiquitarForm"
                      className={'formulario-contratos-submit-btn'}
                      disabled={Editar}
                    >
                      Finiquitar contrato
                    </Button>
                  </Form.Item>
                </Form>

              </TabPane>

              {/* TAB NUEVO CONTRATO */}
              <TabPane
                tab={<span><FileAddOutlined style={{ color: '#75d154' }} />Nuevo Contrato</span>}
                key="Nuevo Contrato"
                disabled={nuevoContratoBloqueado}
                style={{ position: 'relative' }}
              >
                <Divider orientation={'left'}>Nuevo Contrato</Divider>

                <Form
                  {...layout}
                  name="nuevoContratoForm"
                  onFinish={this.nuevoContratoFinish}
                  ref={this.nuevoContratoFormRef}
                >

                  {/* SECCIÓN FORMULARIO */}
                  <Form.Item
                    name={"Tipo_Contrato"}
                    label="Tipo de contrato"
                    style={{ marginTop: "20px" }}
                    rules={[
                      {
                        required: true,
                        message: "Debe ingresar el tipo de contrato",
                      },
                    ]}
                  >
                    <Radio.Group
                      style={{ display: "flex" }}
                      onChange={(e) => {
                        this.setState({
                          tipoFechaContratoNuevo: e.target.value
                        });

                        this.nuevoContratoFormRef.current.setFieldsValue({
                          Contrato_Fecha: null,
                        });
                      }}
                    >
                      <Radio value={"Plazo fijo"}>Plazo Fijo</Radio>
                      <Radio value={"Indefinido"}>Indefinido</Radio>
                    </Radio.Group>
                  </Form.Item>

                    {/* //nuevo campo relevo// */}
                  <Form.Item
                      name={"Relevo"}
                      label="Relevo"
                      style={{ marginTop: "20px" }}
                      rules={[
                        {
                          required: true,
                          message: "Obligatorio",
                        },
                      ]}
                    >
                      <Radio.Group
                        style={{ display: "flex" }}
                        // onChange={(e) => {
                        //   //setEsRelevo(e.target.value);
                        // }}
                      >
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {/* //nuevo campo relevo// */}

                  <Form.Item
                    name={"Contrato_Fecha"}
                    label="Fecha de contratación"
                    rules={[
                      {
                        required: true,
                        message: "Debe ingresar la fecha de contratación",
                      },
                    ]}
                  >
                    {this.condicionalFechaContrato(this.state.tipoFechaContratoNuevo, 'nuevo')}
                  </Form.Item>

                  <Form.Item
                    name={"Costo_Bruto_Diario"}
                    label={<p style={{ margin: "0px" }}>Costo Bruto Diario</p>}
                    rules={[
                      {
                        required: false,
                        message: "Obligatorio",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      className="campos-formulario-contratos"
                    />
                  </Form.Item>

                  <Form.Item
                    name={"Costo_Liquido_Diario"}
                    label={<p style={{ margin: "0px" }}>Costo Liquido Diario</p>}
                    rules={[
                      {
                        required: false,
                        message: "Obligatorio",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      type="number"
                      min={0}
                      className="campos-formulario-contratos"
                    />
                  </Form.Item>

                  {/* SECCIÓN FICHA DEL TRABAJADOR */}
                  <Form.Item
                    label={
                      <p style={{ margin: "0px", lineHeight: '14px' }}>
                        Modificar ficha actual
                        <br />
                        del trabajador
                      </p>
                    }
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                    extra={
                      editoFichaNuevo
                        ? <Alert
                          message={
                            <>
                              {/* <span>Cambios de ficha guardados, terminar formulario para confirmar. </span>
                              <span
                                style={{ fontWeight: '900', cursor: 'pointer' }}
                                onClick={this.cerrarModalFichaTrabajador}
                              >
                                Click aquí para descartar cambios de ficha
                              </span> */}
                            </>
                          }
                          type="success"
                          showIcon
                          style={{ marginTop: '10px' }}
                        />
                        : <Alert
                          message={
                            <>
                              {/* <span>No se han hecho cambios en la ficha del trabajador.</span> */}
                            </>
                          }
                          type="info"
                          showIcon
                          style={{ marginTop: '10px' }}
                        />
                    }
                  >
                    <Button
                      type="primary"
                      className={'formulario-contratos-modificar-ficha'}
                      onClick={() => {
                        this.abrirModalFichaTrabajador('nuevo');
                      }}
                      disabled={Editar}
                    >
                      Modificar <DiffOutlined style={{ color: 'white' }} />
                    </Button>

                    <Popover
                      title={"Modificar ficha:"}
                      placement={"top"}
                      trigger={'click'}
                      content={
                        <div className={"popover-div"}>
                          <p className={"info-text"}>
                            (OPCIONAL) Si se necesita modificar información de la ficha del<br />
                            trabajador (por ej. cargo, régimen, etc) para el nuevo contrato,<br />
                            se puede utilizar este formulario.
                          </p>
                        </div>
                      }
                    >
                      <InfoCircleTwoTone
                        style={{ marginLeft: '15px' }}
                        className={"info-epps-icon"}
                        twoToneColor={"#48b9fd"}
                      />
                    </Popover>
                  </Form.Item>

                  {/* BTN SUBMIT */}
                  <Form.Item {...tailLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      form="nuevoContratoForm"
                      className={'formulario-contratos-submit-btn'}
                      disabled={Editar}
                    >
                      Generar Nuevo Contrato
                    </Button>
                  </Form.Item>

                </Form>

              </TabPane>

            </Tabs>
            }

            

          </Col>

        </Row>

      </div >
    )

  }

  contenidoModalEditarContrato = (contratoSeleccionado) => {

    const { causalesFiniquito, contratosTrabajador } = this.state;
    const { Se_Puede_Recontratar } = this.state.trabajadorSeleccionado;

    // se le pasa el dato en el mismo contrato para no tener que enviar datos del trabajador aparte
    contratoSeleccionado.Se_Puede_Recontratar = Se_Puede_Recontratar;

    let fechaFinUltimoContrato;

    // si tiene más de un contrato, pasarle la fecha fin del penúltimo contrato
    if (contratosTrabajador.length > 1) {

      if (contratosTrabajador[1].Contrato_Finiquitado) {
        fechaFinUltimoContrato = contratosTrabajador[1].Fecha_Finiquito;

      } else {
        fechaFinUltimoContrato = contratosTrabajador[1].Contrato_Fecha_Fin;
      }
    }

    return (
      <FormularioEditarContrato
        contratoSeleccionado={contratoSeleccionado}
        cerrarModalEditarContrato={this.cerrarModalEditarContrato}
        causalesFiniquito={causalesFiniquito}
        disabledDateFiniquito={this.disabledDateFiniquito}
        fechaFinUltimoContrato={fechaFinUltimoContrato}
        editarContrato={this.editarContrato}
      />
    )

    // this.editarContratoFormRef.current.setFieldsValue(contratoSeleccionado);

    // return (
    // <div className={'maven-r'}>

    //   {/* HEADER */}
    //   <div className={'modal-header-wrapper'}>
    //     <PageHeader
    //       className="site-page-header"
    //       onBack={() => {
    //         this.cerrarModalEditarContrato();
    //       }}
    //       title="Editar Contrato"
    //       subTitle={<span>Modificar la información registrada del contrato seleccionado</span>}
    //       backIcon={<LeftCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={'#0141d074'} />}
    //     />
    //   </div>

    //   {/* FORMULARIO EDITAR CONTRATO */}
    //   <div>

    //     <Form
    //       {...layout}
    //       name="editarContratoForm"
    //       onFinish={this.editarContratoFinish}
    //       ref={this.editarContratoFormRef}
    //       initialValues={contratoSeleccionado}
    //     >

    //       <Form.Item
    //         name={"Tipo_Contrato"}
    //         label="Tipo de contrato"
    //         style={{ marginTop: "20px" }}
    //         rules={[
    //           {
    //             required: true,
    //             message: "Obligatorio",
    //           },
    //         ]}
    //       >
    //         <Radio.Group
    //           onChange={(e) => {
    //             this.editarContratoFormRef.current.setFieldsValue({
    //               Contrato_Fecha: null,
    //             });
    //           }}
    //         >
    //           <Radio value={"Plazo fijo"}>Plazo Fijo</Radio>
    //           <Radio value={"Indefinido"}>Indefinido</Radio>
    //         </Radio.Group>
    //       </Form.Item>

    //       <Form.Item
    //         name={"Contrato_Fecha"}
    //         label="Fecha de contratación"
    //         rules={[
    //           {
    //             required: true,
    //             message: "Obligatorio",
    //           },
    //         ]}
    //       >
    //         {this.condicionalFechaContratoEditar(tipoFechaEditarContrato)}

    //       </Form.Item>

    //       <Form.Item
    //         name={"Costo_Bruto_Diario"}
    //         label={<p style={{ margin: "0px" }}>Costo Bruto Diario</p>}
    //         rules={[
    //           {
    //             required: false,
    //             message: "Obligatorio",
    //           },
    //         ]}
    //       >
    //         <InputNumber
    //           style={{ width: "100%" }}
    //           type="number"
    //           min={0}
    //           className="campos-formulario-contratos"
    //         />
    //       </Form.Item>

    //       <Form.Item
    //         name={"Costo_Liquido_Diario"}
    //         label={<p style={{ margin: "0px" }}>Costo Liquido Diario</p>}
    //         rules={[
    //           {
    //             required: false,
    //             message: "Obligatorio",
    //           },
    //         ]}
    //       >
    //         <InputNumber
    //           style={{ width: "100%" }}
    //           type="number"
    //           min={0}
    //           className="campos-formulario-contratos"
    //         />
    //       </Form.Item>

    //       <Form.Item {...tailLayout}>
    //         <Button
    //           type="primary"
    //           htmlType="submit"
    //           form="editarContratoForm"
    //           className={'formulario-contratos-submit-btn'}
    //         >
    //           Editar contrato
    //         </Button>
    //       </Form.Item>

    //     </Form>

    //   </div>

    // </div>
    // )
  }

  editarContrato = async (values, estaFiniquitado, idContrato) => {

    this.setState({ loading: true });

    const { Causal_Finiquito, Contrato_Fecha_Inicio, Contrato_Fecha_Inicio_Unix,
      Costo_Bruto_Diario, Costo_Liquido_Diario, Fecha_Finiquito, Observaciones_Finiquito,
      Se_Puede_Recontratar, Tipo_Contrato, Contrato_Fecha_Fin, Contrato_Fecha_Fin_Unix, Fecha_Finiquito_Unix,
      Relevo
    } = values;
 
    let objEditarContrato = {
      Tipo_Contrato,
      Relevo,
      Contrato_Fecha_Inicio,
      Contrato_Fecha_Inicio_Unix,
      Contrato_Fecha_Fin:(Tipo_Contrato === "Indefinido" ? null: Contrato_Fecha_Fin),
      Contrato_Fecha_Fin_Unix:(Tipo_Contrato === "Indefinido" ? null: Contrato_Fecha_Fin_Unix),
      Costo_Bruto_Diario,
      Costo_Liquido_Diario,
    }

    if (estaFiniquitado) {

      objEditarContrato.Fecha_Finiquito = Fecha_Finiquito;
      objEditarContrato.Fecha_Finiquito_Unix = Fecha_Finiquito_Unix;
      objEditarContrato.Causal_Finiquito = Causal_Finiquito;
      if (Observaciones_Finiquito) objEditarContrato.Observaciones_Finiquito = Observaciones_Finiquito;
    }

    // API EDITAR CONTRATO
    
    await actualizarContratoSoloContrato(objEditarContrato, idContrato);
    
    const idTrabajador = this.state.trabajadorSeleccionado._id.toString();

    let objEditarTrabajador = {
      Se_Puede_Recontratar,
      Tipo_Contrato,
      Relevo,
      Contrato_Fecha_Inicio,
      Contrato_Fecha_Inicio_Unix,
      Contrato_Fecha_Fin:(Tipo_Contrato === "Indefinido" ? null: Contrato_Fecha_Fin),
      Contrato_Fecha_Fin_Unix:(Tipo_Contrato === "Indefinido" ? null: Contrato_Fecha_Fin_Unix),
      Costo_Bruto_Diario,
      Costo_Liquido_Diario,
    }

    if (estaFiniquitado) {
      objEditarTrabajador.Fecha_Finiquito = Fecha_Finiquito;
      objEditarTrabajador.Fecha_Finiquito_Unix = Fecha_Finiquito_Unix;
    }

      // API EDITAR TRABAJADOR
      const objNuevoTrabajador = await actualizarTrabajador(objEditarTrabajador, idTrabajador)

      const { trabajador: trabajadorActualizado, categoriaTrabajador: nuevaCategoriaTrabajador } = objNuevoTrabajador;

      await this.refreshContratos(trabajadorActualizado, nuevaCategoriaTrabajador);
      
    Swal.fire({
      title: "¡Bien!",
      text: "Se ha editado el contrato exitosamente.",
      icon: "success",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Ok",
    });

    this.setState({
      loading: false,
    });

    this.cerrarModalEditarContrato();
  }

  refreshContratos = async (trabajadorActualizado, nuevaCategoriaTrabajador) => {
    // función encargada de hacer refresh a categorización de los trabajadores y a la lista de contratos
    // del trabajador, después de que se haya concretado una renovación de contrato, etc.

    // const { trabajadorSeleccionado } = this.state;
    // const idTrabajadorSeleccionado = trabajadorSeleccionado._id;

    let filtrosValues = this.filtrosFormRef.current.getFieldsValue();

    // // filtrando denuevo, trayendo los trabajadores actualizados
    await this.filtrarTrabajadores(filtrosValues);

    // let tituloEstado;
    // let trabajadorActualizado; // se debe actualizar la variable trabajadorSeleccionado ya que se puede haber editado su ficha
    // let categoriasLength = listaCategorias.length;

    // for (let i = 0; i < categoriasLength; i++) {

    //   let listaTrabajadores = listaCategorias[i].listaTrabajadores;

    //   let trabajador = listaTrabajadores.filter(trabajador => trabajador._id.toString() === idTrabajadorSeleccionado);

    //   // si está en la categoría, entonces se corta el loop y se guarda el nombre de la categoría
    //   if (trabajador.length > 0) {
    //     tituloEstado = listaTrabajadores.titulo;
    //     trabajadorActualizado = trabajador[0];
    //     break;
    //   }
    // }

    await this.seleccionarTrabajador(trabajadorActualizado, nuevaCategoriaTrabajador);
  }

  onTabsChange = async (valTab, sePuedeRecontratar, observacionesFiniquito) => {


    let stateObj = { selectedTab: valTab };

    // validación en caso de que el trabajador se haya finiquitado con sugerencia de 
    // NO volver a contratarlo
    if(this.renovacionFormRef.current !== null){
      await this.renovacionFormRef.current.resetFields();
    }
    if(this.nuevoContratoFormRef.current !== null){
      await this.nuevoContratoFormRef.current.resetFields();
    }

    if (sePuedeRecontratar === false && ['Renovar Contrato', 'Nuevo Contrato'].includes(valTab)) {
 
      let cancelFlag = false;
      let msg = '<span>La última vez que se finiquitó al trabajador se recomendó no volver a contratarlo.</span><br/><br/>';

      if (observacionesFiniquito) msg = `${msg} <span>Se ingresó la observación:<br/>${observacionesFiniquito}</span><br/><br/>`;

      msg = msg + '<p>¿deseas continuar?</p>';

      await Swal.fire({
        title: "Advertencia",
        html: msg,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e60aa3",
        cancelButtonColor: "#64b4e5",
        confirmButtonText: "Continuar",
        cancelButtonText: "Volver",

      }).then(async (result) => {

        if (!result.value) {
          cancelFlag = true;
        }
      })

      // si seleccionó volver, volverá a tab de Contratos
      if (cancelFlag) {
        stateObj.selectedTab = 'Contratos';
      }

    }

    this.setState(stateObj)
  }

  disabledDateNuevoContrato = (current) => {
    // función para dejar bloqueado desde la fecha fin del último contrato hacia atrás,
    // y en caso de ser contrato indefinido, éste debiera tener fecha de finiquito (no puedes
    // crearle un nuevo contrato a un trabajador indefinido, por lo tanto utilizar fecha finiquito

    const { Contrato_Fecha_Fin_Unix, Fecha_Finiquito_Unix } = this.state.trabajadorSeleccionado.Contrato_Actual;

    if (Fecha_Finiquito_Unix) {

      const fechaFiniquito = moment.unix(Fecha_Finiquito_Unix).add(1, 'day');
      return current && current < fechaFiniquito;

    } else if (Contrato_Fecha_Fin_Unix) {

      const fechaFinContrato = moment.unix(Contrato_Fecha_Fin_Unix);
      return current && current < fechaFinContrato;

    } else {
      return false;
    }

  }

  disabledDateFiniquito = (current) => {
    // estarán disponibles para finiquitar sólo las fechas dentro del contrato
    const { Tipo_Contrato, Contrato_Fecha_Inicio, Contrato_Fecha_Inicio_Unix,
      Contrato_Fecha_Fin, Contrato_Fecha_Fin_Unix } = this.state.trabajadorSeleccionado.Contrato_Actual;

    const fechaInicioContrato = moment.unix(Contrato_Fecha_Inicio_Unix).utc();
    const fechaFinContrato = moment.unix(Contrato_Fecha_Fin_Unix).utc();

    // const fechaInicioContrato = moment(Contrato_Fecha_Inicio).utc();
    // const fechaFinContrato = moment(Contrato_Fecha_Fin).utc();

    if (current < fechaInicioContrato) {
      return current && current < fechaInicioContrato;

    } else if (current > fechaFinContrato && Tipo_Contrato === 'Plazo fijo') {
      return current && current > fechaFinContrato;

    } else {
      return false;
    }

  }

  searchTrabajador = (e) => {

    let val = e.target.value;

    let stateObj = {};

    if (val) {
      stateObj.busquedaTrabajador = val;
    } else {
      stateObj.busquedaTrabajador = null;
    }

    this.setState(stateObj);
  }

  contenidoModalVisualizarFicha = (fichaVisualizar) => {

    const { epps, documentos, titulos, especialidades } = this.state;

    return (
      <FormularioTrabajadorVisualizar
        Formulario={fichaVisualizar}
        epps={epps}
        documentos={documentos}
        titulos={titulos}
        especialidades={especialidades}
      />
    )
  }

  render() {

    const { cargos, categoriasTrabajadores, trabajadorSeleccionado, modalFichaTrabajador,
      modalDetalle, regimenes, epps, documentos, titulos, especialidades, regiones, provincias,
      comunas, modalEditarContrato, contratoSeleccionado, estadosContractuales, todosLosTrabajadores,
      busquedaTrabajador, fichaVisualizar, modalVisualizarFicha } = this.state;

    return (
      <Layout style={{ height: "100%" }}>

        <Loading cargando={this.state.loading} />

        <Header style={{ background: "none" }}>
          <HeaderFicha titulo="Contratos y finiquitos" />
        </Header>

        <hr className="division" style={{ bottom: '12px' }} />

        {/* CONTENIDO */}
        <Content
          className="contenedor-general maven-r"
          style={{ background: "none" }}
        >

          {/* FILTROS */}
          <Form
            onFinish={this.filtrarTrabajadores}
            ref={this.filtrosFormRef}
            className={'maven-r'}
            name={"filtrar-form"}
          >

            <Row gutter={20} style={{ marginBottom: '20px' }}>

              {/* FILTRO CC */}
              <Col xs={24} sm={12} md={6} lg={4} xl={3}
                style={{ marginBottom: '15px' }}
              >
                <div>
                  <p style={{ marginBottom: '10px' }}>Filtrar</p>
                </div>

                <Form.Item
                  name="cargo"
                  noStyle
                >
                  <Select
                    placeholder="Cargo"
                    className={'filtro-desviaciones'}
                    allowClear={true}
                  >
                    {cargos.map((value, index) => (
                      <Select.Option key={index} value={value._id}>
                        {value.Nombre}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

              </Col>

              {/* FILTRO ESTADO CONTRACTUAL */}
              <Col xs={24} sm={12} md={6} lg={4} xl={3}
                style={{ marginBottom: '15px' }}
              >

                <div style={{ height: '32px' }}></div>

                <Form.Item
                  name="estadosContractuales"
                  noStyle
                >
                  <Select
                    placeholder="Estados Contractuales"
                    className={'filtro-desviaciones'}
                    allowClear={true}
                    mode={'multiple'}
                    maxTagCount={1}
                  >
                    {estadosContractuales.map((titulo, index) => (
                      <Select.Option key={index} value={titulo}>
                        {titulo}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* FILTRO TITULO */}
              <Col xs={24} sm={12} md={6} lg={4} xl={3}
                style={{ marginBottom: '15px' }}
              >

                <div style={{ height: '32px' }}></div>

                <Form.Item
                  name="titulos"
                  noStyle
                >
                  <Select
                    placeholder="Titulos"
                    className={'filtro-desviaciones'}
                    allowClear={true}
                    // mode={'multiple'}
                    maxTagCount={1}
                  >
                    {titulos.map((value, index) => (
                      <Select.Option key={index} value={value._id}>
                        {value.Nombre}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* FILTRO TIPO CONTRATO */}
              <Col xs={24} sm={12} md={6} lg={4} xl={3}
                style={{ marginBottom: '15px' }}
              >

                <div style={{ height: '32px' }}></div>

                <Form.Item
                  name="tipoContrato"
                  noStyle
                >
                  <Select
                    placeholder="Tipo Contratos"
                    className={'filtro-desviaciones'}
                    allowClear={true}
                    // mode={'multiple'}
                    maxTagCount={1}
                  >
                    <Select.Option value="Plazo fijo">
                      Plazo Fijo
                    </Select.Option>

                    <Select.Option value="Indefinido">
                      Indefinido
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              {/* BTN FILTRAR */}
              <Col xs={24} sm={24} md={6} lg={3} xl={3}
                className={'filtrar-contratos-btn-col'}
              >
                <div style={{ height: '32px' }}></div>

                <Button
                  type="primary"
                  htmlType="submit"
                  form="filtrar-form"
                  className={'filtrar-contratos-btn'}
                >
                  Filtrar Contratos
                </Button>
              </Col>

              <Col flex={'auto'}></Col>

              {/* BÚSQUEDA POR NOMBRE */}
              {/* Incompleto: recordar que debe buscar en TODOS los trabajadores de la empresa, no solo los filtrados */}
              <Col xs={24} sm={24} md={68} lg={5} xl={5}>
                <div>
                  <p style={{ marginBottom: '10px' }}>Búsqueda por nombre</p>
                </div>

                <Search
                  value={busquedaTrabajador}
                  placeholder="Buscar nombre..."
                  onChange={(value) => this.searchTrabajador(value)}
                />

                {/* <Select
                  placeholder="Buscar nombre..."
                  className={'filtro-desviaciones'}
                  allowClear={true}
                  showSearch={true}
                  onSelect={(val) => {

                    const trabajadorSearch = todosLosTrabajadores.find(trabajador => {

                      const { Nombre, Apellido_Paterno, Apellido_Materno } = trabajador;

                      const nombreCompleto = `${Nombre} ${Apellido_Paterno} ${Apellido_Materno}`;

                      return nombreCompleto === val;
                    })

                    this.setState({
                      trabajadorSearch,
                    })
                    // this.setBusquedaTrabajador();
                  }}
                >
                  {todosLosTrabajadores.map((trabajador, index) => {

                    const { Nombre, Apellido_Paterno, Apellido_Materno, _id } = trabajador;

                    const nombreCompleto = `${Nombre} ${Apellido_Paterno} ${Apellido_Materno}`;

                    return (
                      <Select.Option key={index} value={nombreCompleto}>
                        {nombreCompleto}
                      </Select.Option>
                    )
                  })}
                </Select> */}
              </Col>


            </Row>


          </Form>

          {this.renderCategorias(categoriasTrabajadores)}

        </Content>

        {/* MODAL CONTRATOS */}
        <Modal
          visible={modalDetalle}
          onCancel={this.cerrarModal}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          style={{ top: 20 }}
          width={"90%"}
          bodyStyle={{ borderRadius: "16px" }}
          key={1}
        >

          {trabajadorSeleccionado ? this.contenidoModal(trabajadorSeleccionado) : <></>}
        </Modal>

        {/* MODAL MODIFICAR FICHA TRABAJADOR */}
        <Modal
          visible={modalFichaTrabajador}
          onCancel={this.cerrarModalFichaTrabajador}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          style={{ top: 20 }}
          width={"90%"}
          bodyStyle={{ borderRadius: "16px" }}
          key={2}
        >
          {modalFichaTrabajador
            ? <FormEditarTrabajadorContrato

              // TRABAJADOR
              Formulario={trabajadorSeleccionado}

              // FUNCIONES
              guardarNuevaFicha={this.guardarNuevaFicha}
              obtenerImagen={this.obtenerImagen}
              cerrarModal={this.cerrarModalFichaTrabajador}

              // PARÁMETROS
              cargosTrabajador={cargos}
              regimenes={regimenes}
              epps={epps}
              documentos={documentos}
              titulos={titulos}
              especialidades={especialidades}

              regiones={regiones}
              provincias={provincias}
              comunas={comunas}

              // FUNCIONALIDAD REGIONES / PROVINCIAS / COMUNAS
              // ciudadesSeleccionadas={this.props.ciudadesSeleccionadas}
              // comunasSeleccionadas={this.props.comunasSeleccionadas}
              // buscarComuna={this.buscarComuna}
              // buscarProvincias={this.buscarProvincias}

              editarCerrado={this.state.editarCerrado}
              cambiarEditarCerrado={this.cambiarEditarCerrado}
            />
            : <></>
          }
        </Modal>

        {/* MODAL EDITAR CONTRATO */}
        <Modal
          visible={modalEditarContrato}
          onCancel={this.cerrarModalEditarContrato}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          style={{ top: 20 }}
          width={"75%"}
          bodyStyle={{ borderRadius: "16px" }}
          key={3}
        >

          {contratoSeleccionado ? this.contenidoModalEditarContrato(contratoSeleccionado) : <></>}

        </Modal>

        {/* MODAL VISUALIZAR FICHA TRABAJADOR */}
        <Modal
          visible={modalVisualizarFicha}
          onCancel={this.cerrarModalVisualizarFicha}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          style={{ top: 20 }}
          width={"75%"}
          bodyStyle={{ borderRadius: "16px" }}
          key={4}
        >
          {fichaVisualizar ? this.contenidoModalVisualizarFicha(fichaVisualizar) : <></>}
        </Modal>

        {/* SCOPED STYLES */}
        <span>
          <style jsx="true">
            {`
          `}
          </style>
        </span>

      </Layout >
    );
  }
}

export default Contratos;