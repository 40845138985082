import React, { Component } from 'react';

// import vacas from '../../img/icons/vacaciones.svg'
import vacas from "../../img/icons-nuevos/Vacaciones.svg";

import {
    Row,
    Col,
    Layout,
    Card,
    Form,
    Button,
    Select,
    Radio,
    InputNumber,
    Modal,
    PageHeader,
    DatePicker,
    Popover,
    Tooltip,
    Input
} from 'antd';
import './Vacaciones.css';
import HeaderFicha from '../../components/headerFicha/HeaderFicha';
import Loading from "../../components/loading/Loading";
//import TimeLineTrabajador from "../../components/timeLineTrabajador/TimeLineTrabajadorPopover";
import TimeLineTrabajador from "../../components/timeLineTrabajador/TimeLineTrabajadorPopover3";
import { traer_todos_las_fechas_trabajador_descansos_finiquitados } from "../../Redux/actions/descansoFiniquitadosActions";

import { connect } from 'react-redux';
import * as vacacionActions from '../../Redux/actions/vacacionActions';
import FormItem from 'antd/lib/form/FormItem';

import Swal from "sweetalert2";
import moment from 'moment';
import accesosPagina from '../../funcionesGenericas/accesosUsuario';
import locale from 'antd/lib/date-picker/locale/es_ES';

import {
    momentToStringFechaInicio,
    momentToStringFechaFin,
    databaseFormat,
    convertirFechaString
} from "../../funcionesGenericas/fechas";

const { Header, Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const queryString = require('query-string');


class Vacaciones extends Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            idEmpresa:null,
            mostrarCalendario: false,
            bitacora: [],
            archivoSubir: "",

            //informacion no ingresada solo para mostrar
            cargo: null,
            LoadingForm: false,
            fechasBloqueadas: [],

            //modal
            modalVacaciones: false,
            idVacaciones: "",

            //lógica históricas
            mostrarPantalla: 'none',

            //lógica progresivas
            disabledInputs: true,
            disabledRadios: true,
            disabledProgresivas: true,

            fechasEfectivas: null,
            periodosCondicional: [],
            diasPorPeriodo: [],
            vacacionesBitacoras: [],

            idTrabajadorQuery: null,

            //nueva logica de contratos
            selectedContrato: null,
            bitacora:[],
            selectedDates:[null,null],
            selectedDatesTimeline:[null,null],
            firstDaysOfTheMonths: [],
            fechasHistoricasVacaciones:[],
        };
    }

    state = {
        LoadingForm: false,
        fechasBloqueadas: []
    }

    onFinish = async (values) => {

        /***** ORDENANDO PERIODOS DE VACACIONES *****/
        let { periodosCondicional } = this.state;
        let periodosSeleccionados = [];

        periodosCondicional.forEach((periodo, index) => {
            if (values.FechasTrabajadas.includes(index)) {

                let { inicio, fin, inicioUnix, finUnix } = periodo;

                let objPeriodo = {
                    inicio,
                    fin,
                    inicioUnix,
                    finUnix,
                    diasConsumidos: this.state.diasPorPeriodo[index],
                    diasNumberConsumidos: this.state.diasNumberPorPeriodo[index],
                    tipoDias: values.TipoDias,
                }

                periodosSeleccionados.push(objPeriodo)
            }
        })

        values.FechasTrabajadas = periodosSeleccionados;

        /***** WARNING PARA EXCESO DE NORMALES Y RESTRICCIÓN PARA EXCESO DE PROGRESIVAS *****/
        let { SaldoVacaciones, SaldoProgresivos } = this.formRef.current.getFieldsValue(['SaldoVacaciones', 'SaldoProgresivos']);

        let cancelarFlag = false;
        if (SaldoVacaciones < 0) {

            // Caso: exceso de normales
            await Swal.fire({
                title: "Advertencia",
                text: `Vas a ingresar vacaciones adelantadas al trabajador, ¿deseas continuar?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1FC600",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",

            }).then(async (result) => {

                if (result.value !== true) {
                    cancelarFlag = true;
                }
            })
        } else if (SaldoProgresivos < 0) {
            // Caso: exceso de progresivos
            await Swal.fire({
                title: "Aviso",
                text: `Legalmente no se le pueden ingresar vacaciones progresivas adelantadas al trabajador.`,
                icon: "warning",
                confirmButtonColor: "#1FC600",
                confirmButtonText: "Ok",

            }).then(async () => {
                cancelarFlag = true;
            })
        }

        if (cancelarFlag) {
            return;
        }

        let { fechasEfectivas } = this.state;

        if (this.props.Trabajador) {


            // if (values.FechasTrabajadas === null || values.FechasTrabajadas[0].toString() === 'Invalid Date'
            //     || fechasEfectivas === null || fechasEfectivas[0].toString() === 'Invalid Date') {

            //     Swal.fire({
            //         title: "Ups",
            //         text: "Debes ingresar las fechas necesarias",
            //         icon: "warning",
            //         confirmButtonColor: "#E60AA3",
            //         confirmButtonText: "Ok",
            //     });

            //     return;
            // }
            if (!values.esBonificacion) {

                //VALIDACIÓN DE WARNING
                let listaFechas = [];
                await this.state.bitacora.forEach((bitacora) => {
                    if(bitacora.TipoRegistroBitacoraTrabajador !== "Vacio"){
                        listaFechas.push(moment.utc(bitacora.Fecha));
                    }
                });

                const fecha1 = moment.utc(momentToStringFechaInicio(moment(fechasEfectivas[0]), databaseFormat));
                const fecha2 = moment.utc(momentToStringFechaInicio(moment(fechasEfectivas[1]), databaseFormat));

                let warningFlag = false;
                let confirmFlag = false;

                await listaFechas.forEach(async (fecha) => {

                    if (await fecha.isBetween(fecha1, fecha2)) {

                        warningFlag = true;
                        return;
                    }
                })

                if (warningFlag) {

                    await Swal.fire({
                        title: "Advertencia",
                        text: `Las fechas que seleccionaste ya tienen planificación, ¿deseas continuar?`,
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#1FC600",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",

                    }).then(async (result) => {

                        if (!result.value) {
                            confirmFlag = true;
                        }
                    })

                    if (confirmFlag) {
                        return;
                    }

                }

            }

            // es bonificación o registro de vacaciones ?
            if (values.esBonificacion) {
                
                await this.props.insertarBonificacion(
                    values.TipoDias,
                    values.DisponiblesVacaciones,
                    values.DisponiblesProgresivos,
                    values.DisponiblesTotal,
                    values.FeriadoLegalVacaciones,
                    values.FeriadoLegalProgresivos,
                    values.FeriadoLegalTotal,
                    values.SaldoVacaciones,
                    values.SaldoProgresivos,
                    values.SaldoTotal,
                    values.FechasTrabajadas,
                    values.esBonificacion,
                    values.montoBonificacion,
                    values.Observacion,
                );

            } else {
                
                await this.props.insertarDatosAvanzado(
                    values.TipoDias,
                    values.DisponiblesVacaciones,
                    values.DisponiblesProgresivos,
                    values.DisponiblesTotal,
                    values.FeriadoLegalVacaciones,
                    values.FeriadoLegalProgresivos,
                    values.FeriadoLegalTotal,
                    values.SaldoVacaciones,
                    values.SaldoProgresivos,
                    values.SaldoTotal,
                    fechasEfectivas,
                    values.FechasTrabajadas,
                    values.Observacion,
                );
            }

            // let response = await this.props.traer_todos_las_fechas_trabajador(
            //     this.props.Trabajador
            // );

            // let fechas = [];

            // response.forEach(element => {
            //     fechas.push(element.Fecha);
            // });

            // const responseDFiniquitados = await traer_todos_las_fechas_trabajador_descansos_finiquitados(this.props.Trabajador);

            // responseDFiniquitados.forEach(finiquito => {
            //     let newFiniquito = {...finiquito};
            //     newFiniquito.finiquitoDescanso = true;
            //     newFiniquito.TipoRegistroBitacoraTrabajador = 'descansoFiniquitado';
            //     response.push(newFiniquito);
            // });

            // await this.setState({
            //     fechasBloqueadas: fechas,
            //     bitacora: response,
            //     mostrarCalendario: true,
            // });

            // await this.formRef.current.resetFields([
            //     'TipoDias',
            //     'FechasTrabajadas',
            //     'FechasEfectivas',
            //     'DisponiblesVacaciones',
            //     'DisponiblesTotal',
            //     'FeriadoLegalVacaciones',
            //     'FeriadoLegalTotal',
            //     'SaldoVacaciones',
            //     'SaldoTotal',
            //     'Correo',
            //     'montoBonificacion',
            // ]);

            // if (!this.state.disabledProgresivas) {

            //     await this.formRef.current.resetFields([
            //         'DisponiblesProgresivos',
            //         'FeriadoLegalProgresivos',
            //         'SaldoProgresivos',
            //     ]);
            // }

            

            this.reiniciarFormulario(this.props.Trabajador);
        }
    }

    reiniciarFormulario = (idTrabajador) => {

        this.handleChangeTrabajadorNew(idTrabajador);

        this.formRef.current.setFieldsValue({
            cantidadTomadas: 1,
            esBonificacion: false,
        })

        this.setState({
            disabledInputs: true,
            fechasEfectivas: null,
            periodosCondicional: [],
            isCompensacion: false,
        })
    }

    async componentDidMount() {

        let usuario = localStorage.getItem("usuario-people-manage");

        if (
            usuario !== null &&
            usuario !== "" &&
            usuario !== undefined &&
            usuario !== "undefined"
        ) {
            usuario = JSON.parse(usuario);
            const id = usuario.EmpresaSeleccionada.Empresa._id;

            this.props.traer_todos_los_trabajadores(id);
            const accesos = await accesosPagina.obtenerAccesosPagina('ProcesosVacaciones');

            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
            let feriados = await this.props.ObtenerFeriados(idEmpresa);

            const parsed = queryString.parse(this.props.location.search);
            let idTrabajadorQuery = parsed.idTrabajador;

            if (idTrabajadorQuery) {

                this.handleChangeTrabajador(idTrabajadorQuery);
                this.formRef.current.setFieldsValue({ trabajador: idTrabajadorQuery });

            }
            this.setState({
                Ver: accesos.Ver,
                Editar: accesos.Editar,
                Eliminar: accesos.Eliminar,
                feriados,
                idEmpresa:id,
            });
        }
    }

    handleFechas = async (valueMoment, contenedorFechas) => {
        let fechas = [];
        await contenedorFechas.forEach(fecha => {
            let dia = new Date(fecha);
            fechas.push(dia);
        });

        await this.props.insertarFechas(fechas);
    }

    handleFecha2 = async (valueMoment, contenedorFechas) => {
        let fechas = [];
        await contenedorFechas.forEach(fecha => {
            let dia = new Date(fecha);
            fechas.push(dia);
        });

        await this.props.insertarFechas2(fechas);
    }

    // handleChangeTrabajador = async (idTrabajador) => {

    //     let trabajador = this.props.trabajadores.find(x => x._id === idTrabajador);
    //     if (trabajador) {

    //         await this.props.insertarTrabajador(idTrabajador);

    //         let response = await this.props.traer_todos_las_fechas_trabajador(idTrabajador);

    //         let fechas = [];

    //         let normalesTomadas = 0;
    //         let progresivasTomadas = 0;
    //         let hayVacaciones = false;

    //         let vacacionesBitacoras = [];

    //         // Recorriendo bitácora: para validar si es que hay vacaciones y en ese caso,
    //         // contar cuántas son, para descontárla al saldo después.
    //         response.forEach(bitacora => {

    //             fechas.push(bitacora.Fecha);

    //             //Si es bitácora de vacaciones, contarla.
    //             if (bitacora.TipoRegistroBitacoraTrabajador === '5f3eebac978b284b68ebced5') {

    //                 //validación para que solo cuente los días hábiles de la bitácora
    //                 let numeroSemanalFecha = moment(bitacora.Fecha).add(3, 'hours').isoWeekday();
    //                 let esFeriadoFlag = false;

    //                 vacacionesBitacoras.push(bitacora);

    //                 if (![6, 7].includes(numeroSemanalFecha)) {

    //                     //validación: si es feriado, no contarlo
    //                     this.state.feriados.forEach((feriado) => {

    //                         let feriadoMoment = moment(feriado.Fecha).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
    //                         let bitacoraMoment = moment(bitacora.Fecha).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });

    //                         if (feriadoMoment.isSame(bitacoraMoment)) {
    //                             esFeriadoFlag = true;
    //                         }
    //                     })


    //                     if (!esFeriadoFlag) {
    //                         if (bitacora.Vacaciones.TipoDias === 'Normales') normalesTomadas++
    //                         if (bitacora.Vacaciones.TipoDias === 'Progresivos') progresivasTomadas++;

    //                         hayVacaciones = true;
    //                     }
    //                 }
    //             }
    //         });


    //         const responseDFiniquitados = await traer_todos_las_fechas_trabajador_descansos_finiquitados(idTrabajador);

    //         responseDFiniquitados.forEach(finiquito => {
    //             let newFiniquito = {...finiquito};
    //             newFiniquito.finiquitoDescanso = true;
    //             newFiniquito.TipoRegistroBitacoraTrabajador = 'descansoFiniquitado';
    //             response.push(newFiniquito);
    //         });

    //         await this.setState({
    //             fechasBloqueadas: fechas,
    //             bitacora: response,
    //             mostrarCalendario: true,
    //         });

    //         await this.formRef.current.resetFields([
    //             'TipoDias',
    //             'FechasTrabajadas',
    //             'FechasEfectivas',
    //             'DisponiblesVacaciones',
    //             'DisponiblesProgresivos',
    //             'DisponiblesTotal',
    //             'FeriadoLegalVacaciones',
    //             'FeriadoLegalProgresivos',
    //             'FeriadoLegalTotal',
    //             'SaldoVacaciones',
    //             'SaldoProgresivos',
    //             'SaldoTotal',
    //             'Correo',
    //             'cantidadTomadas'
    //         ]);

    //         /****** INICIO LÓGICA HISTÓRICAS ******/

    //         let usuario = localStorage.getItem("usuario-people-manage");
    //         usuario = JSON.parse(usuario);
    //         const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

    //         let fechaInicioSistema = moment.unix(usuario.EmpresaSeleccionada.Empresa.Fecha_Inicio_Sistema_Unix);
    //         let cancelarFlag = false;

    //         if (!trabajador.Contrato_Fecha_Inicio) {

    //             await Swal.fire({
    //                 title: "Error",
    //                 text: `Este trabajador no tiene registro de su fecha de contrato, debe ingresarse en el registro de trabajadores antes de proseguir.`,
    //                 icon: "warning",
    //                 confirmButtonColor: "#1FC600",
    //                 confirmButtonText: "Ok",

    //             }).then(async () => {
    //                 cancelarFlag = true;
    //             })
    //         }

    //         if (cancelarFlag) {
    //             return;
    //         }

    //         let fechaContrato = trabajador.Contrato_Fecha_Inicio_Unix ? moment.unix(trabajador.Contrato_Fecha_Inicio_Unix) : moment(trabajador.Contrato_Fecha_Inicio);


    //         let historicas = await this.props.ObtenerVacacionesHistoricas(trabajador._id, idEmpresa);

    //         let mostrarPantalla;

    //         let fechaContratoClone1 = fechaContrato.clone();

    //         // HAY CUATRO CASOS POSIBLES:
    //         // LA PERSONA FUE CONTRATADA ANTES DEL SISTEMA, Y NO LE HAN INGRESADO HISTÓRICAS: PREGUNAR CONFIRMACIÓN
    //         // LA PERSONA FUE CONTRATADA ANTES DEL SISTEMA, Y YA SE LE INGRESARON HISTÓRICAS: DEJAR INGRESAR
    //         // LA PERSONA FUE CONTRATADA DESPUÉS DEL SISTEMA: DEJAR INGRESAR
    //         // LA PERSONA FUE CONTRATADA ANTES DEL SISTEMA, PERO YA SE LE INGRESARON VACACIONES (NO HISTÓRICAS),
    //         //   LO QUE SIGNIFICA QUE YA SE CONFIRMÓ ANTERIORMENTE QUE NO SE LE QUIEREN INGRESAR HISTÓRICAS: DEJAR INGRESAR
    //         if (fechaContratoClone1.add(1, 'years').isBefore(fechaInicioSistema)) {

    //             if (historicas.length > 0) {
    //                 mostrarPantalla = 'formulario';

    //             } else {

    //                 if (hayVacaciones) {
    //                     mostrarPantalla = 'formulario';

    //                 } else {
    //                     await Swal.fire({
    //                         title: "Advertencia",
    //                         html:
    //                             `<span>
    //                                 Si el trabajador posee saldo de vacaciones pasadas sin utilizar,
    //                                 deberían ingresarse en el módulo de vacaciones históricas antes de ingresar vacaciones aquí.
    //                                 <br /><br />
    //                                 Si el trabajador no posee vacaciones antiguas sin utilizar, continúe.
    //                             </span>`,
    //                         width: 600,
    //                         icon: "warning",
    //                         showCancelButton: true,
    //                         confirmButtonColor: "#48B9FD",
    //                         cancelButtonColor: "#E60AA3",
    //                         confirmButtonText: "Continuar",
    //                         cancelButtonText: "Ir a módulo de vacaciones históricas",
    //                         allowOutsideClick: false,

    //                     }).then(async (result) => {

    //                         if (result.dismiss === 'cancel') {
    //                             window.location.href = `/CalendarioTrabajador?Trabajador=${trabajador._id}&directHistorico=true`
    //                         }
    //                     })
    //                 }
    //             }

    //         } else {
    //             mostrarPantalla = 'formulario';
    //         }

    //         /****** FIN LÓGICA HISTÓRICAS ******/


    //         /****** INICIO LÓGICA INPUTS PROGRESIVAS ******/

    //         let disabledProgresivas = false;
    //         let progresivasValue;

    //         if (trabajador.Requirio_Progresivas !== undefined) {

    //             if (trabajador.Requirio_Progresivas) {
    //                 disabledProgresivas = false;
    //                 progresivasValue = null;

    //             } else {
    //                 disabledProgresivas = true;
    //                 progresivasValue = 0;
    //             }

    //         } else {
    //             disabledProgresivas = true;
    //             progresivasValue = 0;
    //         }

    //         await this.setState({
    //             disabledProgresivas: disabledProgresivas,
    //             mostrarPantalla,
    //         })

    //         this.formRef.current.setFieldsValue({
    //             DisponiblesProgresivos: progresivasValue,
    //             FeriadoLegalProgresivos: progresivasValue,
    //             // SaldoProgresivos: progresivasValue,
    //         })

    //         /******** FIN LÓGICA INPUTS PROGRESIVAS ********/


    //         /******* INICIO LÓGICA SALDOS NORMALES Y PROGRESIVOS *******/

    //         // EL SALDO SE CALCULA CON:
    //         // SALDOS HISTÓRICOS + SALDO DE DÍAS TRABAJADOS - VACACIONES EN BITÁCORA DEL TRABAJADOR
    //         let saldoHistoricoNormales = 0;
    //         let saldoHistoricoProgresivos = 0;

    //         // + HISTÓRICAS
    //         if (historicas.length > 0) {

    //             historicas.forEach(registro => {
    //                 if (registro.TipoDeDias === 'Normales') saldoHistoricoNormales += registro.CantidadDiasHabiles;
    //                 if (registro.TipoDeDias === 'Progresivos') saldoHistoricoProgresivos += registro.CantidadDiasHabiles;
    //             });

    //         }

    //         // + TRABAJADAS
    //         let fechaContratoClone = fechaContrato.clone();

    //         // si la fecha de contrato del trabajador está antes del sistema, utilizar como 
    //         // fechaInicioCalculoTrabajo el año siguiente a lo que se determina como histórico
    //         let fechaInicioCalculoTrabajo;

    //         if (fechaContratoClone.add(1, 'year').isAfter(fechaInicioSistema)) {

    //             fechaInicioCalculoTrabajo = fechaContrato.clone();
    //         } else {

    //             let opcionesPeriodo = [];

    //             let cantidadPeriodos = fechaInicioSistema.diff(fechaContrato, 'years');
    //             cantidadPeriodos++;

    //             for (let index = 0; index < cantidadPeriodos; index++) {

    //                 let fechaInicioPeriodo = fechaContrato.clone().add(index, 'years');

    //                 opcionesPeriodo.push(fechaInicioPeriodo);
    //             }

    //             fechaInicioCalculoTrabajo = opcionesPeriodo[opcionesPeriodo.length - 1];
    //         }

    //         let diaActual = moment();
    //         let ctdadMesesTrabajados = diaActual.diff(fechaInicioCalculoTrabajo, 'months');

    //         let cantidadRegimenVacaciones = trabajador.Regimen_Vacaciones;
    //         let cantidadPorMes = cantidadRegimenVacaciones / 12;

    //         let saldoTrabajados = Math.trunc(cantidadPorMes * ctdadMesesTrabajados);
    //         let progresivosTrabajados = 0;

    //         // - DÍAS YA TOMADOS: sigue en ctrl+F: ref1


    //         /******** FIN LÓGICA SALDOS NORMALES Y PROGRESIVOS ********/


    //         /******* INICIO LÓGICA PERIODOS *******/

    //         // LOS PERIODOS SE COMPONEN DE 3 PARTES:
    //         // PERIODOS HISTÓRICOS + PERIODOS TRABAJADOS + PERIODO VIGENTE + PERIODOS FUTUROS (PARA VACACIONES NORMALES)

    //         let periodosNormales = [];
    //         let periodosProgresivos = [];

    //         // preparando array de saldos y periodos ya consumidos, para posteriormente restarlos 
    //         let bitacorasYaTomadas = [];

    //         vacacionesBitacoras.forEach(bitacora => {

    //             let cantidadEncontrada = bitacorasYaTomadas.filter((proceso) =>

    //                 moment(proceso.Fecha_Inicio).format('YYYY-MM-DD') ===
    //                 moment(bitacora.Fecha_Inicio).format('YYYY-MM-DD') &&
    //                 moment(proceso.Fecha_Fin).format('YYYY-MM-DD') ===
    //                 moment(bitacora.Fecha_Fin).format('YYYY-MM-DD') &&
    //                 proceso.TipoRegistroBitacoraTrabajador.toString() ===
    //                 bitacora.TipoRegistroBitacoraTrabajador.toString(),
    //             );

    //             if (cantidadEncontrada.length === 0) {
    //                 bitacorasYaTomadas.push(bitacora);
    //             }
    //         })

    //         let periodosYaTomados = [];

    //         // saldos consumidos en bitácora
    //         bitacorasYaTomadas.forEach(bitacora => {

    //             bitacora.FechasTrabajadasVacaciones.forEach(infoFecha => {
    //                 periodosYaTomados.push(infoFecha);
    //             });
    //         });

    //         //saldos consumidos en bonificaciones: descontando al periodo y al saldo
    //         let bonificacionesDocs = await this.props.obtenerBonificacionesTrabajador(idTrabajador);

    //         bonificacionesDocs.forEach(docBonificacion => {

    //             docBonificacion.FechasTrabajadas.forEach(periodo => {

    //                 if (periodo.tipoDias == 'Normales') normalesTomadas += periodo.diasNumberConsumidos;
    //                 if (periodo.tipoDias == 'Progresivos') progresivasTomadas += periodo.diasNumberConsumidos;

    //                 periodosYaTomados.push(periodo);
    //             });
    //         });

    //         // PERIODOS HISTÓRICOS
    //         if (historicas.length > 0) {

    //             historicas.forEach(periodoHistorico => {

    //                 let inicioUnix = periodoHistorico.FechaInicioUnix;
    //                 let finUnix = periodoHistorico.FechaFinUnix;

    //                 // VALIDACIÓN: restar saldo ya utilizado a periodo: y si queda en cero no se agrega.
    //                 let periodosUsados = periodosYaTomados.filter((periodo) => periodo.inicioUnix === inicioUnix && periodo.finUnix === finUnix && periodo.tipoDias === periodoHistorico.TipoDeDias);
    //                 let saldoResto = periodoHistorico.CantidadDiasHabiles;
    //                 let agregarFlag = true;

    //                 if (periodosUsados.length > 0) {

    //                     periodosUsados.forEach(periodo => {
    //                         saldoResto -= periodo.diasNumberConsumidos;
    //                     })

    //                     if (saldoResto > 1) {
    //                         periodoHistorico.CantidadDiasHabiles = saldoResto;
    //                     } else {
    //                         agregarFlag = false;
    //                     }
    //                 }

    //                 let objPeriodo = {
    //                     inicio: moment.unix(periodoHistorico.FechaInicioUnix),
    //                     fin: moment.unix(periodoHistorico.FechaFinUnix),

    //                     inicioUnix: periodoHistorico.FechaInicioUnix,
    //                     finUnix: periodoHistorico.FechaFinUnix,

    //                     saldo: periodoHistorico.CantidadDiasHabiles,
    //                 }

    //                 if (agregarFlag) {
    //                     if (periodoHistorico.TipoDeDias === 'Normales') periodosNormales.push(objPeriodo);
    //                     if (periodoHistorico.TipoDeDias === 'Progresivos') periodosProgresivos.push(objPeriodo);
    //                 }
    //             })
    //         }

    //         //PERIODOS TRABAJADOS NORMALES + EL ÚLTIMO PERIODO ES EL VIGENTE
    //         let fechaActual = moment();
    //         let cantidadAniosTrabajados = fechaActual.diff(fechaInicioCalculoTrabajo, 'years');
    //         cantidadAniosTrabajados++;

    //         let inicioPeriodosFuturos;

    //         for (let index = 0; index < cantidadAniosTrabajados; index++) {

    //             let fechaInicioPeriodo = fechaInicioCalculoTrabajo.clone().add(index, 'years');
    //             let fechaFinPeriodo = fechaInicioCalculoTrabajo.clone().add(index + 1, 'years').subtract(1, 'days');

    //             let inicioUnix = fechaInicioPeriodo.unix();
    //             let finUnix = fechaFinPeriodo.unix();

    //             let saldoNormal;
    //             let esPeriodoVigente = false;

    //             // si es el último, es el periodo vigente, el cual tiene saldo según meses trabajados
    //             // si no, es un año ya trabajado, por lo que tiene todo el saldo de su régimen
    //             if (index === cantidadAniosTrabajados - 1) {

    //                 inicioPeriodosFuturos = fechaInicioPeriodo.clone().add(1, 'year');

    //                 let mesesTrabajadosEnPeriodoVigente = fechaActual.diff(fechaInicioPeriodo, 'months');
    //                 let saldoDeVigente = Math.trunc(cantidadPorMes * mesesTrabajadosEnPeriodoVigente);

    //                 saldoNormal = saldoDeVigente;
    //                 esPeriodoVigente = true;

    //             } else {
    //                 saldoNormal = cantidadRegimenVacaciones;
    //             }

    //             // VALIDACIÓN: restar saldo ya utilizado a periodo: y si queda en cero no se agrega.
    //             let periodosUsados = periodosYaTomados.filter((periodo) => periodo.inicioUnix === inicioUnix && periodo.finUnix === finUnix && periodo.tipoDias === 'Normales');
    //             let saldoResto = saldoNormal;

    //             if (periodosUsados.length > 0) {

    //                 periodosUsados.forEach(periodo => {
    //                     saldoResto -= periodo.diasNumberConsumidos;
    //                 })

    //                 if (saldoResto > 1) {
    //                     saldoNormal = saldoResto;
    //                 } else {
    //                     continue;
    //                 }
    //             }

    //             let nuevoPeriodo = {

    //                 inicio: fechaInicioPeriodo,
    //                 fin: fechaFinPeriodo,
    //                 inicioUnix,
    //                 finUnix,

    //                 saldo: saldoNormal,
    //                 esPeriodoVigente,
    //             };


    //             // si la fecha inicio y fin de acá están en un periodo de los periodosYaTomados = [],
    //             // entonces:
    //             //  restar saldo consumido al de acá y después
    //             //  si es saldo es 0, no agregar (o agregar con saldo 0)

    //             periodosNormales.push(nuevoPeriodo);
    //         }

    //         //PERIODOS FUTUROS (APLICAN SÓLO A VACACIONES NORMALES)
    //         let cantidadPeriodosFuturos = 10;

    //         for (let index = 0; index < cantidadPeriodosFuturos; index++) {

    //             let fechaInicioPeriodo = inicioPeriodosFuturos.clone().add(index, 'year');
    //             let fechaFinPeriodo = inicioPeriodosFuturos.clone().add(index + 1, 'years');

    //             let inicioUnix = fechaInicioPeriodo.unix();
    //             let finUnix = fechaFinPeriodo.unix();

    //             // VALIDACIÓN: restar saldo ya utilizado a periodo: y si queda en cero no se agrega.
    //             let periodosUsados = periodosYaTomados.filter((periodo) => periodo.inicioUnix === inicioUnix && periodo.finUnix === finUnix && periodo.tipoDias === 'Normales');
    //             let saldoResto = cantidadRegimenVacaciones;
    //             let saldoPeriodo;

    //             if (periodosUsados.length > 0) {

    //                 periodosUsados.forEach(periodo => {
    //                     saldoResto -= periodo.diasNumberConsumidos;
    //                 })

    //                 if (saldoResto > 1) {
    //                     saldoPeriodo = saldoResto;
    //                 } else {
    //                     continue;
    //                 }
    //             } else {
    //                 saldoPeriodo = cantidadRegimenVacaciones;
    //             }

    //             let nuevoPeriodo = {

    //                 inicio: fechaInicioPeriodo,
    //                 fin: fechaFinPeriodo,
    //                 inicioUnix,
    //                 finUnix,

    //                 saldo: saldoPeriodo,
    //                 isFuturo: true,
    //             };

    //             periodosNormales.push(nuevoPeriodo);
    //         }

    //         //PERIODOS TRABAJADOS PROGRESIVOS
    //         let fechaInicioCalculoProgresivos;
    //         let fechaContratoClone2 = fechaContrato.clone();

    //         // Si ya cumplió 120 meses con empladores anteriores, simplemente calcular a partir de la fecha de contrato,
    //         // si no, utilizar la fecha del inicio del periodo en el que cumplió los 120 meses
    //         if (trabajador.Meses_Empleadores_Anteriores >= 120) {

    //             fechaInicioCalculoProgresivos = fechaContratoClone2;

    //         } else {

    //             let cantidadMesesFaltantes = 120 - trabajador.Meses_Empleadores_Anteriores;
    //             let fechaCumplio120Meses = fechaContratoClone2.add(cantidadMesesFaltantes, 'months');

    //             let fechaContratoClone3 = fechaContrato.clone();
    //             let ctdadPeriodosARevisar = fechaActual.diff(fechaContratoClone3, 'years');

    //             for (let index = 0; index < ctdadPeriodosARevisar; index++) {

    //                 let fechaInicio = fechaContratoClone3.clone().add(index, 'years');
    //                 let fechaFin = fechaContratoClone3.clone().add(index + 1, 'years');

    //                 if (fechaCumplio120Meses.isBetween(fechaInicio, fechaFin)) {

    //                     fechaInicioCalculoProgresivos = fechaInicio.add(1, 'year');
    //                 }

    //             }

    //         }

    //         let cantidadAniosProgresivos = fechaActual.diff(fechaInicioCalculoProgresivos, 'years')

    //         for (let index = 0; index < cantidadAniosProgresivos; index++) {

    //             if (index >= 2) {

    //                 // cantidadDiasProgresivos siempre saca la cantidad de días que debe sumar
    //                 // para el periodo
    //                 let cantidadDiasProgresivos = Math.trunc((index + 1) / 3);

    //                 let fechaInicioPeriodo = fechaInicioCalculoProgresivos.clone().add(index, 'years');
    //                 let fechaFinPeriodo = fechaInicioCalculoProgresivos.clone().add(index + 1, 'years').subtract(1, 'day');

    //                 let inicioUnix = fechaInicioPeriodo.unix();
    //                 let finUnix = fechaFinPeriodo.unix();

    //                 let agregarFlag = false;

    //                 if (fechaInicioPeriodo.clone().add(1, 'year').isAfter(trabajador.Fecha_Progresivas) &&
    //                     fechaInicioPeriodo.clone().add(1, 'year').isAfter(fechaInicioCalculoTrabajo)) {

    //                     progresivosTrabajados += cantidadDiasProgresivos;
    //                     agregarFlag = true;
    //                 }

    //                 // VALIDACIÓN: restar saldo ya utilizado a periodo: y si queda en cero no se agrega.
    //                 let periodosUsados = periodosYaTomados.filter((periodo) => periodo.inicioUnix === inicioUnix && periodo.finUnix === finUnix && periodo.tipoDias === 'Progresivos');
    //                 let saldoResto = cantidadDiasProgresivos;

    //                 if (periodosUsados.length > 0) {

    //                     periodosUsados.forEach(periodo => {
    //                         saldoResto -= periodo.diasNumberConsumidos;
    //                     })

    //                     if (saldoResto > 1) {
    //                         cantidadDiasProgresivos = saldoResto;
    //                     } else {
    //                         continue;
    //                     }
    //                 }

    //                 let nuevoPeriodo = {

    //                     inicio: fechaInicioPeriodo,
    //                     fin: fechaFinPeriodo,
    //                     inicioUnix,
    //                     finUnix,

    //                     saldo: cantidadDiasProgresivos,
    //                 };

    //                 // ENTRAN SÓLO LOS PERIODOS QUE ESTÉN DESDE EL CUAL EL TRABAJADOR PIDIÓ PROGRESIVAS
    //                 // && QUE SON DESPUÉS DE LOS HISTÓRICOS
    //                 if (agregarFlag) {
    //                     periodosProgresivos.push(nuevoPeriodo);
    //                 }
    //             }
    //         }

    //         //ctrl+F: ref1
    //         let resultadoNormales = (saldoHistoricoNormales + saldoTrabajados) - normalesTomadas;
    //         let resultadoProgresivos = (saldoHistoricoProgresivos + progresivosTrabajados) - progresivasTomadas;

    //         this.formRef.current.setFieldsValue({

    //             cantidadTomadas: 1,

    //             DisponiblesVacaciones: resultadoNormales,
    //             DisponiblesProgresivos: resultadoProgresivos,
    //             esBonificacion: false,
    //         })

    //         this.setState({
    //             periodosNormales,
    //             periodosProgresivos,
    //             vacacionesBitacoras,
    //             disabledRadios: false,
    //             disabledInputs: true,
    //         })

    //         /******* FIN LÓGICA PERIODOS *******/
    //     }
    // };

    handleChangeTrabajadorNew = async (idTrabajador) => {

        let trabajador = this.props.trabajadores.find(x => x._id === idTrabajador);
        if (trabajador) {
            this.setState({loading:true});
            const { idEmpresa } = this.state;
            await this.props.insertarTrabajador(idTrabajador);

            //obtenemos todos los contratos del trabajador
            await this.props.traer_todos_contratos_trabajador(idTrabajador, idEmpresa);

            //se obtienen las bitacoras historicas del trabaajdor
            const newBitacorasHistorico = await this.props.traer_todos_las_vacaciones_historicos(idTrabajador);


            await this.formRef.current.resetFields([
                'TipoDias',
                'FechasTrabajadas',
                'FechasEfectivas',
                'DisponiblesVacaciones',
                'DisponiblesProgresivos',
                'DisponiblesTotal',
                'FeriadoLegalVacaciones',
                'FeriadoLegalProgresivos',
                'FeriadoLegalTotal',
                'SaldoVacaciones',
                'SaldoProgresivos',
                'SaldoTotal',
                'Correo',
                'cantidadTomadas',
                'contrato',
                'Observacion'
            ]);

            this.setState({
                fechasHistoricasVacaciones: newBitacorasHistorico,
                loading:false,
                fechasBloqueadas: [],
                bitacora: []
            });
        }
    };

    handleContrato = async (idContrato) => {
        try{
            this.setState({loading:true})
            const idTrabajador = this.props.Trabajador;
            let trabajador = this.props.trabajadores.find(x => x._id === idTrabajador);
            if(this.formRef.current !== undefined && this.formRef.current !== null){
                if (trabajador) {

                    //let response = await this.props.traer_todos_las_fechas_trabajador(idTrabajador);
                    let bitacorasMostrar = await this.props.traer_todos_las_fechas_trabajador_por_contrato(idTrabajador,idContrato);

                    //SE OBTIENE TODOS LOS PERIODOS DE TRABAJO DE LA PERSONA
                    let objVacaciones = await this.props.traer_todos_las_vacaciones_trabajador_por_contrato(idTrabajador,idContrato);
                    if(objVacaciones === null || objVacaciones === undefined){
                        console.error('error, no se encontraron datos')
                        return;
                    }

                    //console.log(objVacaciones)
                    let vacacionesTrabajador = objVacaciones.data;
                    let contratoBuscado = objVacaciones.contrato;

                    //SE DECLARAN LAS VARIABLES PARA COMENZAR CON LA LOGICA
                    const ID_VACACIONES = '5f3eebac978b284b68ebced5';
                    //let fechas = [];

                    let normalesTomadas = 0;
                    let progresivasTomadas = 0;
                    let hayVacaciones = false;
                    
                    let vacacionesBitacoras = [];

                    // Recorriendo bitácora: para validar si es que hay vacaciones y en ese caso,
                    // contar cuántas son, para descontárla al saldo después.
                    vacacionesTrabajador.forEach(bitacora => {


                        //Si es bitácora de vacaciones, contarla.
                        if (bitacora.TipoRegistroBitacoraTrabajador === ID_VACACIONES) {

                            //validación para que solo cuente los días hábiles de la bitácora
                            let numeroSemanalFecha = moment(bitacora.Fecha).add(3, 'hours').isoWeekday();
                            let esFeriadoFlag = false;

                            vacacionesBitacoras.push(bitacora);

                            if (![6, 7].includes(numeroSemanalFecha)) {

                                //validación: si es feriado, no contarlo
                                this.state.feriados.forEach((feriado) => {

                                    let feriadoMoment = moment(feriado.Fecha).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
                                    let bitacoraMoment = moment(bitacora.Fecha).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });

                                    if (feriadoMoment.isSame(bitacoraMoment)) {
                                        esFeriadoFlag = true;
                                    }
                                })


                                if (!esFeriadoFlag) {
                                    if (bitacora.Vacaciones.TipoDias === 'Normales') normalesTomadas++
                                    if (bitacora.Vacaciones.TipoDias === 'Progresivos') progresivasTomadas++;

                                    hayVacaciones = true;
                                }
                            }
                        }
                    });

                    const responseDFiniquitados = await traer_todos_las_fechas_trabajador_descansos_finiquitados(idTrabajador);

                    responseDFiniquitados.forEach(finiquito => {
                        let newFiniquito = {...finiquito};
                        newFiniquito.finiquitoDescanso = true;
                        newFiniquito.TipoRegistroBitacoraTrabajador = 'descansoFiniquitado';
                        bitacorasMostrar.bitacoras.push(newFiniquito);
                    });

                    await this.formRef.current.resetFields([
                        'TipoDias',
                        'FechasTrabajadas',
                        'FechasEfectivas',
                        'DisponiblesVacaciones',
                        'DisponiblesProgresivos',
                        'DisponiblesTotal',
                        'FeriadoLegalVacaciones',
                        'FeriadoLegalProgresivos',
                        'FeriadoLegalTotal',
                        'SaldoVacaciones',
                        'SaldoProgresivos',
                        'SaldoTotal',
                        'Correo',
                        'cantidadTomadas',
                        'Observacion'
                    ]);

                    await this.setState({
                        fechasBloqueadas: bitacorasMostrar.data.bitNoModificar,
                        bitacora: bitacorasMostrar.data.bitacoras,
                        mostrarCalendario: true,
                    });

                    /****** INICIO LÓGICA HISTÓRICAS ******/

                    let usuario = localStorage.getItem("usuario-people-manage");
                    usuario = JSON.parse(usuario);
                    const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

                    let fechaInicioSistema = moment.unix(usuario.EmpresaSeleccionada.Empresa.Fecha_Inicio_Sistema_Unix);
                    let cancelarFlag = false;
                    //se intercambian las variables (Trabajador => contratoBuscado) para indentificar el contrato seleccionado
                    //se dejara comentado en donde se realizen los cambios

                    //** if (!contratoBuscado.Contrato_Fecha_Inicio) {
                    if (!contratoBuscado.Contrato_Fecha_Inicio) {

                        await Swal.fire({
                            title: "Error",
                            text: `Este trabajador no tiene registro de su fecha de contrato, debe ingresarse en el registro de trabajadores antes de proseguir.`,
                            icon: "warning",
                            confirmButtonColor: "#1FC600",
                            confirmButtonText: "Ok",

                        }).then(async () => {
                            cancelarFlag = true;
                        })
                    }

                    if (cancelarFlag) {
                        return;
                    }

                    //** let fechaContrato = trabajador.Contrato_Fecha_Inicio_Unix ? moment.unix(trabajador.Contrato_Fecha_Inicio_Unix) : moment(trabajador.Contrato_Fecha_Inicio);
                    let fechaContrato = contratoBuscado.Contrato_Fecha_Inicio_Unix ? moment.unix(contratoBuscado.Contrato_Fecha_Inicio_Unix) : moment(contratoBuscado.Contrato_Fecha_Inicio);


                    //estas vacaciones son las que existen antes del software (posiblemente haya problemas en un futuro con este punto ya que no se ligan a ningun contrato)
                    //let historicas = await this.props.ObtenerVacacionesHistoricas(trabajador._id, idEmpresa);
                    //se cambia logica ahora se buscan en base al contrato
                    let RespHistoricas = await this.props.ObtenerVacacionesHistoricasPorContrato(trabajador._id, idEmpresa,idContrato);

                    //se agrega valicación para no dejar que se caiga
                    if(RespHistoricas.status !== 200){
                        Swal.fire({
                            title: "Ups",
                            text: "Error al obtener vacaciones historicas",
                            icon: "warning",
                            confirmButtonColor: "#E60AA3",
                            confirmButtonText: "Ok",
                        });
                        return;
                    }
                    let historicas = RespHistoricas.data;

                    let mostrarPantalla;

                    let fechaContratoClone1 = fechaContrato.clone();

                    // HAY CUATRO CASOS POSIBLES:
                    // LA PERSONA FUE CONTRATADA ANTES DEL SISTEMA, Y NO LE HAN INGRESADO HISTÓRICAS: PREGUNAR CONFIRMACIÓN
                    // LA PERSONA FUE CONTRATADA ANTES DEL SISTEMA, Y YA SE LE INGRESARON HISTÓRICAS: DEJAR INGRESAR
                    // LA PERSONA FUE CONTRATADA DESPUÉS DEL SISTEMA: DEJAR INGRESAR
                    // LA PERSONA FUE CONTRATADA ANTES DEL SISTEMA, PERO YA SE LE INGRESARON VACACIONES (NO HISTÓRICAS),
                    //   LO QUE SIGNIFICA QUE YA SE CONFIRMÓ ANTERIORMENTE QUE NO SE LE QUIEREN INGRESAR HISTÓRICAS: DEJAR INGRESAR

                    if (fechaContratoClone1.add(1, 'years').isBefore(fechaInicioSistema)) {

                        if (historicas.length > 0) {
                            mostrarPantalla = 'formulario';

                        } else {

                            if (hayVacaciones) {
                                mostrarPantalla = 'formulario';

                            } else {
                                await Swal.fire({
                                    title: "Advertencia",
                                    html:
                                        `<span>
                                            Si el trabajador posee saldo de vacaciones pasadas sin utilizar,
                                            deberían ingresarse en el módulo de vacaciones históricas antes de ingresar vacaciones aquí.
                                            <br /><br />
                                            Si el trabajador no posee vacaciones antiguas sin utilizar, continúe.
                                        </span>`,
                                    width: 600,
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#48B9FD",
                                    cancelButtonColor: "#E60AA3",
                                    confirmButtonText: "Continuar",
                                    cancelButtonText: "Ir a módulo de vacaciones históricas",
                                    allowOutsideClick: false,

                                }).then(async (result) => {

                                    if (result.dismiss === 'cancel') {
                                        window.location.href = `/CalendarioTrabajadorNew?Trabajador=${trabajador._id}&directHistorico=true`
                                    }
                                })
                            }
                        }

                    } else {
                        mostrarPantalla = 'formulario';
                    }

                    /****** FIN LÓGICA HISTÓRICAS ******/


                    /****** INICIO LÓGICA INPUTS PROGRESIVAS ******/

                    let disabledProgresivas = false;
                    let progresivasValue;

                    if (trabajador.Requirio_Progresivas !== undefined) {

                        if (trabajador.Requirio_Progresivas) {
                            disabledProgresivas = false;
                            progresivasValue = null;

                        } else {
                            disabledProgresivas = true;
                            progresivasValue = 0;
                        }

                    } else {
                        disabledProgresivas = true;
                        progresivasValue = 0;
                    }


                    await this.setState({
                        disabledProgresivas: disabledProgresivas,
                        mostrarPantalla,
                    });

                    
                    if(this.formRef.current === null){
                        return;
                    }
                    this.formRef.current.setFieldsValue({
                        DisponiblesProgresivos: progresivasValue,
                        FeriadoLegalProgresivos: progresivasValue,
                        // SaldoProgresivos: progresivasValue,
                    });

                    /******** FIN LÓGICA INPUTS PROGRESIVAS ********/


                    /******* INICIO LÓGICA SALDOS NORMALES Y PROGRESIVOS *******/

                    // EL SALDO SE CALCULA CON:
                    // SALDOS HISTÓRICOS + SALDO DE DÍAS TRABAJADOS - VACACIONES EN BITÁCORA DEL TRABAJADOR
                    let saldoHistoricoNormales = 0;
                    let saldoHistoricoProgresivos = 0;

                    // + HISTÓRICAS
                    if (historicas.length > 0) {

                        historicas.forEach(registro => {
                            if (registro.TipoDeDias === 'Normales') saldoHistoricoNormales += registro.CantidadDiasHabiles;
                            if (registro.TipoDeDias === 'Progresivos') saldoHistoricoProgresivos += registro.CantidadDiasHabiles;
                        });

                    }

                    // + TRABAJADAS
                    let fechaContratoClone = fechaContrato.clone();

                    // si la fecha de contrato del trabajador está antes del sistema, utilizar como 
                    // fechaInicioCalculoTrabajo el año siguiente a lo que se determina como histórico
                    let fechaInicioCalculoTrabajo;
                    
                    if (fechaContratoClone.add(1, 'year').isAfter(fechaInicioSistema)) {
                        fechaInicioCalculoTrabajo = fechaContrato.clone();
                    } else {

                        let opcionesPeriodo = [];

                        let cantidadPeriodos = fechaInicioSistema.diff(fechaContrato, 'years');
                        cantidadPeriodos++;

                        for (let index = 0; index < cantidadPeriodos; index++) {

                            let fechaInicioPeriodo = fechaContrato.clone().add(index, 'years');

                            opcionesPeriodo.push(fechaInicioPeriodo);
                        }

                        fechaInicioCalculoTrabajo = opcionesPeriodo[opcionesPeriodo.length - 1];
                    }


                    /////////////////////////SE REEMPLAZO EL DIA ACTUAL POR LA LOGICA DE CONTRATOS
                    //let diaActual = moment();
                    let diaActual;

                    if(contratoBuscado.Tipo_Contrato === 'Indefinido'){
                        if(contratoBuscado.Contrato_Finiquitado === true){
                            let newFinContrato = convertirFechaString(moment.utc(contratoBuscado.Fecha_Finiquito), 2).fechaString;
                            diaActual = moment.utc(newFinContrato);
                        }else{
                            diaActual = moment.utc();
                        }
                    }else{
                        if(contratoBuscado.Contrato_Finiquitado === true){
                            let newFinContrato = convertirFechaString(moment.utc(contratoBuscado.Fecha_Finiquito), 2).fechaString;
                            diaActual = moment.utc(newFinContrato);
                        }else{
                            let newFinContrato = convertirFechaString(moment.utc(contratoBuscado.Contrato_Fecha_Fin), 2).fechaString;
                            diaActual = moment.utc(newFinContrato);
                        }
                    }

                    let ctdadMesesTrabajados = diaActual.diff(fechaInicioCalculoTrabajo, 'months');

                    let cantidadRegimenVacaciones = trabajador.Regimen_Vacaciones;
                    let cantidadPorMes = cantidadRegimenVacaciones / 12;

                    let saldoTrabajados = Math.trunc(cantidadPorMes * ctdadMesesTrabajados);
                    let progresivosTrabajados = 0;

                    // - DÍAS YA TOMADOS: sigue en ctrl+F: ref1


                    /******** FIN LÓGICA SALDOS NORMALES Y PROGRESIVOS ********/


                    /******* INICIO LÓGICA PERIODOS *******/

                    // LOS PERIODOS SE COMPONEN DE 3 PARTES:
                    // PERIODOS HISTÓRICOS + PERIODOS TRABAJADOS + PERIODO VIGENTE + PERIODOS FUTUROS (PARA VACACIONES NORMALES)

                    let periodosNormales = [];
                    let periodosProgresivos = [];

                    // preparando array de saldos y periodos ya consumidos, para posteriormente restarlos 
                    let bitacorasYaTomadas = [];

                    vacacionesBitacoras.forEach(bitacora => {

                        let cantidadEncontrada = bitacorasYaTomadas.filter((proceso) =>

                            moment(proceso.Fecha_Inicio).format('YYYY-MM-DD') ===
                            moment(bitacora.Fecha_Inicio).format('YYYY-MM-DD') &&
                            moment(proceso.Fecha_Fin).format('YYYY-MM-DD') ===
                            moment(bitacora.Fecha_Fin).format('YYYY-MM-DD') &&
                            proceso.TipoRegistroBitacoraTrabajador.toString() ===
                            bitacora.TipoRegistroBitacoraTrabajador.toString(),
                        );

                        if (cantidadEncontrada.length === 0) {
                            bitacorasYaTomadas.push(bitacora);
                        }
                    });

                    let periodosYaTomados = [];

                    // saldos consumidos en bitácora
                    bitacorasYaTomadas.forEach(bitacora => {

                        bitacora.FechasTrabajadasVacaciones.forEach(infoFecha => {
                            periodosYaTomados.push(infoFecha);
                        });
                    });

                    //saldos consumidos en bonificaciones: descontando al periodo y al saldo
                    let bonificacionesDocs = await this.props.obtenerBonificacionesTrabajadorPorContrato(idTrabajador,idContrato);

                    bonificacionesDocs.forEach(docBonificacion => {

                        docBonificacion.FechasTrabajadas.forEach(periodo => {

                            if (periodo.tipoDias == 'Normales') normalesTomadas += periodo.diasNumberConsumidos;
                            if (periodo.tipoDias == 'Progresivos') progresivasTomadas += periodo.diasNumberConsumidos;

                            periodosYaTomados.push(periodo);
                        });
                    });


                    // PERIODOS HISTÓRICOS
                    if (historicas.length > 0) {

                        historicas.forEach(periodoHistorico => {

                            let inicioUnix = periodoHistorico.FechaInicioUnix;
                            let finUnix = periodoHistorico.FechaFinUnix;

                            // VALIDACIÓN: restar saldo ya utilizado a periodo: y si queda en cero no se agrega.
                            let periodosUsados = periodosYaTomados.filter((periodo) => periodo.inicioUnix === inicioUnix && periodo.finUnix === finUnix && periodo.tipoDias === periodoHistorico.TipoDeDias);
                            let saldoResto = periodoHistorico.CantidadDiasHabiles;
                            let agregarFlag = true;

                            if (periodosUsados.length > 0) {

                                periodosUsados.forEach(periodo => {
                                    saldoResto -= periodo.diasNumberConsumidos;
                                })

                                if (saldoResto > 1) {
                                    periodoHistorico.CantidadDiasHabiles = saldoResto;
                                } else {
                                    agregarFlag = false;
                                }
                            }

                            let objPeriodo = {
                                inicio: moment.unix(periodoHistorico.FechaInicioUnix),
                                fin: moment.unix(periodoHistorico.FechaFinUnix),

                                inicioUnix: periodoHistorico.FechaInicioUnix,
                                finUnix: periodoHistorico.FechaFinUnix,

                                saldo: periodoHistorico.CantidadDiasHabiles,
                            }

                            if (agregarFlag) {
                                if (periodoHistorico.TipoDeDias === 'Normales') periodosNormales.push(objPeriodo);
                                if (periodoHistorico.TipoDeDias === 'Progresivos') periodosProgresivos.push(objPeriodo);
                            }
                        })
                    }

                    //PERIODOS TRABAJADOS NORMALES + EL ÚLTIMO PERIODO ES EL VIGENTE
                    let fechaActual;

                    if(contratoBuscado.Tipo_Contrato === 'Indefinido'){
                        if(contratoBuscado.Contrato_Finiquitado === true){
                            let newFinContrato = convertirFechaString(moment.utc(contratoBuscado.Fecha_Finiquito), 2).fechaString;
                            fechaActual = moment.utc(newFinContrato);
                        }else{
                            fechaActual = moment.utc();
                        }
                    }else{
                        if(contratoBuscado.Contrato_Finiquitado === true){
                            let newFinContrato = convertirFechaString(moment.utc(contratoBuscado.Fecha_Finiquito), 2).fechaString;
                            fechaActual = moment.utc(newFinContrato);
                        }else{
                            let newFinContrato = convertirFechaString(moment.utc(contratoBuscado.Contrato_Fecha_Fin), 2).fechaString;
                            fechaActual = moment.utc(newFinContrato);
                        }
                    }

                    let cantidadAniosTrabajados = fechaActual.diff(fechaInicioCalculoTrabajo, 'years');
                    //console.log(cantidadAniosTrabajados);
                    cantidadAniosTrabajados++;

                    let inicioPeriodosFuturos;

                    for (let index = 0; index < cantidadAniosTrabajados; index++) {

                        let fechaInicioPeriodo = fechaInicioCalculoTrabajo.clone().add(index, 'years');
                        let fechaFinPeriodo = fechaInicioCalculoTrabajo.clone().add(index + 1, 'years').subtract(1, 'days');

                        let inicioUnix = fechaInicioPeriodo.unix();
                        let finUnix = fechaFinPeriodo.unix();

                        let saldoNormal;
                        let esPeriodoVigente = false;

                        // si es el último, es el periodo vigente, el cual tiene saldo según meses trabajados
                        // si no, es un año ya trabajado, por lo que tiene todo el saldo de su régimen
                        if (index === cantidadAniosTrabajados - 1) {

                            inicioPeriodosFuturos = fechaInicioPeriodo.clone().add(1, 'year');

                            let mesesTrabajadosEnPeriodoVigente = fechaActual.diff(fechaInicioPeriodo, 'months');
                            let saldoDeVigente = Math.trunc(cantidadPorMes * mesesTrabajadosEnPeriodoVigente);

                            saldoNormal = saldoDeVigente;
                            esPeriodoVigente = true;

                        } else {
                            saldoNormal = cantidadRegimenVacaciones;
                        }

                        // VALIDACIÓN: restar saldo ya utilizado a periodo: y si queda en cero no se agrega.
                        let periodosUsados = periodosYaTomados.filter((periodo) => periodo.inicioUnix === inicioUnix && periodo.finUnix === finUnix && periodo.tipoDias === 'Normales');
                        let saldoResto = saldoNormal;

                        if (periodosUsados.length > 0) {

                            periodosUsados.forEach(periodo => {
                                saldoResto -= periodo.diasNumberConsumidos;
                            })

                            if (saldoResto > 1) {
                                saldoNormal = saldoResto;
                            } else {
                                continue;
                            }
                        }

                        let nuevoPeriodo = {

                            inicio: fechaInicioPeriodo,
                            fin: fechaFinPeriodo,
                            inicioUnix,
                            finUnix,

                            saldo: saldoNormal,
                            esPeriodoVigente,
                        };


                        // si la fecha inicio y fin de acá están en un periodo de los periodosYaTomados = [],
                        // entonces:
                        //  restar saldo consumido al de acá y después
                        //  si es saldo es 0, no agregar (o agregar con saldo 0)

                        periodosNormales.push(nuevoPeriodo);
                    }

                    //PERIODOS FUTUROS (APLICAN SÓLO A VACACIONES NORMALES)

                    ///////////*************************CONTROLAR QUE EL CONTRATO NO HAYA TERMINADO**********************************//////////////////////
                    let cantidadPeriodosFuturos = -1;
                    //console.log(contratoBuscado)
                    if(contratoBuscado.Tipo_Contrato === 'Indefinido'){
                        if(contratoBuscado.Contrato_Finiquitado === true){
                            let newFinContrato = convertirFechaString(moment.utc(contratoBuscado.Fecha_Finiquito), 2).fechaString;
                            if(moment.utc(newFinContrato).isBefore(moment.utc())){
                                const diffAnios = moment.utc().diff(moment.utc(newFinContrato),'years');
                                cantidadPeriodosFuturos = diffAnios;
                            }else{
                                cantidadPeriodosFuturos = -1;
                            }
                        }else{
                            cantidadPeriodosFuturos = 10;
                        }
                    }else{
                        if(contratoBuscado.Contrato_Finiquitado === true){
                            let newFinContrato = convertirFechaString(moment.utc(contratoBuscado.Fecha_Finiquito), 2).fechaString;
                            if(moment.utc(newFinContrato).isBefore(moment.utc())){
                                const diffAnios = moment.utc().diff(moment.utc(newFinContrato),'years');
                                cantidadPeriodosFuturos = diffAnios;
                            }else{
                                cantidadPeriodosFuturos = -1;
                            }
                        }else{
                            let newFinContrato = convertirFechaString(moment.utc(contratoBuscado.Contrato_Fecha_Fin), 2).fechaString;
                            if(moment.utc(newFinContrato).isBefore(moment.utc())){
                                const diffAnios = moment.utc().diff(moment.utc(newFinContrato),'years');
                                cantidadPeriodosFuturos = diffAnios;
                            }else{
                                cantidadPeriodosFuturos = -1;
                            }
                        }
                    }

                    for (let index = 0; index < cantidadPeriodosFuturos; index++) {

                        let fechaInicioPeriodo = inicioPeriodosFuturos.clone().add(index, 'year');
                        let fechaFinPeriodo = inicioPeriodosFuturos.clone().add(index + 1, 'years');

                        let inicioUnix = fechaInicioPeriodo.unix();
                        let finUnix = fechaFinPeriodo.unix();

                        // VALIDACIÓN: restar saldo ya utilizado a periodo: y si queda en cero no se agrega.
                        let periodosUsados = periodosYaTomados.filter((periodo) => periodo.inicioUnix === inicioUnix && periodo.finUnix === finUnix && periodo.tipoDias === 'Normales');
                        let saldoResto = cantidadRegimenVacaciones;
                        let saldoPeriodo;

                        if (periodosUsados.length > 0) {

                            periodosUsados.forEach(periodo => {
                                saldoResto -= periodo.diasNumberConsumidos;
                            })

                            if (saldoResto > 1) {
                                saldoPeriodo = saldoResto;
                            } else {
                                continue;
                            }
                        } else {
                            saldoPeriodo = cantidadRegimenVacaciones;
                        }

                        let nuevoPeriodo = {

                            inicio: fechaInicioPeriodo,
                            fin: fechaFinPeriodo,
                            inicioUnix,
                            finUnix,

                            saldo: saldoPeriodo,
                            isFuturo: true,
                        };

                        periodosNormales.push(nuevoPeriodo);
                    }

                    //PERIODOS TRABAJADOS PROGRESIVOS
                    let fechaInicioCalculoProgresivos;
                    let fechaContratoClone2 = fechaContrato.clone();

                    // Si ya cumplió 120 meses con empladores anteriores, simplemente calcular a partir de la fecha de contrato,
                    // si no, utilizar la fecha del inicio del periodo en el que cumplió los 120 meses
                    if (trabajador.Meses_Empleadores_Anteriores >= 120) {

                        fechaInicioCalculoProgresivos = fechaContratoClone2;

                    } else {

                        let cantidadMesesFaltantes = 120 - trabajador.Meses_Empleadores_Anteriores;
                        let fechaCumplio120Meses = fechaContratoClone2.add(cantidadMesesFaltantes, 'months');

                        let fechaContratoClone3 = fechaContrato.clone();
                        let ctdadPeriodosARevisar = fechaActual.diff(fechaContratoClone3, 'years');

                        for (let index = 0; index < ctdadPeriodosARevisar; index++) {

                            let fechaInicio = fechaContratoClone3.clone().add(index, 'years');
                            let fechaFin = fechaContratoClone3.clone().add(index + 1, 'years');

                            if (fechaCumplio120Meses.isBetween(fechaInicio, fechaFin)) {

                                fechaInicioCalculoProgresivos = fechaInicio.add(1, 'year');
                            }

                        }

                    }

                    let cantidadAniosProgresivos = fechaActual.diff(fechaInicioCalculoProgresivos, 'years')

                    for (let index = 0; index < cantidadAniosProgresivos; index++) {

                        if (index >= 2) {

                            // cantidadDiasProgresivos siempre saca la cantidad de días que debe sumar
                            // para el periodo
                            let cantidadDiasProgresivos = Math.trunc((index + 1) / 3);

                            let fechaInicioPeriodo = fechaInicioCalculoProgresivos.clone().add(index, 'years');
                            let fechaFinPeriodo = fechaInicioCalculoProgresivos.clone().add(index + 1, 'years').subtract(1, 'day');

                            let inicioUnix = fechaInicioPeriodo.unix();
                            let finUnix = fechaFinPeriodo.unix();

                            if(index === 0){
                                console.log('primera iteración')
                            }

                            let agregarFlag = false;

                            if (fechaInicioPeriodo.clone().add(1, 'year').isAfter(trabajador.Fecha_Progresivas) &&
                                fechaInicioPeriodo.clone().add(1, 'year').isAfter(fechaInicioCalculoTrabajo)) {

                                progresivosTrabajados += cantidadDiasProgresivos;
                                agregarFlag = true;
                            }

                            // VALIDACIÓN: restar saldo ya utilizado a periodo: y si queda en cero no se agrega.
                            let periodosUsados = periodosYaTomados.filter((periodo) => periodo.inicioUnix === inicioUnix && periodo.finUnix === finUnix && periodo.tipoDias === 'Progresivos');
                                            
                            let saldoResto = cantidadDiasProgresivos;

                            if (periodosUsados.length > 0) {

                                periodosUsados.forEach(periodo => {
                                    saldoResto -= periodo.diasNumberConsumidos;
                                })

                                if (saldoResto > 1) {
                                    cantidadDiasProgresivos = saldoResto;
                                } else {
                                    continue;
                                }
                            }

                            let nuevoPeriodo = {

                                inicio: fechaInicioPeriodo,
                                fin: fechaFinPeriodo,
                                inicioUnix,
                                finUnix,

                                saldo: cantidadDiasProgresivos,
                            };

                            // ENTRAN SÓLO LOS PERIODOS QUE ESTÉN DESDE EL CUAL EL TRABAJADOR PIDIÓ PROGRESIVAS
                            // && QUE SON DESPUÉS DE LOS HISTÓRICOS
                            if (agregarFlag) {
                                periodosProgresivos.push(nuevoPeriodo);
                            }
                        }
                    }

                    //ctrl+F: ref1
                    let resultadoNormales = (saldoHistoricoNormales + saldoTrabajados) - normalesTomadas;
                    let resultadoProgresivos = (saldoHistoricoProgresivos + progresivosTrabajados) - progresivasTomadas;

                    let arrayIniciosDeMes = [];

                    let fechaInicioOriginal = convertirFechaString(moment.utc(bitacorasMostrar.data.inicioFechaRangoBusqueda), 1).fechaString;
                    let fechaInicioString = convertirFechaString(moment.utc(bitacorasMostrar.data.inicioFechaRangoBusqueda).startOf("month"), 1).fechaString;
                    let fechaFinString = convertirFechaString(moment.utc(bitacorasMostrar.data.FinFechaRangoBusqueda).endOf("month"), 2).fechaString;
                    let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;
                
                    for (let index = 0; index < diferenciaMeses; index++) {
                        let fechaAgregar;
                  
                        if(index === 0){
                          if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
                            fechaAgregar = moment.utc(fechaInicioOriginal);
                          } else {
                            fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
                          }
                        } else {
                          fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
                        }
                  
                        arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
                    }

                    if(this.formRef.current === null){
                        return;
                    }

                    this.formRef.current.setFieldsValue({
                        cantidadTomadas: 1,

                        DisponiblesVacaciones: resultadoNormales,
                        DisponiblesProgresivos: resultadoProgresivos,
                        esBonificacion: false,
                    }); 
                    

                    this.setState({
                        periodosNormales,
                        periodosProgresivos,
                        vacacionesBitacoras,
                        disabledRadios: false,
                        disabledInputs: true,

                        //========nuevos valores setiados

                        fechasBloqueadas: bitacorasMostrar.data.bitNoModificar,
                        bitacora: bitacorasMostrar.data.bitacoras,
                        selectedContrato: [bitacorasMostrar.data.InicioContrato, bitacorasMostrar.data.FinContrato],
                        selectedDates: [
                        bitacorasMostrar.data.inicioFechaRangoBusqueda,
                        bitacorasMostrar.data.FinFechaRangoBusqueda,
                        ],
                        selectedDatesTimeline: [
                        bitacorasMostrar.data.inicioFechaRangoBusqueda,
                        bitacorasMostrar.data.FinFechaRangoBusqueda,
                        ],
                        firstDaysOfTheMonths: arrayIniciosDeMes,
                        contEstados: bitacorasMostrar.data.contEstados,
                        mostrarCalendario: true,
                        loading: false,
                    });

                    // /******* FIN LÓGICA PERIODOS *******/
                }
            }
        }catch(error){
            console.log(error);
            this.setState({
                loading:false
            })
        }
    };

    handleVacaciones = async (fechas) => {

        if (fechas == null) {
            return;
        }

        /******* INICIO LÓGICA DE DÍAS HÁBILES *******/

        let fechaInicio = fechas[0];
        let fechaFin = fechas[1];

        let cantidadDeDias = fechaFin.diff(fechaInicio, 'days') + 1;

        let diasHabiles = [];

        for (let index = 0; index < cantidadDeDias; index++) {

            let newFecha = fechaInicio.clone();
            newFecha.add(index, 'days');

            if (newFecha.isoWeekday() !== 6 && newFecha.isoWeekday() !== 7) {
                diasHabiles.push(diasHabiles);
            }

        }

        let cantidadHabiles = diasHabiles.length;

        let tipoDeDias = this.formRef.current.getFieldsValue(['TipoDias']).TipoDias;

        if (tipoDeDias === 'Normales') this.formRef.current.setFieldsValue({ FeriadoLegalVacaciones: cantidadHabiles })
        if (tipoDeDias === 'Progresivos') this.formRef.current.setFieldsValue({ FeriadoLegalProgresivos: cantidadHabiles })

        /******* FIN LÓGICA DE DÍAS HÁBILES *******/

        this.forceUpdate();
    }

    calcularFechasHabiles = async () => {

        let index = 0;
        let incrementoFecha = 0;
        let { cantidadTomadas, FechasEfectivas } = this.formRef.current.getFieldsValue(['cantidadTomadas', 'FechasEfectivas']);
       

        if (FechasEfectivas == undefined || FechasEfectivas == null) {
            this.forceUpdate();
            return;
        }

        let fechasInicioFinVacaciones = [];

        // push de fecha inicio
        fechasInicioFinVacaciones.push(FechasEfectivas.set({ hour: 12, minute: 0, second: 0, millisecond: 0 }));

        while (index < cantidadTomadas) {

            let fechaIter = FechasEfectivas.clone().add(incrementoFecha, 'days');
            let esFeriadoFlag = false;

            if (![6, 7].includes(fechaIter.isoWeekday())) {

                //validación: si es feriado, no contarlo
                this.state.feriados.forEach((feriado) => {

                    let feriadoMoment = moment(feriado.Fecha).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
                    let iterMoment = fechaIter.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });

                    if (feriadoMoment.isSame(iterMoment)) {
                        esFeriadoFlag = true;
                    }
                })

                // si es feriado, no lo cuenta
                if (!esFeriadoFlag) {
                    index++;
                }

                // si es la última iternación, es la fecha fin
                if (index === cantidadTomadas) {
                    fechasInicioFinVacaciones.push(fechaIter);
                }
            }

            incrementoFecha++;
        }

        this.setState({ fechasEfectivas: fechasInicioFinVacaciones })
    }

    modalCancelar = () => {
        this.setState({
            modalVacaciones: false,
        });
    };

    dobleClickBarra = (itemSeleccionado) => {
        if (itemSeleccionado.group === "5f3eebac978b284b68ebced5") {
            this.setState({
                idVacaciones: itemSeleccionado.Vacaciones,
                modalVacaciones: true,
            });
        }
    };

    descargarTicket = (itemSeleccionado) => {
        var win = window.open(
            "/ImpresionVacaciones?Vacaciones=" + itemSeleccionado,
            "_blank"
        );
        win.focus();
    };

    eliminarRegistro = async (itemSeleccionado) => {

        await Swal.fire({
            title: "Confirmar",
            text: `¿Estás seguro que quieres eliminar el registro de vacaciones?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1FC600",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí",
            cancelButtonText: "Cancelar",

        }).then(async (result) => {
            if (result.value) {
                
                const idTrabajador = this.props.Trabajador;

                let trabajador = this.props.trabajadores.find(x => x._id === idTrabajador);
                if (trabajador) {
                    this.setState({loading:true});
                    const { idEmpresa } = this.state;
                    await this.props.insertarTrabajador(idTrabajador);
        
                    await this.props.eliminarRegistro(
                        itemSeleccionado.Fecha_Inicio_DB,
                        itemSeleccionado.Fecha_Fin_DB,
                        itemSeleccionado.IdImprimir,
                    );
                    //obtenemos todos los contratos del trabajador
                    await this.props.traer_todos_contratos_trabajador(idTrabajador, idEmpresa);
        
                    //se obtienen las bitacoras historicas del trabaajdor
                    const newBitacorasHistorico = await this.props.traer_todos_las_vacaciones_historicos(idTrabajador);
        
                    await this.formRef.current.resetFields([
                        'TipoDias',
                        'FechasTrabajadas',
                        'FechasEfectivas',
                        'DisponiblesVacaciones',
                        'DisponiblesProgresivos',
                        'DisponiblesTotal',
                        'FeriadoLegalVacaciones',
                        'FeriadoLegalProgresivos',
                        'FeriadoLegalTotal',
                        'SaldoVacaciones',
                        'SaldoProgresivos',
                        'SaldoTotal',
                        'Correo',
                        'cantidadTomadas',
                        'contrato',
                        'Observacion'
                    ]);
        
                    this.setState({
                        fechasHistoricasVacaciones: newBitacorasHistorico,
                        loading:false
                    });
                }else{

                    await Swal.fire({
                        title: "Advertencia",
                        text: `No se logro obtener los datos del trabajador`,
                        icon: "error",
                        confirmButtonColor: "#1FC600",
                        confirmButtonText: "Ok",
                    });
                }

                // let response = await this.props.traer_todos_las_fechas_trabajador(this.props.Trabajador);

                // let fechas = [];

                // await response.forEach(element => {
                //     fechas.push(element.Fecha);
                // });

                // const responseDFiniquitados = await traer_todos_las_fechas_trabajador_descansos_finiquitados(this.props.Trabajador);
                // responseDFiniquitados.forEach(finiquito => {
                //     let newFiniquito = {...finiquito};
                //     newFiniquito.finiquitoDescanso = true;
                //     newFiniquito.TipoRegistroBitacoraTrabajador = 'descansoFiniquitado';
                //     response.push(newFiniquito);
                // });

                // await this.setState({
                //     fechasBloqueadas: fechas,
                //     bitacora: response,
                //     mostrarCalendario: true,
                //     modalDescanso: false,
                // });

                // if (this.props.Trabajador) {
                //     this.reiniciarFormulario(this.props.Trabajador);
                // }
            }

        });
    }

    calcularTotales() {

        let fields = ['DisponiblesVacaciones', 'DisponiblesProgresivos',
            'FeriadoLegalVacaciones', 'FeriadoLegalProgresivos',
            'SaldoVacaciones', 'SaldoProgresivos'];

        if (this.formRef.current) {

            // Obteniendo datos necesarios para calcular los totales
            let { DisponiblesVacaciones, DisponiblesProgresivos,
                FeriadoLegalVacaciones, FeriadoLegalProgresivos,
                SaldoVacaciones, SaldoProgresivos } = this.formRef.current.getFieldsValue(fields);

            // Seteando a 0 en caso de que sean null o undefined
            if (!(DisponiblesVacaciones > 0)) DisponiblesVacaciones = 0;
            if (!(DisponiblesProgresivos > 0)) DisponiblesProgresivos = 0;

            if (!(FeriadoLegalVacaciones > 0)) FeriadoLegalVacaciones = 0;
            if (!(FeriadoLegalProgresivos > 0)) FeriadoLegalProgresivos = 0;

            if (!(SaldoVacaciones > 0)) SaldoVacaciones = 0;
            if (!(SaldoProgresivos > 0)) SaldoProgresivos = 0;

            //seteando los totales
            this.formRef.current.setFieldsValue({

                //TOTALES DE LA DERECHA
                DisponiblesTotal: DisponiblesVacaciones + DisponiblesProgresivos,
                FeriadoLegalTotal: FeriadoLegalVacaciones + FeriadoLegalProgresivos,

                //TOTALES RESTANTES DE ABAJO
                SaldoVacaciones: DisponiblesVacaciones - FeriadoLegalVacaciones,
                SaldoProgresivos: DisponiblesProgresivos - FeriadoLegalProgresivos,

                SaldoTotal: (DisponiblesVacaciones + DisponiblesProgresivos) - (FeriadoLegalVacaciones + FeriadoLegalProgresivos),
            })
        }
    }

    handleChangeRadios = async (e) => {

        let val = e.target.value;

        let { cantidadTomadas } = this.formRef.current.getFieldsValue(['cantidadTomadas']);

        let periodosDeSelect;

        if (val === 'Normales') {

            this.formRef.current.setFieldsValue({
                FeriadoLegalVacaciones: cantidadTomadas,
                FeriadoLegalProgresivos: 0
            });

            periodosDeSelect = this.state.periodosNormales;

        } else if (val === 'Progresivos') {

            this.formRef.current.setFieldsValue({
                FeriadoLegalProgresivos: cantidadTomadas,
                FeriadoLegalVacaciones: 0,
            });

            periodosDeSelect = this.state.periodosProgresivos;
        }

        //se añade logica para que cuando el periodo este vacio se genere el reset del form
        if(periodosDeSelect.length === 0){
            this.formRef.current.setFieldsValue({
                FechasTrabajadas:[]
            });
        }

        await this.setState({
            periodosCondicional: periodosDeSelect,
            disabledInputs: false,
        });



        this.actualizarPeriodos(periodosDeSelect);
    }

    actualizarPeriodos = (newPeriodo) => {

        // periodosCondicional varía: se convierte en periodosNormales o en periodosProgresivos
        // dependiendo del valor del RadioButton
        let { periodosCondicional } = this.state;

        periodosCondicional = newPeriodo !== undefined ? newPeriodo : periodosCondicional;

        if (periodosCondicional.length > 0) {

            let { cantidadTomadas } = this.formRef.current.getFieldsValue(['cantidadTomadas']);

            let periodosTomados = [];

            let diasPorPeriodo = {};
            let diasNumberPorPeriodo = {};
            let periodosAdelantados = [];

            //Acá se va consumiendo el saldo de cada periodo
            for (let i = 0; i < periodosCondicional.length; i++) {

                let saldoConsumidoDePeriodo;

                let trabajador = this.props.trabajadores.find(x => x._id === this.props.Trabajador);
                let regimenVacaciones = trabajador.Regimen_Vacaciones;
                // si es el periodo vigente se considera la posibilidad de que se consuman vacaciones
                // adelantadas desde aquí en adelante
                if (periodosCondicional[i].esPeriodoVigente) {

                    if (cantidadTomadas > periodosCondicional[i].saldo) {

                        periodosAdelantados.push(i);

                        if (cantidadTomadas > regimenVacaciones) {
                            saldoConsumidoDePeriodo = regimenVacaciones;
                        } else {
                            saldoConsumidoDePeriodo = cantidadTomadas;
                        }

                        //en el caso de que todavía no pase el régimen, restarle a la cantidad tomada, sino, restale a régimen.
                        let excesoEnVigente = cantidadTomadas <= regimenVacaciones ? cantidadTomadas - periodosCondicional[i].saldo : regimenVacaciones - periodosCondicional[i].saldo;
                        cantidadTomadas -= periodosCondicional[i].saldo + excesoEnVigente;

                    } else {

                        saldoConsumidoDePeriodo = cantidadTomadas;
                        cantidadTomadas -= regimenVacaciones;
                    }

                } else {

                    //chequeando si es periodo futuro, para considerarlo como adelantado
                    if (periodosCondicional[i].isFuturo) {
                        periodosAdelantados.push(i);
                    }

                    if (cantidadTomadas > periodosCondicional[i].saldo) {

                        saldoConsumidoDePeriodo = periodosCondicional[i].saldo;

                    } else {
                        saldoConsumidoDePeriodo = cantidadTomadas;
                    }

                    cantidadTomadas -= periodosCondicional[i].saldo;
                }

                periodosTomados.push(i);

                diasPorPeriodo[i] = `${saldoConsumidoDePeriodo} ${saldoConsumidoDePeriodo === 1 ? 'día' : 'días'}`;
                diasNumberPorPeriodo[i] = saldoConsumidoDePeriodo;

                if (cantidadTomadas < 1) {
                    break;
                }
            }

            this.formRef.current.setFieldsValue({
                FechasTrabajadas: periodosTomados,
            })

            this.setState({
                diasPorPeriodo,
                diasNumberPorPeriodo,
                periodosAdelantados
            })

            // validar si es el vigente y si es progresivo, restringir adelantadas
        }
    }

    actualizarFechaTimeline = (fechaInicio, fechaFin) => {
        let arrayIniciosDeMes = [];

        let fechaInicioOriginal = convertirFechaString(moment.utc(fechaInicio), 1).fechaString;
        let fechaInicioString = convertirFechaString(moment.utc(fechaInicio).startOf("month"), 1).fechaString;
        let fechaFinString = convertirFechaString(moment.utc(fechaFin).endOf("month"), 2).fechaString;
        let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;
    
        for (let index = 0; index < diferenciaMeses; index++) {
            let fechaAgregar;
      
            if(index === 0){
              if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
                fechaAgregar = moment.utc(fechaInicioOriginal);
              } else {
                fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
              }
            } else {
              fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
            }
      
            arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
        }
    
        this.setState({
          selectedDatesTimeline: [fechaInicio, fechaFin],
          firstDaysOfTheMonths: arrayIniciosDeMes,
        });
    };

    handleNewDates = async (fechaInicio, fechaFin) => {
        try {
          const idTrabajador = this.props.Trabajador;
          let user = this.props.trabajadores.find((x) => x._id === idTrabajador);
          if (user) {
            const { contrato: idContrato } = this.formRef.current.getFieldsValue();
            //SE OBTIENE LAS BITACORAS QUE SE MOSTRARAN
            let bitacorasMostrar =await this.props.traer_todos_las_fechas_trabajador_por_contrato(idTrabajador,idContrato,fechaInicio,fechaFin);

            let arrayIniciosDeMes = [];

            let fechaInicioOriginal = convertirFechaString(moment.utc(bitacorasMostrar.data.inicioFechaRangoBusqueda), 1).fechaString;
            let fechaInicioString = convertirFechaString(moment.utc(bitacorasMostrar.data.inicioFechaRangoBusqueda).startOf("month"), 1).fechaString;
            let fechaFinString = convertirFechaString(moment.utc(bitacorasMostrar.data.FinFechaRangoBusqueda).endOf("month"), 2).fechaString;
            let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;
        
            for (let index = 0; index < diferenciaMeses; index++) {
                let fechaAgregar;
          
                if(index === 0){
                  if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
                    fechaAgregar = moment.utc(fechaInicioOriginal);
                  } else {
                    fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
                  }
                } else {
                  fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
                }
          
                arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
            }
    
            this.setState({
                fechasBloqueadas: bitacorasMostrar.data.bitNoModificar,
                bitacora: bitacorasMostrar.data.bitacoras,
                //========nuevos valores setiados

                fechasBloqueadas: bitacorasMostrar.data.bitNoModificar,
                bitacora: bitacorasMostrar.data.bitacoras,
                selectedContrato: [bitacorasMostrar.data.InicioContrato, bitacorasMostrar.data.FinContrato],
                selectedDates: [
                  bitacorasMostrar.data.inicioFechaRangoBusqueda,
                  bitacorasMostrar.data.FinFechaRangoBusqueda,
                ],
                selectedDatesTimeline: [
                  bitacorasMostrar.data.inicioFechaRangoBusqueda,
                  bitacorasMostrar.data.FinFechaRangoBusqueda,
                ],
                firstDaysOfTheMonths: arrayIniciosDeMes,
                contEstados: bitacorasMostrar.data.contEstados,
                mostrarCalendario: true,
                loading: false,
            });
    
            await this.formRef.current.resetFields([
                'TipoDias',
                'FechasTrabajadas',
                'FechasEfectivas',
                // 'DisponiblesVacaciones',
                // 'DisponiblesProgresivos',
                // 'DisponiblesTotal',
                // 'FeriadoLegalVacaciones',
                // 'FeriadoLegalProgresivos',
                // 'FeriadoLegalTotal',
                // 'SaldoVacaciones',
                // 'SaldoProgresivos',
                // 'SaldoTotal',
                'Correo',
                'cantidadTomadas'
            ]);
          }
        } catch (error) {
          await this.setState({
            loading: false,
          });
          console.log(error);
        }
    };

    render() {

        let disabledInputs = this.state.disabledInputs;
        let disabledRadios = this.state.disabledRadios;
        let disabledProgresivas = this.state.disabledProgresivas;

        let style = { marginBottom: '0px', marginTop: '5px', color: 'black' }

        this.calcularTotales();

        let disponiblesNormales;
        let disponiblesProgresivos;

        if (this.formRef.current) {

            let values = this.formRef.current.getFieldsValue([
                'DisponiblesVacaciones',
                'DisponiblesProgresivos',
            ]);

            disponiblesNormales = values.DisponiblesVacaciones;
            disponiblesProgresivos = values.DisponiblesProgresivos;
        }

        return (
            <Layout>
                <Loading cargando={this.props.loading} />

                <Header style={{ background: 'none' }}>
                    <HeaderFicha
                        titulo="Vacaciones"
                        subtitulo="Son los días programados como libres de trabajo efectivo"
                    />
                </Header>

                <hr className="division" />

                <Content
                    className="contenedor-general cont-tabla-celeste"
                    style={{ background: 'none' }}
                >
                    <Row gutter={25}>

                        <Col className="gutter-row" span={8} xs={24} s={24} sm={24} md={8} lg={8} xl={8}>
                            <Card
                                style={{
                                    borderRadius: "16px",
                                    height: "100%",
                                    width: "100%",
                                    boxShadow: "0px 3px 6px #74747465",
                                    padding: '20px'
                                }}
                            >
                                <Row>

                                    <Col span={24} style={{ display: 'inherit' }}>
                                        <img src={vacas} alt="cama arreglada para descansar" />
                                        <h1 className="formVacaciones_card_title">Generar Vacaciones</h1>

                                    </Col>

                                    <Col span={24}>
                                        <p style={{ fontFamily: 'MavenPro-Regular', display: 'block', marginTop: '10px', marginBottom: '0px' }}>
                                            Seleccione un trabajador para ingresarle vacaciones:
                                        </p>
                                    </Col>

                                    <Col span={24} style={{ marginTop: '15px' }}>

                                        <Form onFinish={this.onFinish} ref={this.formRef} name="vacacion_trabajador">

                                            {/* TRABAJADOR */}
                                            <Form.Item
                                                name="trabajador"
                                                label="Nombre Trabajador"
                                            // className="inputsFormVacaciones_Text"
                                            >
                                                <Select
                                                    showSearch={true}
                                                    placeholder="Selecciona un trabajador"
                                                    optionFilterProp="children"
                                                    size='middle'
                                                    loading={this.props.loading}
                                                    onChange={this.handleChangeTrabajadorNew}
                                                    filterOption={(input, option) => {
                                                        return option.children.props.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }}
                                                >
                                                    {this.props.trabajadores === null
                                                        ? (<Option value="Cargando trabajadores...">Cargando trabajadores...</Option>)
                                                        : (
                                                            this.props.trabajadores.map(
                                                                ({ Nombre, Apellido_Paterno, Apellido_Materno, _id }, index) => (
                                                                    <Option key={index} value={_id} style={{ fontFamily: 'MavenPro-Regular' }}>
                                                                        <Tooltip title={Nombre + " " + Apellido_Paterno + " " + Apellido_Materno} color={'geekblue'}>
                                                                            <b>{Nombre + ' ' + Apellido_Paterno + ' ' + Apellido_Materno}</b>
                                                                        </Tooltip>
                                                                    </ Option>
                                                                )
                                                            )
                                                        )}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name="contrato"
                                                label="Contrato Trabajador"
                                                rules={[
                                                {
                                                    required: true,
                                                    message: "Obligatorio",
                                                },
                                                ]}
                                            >
                                                <Select
                                                // showSearch={true}
                                                placeholder="Selecciona el contrato del trabajador"
                                                optionFilterProp="children"
                                                size="middle"
                                                loading={this.props.loading}
                                                onChange={this.handleContrato}
                                                // filterOption={(input, option) => {
                                                //     return (
                                                //     option.children.props.children
                                                //         .toLowerCase()
                                                //         .indexOf(input.toLowerCase()) >= 0
                                                //     );
                                                // }}
                                                >
                                                {this.props.contratos === null ? (
                                                    <Option value="Cargando contratos...">
                                                    Cargando contratos...
                                                    </Option>
                                                ) : (
                                                    this.props.contratos.map((contrato, index) => (
                                                    <>
                                                        {contrato.Tipo_Contrato === "Plazo fijo" ? (
                                                        <Option key={index} value={contrato._id}>
                                                            <b>
                                                            {moment
                                                                .utc(contrato.Contrato_Fecha_Inicio)
                                                                .format("DD-MM-YYYY")}{" "}
                                                            al{" "}
                                                            {contrato.Contrato_Finiquitado === true
                                                                ? moment
                                                                    .utc(contrato.Fecha_Finiquito)
                                                                    .format("DD-MM-YYYY")
                                                                : moment
                                                                    .utc(contrato.Contrato_Fecha_Fin)
                                                                    .format("DD-MM-YYYY")}
                                                            </b>
                                                        </Option>
                                                        ) : (
                                                        <Option key={index} value={contrato._id}>
                                                            <b>
                                                            {moment
                                                                .utc(contrato.Contrato_Fecha_Inicio)
                                                                .format("DD-MM-YYYY")}{" "}
                                                            
                                                            {contrato.Contrato_Finiquitado === true
                                                                ? 'Al ' + moment
                                                                    .utc(contrato.Fecha_Finiquito)
                                                                    .format("DD-MM-YYYY")
                                                                : "A Contrato Indefinido"}
                                                            </b>
                                                        </Option>
                                                        )}
                                                    </>
                                                    ))
                                                )}
                                                </Select>
                                            </Form.Item>

                                            {/* CANTIDAD DE DÍAS TOMADOS */}
                                            <Popover
                                                title={'Información:'}
                                                placement={'right'}
                                                content={(() => {

                                                    if (disponiblesNormales != undefined && disponiblesProgresivos != undefined) {
                                                        return (
                                                            <div className={'popover-div'}>

                                                                <table className={'popover-table'}>
                                                                    <tr>
                                                                        <td className={'popover-td'}>
                                                                            Días normales disponibles:
                                                                        </td>
                                                                        <th className={'popover-th'}>
                                                                            {disponiblesNormales}
                                                                        </th>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className={'popover-td'}>
                                                                            Días progresivos disponibles:
                                                                        </td>
                                                                        <th className={'popover-th'}>
                                                                            {disponiblesProgresivos}
                                                                        </th>
                                                                    </tr>
                                                                </table>

                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <>
                                                                Seleccione a un trabajador para ver sus vacaciones disponibles.
                                                            </>
                                                        )
                                                    }

                                                })()}
                                            >
                                                <FormItem
                                                    name='cantidadTomadas'
                                                    label='Cantidad de días hábiles a tomar'
                                                >
                                                    <InputNumber
                                                        disabled={disabledRadios}
                                                        // disabled={true}
                                                        onChange={(val) => {

                                                            // if (val == null || val == 'null' || val == undefined || val == '') {
                                                            //     val = 1;
                                                            // }

                                                            this.calcularFechasHabiles();

                                                            let { TipoDias } = this.formRef.current.getFieldsValue(['TipoDias'])

                                                            if (TipoDias === 'Normales') {
                                                                this.formRef.current.setFieldsValue({
                                                                    FeriadoLegalVacaciones: val,
                                                                });

                                                            } else if (TipoDias === 'Progresivos') {
                                                                this.formRef.current.setFieldsValue({
                                                                    FeriadoLegalProgresivos: val,
                                                                });

                                                            }

                                                            this.actualizarPeriodos();
                                                        }}
                                                        placeholder="Número"
                                                        min={1}

                                                        style={{ width: '100%' }} />
                                                </FormItem>
                                            </Popover>

                                            {/* TIPO DE DÍAS */}
                                            <FormItem
                                                name='TipoDias'
                                                label="Son días"
                                                className="inputsFormVacaciones_Text"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Obligatorio',
                                                    },
                                                ]}
                                            >
                                                <Radio.Group
                                                    disabled={disabledRadios}
                                                    onChange={(e) => {
                                                        this.handleChangeRadios(e)
                                                    }}
                                                >
                                                    <Radio value={'Normales'}>Normales</Radio>
                                                    <Radio
                                                        value={'Progresivos'}
                                                        disabled={disabledProgresivas}
                                                    >
                                                        Progresivos
                                                    </Radio>
                                                </Radio.Group>
                                            </FormItem>

                                            {/* ¿ES BONIFICACIÓN? */}
                                            <FormItem
                                                name='esBonificacion'
                                                label="¿Es bonificación?"
                                                className="inputsFormVacaciones_Text"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Obligatorio',
                                                    },
                                                ]}
                                            >
                                                <Radio.Group
                                                    disabled={disabledRadios}
                                                    onChange={(e) => {

                                                        let val = e.target.value;

                                                        this.setState({
                                                            isCompensacion: val,
                                                            // disabledInputs: val,
                                                        })
                                                    }}
                                                >
                                                    <Radio value={true}>Sí</Radio>
                                                    <Radio value={false}>No</Radio>
                                                </Radio.Group>
                                            </FormItem>

                                            {/* MONTO BONIFICACIÓN */}
                                            {(() => {
                                                if (this.state.isCompensacion) {
                                                    return (
                                                        <FormItem
                                                            name='montoBonificacion'
                                                            label='Monto de bonificación ($)'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Obligatorio',
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                disabled={disabledRadios}
                                                                placeholder="Monto"
                                                                min={1}
                                                                style={{ width: '100%' }}
                                                            />
                                                        </FormItem>
                                                    )
                                                }
                                            })()}

                                            {/* FECHAS USO DE VACACIONES */}
                                            {(() => {
                                                if (!this.state.isCompensacion) {
                                                    return (
                                                        <Form.Item
                                                            name="FechasEfectivas"
                                                            label='Días Efectivos en que se hará uso del Feriado Legal'
                                                            labelCol={{ span: 24 }}
                                                            className="inputsFormVacaciones_Text"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Obligatorio',
                                                                },
                                                            ]}
                                                            extra={
                                                                this.state.fechasEfectivas
                                                                    ? <Tooltip
                                                                        placement={'bottom'}
                                                                        title={'Las fechas se calculan según la cantidad de días ingresada, sin contar días inhábiles y feriados.'}
                                                                    >
                                                                        <p style={style}>
                                                                            (desde {moment(this.state.fechasEfectivas[0]).format('DD-MM-YYYY')} hasta {moment(this.state.fechasEfectivas[1]).format('DD-MM-YYYY')})
                                                                        </p>
                                                                    </Tooltip>
                                                                    : null
                                                            }
                                                        >
                                                            <DatePicker
                                                                locale={locale}
                                                                disabled={disabledInputs}
                                                                onChange={this.calcularFechasHabiles}
                                                                disabledDate={(dia) => {
                                                                    let diaString = convertirFechaString(
                                                                      dia,
                                                                      1
                                                                    ).fechaString;
                                                                    const fechas = this.state.selectedDates;
                                                                    if (fechas[0] !== null && fechas[1] !== null) {
                                                                      if (
                                                                        moment
                                                                          .utc(diaString)
                                                                          .isBetween(
                                                                            moment.utc(fechas[0]),
                                                                            moment.utc(fechas[1]),
                                                                            null,
                                                                            "[]"
                                                                          )
                                                                      ) {
                                                                        return false;
                                                                      }
                                                                    }
                                                                    return true;
                                                                  }}
                                                            />
                                                        </Form.Item>
                                                    )
                                                }
                                            })()}

                                            {/* FECHAS PERIODO(S) */}
                                            <Form.Item
                                                name="FechasTrabajadas"
                                                label="Periodo trabajado al cual corresponden las vacaciones"
                                                labelCol={{ span: 24 }}
                                                className="inputsFormVacaciones_Text"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Obligatorio',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    disabled={true}
                                                >
                                                    {this.state.periodosCondicional.map(({ inicio, fin }, index) => {

                                                        let { diasPorPeriodo, periodosAdelantados } = this.state;

                                                        let textClass = '';

                                                        if (periodosAdelantados) {
                                                            textClass = periodosAdelantados.includes(index) ? 'periodo-adelantado-text' : '';
                                                        }

                                                        return (
                                                            <Option
                                                                key={index}
                                                                value={index}
                                                            >
                                                                <span className={textClass}>
                                                                    {inicio.format('DD-MM-YYYY')} - {fin.format('DD-MM-YYYY')} ({diasPorPeriodo ? diasPorPeriodo[index] : ''})
                                                                </span>

                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>

                                            {/* TEXTOS */}
                                            <Row className="inputsFormVacaciones_Text" style={{ textAlign: 'center' }}>

                                                <Col span={6}>
                                                    <p></p>
                                                </Col >

                                                <Col span={6}>
                                                    <p>Vacaciones </p>
                                                </Col >

                                                <Col span={6}>
                                                    <p> Progresivos  </p>
                                                </Col>

                                                <Col span={6}>
                                                    <p>Total </p>
                                                </Col>

                                            </Row>

                                            {/* SALDOS DISPONIBLES */}
                                            <Row className="inputsFormVacaciones_Text">

                                                <Col span={6} style={{ paddingRight: '10px' }}>
                                                    <p>Saldo días disponible</p>
                                                </Col >

                                                <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                                                    <FormItem
                                                        name='DisponiblesVacaciones'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Obligatorio',
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            placeholder="Número"
                                                            // min={0}
                                                            style={{ width: '100%' }}
                                                            // disabled={disabledInputs}
                                                            disabled={true}
                                                        />
                                                    </FormItem>
                                                </Col >

                                                <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                                                    <FormItem
                                                        name='DisponiblesProgresivos'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Obligatorio',
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            placeholder="Número"
                                                            // min={0}
                                                            style={{ width: '100%' }}
                                                            // disabled={disabledInputs ? disabledInputs : disabledProgresivas}
                                                            disabled={true}
                                                        />
                                                    </FormItem>
                                                </Col>

                                                <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                                                    <FormItem
                                                        name='DisponiblesTotal'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Obligatorio',
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            placeholder="Número"
                                                            // min={0}
                                                            style={{ width: '100%' }}
                                                            // disabled={disabledInputs}
                                                            disabled={true}
                                                        />
                                                    </FormItem>
                                                </Col>

                                            </Row>

                                            {/* DÍAS TOMADOS */}
                                            <Row className="inputsFormVacaciones_Text">

                                                <Col span={6} style={{ paddingRight: '10px' }}>
                                                    <p>Días hábiles tomados</p>
                                                </Col >

                                                <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                                                    <FormItem name='FeriadoLegalVacaciones'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Obligatorio',
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            // disabled={disabledInputs}
                                                            disabled={true}
                                                            placeholder="Número"
                                                            // min={0}
                                                            style={{ width: '100%' }} />
                                                    </FormItem>
                                                </Col >

                                                <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                                                    <FormItem name='FeriadoLegalProgresivos'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Obligatorio',
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            // disabled={disabledInputs ? disabledInputs : disabledProgresivas}
                                                            disabled={true}
                                                            placeholder="Número"
                                                            // min={0}
                                                            style={{ width: '100%' }} />
                                                    </FormItem>
                                                </Col>

                                                <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                                                    <FormItem name='FeriadoLegalTotal'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Obligatorio',
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            // disabled={disabledInputs}
                                                            disabled={true}
                                                            placeholder="Número"
                                                            // min={0}
                                                            style={{ width: '100%' }} />
                                                    </FormItem>
                                                </Col>

                                            </Row>

                                            {/* SALDOS RESTANTE */}
                                            <Row className="inputsFormVacaciones_Text">

                                                <Col span={6} style={{ paddingRight: '10px' }}>
                                                    <p>Saldo Días</p>
                                                </Col >

                                                <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                                                    <FormItem name='SaldoVacaciones'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Obligatorio',
                                                            },
                                                        ]} >
                                                        <InputNumber
                                                            // disabled={disabledInputs} 
                                                            disabled={true}
                                                            placeholder="Número"
                                                            // min={0}
                                                            style={{ width: '100%' }} />
                                                    </FormItem>
                                                </Col >

                                                <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                                                    <FormItem name='SaldoProgresivos'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Obligatorio',
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            // disabled={disabledInputs ? disabledInputs : disabledProgresivas}
                                                            disabled={true}
                                                            placeholder="Número"
                                                            // min={0}
                                                            style={{ width: '100%' }} />
                                                    </FormItem>
                                                </Col>

                                                <Col span={6} style={{ paddingRight: '1%', paddingLeft: '1%' }}>
                                                    <FormItem name='SaldoTotal'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Obligatorio',
                                                            },
                                                        ]}>
                                                        <InputNumber
                                                            // disabled={disabledInputs}
                                                            disabled={true}
                                                            placeholder="Número"
                                                            // min={0}
                                                            style={{ width: '100%' }} />
                                                    </FormItem>
                                                </Col>

                                            </Row>

                                            {/* Observacion */}
                                            <Form.Item
                                                name="Observacion"
                                                label="Observacion"
                                                className="inputsFormDescanso_Text"
                                            >
                                                <TextArea rows={3} maxLength={200} placeholder="(Opcional)" />
                                            </Form.Item>

                                            {/* Flag Correo */}
                                            <Form.Item
                                                name="Correo"
                                                label="Enviar mail de notificación"
                                                className="inputsFormVacaciones_Text"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Obligatorio',
                                                    },
                                                ]}
                                            >
                                                <Radio.Group
                                                    onChange={({ target: { value } }) => this.props.enviarCorreo(value)}
                                                    value={true}
                                                    disabled={disabledInputs}
                                                >
                                                    <Radio value={true}>Sí</Radio>
                                                    <Radio value={false}>No</Radio>
                                                </Radio.Group>

                                            </Form.Item>

                                            <Row>
                                                <Col
                                                    span={24}
                                                    //className="vacacionesBtn_guardar"
                                                    style={{ marginTop: '24px', textAlign: 'right' }}
                                                >
                                                    <Button
                                                        //className="guardar-form"
                                                        type="primary"
                                                        htmlType="submit"
                                                        form="vacacion_trabajador"
                                                        className={!this.state.Editar || disabledInputs ? "guardar-form-disabled" : "guardar-form"}
                                                        disabled={disabledInputs === true ? disabledInputs : !this.state.Editar}
                                                    >
                                                        Guardar
                                                    </Button>
                                                </Col>
                                            </Row>

                                        </Form>

                                    </Col>

                                </Row>
                            </Card>
                        </Col>

                        <Col className="gutter-row" span={16} xs={24} s={24} sm={24} md={16} lg={16} xl={16}>
                            <TimeLineTrabajador
                                dobleClickBarra={this.dobleClickBarra}
                                mostrarCalendario={this.state.mostrarCalendario}
                                bitacora={this.state.bitacora}
                                selectedContrato={this.state.selectedContrato}
                                selectedDates={this.state.selectedDates}
                                selectedDatesTimeline={this.state.selectedDatesTimeline}
                                firstDaysOfTheMonths={this.state.firstDaysOfTheMonths}
                                actualizarFechaTimeline={this.actualizarFechaTimeline}
                               
                                tipo={"5f3eebac978b284b68ebced5"}
                                eliminarRegistro={this.eliminarRegistro}
                                descargarRegistro={this.descargarTicket}
                                cambiarFechas={this.handleNewDates}
                                Eliminar={this.state.Eliminar}
                                Ver={this.state.Ver}
                                ArrayHistorial={this.state.fechasHistoricasVacaciones}
                                TituloHistorial={"Dias Vacaciones"}
                                contEstados={this.state.contEstados}
                            />
                        </Col>
                    </Row>
                </Content >

                <Modal
                    //propiedades cambiables del modal
                    wrapClassName="modalCrear"
                    visible={this.state.modalVacaciones}
                    onCancel={this.modalCancelar}
                    //propiedades obligatorias para modal mantenedor
                    bodyStyle={{ borderRadius: "16px" }}
                    width="40%"
                    footer={null}
                    style={{ top: "30%" }}
                    closable={false}
                    maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
                >
                    <Row>
                        <Col span={24} style={{ display: "flex" }} className="title">
                            <img src={vacas} style={{ width: "35px" }} alt="icono modal" />
                            <PageHeader
                                style={{ padding: "0px 10px 0px 10px" }}
                                className="site-page-header"
                                title="Acciones vacaciones"
                            />
                        </Col>
                        <Col
                            style={{
                                textAlign: "center",
                                paddingTop: "50px",
                                paddingBottom: "50px",
                            }}
                            span={12}
                        >
                            <Button
                                className="descargar-excel"
                                type="primary"
                                //size={size}
                                style={{ marginRight: "20px" }}
                            >
                                Eliminar registro
                            </Button>
                        </Col>
                        <Col
                            span={12}
                            style={{
                                textAlign: "center",
                                paddingTop: "50px",
                                paddingBottom: "50px",
                            }}
                        >
                            <Button
                                className="btn-verde"
                                onClick={() => {
                                    this.descargarTicket();
                                }}
                                //size={size}
                                style={{ marginRight: "20px" }}
                            >
                                Descargar Documento
                            </Button>
                        </Col>
                    </Row>
                </Modal>

                <span>
                    <style jsx="true">
                        {`
                            .ant-input-number-disabled .ant-input-number-input {
                                background-color: white !important;
                                color: #333333 !important;
                            }
                            .ant-select-disabled.ant-select-multiple .ant-select-selection-item{
                                background-color: white !important;
                                color: #333333 !important;
                            }
                        `}
                    </style>
                </span>
            </Layout >
        );
    }
}

const EstadosAPropiedades = ({
    VacacionReducer: {
        loading,
        err,
        trabajadores,
        form,
        form: { Fechas, Trabajador, Fechas2 },
        contratos,
    },
}) => {
    return {
        loading,
        err,
        trabajadores,
        form,
        Fechas,
        Trabajador,
        Fechas2,
        contratos
    };
};

export default connect(EstadosAPropiedades, vacacionActions)(Vacaciones);
