import API from "../../Redux/Api/api";

export const asignacionActividad = async (
  idEmpresa,
  fechaInicio,
  fechaFin,
  ccSeleccionados,
  limit,
  skip
) => {
  try {
    ///Faena/FiltradoNewDataDesarrolloSeba?empresa=5fe103b89fe3e8002f4c7367
    const url = `${API}/Faena/FiltradoNewDataActividad?empresa=${idEmpresa}&limit=${limit}&skip=${skip}`;
    let objeto = {
      fechaInicio: fechaInicio,
      fechaFin: fechaFin,
      ccSeleccionados: ccSeleccionados,
    };
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objeto),
    });

    const status = response.status;
    let data;

    
    switch (status) {
      case 200:
        data = await response.json();

        let dataAsignacion = data;

        return dataAsignacion;

      default:
        const error = data.err.message.toString();
        console.error(error);
        return [];
    }
  } catch (error) {
    console.error(error);
  }
};

export const traerActividadesCentrosCostos = async (idCentroCosto) => {
  try {
        const url = `${API}/CentroDeCostos/TraerActividades/${idCentroCosto}`;
        const response = await fetch(url, {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            mode: "no-cors",
          },
        });
    
        const status = response.status;
        let data;
    
        switch (status) {
          case 200:
            data = await response.json();
            return data;

          default:
            const error = data.err.message.toString();
            console.error(error);
            return [];
        }
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const detalleActividadOrigen = async (
  idEmpresa,
  idFaena
) => {
  try {
    const url = `${API}/DetalleActividad/BitacoraTrabajadores?idEmpresa=${idEmpresa}&idFaena=${idFaena}`;
    let objetoEnviar = {};
    
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(objetoEnviar),
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
        data = await response.json();

        let bitacoraTrabajadores = data.data;
        return bitacoraTrabajadores;

      default:
        const error = data.err.message.toString();
        console.error(error);
        return [];
    }
  } catch (error) {
    console.error(error);
  }
};


export const detalleActividadOrigenModal = async (  
  idEmpresa,
  idFaena
) => {
  
  try {
        const url = `${API}/DetalleActividadOrigen/?idEmpresa=${idEmpresa}&idFaena=${idFaena}`;
        const response = await fetch(url, {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            mode: "no-cors",
          },
        });
    
        const status = response.status;
        let data;
    
        switch (status) {
          case 200:
            data = await response.json();
            return data;

          default:
            const error = data.err.message.toString();
            console.error(error);
            return [];
        }
  } catch (error) {
    console.error(error);
    return [];
  }
}


export const detalleActividadDestinoModal = async (  
  idEmpresa,
  idPlantilla,
  idActividadOrigen
) => {
  
  try {
        const url = `${API}/DetalleActividadDestino/?idEmpresa=${idEmpresa}&idPlantilla=${idPlantilla}&idActividadOrigen=${idActividadOrigen}`;
        const response = await fetch(url, {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            mode: "no-cors",
          },
        });
    
        const status = response.status;
        let data;
    
        switch (status) {
          case 200:
            data = await response.json();
            return data;

          default:
            const error = data.err.message.toString();
            console.error(error);
            return [];
        }
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const traspasarTrabajadoresModal = async (  
  objetoRe
) => {
  try {
        const url = `${API}/Faena/TraspasarTrabajadores`;

        let objetoEnviar = {
            Empresa: objetoRe.Empresa,
            Centro_De_Costo: objetoRe.Centro_De_Costo,
            ConfirmacionTraspaso: objetoRe.ConfirmacionTraspaso,
            Expira: objetoRe.Expira,
            Fecha_Fin: objetoRe.Fecha_Fin,
            Fecha_Fin_Unix: objetoRe.Fecha_Fin_Unix,
            Fecha_Inicio: objetoRe.Fecha_Inicio,
            Fecha_Inicio_Unix: objetoRe.Fecha_Inicio_Unix,
            Nombre: objetoRe.Nombre,
            PlantillaBase: objetoRe.PlantillaBase,
            Rango_Fechas: objetoRe.Rango_Fechas,
            Vigencia: objetoRe.Vigencia,
            plantilla: objetoRe.plantilla
        };

        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            mode: "no-cors",
          },
          body: JSON.stringify(objetoEnviar),
        });
    
        const status = response.status;
        let data;
    
        switch (status) {
          case 200:
          case 201:
            data = await response.json();
            return data;

          default:
            const error = data.err.message.toString();
            console.error(error);
            return [];
        }
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const disponibilidadTransbordoTrabajadorModal = async (  
  ID_TRABAJADOR,
  INICIO_PLANIFICACION,
  FIN_PLANIFICACION,
  ID_EMPRESA
) => {
  
  try {
    
        const url = `${API}/BitacoraTrabajador/DisponibilidadTransbordo/${ID_TRABAJADOR}/${INICIO_PLANIFICACION}/${FIN_PLANIFICACION}?empresa=${ID_EMPRESA}`;
        const response = await fetch(url, {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            mode: "no-cors",
          },
        });
    
        const status = response.status;
        let data;
    
        switch (status) {
          case 200:
            data = await response.json();
            return {data,status};

          default:
            const error = data.err.message.toString();
            console.error(error);
            return {data: [],status};
        }
  } catch (error) {
    console.error(error);
    return {data: [],status:500};
  }
}