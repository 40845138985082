import React, { Component } from "react";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import Swal from "sweetalert2";

import {
    obtenerCentroCostos,
    obtenerFaenas,
    obtenerPlazas,
    obtenerDetallePlaza
} from './reporteNecesidadesAPI';

import CheckVerde from "../../img/icons/check-verde.svg";
import NoAsignado from "../../img/icons/NoAsignado.svg";
import Avatar from "../../img/icons/avatar.svg";


import "./reporteNecesidades.css";
import locale from "antd/es/date-picker/locale/es_ES";

import { returnTimeZoneAdd } from '../../funcionesGenericas/fechas'



import {
    Layout,
    DatePicker,
    Row,
    Col,
    Select,
    InputNumber,
    Badge,
    Form,
    Button,
    Modal,
    PageHeader,
    Tabs,
    Input,
    Divider,
    Popover,
    Alert,
    Radio,
    Checkbox,
    Card,
    Empty,
    Tooltip,
    Steps
} from "antd";
import { EyeFilled, EditFilled, LeftCircleTwoTone, CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import moment from "moment";

const { Header, Content } = Layout;
const { RangePicker } = DatePicker;
const { Step } = Steps;


class ReporteNecesidades extends Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            idEmpresa: null,
            centroCostos: [],

            faenas: [],
            showModalDetalle: false,
            showModalDetalle2: false,

            plaza: {},

            fechaDesde: '',
            fechaHasta: '',


            detallePlaza: [],

            nombrePlazaSeleccionada: '',
            arrPuestosTrabajadores: [],

        }
    }

    async componentDidMount() {
        let usuario = localStorage.getItem("usuario-people-manage");
        if (usuario) {
            usuario = JSON.parse(usuario);
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

            const centroCostos = await obtenerCentroCostos(idEmpresa);

            let fechaInicial = moment().utc();
            let fechaFin = moment().utc().add(1, 'week');

            this.formRef.current.setFieldsValue({ fechasReporte: [fechaInicial, fechaFin] });


            let zonaHorariaMoment = returnTimeZoneAdd(fechaInicial);
            fechaInicial = fechaInicial.clone().add(zonaHorariaMoment, "minute").utc().startOf("day").format("YYYY-MM-DDTHH:mm:ss[Z]");


            let zonaHorariaMomentFin = returnTimeZoneAdd(fechaFin);
            fechaFin = fechaFin.clone().add(zonaHorariaMomentFin, "minute").utc().endOf("day").format("YYYY-MM-DDTHH:mm:ss[Z]");

            let values = {};
            values.centroCostos = [];
            values.fechasReporte = [fechaInicial, fechaFin];


            const faenas = await obtenerFaenas(idEmpresa, values);

            this.setState({ idEmpresa, centroCostos, loading: false, faenas, fechas: [fechaInicial, fechaFin] })
        }
    }

    filtrarFaenas = async (values) => {
        let usuario = localStorage.getItem("usuario-people-manage");
        if (usuario) {
            usuario = JSON.parse(usuario);
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
            if (values.fechasReporte === undefined || values.fechasReporte === null) {
                Swal.fire({
                    icon: "error",
                    title: "Uuups...",
                    text: "Debe ingresar fechas",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "OK",
                });
                return;
            }

            this.setState({ loading: true });
            let fechaInicial = values.fechasReporte[0];
            let zonaHorariaMoment = returnTimeZoneAdd(fechaInicial);
            fechaInicial = fechaInicial.clone().add(zonaHorariaMoment, "minute").utc().startOf("day").format("YYYY-MM-DDTHH:mm:ss[Z]");

            let fechaFin = values.fechasReporte[1];
            let zonaHorariaMomentFin = returnTimeZoneAdd(fechaFin);
            fechaFin = fechaFin.clone().add(zonaHorariaMomentFin, "minute").utc().endOf("day").format("YYYY-MM-DDTHH:mm:ss[Z]");

            values.centroCostos = (values.centroCostos == undefined ? [] : values.centroCostos);
            values.fechasReporte = [fechaInicial, fechaFin];

            const faenas = await obtenerFaenas(idEmpresa, values);


            this.setState({ faenas, loading: false });
        }
    }

    filtrarPlaza = async (idFaena) => {
        let usuario = localStorage.getItem("usuario-people-manage");
        if (usuario) {
            usuario = JSON.parse(usuario);
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
            const { fechasReporte } = this.formRef.current.getFieldsValue(['fechasReporte']);

            if (fechasReporte === undefined || fechasReporte === null) {
                Swal.fire({
                    icon: "error",
                    title: "Uuups...",
                    text: "Debe ingresar fechas",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "OK",
                });
                return;
            }

            this.setState({
                loading: true,
            });

            let values = {};
            let fechaInicial = fechasReporte[0];
            let zonaHorariaMoment = returnTimeZoneAdd(fechaInicial);
            fechaInicial = fechaInicial.clone().add(zonaHorariaMoment, "minute").utc().startOf("day").format("YYYY-MM-DDTHH:mm:ss[Z]");

            let fechaFin = fechasReporte[1];
            let zonaHorariaMomentFin = returnTimeZoneAdd(fechaFin);
            fechaFin = fechaFin.clone().add(zonaHorariaMomentFin, "minute").utc().endOf("day").format("YYYY-MM-DDTHH:mm:ss[Z]");

            values.idFaena = idFaena;
            values.fechasReporte = [fechaInicial, fechaFin];

            const plaza = await obtenerPlazas(idEmpresa, values);


            this.setState({ plaza, loading: false, showModalDetalle: true, fechaDesde: fechaInicial, fechaHasta: fechaFin });
        }
    }

    detallePlaza = async (idPlaza, idFaena, idsPuestoPlaza, idCargo) => {

        let usuario = localStorage.getItem("usuario-people-manage");
        if (usuario) {

            this.setState({ loading: true })
            usuario = JSON.parse(usuario);
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
            const { fechasReporte } = this.formRef.current.getFieldsValue(['fechasReporte']);

            if (fechasReporte === undefined || fechasReporte === null) {
                Swal.fire({
                    icon: "error",
                    title: "Uuups...",
                    text: "Debe ingresar fechas",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "OK",
                });
                return;
            }

            let values = {};
            let fechaInicial = fechasReporte[0];
            let zonaHorariaMoment = returnTimeZoneAdd(fechaInicial);
            fechaInicial = fechaInicial.clone().add(zonaHorariaMoment, "minute").utc().startOf("day").format("YYYY-MM-DDTHH:mm:ss[Z]");

            let fechaFin = fechasReporte[1];
            let zonaHorariaMomentFin = returnTimeZoneAdd(fechaFin);
            fechaFin = fechaFin.clone().add(zonaHorariaMomentFin, "minute").utc().endOf("day").format("YYYY-MM-DDTHH:mm:ss[Z]");

            values.idPlaza = idPlaza;
            values.idFaena = idFaena;
            values.fechasReporte = [fechaInicial, fechaFin];
            values.idsPuestoPlaza = idsPuestoPlaza;
            values.idCargo = idCargo;

            const { detallePlaza, arrPuestosTrabajadores } = await obtenerDetallePlaza(idEmpresa, values);
            this.setState(
                {
                    detallePlaza,
                    arrPuestosTrabajadores,
                    showModalDetalle: false,
                    showModalDetalle2: true,
                    loading: false,
                }
            )
        }

    }

    abreviar = (str) => {
        const num = 17;
        if (str.length <= num) {
            return str
        }
        return str.slice(0, num) + '...';
    }

    redireccionarAsignarTrabajador = (trabajador, idPuesto) => {

        console.log({ trabajador, idPuesto });
        const { Nombre, Apellido_Paterno, Apellido_Materno } = trabajador;

        const idFaena = this.state.plaza._id;

        let nombreCompleto = `${Nombre}_${Apellido_Paterno}_${Apellido_Materno}`.toUpperCase();

        nombreCompleto = nombreCompleto.replace(/\s/g, '_');

        var win = window.open(`/DetalleFaena?idFaena=${idFaena}&idPuesto=${idPuesto}&nombreTrabajador=${nombreCompleto}`);
    }

    render() {
        return (
            <Layout style={{ height: "100%" }}>
                <Loading cargando={this.state.loading} />
                <Header style={{ background: "none" }}>
                    <HeaderFicha
                        titulo="Necesidades"
                        subtitulo="Planifica según las necesidades"
                    />
                </Header>

                <hr className="division" />

                {/* CONTENIDO */}
                <Content
                    className="contenedor-general maven-r"
                    style={{ background: "none" }}
                >

                    {/* FILTROS */}
                    <Form
                        className={'maven-r'}
                        name="formReporte"
                        onFinish={this.filtrarFaenas}
                        ref={this.formRef}
                    >
                        <div>
                            <p style={{ marginBottom: '10px' }}>Filtrar por:</p>
                        </div>

                        <Row gutter={20} style={{ marginBottom: '20px' }}>

                            {/* FILTRO CENTRO DE COSTO */}
                            <Col xs={24} s={24} sm={24} md={24} lg={8} xl={3}
                                style={{ marginBottom: '15px' }}
                            >
                                <Form.Item
                                    name="centroCostos"
                                    noStyle

                                >
                                    <Select
                                        placeholder="Centro de costos"
                                        className={'filtro-desviaciones select-reporte-dia'}
                                        allowClear={true}
                                        mode={"multiple"}
                                        maxTagCount={2}
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.centroCostos.map((value, index) => (
                                            <Select.Option key={index} value={value._id}>
                                                {value.Nombre}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                            </Col>


                            <Col xs={24} s={24} sm={24} md={24} lg={8} xl={6}
                                style={{ marginBottom: '15px' }}
                            >
                                <Form.Item
                                    name="fechasReporte"
                                    noStyle
                                >
                                    <RangePicker
                                        format={"DD-MM-YYYY"}
                                        locale={locale}
                                    />
                                </Form.Item>
                            </Col>


                            {/* BTN FILTRAR */}
                            <Col xs={24} s={24} sm={24} md={24} lg={8} xl={2}
                                className={'filtrar-contratos-btn-col'}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    form="formReporte"
                                    className={'filtrar-contratos-btn'}
                                >
                                    Filtrar
                                </Button>
                            </Col>

                        </Row>
                    </Form>

                    <Row gutter={20}>
                        <Col xs={24} s={24} sm={24} md={24} lg={24} xl={23}>
                            <Row gutter={[15, 15]}>
                                {this.state.faenas.map((faena) => (
                                    <Col xs={24} sm={24} md={24} lg={12} xl={6} key={faena._id}>
                                        <Card className={'tarjetaReporteNecesidades'}>
                                            <Row gutter={15}>
                                                <Col xs={5} sm={5} md={4} lg={5} xl={5}>
                                                    <img src={faena.statusActividad === "WARNING" ? NoAsignado : CheckVerde} style={{ width: '75%' }} />
                                                </Col>
                                                <Col xs={19} sm={19} md={20} lg={19} xl={19}>
                                                    <Row>
                                                        <Col><div className={'tituloTarjeta'}><label>{faena.Centro_De_Costo.Nombre}</label></div></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={11} className={'labelTipo'}>Actividad</Col>
                                                        <Col span={13} className={'labelContenido'}>{faena.Nombre}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={11} className={'labelTipo'}>Inicio actividad</Col>
                                                        <Col span={13} className={'labelContenido'}>{moment.utc(faena.Fecha_Inicio).format('DD-MM-YYYY')}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={11} className={'labelTipo'}>Término actividad</Col>
                                                        <Col span={13} className={'labelContenido'}>{moment.utc(faena.Fecha_Fin).format('DD-MM-YYYY')}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={11} className={'labelTipo'}>Cubiertos</Col>
                                                        <Col span={13} className={'labelContenido'}>{faena.cantidadCubiertas}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={11} className={'labelTipo'}>Por cubrir</Col>
                                                        <Col span={13} className={'labelContenido'}>{faena.cantidadPorCubrir}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={11} className={'labelTipo'}>Total general</Col>
                                                        <Col span={13} className={'labelContenido'}>{faena.totalPlazasDias}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <div style={{ width: '100%', textAlign: 'right', marginTop: '10%' }}>
                                                <Button
                                                    type="primary"
                                                    className={'botonDetalle'}
                                                    onClick={() => { this.filtrarPlaza(faena._id) }}
                                                >
                                                    Detalle
                                                <EyeFilled />
                                                </Button>
                                            </div>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>

                    <Modal
                        visible={this.state.showModalDetalle}
                        footer={null}
                        className={'modalDetalleActividadNecesidades'}
                        closable={false}
                        maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
                    >
                        {
                            Object.keys(this.state.plaza).length > 0 ? (
                                <>
                                    <div className={'tarjetaModalDetalleActividad1'}>
                                        <PageHeader
                                            className="site-page-header"
                                            onBack={() => {

                                                this.setState({ showModalDetalle: false })
                                            }}
                                            title="Necesidades"
                                            subTitle={<span>Plazas de {this.state.plaza.Centro_De_Costo[0].Nombre} del {moment.utc(this.state.fechaDesde).format('DD-MM-YYYY')} al {moment.utc(this.state.fechaHasta).format('DD-MM-YYYY')}</span>}
                                            backIcon={<LeftCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={'#0141d074'} />}
                                        />
                                    </div>


                                    <Card className={'tarjetaModalDetalleActividad2'}>

                                        <div className={'tituloTarjeta'}><label>{this.state.plaza.Centro_De_Costo[0].Nombre}</label></div>

                                        <div className={'contenedortarjetaModalDetalleActividad2'}>
                                            <Row className={'encabezadoTarjetaModalDetalleActividad2'}>
                                                <Col span={1}><div></div></Col>
                                                <Col span={4} style={{ fontFamily: "MavenPro-SemiBold", fontSize: "16px" }}>Plazas</Col>
                                                <Col span={3} style={{ fontFamily: "MavenPro-SemiBold", fontSize: "16px", textAlign: "center" }}>N° Puestos</Col>
                                                <Col span={3} style={{ fontFamily: "MavenPro-SemiBold", fontSize: "16px", textAlign: "center" }}>Cubiertos</Col>
                                                <Col span={3} style={{ fontFamily: "MavenPro-SemiBold", fontSize: "16px", textAlign: "center" }}>Por Cubrir</Col>
                                                <Col span={4} style={{ fontFamily: "MavenPro-SemiBold", fontSize: "16px", textAlign: "center" }}>Total General</Col>
                                                <Col span={4} style={{ fontFamily: "MavenPro-SemiBold", fontSize: "16px", textAlign: "center" }}>Acciones</Col>
                                            </Row>


                                            <div className={'contenidoTarjetaModalDetalleActividad2'}>
                                                {this.state.plaza.plazasBuscadas.map((plaza) => (

                                                    <Row style={{ marginBottom: '2%' }} key={plaza._id}>
                                                        <Col span={1}><Badge color={plaza.statusPlaza === "WARNING" ? 'red' : '#52D68F'} style={{ width: '75%' }} className={'badge'} /></Col>
                                                        <Col span={4}>
                                                            {/* <Tooltip title={plaza.NombreDePlaza} color={'geekblue'}> */}
                                                            <span>
                                                                {/* {this.abreviar(plaza.NombreDePlaza)} */}
                                                                {plaza.NombreDePlaza}
                                                            </span>
                                                            {/* </Tooltip> */}
                                                        </Col>
                                                        <Col span={3} style={{ textAlign: "center" }}>{plaza.cantidadPuestos}</Col>
                                                        <Col span={3} style={{ textAlign: "center" }}><span>{plaza.cantidadDiasCubiertos}</span></Col>
                                                        <Col span={3} style={{ textAlign: "center" }}><span>{plaza.cantidadDiasPorCubrir}</span></Col>
                                                        <Col span={4} style={{ textAlign: "center" }}><span>{plaza.cantidadDiasTotal}</span></Col>
                                                        <Col span={4} style={{ textAlign: "center" }}>
                                                            <Button
                                                                type="primary"
                                                                className={'botonDetalle'}
                                                                style={{ marginRight: '2%' }}
                                                                onClick={() => {
                                                                    const idsPuestoPlaza = [];
                                                                    plaza.PuestosPlaza.map((puestoPlaza) => idsPuestoPlaza.push(puestoPlaza._id));

                                                                    this.detallePlaza(plaza._id, this.state.plaza._id, idsPuestoPlaza, plaza.idCargo);
                                                                    this.setState({ nombrePlazaSeleccionada: plaza.NombreDePlaza })
                                                                }}
                                                            >
                                                                Detalle
                                                            <EyeFilled />
                                                            </Button>
                                                            <Button
                                                                type="primary"
                                                                className={'botonDetalle'}
                                                                disabled={true}
                                                                onClick={() => {
                                                                    let win = window.open('/DetalleFaena?idFaena=' + this.state.plaza._id + '&idPlaza=' + plaza.IdPlaza, "_blank");
                                                                    win.focus();
                                                                }}
                                                            >
                                                                Ir a plaza
                                                            <EditFilled />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </div>
                                        </div>

                                    </Card>

                                </>

                            ) : (
                                <>
                                    <div className={'tarjetaModalDetallePlaza1'}>
                                        <PageHeader
                                            className="site-page-header"
                                            onBack={() => {
                                                this.setState({ showModalDetalle: false })
                                            }}
                                            title="Necesidades"
                                            backIcon={<LeftCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={'#0141d074'} />}
                                        />
                                    </div>
                                    <Empty
                                        imageStyle={{
                                            height: 200,
                                            marginTop: "20px",
                                        }}
                                        description={
                                            <b
                                                className={
                                                    "seleccion-trabajador-disponible"
                                                }
                                            >
                                                No se encontro información relacionada a
                                                este filtro.
                                                    </b>
                                        }
                                    ></Empty>
                                </>
                            )
                        }
                    </Modal>

                    <Modal
                        visible={this.state.showModalDetalle2}
                        footer={null}
                        className={'modalDetallePlazaNecesidades'}
                        closable={false}
                        maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
                    >
                        {
                            Object.keys(this.state.plaza).length > 0 ?
                                (
                                    <>
                                        <div className={'tarjetaModalDetallePlaza1'}>
                                            <PageHeader
                                                className="site-page-header"
                                                onBack={() => {
                                                    this.setState({ showModalDetalle2: false, showModalDetalle: true })
                                                }}
                                                title="Necesidades"
                                                subTitle={<span>Plaza {this.state.nombrePlazaSeleccionada} de {this.state.plaza.Centro_De_Costo[0].Nombre} del {moment.utc(this.state.fechaDesde).format('DD-MM-YYYY')} al {moment.utc(this.state.fechaHasta).format('DD-MM-YYYY')}</span>}
                                                backIcon={<LeftCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={'#0141d074'} />}
                                            />
                                        </div>

                                        {this.state.detallePlaza.map((arrayDias, index) => (

                                            <Card className={'tarjetaModalDetallePlaza2'} key={index}>
                                                <div><label className={'tituloTarjeta'}>Reporte Específico de Necesidades por Cargo</label></div>
                                                <div><label>{this.state.nombrePlazaSeleccionada} N°{index + 1}</label></div>
                                                
                                                <div style={{ width: '100%', paddingTop: '20px', paddingBottom: '20px' }}>
                                                    <div style={{ width: '100%', height: '120px', overflowX: 'scroll' }}>
                                                        <Steps
                                                            progressDot={(index, status, title, description) => {
                                                                if (status.description === "Cubierta") {
                                                                    return (
                                                                        <CheckCircleTwoTone
                                                                            twoToneColor={'#09C45A'}
                                                                            style={{ fontSize: '15px', position: 'relative', right: '2px', bottom: '2px', zIndex: '1000' }}
                                                                        />
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <CloseCircleTwoTone
                                                                            twoToneColor={'#F3431D'}
                                                                            style={{ fontSize: '15px', position: 'relative', right: '2px', bottom: '2px', zIndex: '1000' }}
                                                                        />
                                                                    )
                                                                }
                                                            }}
                                                        >
                                                            {arrayDias.map((dia, index) => (
                                                                <Step status={'finish'} className={'stepReporteNecesidadesPorCargo'} title={dia.fechaDia} description={dia.statusDia === "OK" ? "Cubierta" : "No Cubierta"} key={index} />
                                                            ))}

                                                        </Steps>

                                                    </div >

                                                    <span>
                                                        <style jsx="true">
                                                            {`
                                                        .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
                                                        background-color: #64b4e5 !important;
                                                        }
                                                        .ant-steps {
                                                        position: relative !important;
                                                        top: 10px !important;
                                                        height: fit-content !important;
                                                        }
                                                    `}
                                                        </style>
                                                    </span>
                                                </div >

                                                {/* <div><label style={{ color: '#9b9b9b', fontFamily: "MavenPro-Bold", fontSize: '16px', paddingBottom: '3%' }}>Debe deslizar</label></div> */}
                                            </Card>

                                        ))}

                                        {/* {this.state.arrPuestosTrabajadores.map((objPuesto, index) => {

                                            const { statusPuesto, trabajadoresDisponibles, idPuesto } = objPuesto;

                                            if (statusPuesto === 'CUBIERTA') {
                                                return (
                                                    <Card className={'tarjetaModalDetallePlaza3'} key={index}>
                                                        <div className={'tituloTarjeta'}><label>Trabajadores disponibles para puesto {this.state.nombrePlazaSeleccionada} N°{index + 1}</label></div>

                                                        <div >
                                                            <CheckCircleTwoTone
                                                                twoToneColor={'#09C45A'}
                                                                style={{ fontSize: '25px', display: 'inline-block' }}
                                                            />

                                                            <span style={{ marginLeft: '10px', color: '#0aaa4f', fontSize: '15px' }}>Puesto completamente cubierto.</span>

                                                        </div>

                                                    </Card>
                                                )
                                            }

                                            return (
                                                <Card className={'tarjetaModalDetallePlaza3'}>

                                                    <div className={'tituloTarjeta'}><label>Trabajadores disponibles para puesto {this.state.nombrePlazaSeleccionada} N°{index + 1}</label></div>

                                                    <Row gutter={[0, 15]} className={'trabajadores-cards-row'}>

                                                        {trabajadoresDisponibles.map((trabajador, index) => {

                                                            const { Nombre, Apellido_Paterno, Apellido_Materno, Telefono, Imagen } = trabajador;

                                                            let nombreCompleto = `${Nombre.split(' ')[0]} ${Apellido_Paterno}`;
                                                            const telefono = Telefono ? Telefono : 'Sin teléfono';

                                                            return (
                                                                <Col xs={24} s={24} sm={12} md={8} lg={8} xl={6} key={index}>
                                                                    <Card className={'tarjetaGenteDisponible'}>
                                                                        <Row style={{ marginBottom: '2%' }}>
                                                                            <Col span={10}>
                                                                                <div className={'res-trabajador-img-wrapper'}>
                                                                                    <img src={Imagen} className={'res-trabajador-img'} />
                                                                                </div>
                                                                            </Col>
                                                                            <Col span={14}>
                                                                                <div className={'tituloTarjeta'}><label>{nombreCompleto}</label></div>
                                                                                <label className={'numeroGenteDisponible'}>{telefono}</label>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row style={{ marginBottom: '2%' }} gutter={4}> */}
                                                                            {/* <Col xs={24} s={24} sm={24} md={24} lg={24} xl={12}>
                                                                                <Button
                                                                                    type="primary"
                                                                                    className={'botonDetalleGenteDisponible'}
                                                                                >
                                                                                    Detalle
                                                                                <EyeFilled />
                                                                                </Button>
                                                                            </Col> */}
                                                                            {/* <Col xs={24} s={24} sm={24} md={24} lg={24} xl={24}>
                                                                                <Button className={'botonAsignarPlazaGenteDisponible'}
                                                                                    disabled={true}
                                                                                    onClick={() => {
                                                                                        this.redireccionarAsignarTrabajador(trabajador, idPuesto);
                                                                                    }}
                                                                                >
                                                                                    Asignar plaza
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card>
                                                                </Col>

                                                            )
                                                        })}


                                                    </Row>
                                                </Card>
                                            )
                                        })} */}


                                    </>
                                )
                                :
                                (
                                    <>
                                        <div className={'tarjetaModalDetallePlaza1'}>
                                            <PageHeader
                                                className="site-page-header"
                                                onBack={() => {
                                                    this.setState({ showModalDetalle2: false, showModalDetalle: true })
                                                }}
                                                title="Necesidades"
                                                backIcon={<LeftCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={'#0141d074'} />}
                                            />
                                        </div>
                                        <Empty
                                        imageStyle={{
                                            height: 200,
                                            marginTop: "20px",
                                        }}
                                        description={
                                            <b
                                                className={
                                                    "seleccion-trabajador-disponible"
                                                }
                                            >
                                                No se encontro información relacionada a
                                                este filtro.
                                                </b>
                                        }
                                    ></Empty>
                                    </>
                                    
                                )
                        }

                    </Modal>

                </Content>
            </Layout >
        )
    }

}


export default ReporteNecesidades;