export const LOADING = "MANTENEDOR_FERIADOS_LOADING";
export const PARAR_LOADING = "MANTENEDOR_FERIADOS_LOADING_PARAR";
export const ERROR = "MANTENEDOR_FERIADOS_ERROR";


export const MANTENEDOR_FERIADOS_MOSTRAR_MODAL = "MANTENEDOR_FERIADOS_MOSTRAR_MODAL";
export const MANTENEDOR_FERIADOS_OCULTAR_MODAL = "MANTENEDOR_FERIADOS_OCULTAR_MODAL";
export const MANTENEDOR_FERIADOS_OCULTAR_MODAL_EDITAR = "MANTENEDOR_FERIADOS_OCULTAR_MODAL_EDITAR";
export const MANTENEDOR_FERIADOS_TOMAR_VALORES_EDITAR = "MANTENEDOR_FERIADOS_TOMAR_VALORES_EDITAR";


export const MANTENEDOR_FERIADOS_DESCARGAR_EXCEL = "MANTENEDOR_FERIADOS_DESCARGAR_EXCEL";


export const MANTENEDOR_FERIADOS_INGRESAR_FERIADO = "MANTENEDOR_FERIADOS_INGRESAR_FERIADO";
export const MANTENEDOR_FERIADOS_OBTENER_FERIADOS = "MANTENEDOR_FERIADOS_OBTENER_FERIADOS";
export const MANTENEDOR_FERIADOS_OBTENER_FERIADO = "MANTENEDOR_FERIADOS_OBTENER_FERIADO";
export const MANTENEDOR_FERIADOS_ELIMINAR_FERIADO = "MANTENEDOR_FERIADOS_ELIMINAR_FERIADO";
export const MANTENEDOR_FERIADOS_EDITAR_FERIADO = "MANTENEDOR_FERIADOS_EDITAR_FERIADO";
export const MANTENEDOR_FERIADOS_PARA_LOADING_EXCEL = "MANTENEDOR_FERIADOS_PARA_LOADING_EXCEL_FERIADO";
export const MANTENEDOR_FERIADOS_PARA_SUBIR_EXCEL = "MANTENEDOR_FERIADOS_PARA_SUBIR_EXCEL_FERIADO";