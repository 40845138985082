export const LOADING = "MANTENEDOR_TITULO_LOADING";
export const ERROR = "MANTENEDOR_TITULO_ERROR";
export const INGRESAR_ESPECIALIDAD = "MANTENEDOR_ESPECIALIDAD_INGRESAR";
export const LIMPIAR_ERROR_SUCCESS =
  "MANTENEDOR_ESPECIALIDAD_LIMPIAR_ERROR_SUCCESS";
export const MOSTRAR_MODAL_ESPECIALIDAD =
  "MANTENEDOR_ESPECIALIDAD_MOSTRAR_MODAL";
export const OCULTAR_MODAL_ESPECIALIDAD =
  "MANTENEDOR_ESPECIALIDAD_OCULTAR_MODAL";
export const OCULTAR_MODAL_ESPECIALIDAD_EDITAR =
  "MANTENEDOR_ESPECIALIDAD_OCULTAR_MODAL_EDITAR";
export const OBTENER_ESPECIALIDADES_MANTENEDOR =
  "MANTENEDOR_ESPECIALIDAD_OBTENER_ESPECIALIDADES";
export const OBTENER_ESPECIALIDAD_MANTENEDOR =
  "MANTENEDOR_ESPECIALIDAD_OBTENER_ESPECIALIDAD";
export const ELIMINAR_ESPECIALIDAD_MANTENEDOR =
  "MANTENEDOR_ESPECIALIDAD_ELIMINAR_ESPECIALIDAD";
export const EDITAR_ESPECIALIDAD_MANTENEDOR =
  "MANTENEDOR_ESPECIALIDAD_EDITAR_ESPECIALIDAD";
export const TOMAR_VALORES_EDITAR =
  "MANTENEDOR_ESPECIALIDAD_TOMAR_VALORES_EDITAR";
