// Tipos de consultas
import {
  ERROR,
  INGRESAR_USUARIO,
  LOADING,
  OBTENER_ROLES,
  LIMPIAR_ERROR_SUCCESS,
  MOSTRAR_MODAL_USUARIO,
  OCULTAR_MODAL_USUARIO,
  OBTENER_USUARIOS_MANTENEDOR,
  ELIMINAR_USUARIO_MANTENEDOR,
  OBTENER_USUARIO_MANTENEDOR,
  OCULTAR_MODAL_USUARIO_EDITAR,
  OBTENER_ROLES_USUARIO_SELECT,
  EDITAR_USUARIO_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  PARAR_LOADING,
  MANTENEDOR_USUARIO_OBTENER_EMPRESAS_VIGENTES,
  MANTENEDOR_USUARIO_OBTENER_HOLDINGS_VIGENTES,
  ACTUALIZAR_VALORES_EMPRESAS
} from "../types/MantenedorUsuarioTypes";

const INITIAL_STATE = {
  loading: false,
  roles: [],
  error: null,
  success: null,
  modalUsuario: false,
  modalUsuarioEditar: false,
  usuarios: [],
  usuario: null,
  tablaCargando: true,
  empresasHolding: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {

    case ACTUALIZAR_VALORES_EMPRESAS:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          Empresas: payload,
        },
      };
    case INGRESAR_USUARIO:
      return {
        ...state,
        loading: false,
        success: payload,
        modalUsuario: false,
        tablaCargando: false,
      };
    case OBTENER_ROLES:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        roles: payload,
      };
    case OBTENER_ROLES_USUARIO_SELECT:
      return {
        ...state,
        loading: false,
        roles: payload,
      };
    case MANTENEDOR_USUARIO_OBTENER_EMPRESAS_VIGENTES:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        empresasHolding: payload,
      };

    case EDITAR_USUARIO_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        modalUsuarioEditar: false,
      };

    case TOMAR_VALORES_EDITAR:
      const { nombreCampo, valor } = payload;
      return {
        ...state,

        usuario: {
          ...state.usuario,
          [nombreCampo]: valor,
        },
      };
    case LOADING:
      return {
        ...state,
        loading: true,
        tablaCargando: true,
      };
    case PARAR_LOADING:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      };
    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        tablaCargando: false,
      };
    case LIMPIAR_ERROR_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };
    case MOSTRAR_MODAL_USUARIO:
      return {
        ...state,
        modalUsuario: true,
      };
    case MANTENEDOR_USUARIO_OBTENER_HOLDINGS_VIGENTES: {
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        empresasHolding: payload,
      };
    }
    case OBTENER_USUARIOS_MANTENEDOR:



      return {
        ...state,
        usuarios: payload,
        loading: false,
        tablaCargando: false,
      };
    case OBTENER_USUARIO_MANTENEDOR:
      return {
        ...state,
        usuario: payload,
        loading: false,
        tablaCargando: false,
        modalUsuarioEditar: true,
      };
    case ELIMINAR_USUARIO_MANTENEDOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      };
    case OCULTAR_MODAL_USUARIO:
      return {
        ...state,
        modalUsuario: false,
      };
    case OCULTAR_MODAL_USUARIO_EDITAR:
      return {
        ...state,
        modalUsuarioEditar: false,
      };
    default:
      return state;
  }
};
