import API from "../../Redux/Api/api";

export const obtenerCentroCostos = async (idEmpresa) => {

    try {
        const url = `${API}/CentroDeCostos?empresa=${idEmpresa}`;

        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });

        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let centroCostos = data.centrosDeCostos;
                return centroCostos;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }
    } catch (error) {
        console.error(error);
    }

    return [];

}

export const obtenerFaenas = async (idEmpresa, values) => {
    try {
        const url = `${API}/Faena/ReporteNecesidades/Actividades?empresa=${idEmpresa}`;
        // const url = `${API}/Faena/ReporteDelDiaOtrosContratos?empresa=${idEmpresa}`;
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(values),
        });
        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let faenas = data.faenas;
                return faenas;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }

    } catch (error) {
        console.error(error);
    }

    return [];
}

export const obtenerPlazas = async (idEmpresa, values) => {
    try {
        const url = `${API}/Faena/ReporteNecesidades/Plazas?empresa=${idEmpresa}`;
        // const url = `${API}/Faena/ReporteDelDiaOtrosContratos?empresa=${idEmpresa}`;
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(values),
        });
        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let plazas = data.actividadSeleccionada;
                return plazas;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }

    } catch (error) {
        console.error(error);
    }

    return [];
}

export const obtenerDetallePlaza = async (idEmpresa, values) => {

    // console.log({
    //     values,
    //     idEmpresa
    // })

    try {

        const url = `${API}/Faena/ReporteNecesidades/DetallePlaza?empresa=${idEmpresa}`;
        // const url = `${API}/Faena/ReporteDelDiaOtrosContratos?empresa=${idEmpresa}`;
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(values),
        });
        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let detallePlaza = data.timelinesArr;
                let arrPuestosTrabajadores = data.trabajadoresBuscados;
                return { detallePlaza, arrPuestosTrabajadores };

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }

    } catch (error) {
        console.error(error);
    }

    return [];
}