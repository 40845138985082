import API from "../../Redux/Api/api";
import Swal from "sweetalert2";

export const obtenerCargos = async (idEmpresa) => {
    try {

        const url = `${API}/CargosTrabajadores/ReporteUtilizacionRelevos?empresa=${idEmpresa}`;

        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });

        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let cargos = data.data;
                return cargos;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }

    } catch (error) {
        console.error(error);
    }

    return [];

}

export const obtenerReporteUtilizacionRelevos = async (values, idEmpresa) => {

    try {

        const url = `${API}/ReporteUtilizacionRelevos?empresa=${idEmpresa}`;

        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(values),
        });

        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let reporte = data.relevos;
                return reporte;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }

    } catch (error) {
        console.error(error);
    }

    return [];

}

export const obtenerDetalleCurvaRelevos = async (values, idEmpresa) => {

    try {

        const url = `${API}/ReporteUtilizacionRelevos/Detalle?empresa=${idEmpresa}`;

        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(values),
        });

        const status = response.status;
        let data;

        switch (status) {
            case 200:
            case 201:

                data = await response.json();
                let detalle = data.relevos;
                return detalle;

            default:

                const error = data.err.message.toString();
                console.error(error);

                return [];
        }

    } catch (error) {
        console.error(error);
    }

    return [];

}


export const descargarExcelDetalleRelevo = async (dataDetalleRelevo) => {
    try {

        const url = `${API}/ReporteUtilizacionRelevos/DescargarExcel`;

        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(dataDetalleRelevo),
        });

        const status = await response.status;
        const excel = await response.blob();

        console.log("Status");
        console.log(status);
        
        switch (status) {
            case 200:
            case 201:

                const url = window.URL.createObjectURL(excel);
                const a = document.createElement("a");
                a.href = url;
                a.download = `DetalleRelevo.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();

                // Swal.fire({
                //     icon: "success",
                //     title: "Proceso Exitoso",
                //     text: "Documento descargado exitosamente",
                //     confirmButtonColor: "#E60AA3",
                //     confirmButtonText: "OK",
                // });

                return;

            default:

                Swal.fire({
                    icon: "error",
                    title: "Uuups...",
                    text: "No se pudo cargar la información.",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "OK",
                });

                return;
        }
        
    } catch (error) {

        Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
        
        return;
    }
}