import React, { Component } from "react";

import inactivo from "../../img/icons/inactivo.svg";
import activo from "../../img/icons/activo.svg";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import FormularioEmpresas from "../../components/formularioEmpresas/FormularioEmpresas";
import FormularioEmpresasEditar from "../../components/formularioEmpresas/FormularioEmpresasEditar";
import Loading from "../../components/loading/Loading";

import { connect } from "react-redux";
import * as mantenedorEmpresaActions from "../../Redux/actions/MantenedorEmpresaActions";

import {
  Layout,
  Table,
  Space,
  Button,
  Row,
  Col,
  Input,
  Modal,
  Form,
} from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import Swal from "sweetalert2";

const { Header, Content } = Layout;
const { Search } = Input;
const { Column } = Table;

class MantenedorEmpresas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tablaCargando: false,
      departamento: true,
      form: {
        Calle: "",
        Ciudad: "",
        Codigo_Postal: "",
        Codigo_Actividad_Economica: "",
        Comuna: "",
        Nombre: "",
        Descripcion: "",
        Razon_Social: "",
        Rut: "",
        Email: "",
        Giro: "",
        Numero_Calle: "",
        Region: "",
        Telefono: "",
        Tipo_De_Empresa: "",
        Tipo_De_Vivienda: "",
        Contacto_Comercial_Rut: "",
        Contacto_Comercial_Nombres: "",
        Contacto_Comercial_Apellido_Paterno: "",
        Contacto_Comercial_Apellido_Materno: "",
        Contacto_Comercial_Telefono: "",
        Contacto_Comercial_Email: "",
        Nombres_Representante: "",
        Apellido_Paterno_Representante: "",
        Apellido_Materno_Representante: "",
        Rut_Representante: "",
        imagen: null,
        Empresa_Matriz: true,
        Vigencia: true,
        validacionRut: "",
      },
      size: "large",
      empresasFiltros: null,
      editarCerrado: true,
    };
    this.obtenerImagen = this.obtenerImagen.bind(this);
    this.crearEmpresa = this.crearEmpresa.bind(this);
    this.cambiarEstadoVigencia = this.cambiarEstadoVigencia.bind(this);
    this.TomarValoresEditar = this.TomarValoresEditar.bind(this);
    this.EditarEmpresa = this.EditarEmpresa.bind(this);
    this.validacionRut = this.validacionRut.bind(this);
    this.buscarProvincias = this.buscarProvincias.bind(this);
    this.buscarComuna = this.buscarComuna.bind(this);
    this.cambiarEstadoNumeroDepartamento = this.cambiarEstadoNumeroDepartamento.bind(this);
  }

  componentDidMount() {
    this.props.ObtenerCodigosActividadEconomica();
    this.props.ObtenerTipoEmpresa();
    this.props.ObtenerEmpresas();

    this.props.ObtenerNacionalidades();

    this.props.ObtenerRegiones();
    this.props.ObtenerComunas();
    this.props.ObtenerProvincias();
  }

  search = (value) => {
    let busqueda = value.target.value;
    if (value !== "") {
      const EmpresasRedux = this.props.empresas;

      const empresasFiltros = EmpresasRedux.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(busqueda)
        )
      );

      this.setState({ empresasFiltros });
    } else {
      this.setState({ empresasFiltros: null });
    }
  };
  //---------MODALS-----------------------------------------------------///

  mostrarModalCrear = () => {
    this.props.MostrarModalEmpresa();
  };

  modalCrearCancelar = () => {
    this.props.OcultarModalEmpresa();
  };

  modalEditarCancelar = () => {
    this.setState({ editarCerrado: true })
    this.props.OcultarModalEmpresaEditar();
  };

  modalEmpresaCancelar = () => {
    this.props.OcultarModalEmpresa();
  };

  cambiarEditarCerrado = () => {
    this.setState({ editarCerrado: false })
  }

  //funcion para obtener y guardar la imagen
  obtenerImagen = (imagenNueva) => {
    this.setState({
      form: {
        ...this.state.form,
        imagen: imagenNueva,
      },
    });
  };

  //FUNCIONES PARA EJECUTAR APIS
  crearEmpresa = async (empresa) => {
    empresa.Vigencia = this.state.form.Vigencia;

    await this.props.CrearEmpresa(empresa);
    this.props.ObtenerEmpresas();
  };

  eliminarEmpresa = async (idEmpresa) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminará la empresa",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        await this.props.EliminarEmpresa(idEmpresa);
        this.props.ObtenerEmpresas();
      }
    });
  };

  ObtenerEmpresa = async (idEmpresa) => {
    await this.props.ObtenerEmpresa(idEmpresa);
  };

  EditarEmpresa = (Empresa) => {
    Empresa._id = this.props.empresa._id;
    Empresa.Vigencia = this.props.empresa.Vigencia;
    this.props.EditarEmpresa(Empresa);
  };

  TomarValoresEditar = (nombreCampo, valor) => {
    this.props.TomarValoresEditar(nombreCampo, valor);
  };
  //funcion para obtener el cambio del valor del switch
  cambiarEstadoVigencia = () => {
    this.setState({
      form: {
        ...this.state.form,
        Vigencia: this.state.form.Vigencia === true ? false : true,
      },
    });
  };

  validacionRut = (EstadoValidacion) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        validacionRut: EstadoValidacion,
      },
    });
  };

  //funciones para buscar select anidados de ciudades y provincias
  buscarProvincias = (idRegion) => {
    let provincias = this.props.ciudades;
    let nuevasProvincias = provincias.filter(provincia => provincia.Region === idRegion);
    this.props.InsertarProvincias(nuevasProvincias);
  }

  buscarComuna = (idProvincia) => {
    let comunas = this.props.comunas;

    let nuevasComunas = comunas.filter(comuna => comuna.Provincia === idProvincia);

    this.props.InsertarComunas(nuevasComunas);
  }

  //activar input de numero de  departamento
  cambiarEstadoNumeroDepartamento = (tipo) => {
    if (tipo.toString() === "Departamento") {
      this.setState({
        departamento: false,
      });
    } else {
      this.setState({
        departamento: true,
      });
    }
  }

  render() {
    const { size, empresasFiltros } = this.state;

    return (
      <Layout style={{ height: "100%" }}>
        <Loading cargando={this.props.loading} />
        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Empresas"
            subtitulo="Registra y administra las empresas"
          />
        </Header>

        <hr className="division" />

        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row style={{ padding: "6px 0px 22px" }} align="bottom">
            <Col className="div-input-search" span={6}>
              <Search
                placeholder="Buscar"
                onChange={(value) => this.search(value)}
                enterButton
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={6}></Col>
            <Col style={{ textAlign: "right" }} span={12}>
              {/* <Button
                className="descargar-excel"
                type="primary"
                onClick={() => {
                  this.descargarExcel();
                }}
                size={size}
                style={{ marginRight: "20px" }}
              >
                Descargar Excel
              </Button> */}
              <Button
                className="guardar-form"
                type="primary"
                onClick={() => {
                  this.mostrarModalCrear();
                }}
                size={size}
              >
                Crear empresa
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="_id"
            dataSource={
              empresasFiltros === null ? this.props.empresas : empresasFiltros
            }
            expandable={{
              expandedRowRender: (_id) => (
                <>
                  <Form>
                    <Form.Item>
                      <h4>Contacto de la empresa:</h4>
                    </Form.Item>
                    <Form.Item label="Nombre">
                      {_id.Contacto_Comercial_Nombres +
                        " " +
                        _id.Contacto_Comercial_Apellido_Paterno +
                        " " +
                        _id.Contacto_Comercial_Apellido_Materno}
                    </Form.Item>
                    <Form.Item label="Rut">
                      {_id.Contacto_Comercial_Rut}
                    </Form.Item>
                    <Form.Item label="Telefono">
                      {_id.Contacto_Comercial_Telefono}
                    </Form.Item>
                    <Form.Item label="Email">
                      {_id.Contacto_Comercial_Email}
                    </Form.Item>
                  </Form>
                </>
              ),
            }}
            loading={this.state.tablaCargando}
            bordered={true}
            pagination={{
              pageSize: [100],
            }}
          >
            <Column
              title="Foto"
              key="Foto"
              align="center"
              render={(value) => {
                return (
                  <img src={value.Imagen} className="imagen-tabla" alt="i" />
                );
              }}
            />
            <Column
              title="Razón Social"
              dataIndex="Razon_Social"
              key="Razon_Social"
            />
            <Column title="Rut" dataIndex="Rut" key="Rut" />
            <Column title="Email" dataIndex="Email" key="Email" />
            {/* <Column
              title="Sucursales"
              dataIndex="Sucursales"
              key="Sucursales"
              align="center"
              render={(value) => (
                <Space size="small">
                  <Button
                    className="ver-button"
                    icon={
                      <img
                        src={vista}
                        style={{ width: 30 }}
                        alt="icono editar"
                      />
                    }
                  />
                </Space>
              )}
            /> */}
            <Column
              title="Vigencia"
              key="Vigencia"
              align="center"
              render={(valor) => {
                return (
                  <img
                    src={valor.Vigencia === true ? activo : inactivo}
                    alt="imagen perfil de tabla"
                  />
                );
              }}
            />

            <Column
              title="Acciones"
              key="Action"
              align='center'
              render={(value) => (
                <Space size="middle">
                  <Button
                    className="editar-button"
                    onClick={() => {
                      this.ObtenerEmpresa(value._id);
                    }}
                    icon={<EditFilled />}
                  />
                  <Button
                    className="eliminar-button"
                    onClick={() => {
                      this.eliminarEmpresa(value._id);
                    }}
                    icon={<DeleteFilled />}
                  />
                </Space>
              )}
            />
          </Table>
        </Content>
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalEmpresa}
          onCancel={this.modalCrearCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioEmpresas
            vigencia={this.state.form.vigencia}
            validacionRutFunc={this.validacionRut}
            validacionRut={this.state.form.validacionRut}
            cambiarEstadoVigencia={this.cambiarEstadoVigencia}
            buscarProvincias={this.buscarProvincias}
            buscarComuna={this.buscarComuna}
            cambiarEstadoNumeroDepartamento={this.cambiarEstadoNumeroDepartamento}
            Formulario={this.state.form}
            TipoEmpresa={this.props.tipoEmpresa}
            crearEmpresa={this.crearEmpresa}
            imagen={this.state.form.imagen}
            obtenerImagen={this.obtenerImagen}
            codigosActividadEconomica={this.props.codigosActividadEconomica}
            regiones={this.props.regiones}
            nacionalidades={this.props.nacionalidades}
            ciudadesSeleccionadas={this.props.ciudadesSeleccionadas}
            comunasSeleccionadas={this.props.comunasSeleccionadas}
            departamento={this.state.departamento}
          />
        </Modal>
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalEmpresaEditar}
          onCancel={this.modalEditarCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <FormularioEmpresasEditar
            ObtenerTipoEmpresa={this.props.ObtenerTipoEmpresa}
            Formulario={this.props.empresa}
            TipoEmpresa={this.props.tipoEmpresa}
            EditarEmpresa={this.EditarEmpresa}
            TomarValoresEditar={this.TomarValoresEditar}
            imagen={this.state.form.imagen}
            obtenerImagen={this.obtenerImagen}
            buscarProvincias={this.buscarProvincias}
            buscarComuna={this.buscarComuna}
            cambiarEstadoNumeroDepartamento={this.cambiarEstadoNumeroDepartamento}
            codigosActividadEconomica={this.props.codigosActividadEconomica}
            regiones={this.props.regiones}
            nacionalidades={this.props.nacionalidades}
            ciudadesSeleccionadas={this.props.ciudadesSeleccionadas}
            comunasSeleccionadas={this.props.comunasSeleccionadas}
            departamento={this.state.departamento}

            editarCerrado={this.state.editarCerrado}
            cambiarEditarCerrado={this.cambiarEditarCerrado}
          />
        </Modal>
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  MantenedorEmpresaReducer: {
    loading,
    tipoEmpresa,
    regiones,
    ciudades,
    comunas,
    error,
    success,
    modalEmpresa,
    modalEmpresaEditar,
    empresas,
    empresa,
    tablaCargando,
    codigosActividadEconomica,
    nacionalidades,
    ciudadesSeleccionadas,
    comunasSeleccionadas,
  },
}) => {
  return {
    loading,
    tipoEmpresa,
    regiones,
    ciudades,
    comunas,
    error,
    success,
    modalEmpresa,
    modalEmpresaEditar,
    empresas,
    empresa,
    tablaCargando,
    codigosActividadEconomica,
    nacionalidades,
    ciudadesSeleccionadas,
    comunasSeleccionadas,
  };
};

export default connect(
  EstadosAPropiedades,
  mantenedorEmpresaActions
)(MantenedorEmpresas);
