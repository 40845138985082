import React from "react";
import Login from "./views/login/Login";
import RecuperarPassword from "./views/recuperarPassword/RecuperarPassword"
import CentroControl from "./views/centroControl/CentroControl";
//import Ficha from "./views/Ficha/Ficha";
import Formatos from "../src/views/Formatos/Formatos";

import AccesosSistema from "../src/views/accesosSistema/AccesosSistema";

import MantenedorUsuarios from "../src/views/mantenedorUsuarios/MantenedorUsuario";
import MantenedirSuperUsuarios from "../src/viewSuperUsuario/MantenedorUsuario/MantenedorUsuario";

import MantenedorEmpresas from "../src/views/mantenedorEmpresas/MantenedorEmpresas";
import MantenedorDocumentos from "../src/views/mantenedorDocumentos/MantenedorDocumentos";
import MantenedorVideos from "../src/views/mantenedorVideos/MantenedorVideos";
import MantenedorTipoEmpresas from "../src/views/mantenedorTipoEmpresa/MantenedorTipoEmpresas";
import MantenedorHoldings from "../src/views/mantenedorHoldings/MantenedorHoldings";
import MantenedorCargos from "../src/views/mantenedorCargos/MantenedorCargos";
import MantenedorRegimenTrabajo from "./views/mantenedorRegimenTrabajo/MantenedorRegimenTrabajo";
// import MantenedorRoles from "../src/views/MantenedorRoles/MantenedorRoles";
import MantenedorCentroCosto from "../src/views/mantenedorCentroCosto/mantenedorCentroCosto";
import MantenedorFeriados from "../src/views/mantenedorFeriados/MantenedorFeriados";
import MantenedorTrabajador from "./views/mantenedorTrabajador/MantenedorTrabajador";
import MantenedorSucursales from "./views/mantenedorSucursales/MantenedorSucursales";
import MantenedorTitulos from "./views/mantenedorTitulos/MantenedorTitulos";
import MantenedorEspecialidades from "./views/mantenedorEspecialidades/MantenedorEspecialidades";
import MantenedorEPP from "./views/mantenedorEPP/MantenedorEPP";
import MantenedorPerfilesUsuario from "./views/mantenedorPerfilesUsuario/MantenedorPerfilesUsuario";

import CheckOut from "./views/checkOut/CheckOut";

import CalendarioTrabajador from "./views/calendarioTrabajador/CalendarioTrabajador";
import FiltroTrabajadores from "./views/filtroTrabajadores/FiltroTrabajadores";
import Contratos from "./views/contratos/contratos";
import Descanso from "./views/Descanso/Descanso";
import Vacaciones from "./views/Vacaciones/Vacaciones";
import Licencia from "./views/Licencia/Licencia";
import AsistenciaQR from "./views/asistenciaQR/AsistenciaQR";
import Ausente from "./views/Ausente/Ausente";
import Accidente from './views/Accidente/Accidente';
import Permiso from './views/Permiso/Permiso';
import EnTransito from './views/enTransito/EnTransito'
import DetalleFaena from "./views/detalleFaena/DetalleFaena";
import DetalleActividad from "./views/detalleFaena/DetalleActividad";
import AsistenciaTimeLine from "./views/asistenciaTimeLine/AsistenciaTimeLine";

//reporteria
import ReporteriaAsistenciaPersonas from "./views/reporteriaAsistenciaPersonas/ReporteriaAsistenciaPersonas";
import ReporteriaAsistenciaCC from "./views/reporteriaAsistenciaCC/ReporteriaAsistenciaCC";

import DetalleCostos from "./views/detalleCostos/DetalleCostos";
import CierreDeMes from './views/cierreDeMes/CierreDeMes';
import Desviaciones from './views/desviaciones/desviaciones';

import ReporteriaExcel from "./views/reporteriaExcel/reporteriaExcel";
import ReporteUtilizacionRelevo from "./views/reporteUtilizacionRelevo/reporteUtilizacionRelevo";

//impresiones
import ImprimirLicencia from "./viewsImpresion/licencia/Licencia";
import ImprimirAusente from "./viewsImpresion/ausente/Ausente";
import ImprimirAccidente from "./viewsImpresion/accidente/Accidente";
import ImprimirDescanso from './viewsImpresion/descanso/Descanso';
import ImprimirVacaciones from './viewsImpresion/vacaciones/Vacaciones';
import ImprimirEntregaEpp from './viewsImpresion/epp/EntregaEpp';

import MantenedorPlantillasDeFaena from "./views/mantenedorPlantillaDeFaena/MantenedorPlantillaDeFaena";
import AsignacionFaenas from "./views/asignacionFaenas/asignacionFaenas";
import AsignacionActividades from "./views/asignacionFaenas/asignacionActividades";

import TestingView from "./views/testingView/testingView";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Layout from "../src/components/Layouts/Layout";

import ReporteDelDia from "./views/reporteDelDia/reporteDelDia";
import ReporteNecesidades from "./views/reporteNecesidades/reporteNecesidades";

import "antd";
import "./css/global.css";
import "antd/dist/antd.css";
import CalendarioTrabajadorNew from "./views/calendarioTrabajador/CalendarioTrabajadorNew";
import Documentacion from "./views/documentacion/Documentacion";

const App = () => (
  <BrowserRouter>
    <Layout>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/RecuperarPassword" component={RecuperarPassword} />

        <Route path="/AccesosSistema" component={AccesosSistema} />

        <Route path="/Empresas" component={MantenedorEmpresas} />
        <Route path="/SuperUsuarios" component={MantenedirSuperUsuarios} />

        <Route path="/Usuarios" component={MantenedorUsuarios} />

        <Route path="/CentroControl" component={CentroControl} />

        <Route path="/Formatos" component={Formatos} />

        <Route path="/Documentos" component={MantenedorDocumentos} />
        <Route path="/Feriados" component={MantenedorFeriados} />
        <Route path="/TiposEmpresa" component={MantenedorTipoEmpresas} />
        {/* <Route path="/Holdings" component={MantenedorHoldings} /> */}
        <Route path="/CentroDeCosto" component={MantenedorCentroCosto} />
        <Route path="/Cargos" component={MantenedorCargos} />
        <Route path="/Regimen" component={MantenedorRegimenTrabajo} />
        <Route path="/Trabajador" component={MantenedorTrabajador} />
        <Route path="/Sucursales" component={MantenedorSucursales} />
        <Route path="/Titulos" component={MantenedorTitulos} />
        <Route path="/Especialidades" component={MantenedorEspecialidades} />
        <Route path="/EPP" component={MantenedorEPP} />
        <Route path="/PerfilesPermiso" component={MantenedorPerfilesUsuario} />

        <Route path="/Videos" component={MantenedorVideos} />

        <Route path="/DetalleFaena" component={DetalleFaena} />
        <Route path="/DetalleActividad" component={DetalleActividad} />
        <Route path="/Faena" component={MantenedorPlantillasDeFaena} />
        <Route path="/AsignacionFaena" component={AsignacionFaenas} />
        <Route path="/AsignacionActividades" component={AsignacionActividades} />
        <Route path="/CalendarioTrabajador" component={CalendarioTrabajador} />
        <Route path="/CalendarioTrabajadorNew" component={CalendarioTrabajadorNew} />
        <Route path="/FiltroTrabajadores" component={FiltroTrabajadores} />

        {/* <=============================================== REPORTE DEL DIA ===============================================> */}
        <Route path="/ReporteDelDia" component={ReporteDelDia} />
        {/* <=============================================== REPORTE DEL DIA ===============================================> */}

        {/* <=============================================== REPORTE NECESIDADES ===============================================> */}
        <Route path="/ReporteNecesidades" component={ReporteNecesidades} />
        {/* <=============================================== REPORTE NECESIDADES ===============================================> */}

        <Route path="/Contratos" component={Contratos} />
        <Route path="/Descanso" component={Descanso} />
        <Route path="/Vacaciones" component={Vacaciones} />
        <Route path="/Licencias" component={Licencia} />
        <Route path="/Mutual" component={Accidente} />
        <Route path="/Permisos" component={Permiso} />
        <Route path="/EnTransito" component={EnTransito} />
        <Route path="/Ausentes" component={Ausente} />
        <Route path="/AsistenciaQR" component={AsistenciaQR} />
        <Route path="/AsistenciaTimeLine" component={AsistenciaTimeLine} />
        <Route path="/CheckOut" component={CheckOut} />

        <Route path="/ImpresionLicencia" component={ImprimirLicencia} />
        <Route path="/ImpresionAccidente" component={ImprimirAccidente} />
        <Route path="/ImpresionAusente" component={ImprimirAusente} />
        <Route path="/ImpresionDescanso" component={ImprimirDescanso} />
        <Route path="/ImpresionVacaciones" component={ImprimirVacaciones} />
        <Route path="/ImpresionEpp" component={ImprimirEntregaEpp} />


        <Route path="/Desviaciones" component={Desviaciones} />
        <Route path="/ReporteriaAsistenciaPersonas" component={ReporteriaAsistenciaPersonas} />
        <Route path="/ReporteriaAsistenciaCC" component={ReporteriaAsistenciaCC} />
        <Route path="/CierreDeMes" component={CierreDeMes} />
        <Route path="/DetalleCostos" component={DetalleCostos} />
        
        <Route path="/Reporteria/Excel" component={ReporteriaExcel} />
        <Route path="/ReporteUtilizacionRelevo" component={ReporteUtilizacionRelevo} />


        <Route path="/TestingView" component={TestingView} />

        {/* <Route path="/FichaTrabajador" component={Ficha} /> */}
        {/* <Route path="/Roles" component={MantenedorRoles} /> */}
        {/* Not Found */}

        <Route path="/Documentacion" component={Documentacion} />
        <Redirect to="/" />
      </Switch>

    </Layout>
  </BrowserRouter>
);

export default App;
