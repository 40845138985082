// Tipos de consultas
import { MOSTRAR, OCULTAR } from '../types/LayoutTypes';

const INITIAL_STATE = {
    ocultar: true
}

export default (state = INITIAL_STATE, { type }) => {
    switch (type) {
        case MOSTRAR:
            return {
                ...state,
                ocultar: false
            }
        case OCULTAR:
            return {
                ...state,
                ocultar: true
            }
        default:
            return state
    }
}