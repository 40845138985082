import React, { Component } from "react";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import { connect } from "react-redux";
import * as detalleFaenaActions from "../../Redux/actions/DetalleFaenaActions";
import Loading from "../../components/loading/Loading";

//time-line
import Timeline from "react-calendar-timeline";
import {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
  //dateOrMomentToMomentTimeZoneChile,
} from "react-calendar-timeline";

import containerResizeDetector from "react-calendar-timeline/lib/resize-detector/container";

import "../../../node_modules/react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import "../../css/calendarioLine.css";
import "./DetalleFaena.css";

import icon_error_equis from "../../img/icons/error-equis.svg";
//import icon_warning_amarillo from "../../img/icons/alerta-amarilla.svg";
import icon_arrow_green from "../../img/icons/arrow.svg";
import icon_persona_agregar from "../../img/icons/persona-agregar.svg";
import reloj from "../../img/icons/Historico.svg";
import ticket from "../../img/icons/ticket.png";

import {
  Layout,
  Button,
  Col,
  Row,
  Drawer,
  Input,
  DatePicker,
  Space,
  Popover,
  Empty,
  notification,
  Timeline as TimelineAntd,
  Divider,
  Select,
  Modal
} from "antd";

import DashboardDinamico from "../../components/dashboardDinamico/DashboardDinamico";
import { EditFilled, DeleteFilled } from "@ant-design/icons";

import Swal from "sweetalert2";
import accesosPagina from "../../funcionesGenericas/accesosUsuario";
import coloresGenerica from "../../funcionesGenericas/coloresEstadoTrabajo";
import { removeAccents } from "../../funcionesGenericas/textos"
import {
  convertirFechaString,
  // corregirZonaHoraria,
} from "../../funcionesGenericas/fechas";

import Transbordo from "../../components/transbordo/transbordo";
//import IconTransbordo from "../../img/icons/cambio.svg";

const queryString = require("query-string");
const { RangePicker } = DatePicker;
const { Header, Content } = Layout;
const { Search } = Input;

class DetalleFaena extends Component {

  constructor(props) {
    super(props);
    this.state = {
      PlazaCubrir: null,

      //
      idCupoPlaza: "",
      idPlaza: "",

      //fechas para rellenar horas
      fechaInicio: "",
      fechaFin: "",

      //fechas plaza no se pueden ingresar
      arregloFechasInhabilitada: [],
      idUsuario: "",
      trabajadorSearch: "",
      dates: null,

      //arreglo con las fechas que se desean editar
      fechasEditarSelect: [],
      fechasEditarTexto: [],


      //fechas de la busqueda de la faena
      busquedaFaenaInicio: null,
      busquedaFaenaFin: null,
      busquedaIdFaena: null,


      //fechas string para mostrar visualmente
      fechaInicioStringFaena: "",
      fechaFinStringFaena: "",


      //fechas de busqueda por defecto
      defaultDates: [],

      trabajadoresFechas: [],
      trabajadoresFiltrados: [],

      plazas: [],
      plazasSeleccionadas: [],
      gruposFiltrados: [],

      //transbordo
      mostrarModalTransbordo: false,
      trabajadorTransbordo: null,
      // centroCostoTransbordo: null,
      plazaTransbordo: null,
      puestoPlazaTransbordo: null,
      fechaTrabajoOrigenDesde: new Date(),
      fechaTrabajoOrigenHasta: new Date(),
      random: null
    };
  }

  async componentWillMount() {
    const accesos = await accesosPagina.obtenerAccesosPagina(
      'PlanificacionDetalleActividad',
      'AsignacionesDetalle'
    );

    this.setState({
      Ver: accesos.Ver,
      Editar: accesos.Editar,
      Eliminar: accesos.Eliminar,
    })
  }

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      let parametros = queryString.parse(this.props.location.search);
      let faena = await this.props.obtenerFaena(parametros.idFaena);

      let plazas = faena.Plazas;
      //console.log(faena);

      const momentFirstDay = moment.utc(faena.Fecha_Inicio);
      const momentLastDay = moment.utc(faena.Fecha_Fin);

      // console.log('fechas antes de formateo');
      // console.log(momentFirstDay);
      // console.log(momentLastDay);

      const firstDay = convertirFechaString(momentFirstDay.clone(), 1).fechaDDMMYYYY;
      const lastDay = convertirFechaString(momentLastDay.clone(), 1).fechaDDMMYYYY;
      const firstDayBdFormat = convertirFechaString(momentFirstDay.clone(), 1).fechaString;
      const lastDayBdFormat = convertirFechaString(momentLastDay.clone(), 1).fechaString;


      // console.log('fechas en formato DD-MM-YYYY')
      // console.log(firstDay)
      // console.log(lastDay)

      await this.props.ObtenerFeriados(idEmpresa);

      let plazasSeleccionadas = [];

      if (parametros.idPlaza) {
        plazasSeleccionadas = [parametros.idPlaza];
      }

      const gruposFiltrados = await this.props.ObtenerDetalleFaenaPorRangoDeFecha(
        parametros.idFaena,
        firstDayBdFormat,
        lastDayBdFormat,
        plazasSeleccionadas,
      );

      this.setState({
        busquedaFaenaInicio: firstDayBdFormat,
        busquedaFaenaFin: lastDayBdFormat,
        fechaInicioStringFaena: firstDay,
        fechaFinStringFaena: lastDay,
        busquedaIdFaena: parametros.idFaena,
        defaultDates: [momentFirstDay.clone(), momentLastDay.clone()],

        plazas,
        gruposFiltrados: gruposFiltrados ? gruposFiltrados : [],
        plazasSeleccionadas,
      });

      this.revisarRedireccion();
    }
  }

  revisarRedireccion = async () => {
    // función encargada de tratarse el caso de una redirección desde reporte de necesidades

    let parametros = queryString.parse(this.props.location.search);

    let { idPuesto, nombreTrabajador } = parametros;

    if (idPuesto) {

      nombreTrabajador = nombreTrabajador.replace(/_/g, ' ');

      const { gruposFiltrados } = this.state;

      const grupos = gruposFiltrados.length > 0 ? gruposFiltrados : this.props.grupos

      const grupoPuestoSeleccionado = grupos.filter(grupo => grupo.TipoSeccion === 'HeaderSeccion' && grupo.id === idPuesto);

      if (grupoPuestoSeleccionado.length === 1) {
        await this.buscarTrabajadoresParaElCargo(grupoPuestoSeleccionado[0]);

        this.setState({
          trabajadorSearch: nombreTrabajador,
        });

        this.setBusqueda(nombreTrabajador);

      } else {
        Swal.fire({
          title: "Error",
          text: "No se ha encontrado puesto de plaza seleccionado.",
          icon: "error",
        });
      }
    }

  }

  //FUNCION ENCARGADA DE BUSCAR INFORMACION DEL TRABAJADOR
  //DIAS QUE TRABAJARA SEGUN EL ARREGLO ESCOGIDO
  buscarInformacionTrabajador = async (
    idTrabajador,
    idPuestoPlaza,
    idPlaza,
    idGrupo,
    Inicio_Planificacion,
    Fin_Planificacion
  ) => {
    await this.vaciarFechasFiltros();

    let parametros = queryString.parse(this.props.location.search);
    const idFaena = parametros.idFaena;
    // let nuevo_fin = moment(Fin_Planificacion)
    //   .add("day", 1)
    //   .format("YYYY-MM-DD");

    await this.props.buscarTrabajadoresEliminarHoras(
      idTrabajador,
      idPuestoPlaza,
      idPlaza,
      idGrupo,
      Inicio_Planificacion,
      Fin_Planificacion,
      idFaena
    );
  };

  buscarInformacionTrabajadorEditar = async (
    idTrabajador,
    idPuestoPlaza,
    idPlaza,
    idGrupo,
    Inicio_Planificacion,
    Fin_Planificacion,
    Inicio_PlanificacionUnix,
    Fin_PlanificacionUnix
  ) => {

    await this.vaciarFechasFiltros();

    let parametros = queryString.parse(this.props.location.search);
    const idFaena = parametros.idFaena;

    await this.props.buscarTrabajadoresEditarHoras(
      idTrabajador,
      idPuestoPlaza,
      idPlaza,
      idGrupo,
      Inicio_Planificacion,
      Fin_Planificacion,
      idFaena,
      Inicio_PlanificacionUnix,
      Fin_PlanificacionUnix
    );
  };

  eliminarDiasTrabajo = async () => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminará esta planificación",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        const idTrabajador = this.props.trabajador._id;
        const Inicio_Planificacion = this.props.Inicio_Planificacion;
        const Fin_Planificacion = this.props.Fin_Planificacion;
        const idPuestoPlaza = this.props.idPuestoPlaza;
        const idPlaza = this.props.idPlaza;

        const gruposFiltrados = await this.props.EliminarPlanificacion(
          Inicio_Planificacion,
          Fin_Planificacion,
          idTrabajador,
          idPlaza,
          idPuestoPlaza,
          this.state.busquedaFaenaInicio,
          this.state.busquedaFaenaFin,
          this.state.busquedaIdFaena,
          this.state.plazasSeleccionadas,
        );

        this.setState({
          gruposFiltrados: gruposFiltrados ? gruposFiltrados : [],
          plazasSeleccionadas: gruposFiltrados ? this.state.plazasSeleccionadas : [],
        });
      }
    });
  };

  actualizarInformacionPlaza = async () => {
    try {
      const gruposFiltrados = await this.props.ActualizarDatosPlaza(
        this.state.busquedaFaenaInicio,
        this.state.busquedaFaenaFin,
        this.state.busquedaIdFaena,
        this.state.plazasSeleccionadas,
      );
      if (!gruposFiltrados === false) {
        this.setState({
          gruposFiltrados: gruposFiltrados ? gruposFiltrados : [],
          plazasSeleccionadas: gruposFiltrados ? this.state.plazasSeleccionadas : [],
        });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error)
      return false;
    }
  }

  cerrarModalTransbordo = async () => {
    this.setState({
      mostrarModalTransbordo: false,
    });
  }

  actualizarDiasDeTrabajo = async () => {
    let nuevaFechaFinBD = convertirFechaString(this.state.fechasEditarSelect[1], 2).fechaString;
    //let nuevaFechaFinBD = momentToStringFechaFin(this.state.fechasEditarSelect[1], databaseFormat);
    let fechaInicioBD = convertirFechaString(moment.utc(this.props.Inicio_Planificacion), 1).fechaString;
    let fechaFinBD = convertirFechaString(moment.utc(this.props.Fin_Planificacion), 2).fechaString;

    let nuevaFechaFinUnix = convertirFechaString(this.state.fechasEditarSelect[1], 2).fechaUNIX;
    let fechaInicioUnix = this.props.Inicio_PlanificacionUnix;
    let fechaFinUnix = this.props.Fin_PlanificacionUnix;

    let dataEnviar = {
      nuevaFechaFinPlanificacion: nuevaFechaFinBD,
      nuevaFechaFinPlanificacionUnix: nuevaFechaFinUnix,
      Inicio_Planificacion: fechaInicioBD,
      Inicio_PlanificacionUnix: fechaInicioUnix,
      Fin_Planificacion: fechaFinBD,
      Fin_PlanificacionUnix: fechaFinUnix,

      idTrabajador: this.props.trabajador._id,
      idPuestoPlaza: this.props.idPuestoPlaza,
      idPlaza: this.props.idPlaza,

      idfaena: this.state.busquedaIdFaena,
    };

    if (!moment.utc(fechaFinBD).isSame(moment.utc(nuevaFechaFinBD))) {
      if (
        !moment.utc(fechaFinBD).isAfter(
          moment.utc(nuevaFechaFinBD)
        )
      ) {
        //flujo para alargar dias de trabajo
        const gruposFiltrados = await this.props.AlargarPlanificacion(
          dataEnviar,
          this.state.busquedaFaenaInicio,
          this.state.busquedaFaenaFin,
          this.state.busquedaIdFaena,
          this.state.plazasSeleccionadas,
        )

        this.setState({
          gruposFiltrados: gruposFiltrados ? gruposFiltrados : [],
          plazasSeleccionadas: gruposFiltrados ? this.state.plazasSeleccionadas : [],
        })
      } else {
        //flujo para acortar dias de trabajo
        const gruposFiltrados = await this.props.AcortarPlanificacion(
          dataEnviar,
          this.state.busquedaFaenaInicio,
          this.state.busquedaFaenaFin,
          this.state.busquedaIdFaena,
          this.state.plazasSeleccionadas,
        );

        this.setState({
          gruposFiltrados: gruposFiltrados ? gruposFiltrados : [],
          plazasSeleccionadas: gruposFiltrados ? this.state.plazasSeleccionadas : [],
        })
      }
    } else {
      Swal.fire({
        title: "Uups",
        text: "Se debe seleccionar una fecha distinta a la actual de la planificación para editar la planificación",
        icon: "error",
        //showCancelButton: true,
        //confirmButtonColor: "#1FC600",
        // cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        //cancelButtonText: "No",
      })
    }
  };

  buscarTrabajadoresParaElCargo = async (group) => {
    let idCupoPlaza = group.id;

    let arregloDeFechasInhabilitadas = [];

    let registrosBitacora = group.plaza.BitacoraPlaza;

    registrosBitacora.forEach((bitacora) => {
      if (bitacora.IdPuestoPlaza === idCupoPlaza) {
        arregloDeFechasInhabilitadas.push(bitacora);
      }
    });

    this.setState({
      PlazaCubrir: group,
      idCupoPlaza: group.id,
      idPlaza: group.idPlaza,
      arregloFechasInhabilitada: arregloDeFechasInhabilitadas,
    });

    let objetoEnviar = {
      titulos_profesionales: group.titulos_profesionales,
      especialidades: group.especialidades,
      cargo: group.cargo,
    };

    await this.props.ObtenerTrabajadoresParaCargo(objetoEnviar);
  };

  //FUNCION PARA ASIGNAR UNA PLAZA
  asignarPlaza = (trabajador, fechas) => {

    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se asignará esta persona a la plaza",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async (result) => {

      if (result.value) {
        let usuario = localStorage.getItem("usuario-people-manage");

        if (
          usuario !== null &&
          usuario !== "" &&
          usuario !== undefined &&
          usuario !== "undefined"
        ) {
          //id de la empresa
          usuario = JSON.parse(usuario);
          const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

          //id de la plaza que deseo cubrir
          let idPlazaCupo = this.state.idCupoPlaza;

          let idTrabajador = trabajador._id;
          let idFaena = this.props.faena._id;
          let plaza = this.state.PlazaCubrir.plaza;
          ////////////////////////////////////////////
          //////////////////////////LOGICA PARA AGREGAR HORAS A LA BITACORA

          //valido que las fechas esten rellenadas
          if (!fechas || fechas.length === 0) {
            Swal.fire({
              title: "Error",
              text: "Debe seleccionar una fecha de inicio y fin",
              icon: "error",
            });
            return;
          }

          //relleno la cantidad de dias que desea llenar

          //inicio faena
          let fechaInicioFaena = moment.utc(convertirFechaString(fechas[0], 1).fechaString);
          let fechaInicioUnix = convertirFechaString(fechaInicioFaena.clone(), 1).fechaUNIX;
          let fechaInicioBD = convertirFechaString(fechaInicioFaena.clone(), 1).fechaString;
          //fin faena
          let fechaFinFaena = moment.utc(convertirFechaString(fechas[1], 2).fechaString);
          let fechaFinUnix = convertirFechaString(fechaFinFaena.clone(), 2).fechaUNIX;
          let fechaFinBD = convertirFechaString(fechaFinFaena.clone(), 2).fechaString;

          let cantidadDias = fechaFinFaena.diff(fechaInicioFaena, "days") + 1;
          let arregloBitacoraTrabajador = [];
          let arregloBitacoraFaena = [];

          for (let index = 0; index < cantidadDias; index++) {
            //let fechaUnix = momentToUnixFechaInicio(fechaInicioFaena);

            let fechatransformar = fechaInicioFaena.clone().add(index, "day");
            let fechaUnixNuevoDia = convertirFechaString(fechatransformar.clone(), 1).fechaUNIX;
            let fechaString = convertirFechaString(fechatransformar.clone(), 1).fechaString;

            //ingreso bitacora para la faena
            let bitacora = {
              Fecha: fechaString,
              FechaUnix: fechaUnixNuevoDia,
              IdTrabajador: trabajador._id,
              Estado: "5f3ef6591ecf531c1c8ab4a8",
              IdPuestoPlaza: idPlazaCupo,
              Inicio_Planificacion: fechaInicioBD,
              Fin_Planificacion: fechaFinBD,
              Inicio_PlanificacionUnix: fechaInicioUnix,
              Fin_PlanificacionUnix: fechaFinUnix,
              Centro_Costos: this.props.faena.Centro_De_Costo._id,
            };

            arregloBitacoraFaena.push(bitacora);

            //ingreso para la bitacora del trabajador
            let bitacoraTrabajador = {
              Fecha: fechaString,
              FechaUnix: fechaUnixNuevoDia,
              Inicio_Planificacion: fechaInicioBD,
              Fin_Planificacion: fechaFinBD,
              Inicio_Planificacion_Unix: fechaInicioUnix,
              Fin_Planificacion_Unix: fechaFinUnix,
              IdPuestoPlaza: idPlazaCupo,
              IdPlaza: plaza._id,
              IdTrabajador: idTrabajador,
              IdFaena: idFaena,
              Estado: "5f3ef6591ecf531c1c8ab4a8",
              TipoRegistroBitacoraTrabajador: "5f3eedf56dd7cc57f0d3a261",
              Centro_Costos: this.props.faena.Centro_De_Costo._id,
            };

            arregloBitacoraTrabajador.push(bitacoraTrabajador);
          }

          // console.log('bitacoras');
          // console.log('Arreglo de bitacoras de trabajador')
          // console.log(arregloBitacoraTrabajador);

          // console.log('Arreglo de bitacoras de faenas')
          // console.log(arregloBitacoraFaena)
          // return;

          const gruposFiltrados = await this.props.ActualizarPlazaYIngresarBitacoraTrabajador(
            arregloBitacoraTrabajador,
            plaza._id,
            idPlazaCupo,
            idTrabajador,
            fechaInicioBD,
            fechaFinBD,
            arregloBitacoraFaena,
            idEmpresa,
            this.state.busquedaFaenaInicio,
            this.state.busquedaFaenaFin,
            this.state.busquedaIdFaena,
            this.state.plazasSeleccionadas
          );

          // this.openNotificationAsignacion('bottomRight', idTrabajador)

          this.setState({
            trabajadorSearch: "",
            fechaInicio: "",
            fechaFin: "",
            trabajadoresFechas: [],
            gruposFiltrados: gruposFiltrados ? gruposFiltrados : [],
            plazasSeleccionadas: gruposFiltrados ? this.state.plazasSeleccionadas : [],
          });
        }
      }

    });
  };

  //FUNCION PARA MOSTRAR INFORMACION DEL CALENDARIO DEL LADO IZQUIERDO
  calendarioDataIzquierda = (group) => {

    switch (group.TipoSeccion) {

      case "HeaderFaena":
        return (
          <Row className="custom-group">
            <Col span={18}>
              <h3 className="header-plaza">Faena {group.title}</h3>
            </Col>
          </Row>
        );

      case "Header":
        let textoRellenada = `(${group.cantRellenas}/${group.cantPlazas})`;
        return (
          <Row className="custom-group">
            {/* <Col span={6}>
              <img src={icon_error_equis} alt="icono" />
            </Col> */}
            <Col span={18}>
              <h3 className="header-plaza">
                {group.title} {textoRellenada}
              </h3>
            </Col>
          </Row>
        );

      case "HeaderSeccion":
        return (
          <Row
            className="custom-group cont-sub-titulo"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.buscarTrabajadoresParaElCargo(group);
            }}
          >
            <Col span={5}>
              <img
                className="img-icon"
                src={icon_persona_agregar}
                alt="icono"
              />
            </Col>
            <Col span={17}>
              <h3 className="campo-plaza-light">
                {group.nombrePlaza} N°{group.numeroPlaza}
              </h3>
            </Col>
          </Row>
        );

      case "Usuario":
        return (
          <Row
            className="custom-group cont-plaza"
            // style={{ cursor: "pointer" }}
            onClick={() => {
              this.redireccionarADetalle(group.trabajador._id);
            }}
          >
            <Col span={5}>
              <img
                className="img-icon persona"
                src={group.trabajador.Imagen}
                alt="icono"
              />
            </Col>
            <Col span={17}>
              <h3 className="campo-plaza-light cursor-pointer">
                {group.trabajador.Nombre} {group.trabajador.Apellido_Paterno}
              </h3>
            </Col>
          </Row>
        );

      default:
        return (
          <Row className="custom-group">
            <Col span={18}>
              <h3 className="header-plaza">n</h3>
            </Col>
          </Row>
        );
    }
  };

  //FUNCION ENCARGADA DE MOSTRAR EL CALENDARIO CUANDO SE CARGA LA INFORMACION
  mostrarCalendario = () => {

    if (this.props.terminoCargaCalendario === true) {
      // let fechaInicio = moment(this.props.faena.Fecha_Inicio).startOf("month");
      // let fechaFin = moment(this.props.faena.Fecha_Fin).endOf("month");
      let fechaInicio = moment.unix(this.props.faena.Fecha_Inicio_Unix);
      let fechaFin = moment.unix(this.props.faena.Fecha_Fin_Unix);

      const { gruposFiltrados } = this.state;

      return (
        <Timeline
          resizeDetector={containerResizeDetector}
          groups={gruposFiltrados.length > 0 ? gruposFiltrados : this.props.grupos}
          items={this.props.items}
          sidebarWidth={250}
          //defaultTimeStart={moment()}
          defaultTimeStart={fechaInicio}
          //defaultTimeEnd={moment().add(35, "day")}
          defaultTimeEnd={fechaFin}
          groupRenderer={({ group }) => {
            return this.calendarioDataIzquierda(group);
          }}
          itemRenderer={({
            item,
            itemContext,
            getItemProps,
            getResizeProps,
          }) => {
            const {
              left: leftResizeProps,
              right: rightResizeProps,
            } = getResizeProps();

            if (item.colorBarra !== "item-gris") {
              return (
                <>
                  <Popover
                    title={`Acciones:`}
                    content={
                      <Space className={"popover-contenido"}>
                        <Button
                          className="editar-button"
                          disabled={!this.state.Editar}
                          onClick={() => {
                            {
                              let idGrupo = item.idGrupo;
                              let idTrabajador = item.idTrabajador;
                              let idPuestoPlaza = item.idPuestoPlaza;
                              let idPlaza = item.idPlaza;

                              //==============
                              let Inicio_Planificacion = item.Inicio_Planificacion;
                              let Fin_Planificacion = item.Fin_Planificacion;

                              let Inicio_PlanificacionUnix = item.Inicio_PlanificacionUnix;
                              let Fin_PlanificacionUnix = item.Fin_PlanificacionUnix;

                              if (idGrupo !== "0") {
                                if (
                                  idTrabajador !== undefined &&
                                  idPuestoPlaza !== undefined &&
                                  idPlaza !== undefined &&
                                  Inicio_Planificacion !== undefined &&
                                  Fin_Planificacion !== undefined &&
                                  Inicio_Planificacion !== "undefined" &&
                                  Fin_Planificacion !== "undefined"
                                ) {
                                  this.buscarInformacionTrabajadorEditar(
                                    idTrabajador,
                                    idPuestoPlaza,
                                    idPlaza,
                                    idGrupo,
                                    Inicio_Planificacion,
                                    Fin_Planificacion,
                                    Inicio_PlanificacionUnix,
                                    Fin_PlanificacionUnix
                                  );

                                  this.setState({
                                    fechasEditarSelect: [
                                      moment.utc(Inicio_Planificacion),
                                      moment.utc(Fin_Planificacion),
                                    ],
                                    fechasEditarTexto: [
                                      moment.utc(Inicio_Planificacion),
                                      moment.utc(Fin_Planificacion),
                                    ],
                                  });
                                }
                              }
                            }
                          }}
                          icon={<EditFilled />}
                        />

                        <Button
                          className="eliminar-button"
                          disabled={!this.state.Eliminar}
                          onClick={() => {
                            {
                              let idGrupo = item.idGrupo;
                              let idTrabajador = item.idTrabajador;
                              let idPuestoPlaza = item.idPuestoPlaza;
                              let idPlaza = item.idPlaza;

                              //==============
                              let Inicio_Planificacion = item.Inicio_Planificacion;
                              let Fin_Planificacion = item.Fin_Planificacion;

                              // let Inicio_PlanificacionUnix = item.Inicio_PlanificacionUnix;
                              // let Fin_PlanificacionUnix = item.Fin_PlanificacionUnix;

                              if (idGrupo !== "0") {
                                if (
                                  idTrabajador !== undefined &&
                                  idPuestoPlaza !== undefined &&
                                  idPlaza !== undefined &&
                                  Inicio_Planificacion !== undefined &&
                                  Fin_Planificacion !== undefined &&
                                  Inicio_Planificacion !== "undefined" &&
                                  Fin_Planificacion !== "undefined"
                                ) {
                                  this.buscarInformacionTrabajador(
                                    idTrabajador,
                                    idPuestoPlaza,
                                    idPlaza,
                                    idGrupo,
                                    Inicio_Planificacion,
                                    Fin_Planificacion
                                  );
                                }
                              }
                            }
                          }}
                          icon={<DeleteFilled />}
                        />

                        <Button
                          className="transbordo-button"
                          // disabled={!this.state.Eliminar}
                          onClick={() => {
                          
                            const { idTrabajador, idPlaza, idPuestoPlaza, Inicio_Planificacion, Fin_Planificacion } = item;
                            this.setState({
                              mostrarModalTransbordo: true,
                              trabajadorTransbordo: idTrabajador,
                              plazaTransbordo: idPlaza,
                              puestoPlazaTransbordo: idPuestoPlaza,
                              fechaTrabajoOrigenDesde: Inicio_Planificacion,
                              fechaTrabajoOrigenHasta: Fin_Planificacion,
                              random: Math.random().toString(36).substring(7)
                            });
                          }}
                          icon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 32.17 32.17">
                              <g className="iconTransbordo">
                                <path className="a" d="M86.283,63.142a3.142,3.142,0,1,0-3.142,3.142A3.145,3.145,0,0,0,86.283,63.142Z" transform="translate(-74.973 -56.23)" />
                                <path className="a" d="M8.168,16.336A8.168,8.168,0,1,0,0,8.168,8.176,8.176,0,0,0,8.168,16.336Zm0-15.08A6.91,6.91,0,0,1,12.477,13.57a4.4,4.4,0,0,0-8.619,0A6.91,6.91,0,0,1,8.168,1.257Z" />
                                <path className="a" d="M338.283,315.142a3.142,3.142,0,1,0-3.142,3.142A3.145,3.145,0,0,0,338.283,315.142Z" transform="translate(-311.14 -292.397)" />
                                <path className="a" d="M260.168,252a8.168,8.168,0,1,0,8.168,8.168A8.177,8.177,0,0,0,260.168,252Zm4.309,13.57a4.4,4.4,0,0,0-8.619,0,6.911,6.911,0,1,1,8.619,0Z" transform="translate(-236.166 -236.166)" />
                                <path className="a" d="M57.938,297.31a4.4,4.4,0,0,1-4.4-4.4v-.628h.628a.628.628,0,0,0,.5-1.005l-3.77-5.027a.628.628,0,0,0-1.005,0l-3.77,5.027a.628.628,0,0,0,.5,1.005h.628v.628a5.661,5.661,0,0,0,5.655,5.655h5.027a.628.628,0,1,0,0-1.257Z" transform="translate(-43.11 -268.03)" />
                                <path className="a" d="M57.938,297.31a4.4,4.4,0,0,1-4.4-4.4v-.628h.628a.628.628,0,0,0,.5-1.005l-3.77-5.027a.628.628,0,0,0-1.005,0l-3.77,5.027a.628.628,0,0,0,.5,1.005h.628v.628a5.661,5.661,0,0,0,5.655,5.655h5.027a.628.628,0,1,0,0-1.257Z" transform="translate(75.456 300.451) rotate(180)" />
                              </g>
                            </svg>
                          }
                        />
                      </Space>
                    }
                  >
                    <div
                      {...getItemProps(item.itemProps)}
                      className={`barra-color ${item.colorBarra}`}
                    //AL HACER DOBLE CLICK SE ENCARGA DE BUSCAR INFORMACION DEL TRABAJADOR
                    >
                      {itemContext.useResizeHandle ? (
                        <div {...leftResizeProps} />
                      ) : (
                        ""
                      )}

                      <div
                        className="rct-item-content"
                        style={{
                          maxHeight: `${itemContext.dimensions.height}`,
                        }}
                      >
                        {/* <h4>{itemContext.title}</h4> */}
                      </div>

                      {itemContext.useResizeHandle ? (
                        <div {...rightResizeProps} />
                      ) : (
                        ""
                      )}
                    </div>
                  </Popover>

                </>
              );
            } else {
              return (
                <div
                  {...getItemProps(item.itemProps)}
                  className={`barra-color ${item.colorBarra}`}
                //AL HACER DOBLE CLICK SE ENCARGA DE BUSCAR INFORMACION DEL TRABAJADOR
                >
                  {itemContext.useResizeHandle ? (
                    <div {...leftResizeProps} />
                  ) : (
                    ""
                  )}

                  <div
                    className="rct-item-content"
                    style={{
                      maxHeight: `${itemContext.dimensions.height}`,
                    }}
                  >
                    {/* <h4>{itemContext.title}</h4> */}
                  </div>

                  {itemContext.useResizeHandle ? (
                    <div {...rightResizeProps} />
                  ) : (
                    ""
                  )}
                </div>
              );
            }
          }}
        >
          <TimelineHeaders>
            <SidebarHeader>
              {({ getRootProps }) => {
                return (
                  <div
                    {...getRootProps()}
                    style={{ width: 250, borderTop: "1px solid #bbbbbb" }}
                  >
                    {/* <h1 id="nombre-faena-izq">Faena Puerto Montt</h1> */}
                  </div>
                );
              }}
            </SidebarHeader>

            <DateHeader unit="primaryHeader" />
            <DateHeader />
          </TimelineHeaders>
        </Timeline>
      );
    } else {
      return <></>;
    }

  };

  //FUNCIONES DE REDIRECCIONAR A OTRA VISTA
  redireccionarADetalle = (idUsuario) => {
    let ruta = `/CalendarioTrabajador?Trabajador=${idUsuario}`;
    window.open(ruta, "_blank");
  };

  RedireccionarDetalle = () => {
    if (this.state.Redireccionar) {
      let ruta = `/CalendarioTrabajador?Trabajador=${this.state.idUsuario}`;

      window.open(ruta, "_blank");

      this.setState({
        Redireccionar: false,
      });
    }
  };

  vaciarFechasFiltros = () => {
    this.setState({
      fechaInicio: "",
      fechaFin: "",
    });
  };

  detalleTrabajador = (trabajador) => {

    const detalleBitacoraTrabajador = trabajador.detalleBitacoraTrabajador;

    if (detalleBitacoraTrabajador.length !== 0) {
      return (
        <div
          style={{
            width: "100%",
          }}
        >

          <TimelineAntd>
            {detalleBitacoraTrabajador.map((value, index) => {
              let hexColor = coloresGenerica.obtenerHexadecimalBitacoraTrabajador(
                value.tipoBitacora
              );
              let tituloBitacora = coloresGenerica.obtenerTituloBitacoraTrabajador(
                value.tipoBitacora
              );
              return (
                <TimelineAntd.Item key={index} color={hexColor}>
                  <div style={{ width: "100%", height: "100%", borderRadius: "5px", backgroundColor: "#eee", border: "1px solid #ddd" }}>
                    <p style={{ margin: "0px", fontFamily: "MavenPro-SemiBold" }}>
                      {tituloBitacora}
                    </p>
                    {value.centroCosto !== undefined ? <p style={{ margin: "0px", fontSize: "12px", fontFamily: "MavenPro-Regular" }}> Centro Costo: {value.centroCosto}</p> : <></>}
                    {value.faena !== undefined ? <p style={{ margin: "0px", fontSize: "12px", fontFamily: "MavenPro-Regular" }}> Actividad: {value.faena}</p> : <></>}

                    <p style={{ margin: "0px", fontFamily: "MavenPro-Regular" }}>
                      {value.fecha_inicio} - {value.fecha_fin}
                    </p>
                  </div>
                </TimelineAntd.Item>
              );
            })}
          </TimelineAntd>
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: "100%",
          }}
        >
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Empty
                style={{ marginTop: "15px", marginLeft: 'auto', marginRight: 'auto' }}
                description={
                  <p>No se han encontrado registros en los<br />últimos 15 días. Si desea ver mas información<br />vea el detalle del trabajador.</p>
                }
              />
            </Col>
          </Row>
        </div>
      );
    }
  };

  mostrarTrabajadoresAsignar = () => {
    // Si hay búsqueda, filtrar trabajadores, si no, mostrar todos desde redux

    const { trabajadorSearch, trabajadoresFiltrados, trabajadoresFechas } = this.state;

    const listaTrabajadores = trabajadorSearch ? trabajadoresFiltrados : this.props.trabajadores

    if (listaTrabajadores.length > 0) {

      return listaTrabajadores.map((trabajador, index) => {
     
        const {
          RegimenDeTrabajo,
          Imagen,
          Nombre,
          Apellido_Paterno,
          Apellido_Materno,
          Tipo_Contrato,
          Contrato_Fecha_Inicio,
          Contrato_Fecha_Inicio_Unix,
          Contrato_Fecha_Fin,
          Contrato_Fecha_Fin_Unix,
          contratoVencido,
          Finiquitado,
          Fecha_Finiquito_Unix
        } = trabajador;

        // const momentContratoIn = moment.utc(Contrato_Fecha_Inicio);
        // let fechaContrato = convertirFechaString(momentContratoIn.clone(), 1).fechaDDMMYYYY;

        let horasRestarFechaInicioContrato = moment.utc(moment.unix(Contrato_Fecha_Inicio_Unix)).hours();
        let fechaStringInicioContrato = convertirFechaString(moment.unix(Contrato_Fecha_Inicio_Unix).subtract(horasRestarFechaInicioContrato, 'hours'), 1).fechaString;

        let horasRestarFechaFinContrato = moment.utc(moment.unix(Contrato_Fecha_Fin_Unix)).hours() + 1;
        let fechaStringFinContrato = convertirFechaString(moment.unix(Contrato_Fecha_Fin_Unix).subtract(horasRestarFechaFinContrato, 'hours'), 1).fechaString;

        let horasRestarFechaFiniquito = moment.utc(moment.unix(Fecha_Finiquito_Unix)).hours();
        let fechaStringFiniquitoContrato = convertirFechaString(moment.unix(Fecha_Finiquito_Unix).subtract(horasRestarFechaFiniquito, 'hours'), 1).fechaString;
 
        let fechaInicioContrato = convertirFechaString(moment.utc(fechaStringInicioContrato), 1).fechaDDMMYYYY;
        let fechaFinContrato = convertirFechaString(moment.utc(fechaStringFinContrato), 2).fechaDDMMYYYY;
        let fechaFiniquito = convertirFechaString(moment.utc(fechaStringFiniquitoContrato), 1).fechaDDMMYYYY;

        let fechaContrato = fechaInicioContrato;

        if (Tipo_Contrato === 'Plazo fijo') {
          // fechaContrato = fechaContrato + ` ${convertirFechaString(moment.utc(Contrato_Fecha_Fin).clone(), 2).fechaDDMMYYYY}`;
          fechaContrato = fechaInicioContrato + ' / ' + fechaFinContrato;
        }

        let estadoTrabajador;
        let iconoEstado;

        if (contratoVencido) {
          iconoEstado = icon_error_equis;
        } else {
          iconoEstado = icon_arrow_green;
        }
        return (
          <Popover
            content={
              <>
                {/* INFO PLANIFICACIÓN Y CONTRATO TRABAJADOR */}

                <div>
                  <p
                    style={{
                      marginBottom: "10px",
                      marginTop: '10px',
                      fontFamily: "MavenPro-SemiBold",
                      textAlign: "left",
                    }}
                  >
                    Información del trabajador
                  </p>

                  <p
                    style={{
                      marginBottom: "5px",
                      fontFamily: "MavenPro-Regular",
                      textAlign: "left",
                    }}
                  >
                    Régimen: {RegimenDeTrabajo.Nombre} ({RegimenDeTrabajo.Cantidad_Dias_Trabajo}x{RegimenDeTrabajo.Cantidad_Dias_Descanso})
                  </p>

                  <p
                    style={{
                      marginBottom: "5px",
                      fontFamily: "MavenPro-Regular",
                      textAlign: "left",
                    }}
                  >
                    Contrato: {fechaContrato} ({Tipo_Contrato})
                  </p>

                  {Finiquitado === true ?
                    (
                      <p
                        style={{
                          marginBottom: "5px",
                          fontFamily: "MavenPro-Regular",
                          textAlign: "left",
                        }}
                      >
                        Finiquitado: {Fecha_Finiquito_Unix === null ? fechaFinContrato : fechaFiniquito}
                      </p>
                    )
                    :
                    (
                      <></>
                    )
                  }

                </div>

                <Divider />

                <div>

                  <p
                    style={{
                      marginBottom: "20px",
                      fontFamily: "MavenPro-SemiBold",
                      textAlign: "left",
                    }}
                  >
                    Últimas planificaciones
                  </p>

                  {this.detalleTrabajador(trabajador)}
                </div>

              </>
            }
            trigger="hover"
            placement="left"
            key={index}
          >
            <Row key={index} className="seccion-trabajador">

              <Col span={6}>
                <img
                  alt="imagen-trabajador"
                  src={Imagen}
                  className="seleccion-trabajador-img"
                />
              </Col>

              <Col span={15}>
                <h2
                  className="seleccion-trabajador-nombre"
                  alt="imagen trabajador"
                  style={{ paddingRight: "15px" }}
                >
                  {`${Nombre} ${Apellido_Paterno} ${Apellido_Materno} `}
                </h2>
              </Col>

              <Col span={3}>
                <img
                  alt="agregar trabajador"
                  src={iconoEstado}
                  className="seleccion-trabajador-icon"
                />
              </Col>
              {(() => {

                if (estadoTrabajador === 'no disponible contrato vencido') {

                  return (
                    <>
                      <Col span={6}></Col>
                      <Col span={18}><span>Contrato vencido</span></Col>
                    </>
                  );

                } else if (estadoTrabajador === 'no disponible') {

                  return (
                    <>
                      <Col span={6}></Col>
                      <Col span={18}><span>Trabajador ya tiene planificación</span></Col>
                    </>
                  );

                } else {

                  return (
                    <>
                      <Col span={24} className="seccion-datepicker maven-r">
                        {contratoVencido
                          ? <Row>
                            <Col flex={'auto'}></Col>
                            <Col span={18}>
                              <p style={{ fontSize: '14px', margin: '0px' }}>Contrato no vigente</p>
                            </Col>
                          </Row>
                          : <RangePicker
                            locale={locale}
                            format="DD-MM-YYYY"
                            value={trabajadoresFechas[index]}
                            disabledDate={(d) => {

                              //bitacora del trabajador seleccionado
                              let fechas_trabajador = trabajador.Bitacora;

                              let arregloFechas = this.state.arregloFechasInhabilitada;

                              let finContratoTrabajador;

                              if (trabajador.Tipo_Contrato === 'Plazo fijo') {
                                finContratoTrabajador = moment.utc(trabajador.Contrato_Fecha_Fin);
                              }

                              const finiquitoObj = {
                                finiquitado: trabajador.Finiquitado,
                                fechaFinFiniquito: trabajador.Fecha_Finiquito,
                                fechaFinFiniquitoUnix: trabajador.Fecha_Finiquito_Unix
                              }

                              return this.restringirFechasAsingacion(
                                d,
                                fechas_trabajador,
                                arregloFechas,
                                finContratoTrabajador,
                                finiquitoObj,
                              );
                            }}
                            onChange={(value) => {

                              if (value) {
                                let trabajadoresFechas = this.state.trabajadoresFechas;

                                trabajadoresFechas[index] = [value[0], value[1]];

                                this.setState({ trabajadoresFechas });

                              } else {

                                let trabajadoresFechas = this.state.trabajadoresFechas;

                                trabajadoresFechas[index] = [];

                                this.setState({ trabajadoresFechas });
                              }
                            }}
                          />
                        }
                      </Col>
                      {contratoVencido
                        ? <></>
                        : <Col
                          style={{ textAlign: "right", marginTop: "20px" }}
                          span={24}
                        >
                          <Button
                            onClick={async () => {
                              await this.asignarPlaza(trabajador, trabajadoresFechas[index])
                            }}
                            className="btn-guardar-pequeño"
                          >
                            Asignar Plaza
                          </Button>
                        </Col>}
                    </>
                  );

                }
              })()}
            </Row>
          </Popover>
        )
      });

    } else {
      return (
        <Row>
          <Col span={24}>
            <Empty
              style={{ marginTop: "15px" }}
              description={
                <h2 className={"seleccion-trabajador-disponible"}>
                  No se han encontrado resultados.
                </h2>
              }
            />
          </Col>
        </Row>
      );
    }
  };

  // Función para limitar RangePicker a sólo 2 meses
  disabledDate = (current) => {
    if (!this.state.dates || this.state.dates.length === 0) {
      return false;
    }

    const tooLate =
      this.state.dates[0] && current.diff(this.state.dates[0], "months") > 2;
    const tooEarly =
      this.state.dates[1] && this.state.dates[1].diff(current, "months") > 2;
    return tooEarly || tooLate;
  };

  filtrarCalendario = (fechas) => {
    if (fechas) {
      const parametros = queryString.parse(this.props.location.search);
      // const firstDayBdFormat = convertirFechaString(momentFirstDay.clone(),1).fechaString;
      // const lastDayBdFormat = convertirFechaString(momentLastDay.clone(),1).fechaString;
      const firstDay = convertirFechaString(fechas[0].startOf("month").clone(), 1).fechaString;
      const lastDay = convertirFechaString(fechas[1].endOf("month").clone(), 2).fechaString;

      this.setState({
        busquedaFaenaInicio: firstDay,
        busquedaFaenaFin: lastDay,
        defaultDates: fechas,
      });

      this.props.ObtenerDetalleFaenaPorRangoDeFecha(
        parametros.idFaena,
        firstDay,
        lastDay,
        this.state.plazasSeleccionadas,
      );
    }
  };

  ///////////////////////////FUNCIONES ENCARGADAS DE RESTRINGIR FECHAS DE LOS DATE PICKER
  restringirFechasAsingacion = (dia, bitacoraTrabajador, fechasBloqueadas, finContratoTrabajador, finiquitoObj) => {

    //variable para verificar si se debe bloquear el dia
    let validacion = false;

    //tomo la fecha de inicio de la faena
    //si la faena no existe se tomara la fecha de hoy
    let str = moment().format('yyyy/MM/DD');
    let inicio_faena = this.props.faena === null ? moment.utc(convertirFechaString(moment(str), 1).fechaString) : moment.utc(this.props.faena.Fecha_Inicio);

    // como fin disponible: si el contrato termina antes que la actividad, utilizar la fecha fin del contrato
    let fin_restringido;
    if (finContratoTrabajador) {
      //si la faena es null se tomara la fecha de hoy
      //caso contrario se valida que fecha es mejor. Si la del fin del contrato o la faena. La menor sera esocogida
      fin_restringido =
        this.props.faena === null
          ? moment.utc(convertirFechaString(moment(str), 1).fechaString)
          : (finContratoTrabajador.isBefore(moment.utc(this.props.faena.Fecha_Fin))

            ? finContratoTrabajador
            : moment.utc(this.props.faena.Fecha_Fin))
    } else {
      //se valida si existe la faena en caso contrario se convierte la fecha del fin de la faena
      fin_restringido =
        this.props.faena === null
          ? moment.utc(convertirFechaString(moment(str), 1).fechaString)
          : moment.utc(this.props.faena.Fecha_Fin)
    }

    let finEnFechaMoment;

    if (finContratoTrabajador) {
      finEnFechaMoment = finContratoTrabajador.isBefore(moment.utc(this.props.faena.Fecha_Fin))
        ? finContratoTrabajador
        : moment.utc(this.props.faena.Fecha_Fin)

    } else {
      finEnFechaMoment = moment.utc(this.props.faena.Fecha_Fin);
    }

    //VALIDAR SI REALMENTE FUNCIONO ALGUNA VEZ ESTA VALIDACIÓN
    //SEGUN SE RECUERDA SE QUITO YA QUE NECESITABAN COLOCAR PLANIFICACIONES EN FECHAS DE RELEVO
    //buscar las fechas inhabilitadas
    // fechasBloqueadas.forEach((bitacora) => {
    //   let fechaFormatoBD = moment.utc(convertirFechaString(dia,1).fechaString);
    //   if (fechaFormatoBD.isSame(moment.utc(bitacora.Fecha))) {
    //     // if (bitacora.FinTrabajo === false
    //     //   && !moment(dia.format("DD-MM-YYYY")).isSame(convertirFechaString(finEnFechaMoment.clone(),1).fechaDDMMYYYY)) {
    //       validacion = true;
    //     //}
    //   }
    // });

    //validar fechas del trabajador
    if (validacion === false) {
      let fechaFormatoBD = moment.utc(convertirFechaString(dia, 1).fechaString);
      bitacoraTrabajador.forEach(({ Fecha }) => {
        if (fechaFormatoBD.isSame(moment.utc(Fecha))) {
          validacion = true;
        }
      });
    }


    //validar en caso de finiquito adelantado al trabajo
    if (validacion === false) {
      if (finiquitoObj.finiquitado === true) {
        let fechaFormatoBD = moment.utc(convertirFechaString(dia, 1).fechaString);
        if (fechaFormatoBD.isSameOrAfter(moment.utc(finiquitoObj.fechaFinFiniquito))) {
          validacion = true;
        }
      }
    }



    if (validacion === true) {
      return true;

    } else {

      if (!dia) {
        return true;
      } else {
        let fechaFormatoBD = moment.utc(convertirFechaString(dia, 1).fechaString);
        if (!fechaFormatoBD.isBetween(inicio_faena, fin_restringido, null, "[]")) {
          return true;
        }
        return false;
      }
    }
  };

  restringirFechasEditar = (dia, bitacoraTrabajador, fechasBloqueadas) => {
    //variable para verificar si se debe bloquear el dia
    let validacion = false;

    const inicio_faena = this.props.faena === null ? moment.utc(convertirFechaString(moment(), 1).fechaString) : moment.utc(this.props.faena.Fecha_Inicio);
    const fin_faena = this.props.faena === null ? moment.utc(convertirFechaString(moment(), 2).fechaString) : moment.utc(this.props.faena.Fecha_Fin);
    if (validacion === true) {
      return true;
    } else {
      if (!dia) {
        return true;
      } else {
        let fechaFormatoBD = moment.utc(convertirFechaString(dia, 1).fechaString);
        if (!fechaFormatoBD.isBetween(inicio_faena, fin_faena, null, "[]")) {
          return true;
        }
        //|| !dia.isBetween(inicio_faena, fin_restringido,null,"[]")
        return false;
      }
    }
  };


  ///////////////////////////FUNCION ENCARGADA DE RECOMENDAR FECHAS DE DESCANSO Y TRABAJO
  // recomendarDescansosYTrabajos = (current, trabajador) => {
  //   const feriados = this.props.feriadosSistema;

  //   const style = {};
  //   if (current.date() === 1) {
  //     style.border = "1px solid #1890ff";
  //     style.borderRadius = "50%";
  //   }
  //   return (
  //     <div className="ant-picker-cell-inner" style={style}>
  //       {current.date()}
  //     </div>
  //   );
  // };

  openNotificationAsignacion = (placement, idTrabajador) => {

    notification.open(
      {

        message: '¡Bien!',
        description: 'Haz click para ver sugerencias!',

        duration: null,
        placement,
        icon: (
          <img
            style={{ width: 20, marginTop: '-3' }}
            src={ticket}
            alt="ticket"
          />
        ),

        onClose: notification.destroy(),

        onClick: () => {

          notification.destroy()

          notification.open({
            message: `¡Bien hecho!`,
            placement,
            style: { width: 500 },
            icon: (
              <img
                style={{ width: 20, marginRight: '-20' }}
                src={ticket}
                alt="ticket"
              />
            ),
            duration: null,
            description: (
              <>

                <p style={{ marginRight: 10 }}>
                  Asignaste correctamente al Trabajador a la Actividad. Te sugerimos continuar con...
                </p>

                {/* <p style={{ marginRight: 10 }}>
                  
                </p> */}


                {/************** SUGERIDA ***************/}

                <Row>
                  <Col span={3}>

                    <a href={`/ CalendarioTrabajador ? Trabajador = ${idTrabajador} `}>
                      <img
                        style={{ width: 20, cursor: 'pointer', justifyContent: 'center' }}
                        src={reloj}
                        alt="next"
                      />
                    </a>


                  </Col>
                  <Col span={21}>


                    <a href={`/ CalendarioTrabajador ? Trabajador = ${idTrabajador} `}>
                      <p
                        style={{ cursor: 'pointer', fontFamily: "MavenPro-Bold" }}
                      >
                        Ver el calendario del Trabajador
                      </p>
                    </a>

                  </Col>
                </Row>

                <p style={{ marginRight: 10 }}>
                  O también puedes...
                </p>


                {/************** Extra 1 - Planificar Actividades para los Equipos de Trabajo ***************/}

                <Row>
                  <Col span={3}>

                    <a href={`/ AsignacionFaena`}>
                      <img
                        style={{ width: 20, cursor: 'pointer', justifyContent: 'center' }}
                        src={reloj}
                        alt="next"
                      />
                    </a>


                  </Col>
                  <Col span={21}>

                    <a href={`/ AsignacionFaena`}>
                      <p
                        style={{ cursor: 'pointer', fontFamily: "MavenPro-Bold" }}
                      >
                        Planificar Actividades para los Equipos de Trabajo
                      </p>
                    </a>

                  </Col>
                </Row >


                {/************** Extra 2 - Ver todos los trabajores ***************/}

                {/* <Row>
                  <Col span={3}>

                    <a href={`/ Trabajador`}>
                      <img
                        style={{ width: 20, cursor: 'pointer', justifyContent: 'center' }}
                        src={reloj}
                        alt="next"
                      />
                    </a>

                  </Col>
                  <Col span={21}>

                    <a href={`/ Trabajador`}>
                      <p
                        style={{ cursor: 'pointer', fontFamily: "MavenPro-Bold" }}
                      >
                        Ver todos los Trabajadores
                      </p>
                    </a>

                  </Col>
                </Row > */}


                {/************** Extra 3 - Ver las Plantillas de Equipos de Trabajo ***************/}

                <Row>
                  <Col span={3}>

                    <a href={`/ Faena`}>
                      <img
                        style={{ width: 20, cursor: 'pointer', justifyContent: 'center' }}
                        src={reloj}
                        alt="next"
                      />
                    </a>

                  </Col>
                  <Col span={21}>

                    <a href={`/ Faena`}>
                      <p
                        style={{ cursor: 'pointer', fontFamily: "MavenPro-Bold" }}
                      >
                        Ver las Plantillas de Equipos de Trabajo
                      </p>
                    </a>

                  </Col>
                </Row >


              </ >
            ),
          })

        },
      },

    );
  };

  componentWillUnmount() {
    notification.destroy();
  }

  setBusqueda = (search) => {

    let trabajadores = this.props.trabajadores;
    let trabajadoresFiltrados = [];

    console.log({
      search,
      trabajadores,
    })

    if (search) {

      trabajadoresFiltrados = trabajadores.filter((trabajador) => {

        const { Nombre, Apellido_Paterno, Apellido_Materno } = trabajador;

        let nombreTrabajador = removeAccents(`${Nombre} ${Apellido_Paterno} ${Apellido_Materno} `).toUpperCase();
        let busqueda = removeAccents(search).toUpperCase();

        return nombreTrabajador.includes(busqueda);
      });
    }

    // seteando trabajadoresFechas vacío para funcionalidad de fechas
    let trabajadoresFechas = [];

    const objState = { trabajadorSearch: search, trabajadoresFechas, trabajadoresFiltrados }

    this.setState(objState);
  }

  filtrarGrupos = (grupos, plazasSeleccionadas) => {

    if (!(plazasSeleccionadas.length > 0)) {
      return grupos;
    }

    return grupos.filter(group => {

      const { TipoSeccion } = group;

      switch (TipoSeccion) {

        case 'Header':
          if (!plazasSeleccionadas.includes(group.id)) return false;

        case 'HeaderSeccion':
        case 'Usuario':
          if (!plazasSeleccionadas.includes(group.idPlantilla)) return false;

        default:
          return true;
      }
    })

  }

  render() {

    let nombreCentroCosto = "";

    try {

      if (this.props.faena) {
        nombreCentroCosto = this.props.faena.Centro_De_Costo.Nombre;
      }
    } catch (error) {
      console.log(error);
      nombreCentroCosto = "Error al obtener nombre";
    }

    return (
      <>
        {this.RedireccionarDetalle()}

        <Layout style={{ height: "100%" }}>
          <Loading cargando={this.props.loading} />

          {/* DRAWER ASIGNAR */}
          <Drawer
            style={{ zIndex: '1000' }}
            placement={"right"}
            closable={true}
            onClose={() => {
              this.props.ocultarTrabajdores();
              this.setState({
                trabajadorSearch: "",
              });
            }}
            visible={this.props.mostrarTrabajadores}
            key={"1"}
            width={"25%"}
          >
            <Row>

              <Col span={24}>
                <h4 className="titulos-buscar-trabajador">
                  Perfiles para la plaza
                </h4>
              </Col>

              <Col span={24}>
                <Search
                  placeholder="Buscar trabajadores"
                  onChange={(e) => {
                    this.setBusqueda(e.target.value);
                  }}
                  value={this.state.trabajadorSearch}
                  enterButton
                />
              </Col>

              <Col className="cont-trabajador" span={24}>
                {this.mostrarTrabajadoresAsignar()}
              </Col>

            </Row>
          </Drawer>

          {/* DRAWER ELIMINAR */}
          <Drawer
            style={{ zIndex: '1000' }}
            placement={"right"}
            closable={true}
            onClose={() => {
              this.props.ocultarTrabajdoresEliminarHoras();
            }}
            visible={this.props.mostrarTrabajadorBorrarHoras}
            key={"2"}
            width={"25%"}
          >
            <Row>
              <Col span={24}>
                <h4 className="titulos-buscar-trabajador">
                  Eliminar tiempos del personal
                </h4>
              </Col>

              <Col span={24}>
                <h4
                  style={{
                    fontFamily: "MavenPro-Regular",
                    fontSize: "14px",
                    textAlign: "justify",
                    margin: "0px",
                  }}
                >
                  Importante: Se eliminarán sólo los días que no tengan
                  asistencia ya cubierta (color azul).
                </h4>
              </Col>

              <Col className="cont-trabajador" span={24}>
                <Row key={"1"} className="seccion-trabajador">
                  <Col span={6}>
                    <img
                      alt="seleccionar trabajador"
                      src={
                        this.props.trabajador === null
                          ? "sin data"
                          : this.props.trabajador.Imagen
                      }
                      className="seleccion-trabajador-img"
                    />
                  </Col>

                  <Col span={18}>
                    <h5
                      className="seleccion-trabajador-nombre"
                      alt="imagen trabajador"
                    >
                      {this.props.trabajador === null
                        ? "sin data"
                        : `${this.props.trabajador.Nombre} ${this.props.trabajador.Apellido_Paterno} ${this.props.trabajador.Apellido_Materno} `}
                    </h5>
                    <h5
                      className="seleccion-trabajador-disponible"
                      alt="disponibilidad"
                    >
                      {this.props.bitacoraRangoEliminar.length === 0
                        ? "sin data"
                        : `${moment.utc(this.props.bitacoraRangoEliminar[0].Fecha).format("DD/MM/YYYY")}
      - ${moment.utc(this.props.bitacoraRangoEliminar[this.props.bitacoraRangoEliminar.length - 1]
                          .Fecha).format("DD/MM/YYYY")
                        } `}
                    </h5>
                  </Col>
                  <Col
                    style={{ textAlign: "right", marginTop: "20px" }}
                    span={12}
                  >
                    <Button
                      onClick={() => {
                        this.eliminarDiasTrabajo();
                      }}
                      className="btn-guardar-pequeño"
                      disabled={this.props.bitacoraRangoEliminar.length === 0 ? true : false}
                    >
                      Eliminar Planificación
                    </Button>
                  </Col>

                  <Col
                    style={{ textAlign: "center", marginTop: "20px" }}
                    span={12}
                  ></Col>
                </Row>
              </Col>
            </Row>
          </Drawer>

          {/* DRAWER EDITAR */}
          <Drawer
            style={{ zIndex: '1000' }}
            placement={"right"}
            closable={true}
            onClose={() => {
              this.setState({
                fechasEditarSelect: [],
                fechasEditarTexto: [],
              });
              this.props.ocultarTrabajdoresEditarHoras();
            }}
            visible={this.props.mostrarTrabajadorEditarHoras}
            key={"3"}
            width={"25%"}
          >
            <Row>
              <Col span={24}>
                <h4 className="titulos-buscar-trabajador">
                  Editar tiempos del personal
                </h4>
              </Col>

              <Col span={24}>
                <h4
                  style={{
                    fontFamily: "MavenPro-Regular",
                    fontSize: "14px",
                    textAlign: "justify",
                    margin: "0px",
                  }}
                >
                  Importante: Se pueden agregar o quitar días de planificación.
                  Al quitar días se borrarán los días quitados. No se logrará
                  hacer la acción si se desea borrar un día de asistencia pasada.
                </h4>
              </Col>

              <Col className="cont-trabajador" span={24}>
                <Row key={"1"} className="seccion-trabajador">
                  <Col span={6}>
                    <img
                      alt="seleccionar trabajador"
                      src={
                        this.props.trabajador === null
                          ? "sin data"
                          : this.props.trabajador.Imagen
                      }
                      className="seleccion-trabajador-img"
                    />
                  </Col>

                  <Col span={18}>
                    <h5
                      className="seleccion-trabajador-nombre"
                      alt="imagen trabajador"
                    >
                      {this.props.trabajador === null
                        ? "sin data"
                        : `${this.props.trabajador.Nombre} ${this.props.trabajador.Apellido_Paterno} ${this.props.trabajador.Apellido_Materno} `}
                    </h5>
                    <h5
                      className="seleccion-trabajador-disponible"
                      alt="disponibilidad"
                    >
                      {this.state.fechasEditarTexto.length === 0
                        ? "sin data"
                        : `${this.state.fechasEditarTexto[0].format("DD/MM/YYYY")}
                        - ${this.state.fechasEditarTexto[1].format("DD/MM/YYYY")} `}
                    </h5>
                  </Col>

                  <Col span={24} className="seccion-datepicker">
                    <RangePicker
                      locale={locale}
                      format="DD-MM-YYYY"
                      value={this.state.fechasEditarSelect}
                      disabled={[true, false]}
                      disabledDate={(dia) => {
                        //bitacora del trabajador seleccionado
                        let fechas_trabajador = this.props.bitacoraTrabajadores;
                        let arregloFechas = this.state.arregloFechasInhabilitada;

                        return this.restringirFechasEditar(
                          dia,
                          fechas_trabajador,
                          arregloFechas
                        );
                      }}
                      onChange={(value) => {
                        this.setState({
                          fechasEditarSelect: value,
                        });
                      }}
                    />
                  </Col>
                  <Col
                    style={{ textAlign: "right", marginTop: "20px" }}
                    span={12}
                  >
                    <Button
                      onClick={() => {
                        this.actualizarDiasDeTrabajo();
                      }}
                      className="btn-guardar-pequeño"
                    >
                      Editar Planificación
                    </Button>
                  </Col>

                  <Col
                    style={{ textAlign: "center", marginTop: "20px" }}
                    span={12}
                  ></Col>
                </Row>
              </Col>
            </Row>
          </Drawer>


          <Header style={{ background: "none" }}>
            <HeaderFicha
              titulo={`Trabajadores de la actividad: ${this.props.faena === null
                ? `cargando`
                : `${this.props.faena.Nombre.toUpperCase()}`
                } `}
              subtitulo="Planifica a los trabajadores dentro de la actividad."
            />
          </Header>

          <hr className="division" style={{ marginRight: 35 }} />

          <Content
            className="contenedor-general cont-tabla-celeste"
            style={{ background: "none" }}
          >
            <DashboardDinamico reporteDashboard={this.props.reporteDashboard} />




            <Row style={{ padding: "6px 0px 22px" }} align="bottom">
              <Col span={24} className="menu-header-detalle-actividad">
                <Row>
                  <Col span={24}>
                    <p style={{ fontFamily: "MavenPro-Bold", marginBottom: "3px", marginTop: '20px' }}>
                      Filtrar:
                    </p>

                    <Row gutter={15}>

                      <Col span={6}>

                        <RangePicker
                          locale={locale}
                          picker="month"
                          //disabledDate={this.disabledDate}
                          value={this.state.defaultDates}
                          onChange={(fechas) => {
                            this.filtrarCalendario(fechas);
                          }}
                          onCalendarChange={(e) => {
                            this.setState({
                              dates: e,
                            });
                          }}
                          onOpenChange={(val) => {
                            this.setState({
                              dates: null,
                            });
                          }}
                        />

                      </Col>

                      <Col span={6}>

                        <Select
                          mode="multiple"
                          className="campos-formulario-generico"
                          placeholder="Plazas"
                          maxTagCount={1}
                          value={this.state.plazasSeleccionadas}
                          onChange={(plazasSeleccionadas) => {
                            const { grupos } = this.props;

                            const gruposFiltrados = this.filtrarGrupos(grupos, plazasSeleccionadas);

                            this.setState({
                              plazasSeleccionadas,
                              gruposFiltrados,
                            });
                          }}
                        >
                          {this.state.plazas.map((plaza, index) => (
                            <Select.Option
                              value={plaza.IdPlaza}
                              key={index}
                            >
                              {plaza.NombreDePlaza}
                            </Select.Option>
                          ))}
                        </Select>

                      </Col>

                    </Row>

                  </Col>

                  <Col span={24} style={{ marginTop: "10px" }}>

                    <h3 style={{ fontFamily: "MavenPro-Bold", fontSize: "16px" }}>
                      {`${this.props.faena === null
                        ? `cargando`
                        : `${this.props.faena.Nombre}: 
                       ${this.state.fechaInicioStringFaena} al 
                       ${this.state.fechaFinStringFaena}`
                        } `}
                    </h3>

                    <h2 style={{ fontFamily: "MavenPro-Bold", fontSize: "20px" }}>
                      {`Centro de costo: ${nombreCentroCosto} `}
                    </h2>

                  </Col>
                </Row>
              </Col>
              <Col id="cont-calendario-timeline">{this.mostrarCalendario()}</Col>
            </Row>
          </Content>

          <Modal
            //propiedades cambiables del modal
            // wrapClassName="modalDocumentos"
            visible={this.state.mostrarModalTransbordo}
            onCancel={() => {
              this.setState({
                mostrarModalTransbordo: false,
              });
            }}
            //propiedades obligatorias para modal mantenedor
            bodyStyle={{ borderRadius: "16px", padding: '0px' }}
            width="35%"
            footer={null}
            // style={{ top: '15%' }}
            className={'modalTransbordo'}
            closable={false}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}

          >
            <Transbordo
              idTrabajador={this.state.trabajadorTransbordo}
              idCentroCosto={this.props.faena ? this.props.faena.Centro_De_Costo._id : null}
              idFaena={this.props.faena ? this.props.faena._id : null}
              idPlaza={this.state.plazaTransbordo}
              idPuestoPlaza={this.state.puestoPlazaTransbordo}
              fechaTrabajoOrigenDesde={this.state.fechaTrabajoOrigenDesde}
              fechaTrabajoOrigenHasta={this.state.fechaTrabajoOrigenHasta}
              funcionRecargaInformacion={this.actualizarInformacionPlaza}
              random={this.state.random}
              cerrarModal={this.cerrarModalTransbordo}
            />
          </Modal>
        </Layout>

        <span>
          <style jsx="true">
            {`
        .ant - popover - title {
        background - color: #061fcb!important;
        color: white!important;
      }
      `}
          </style>
        </span>
      </>
    );
  }
}

const EstadosAPropiedades = ({
  DetalleFaenaReducer: {
    loading,
    error,
    success,
    faena,
    grupos,
    terminoCargaCalendario,
    trabajadores,
    mostrarTrabajadores,
    items,
    mostrarTrabajadorBorrarHoras,
    mostrarTrabajadorEditarHoras,
    bitacoraTrabajadores,
    bitacoraRangoEliminar,
    trabajador,
    idPuestoPlaza,
    idPlaza,
    reporteDashboard,
    Inicio_Planificacion,
    Fin_Planificacion,
    Inicio_PlanificacionUnix,
    Fin_PlanificacionUnix,
    FaenaEliminar,
    feriadosSistema,
  },
}) => {
  return {
    loading,
    error,
    success,
    faena,
    grupos,
    terminoCargaCalendario,
    trabajadores,
    mostrarTrabajadores,
    items,
    mostrarTrabajadorBorrarHoras,
    mostrarTrabajadorEditarHoras,
    bitacoraTrabajadores,
    bitacoraRangoEliminar,
    trabajador,
    idPuestoPlaza,
    idPlaza,
    reporteDashboard,
    Inicio_Planificacion,
    Fin_Planificacion,
    Inicio_PlanificacionUnix,
    Fin_PlanificacionUnix,
    FaenaEliminar,
    feriadosSistema,
  };
};

export default connect(EstadosAPropiedades, detalleFaenaActions)(DetalleFaena);
