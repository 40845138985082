import {
  ERROR,
  INGRESAR_ESPECIALIDAD,
  LOADING,
  MOSTRAR_MODAL_ESPECIALIDAD,
  OCULTAR_MODAL_ESPECIALIDAD,
  OBTENER_ESPECIALIDADES_MANTENEDOR,
  ELIMINAR_ESPECIALIDAD_MANTENEDOR,
  OBTENER_ESPECIALIDAD_MANTENEDOR,
  EDITAR_ESPECIALIDAD_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  OCULTAR_MODAL_ESPECIALIDAD_EDITAR,
} from "../types/MantenedorEspecialidadesTypes";

import API from "../Api/api";

import Swal from "sweetalert2";

export const CrearEspecialidad = (Especialidad) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Especialidades`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(Especialidad),
    });
    const status = response.status;

    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Se ingresó la especialidad correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: INGRESAR_ESPECIALIDAD,
          payload: "Se ingresó la especialidad correctamente",
        });

        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerEspecialidades = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;
      const url = `${API}/Especialidades?empresa=${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      const data = await response.json();

      switch (status) {
        case 200:
        case 201:
          const especialidades = data.data;

          dispatch({
            type: OBTENER_ESPECIALIDADES_MANTENEDOR,
            payload: especialidades,
          });
          break;

        default:
          const error = data.err.message.toString();

          Swal.fire({
            title: "Uups",
            text: "Error status: " + status + " ,detalle: " + error,
            icon: "error",
          });

          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerEspecialidad = (idEspecialidad) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Especialidades/${idEspecialidad}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const especialidad = data.data;
        dispatch({
          type: OBTENER_ESPECIALIDAD_MANTENEDOR,
          payload: especialidad,
        });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarEspecialidad = (idEspecialidad) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Especialidades/${idEspecialidad}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "La especialidad fue eliminada",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: ELIMINAR_ESPECIALIDAD_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarEspecialidad = (Especialidad) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Especialidades/${Especialidad._id}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },

      body: JSON.stringify(Especialidad),
    });
    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "La especialidad fue actualizada",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: EDITAR_ESPECIALIDAD_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const TomarValoresEditar = (nombreCampo, valor) => (dispatch) => {
  let objeto = {
    nombreCampo,
    valor,
  };
  dispatch({ type: TOMAR_VALORES_EDITAR, payload: objeto });
};

export const MostrarModalEspecialidad = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_ESPECIALIDAD });
};

export const OcultarModalEspecialidad = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_ESPECIALIDAD });
};

export const OcultarModalEspecialidadEditar = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_ESPECIALIDAD_EDITAR });
};
