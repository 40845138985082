import React from "react";

import "antd/dist/antd.css";
import "./SubirFoto.css";
import Swal from "sweetalert2";
import { Upload, Button } from "antd";
import { LoadingOutlined, CameraFilled, EditFilled } from "@ant-design/icons";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

  if (!isJpgOrPng) {
    Swal.fire({
      title: "OOPS!",
      icon: "error",
      text: "Solo se puede subir imagenes JPG/PNG",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    Swal.fire({
      title: "OOPS!",
      icon: "error",
      text: "La imagen debe pesar menos de 2mb",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  }
  return isJpgOrPng && isLt2M;
}

class SubirFoto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  state = {
    loading: false,
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {

        this.props.ObtenerImagen(imageUrl);
        this.setState({
          imageUrl,
          loading: false,
        });
      });
    }
  };

  componentWillReceiveProps = () => {
    this.setState({
      imageUrl: null,
    })
  }

  render() {
    const uploadButton = (
      <Button className="subir-foto" shape="circle">
        {this.state.loading ? <LoadingOutlined /> : <CameraFilled />}
      </Button>
    );

    const editButton = (
      <Button className="subir-foto" shape="circle">
        {this.state.loading ? <LoadingOutlined /> : <EditFilled />}
      </Button>
    );
    const { imageUrl } = this.state;
    return (
      <div style={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>

        <Upload
          name="avatar"
          listType="picture-card"
          showUploadList={false}
          method="GET"
          action="#"
          beforeUpload={beforeUpload}
          onChange={this.handleChange}
          accept={'.jpg , .jpeg , .png'}
          className={'upload-subir-foto'}

        >
          {imageUrl
            ? (<img src={imageUrl} alt="avatar" style={{ width: "100%" }} />)
            : (<img src={this.props.avatar} alt="avatar" style={{ width: "100%" }} />)
          }

          {imageUrl ? editButton : uploadButton}
        </Upload>
      </div>
    );
  }
}

export default SubirFoto;
