import React, { Component } from "react";

import { connect } from "react-redux";
import * as mantenedorCargosActions from "../../Redux/actions/MantenedorCargosActions";

import activo from "../../img/icons/activo.svg";
import inactivo from "../../img/icons/inactivo.svg";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import FormularioCargos from "../../components/formularioCargos/FormularioCargos";
import FormularioCargosEditar from "../../components/formularioCargos/FormularioCargosEditar";

import {
  Layout,
  Table,
  Space,
  Button,
  Row,
  Col,
  Input,
  Modal,
  //Tag,
} from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";

import Swal from "sweetalert2";
import accesosPagina from '../../funcionesGenericas/accesosUsuario';

const { Header, Content } = Layout;
const { Search } = Input;
const { Column } = Table;

class MantenedorCargos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tablaCargando: false,
      form: {
        Nombre: "",
        Vigencia: true,
        Titulo_Profesional: false,
        Especialidades: false,

        NombreEspecialidad: "",
        EspecialidadExpira: true,
        arregloEspecialidades: [],

        NombreTitulo: "",
        TituloExpira: true,
        arregloTitulos: [],
      },

      size: "large",
      cargosFiltros: null,
    };
    this.cambiarValores = this.cambiarValores.bind(this);
    this.cambiarValoresArreglos = this.cambiarValoresArreglos.bind(this);
    this.crearCargo = this.crearCargo.bind(this);
    this.TomarValoresEditar = this.TomarValoresEditar.bind(this);
    this.EditarCargo = this.EditarCargo.bind(this);
  }

  search = (value) => {
    let busqueda = value.target.value;
    if (value !== "") {
      const cargosRedux = this.props.cargos;

      const cargosFiltros = cargosRedux.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(busqueda)
        )
      );

      this.setState({ cargosFiltros });
    } else {
      this.setState({ cargosFiltros: null });
    }
  };

  //descargarExcel = () => {
  //     this.props.DescargarExcel();
  //   };

  //   search = (value) => {
  //     let busqueda = value.target.value;
  //     if (value !== "") {
  //       const RolesRedux = this.props.roles;

  //       const rolesFiltros = RolesRedux.filter((o) =>
  //         Object.keys(o).some((k) =>
  //           String(o[k]).toLowerCase().includes(busqueda)
  //         )
  //       );

  //       this.setState({ rolesFiltros });
  //     } else {
  //       this.setState({ rolesFiltros: null });
  //     }
  //   };

  cambiarValores = (nombre, valor) => {
    this.setState({
      form: {
        ...this.state.form,
        [nombre]: valor,
      },
    });
  };

  cambiarValoresArreglos = (nombre, valor) => {
    this.setState({
      form: {
        ...this.state.form,
        [nombre]: valor,
        NombreEspecialidad: "",
        EspecialidadExpira: true,
        NombreTitulo: "",
        TituloExpira: true,
      },
    });
  };

  mostrarModalCrear = () => {
    this.props.MostrarModalCargo();
  };

  async componentDidMount() {

    const accesos = await accesosPagina.obtenerAccesosPagina('MantenedorCargos');

    await this.props.ObtenerCargos();
    let epps = await this.props.ObtenerEpps();
    let documentos = await this.props.ObtenerDocumentos();

    this.setState({
      Ver: accesos.Ver,
      Editar: accesos.Editar,
      Eliminar: accesos.Eliminar,

      epps,
      documentos
    });
  }

  modalCrearCancelar = () => {
    this.props.OcultarModalCargo();
  };

  modalEditarCancelar = () => {
    this.props.OcultarModalCargoEditar();
  };

  crearCargo = (cargo) => {
    this.props.CrearCargoTrabajador(cargo);
  };

  EliminarCargo = (idCargo) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminará el cargo",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.EliminarCargo(idCargo);
      }
    });
  };

  ObtenerCargo = (idCargo) => {
    this.props.ObtenerCargo(idCargo);
  };

  EditarCargo = (cargo) => {
    cargo._id = this.props.cargo._id;
    this.props.EditarCargo(cargo);
  };

  TomarValoresEditar = (nombreCampo, valor) => {
    this.props.TomarValoresEditar(nombreCampo, valor);
  };

  render() {
    const { size, cargosFiltros } = this.state;

    return (
      <Layout style={{ height: "100%" }}>

        <Loading cargando={this.props.loading} />

        <Header style={{ background: "none" }}>
          <HeaderFicha titulo="Cargos" subtitulo="Organiza y crea cargos" />
        </Header>

        <hr className="division" />

        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row style={{ padding: "6px 0px 22px" }} align="bottom">

            <Col className="div-input-search" span={6}>
              <Search
                placeholder="Buscar"
                //onSearch={(value) => this.search(value)}
                enterButton
                onChange={(value) => this.search(value)}
              />
            </Col>

            <Col style={{ textAlign: "right" }} span={6}></Col>
            <Col style={{ textAlign: "right" }} span={12}>

              {/* <Button
                className="descargar-excel"
                type="primary"
                onClick={() => {
                  this.descargarExcel();
                }}
                size={size}
                style={{ marginRight: "20px" }}
              >
                Descargar Excel
              </Button> */}
              <Button
                className={!this.state.Editar ? "guardar-form-disabled" : "guardar-form"}
                disabled={!this.state.Editar}
                type="primary"
                onClick={() => {
                  this.mostrarModalCrear();
                }}
                size={size}
              >
                Crear cargo
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="_id"
            dataSource={
              cargosFiltros === null ? this.props.cargos : cargosFiltros
            }
            loading={this.props.tablaCargando}
            bordered={true}
            showSorterTooltip={false}
          >
            <Column
              sorter={(a, b) => {
                return a.Nombre.localeCompare(b.Nombre);
              }}
              align="left"
              title="Nombre"
              dataIndex="Nombre"
              key="Nombre"
              render={(value) => {
                return value.toUpperCase();
              }}
            />
            {/* <Column
              render = {(values)=>{

                if(values.length !== 0){
                return (
                  <div style={{textAlign:"center"}}>
                     {
                       values.map((valor,index)=>
                        <Tag style={{width:"100px",height:"25px"}} key={index} color="blue">{valor.Nombre}</Tag>
                       )
                     }
                  </div>
                )
                }else{
                  return (
                    <div style={{textAlign:"center"}}>
                      <Tag style={{width:"100px",height:"25px"}} key={1} color="#969696">Sin Titulo</Tag>
                    </div>
                  )
                }
              }}
              align="left"
              title="Titulos Profesionales"
              dataIndex="Titulos_Profesionales"
              key="Titulos_Profesionales"
            />
            <Column
             render = {(values)=>{
              if(values.length !== 0){
                return (
                  <div style={{textAlign:"center"}}>
                    {
                      values.map((valor,index)=>
                        <Tag style={{width:"100px",height:"25px"}} name={valor.Nombre} key={index} color="blue">{valor.Nombre}</Tag>
                      )
                    }
                  </div>
                )
              }else{
                return (
                  <div style={{textAlign:"center"}}>
                    <Tag style={{width:"100px",height:"25px"}} key={1} color="#969696">Sin Especialidad</Tag>
                  </div>
                )
              }
             }}
              align="left"
              title="Especialidades"
              dataIndex="Especialidades"
              key="Especialidades"
            /> */}
            <Column
              sorter={(a, b) => {
                return a.Vigencia - b.Vigencia;
              }}
              title="Vigencia"
              key="Vigencia"
              align="center"
              render={(valor) => {
                return (
                  <img
                    src={valor.Vigencia === true ? activo : inactivo}
                    alt="imagen perfil de tabla"
                  />
                );
              }}
            />

            <Column
              title="Acciones"
              key="Action"
              align="center"
              render={(value) => (
                <Space size="middle">
                  <Button
                    onClick={() => {

                      this.ObtenerCargo(value._id);
                    }}
                    className="editar-button"
                    icon={<EditFilled />}
                    disabled={!this.state.Editar}
                  />
                  <Button
                    className="eliminar-button"
                    onClick={() => {
                      this.EliminarCargo(value._id);
                    }}
                    icon={<DeleteFilled />}
                    disabled={!this.state.Eliminar}
                  />
                </Space>
              )}
            />
          </Table>
        </Content>

        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalCargo}
          onCancel={this.modalCrearCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioCargos
            Formulario={this.state.form}
            cambiarValores={this.cambiarValores}
            cambiarValoresArreglos={this.cambiarValoresArreglos}
            crearCargo={this.crearCargo}
            epps={this.state.epps}
            documentos={this.state.documentos}
          />
        </Modal>

        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalEditar"
          visible={this.props.modalCargoEditar}
          onCancel={this.modalEditarCancelar}
          // propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioCargosEditar
            Formulario={this.props.cargo}
            EditarCargo={this.EditarCargo}
            TomarValoresEditar={this.TomarValoresEditar}
            epps={this.state.epps}
            documentos={this.state.documentos}
          />
        </Modal>

      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  MantenedorCargosReducer: {
    loading,
    error,
    success,
    modalCargo,
    modalCargoEditar,
    cargos,
    cargo,
    tablaCargando,
  },
}) => {
  return {
    loading,
    error,
    success,
    modalCargo,
    modalCargoEditar,
    cargos,
    cargo,
    tablaCargando,
  };
};

export default connect(
  EstadosAPropiedades,
  mantenedorCargosActions
)(MantenedorCargos);
