import React from 'react'
import { Bar } from '@ant-design/charts';

const PeriodosBarchart = (props) => {

    let { data } = props;

    // var data = [
    //     {
    //         year: '1991',
    //         value: 3,
    //         type: 'Lon',
    //     },
    //     {
    //         year: '1992',
    //         value: 4,
    //         type: 'Lon',
    //     },
    //     {
    //         year: '1993',
    //         value: 3.5,
    //         type: 'Lon',
    //     },
    //     {
    //         year: '1994',
    //         value: 5,
    //         type: 'Lon',
    //     },
    //     {
    //         year: '1995',
    //         value: 4.9,
    //         type: 'Lon',
    //     },
    //     {
    //         year: '1996',
    //         value: 6,
    //         type: 'Lon',
    //     },
    //     {
    //         year: '1997',
    //         value: 7,
    //         type: 'Lon',
    //     },
    //     {
    //         year: '1998',
    //         value: 9,
    //         type: 'Lon',
    //     },
    //     {
    //         year: '1999',
    //         value: 13,
    //         type: 'Lon',
    //     },
    //     {
    //         year: '1991',
    //         value: 3,
    //         type: 'Bor',
    //     },
    //     {
    //         year: '1992',
    //         value: 4,
    //         type: 'Bor',
    //     },
    //     {
    //         year: '1993',
    //         value: 3.5,
    //         type: 'Bor',
    //     },
    //     {
    //         year: '1994',
    //         value: 5,
    //         type: 'Bor',
    //     },
    //     {
    //         year: '1995',
    //         value: 4.9,
    //         type: 'Bor',
    //     },
    //     {
    //         year: '1996',
    //         value: 6,
    //         type: 'Bor',
    //     },
    //     {
    //         year: '1997',
    //         value: 7,
    //         type: 'Bor',
    //     },
    //     {
    //         year: '1998',
    //         value: 9,
    //         type: 'Bor',
    //     },
    //     {
    //         year: '1999',
    //         value: 13,
    //         type: 'Bor',
    //     },
    // ];

    var config = {
        data: data.reverse(),
        isStack: true,
        xField: 'value',
        yField: 'tipo',
        seriesField: 'type',
        label: {
            position: 'middle',
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'interval-hide-overlap' },
                { type: 'adjust-color' },
            ],
        },
        color: function color(_ref) {
            let valor = _ref.type;
            switch (valor) {
                case 'Trabajo': return '#75d154';
                case 'Descanso': return '#3CADF0';
                case 'Desviación': return '#ff704d';
                default: return 'gray';
            }
        },
        legend: {
            flipPage: false,
        }
    };

    return (
        <Bar {...config} />
    )
}

export default PeriodosBarchart;
