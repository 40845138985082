import React, { Component } from "react";

import { connect } from "react-redux";
import * as mantenedorHoldingsActions from "../../Redux/actions/MantenedorHoldingsActions";

import activo from "../../img/icons/activo.svg";
import inactivo from "../../img/icons/inactivo.svg";
import vista from "../../img/icons/vista.svg";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import FormularioHoldings from "../../components/formularioHoldings/FormularioHoldings";
import FormularioHoldingsEditar from "../../components/formularioHoldings/FormularioHoldingsEditar";
import Loading from "../../components/loading/Loading";

import Swal from "sweetalert2";
import { Layout, Table, Space, Button, Row, Col, Input, Modal } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";

const { Header, Content } = Layout;
const { Search } = Input;
const { Column } = Table;

class MantenedorHoldings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tablaCargando: false,
      form: {
        Nombre: "",
        Empresas: [],
        Vigencia: true,
      },
      size: "large",
      holdingsFiltros: null,
    };
    this.cambiarEstadoVigencia = this.cambiarEstadoVigencia.bind(this);
    this.TomarValoresEditar = this.TomarValoresEditar.bind(this);
    this.EditarHolding = this.EditarHolding.bind(this);
    this.crearHolding = this.crearHolding.bind(this);
  }

  //  FUNCION BUSQUEDA
  search = (value) => {
    let busqueda = value.target.value;
    if (value !== "") {
      const HoldingsRedux = this.props.holdings;

      const holdingsFiltros = HoldingsRedux.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(busqueda)
        )
      );

      this.setState({ holdingsFiltros });
    } else {
      this.setState({ holdingsFiltros: null });
    }
  };

  mostrarModalCrear = () => {
    this.props.MostrarModalHolding();
  };

  //FUNCIONES NATIVAS DE REACT
  componentDidMount() {
    this.props.ObtenerEmpresas();
    this.props.ObtenerHoldings();
  }

  modalCrearCancelar = () => {
    this.props.OcultarModalHolding();
  };

  modalEditarCancelar = () => {
    this.props.OcultarModalHoldingEditar();
  };

  //funcion para obtener el cambio del valor del switch
  cambiarEstadoVigencia = () => {
    this.setState({
      form: {
        ...this.state.form,
        Vigencia: this.state.form.Vigencia === true ? false : true,
      },
    });
  };

  //FUNCIONES PARA EJECUTAR APIS
  crearHolding = (holding) => {
    holding.Vigencia = this.state.form.Vigencia;
    this.props.CrearHolding(holding);
  };

  eliminarHolding = (idHolding) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminará el holding",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.EliminarHolding(idHolding);
      }
    });
  };

  ObtenerHolding = async (idHolding) => {
    this.props.ObtenerHolding(idHolding);
  };

  EditarHolding = (Holding) => {
    Holding._id = this.props.holding._id;
    Holding.Vigencia = this.props.holding.Vigencia;
    this.props.EditarHolding(Holding);
  };

  TomarValoresEditar = (nombreCampo, valor) => {
    this.props.TomarValoresEditar(nombreCampo, valor);
  };

  modalHoldingCancelar = () => {
    this.props.OcultarModalHolding();
  };

  render() {
    const { size, holdingsFiltros } = this.state;

    return (
      <Layout style={{ height: "100%" }}>
        <Loading cargando={this.props.loading} />
        <Header style={{ background: "none" }}>
          <HeaderFicha titulo="Holdings" subtitulo="Vincula empresas" />
        </Header>
        <hr className="division" />
        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row style={{ padding: "6px 0px 22px" }} align="bottom">
            <Col className="div-input-search" span={6}>
              <Search
                placeholder="Buscar"
                onChange={(value) => this.search(value)}
                enterButton
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={6}></Col>
            <Col style={{ textAlign: "right" }} span={12}>
              {/* <Button
                className="descargar-excel"
                type="primary"
                onClick={() => {
                  this.descargarExcel();
                }}
                size={size}
                style={{ marginRight: "20px" }}
              >
                Descargar Excel
              </Button> */}
              <Button
                className="guardar-form"
                type="primary"
                onClick={() => {
                  this.mostrarModalCrear();
                }}
                size={size}
              >
                Crear Holding
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="_id"
            dataSource={
              holdingsFiltros === null ? this.props.holdings : holdingsFiltros
            }
            loading={this.props.tablaCargando}
            bordered={true}
            showSorterTooltip={false}
          >
            <Column
              //   sorter={(a, b) => {
              //     return a.Apellido_Materno.localeCompare(b.Apellido_Materno);
              //   }}
              title="Nombre"
              dataIndex="Nombre"
              key="Nombre"
              align="left"
              ellipsis={true}
            />

            <Column
              title="Empresas"
              dataIndex="Empresas"
              key="Empresas"
              align="center"
              render={(value) => (
                <Space size="small">
                  <Button
                    className="ver-button"
                    icon={
                      <img
                        src={vista}
                        style={{ width: 30 }}
                        alt="icono editar"
                      />
                    }
                  />
                </Space>
              )}
            />
            <Column
              ellipsis={true}
              sorter={(a, b) => {
                return a.Vigencia - b.Vigencia;
              }}
              title="Vigencia"
              key="Vigencia"
              align="center"
              render={(valor) => {
                return (
                  <img
                    src={valor.Vigencia === true ? activo : inactivo}
                    alt="imagen perfil de tabla"
                  />
                );
              }}
            />

            <Column
              title="Acciones"
              key="Action"
              render={(value) => (
                <Space size="small">
                  <Button
                    className="editar-button"
                    onClick={() => {
                      this.ObtenerHolding(value._id);
                    }}
                    icon={<EditFilled />}
                  />
                  <Button
                    className="eliminar-button"
                    onClick={() => {
                      this.eliminarHolding(value._id);
                    }}
                    icon={<DeleteFilled />}
                  />
                </Space>
              )}
            />
          </Table>
        </Content>
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalHolding}
          onCancel={this.modalCrearCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="60%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <FormularioHoldings
            // propiedades cambiables del formulario
            Empresas={this.props.empresas}
            Formulario={this.state.form}
            vigencia={this.state.form.vigencia}
            cambiarEstadoVigencia={this.cambiarEstadoVigencia}
            crearHolding={this.crearHolding}
          />
        </Modal>
        <Modal
          // propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalHoldingEditar}
          onCancel={this.modalEditarCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="60%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <FormularioHoldingsEditar
            // propiedades cambiables del formulario
            Empresas={this.props.empresas}
            Formulario={this.props.holding}
            ObtenerEmpresas={this.props.ObtenerEmpresas}
            editarHolding={this.EditarHolding}
            TomarValoresEditar={this.TomarValoresEditar}
          />
        </Modal>
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  MantenedorHoldingsReducer: {
    loading,
    empresas,
    error,
    success,
    modalHolding,
    modalHoldingEditar,
    holdings,
    holding,
    tablaCargando,
  },
}) => {
  return {
    loading,
    empresas,
    error,
    success,
    modalHolding,
    modalHoldingEditar,
    holdings,
    holding,
    tablaCargando,
  };
};

export default connect(
  EstadosAPropiedades,
  mantenedorHoldingsActions
)(MantenedorHoldings);
