import React, { useEffect } from "react";
import Swal from "sweetalert2";
import faena from "../../img/icons/faena.svg";
import iconLapiz from "../../img/icons/lapiz-gris.png";
import iconBasurero from "../../img/icons/basurero-gris.png";
import iconOjoGris from "../../img/icons/ojo-gris.png";
import iconDashboard from "../../img/icons/dashboard-azul.png";
import iconAsignaciones from "../../img/icons/asignaciones-azul.png";
import iconTrabajadores from "../../img/icons-sidebar/azules/Trabajadores.png";
import iconProcesosDelTrabajador from "../../img/icons/procesos-del-trabajador-azul.png";
import iconAsistencia from "../../img/icons/asistencia-azul.png";
import iconMantenedores from "../../img/icons/mantenedores.png";

import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  PageHeader,
  Checkbox,
} from "antd";

import {
  FileDoneOutlined,
} from "@ant-design/icons";

const FormularioPerfilPermisoEditar = (props) => {

  const [form] = Form.useForm();

  const onFinish = (values) => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      let permisos = {

        Nombre: values.Nombre,

        Empresa: usuario.EmpresaSeleccionada.Empresa._id,

        Dashboard: {
          Ver: (values.Dashboard !== "undefined" && values.Dashboard !== undefined ? values.Dashboard.some(value => value === "Ver") : false),
          Editar: (values.Dashboard !== "undefined" && values.Dashboard !== undefined ? values.Dashboard.some(value => value === "Editar") : false),
          Eliminar: (values.Dashboard !== "undefined" && values.Dashboard !== undefined ? values.Dashboard.some(value => value === "Eliminar") : false),
        },

        MantenedorTrabajadores: {
          Ver: (values.MantenedorTrabajadores !== "undefined" && values.MantenedorTrabajadores !== undefined ? values.MantenedorTrabajadores.some(value => value === "Ver") : false),
          Editar: (values.MantenedorTrabajadores !== "undefined" && values.MantenedorTrabajadores !== undefined ? values.MantenedorTrabajadores.some(value => value === "Editar") : false),
          Eliminar: (values.MantenedorTrabajadores !== "undefined" && values.MantenedorTrabajadores !== undefined ? values.MantenedorTrabajadores.some(value => value === "Eliminar") : false),
        },

        Contratos: {
          Ver: (values.Contratos !== "undefined" && values.Contratos !== undefined ? values.Contratos.some(value => value === "Ver") : false),
          Editar: (values.Contratos !== "undefined" && values.Contratos !== undefined ? values.Contratos.some(value => value === "Editar") : false),
          Eliminar: (values.Contratos !== "undefined" && values.Contratos !== undefined ? values.Contratos.some(value => value === "Eliminar") : false),
        },

        PlanificacionEquiposDeTrabajo: {
          Ver: (values.PlanificacionEquiposDeTrabajo !== "undefined" && values.PlanificacionEquiposDeTrabajo !== undefined ? values.PlanificacionEquiposDeTrabajo.some(value => value === "Ver") : false),
          Editar: (values.PlanificacionEquiposDeTrabajo !== "undefined" && values.PlanificacionEquiposDeTrabajo !== undefined ? values.PlanificacionEquiposDeTrabajo.some(value => value === "Editar") : false),
          Eliminar: (values.PlanificacionEquiposDeTrabajo !== "undefined" && values.PlanificacionEquiposDeTrabajo !== undefined ? values.PlanificacionEquiposDeTrabajo.some(value => value === "Eliminar") : false),
        },

        PlanificacionActividades: {
          Ver: (values.PlanificacionActividades !== "undefined" && values.PlanificacionActividades !== undefined ? values.PlanificacionActividades.some(value => value === "Ver") : false),
          Editar: (values.PlanificacionActividades !== "undefined" && values.PlanificacionActividades !== undefined ? values.PlanificacionActividades.some(value => value === "Editar") : false),
          Eliminar: (values.PlanificacionActividades !== "undefined" && values.PlanificacionActividades !== undefined ? values.PlanificacionActividades.some(value => value === "Eliminar") : false),
        },

        PlanificacionDetalleActividad: {
          Ver: (values.PlanificacionDetalleActividad !== "undefined" && values.PlanificacionDetalleActividad !== undefined ? values.PlanificacionDetalleActividad.some(value => value === "Ver") : false),
          Editar: (values.PlanificacionDetalleActividad !== "undefined" && values.PlanificacionDetalleActividad !== undefined ? values.PlanificacionDetalleActividad.some(value => value === "Editar") : false),
          Eliminar: (values.PlanificacionDetalleActividad !== "undefined" && values.PlanificacionDetalleActividad !== undefined ? values.PlanificacionDetalleActividad.some(value => value === "Eliminar") : false),
        },

        PlanificacionDetalleTrabajador: {
          Ver: (values.PlanificacionDetalleTrabajador !== "undefined" && values.PlanificacionDetalleTrabajador !== undefined ? values.PlanificacionDetalleTrabajador.some(value => value === "Ver") : false),
          Editar: (values.PlanificacionDetalleTrabajador !== "undefined" && values.PlanificacionDetalleTrabajador !== undefined ? values.PlanificacionDetalleTrabajador.some(value => value === "Editar") : false),
          Eliminar: (values.PlanificacionDetalleTrabajador !== "undefined" && values.PlanificacionDetalleTrabajador !== undefined ? values.PlanificacionDetalleTrabajador.some(value => value === "Eliminar") : false),
        },

        AsistenciaCheckIn: {
          Ver: (values.AsistenciaCheckIn !== "undefined" && values.AsistenciaCheckIn !== undefined ? values.AsistenciaCheckIn.some(value => value === "Ver") : false),
          Editar: (values.AsistenciaCheckIn !== "undefined" && values.AsistenciaCheckIn !== undefined ? values.AsistenciaCheckIn.some(value => value === "Editar") : false),
          Eliminar: (values.AsistenciaCheckIn !== "undefined" && values.AsistenciaCheckIn !== undefined ? values.AsistenciaCheckIn.some(value => value === "Eliminar") : false),
        },

        AsistenciaManual: {
          Ver: (values.AsistenciaManual !== "undefined" && values.AsistenciaManual !== undefined ? values.AsistenciaManual.some(value => value === "Ver") : false),
          Editar: (values.AsistenciaManual !== "undefined" && values.AsistenciaManual !== undefined ? values.AsistenciaManual.some(value => value === "Editar") : false),
          Eliminar: (values.AsistenciaManual !== "undefined" && values.AsistenciaManual !== undefined ? values.AsistenciaManual.some(value => value === "Eliminar") : false),
        },

        AsistenciaQR: {
          Ver: (values.AsistenciaQR !== "undefined" && values.AsistenciaQR !== undefined ? values.AsistenciaQR.some(value => value === "Ver") : false),
          Editar: (values.AsistenciaQR !== "undefined" && values.AsistenciaQR !== undefined ? values.AsistenciaQR.some(value => value === "Editar") : false),
          Eliminar: (values.AsistenciaQR !== "undefined" && values.AsistenciaQR !== undefined ? values.AsistenciaQR.some(value => value === "Eliminar") : false),
        },

        AsistenciaCheckOut: {
          Ver: (values.AsistenciaCheckOut !== "undefined" && values.AsistenciaCheckOut !== undefined ? values.AsistenciaCheckOut.some(value => value === "Ver") : false),
          Editar: (values.AsistenciaCheckOut !== "undefined" && values.AsistenciaCheckOut !== undefined ? values.AsistenciaCheckOut.some(value => value === "Editar") : false),
          Eliminar: (values.AsistenciaCheckOut !== "undefined" && values.AsistenciaCheckOut !== undefined ? values.AsistenciaCheckOut.some(value => value === "Eliminar") : false),
        },

        ProcesosDescanso: {
          Ver: (values.ProcesosDescanso !== "undefined" && values.ProcesosDescanso !== undefined ? values.ProcesosDescanso.some(value => value === "Ver") : false),
          Editar: (values.ProcesosDescanso !== "undefined" && values.ProcesosDescanso !== undefined ? values.ProcesosDescanso.some(value => value === "Editar") : false),
          Eliminar: (values.ProcesosDescanso !== "undefined" && values.ProcesosDescanso !== undefined ? values.ProcesosDescanso.some(value => value === "Eliminar") : false),
        },

        ProcesosEnTransito: {
          Ver: (values.ProcesosEnTransito !== "undefined" && values.ProcesosEnTransito !== undefined ? values.ProcesosEnTransito.some(value => value === "Ver") : false),
          Editar: (values.ProcesosEnTransito !== "undefined" && values.ProcesosEnTransito !== undefined ? values.ProcesosEnTransito.some(value => value === "Editar") : false),
          Eliminar: (values.ProcesosEnTransito !== "undefined" && values.ProcesosEnTransito !== undefined ? values.ProcesosEnTransito.some(value => value === "Eliminar") : false),
        },

        ProcesosVacaciones: {
          Ver: (values.ProcesosVacaciones !== "undefined" && values.ProcesosVacaciones !== undefined ? values.ProcesosVacaciones.some(value => value === "Ver") : false),
          Editar: (values.ProcesosVacaciones !== "undefined" && values.ProcesosVacaciones !== undefined ? values.ProcesosVacaciones.some(value => value === "Editar") : false),
          Eliminar: (values.ProcesosVacaciones !== "undefined" && values.ProcesosVacaciones !== undefined ? values.ProcesosVacaciones.some(value => value === "Eliminar") : false),
        },

        ProcesosLicencia: {
          Ver: (values.ProcesosLicencia !== "undefined" && values.ProcesosLicencia !== undefined ? values.ProcesosLicencia.some(value => value === "Ver") : false),
          Editar: (values.ProcesosLicencia !== "undefined" && values.ProcesosLicencia !== undefined ? values.ProcesosLicencia.some(value => value === "Editar") : false),
          Eliminar: (values.ProcesosLicencia !== "undefined" && values.ProcesosLicencia !== undefined ? values.ProcesosLicencia.some(value => value === "Eliminar") : false),
        },

        ProcesosMutual: {
          Ver: (values.ProcesosMutual !== "undefined" && values.ProcesosMutual !== undefined ? values.ProcesosMutual.some(value => value === "Ver") : false),
          Editar: (values.ProcesosMutual !== "undefined" && values.ProcesosMutual !== undefined ? values.ProcesosMutual.some(value => value === "Editar") : false),
          Eliminar: (values.ProcesosMutual !== "undefined" && values.ProcesosMutual !== undefined ? values.ProcesosMutual.some(value => value === "Eliminar") : false),
        },

        ProcesosPermisos: {
          Ver: (values.ProcesosPermisos !== "undefined" && values.ProcesosPermisos !== undefined ? values.ProcesosPermisos.some(value => value === "Ver") : false),
          Editar: (values.ProcesosPermisos !== "undefined" && values.ProcesosPermisos !== undefined ? values.ProcesosPermisos.some(value => value === "Editar") : false),
          Eliminar: (values.ProcesosPermisos !== "undefined" && values.ProcesosPermisos !== undefined ? values.ProcesosPermisos.some(value => value === "Eliminar") : false),
        },

        ProcesosAusente: {
          Ver: (values.ProcesosAusente !== "undefined" && values.ProcesosAusente !== undefined ? values.ProcesosAusente.some(value => value === "Ver") : false),
          Editar: (values.ProcesosAusente !== "undefined" && values.ProcesosAusente !== undefined ? values.ProcesosAusente.some(value => value === "Editar") : false),
          Eliminar: (values.ProcesosAusente !== "undefined" && values.ProcesosAusente !== undefined ? values.ProcesosAusente.some(value => value === "Eliminar") : false),
        },

        ReporteriaDesviaciones: {
          Ver: (values.ReporteriaDesviaciones !== "undefined" && values.ReporteriaDesviaciones !== undefined ? values.ReporteriaDesviaciones.some(value => value === "Ver") : false),
          Editar: (values.ReporteriaDesviaciones !== "undefined" && values.ReporteriaDesviaciones !== undefined ? values.ReporteriaDesviaciones.some(value => value === "Editar") : false),
          Eliminar: (values.ReporteriaDesviaciones !== "undefined" && values.ReporteriaDesviaciones !== undefined ? values.ReporteriaDesviaciones.some(value => value === "Eliminar") : false),
        },

        ReporteriaDelDia: {
          Ver: (values.ReporteriaDelDia !== "undefined" && values.ReporteriaDelDia !== undefined ? values.ReporteriaDelDia.some(value => value === "Ver") : false),
          Editar: (values.ReporteriaDelDia !== "undefined" && values.ReporteriaDelDia !== undefined ? values.ReporteriaDelDia.some(value => value === "Editar") : false),
          Eliminar: (values.ReporteriaDelDia !== "undefined" && values.ReporteriaDelDia !== undefined ? values.ReporteriaDelDia.some(value => value === "Eliminar") : false),
        },

        ReporteriaNecesidades: {
          Ver: (values.ReporteriaNecesidades !== "undefined" && values.ReporteriaNecesidades !== undefined ? values.ReporteriaNecesidades.some(value => value === "Ver") : false),
          Editar: (values.ReporteriaNecesidades !== "undefined" && values.ReporteriaNecesidades !== undefined ? values.ReporteriaNecesidades.some(value => value === "Editar") : false),
          Eliminar: (values.ReporteriaNecesidades !== "undefined" && values.ReporteriaNecesidades !== undefined ? values.ReporteriaNecesidades.some(value => value === "Eliminar") : false),
        },

        ReporteriaUtilizacionRelevos: {
          Ver: (values.ReporteriaUtilizacionRelevos !== "undefined" && values.ReporteriaUtilizacionRelevos !== undefined ? values.ReporteriaUtilizacionRelevos.some(value => value === "Ver") : false),
          Editar: (values.ReporteriaUtilizacionRelevos !== "undefined" && values.ReporteriaUtilizacionRelevos !== undefined ? values.ReporteriaUtilizacionRelevos.some(value => value === "Editar") : false),
          Eliminar: (values.ReporteriaUtilizacionRelevos !== "undefined" && values.ReporteriaUtilizacionRelevos !== undefined ? values.ReporteriaUtilizacionRelevos.some(value => value === "Eliminar") : false),
        },

        ReporteriaAsistenciaPersonas: {
          Ver: (values.ReporteriaAsistenciaPersonas !== "undefined" && values.ReporteriaAsistenciaPersonas !== undefined ? values.ReporteriaAsistenciaPersonas.some(value => value === "Ver") : false),
          Editar: (values.ReporteriaAsistenciaPersonas !== "undefined" && values.ReporteriaAsistenciaPersonas !== undefined ? values.ReporteriaAsistenciaPersonas.some(value => value === "Editar") : false),
          Eliminar: (values.ReporteriaAsistenciaPersonas !== "undefined" && values.ReporteriaAsistenciaPersonas !== undefined ? values.ReporteriaAsistenciaPersonas.some(value => value === "Eliminar") : false),
        },

        ReporteriaAsistenciaCC: {
          Ver: (values.ReporteriaAsistenciaCC !== "undefined" && values.ReporteriaAsistenciaCC !== undefined ? values.ReporteriaAsistenciaCC.some(value => value === "Ver") : false),
          Editar: (values.ReporteriaAsistenciaCC !== "undefined" && values.ReporteriaAsistenciaCC !== undefined ? values.ReporteriaAsistenciaCC.some(value => value === "Editar") : false),
          Eliminar: (values.ReporteriaAsistenciaCC !== "undefined" && values.ReporteriaAsistenciaCC !== undefined ? values.ReporteriaAsistenciaCC.some(value => value === "Eliminar") : false),
        },

        ReporteriaAuditoria: {
          Ver: (values.ReporteriaAuditoria !== "undefined" && values.ReporteriaAuditoria !== undefined ? values.ReporteriaAuditoria.some(value => value === "Ver") : false),
          Editar: (values.ReporteriaAuditoria !== "undefined" && values.ReporteriaAuditoria !== undefined ? values.ReporteriaAuditoria.some(value => value === "Editar") : false),
          Eliminar: (values.ReporteriaAuditoria !== "undefined" && values.ReporteriaAuditoria !== undefined ? values.ReporteriaAuditoria.some(value => value === "Eliminar") : false),
        },

        ReporteriaCierreDeMes: {
          Ver: (values.ReporteriaCierreDeMes !== "undefined" && values.ReporteriaCierreDeMes !== undefined ? values.ReporteriaCierreDeMes.some(value => value === "Ver") : false),
          Editar: (values.ReporteriaCierreDeMes !== "undefined" && values.ReporteriaCierreDeMes !== undefined ? values.ReporteriaCierreDeMes.some(value => value === "Editar") : false),
          Eliminar: (values.ReporteriaCierreDeMes !== "undefined" && values.ReporteriaCierreDeMes !== undefined ? values.ReporteriaCierreDeMes.some(value => value === "Eliminar") : false),
        },

        ReporteriaCosteo: {
          Ver: (values.ReporteriaCosteo !== "undefined" && values.ReporteriaCosteo !== undefined ? values.ReporteriaCosteo.some(value => value === "Ver") : false),
          Editar: (values.ReporteriaCosteo !== "undefined" && values.ReporteriaCosteo !== undefined ? values.ReporteriaCosteo.some(value => value === "Editar") : false),
          Eliminar: (values.ReporteriaCosteo !== "undefined" && values.ReporteriaCosteo !== undefined ? values.ReporteriaCosteo.some(value => value === "Eliminar") : false),
        },

        MantenedorUsuarios: {
          Ver: (values.MantenedorUsuarios !== "undefined" && values.MantenedorUsuarios !== undefined ? values.MantenedorUsuarios.some(value => value === "Ver") : false),
          Editar: (values.MantenedorUsuarios !== "undefined" && values.MantenedorUsuarios !== undefined ? values.MantenedorUsuarios.some(value => value === "Editar") : false),
          Eliminar: (values.MantenedorUsuarios !== "undefined" && values.MantenedorUsuarios !== undefined ? values.MantenedorUsuarios.some(value => value === "Eliminar") : false),
        },

        MantenedorPerfilesUsuario: {
          Ver: (values.MantenedorPerfilesUsuario !== "undefined" && values.MantenedorPerfilesUsuario !== undefined ? values.MantenedorPerfilesUsuario.some(value => value === "Ver") : false),
          Editar: (values.MantenedorPerfilesUsuario !== "undefined" && values.MantenedorPerfilesUsuario !== undefined ? values.MantenedorPerfilesUsuario.some(value => value === "Editar") : false),
          Eliminar: (values.MantenedorPerfilesUsuario !== "undefined" && values.MantenedorPerfilesUsuario !== undefined ? values.MantenedorPerfilesUsuario.some(value => value === "Eliminar") : false),
        },

        MantenedorAccesosSistema: {
          Ver: (values.MantenedorAccesosSistema !== "undefined" && values.MantenedorAccesosSistema !== undefined ? values.MantenedorAccesosSistema.some(value => value === "Ver") : false),
          Editar: (values.MantenedorAccesosSistema !== "undefined" && values.MantenedorAccesosSistema !== undefined ? values.MantenedorAccesosSistema.some(value => value === "Editar") : false),
          Eliminar: (values.MantenedorAccesosSistema !== "undefined" && values.MantenedorAccesosSistema !== undefined ? values.MantenedorAccesosSistema.some(value => value === "Eliminar") : false),
        },

        MantenedorSucursales: {
          Ver: (values.MantenedorSucursales !== "undefined" && values.MantenedorSucursales !== undefined ? values.MantenedorSucursales.some(value => value === "Ver") : false),
          Editar: (values.MantenedorSucursales !== "undefined" && values.MantenedorSucursales !== undefined ? values.MantenedorSucursales.some(value => value === "Editar") : false),
          Eliminar: (values.MantenedorSucursales !== "undefined" && values.MantenedorSucursales !== undefined ? values.MantenedorSucursales.some(value => value === "Eliminar") : false),
        },

        MantenedorCentroDeCostos: {
          Ver: (values.MantenedorCentroDeCostos !== "undefined" && values.MantenedorCentroDeCostos !== undefined ? values.MantenedorCentroDeCostos.some(value => value === "Ver") : false),
          Editar: (values.MantenedorCentroDeCostos !== "undefined" && values.MantenedorCentroDeCostos !== undefined ? values.MantenedorCentroDeCostos.some(value => value === "Editar") : false),
          Eliminar: (values.MantenedorCentroDeCostos !== "undefined" && values.MantenedorCentroDeCostos !== undefined ? values.MantenedorCentroDeCostos.some(value => value === "Eliminar") : false),
        },

        MantenedorRegimenDeTrabajo: {
          Ver: (values.MantenedorRegimenDeTrabajo !== "undefined" && values.MantenedorRegimenDeTrabajo !== undefined ? values.MantenedorRegimenDeTrabajo.some(value => value === "Ver") : false),
          Editar: (values.MantenedorRegimenDeTrabajo !== "undefined" && values.MantenedorRegimenDeTrabajo !== undefined ? values.MantenedorRegimenDeTrabajo.some(value => value === "Editar") : false),
          Eliminar: (values.MantenedorRegimenDeTrabajo !== "undefined" && values.MantenedorRegimenDeTrabajo !== undefined ? values.MantenedorRegimenDeTrabajo.some(value => value === "Eliminar") : false),
        },

        MantenedorFeriados: {
          Ver: (values.MantenedorFeriados !== "undefined" && values.MantenedorFeriados !== undefined ? values.MantenedorFeriados.some(value => value === "Ver") : false),
          Editar: (values.MantenedorFeriados !== "undefined" && values.MantenedorFeriados !== undefined ? values.MantenedorFeriados.some(value => value === "Editar") : false),
          Eliminar: (values.MantenedorFeriados !== "undefined" && values.MantenedorFeriados !== undefined ? values.MantenedorFeriados.some(value => value === "Eliminar") : false),
        },

        MantenedorTitulos: {
          Ver: (values.MantenedorTitulos !== "undefined" && values.MantenedorTitulos !== undefined ? values.MantenedorTitulos.some(value => value === "Ver") : false),
          Editar: (values.MantenedorTitulos !== "undefined" && values.MantenedorTitulos !== undefined ? values.MantenedorTitulos.some(value => value === "Editar") : false),
          Eliminar: (values.MantenedorTitulos !== "undefined" && values.MantenedorTitulos !== undefined ? values.MantenedorTitulos.some(value => value === "Eliminar") : false),
        },

        MantenedorEspecialidades: {
          Ver: (values.MantenedorEspecialidades !== "undefined" && values.MantenedorEspecialidades !== undefined ? values.MantenedorEspecialidades.some(value => value === "Ver") : false),
          Editar: (values.MantenedorEspecialidades !== "undefined" && values.MantenedorEspecialidades !== undefined ? values.MantenedorEspecialidades.some(value => value === "Editar") : false),
          Eliminar: (values.MantenedorEspecialidades !== "undefined" && values.MantenedorEspecialidades !== undefined ? values.MantenedorEspecialidades.some(value => value === "Eliminar") : false),
        },

        MantenedorCargos: {
          Ver: (values.MantenedorCargos !== "undefined" && values.MantenedorCargos !== undefined ? values.MantenedorCargos.some(value => value === "Ver") : false),
          Editar: (values.MantenedorCargos !== "undefined" && values.MantenedorCargos !== undefined ? values.MantenedorCargos.some(value => value === "Editar") : false),
          Eliminar: (values.MantenedorCargos !== "undefined" && values.MantenedorCargos !== undefined ? values.MantenedorCargos.some(value => value === "Eliminar") : false),
        },

        MantenedorEpp: {
          Ver: (values.MantenedorEpp !== "undefined" && values.MantenedorEpp !== undefined ? values.MantenedorEpp.some(value => value === "Ver") : false),
          Editar: (values.MantenedorEpp !== "undefined" && values.MantenedorEpp !== undefined ? values.MantenedorEpp.some(value => value === "Editar") : false),
          Eliminar: (values.MantenedorEpp !== "undefined" && values.MantenedorEpp !== undefined ? values.MantenedorEpp.some(value => value === "Eliminar") : false),
        },

        MantenedorDocumentos: {
          Ver: (values.MantenedorDocumentos !== "undefined" && values.MantenedorDocumentos !== undefined ? values.MantenedorDocumentos.some(value => value === "Ver") : false),
          Editar: (values.MantenedorDocumentos !== "undefined" && values.MantenedorDocumentos !== undefined ? values.MantenedorDocumentos.some(value => value === "Editar") : false),
          Eliminar: (values.MantenedorDocumentos !== "undefined" && values.MantenedorDocumentos !== undefined ? values.MantenedorDocumentos.some(value => value === "Eliminar") : false),
        },

      }

      props.editarPerfilPermiso(permisos);
    }
  };

  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  useEffect(() => {

    // Los que tengan variable "Permiso..." son permisos a los cuales se les cambiaron los nombres

    let { Nombre } = props.Formulario;

    let Dashboard = [];
    if (props.Formulario.Dashboard != undefined) {
      if (props.Formulario.Dashboard.Ver === true) {
        Dashboard.push("Ver");
      }
      if (props.Formulario.Dashboard.Editar === true) {
        Dashboard.push("Editar");
      }
      if (props.Formulario.Dashboard.Eliminar === true) {
        Dashboard.push("Eliminar");
      }
    }

    let MantenedorTrabajadores = [];
    let PermisosMantenedorTrabajadores;

    if (props.Formulario.MantenedorTrabajadores != undefined) {
      PermisosMantenedorTrabajadores = props.Formulario.MantenedorTrabajadores;

    } else if (props.Formulario.MantenedorFichaTrabajador != undefined) {
      PermisosMantenedorTrabajadores = props.Formulario.MantenedorFichaTrabajador;
    }

    if (PermisosMantenedorTrabajadores != undefined) {
      if (PermisosMantenedorTrabajadores.Ver === true) {
        MantenedorTrabajadores.push("Ver");
      }
      if (PermisosMantenedorTrabajadores.Editar === true) {
        MantenedorTrabajadores.push("Editar");
      }
      if (PermisosMantenedorTrabajadores.Eliminar === true) {
        MantenedorTrabajadores.push("Eliminar");
      }
    }

    let Contratos = [];
    if (props.Formulario.Contratos != undefined) {
      if (props.Formulario.Contratos.Ver === true) {
        Contratos.push("Ver");
      }
      if (props.Formulario.Contratos.Editar === true) {
        Contratos.push("Editar");
      }
      if (props.Formulario.Contratos.Eliminar === true) {
        Contratos.push("Eliminar");
      }
    }

    let PlanificacionEquiposDeTrabajo = [];
    let PermisosPlanificacionEquiposDeTrabajo;

    if (props.Formulario.PlanificacionEquiposDeTrabajo != undefined) {
      PermisosPlanificacionEquiposDeTrabajo = props.Formulario.PlanificacionEquiposDeTrabajo;
    } else if (props.Formulario.AsignacionesPlantilla != undefined) {
      PermisosPlanificacionEquiposDeTrabajo = props.Formulario.AsignacionesPlantilla;
    }

    if (PermisosPlanificacionEquiposDeTrabajo != undefined) {
      if (PermisosPlanificacionEquiposDeTrabajo.Ver === true) {
        PlanificacionEquiposDeTrabajo.push("Ver");
      }
      if (PermisosPlanificacionEquiposDeTrabajo.Editar === true) {
        PlanificacionEquiposDeTrabajo.push("Editar");
      }
      if (PermisosPlanificacionEquiposDeTrabajo.Eliminar === true) {
        PlanificacionEquiposDeTrabajo.push("Eliminar");
      }
    }

    let PlanificacionActividades = [];
    let PermisosPlanificacionActividades;

    if (props.Formulario.PlanificacionActividades != undefined) {
      PermisosPlanificacionActividades = props.Formulario.PlanificacionActividades;
    } else if (props.Formulario.AsignacionesCentroDeCosto != undefined) {
      PermisosPlanificacionActividades = props.Formulario.AsignacionesCentroDeCosto;
    }

    if (PermisosPlanificacionActividades != undefined) {
      if (PermisosPlanificacionActividades.Ver === true) {
        PlanificacionActividades.push("Ver");
      }
      if (PermisosPlanificacionActividades.Editar === true) {
        PlanificacionActividades.push("Editar");
      }
      if (PermisosPlanificacionActividades.Eliminar === true) {
        PlanificacionActividades.push("Eliminar");
      }
    }

    let PlanificacionDetalleActividad = [];
    let PermisosPlanificacionDetalleActividad;

    if (props.Formulario.PlanificacionDetalleActividad != undefined) {
      PermisosPlanificacionDetalleActividad = props.Formulario.PlanificacionDetalleActividad;
    } else if (props.Formulario.AsignacionesDetalle != undefined) {
      PermisosPlanificacionDetalleActividad = props.Formulario.AsignacionesDetalle;
    }

    if (PermisosPlanificacionDetalleActividad != undefined) {
      if (PermisosPlanificacionDetalleActividad.Ver === true) {
        PlanificacionDetalleActividad.push("Ver");
      }
      if (PermisosPlanificacionDetalleActividad.Editar === true) {
        PlanificacionDetalleActividad.push("Editar");
      }
      if (PermisosPlanificacionDetalleActividad.Eliminar === true) {
        PlanificacionDetalleActividad.push("Eliminar");
      }
    }

    let PlanificacionDetalleTrabajador = [];
    let PermisosPlanificacionDetalleTrabajador;

    if (props.Formulario.PlanificacionDetalleTrabajador != undefined) {
      PermisosPlanificacionDetalleTrabajador = props.Formulario.PlanificacionDetalleTrabajador;
    } else if (props.Formulario.DetalleTrabajador != undefined) {
      PermisosPlanificacionDetalleTrabajador = props.Formulario.DetalleTrabajador;
    }

    if (PermisosPlanificacionDetalleTrabajador != undefined) {
      if (PermisosPlanificacionDetalleTrabajador.Ver === true) {
        PlanificacionDetalleTrabajador.push("Ver");
      }
      if (PermisosPlanificacionDetalleTrabajador.Editar === true) {
        PlanificacionDetalleTrabajador.push("Editar");
      }
      if (PermisosPlanificacionDetalleTrabajador.Eliminar === true) {
        PlanificacionDetalleTrabajador.push("Eliminar");
      }
    }

    let AsistenciaCheckIn = [];
    let PermisosAsistenciaCheckIn;

    if (props.Formulario.AsistenciaCheckIn != undefined) {
      PermisosAsistenciaCheckIn = props.Formulario.AsistenciaCheckIn;
    } else if (props.Formulario.ProcesosCheck != undefined) {
      PermisosAsistenciaCheckIn = props.Formulario.ProcesosCheck;
    }

    if (PermisosAsistenciaCheckIn != undefined) {
      if (PermisosAsistenciaCheckIn.Ver === true) {
        AsistenciaCheckIn.push("Ver");
      }
      if (PermisosAsistenciaCheckIn.Editar === true) {
        AsistenciaCheckIn.push("Editar");
      }
      if (PermisosAsistenciaCheckIn.Eliminar === true) {
        AsistenciaCheckIn.push("Eliminar");
      }
    }

    let AsistenciaManual = [];
    let PermisosAsistenciaManual;

    if (props.Formulario.AsistenciaManual != undefined) {
      PermisosAsistenciaManual = props.Formulario.AsistenciaManual;
    } else if (props.Formulario.Asistencia != undefined) {
      PermisosAsistenciaManual = props.Formulario.Asistencia;
    }

    if (PermisosAsistenciaManual != undefined) {
      if (PermisosAsistenciaManual.Ver === true) {
        AsistenciaManual.push("Ver");
      }
      if (PermisosAsistenciaManual.Editar === true) {
        AsistenciaManual.push("Editar");
      }
      if (PermisosAsistenciaManual.Eliminar === true) {
        AsistenciaManual.push("Eliminar");
      }
    }

    let AsistenciaQR = [];
    let PermisosAsistenciaQR;

    if (props.Formulario.AsistenciaQR != undefined) {
      PermisosAsistenciaQR = props.Formulario.AsistenciaQR;
    } else if (props.Formulario.ValidacionQR != undefined) {
      PermisosAsistenciaQR = props.Formulario.ValidacionQR;
    }

    if (PermisosAsistenciaQR != undefined) {
      if (PermisosAsistenciaQR.Ver === true) {
        AsistenciaQR.push("Ver");
      }
      if (PermisosAsistenciaQR.Editar === true) {
        AsistenciaQR.push("Editar");
      }
      if (PermisosAsistenciaQR.Eliminar === true) {
        AsistenciaQR.push("Eliminar");
      }
    }

    let AsistenciaCheckOut = [];
    let PermisosAsistenciaCheckOut;

    if (props.Formulario.AsistenciaCheckOut != undefined) {
      PermisosAsistenciaCheckOut = props.Formulario.AsistenciaCheckOut;
    } else if (props.Formulario.ProcesosCheck != undefined) {
      PermisosAsistenciaCheckOut = props.Formulario.ProcesosCheck;
    }

    if (PermisosAsistenciaCheckOut != undefined) {
      if (PermisosAsistenciaCheckOut.Ver === true) {
        AsistenciaCheckOut.push("Ver");
      }
      if (PermisosAsistenciaCheckOut.Editar === true) {
        AsistenciaCheckOut.push("Editar");
      }
      if (PermisosAsistenciaCheckOut.Eliminar === true) {
        AsistenciaCheckOut.push("Eliminar");
      }
    }

    let ProcesosDescanso = [];
    if (props.Formulario.ProcesosDescanso != undefined) {
      if (props.Formulario.ProcesosDescanso.Ver === true) {
        ProcesosDescanso.push("Ver");
      }
      if (props.Formulario.ProcesosDescanso.Editar === true) {
        ProcesosDescanso.push("Editar");
      }
      if (props.Formulario.ProcesosDescanso.Eliminar === true) {
        ProcesosDescanso.push("Eliminar");
      }
    }

    let ProcesosEnTransito = [];
    if (props.Formulario.ProcesosEnTransito != undefined) {
      if (props.Formulario.ProcesosEnTransito.Ver === true) {
        ProcesosEnTransito.push("Ver");
      }
      if (props.Formulario.ProcesosEnTransito.Editar === true) {
        ProcesosEnTransito.push("Editar");
      }
      if (props.Formulario.ProcesosEnTransito.Eliminar === true) {
        ProcesosEnTransito.push("Eliminar");
      }
    }

    let ProcesosVacaciones = [];
    if (props.Formulario.ProcesosVacaciones != undefined) {
      if (props.Formulario.ProcesosVacaciones.Ver === true) {
        ProcesosVacaciones.push("Ver");
      }
      if (props.Formulario.ProcesosVacaciones.Editar === true) {
        ProcesosVacaciones.push("Editar");
      }
      if (props.Formulario.ProcesosVacaciones.Eliminar === true) {
        ProcesosVacaciones.push("Eliminar");
      }
    }

    let ProcesosLicencia = [];
    if (props.Formulario.ProcesosLicencia != undefined) {
      if (props.Formulario.ProcesosLicencia.Ver === true) {
        ProcesosLicencia.push("Ver");
      }
      if (props.Formulario.ProcesosLicencia.Editar === true) {
        ProcesosLicencia.push("Editar");
      }
      if (props.Formulario.ProcesosLicencia.Eliminar === true) {
        ProcesosLicencia.push("Eliminar");
      }
    }

    let ProcesosMutual = [];
    if (props.Formulario.ProcesosMutual != undefined) {
      if (props.Formulario.ProcesosMutual.Ver === true) {
        ProcesosMutual.push("Ver");
      }
      if (props.Formulario.ProcesosMutual.Editar === true) {
        ProcesosMutual.push("Editar");
      }
      if (props.Formulario.ProcesosMutual.Eliminar === true) {
        ProcesosMutual.push("Eliminar");
      }
    }

    let ProcesosPermisos = [];
    if (props.Formulario.ProcesosPermisos != undefined) {
      if (props.Formulario.ProcesosPermisos.Ver === true) {
        ProcesosPermisos.push("Ver");
      }
      if (props.Formulario.ProcesosPermisos.Editar === true) {
        ProcesosPermisos.push("Editar");
      }
      if (props.Formulario.ProcesosPermisos.Eliminar === true) {
        ProcesosPermisos.push("Eliminar");
      }
    }

    let ProcesosAusente = [];
    if (props.Formulario.ProcesosAusente != undefined) {
      if (props.Formulario.ProcesosAusente.Ver === true) {
        ProcesosAusente.push("Ver");
      }
      if (props.Formulario.ProcesosAusente.Editar === true) {
        ProcesosAusente.push("Editar");
      }
      if (props.Formulario.ProcesosAusente.Eliminar === true) {
        ProcesosAusente.push("Eliminar");
      }
    }

    let ReporteriaDesviaciones = [];
    if (props.Formulario.ReporteriaDesviaciones != undefined) {
      if (props.Formulario.ReporteriaDesviaciones.Ver === true) {
        ReporteriaDesviaciones.push("Ver");
      }
      if (props.Formulario.ReporteriaDesviaciones.Editar === true) {
        ReporteriaDesviaciones.push("Editar");
      }
      if (props.Formulario.ReporteriaDesviaciones.Eliminar === true) {
        ReporteriaDesviaciones.push("Eliminar");
      }
    }

    let ReporteriaDelDia = [];
    if (props.Formulario.ReporteriaDelDia != undefined) {
      if (props.Formulario.ReporteriaDelDia.Ver === true) {
        ReporteriaDelDia.push("Ver");
      }
      if (props.Formulario.ReporteriaDelDia.Editar === true) {
        ReporteriaDelDia.push("Editar");
      }
      if (props.Formulario.ReporteriaDelDia.Eliminar === true) {
        ReporteriaDelDia.push("Eliminar");
      }
    }

    let ReporteriaNecesidades = [];
    if (props.Formulario.ReporteriaNecesidades != undefined) {
      if (props.Formulario.ReporteriaNecesidades.Ver === true) {
        ReporteriaNecesidades.push("Ver");
      }
      if (props.Formulario.ReporteriaNecesidades.Editar === true) {
        ReporteriaNecesidades.push("Editar");
      }
      if (props.Formulario.ReporteriaNecesidades.Eliminar === true) {
        ReporteriaNecesidades.push("Eliminar");
      }
    }

    let ReporteriaUtilizacionRelevos = [];
    if (props.Formulario.ReporteriaUtilizacionRelevos != undefined) {
      if (props.Formulario.ReporteriaUtilizacionRelevos.Ver === true) {
        ReporteriaUtilizacionRelevos.push("Ver");
      }
      if (props.Formulario.ReporteriaUtilizacionRelevos.Editar === true) {
        ReporteriaUtilizacionRelevos.push("Editar");
      }
      if (props.Formulario.ReporteriaUtilizacionRelevos.Eliminar === true) {
        ReporteriaUtilizacionRelevos.push("Eliminar");
      }
    }

    let ReporteriaAsistenciaPersonas = [];
    if (props.Formulario.ReporteriaAsistenciaPersonas != undefined) {
      if (props.Formulario.ReporteriaAsistenciaPersonas.Ver === true) {
        ReporteriaAsistenciaPersonas.push("Ver");
      }
      if (props.Formulario.ReporteriaAsistenciaPersonas.Editar === true) {
        ReporteriaAsistenciaPersonas.push("Editar");
      }
      if (props.Formulario.ReporteriaAsistenciaPersonas.Eliminar === true) {
        ReporteriaAsistenciaPersonas.push("Eliminar");
      }
    }

    let ReporteriaAsistenciaCC = [];
    if (props.Formulario.ReporteriaAsistenciaCC != undefined) {
      if (props.Formulario.ReporteriaAsistenciaCC.Ver === true) {
        ReporteriaAsistenciaCC.push("Ver");
      }
      if (props.Formulario.ReporteriaAsistenciaCC.Editar === true) {
        ReporteriaAsistenciaCC.push("Editar");
      }
      if (props.Formulario.ReporteriaAsistenciaCC.Eliminar === true) {
        ReporteriaAsistenciaCC.push("Eliminar");
      }
    }

    let ReporteriaAuditoria = [];
    let PermisosReporteriaAuditoria;

    if (props.Formulario.ReporteriaAuditoria != undefined) {
      PermisosReporteriaAuditoria = props.Formulario.ReporteriaAuditoria;
    } else if (props.Formulario.Auditoria != undefined) {
      PermisosReporteriaAuditoria = props.Formulario.Auditoria;
    }

    if (PermisosReporteriaAuditoria != undefined) {
      if (PermisosReporteriaAuditoria.Ver === true) {
        ReporteriaAuditoria.push("Ver");
      }
      if (PermisosReporteriaAuditoria.Editar === true) {
        ReporteriaAuditoria.push("Editar");
      }
      if (PermisosReporteriaAuditoria.Eliminar === true) {
        ReporteriaAuditoria.push("Eliminar");
      }
    }

    let ReporteriaCierreDeMes = [];
    let PermisosReporteriaCierreDeMes;

    if (props.Formulario.ReporteriaCierreDeMes != undefined) {
      PermisosReporteriaCierreDeMes = props.Formulario.ReporteriaCierreDeMes;
    } else if (props.Formulario.CierreDeMes != undefined) {
      PermisosReporteriaCierreDeMes = props.Formulario.CierreDeMes;
    }

    if (PermisosReporteriaCierreDeMes != undefined) {
      if (PermisosReporteriaCierreDeMes.Ver === true) {
        ReporteriaCierreDeMes.push("Ver");
      }
      if (PermisosReporteriaCierreDeMes.Editar === true) {
        ReporteriaCierreDeMes.push("Editar");
      }
      if (PermisosReporteriaCierreDeMes.Eliminar === true) {
        ReporteriaCierreDeMes.push("Eliminar");
      }
    }

    let ReporteriaCosteo = [];
    let PermisosReporteriaCosteo;

    if (props.Formulario.ReporteriaCosteo != undefined) {
      PermisosReporteriaCosteo = props.Formulario.ReporteriaCosteo;
    } else if (props.Formulario.Costeo != undefined) {
      PermisosReporteriaCosteo = props.Formulario.Costeo;
    }

    if (PermisosReporteriaCosteo != undefined) {
      if (PermisosReporteriaCosteo.Ver === true) {
        ReporteriaCosteo.push("Ver");
      }
      if (PermisosReporteriaCosteo.Editar === true) {
        ReporteriaCosteo.push("Editar");
      }
      if (PermisosReporteriaCosteo.Eliminar === true) {
        ReporteriaCosteo.push("Eliminar");
      }
    }

    let MantenedorUsuarios = [];
    if (props.Formulario.MantenedorUsuarios != undefined) {
      if (props.Formulario.MantenedorUsuarios.Ver === true) {
        MantenedorUsuarios.push("Ver");
      }
      if (props.Formulario.MantenedorUsuarios.Editar === true) {
        MantenedorUsuarios.push("Editar");
      }
      if (props.Formulario.MantenedorUsuarios.Eliminar === true) {
        MantenedorUsuarios.push("Eliminar");
      }
    }

    let MantenedorPerfilesUsuario = [];
    let PermisosMantenedorPerfilesUsuario;

    if (props.Formulario.MantenedorPerfilesUsuario != undefined) {
      PermisosMantenedorPerfilesUsuario = props.Formulario.MantenedorPerfilesUsuario;
    } else if (props.Formulario.MantenedorPerfilesPermiso != undefined) {
      PermisosMantenedorPerfilesUsuario = props.Formulario.MantenedorPerfilesPermiso;
    }

    if (PermisosMantenedorPerfilesUsuario != undefined) {
      if (PermisosMantenedorPerfilesUsuario.Ver === true) {
        MantenedorPerfilesUsuario.push("Ver");
      }
      if (PermisosMantenedorPerfilesUsuario.Editar === true) {
        MantenedorPerfilesUsuario.push("Editar");
      }
      if (PermisosMantenedorPerfilesUsuario.Eliminar === true) {
        MantenedorPerfilesUsuario.push("Eliminar");
      }
    }

    let MantenedorAccesosSistema = [];
    let PermisosMantenedorAccesosSistema;

    if (props.Formulario.MantenedorAccesosSistema != undefined) {
      PermisosMantenedorAccesosSistema = props.Formulario.MantenedorAccesosSistema;
    } else if (props.Formulario.MantenedorAccesosPermiso != undefined) {
      PermisosMantenedorAccesosSistema = props.Formulario.MantenedorAccesosPermiso;
    }

    if (PermisosMantenedorAccesosSistema != undefined) {
      if (PermisosMantenedorAccesosSistema.Ver === true) {
        MantenedorAccesosSistema.push("Ver");
      }
      if (PermisosMantenedorAccesosSistema.Editar === true) {
        MantenedorAccesosSistema.push("Editar");
      }
      if (PermisosMantenedorAccesosSistema.Eliminar === true) {
        MantenedorAccesosSistema.push("Eliminar");
      }
    }

    let MantenedorSucursales = [];
    if (props.Formulario.MantenedorSucursales != undefined) {
      if (props.Formulario.MantenedorSucursales.Ver === true) {
        MantenedorSucursales.push("Ver");
      }
      if (props.Formulario.MantenedorSucursales.Editar === true) {
        MantenedorSucursales.push("Editar");
      }
      if (props.Formulario.MantenedorSucursales.Eliminar === true) {
        MantenedorSucursales.push("Eliminar");
      }
    }

    let MantenedorCentroDeCostos = [];
    if (props.Formulario.MantenedorCentroDeCostos != undefined) {
      if (props.Formulario.MantenedorCentroDeCostos.Ver === true) {
        MantenedorCentroDeCostos.push("Ver");
      }
      if (props.Formulario.MantenedorCentroDeCostos.Editar === true) {
        MantenedorCentroDeCostos.push("Editar");
      }
      if (props.Formulario.MantenedorCentroDeCostos.Eliminar === true) {
        MantenedorCentroDeCostos.push("Eliminar");
      }
    }

    let MantenedorRegimenDeTrabajo = [];
    if (props.Formulario.MantenedorRegimenDeTrabajo != undefined) {
      if (props.Formulario.MantenedorRegimenDeTrabajo.Ver === true) {
        MantenedorRegimenDeTrabajo.push("Ver");
      }
      if (props.Formulario.MantenedorRegimenDeTrabajo.Editar === true) {
        MantenedorRegimenDeTrabajo.push("Editar");
      }
      if (props.Formulario.MantenedorRegimenDeTrabajo.Eliminar === true) {
        MantenedorRegimenDeTrabajo.push("Eliminar");
      }
    }

    let MantenedorFeriados = [];
    if (props.Formulario.MantenedorFeriados != undefined) {
      if (props.Formulario.MantenedorFeriados.Ver === true) {
        MantenedorFeriados.push("Ver");
      }
      if (props.Formulario.MantenedorFeriados.Editar === true) {
        MantenedorFeriados.push("Editar");
      }
      if (props.Formulario.MantenedorFeriados.Eliminar === true) {
        MantenedorFeriados.push("Eliminar");
      }
    }

    let MantenedorTitulos = [];
    if (props.Formulario.MantenedorTitulos != undefined) {
      if (props.Formulario.MantenedorTitulos.Ver === true) {
        MantenedorTitulos.push("Ver");
      }
      if (props.Formulario.MantenedorTitulos.Editar === true) {
        MantenedorTitulos.push("Editar");
      }
      if (props.Formulario.MantenedorTitulos.Eliminar === true) {
        MantenedorTitulos.push("Eliminar");
      }
    }

    let MantenedorEspecialidades = [];
    if (props.Formulario.MantenedorEspecialidades != undefined) {
      if (props.Formulario.MantenedorEspecialidades.Ver === true) {
        MantenedorEspecialidades.push("Ver");
      }
      if (props.Formulario.MantenedorEspecialidades.Editar === true) {
        MantenedorEspecialidades.push("Editar");
      }
      if (props.Formulario.MantenedorEspecialidades.Eliminar === true) {
        MantenedorEspecialidades.push("Eliminar");
      }
    }

    let MantenedorCargos = [];
    if (props.Formulario.MantenedorCargos != undefined) {
      if (props.Formulario.MantenedorCargos.Ver === true) {
        MantenedorCargos.push("Ver");
      }
      if (props.Formulario.MantenedorCargos.Editar === true) {
        MantenedorCargos.push("Editar");
      }
      if (props.Formulario.MantenedorCargos.Eliminar === true) {
        MantenedorCargos.push("Eliminar");
      }
    }

    let MantenedorEpp = [];
    if (props.Formulario.MantenedorEpp != undefined) {
      if (props.Formulario.MantenedorEpp.Ver === true) {
        MantenedorEpp.push("Ver");
      }
      if (props.Formulario.MantenedorEpp.Editar === true) {
        MantenedorEpp.push("Editar");
      }
      if (props.Formulario.MantenedorEpp.Eliminar === true) {
        MantenedorEpp.push("Eliminar");
      }
    }

    let MantenedorDocumentos = [];
    if (props.Formulario.MantenedorDocumentos != undefined) {
      if (props.Formulario.MantenedorDocumentos.Ver === true) {
        MantenedorDocumentos.push("Ver");
      }
      if (props.Formulario.MantenedorDocumentos.Editar === true) {
        MantenedorDocumentos.push("Editar");
      }
      if (props.Formulario.MantenedorDocumentos.Eliminar === true) {
        MantenedorDocumentos.push("Eliminar");
      }
    }

    form.setFieldsValue({
      Nombre,
      Dashboard,
      MantenedorTrabajadores,
      Contratos,
      PlanificacionEquiposDeTrabajo,
      PlanificacionActividades,
      PlanificacionDetalleActividad,
      PlanificacionDetalleTrabajador,

      AsistenciaCheckIn,
      AsistenciaManual,
      AsistenciaQR,
      AsistenciaCheckOut,

      ProcesosDescanso,
      ProcesosEnTransito,
      ProcesosVacaciones,
      ProcesosLicencia,
      ProcesosMutual,
      ProcesosPermisos,
      ProcesosAusente,

      ReporteriaDesviaciones,
      ReporteriaDelDia,
      ReporteriaNecesidades,
      ReporteriaUtilizacionRelevos,
      ReporteriaAsistenciaPersonas,
      ReporteriaAsistenciaCC,
      ReporteriaAuditoria,
      ReporteriaCierreDeMes,
      ReporteriaCosteo,

      MantenedorUsuarios,
      MantenedorPerfilesUsuario,
      MantenedorAccesosSistema,
      MantenedorSucursales,
      MantenedorCentroDeCostos,
      MantenedorRegimenDeTrabajo,
      MantenedorFeriados,
      MantenedorTitulos,
      MantenedorEspecialidades,
      MantenedorCargos,
      MantenedorEpp,
      MantenedorDocumentos,
    });
  });

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ padding: "5px 20px 0px 20px" }}>

        <Col span={24} style={{ display: "flex" }} className="title">
          <img src={faena} style={{ width: "35px" }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Editar perfil de permiso"
          />
        </Col>

        <Col span={24}>

          <Card className="card-perfil" style={{ width: "100%" }}>

            <Form.Item
              name={"Nombre"}
              label="Nombre"
              rules={[
                {
                  required: true,
                  message: "Debe introducir un nombre",
                  min: 2,
                },
              ]}
            >
              <Input maxLength={24} name="Nombre" className="campos" />
            </Form.Item>

            <Row>
              <Col
                span={24}
                style={{
                  padding: "50px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <table
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          textAlign: "left",
                          padding: "5px",
                          borderRadius: "5px 0px 0px 5px",
                          paddingLeft: "30px",
                        }}
                      >
                        Categoría
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          padding: "5px",
                          borderRadius: "0px 5px 5px 0px",
                        }}
                      >
                        Permisos
                      </th>
                    </tr>
                  </thead>

                  <tbody>

                    <tr>
                      <td></td>
                      <td>
                        <Row>
                          <Col span={8}>
                            <img
                              alt="imagen"
                              style={{
                                width: "18px",
                              }}
                              src={iconOjoGris}
                            />
                          </Col>
                          <Col span={8}>
                            <img
                              alt="imagen"
                              style={{
                                width: "15px",
                              }}
                              src={iconLapiz}
                            />
                          </Col>
                          <Col span={8}>
                            <img
                              alt="imagen"
                              style={{
                                width: "12px",
                              }}
                              src={iconBasurero}
                            />
                          </Col>
                        </Row>
                      </td>
                    </tr>

                    {/* DASHBOARD */}
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos"
                            src={iconDashboard}
                          />
                          Dashboard
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"Dashboard"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconAsignaciones}
                          />
                        </h4>
                      </td>
                      <td>
                      </td>
                    </tr>

                    {/* TRABAJADORES */}
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos"
                            src={iconTrabajadores}
                          />
                          Trabajadores
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"MantenedorTrabajadores"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconAsignaciones}
                          />
                        </h4>
                      </td>
                      <td>
                      </td>
                    </tr>

                    {/* CONTRATOS */}
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          {/* <img
                            alt="imagen"
                            className="icons-sistema-accesos"
                            src={iconTrabajadores}
                          /> */}
                          <FileDoneOutlined
                            style={{ color: 'blue', fontSize: '15px', position: 'relative' }}
                            className="icons-sistema-accesos"
                          />
                          Contratos
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"Contratos"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconAsignaciones}
                          />
                        </h4>
                      </td>
                      <td>
                      </td>
                    </tr>

                    {/* PLANIFICACIÓN */}
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos"
                            src={iconAsignaciones}
                          />
                          Planificación
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                  onChange={(e) => {
                                    let check = e.target.checked;
                                    let valores = form.getFieldsValue([
                                      "PlanificacionEquiposDeTrabajo",
                                      "PlanificacionActividades",
                                      "PlanificacionDetalleActividad",
                                      "PlanificacionDetalleTrabajador"
                                    ]);

                                    if (check) {

                                      if (valores.PlanificacionEquiposDeTrabajo !== undefined && valores.PlanificacionEquiposDeTrabajo !== "undefined") {
                                        let nuevoValor = valores.PlanificacionEquiposDeTrabajo.filter(valor => valor !== "Ver");
                                        nuevoValor.push("Ver");
                                        valores.PlanificacionEquiposDeTrabajo = nuevoValor;
                                      } else {
                                        valores.PlanificacionEquiposDeTrabajo = ["Ver"];
                                      }

                                      if (valores.PlanificacionActividades !== undefined && valores.PlanificacionActividades !== "undefined") {
                                        let nuevoValor = valores.PlanificacionActividades.filter(valor => valor !== "Ver");
                                        nuevoValor.push("Ver");
                                        valores.PlanificacionActividades = nuevoValor;
                                      } else {
                                        valores.PlanificacionActividades = ["Ver"];
                                      }

                                      if (valores.PlanificacionDetalleActividad !== undefined && valores.PlanificacionDetalleActividad !== "undefined") {
                                        let nuevoValor = valores.PlanificacionDetalleActividad.filter(valor => valor !== "Ver");
                                        nuevoValor.push("Ver");
                                        valores.PlanificacionDetalleActividad = nuevoValor;
                                      } else {
                                        valores.PlanificacionDetalleActividad = ["Ver"];
                                      }

                                      if (valores.PlanificacionDetalleTrabajador !== undefined && valores.PlanificacionDetalleTrabajador !== "undefined") {
                                        let nuevoValor = valores.PlanificacionDetalleTrabajador.filter(valor => valor !== "Ver");
                                        nuevoValor.push("Ver");
                                        valores.PlanificacionDetalleTrabajador = nuevoValor;
                                      } else {
                                        valores.PlanificacionDetalleTrabajador = ["Ver"];
                                      }

                                    } else {

                                      if (valores.PlanificacionEquiposDeTrabajo !== undefined && valores.PlanificacionEquiposDeTrabajo !== "undefined") {
                                        let nuevoValor = valores.PlanificacionEquiposDeTrabajo.filter(valor => valor !== "Ver");
                                        valores.PlanificacionEquiposDeTrabajo = nuevoValor;
                                      } else {
                                        valores.PlanificacionEquiposDeTrabajo = [];
                                      }

                                      if (valores.PlanificacionActividades !== undefined && valores.PlanificacionActividades !== "undefined") {
                                        let nuevoValor = valores.PlanificacionActividades.filter(valor => valor !== "Ver");
                                        valores.PlanificacionActividades = nuevoValor;
                                      } else {
                                        valores.PlanificacionActividades = [];
                                      }

                                      if (valores.PlanificacionDetalleActividad !== undefined && valores.PlanificacionDetalleActividad !== "undefined") {
                                        let nuevoValor = valores.PlanificacionDetalleActividad.filter(valor => valor !== "Ver");
                                        valores.PlanificacionDetalleActividad = nuevoValor;
                                      } else {
                                        valores.PlanificacionDetalleActividad = [];
                                      }

                                      if (valores.PlanificacionDetalleTrabajador !== undefined && valores.PlanificacionDetalleTrabajador !== "undefined") {
                                        let nuevoValor = valores.PlanificacionDetalleTrabajador.filter(valor => valor !== "Ver");
                                        valores.PlanificacionDetalleTrabajador = nuevoValor;
                                      } else {
                                        valores.PlanificacionDetalleTrabajador = [];
                                      }
                                    }

                                    form.setFieldsValue({
                                      PlanificacionEquiposDeTrabajo: valores.PlanificacionEquiposDeTrabajo,
                                      PlanificacionActividades: valores.PlanificacionActividades,
                                      PlanificacionDetalleActividad: valores.PlanificacionDetalleActividad,
                                      PlanificacionDetalleTrabajador: valores.PlanificacionDetalleTrabajador,
                                    });
                                  }}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                  onChange={(e) => {
                                    let check = e.target.checked;
                                    let valores = form.getFieldsValue([
                                      "PlanificacionEquiposDeTrabajo",
                                      "PlanificacionActividades",
                                      "PlanificacionDetalleActividad",
                                      "PlanificacionDetalleTrabajador"
                                    ]);

                                    if (check) {

                                      if (valores.PlanificacionEquiposDeTrabajo !== undefined && valores.PlanificacionEquiposDeTrabajo !== "undefined") {
                                        let nuevoValor = valores.PlanificacionEquiposDeTrabajo.filter(valor => valor !== "Editar");
                                        nuevoValor.push("Editar");
                                        valores.PlanificacionEquiposDeTrabajo = nuevoValor;
                                      } else {
                                        valores.PlanificacionEquiposDeTrabajo = ["Editar"];
                                      }

                                      if (valores.PlanificacionActividades !== undefined && valores.PlanificacionActividades !== "undefined") {
                                        let nuevoValor = valores.PlanificacionActividades.filter(valor => valor !== "Editar");
                                        nuevoValor.push("Editar");
                                        valores.PlanificacionActividades = nuevoValor;
                                      } else {
                                        valores.PlanificacionActividades = ["Editar"];
                                      }

                                      if (valores.PlanificacionDetalleActividad !== undefined && valores.PlanificacionDetalleActividad !== "undefined") {
                                        let nuevoValor = valores.PlanificacionDetalleActividad.filter(valor => valor !== "Editar");
                                        nuevoValor.push("Editar");
                                        valores.PlanificacionDetalleActividad = nuevoValor;
                                      } else {
                                        valores.PlanificacionDetalleActividad = ["Editar"];
                                      }

                                      if (valores.PlanificacionDetalleTrabajador !== undefined && valores.PlanificacionDetalleTrabajador !== "undefined") {
                                        let nuevoValor = valores.PlanificacionDetalleTrabajador.filter(valor => valor !== "Editar");
                                        nuevoValor.push("Editar");
                                        valores.PlanificacionDetalleTrabajador = nuevoValor;
                                      } else {
                                        valores.PlanificacionDetalleTrabajador = ["Editar"];
                                      }

                                    } else {

                                      if (valores.PlanificacionEquiposDeTrabajo !== undefined && valores.PlanificacionEquiposDeTrabajo !== "undefined") {
                                        let nuevoValor = valores.PlanificacionEquiposDeTrabajo.filter(valor => valor !== "Editar");
                                        valores.PlanificacionEquiposDeTrabajo = nuevoValor;
                                      } else {
                                        valores.PlanificacionEquiposDeTrabajo = [];
                                      }

                                      if (valores.PlanificacionActividades !== undefined && valores.PlanificacionActividades !== "undefined") {
                                        let nuevoValor = valores.PlanificacionActividades.filter(valor => valor !== "Editar");
                                        valores.PlanificacionActividades = nuevoValor;
                                      } else {
                                        valores.PlanificacionActividades = [];
                                      }

                                      if (valores.PlanificacionDetalleActividad !== undefined && valores.PlanificacionDetalleActividad !== "undefined") {
                                        let nuevoValor = valores.PlanificacionDetalleActividad.filter(valor => valor !== "Editar");
                                        valores.PlanificacionDetalleActividad = nuevoValor;
                                      } else {
                                        valores.PlanificacionDetalleActividad = [];
                                      }

                                      if (valores.PlanificacionDetalleTrabajador !== undefined && valores.PlanificacionDetalleTrabajador !== "undefined") {
                                        let nuevoValor = valores.PlanificacionDetalleTrabajador.filter(valor => valor !== "Editar");
                                        valores.PlanificacionDetalleTrabajador = nuevoValor;
                                      } else {
                                        valores.PlanificacionDetalleTrabajador = [];
                                      }

                                    }

                                    form.setFieldsValue({
                                      PlanificacionEquiposDeTrabajo: valores.PlanificacionEquiposDeTrabajo,
                                      PlanificacionActividades: valores.PlanificacionActividades,
                                      PlanificacionDetalleActividad: valores.PlanificacionDetalleActividad,
                                      PlanificacionDetalleTrabajador: valores.PlanificacionDetalleTrabajador,
                                    });

                                  }}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                  onChange={(e) => {
                                    let check = e.target.checked;
                                    let valores = form.getFieldsValue([
                                      "PlanificacionEquiposDeTrabajo",
                                      "PlanificacionActividades",
                                      "PlanificacionDetalleActividad",
                                      "PlanificacionDetalleTrabajador"
                                    ]);

                                    if (check) {

                                      if (valores.PlanificacionEquiposDeTrabajo !== undefined && valores.PlanificacionEquiposDeTrabajo !== "undefined") {
                                        let nuevoValor = valores.PlanificacionEquiposDeTrabajo.filter(valor => valor !== "Eliminar");
                                        nuevoValor.push("Eliminar");
                                        valores.PlanificacionEquiposDeTrabajo = nuevoValor;
                                      } else {
                                        valores.PlanificacionEquiposDeTrabajo = ["Eliminar"];
                                      }

                                      if (valores.PlanificacionActividades !== undefined && valores.PlanificacionActividades !== "undefined") {
                                        let nuevoValor = valores.PlanificacionActividades.filter(valor => valor !== "Eliminar");
                                        nuevoValor.push("Eliminar");
                                        valores.PlanificacionActividades = nuevoValor;
                                      } else {
                                        valores.PlanificacionActividades = ["Eliminar"];
                                      }

                                      if (valores.PlanificacionDetalleActividad !== undefined && valores.PlanificacionDetalleActividad !== "undefined") {
                                        let nuevoValor = valores.PlanificacionDetalleActividad.filter(valor => valor !== "Eliminar");
                                        nuevoValor.push("Eliminar");
                                        valores.PlanificacionDetalleActividad = nuevoValor;
                                      } else {
                                        valores.PlanificacionDetalleActividad = ["Eliminar"];
                                      }

                                      if (valores.PlanificacionDetalleTrabajador !== undefined && valores.PlanificacionDetalleTrabajador !== "undefined") {
                                        let nuevoValor = valores.PlanificacionDetalleTrabajador.filter(valor => valor !== "Eliminar");
                                        nuevoValor.push("Eliminar");
                                        valores.PlanificacionDetalleTrabajador = nuevoValor;
                                      } else {
                                        valores.PlanificacionDetalleTrabajador = ["Eliminar"];
                                      }

                                    } else {

                                      if (valores.PlanificacionEquiposDeTrabajo !== undefined && valores.PlanificacionEquiposDeTrabajo !== "undefined") {
                                        let nuevoValor = valores.PlanificacionEquiposDeTrabajo.filter(valor => valor !== "Eliminar");
                                        valores.PlanificacionEquiposDeTrabajo = nuevoValor;
                                      } else {
                                        valores.PlanificacionEquiposDeTrabajo = [];
                                      }

                                      if (valores.PlanificacionActividades !== undefined && valores.PlanificacionActividades !== "undefined") {
                                        let nuevoValor = valores.PlanificacionActividades.filter(valor => valor !== "Eliminar");
                                        valores.PlanificacionActividades = nuevoValor;
                                      } else {
                                        valores.PlanificacionActividades = [];
                                      }

                                      if (valores.PlanificacionDetalleActividad !== undefined && valores.PlanificacionDetalleActividad !== "undefined") {
                                        let nuevoValor = valores.PlanificacionDetalleActividad.filter(valor => valor !== "Eliminar");
                                        valores.PlanificacionDetalleActividad = nuevoValor;
                                      } else {
                                        valores.PlanificacionDetalleActividad = [];
                                      }

                                      if (valores.PlanificacionDetalleTrabajador !== undefined && valores.PlanificacionDetalleTrabajador !== "undefined") {
                                        let nuevoValor = valores.PlanificacionDetalleTrabajador.filter(valor => valor !== "Eliminar");
                                        valores.PlanificacionDetalleTrabajador = nuevoValor;
                                      } else {
                                        valores.PlanificacionDetalleTrabajador = [];
                                      }
                                    }

                                    form.setFieldsValue({
                                      PlanificacionEquiposDeTrabajo: valores.PlanificacionEquiposDeTrabajo,
                                      PlanificacionActividades: valores.PlanificacionActividades,
                                      PlanificacionDetalleActividad: valores.PlanificacionDetalleActividad,
                                      PlanificacionDetalleTrabajador: valores.PlanificacionDetalleTrabajador,
                                    });
                                  }}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconAsignaciones}
                          />
                          Equipos de Trabajo
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"PlanificacionEquiposDeTrabajo"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconAsignaciones}
                          />
                          Actividades
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"PlanificacionActividades"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconAsignaciones}
                          />
                          Detalle de Actividad
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"PlanificacionDetalleActividad"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconAsignaciones}
                          />
                          Detalle de Trabajador
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"PlanificacionDetalleTrabajador"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>

                    {/* ASISTENCIA */}
                    <tr>
                      <td>
                        {" "}
                        <h4

                        >
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconAsignaciones}
                          />

                        </h4>
                      </td>
                      <td>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos"
                            src={iconAsistencia}
                          />
                          Asistencia
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                  onChange={(e) => {
                                    let check = e.target.checked;
                                    let valores = form.getFieldsValue([
                                      "AsistenciaCheckIn",
                                      "AsistenciaManual",
                                      "AsistenciaQR",
                                      "AsistenciaCheckOut"
                                    ]);

                                    if (check) {

                                      if (valores.AsistenciaCheckIn !== undefined && valores.AsistenciaCheckIn !== "undefined") {
                                        let nuevoValor = valores.AsistenciaCheckIn.filter(valor => valor !== "Ver");
                                        nuevoValor.push("Ver");
                                        valores.AsistenciaCheckIn = nuevoValor;
                                      } else {
                                        valores.AsistenciaCheckIn = ["Ver"];
                                      }

                                      if (valores.AsistenciaManual !== undefined && valores.AsistenciaManual !== "undefined") {
                                        let nuevoValor = valores.AsistenciaManual.filter(valor => valor !== "Ver");
                                        nuevoValor.push("Ver");
                                        valores.AsistenciaManual = nuevoValor;
                                      } else {
                                        valores.AsistenciaManual = ["Ver"];
                                      }

                                      if (valores.AsistenciaQR !== undefined && valores.AsistenciaQR !== "undefined") {
                                        let nuevoValor = valores.AsistenciaQR.filter(valor => valor !== "Ver");
                                        nuevoValor.push("Ver");
                                        valores.AsistenciaQR = nuevoValor;
                                      } else {
                                        valores.AsistenciaQR = ["Ver"];
                                      }

                                      if (valores.AsistenciaCheckOut !== undefined && valores.AsistenciaCheckOut !== "undefined") {
                                        let nuevoValor = valores.AsistenciaCheckOut.filter(valor => valor !== "Ver");
                                        nuevoValor.push("Ver");
                                        valores.AsistenciaCheckOut = nuevoValor;
                                      } else {
                                        valores.AsistenciaCheckOut = ["Ver"];
                                      }

                                    } else {

                                      if (valores.AsistenciaCheckIn !== undefined && valores.AsistenciaCheckIn !== "undefined") {
                                        let nuevoValor = valores.AsistenciaCheckIn.filter(valor => valor !== "Ver");
                                        valores.AsistenciaCheckIn = nuevoValor;
                                      } else {
                                        valores.AsistenciaCheckIn = [];
                                      }

                                      if (valores.AsistenciaManual !== undefined && valores.AsistenciaManual !== "undefined") {
                                        let nuevoValor = valores.AsistenciaManual.filter(valor => valor !== "Ver");
                                        valores.AsistenciaManual = nuevoValor;
                                      } else {
                                        valores.AsistenciaManual = [];
                                      }

                                      if (valores.AsistenciaQR !== undefined && valores.AsistenciaQR !== "undefined") {
                                        let nuevoValor = valores.AsistenciaQR.filter(valor => valor !== "Ver");
                                        valores.AsistenciaQR = nuevoValor;
                                      } else {
                                        valores.AsistenciaQR = [];
                                      }

                                      if (valores.AsistenciaCheckOut !== undefined && valores.AsistenciaCheckOut !== "undefined") {
                                        let nuevoValor = valores.AsistenciaCheckOut.filter(valor => valor !== "Ver");
                                        valores.AsistenciaCheckOut = nuevoValor;
                                      } else {
                                        valores.AsistenciaCheckOut = [];
                                      }

                                    }

                                    form.setFieldsValue({
                                      AsistenciaCheckIn: valores.AsistenciaCheckIn,
                                      AsistenciaManual: valores.AsistenciaManual,
                                      AsistenciaQR: valores.AsistenciaQR,
                                      AsistenciaCheckOut: valores.AsistenciaCheckOut,
                                    });


                                  }}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                  onChange={(e) => {
                                    let check = e.target.checked;
                                    let valores = form.getFieldsValue([
                                      "AsistenciaCheckIn",
                                      "AsistenciaManual",
                                      "AsistenciaQR",
                                      "AsistenciaCheckOut"
                                    ]);

                                    if (check) {

                                      if (valores.AsistenciaCheckIn !== undefined && valores.AsistenciaCheckIn !== "undefined") {
                                        let nuevoValor = valores.AsistenciaCheckIn.filter(valor => valor !== "Editar");
                                        nuevoValor.push("Editar");
                                        valores.AsistenciaCheckIn = nuevoValor;
                                      } else {
                                        valores.AsistenciaCheckIn = ["Editar"];
                                      }

                                      if (valores.AsistenciaManual !== undefined && valores.AsistenciaManual !== "undefined") {
                                        let nuevoValor = valores.AsistenciaManual.filter(valor => valor !== "Editar");
                                        nuevoValor.push("Editar");
                                        valores.AsistenciaManual = nuevoValor;
                                      } else {
                                        valores.AsistenciaManual = ["Editar"];
                                      }

                                      if (valores.AsistenciaQR !== undefined && valores.AsistenciaQR !== "undefined") {
                                        let nuevoValor = valores.AsistenciaQR.filter(valor => valor !== "Editar");
                                        nuevoValor.push("Editar");
                                        valores.AsistenciaQR = nuevoValor;
                                      } else {
                                        valores.AsistenciaQR = ["Editar"];
                                      }

                                      if (valores.AsistenciaCheckOut !== undefined && valores.AsistenciaCheckOut !== "undefined") {
                                        let nuevoValor = valores.AsistenciaCheckOut.filter(valor => valor !== "Editar");
                                        nuevoValor.push("Editar");
                                        valores.AsistenciaCheckOut = nuevoValor;
                                      } else {
                                        valores.AsistenciaCheckOut = ["Editar"];
                                      }

                                    } else {

                                      if (valores.AsistenciaCheckIn !== undefined && valores.AsistenciaCheckIn !== "undefined") {
                                        let nuevoValor = valores.AsistenciaCheckIn.filter(valor => valor !== "Editar");
                                        valores.AsistenciaCheckIn = nuevoValor;
                                      } else {
                                        valores.AsistenciaCheckIn = [];
                                      }

                                      if (valores.AsistenciaManual !== undefined && valores.AsistenciaManual !== "undefined") {
                                        let nuevoValor = valores.AsistenciaManual.filter(valor => valor !== "Editar");
                                        valores.AsistenciaManual = nuevoValor;
                                      } else {
                                        valores.AsistenciaManual = [];
                                      }

                                      if (valores.AsistenciaQR !== undefined && valores.AsistenciaQR !== "undefined") {
                                        let nuevoValor = valores.AsistenciaQR.filter(valor => valor !== "Editar");
                                        valores.AsistenciaQR = nuevoValor;
                                      } else {
                                        valores.AsistenciaQR = [];
                                      }

                                      if (valores.AsistenciaCheckOut !== undefined && valores.AsistenciaCheckOut !== "undefined") {
                                        let nuevoValor = valores.AsistenciaCheckOut.filter(valor => valor !== "Editar");
                                        valores.AsistenciaCheckOut = nuevoValor;
                                      } else {
                                        valores.AsistenciaCheckOut = [];
                                      }
                                    }

                                    form.setFieldsValue({
                                      AsistenciaCheckIn: valores.AsistenciaCheckIn,
                                      AsistenciaManual: valores.AsistenciaManual,
                                      AsistenciaQR: valores.AsistenciaQR,
                                      AsistenciaCheckOut: valores.AsistenciaCheckOut,
                                    });
                                  }}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                  onChange={(e) => {
                                    let check = e.target.checked;
                                    let valores = form.getFieldsValue([
                                      "AsistenciaCheckIn",
                                      "AsistenciaManual",
                                      "AsistenciaQR",
                                      "AsistenciaCheckOut"
                                    ]);

                                    if (check) {

                                      if (valores.AsistenciaCheckIn !== undefined && valores.AsistenciaCheckIn !== "undefined") {
                                        let nuevoValor = valores.AsistenciaCheckIn.filter(valor => valor !== "Eliminar");
                                        nuevoValor.push("Eliminar");
                                        valores.AsistenciaCheckIn = nuevoValor;
                                      } else {
                                        valores.AsistenciaCheckIn = ["Eliminar"];
                                      }

                                      if (valores.AsistenciaManual !== undefined && valores.AsistenciaManual !== "undefined") {
                                        let nuevoValor = valores.AsistenciaManual.filter(valor => valor !== "Eliminar");
                                        nuevoValor.push("Eliminar");
                                        valores.AsistenciaManual = nuevoValor;
                                      } else {
                                        valores.AsistenciaManual = ["Eliminar"];
                                      }

                                      if (valores.AsistenciaQR !== undefined && valores.AsistenciaQR !== "undefined") {
                                        let nuevoValor = valores.AsistenciaQR.filter(valor => valor !== "Eliminar");
                                        nuevoValor.push("Eliminar");
                                        valores.AsistenciaQR = nuevoValor;
                                      } else {
                                        valores.AsistenciaQR = ["Eliminar"];
                                      }

                                      if (valores.AsistenciaCheckOut !== undefined && valores.AsistenciaCheckOut !== "undefined") {
                                        let nuevoValor = valores.AsistenciaCheckOut.filter(valor => valor !== "Eliminar");
                                        nuevoValor.push("Eliminar");
                                        valores.AsistenciaCheckOut = nuevoValor;
                                      } else {
                                        valores.AsistenciaCheckOut = ["Eliminar"];
                                      }

                                    } else {

                                      if (valores.AsistenciaCheckIn !== undefined && valores.AsistenciaCheckIn !== "undefined") {
                                        let nuevoValor = valores.AsistenciaCheckIn.filter(valor => valor !== "Eliminar");
                                        valores.AsistenciaCheckIn = nuevoValor;
                                      } else {
                                        valores.AsistenciaCheckIn = [];
                                      }

                                      if (valores.AsistenciaManual !== undefined && valores.AsistenciaManual !== "undefined") {
                                        let nuevoValor = valores.AsistenciaManual.filter(valor => valor !== "Eliminar");
                                        valores.AsistenciaManual = nuevoValor;
                                      } else {
                                        valores.AsistenciaManual = [];
                                      }

                                      if (valores.AsistenciaQR !== undefined && valores.AsistenciaQR !== "undefined") {
                                        let nuevoValor = valores.AsistenciaQR.filter(valor => valor !== "Eliminar");
                                        valores.AsistenciaQR = nuevoValor;
                                      } else {
                                        valores.AsistenciaQR = [];
                                      }

                                      if (valores.AsistenciaCheckOut !== undefined && valores.AsistenciaCheckOut !== "undefined") {
                                        let nuevoValor = valores.AsistenciaCheckOut.filter(valor => valor !== "Eliminar");
                                        valores.AsistenciaCheckOut = nuevoValor;
                                      } else {
                                        valores.AsistenciaCheckOut = [];
                                      }

                                    }

                                    form.setFieldsValue({
                                      AsistenciaCheckIn: valores.AsistenciaCheckIn,
                                      AsistenciaManual: valores.AsistenciaManual,
                                      AsistenciaQR: valores.AsistenciaQR,
                                      AsistenciaCheckOut: valores.AsistenciaCheckOut,
                                    });


                                  }}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconAsistencia}
                          />
                          Check-In
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"AsistenciaCheckIn"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconAsistencia}
                          />
                          Asistencia Manual
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"AsistenciaManual"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconAsistencia}
                          />
                          Asistencia QR
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"AsistenciaQR"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconAsistencia}
                          />
                          Check-Out
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"AsistenciaCheckOut"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>

                    {/* PROCESOS */}
                    <tr>
                      <td>
                        {" "}
                        <h4

                        >
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconAsignaciones}
                          />

                        </h4>
                      </td>
                      <td>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos"
                            src={iconProcesosDelTrabajador}
                          />
                          Procesos del trabajador
                        </h4>
                      </td>
                      <td>
                        <Row>
                          <Col span={8}>
                            <Checkbox
                              onChange={(e) => {
                                let check = e.target.checked;
                                let valores = form.getFieldsValue([
                                  "ProcesosDescanso",
                                  "ProcesosEnTransito",
                                  "ProcesosVacaciones",
                                  "ProcesosLicencia",
                                  "ProcesosMutual",
                                  "ProcesosPermisos",
                                  "ProcesosAusente"
                                ]);

                                if (check) {

                                  if (valores.ProcesosDescanso !== undefined && valores.ProcesosDescanso !== "undefined") {
                                    let nuevoValor = valores.ProcesosDescanso.filter(valor => valor !== "Ver");
                                    nuevoValor.push("Ver");
                                    valores.ProcesosDescanso = nuevoValor;
                                  } else {
                                    valores.ProcesosDescanso = ["Ver"];
                                  }

                                  if (valores.ProcesosEnTransito !== undefined && valores.ProcesosEnTransito !== "undefined") {
                                    let nuevoValor = valores.ProcesosEnTransito.filter(valor => valor !== "Ver");
                                    nuevoValor.push("Ver");
                                    valores.ProcesosEnTransito = nuevoValor;
                                  } else {
                                    valores.ProcesosEnTransito = ["Ver"];
                                  }

                                  if (valores.ProcesosVacaciones !== undefined && valores.ProcesosVacaciones !== "undefined") {
                                    let nuevoValor = valores.ProcesosVacaciones.filter(valor => valor !== "Ver");
                                    nuevoValor.push("Ver");
                                    valores.ProcesosVacaciones = nuevoValor;
                                  } else {
                                    valores.ProcesosVacaciones = ["Ver"];
                                  }

                                  if (valores.ProcesosLicencia !== undefined && valores.ProcesosLicencia !== "undefined") {
                                    let nuevoValor = valores.ProcesosLicencia.filter(valor => valor !== "Ver");
                                    nuevoValor.push("Ver");
                                    valores.ProcesosLicencia = nuevoValor;
                                  } else {
                                    valores.ProcesosLicencia = ["Ver"];
                                  }

                                  if (valores.ProcesosMutual !== undefined && valores.ProcesosMutual !== "undefined") {
                                    let nuevoValor = valores.ProcesosMutual.filter(valor => valor !== "Ver");
                                    nuevoValor.push("Ver");
                                    valores.ProcesosMutual = nuevoValor;
                                  } else {
                                    valores.ProcesosMutual = ["Ver"];
                                  }

                                  if (valores.ProcesosPermisos !== undefined && valores.ProcesosPermisos !== "undefined") {
                                    let nuevoValor = valores.ProcesosPermisos.filter(valor => valor !== "Ver");
                                    nuevoValor.push("Ver");
                                    valores.ProcesosPermisos = nuevoValor;
                                  } else {
                                    valores.ProcesosPermisos = ["Ver"];
                                  }

                                  if (valores.ProcesosAusente !== undefined && valores.ProcesosAusente !== "undefined") {
                                    let nuevoValor = valores.ProcesosAusente.filter(valor => valor !== "Ver");
                                    nuevoValor.push("Ver");
                                    valores.ProcesosAusente = nuevoValor;
                                  } else {
                                    valores.ProcesosAusente = ["Ver"];
                                  }

                                } else {

                                  if (valores.ProcesosDescanso !== undefined && valores.ProcesosDescanso !== "undefined") {
                                    let nuevoValor = valores.ProcesosDescanso.filter(valor => valor !== "Ver");
                                    valores.ProcesosDescanso = nuevoValor;
                                  } else {
                                    valores.ProcesosDescanso = [];
                                  }

                                  if (valores.ProcesosEnTransito !== undefined && valores.ProcesosEnTransito !== "undefined") {
                                    let nuevoValor = valores.ProcesosEnTransito.filter(valor => valor !== "Ver");
                                    valores.ProcesosEnTransito = nuevoValor;
                                  } else {
                                    valores.ProcesosEnTransito = [];
                                  }

                                  if (valores.ProcesosVacaciones !== undefined && valores.ProcesosVacaciones !== "undefined") {
                                    let nuevoValor = valores.ProcesosVacaciones.filter(valor => valor !== "Ver");
                                    valores.ProcesosVacaciones = nuevoValor;
                                  } else {
                                    valores.ProcesosVacaciones = [];
                                  }

                                  if (valores.ProcesosLicencia !== undefined && valores.ProcesosLicencia !== "undefined") {
                                    let nuevoValor = valores.ProcesosLicencia.filter(valor => valor !== "Ver");
                                    valores.ProcesosLicencia = nuevoValor;
                                  } else {
                                    valores.ProcesosLicencia = [];
                                  }

                                  if (valores.ProcesosMutual !== undefined && valores.ProcesosMutual !== "undefined") {
                                    let nuevoValor = valores.ProcesosMutual.filter(valor => valor !== "Ver");
                                    valores.ProcesosMutual = nuevoValor;
                                  } else {
                                    valores.ProcesosMutual = [];
                                  }

                                  if (valores.ProcesosPermisos !== undefined && valores.ProcesosPermisos !== "undefined") {
                                    let nuevoValor = valores.ProcesosPermisos.filter(valor => valor !== "Ver");
                                    valores.ProcesosPermisos = nuevoValor;
                                  } else {
                                    valores.ProcesosPermisos = [];
                                  }

                                  if (valores.ProcesosAusente !== undefined && valores.ProcesosAusente !== "undefined") {
                                    let nuevoValor = valores.ProcesosAusente.filter(valor => valor !== "Ver");
                                    valores.ProcesosAusente = nuevoValor;
                                  } else {
                                    valores.ProcesosAusente = [];
                                  }
                                }

                                form.setFieldsValue({
                                  ProcesosDescanso: valores.ProcesosDescanso,
                                  ProcesosEnTransito: valores.ProcesosEnTransito,
                                  ProcesosVacaciones: valores.ProcesosVacaciones,
                                  ProcesosLicencia: valores.ProcesosLicencia,
                                  ProcesosMutual: valores.ProcesosMutual,
                                  ProcesosPermisos: valores.ProcesosPermisos,
                                  ProcesosAusente: valores.ProcesosAusente,
                                });
                              }}
                            ></Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox onChange={(e) => {
                              let check = e.target.checked;
                              let valores = form.getFieldsValue([
                                "ProcesosDescanso",
                                "ProcesosEnTransito",
                                "ProcesosVacaciones",
                                "ProcesosLicencia",
                                "ProcesosMutual",
                                "ProcesosPermisos",
                                "ProcesosAusente"
                              ]);

                              if (check) {

                                if (valores.ProcesosDescanso !== undefined && valores.ProcesosDescanso !== "undefined") {
                                  let nuevoValor = valores.ProcesosDescanso.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ProcesosDescanso = nuevoValor;
                                } else {
                                  valores.ProcesosDescanso = ["Editar"];
                                }

                                if (valores.ProcesosEnTransito !== undefined && valores.ProcesosEnTransito !== "undefined") {
                                  let nuevoValor = valores.ProcesosEnTransito.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ProcesosEnTransito = nuevoValor;
                                } else {
                                  valores.ProcesosEnTransito = ["Editar"];
                                }

                                if (valores.ProcesosVacaciones !== undefined && valores.ProcesosVacaciones !== "undefined") {
                                  let nuevoValor = valores.ProcesosVacaciones.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ProcesosVacaciones = nuevoValor;
                                } else {
                                  valores.ProcesosVacaciones = ["Editar"];
                                }

                                if (valores.ProcesosLicencia !== undefined && valores.ProcesosLicencia !== "undefined") {
                                  let nuevoValor = valores.ProcesosLicencia.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ProcesosLicencia = nuevoValor;
                                } else {
                                  valores.ProcesosLicencia = ["Editar"];
                                }

                                if (valores.ProcesosMutual !== undefined && valores.ProcesosMutual !== "undefined") {
                                  let nuevoValor = valores.ProcesosMutual.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ProcesosMutual = nuevoValor;
                                } else {
                                  valores.ProcesosMutual = ["Editar"];
                                }

                                if (valores.ProcesosPermisos !== undefined && valores.ProcesosPermisos !== "undefined") {
                                  let nuevoValor = valores.ProcesosPermisos.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ProcesosPermisos = nuevoValor;
                                } else {
                                  valores.ProcesosPermisos = ["Editar"];
                                }

                                if (valores.ProcesosAusente !== undefined && valores.ProcesosAusente !== "undefined") {
                                  let nuevoValor = valores.ProcesosAusente.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ProcesosAusente = nuevoValor;
                                } else {
                                  valores.ProcesosAusente = ["Editar"];
                                }

                              } else {

                                if (valores.ProcesosDescanso !== undefined && valores.ProcesosDescanso !== "undefined") {
                                  let nuevoValor = valores.ProcesosDescanso.filter(valor => valor !== "Editar");
                                  valores.ProcesosDescanso = nuevoValor;
                                } else {
                                  valores.ProcesosDescanso = [];
                                }

                                if (valores.ProcesosEnTransito !== undefined && valores.ProcesosEnTransito !== "undefined") {
                                  let nuevoValor = valores.ProcesosEnTransito.filter(valor => valor !== "Editar");
                                  valores.ProcesosEnTransito = nuevoValor;
                                } else {
                                  valores.ProcesosEnTransito = [];
                                }

                                if (valores.ProcesosVacaciones !== undefined && valores.ProcesosVacaciones !== "undefined") {
                                  let nuevoValor = valores.ProcesosVacaciones.filter(valor => valor !== "Editar");
                                  valores.ProcesosVacaciones = nuevoValor;
                                } else {
                                  valores.ProcesosVacaciones = [];
                                }

                                if (valores.ProcesosLicencia !== undefined && valores.ProcesosLicencia !== "undefined") {
                                  let nuevoValor = valores.ProcesosLicencia.filter(valor => valor !== "Editar");
                                  valores.ProcesosLicencia = nuevoValor;
                                } else {
                                  valores.ProcesosLicencia = [];
                                }

                                if (valores.ProcesosMutual !== undefined && valores.ProcesosMutual !== "undefined") {
                                  let nuevoValor = valores.ProcesosMutual.filter(valor => valor !== "Editar");
                                  valores.ProcesosMutual = nuevoValor;
                                } else {
                                  valores.ProcesosMutual = [];
                                }

                                if (valores.ProcesosPermisos !== undefined && valores.ProcesosPermisos !== "undefined") {
                                  let nuevoValor = valores.ProcesosPermisos.filter(valor => valor !== "Editar");
                                  valores.ProcesosPermisos = nuevoValor;
                                } else {
                                  valores.ProcesosPermisos = [];
                                }

                                if (valores.ProcesosAusente !== undefined && valores.ProcesosAusente !== "undefined") {
                                  let nuevoValor = valores.ProcesosAusente.filter(valor => valor !== "Editar");
                                  valores.ProcesosAusente = nuevoValor;
                                } else {
                                  valores.ProcesosAusente = [];
                                }
                              }

                              form.setFieldsValue({
                                ProcesosDescanso: valores.ProcesosDescanso,
                                ProcesosVacaciones: valores.ProcesosVacaciones,
                                ProcesosEnTransito: valores.ProcesosEnTransito,
                                ProcesosLicencia: valores.ProcesosLicencia,
                                ProcesosMutual: valores.ProcesosMutual,
                                ProcesosPermisos: valores.ProcesosPermisos,
                                ProcesosAusente: valores.ProcesosAusente,
                              });
                            }}></Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox onChange={(e) => {
                              let check = e.target.checked;
                              let valores = form.getFieldsValue([
                                "ProcesosDescanso",
                                "ProcesosEnTransito",
                                "ProcesosVacaciones",
                                "ProcesosLicencia",
                                "ProcesosMutual",
                                "ProcesosPermisos",
                                "ProcesosAusente"]);

                              if (check) {

                                if (valores.ProcesosDescanso !== undefined && valores.ProcesosDescanso !== "undefined") {
                                  let nuevoValor = valores.ProcesosDescanso.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ProcesosDescanso = nuevoValor;
                                } else {
                                  valores.ProcesosDescanso = ["Eliminar"];
                                }

                                if (valores.ProcesosEnTransito !== undefined && valores.ProcesosEnTransito !== "undefined") {
                                  let nuevoValor = valores.ProcesosEnTransito.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ProcesosEnTransito = nuevoValor;
                                } else {
                                  valores.ProcesosEnTransito = ["Eliminar"];
                                }

                                if (valores.ProcesosVacaciones !== undefined && valores.ProcesosVacaciones !== "undefined") {
                                  let nuevoValor = valores.ProcesosVacaciones.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ProcesosVacaciones = nuevoValor;
                                } else {
                                  valores.ProcesosVacaciones = ["Eliminar"];
                                }

                                if (valores.ProcesosLicencia !== undefined && valores.ProcesosLicencia !== "undefined") {
                                  let nuevoValor = valores.ProcesosLicencia.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ProcesosLicencia = nuevoValor;
                                } else {
                                  valores.ProcesosLicencia = ["Eliminar"];
                                }

                                if (valores.ProcesosMutual !== undefined && valores.ProcesosMutual !== "undefined") {
                                  let nuevoValor = valores.ProcesosMutual.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ProcesosMutual = nuevoValor;
                                } else {
                                  valores.ProcesosMutual = ["Eliminar"];
                                }

                                if (valores.ProcesosPermisos !== undefined && valores.ProcesosPermisos !== "undefined") {
                                  let nuevoValor = valores.ProcesosPermisos.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ProcesosPermisos = nuevoValor;
                                } else {
                                  valores.ProcesosPermisos = ["Eliminar"];
                                }

                                if (valores.ProcesosAusente !== undefined && valores.ProcesosAusente !== "undefined") {
                                  let nuevoValor = valores.ProcesosAusente.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ProcesosAusente = nuevoValor;
                                } else {
                                  valores.ProcesosAusente = ["Eliminar"];
                                }

                              } else {

                                if (valores.ProcesosDescanso !== undefined && valores.ProcesosDescanso !== "undefined") {
                                  let nuevoValor = valores.ProcesosDescanso.filter(valor => valor !== "Eliminar");
                                  valores.ProcesosDescanso = nuevoValor;
                                } else {
                                  valores.ProcesosDescanso = [];
                                }

                                if (valores.ProcesosEnTransito !== undefined && valores.ProcesosEnTransito !== "undefined") {
                                  let nuevoValor = valores.ProcesosEnTransito.filter(valor => valor !== "Eliminar");
                                  valores.ProcesosEnTransito = nuevoValor;
                                } else {
                                  valores.ProcesosEnTransito = [];
                                }

                                if (valores.ProcesosVacaciones !== undefined && valores.ProcesosVacaciones !== "undefined") {
                                  let nuevoValor = valores.ProcesosVacaciones.filter(valor => valor !== "Eliminar");
                                  valores.ProcesosVacaciones = nuevoValor;
                                } else {
                                  valores.ProcesosVacaciones = [];
                                }

                                if (valores.ProcesosLicencia !== undefined && valores.ProcesosLicencia !== "undefined") {
                                  let nuevoValor = valores.ProcesosLicencia.filter(valor => valor !== "Eliminar");
                                  valores.ProcesosLicencia = nuevoValor;
                                } else {
                                  valores.ProcesosLicencia = [];
                                }

                                if (valores.ProcesosMutual !== undefined && valores.ProcesosMutual !== "undefined") {
                                  let nuevoValor = valores.ProcesosMutual.filter(valor => valor !== "Eliminar");
                                  valores.ProcesosMutual = nuevoValor;
                                } else {
                                  valores.ProcesosMutual = [];
                                }

                                if (valores.ProcesosPermisos !== undefined && valores.ProcesosPermisos !== "undefined") {
                                  let nuevoValor = valores.ProcesosPermisos.filter(valor => valor !== "Eliminar");
                                  valores.ProcesosPermisos = nuevoValor;
                                } else {
                                  valores.ProcesosPermisos = [];
                                }

                                if (valores.ProcesosAusente !== undefined && valores.ProcesosAusente !== "undefined") {
                                  let nuevoValor = valores.ProcesosAusente.filter(valor => valor !== "Eliminar");
                                  valores.ProcesosAusente = nuevoValor;
                                } else {
                                  valores.ProcesosAusente = [];
                                }
                              }

                              form.setFieldsValue({
                                ProcesosDescanso: valores.ProcesosDescanso,
                                ProcesosEnTransito: valores.ProcesosEnTransito,
                                ProcesosVacaciones: valores.ProcesosVacaciones,
                                ProcesosLicencia: valores.ProcesosLicencia,
                                ProcesosMutual: valores.ProcesosMutual,
                                ProcesosPermisos: valores.ProcesosPermisos,
                                ProcesosAusente: valores.ProcesosAusente,
                              });

                            }}></Checkbox>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconProcesosDelTrabajador}
                          />
                          Descansos
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ProcesosDescanso"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconProcesosDelTrabajador}
                          />
                          En Tránsito
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ProcesosEnTransito"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconProcesosDelTrabajador}
                          />
                          Vacaciones
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ProcesosVacaciones"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconProcesosDelTrabajador}
                          />
                          Licencias
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ProcesosLicencia"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconProcesosDelTrabajador}
                          />
                          Licencia Mutual
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ProcesosMutual"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconProcesosDelTrabajador}
                          />
                          Permisos
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ProcesosPermisos"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconProcesosDelTrabajador}
                          />
                          Día Ausente
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ProcesosAusente"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>

                    {/* REPORTERÍA */}
                    <tr>
                      <td>
                        {" "}
                        <h4

                        >
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconAsignaciones}
                          />

                        </h4>
                      </td>
                      <td>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos"
                            src={iconMantenedores}
                          />
                          Reportería
                        </h4>
                      </td>
                      <td>
                        <Row>
                          <Col span={8}>
                            <Checkbox onChange={(e) => {
                              let check = e.target.checked;
                              let valores = form.getFieldsValue([
                                "ReporteriaDesviaciones",
                                "ReporteriaAsistenciaPersonas",
                                "ReporteriaAsistenciaCC",
                                "ReporteriaAuditoria",
                                "ReporteriaCierreDeMes",
                                "ReporteriaCosteo",
                                'ReporteriaDelDia',
                                "ReporteriaNecesidades",
                                "ReporteriaUtilizacionRelevos"
                              ]);

                              if (check) {

                                if (valores.ReporteriaDesviaciones !== undefined && valores.ReporteriaDesviaciones !== "undefined") {
                                  let nuevoValor = valores.ReporteriaDesviaciones.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.ReporteriaDesviaciones = nuevoValor;
                                } else {
                                  valores.ReporteriaDesviaciones = ["Ver"];
                                }

                                if (valores.ReporteriaDelDia !== undefined && valores.ReporteriaDelDia !== "undefined") {
                                  let nuevoValor = valores.ReporteriaDelDia.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.ReporteriaDelDia = nuevoValor;
                                } else {
                                  valores.ReporteriaDelDia = ["Ver"];
                                }

                                if (valores.ReporteriaNecesidades !== undefined && valores.ReporteriaNecesidades !== "undefined") {
                                  let nuevoValor = valores.ReporteriaNecesidades.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.ReporteriaNecesidades = nuevoValor;
                                } else {
                                  valores.ReporteriaNecesidades = ["Ver"];
                                }

                                if (valores.ReporteriaUtilizacionRelevos !== undefined && valores.ReporteriaUtilizacionRelevos !== "undefined") {
                                  let nuevoValor = valores.ReporteriaUtilizacionRelevos.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.ReporteriaUtilizacionRelevos = nuevoValor;
                                } else {
                                  valores.ReporteriaUtilizacionRelevos = ["Ver"];
                                }

                                if (valores.ReporteriaAsistenciaPersonas !== undefined && valores.ReporteriaAsistenciaPersonas !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAsistenciaPersonas.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.ReporteriaAsistenciaPersonas = nuevoValor;
                                } else {
                                  valores.ReporteriaAsistenciaPersonas = ["Ver"];
                                }

                                if (valores.ReporteriaAsistenciaCC !== undefined && valores.ReporteriaAsistenciaCC !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAsistenciaCC.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.ReporteriaAsistenciaCC = nuevoValor;
                                } else {
                                  valores.ReporteriaAsistenciaCC = ["Ver"];
                                }

                                if (valores.ReporteriaAuditoria !== undefined && valores.ReporteriaAuditoria !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAuditoria.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.ReporteriaAuditoria = nuevoValor;
                                } else {
                                  valores.ReporteriaAuditoria = ["Ver"];
                                }

                                if (valores.ReporteriaCierreDeMes !== undefined && valores.ReporteriaCierreDeMes !== "undefined") {
                                  let nuevoValor = valores.ReporteriaCierreDeMes.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.ReporteriaCierreDeMes = nuevoValor;
                                } else {
                                  valores.ReporteriaCierreDeMes = ["Ver"];
                                }

                                if (valores.ReporteriaCosteo !== undefined && valores.ReporteriaCosteo !== "undefined") {
                                  let nuevoValor = valores.ReporteriaCosteo.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.ReporteriaCosteo = nuevoValor;
                                } else {
                                  valores.ReporteriaCosteo = ["Ver"];
                                }

                              } else {

                                if (valores.ReporteriaDesviaciones !== undefined && valores.ReporteriaDesviaciones !== "undefined") {
                                  let nuevoValor = valores.ReporteriaDesviaciones.filter(valor => valor !== "Ver");
                                  valores.ReporteriaDesviaciones = nuevoValor;
                                } else {
                                  valores.ReporteriaDesviaciones = [];
                                }

                                if (valores.ReporteriaDelDia !== undefined && valores.ReporteriaDelDia !== "undefined") {
                                  let nuevoValor = valores.ReporteriaDelDia.filter(valor => valor !== "Ver");
                                  valores.ReporteriaDelDia = nuevoValor;
                                } else {
                                  valores.ReporteriaDelDia = [];
                                }

                                if (valores.ReporteriaNecesidades !== undefined && valores.ReporteriaNecesidades !== "undefined") {
                                  let nuevoValor = valores.ReporteriaNecesidades.filter(valor => valor !== "Ver");
                                  valores.ReporteriaNecesidades = nuevoValor;
                                } else {
                                  valores.ReporteriaNecesidades = [];
                                }

                                if (valores.ReporteriaUtilizacionRelevos !== undefined && valores.ReporteriaUtilizacionRelevos !== "undefined") {
                                  let nuevoValor = valores.ReporteriaUtilizacionRelevos.filter(valor => valor !== "Ver");
                                  valores.ReporteriaUtilizacionRelevos = nuevoValor;
                                } else {
                                  valores.ReporteriaUtilizacionRelevos = [];
                                }

                                if (valores.ReporteriaAsistenciaPersonas !== undefined && valores.ReporteriaAsistenciaPersonas !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAsistenciaPersonas.filter(valor => valor !== "Ver");
                                  valores.ReporteriaAsistenciaPersonas = nuevoValor;
                                } else {
                                  valores.ReporteriaAsistenciaPersonas = [];
                                }

                                if (valores.ReporteriaAsistenciaCC !== undefined && valores.ReporteriaAsistenciaCC !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAsistenciaCC.filter(valor => valor !== "Ver");
                                  valores.ReporteriaAsistenciaCC = nuevoValor;
                                } else {
                                  valores.ReporteriaAsistenciaCC = [];
                                }

                                if (valores.ReporteriaAuditoria !== undefined && valores.ReporteriaAuditoria !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAuditoria.filter(valor => valor !== "Ver");
                                  valores.ReporteriaAuditoria = nuevoValor;
                                } else {
                                  valores.ReporteriaAuditoria = [];
                                }

                                if (valores.ReporteriaCierreDeMes !== undefined && valores.ReporteriaCierreDeMes !== "undefined") {
                                  let nuevoValor = valores.ReporteriaCierreDeMes.filter(valor => valor !== "Ver");
                                  valores.ReporteriaCierreDeMes = nuevoValor;
                                } else {
                                  valores.ReporteriaCierreDeMes = [];
                                }

                                if (valores.ReporteriaCosteo !== undefined && valores.ReporteriaCosteo !== "undefined") {
                                  let nuevoValor = valores.ReporteriaCosteo.filter(valor => valor !== "Ver");
                                  valores.ReporteriaCosteo = nuevoValor;
                                } else {
                                  valores.ReporteriaCosteo = [];
                                }

                              }

                              form.setFieldsValue({
                                ReporteriaDesviaciones: valores.ReporteriaDesviaciones,
                                ReporteriaAsistenciaPersonas: valores.ReporteriaAsistenciaPersonas,
                                ReporteriaAsistenciaCC: valores.ReporteriaAsistenciaCC,
                                ReporteriaAuditoria: valores.ReporteriaAuditoria,
                                ReporteriaCierreDeMes: valores.ReporteriaCierreDeMes,
                                ReporteriaCosteo: valores.ReporteriaCosteo,
                                ReporteriaDelDia: valores.ReporteriaDelDia,
                                ReporteriaNecesidades: valores.ReporteriaNecesidades,
                                ReporteriaUtilizacionRelevos: valores.ReporteriaUtilizacionRelevos
                              });
                            }}></Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox onChange={(e) => {
                              let check = e.target.checked;
                              let valores = form.getFieldsValue([
                                "ReporteriaDesviaciones",
                                "ReporteriaAsistenciaPersonas",
                                "ReporteriaAsistenciaCC",
                                "ReporteriaAuditoria",
                                "ReporteriaCierreDeMes",
                                "ReporteriaCosteo",
                                'ReporteriaDelDia',
                                "ReporteriaNecesidades",
                                "ReporteriaUtilizacionRelevos"
                              ]);

                              if (check) {

                                if (valores.ReporteriaDesviaciones !== undefined && valores.ReporteriaDesviaciones !== "undefined") {
                                  let nuevoValor = valores.ReporteriaDesviaciones.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ReporteriaDesviaciones = nuevoValor;
                                } else {
                                  valores.ReporteriaDesviaciones = ["Editar"];
                                }

                                if (valores.ReporteriaDelDia !== undefined && valores.ReporteriaDelDia !== "undefined") {
                                  let nuevoValor = valores.ReporteriaDelDia.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ReporteriaDelDia = nuevoValor;
                                } else {
                                  valores.ReporteriaDelDia = ["Editar"];
                                }

                                if (valores.ReporteriaNecesidades !== undefined && valores.ReporteriaNecesidades !== "undefined") {
                                  let nuevoValor = valores.ReporteriaNecesidades.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ReporteriaNecesidades = nuevoValor;
                                } else {
                                  valores.ReporteriaNecesidades = ["Editar"];
                                }

                                if (valores.ReporteriaUtilizacionRelevos !== undefined && valores.ReporteriaUtilizacionRelevos !== "undefined") {
                                  let nuevoValor = valores.ReporteriaUtilizacionRelevos.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ReporteriaUtilizacionRelevos = nuevoValor;
                                } else {
                                  valores.ReporteriaUtilizacionRelevos = ["Editar"];
                                }

                                if (valores.ReporteriaAsistenciaPersonas !== undefined && valores.ReporteriaAsistenciaPersonas !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAsistenciaPersonas.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ReporteriaAsistenciaPersonas = nuevoValor;
                                } else {
                                  valores.ReporteriaAsistenciaPersonas = ["Editar"];
                                }

                                if (valores.ReporteriaAsistenciaCC !== undefined && valores.ReporteriaAsistenciaCC !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAsistenciaCC.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ReporteriaAsistenciaCC = nuevoValor;
                                } else {
                                  valores.ReporteriaAsistenciaCC = ["Editar"];
                                }

                                if (valores.ReporteriaAuditoria !== undefined && valores.ReporteriaAuditoria !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAuditoria.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ReporteriaAuditoria = nuevoValor;
                                } else {
                                  valores.ReporteriaAuditoria = ["Editar"];
                                }

                                if (valores.ReporteriaCierreDeMes !== undefined && valores.ReporteriaCierreDeMes !== "undefined") {
                                  let nuevoValor = valores.ReporteriaCierreDeMes.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ReporteriaCierreDeMes = nuevoValor;
                                } else {
                                  valores.ReporteriaCierreDeMes = ["Editar"];
                                }

                                if (valores.ReporteriaCosteo !== undefined && valores.ReporteriaCosteo !== "undefined") {
                                  let nuevoValor = valores.ReporteriaCosteo.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.ReporteriaCosteo = nuevoValor;
                                } else {
                                  valores.ReporteriaCosteo = ["Editar"];
                                }

                              } else {

                                if (valores.ReporteriaDesviaciones !== undefined && valores.ReporteriaDesviaciones !== "undefined") {
                                  let nuevoValor = valores.ReporteriaDesviaciones.filter(valor => valor !== "Editar");
                                  valores.ReporteriaDesviaciones = nuevoValor;
                                } else {
                                  valores.ReporteriaDesviaciones = [];
                                }

                                if (valores.ReporteriaDelDia !== undefined && valores.ReporteriaDelDia !== "undefined") {
                                  let nuevoValor = valores.ReporteriaDelDia.filter(valor => valor !== "Editar");
                                  valores.ReporteriaDelDia = nuevoValor;
                                } else {
                                  valores.ReporteriaDelDia = [];
                                }

                                if (valores.ReporteriaNecesidades !== undefined && valores.ReporteriaNecesidades !== "undefined") {
                                  let nuevoValor = valores.ReporteriaNecesidades.filter(valor => valor !== "Editar");
                                  valores.ReporteriaNecesidades = nuevoValor;
                                } else {
                                  valores.ReporteriaNecesidades = [];
                                }

                                if (valores.ReporteriaUtilizacionRelevos !== undefined && valores.ReporteriaUtilizacionRelevos !== "undefined") {
                                  let nuevoValor = valores.ReporteriaUtilizacionRelevos.filter(valor => valor !== "Editar");
                                  valores.ReporteriaUtilizacionRelevos = nuevoValor;
                                } else {
                                  valores.ReporteriaUtilizacionRelevos = [];
                                }

                                if (valores.ReporteriaAsistenciaPersonas !== undefined && valores.ReporteriaAsistenciaPersonas !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAsistenciaPersonas.filter(valor => valor !== "Editar");
                                  valores.ReporteriaAsistenciaPersonas = nuevoValor;
                                } else {
                                  valores.ReporteriaAsistenciaPersonas = [];
                                }

                                if (valores.ReporteriaAsistenciaCC !== undefined && valores.ReporteriaAsistenciaCC !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAsistenciaCC.filter(valor => valor !== "Editar");
                                  valores.ReporteriaAsistenciaCC = nuevoValor;
                                } else {
                                  valores.ReporteriaAsistenciaCC = [];
                                }

                                if (valores.ReporteriaAuditoria !== undefined && valores.ReporteriaAuditoria !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAuditoria.filter(valor => valor !== "Editar");
                                  valores.ReporteriaAuditoria = nuevoValor;
                                } else {
                                  valores.ReporteriaAuditoria = [];
                                }

                                if (valores.ReporteriaCierreDeMes !== undefined && valores.ReporteriaCierreDeMes !== "undefined") {
                                  let nuevoValor = valores.ReporteriaCierreDeMes.filter(valor => valor !== "Editar");
                                  valores.ReporteriaCierreDeMes = nuevoValor;
                                } else {
                                  valores.ReporteriaCierreDeMes = [];
                                }

                                if (valores.ReporteriaCosteo !== undefined && valores.ReporteriaCosteo !== "undefined") {
                                  let nuevoValor = valores.ReporteriaCosteo.filter(valor => valor !== "Editar");
                                  valores.ReporteriaCosteo = nuevoValor;
                                } else {
                                  valores.ReporteriaCosteo = [];
                                }

                              }

                              form.setFieldsValue({
                                ReporteriaDesviaciones: valores.ReporteriaDesviaciones,
                                ReporteriaAsistenciaPersonas: valores.ReporteriaAsistenciaPersonas,
                                ReporteriaAsistenciaCC: valores.ReporteriaAsistenciaCC,
                                ReporteriaAuditoria: valores.ReporteriaAuditoria,
                                ReporteriaCierreDeMes: valores.ReporteriaCierreDeMes,
                                ReporteriaCosteo: valores.ReporteriaCosteo,
                                ReporteriaDelDia: valores.ReporteriaDelDia,
                                ReporteriaNecesidades: valores.ReporteriaNecesidades,
                                ReporteriaUtilizacionRelevos: valores.ReporteriaUtilizacionRelevos
                              });
                            }}></Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox onChange={(e) => {
                              let check = e.target.checked;
                              let valores = form.getFieldsValue([
                                "ReporteriaDesviaciones",
                                "ReporteriaAsistenciaPersonas",
                                "ReporteriaAsistenciaCC",
                                "ReporteriaAuditoria",
                                "ReporteriaCierreDeMes",
                                "ReporteriaCosteo",
                                'ReporteriaDelDia',
                                "ReporteriaNecesidades",
                                "ReporteriaUtilizacionRelevos"
                              ]);

                              if (check) {

                                if (valores.ReporteriaDesviaciones !== undefined && valores.ReporteriaDesviaciones !== "undefined") {
                                  let nuevoValor = valores.ReporteriaDesviaciones.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ReporteriaDesviaciones = nuevoValor;
                                } else {
                                  valores.ReporteriaDesviaciones = ["Eliminar"];
                                }

                                if (valores.ReporteriaDelDia !== undefined && valores.ReporteriaDelDia !== "undefined") {
                                  let nuevoValor = valores.ReporteriaDelDia.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ReporteriaDelDia = nuevoValor;
                                } else {
                                  valores.ReporteriaDelDia = ["Eliminar"];
                                }

                                if (valores.ReporteriaNecesidades !== undefined && valores.ReporteriaNecesidades !== "undefined") {
                                  let nuevoValor = valores.ReporteriaNecesidades.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ReporteriaNecesidades = nuevoValor;
                                } else {
                                  valores.ReporteriaNecesidades = ["Eliminar"];
                                }

                                if (valores.ReporteriaUtilizacionRelevos !== undefined && valores.ReporteriaUtilizacionRelevos !== "undefined") {
                                  let nuevoValor = valores.ReporteriaUtilizacionRelevos.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ReporteriaUtilizacionRelevos = nuevoValor;
                                } else {
                                  valores.ReporteriaUtilizacionRelevos = ["Eliminar"];
                                }

                                if (valores.ReporteriaAsistenciaPersonas !== undefined && valores.ReporteriaAsistenciaPersonas !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAsistenciaPersonas.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ReporteriaAsistenciaPersonas = nuevoValor;
                                } else {
                                  valores.ReporteriaAsistenciaPersonas = ["Eliminar"];
                                }

                                if (valores.ReporteriaAsistenciaCC !== undefined && valores.ReporteriaAsistenciaCC !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAsistenciaCC.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ReporteriaAsistenciaCC = nuevoValor;
                                } else {
                                  valores.ReporteriaAsistenciaCC = ["Eliminar"];
                                }

                                if (valores.ReporteriaAuditoria !== undefined && valores.ReporteriaAuditoria !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAuditoria.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ReporteriaAuditoria = nuevoValor;
                                } else {
                                  valores.ReporteriaAuditoria = ["Eliminar"];
                                }

                                if (valores.ReporteriaCierreDeMes !== undefined && valores.ReporteriaCierreDeMes !== "undefined") {
                                  let nuevoValor = valores.ReporteriaCierreDeMes.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ReporteriaCierreDeMes = nuevoValor;
                                } else {
                                  valores.ReporteriaCierreDeMes = ["Eliminar"];
                                }

                                if (valores.ReporteriaCosteo !== undefined && valores.ReporteriaCosteo !== "undefined") {
                                  let nuevoValor = valores.ReporteriaCosteo.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.ReporteriaCosteo = nuevoValor;
                                } else {
                                  valores.ReporteriaCosteo = ["Eliminar"];
                                }

                              } else {

                                if (valores.ReporteriaDesviaciones !== undefined && valores.ReporteriaDesviaciones !== "undefined") {
                                  let nuevoValor = valores.ReporteriaDesviaciones.filter(valor => valor !== "Eliminar");
                                  valores.ReporteriaDesviaciones = nuevoValor;
                                } else {
                                  valores.ReporteriaDesviaciones = [];
                                }

                                if (valores.ReporteriaDelDia !== undefined && valores.ReporteriaDelDia !== "undefined") {
                                  let nuevoValor = valores.ReporteriaDelDia.filter(valor => valor !== "Eliminar");
                                  valores.ReporteriaDelDia = nuevoValor;
                                } else {
                                  valores.ReporteriaDelDia = [];
                                }

                                if (valores.ReporteriaNecesidades !== undefined && valores.ReporteriaNecesidades !== "undefined") {
                                  let nuevoValor = valores.ReporteriaNecesidades.filter(valor => valor !== "Eliminar");
                                  valores.ReporteriaNecesidades = nuevoValor;
                                } else {
                                  valores.ReporteriaNecesidades = [];
                                }

                                if (valores.ReporteriaUtilizacionRelevos !== undefined && valores.ReporteriaUtilizacionRelevos !== "undefined") {
                                  let nuevoValor = valores.ReporteriaUtilizacionRelevos.filter(valor => valor !== "Eliminar");
                                  valores.ReporteriaUtilizacionRelevos = nuevoValor;
                                } else {
                                  valores.ReporteriaUtilizacionRelevos = [];
                                }

                                if (valores.ReporteriaAsistenciaPersonas !== undefined && valores.ReporteriaAsistenciaPersonas !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAsistenciaPersonas.filter(valor => valor !== "Eliminar");
                                  valores.ReporteriaAsistenciaPersonas = nuevoValor;
                                } else {
                                  valores.ReporteriaAsistenciaPersonas = [];
                                }

                                if (valores.ReporteriaAsistenciaCC !== undefined && valores.ReporteriaAsistenciaCC !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAsistenciaCC.filter(valor => valor !== "Eliminar");
                                  valores.ReporteriaAsistenciaCC = nuevoValor;
                                } else {
                                  valores.ReporteriaAsistenciaCC = [];
                                }

                                if (valores.ReporteriaAuditoria !== undefined && valores.ReporteriaAuditoria !== "undefined") {
                                  let nuevoValor = valores.ReporteriaAuditoria.filter(valor => valor !== "Eliminar");
                                  valores.ReporteriaAuditoria = nuevoValor;
                                } else {
                                  valores.ReporteriaAuditoria = [];
                                }

                                if (valores.ReporteriaCierreDeMes !== undefined && valores.ReporteriaCierreDeMes !== "undefined") {
                                  let nuevoValor = valores.ReporteriaCierreDeMes.filter(valor => valor !== "Eliminar");
                                  valores.ReporteriaCierreDeMes = nuevoValor;
                                } else {
                                  valores.ReporteriaCierreDeMes = [];
                                }

                                if (valores.ReporteriaCosteo !== undefined && valores.ReporteriaCosteo !== "undefined") {
                                  let nuevoValor = valores.ReporteriaCosteo.filter(valor => valor !== "Eliminar");
                                  valores.ReporteriaCosteo = nuevoValor;
                                } else {
                                  valores.ReporteriaCosteo = [];
                                }

                              }

                              form.setFieldsValue({
                                ReporteriaDesviaciones: valores.ReporteriaDesviaciones,
                                ReporteriaAsistenciaPersonas: valores.ReporteriaAsistenciaPersonas,
                                ReporteriaAsistenciaCC: valores.ReporteriaAsistenciaCC,
                                ReporteriaAuditoria: valores.ReporteriaAuditoria,
                                ReporteriaCierreDeMes: valores.ReporteriaCierreDeMes,
                                ReporteriaCosteo: valores.ReporteriaCosteo,
                                ReporteriaDelDia: valores.ReporteriaDelDia,
                                ReporteriaNecesidades: valores.ReporteriaNecesidades,
                                ReporteriaUtilizacionRelevos: valores.ReporteriaUtilizacionRelevos
                              });
                            }}></Checkbox>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Desviaciones
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ReporteriaDesviaciones"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Reporte del día
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ReporteriaDelDia"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Reporte Necesidades
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ReporteriaNecesidades"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Reporte Utilización Relevo
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ReporteriaUtilizacionRelevos"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Asistencia de Personas
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ReporteriaAsistenciaPersonas"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Asistencia por CC
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ReporteriaAsistenciaCC"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Auditoria
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ReporteriaAuditoria"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Cierre De Mes
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ReporteriaCierreDeMes"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Costeo
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"ReporteriaCosteo"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>

                    {/* MANTENEDORES */}
                    <tr>
                      <td>
                        {" "}
                        <h4

                        >
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconAsignaciones}
                          />

                        </h4>
                      </td>
                      <td>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos"
                            src={iconMantenedores}
                          />
                          Mantenedores
                        </h4>
                      </td>
                      <td>
                        <Row>
                          <Col span={8}>
                            <Checkbox onChange={(e) => {
                              let check = e.target.checked;
                              let valores = form.getFieldsValue([
                                "MantenedorUsuarios",
                                "MantenedorPerfilesUsuario",
                                "MantenedorAccesosSistema",
                                "MantenedorSucursales",
                                "MantenedorCentroDeCostos",
                                "MantenedorRegimenDeTrabajo",
                                "MantenedorFeriados",
                                "MantenedorTitulos",
                                "MantenedorEspecialidades",
                                "MantenedorCargos",
                                "MantenedorEpp",
                                "MantenedorDocumentos"
                              ]);

                              if (check) {

                                if (valores.MantenedorUsuarios !== undefined && valores.MantenedorUsuarios !== "undefined") {
                                  let nuevoValor = valores.MantenedorUsuarios.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.MantenedorUsuarios = nuevoValor;
                                } else {
                                  valores.MantenedorUsuarios = ["Ver"];
                                }

                                if (valores.MantenedorPerfilesUsuario !== undefined && valores.MantenedorPerfilesUsuario !== "undefined") {
                                  let nuevoValor = valores.MantenedorPerfilesUsuario.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.MantenedorPerfilesUsuario = nuevoValor;
                                } else {
                                  valores.MantenedorPerfilesUsuario = ["Ver"];
                                }

                                if (valores.MantenedorAccesosSistema !== undefined && valores.MantenedorAccesosSistema !== "undefined") {
                                  let nuevoValor = valores.MantenedorAccesosSistema.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.MantenedorAccesosSistema = nuevoValor;
                                } else {
                                  valores.MantenedorAccesosSistema = ["Ver"];
                                }

                                if (valores.MantenedorSucursales !== undefined && valores.MantenedorSucursales !== "undefined") {

                                  let nuevoValor = valores.MantenedorSucursales.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.MantenedorSucursales = nuevoValor;
                                } else {
                                  valores.MantenedorSucursales = ["Ver"];
                                }

                                if (valores.MantenedorCentroDeCostos !== undefined && valores.MantenedorCentroDeCostos !== "undefined") {
                                  let nuevoValor = valores.MantenedorCentroDeCostos.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.MantenedorCentroDeCostos = nuevoValor;
                                } else {
                                  valores.MantenedorCentroDeCostos = ["Ver"];
                                }

                                if (valores.MantenedorRegimenDeTrabajo !== undefined && valores.MantenedorRegimenDeTrabajo !== "undefined") {
                                  let nuevoValor = valores.MantenedorRegimenDeTrabajo.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.MantenedorRegimenDeTrabajo = nuevoValor;
                                } else {
                                  valores.MantenedorRegimenDeTrabajo = ["Ver"];
                                }

                                if (valores.MantenedorFeriados !== undefined && valores.MantenedorFeriados !== "undefined") {
                                  let nuevoValor = valores.MantenedorFeriados.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.MantenedorFeriados = nuevoValor;
                                } else {
                                  valores.MantenedorFeriados = ["Ver"];
                                }

                                if (valores.MantenedorTitulos !== undefined && valores.MantenedorTitulos !== "undefined") {
                                  let nuevoValor = valores.MantenedorTitulos.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.MantenedorTitulos = nuevoValor;
                                } else {
                                  valores.MantenedorTitulos = ["Ver"];
                                }

                                if (valores.MantenedorEspecialidades !== undefined && valores.MantenedorEspecialidades !== "undefined") {
                                  let nuevoValor = valores.MantenedorEspecialidades.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.MantenedorEspecialidades = nuevoValor;
                                } else {
                                  valores.MantenedorEspecialidades = ["Ver"];
                                }

                                if (valores.MantenedorCargos !== undefined && valores.MantenedorCargos !== "undefined") {
                                  let nuevoValor = valores.MantenedorCargos.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.MantenedorCargos = nuevoValor;
                                } else {
                                  valores.MantenedorCargos = ["Ver"];
                                }

                                if (valores.MantenedorEpp !== undefined && valores.MantenedorEpp !== "undefined") {
                                  let nuevoValor = valores.MantenedorEpp.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.MantenedorEpp = nuevoValor;
                                } else {
                                  valores.MantenedorEpp = ["Ver"];
                                }

                                if (valores.MantenedorDocumentos !== undefined && valores.MantenedorDocumentos !== "undefined") {
                                  let nuevoValor = valores.MantenedorDocumentos.filter(valor => valor !== "Ver");
                                  nuevoValor.push("Ver");
                                  valores.MantenedorDocumentos = nuevoValor;
                                } else {
                                  valores.MantenedorDocumentos = ["Ver"];
                                }

                              } else {

                                if (valores.MantenedorUsuarios !== undefined && valores.MantenedorUsuarios !== "undefined") {
                                  let nuevoValor = valores.MantenedorUsuarios.filter(valor => valor !== "Ver");
                                  valores.MantenedorUsuarios = nuevoValor;
                                } else {
                                  valores.MantenedorUsuarios = [];
                                }

                                if (valores.MantenedorPerfilesUsuario !== undefined && valores.MantenedorPerfilesUsuario !== "undefined") {
                                  let nuevoValor = valores.MantenedorPerfilesUsuario.filter(valor => valor !== "Ver");
                                  valores.MantenedorPerfilesUsuario = nuevoValor;
                                } else {
                                  valores.MantenedorPerfilesUsuario = [];
                                }

                                if (valores.MantenedorAccesosSistema !== undefined && valores.MantenedorAccesosSistema !== "undefined") {
                                  let nuevoValor = valores.MantenedorAccesosSistema.filter(valor => valor !== "Ver");
                                  valores.MantenedorAccesosSistema = nuevoValor;
                                } else {
                                  valores.MantenedorAccesosSistema = [];
                                }

                                if (valores.MantenedorSucursales !== undefined && valores.MantenedorSucursales !== "undefined") {
                                  let nuevoValor = valores.MantenedorSucursales.filter(valor => valor !== "Ver");
                                  valores.MantenedorSucursales = nuevoValor;
                                } else {
                                  valores.MantenedorSucursales = [];
                                }

                                if (valores.MantenedorCentroDeCostos !== undefined && valores.MantenedorCentroDeCostos !== "undefined") {
                                  let nuevoValor = valores.MantenedorCentroDeCostos.filter(valor => valor !== "Ver");
                                  valores.MantenedorCentroDeCostos = nuevoValor;
                                } else {
                                  valores.MantenedorCentroDeCostos = [];
                                }

                                if (valores.MantenedorRegimenDeTrabajo !== undefined && valores.MantenedorRegimenDeTrabajo !== "undefined") {
                                  let nuevoValor = valores.MantenedorRegimenDeTrabajo.filter(valor => valor !== "Ver");
                                  valores.MantenedorRegimenDeTrabajo = nuevoValor;
                                } else {
                                  valores.MantenedorRegimenDeTrabajo = [];
                                }

                                if (valores.MantenedorFeriados !== undefined && valores.MantenedorFeriados !== "undefined") {
                                  let nuevoValor = valores.MantenedorFeriados.filter(valor => valor !== "Ver");
                                  valores.MantenedorFeriados = nuevoValor;
                                } else {
                                  valores.MantenedorFeriados = [];
                                }

                                if (valores.MantenedorTitulos !== undefined && valores.MantenedorTitulos !== "undefined") {
                                  let nuevoValor = valores.MantenedorTitulos.filter(valor => valor !== "Ver");
                                  valores.MantenedorTitulos = nuevoValor;
                                } else {
                                  valores.MantenedorTitulos = [];
                                }

                                if (valores.MantenedorEspecialidades !== undefined && valores.MantenedorEspecialidades !== "undefined") {
                                  let nuevoValor = valores.MantenedorEspecialidades.filter(valor => valor !== "Ver");
                                  valores.MantenedorEspecialidades = nuevoValor;
                                } else {
                                  valores.MantenedorEspecialidades = [];
                                }

                                if (valores.MantenedorCargos !== undefined && valores.MantenedorCargos !== "undefined") {
                                  let nuevoValor = valores.MantenedorCargos.filter(valor => valor !== "Ver");
                                  valores.MantenedorCargos = nuevoValor;
                                } else {
                                  valores.MantenedorCargos = [];
                                }

                                if (valores.MantenedorEpp !== undefined && valores.MantenedorEpp !== "undefined") {
                                  let nuevoValor = valores.MantenedorEpp.filter(valor => valor !== "Ver");
                                  valores.MantenedorEpp = nuevoValor;
                                } else {
                                  valores.MantenedorEpp = [];
                                }

                                if (valores.MantenedorDocumentos !== undefined && valores.MantenedorDocumentos !== "undefined") {
                                  let nuevoValor = valores.MantenedorDocumentos.filter(valor => valor !== "Ver");
                                  valores.MantenedorDocumentos = nuevoValor;
                                } else {
                                  valores.MantenedorDocumentos = [];
                                }

                              }

                              form.setFieldsValue({
                                MantenedorUsuarios: valores.MantenedorUsuarios,
                                MantenedorPerfilesUsuario: valores.MantenedorPerfilesUsuario,
                                MantenedorAccesosSistema: valores.MantenedorAccesosSistema,
                                MantenedorSucursales: valores.MantenedorSucursales,
                                MantenedorCentroDeCostos: valores.MantenedorCentroDeCostos,
                                MantenedorRegimenDeTrabajo: valores.MantenedorRegimenDeTrabajo,
                                MantenedorFeriados: valores.MantenedorFeriados,
                                MantenedorTitulos: valores.MantenedorTitulos,
                                MantenedorEspecialidades: valores.MantenedorEspecialidades,
                                MantenedorCargos: valores.MantenedorCargos,
                                MantenedorEpp: valores.MantenedorEpp,
                                MantenedorDocumentos: valores.MantenedorDocumentos,
                              });


                            }}></Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox onChange={(e) => {
                              let check = e.target.checked;
                              let valores = form.getFieldsValue([
                                "MantenedorUsuarios",
                                "MantenedorPerfilesUsuario",
                                "MantenedorAccesosSistema",
                                "MantenedorSucursales",
                                "MantenedorCentroDeCostos",
                                "MantenedorRegimenDeTrabajo",
                                "MantenedorFeriados",
                                "MantenedorTitulos",
                                "MantenedorEspecialidades",
                                "MantenedorCargos",
                                "MantenedorEpp",
                                "MantenedorDocumentos"
                              ]);

                              if (check) {

                                if (valores.MantenedorUsuarios !== undefined && valores.MantenedorUsuarios !== "undefined") {
                                  let nuevoValor = valores.MantenedorUsuarios.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.MantenedorUsuarios = nuevoValor;
                                } else {
                                  valores.MantenedorUsuarios = ["Editar"];
                                }

                                if (valores.MantenedorPerfilesUsuario !== undefined && valores.MantenedorPerfilesUsuario !== "undefined") {
                                  let nuevoValor = valores.MantenedorPerfilesUsuario.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.MantenedorPerfilesUsuario = nuevoValor;
                                } else {
                                  valores.MantenedorPerfilesUsuario = ["Editar"];
                                }

                                if (valores.MantenedorAccesosSistema !== undefined && valores.MantenedorAccesosSistema !== "undefined") {
                                  let nuevoValor = valores.MantenedorAccesosSistema.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.MantenedorAccesosSistema = nuevoValor;
                                } else {
                                  valores.MantenedorAccesosSistema = ["Editar"];
                                }

                                if (valores.MantenedorSucursales !== undefined && valores.MantenedorSucursales !== "undefined") {
                                  let nuevoValor = valores.MantenedorSucursales.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.MantenedorSucursales = nuevoValor;
                                } else {
                                  valores.MantenedorSucursales = ["Editar"];
                                }

                                if (valores.MantenedorCentroDeCostos !== undefined && valores.MantenedorCentroDeCostos !== "undefined") {
                                  let nuevoValor = valores.MantenedorCentroDeCostos.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.MantenedorCentroDeCostos = nuevoValor;
                                } else {
                                  valores.MantenedorCentroDeCostos = ["Editar"];
                                }

                                if (valores.MantenedorRegimenDeTrabajo !== undefined && valores.MantenedorRegimenDeTrabajo !== "undefined") {
                                  let nuevoValor = valores.MantenedorRegimenDeTrabajo.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.MantenedorRegimenDeTrabajo = nuevoValor;
                                } else {
                                  valores.MantenedorRegimenDeTrabajo = ["Editar"];
                                }

                                if (valores.MantenedorFeriados !== undefined && valores.MantenedorFeriados !== "undefined") {
                                  let nuevoValor = valores.MantenedorFeriados.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.MantenedorFeriados = nuevoValor;
                                } else {
                                  valores.MantenedorFeriados = ["Editar"];
                                }

                                if (valores.MantenedorTitulos !== undefined && valores.MantenedorTitulos !== "undefined") {
                                  let nuevoValor = valores.MantenedorTitulos.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.MantenedorTitulos = nuevoValor;
                                } else {
                                  valores.MantenedorTitulos = ["Editar"];
                                }

                                if (valores.MantenedorEspecialidades !== undefined && valores.MantenedorEspecialidades !== "undefined") {
                                  let nuevoValor = valores.MantenedorEspecialidades.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.MantenedorEspecialidades = nuevoValor;
                                } else {
                                  valores.MantenedorEspecialidades = ["Editar"];
                                }

                                if (valores.MantenedorCargos !== undefined && valores.MantenedorCargos !== "undefined") {
                                  let nuevoValor = valores.MantenedorCargos.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.MantenedorCargos = nuevoValor;
                                } else {
                                  valores.MantenedorCargos = ["Editar"];
                                }

                                if (valores.MantenedorEpp !== undefined && valores.MantenedorEpp !== "undefined") {
                                  let nuevoValor = valores.MantenedorEpp.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.MantenedorEpp = nuevoValor;
                                } else {
                                  valores.MantenedorEpp = ["Editar"];
                                }

                                if (valores.MantenedorDocumentos !== undefined && valores.MantenedorDocumentos !== "undefined") {
                                  let nuevoValor = valores.MantenedorDocumentos.filter(valor => valor !== "Editar");
                                  nuevoValor.push("Editar");
                                  valores.MantenedorDocumentos = nuevoValor;
                                } else {
                                  valores.MantenedorDocumentos = ["Editar"];
                                }

                              } else {

                                if (valores.MantenedorUsuarios !== undefined && valores.MantenedorUsuarios !== "undefined") {
                                  let nuevoValor = valores.MantenedorUsuarios.filter(valor => valor !== "Editar");
                                  valores.MantenedorUsuarios = nuevoValor;
                                } else {
                                  valores.MantenedorUsuarios = [];
                                }

                                if (valores.MantenedorPerfilesUsuario !== undefined && valores.MantenedorPerfilesUsuario !== "undefined") {
                                  let nuevoValor = valores.MantenedorPerfilesUsuario.filter(valor => valor !== "Editar");
                                  valores.MantenedorPerfilesUsuario = nuevoValor;
                                } else {
                                  valores.MantenedorPerfilesUsuario = [];
                                }

                                if (valores.MantenedorAccesosSistema !== undefined && valores.MantenedorAccesosSistema !== "undefined") {
                                  let nuevoValor = valores.MantenedorAccesosSistema.filter(valor => valor !== "Editar");
                                  valores.MantenedorAccesosSistema = nuevoValor;
                                } else {
                                  valores.MantenedorAccesosSistema = [];
                                }

                                if (valores.MantenedorSucursales !== undefined && valores.MantenedorSucursales !== "undefined") {
                                  let nuevoValor = valores.MantenedorSucursales.filter(valor => valor !== "Editar");
                                  valores.MantenedorSucursales = nuevoValor;
                                } else {
                                  valores.MantenedorSucursales = [];
                                }

                                if (valores.MantenedorCentroDeCostos !== undefined && valores.MantenedorCentroDeCostos !== "undefined") {
                                  let nuevoValor = valores.MantenedorCentroDeCostos.filter(valor => valor !== "Editar");
                                  valores.MantenedorCentroDeCostos = nuevoValor;
                                } else {
                                  valores.MantenedorCentroDeCostos = [];
                                }

                                if (valores.MantenedorRegimenDeTrabajo !== undefined && valores.MantenedorRegimenDeTrabajo !== "undefined") {
                                  let nuevoValor = valores.MantenedorRegimenDeTrabajo.filter(valor => valor !== "Editar");
                                  valores.MantenedorRegimenDeTrabajo = nuevoValor;
                                } else {
                                  valores.MantenedorRegimenDeTrabajo = [];
                                }

                                if (valores.MantenedorFeriados !== undefined && valores.MantenedorFeriados !== "undefined") {
                                  let nuevoValor = valores.MantenedorFeriados.filter(valor => valor !== "Editar");
                                  valores.MantenedorFeriados = nuevoValor;
                                } else {
                                  valores.MantenedorFeriados = [];
                                }

                                if (valores.MantenedorTitulos !== undefined && valores.MantenedorTitulos !== "undefined") {
                                  let nuevoValor = valores.MantenedorTitulos.filter(valor => valor !== "Editar");
                                  valores.MantenedorTitulos = nuevoValor;
                                } else {
                                  valores.MantenedorTitulos = [];
                                }

                                if (valores.MantenedorEspecialidades !== undefined && valores.MantenedorEspecialidades !== "undefined") {
                                  let nuevoValor = valores.MantenedorEspecialidades.filter(valor => valor !== "Editar");
                                  valores.MantenedorEspecialidades = nuevoValor;
                                } else {
                                  valores.MantenedorEspecialidades = [];
                                }

                                if (valores.MantenedorCargos !== undefined && valores.MantenedorCargos !== "undefined") {
                                  let nuevoValor = valores.MantenedorCargos.filter(valor => valor !== "Editar");
                                  valores.MantenedorCargos = nuevoValor;
                                } else {
                                  valores.MantenedorCargos = [];
                                }

                                if (valores.MantenedorEpp !== undefined && valores.MantenedorEpp !== "undefined") {
                                  let nuevoValor = valores.MantenedorEpp.filter(valor => valor !== "Editar");
                                  valores.MantenedorEpp = nuevoValor;
                                } else {
                                  valores.MantenedorEpp = [];
                                }

                                if (valores.MantenedorDocumentos !== undefined && valores.MantenedorDocumentos !== "undefined") {
                                  let nuevoValor = valores.MantenedorDocumentos.filter(valor => valor !== "Editar");
                                  valores.MantenedorDocumentos = nuevoValor;
                                } else {
                                  valores.MantenedorDocumentos = [];
                                }

                              }

                              form.setFieldsValue({
                                MantenedorUsuarios: valores.MantenedorUsuarios,
                                MantenedorPerfilesUsuario: valores.MantenedorPerfilesUsuario,
                                MantenedorAccesosSistema: valores.MantenedorAccesosSistema,
                                MantenedorSucursales: valores.MantenedorSucursales,
                                MantenedorCentroDeCostos: valores.MantenedorCentroDeCostos,
                                MantenedorRegimenDeTrabajo: valores.MantenedorRegimenDeTrabajo,
                                MantenedorFeriados: valores.MantenedorFeriados,
                                MantenedorTitulos: valores.MantenedorTitulos,
                                MantenedorEspecialidades: valores.MantenedorEspecialidades,
                                MantenedorCargos: valores.MantenedorCargos,
                                MantenedorEpp: valores.MantenedorEpp,
                                MantenedorDocumentos: valores.MantenedorDocumentos,
                              });


                            }}></Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox onChange={(e) => {
                              let check = e.target.checked;
                              let valores = form.getFieldsValue([
                                "MantenedorUsuarios",
                                "MantenedorPerfilesUsuario",
                                "MantenedorAccesosSistema",
                                "MantenedorSucursales",
                                "MantenedorCentroDeCostos",
                                "MantenedorRegimenDeTrabajo",
                                "MantenedorFeriados",
                                "MantenedorTitulos",
                                "MantenedorEspecialidades",
                                "MantenedorCargos",
                                "MantenedorEpp",
                                "MantenedorDocumentos"
                              ]);

                              if (check) {

                                if (valores.MantenedorUsuarios !== undefined && valores.MantenedorUsuarios !== "undefined") {
                                  let nuevoValor = valores.MantenedorUsuarios.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.MantenedorUsuarios = nuevoValor;
                                } else {
                                  valores.MantenedorUsuarios = ["Eliminar"];
                                }

                                if (valores.MantenedorPerfilesUsuario !== undefined && valores.MantenedorPerfilesUsuario !== "undefined") {
                                  let nuevoValor = valores.MantenedorPerfilesUsuario.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.MantenedorPerfilesUsuario = nuevoValor;
                                } else {
                                  valores.MantenedorPerfilesUsuario = ["Eliminar"];
                                }

                                if (valores.MantenedorAccesosSistema !== undefined && valores.MantenedorAccesosSistema !== "undefined") {
                                  let nuevoValor = valores.MantenedorAccesosSistema.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.MantenedorAccesosSistema = nuevoValor;
                                } else {
                                  valores.MantenedorAccesosSistema = ["Eliminar"];
                                }

                                if (valores.MantenedorSucursales !== undefined && valores.MantenedorSucursales !== "undefined") {
                                  let nuevoValor = valores.MantenedorSucursales.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.MantenedorSucursales = nuevoValor;
                                } else {
                                  valores.MantenedorSucursales = ["Eliminar"];
                                }

                                if (valores.MantenedorCentroDeCostos !== undefined && valores.MantenedorCentroDeCostos !== "undefined") {
                                  let nuevoValor = valores.MantenedorCentroDeCostos.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.MantenedorCentroDeCostos = nuevoValor;
                                } else {
                                  valores.MantenedorCentroDeCostos = ["Eliminar"];
                                }

                                if (valores.MantenedorRegimenDeTrabajo !== undefined && valores.MantenedorRegimenDeTrabajo !== "undefined") {
                                  let nuevoValor = valores.MantenedorRegimenDeTrabajo.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.MantenedorRegimenDeTrabajo = nuevoValor;
                                } else {
                                  valores.MantenedorRegimenDeTrabajo = ["Eliminar"];
                                }

                                if (valores.MantenedorFeriados !== undefined && valores.MantenedorFeriados !== "undefined") {
                                  let nuevoValor = valores.MantenedorFeriados.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.MantenedorFeriados = nuevoValor;
                                } else {
                                  valores.MantenedorFeriados = ["Eliminar"];
                                }

                                if (valores.MantenedorTitulos !== undefined && valores.MantenedorTitulos !== "undefined") {
                                  let nuevoValor = valores.MantenedorTitulos.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.MantenedorTitulos = nuevoValor;
                                } else {
                                  valores.MantenedorTitulos = ["Eliminar"];
                                }

                                if (valores.MantenedorEspecialidades !== undefined && valores.MantenedorEspecialidades !== "undefined") {
                                  let nuevoValor = valores.MantenedorEspecialidades.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.MantenedorEspecialidades = nuevoValor;
                                } else {
                                  valores.MantenedorEspecialidades = ["Eliminar"];
                                }

                                if (valores.MantenedorCargos !== undefined && valores.MantenedorCargos !== "undefined") {
                                  let nuevoValor = valores.MantenedorCargos.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.MantenedorCargos = nuevoValor;
                                } else {
                                  valores.MantenedorCargos = ["Eliminar"];
                                }

                                if (valores.MantenedorEpp !== undefined && valores.MantenedorEpp !== "undefined") {
                                  let nuevoValor = valores.MantenedorEpp.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.MantenedorEpp = nuevoValor;
                                } else {
                                  valores.MantenedorEpp = ["Eliminar"];
                                }

                                if (valores.MantenedorDocumentos !== undefined && valores.MantenedorDocumentos !== "undefined") {
                                  let nuevoValor = valores.MantenedorDocumentos.filter(valor => valor !== "Eliminar");
                                  nuevoValor.push("Eliminar");
                                  valores.MantenedorDocumentos = nuevoValor;
                                } else {
                                  valores.MantenedorDocumentos = ["Eliminar"];
                                }

                              } else {

                                if (valores.MantenedorUsuarios !== undefined && valores.MantenedorUsuarios !== "undefined") {
                                  let nuevoValor = valores.MantenedorUsuarios.filter(valor => valor !== "Eliminar");
                                  valores.MantenedorUsuarios = nuevoValor;
                                } else {
                                  valores.MantenedorUsuarios = [];
                                }

                                if (valores.MantenedorPerfilesUsuario !== undefined && valores.MantenedorPerfilesUsuario !== "undefined") {
                                  let nuevoValor = valores.MantenedorPerfilesUsuario.filter(valor => valor !== "Eliminar");
                                  valores.MantenedorPerfilesUsuario = nuevoValor;
                                } else {
                                  valores.MantenedorPerfilesUsuario = [];
                                }

                                if (valores.MantenedorAccesosSistema !== undefined && valores.MantenedorAccesosSistema !== "undefined") {
                                  let nuevoValor = valores.MantenedorAccesosSistema.filter(valor => valor !== "Eliminar");
                                  valores.MantenedorAccesosSistema = nuevoValor;
                                } else {
                                  valores.MantenedorAccesosSistema = [];
                                }

                                if (valores.MantenedorSucursales !== undefined && valores.MantenedorSucursales !== "undefined") {
                                  let nuevoValor = valores.MantenedorSucursales.filter(valor => valor !== "Eliminar");
                                  valores.MantenedorSucursales = nuevoValor;
                                } else {
                                  valores.MantenedorSucursales = [];
                                }

                                if (valores.MantenedorCentroDeCostos !== undefined && valores.MantenedorCentroDeCostos !== "undefined") {
                                  let nuevoValor = valores.MantenedorCentroDeCostos.filter(valor => valor !== "Eliminar");
                                  valores.MantenedorCentroDeCostos = nuevoValor;
                                } else {
                                  valores.MantenedorCentroDeCostos = [];
                                }

                                if (valores.MantenedorRegimenDeTrabajo !== undefined && valores.MantenedorRegimenDeTrabajo !== "undefined") {
                                  let nuevoValor = valores.MantenedorRegimenDeTrabajo.filter(valor => valor !== "Eliminar");
                                  valores.MantenedorRegimenDeTrabajo = nuevoValor;
                                } else {
                                  valores.MantenedorRegimenDeTrabajo = [];
                                }

                                if (valores.MantenedorFeriados !== undefined && valores.MantenedorFeriados !== "undefined") {
                                  let nuevoValor = valores.MantenedorFeriados.filter(valor => valor !== "Eliminar");
                                  valores.MantenedorFeriados = nuevoValor;
                                } else {
                                  valores.MantenedorFeriados = [];
                                }

                                if (valores.MantenedorTitulos !== undefined && valores.MantenedorTitulos !== "undefined") {
                                  let nuevoValor = valores.MantenedorTitulos.filter(valor => valor !== "Eliminar");
                                  valores.MantenedorTitulos = nuevoValor;
                                } else {
                                  valores.MantenedorTitulos = [];
                                }

                                if (valores.MantenedorEspecialidades !== undefined && valores.MantenedorEspecialidades !== "undefined") {
                                  let nuevoValor = valores.MantenedorEspecialidades.filter(valor => valor !== "Eliminar");
                                  valores.MantenedorEspecialidades = nuevoValor;
                                } else {
                                  valores.MantenedorEspecialidades = [];
                                }

                                if (valores.MantenedorCargos !== undefined && valores.MantenedorCargos !== "undefined") {
                                  let nuevoValor = valores.MantenedorCargos.filter(valor => valor !== "Eliminar");
                                  valores.MantenedorCargos = nuevoValor;
                                } else {
                                  valores.MantenedorCargos = [];
                                }

                                if (valores.MantenedorEpp !== undefined && valores.MantenedorEpp !== "undefined") {
                                  let nuevoValor = valores.MantenedorEpp.filter(valor => valor !== "Eliminar");
                                  valores.MantenedorEpp = nuevoValor;
                                } else {
                                  valores.MantenedorEpp = [];
                                }

                                if (valores.MantenedorDocumentos !== undefined && valores.MantenedorDocumentos !== "undefined") {
                                  let nuevoValor = valores.MantenedorDocumentos.filter(valor => valor !== "Eliminar");
                                  valores.MantenedorDocumentos = nuevoValor;
                                } else {
                                  valores.MantenedorDocumentos = [];
                                }

                              }

                              form.setFieldsValue({
                                MantenedorUsuarios: valores.MantenedorUsuarios,
                                MantenedorPerfilesUsuario: valores.MantenedorPerfilesUsuario,
                                MantenedorAccesosSistema: valores.MantenedorAccesosSistema,
                                MantenedorSucursales: valores.MantenedorSucursales,
                                MantenedorCentroDeCostos: valores.MantenedorCentroDeCostos,
                                MantenedorRegimenDeTrabajo: valores.MantenedorRegimenDeTrabajo,
                                MantenedorFeriados: valores.MantenedorFeriados,
                                MantenedorTitulos: valores.MantenedorTitulos,
                                MantenedorEspecialidades: valores.MantenedorEspecialidades,
                                MantenedorCargos: valores.MantenedorCargos,
                                MantenedorEpp: valores.MantenedorEpp,
                                MantenedorDocumentos: valores.MantenedorDocumentos,
                              });


                            }}></Checkbox>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Usuarios
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"MantenedorUsuarios"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Perfiles de Usuario
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"MantenedorPerfilesUsuario"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Accesos al Sistema
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"MantenedorAccesosSistema"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Sucursales
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"MantenedorSucursales"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Centros de Costo
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"MantenedorCentroDeCostos"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Régimen de Trabajo
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"MantenedorRegimenDeTrabajo"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Feriados
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"MantenedorFeriados"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Títulos
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"MantenedorTitulos"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Especialidades
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"MantenedorEspecialidades"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Cargos
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"MantenedorCargos"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Elementos de Protección
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"MantenedorEpp"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <h4>
                          <img
                            alt="imagen"
                            className="icons-sistema-accesos icon-no-visible"
                            src={iconDashboard}
                          />
                          Documentos
                        </h4>
                      </td>
                      <td>
                        <Form.Item
                          wrapperCol={24}
                          span={24}
                          name={"MantenedorDocumentos"}
                          style={{
                            margin: "0px",
                          }}
                        >
                          <Checkbox.Group>
                            <Row>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  value="Ver"
                                  defaultChecked={false}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Editar"}
                                ></Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  style={{
                                    padding: "5px",
                                  }}
                                  defaultChecked={false}
                                  value={"Eliminar"}
                                ></Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                    </tr>
                  </tbody>

                </table>
              </Col>
            </Row>

          </Card>

        </Col>

        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Button className="guardar-form" type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>

      </Row>
    </Form>
  );
};

export default FormularioPerfilPermisoEditar;
