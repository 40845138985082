export const LOADING = "MANTENEDOR_CENTROCOSTO_LOADING";
// export const PARAR_LOADING = "MANTENEDOR_CENTROCOSTO_LOADING_PARAR";
export const ERROR = "MANTENEDOR_CENTROCOSTO_ERROR";
export const INGRESAR_CENTROCOSTO = "MANTENEDOR_CENTROCOSTO_INGRESAR";
export const LIMPIAR_ERROR_SUCCESS =
  "MANTENEDOR_CENTROCOSTO_LIMPIAR_ERROR_SUCCESS";
export const MOSTRAR_MODAL_CENTROCOSTO = "MANTENEDOR_CENTROCOSTO_MOSTRAR_MODAL";
export const OCULTAR_MODAL_CENTROCOSTO = "MANTENEDOR_CENTROCOSTO_OCULTAR_MODAL";
export const OCULTAR_MODAL_CENTROCOSTO_EDITAR =
  "MANTENEDOR_CENTROCOSTO_OCULTAR_MODAL_EDITAR";
export const OBTENER_CENTROCOSTOS_MANTENEDOR =
  "MANTENEDOR_CENTROCOSTO_OBTENER_CENTROCOSTOS";
export const OBTENER_CENTROCOSTO_MANTENEDOR =
  "MANTENEDOR_CENTROCOSTO_OBTENER_CENTROCOSTO";
export const ELIMINAR_CENTROCOSTO_MANTENEDOR =
  "MANTENEDOR_CENTROCOSTO_ELIMINAR_CENTROCOSTO";
export const EDITAR_CENTROCOSTO_MANTENEDOR =
  "MANTENEDOR_CENTROCOSTO_EDITAR_CENTROCOSTO";
export const TOMAR_VALORES_EDITAR =
  "MANTENEDOR_CENTROCOSTO_TOMAR_VALORES_EDITAR";
export const DESCARGAR_EXCEL = "MANTENEDOR_CENTROCOSTO_DESCARGAR_EXCEL";
export const OBTENER_EMPRESAS_MANTENEDOR =
  "MANTENEDOR_CENTROCOSTO_OBTENER_EMPRESAS";
  export const MANTENEDOR_CENTROCOSTO_OBTENER_CARGOS =
  "MANTENEDOR_CENTROCOSTO_OBTENER_CARGOS";
