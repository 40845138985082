import React, { Component } from "react";

import { connect } from "react-redux";
import * as mantenedorTrabajadorActions from "../../Redux/actions/MantenedorTrabajadorActions";

import API from "../../Redux/Api/api";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
// import FormularioTrabajador from "../../components/formTrabajador/FormularioTrabajador";
// import FormularioTrabajadorEditar from "../../components/formTrabajador/FormularioTrabajadorEditar";
import FormularioTrabajadorNuevo from "../../components/formTrabajador/FormularioTrabajadorNuevo";
import FormularioTrabajadorEditarNuevo from "../../components/formTrabajador/FormularioTrabajadorEditarNuevo";
import Loading from "../../components/loading/Loading";

import { Layout, Table, Space, Button, Row, Col, Input, Modal, Tooltip, Select, Pagination, PageHeader, Divider, Typography,Checkbox  } from "antd";
import { EyeFilled, EditFilled, DeleteFilled, ContactsTwoTone } from "@ant-design/icons";
import imgAlerta from "../../img/icons/alerta-amarilla.svg";
import iconExcel from "../../img/icons/excel.svg";
import iconUsuario from "../../img/icons/usuario.svg";
import iconDocumentoBase from "../../img/icons/documentoBase.svg";
import iconCargaMasiva from "../../img/icons/cargaMasiva.svg";

import Swal from "sweetalert2";
import accesosPagina from "../../funcionesGenericas/accesosUsuario";

import './MantenedorTrabajador.css';

import moment from "moment";
import { convertirFechaString } from "../../funcionesGenericas/fechas"

const { Header, Content } = Layout;
const { Search } = Input;
const { Column } = Table;

class MantenedorTrabajador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tablaCargando: false,
      form: {
        Rut: "",
        Nombre: "",
        Apellido_Paterno: "",
        Apellido_Materno: "",
        Fecha_De_Nacimiento: "",
        Imagen: "",
        Calle: "",
        Numero_Calle: 0,
        Tipo_De_Vivienda: "",
        Region: "",
        Comuna: "",
        Correo: "",
        Nacionalidad: "",
        Sexo: "",
        RegimenDeTrabajo: "",
        Codigo_Base: "",
        Contacto_Nombre: "",
        Contacto_Correo: "",
        Contacto_Fono_Fijo: 0,
        Contacto_Fono_Celular: 0,
        Contacto_Emergencia: [
          {
            Correo: "",
            Fono: 0,
            Nombre: "",
            Calle: "",
            Numero_Calle: 0,
            Region: "",
            Comuna: "",
            Tipo_De_Vivienda: "",
          },
        ],
        Estado_Civil: "",
        Nombre_Conyuge: "",
        Rut_Conyuge: "",
        Cantidad_Hijos: 0,
        Hijos: [
          {
            Nombre: "",
            Apellido_Paterno: "",
            Apellido_Materno: "",
            Rut: "",
            Fecha_De_Nacimiento: "",
            Sexo_Hijo: "",
          },
        ],
        Vigencia: true,

        imagen: null,
        validacionRut: "",

        TituloSeleccionado: "",
        arregloTitulos: [],
        EspecialidadSeleccionado: "",
        arregloEspecialidades: [],

        eppSeleccionado: "",
        arregloEpps: [],
      },
      epps: "",
      size: "large",

      trabajadoresFiltros: null,

      departamento: true,
      editarCerrado: true,
      cargos: [],
      estados: [],
      cargoSeleccionado: null,
      estadoSeleccionado: 'Todos',
      valorSearch: '',

      filtroPActual: 1,
      paginasCC:0,
      pageSizeDefault: 10,
      modalReporteria: false,
      estadoCheck: false
    };

    this.cambiarEstadoVigencia = this.cambiarEstadoVigencia.bind(this);
    this.TomarValoresEditar = this.TomarValoresEditar.bind(this);
    this.EditarTrabajador = this.EditarTrabajador.bind(this);
    this.crearTrabajador = this.crearTrabajador.bind(this);
    this.obtenerImagen = this.obtenerImagen.bind(this);
    this.validacionRut = this.validacionRut.bind(this);
    this.cambiarValores = this.cambiarValores.bind(this);
    this.agregarTitulo = this.agregarTitulo.bind(this);
    this.agregarEspecialidad = this.agregarEspecialidad.bind(this);
    //comunas ciudad provincia
    this.buscarProvincias = this.buscarProvincias.bind(this);
    this.buscarComuna = this.buscarComuna.bind(this);
    this.cambioValorVivienda = this.cambioValorVivienda.bind(this);
  }

  //FUNCIONES NATIVAS DE REACT
  componentWillMount = async () => {
    const accesos = await accesosPagina.obtenerAccesosPagina(
      "MantenedorTrabajadores",
      "MantenedorFichaTrabajador"
    );

    this.setState({
      Ver: accesos.Ver,
      Editar: accesos.Editar,
      Eliminar: accesos.Eliminar,
    });
  };

  async componentDidMount() {

    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {

      const {paginasCC, pageSizeDefault} = this.state;

      usuario = JSON.parse(usuario);

      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      await this.props.ObtenerTrabajadores('Todos', null, null, pageSizeDefault, paginasCC);

      const cargos = await this.props.ObtenerCargosTrabajador(idEmpresa);
      const estadosObtenidos = await this.props.ObtenerEstadosTrabajador(idEmpresa);
      const estados = estadosObtenidos.filter((estado) => estado._id !== '5f904101d9c57145a09eb058');

      //OBTENER PARA REGIONES COMUNAS Y PROVINCIAS
      await this.props.ObtenerRegiones();
      await this.props.ObtenerProvincias();
      await this.props.ObtenerComunas();

      await this.props.ObtenerTitulos();
      await this.props.ObtenerEspecialidades();
      await this.props.ObtenerEPPs();
      await this.props.ObtenerRegimenes();
      await this.props.ObtenerDocumentos();

      this.setState({
        cargos: cargos ? cargos : [],
        estados: estados ? estados : [],
      })
    }
  }

  //Descargable Excel
  descargarExcel = async (
    informacionDescargar,subtitulo
   ) => {
    try {
      // let datosNecesarios = [];

      // this.props.trabajadores.forEach(tra => {
      //   // let cargoNombre = tra.Cargo.Nombre && tra.Cargo.Nombre === undefined? "No tiene cargo":tra.Cargo.Nombre;
      //   // let estado =tra.estadoActual.estado === undefined? "No tiene estado":tra.estadoActual.estado;

      //    let cargoNombre = tra.Cargo ? tra.Cargo.Nombre:"N/A";
      //    let phone = tra.Telefono ? tra.Telefono:"N/A";


      //   datosNecesarios.push({
      //     nombre_completo: tra.nombre_completo,
      //     Rut:	tra.Rut,
      //     Cargo:	cargoNombre,
      //     Telefono: phone,
      //     estadoActual:	tra.estadoActual.estado,
      //   })
      // });

      const { estadoSeleccionado, cargoSeleccionado } = this.state;

      let usuario = localStorage.getItem("usuario-people-manage");
      if (
        usuario !== null &&
        usuario !== "" &&
        usuario !== undefined &&
        usuario !== "undefined"
      ) {

        usuario = JSON.parse(usuario);
        const empresa = usuario.EmpresaSeleccionada.Empresa._id;
        let str = moment().format('YYYY-MM-DD');
        let dateIn = convertirFechaString(moment(str), 1).fechaString;
        let dateOut = convertirFechaString(moment(str), 2).fechaString;

        const url = estadoSeleccionado !== 'Finiquitado' ? `${API}/MantenedorTrabajadores/DescargarExcel` : `${API}/MantenedorTrabajadoresFiniquitados/DescargarExcel`;

        let objeto = {
          dateIn: dateIn,
          dateOut: dateOut,
          empresa: empresa,
          estado: estadoSeleccionado,
          cargo: cargoSeleccionado,
          subtitulo:"Informe",
        };

        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objeto),
        });

        const status = await response.status;
        const excel = await response.blob();

        if (status === 200 || status === 201) {
          const url = window.URL.createObjectURL(excel);
          const a = document.createElement("a");
          a.href = url;
          a.download = `TrabajadoresSistema.xlsx`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
        }

      }

    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
   };
  //Fin descargable excel

  descargarExcelTitulosyEspecialidades = async () => {
    try {
      const { estadoSeleccionado, cargoSeleccionado } = this.state;

      let usuario = localStorage.getItem("usuario-people-manage");
      if (
        usuario !== null &&
        usuario !== "" &&
        usuario !== undefined &&
        usuario !== "undefined"
      ) {

        usuario = JSON.parse(usuario);
        const empresa = usuario.EmpresaSeleccionada.Empresa._id;
        let str = moment().format('YYYY-MM-DD');
        let dateIn = convertirFechaString(moment(str), 1).fechaString;
        let dateOut = convertirFechaString(moment(str), 2).fechaString;

        const url = estadoSeleccionado !== 'Finiquitado' ? `${API}/MantenedorTrabajadores/DescargarExcelTitulosyEspecialidades` : `${API}/MantenedorTrabajadoresFiniquitados/DescargarExcelTitulosyEspecialidades`;

        let objeto = {
          dateIn: dateIn,
          dateOut: dateOut,
          empresa: empresa,
          estado: estadoSeleccionado,
          cargo: cargoSeleccionado,
          subtitulo:"Informe",
        };

        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objeto),
        });

        const status = await response.status;
        const excel = await response.blob();

        if (status === 200 || status === 201) {
          const url = window.URL.createObjectURL(excel);
          const a = document.createElement("a");
          a.href = url;
          a.download = `TrabajadoresSistema.xlsx`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
        }

      }

    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
   };

   descargarExcelDocumentos = async () => {
    try {
      const { estadoSeleccionado, cargoSeleccionado } = this.state;

      let usuario = localStorage.getItem("usuario-people-manage");
      if (
        usuario !== null &&
        usuario !== "" &&
        usuario !== undefined &&
        usuario !== "undefined"
      ) {

        usuario = JSON.parse(usuario);
        const empresa = usuario.EmpresaSeleccionada.Empresa._id;
        let str = moment().format('YYYY-MM-DD');
        let dateIn = convertirFechaString(moment(str), 1).fechaString;
        let dateOut = convertirFechaString(moment(str), 2).fechaString;

        console.log("estadoSeleccionado");
        console.log(estadoSeleccionado);

        const url = estadoSeleccionado !== 'Finiquitado' ? `${API}/MantenedorTrabajadores/DescargarExcelDocumentos` : `${API}/MantenedorTrabajadoresFiniquitados/DescargarExcelDocumentos`;

        let objeto = {
          dateIn: dateIn,
          dateOut: dateOut,
          empresa: empresa,
          estado: estadoSeleccionado,
          cargo: cargoSeleccionado,
          subtitulo:"Informe",
        };

        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objeto),
        });

        const status = await response.status;
        const excel = await response.blob();

        if (status === 200 || status === 201) {
          const url = window.URL.createObjectURL(excel);
          const a = document.createElement("a");
          a.href = url;
          a.download = `TrabajadoresSistemaDocumentos.xlsx`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
        }

      }

    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
   };

  //funciones para buscar select anidados de ciudades y provincias
  buscarProvincias = (idRegion) => {
    let provincias = this.props.ciudades;
    let nuevasProvincias = provincias.filter(
      (provincia) => provincia.Region === idRegion
    );
    this.props.InsertarProvincias(nuevasProvincias);
  };

  buscarComuna = (idProvincia) => {
    let comunas = this.props.comunas;
    let nuevasComunas = comunas.filter(
      (comuna) => comuna.Provincia === idProvincia
    );
    this.props.InsertarComunas(nuevasComunas);
  };

  agregarTitulo = () => {
    let titulos = this.state.form.arregloTitulos;
    let valorSeleccionado = this.state.form.TituloSeleccionado;
    let nombre = "";

    this.props.titulos.forEach((element) => {
      if (element._id === valorSeleccionado) {
        nombre = element.Nombre;
      }
    });

    let objeto = {
      Titulo: valorSeleccionado,
      URL: "AAAAAAAAAA",
      Nombre: nombre,
    };

    titulos.push(objeto);
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        arregloTitulos: titulos,
      },
    });
  };

  agregarEspecialidad = () => {
    let especialidades = this.state.form.arregloEspecialidades;
    let valorSeleccionado = this.state.form.EspecialidadSeleccionado;
    let nombre = "";

    this.props.especialidades.forEach((element) => {
      if (element._id === valorSeleccionado) {
        nombre = element.Nombre;
      }
    });

    let objeto = {
      Especialidad: this.state.form.EspecialidadSeleccionado,
      URL: "AAAAAAAAAA",
      Nombre: nombre,
    };

    especialidades.push(objeto);
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        arregloEspecialidades: especialidades,
      },
    });
  };

  cambiarValores = (nombre, valor) => {
    this.setState({
      form: {
        ...this.state.form,
        [nombre]: valor,
      },
    });
  };

  mostrarModalCrear = () => {
    this.props.MostrarModalTrabajador();
  };

  modalCrearCancelar = () => {
    this.props.OcultarModalTrabajador();
  };

  modalEditarCancelar = () => {
    this.setState({ editarCerrado: true });
    this.props.OcultarModalTrabajadorEditar();
  };

  cambiarEditarCerrado = () => {
    this.setState({ editarCerrado: false });
  };

  //funcion para obtener el cambio del valor del switch
  cambiarEstadoVigencia = () => {
    this.setState({
      form: {
        ...this.state.form,
        Vigencia: this.state.form.Vigencia === true ? false : true,
      },
    });
  };

  //FUNCIONES PARA EJECUTAR APIS
  crearTrabajador = async (trabajador) => {
    trabajador.Vigencia = this.state.form.Vigencia;
    await this.props.CrearTrabajador(trabajador);
  };

  eliminarTrabajador = async (idTrabajador) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminará el Trabajador",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        await this.props.EliminarTrabajador(idTrabajador);
        await this.props.ObtenerTrabajadores('Todos', null, null, 10, 0);
        // this.props.ObtenerTrabajadores();
      }
    });
  };

  ObtenerTrabajador = async (idTrabajador) => {
    await this.props.ObtenerTrabajador(idTrabajador);
  };

  EditarTrabajador = async (Trabajador) => {
    Trabajador._id = this.props.trabajador._id;
    Trabajador.Vigencia = this.props.trabajador.Vigencia;
    await this.props.EditarTrabajador(Trabajador);
    await this.props.ObtenerTrabajadores('Todos', null, null, 10, 0);
    // await this.props.ObtenerTrabajadores();
    this.forceUpdate();
  };

  TomarValoresEditar = (nombreCampo, valor) => {
    this.props.TomarValoresEditar(nombreCampo, valor);
  };

  modalTrabajadorCancelar = () => {
    this.props.OcultarModalTrabajador();
  };

  validacionRut = (EstadoValidacion) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        validacionRut: EstadoValidacion,
      },
    });
  };

  NuevaPestaña = (idUsuario) => {
    //window.open("/CalendarioTrabajador?Trabajador=" + idUsuario);
    window.open("/CalendarioTrabajadorNew?Trabajador=" + idUsuario);
  };

  //funcion para obtener y guardar la imagen
  obtenerImagen = (imagenNueva) => {
    this.setState({
      form: {
        ...this.state.form,
        imagen: imagenNueva,
      },
    });
  };

  //cambia el valor del formulario departamento

  cambioValorVivienda = (valor) => {
    this.setState({
      departamento: valor === "Departamento" ? false : true,
    });
  };

  validarRutUsuario = async (rutUsuario) => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      let trabajador = {
        Rut: rutUsuario,
        Empresa: idEmpresa,
      };

      await this.props.validarRutUsuarioSistema(trabajador);
    }
  };

  subirExcel = async (idEmpresa, archivo, objFecha) => {
    try {
      let formData = new FormData();
      formData.append("archivo", archivo);
      formData.append("body", JSON.stringify(objFecha));

      const url = `${API}/Trabajadores/CargaMasiva/Excel/${idEmpresa}`;

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        body: formData,
      });

      const status = await response.status;
      const { err, data, url: urlArchivo } = await response.json();

      let respuesta;
      if (status === 200 || status === 201) {
        respuesta = {
          status: status,
          data: data,
          err: err,
          urlArchivo,
        };
      } else {
        respuesta = {
          status: status,
          data: data,
          err: err,
          urlArchivo,
        };
      }

      return respuesta;
    } catch (error) {
      let respuesta;
      respuesta = {
        status: 500,
        data: error,
      };
      return respuesta;
    }
  };

  procesarSubirExcel = async (idEmpresa, archivo) => {
    try {

      let month = this.state.month;
      let year = this.state.year;
      let objFecha = {
        Mes: month,
        Ano: year,
        Fecha: this.state.fechaSeleccionada,
      };

      let respuestaExcel = await this.subirExcel(idEmpresa, archivo, objFecha);

      //this.desactivarLoading();

      if (respuestaExcel.status === 200 || respuestaExcel.status === 201) {
        Swal.fire({
          title: "Bien!",
          text: "Se ingresó el excel correctamente",
          icon: "success",
        }).then((value) => {
          window.location.reload();
        });
      } else if (respuestaExcel.status === 409) {
        Swal.fire({
          title: "Uups",
          text: "Error en el excel. Descargar para ver detalle",
          icon: "warning",
          confirmButtonText: "Ok, Descargar",
          confirmButtonColor: "#37a537",
          showCancelButton: true,
          cancelButtonText: "Cerrar",
        }).then((result) => {
          if (result.isConfirmed) {
            var win = window.open(respuestaExcel.urlArchivo, "_blank");
            win.focus();
          }
        });
      } else {
        Swal.fire({
          title: "Uups",
          text:
            respuestaExcel.data.message ||
            "Ocurrió un error al subir el archivo excel, intenta nuevamente.",
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Uups",
        text: "Error no controlado, Error: " + error,
        icon: "error",
      });
    }
  };

  obtenerColorEstado = (estado) => {

    const estadoDescanso = "5f3eeb78978b284b68ebced4";
    const estadoVacaciones = "5f3eebac978b284b68ebced5";
    const estadoLicencia = "5f3eebf46dd7cc57f0d3a25d";
    const estadoLicenciaMutual = "5f3eec676dd7cc57f0d3a25e";
    const estadoPermiso = "5f3eec876dd7cc57f0d3a25f";
    const estadoAusente = "5f3eeca16dd7cc57f0d3a260";
    const estadoTrabajoEfectivo = "5f3eedf56dd7cc57f0d3a261";
    const estadoEnTransito = "5f8f369e8efe155a48df37fb";
    const estadoNoAsingado = "5f904101d9c57145a09eb058";
    
    switch (estado.tipoBitacora) {

      // Efectivo
      case estadoTrabajoEfectivo:
        return "#52D68F";

      // Descanso
      case estadoDescanso:
        return "#F296F5"; 

      // Vacaciones
      case estadoVacaciones:
        return "#3CADF0";

      // En Tránsito
      case estadoEnTransito:
        return "#7c8dfc";

      // Licencia
      case estadoLicencia:
        return "#5C8CDF";

      // Mutual
      case estadoLicenciaMutual:
        return "#EFBF20";

      // Permiso
      case estadoPermiso:
        return "#EF7720";

      // Ausente
      case estadoAusente:
        return "#8B437D";

      case "Finiquitado":
        return "black";

      default:
        return "#E64040";
    }

  }

  // search = (value) => {

  //   let busqueda = value.target.value;

  //   if (value !== "") {

  //     busqueda = busqueda.toLowerCase();
  //     const trabajadores = this.props.trabajadores;

  //     const trabajadoresFiltros = trabajadores.filter((trabajador) => {

  //       // búsqueda en todos los datos accesibles del trabajador
  //       let busquedaGeneral = Object.keys(trabajador).some((k) =>
  //         String(trabajador[k]).toLowerCase().includes(busqueda)
  //       )

  //       // búsqueda por el nombre del cargo, es aparte porque el dato Cargo viene de un populate
  //       let busquedaCargo = false;

  //       if (trabajador.Cargo) {
  //         busquedaCargo = trabajador.Cargo.Nombre.toLowerCase().includes(busqueda);
  //       }

  //       return busquedaGeneral || busquedaCargo;
  //     });

  //     this.setState({
  //       trabajadoresFiltros,
  //       valorSearch: busqueda,
  //       // cargoSeleccionado: null
  //     });

  //   } else {
  //     this.setState({
  //       trabajadoresFiltros: null,
  //       valorSearch: busqueda,
  //       // cargoSeleccionado: null
  //     });
  //   }
  // };

  buscarPorNombreORut = async () => {

    const { cargoSeleccionado, estadoSeleccionado, valorSearch, pageSizeDefault, paginasCC, estadoCheck } = this.state;
    
    if(valorSearch){
      await this.props.ObtenerTrabajadores(estadoSeleccionado, cargoSeleccionado, valorSearch, pageSizeDefault, paginasCC, estadoCheck);
      this.setState({ trabajadoresFiltros: null, estadoSeleccionado });
    }

    if(valorSearch === "" && this.props.trabajadores.length === 0){
      await this.props.ObtenerTrabajadores(estadoSeleccionado, cargoSeleccionado, null, pageSizeDefault, paginasCC, estadoCheck);
      this.setState({ trabajadoresFiltros: null, estadoSeleccionado });
    }

  }

  filtrarPorCargo = async (cargoSeleccionado) => {
    const { estadoSeleccionado, valorSearch, pageSizeDefault, paginasCC } = this.state;

    if (cargoSeleccionado) {
      await this.props.ObtenerTrabajadores(estadoSeleccionado, cargoSeleccionado, valorSearch, pageSizeDefault, paginasCC);
      this.setState({ valorSearch: '', trabajadoresFiltros: null, cargoSeleccionado, filtroPActual: 1, estadoCheck: false   });
    } else {
      await this.props.ObtenerTrabajadores(estadoSeleccionado, null, valorSearch, pageSizeDefault, paginasCC );
      this.setState({ valorSearch: '', trabajadoresFiltros: null, cargoSeleccionado: null, filtroPActual: 1, estadoCheck: false  });
    }
  }

  filtrarPorEstado = async (estadoSeleccionado) => {
    const { cargoSeleccionado, valorSearch, pageSizeDefault, paginasCC, estadoCheck } = this.state;
    
    if (estadoSeleccionado) {
      await this.props.ObtenerTrabajadores(estadoSeleccionado, cargoSeleccionado, valorSearch, pageSizeDefault, paginasCC);
      this.setState({ valorSearch: '', trabajadoresFiltros: null, estadoSeleccionado, filtroPActual: 1, estadoCheck: false });
    }
  }
  filtrarPorCheck = async (estadoSeleccionadoCheck) => {
    const { cargoSeleccionado, valorSearch, pageSizeDefault, paginasCC, estadoCheck } = this.state;
    
    if (estadoSeleccionadoCheck) {
      await this.props.ObtenerTrabajadores('Todos', cargoSeleccionado, valorSearch, pageSizeDefault, paginasCC,estadoSeleccionadoCheck);
      this.setState({ valorSearch: '', trabajadoresFiltros: null, estadoSeleccionado:'Todos', filtroPActual: 1 });
    }else{
      await this.props.ObtenerTrabajadores('Todos', cargoSeleccionado, valorSearch, pageSizeDefault, paginasCC,estadoSeleccionadoCheck);
      this.setState({ valorSearch: '', trabajadoresFiltros: null, estadoSeleccionado:'Todos', filtroPActual: 1 });
    }
  }

  paginationFunction = async (page, limit) => {
    
    const { cargoSeleccionado, estadoSeleccionado, valorSearch,estadoCheck } = this.state;
    const skip = (( limit * page ) - limit);

    await this.props.ObtenerTrabajadores(
      estadoSeleccionado,
      cargoSeleccionado,
      valorSearch,
      limit,
      skip,
      estadoCheck
    );

    this.setState({
      // paginasCC: personas.myCountTrabajadores,
      filtroPActual: page,
      pageSizeDefault: limit
    })

  };

  sortTabla = async (columna) => {
    
    const { cargoSeleccionado, estadoSeleccionado, valorSearch, pageSizeDefault, paginasCC } = this.state;

    if(columna){
      await this.props.ObtenerTrabajadores(estadoSeleccionado, cargoSeleccionado, valorSearch, pageSizeDefault, paginasCC, columna);
    }

  }

  render() {
    const { trabajadoresFiltros, estadoSeleccionado } = this.state;

    let tableData = trabajadoresFiltros === null ? this.props.trabajadores : trabajadoresFiltros;

    return (
      <Layout style={{ height: "100%" }}>
        <Loading cargando={this.props.loading} />

        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo={estadoSeleccionado !== 'Finiquitado' ? 'Trabajadores vigentes del sistema' : 'Trabajadores no vigentes del sistema'}
          // subtitulo={estadoSeleccionado !== 'Finiquitado' ? 'Trabajadores vigentes del sistema' : 'Trabajadores no vigentes del sistema'}
          />
        </Header>

        <hr className="division" />

        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row style={{ padding: "6px 0px 22px" }} align="bottom" gutter={15}>

            <Col className="div-input-search" xs={12} sm={6} md={6} lg={3} xl={3}>
              <Search
                placeholder="Buscar"
                onChange={(value) => this.setState({valorSearch: value.target.value})}
                onBlur={() => this.buscarPorNombreORut()}
                enterButton
                value={this.state.valorSearch}

              />
            </Col>

            <Col className="div-input-search" xs={12} sm={6} md={6} lg={3} xl={3}>

              <Select
                defaultValue={this.state.cargoSeleccionado}
                allowClear={true}
                className="campos-formulario-contratos"
                placeholder="Cargo"
                value={this.state.cargoSeleccionado}
                onChange={(val) => {
                  this.filtrarPorCargo(val);
                }}
              >
                {this.state.cargos.map((cargo, index) => {

                  const { Nombre, _id } = cargo;

                  return (
                    <Select.Option value={_id} key={index} >
                      {Nombre}
                    </Select.Option>
                  )
                })}

              </Select>
            </Col>

            <Col className="div-input-search" xs={12} sm={6} md={6} lg={3} xl={3}>

              <Select
                defaultValue={this.state.estadoSeleccionado}
                className="campos-formulario-contratos"
                placeholder="Estado"
                value={this.state.estadoSeleccionado}
                onChange={(val) => {
                  this.filtrarPorEstado(val);
                }}
              >
                <Select.Option value={'Todos'} key={'Todos'}>Todos</Select.Option>
                <Select.Option value={'No asignado'} key={'No asignado'}>No asignado</Select.Option>
                {this.state.estados.map((estado, index) => {
                  const { Nombre, _id } = estado;
                  return (
                    <Select.Option value={_id} key={index} >{Nombre}</Select.Option>
                  )
                })}
                <Select.Option value={'Finiquitado'} key={'Finiquitado'} style={{ fontFamily: 'MavenPro-SemiBold' }}>Finiquitado</Select.Option>

              </Select>
            </Col>

            {/* <Col style={{ textAlign: "right" }} span={2}></Col> */}
            <Col className="div-input-search" xs={1} sm={1} md={1} lg={1} xl={1}>
              <Checkbox
                checked={this.state.estadoCheck}
                defaultChecked={this.state.estadoCheck}
                onChange={(val)=>{
                  const isChecked = val.target.checked;
                  this.setState({ estadoCheck: isChecked });
                  this.filtrarPorCheck(isChecked);
                }} 
                disabled={this.state.estadoSeleccionado !== "Todos" ? true : false}
              >
                Finiquitados
              </Checkbox>
            </Col>
       
            <Col style={{ textAlign: "right" }} xs={11} sm={17} md={15} lg={14} xl={14}>
              <Button
                className="descargar-excel-trabajador"
                type="primary"
                // size={size}
                style={{ marginRight: "12px" }}
                onClick={() => this.setState({ modalReporteria: true })}
              >
                <div style={{ display:"flex", justifyContent: "center" }}>
                  <Typography style={{ marginRight: "0.5rem", color: "#fff" }}>Reportería</Typography>
                  <img src={iconExcel} alt="Icono de excel" width="20px"/>
                </div>
              </Button>

              <Button
                className={
                  !this.state.Editar ? "guardar-form-trabajador-disabled" : "guardar-form-trabajador"
                }
                disabled={!this.state.Editar}
                type="primary"
                onClick={() => {
                  this.mostrarModalCrear();
                }}
              // size={size}
              >
                <div style={{ display:"flex", justifyContent: "center" }}>
                  <Typography style={{ marginRight: "0.5rem", color: "#fff" }}>Crear Trabajador</Typography>
                  <img src={iconUsuario} alt="Icono de excel" width="20px"/>
                </div>
              </Button>

            </Col>

          </Row>

          <Table
            rowKey="_id"
            dataSource={tableData}
            loading={this.props.tablaCargando}
            bordered={true}
            showSorterTooltip={false}
            scroll={{ x: true }}
            pagination={false}
          >
            <Column
              title="Imagen"
              key="Imagen"
              align="center"
              render={(value) => {
                if (value.CargaMasiva === true) {
                  return (
                    <div>
                      <img
                        src={value.Imagen}
                        className="imagen-tabla"
                        alt="i"
                      />
                      <img
                        src={imgAlerta}
                        style={{
                          position: "absolute",
                          marginLeft: "-12px",
                          top: "0",
                        }}
                        width={20}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <img
                        src={value.Imagen}
                        className="imagen-tabla"
                        alt="i"
                      />
                    </div>
                  );
                }
              }}
            />

            <Column
              // sorter={(a, b) => {
              //   return a.nombre_completo.localeCompare(b.nombre_completo);
              // }}
              render={(value) => {
                return value;
              }}
              title="Nombre Completo"
              dataIndex="nombre_completo"
              key="nombre_completo"
              align="left"
              ellipsis={true}
            />

            <Column
              // sorter={(a, b) => {

              //   const quitarDigitoVerificadorA = a.Rut !== undefined && a.Rut !== '' ? a.Rut.split('-') : '111';
              //   const quitarDigitoVerificadorB = b.Rut !== undefined && b.Rut !== '' ? b.Rut.split('-') : '111';

              //   const rutA = quitarDigitoVerificadorA !== '111' ? quitarDigitoVerificadorA[0].split('.').join('') : '111';
              //   const rutB = quitarDigitoVerificadorB !== '111' ? quitarDigitoVerificadorB[0].split('.').join('') : '111';

              //   const numberFormatoA = parseInt(rutA, 10);
              //   const numberFormatoB = parseInt(rutB, 10);

              //   return numberFormatoA - numberFormatoB;

              // }}
              title="Rut/DNI"
              dataIndex="Rut"
              key="Rut"
              ellipsis={true}
              render={(value) => (
                <p style={{ whiteSpace: 'nowrap', marginBottom: '0px' }}>{value}</p>
              )}
            />

            <Column
              //Error al traer el Nombre del Cargo viene undefined
              // sorter={(a, b) => {
              //   let cargoA = a.Cargo !== undefined ? a.Cargo.Nombre : 'aaaaa';
              //   let cargoB = b.Cargo !== undefined ? b.Cargo.Nombre : 'aaaaa';
              //   return cargoA.localeCompare(cargoB);
              // }}
              title="Cargo"
              dataIndex="Cargo"
              key="Cargo"
              ellipsis={true}
              render={(cargo => {
                if (cargo) {
                  return cargo.Nombre;
                } else {
                  return 'N/A';
                }
              })}
            />

            <Column
              // sorter={(a, b) => {

              //   const quitarFormatoA = a.Telefono !== undefined && a.Telefono !== '' ? a.Telefono.replace('+56', '') : '111';
              //   const quitarFormatoB = b.Telefono !== undefined && b.Telefono !== '' ? b.Telefono.replace('+56', '') : '111';

              //   const numberFormatoA = parseInt(quitarFormatoA, 10);
              //   const numberFormatoB = parseInt(quitarFormatoB, 10);

              //   return numberFormatoA - numberFormatoB;

              // }}
              title="Teléfono"
              dataIndex="Telefono"
              key="Telefono"
              ellipsis={true}
              render={(telefono => {
                if (telefono) {
                  return telefono;
                } else {
                  return 'N/A';
                }
              })}
            />

            <Column
              title="Estado Hoy"
              dataIndex="estadoActual"
              key="estadoActual"
              ellipsis={true}
              align={'center'}
              render={(objEstado => {
                
                let color = this.obtenerColorEstado(objEstado);
                let objTitle = {};
                const trabajo = "5f3eedf56dd7cc57f0d3a261"

                if (objEstado.tipoBitacora !== undefined && objEstado.tipoBitacora !== 'Finiquitado') {

                    if (objEstado.tipoBitacora === trabajo) {
                      objTitle = {
                        title:
                          <div style={{color:'#262E2D'}}>
                            <p><b>Centro de costos:</b> {objEstado.centroCosto}</p>
                            <p><b>Actividad:</b> {objEstado.actividad}</p>
                            <p><b>Plaza:</b> {objEstado.plaza}</p>
                            <p><b>Fecha inicio:</b> {objEstado.estadoInicio}</p>
                            <p><b>Fecha fin:</b> {objEstado.estadoFin}</p>
                          </div>
                      }
                    } else {
                      objTitle = {
                        title:
                          <div style={{color:'black'}}>
                            <p><b>Fecha inicio:</b> {objEstado.estadoInicio}</p>
                            <p><b>Fecha fin:</b> {objEstado.estadoFin}</p>
                          </div>
                      }
                  }

                }

                return (
                  <Tooltip
                    {...objTitle}
                    color={'#E6F5FF'}
                    overlayInnerStyle={{
                      borderRadius:'16px',
                      boxShadow:'0px 3px 6px #00000029',
                      opacity:'1',
                      fontFamily:'MavenPro-Regular',
                      textAlign:'left',
                      letterSpacing:'0px',
                      width: '195px',
                      height: 'auto'
                    }}
                  >
                    {/* <ContactsTwoTone twoToneColor={color} style={{ display: 'inline-block', marginRight: '8px' }} /> */}
                    <span style={{ color, fontWeight: '600', display: 'inline-block' }}>{objEstado.estado}</span>

                  </Tooltip>
                );
              })}
            />


            <Column
              title="Acciones"
              key="Action"
              align="center"
              render={(value) => (
                <Space size="middle">
                  <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                    <Button
                      onClick={() => {
                        this.NuevaPestaña(value._id);
                      }}
                      className="acciones-trabajador-button hover-azul"
                      //disabled={}
                      icon={<EyeFilled style={{ fontSize: '18px' }} />}
                      disabled={
                        value.CargaMasiva === true ? true : !this.state.Ver
                      }
                    />
                  </Tooltip>
                  {estadoSeleccionado !== 'Finiquitado' ? 
                  (
                    <Tooltip title="Editar Trabajador" color={'geekblue'}>
                      <Button
                        className="acciones-trabajador-button hover-celeste"
                        onClick={() => {
                          this.ObtenerTrabajador(value._id);
                        }}
                        icon={<EditFilled style={{ fontSize: '18px' }} />}
                        disabled={
                          value.tipoBitacora === 'Finiquitado' ? true : !this.state.Editar
                        }
                        //disabled={!this.state.Editar}
                      />
                    </Tooltip>
                  ) : 
                  (
                    <Button
                      className="acciones-trabajador-button"
                      icon={<EditFilled style={{ fontSize: '18px' }} />}
                      disabled={true}
                    />
                  )}

                  {estadoSeleccionado !== 'Finiquitado' ? 
                  (
                    <Tooltip title="Eliminar Trabajador" color={'geekblue'}>
                      <Button
                        className="acciones-trabajador-button hover-rojo"
                        onClick={() => {
                          this.eliminarTrabajador(value._id);
                        }}
                        icon={<DeleteFilled style={{ fontSize: '18px' }} />}
                        disabled={
                          value.tipoBitacora === 'Finiquitado' ? true : !this.state.Editar
                        }
                        //disabled={!this.state.Eliminar}
                      />
                    </Tooltip>
                  ) : 
                  (
                    <Button
                      className="acciones-trabajador-button"
                      icon={<DeleteFilled style={{ fontSize: '18px' }} />}
                      disabled={true}
                    />
                  )}

                </Space>
              )}
            />


          </Table>

          <Col className={"col-pagination"}>
              <Pagination current={this.state.filtroPActual} onChange={this.paginationFunction} total={this.props.cantidadTrabajadores} defaultPageSize={this.state.pageSizeDefault} pageSize={this.state.pageSizeDefault} showSizeChanger={true} pageSizeOptions={["10"]} />
          </Col>

        </Content>

        {/* MODAL CREAR */}
        <Modal
          //modal redondeado
          className={'modal-perfil-trabajador'}
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalTrabajador}
          onCancel={this.modalCrearCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={" "}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        
        >
          {/* <FormularioTrabajador */}
          <FormularioTrabajadorNuevo
            //propiedades cambiables del formulario
            cambiarValores={this.cambiarValores}
            Formulario={this.state.form}
            vigencia={this.state.form.vigencia}
            cambiarEstadoVigencia={this.cambiarEstadoVigencia}
            crearTrabajador={this.crearTrabajador}
            imagen={this.state.form.imagen}
            obtenerImagen={this.obtenerImagen}
            validacionRut={this.validacionRut}
            cargosTrabajador={this.props.cargosTrabajador}
            agregarTitulo={this.agregarTitulo}
            agregarEspecialidad={this.agregarEspecialidad}
            eppSeleccionado={this.state.eppSeleccionado}
            //regiones ciudades comunas
            regiones={this.props.regiones}
            //ciudades={this.props.ciudades}
            comunas={this.props.comunas}
            ciudadesSeleccionadas={this.props.ciudadesSeleccionadas}
            comunasSeleccionadas={this.props.comunasSeleccionadas}
            //funciones
            buscarComuna={this.buscarComuna}
            buscarProvincias={this.buscarProvincias}
            //select
            regimenes={this.props.regimenes}
            titulos={this.props.titulos}
            especialidades={this.props.especialidades}
            epps={this.props.epps}
            documentos={this.props.documentos}
            //valor para saber si se puede ingresar departamento
            departamento={this.state.departamento}
            cambioValorVivienda={this.cambioValorVivienda}
            validarRutUsuario={this.validarRutUsuario}
          />
        </Modal>

        {/* MODAL EDITAR */}
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalTrabajadorEditar}
          onCancel={this.modalEditarCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          {/* <FormularioTrabajadorEditar */}
          <FormularioTrabajadorEditarNuevo
            //propiedades cambiables del formulario

            Formulario={this.props.trabajador}
            editarTrabajador={this.EditarTrabajador}
            TomarValoresEditar={this.TomarValoresEditar}
            obtenerImagen={this.obtenerImagen}
            cargosTrabajador={this.props.cargosTrabajador}
            regimenes={this.props.regimenes}
            //nuevas funcionalidades
            regiones={this.props.regiones}
            comunas={this.props.comunas}
            ciudadesSeleccionadas={this.props.ciudadesSeleccionadas}
            comunasSeleccionadas={this.props.comunasSeleccionadas}
            //funciones
            buscarComuna={this.buscarComuna}
            buscarProvincias={this.buscarProvincias}
            //select
            epps={this.props.epps}
            documentos={this.props.documentos}
            //titulos y especialidades
            titulos={this.props.titulos}
            especialidades={this.props.especialidades}
            editarCerrado={this.state.editarCerrado}
            cambiarEditarCerrado={this.cambiarEditarCerrado}
          />
        </Modal>

        {/* MODAL REPORTERIA */}
        <Modal
          //modal redondeado
          className={'modal-perfil-trabajador'}
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.state.modalReporteria}
          onCancel={() => this.setState({modalReporteria: false})}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="50%"
          footer={" "}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        
        >
          <Row>
            <Col span={24} style={{ display: "flex" }} className="title">
              {/* <img src={} style={{ width: "35px" }} alt="icono modal" /> */}
              <PageHeader
                style={{ padding: "0px 10px 0px 10px" }}
                className="site-page-header"
                title="Reportería"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} md={6} lg={3} xl={11}>
              <Row style={{paddingLeft: "0.5rem"}}>
                <Col xs={24}>
                  <h3 style={{fontFamily: "MavenPro-Bold", color: "#001fcb"}}>Cargar reportes</h3>
                </Col>
                <Col xs={24} style={{marginBottom: "0.5rem"}}>
                  <Typography>Descarga este documento para hacer el ingreso de los trabajadores por carga masiva.</Typography>
                </Col>
                <Col xs={24} style={{marginBottom: "0.5rem"}}>
                  <Button
                    className="excel-carga-masiva"
                    type="primary"
                    // size={size}
                    style={{ marginRight: "12px" }}
                    onClick={() => {
                      var win = window.open(
                        "https://peoplemanage.blob.core.windows.net/archivos-sistema/excel_base_trabajadores.xlsx",
                        "_blank"
                      );
                    }}
                  >
                    <div style={{ display:"flex", justifyContent: "center" }}>
                      <Typography style={{ marginRight: "0.5rem", color: "#37a537" }}>Documento Base</Typography>
                      <img src={iconDocumentoBase} alt="Icono documento base" width="20px"/>
                    </div>
                  </Button>
                </Col>
                <Col xs={24} style={{marginBottom: "0.5rem"}}>
                  <Typography>Carga el documento con los datos actualizados.</Typography>
                </Col>
                <Col xs={24} style={{marginBottom: "0.5rem"}}>
                  <Button
                    className="excel-carga-masiva"
                    type="primary"
                    // size={size}
                    style={{ marginRight: "12px" }}
                    onClick={() => {
                      let input = document.createElement("input");
                      input.type = "file";
                      input.accept =
                        "application/vnd.ms-excel, application/vnd.ms-excel.addin.macroEnabled.12, application/vnd.ms-excel.sheet.macroEnabled.12, application/vnd.ms-excel.template.macroEnabled.12, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                      input.click();
                      input.onchange = async (e) => {
                        const archivo = input.files[0];

                        let usuario = localStorage.getItem("usuario-people-manage");
                        if (
                          usuario !== null &&
                          usuario !== "" &&
                          usuario !== undefined &&
                          usuario !== "undefined"
                        ) {
                          usuario = JSON.parse(usuario);
                          const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
                          this.procesarSubirExcel(idEmpresa, archivo);
                        }
                      };
                    }}
                  >
                    <div style={{ display:"flex", justifyContent: "center" }}>
                      <Typography style={{ marginRight: "0.5rem", color: "#37a537" }}>Carga masiva</Typography>
                      <img src={iconCargaMasiva} alt="Icono documento base" width="20px"/>
                    </div>
                  </Button>
                </Col>
              </Row>
            </Col>
            <Divider type="vertical" style={{ height: "20rem", backgroundColor: "#c8c8c8" }}/>
            <Col xs={12} sm={6} md={6} lg={3} xl={11}>
              <Row style={{paddingLeft: "0.5rem"}}>
                <Col xs={24}>
                  <h3 style={{fontFamily: "MavenPro-Bold", color: "#001fcb"}}>Descargar reportes</h3>
                </Col>
                <Col xs={24} style={{marginBottom: "0.5rem"}}>
                  <Typography>Detalle Trabajadores:</Typography>
                </Col>
                <Col xs={24} style={{marginBottom: "0.5rem"}}>
                  <Button
                    className="excel-reportes"
                    type="primary"
                    style={{
                      marginRight: "12px",
                    }}
                    onClick={()=>{this.descargarExcel()}}
                  >
                    Por estado
                  </Button>
                </Col>
                <Col xs={24} style={{marginBottom: "0.5rem"}}>
                  <Button
                    className="excel-reportes"
                    type="primary"
                    style={{
                      marginRight: "12px",
                    }}
                    onClick={()=>{this.descargarExcelTitulosyEspecialidades()}}
                  >
                    Por títulos y especialidades
                  </Button>
                </Col>
                <Col xs={24}>
                  <Button
                    className="excel-reportes"
                    type="primary"
                    style={{
                      marginRight: "12px",
                    }}
                    onClick={()=>{this.descargarExcelDocumentos()}}
                  >
                    Por documentos
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>

      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  MantenedorTrabajadorReducer: {
    error,
    loading,
    success,
    modalTrabajador,
    modalTrabajadorEditar,
    trabajadores,
    cantidadTrabajadores,
    trabajador,
    tablaCargando,
    cargosTrabajador,
    titulos,
    especialidades,
    regimenes,
    regiones,
    ciudades,
    comunas,
    ciudadesSeleccionadas,
    comunasSeleccionadas,
    documentos,
    epps,
  },
}) => {
  return {
    error,
    loading,
    success,
    modalTrabajador,
    modalTrabajadorEditar,
    trabajadores,
    cantidadTrabajadores,
    trabajador,
    tablaCargando,
    cargosTrabajador,
    titulos,
    especialidades,
    regimenes,
    regiones,
    ciudades,
    comunas,
    ciudadesSeleccionadas,
    comunasSeleccionadas,
    documentos,
    epps,
  };
};

export default connect(
  EstadosAPropiedades,
  mantenedorTrabajadorActions
)(MantenedorTrabajador);
