import React, { Component } from "react";
import "./reporteriaExcel.css";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import API from "../../Redux/Api/api";
import Swal from "sweetalert2";
import { Layout,Card,Row,Col,DatePicker,Form,Modal,Button,Switch,Select} from "antd";
import descargarExcel from "../../img/icons/descargarExcel.svg";
import ExcelAzul from "../../img/icons-sidebar/azules/IconExcel.svg";
import locale from "antd/es/date-picker/locale/es_ES";
import { convertirFechaString } from "../../funcionesGenericas/fechas";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Header, Content } = Layout;



class reporteriaExcel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            Empresa: "",
            mostrarModalReporteActividadTrabajador: false,
            reporteActividadTrabajadorUnificado: true,
            mostrarModalReporteContratosTrabajadores: false,
            mostrarModalReporteLicencias: false,
            mostrarModalReporteEppsHistoricos: false,
            mostrarModalReportePeriodoTrabajadorEstado: false,
            tipoRegistroBitacoraTrabajadores: [],
            fechaSeleccionada: moment().format("YYYY-MM-DD"),
            fechasFiltro: null,
            selectedMonth: null
        };
    }
    componentDidMount = async () => {
        this.setState({loading: true})
        let usuario = localStorage.getItem("usuario-people-manage");
        if (
          usuario !== null &&
          usuario !== "" &&
          usuario !== undefined &&
          usuario !== "undefined"
        ) {
            usuario = JSON.parse(usuario);   
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
            this.setState({loading: false, Empresa: idEmpresa});
        }
    };

    mostrarModalReporteActividadTrabajador = () => {
        this.setState({
            mostrarModalReporteActividadTrabajador: true
        })
    };
    
    cancelarModalReporteActividadTrabajador = () => {
        this.setState({ mostrarModalReporteActividadTrabajador: false });
    };

    setFecha = (fecha) => {
        let str = moment(fecha).format('YYYY-MM-DD')
        let fechaFormateada = convertirFechaString(moment(str), 1).fechaString

        this.setState({
          fechaSeleccionada: moment(fechaFormateada).format("YYYY-MM-DD")
        });
    };
    
    descargarReporteExcelGeneral = async (

        informacionDescargar,subtitulo,value
       ) => {
        try {
         this.setState({loading:true });

         let unificado = value.reporteUnificado === undefined ? this.state.reporteActividadTrabajadorUnificado : value.reporteUnificado

         const inicioString = value.fechasReporte[0].format('YYYY-MM-DD');
         const inicio = convertirFechaString(moment(inicioString), 1).fechaString;

         const finString = value.fechasReporte[1].format('YYYY-MM-DD');
         const fin = convertirFechaString(moment(finString), 2).fechaString;

         const fechaSelectString = value.fechaDiaSeleccionado ? value.fechaDiaSeleccionado.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
         const fechaSelect = convertirFechaString(moment(fechaSelectString), 1).fechaString;

         //  const inicio = convertirFechaString(moment.utc(value.fechasReporte[0]),1).fechaString;
         //  const fin = convertirFechaString(moment.utc(value.fechasReporte[1]),2).fechaString;
         //  const fechaSelect = convertirFechaString(moment.utc(value.fechaDiaSeleccionado),1).fechaString;

         if(moment.utc(fechaSelect).isBetween(moment.utc(inicio), moment.utc(fin))){
            const objeto = {
                fechaBusquedaInicioBuq:inicio,
                fechaBusquedaFinBuq:fin,
                fechaSelect
             }
                
            let usuario = localStorage.getItem("usuario-people-manage");
            usuario = JSON.parse(usuario);
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
            //console.log(idEmpresa);

            let url = '';

            if(unificado){
                url = `${API}/Trabajadores/Reporte/ExcelGeneralUnificado?empresa=${idEmpresa}`;
            }else{
                url = `${API}/Trabajadores/Reporte/ExcelGeneral?empresa=${idEmpresa}`;
            }
        
            //const url = `${API}/Trabajadores/Reporte/ExcelGeneral?empresa=${idEmpresa}`;
            //const urlV2 = `${API}/Trabajadores/Reporte/ExcelGeneralV2?empresa=${idEmpresa}`;
              
            const response = await fetch(url, {
                method: "POST",
                mode: "cors",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
                body: JSON.stringify(objeto),
            });
             
            const status = await response.status;
            const excel = await response.blob();
              
            //console.log(status)
            if (status === 200 || status === 201) {
                const url = window.URL.createObjectURL(excel);
                const a = document.createElement("a");
                a.href = url;
                a.download = `TrabajadoresSistemaGeneral.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                this.setState({ mostrarModalReporteActividadTrabajador: false,loading:false });
            } else {
                this.setState({ loading: false });
                Swal.fire({
                  icon: "error",
                  title: "Uuups...",
                  text: "No se pudo cargar la información.",
                  confirmButtonColor: "#E60AA3",
                  confirmButtonText: "OK",
                });
            }
         } else {
            this.setState({ loading: false });
            Swal.fire({
                icon: "error",
                title: "Uuups...",
                text: "La fecha seleccionada debe estar dentro del rango",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "OK",
            });
         }

        } catch (error) {
          console.log(error);
          this.setState({ loading: false });
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
        }
    };
    
    //Segundo descargable Contratos de trabajadores
    mostrarModalReporteContratosTrabajadores = () => {
        this.setState({
            mostrarModalReporteContratosTrabajadores: true
        })
    };
    
    cancelarModalReporteContratosTrabajadores = () => {
        this.setState({ mostrarModalReporteContratosTrabajadores: false });
    };

    descargarReporteExcelContratosTrabajadores = async (
        informacionDescargar,subtitulo,value
    ) => {
        try {
            this.setState({ loading: true });

            const inicioString = value.fechasReporte[0].format('YYYY-MM-DD');
            const inicio = convertirFechaString(moment(inicioString), 1).fechaString;
   
            const finString = value.fechasReporte[1].format('YYYY-MM-DD');
            const fin = convertirFechaString(moment(finString), 2).fechaString;

            // const inicio = convertirFechaString(moment.utc(value.fechasReporte[0]),1).fechaString;
            // const fin = convertirFechaString(moment.utc(value.fechasReporte[1]),2).fechaString;

            const objeto = {
                fechaInicio:inicio,
                fechaFin:fin,
            }

            let usuario = localStorage.getItem("usuario-people-manage");
            usuario = JSON.parse(usuario);
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

            //   const url = `${API}/Trabajadores/Reporte/ExcelTestingNew?empresa=${idEmpresa}`;
            const url = `${API}/Trabajadores/Reporte/ExcelContratosTrabajadores?empresa=${idEmpresa}`;
          
            const response = await fetch(url, {
                method: "POST",
                mode: "cors",
                headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                },
                body: JSON.stringify(objeto),
            });
            
            const status = await response.status;
            const excel = await response.blob();
          
            if (status === 200 || status === 201) {
                const url = window.URL.createObjectURL(excel);
                const a = document.createElement("a");
                a.href = url;
                a.download = `ExcelContratosTrabajadores.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                this.setState({ mostrarModalReporteContratosTrabajadores: false,loading:false });
            } else {
                this.setState({ loading: false });
                Swal.fire({
                icon: "error",
                title: "Uuups...",
                text: "No se pudo cargar la información.",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "OK",
                });
            }

        } catch (error) {
          console.log(error);
          this.setState({ loading: false });
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
        }
    };

    disabledDate = (current) => {
        if (!this.state.fechasFiltro) {
          return true;
        }
    
        // const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
        // const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
        // return !!tooEarly || !!tooLate;
    };
    
    mostrarModalReporteLicencias = () => {
        this.setState({
            mostrarModalReporteLicencias: true
        })
    };
    
    cancelarModalReporteLicencias = () => {
        this.setState({ mostrarModalReporteLicencias: false });
    };

    
    //REPORTE DE LICENCIA
    descargarReporteExcelLicenciasTrabajadores = async (
        informacionDescargar,fechaHoy,value
    ) => {
        //console.log("descargando excel de licencia")   
      
        try {
            this.setState({ loading: true });
            const { Empresa } = this.state
            const inicio = convertirFechaString(moment.utc(value.fechasReporte[0]),1).fechaString;
            const fin = convertirFechaString(moment.utc(value.fechasReporte[1]),2).fechaString;
            const fechaHoyStr = convertirFechaString(moment.utc(fechaHoy),1).fechaString;

            const objeto = {
                fechaInicio:inicio,
                fechaFin:fin,
                fechaHoyStr
            }
            const url = `${API}/Trabajadores/Reporte/ExcelLicenciasTrabajadores?empresa=${Empresa}`;
            const response = await fetch(url, {
                method: "POST",
                mode: "cors",
                headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                },
                body: JSON.stringify(objeto),
            });
      
            const status = await response.status;
            const excel = await response.blob();
          
            if (status === 200 || status === 201) {
                const url = window.URL.createObjectURL(excel);
                const a = document.createElement("a");
                a.href = url;
                a.download = `ExcelLicenciasTrabajadores.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                this.setState({ mostrarModalReporteLicencias: false,loading:false });
            } else {
                this.setState({ loading: false });
                Swal.fire({
                icon: "error",
                title: "Uuups...",
                text: "No se pudo cargar la información.",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "OK",
                });
            }

        } catch (error) {
          console.log(error);
          this.setState({ loading: false });
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
        }
    };


    //REPORTE DE EPPS HISTORICOS
    mostrarModalReporteEppsHistoricos = () => {
        this.setState({ mostrarModalReporteEppsHistoricos: true })
    };
    
    cancelarModalReporteEppsHistoricos = () => {
        this.setState({ mostrarModalReporteEppsHistoricos: false });
    };

    descargarReporteEppsHistoricosTrabajadores = async (
        informacionDescargar,fechaHoy,value
    ) => {
        try {
            this.setState({ loading: true });
            const { Empresa } = this.state
            const inicio = convertirFechaString(moment.utc(value.fechasReporte[0]),1).fechaString;
            const fin = convertirFechaString(moment.utc(value.fechasReporte[1]),2).fechaString;
            const fechaHoyStr = convertirFechaString(moment.utc(fechaHoy),1).fechaString;

            const objeto = {
                fechaInicio:inicio,
                fechaFin:fin,
                fechaHoyStr
            }
            // console.log("descargando epps historicos")
            // console.log(objeto)
            // return
            const url = `${API}/Trabajadores/Reporte/ExcelEppsHistoricasTrabajadores?empresa=${Empresa}`;
            const response = await fetch(url, {
                method: "POST",
                mode: "cors",
                headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                },
                body: JSON.stringify(objeto),
            });
      
            const status = await response.status;
            const excel = await response.blob();
          
            if (status === 200 || status === 201) {
                const url = window.URL.createObjectURL(excel);
                const a = document.createElement("a");
                a.href = url;
                a.download = `ExcelEppsHistoricosTrabajadores.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                this.setState({ mostrarModalReporteEppsHistoricos: false,loading:false });
            } else {
                this.setState({ loading: false });
                Swal.fire({
                icon: "error",
                title: "Uuups...",
                text: "No se pudo cargar la información.",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "OK",
                });
            }

        } catch (error) {
          console.log(error);
          this.setState({ loading: false });
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });
        }
    };

    //REPORTE DE PERIODO DE TRABAJADOR POR ESTADO
    mostrarModalReportePeriodoTrabajadorEstado = async() => {
        try {
            const response = await fetch(`${API}/ReporteriaExcel/ObtenerTipoRegistroBitacoraTrabajador`, {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                }
            });
          
            if (!response.ok) {
                throw new Error('Error fetching data');
            }
          
            const data = await response.json();
            //console.log(data, "data");
            this.setState({
                mostrarModalReportePeriodoTrabajadorEstado: true,
                tipoRegistroBitacoraTrabajadores: data.registros // Almacena los registros en el estado
            });
        } catch (error) {
            console.error('Error:', error);
            this.setState({
                mostrarModalReportePeriodoTrabajadorEstado: true,
                tipoRegistroBitacoraTrabajadores: [] // Maneja el caso de error
            });
        }
        // this.setState({
        //     mostrarModalReportePeriodoTrabajadorEstado: true
        // })
    };
    cancelarModalReportePeriodoTrabajadorEstado = () => {
        this.setState({ mostrarModalReportePeriodoTrabajadorEstado: false });
    };
    
    descargarReportePeriodoTrabajadorEstado = async (informacionDescargar,subtitulo,value) => {
        //console.log("REPORTE DE PERIODO DE TRABAJADOR POR ESTADO");
        //console.log(informacionDescargar, subtitulo, value);
        this.setState({ loading: true });
        //se obtiene el id de la emprea IMPORTANTE!!!
        const { Empresa } = this.state
        const {fechasReporte, estadoSeleccionado} = value;
        //console.log(fechasReporte, estadoSeleccionado, "de values");
      
        const inicio = convertirFechaString(moment.utc(fechasReporte[0]),1).fechaString;
        const fin = convertirFechaString(moment.utc(fechasReporte[1]),2).fechaString;
        const fechaHoyStr = convertirFechaString(moment.utc(),1).fechaString;

        const objeto = {
            fechaInicio:inicio,
            fechaFin:fin,
            fechaHoyStr,
            estadoSeleccionado,
            tituloExcel: informacionDescargar,
            tituloHoja: subtitulo
        }
        // console.log({objeto,Empresa});
        
        const url = `${API}/Trabajadores/Reporte/ExcelPeriodoTrabajadoresPorEstado?empresa=${Empresa}`;
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            },
            body: JSON.stringify(objeto),
        });
  
        const status = await response.status;
        const excel = await response.blob();
      
        if (status === 200 || status === 201) {
            
            const url = window.URL.createObjectURL(excel);
            const a = document.createElement("a");
            a.href = url;
            a.download = `ExcelPeriodoAsistenciaPorEstadoTrabajador.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            this.setState({ mostrarModalReportePeriodoTrabajadorEstado: false,loading:false });
        } else {
            
            this.setState({ loading: false });
            Swal.fire({
                icon: "error",
                title: "Uuups...",
                text: "No se pudo cargar la información.",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "OK",
            });
        }
     

    };

    render() {
        const { loading, fechasFiltro , reporteActividadTrabajadorUnificado, tipoRegistroBitacoraTrabajadores } = this.state;
        
        return (
            <Layout style={{ height: "100%" }}>

                <Loading cargando={loading} />

                <Header style={{ background: "none" }}>
                    <HeaderFicha titulo="Reportes Excel" subtitulo="Descarga documentos excel" />
                </Header>

                <hr className="division" />

                <Content
                    //className="contenedor-general cont-tabla-celeste"
                    style={{ background: "none" }}
                >
                <Row>
                    <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                            <Card title="Reporte Excel"
                                 onClick={() => {
                                    this.mostrarModalReporteActividadTrabajador();
                                  }}
                                className="cont-reportes-excel title-re card-re-head card-report-excel"
                                extra= {   
                                    <div 
                                        className="div-report-excel"
                                    >
                                        <img src={descargarExcel} onClick={() => { //this.descargarExcel();
                                        }} className="img-report-excel"></img>
                                    </div>
                                }  
                            >
                                <p className="p-report-excel">Descripción</p>
                                <h2 className="h2-report-excel" >Actividad por trabajador</h2>
                            </Card>

                            <Card title="Reporte Excel"
                                 onClick={() => {
                                    this.mostrarModalReporteContratosTrabajadores();
                                  }}
                                className="cont-reportes-excel title-re card-re-head card-report-excel"
                                extra= {   
                                    <div 
                                        className="div-report-excel"
                                    >
                                        <img src={descargarExcel} onClick={() => { //this.descargarExcel();
                                        }} className="img-report-excel"></img>
                                    </div>
                                }  
                            >
                                <p className="p-report-excel">Descripción</p>
                                <h2 className="h2-report-excel" >Contratos de trabajadores</h2>
                            </Card>

                            <Card title="Reporte Excel"
                                 onClick={() => {
                                    this.mostrarModalReporteLicencias();
                                  }}
                                className="cont-reportes-excel title-re card-re-head card-report-excel"
                                extra= {   
                                    <div 
                                        className="div-report-excel"
                                    >
                                        <img src={descargarExcel} onClick={() => { //this.descargarExcel();
                                        }} className="img-report-excel"></img>
                                    </div>
                                }  
                            >
                                <p className="p-report-excel">Descripción</p>
                                <h2 className="h2-report-excel" >Licencias</h2>
                            </Card>

                            <Card title="Reporte Excel"
                                onClick={() => {
                                    this.mostrarModalReporteEppsHistoricos();
                                }}
                                className="cont-reportes-excel title-re card-re-head card-report-excel"
                                extra= {   
                                    <div 
                                        className="div-report-excel"
                                    >
                                        <img src={descargarExcel} onClick={() => { //this.descargarExcel();
                                        }} className="img-report-excel"></img>
                                    </div>
                                }  
                            >
                                <p className="p-report-excel">Descripción</p>
                                <h2 className="h2-report-excel" >Epps historicos</h2>
                            </Card>

                            <Card title="Reporte Excel"
                                onClick={() => {
                                this.mostrarModalReportePeriodoTrabajadorEstado();
                                }}
                                className="cont-reportes-excel title-re card-re-head card-report-excel"
                                extra= {   
                                    <div 
                                        className="div-report-excel"
                                    >
                                        <img src={descargarExcel} onClick={() => { //this.descargarExcel();
                                        }} className="img-report-excel"></img>
                                    </div>
                                }  
                            >
                                <p className="p-report-excel">Descripción</p>
                                <h2 className="h2-report-excel" >Periodo de trabajador por estado</h2>
                            </Card>
                        </Row>
                    </Col>
                </Row>

                <Modal
                    wrapClassName="modalExcel"
                    visible={this.state.mostrarModalReporteActividadTrabajador}
                    onCancel={() => this.cancelarModalReporteActividadTrabajador()}
                    //propiedades obligatorias para modal mantenedor
                    bodyStyle={{ borderRadius: "16px" }}
                    footer={null}
                    style={{ top: 20, borderRadius: "16px" }}
                    closable={false}
                    maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
                    className={"modal-repotes-excel"}
                >
                <Row> 
                    <Col 
                    xs={{ span: 2, offset: 0 }}
                    sm={{ span: 2, offset: 0 }}
                    md={{ span: 2, offset: 0 }}
                    lg={{ span: 2, offset: 0 }}
                    xl={{ span: 2, offset: 0 }}
                    xxl={{ span: 2, offset: 0 }}
                    style={{marginRight:'14px'}}
                    >
                        <img src={ExcelAzul} style={{marginTop:'8px'}}></img>
                    </Col>
                    <Col 
                    xs={{ span: 20, offset: 0 }}
                    sm={{ span: 20, offset: 0 }}
                    md={{ span: 20, offset: 0 }}
                    lg={{ span: 20, offset: 0}}
                    xl={{ span: 20, offset: 0 }}
                    xxl={{ span: 20, offset: 0 }}
                    >
                        <h1 style={{fontFamily:'MavenPro-Bold',color: '#061FCB'}}>Filtrar Por Fecha</h1>
                    </Col>
                </Row>
            
                <Form
                    name="formReporteActividad"
                    onFinish={(e)=>this.descargarReporteExcelGeneral("","",e)}
                    ref={this.formRef}  
                >
                <div>
                    <p style={{ marginBottom: '10px',fontFamily: 'MavenPro-Bold' }}>Actividad por trabajador</p>
                </div>
                <div>
                    <p style={{ marginBottom: '10px',fontFamily: 'MavenPro-Regular'  }}>Selecciona el rango de fechas que necesitas estén en el documento</p>
                </div>
                <Row gutter={20} style={{ marginBottom: '20px' }}>
                    <p style={{fontFamily: 'MavenPro-Regular', marginLeft:'10px'  }}>¿Reporte unificado?</p>
                    <Col  xs={24} sm={24} md={24} lg={24} xl={24}
                        style={{ marginBottom: '5px' }}
                    >
                        <Form.Item
                            name="reporteUnificado"
                                
                        >
                            <Switch  
                                onChange={(value)=>{
                                    this.setState({reporteActividadTrabajadorUnificado:value})
                                }}
                                defaultChecked={true}
                                
                            />
                        </Form.Item>
                    </Col>
                    <Col  xs={24} sm={24} md={24} lg={24} xl={24}
                        style={{ marginBottom: '15px' }}
                    >
                        <Form.Item
                            name="fechasReporte"
                            rules={[
                                {
                                required: true,
                                message: "Debe introducir una fecha de inicio y término",
                                },
                            ]}
                            >
                            <RangePicker
                                locale={locale}
                                allowClear={false}
                                name="fechasReporte"
                                format={["DD-MM-YYYY"]}
                                onChange={(fechas) => this.setState({fechasFiltro: fechas})}
                            />
                        </Form.Item>
                      
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <p style={{ marginBottom: '10px', fontFamily: 'MavenPro-Regular'}}>Fecha estado específico del trabajador</p>  
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}
                        style={{ marginBottom: '15px' }}
                    >
                        <Form.Item
                        name="fechaDiaSeleccionado"
                        rules={[
                            {
                            required: false,
                            message: "Debe seleccionar un dia en especifico",
                            },
                        ]}
                        >
                            <DatePicker
                                locale={locale}
                                allowClear={false}
                                name="fechaDiaSeleccionado"
                                format={'DD-MM-YYYY'}
                                // picker="day"
                                defaultValue={moment()}
                                disabled={fechasFiltro === null ? true : false}
                                // disabledDate={(current) => current.isAfter(moment().subtract(1,"day"))}
                                disabledDate={(dia) => {
                                    let validacion = false;

                                    let diaString = dia.format('YYYY-MM-DD');
                                    let diaSeleccionado = convertirFechaString(moment(diaString), 1).fechaString;

                                    let fechaInicioString = fechasFiltro[0].format('YYYY-MM-DD');
                                    let fechaInicio = convertirFechaString(moment(fechaInicioString), 1).fechaString;

                                    let fechaFinString = fechasFiltro[1].format('YYYY-MM-DD');
                                    let fechaFin = convertirFechaString(moment(fechaFinString), 2).fechaString;

                                    if(moment.utc(diaSeleccionado).isBetween(moment.utc(fechaInicio), moment.utc(fechaFin), null, '[]')){
                                        validacion = false;
                                    } else {
                                        validacion = true;
                                    }
                                    
                                    return validacion;
                                }}

                                onChange={(fecha) => {
                                  if (fecha == null) {
                                    this.setFecha(moment());
                                  } else {
                                    this.setFecha(fecha);
                                  }
                                }}
                            />
                        </Form.Item>
                    </Col>
              
                    <Col xs={24} s={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:'center'}}>
                        <Button
                            //type="primary"
                            htmlType="submit"
                            form="formReporteActividad"
                            //onClick={()=>this.descargarReporteExcelGeneral()}
                            className="modal-repotes-excel-btn"
                        >
                            Descargar
                        </Button>
                    </Col>
                </Row>
                </Form>
                </Modal>
                
                {/* Contratos de trabajadores MODAL */}
                <Modal
                wrapClassName="modalExcel"
                visible={this.state.mostrarModalReporteContratosTrabajadores}
                onCancel={() => this.cancelarModalReporteContratosTrabajadores()}
                //propiedades obligatorias para modal mantenedor
                bodyStyle={{ borderRadius: "16px" }}
                footer={null}
                style={{ top: 20, borderRadius: "16px" }}
                closable={false}
                maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
                className={"modal-repotes-excel"}
                >
                    <Row> 
                        <Col 
                            xs={{ span: 2, offset: 0 }}
                            sm={{ span: 2, offset: 0 }}
                            md={{ span: 2, offset: 0 }}
                            lg={{ span: 2, offset: 0 }}
                            xl={{ span: 2, offset: 0 }}
                            xxl={{ span: 2, offset: 0 }}
                            style={{marginRight:'14px'}}
                        >
                            <img src={ExcelAzul} style={{marginTop:'8px'}}></img>
                        </Col>
                        <Col 
                        xs={{ span: 20, offset: 0 }}
                        sm={{ span: 20, offset: 0 }}
                        md={{ span: 20, offset: 0 }}
                        lg={{ span: 20, offset: 0}}
                        xl={{ span: 20, offset: 0 }}
                        xxl={{ span: 20, offset: 0 }}
                        >
                            <h1 style={{fontFamily:'MavenPro-Bold',color: '#061FCB'}}>Filtrar Por Fecha</h1>
                        </Col>
                    </Row>
                
                    <Form
                        name="formReporteContratos"
                        onFinish={(e)=>this.descargarReporteExcelContratosTrabajadores("","",e)}
                        ref={this.formRef}  
                    >
                        <div>
                            <p style={{ marginBottom: '10px',fontFamily: 'MavenPro-Bold' }}>Contratos de trabajadores</p>
                        </div>
                        <div>
                            <p style={{ marginBottom: '10px',fontFamily: 'MavenPro-Regular'  }}>Selecciona el rango de fechas que necesitas para el documento.</p>
                        </div>
                        <Row gutter={20} style={{ marginBottom: '20px' }}>
                            <Col  xs={24} sm={24} md={24} lg={24} xl={24}
                                style={{ marginBottom: '15px' }}
                            >
                                <Form.Item
                                    name="fechasReporte"
                                    rules={[
                                        {
                                        required: true,
                                        message: "Debe introducir una fecha de inicio y término",
                                        },
                                    ]}
                                    >
                                    <RangePicker
                                        locale={locale}
                                        allowClear={false}
                                        name="fechasReporte"
                                        format={["DD-MM-YYYY"]}
                                    />
                                </Form.Item>
                            
                            </Col>
                    
                            <Col xs={24} s={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:'center'}}>
                                <Button
                                    //type="primary"
                                    htmlType="submit"
                                    form="formReporteContratos"
                                    //onClick={()=>this.descargarReporteExcelGeneral()}
                                    className="modal-repotes-excel-btn"
                                >
                                    Descargar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>


                {/* REPORTE DE LICENCIAS */}
                <Modal
                wrapClassName="modalExcel"
                visible={this.state.mostrarModalReporteLicencias}
                onCancel={() => this.cancelarModalReporteLicencias()}
                //propiedades obligatorias para modal mantenedor
                bodyStyle={{ borderRadius: "16px" }}
                footer={null}
                style={{ top: 20, borderRadius: "16px" }}
                closable={false}
                maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
                className={"modal-repotes-excel"}
                >
                    <Row> 
                        <Col 
                            xs={{ span: 2, offset: 0 }}
                            sm={{ span: 2, offset: 0 }}
                            md={{ span: 2, offset: 0 }}
                            lg={{ span: 2, offset: 0 }}
                            xl={{ span: 2, offset: 0 }}
                            xxl={{ span: 2, offset: 0 }}
                            style={{marginRight:'14px'}}
                        >
                            <img src={ExcelAzul} style={{marginTop:'8px'}}></img>
                        </Col>
                        <Col 
                        xs={{ span: 20, offset: 0 }}
                        sm={{ span: 20, offset: 0 }}
                        md={{ span: 20, offset: 0 }}
                        lg={{ span: 20, offset: 0}}
                        xl={{ span: 20, offset: 0 }}
                        xxl={{ span: 20, offset: 0 }}
                        >
                            <h1 style={{fontFamily:'MavenPro-Bold',color: '#061FCB'}}>Filtrar Por Fecha</h1>
                        </Col>
                    </Row>
                
                    <Form
                        name="formReporteLicencia"
                        onFinish={(e)=>this.descargarReporteExcelLicenciasTrabajadores("Reporte de licencias",moment.utc(),e)}
                        ref={this.formRef}  
                    >
                        <div>
                            <p style={{ marginBottom: '10px',fontFamily: 'MavenPro-Bold' }}>Licencia y licencia de mutualidad</p>
                        </div>
                        <div>
                            <p style={{ marginBottom: '10px',fontFamily: 'MavenPro-Regular'  }}>Selecciona el rango de fechas que necesitas para el documento.</p>
                        </div>
                        <Row gutter={20} style={{ marginBottom: '20px' }}>
                            <Col  xs={24} sm={24} md={24} lg={24} xl={24}
                                style={{ marginBottom: '15px' }}
                            >
                                <Form.Item
                                    name="fechasReporte"
                                    rules={[
                                        {
                                        required: true,
                                        message: "Debe introducir una fecha de inicio y término",
                                        },
                                    ]}
                                    >
                                    <RangePicker
                                        locale={locale}
                                        allowClear={false}
                                        name="fechasReporte"
                                        format={["DD-MM-YYYY"]}
                                    />
                                </Form.Item>
                            
                            </Col>
                    
                            <Col xs={24} s={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:'center'}}>
                                <Button
                                    //type="primary"
                                    htmlType="submit"
                                    form="formReporteLicencia"
                                    //onClick={()=>this.descargarReporteExcelGeneral()}
                                    className="modal-repotes-excel-btn"
                                >
                                    Descargar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                {/* REPORTE DE EPPS HISTORICAS */}
                <Modal
                    wrapClassName="modalExcel"
                    visible={this.state.mostrarModalReporteEppsHistoricos}
                    onCancel={() => this.cancelarModalReporteEppsHistoricos()}
                    //propiedades obligatorias para modal mantenedor
                    bodyStyle={{ borderRadius: "16px" }}
                    footer={null}
                    style={{ top: 20, borderRadius: "16px" }}
                    closable={false}
                    maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
                    className={"modal-repotes-excel"}
                >
                    <Row> 
                        <Col 
                            xs={{ span: 2, offset: 0 }}
                            sm={{ span: 2, offset: 0 }}
                            md={{ span: 2, offset: 0 }}
                            lg={{ span: 2, offset: 0 }}
                            xl={{ span: 2, offset: 0 }}
                            xxl={{ span: 2, offset: 0 }}
                            style={{marginRight:'14px'}}
                        >
                            <img src={ExcelAzul} style={{marginTop:'8px'}}></img>
                        </Col>
                        <Col 
                        xs={{ span: 20, offset: 0 }}
                        sm={{ span: 20, offset: 0 }}
                        md={{ span: 20, offset: 0 }}
                        lg={{ span: 20, offset: 0}}
                        xl={{ span: 20, offset: 0 }}
                        xxl={{ span: 20, offset: 0 }}
                        >
                            <h1 style={{fontFamily:'MavenPro-Bold',color: '#061FCB'}}>Filtrar Por Fecha</h1>
                        </Col>
                    </Row>
                
                    <Form
                        name="formReporteEppsHistoricas"
                        onFinish={(e)=>this.descargarReporteEppsHistoricosTrabajadores("Reporte de epps historicas",moment.utc(),e)}
                        ref={this.formRef}  
                    >
                        <div>
                            <p style={{ marginBottom: '10px',fontFamily: 'MavenPro-Bold' }}>Elementos de protección históricos</p>
                        </div>
                        <div>
                            <p style={{ marginBottom: '10px',fontFamily: 'MavenPro-Regular'  }}>Selecciona el rango de fechas que necesitas para el documento.</p>
                        </div>
                        <Row gutter={20} style={{ marginBottom: '20px' }}>
                            <Col  xs={24} sm={24} md={24} lg={24} xl={24}
                                style={{ marginBottom: '15px' }}
                            >
                                <Form.Item
                                    name="fechasReporte"
                                    rules={[
                                        {
                                        required: true,
                                        message: "Debe introducir una fecha de inicio y término",
                                        },
                                    ]}
                                    >
                                    <RangePicker
                                        locale={locale}
                                        allowClear={false}
                                        name="fechasReporte"
                                        format={["DD-MM-YYYY"]}
                                    />
                                </Form.Item>
                            
                            </Col>
                    
                            <Col xs={24} s={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:'center'}}>
                                <Button
                                    //type="primary"
                                    htmlType="submit"
                                    form="formReporteEppsHistoricas"
                                    //onClick={()=>this.descargarReporteExcelGeneral()}
                                    className="modal-repotes-excel-btn"
                                >
                                    Descargar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                 
                {/* REPORTE DE PERIODO DE TRABAJADOR POR ESTADO */}
                <Modal
                    wrapClassName="modalExcel"
                    visible={this.state.mostrarModalReportePeriodoTrabajadorEstado}
                    onCancel={() => this.cancelarModalReportePeriodoTrabajadorEstado()}
                    //propiedades obligatorias para modal mantenedor
                    bodyStyle={{ borderRadius: "16px" }}
                    footer={null}
                    style={{ top: 20, borderRadius: "16px" }}
                    closable={false}
                    maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
                    className={"modal-repotes-excel"}
                >
                <Row> 
                    <Col 
                    xs={{ span: 2, offset: 0 }}
                    sm={{ span: 2, offset: 0 }}
                    md={{ span: 2, offset: 0 }}
                    lg={{ span: 2, offset: 0 }}
                    xl={{ span: 2, offset: 0 }}
                    xxl={{ span: 2, offset: 0 }}
                    style={{marginRight:'14px'}}
                    >
                        <img src={ExcelAzul} style={{marginTop:'8px'}}></img>
                    </Col>
                    <Col 
                    xs={{ span: 20, offset: 0 }}
                    sm={{ span: 20, offset: 0 }}
                    md={{ span: 20, offset: 0 }}
                    lg={{ span: 20, offset: 0}}
                    xl={{ span: 20, offset: 0 }}
                    xxl={{ span: 20, offset: 0 }}
                    >
                        <h1 style={{fontFamily:'MavenPro-Bold',color: '#061FCB'}}>Filtrar Por Fecha</h1>
                    </Col>
                </Row>
            
                <Form
                    name="formReportePeriodoTrabajadorEstado"
                    onFinish={(e)=>this.descargarReportePeriodoTrabajadorEstado("Datos de Asistencia por estado de trabajador","Asistencia por estado",e)}
                    ref={this.formRef}  
                >
                <div>
                    <p style={{ marginBottom: '10px',fontFamily: 'MavenPro-Bold' }}>Periodo de trabajador por estado</p>
                </div>
                <div>
                    <p style={{ marginBottom: '10px',fontFamily: 'MavenPro-Regular'  }}>Seleccione periodos de fechas y estado que necesitas ver en el documento.</p>
                </div>
                <Row gutter={20} style={{ marginBottom: '20px' }}>

                    <Col  xs={24} sm={24} md={24} lg={24} xl={24}
                        style={{ marginBottom: '15px' }}
                    >
                        <Form.Item
                            name="fechasReporte"
                            rules={[
                                {
                                required: true,
                                message: "Debe introducir una fecha de inicio y término",
                                },
                            ]}
                            >
                            <RangePicker
                                locale={locale}
                                allowClear={true}
                                name="fechasReporte"
                                format={["DD-MM-YYYY"]}
                                onChange={(fechas) => this.setState({fechasFiltro: fechas})}
                                onCalendarChange={   (dates) => {
                                    if (dates && dates[0]) {
                                      this.setState({ selectedMonth:dates[0]})
                                      
                                    } else {
                                        this.setState({ selectedMonth:null})
                                    }
                                }}
                                disabledDate={
                                    (current) => {
                                        const { selectedMonth } = this.state;
                                        if (!selectedMonth) return false;
                                        return current && current.month() > selectedMonth.month();
                                    }
                                }
                            />
                        </Form.Item>
                      
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <p style={{ marginBottom: '10px', fontFamily: 'MavenPro-Regular'}}>Estado</p>  
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}
                        style={{ marginBottom: '15px' }}
                    >
                        <Form.Item
                            name="estadoSeleccionado"
                            rules={[
                                {
                                required: true,
                                message: "Debe seleccionar un estado",
                                },
                            ]}
                        >
                            <Select placeholder="Seleccionar estado">
                                {tipoRegistroBitacoraTrabajadores.map((registro, index) => (
                                    <option key={index} value={registro._id}>{registro.Nombre}</option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
              
                    <Col xs={24} s={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:'center'}}>
                        <Button
                            htmlType="submit"
                            form="formReportePeriodoTrabajadorEstado"
                            className="modal-repotes-excel-btn"
                        >
                            Descargar
                        </Button>
                    </Col>
                </Row>
                </Form>
                </Modal>
                
                                    
                </Content>

            </Layout>
        );
    }
}

export default reporteriaExcel;