import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import LayoutReducer from "./LayoutReducer";
import FiltroTrabajadoresReducer from "./FiltroTrabajadoresReducer";
import MantenedorUsuarioReducer from "./MantenedorUsuarioReducer";
import MantenedorEmpresaReducer from "./MantenedorEmpresaReducer.js";
import MantenedorTipoEmpresaReducer from "./MantenedorTipoEmpresaReducer";
import MantenedorRolReducer from "./MantenedorRolReducer";
import MantenedorDocumentosReducer from "./MantenedorDocumentosReducer";
import MantenedorFeriadosReducer from "./MantenedorFeriadosReducer";
import MantenedorHoldingsReducer from "./MantenedorHoldingsReducer";
import MantenedorCentroCostosReducer from "./MantenedorCentroCostosReducer";
import MantenedorCargosReducer from "./MantenedorCargosReducer";
import MantenedorRegimenTrabajoReducer from "./MantenedorRegimenTrabajoReducer";
import MantenedorTrabajadorReducer from "./MantenedorTrabajadorReducer";
import MantenedorSucursalesReducer from "./MantenedorSucursalesReducer";
import MantenedorTitulosReducer from "./MantenedorTitulosReducer";
import MantenedorEPPReducer from "./MantenedorEPPReducer";

import MantenedorEspecialidadesReducer from "./MantenedorEspecialidadesReducer";
import MantenedorPlantillaDeFaenaReducer from "./MantenedorPlantillaDeFaenaReducer";
import MantenedorPerfilesUsuarioReducer from "./MantenedorPerfilesUsuarioReducer";


import CalendarioTrabajadorReducer from "./CalendarioTrabajadorReducer";
import asignacionFaenasReducer from "./asignacionFaenasReducer";
import DetalleFaenaReducer from "./DetalleFaenaReducer";
import LoginReducer from "./LoginReducer";
import DescansoReducer from './descansoReducer';
import VacacionReducer from './vacacionReducer';
import AsistenciaReducer from './asistenciaReducer';
import AusenteReducer from './ausenteReducer';
import LicenciaReducer from "./LicenciaReducer";
import AccidenteReducer from './accidenteReducer';
import PermisoReducer from './permisoReducer';
import EnTransitoReducer from './enTransitoReducer';
import AsistenciaTimeLineReducer from "./asistenciaTimeLineReducer";


import CheckOutReducer from "./CheckOutReducer";

export default combineReducers({
  LoginReducer,
  AuthReducer,
  LayoutReducer,
  MantenedorUsuarioReducer,
  MantenedorEmpresaReducer,
  MantenedorRolReducer,
  MantenedorDocumentosReducer,
  MantenedorFeriadosReducer,
  MantenedorTipoEmpresaReducer,
  MantenedorHoldingsReducer,
  MantenedorCentroCostosReducer,
  MantenedorCargosReducer,
  MantenedorRegimenTrabajoReducer,
  MantenedorTrabajadorReducer,
  MantenedorSucursalesReducer,
  MantenedorTitulosReducer,
  MantenedorEspecialidadesReducer,
  MantenedorPlantillaDeFaenaReducer,
  CalendarioTrabajadorReducer,
  asignacionFaenasReducer,
  DetalleFaenaReducer,
  FiltroTrabajadoresReducer,
  DescansoReducer,
  VacacionReducer,
  AsistenciaReducer,
  AusenteReducer,
  LicenciaReducer,
  AccidenteReducer,
  PermisoReducer,
  EnTransitoReducer,
  AsistenciaTimeLineReducer,
  MantenedorEPPReducer,
  MantenedorPerfilesUsuarioReducer,
  CheckOutReducer
});
