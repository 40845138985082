import React, { Component } from "react";
import { Row, Layout, Col, DatePicker, Input, Select, Button, Empty,Pagination, Card,Steps } from "antd";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import coloresEstadoTrabajo from "../../funcionesGenericas/coloresEstadoTrabajo";
import Loading from "../../components/loading/Loading";
import moment from "moment";
import "moment/locale/es";

// import TrabajoEfectivo from "../../img/dashboard/bubble/TrabajoEfectivo.svg";
// import Descanso from "../../img/dashboard/bubble/Descanso.svg";
// import EnTransito from "../../img/dashboard/bubble/EnTransito.svg";
// import Vacaciones from "../../img/dashboard/bubble/Vacaciones.svg";
// import Licencia from "../../img/dashboard/bubble/Licencia.svg";
// import LicenciaMutual from "../../img/dashboard/bubble/LicenciaMutual.svg";
// import Permiso from "../../img/dashboard/bubble/Permiso.svg";
// import Ausente from "../../img/dashboard/bubble/Ausente.svg";
// import NoAsignado from "../../img/dashboard/bubble/NoAsignado.svg";

import TrabajoEfectivo from "../../img/icons-nuevos/TrabajoEfectivo.svg";
import Descanso from "../../img/icons-nuevos/Descanso.svg";
import EnTransito from "../../img/icons-nuevos/EnTransito.svg";
import Vacaciones from "../../img/icons-nuevos/Vacaciones.svg";
import Licencia from "../../img/icons-nuevos/Licencia.svg";
import LicenciaMutual from "../../img/icons-nuevos/LicenciaMutualidad.svg";
import Permiso from "../../img/icons-nuevos/Permiso.svg";
import Ausente from "../../img/icons-nuevos/Ausente.svg";
import NoAsignado from "../../img/icons-nuevos/NoAsignado.svg";
import descargarExcel from "../../img/icons/descargarExcel.svg";
import NoContratado from "../../img/icons/accidente.svg";

// import locale from "antd/es/date-picker/locale/es_ES";

import "./ReporteriaAsistenciaPersonas.css";

import Timeline, {
  DateHeader,
  TimelineHeaders,
  TodayMarker,
  CustomMarker,
  SidebarHeader,
} from "react-calendar-timeline";

// import Swal from "sweetalert2";

import API from "../../Redux/Api/api";
import locale from "antd/es/date-picker/locale/es_ES";
import Swal from "sweetalert2";

import {
  databaseFormat,
  momentToStringFechaInicio,
  momentToStringFechaFin,
  convertirFechaString,
} from "../../funcionesGenericas/fechas";

const { Search } = Input;
const { Header, Content } = Layout;
const { RangePicker } = DatePicker;
const { Step } = Steps;
const customDot = (dot, { status, index,title }) => (

  <span>{dot}</span>
  
);
class ReporteriaAsistenciaPersonas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      mostrarCalendario: false,
      grupos: [],
      items: [],
      firstDay: null,
      lastDay: null,

      centrosCosto: [],
      cargos: [],

      selectedCentroCosto: null,
      selectedPersona:[],//nuevo
      selectedCargo: null,
      selectedFechaInicio: null,
      selectedFechaFin: null,
      selectedDates:[null,null],
      filtroPActual: 1,
      //filtroPSize:10,
      TrabajadoresCount:[],

      trabajadorSearch: '',
      gruposFiltrado: null,

      limiteBusqueda: 300,
      //limit:10,
      limit:3,
      skip:0,
      //trabajadoresFiltrados: null,
      trabajadoresFiltrados: [],
      trabajadoresBuscadosFiltros: [],
      firstDaysOfTheMonths: []
    };
  }

  componentDidMount = async () => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      //para buscar por el mes actual al abrir ésta página
      const firstDay = moment().startOf("month").format("YYYY-MM-DD");
      const lastDay = moment().endOf("month").format("YYYY-MM-DD");
      

      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
  
      const objBusqueda = {
        empresa: idEmpresa,
        centroCosto: null,
        cargo: null,
        fechaInicio: firstDay,
        fechaFin: lastDay,
        //agregadas despues
        //limit:10,
        limit:3,
        skip:0
      };

      let respuesta = await this.buscarTrabajadores(objBusqueda);

      let centrosDeCosto = await this.obtenerCentrosDeCostos(idEmpresa)
      let cargos = await this.obtenerCargos(idEmpresa);

      if (respuesta.status !== 200 || centrosDeCosto.status !== 200 || cargos.status !== 200) {

        this.setState({
          loading: false,
        })

        Swal.fire({
          title: "¡Ups!",
          text: "Para ver este reporte debes tener centros de costo, cargos y trabajadores registrados.",
          icon: "warning",
          confirmButtonColor: "#1FC600",
          confirmButtonText: "Ok",
        });

        return;
      }

      let arrayIniciosDeMes = [];
      let diferenciaMeses = moment.utc(lastDay).diff(moment.utc(firstDay), 'months') + 1;

      for (let index = 0; index < diferenciaMeses; index++) {
        let fechaAgregar;
        fechaAgregar = moment.utc(firstDay).add(index, "months");
        arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
      }

      this.setState({
        centrosCosto: centrosDeCosto.data,
        cargos: cargos.data,
        selectedPersona: [],
        selectedFechaInicio: firstDay,
        selectedFechaFin: lastDay,
        idEmpresa,
        trabajadoresFiltrados: respuesta.data,
        TrabajadoresCount: respuesta.trabajadoresCount[0].myCount,
        filtroPActual: 1,
        selectedDates:[moment.utc().startOf("month"),moment.utc().endOf("month")],
        firstDaysOfTheMonths: arrayIniciosDeMes
      })

      this.ordenarDataCalendario(respuesta, firstDay, lastDay);
    }
  };
   //<--Btn Paginacion-->
  paginationFunc = async(page, limit)=>{
    //console.log(this.state)
    this.setState({loading:true});
    const {selectedFechaInicio,selectedFechaFin,idEmpresa,selectedCargo:sCargo} = this.state;

    const skip = (( limit * page ) - limit);
    const objBusqueda = {
      empresa: idEmpresa,
      centroCosto: null,
      cargo: sCargo,
      fechaInicio: selectedFechaInicio,
      fechaFin: selectedFechaFin,
      limit:limit,
      skip:skip
    };

    //if (fechas) {
      // const fechaInicio = convertirFechaString(moment.utc(fechas[0]), 1).fechaString;
      // const fechaFin = convertirFechaString(moment.utc(fechas[1]), 2).fechaString;
      const obternerReporteAsis =  await this.buscarTrabajadores(objBusqueda);
      this.setState({
        trabajadoresFiltrados: obternerReporteAsis.data,
        TrabajadoresCount: obternerReporteAsis.trabajadoresCount[0].myCount,
        filtroPActual: page,
        limit:limit,
        // skip:skip,
        loading:false
      })
      this.ordenarDataCalendario(obternerReporteAsis, selectedFechaInicio, selectedFechaFin);
      //console.log(obternerReporteAsis.data)
    //}
  }
  //<--Btn Paginacion-->

  ordenarDataCalendario = async (resData, firstDay, lastDay) => {

    let gruposNuevos = [];

    let itemsNuevos = [];

    //Se agregaran nuevos grupos para mostrar al lado derecho de la time line
    resData.data.forEach((grupo) => {

      let nombre = grupo.Nombre.split(' ')[0];
      let apellido = grupo.Apellido_Paterno[0] + '.';
      let cargo = grupo.Cargo.Nombre;
      let nombreCompleto = `${grupo.Nombre} ${grupo.Apellido_Paterno} ${grupo.Apellido_Materno}`;

      if (cargo.length > 18) {
        cargo = cargo.substring(0, 18) + '...';
      }

      let obj = {
        id: grupo._id,
        Imagen: grupo.Imagen,
        Nombre: nombre,
        Apellido: apellido,
        Bitacora: grupo.Bitacora,
        Cargo: cargo,
        NombreCompleto: nombreCompleto,
        height: 44,
      };

      gruposNuevos.push(obj);

      //Se agregan los items (dia a dia) para poder mejorar experiencia
      grupo.Bitacora.forEach((bitacora) => {

        let time = new Date(bitacora.Fecha).getTime();

        let fechaInicio;
        let fechaFin;

        if (bitacora.FechaUnix) {

          fechaInicio = moment.unix(bitacora.FechaUnix).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          });

          fechaFin = moment.unix(bitacora.FechaUnix).set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 59,
          });

        } else {

          fechaInicio = moment.utc(bitacora.Fecha).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          });

          fechaFin = moment.utc(bitacora.Fecha).set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 59,
          });
        }

        let item = {
          id: time + Math.floor(Math.random() * 10000 + 1),
          group: bitacora.IdTrabajador,
          title: "",
          start_time: fechaInicio,
          end_time: fechaFin,
          canMove: false,
          canResize: false,
          canChangeGroup: false,
          colorBarra: coloresEstadoTrabajo.obtenerColorBitacoraTrabajador(
            bitacora.TipoRegistroBitacoraTrabajador
          ),
          bitacora: bitacora,
          leyenda: this.obtenerLeyendaItem(bitacora.TipoRegistroBitacoraTrabajador),
        };
        itemsNuevos.push(item);
      });
    });

    //ingreso el primer dia y el y ultimo ademas de agregar los items y los grupos
    this.setState({
      loading: false,
      grupos: gruposNuevos,
      items: itemsNuevos,
      mostrarCalendario: true,
      firstDay: moment(firstDay).utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0, }),
      lastDay: moment(lastDay).utc().set({ hour: 23, minute: 59, second: 59, millisecond: 59 }),
    });
  }

  obtenerColorUrgencia = (cantidad) => {
    //Sobre 2 Amarillo
    //sobre 5 Rojo

    if (cantidad <= 2) {
      return "color-fondo-verde";
    }

    if (cantidad > 2 && cantidad < 5) {
      return "color-fondo-amarillo";
    }

    if (cantidad >= 5) {
      return "color-fondo-rojo";
    }
  }

  obtenerLeyendaItem = (idBitacora) => {

    switch (idBitacora) {
      // Trabajo efectivo
      case "5f3eedf56dd7cc57f0d3a261":
        return "TE";
      // Descanso
      case "5f3eeb78978b284b68ebced4":
        return "DE";
      // En Tránsito
      case "5f8f369e8efe155a48df37fb":
        return "ET";
      // Vacaciones
      case "5f3eebac978b284b68ebced5":
        return "VA";
      // Licencia
      case "5f3eebf46dd7cc57f0d3a25d":
        return "LI";
      // Licencia Mutual
      case "5f3eec676dd7cc57f0d3a25e":
        return "LM";
      // Permiso
      case "5f3eec876dd7cc57f0d3a25f":
        return "PE";
      // Ausente
      case "5f3eeca16dd7cc57f0d3a260":
        return "AU";
      case "Vacio":
        return "NA"
      default:
        return "NC"
    }
  }

  mostrarCalendario = () => {

    if (this.state.mostrarCalendario === true && this.state.grupos.length === 0 && this.state.items.length === 0) {
      return (
        <Empty
          style={{ marginTop: "30px" }}
          description={
            <p style={{ fontFamily: 'MavenPro-SemiBold' }}>
              No se han encontrado resultados, intenta filtrar con otros parámetros.
            </p>
          }
        />
      )
    } else if (this.state.mostrarCalendario === true) {

      let today = moment().format("YYYY-MM-DD HH:MM");
      let fecha = new Date(today);
      fecha = fecha.getTime();

      let visibleStart = this.state.firstDay.unix();
      let visibleEnd = this.state.lastDay.unix();

      return (
        <>
          <Timeline
            groups={this.state.gruposFiltrado ? this.state.gruposFiltrado : this.state.grupos}
            items={this.state.items}
            sidebarWidth={200}
            // defaultTimeStart={this.state.firstDay}
            // defaultTimeEnd={this.state.lastDay}
            visibleTimeStart={visibleStart * 1000} // *1000 porque pide unix en milliseconds
            visibleTimeEnd={visibleEnd * 1000}
            rightSidebarWidth={400}

            groupRenderer={(data) => {
              if (
                data.isRightSidebar !== undefined &&
                data.isRightSidebar === true
              ) {

                const bitacoras = data.group.Bitacora;

                let trabajoEfectivo = 0;
                let Descanso = 0;
                let Vacaciones = 0;
                let Licencias = 0;
                let LicenciaMutual = 0;
                let Permiso = 0;
                let Ausente = 0;
                let EnTransito = 0;
                let vacio = 0;
                let noControlado = 0;

                bitacoras.forEach((bitacora) => {
                  switch (bitacora.TipoRegistroBitacoraTrabajador) {
                    // Trabajo efectivo
                    case "5f3eedf56dd7cc57f0d3a261":
                      trabajoEfectivo++;
                      break;

                    // Descanso
                    case "5f3eeb78978b284b68ebced4":
                      Descanso++;
                      break;

                    // Vacaciones
                    case "5f3eebac978b284b68ebced5":
                      Vacaciones++;
                      break;

                    // Licencia
                    case "5f3eebf46dd7cc57f0d3a25d":
                      Licencias++;
                      break;

                    // Licencia Mutual
                    case "5f3eec676dd7cc57f0d3a25e":
                      LicenciaMutual++;
                      break;

                    // Permiso
                    case "5f3eec876dd7cc57f0d3a25f":
                      Permiso++;
                      break;

                    // Ausente
                    case "5f3eeca16dd7cc57f0d3a260":
                      Ausente++;
                      break;

                    // En Transito
                    case "5f8f369e8efe155a48df37fb":
                      EnTransito++;
                      break;
                    //Vacio o No asignado
                    case "Vacio":
                      vacio++;
                      break;

                    default:
                      noControlado++;
                      break;
                  }
                });

                return (
                  <Row
                    className="contenedor-header-tabla-derecha" >
                    <Col span={3}
                      className={trabajoEfectivo === 0 ? "color-fondo-rojo" : "color-fondo-verde"}
                      style={{ textAlign: "center" }}>
                      <h6>{trabajoEfectivo}</h6>
                    </Col>

                    <Col span={3}
                      className="color-fondo-verde"
                      style={{ textAlign: "center" }}>
                      <h6>{Descanso === 0 ? '-' : Descanso}</h6>
                    </Col>

                    <Col span={3}
                      className="color-fondo-verde"
                      style={{ textAlign: "center" }}>
                      <h6>{Vacaciones === 0 ? '-' : Vacaciones}</h6>
                    </Col>

                    <Col span={3}
                      className={this.obtenerColorUrgencia(EnTransito)}
                      style={{ textAlign: "center" }}>
                      <h6>{EnTransito === 0 ? '-' : EnTransito}</h6>
                    </Col>

                    <Col span={3}
                      className={this.obtenerColorUrgencia(Licencias)}
                      style={{ textAlign: "center" }}>
                      <h6>{Licencias === 0 ? '-' : Licencias}</h6>
                    </Col>

                    <Col span={3}
                      className={this.obtenerColorUrgencia(LicenciaMutual)}
                      style={{ textAlign: "center" }}>
                      <h6>{LicenciaMutual === 0 ? '-' : LicenciaMutual}</h6>
                    </Col>

                    <Col span={3}
                      className={this.obtenerColorUrgencia(Permiso)}
                      style={{ textAlign: "center" }}>
                      <h6>{Permiso === 0 ? '-' : Permiso}</h6>
                    </Col>

                    <Col span={3}
                      className={this.obtenerColorUrgencia(Ausente)}
                      style={{ textAlign: "center" }}>
                      <h6>{Ausente === 0 ? '-' : Ausente}</h6>
                    </Col>

                  </Row>
                );

              } else {

                return (
                  <Row>
                    <Col span={6} style={{ textAlign: 'center' }}>
                      <img
                        src={data.group.Imagen}
                        style={{
                          width: 30,
                          height: 30,
                          borderRadius: "100%",
                        }}
                        alt="icono"
                      />
                    </Col>
                    <Col span={18}>
                      <div
                        style={{
                          height: 'fit-content',
                          position: 'relative',
                          bottom: '6px',
                        }}
                      >
                        <h1
                          //className="nombre-asistencia-timeline regular-maven"
                          style={{ margin: 0, fontFamily: 'MavenPro-Regular', height: 15 }}
                        >
                          {data.group.Nombre} {data.group.Apellido}
                        </h1>

                        <p
                          style={{
                            margin: 0,
                            height: 15,
                            fontFamily: 'MavenPro-Medium',
                          }}
                        >
                          {data.group.Cargo}
                        </p>
                      </div>
                    </Col>
                  </Row>
                );
              }
            }}

            itemRenderer={({
              item,
              itemContext,
              getItemProps,
              getResizeProps,
            }) => {
              const {
                left: leftResizeProps,
                right: rightResizeProps,
              } = getResizeProps();

              return (
                <div
                  {...getItemProps(item.itemProps)}
                  className={`barra-color ${item.colorBarra}`}
                  onDoubleClick={() => {
                    //this.dobleClickBarra(item.bitacora);
                  }}
                >
                  {itemContext.useResizeHandle ? (
                    <div {...leftResizeProps} />
                  ) : (
                      ""
                    )}

                  <div
                    className="cont-numeros-items-asistencia-personas"
                    style={{
                      maxHeight: `${itemContext.dimensions.height}`,
                    }}
                  >
                    <p style={{ fontSize: '11px', color: 'white' }}>{item.leyenda}</p>
                  </div>

                  {itemContext.useResizeHandle ? (
                    <div {...rightResizeProps} />
                  ) : (
                      ""
                    )}
                </div>
              );
            }}
          >
            <TodayMarker />

            <CustomMarker date={fecha}>
              {({ styles, date }) => {
                const customStyles = {
                  ...styles,
                  backgroundColor: "#74c9fc",
                  width: "4px",
                };
                return <div style={customStyles} />;
              }}
            </CustomMarker>

            <TimelineHeaders
              style={{
                backgroundColor: "#f4f4f4",
              }}
              calendarHeaderClassName="calendarioHeader"
            >
              <SidebarHeader variant="right">

                {({ getRootProps, data }) => {
                  return (
                    <div
                      {...getRootProps()}
                      className="contenedor-derecho-data"
                    >
                      <Row className="contenedor-header-tabla-derecha">
                        <Col span={3} style={{ textAlign: "center" }}>
                          <h6>Trabajado</h6>
                        </Col>
                        <Col span={3} style={{ textAlign: "center" }}>
                          <h6>Descanso</h6>
                        </Col>
                        <Col span={3} style={{ textAlign: "center" }}>
                          <h6>Vacaciones</h6>
                        </Col>
                        <Col span={3} style={{ textAlign: "center" }}>
                          <h6>En Tránsito</h6>
                        </Col>
                        <Col span={3} style={{ textAlign: "center" }}>
                          <h6> Licencias</h6>
                        </Col>
                        <Col span={3} style={{ textAlign: "center" }}>
                          <h6> Licencia Mutual</h6>
                        </Col>
                        <Col span={3} style={{ textAlign: "center" }}>
                          <h6> Permiso</h6>
                        </Col>
                        <Col span={3} style={{ textAlign: "center" }}>
                          <h6> Ausente</h6>
                        </Col>
                      </Row>
                    </div>
                  );
                }}

              </SidebarHeader>

              <DateHeader unit="primaryHeader" className="mesHeader" />

              <DateHeader
                style={{
                  fontFamily: "MavenPro-SemiBold",
                  color: "black",
                  height: 40,
                }}
              />

            </TimelineHeaders>

          </Timeline>
        </>
      );
    } else {
      return false;
    }
  };

  // setCentroCosto = (val) => {
  //   this.setState({
  //     selectedCentroCosto: val,
  //   })
  // }

  setCargo = async (val) => {
    this.setState({
      loading:true
    });

    let fechaInicio = this.state.selectedFechaInicio;
    let fechaFin = this.state.selectedFechaFin;

    //VALIDACIÓN: FECHAS NO ESTÁ VACÍO
    if (fechaInicio === null || fechaFin === null) {

      Swal.fire({
        title: "¡Ups!",
        text: "Debes seleccionar un rango de fechas para filtrar.",
        icon: "warning",
        confirmButtonColor: '#e60aa3',
        confirmButtonText: "Ok",
      });

      return;
    }

    const objFiltrar = {
      empresa: this.state.idEmpresa,
      cargo: val,
      fechaInicio,
      fechaFin,
    };


    const respTrabajadoresPorCargo = await this.buscarTrabajadoresFiltradosPorCargo(objFiltrar);
    if(respTrabajadoresPorCargo.status === 200){
      this.setState({
        selectedCargo: val,
        trabajadoresBuscadosFiltros:respTrabajadoresPorCargo.data,
        selectedPersona:[],
        loading:false
      });
    }else{
      Swal.fire({
        title: "¡Ups!",
        text: `Ha ocurrido un problema al buscar trabajadores, Status: ${respTrabajadoresPorCargo.status}`,
        icon: "warning",
        confirmButtonColor: "#1FC600",
        confirmButtonText: "Ok",
      });
      this.setState({
        selectedCargo: val,
        trabajadoresBuscadosFiltros:[],
        selectedPersona:[],
        loading:false
      });
    }
  }
  setSelectedPersona = (val) => {
    this.setState({
      selectedPersona: val,
    })
  }

  setFechas = (fechas) => {
    
    if (fechas && fechas[0] && fechas[1]) {

      let fechaInicio = fechas[0].clone();
      let fechaFin = fechas[1].clone();

      // fechaInicio = momentToStringFechaInicio(fechaInicio, databaseFormat);
      // fechaFin = momentToStringFechaFin(fechaFin, databaseFormat);
      fechaInicio = convertirFechaString(fechaInicio,1).fechaString;
      fechaFin = convertirFechaString(fechaFin,2).fechaString;

      this.setState({
        selectedFechaInicio: fechaInicio,
        selectedFechaFin: fechaFin,
        selectedDates:fechas,
      })

    } else {

      this.setState({
        selectedFechaInicio: null,
        selectedFechaFin: null,
        selectedDates:[null,null],
      })
    }
  }

  filtrar = async () => {
    let fechaInicio = this.state.selectedFechaInicio;
    let fechaFin = this.state.selectedFechaFin;

    //VALIDACIÓN: FECHAS NO ESTÁ VACÍO
    if (fechaInicio === null || fechaFin === null) {

      Swal.fire({
        title: "¡Ups!",
        text: "Debes seleccionar un rango de fechas para filtrar.",
        icon: "warning",
        confirmButtonColor: '#e60aa3',
        confirmButtonText: "Ok",
      });

      return;
    }
    this.setState({
      loading: true,
      trabajadorSearch: '',
      gruposFiltrado: null,
    });

    const objFiltrar = {
      empresa: this.state.idEmpresa,
      centroCosto: this.state.selectedCentroCosto,
      cargo: this.state.selectedCargo,
      selectedPersona: this.state.selectedPersona,
      fechaInicio,
      fechaFin,
      limit: this.state.limit,
      skip:0,
    };

    const respuesta = await this.buscarTrabajadoresFiltrado(objFiltrar);

    let arrayIniciosDeMes = [];

    let fechaInicioOriginal = convertirFechaString(moment.utc(fechaInicio), 1).fechaString;
    let fechaInicioString = convertirFechaString(moment.utc(fechaInicio).startOf("month"), 1).fechaString;
    let fechaFinString = convertirFechaString(moment.utc(fechaFin).endOf("month"), 2).fechaString;
    let diferenciaMeses = moment.utc(fechaFinString).diff(moment.utc(fechaInicioString), 'months') + 1;

    for (let index = 0; index < diferenciaMeses; index++) {
      let fechaAgregar;

      if(index === 0){
        if(!moment.utc(fechaInicioString).isSame(moment.utc(fechaInicioOriginal))){
          fechaAgregar = moment.utc(fechaInicioOriginal);
        } else {
          fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
        }
      } else {
        fechaAgregar = moment.utc(fechaInicioString).add(index, "months");
      }

      arrayIniciosDeMes.push(convertirFechaString(fechaAgregar, 1).fechaString);
    }

    // console.log("respuesta.data");
    // console.log(respuesta.data);

    this.setState({ 
      trabajadoresFiltrados: respuesta.data,
      TrabajadoresCount: respuesta.trabajadoresCount[0] === undefined?0:respuesta.trabajadoresCount[0].myCount,
      filtroPActual: 1,
      firstDaysOfTheMonths: arrayIniciosDeMes,
    });

    this.ordenarDataCalendario(respuesta, fechaInicio, fechaFin);
  }

  buscar = async (busqueda) => {

    let search = busqueda.toUpperCase();
    let grupos = this.state.grupos;

    if (search) {

      let trabajadoresFiltrados = grupos.filter(grupo => {

        let nombreTrabajador = grupo.NombreCompleto.toUpperCase();
        return nombreTrabajador.includes(search);
      })

      this.setState({
        gruposFiltrado: trabajadoresFiltrados,
      })

    } else {
      this.setState({
        gruposFiltrado: null,
      })
    }
  }

  descargarExcel = () => {

    //this.setState({ loading: true });

    // // Preparando data para sacar archivo excel
    // const { trabajadoresFiltrados, firstDay, lastDay } = this.state;

    // const arrTrabajadores = [];

    // trabajadoresFiltrados.forEach(trabajador => {

    //   const { Rut, Nombre, Apellido_Paterno, Apellido_Materno, Cargo } = trabajador;

    //   let objTrabajador = {
    //     rut: Rut,
    //     nombreCompleto: `${Apellido_Paterno} ${Apellido_Materno} ${Nombre}`,
    //     cargo: Cargo.Nombre,
    //   }

    //   //contabilización de bitácoras de cada tipo
    //   let trabajo = 0;
    //   let descanso = 0;
    //   let vacaciones = 0;
    //   let transito = 0;
    //   let licencia = 0;
    //   let licenciaMutual = 0;
    //   let permisoConGoce = 0;
    //   let permisoSinGoce = 0;
    //   let ausente = 0;
    //   let vacio = 0;
    //   let sinAsignar;

    //   trabajador.Bitacora.forEach((bitacora) => {

    //     switch (bitacora.TipoRegistroBitacoraTrabajador) {

    //       // Trabajo efectivo
    //       case "5f3eedf56dd7cc57f0d3a261":
    //         trabajo++;
    //         break;

    //       // Descanso
    //       case "5f3eeb78978b284b68ebced4":
    //         descanso++;
    //         break;

    //       // En Tránsito
    //       case "5f8f369e8efe155a48df37fb":
    //         transito++;
    //         break;

    //       // Vacaciones
    //       case "5f3eebac978b284b68ebced5":
    //         vacaciones++
    //         break;

    //       // Licencia
    //       case "5f3eebf46dd7cc57f0d3a25d":
    //         licencia++;
    //         break;

    //       // Licencia Mutual
    //       case "5f3eec676dd7cc57f0d3a25e":
    //         licenciaMutual++;
    //         break;

    //       // Permiso
    //       case "5f3eec876dd7cc57f0d3a25f":

    //         let idsPermisosConGoce = ['5f5a870f002d3205dc7f24f2', '5f5a8754002d3205dc7f24f4'];
    //         let tipoPermiso = bitacora.Permiso.TipoPermiso;


    //         // es con goce o sin goce de sueldo ?
    //         if (idsPermisosConGoce.includes(tipoPermiso)) {
    //           permisoConGoce++;
    //         } else {
    //           permisoSinGoce++;
    //         }

    //         break;

    //       // Ausente
    //       case "5f3eeca16dd7cc57f0d3a260":
    //         ausente++;
    //         break;
    //       case "Vacio":
    //         vacio++;
    //         break;
    //     }
    //   })

    //   const cantidadDias = firstDay.clone().daysInMonth();
    //   sinAsignar = cantidadDias - trabajador.Bitacora.length;

    //   if (trabajo === 0) trabajo = '-';
    //   if (descanso === 0) descanso = '-';
    //   if (vacaciones === 0) vacaciones = '-';
    //   if (transito === 0) transito = '-';
    //   if (licencia === 0) licencia = '-';
    //   if (licenciaMutual === 0) licenciaMutual = '-';
    //   if (permisoConGoce === 0) permisoConGoce = '-';
    //   if (permisoSinGoce === 0) permisoSinGoce = '-';
    //   if (ausente === 0) ausente = '-';
    //   if (vacio === 0) vacio = '-';
    //   if (sinAsignar === 0) sinAsignar = '-';

    //   const objBitacoras = {
    //     trabajo,
    //     descanso,
    //     vacaciones,
    //     transito,
    //     licencia,
    //     licenciaMutual,
    //     permisoConGoce,
    //     permisoSinGoce,
    //     ausente,
    //     vacio,
    //     sinAsignar
    //   }

    //   objTrabajador = { ...objBitacoras, ...objTrabajador };
    //   arrTrabajadores.push(objTrabajador);
    // });

    // // Creando título del reporte
    // let inicio = firstDay.clone().format('DD-MM-YYYY');
    // let fin = lastDay.clone().format('DD-MM-YYYY');

    // let titulo = `Reporte de Asistencia de ${inicio} hasta ${fin}`;

    // const objEnviar = { titulo, arrTrabajadores };

    // enviando request

    let fechaInicio = this.state.selectedFechaInicio;
    let fechaFin = this.state.selectedFechaFin;

    //VALIDACIÓN: FECHAS NO ESTÁ VACÍO
    if (fechaInicio === null || fechaFin === null) {

      Swal.fire({
        title: "¡Ups!",
        text: "Debes seleccionar un rango de fechas para filtrar.",
        icon: "warning",
        confirmButtonColor: '#e60aa3',
        confirmButtonText: "Ok",
      });

      return;
    }
    
    //TRANSFORMACION DE FECHAS A MOMENT
    const momentFechaInicio = moment(fechaInicio);
    const momentFechaFin = moment(fechaFin);

    //DIFERENCIA DE MESES Y DIAS
    const diferenciaEnMeses = momentFechaFin.diff(momentFechaInicio, "months");
    const diferenciaEnDias = momentFechaFin.diff(momentFechaInicio, "days") + 1;
    // console.log("Cantidad de meses entre las fechas:", diferenciaEnMeses);
    // console.log("Cantidad de dias entre las fechas:", diferenciaEnDias);

    //VALIDACIÓN: MESES O DIAS
    if (diferenciaEnMeses >=4 || diferenciaEnDias >=100) {

      Swal.fire({
        title: "¡Ups!",
        text: `No se puede descargar más de 4 meses o exceder los 100 días. Usted seleccionó un período de ${diferenciaEnMeses} meses.`,
        icon: "warning",
        confirmButtonColor: '#e60aa3',
        confirmButtonText: "Ok",
      });

      return;
    }

    this.setState({ loading: true });
    const objFiltrar = {
      empresa: this.state.idEmpresa,
      centroCosto: this.state.selectedCentroCosto,
      cargo: this.state.selectedCargo,
      selectedPersona: this.state.selectedPersona,
      fechaInicio,
      fechaFin,
    };

    // this.setState({
    //   loading: true,
    // });

    this.postDescargarExcel(objFiltrar);
  }

  /*************** INICIO API  ****************/

  buscarTrabajadores = async (objBusqueda) => {

    try {
      //const url = `${API}/Trabajadores/ver/asistencia/filtrado?limite=${this.state.limiteBusqueda}`;
      const url = `${API}/Trabajadores/ver/asistencia/filtrado`;
      //console.log(objBusqueda)
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objBusqueda),
      });

      const status = await response.status;
      const data = await response.json();
    
      let respuesta;
      if (status === 200 || status === 201) {

        respuesta = {
          status: status,
          data: data.trabajadores,
          trabajadoresCount: data.cantRegistros
        };

      } else {
        respuesta = {
          status: status,
          data: data.err,
        };
      }

      return respuesta;

    } catch (error) {

      let respuesta;

      respuesta = {
        status: 500,
        data: error,
      };

      return respuesta;
    }
  };

  obtenerCentrosDeCostos = async (idEmpresa) => {
    try {
      const url = `${API}/CentroDeCostos?empresa=${idEmpresa}`;

      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = await response.status;
      const data = await response.json();

      let respuesta;

      if (status === 200 || status === 201) {
        respuesta = {
          status: status,
          data: data.centrosDeCostos,
        };

      } else {

        respuesta = {
          status: status,
          data: data.err,
        };
      }

      return respuesta;

    } catch (error) {

      let respuesta;

      respuesta = {
        status: 500,
        data: error,
      };

      return respuesta;
    }
  };

  obtenerCargos = async (idEmpresa) => {

    try {
      const url = `${API}/CargosTrabajadores?empresa=${idEmpresa}`;

      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = await response.status;
      const data = await response.json();

      let respuesta;

      if (status === 200 || status === 201) {

        respuesta = {
          status: status,
          data: data.data,
        };

      } else {

        respuesta = {
          status: status,
          data: data.err,
        };
      }

      return respuesta;

    } catch (error) {

      let respuesta;

      respuesta = {
        status: 500,
        data: error,
      };

      return respuesta;
    }
  };

  buscarTrabajadoresFiltrado = async (objFiltrar) => {
    
    try {
      // const url = `${API}/Trabajadores/ver/asistencia/filtrado?limite=${this.state.limiteBusqueda}`;
      const url = `${API}/Trabajadores/ver/asistencia/filtrado`;

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objFiltrar),
      });

      const status = await response.status;
      const data = await response.json();

      let respuesta;

      if (status === 200 || status === 201) {

        respuesta = {
          status: status,
          data: data.trabajadores,
          trabajadoresCount: data.cantRegistros
        };

      } else {
        respuesta = {
          status: status,
          data: data.err,
        };
      }

      return respuesta;

    } catch (error) {

      let respuesta;

      respuesta = {
        status: 500,
        data: error,
      };

      return respuesta;
    }

  }

  buscarTrabajadoresFiltradosPorCargo = async (objFiltrar) => {
    try {
      // const url = `${API}/Trabajadores/ver/asistencia/filtrado?limite=${this.state.limiteBusqueda}`;
      const url = `${API}/Trabajadores/Por/Cargo/Contratos`;

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objFiltrar),
      });

      const status = await response.status;
      const data = await response.json();
      let respuesta;
      if (status === 200 || status === 201) {
        respuesta = {
            status: status,
            data: data.trabajadores,
        };
      } else {
        respuesta = {
          status: status,
          data: data.err,
        };
      }

      return respuesta;

    } catch (error) {
      let respuesta = {
        status: 500,
        data: error,
      };
      return respuesta;
    }
  }

  postDescargarExcel = async (objEnviar) => {

    try {

      const url = `${API}/Trabajadores/ReporteAsistencia/Excel/${this.state.idEmpresa}`;

      const fetchObj = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(objEnviar),
      };

      const response = await fetch(url, fetchObj);

      const status = await response.status;

      if (status === 200 || status === 201) {

        let blob = await response.blob();

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `AsistenciaPersonas.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();

        Swal.fire({
          title: "¡Bien!",
          text: `Se ha descargado el documento Excel.`,
          icon: "success",
          confirmButtonColor: "#1FC600",
          confirmButtonText: "Ok",
        });

      } else {

        Swal.fire({
          title: "¡Ups!",
          text: `Ha ocurrido un problema al generar el reporte en formato Excel.`,
          icon: "warning",
          confirmButtonColor: "#1FC600",
          confirmButtonText: "Ok",
        });

      }

      this.setState({ loading: false });

    } catch (err) {
      this.setState({ loading: false });
      console.error(err);
      return;
    }
  }

  /***************** FIN API  ******************/

  render() {
    const { trabajadoresFiltrados, firstDaysOfTheMonths } = this.state;
    return (
      <Layout>
        <Loading cargando={this.state.loading} />

        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Asistencia de personal"
            subtitulo="Verifica la asistencia de tus trabajadores"
          />
        </Header>

        <hr className="division" />

        <Content
          className="contenedor-general cont-tabla-celeste maven-r"
          style={{ background: "none" }}
        >

          {/* TÍTULOS DE FILTROS */}
          <Row gutter={10}>

            <Col span={4}>
              <h4 style={{ fontFamily: 'MavenPro-Regular' }}>
                Filtrar:
              </h4>
            </Col>

            <Col span={15}></Col>

            {/* <Col span={5}>
              <h4 style={{ fontFamily: 'MavenPro-Regular' }}>
                Búsqueda por nombre:
              </h4>
            </Col> */}
          </Row>

          {/* FILTROS */}
          <Row style={{ marginBottom: '20px' }} gutter={10}>
            {/* DATEPICKER */}
            <Col 
              //span={4}
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 4, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 4, offset: 0 }}
            >
              <RangePicker
                value={this.state.selectedDates}
                style={{ width: '100%', borderRadius: '8px' }}
                locale={locale}
                allowClear={false}
                onChange={(fechas) => {
                  this.setFechas(fechas)
                }}
              />
            </Col>

            {/* CARGOS */}
            <Col 
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 4, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 4, offset: 0 }}
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="Cargos"
                onSelect={(value) => {
                  this.setCargo(value);
                }}
                onClear={() => {
                  this.setState({
                    selectedCargo: null,
                    selectedPersona:[],
                    trabajadoresBuscadosFiltros:[],
                  })
                }}
              >
                {this.state.cargos.map((value, index) => (
                  <Select.Option key={index} value={value._id} name="Cargo">
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            
            {/* PERSONAS */}
            <Col 
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 4, offset: 0 }}
              xl={{ span: 4, offset: 0 }}
              xxl={{ span: 4, offset: 0 }}
            >
              <Select
                allowClear
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Seleccionar Persona/s"
                disabled={this.state.selectedCargo === null ? true : false}
                
                onChange={(val) => {
                  this.setState({ selectedPersona: val });
                }}
                value={this.state.selectedPersona}
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
              >
                {this.state.trabajadoresBuscadosFiltros.map((value, index) => (
                  <Select.Option key={index} value={value._id} name="Persona">
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            {/* BTN FILTRAR */}
            <Col 
              //span={3}
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 3, offset: 0 }}
              xl={{ span: 3, offset: 0 }}
              xxl={{ span: 3, offset: 0 }}
            >
              <Button
                //className="rep-asistencia-filtrar-btn"
                style={{
                  color: '#FFFFFF', 
                  textAlign: 'center',
                  fontFamily: 'MavenPro-Bold',
                  width: '156px',
                  height: '30px',
                  background: '#64B4E5 0% 0% no-repeat padding-box',
                  borderRadius: '9px',
                  opacity: '1',
                }}
                onClick={() => {
                  this.filtrar();
                }}
              >
                Filtrar
              </Button>
            </Col>

            <Col flex={'auto'}></Col>

            {/* SEARCH BAR */}
            {/* <Col span={5}>

              <Search
                placeholder="Buscar trabajadores"
                onChange={(e) => {
                  this.setState({
                    trabajadorSearch: e.target.value,
                  })
                  this.buscar(e.target.value);
                }}

                value={this.state.trabajadorSearch}
                enterButton
              />

            </Col> */}


          </Row>


          <Row align={'bottom'}>
            <Col span={18} style={{ textAlign: 'left', marginBottom: '0px' }}>
              {/* <div style={{ textAlign: 'left' }}>
                <p style={{ fontFamily: 'MavenPro-Regular', marginBottom: '5px' }}>
                  Actualmente mostrando reporte de {moment.utc(this.state.firstDay).format('DD-MM-YYYY')} hasta {moment.utc(this.state.lastDay).format('DD-MM-YYYY')}
                </p>
              </div> */}
              <Col style={{ textAlign: 'left' }}>
                <p style={{ fontFamily: 'MavenPro-Regular', marginBottom: '5px' }}>
                  <p style={{fontFamily: 'MavenPro-Bold'}}>Actualmente mostrando reporte de:<b style={{fontFamily: 'MavenPro-Regular'}}>{moment.utc(this.state.firstDay).format('DD-MM-YYYY')} hasta {moment.utc(this.state.lastDay).format('DD-MM-YYYY')}</b></p>
                </p>
              </Col>

              <Card 
                // className={'maven-r'} 
                // style={{
                // background:'#ffffff 0% 0% no-repeat padding-box;',
                // borderRadius:'16px',
                // boxShadow:'0px 3px 6px #00000029;',
                // border:'5px solid #e2e2e2;',
                // width:'1212px',//'650px''1320px',
                // opacity:'1',
                // marginBottom:'10px'
                // }}
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                xl={{ span: 24, offset: 0 }}
                xxl={{ span: 24, offset: 0 }}
                style={{
                  background:'#ffffff 0% 0% no-repeat padding-box;',
                  borderRadius:'16px',
                  boxShadow:'0px 3px 6px #00000029;',
                  border:'5px solid #e2e2e2;',
                  opacity:'1',  
                }}
              >
                {/* <Col> */}
                
                  <Row>
                    <Col style={{fontFamily:'MavenPro-Bold',marginTop:'18px'}}>Leyenda</Col>
                    {/* <Col><img src={TrabajoEfectivo} style={{width:'35px',height:'35px',marginLeft:'50%'}}></img><Col><h1>Trabajo efectivo</h1></Col></Col> */}
                    <Row>
                    {/* width: 60%;margin: auto;display: block;display: flex;justify-content: center; */}
                      <Col span={24}><img src={TrabajoEfectivo} style={{width:'35px',height:'35px',margin:'auto',display:'block'}}></img></Col>
                      <Col span={24} style={{textAlign:'center'}}>Trabajo efectivo </Col>
                    </Row>

                    <Row>
                      <Col span={24}><img src={Descanso} style={{width:'35px',height:'35px',margin:'auto',display:'block'}}></img></Col>   
                      <Col span={24} style={{textAlign:'center'}}>Descanso </Col>
                    </Row>

                    <Row>
                      <Col span={24}><img src={Vacaciones} style={{width:'35px',height:'35px',margin:'auto',display:'block'}}></img></Col>
                      <Col span={24} style={{textAlign:'center'}}>Vacaciones </Col>
                    </Row>

                    <Row>
                      <Col span={24}><img src={EnTransito} style={{width:'35px',height:'35px',margin:'auto',display:'block'}}></img></Col>
                      <Col span={24} style={{textAlign:'center'}}>En tránsito </Col>
                    </Row>

                    <Row>
                      <Col span={24}><img src={Licencia} style={{width:'35px',height:'35px',margin:'auto',display:'block'}}></img></Col>
                      <Col span={24} style={{textAlign:'center'}}>Licencia </Col>
                    </Row>
                   
                  <Row>
                    <Col span={24}><img src={LicenciaMutual} style={{width:'35px',height:'35px',margin:'auto',display:'block'}}></img></Col>
                    <Col span={24} style={{textAlign:'center'}}>Licencia mutualidad </Col>
                  </Row>

                  <Row>
                    <Col span={24}><img src={Permiso} style={{width:'35px',height:'35px',margin:'auto',display:'block'}}></img></Col>
                    <Col span={24} style={{textAlign:'center'}}>Permiso </Col>
                  </Row>

                  <Row>
                    <Col span={24}><img src={Ausente} style={{width:'35px',height:'35px',margin:'auto',display:'block'}}></img></Col>
                    <Col span={24} style={{textAlign:'center'}}>Ausente </Col>
                  </Row>

                  <Row>
                    <Col span={24}><img src={NoAsignado} style={{width:'35px',height:'35px',margin:'auto',display:'block'}}></img></Col>
                    <Col span={24} style={{textAlign:'center'}}>No asignado </Col>
                  </Row>

                  <Row>
                    <Col span={24}><img src={NoContratado} style={{transform:`rotate(45deg)`,filter:'grayscale(100%)',width:'35px',height:'35px',margin:'auto',display:'block'}}></img></Col>
                    <Col span={24} style={{textAlign:'center'}}>No contratado </Col> 
                  </Row>
                    
                </Row>
      

              </Card>

            </Col>

            {/* <Col span={6} style={{ textAlign: 'right' }}>
              <div style={{ marginBottom: '10px', textAlign: 'right' }}>
                <Button
                  className="rep-asistencia-excel-btn"
                  onClick={() => {
                    this.descargarExcel();
                  }}
                >
                  Descargar Reporte En Excel
                </Button>
              </div>
            </Col> */}

            
            <Col 
              //span={6} style={{ textAlign: 'right' }}
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
              xxl={{ span: 6, offset: 0 }}
              style={{ textAlign: 'right' }}
            >
              <div style={{ marginBottom: '10px', textAlign: 'right',cursor:'pointer' }}>
                <img src={descargarExcel} onClick={() => {
                    this.descargarExcel();
                  }}></img>
              </div>
            </Col>

          </Row>

            {/* //nueva timeline */}
             {/* TIMELINE ANTIGUA CODIGO */}

             {trabajadoresFiltrados.length > 0 
              ?
              (
                <>
                {
                  trabajadoresFiltrados.map((trabajadores) =>(
                  <>
                  
                    <Card style={{background:'#F4F4F4',border:'1px solid #e2e2e2',borderRadius:"16px",boxShadow:'0px 3px 6px #00000029',marginTop:'15px'}}>
                      <Row>

                        <Col 
                          //span={12}
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                          xxl={{ span: 12, offset: 0 }}
                        >
                          <h1 style={{color:'#061FCB',fontFamily:'MavenPro-Bold'}}>Calendario del trabajador:</h1>
                          <h1 style={{fontFamily: 'MavenPro-Regular',color:'#000000'}}>{`${trabajadores.Nombre} ${trabajadores.Apellido_Paterno} / ${trabajadores.Cargo.Nombre}`}</h1>
                        </Col>

                        <Col 
                          //span={12} style={{paddingLeft:'270px'}}
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 8, offset: 4 }}
                          xl={{ span: 8, offset: 4 }}
                          xxl={{ span: 8, offset: 4 }}
                          style={{textAlign:'right'}}
                        >
                          <Row >
                            <Row span={24}>
                              <Col style={{fontFamily: 'MavenPro-Bold',color:'#000000'}}>Recuento de días filtrados:</Col>
                            </Row>
                            
                            <Row>
                              <Col span={24}><img src={TrabajoEfectivo} style={{width:'28px',height:'28px',margin:'auto',display:'block'}}></img></Col>
                              <Col span={24} style={{textAlign:'center'}}>{trabajadores.DiasTrabajo}</Col>
                            </Row>

                            <Row>
                              <Col span={24}><img src={Descanso} style={{width:'28px',height:'28px',margin:'auto',display:'block'}}></img></Col>   
                              <Col span={24} style={{textAlign:'center'}}>{trabajadores.DiasDescanso}</Col>
                            </Row>

                            <Row>
                              <Col span={24}><img src={Vacaciones} style={{width:'28px',height:'28px',margin:'auto',display:'block'}}></img></Col>
                              <Col span={24} style={{textAlign:'center'}}>{trabajadores.DiasVacaciones}</Col>
                            </Row>

                            <Row>
                              <Col span={24}><img src={EnTransito} style={{width:'28px',height:'28px',margin:'auto',display:'block'}}></img></Col>
                              <Col span={24} style={{textAlign:'center'}}>{trabajadores.DiasEnTransito}</Col>
                            </Row>

                            <Row>
                              <Col span={24}><img src={Licencia} style={{width:'28px',height:'28px',margin:'auto',display:'block'}}></img></Col>
                              <Col span={24} style={{textAlign:'center'}}>{trabajadores.DiasLicencias}</Col>
                            </Row>
                            
                          <Row>
                            <Col span={24}><img src={LicenciaMutual} style={{width:'28px',height:'28px',margin:'auto',display:'block'}}></img></Col>
                            <Col span={24} style={{textAlign:'center'}}>{trabajadores.DiasLicenciaMutual}</Col>
                          </Row>

                          <Row>
                            <Col span={24}><img src={Permiso} style={{width:'28px',height:'28px',margin:'auto',display:'block'}}></img></Col>
                            <Col span={24} style={{textAlign:'center'}}>{trabajadores.DiasPermiso}</Col>
                          </Row>

                          <Row>
                            <Col span={24}><img src={Ausente} style={{width:'28px',height:'28px',margin:'auto',display:'block'}}></img></Col>
                            <Col span={24} style={{textAlign:'center'}}>{trabajadores.DiasAusente}</Col>
                          </Row>

                          <Row>
                            <Col span={24}><img src={NoAsignado} style={{width:'28px',height:'28px',margin:'auto',display:'block'}}></img></Col>
                            <Col span={24} style={{textAlign:'center'}}>{trabajadores.DiasNoTrabajados}</Col>
                          </Row>

                          <Row>
                            <Col span={24}><img src={NoContratado} style={{transform:`rotate(45deg)`,filter:'grayscale(100%)',width:'28px',height:'28px',margin:'auto',display:'block'}}></img></Col>
                            <Col span={24} style={{textAlign:'center'}}>{trabajadores.DiasNoControlado}</Col> 
                          </Row>
                          </Row>
                          {/* <Col> */}
                                {/* <img src={TrabajoEfectivo} style={{width:'28px',height:'28px'}}></img>0
                                <img src={Descanso} style={{width:'28px',height:'28px'}}></img>0
                                <img src={Vacaciones} style={{width:'28px',height:'28px'}}></img>0
                                <img src={EnTransito} style={{width:'28px',height:'28px'}}></img>0
                                <img src={Licencia} style={{width:'28px',height:'28px'}}></img>0
                                <img src={LicenciaMutual} style={{width:'28px',height:'28px'}}></img>0
                                <img src={Permiso} style={{width:'28px',height:'28px'}}></img>0
                                <img src={Ausente} style={{width:'28px',height:'28px'}}></img>0
                                <img src={NoAsignado} style={{width:'28px',height:'28px'}}></img>0
                                <img src={NoContratado} style={{transform:`rotate(45deg)`,filter:'grayscale(100%)',width:'28px',height:'28px'}}></img>0 */}
                          {/* </Col> */}
                        
                        </Col>
                      </Row>
                      {/* <h1 style={{fontFamily: 'MavenPro-Bold',color:'#ffffff'}}>{trabajadores.Cargo.Nombre}</h1>
                      <h1 style={{fontFamily: 'MavenPro-Bold',color:'#ffffff'}}>{`${trabajadores.Nombre} ${trabajadores.Apellido_Paterno}`}</h1> */}
                      <Card style={{border:'1px solid #e2e2e2',borderRadius:"16px"}}>
                      <div
                        style={{
                          width: "100%",
                          height: "100px",
                          overflowX: "scroll",
                        }}
                        className="contenedor-step-asig-act"
                        >
                          <Steps
                            current={0}
                            progressDot={customDot}
                            className="ant-icon-tamaño testContainer testTail step-fecha-inicio-fin-mes"
                            direction={"horizontal"}
                            labelPlacement={"horizontal"}
                            responsive={false}
                            style={{marginBottom: "0.5rem"}}
                          >
                            {
                              trabajadores.Bitacora.length > 0 && trabajadores.Bitacora.map((bit)=>
                              <>
                                <Step 
                                  key={bit._id} 
                                  title={firstDaysOfTheMonths.filter((fecha) => 
                                    moment.utc(fecha).isSame(moment.utc(convertirFechaString(moment.utc(bit.Fecha), 1).fechaString))).length > 0 && moment.utc(bit.Fecha).format('MMMM').substring(0, 3).toUpperCase()
                                  } 
                                  className={
                                    bit.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261" && "dot-green-timeline"  
                                  }
                                />

                              </>)
                            }
                          </Steps>


                          <Steps
                            current={0}
                            progressDot={customDot}
                            className="ant-icon-tamaño testContainer testTail"
                          > 
                            {
                              // className={bitacora.Estado === "5f3ef5ce1ecf531c1c8ab4a7"? "dot-red" : bitacora.Estado ==="5f3ef6981ecf531c1c8ab4aa"? "dot-blue": bitacora.Estado === "5f3ef6861ecf531c1c8ab4a9"? "dot-green": bitacora.Estado ==="5f3ef6591ecf531c1c8ab4a8"? "dot-yellow" : "dot-white"} 

                              trabajadores.Bitacora.length && trabajadores.Bitacora.map((bitacora)=>
                                <>
                                  <Step 
                                    key={bitacora._id} 
                                    title={moment.utc(bitacora.Fecha).format('DD')} 
                                    // className={bitacora.TipoRegistroBitacoraTrabajador=== "5f3eedf56dd7cc57f0d3a261" ? "dot-green" :bitacora.TipoRegistroBitacoraTrabajador=== "5f3eeb78978b284b68ebced4"? "dot-cyan" :bitacora.TipoRegistroBitacoraTrabajador=== "5f3eebac978b284b68ebced5"? "dot-cyan" :bitacora.TipoRegistroBitacoraTrabajador=== "5f8f369e8efe155a48df37fb"? "dot-violet":bitacora.TipoRegistroBitacoraTrabajador=== "5f3eebf46dd7cc57f0d3a25d"?"dot-orange":bitacora.TipoRegistroBitacoraTrabajador=== "5f3eec676dd7cc57f0d3a25e"?"dot-orange":bitacora.TipoRegistroBitacoraTrabajador=== "5f3eec876dd7cc57f0d3a25f"?"dot-orange":bitacora.TipoRegistroBitacoraTrabajador=== "5f3eeca16dd7cc57f0d3a260"?"dot-orange" : bitacora.TipoRegistroBitacoraTrabajador=== "No Contratado"? "dot-grey"  : "dot-red"} 
                                    className={
                                      bitacora.TipoRegistroBitacoraTrabajador === "5f3eedf56dd7cc57f0d3a261"? "dot-green-timeline" :
                                      bitacora.TipoRegistroBitacoraTrabajador === "5f3eeb78978b284b68ebced4"? "dot-pink-timeline"  :
                                      bitacora.TipoRegistroBitacoraTrabajador === "5f8f369e8efe155a48df37fb"? "dot-lightpurple-timeline":
                                      bitacora.TipoRegistroBitacoraTrabajador === "5f3eebac978b284b68ebced5"? "dot-cyan-timeline"  :
                                      bitacora.TipoRegistroBitacoraTrabajador === "5f3eebf46dd7cc57f0d3a25d"? "dot-electric-blue-timeline":
                                      bitacora.TipoRegistroBitacoraTrabajador === "5f3eec676dd7cc57f0d3a25e"? "dot-yellow-timeline":
                                      bitacora.TipoRegistroBitacoraTrabajador === "5f3eec876dd7cc57f0d3a25f"? "dot-orange-timeline":
                                      bitacora.TipoRegistroBitacoraTrabajador === "5f3eeca16dd7cc57f0d3a260"? "dot-violet-timeline":
                                      bitacora.TipoRegistroBitacoraTrabajador === "5f904101d9c57145a09eb058"? "dot-red-timeline"   :
                                      // "dot-white"
                                      "dot-red-timeline"
                                    }
                                  />
                                </>) 
                              
                            }
                          </Steps>
                      </div> 
                        

                      </Card>
                    </Card>
                    
                  </>))
                }
                <Col style={{ marginTop: '10px',textAlign: 'right'}}>
                  {
                    this.state.TrabajadoresCount!=0 ? <Pagination current={this.state.filtroPActual} onChange={this.paginationFunc} total={this.state.TrabajadoresCount} pageSize={this.state.limit} showSizeChanger={true} pageSizeOptions={["3","10","20","30","50"]}/> : ''
                  }
                </Col>
                
                
            
                </>
              )
              
              : (
                <>
                  <Empty
                    imageStyle={{ height: 200, marginTop: "20px" }}
                    description={
                      <h2 className={"seleccion-trabajador-disponible"}>
                        <b>No Hay registros.</b>
                        <br />
                        <b>Verifica si existen personas en el centro de costos.</b>
                      </h2>
                    }
                  ></Empty>
                </>
              )}
            {/* .... */}




            {/* //fin nueva timeline */}

 
                       

        </Content>

      </Layout>
    );
  }

}

export default ReporteriaAsistenciaPersonas;
