import React, { Component } from "react";

import { connect } from "react-redux";
import * as mantenedorCentroCostosActions from "../../Redux/actions/MantenedorCentroCostosActions";

import activo from "../../img/icons/activo.svg";
import inactivo from "../../img/icons/inactivo.svg";

import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import FormularioCentroCosto from "../../components/formularioCentroCosto/FormularioCentroCosto";
import FormularioCentroCostoEditar from "../../components/formularioCentroCosto/FormularioCentroCostoEditar";

import { Layout, Table, Space, Button, Row, Col, Input, Modal } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";

import Swal from "sweetalert2";
import accesosPagina from '../../funcionesGenericas/accesosUsuario';

const { Header, Content } = Layout;
const { Search } = Input;
const { Column } = Table;

class MantenedorCentroCosto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tablaCargando: false,
      form: {
        Nombre: "",
        Empresa: "",
        Codigo_Base_Sistema: "",
        Vigencia: true,
      },

      size: "large",
      centroCostoFiltros: null,
    };

    this.cambiarEstadoVigencia = this.cambiarEstadoVigencia.bind(this);
    this.crearCentroCosto = this.crearCentroCosto.bind(this);
    this.EditarCentroCosto = this.EditarCentroCosto.bind(this);
    this.TomarValoresEditar = this.TomarValoresEditar.bind(this);
  }

  search = (value) => {
    let busqueda = value.target.value;
    if (value !== "") {
      const centroCostoRedux = this.props.centroCostos;

      const centroCostoFiltros = centroCostoRedux.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(busqueda)
        )
      );

      this.setState({ centroCostoFiltros });
    } else {
      this.setState({ centroCostoFiltros: null });
    }
  };

  mostrarModalCrear = () => {
    this.props.MostrarModalCentroCosto();
  };

  async componentDidMount() {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      const accesos = await accesosPagina.obtenerAccesosPagina('MantenedorCentroDeCostos');

      this.setState({
        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
      });

      await this.props.ObtenerEmpresas();
      await this.props.ObtenerCentroCostos(usuario.EmpresaSeleccionada.Empresa._id);
    }
  }

  modalCrearCancelar = () => {
    this.props.OcultarModalCentroCosto();
  };

  modalEditarCancelar = () => {
    this.props.OcultarModalCentroCostoEditar();
  };

  cambiarEstadoVigencia = () => {
    this.setState({
      form: {
        ...this.state.form,
        Vigencia: this.state.form.Vigencia === true ? false : true,
      },
    });
  };

  crearCentroCosto = (centroCosto) => {
    centroCosto.Vigencia = this.state.form.Vigencia;

    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      centroCosto.Empresa = usuario.EmpresaSeleccionada.Empresa._id;
      this.props.CrearCentroCosto(centroCosto);
    } else {
      Swal.fire({
        title: "Error!",
        text: "Se perdió la sesión",
        icon: "error",
      });
    }
  };

  EliminarCentroCosto = (idCentroCosto) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se eliminará el centro de costo",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.EliminarCentroCosto(idCentroCosto);
      }
    });
  };

  ObtenerCentroCosto = (idCentroCosto) => {
    this.props.ObtenerCentroCosto(idCentroCosto);
  };

  EditarCentroCosto = (CentroCosto) => {
    CentroCosto._id = this.props.centroCosto._id;
    CentroCosto.Vigencia = this.props.centroCosto.Vigencia;
    this.props.EditarCentroCosto(CentroCosto);
  };

  TomarValoresEditar = (nombreCampo, valor) => {
    this.props.TomarValoresEditar(nombreCampo, valor);
  };

  render() {
    const { size, centroCostoFiltros } = this.state;

    return (
      <Layout style={{ height: "100%" }}>
        <Loading cargando={this.props.loading} />
        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Centros de costo"
            subtitulo="Registra y administra centros de costo"
          />
        </Header>
        <hr className="division" />
        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <Row style={{ padding: "6px 0px 22px" }} align="bottom">
            <Col className="div-input-search" span={6}>
              <Search
                placeholder="Buscar"
                //onSearch={(value) => this.search(value)}
                enterButton
                onChange={(value) => this.search(value)}
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={6}></Col>
            <Col style={{ textAlign: "right" }} span={12}>
              {/* <Button
                className="descargar-excel"
                type="primary"
                onClick={() => {
                  this.descargarExcel();
                }}
                size={size}
                style={{ marginRight: "20px" }}
              >
                Descargar Excel
              </Button> */}
              <Button
                className={!this.state.Editar ? "guardar-form-disabled" : "guardar-form"}
                disabled={!this.state.Editar}
                type="primary"
                onClick={() => {
                  this.mostrarModalCrear();
                }}
                size={size}
              >
                Crear centro de costo
              </Button>
            </Col>
          </Row>

          <Table
            rowKey="_id"
            dataSource={
              centroCostoFiltros === null
                ? this.props.centroCostos
                : centroCostoFiltros
            }
            loading={this.props.tablaCargando}
            bordered={true}
            showSorterTooltip={false}
          >
            <Column
              sorter={(a, b) => {
                return a.Nombre.localeCompare(b.Nombre);
              }}
              align="left"
              title="Nombre"
              dataIndex="Nombre"
              key="Nombre"
            />

            <Column
              sorter={(a, b) => {
                return a.Codigo_Base_Sistema.localeCompare(
                  b.Codigo_Base_Sistema
                );
              }}
              align="left"
              title="Código base sistema a conectar"
              dataIndex="Codigo_Base_Sistema"
              key="Codigo_Base_Sistema"
            />
            <Column
              sorter={(a, b) => {
                return a.Vigencia - b.Vigencia;
              }}
              title="Vigencia"
              key="Vigencia"
              align="center"
              render={(valor) => {
                return (
                  <img
                    src={valor.Vigencia === true ? activo : inactivo}
                    alt="imagen perfil de tabla"
                  />
                );
              }}
            />

            <Column
              title="Acciones"
              key="Action"
              align="center"
              render={(value) => (
                <Space size="middle">
                  <Button
                    onClick={() => {
                      this.ObtenerCentroCosto(value._id);
                    }}
                    className="editar-button"
                    icon={<EditFilled />}
                    disabled={!this.state.Editar}
                  />
                  <Button
                    className="eliminar-button"
                    onClick={() => {
                      this.EliminarCentroCosto(value._id);
                    }}
                    icon={<DeleteFilled />}
                    disabled={!this.state.Eliminar}
                  />
                </Space>
              )}
            />
          </Table>
        </Content>
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalCrear"
          visible={this.props.modalCentroCosto}
          onCancel={this.modalCrearCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioCentroCosto
            //propiedades cambiables del formulario
            Empresas={this.props.empresas}
            Formulario={this.state.form}
            vigencia={this.state.form.vigencia}
            cambiarEstadoVigencia={this.cambiarEstadoVigencia}
            crearCentroCosto={this.crearCentroCosto}
          />
        </Modal>
        <Modal
          //propiedades cambiables del modal
          wrapClassName="modalEditar"
          visible={this.props.modalCentroCostoEditar}
          onCancel={this.modalEditarCancelar}
          //propiedades obligatorias para modal mantenedor
          bodyStyle={{ borderRadius: "16px" }}
          width="80%"
          footer={null}
          style={{ top: 20 }}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
        >
          <FormularioCentroCostoEditar
            //propiedades cambiables del formulario
            Empresas={this.props.empresas}
            Formulario={this.props.centroCosto}
            EditarCentroCosto={this.EditarCentroCosto}
            TomarValoresEditar={this.TomarValoresEditar}
          />
        </Modal>
      </Layout>
    );
  }
}

const EstadosAPropiedades = ({
  MantenedorCentroCostosReducer: {
    loading,
    error,
    success,
    modalCentroCosto,
    modalCentroCostoEditar,
    centroCostos,
    centroCosto,
    tablaCargando,
    empresas,
  },
}) => {
  return {
    loading,
    error,
    success,
    modalCentroCosto,
    modalCentroCostoEditar,
    centroCostos,
    centroCosto,
    tablaCargando,
    empresas,
  };
};

export default connect(
  EstadosAPropiedades,
  mantenedorCentroCostosActions
)(MantenedorCentroCosto);
