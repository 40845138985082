import {
  ERROR,
  INGRESAR_HOLDING,
  LOADING,
  OBTENER_EMPRESAS,
  MOSTRAR_MODAL_HOLDING,
  OCULTAR_MODAL_HOLDING,
  OBTENER_HOLDINGS_MANTENEDOR,
  ELIMINAR_HOLDING_MANTENEDOR,
  OBTENER_HOLDING_MANTENEDOR,
  OCULTAR_MODAL_HOLDING_EDITAR,
  EDITAR_HOLDING_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  //   PARAR_LOADING,
} from "../types/MantenedorHoldingsTypes";
import API from "../Api/api";
// import { obtenerFechaHoy } from "../../funcionesGenericas/fechas";
import Swal from "sweetalert2";

export const ObtenerEmpresas = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Empresa`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const empresas = data.empresas;
        dispatch({ type: OBTENER_EMPRESAS, payload: empresas });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const CrearHolding = (holding) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const url = `${API}/Holding`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(holding),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "Se ingreso holding correctamente",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({
          type: INGRESAR_HOLDING,
          payload: "Se ingreso holding correctamente",
        });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerHoldings = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Holding`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const holdings = data.Holdings;

        dispatch({ type: OBTENER_HOLDINGS_MANTENEDOR, payload: holdings });
        break;

      default:
        const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerHolding = (idHolding) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Holding/${idHolding}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let holding = data.Holding;
        holding.validacionRut = "success";
        dispatch({ type: OBTENER_HOLDING_MANTENEDOR, payload: holding });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarHolding = (idHolding) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Holding/${idHolding}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El Holding fue eliminado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });
        dispatch({ type: ELIMINAR_HOLDING_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarHolding = (Holding) => async (dispatch) => {
  dispatch({ type: LOADING });
  const idHolding = Holding._id;
  delete Holding._id;
  try {
    const url = `${API}/Holding/${idHolding}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(Holding),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El Holding fue actualizado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });
        dispatch({ type: EDITAR_HOLDING_MANTENEDOR });
        break;

      default:
        const error = data.err.message;
        console.log(data);
        console.log(error);
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR, payload: error });
  }
};

export const TomarValoresEditar = (nombreCampo, valor) => (dispatch) => {
  let objeto = {
    nombreCampo,
    valor,
  };
  dispatch({ type: TOMAR_VALORES_EDITAR, payload: objeto });
};

export const MostrarModalHolding = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_HOLDING });
};

export const OcultarModalHolding = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_HOLDING });
};

export const OcultarModalHoldingEditar = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_HOLDING_EDITAR });
};

//   export const DescargarExcel = () => async (dispatch) => {
//     dispatch({ type: LOADING });
//     try {
//       const url = `${API}/Usuario/Descargar/Excel`;
//       let status;
//       fetch(url)
//         .then((response) => {
//           status = response.status;
//           return response.blob();
//         })
//         .then((blob) => {
//           switch (status) {
//             case 200:
//             case 201:
//               const url = window.URL.createObjectURL(blob);
//               const a = document.createElement("a");
//               a.href = url;
//               a.download = `Usuarios_${obtenerFechaHoy()}.xlsx`;
//               document.body.appendChild(a);
//               a.click();
//               a.remove();

//               dispatch({ type: PARAR_LOADING });
//               break;
//             default:
//               Swal.fire({
//                 title: "Uups",
//                 text:
//                   "Error status: " +
//                   status +
//                   " al realizar la descarga del archivo",
//                 icon: "error",
//               });

//               dispatch({ type: PARAR_LOADING });
//               break;
//           }
//         });
//     } catch (error) {
//       dispatch({ type: ERROR, payload: error });
//     }
//   };
