import {
  ERROR,
  LOADING,
  LIMPIAR_ERROR_SUCCESS,
  MOSTRAR_MODAL_CARGO,
  OCULTAR_MODAL_CARGO,
  MANTENEDOR_CARGO_CREAR_CARGO,
  MANTENEDOR_CARGO_OBTENER_CARGOS,
  MANTENEDOR_CARGO_ELIMINAR_CARGO,
  OCULTAR_MODAL_CARGO_EDITAR,
  MANTENEDOR_CARGO_OBTENER_CARGO,
  MANTENEDOR_CARGO_TOMAR_VALORES_EDITAR,
  MANTENEDOR_CARGO_EDITAR_CARGO
} from "../types/MantenedorCargosTypes";

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: null,
  modalCargo: false,
  modalCargoEditar:false,
  tablaCargando: false,
  cargos:[],
  cargo: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {

    case MANTENEDOR_CARGO_TOMAR_VALORES_EDITAR:
      const { nombreCampo, valor } = payload;
      return {
        ...state,
        cargo: {
          ...state.cargo,
          [nombreCampo]: valor,
        },
      };

    case MANTENEDOR_CARGO_EDITAR_CARGO: 
      return{
        ...state,
        loading:false,
        tablaCargando:false,
      }

    case MANTENEDOR_CARGO_ELIMINAR_CARGO:
      return {
        ...state,
        loading: false,
        tablaCargando:false,
      }

    case MANTENEDOR_CARGO_OBTENER_CARGO:
      return{
        ...state,
        loading:false,
        tablaCargando:false,
        modalCargoEditar:true,
        cargo: payload,
      }

    case MANTENEDOR_CARGO_OBTENER_CARGOS:
      return {
        ...state,
        loading:false,
        tablaCargando:false,
        cargos: payload
      }

    case MANTENEDOR_CARGO_CREAR_CARGO:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      }

    case LOADING:
      return {
        ...state,
        loading: true,
        tablaCargando: true,
      };

    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        tablaCargando: false,
      };
    case LIMPIAR_ERROR_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };

    case OCULTAR_MODAL_CARGO_EDITAR:
      return {
        ...state,
        modalCargoEditar:false,
      }
    case MOSTRAR_MODAL_CARGO:
      return {
        ...state,
        modalCargo: true,
      };

    case OCULTAR_MODAL_CARGO:
      return {
        ...state,
        modalCargo: false,
      };
    default:
      return state;
  }
};
