import {
  ERROR,
  LOADING,
  PARAR_LOADING,
  MANTENEDOR_FERIADOS_OBTENER_FERIADOS,
  MANTENEDOR_FERIADOS_MOSTRAR_MODAL,
  MANTENEDOR_FERIADOS_OCULTAR_MODAL,
  MANTENEDOR_FERIADOS_INGRESAR_FERIADO,
  MANTENEDOR_FERIADOS_ELIMINAR_FERIADO,
  MANTENEDOR_FERIADOS_OBTENER_FERIADO,
  MANTENEDOR_FERIADOS_OCULTAR_MODAL_EDITAR,
  MANTENEDOR_FERIADOS_TOMAR_VALORES_EDITAR,
  MANTENEDOR_FERIADOS_EDITAR_FERIADO,
  MANTENEDOR_FERIADOS_PARA_LOADING_EXCEL,
  MANTENEDOR_FERIADOS_PARA_SUBIR_EXCEL
} from "../types/MantenedorFeriadosType";

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: null,
  tablaCargando: true,
  modalFeriado: false,
  modalFeriadoEditar: false,
  feriados: [],
  feriado: null,

};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {

    case MANTENEDOR_FERIADOS_OBTENER_FERIADOS:
      return {
        ...state,
        loading: false,
        feriados: payload,
        tablaCargando: false,
      }
    case MANTENEDOR_FERIADOS_PARA_SUBIR_EXCEL:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        error: null,
      }
    case MANTENEDOR_FERIADOS_PARA_LOADING_EXCEL:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        error: null
      }
    case MANTENEDOR_FERIADOS_INGRESAR_FERIADO:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      }
    case MANTENEDOR_FERIADOS_ELIMINAR_FERIADO:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      }
    case MANTENEDOR_FERIADOS_OBTENER_FERIADO:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        feriado: payload,
        modalFeriadoEditar: true,
      }
    case LOADING:
      return {
        ...state,
        loading: true,
        tablaCargando: true,
      };
    case PARAR_LOADING:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      };
    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        tablaCargando: false,
      };
    case MANTENEDOR_FERIADOS_OCULTAR_MODAL_EDITAR:
      return {
        ...state,
        modalFeriadoEditar: false,
      }
    case MANTENEDOR_FERIADOS_MOSTRAR_MODAL:
      return {
        ...state,
        modalFeriado: true,
      };
    case MANTENEDOR_FERIADOS_TOMAR_VALORES_EDITAR:
      const { nombreCampo, valor } = payload;
      return {
        ...state,
        feriado: {
          ...state.feriado,
          [nombreCampo]: valor,
        }
      }
    case MANTENEDOR_FERIADOS_EDITAR_FERIADO:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      }
    case MANTENEDOR_FERIADOS_OCULTAR_MODAL:
      return {
        ...state,
        modalFeriado: false,
      }

    default:
      return state;
  }
};
