export const LOADING = "MANTENEDOR_DETALLE_FAENA_LOADING";
export const LOADING_PARAR = "MANTENEDOR_DETALLE_FAENA_LOADING_PARAR";
export const ERROR = "MANTENEDOR_DETALLE_FAENA_ERROR";
export const LIMPIAR_ERROR_SUCCESS = "MANTENEDOR_DETALLE_FAENA_LIMPIAR_ERROR_SUCCESS";
export const MANTENEDOR_DETALLE_FAENA_OBTENER_FAENA = "MANTENEDOR_DETALLE_FAENA_OBTENER_FAENA";
export const MANTENEDOR_DETALLE_FAENA_ACTUALIZAR_FAENA = "MANTENEDOR_DETALLE_FAENA_ACTUALIZAR_FAENA";
export const MANTENEDOR_DETALLE_FAENA_OBTENER_TRABAJADORES_PARA_CARGO = "MANTENEDOR_DETALLE_FAENA_OBTENER_TRABAJADORES_PARA_CARGO";
export const MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADORES = "MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADORES";
export const MANTENEDOR_DETALLE_FAENA_OBTENER_FERIADOS = "MANTENEDOR_DETALLE_FAENA_OBTENER_FERIADOS";

export const MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADOR_BORRAR_HORAS = "MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADOR_BORRAR_HORAS";
export const MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADOR_EDITAR_HORAS = "MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADOR_EDITAR_HORAS";
export const MANTENEDOR_DETALLE_FAENA_MOSTRAR_PANTALLA_TRABAJADOR_BORRAR_HORAS = "MANTENEDOR_DETALLE_FAENA_MOSTRAR_PANTALLA_TRABAJADOR_BORRAR_HORAS";
export const MANTENEDOR_DETALLE_FAENA_PARA_LOADING = "MANTENEDOR_DETALLE_FAENA_PARA_LOADING";


export const MANTENEDOR_ELIMINAR_PLANIFICACION_TRABAJADOR = "MANTENEDOR_ELIMINAR_PLANIFICACION_TRABAJADOR";
export const MANTENEDOR_ALARGAR_PLANIFICACION_TRABAJADOR = "MANTENEDOR_ALARGAR_PLANIFICACION_TRABAJADOR";

export const MANTENEDOR_DETALLE_FAENA_MOSTRAR_PANTALLA_TRABAJADOR_EDITAR_HORAS = "MANTENEDOR_DETALLE_FAENA_MOSTRAR_PANTALLA_TRABAJADOR_EDITAR_HORAS";
export const MANTENEDOR_DETALLE_FAENA_MODIFICAR_ITEMS_GRUPOS = "MANTENEDOR_DETALLE_FAENA_MODIFICAR_ITEMS_GRUPOS";
