import React, { Component } from "react";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";

import Swal from "sweetalert2";

import Enero from "../../img/meses/Enero.png";
import Febrero from "../../img/meses/Febrero.png";
import Marzo from "../../img/meses/Marzo.png";
import Abril from "../../img/meses/Abril.png";
import Mayo from "../../img/meses/Mayo.png";
import Junio from "../../img/meses/Junio.png";
import Julio from "../../img/meses/Julio.png";
import Agosto from "../../img/meses/Agosto.png";
import Septiembre from "../../img/meses/Septiembre.png";
import Octubre from "../../img/meses/Octubre.png";
import Noviembre from "../../img/meses/Noviembre.png";
import Diciembre from "../../img/meses/Diciembre.png";
import locale from 'antd/lib/date-picker/locale/es_ES';
import moment from 'moment';
import API from "../../Redux/Api/api";

import { Layout, Row, Col, Card, Button , DatePicker } from "antd";

const { Header, Content } = Layout;

class CierreDeMes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Empresa: null,
      Anio: new Date().getFullYear(),
      Meses: [
        {
          Mes: 1,
          NombreMes: "ENERO",
          Cerrado: false,
          Nuevo: true,
          Img: Enero,
        },
        {
          Mes: 2,
          NombreMes: "FEBRERO",
          Cerrado: false,
          Nuevo: true,
          Img: Febrero,
        },
        {
          Mes: 3,
          NombreMes: "MARZO",
          Cerrado: false,
          Nuevo: true,
          Img: Marzo,
        },
        {
          Mes: 4,
          NombreMes: "ABRIL",
          Cerrado: false,
          Nuevo: true,
          Img: Abril,
        },
        {
          Mes: 5,
          NombreMes: "MAYO",
          Cerrado: false,
          Nuevo: true,
          Img: Mayo,
        },
        {
          Mes: 6,
          NombreMes: "JUNIO",
          Cerrado: false,
          Nuevo: true,
          Img: Junio,
        },
        {
          Mes: 7,
          NombreMes: "JULIO",
          Cerrado: false,
          Nuevo: true,
          Img: Julio,
        },
        {
          Mes: 8,
          NombreMes: "AGOSTO",
          Cerrado: false,
          Nuevo: true,
          Img: Agosto,
        },
        {
          Mes: 9,
          NombreMes: "SEPTIEMBRE",
          Cerrado: false,
          Nuevo: true,
          Img: Septiembre,
        },
        {
          Mes: 10,
          NombreMes: "OCTUBRE",
          Cerrado: false,
          Nuevo: true,
          Img: Octubre,
        },
        {
          Mes: 11,
          NombreMes: "NOVIEMBRE",
          Cerrado: false,
          Nuevo: true,
          Img: Noviembre,
        },
        {
          Mes: 12,
          NombreMes: "DICIEMBRE",
          Cerrado: false,
          Nuevo: true,
          Img: Diciembre,
        },
      ],
    };
  }

  componentDidMount = async () => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      await this.setState({
        Empresa: idEmpresa,
      });

      try {

        await this.buscarCierres(idEmpresa);

        this.setState({
          Loading: false,
        });

      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: `Ha ocurrido un error ${error}`,
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }
    }
  };

  buscarCierres = async (idEmpresa) => {
    try {
      const Anio = this.state.Anio;

      const url = `${API}/CierreDeMes/${idEmpresa}/${Anio}`;
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
      });

      const data = await response.json();

      const mesesObtenidos = data.data;
      let nuevosMeses = this.state.Meses;

      nuevosMeses.forEach((mes) => {
        mesesObtenidos.forEach((mesObtenido) => {
          if (mesObtenido.Mes === mes.Mes) {
            mes.Cerrado = mesObtenido.Cerrado;
            mes._id = mesObtenido._id;
            mes.Nuevo = false;
          }
        });
      });

      this.setState({
        Meses: nuevosMeses,
      });

      return data;
    } catch (error) {
      return error;
    }
  };

  buscarCierresDenuevo = async (Anio) => {
    try {
      this.setState({
        Loading:true
      });
      const idEmpresa = this.state.Empresa;

      const url = `${API}/CierreDeMes/${idEmpresa}/${Anio}`;
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
      });

      const data = await response.json();

      const mesesObtenidos = data.data;
      let nuevosMeses = [...this.state.Meses];

      nuevosMeses.forEach((mes) => {
        let mesFiltrado = mesesObtenidos.filter((mesOb)=> mesOb.Mes === mes.Mes)[0];
        if(mesFiltrado){
          mes.Cerrado = mesFiltrado.Cerrado;
          mes._id = mesFiltrado._id;
          mes.Nuevo = false;
        }else{
          mes.Cerrado = false;
          mes._id = null;
          mes.Nuevo = true;
        }
      });
      this.setState({
        Meses: nuevosMeses,
        Loading:false
      });

      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  buscarCierresPorMes = async (mes) => {
    try {
      const Empresa = this.state.Empresa;
      const Anio = this.state.Anio;
      const { Mes } = mes;

      const url = `${API}/CierreDeMes/${Empresa}/${Anio}/${Mes}`;
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
      });

      const { data } = await response.json();

      return data;
    } catch (error) {
      return error;
    }
  };

  handleButton = (mes) => {
    // this.setState({
    //   Loading: true,
    // });
    const { NombreMes, Mes, Nuevo } = mes;
    let estadoCierre = true;

    if (Nuevo) {
      this.crearCierre(NombreMes, Mes, estadoCierre);

    } else {
      estadoCierre = mes.Cerrado === true ? false : true;
      this.actualizarCierre(mes, estadoCierre, mes._id);
    }
  };

  crearCierre = async (nombre, numMes, estadoCierre) => {
  
    try {
      const CierreDeMes = {
        Empresa: this.state.Empresa,
        NombreMes: nombre,
        Mes: numMes,
        Anio: this.state.Anio,
        Cerrado: estadoCierre,
      };

      const url = `${API}/CierreDeMes`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(CierreDeMes),
      });

      const status = await response.status;

      if (status === 200 || status === 201) {
        this.setState({
          Loading: false,
        });

        Swal.fire({
          icon: "success",
          title: "Bien",
          text: "Se cerró el mes correctamente.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        }).then((value) => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: "No se pudo cerrar el mes.",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "Ha occurrido un error.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
  };

  actualizarCierre = async (mes, estadoCierre, idCierreMes) => {
    try {
      const CierreDeMes = {
        Cerrado: estadoCierre,
      };

      const url = `${API}/CierreDeMes/${idCierreMes}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(CierreDeMes),
      });

      const status = await response.status;
      const {
        data: { Cerrado, Empresa },
      } = await response.json();

      if (status === 200 || status === 201) {
        await this.buscarCierres(Empresa);

        this.setState({
          Loading: false,
        });

        if (Cerrado) {
          Swal.fire({
            icon: "success",
            title: "Bien",
            text: "Se cerró el mes correctamente.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          }).then((value) => {
            window.location.reload();
            this.setState({
              Loading: true,
            });
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Bien",
            text: "Se abrió el mes correctamente.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          }).then((value) => {
            window.location.reload();
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Uuups...",
          text: `Ha occurrido un error`,
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: `Ha occurrido un error ${error}`,
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
    }
  };

  render() {
    return (
      <Layout style={{ height: "100%" }}>
        <Loading cargando={this.state.Loading} />
        <Header style={{ background: "none" }}>
          <HeaderFicha
            titulo="Cierre de Mes"
            subtitulo="Comienza y finaliza los procesos"
          />
        </Header>
        <hr className="division" />
        <Content
          className="contenedor-general cont-tabla-celeste"
          style={{ background: "none" }}
        >
          <p
            style={{ fontFamily: "MavenPro-Bold", color: "#001FCB" }}
          >{`Año ${this.state.Anio}`}</p>
          <Row style={{ padding: "6px 0px 22px" }}>
            <Col span={6} style={{ padding: "10px" }}>
              <DatePicker
                style={{ height: "39px" }}
                locale={locale}
                picker="year"
                
                //defaultValue={moment('' + this.props.periodo, yearFormat)}
                //format={yearFormat} picker="year"
                onChange={(date,fecha) => {
                  this.buscarCierresDenuevo(fecha);
                  // console.log(date);
                  // console.log(fecha)
                }}
              />
            </Col>
          </Row>
          

          <Row style={{ padding: "6px 0px 22px" }}>
            {this.state.Meses.map(
              ({ Mes, NombreMes, Cerrado, Nuevo, Img }, posicion) => (
                <Col key={posicion} span={6} style={{ padding: "10px" }}>
                  <Card
                    style={{
                      borderRadius: "16px",
                      height: "100%",
                      width: "100%",
                      boxShadow: "0px 3px 6px #74747465",
                    }}
                  >
                    <Row>
                      <Col span={8}>
                        <img
                          style={{ borderRadius: "50%", width: 55, height: 55 }}
                          src={Img}
                          alt={NombreMes}
                        />
                      </Col>
                      <Col span={16}>
                        <Row>
                          <Col style={{ lineHeight: "17px" }} span={24}>
                            <h3
                              style={{
                                fontFamily: "MavenPro-Bold",
                                fontSize: "12px",
                                color: "#061FCB",
                              }}
                            >
                              {NombreMes}
                            </h3>
                            <h3
                              style={{
                                fontFamily: "MavenPro-SemiBold",
                              }}
                            >
                              Estado:{" "}
                              {Cerrado === false ? "Abierto" : "Cerrado"}
                            </h3>
                          </Col>

                          {/* Botón */}
                          <Col span={24}>
                            <Button
                              onClick={() => {
                                this.handleButton(this.state.Meses[posicion]);
                              }}
                              className={Cerrado === false ? "crear-embarco-button" : "crear-embarco-button-gris"}
                            >
                              {Cerrado === false ? "Cerrar" : "Abrir"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              )
            )}
          </Row>
        </Content>
      </Layout>
    );
  }
}

export default CierreDeMes;
