import {
  LOADING,
  LOADING_PARAR,
  MANTENEDOR_DETALLE_FAENA_OBTENER_FAENA,
  ERROR,
  MANTENEDOR_DETALLE_FAENA_OBTENER_TRABAJADORES_PARA_CARGO,
  MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADORES,
  //MANTENEDOR_DETALLE_FAENA_ACTUALIZAR_FAENA,
  MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADOR_BORRAR_HORAS,
  MANTENEDOR_DETALLE_FAENA_MOSTRAR_PANTALLA_TRABAJADOR_BORRAR_HORAS,
  MANTENEDOR_DETALLE_FAENA_PARA_LOADING,
  //MANTENEDOR_ELIMINAR_PLANIFICACION_TRABAJADOR,
  MANTENEDOR_DETALLE_FAENA_MOSTRAR_PANTALLA_TRABAJADOR_EDITAR_HORAS,
  MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADOR_EDITAR_HORAS,
  //MANTENEDOR_ALARGAR_PLANIFICACION_TRABAJADOR,
  MANTENEDOR_DETALLE_FAENA_OBTENER_FERIADOS,
  MANTENEDOR_DETALLE_FAENA_MODIFICAR_ITEMS_GRUPOS,
} from "../types/DetalleFaenaType";
import API from "../Api/api";
import moment from "moment";
import coloresEstadoTrabajo from "../../funcionesGenericas/coloresEstadoTrabajo";
import Swal from "sweetalert2";

import {
  corregirZonaHoraria,
  convertirFechaString,
} from "../../funcionesGenericas/fechas";

export const ObtenerDetalleFaena = (idFaena) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Faena/${idFaena}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let faena = data.faena;
        let dataOrdenada = ordenarFaena(faena);

        let dataItems = obtenerItems(faena);

        let informacion = {
          faena,
          dataOrdenada: dataOrdenada,
          items: dataItems.itemsFaena,
          reporteDashboard: dataItems.reporteDashboard,
        };

        dispatch({
          type: MANTENEDOR_DETALLE_FAENA_OBTENER_FAENA,
          payload: informacion,
        });

        break;
      case 404:
        Swal.fire("Error", "Actividad no encontrada", "error");
        dispatch({ type: ERROR, payload: data.err });

        break;
      default:
        Swal.fire(
          "Error",
          `Error al realizar la petición, Status: ${status}`,
          "error"
        );
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerDetalleFaenaPorRangoDeFecha = (
  idFaena,
  fecha_inicio,
  fecha_fin,
  plazasSeleccionadas,
) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Faena/ByDates/${idFaena}`;

    const objEnviar = {
      fecha_inicio,
      fecha_fin,
    };
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(objEnviar),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let faena = data.faena;
        let dataOrdenada = ordenarFaena(faena);
        let gruposFiltrados = filtrarGrupos(dataOrdenada, plazasSeleccionadas);

        let dataItems = await obtenerItems(faena, fecha_inicio, fecha_fin,plazasSeleccionadas);

        let informacion = {
          faena,
          dataOrdenada: dataOrdenada,
          items: dataItems.itemsFaena,
          reporteDashboard: dataItems.reporteDashboard,
        };

        dispatch({
          type: MANTENEDOR_DETALLE_FAENA_OBTENER_FAENA,
          payload: informacion,
        });
        return gruposFiltrados;

      case 404:
        Swal.fire("Error", "Actividad no encontrada", "error");
        dispatch({ type: ERROR, payload: data.err });

        break;
      default:
        Swal.fire(
          "Error",
          `Error al realizar la petición, Status: ${status}`,
          "error"
        );
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ActualizarPlazaYIngresarBitacoraTrabajador = (
  BitacoraTrabajador,
  idPlaza,
  idPuestoPlaza,
  idTrabajador,
  fecha_inicio,
  fecha_fin,
  nuevasBitacorasPlaza,
  idEmpresa,
  busquedaFaenaInicio,
  busquedaFaenaFin,
  busquedaIdFaena,
  plazasSeleccionadas,

) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const objEnviar = {
      idPuestoPlaza,
      idTrabajador,
      fecha_inicio,
      fecha_fin,
      nuevasBitacorasPlaza,
      idEmpresa,
      idfaena: busquedaIdFaena,
    };

    const url = `${API}/Plaza/${idPlaza}/Agregar/Trabajador`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(objEnviar),
    });

    const status = response.status;
    let data;

    switch (status) {
      case 200:
      case 201:
        data = await response.json();

        const urlBitacoraTrabajador = `${API}/BitacoraTrabajadores`;

        const responseTrabajador = await fetch(urlBitacoraTrabajador, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            mode: "no-cors",
          },
          body: JSON.stringify(BitacoraTrabajador),
        });

        const statusTrabajador = responseTrabajador.status;

        switch (statusTrabajador) {
          case 200:
          case 201:

            // console.log('id faena')
            // console.log(busquedaIdFaena);
            // console.log('fecha de inicio faena')
            // console.log(busquedaFaenaInicio);
            // console.log('fecha de fin faena')
            // console.log(busquedaFaenaFin);
            // console.log('plazas seleccionadas')
            // console.log(plazasSeleccionadas);
            let informacion = await funcionObtenerDetalleFaenaPorRangoDeFecha(
              busquedaIdFaena,
              busquedaFaenaInicio,
              busquedaFaenaFin,
              plazasSeleccionadas
            );
            dispatch({ type: LOADING_PARAR });

            console.log(informacion);

            if (informacion instanceof Error) {
              Swal.fire(
                "Error",
                "Error no controlado al obtener nuevamente la actividad",
                "error"
              );
            } else {
              Swal.fire({
                title: "Bien",
                text: "El trabajador fue asignado",
                icon: "success",
                confirmButtonColor: "#E60AA3",
                confirmButtonText: "Continuar",
              }).then((value) => {
                dispatch({
                  type: MANTENEDOR_DETALLE_FAENA_OBTENER_FAENA,
                  payload: informacion,
                });

                //window.location.reload();
              });
              return informacion.gruposFiltrados;
            }

            break;
          case 404:
            data = await response.json();

            Swal.fire("Error", "Servicio no encontrado", "error");
            dispatch({ type: ERROR, payload: data.err });

            break;
          default:
            data = await response.json();

            Swal.fire(
              "Error",
              `Error al realizar la petición, Status: ${status}`,
              "error"
            );
            dispatch({ type: ERROR, payload: data.err });
            break;
        }

        break;
      case 404:
        data = await response.json();

        Swal.fire("Error", "Servicio no encontrado", "error");
        dispatch({ type: ERROR, payload: data.err });

        break;
      case 409:
        data = await response.json();

        Swal.fire("Error", data.err, "error");
        dispatch({ type: ERROR, payload: data.err });

        break;
      case 420:
        Swal.fire({
          title: "Uups",
          text:
            "No se pueden eliminar faenas en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({
          type: ERROR,
          payload:
            "No se pueden eliminar faenas en un periodo que se encuentra cerrado",
        });

        break;

      default:
        data = await response.json();

        Swal.fire(
          "Error",
          `Error al realizar la petición, Status: ${status}`,
          "error"
        );
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ERROR, payload: error });
  }
};

export const ActualizarPlazaYEliminarBitacoraTrabajador = (
  Plaza,
  idPlaza,
  arregloDiasBorrar
) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const urlBitacora = `${API}/BitacoraTrabajadores`;

    const responseBitacora = await fetch(urlBitacora, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(arregloDiasBorrar),
    });

    const statusBitacora = responseBitacora.status;
    let dataBitacora;

    switch (statusBitacora) {
      case 200:
      case 201:
        dataBitacora = await responseBitacora.json();

        const url = `${API}/Plazas/Edit/${idPlaza}`;

        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            mode: "no-cors",
          },
          body: JSON.stringify(Plaza),
        });

        const status = response.status;
        let data;

        switch (status) {
          case 200:
          case 201:
            data = await response.json();

            Swal.fire({
              title: "Bien",
              text: "Se registraron los cambios solicitados",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            }).then((value) => {
              window.location.reload();
            });
            dispatch({ type: MANTENEDOR_DETALLE_FAENA_PARA_LOADING });
            delete Plaza._id;

            break;
          case 404:
            data = await response.json();

            Swal.fire("Error", "Servicio no encontrado", "error");
            dispatch({ type: ERROR, payload: data.err });

            break;
          default:
            data = await response.json();

            Swal.fire(
              "Error",
              `Error al realizar la petición, Status: ${status}`,
              "error"
            );
            dispatch({ type: ERROR, payload: data.err });
            break;
        }

        break;
      case 404:
        dataBitacora = await responseBitacora.json();

        Swal.fire("Error", "Servicio no encontrado", "error");
        dispatch({ type: ERROR, payload: dataBitacora.err });

        break;
      default:
        dataBitacora = await responseBitacora.json();

        Swal.fire(
          "Error",
          `Error al realizar la petición, Status: ${statusBitacora}`,
          "error"
        );
        dispatch({ type: ERROR, payload: dataBitacora.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarPlanificacion = (
  Inicio_Planificacion,
  Fin_Planificacion,
  idTrabajador,
  idPlaza,
  idPuestoPlaza,

  busquedaFaenaInicio,
  busquedaFaenaFin,
  busquedaIdFaena,
  plazasSeleccionadas,

) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const urlBitacora = `${API}/Faena/planificacion/trabajador`;

    const objEnviar = {
      Fecha_Inicio: Inicio_Planificacion,
      Fecha_Fin: Fin_Planificacion,
      IdTrabajador: idTrabajador,
      idPlaza,
      idPuestoPlaza,
      idfaena: busquedaIdFaena,
    };

    const responseBitacora = await fetch(urlBitacora, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(objEnviar),
    });

    const statusBitacora = responseBitacora.status;
    let dataBitacora;

    switch (statusBitacora) {
      case 200:
      case 201:
        let informacion = await funcionObtenerDetalleFaenaPorRangoDeFecha(
          busquedaIdFaena,
          busquedaFaenaInicio,
          busquedaFaenaFin,
          plazasSeleccionadas
        );
        dispatch({ type: LOADING_PARAR });

        if (informacion instanceof Error) {
          Swal.fire(
            "Error",
            "Error no controlado al obtener nuevamente la actividad",
            "error"
          );
        } else {
          Swal.fire({
            title: "Bien",
            text: "Se eliminó su planificación",
            icon: "success",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          }).then((value) => {
            dispatch({
              type: MANTENEDOR_DETALLE_FAENA_OBTENER_FAENA,
              payload: informacion,
            });
          });
          
          return informacion.gruposFiltrados;
        }
        break;
      case 404:
        dataBitacora = await responseBitacora.json();

        Swal.fire("Error", "Servicio no encontrado", "error");
        dispatch({ type: ERROR, payload: dataBitacora.err });

        break;
      case 420:
        Swal.fire({
          title: "Uups",
          text:
            "No se pueden eliminar actividad en un periodo que se encuentra cerrado",
          icon: "error",
        });

        dispatch({
          type: ERROR,
          payload:
            "No se pueden eliminar faenas en un periodo que se encuentra cerrado",
        });
      case 409:
        Swal.fire(
          "Error",
          `El usuario ya tiene dias planificados esos dias. no se puede realizar planificacion esos dias.`,
          "warning"
        );
        dispatch({ type: ERROR, payload: dataBitacora.err });
        break;
      case 412:
        Swal.fire(
          "Error",
          `La planificación de trabajo ya posee descanso asignado. Debe eliminar el descanso si desea modificar el trabajo.`,
          "warning"
        );
        dispatch({ type: ERROR, payload: dataBitacora.err });
        break;

        break;
      default:
        dataBitacora = await responseBitacora.json();
        Swal.fire(
          "Error",
          `Error al realizar la petición, Status: ${statusBitacora}`,
          "error"
        );
        dispatch({ type: ERROR, payload: dataBitacora.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ActualizarDatosPlaza = (
  busquedaFaenaInicio,
  busquedaFaenaFin,
  busquedaIdFaena,
  plazasSeleccionadas,

) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {

    let informacion = await funcionObtenerDetalleFaenaPorRangoDeFecha(
      busquedaIdFaena,
      busquedaFaenaInicio,
      busquedaFaenaFin,
      plazasSeleccionadas
    );
    dispatch({ type: LOADING_PARAR });

    if (informacion instanceof Error) {
     return false;
    } else { 
      dispatch({
        type: MANTENEDOR_DETALLE_FAENA_OBTENER_FAENA,
        payload: informacion,
      });
      return informacion.gruposFiltrados;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
    return false;
  }
};


export const AlargarPlanificacion = (
  dataEnviar,
  busquedaFaenaInicio,
  busquedaFaenaFin,
  busquedaIdFaena,
  plazasSeleccionadas,

) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const urlBitacora = `${API}/Faena/AlargarPlanificacion/Trabajador`;

    const responseBitacora = await fetch(urlBitacora, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(dataEnviar),
    });

    const statusBitacora = responseBitacora.status;
    let dataBitacora;

    switch (statusBitacora) {
      case 200:
      case 201:
        dataBitacora = await responseBitacora.json();

        let informacion = await funcionObtenerDetalleFaenaPorRangoDeFecha(
          busquedaIdFaena,
          busquedaFaenaInicio,
          busquedaFaenaFin,
          plazasSeleccionadas,
        );
        dispatch({ type: LOADING_PARAR });

        if (informacion instanceof Error) {
          Swal.fire(
            "Error",
            "Error no controlado al obtener nuevamente la actividad",
            "error"
          );
        } else {
          Swal.fire({
            title: "¡Bien!",
            text: "Se actualizó la planificación del trabajador.",
            icon: "success",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          }).then((value) => {
            dispatch({
              type: MANTENEDOR_DETALLE_FAENA_OBTENER_FAENA,
              payload: informacion,
            });

          });
          return informacion.gruposFiltrados;
        }
        break;
      case 404:
        dataBitacora = await responseBitacora.json();

        Swal.fire("Error", "Servicio no encontrado", "error");
        dispatch({ type: ERROR, payload: dataBitacora.err });

        break;
      case 420:
        Swal.fire({
          title: "Uups",
          text:
            "No se pueden alargar actividades de un periodo que se encuentra cerrado.",
          icon: "error",
        });

        dispatch({
          type: ERROR,
          payload:
            "No se pueden alargar faenas de un periodo que se encuentra cerrado",
        });

        break;
      case 409:
        Swal.fire(
          "Error",
          `El trabajador ya tiene días planificados durante esas fechas. No se puede realizar planificación esos días.`,
          "warning"
        );
        dispatch({ type: ERROR, payload: dataBitacora.err });
        break;
      case 412:
        Swal.fire(
          "Error",
          `La planificación de trabajo ya posee descanso asignado. Debe eliminar el descanso si desea modificar el trabajo.`,
          "warning"
        );
        dispatch({ type: ERROR, payload: dataBitacora.err });
        break;
      default:
        dataBitacora = await responseBitacora.json();
        Swal.fire(
          "Error",
          `Error al realizar la petición, Status: ${statusBitacora}`,
          "error"
        );
        dispatch({ type: ERROR, payload: dataBitacora.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const AcortarPlanificacion = (
  dataEnviar,
  busquedaFaenaInicio,
  busquedaFaenaFin,
  busquedaIdFaena,
  plazasSeleccionadas,
) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const urlBitacora = `${API}/Faena/AcortarPlanificacion/Trabajador`;

    const responseBitacora = await fetch(urlBitacora, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(dataEnviar),
    });

    const statusBitacora = responseBitacora.status;
    let dataBitacora;

    switch (statusBitacora) {
      case 200:
      case 201:

        let informacion = await funcionObtenerDetalleFaenaPorRangoDeFecha(
          busquedaIdFaena,
          busquedaFaenaInicio,
          busquedaFaenaFin,
          plazasSeleccionadas
        );
        dispatch({ type: LOADING_PARAR });

        if (informacion instanceof Error) {
          Swal.fire(
            "Error",
            "Error no controlado al obtener nuevamente la actividad",
            "error"
          );
        } else {
          Swal.fire({
            title: "Bien",
            text: "Se actualizó la planificación del trabajador.",
            icon: "success",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          }).then((value) => {

            dispatch({
              type: MANTENEDOR_DETALLE_FAENA_OBTENER_FAENA,
              payload: informacion,
            });

          });
          return informacion.gruposFiltrados;
        }

        break;
      case 404:
        dataBitacora = await responseBitacora.json();

        Swal.fire("Error", "Servicio no encontrado", "error");
        dispatch({ type: ERROR, payload: dataBitacora.err });

        break;
      case 420:
        Swal.fire({
          title: "Uups",
          text:
            "No se pueden acortar actividades de un periodo que se encuentra cerrado.",
          icon: "error",
        });

        dispatch({
          type: ERROR,
          payload:
            "No se pueden acortar faenas de un periodo que se encuentra cerrado",
        });

        break;
      case 409:
        Swal.fire(
          "Error",
          `El usuario ya tiene dias planificados esos dias. no se puede realizar planificacion esos dias.`,
          "error"
        );
        dispatch({ type: ERROR, payload: dataBitacora.err });
        break;
      case 412:
        Swal.fire(
          "Error",
          `La planificación de trabajo ya posee descanso asignado. Debe eliminar el descanso si desea modificar el trabajo.`,
          "warning"
        );
        dispatch({ type: ERROR, payload: dataBitacora.err });
        break;
      default:
        dataBitacora = await responseBitacora.json();
        Swal.fire(
          "Error",
          `Error al realizar la petición, Status: ${statusBitacora}`,
          "warning"
        );
        dispatch({ type: ERROR, payload: dataBitacora.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerTrabajadoresParaCargo = (objeto) => async (
  dispatch,
  getStore
) => {
  dispatch({ type: LOADING });
  try {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const id = usuario.EmpresaSeleccionada.Empresa._id;

      const store = getStore().DetalleFaenaReducer;
      const {
        faena: { Fecha_Inicio, Fecha_Fin },
      } = store;

      let fechaBusquedaInicio = moment().subtract(5, 'days').startOf('day');
      let fechaBusquedaFin = moment().add(10, 'days').endOf('day');

      const parametros = {
        ...objeto,
        Fecha_Inicio: moment(Fecha_Inicio).startOf('day'),
        Fecha_Fin: moment(Fecha_Fin).endOf('day'),
        Fecha_Inicio_String: Fecha_Inicio,
        Fecha_Fin_String: Fecha_Fin,
        fechaBusquedaInicio:convertirFechaString(fechaBusquedaInicio,1).fechaString,
        fechaBusquedaFin:convertirFechaString(fechaBusquedaFin,2).fechaString
      };

      // const url = `${API}/Trabajadores/faenav2?empresa=${id}`;
      const url = `${API}/Trabajadores/faenav2/new?empresa=${id}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          mode: "no-cors",
        },
        body: JSON.stringify(parametros),
      });

      const status = await response.status;
      const data = await response.json();

      switch (status) {
        case 200:
        case 201:
          let trabajadores = data.trabajadores;
          // trabajadores = ordenarTrabajadoresParaPuesto(
          //   trabajadores,
          //   Fecha_Inicio,
          //   Fecha_Fin
          // );

          dispatch({
            type: MANTENEDOR_DETALLE_FAENA_OBTENER_TRABAJADORES_PARA_CARGO,
            payload: trabajadores,
          });

          break;
        case 404:
          Swal.fire("Error", "Correo y contraseña incorrectos", "error");
          dispatch({ type: ERROR, payload: data.err });

          break;
        default:
          Swal.fire(
            "Error",
            `Error al realizar la petición, Status: ${status}`,
            "error"
          );
          dispatch({ type: ERROR, payload: data.err });
          break;
      }
    } else {
      window.location = "/";
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ERROR, payload: error });
  }
};

export const ocultarTrabajdores = () => async (dispatch) => {
  dispatch({ type: MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADORES });
};

export const ocultarTrabajdoresEliminarHoras = () => async (dispatch) => {
  dispatch({
    type: MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADOR_BORRAR_HORAS,
  });
};

export const ocultarTrabajdoresEditarHoras = () => async (dispatch) => {
  dispatch({
    type: MANTENEDOR_DETALLE_FAENA_OCULTAR_PANTALLA_TRABAJADOR_EDITAR_HORAS,
  });
};

export const obtenerFaena = (idFaena) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const urlFaena = `${API}/Faena/Buscar?faena=${idFaena}`;

    const responseFaena = await fetch(urlFaena, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
    });

    const statusFaena = responseFaena.status;
    const dataFaena = await responseFaena.json();

    switch (statusFaena) {
      case 200:
      case 201:
        const faena = dataFaena.faena;

        return faena;
        break;
      case 404:
        Swal.fire("Error", "Actividad no encontrada", "error");
        dispatch({ type: ERROR, payload: dataFaena });

        break;
      default:
        Swal.fire(
          "Error",
          `Error al realizar la petición, Status: ${statusFaena}`,
          "error"
        );
        dispatch({ type: ERROR, payload: dataFaena });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const buscarTrabajadoresEliminarHoras = (
  idTrabajador,
  idPlazaCubrir,
  idPlaza,
  idGrupo,
  Inicio_Planificacion,
  Fin_Planificacion,
  idFaena
) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const urlFaena = `${API}/Faena/ByDates/${idFaena}`;

    const objEnviar = {
      fecha_inicio: Inicio_Planificacion,
      fecha_fin: Fin_Planificacion,
    };

    const responseFaena = await fetch(urlFaena, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(objEnviar),
    });

    const statusFaena = responseFaena.status;
    const dataFaena = await responseFaena.json();

    switch (statusFaena) {
      case 200:
      case 201:
        const faena = dataFaena.faena;

        const url = `${API}/Trabajadores/${idTrabajador}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            mode: "no-cors",
          },
        });

        const status = response.status;
        const data = await response.json();

        switch (status) {
          case 200:
          case 201:
            let trabajador = data.trabajador;

            const urlBitacora = `${API}/BitacoraTrabajadores/ByDates/${idTrabajador}`;
            const bodyEnviar = {
              Inicio_Planificacion,
              Fin_Planificacion: Fin_Planificacion,
            };
            const responseBitacora = await fetch(urlBitacora, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                mode: "no-cors",
              },
              body: JSON.stringify(bodyEnviar),
            });

            const statusBitacora = responseBitacora.status;
            const dataBitacora = await responseBitacora.json();

            switch (statusBitacora) {
              case 200:
              case 201:
                let bitacoraTrabajador = dataBitacora.data;

                let arregloDiasPoderEliminar = [];

                bitacoraTrabajador.forEach((bitacora) => {
                  if (
                    bitacora.IdGrupo === idGrupo &&
                    bitacora.IdPuestoPlaza === idPlazaCubrir &&
                    bitacora.IdTrabajador === idTrabajador &&
                    moment.utc(Inicio_Planificacion).isSame(moment.utc(bitacora.Inicio_Planificacion)) &&
                    moment.utc(Fin_Planificacion).isSame(moment.utc(bitacora.Fin_Planificacion))
                  ) {
                    arregloDiasPoderEliminar.push(bitacora);
                  }
                });

                let informacionTrabajador = {
                  trabajador,
                  bitacoraTrabajador,
                  arregloDiasPoderEliminar,
                  idPlazaCubrir,
                  idPlaza,
                  Inicio_Planificacion,
                  Fin_Planificacion,
                  FaenaEliminar: faena,
                };

                dispatch({
                  type: MANTENEDOR_DETALLE_FAENA_MOSTRAR_PANTALLA_TRABAJADOR_BORRAR_HORAS,
                  payload: informacionTrabajador,
                });

                break;
              case 404:
                Swal.fire("Error", "No se encontró el trabajador", "error");
                dispatch({ type: ERROR, payload: data.err });

                break;
              default:
                Swal.fire(
                  "Error",
                  `Error al realizar la petición, Status: ${status}`,
                  "error"
                );
                dispatch({ type: ERROR, payload: data.err });
                break;
            }

            break;
          case 404:
            Swal.fire("Error", "No se encontró al trabajador", "error");
            dispatch({ type: ERROR, payload: data.err });

            break;
          default:
            Swal.fire(
              "Error",
              `Error al realizar la petición, Status: ${status}`,
              "error"
            );
            dispatch({ type: ERROR, payload: data.err });
            break;
        }

        break;
      case 404:
        Swal.fire("Error", "Actividad no encontrada", "error");
        dispatch({ type: ERROR, payload: data.err });

        break;
      default:
        Swal.fire(
          "Error",
          `Error al realizar la petición, Status: ${status}`,
          "error"
        );
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const buscarTrabajadoresEditarHoras = (
  idTrabajador,
  idPlazaCubrir,
  idPlaza,
  idGrupo,
  Inicio_Planificacion,
  Fin_Planificacion,
  idFaena,
  Inicio_PlanificacionUnix,
  Fin_PlanificacionUnix
) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const urlFaena = `${API}/Faena/ByDates/${idFaena}`;

    const objEnviar = {
      fecha_inicio: Inicio_Planificacion,
      fecha_fin: Fin_Planificacion,
    };

    const responseFaena = await fetch(urlFaena, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(objEnviar),
    });

    const statusFaena = responseFaena.status;
    const dataFaena = await responseFaena.json();

    switch (statusFaena) {
      case 200:
      case 201:
        const faena = dataFaena.faena;

        const url = `${API}/Trabajadores/${idTrabajador}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            mode: "no-cors",
          },
        });

        const status = response.status;
        const data = await response.json();

        switch (status) {
          case 200:
          case 201:
            let trabajador = data.trabajador;

            const urlBitacora = `${API}/BitacoraTrabajadores/ByDates/${idTrabajador}`;
            const bodyEnviar = {
              Inicio_Planificacion: Inicio_Planificacion,
              Fin_Planificacion: Fin_Planificacion,
            };
            const responseBitacora = await fetch(urlBitacora, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                mode: "no-cors",
              },
              body: JSON.stringify(bodyEnviar),
            });

            const statusBitacora = responseBitacora.status;
            const dataBitacora = await responseBitacora.json();

            switch (statusBitacora) {
              case 200:
              case 201:
                let bitacoraTrabajador = dataBitacora.data;

                let arregloDiasPoderEliminar = [];

                bitacoraTrabajador.forEach((bitacora) => {
                  if (
                    bitacora.IdGrupo === idGrupo &&
                    bitacora.IdPuestoPlaza === idPlazaCubrir &&
                    bitacora.IdTrabajador === idTrabajador
                  ) {
                    arregloDiasPoderEliminar.push(bitacora);
                  }
                });

                let informacionTrabajador = {
                  trabajador,
                  bitacoraTrabajador,
                  arregloDiasPoderEliminar,
                  idPlazaCubrir,
                  idPlaza,
                  Inicio_Planificacion,
                  Inicio_PlanificacionUnix,
                  Fin_PlanificacionUnix,
                  Fin_Planificacion,
                  FaenaEliminar: faena,
                };

                dispatch({
                  type: MANTENEDOR_DETALLE_FAENA_MOSTRAR_PANTALLA_TRABAJADOR_EDITAR_HORAS,
                  payload: informacionTrabajador,
                });

                break;
              case 404:
                Swal.fire("Error", "No se encontró al trabajador", "error");
                dispatch({ type: ERROR, payload: data.err });

                break;
              default:
                Swal.fire(
                  "Error",
                  `Error al realizar la petición, Status: ${status}`,
                  "error"
                );
                dispatch({ type: ERROR, payload: data.err });
                break;
            }

            break;
          case 404:
            Swal.fire("Error", "No se encontró al trabajador", "error");
            dispatch({ type: ERROR, payload: data.err });

            break;
          default:
            Swal.fire(
              "Error",
              `Error al realizar la petición, Status: ${status}`,
              "error"
            );
            dispatch({ type: ERROR, payload: data.err });
            break;
        }

        break;
      case 404:
        Swal.fire("Error", "actividad no encontrada", "error");
        dispatch({ type: ERROR, payload: data.err });

        break;
      default:
        Swal.fire(
          "Error",
          `Error al realizar la petición, Status: ${status}`,
          "error"
        );
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerFeriados = (idEmpresa) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Feriados?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const feriados = data.feriados;
        dispatch({
          type: MANTENEDOR_DETALLE_FAENA_OBTENER_FERIADOS,
          payload: feriados,
        });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    Swal.fire({
      title: "Uups",
      text: error,
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

export const obtenerCentrosCosto = (idEmpresa) => async (dispatch) => {

  dispatch({ type: LOADING });

  try {
    const url = `${API}/CentroDeCostos?empresa=${idEmpresa}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let centroscosto = data.centrosDeCostos;

        return centroscosto;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const modificarItemsYGrupos = (items, grupos) => async (dispatch) => {

  // función utilizada para filtro de plazas

  const objCambios = { items, grupos };

  dispatch({
    type: MANTENEDOR_DETALLE_FAENA_MODIFICAR_ITEMS_GRUPOS,
    payload: objCambios,
  });
}

const funcionObtenerDetalleFaenaPorRangoDeFecha = async (
  idFaena,
  fecha_inicio,
  fecha_fin,
  plazasSeleccionadas,
) => {
  try {
    const url = `${API}/Faena/ByDates/${idFaena}`;

    const objEnviar = {
      fecha_inicio,
      fecha_fin,
    };
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(objEnviar),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let faena = data.faena;
        let dataOrdenada = ordenarFaena(faena);
        let gruposFiltrados = filtrarGrupos(dataOrdenada, plazasSeleccionadas);

        let dataItems = await obtenerItems(faena, fecha_inicio, fecha_fin,plazasSeleccionadas);

        let informacion = {
          faena,
          dataOrdenada: dataOrdenada,
          items: dataItems.itemsFaena,
          reporteDashboard: dataItems.reporteDashboard,
          gruposFiltrados
        };

        return informacion;
      default:
        return new Error("Error al realizar la petición, status: ", status);
    }
  } catch (error) {
    return error;
  }
};

const ordenarTrabajadoresParaPuesto = (
  Trabajadores,
  Fecha_Inicio,
  Fecha_Fin
) => {

  Fecha_Inicio = moment(Fecha_Inicio);
  Fecha_Fin = moment(Fecha_Fin);

  let disponibles = [];
  let noDisponible = [];

  Trabajadores.forEach((trabajador) => {

    let cantDiasBitacora = trabajador.Bitacora.length;

    let bitacorasTrabajo = [];
    let bitacorasProcesos = [];

    let detalleBitacoraTrabajador = [];

    trabajador.Bitacora.forEach((bitacora) => {

      if (bitacora.Fecha_Inicio !== undefined && bitacora.Fecha_Inicio !== "undefined") {

        let cantidadEncontrada = bitacorasProcesos.filter((proceso) =>
          moment(proceso.Fecha_Inicio).format("YYYY-MM-DD") ===
          moment(bitacora.Fecha_Inicio).format("YYYY-MM-DD") &&
          moment(proceso.Fecha_Fin).format("YYYY-MM-DD") ===
          moment(bitacora.Fecha_Fin).format("YYYY-MM-DD")
          && proceso.TipoRegistroBitacoraTrabajador !== undefined
          && bitacora.TipoRegistroBitacoraTrabajador !== undefined
          && proceso.TipoRegistroBitacoraTrabajador.toString() ===
          bitacora.TipoRegistroBitacoraTrabajador.toString()
        );

        if (cantidadEncontrada.length === 0) {
          const dataVisualizar = {
            fecha_inicio: moment.utc(bitacora.Fecha_Inicio).format("DD-MM-YYYY"),
            fecha_fin: moment.utc(bitacora.Fecha_Fin).format("DD-MM-YYYY"),
            tipoBitacora: bitacora.TipoRegistroBitacoraTrabajador,
          };

          detalleBitacoraTrabajador.push(dataVisualizar);
          bitacorasProcesos.push(bitacora);
        }

      } else {

        let cantidadEncontrada = bitacorasTrabajo.filter(
          (proceso) =>
            moment(proceso.Inicio_Planificacion).format("YYYY-MM-DD") ===
            moment(bitacora.Inicio_Planificacion).format("YYYY-MM-DD") &&
            moment(proceso.Fin_Planificacion).format("YYYY-MM-DD") ===
            moment(bitacora.Fin_Planificacion).format("YYYY-MM-DD") &&
            proceso.IdPlaza !== undefined &&
            bitacora.IdPlaza !== undefined &&
            proceso.IdPuestoPlaza !== undefined &&
            bitacora.IdPuestoPlaza !== undefined &&
            proceso.IdPlaza.toString() === bitacora.IdPlaza.toString() &&
            proceso.IdPuestoPlaza.toString() ===
            bitacora.IdPuestoPlaza.toString()
        );

        if (cantidadEncontrada.length === 0) {
          const dataVisualizar = {
            fecha_inicio: moment.utc(bitacora.Inicio_Planificacion).format(
              "DD-MM-YYYY"
            ),
            fecha_fin: moment.utc(bitacora.Fin_Planificacion).format("DD-MM-YYYY"),
            tipoBitacora: bitacora.TipoRegistroBitacoraTrabajador,
          };
          detalleBitacoraTrabajador.push(dataVisualizar);
          bitacorasTrabajo.push(bitacora);
        }

      }
    });

    const sortedArray = detalleBitacoraTrabajador.sort((a, b) =>
      moment(a.fecha_inicio).format("YYYYMMDD") -
      moment(b.fecha_inicio).format("YYYYMMDD")
    );

    trabajador.detalleBitacoraTrabajador = sortedArray;

    // cantidad de días de la actividad
    let cantidadDias = Fecha_Fin.diff(Fecha_Inicio, "days") + 1;

    // para verificar si tiene vencido el contrato
    let tiempoContratoVencido = 0;

    if (trabajador.Tipo_Contrato === 'Plazo fijo') {
      tiempoContratoVencido = moment().diff(moment.unix(trabajador.Contrato_Fecha_Fin_Unix), 'hours');
    }

    if (tiempoContratoVencido > 0) {
      trabajador.contratoVencido = true;
      trabajador.disponible = false;
      noDisponible.push(trabajador);

    } else if (parseInt(cantDiasBitacora) >= parseInt(cantidadDias)) {
      trabajador.disponible = false;
      noDisponible.push(trabajador);

    } else {
      trabajador.disponible = true;
      disponibles.push(trabajador);
    }
  });

  noDisponible.forEach((tnDisponible) => {
    disponibles.push(tnDisponible);
  });

  return disponibles;
};

const ordenarFaena = (Faena) => {

  //arreglo de grupos
  let grupos = [];
  //arreglo de plazas actuales de la faena
  let arregloPlazas = Faena.Plazas;
  //plantilla
  let plazasPlantilla = Faena.Plantilla.Plazas;

  //ordenando plazas para que queden con orden de la plantilla
  let arregloPlazasOrdenado = [];

  let newArrayPlantilla = [];

  plazasPlantilla.forEach(plaza => {
    let filtrado = newArrayPlantilla.filter((item) => item.NombrePlaza.toString() === plaza.NombrePlaza.toString());
    if(filtrado.length === 0){
      newArrayPlantilla.push(plaza);
    }
  });

  newArrayPlantilla.forEach((plantillaPlaza) => {
    arregloPlazas.forEach(plaza => {
      if (plantillaPlaza.NombrePlaza === plaza.NombreDePlaza) {
        arregloPlazasOrdenado.push(plaza);
      }
    });
  });

  //recorro las plazas
  arregloPlazasOrdenado.forEach((plaza,index) => {
    //id de la plaza (dada por mongo)
    let idPlaza = plaza._id;
    //id de la plaza (plantilla). Necesaria para poder obtener los titulos y las especialidades alojadas en la plantilla y el cargo.
    let idPlazaPlantilla = plaza.IdPlaza;
    let especialidades = [];
    let titulos_profesionales = [];
    let cargo;
    let nombrePlaza = plaza.NombreDePlaza;

    // console.log(`index ${index}`);
    // console.log(nombrePlaza);



    //guardo segun la plantilla el cargo, especialidades y titulos.
    plazasPlantilla.forEach((plazaP) => {
      if (plazaP._id === idPlazaPlantilla) {
        cargo = plazaP.Cargo;
        especialidades = plazaP.Especialidades_Obligatorias;
        titulos_profesionales = plazaP.Titulos_Obligatorios;
      }
    });

    //cantidad de plazas acutuales
    let cantidadplazas = plaza.PuestosPlaza.length;
    //declaro variable para verificar cantidad de plazas rellenas
    let cantidadPlazasRellenas = 0;

    //itero todas las plazas y verifico la cantidad
    plaza.PuestosPlaza.forEach((puestoPlaza) => {
      if (puestoPlaza.Trabajadores.length !== 0) {
        cantidadPlazasRellenas++;
      }
    });

    //genero el cabezal (por darle un nombre) de la seccion de plazas
    let nuevoGrupo = {
      id: idPlazaPlantilla,
      title: nombrePlaza,
      stackItems: true,
      height: 50,
      width: 200,
      Header: true,
      HeaderFaena: false,
      cantPlazas: cantidadplazas,
      cantRellenas: cantidadPlazasRellenas,
      TipoSeccion: "Header",
    };
    grupos.push(nuevoGrupo);

    let numeroPlaza = 1;

    plaza.PuestosPlaza.forEach((puestoPlaza, index) => {
      let puestoPlazaId = puestoPlaza._id;

      let nuevoCargo = {
        title: "Usuario Cargado",
        img: "",
        stackItems: true,
        height: 50,
        width: 200,
        id: puestoPlazaId,
        idPlaza: idPlaza,
        idPlantilla: idPlazaPlantilla,
        TipoSeccion: "HeaderSeccion",
        numeroPlaza,
        nombrePlaza:nombrePlaza,
        plaza,
        especialidades,
        titulos_profesionales,
        cargo,
      };

      grupos.push(nuevoCargo);

      numeroPlaza++;

      

      puestoPlaza.Trabajadores.forEach((trabajador) => {
        if(puestoPlazaId === "614e312063e7de002ff13bd6"){
          console.log(`${puestoPlazaId} + ${trabajador._id} ` + `${puestoPlazaId + trabajador._id} puesto`);
        }
        let nuevoCargo = {
          title: "Usuario Cargado",
          img: "",
          stackItems: true,
          height: 50,
          width: 200,
          Header: false,
          plazaPuesto: puestoPlaza,
          id: puestoPlazaId + trabajador._id,
          idPlaza: idPlaza,
          idPlantilla: idPlazaPlantilla,
          TipoSeccion: "Usuario",
          trabajador,
        };
        grupos.push(nuevoCargo);
      });
    });
  });

  return grupos;
};

const obtenerItems = (Faena, inicioFiltro, finFiltro,plazasSeleccionadas) => {
  //logica para obtener dashboard
  let plazasSinCubrir = 0;
  let plazasReservadas = 0;
  let plazasCubiertas = 0;
  let plazasEnCurso = 0;
  let noContabilizado = 0; //NO COMENTAR o ELIMINAR, sí se utiliza
  let arregloDeDashboard = [];

  let cantidadPuestosPlazas = 0;

  plazasSeleccionadas = plazasSeleccionadas === undefined ? [] : plazasSeleccionadas;
  //se cuenta la cantidad de puestos plaza dependiendo si estan seleccionados
  //en el caso no haber ninguno seleccionado se debe la busqueda libre
  //si esta filtrado se realiza la busqueda solo por los seleccionados
  Faena.Plazas.forEach((plaza) => {
    if(plazasSeleccionadas.length > 0){
      let plazaEncontrada = plazasSeleccionadas.filter((pla) => pla.toString() === plaza.IdPlaza.toString());
      if(plazaEncontrada.length > 0){
        cantidadPuestosPlazas += plaza.PuestosPlaza.length;
      }
    }else{
      cantidadPuestosPlazas += plaza.PuestosPlaza.length;
    }
  });
  // console.log('cantidad puestos plaza')
  // console.log(cantidadPuestosPlazas)

  // let fechaInicioFiltro = moment.utc(inicioFiltro);
  // let fechaFinFiltro = moment.utc(finFiltro);

  //logica para desplegar información
  let fechaInicioFaena = inicioFiltro ? moment.utc(inicioFiltro):  moment.utc(Faena.Fecha_Inicio);
  let fechaFinFaena = finFiltro ? moment.utc(finFiltro): moment.utc(Faena.Fecha_Fin);

  // let fechaInicioCalculo = fechaInicioFaena.isAfter(fechaInicioFiltro)
  //   ? fechaInicioFaena
  //   : fechaInicioFiltro;
  // let fechaFinCalculo = fechaFinFaena.isBefore(fechaFinFiltro)
  //   ? fechaFinFaena
  //   : fechaFinFiltro;


  //CANTIDAD DE PLAZAS FILTRADAS (VALOR ESTA MALO FALTA UN VALOR)

  // let cantidadPlazaFiltrada =
  //   fechaFinCalculo.diff(fechaInicioCalculo, "days") + 1;
  // let cantidadPlazaFiltrada = 0;
  // if(plazasSeleccionadas.length > 0){
  //   cantidadPlazaFiltrada = plazasSeleccionadas.length;
  // }else{
  //   cantidadPlazaFiltrada = Faena.Plazas.length;
  // }

  // console.log(cantidadPlazaFiltrada);

  //cantidad de dias totales por recorrer
  let cantidadDias = fechaFinFaena.diff(fechaInicioFaena, "days") + 1;
  // console.log('Cantidad de días: ',cantidadDias);

  //CANTIDAD TOTAL DE BITÁCORAS EN EL RANGO SELECCIONADO
  let totalCantidadBitacoras = cantidadDias * cantidadPuestosPlazas;
  // console.log('cantidad de bitacoras')
  // console.log(totalCantidadBitacoras);


  let plazas = Faena.Plazas;
  //let idFaena = Faena._id;

  let ItemsFaena = [];
  let arregloDeDias = [];

  for (let index = 0; index < cantidadDias; index++) {
    let fechaInicio = moment.utc(Faena.Fecha_Inicio).add(index, "days");

    arregloDeDias.push(fechaInicio);
  }

  // console.log('dias a contabilizar')
  // console.log(arregloDeDias);


  plazas.forEach((plaza) => {
    let idPlaza = plaza._id;

    plaza.PuestosPlaza.forEach((puestosPlaza) => {
      let idPuestoPlaza = puestosPlaza._id;

      arregloDeDias.forEach((diaFaena) => {
        let diasEncontrado = [];

        plaza.BitacoraPlaza.forEach((bitacora) => {
          let mismaFecha = moment.utc(bitacora.Fecha).isSame(diaFaena);

          if (mismaFecha === true && bitacora.IdPuestoPlaza === idPuestoPlaza) {
            diasEncontrado.push(bitacora);
          }
        });

        diasEncontrado.forEach((bitacora, index) => {
          if (index === 0) {
            switch (bitacora.Estado) {
              // Sin Cubrir
              case "5f3ef5ce1ecf531c1c8ab4a7":
                plazasSinCubrir++;
                break;
              // Reservadas
              case "5f3ef6591ecf531c1c8ab4a8":
                plazasReservadas++;
                break;
              // En Curso
              case "5f3ef6861ecf531c1c8ab4a9":
                plazasEnCurso++;
                break;
              // Ya Cubiertas
              case "5f3ef6981ecf531c1c8ab4aa":
                plazasCubiertas++;
                break;
              default:
                noContabilizado++;
                break;
            }
          }

          let diaEncontrado = diasEncontrado.length === 1 ? false : true;
          //corrijo la conversion de fechas en zona horaria. Tanto la fecha de inicio 
          //como la fecha de fin
          let fechaInicio = corregirZonaHoraria(moment(bitacora.Fecha),1);
          let fechaFin = corregirZonaHoraria(moment(bitacora.Fecha),2);

          //genero un objeto para almacenar las clases para marcar el inicio y el fin
          //en las barras
          let borderClass = " ";
          let igualInicio = moment.utc(bitacora.Fecha).startOf('day').isSame(moment.utc(bitacora.Inicio_Planificacion).startOf('day'));
          let igualFin = moment.utc(bitacora.Fecha).endOf('day').isSame(moment.utc(bitacora.Fin_Planificacion).endOf('day'));
          //valido que bordes deben estar marcados
          if (igualInicio && igualFin) {
            borderClass = "borde-completo";
          } else if (igualInicio) {
            borderClass = "borde-inicio";
          } else if (igualFin) {
            borderClass = "borde-fin";
          }

          // const bit = `${bitacora.IdPuestoPlaza.toString() + bitacora.IdTrabajador.toString()}`
          // if(bit === "614e312063e7de002ff13bd6614b27f563e7de002ff0a99d"){
            
          //   console.log('encontro')
          // }

          let item = {
            //horas
            start_time: fechaInicio,
            end_time: fechaFin,
            Inicio_Planificacion: bitacora.Inicio_Planificacion,
            Fin_Planificacion: bitacora.Fin_Planificacion,
            Inicio_PlanificacionUnix: bitacora.Inicio_PlanificacionUnix,
            Fin_PlanificacionUnix: bitacora.Fin_PlanificacionUnix,
            //data
            id: bitacora._id,
            group: bitacora.IdPuestoPlaza.toString() + bitacora.IdTrabajador.toString(),
            idTrabajador: bitacora.IdTrabajador,
            idPuestoPlaza: bitacora.IdPuestoPlaza,
            idPlaza: idPlaza,
            title: "",
            canMove: false,
            canResize: false,
            canChangeGroup: false,
            idGrupo: bitacora.IdGrupo,
            colorBarra: coloresEstadoTrabajo.obtenerColorEstadoTrabajoDetalleFaena(
              bitacora.Estado,
              diaEncontrado,
              borderClass
            ),
            relevo: diaEncontrado,
          };
          ItemsFaena.push(item);
        });
      });
    });
  });

  plazasSinCubrir =
    totalCantidadBitacoras - plazasCubiertas - plazasEnCurso - plazasReservadas;

  if (plazasSinCubrir < 0) plazasSinCubrir = 0;

  let ObjetoPlazaCubiertas = {
    cantidad: plazasCubiertas,
    titulo: plazasCubiertas + " días",
    subTitulo: "Ya cubiertas",
    tipo: "5f3ef6861ecf531c1c8ab4a9",
  };
  arregloDeDashboard.push(ObjetoPlazaCubiertas);

  let ObjetoPlazaenCurso = {
    cantidad: plazasEnCurso,
    titulo: plazasEnCurso + " días",
    subTitulo: "En curso",
    tipo: "5f3ef6981ecf531c1c8ab4aa",
  };
  arregloDeDashboard.push(ObjetoPlazaenCurso);

  let ObjetoPlazaReservadas = {
    cantidad: plazasReservadas,
    titulo: plazasReservadas + " días",
    subTitulo: "Reservadas",
    tipo: "5f3ef6591ecf531c1c8ab4a8",
  };
  arregloDeDashboard.push(ObjetoPlazaReservadas);

  let ObjetoPlazaSinCubrir = {
    cantidad: plazasSinCubrir,
    titulo: plazasSinCubrir + " días",
    subTitulo: "Sin cubrir",
    tipo: "5f3ef5ce1ecf531c1c8ab4a7",
  };
  arregloDeDashboard.push(ObjetoPlazaSinCubrir);

  let objResponder = {
    itemsFaena: ItemsFaena,
    reporteDashboard: arregloDeDashboard,
  };

  return objResponder;
};

const filtrarGrupos = (grupos, plazasSeleccionadas) => {

  if (!(plazasSeleccionadas.length > 0)) {
    return grupos;
  }

  return grupos.filter(group => {

    const { TipoSeccion } = group;

    switch (TipoSeccion) {

      case 'Header':
        if (!plazasSeleccionadas.includes(group.id)) return false;

      case 'HeaderSeccion':
      case 'Usuario':
        if (!plazasSeleccionadas.includes(group.idPlantilla)) return false;

      default:
        return true;
    }
  })

}