import {
  ERROR,
  INGRESAR_USUARIO,
  LOADING,
  OBTENER_ROLES,
  MOSTRAR_MODAL_USUARIO,
  OCULTAR_MODAL_USUARIO,
  OBTENER_USUARIOS_MANTENEDOR,
  ELIMINAR_USUARIO_MANTENEDOR,
  OBTENER_USUARIO_MANTENEDOR,
  OBTENER_ROLES_USUARIO_SELECT,
  OCULTAR_MODAL_USUARIO_EDITAR,
  EDITAR_USUARIO_MANTENEDOR,
  TOMAR_VALORES_EDITAR,
  PARAR_LOADING,
  MANTENEDOR_USUARIO_OBTENER_EMPRESAS_VIGENTES,
  MANTENEDOR_USUARIO_OBTENER_HOLDINGS_VIGENTES,
  ACTUALIZAR_VALORES_EMPRESAS,
} from "../types/MantenedorUsuarioTypes";
import API from "../Api/api";
import { obtenerFechaHoy } from "../../funcionesGenericas/fechas";
import Swal from "sweetalert2";

export const ObtenerRoles = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Rol?vigencia=true`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        const roles = data.roles;
        dispatch({ type: OBTENER_ROLES, payload: roles });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerRolesSelect = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Rol?vigencia=true`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:


        const roles = data.Roles;
        roles.map((Rol) => {
          return { Nombre: Rol };
        });
        dispatch({ type: OBTENER_ROLES_USUARIO_SELECT, payload: roles });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const CrearUsuario = (usuario) => async (dispatch) => {

  dispatch({ type: LOADING });

  try {
    const url = `${API}/Usuario/${usuario.Rut}/${usuario.Email}`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(usuario),
    });

    const statusCreateUsuario = response.status;
    const data = await response.json();

    switch (statusCreateUsuario) {
      case 200:
      case 201:

        //////////////////////////////////////////////////////////////////////////////
        const idUsuario = data.usuario._id;

        const urlCorreo = `${API}/Enviar/Correo/NuevoUsuario/${idUsuario}`;
        await fetch(urlCorreo, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        let usuarioSesion = localStorage.getItem("usuario-people-manage");
        usuarioSesion = JSON.parse(usuarioSesion);

        const empresa = usuarioSesion.EmpresaSeleccionada.Empresa._id;

        let accesos;

        //Si selecciona ADMIN, crear acceso con todos en true,
        //Si selecciona cualquier otro Rol, crear acceso con todo en false
        if (usuario.Rol === "5f21aebad585cd003f997a6b") {

          accesos = {

            Dashboard: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            MantenedorTrabajadores: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            Contratos: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            PlanificacionEquiposDeTrabajo: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            PlanificacionActividades: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            PlanificacionDetalleActividad: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            PlanificacionDetalleTrabajador: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            AsistenciaCheckIn: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            AsistenciaManual: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            AsistenciaQR: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            AsistenciaCheckOut: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ProcesosDescanso: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ProcesosEnTransito: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ProcesosVacaciones: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ProcesosLicencia: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ProcesosMutual: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ProcesosPermisos: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ProcesosAusente: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ReporteriaDesviaciones: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ReporteriaDelDia: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ReporteriaNecesidades: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ReporteriaUtilizacionRelevos:{
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ReporteriaAsistenciaPersonas: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ReporteriaAsistenciaCC: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ReporteriaAuditoria: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ReporteriaCierreDeMes: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ReporteriaCosteo: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            MantenedorUsuarios: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            MantenedorPerfilesUsuario: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            MantenedorAccesosSistema: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            MantenedorSucursales: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            MantenedorCentroDeCostos: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            MantenedorRegimenDeTrabajo: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            MantenedorFeriados: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            MantenedorTitulos: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            MantenedorEspecialidades: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            MantenedorCargos: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            MantenedorEpp: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            MantenedorDocumentos: {
              Ver: true,
              Editar: true,
              Eliminar: true,
            },
          }

        } else {

          accesos = {

            Dashboard: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            MantenedorTrabajadores: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            Contratos: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            PlanificacionEquiposDeTrabajo: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            PlanificacionActividades: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            PlanificacionDetalleActividad: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            PlanificacionDetalleTrabajador: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            AsistenciaCheckIn: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            AsistenciaManual: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            AsistenciaQR: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            AsistenciaCheckOut: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            ProcesosDescanso: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            ProcesosEnTransito: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            ProcesosVacaciones: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            ProcesosLicencia: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            ProcesosMutual: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            ProcesosPermisos: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            ProcesosAusente: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            ReporteriaDesviaciones: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            ReporteriaDelDia: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            ReporteriaNecesidades: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            ReporteriaUtilizacionRelevos:{
              Ver: true,
              Editar: true,
              Eliminar: true,
            },

            ReporteriaAsistenciaPersonas: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            ReporteriaAsistenciaCC: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            ReporteriaAuditoria: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            ReporteriaCierreDeMes: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            ReporteriaCosteo: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            MantenedorUsuarios: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            MantenedorPerfilesUsuario: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            MantenedorAccesosSistema: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            MantenedorSucursales: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            MantenedorCentroDeCostos: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            MantenedorRegimenDeTrabajo: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            MantenedorFeriados: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            MantenedorTitulos: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            MantenedorEspecialidades: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            MantenedorCargos: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            MantenedorEpp: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },

            MantenedorDocumentos: {
              Ver: false,
              Editar: false,
              Eliminar: false,
            },
          }
        }

        accesos.Empresa = empresa;
        accesos.Usuario = idUsuario;

        const url = `${API}/AccesosSistema`;

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(accesos),
        });

        const status = await response.status;

        switch (status) {
          case 200:
          case 201:

            Swal.fire({
              title: "Bien",
              text: "Se ingresó el usuario correctamente.",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            }).then((value) => {
              window.location.reload();
            });

            dispatch({
              type: INGRESAR_USUARIO,
              payload: "Se ingresó el usuario correctamente",
            });
            break;

          default:
            dispatch({ type: ERROR, payload: 'Error al crear accesos del sistema' });

            Swal.fire({
              title: "Error",
              text: "Error al crear accesos del sistema.",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            }).then((value) => {
              window.location.reload();
            });

            break;
        }
        break;
      case 409:

        Swal.fire({
          title: "¡Ups!",
          text: "Ya existe registro de un usuario con ese RUT o email, intenta denuevo con otro.",
          icon: "warning",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        })

        dispatch({ type: ERROR, payload: "Ya existe registro de un usuario con ese RUT." });

        break;
      default:

        Swal.fire({
          title: "Uups",
          text: "Error status: " + statusCreateUsuario,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }

  } catch (error) {

    Swal.fire({
      title: "Uups",
      text: "Error no controlado, Error: " + error,
      icon: "error",
    });
    dispatch({ type: ERROR, payload: error });
  }
};

export const CrearUsuarioDesdeSuper = (usuario) => async (dispatch) => {

  dispatch({ type: LOADING });

  try {
    const url = `${API}/Usuario/${usuario.Rut}/${usuario.Email}`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(usuario),
    });

    const statusCreateUsuario = response.status;
    const data = await response.json();

    switch (statusCreateUsuario) {
      case 200:
      case 201:

        //////////////////////////////////////////////////////////////////////////////
        const idUsuario = data.usuario._id;

        const urlCorreo = `${API}/Enviar/Correo/NuevoUsuario/${idUsuario}`;
        await fetch(urlCorreo, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        let usuarioSesion = localStorage.getItem("usuario-people-manage");
        usuarioSesion = JSON.parse(usuarioSesion);

        //accesos.ListaEmpresas = usuario.Empresas;
        //accesos.Usuario = idUsuario;

        const objAccesos = {
          ListaEmpresas: usuario.Empresas,
          IdUsuario: idUsuario,
        }

        const url = `${API}/AccesosSistema/Multiempresas`;

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(objAccesos),
        });

        const status = await response.status;

        switch (status) {
          case 200:
          case 201:

            Swal.fire({
              title: "Bien",
              text: "Se ingresó el usuario correctamente.",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            }).then((value) => {
              window.location.reload();
            });

            dispatch({
              type: INGRESAR_USUARIO,
              payload: "Se ingresó el usuario correctamente",
            });
            break;

          default:
            dispatch({ type: ERROR, payload: 'Error al crear accesos del sistema' });

            Swal.fire({
              title: "Error",
              text: "Error al crear accesos del sistema.",
              icon: "success",
              confirmButtonColor: "#E60AA3",
              confirmButtonText: "Continuar",
            }).then((value) => {
              window.location.reload();
            });

            break;
        }



        break;
      //////////////////////////////////////////////////////////////////////////////

      case 409:

        Swal.fire({
          title: "¡Ups!",
          text: "Ya existe registro de un usuario con ese RUT o email, intenta denuevo con otro.",
          icon: "warning",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Ok",
        })

        dispatch({ type: ERROR, payload: "Ya existe registro de un usuario con ese RUT." });

        break;

      default:
        //const error = data.err.message.toString();
        console.log(data);
        Swal.fire({
          title: "Uups",
          text: "Error status: " + statusCreateUsuario,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {

    Swal.fire({
      title: "Uups",
      text: "Error no controlado, Error: " + error,
      icon: "error",
    });

    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerUsuarios = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    let url;
    if (id !== undefined && id !== "" && id !== null) {
      url = `${API}/Usuario?empresas=${id}`;
    } else {
      url = `${API}/Usuario`;
    }
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let usuarios = data.usuarios;
        usuarios.forEach((usuario) => {
          usuario.nombre_completo = `${usuario.Nombres} ${usuario.Apellido_Paterno} ${usuario.Apellido_Materno}`;
        });

        dispatch({ type: OBTENER_USUARIOS_MANTENEDOR, payload: usuarios });
        break;

      default:
        const error = data.err.message.toString();
        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });
        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerUsuario = (idUsuario) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Usuario/${idUsuario}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let usuario = data.usuario;
        usuario.validacionRut = "success";
        dispatch({ type: OBTENER_USUARIO_MANTENEDOR, payload: usuario });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerEmpresasVigentes = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Empresa`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let empresasHolding = data.data;

        dispatch({
          type: MANTENEDOR_USUARIO_OBTENER_EMPRESAS_VIGENTES,
          payload: empresasHolding,
        });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const ObtenerHoldingsVigentes = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Holding`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        let holdings = data.Holdings;
        //usuario.validacionRut = "success";
        dispatch({
          type: MANTENEDOR_USUARIO_OBTENER_HOLDINGS_VIGENTES,
          payload: holdings,
        });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EliminarUsuario = (idUsuario) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const url = `${API}/Usuario/${idUsuario}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El Usuario fue eliminado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: ELIMINAR_USUARIO_MANTENEDOR });
        break;

      default:
        const error = data.err.message.toString();

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const EditarUsuario = (Usuario) => async (dispatch) => {
  dispatch({ type: LOADING });
  const idUsuario = Usuario._id;
  delete Usuario._id;
  try {
    const url = `${API}/Usuario/${idUsuario}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(Usuario),
    });

    const status = response.status;
    const data = await response.json();

    switch (status) {
      case 200:
      case 201:
        Swal.fire({
          title: "Bien",
          text: "El usuario fue actualizado",
          icon: "success",
          confirmButtonColor: "#E60AA3",
          confirmButtonText: "Continuar",
        }).then((value) => {
          window.location.reload();
        });

        dispatch({ type: EDITAR_USUARIO_MANTENEDOR });
        break;

      default:
        const error = data.err.message;

        Swal.fire({
          title: "Uups",
          text: "Error status: " + status + " ,detalle: " + error,
          icon: "error",
        });

        dispatch({ type: ERROR, payload: data.err });
        break;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const TomarValoresEditar = (nombreCampo, valor) => (dispatch) => {
  let objeto = {
    nombreCampo,
    valor,
  };
  dispatch({ type: TOMAR_VALORES_EDITAR, payload: objeto });
};

export const MostrarModalUsuario = () => (dispatch) => {
  dispatch({ type: MOSTRAR_MODAL_USUARIO });
};

export const OcultarModalUsuario = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_USUARIO });
};

export const OcultarModalUsuarioEditar = () => (dispatch) => {
  dispatch({ type: OCULTAR_MODAL_USUARIO_EDITAR });
};

export const DescargarExcelTodos = () => async (dispatch) => {

  dispatch({ type: LOADING });

  try {
    const url = `${API}/Usuario/Descargar/Excel`;
    let status;
    fetch(url)
      .then((response) => {
        status = response.status;
        return response.blob();
      })
      .then((blob) => {
        switch (status) {
          case 200:
          case 201:
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `Usuarios_${obtenerFechaHoy()}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();

            dispatch({ type: PARAR_LOADING });
            break;
          default:
            Swal.fire({
              title: "Uups",
              text:
                "Error status: " +
                status +
                " al realizar la descarga del archivo",
              icon: "error",
            });

            dispatch({ type: PARAR_LOADING });
            break;
        }
      });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const DescargarExcel = () => async (dispatch) => {
  dispatch({ type: LOADING });

  let usuario = localStorage.getItem("usuario-people-manage");

  usuario = JSON.parse(usuario);

  const id = usuario.EmpresaSeleccionada.Empresa._id;

  try {
    const url = `${API}/Usuario/Descargar/Excel?empresa=${id}`;
    let status;
    fetch(url)
      .then((response) => {
        status = response.status;
        return response.blob();
      })
      .then((blob) => {
        switch (status) {
          case 200:
          case 201:
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `Usuarios_${obtenerFechaHoy()}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();

            dispatch({ type: PARAR_LOADING });
            break;

          default:
            Swal.fire({
              title: "Uups",
              text:
                "Error status: " +
                status +
                " al realizar la descarga del archivo",
              icon: "error",
            });

            dispatch({ type: PARAR_LOADING });
            break;
        }
      });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};


export const ActualizarListadoEmpresasSeleccionadas = (nuevosValores) => async (dispatch) => {

  dispatch({ type: ACTUALIZAR_VALORES_EMPRESAS, payload: nuevosValores });

};
