export const LOADING_AUSENTE = "LOADING_AUSENTE";
export const ERROR_AUSENTE = "ERROR_AUSENTE";
export const BUSCAR_TRABAJADORES_AUSENTE = "BUSCAR_TRABAJADORES_AUSENTE";
export const INSERTAR_DATOS_FORMULARIO = "INSETAR_DATOS_FORMULARIO_AUSENTE";
export const INSERTAR_TRABAJADOR_FORM = "INSERTAR_TRABAJADOR_FORM_AUSENTE";
export const INSERTAR_FECHAS_FORM = "INSERTAR_FECHAS_FORM_AUSENTE";
export const INSERTAR_DESCRIPCION_FORM = "INSERTAR_DESCRIPCION_FORM_AUSENTE";
export const ENVIAR_CORREO = "ENVIAR_CORREO_AUSENTE";
export const LOADING_AUSENTE_FALSE= 'LOADING_AUSENTE_FALSE';
export const BUSCAR_CONTRATOS_TRABAJADOR = 'BUSCAR_CONTRATOS_TRABAJADOR';
export const INSERTAR_OBSERVACION_FORM = 'INSERTAR_OBSERVACION_FORM';
