import React, { Component } from "react";

import API from "../../Redux/Api/api";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import "moment/locale/es";
import { convertirFechaString } from "../../funcionesGenericas/fechas";
import Swal from "sweetalert2";
import { EyeFilled } from "@ant-design/icons";
import Excel from "../../img/icons-sidebar/blancas/IconExcel.svg";
import { Line } from "@ant-design/charts";
import Relevo from "../../img/icons/relevo.svg";
import Loading from "../../components/loading/Loading";

import {
  Row,
  Col,
  Card,
  Select,
  Button,
  DatePicker,
  Modal,
  Form,
  Table,
  Tooltip
} from "antd";
const { Column } = Table;

class DashboardCurvaRelevos extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      Empresa: "",
      dataRelevos: [], //DATA DEL GRAFICO
      cargoReporte: [],
      fechaReporte: moment(),
      promedioRelevos: 0,
      cantidadContratosRelevo: 0,
      cantidadActividades: 0,
      totalDiasRelevo: 0,
      dataDetalle: [], //DATA DEL DETALLE DEL GRAFICO
      modalDetalle: false,
    };
  }

  componentDidMount = async () => {
    // this.setState({ loading: true });
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      const cargo = "";
      let strFecha = moment().format("YYYY-MM-DD");

      const startOfMonth = moment(strFecha).startOf("month").format("YYYY-MM-DD");
      const endOfMonth = moment(strFecha).endOf("month").format("YYYY-MM-DD");

      let objetoEnviar = {
        fechaInicio: convertirFechaString(moment(startOfMonth), 1).fechaString,
        fechaFin: convertirFechaString(moment(endOfMonth), 2).fechaString,
        cargo,
      };
      const dataCurvaDeRelevos = await this.obtenerCurvaDeRelevos(objetoEnviar, idEmpresa);

      const reporteCurvaRelevos = dataCurvaDeRelevos.ReporteCurvaRelevos;
      const promedioRelevos = dataCurvaDeRelevos.ResultadosTarjetas.PromedioRelevos;
      const cantidadContratosRelevo = dataCurvaDeRelevos.ResultadosTarjetas.CantidadContratos;
      const cantidadActividades = dataCurvaDeRelevos.ResultadosTarjetas.ActividadesRelevos;
      const totalDiasRelevo = dataCurvaDeRelevos.ResultadosTarjetas.TotalDiasRelevo;

      let formREF = this.formRef.current;
      formREF.setFieldsValue({ fechaReporte: moment() });

      this.setState({
        dataRelevos: reporteCurvaRelevos,
        promedioRelevos,
        cantidadContratosRelevo,
        totalDiasRelevo,
        cantidadActividades,
        Empresa: idEmpresa,
        // loading: false,
      });
    }
  };

  //API DEL GRAFICO CURVA DE RELEVOS
  obtenerCurvaDeRelevos = async (values, idEmpresa) => {
    try {
      const url = `${API}/ReporteUtilizacionRelevos?empresa=${idEmpresa}`;

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(values),
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let reporte = data.relevos;
          return reporte;

        default:
          const error = data.err.message.toString();
          console.error(error);

          return [];
      }
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  //BTN FILTRAR CURVA DE RELEVOS
  filtrarCurvaRelevos = async (values) => {
    this.setState({ loading: true });
    const { Empresa } = this.state;
    const idEmpresa = Empresa;

    let strFecha = moment(values.fechaReporte).format("YYYY-MM-DD");
    const startOfMonth = moment(strFecha).startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment(strFecha).endOf("month").format("YYYY-MM-DD");

    let objetoEnviar = {
      ...values,
      fechaInicio: convertirFechaString(moment(startOfMonth), 1).fechaString,
      fechaFin: convertirFechaString(moment(endOfMonth), 2).fechaString,
    };

    const dataCurvaDeRelevos = await this.obtenerCurvaDeRelevos(objetoEnviar, idEmpresa);

    const reporteCurvaRelevos = dataCurvaDeRelevos.ReporteCurvaRelevos;
    const promedioRelevos = dataCurvaDeRelevos.ResultadosTarjetas.PromedioRelevos;
    const cantidadContratosRelevo = dataCurvaDeRelevos.ResultadosTarjetas.CantidadContratos;
    const cantidadActividades = dataCurvaDeRelevos.ResultadosTarjetas.ActividadesRelevos;
    const totalDiasRelevo = dataCurvaDeRelevos.ResultadosTarjetas.TotalDiasRelevo;

    this.setState({
      loading: false,
      dataRelevos: reporteCurvaRelevos,
      promedioRelevos,
      cantidadContratosRelevo,
      totalDiasRelevo,
      cantidadActividades,
      cargoReporte: values.cargoReporte,
      fechaReporte: values.fechaReporte,
    });
  };

  //API DETALLE CURVA DE RELEVOS
  obtenerDetalleCurvaRelevos = async (values, idEmpresa) => {
    try {
      const url = `${API}/ReporteUtilizacionRelevos/Detalle?empresa=${idEmpresa}`;

      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(values),
      });

      const status = response.status;
      let data;

      switch (status) {
        case 200:
        case 201:
          data = await response.json();
          let detalle = data.relevos;
          return detalle;

        default:
          const error = data.err.message.toString();
          console.error(error);

          return [];
      }
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  //BTN DETALLE CURVA DE RELEVOS
  detalleCurvaRelevos = async () => {
    this.setState({ loading: true });
    const { Empresa } = this.state;
    const idEmpresa = Empresa;
    const { cargoReporte, fechaReporte } = this.state;

    let strFecha = moment(fechaReporte).format("YYYY-MM-DD");

    const startOfMonth = moment(strFecha).startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment(strFecha).endOf("month").format("YYYY-MM-DD");

    let objetoEnviar = {
      cargoReporte: cargoReporte,
      fechaInicio: convertirFechaString(moment(startOfMonth), 1).fechaString,
      fechaFin: convertirFechaString(moment(endOfMonth), 2).fechaString,
    };

    const detalleCurvaRelevos = await this.obtenerDetalleCurvaRelevos(objetoEnviar, idEmpresa);

    this.setState({
      loading: false,
      dataDetalle: detalleCurvaRelevos,
      modalDetalle: true,
    });
  };

  obtenerExcelDetalleCurvaRelevos = async (dataDetalleRelevo) => {
    try {

      const url = `${API}/ReporteUtilizacionRelevos/DescargarExcel`;

      const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
          },
          body: JSON.stringify(dataDetalleRelevo),
      });

      const status = await response.status;
      const excel = await response.blob();
      
      switch (status) {
        case 200:
        case 201:
          const url = window.URL.createObjectURL(excel);
          const a = document.createElement("a");
          a.href = url;
          a.download = `DetalleRelevo.xlsx`;
          document.body.appendChild(a);
          a.click();
          a.remove();

          // Swal.fire({
          //     icon: "success",
          //     title: "Proceso Exitoso",
          //     text: "Documento descargado exitosamente",
          //     confirmButtonColor: "#E60AA3",
          //     confirmButtonText: "OK",
          // });
          return;

        default:
          Swal.fire({
            icon: "error",
            title: "Uuups...",
            text: "No se pudo cargar la información.",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "OK",
          });

          return;
      }
        
    } catch (error) {

      Swal.fire({
        icon: "error",
        title: "Uuups...",
        text: "No se pudo cargar la información.",
        confirmButtonColor: "#E60AA3",
        confirmButtonText: "OK",
      });
      
      return;
    }
  }

  //BTN DESCARGAR EXCEL CURVA DE RELEVOS
  descargarExcelDetalleCurvaRelevos = async () => {

    this.setState({ loading: true });
    let { dataDetalle } = this.state;

    if(dataDetalle && dataDetalle.length > 0){

      let arrayEnviar = [];

      dataDetalle.forEach(element => {
          let trabajador = {
              'NombreTrabajador': element.NombreTrabajador,
              'Rut': element.Rut,
              'Cargo': element.Cargo,
              'Estado': element.Trabajador_Finiquitado ? 'Finiquitado' : 'Vigente',
              'FechaInicio': convertirFechaString(moment.utc(element.Fecha_Inicio_Contrato), 1).fechaDDMMYYYY,
              'FechaFin': convertirFechaString(moment.utc(element.Fecha_Fin_Contrato), 1).fechaDDMMYYYY,
              'DiasRelevo': element.Cantidad_Dias_Relevo,
              'TipoContrato': element.Tipo_Contrato
          }

          arrayEnviar.push(trabajador);
      });

      let excelDescargado = await this.obtenerExcelDetalleCurvaRelevos(arrayEnviar);

      this.setState({loading: false});

    }
  }

  NuevaPestaña = (idUsuario) => {
    //window.open("/CalendarioTrabajador?Trabajador=" + idUsuario);
    window.open("/CalendarioTrabajadorNew?Trabajador=" + idUsuario);
  };

  render() {
    let { cargosEmpresa } = this.props;
    const {
      loading,
      dataRelevos,
      modalDetalle,
      promedioRelevos,
      cantidadContratosRelevo,
      cantidadActividades,
      totalDiasRelevo,
      dataDetalle,
    } = this.state;
    const config = {
      data: dataRelevos,
      padding: "auto",
      xField: "DiaActual",
      yField: "Personas_Contratadas",
    };

    return (
      <>
        <Loading cargando={loading} />
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            className="card-style-dashboard card-title-dashboard"
            title={
              <>
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={20}
                    lg={20}
                    xl={20}
                  >
                    Curva de relevos
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={4}
                    lg={4}
                    xl={4}
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      type="primary"
                      className="btn-detalle-dashboard"
                      onClick={() => {
                        this.detalleCurvaRelevos();
                      }}
                    >
                      <span className="text-detalle-dashboard">Detalle</span>
                      <span>
                        <EyeFilled className="iconoBotonDetalleCurvaRelevos" />
                      </span>
                    </Button>
                  </Col>
                </Row>
              </>
            }
          >
            {/* FILTROS */}
            <Form
              className={"maven-r"}
              name="formReporteUtilizacionRelevos"
              onFinish={this.filtrarCurvaRelevos}
              ref={this.formRef}
            >
              <div>
                <p className={"label-filtros"} style={{ marginBottom: "10px" }}>
                  Filtrar por:
                </p>
              </div>

              <Row gutter={20} style={{ marginBottom: "20px" }}>
                {/* FILTRO CARGO */}
                <Col
                  xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                  style={{ marginBottom: "15px" }}
                >
                  <Form.Item name="cargoReporte" noStyle>
                    <Select
                      mode="multiple"
                      placeholder="Cargo"
                      // className={"select-reporte-utilizacion-relevo"}
                      className={"select-style-dashboard"}
                      allowClear={true}
                      maxTagCount={2}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {cargosEmpresa.map((value, index) => (
                        <Select.Option key={index} value={value._id}>
                          {value.Nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* FILTRO FECHA */}
                <Col
                  xs={24} s={24} sm={7} md={7} lg={5} xl={5} xxl={3}
                  style={{ marginBottom: "15px" }}
                >
                  <Form.Item name="fechaReporte" noStyle>
                    <DatePicker
                      format={"MMMM YYYY"}
                      locale={locale}
                      picker="month"
                      // className={"select-reporte-utilizacion-relevo"}
                      className={"select-style-dashboard"}
                      allowClear={false}
                      placeholder="Mes"
                    />
                  </Form.Item>
                </Col>

                {/* BTN FILTRAR */}
                <Col
                  // xs={24} s={24} sm={5} md={5} lg={4} xl={4} xxl={2}
                  // className={"filtrar-contratos-btn-col"}
                  xs={24} s={24} sm={5} md={5} lg={5} xl={5} xxl={3}
                  className={"btn-col-filtrar-dashboard "}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    form="formReporteUtilizacionRelevos"
                    // className={"filtrar-contratos-btn"}
                    className={"btn-filtrar-dashboard"}
                  >
                    Filtrar
                  </Button>
                </Col>
              </Row>
            </Form>

            {/* INDICADORES */}
            <Row>
              <Col
                xs={24} sm={24} md={11} lg={5} xl={5} xxl={4}
                style={{
                  marginRight: "20px",
                  height: "170px",
                  marginBottom: "20px",
                }}
              >
                <Card
                  className="tarjetasReporteUtilizacionRelevo"
                  style={{
                    backgroundColor: "F4F4F4",
                    borderRadius: "17px",
                    height: "100%",
                  }}
                >
                  <div className="contenedorTitulo">
                    <label className="tituloTarjetaReporteUtilizacionRelevo">
                      Promedio de relevos en rango de fecha
                    </label>
                  </div>
                  <h2 className="valoresTarjetasReporteUtilizacionRelevo">
                    {promedioRelevos}
                  </h2>
                </Card>
              </Col>

              <Col
                xs={24} sm={24} md={11} lg={5} xl={5} xxl={4}
                style={{
                  marginRight: "20px",
                  height: "170px",
                  marginBottom: "20px",
                }}
              >
                <Card
                  className="tarjetasReporteUtilizacionRelevo"
                  style={{
                    backgroundColor: "F4F4F4",
                    borderRadius: "17px",
                    height: "100%",
                  }}
                >
                  <div className="contenedorTitulo">
                    <label className="tituloTarjetaReporteUtilizacionRelevo">
                      Cantidad de contratos con relevo
                    </label>
                  </div>
                  <h2 className="valoresTarjetasReporteUtilizacionRelevo">
                    {cantidadContratosRelevo}
                  </h2>
                </Card>
              </Col>

              <Col
                xs={24} sm={24} md={11} lg={5} xl={5} xxl={4}
                style={{
                  marginRight: "20px",
                  height: "170px",
                  marginBottom: "20px",
                }}
              >
                <Card
                  className="tarjetasReporteUtilizacionRelevo"
                  style={{
                    backgroundColor: "F4F4F4",
                    borderRadius: "17px",
                    height: "100%",
                  }}
                >
                  <div className="contenedorTitulo">
                    <label className="tituloTarjetaReporteUtilizacionRelevo">
                      Cantidad de actividades en que hubieron relevos
                    </label>
                  </div>

                  <h2 className="valoresTarjetasReporteUtilizacionRelevo">
                    {cantidadActividades}
                  </h2>
                </Card>
              </Col>

              <Col
                xs={24} sm={24} md={11} lg={5} xl={5} xxl={4}
                style={{ height: "170px" }}
              >
                <Card
                  className="tarjetasReporteUtilizacionRelevo"
                  style={{
                    backgroundColor: "F4F4F4",
                    borderRadius: "17px",
                    height: "100%",
                  }}
                >
                  <div className="contenedorTitulo">
                    <label className="tituloTarjetaReporteUtilizacionRelevo">
                      Días del mes con relevo
                    </label>
                  </div>
                  <h2 className="valoresTarjetasReporteUtilizacionRelevo">
                    {totalDiasRelevo}
                  </h2>
                </Card>
              </Col>
            </Row>
            
            {/* GRAFICO */}
            <Card 
            // className="tarjeta-contenedora-curva-relevos"
              className={"card-curva-relevos-dashboard"}
            >
              <Row
                style={{
                  marginBottom: "20px",
                }}
              >
                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}
                  style={{ paddingRight: "60px" }}
                >
                  <img src={Relevo} className="iconoRelevo" alt="icono modal" />
                </Col>

                <Col xs={14} sm={15} md={16} lg={18} xl={20} xxl={20}>
                  <h3
                    style={{
                      textAlign: "left",
                      color: "#061FCB",
                      fontFamily: "MavenPro-Bold",
                      paddingTop: "10px",
                    }}
                  >
                    Curva De Relevos
                  </h3>
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Line {...config} />
                </Col>
              </Row>
            </Card>

          </Card>
        </Col>

        <Modal
          visible={modalDetalle}
          onCancel={() => this.setState({ modalDetalle: false })}
          className={"modal-detalle-dashboard"}
          footer={null}
          closable={false}
          maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
        >
          <Row style={{ marginBottom: "20px" }}>
            <Col
              xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}
              style={{ paddingRight: "60px" }}
            >
              <img src={Relevo} className="iconoRelevo" alt="icono modal" />
            </Col>
            {/* <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}> */}
            <Col xs={13} sm={14} md={16} lg={18} xl={20} xxl={20}>
              <h3 className="modal-h3-dashboard">
                Trabajadores por curva de relevos
              </h3>
            </Col>

            {
              dataDetalle.length > 0 ? (
              <>
                <Col 
                  xs={1} sm={1} md={1} lg={1} xl={1} xxl={2}
                  style={{ textAlign: "right" }}
                >
                  <Button
                    type="primary"
                    className={'modal-btn-excel-dashboard'}
                    onClick={this.descargarExcelDetalleCurvaRelevos}
                  >
                    <span className="modal-btn-text-excel-dashboard">Descargar Excel</span>
                    <span><img src={Excel} className="modal-btn-icon-excel-dashboard" alt="Icono Excel"/></span>
                  </Button>
                </Col>
              </>
              ) 
              : 
              (<></>)
            }
          </Row>

          <hr className="modal-division-detalle-dashboard" />

          {/* TABLE */}
          <Table
            rowKey="_id"
            dataSource={dataDetalle}
            loading={this.props.tablaCargando}
            bordered={true}
            showSorterTooltip={false}
            scroll={{ x: true }}
            pagination={{ pageSize: 7 }}
            className={"tabla-detalle-curva-relevos"}
          >
            <Column
              title="Imagen"
              key="Imagen"
              align="center"
              render={(value) => {
                return (
                  <div>
                    <img src={value.Imagen} className="imagen-tabla" alt="i" />
                  </div>
                );
              }}
            />

            <Column
              sorter={(a, b) => {
                return a.NombreTrabajador.localeCompare(b.NombreTrabajador);
              }}
              render={(value) => {
                return value;
              }}
              title="Nombre Completo"
              dataIndex="NombreTrabajador"
              key="NombreTrabajador"
              align="left"
              ellipsis={true}
            />

            <Column
              sorter={(a, b) => {
                const quitarDigitoVerificadorA =
                  a.Rut !== undefined && a.Rut !== ""
                    ? a.Rut.split("-")
                    : "111";
                const quitarDigitoVerificadorB =
                  b.Rut !== undefined && b.Rut !== ""
                    ? b.Rut.split("-")
                    : "111";

                const rutA =
                  quitarDigitoVerificadorA !== "111"
                    ? quitarDigitoVerificadorA[0].split(".").join("")
                    : "111";
                const rutB =
                  quitarDigitoVerificadorB !== "111"
                    ? quitarDigitoVerificadorB[0].split(".").join("")
                    : "111";

                const numberFormatoA = parseInt(rutA, 10);
                const numberFormatoB = parseInt(rutB, 10);

                return numberFormatoA - numberFormatoB;
              }}
              title="Rut/DNI"
              dataIndex="Rut"
              key="Rut"
              ellipsis={true}
              render={(value) => (
                <p style={{ whiteSpace: "nowrap", marginBottom: "0px" }}>
                  {value}
                </p>
              )}
            />

            <Column
              sorter={(a, b) => {
                let cargoA = a.Cargo !== undefined ? a.Cargo.Nombre : "aaaaa";
                let cargoB = b.Cargo !== undefined ? b.Cargo.Nombre : "aaaaa";
                return cargoA.localeCompare(cargoB);
              }}
              title="Cargo"
              dataIndex="Cargo"
              key="Cargo"
              ellipsis={true}
              render={(cargo) => {
                if (cargo) {
                  return cargo;
                } else {
                  return "N/A";
                }
              }}
            />

            <Column
              title="Estado"
              dataIndex="Trabajador_Finiquitado"
              key="Trabajador_Finiquitado"
              ellipsis={true}
              render={(trabajadorFiniquitado) => {
                if (trabajadorFiniquitado) {
                  return "Finiquitado";
                } else {
                  return "Vigente";
                }
              }}
            />

            <Column
              title="Fecha Inicio Contrato"
              dataIndex="Fecha_Inicio_Contrato"
              key="Fecha_Inicio_Contrato"
              ellipsis={true}
              render={(fechaInicioContrato) => {
                return convertirFechaString(moment.utc(fechaInicioContrato), 1)
                  .fechaDDMMYYYY;
              }}
            />

            <Column
              title="Fecha Fin Contrato"
              dataIndex="Fecha_Fin_Contrato"
              key="Fecha_Fin_Contrato"
              ellipsis={true}
              render={(fechaFinContrato) => {
                return convertirFechaString(moment.utc(fechaFinContrato), 1)
                  .fechaDDMMYYYY;
              }}
            />

            <Column
              title="Cantidad Días Relevo"
              dataIndex="Cantidad_Dias_Relevo"
              key="Cantidad_Dias_Relevo"
              ellipsis={true}
              render={(Cantidad_Dias_Relevo) => {
                return Cantidad_Dias_Relevo;
              }}
            />

            <Column
              title="Tipo Contrato"
              dataIndex="Tipo_Contrato"
              key="Tipo_Contrato"
              ellipsis={true}
              render={(Tipo_Contrato) => {
                return Tipo_Contrato;
              }}
            />

            {/* Ficha trabajador */}
            <Column
              title="Detalle"
              dataIndex="IdTrabajador"
              key="FichaTrabajador"
              align="center"
              render={(value) => (
                <Tooltip title="Perfil Trabajador" color={'geekblue'}>
                  <Button
                    onClick={() => {
                      this.NuevaPestaña(value);
                    }}
                    className="acciones-trabajador-button hover-azul"
                    icon={<EyeFilled style={{ fontSize: '18px' }} />}
                  />
                </Tooltip>
              )}
            />

          </Table>

        </Modal>
      </>
    );
  }
}

export default DashboardCurvaRelevos;
