import React, { useState } from "react";
import calendario from "../../img/dashboard/calendario.png";
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  PageHeader,
  Select,
  DatePicker,
  Radio,
  Table,
  Badge
} from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import "moment/locale/es";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
//import moment from "moment";
import Swal from "sweetalert2";
import {
  convertirFechaString,
  corregirZonaHoraria
} from "../../funcionesGenericas/fechas";
import "./FormularioAsignacionFaenas.css";

const { RangePicker } = DatePicker;

const columns = [
 
  {
    title: "Plazas",
    // dataIndex: "plazas",
    key: "plazas",
    className: "columna-plazas-tabla-actividades",
    render: (value) => (
      <>
        {value ? (
          <label>{value.plazas}</label>
        ) : (
          <>
            <label style={{ marginRight: "2px" }}>{value.plazas}</label>
            <span style={{ color: "red" }}>**</span>
          </>
        )}
      </>
    ),
  },
];


const columnsDos = [
 
  {
    title: "Plazas",
    // dataIndex: "plazas",
    key: "plazas",
    className: "columna-plazas-tabla-actividades",
    render: (value) => (
      <>
        {value ? (
          <label>{value.plazas}</label>
        ) : (
          <>
            <label style={{ marginRight: "2px" }}>{value.plazas}</label>
            <span style={{ color: "red" }}>**</span>
          </>
        )}
      </>
    ),
  },
];

const FormularioAsignacionFaenas = (props) => {
  //constante para manejar el formulario
  const [form] = Form.useForm();
  const [vigencia, setVigencia] = useState(true);
  const [confirmacionTraspaso, setConfirmacionTraspaso] = useState(false);
  const [mostrarDetalleTraspaso, setMostrarDetalleTraspaso] = useState(false);
  // const [mostrarRadio, setMostrarRadio] = useState(false);
  const [nombreCC, setNombreCC] = useState('');
  const [deshabilitarRadioButton, setDeshabilitarRadioButton] = useState(true);
  const [centroCostoOrigenName, setCentroCostoOrigenName] = useState('');
  const [actividadOrigenName, setActividadOrigenName] = useState('');
  const [centroCostoDestinoName, setCentroCostoDestinoName] = useState('');
  // const [actividadDestinoName, setActividadDestinoName] = useState('');

  //FUNCIONES PARA MANJERA EL FORMULARIO
  const onFinish = (values) => {
    values.Vigencia = vigencia;
    values.ConfirmacionTraspaso = confirmacionTraspaso;
    

    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);

      // return false;
      if (confirmacionTraspaso === true) {
        let contadorTrabajadores = 0;
        let faltaFecha= false;
        let seleccionadoU = false;
        props.detalleDestino.map((detD)=>{     
          if(detD.idTrabajador){
            contadorTrabajadores++
            if( detD.rangoFechas[0] === "" || detD.rangoFechas[1] === "" || detD.rangoFechas[0] === undefined || detD.rangoFechas[1] === undefined){
              faltaFecha = true;
            
            }
          }
         
        })

        if (contadorTrabajadores === 0) {
           //No hay trabajadores Seleccionados
           Swal.fire({
            title: "Uuups...",
            text: "Debe Seleccionar almenos un trabajador",
            icon: "error",
            confirmButtonColor: "#E60AA3",
            confirmButtonText: "Continuar",
          });
          return;
        }

        if (faltaFecha === true) {
          //Falta sellecionar fechas
          Swal.fire({
           title: "Uuups...",
           text: "Debe Seleccionar fechas disponibles",
           icon: "error",
           confirmButtonColor: "#E60AA3",
           confirmButtonText: "Continuar",
         });
         return;
       }

        //Hay trabajadores Seleccionados
        const objAux = {
          ...values,
          Empresa: usuario.EmpresaSeleccionada.Empresa._id,
          Expira: false,
  
          Fecha_Inicio: convertirFechaString(values.Rango_Fechas[0], 1).fechaString,
          Fecha_Fin: convertirFechaString(values.Rango_Fechas[1], 2).fechaString,
  
          Fecha_Inicio_Unix: convertirFechaString(values.Rango_Fechas[0], 1).fechaUNIX,
          Fecha_Fin_Unix: convertirFechaString(values.Rango_Fechas[1], 2).fechaUNIX,
          plantilla: props.detalleDestino
        };

        props.traspasarTrabajadoresModal(objAux);
       
      

      }else{
        const objAux = {
          ...values,
          Empresa: usuario.EmpresaSeleccionada.Empresa._id,
          Expira: false,
  
          Fecha_Inicio: convertirFechaString(values.Rango_Fechas[0], 1).fechaString,
          Fecha_Fin: convertirFechaString(values.Rango_Fechas[1], 2).fechaString,
  
          Fecha_Inicio_Unix: convertirFechaString(values.Rango_Fechas[0], 1).fechaUNIX,
          Fecha_Fin_Unix: convertirFechaString(values.Rango_Fechas[1], 2).fechaUNIX,
  
        };
        props.CrearFaena(objAux);
      }
      
    }
  };

 
  const onFinishFailed = (values) => {
    const error = values.errorFields[0].errors[0];
    Swal.fire({
      title: "Uuups...",
      text: error,
      icon: "error",
      confirmButtonColor: "#E60AA3",
      confirmButtonText: "Continuar",
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };

  const cambiarVigencia = (val) => {
    setVigencia(val);
  };

  const cambiarTraspaso = (val) => {
    setConfirmacionTraspaso(val);
  };


  //Desbloquea la seccion de seleccion de actividad origen
  const buscarDetalle = async (_id) => {

    cambiarPlantillaEquipoSeleccionada();
    const {idEmpresa} = props;
    const idPlantilla =  form.getFieldValue("PlantillaBase");

    props.actividadesCC.forEach(act => {
      if (act._id.toString() === _id.toString()) {
        setActividadOrigenName(act.Nombre);
      }
    });

    setMostrarDetalleTraspaso(true);
    props.detalleActividadOrigenModal( idEmpresa, _id );
    props.detalleActividadDestinoModal( idEmpresa, idPlantilla );

  };

  //Seleccionar persona y traer fechas disponibles
  const disponibilidadTransbordoSeleccionado = (ID_TRABAJADOR, record) => {
    
    const {idEmpresa} = props;
    const fechaPlanificacion = form.getFieldValue("Rango_Fechas");

    props.disponibilidadTransbordoTrabajadorModal(
      ID_TRABAJADOR, 
      convertirFechaString(fechaPlanificacion[0],1).fechaString,
      convertirFechaString(fechaPlanificacion[1],2).fechaString,
      idEmpresa,
      record
    );
  };


  //Se actualiza y cambia la plantilla destino
  const cambiarPlantillaEquipoSeleccionada = () => {

    const {idEmpresa} = props;
    const idPlantilla =  form.getFieldValue("PlantillaBase");
    const idActividadOrigen =  form.getFieldValue("ActividadOrigen");
    props.detalleActividadDestinoModal( idEmpresa, idPlantilla,idActividadOrigen );

  };

  //se validan los los campos para poder habilitar radio buttons
  const validarCamposVacios = () => {
    
    let valores = form.getFieldsValue(["Nombre","Centro_De_Costo","Rango_Fechas","PlantillaBase"]);
   
    if (valores.Nombre && valores.Centro_De_Costo && valores.Rango_Fechas && valores.PlantillaBase) {
      setDeshabilitarRadioButton(false);
    } else {
      setDeshabilitarRadioButton(true);
      form.setFieldsValue({ConfirmacionTraspaso: false});
      cambiarTraspaso(false);
    }
  };

  const cambioFechaDefinicion = () =>{
    if (confirmacionTraspaso === true && mostrarDetalleTraspaso===true ) {
      props.cambioFechaDefinicion();
    }
  }

  const handlePlatilla = ()=>{
    cambiarPlantillaEquipoSeleccionada();
    validarCamposVacios();
  }

  const buscarActividades = async (_id) => {

    form.setFieldsValue({
      ActividadOrigen: undefined,
    });
    //busqueda de nombre cc seleccionado origen
    props.centrosCosto.forEach(cc => {
      if (cc._id.toString() === _id.toString()) {
        setCentroCostoOrigenName(cc.Nombre);
      }
    });

    setMostrarDetalleTraspaso(false);
    props.traerActividadesCentrosCostos(_id);

  };

  const cambiarCentroCosto = (nombreCentro)=>{
    setNombreCC(nombreCentro);
  }
  

 

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
 
      <Row style={{ padding: "5px 20px 0px 20px" }}>
        <Col span={24} style={{ display: "flex", borderRadius:"16px" }} className="title">
          <img src={calendario} style={{ width: 50 }} alt="icono modal" />
          <PageHeader
            style={{ padding: "0px 10px 0px 10px" }}
            className="site-page-header"
            title="Crear nueva actividad"
          />
        </Col>

        <Col span={24}>
          <Card className="card-actividad-modal" style={{ width: "100%" }}>
            <Form.Item
              name="Nombre"
              label="Nombre:"
              rules={[
                {
                  required: true,
                  message: "Introduzca el nombre de la faena",
                  min: 2,
                  max: 20,
                },
              ]}
            >
              <Input name="Nombre" className="act-modal-campos" onChange={validarCamposVacios}/>
            </Form.Item>

            <Form.Item
              label="Centro de costos"
              name={"Centro_De_Costo"}
              style={{
                marginTop: "20px",
              }}
              rules={[
                {
                  required: true,
                  message: "Seleccione el centro de costo",
                },
              ]}
            >
              <Select
                className="act-modal-campos"
                name="Centro_De_Costo"
                placeholder="Selecciona centro de costo"
                
                onSelect={(value) => {

                  const nombre = props.ObtenerAsignacionesIdCentroCosto(value);
                  setCentroCostoDestinoName(nombre);
                 
                }}
                onChange={validarCamposVacios}
              >
                {props.centrosCosto.map((value, index) => (
                  <Select.Option key={index} value={value._id} name="Cargo">
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="Rango_Fechas"
              label="Fechas de definición"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Debe introducir una fecha de inicio y término",
                },
              ]}
            >
              <RangePicker
                locale={locale}
                allowClear={false}
                name="Rango_Fechas"
                format={["DD-MM-YYYY"]}
                className="act-modal-campos"
                onChange={()=>{
                  validarCamposVacios();
                  cambioFechaDefinicion();
                }}
                disabled={props.fechaDeshabilitada}
                disabledDate={(dia) => {
                  let validacion = false;

                  props.faenasFechasCentroCosto.forEach((element) => {
                    
                    // let fechaInicioCC = dateOrMomentToMomentTimeZoneChile(element.Fecha_Inicio);
                    // let fechaFinCC = dateOrMomentToMomentTimeZoneChile(element.Fecha_Fin);

                    let fechaInicioCC = corregirZonaHoraria(moment(element.Fecha_Inicio), 1);
                    let fechaFinCC = corregirZonaHoraria(moment(element.Fecha_Fin), 2);

                    if (dia.isBetween(fechaInicioCC, fechaFinCC, null, '[]')) {
                      validacion = true;
                    }
                  });

                  if (validacion === true) {
                    return true;
                  }
                }}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>

            <Form.Item
              label="Plantilla de equipo"
              name="PlantillaBase"
              style={{
                marginTop: "20px",
              }}
              rules={[
                {
                  required: true,
                  message: "Seleccione una plantilla de equipo de trabajo",
                },
              ]}
            >
              <Select
                className="act-modal-campos"
                name="PlantillaBase"
                placeholder="Selecciona una plantilla"
                onChange={ handlePlatilla }
              >
                {props.plantillas.map((value, index) => (
                  <Select.Option
                    key={index}
                    value={value._id}
                    name="PlantillaBase"
                  >
                    {value.Nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {/* <Form.Item label="Vigencia" style={{ textAlign: "left" }}>
              <Switch
                defaultChecked
                checked={vigencia}
                onChange={cambiarVigencia}
                className="switch"
              />
            </Form.Item> */}

          {/* //codigo nuevo 03-12-21 */}

          <Form.Item
            name="ConfirmacionTraspaso"
            label="¿Desea traspasar trabajadores desde otra actividad?:"
            //className="inputsFormDescanso_Text"
            rules={[
              {
                required: true,
                message: "Obligatorio",
              },
            ]}
          >
            <Radio.Group
              className={"act-modal-campos radio-group-modal"}
              disabled={deshabilitarRadioButton}
              defaultValue={confirmacionTraspaso}
              onChange={(value) => {
                cambiarTraspaso(
                  value.target.value
                );
              }}
              //value={true}
            >
              <Radio value={true}>Si</Radio>
              <Radio value={false} style={{marginLeft:'55px'}}>No</Radio>
            </Radio.Group>
          </Form.Item>


          {
            confirmacionTraspaso=== true?
            <>

              <Form.Item
                label="Seleccionar centro de origen"
                name="CentroOrigen"
                // style={{
                //   marginTop: "20px",
                // }}
                rules={[
                  {
                    required: true,
                    message: "Seleccionar una centro de origen",
                  },
                ]}
              >
                <Select
                  className="act-modal-campos"
                  name="CentroOrigen"
                  placeholder="Seleccionar centro de origen"
                  onSelect={e => buscarActividades(e)}
                  onChange={e =>cambiarCentroCosto(e)}
                >
                    {props.centrosCosto.map((value, index) => (
                      <Select.Option key={index} value={value._id}  name="CentroOrigen">
                        {value.Nombre}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
         
              <Form.Item
                label="Seleccionar actividad de origen"
                name="ActividadOrigen"
                // style={{
                //   marginTop: "20px",
                // }}
                rules={[
                  {
                    required: true,
                    message: "Seleccionar una actividad de origen",
                  },
                ]}
              >
                <Select
                  className="act-modal-campos"
                  name="ActividadOrigen"
                  placeholder="Seleccionar actividad de origen"
                  onSelect={e => buscarDetalle(e)}
                >
                      {
                        props.actividadesCC.map((value, index) => (
                            <Select.Option key={index} value={value._id} name="ActividadOrigen">
                              {value.Nombre}
                            </Select.Option>
                        ))
                      }
                </Select>
              </Form.Item>
            </>
            :
            ' '
          }
       
          {/* //codigo nuevo 03-12-21 */}
          </Card>
          {
            confirmacionTraspaso === true && mostrarDetalleTraspaso===true? 
            <>
            <Card
              style={{
                marginTop:'10px',
                background:'#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow:'0px 3px 6px #00000029',
                border:'1px solid #E2E2E2',
                borderRadius:'16px',
                opacity:'1'
              }}
            >

              <Row>

              {/* /Detalle Actividad Origen */}
               <Col
                xs={24} sm={24} md={24} lg={8} xl={8}
                //className={"col-faena-form"}
               >
                  <Row>
                    <Col>
                      <h3 className={"h3-cc-modal"}>
                       {`Centro de costo origen: ${ centroCostoOrigenName } `}    
                      </h3>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <h3 className={"h3-act-modal"}>
                      {`Actividad: ${ actividadOrigenName }`}  
                      </h3>
                    </Col>
                  </Row>
                {
                  
                
                    <Table
                      className="table-actividad"
                      style={{overflowX:"auto"}}
                      pagination={false}
                      columnWidth={100}
                      columns={columns}
                      expandable={{
                        expandedRowRender: record => record.bitacoraTrabajadores.map((value) => (
                          
                          value.Trabajadores
                          ?
                          `${value.Trabajadores.Nombre} ${value.Trabajadores.Apellido_Paterno} ${value.Trabajadores.Apellido_Materno} `
                         
                          :
                          <Badge status="error" text="No tiene trabajador" />
                          
                        ))
                      }}
                      dataSource={props.detalleOrigen? props.detalleOrigen:[]}
                    />
                }
              </Col>
              
              {/* /Divicion */}
              <Col
              xs={24} sm={24} md={24} lg={2} xl={2}
              className={"col-icon-form"}
              >
                <DoubleRightOutlined />
              </Col>
              

              {/* /Detalle Actividad Destino */}
              <Col
                xs={24} sm={24} md={24} lg={14} xl={14}
               //className={"col-faena-form"}
              >
                <Row>
                  <Col>
                    <h3 className={"h3-cc-modal"}>
                      {`Centro de costo destino: ${ centroCostoDestinoName } `}   
                    </h3>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h3 className={"h3-act-modal"}>
                      {`Actividad: ${ form.getFieldsValue("Nombre").Nombre }`}  
                    </h3>
                  </Col>
                </Row>

                  {
                      <Table
                      scroll={{ x: 'calc(250px + 100%)'}}
                      //scroll={{ x: 'calc(100%)'}}
                      className={"table-actividad"}
                      //style={{overflowX:"auto"}}
                      pagination={false}
                      columns={columnsDos}
                      expandable={{
                        expandedRowRender: record =>  
                        <>
                        
                        <Row>
                          <Col
                             xs={{ span: 10, offset: 0 }}
                             sm={{ span: 10, offset: 0 }}
                             md={{ span: 10, offset: 0 }}
                            //  lg={{ span: 10, offset: 0 }}
                            //  xl={{ span: 10, offset: 0 }}
                            //  xxl={{ span: 10, offset: 0 }}
                             lg={{ span: 8, offset: 0 }}
                             xl={{ span: 8, offset: 0 }}
                             xxl={{ span: 8, offset: 0 }}
                            //span={6}
                          >
                            <label style={{fontFamily: "MavenPro-Bold"}}>Nombre</label>
                            <Select
                              className="campos"
                              name="TrabajadorSeleccionadoDestino"
                              placeholder="Seleccionar"
                              //onSelect={ (e)=> props.setterTrabajador(record,e)}
                              value={record.idTrabajador}
                              onChange={(value)=>disponibilidadTransbordoSeleccionado(value,record)}
                              allowClear={true}
             
                            >
                               {
                                 record.TrabajadoresSeleccionados !== undefined && record.TrabajadoresSeleccionados.length !== 0 ?
                                  record.TrabajadoresSeleccionados.map((value, index) => (
                                    
                                      <Select.Option key={index} value={value.Trabajadores._id} name="TrabajadorSeleccionadoDestino" disabled={value.deshabilitado === true ? true : false}>
                                        {         
                                          `${value.Trabajadores.Nombre} ${value.Trabajadores.Apellido_Paterno} ${value.Trabajadores.Apellido_Materno}`
                                        }
                                       
                                      </Select.Option>
                                      
                                  ))
                                  :
                                  <></>
                                }      
                            </Select>
                            {
                              //si esta seleccionado muestra el mensaje (ternaria)
                              record.idTrabajador !== '' ?
                              record.fechaDisponibilidad !== undefined && Array.isArray(record.fechaDisponibilidad) 
                              ?
                                record.fechaDisponibilidad.length !== 0
                                ?
                                <Badge status="processing" text="Se puede planificar" style={{color:'green',marginTop:'5px'}} />
                                :
                                <Badge status="error" text="No se puede planificar" style={{color:'red' ,marginTop:'5px'}}  />
                              :
                              <></>
                              :
                              <></>
                            }
                            
                          </Col>
                     {
                       //console.log(props)
                     }
                          <Col
                             xs={{ span: 12, offset: 2 }}
                             sm={{ span: 12, offset: 2 }}
                             md={{ span: 12, offset: 2 }}
                            //  lg={{ span: 12, offset: 2 }}
                            //  xl={{ span: 12, offset: 2 }}
                            //  xxl={{ span: 12, offset: 2 }}
                            //span={12}  offset={2}
                             lg={{ span: 8, offset: 2 }}
                             xl={{ span: 8, offset: 2 }}
                             xxl={{ span: 8, offset: 2 }}
                             span={8}  offset={2}
    
                          >
                            <label style={{fontFamily: "MavenPro-Bold"}}>Fechas</label>
                            <RangePicker
                              locale={locale}
                              name="Rango_Fechas_Trabajador"
                              format={["DD-MM-YYYY"]}
                              //className="campos table-rpicker"
                              //className="table-rpicker"
                              allowClear={false}
                              placeholder={["Fecha inicio","Fecha final"]}
                              disabled={record.deshabilitarRangoFechas === false? false:true}
                              onChange={(e)=> props.setterTrabajadorFecha(record,e)}
                              value={[record.rangoFechas[0] === ''? null : moment.utc(record.rangoFechas[0]),record.rangoFechas[1] === ''? null : moment.utc(record.rangoFechas[1])]}
                              disabledDate={(dia) => {
                                
                                let validacion = false;
                         
                                let fechaInicioCC = corregirZonaHoraria(moment.utc(form.getFieldsValue("").Rango_Fechas[0]), 1);
                                let fechaFinCC = corregirZonaHoraria(moment.utc(form.getFieldsValue("").Rango_Fechas[1]), 2);
                                const diasDisponibles = record.fechaDisponibilidad !== undefined && Array.isArray(record.fechaDisponibilidad) ? record.fechaDisponibilidad : [] ;
                            
                                const cantEncontrado = diasDisponibles.filter((diasDispo)=> moment.utc(diasDispo).format('YYYY-MM-DD') === moment.utc(dia).format('YYYY-MM-DD'));
                                
                                if (!dia.isBetween(fechaInicioCC, fechaFinCC, null, '[]') || cantEncontrado.length === 0) {
                            
                                  validacion = true;
                                }
                                if (validacion === true) {
                                  return true;
                                }
                              }}
                              style={{
                                width: "100%",
                              }}
                            />
                            {
                              //si esta seleccionado muestra el mensaje (ternaria)
                              record.fechaInvalida !== undefined ?
                                record.fechaInvalida !== true
                                ?
                                  <Badge status="processing" text="Se escogieron correctamente las fechas" style={{color:'green',marginTop:'5px'}} />
                                :
                                  <Badge status="error" text="Se deben seleccionar fechas validas" style={{color:'red' ,marginTop:'5px'}}  />
                              
                              :
                              <></>
                            }
                          </Col>
                        </Row>
    
    
    
                        </>,
                        //rowExpandable: record => record.name !== 'Not Expandable',
                      }}
                      dataSource={props.detalleDestino? props.detalleDestino:[]}
                    />
                  }
              
               </Col>
             </Row>

        
          </Card>
            </>
            :
            ' '
            // <>
            //   <h3 style={{textAlign:'center'}}>Confirmar traspaso</h3>
            // </>
          }
            
        </Col>
        <Col span={24} style={{ textAlign: "right", marginTop: 20}}>
          <Button className="guardar-formX" type="primary" htmlType="submit">
            Guardar
          </Button>
        </Col>
      </Row>
      <style jsx="true">
          {`
            .ant-picker-panels
              > *:first-child
              button.ant-picker-header-next-btn {
              visibility: visible !important;
            }

            .ant-picker-panels
              > *:first-child
              button.ant-picker-header-super-next-btn {
              visibility: visible !important;
            }

            .ant-picker-panels > *:last-child {
              display: none;
            }

            .ant-picker-panel-container,
            .ant-picker-footer {
              width: 280px !important;
            }

            .ant-picker-footer-extra > div {
              flex-wrap: wrap !important;
            }
          `}
        </style>
    </Form>
  );
};

export default FormularioAsignacionFaenas;
