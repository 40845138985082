import React from 'react';
import LocaleES from 'antd/lib/date-picker/locale/es_ES';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
function Picker(props) {

    const {
        obtenerFecha,
        // fechasBloqueadas
    } = props;

    let isDisabled = true;

    // if (fechasBloqueadas) isDisabled = false;

    return (
        <>
            <DatePicker
                format={'YYYY-MM-DD'}
                onChange={obtenerFecha}
                locale={LocaleES}
                // picker="day"
                disabled={false}
                disabledDate={(dia) => {
                    return dia > moment();

                    // let fechaEncontrada = false;

                    // fechasBloqueadas.forEach(fecha => {
                    //     if (dia.format('YYYY-MM-DD') === moment(fecha).format('YYYY-MM-DD')) {
                    //         fechaEncontrada = true;
                    //     }
                    // });

                    // if (fechaEncontrada === true) {
                    //     return true;
                    // } else {
                    //     return false;
                    // }
                }}
            />
        </>
    )
}

Picker.propTypes = {
    obtenerFecha: PropTypes.func,
    fechasBloqueadas: PropTypes.array
}

export default Picker
