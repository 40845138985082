import React from "react";
import "../Empresion.css";
import byLogo from "../../img/logos/logo-by-people-managment.svg";
import { Row, Col, Layout, Card } from "antd";
import Loading from "../../components/loading/Loading";
import API from "../../Redux/Api/api";
import moment from "moment"
import { convertirFechaString } from "../../funcionesGenericas/fechas";

const queryString = require('query-string');

class AccidenteImpresion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderDocumento: false,
      loading: true,
      informacionAccidente: null,
    };
  }

  componentDidMount = async () => {
    const parsed = queryString.parse(this.props.location.search);
    let idAccidente = parsed.Accidente;

    let respuesta = await this.buscarAccidente(idAccidente);

    if (respuesta.status === 200) {

      let objAccidente = respuesta.json.data;

      const
        {
          IdTrabajador:
          { Nombre,
            Apellido_Paterno,
            Apellido_Materno,
            Cargo: {
              Nombre: NombreCargo,
            },
            Empresa: {
              Imagen,
              //Razon_Social
            }
          },
          Maniobra,
          Fecha_Inicio,
          Fecha_Fin,
        } = objAccidente


      const strFechaInicio = moment.utc(Fecha_Inicio).format('YYYY-MM-DD');
      const fechaInicio = convertirFechaString(moment.utc(strFechaInicio), 1).fechaDDMMYYYY;

      const strFechaFin = Fecha_Fin ? moment.utc(Fecha_Fin).format('YYYY-MM-DD') : undefined;
      const fechaFin = strFechaFin ? convertirFechaString(moment.utc(strFechaFin), 1).fechaDDMMYYYY : strFechaFin;

      let informacionAccidente = {
        Nombre_trabajador: `${Nombre} ${Apellido_Paterno} ${Apellido_Materno}`,
        Nombre_Cargo: `${NombreCargo}`,
        Fecha_Inicio: `${fechaInicio}`,
        Fecha_Fin: `${fechaFin}`,
        Imagen_Empresa: Imagen,
        Maniobra,
      }

      this.setState({
        informacionAccidente: informacionAccidente,
        renderDocumento: true,
        loading: false,
      });


      setTimeout(function () {
        window.print();
      }, 1000);

    }
  }

  buscarAccidente = async (idAccidente) => {
    let respuesta = {
      status: 0,
      json: null,
    }
    try {

      const url = `${API}/AccidenteLaboral/${idAccidente}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      const data = await response.json();

      respuesta.status = status;
      respuesta.json = data;

      return respuesta;

    } catch (error) {
      respuesta.status = -1;
      respuesta.json = error;
      return respuesta;
    }
  }

  Pintar() {
    if (this.state.renderDocumento === true) {
      return (
        <Layout id="divEmpresion" className="layoutImpresion">
          <Row
            style={{
              paddingTop: "30px",
            }}
          >
            <Col span={18}>
              <Card className="CardMaestra">
                <Card className="CardHeader">
                  <h3 className="ImpresionTitulo">
                    <img
                      src={this.state.informacionAccidente.Imagen_Empresa}
                      className="ImpresionLogoEmpresa"
                      alt="Logo empresa"
                    />{" "}
                    Comprobante de Licencia Mutual
                  </h3>
                </Card>
                <Row
                  style={{
                    marginTop: "30px",
                    padding: "30px",
                  }}
                >
                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={6}
                  >
                    <h5 className="ImprimirNombreCampo">Nombre trabajador: </h5>
                  </Col>
                  <Col span={18}>
                    <h5 className="ImprimirValorCampo">{this.state.informacionAccidente.Nombre_trabajador}</h5>
                  </Col>

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={6}
                  >
                    <h5 className="ImprimirNombreCampo">Cargo: </h5>
                  </Col>
                  <Col span={18}>
                    <h5 className="ImprimirValorCampo">{this.state.informacionAccidente.Nombre_Cargo}</h5>
                  </Col>

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={6}
                  >
                    <h5 className="ImprimirNombreCampo">Días de ausencia por licencia: </h5>
                  </Col>
                  <Col span={18}>
                    {this.state.informacionAccidente.Fecha_Fin !== 'undefined' ?
                      (
                        <h5 className="ImprimirValorCampo">Desde: {this.state.informacionAccidente.Fecha_Inicio} Hasta: {this.state.informacionAccidente.Fecha_Fin}</h5>
                      ) :
                      (
                        <h5 className="ImprimirValorCampo">Desde: {this.state.informacionAccidente.Fecha_Inicio} Hasta: Indefinido</h5>
                      )
                    }

                  </Col>

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={6}
                  >
                    <h5 className="ImprimirNombreCampo">
                      Maniobra que realizaba el equipo:{" "}
                    </h5>
                  </Col>
                  <Col span={18}>
                    <h5 className="ImprimirValorCampo">
                      {this.state.informacionAccidente.Maniobra}
                    </h5>
                  </Col>

                </Row>
                <Card className="CardFirma">
                  <Row>
                    <Col className="contFirmas" span={12}>
                      <hr />
                      <h3 className="NombresFirma">FIRMA DEL EMPLEADOR</h3>
                    </Col>
                    <Col className="contFirmas" span={12}>
                      <hr />

                      <h3 className="NombresFirma">FIRMA DEL TRABAJADOR</h3>
                    </Col>
                  </Row>
                </Card>
              </Card>
              <Row
                style={{
                  paddingTop: "20px",
                  width: "700px",
                }}
              >
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <img src={byLogo} alt="by people managment" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return false;
    }
  }

  render() {
    return (<>
      <Loading cargando={this.state.loading} />
      {this.Pintar()}
    </>);
  }
}

export default AccidenteImpresion;