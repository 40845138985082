import React, { Component } from "react";
import { Layout, Col, Row, Card, DatePicker, Modal, Badge, Tooltip } from "antd";
import DashboardDinamicoTrabajador from "../../components/dashboardDinamicoTrabajador/DashboardDinamicoTrabajador";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";

import FormularioTrabajadorVisualizar from "../../components/fichaTrabajador/FichaTrabajador";
import FormularioTrabajadorVisualizarNuevo from "../../components/fichaTrabajador/FichaTrabajadorNuevo";//Nuevo
import FormularioFichaProfesional from "../../components/formularioFichaProfesional/FormularioFichaProfesional";
import FormularioEntregaEPP from "../../components/formularioEntregaEPP/FormularioEntregaEPP";
import FormularioRegistroVacaciones from "../../components/formularioRegistroVacaciones/FormularioRegistroVacaciones";
import FichaBonificaciones from "../../components/bonificaciones/FichaBonificaciones";

import avatar from "../../img/icons/avatar.svg";
import Carpeta from "../../img/icons/Carpeta.svg";
import Proteccion from "../../img/icons/Proteccion.svg";
import Reloj from "../../img/icons/Reloj.svg";

import embarcado from "../../img/icons/embarcado.svg";
import descanso from "../../img/icons/descanso.svg";
import vacaciones from "../../img/icons/vacaciones.svg";
import vacaciones2 from "../../img/icons/vacacionesClaro.svg";
import licencia from "../../img/icons/Licencia.svg";
import accidente from "../../img/icons/accidente.svg";
import permiso from "../../img/icons/Permiso.svg";
import ausente from "../../img/icons/Ausente.svg";
import enTransito from "../../img/icons/enTransito.svg";
import noAsignado from "../../img/icons/NoAsignado.svg";

import { connect } from "react-redux";
import * as CalendarioTrabajadorActions from "../../Redux/actions/CalendarioTrabajadorActions";

import "./CalendarioTrabajador.css";

import Timeline, {
  DateHeader,
  TimelineHeaders,
  TodayMarker,
  CustomMarker,
} from "react-calendar-timeline";
import "../../../node_modules/react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import {
  convertirFechaString,
  corregirZonaHoraria

} from "../../funcionesGenericas/fechas";
import accesosPagina from '../../funcionesGenericas/accesosUsuario';
import FormularioTrabajadorEditar from "../../components/formTrabajador/FormularioTrabajadorEditar";
import FormularioTrabajadorEditarNuevo from "../../components/formTrabajador/FormularioTrabajadorEditarNuevo";
import Swal from "sweetalert2";

const queryString = require("query-string");
const { Header, Content } = Layout;
const { RangePicker } = DatePicker;

class CalendarioTrabajador extends Component {

  state = {
    Loading: true,
    modalFicha: false,
    modalProfesional: false,
    modalEntregaEpp: false,
    modalDocumentos: false,

    //detalle
    eppDetalle: false,

    cargos: [],
    regiones: [],
    comunas: [],
    provincias: [],
    titulos: [],
    especialidades: [],
    epps: [],
    regimenes: [],
    documentos: [],

    ciudadesSeleccionadas: [],
    comunasSeleccionadas: [],

    editarCerrado: true,

    //arreglo con fechas por defecto
    fechasBusqueda: [],

    //descansos dados
    bonificacionesDescansos: [],

  };

  async componentDidMount() {

    let usuario = localStorage.getItem("usuario-people-manage");

    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

      let parametros = queryString.parse(this.props.location.search);

      let inicioFiltro;
      let finFiltro;

      // si parametros.inicio o parametros.fin son undefined, tomará la fecha actual

      // inicioFiltro = moment(parametros.inicio).startOf('month');
      // finFiltro = moment(parametros.fin).endOf('month');

      inicioFiltro = parametros.inicio != undefined ? moment.utc(parametros.inicio).startOf("month") : moment.utc().startOf("month");
      finFiltro = parametros.fin != undefined ? moment.utc(parametros.fin).endOf("month") : moment.utc().endOf("month");


      // let firstDay = momentToStringFechaInicio(inicioFiltro, databaseFormat);
      // let lastDay = momentToStringFechaFin(finFiltro, databaseFormat);

      let firstDay = convertirFechaString(inicioFiltro, 1).fechaString;
      let lastDay = convertirFechaString(finFiltro, 2).fechaString;

      let trabajador = await this.props.ObtenerTrabajador(parametros.Trabajador);

      await this.props.ObtenerEstados();
      await this.props.ObtenerBitacora(parametros.Trabajador, firstDay, lastDay);
      await this.props.ObtenerEPPSbitacora(parametros.Trabajador, idEmpresa);
      await this.props.ObtenerBitacoraProfesional(parametros.Trabajador, idEmpresa);
      await this.props.ObtenerVacacionesHistoricas(parametros.Trabajador, idEmpresa);
      let bonificaciones = await this.props.obtenerBonificacionesTrabajador(parametros.Trabajador);
      let bonificacionesDescansos = await this.props.obtenerBonificacionesTrabajadorDescansos(parametros.Trabajador);

      const accesos = await accesosPagina.obtenerAccesosPagina('PlanificacionDetalleTrabajador', 'DetalleTrabajador');

      console.log(accesos)

      if (accesos.Editar) {

        let cargos = await this.props.ObtenerCargosTrabajador(idEmpresa);
        //etc
        let regiones = await this.props.ObtenerRegiones();
        let comunas = await this.props.ObtenerComunas();
        let provincias = await this.props.ObtenerProvincias();

        let titulos = await this.props.ObtenerTitulos();
        let especialidades = await this.props.ObtenerEspecialidades();
        let epps = await this.props.ObtenerEPPs();
        let regimenes = await this.props.ObtenerRegimenes();
        let documentos = await this.props.ObtenerDocumentos();

        let trabajadorEditar = await this.props.ObtenerTrabajadorEditar(parametros.Trabajador);

        let ciudadesSeleccionadas = provincias.filter(provincia => provincia.Region === trabajadorEditar.Region);
        let comunasSeleccionadas = comunas.filter(comuna => comuna.Provincia === trabajadorEditar.Ciudad);

        this.setState({
          trabajadorEditar,
          cargos,
          regiones,
          comunas,
          provincias,
          titulos,
          especialidades,
          epps,
          regimenes,
          documentos,
          fechasBusqueda: [inicioFiltro, finFiltro],
          ciudadesSeleccionadas,
          comunasSeleccionadas,
        })
      }

      this.setState({
        trabajador,
        Loading: false,

        // fechaInicioSistema: usuario.EmpresaSeleccionada.Empresa.Fecha_Inicio_Sistema_Unix,

        fechaInicioSistema: usuario.EmpresaSeleccionada.Empresa.Fecha_Inicio_Sistema,

        Ver: accesos.Ver,
        Editar: accesos.Editar,
        Eliminar: accesos.Eliminar,
        bonificaciones,
        bonificacionesDescansos,
      });

      if (parametros.directHistorico) {
        this.setState({
          modalDocumentos: true,
        })

      } else if (parametros.directFichaProfesional) {
        this.setState({
          modalProfesional: true,
        })

      } else if (parametros.directEpp) {
        this.setState({
          modalEntregaEpp: true,
        })
      }
    }
  }

  cerrarModal = () => {
    this.setState({
      modalFicha: false,
      modalProfesional: false,
      modalEntregaEpp: false,
      modalDocumentos: false,
      modalBonificaciones: false,
      editarCerrado: true,

    });


  };

  filtrarCalendario = (fechas) => {
    let parametros = queryString.parse(this.props.location.search);

    if (fechas) {
      // let firstDay = fechas[0].startOf("month").format("YYYY-MM-DD");
      // let lastDay = fechas[1].endOf("month").format("YYYY-MM-DD");

      let firstDay = moment.utc(fechas[0]).startOf("month");
      let lastDay = moment.utc(fechas[1]).endOf("month");

      let firstDayFormat = convertirFechaString(firstDay, 1).fechaString;
      let lastDayFormat = convertirFechaString(lastDay, 2).fechaString;

      this.props.ObtenerBitacora(parametros.Trabajador, firstDayFormat, lastDayFormat);
      this.setState({
        fechasBusqueda: fechas
      })
    }
  };

  mostrarCalendario = () => {
    if (this.props.mostrarCalendario === true) {

      const groups = [
        {
          id: "5f3eedf56dd7cc57f0d3a261",
          title: "Trabajo efectivo",
          height: 53,
          stackItems: true,
          imagen: embarcado,
        },
        {
          id: "5f3eeb78978b284b68ebced4",
          title: "Descanso",
          height: 53,
          stackItems: true,

          imagen: descanso,
        },
        {
          id: "5f8f369e8efe155a48df37fb",
          title: "En Tránsito",
          height: 53,
          stackItems: true,

          imagen: enTransito,
        },
        {
          id: "5f3eebac978b284b68ebced5",
          title: "Vacaciones",
          height: 53,
          stackItems: true,
          imagen: vacaciones,
        },
        {
          id: "5f3eebf46dd7cc57f0d3a25d",
          title: "Licencia",
          height: 53,
          stackItems: true,
          imagen: licencia,
        },
        {
          id: "5f3eec676dd7cc57f0d3a25e",
          title: "Licencia Mutual",
          height: 53,
          stackItems: true,
          imagen: accidente,
        },
        {
          id: "5f3eec876dd7cc57f0d3a25f",
          title: "Permiso",
          height: 53,
          stackItems: true,
          imagen: permiso,
        },
        {
          id: "5f3eeca16dd7cc57f0d3a260",
          title: "Ausente",
          height: 53,
          stackItems: true,
          imagen: ausente,
        },
        {
          id: "5f904101d9c57145a09eb058",
          title: "No asignado",
          height: 53,
          stackItems: true,
          imagen: noAsignado,
        },
      ];

      let today = moment();
      let fecha = new Date(today);
      fecha = fecha.getTime();

      return (
        <>
          <Timeline
            // groups={this.props.estadoTrabajador}
            items={this.props.bitacora}
            groups={groups}
            sidebarWidth={250}
            defaultTimeStart={moment()}
            defaultTimeEnd={moment().add(35, "day")}
            groupRenderer={({ group }) => {
              return (
                <Row className="custom-group">
                  <Col span={6}>
                    <img src={group.imagen} style={{ width: 30 }} alt="icono" />
                  </Col>
                  <Col span={18}>
                    <h3 className="header-plaza">{group.title}</h3>
                  </Col>
                </Row>
              );
            }}
            itemRenderer={({
              item,
              itemContext,
              getItemProps,
              getResizeProps,
            }) => {

              const {
                left: leftResizeProps,
                right: rightResizeProps,
              } = getResizeProps();

              let objTitle = {};

              if (item.TipoRegistroBitacoraTrabajador.toString() === '5f3eedf56dd7cc57f0d3a261') {

                if (item.Centro_Costos && item.IdFaena && item.IdPlaza) {

                  objTitle = {
                    title:
                      <div className={'item-tooltip-calendario'}>
                        <p><b>Centro de costos:</b> {item.Centro_Costos.Nombre}</p>
                        <p><b>Actividad:</b> {item.IdFaena.Nombre}</p>
                        <p><b>Plaza:</b> {item.IdPlaza.NombreDePlaza}</p>
                      </div>
                  }
                }

              }

              if(item.new_tipo_bitacora === true){
                objTitle = {
                  title:<p>Descansos planificados antes del fin de contrato</p>
                }
              }

              return (
                <Tooltip
                  // title={`Centro de costos: ${item.Centro_Costos.Nombre}`}
                  {...objTitle}
                  color={'geekblue'}
                >
                  <div
                    {...getItemProps(item.itemProps)}
                    className={`barra-color ${item.colorBarra}`}
                  >
                    {itemContext.useResizeHandle ? (
                      <div {...leftResizeProps} />
                    ) : (
                      ""
                    )}

                    <div
                      className="rct-item-content"
                      style={{
                        maxHeight: `${itemContext.dimensions.height}`,
                      }}
                    >
                      {/* <h4>{itemContext.title}</h4> */}
                    </div>

                    {itemContext.useResizeHandle ? (
                      <div {...rightResizeProps} />
                    ) : (
                      ""
                    )}
                  </div>
                </Tooltip>

              );
            }}
          >
            <TodayMarker />

            <CustomMarker date={fecha}>
              {/* custom renderer for this marker */}
              {({ styles, date }) => {
                const customStyles = {
                  ...styles,
                  backgroundColor: "#74c9fc",
                  width: "4px",
                };
                return <div style={customStyles} />;
              }}
            </CustomMarker>

            <TimelineHeaders
              style={{
                backgroundColor: "#f4f4f4",
              }}
              calendarHeaderClassName="calendarioHeader"
            >
              <DateHeader unit="primaryHeader" className="mesHeader" />
              <DateHeader
                style={{
                  fontFamily: "MavenPro-SemiBold",
                  color: "black",
                  height: 40,
                }}
              />
            </TimelineHeaders>

          </Timeline>
        </>
      );

    } else {
      return <></>;
    }
  };

  activarCargando = () => {
    this.setState({
      Loading: true,
    });
  };

  desactivarCargando = () => {
    this.setState({
      Loading: false,
    });
  };

  actualizarInformacionUsuario = async () => {
    let usuario = localStorage.getItem("usuario-people-manage");
    if (
      usuario !== null &&
      usuario !== "" &&
      usuario !== undefined &&
      usuario !== "undefined"
    ) {
      usuario = JSON.parse(usuario);
      const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
      let parametros = queryString.parse(this.props.location.search);

      let trabajador = await this.props.ObtenerTrabajador(
        parametros.Trabajador
      );
      await this.props.ObtenerEPPSbitacora(parametros.Trabajador, idEmpresa);
      await this.props.ObtenerBitacoraProfesional(
        parametros.Trabajador,
        idEmpresa
      );
      this.setState({ trabajador, Loading: false });
      return;
    }
  };

  estadoElementosProteccion = () => {
    if (this.props.trabajador) {
      const epps = this.props.trabajador.EPP;
      let contador = 0;

      epps.forEach((epp) => {
        if (epp.encontrado === false || epp.cantidadDiasHabiles <= 0) {
          contador++;
        }
      });

      if (contador >= 1) {
        if (this.state.eppDetalle) {
          return (
            <Badge className="cantidad-alertas" count={contador}>
              <a href="#" className="head-example" />
            </Badge>
          );
        } else {
          return (
            <Badge className="cantidad-alertas" count={contador}>
              <a href="#" className="head-example" />
            </Badge>
          );
        }
      }
    } else {
      return <div></div>;
    }
  };

  eliminarBonificacion = async (idBonificacion) => {

    await Swal.fire({
      title: "Confirmar",
      text: `¿Estás seguro que quieres eliminar el registro de bonificación?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1FC600",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",

    }).then(async (result) => {

      if (result.isConfirmed) {

        await this.props.eliminarBonificacion(idBonificacion);

        let parametros = queryString.parse(this.props.location.search);
        let bonificaciones = await this.props.obtenerBonificacionesTrabajador(parametros.Trabajador);

        this.setState({
          bonificaciones,
        })
      }
    })
  }

  estadoFichaProfesional = () => {

    if (this.props.trabajador) {

      const TitulosProfesionales = this.props.trabajador.Titulos_Profesionales;
      const Especialidades = this.props.trabajador.Especialidades;
      const TiposDeDocumentos = this.props.trabajador.TiposDeDocumentos;

      let contador = 0;

      TitulosProfesionales.forEach((titulo) => {
        if (
          titulo.encontrado === false ||
          (titulo.cantidadDiasHabiles !== undefined
            ? titulo.cantidadDiasHabiles
            : 1) <= 0
        ) {
          contador++;
        }
      });

      Especialidades.forEach((especialidad) => {
        if (
          especialidad.encontrado === false ||
          (especialidad.cantidadDiasHabiles !== undefined
            ? especialidad.cantidadDiasHabiles
            : 1) <= 0
        ) {
          contador++;
        }
      });

      TiposDeDocumentos.forEach((doc) => {
        if (doc.isIngresado === false) {
          contador++;
        }
      });

      if (contador >= 1) {
        if (this.state.eppDetalle) {
          return (
            <Badge className="cantidad-alertas" count={contador}>
              <a href="#" className="head-example" />
            </Badge>
            // <div className="alerta-detallada">
            //   <p>holaaaaaaaa</p>
            // </div>
          );
        } else {
          return (
            <Badge className="cantidad-alertas" count={contador}>
              <a href="#" className="head-example" />
            </Badge>
            // <div className="alerta-detallada">
            //   <p>holaaaaaaaa</p>
            // </div>
          );
        }
      } else {
        return <div></div>;
      }
    } else {
      return <div></div>;
      // return (
      //   <p
      //     style={{
      //       color: "#E61958",
      //     }}
      //   >
      //     Elementos sin ingresar
      //   </p>
      // );
    }
  };

  estadoRegistroVacaciones = () => {

    if (this.state.fechaInicioSistema === undefined || this.state.trabajador === undefined) {
      return;
    }

    if (this.props.vacaciones !== undefined && this.props.vacaciones.length === 0) {

      // let fechaInicioSistema = moment.unix(this.state.fechaInicioSistema);
      // let fechaContratoTrabajador = moment.unix(this.state.trabajador.Contrato_Fecha_Inicio_Unix);

      let fechaInicioSistema = moment(this.state.fechaInicioSistema);
      let fechaContratoTrabajador = moment(this.state.trabajador.Contrato_Fecha_Inicio);

      if (fechaInicioSistema.isAfter(fechaContratoTrabajador)) {
        return (
          <Badge className="cantidad-alertas" count={'!'}>
            <a href="#" className="head-example" />
          </Badge>
        )
      }

    }
  }

  buscarHistorico = async () => {

    let parametros = queryString.parse(this.props.location.search);
    let usuario = localStorage.getItem("usuario-people-manage");
    usuario = JSON.parse(usuario);
    const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;

    let historico = await this.props.ObtenerVacacionesHistoricas(parametros.Trabajador, idEmpresa);

    return historico;

  }

  EditarTrabajador = async (Trabajador) => {
    this.activarCargando();
    Trabajador._id = this.props.trabajador._id;
    Trabajador.Vigencia = this.props.trabajador.Vigencia;

    await this.props.EditarTrabajador(Trabajador);
    await this.props.ObtenerTrabajador();

    let parametros = queryString.parse(this.props.location.search);
    this.props.ObtenerTrabajador(parametros.Trabajador);

    this.cerrarModal();
    this.forceUpdate();

    this.desactivarCargando();
  }

  //funciones para buscar select anidados de ciudades y provincias
  buscarProvincias = (idRegion) => {
    let provincias = this.state.provincias;

    let nuevasProvincias = provincias.filter(
      (provincia) => provincia.Region === idRegion
    );

    this.setState({
      ciudadesSeleccionadas: nuevasProvincias,
    })
  };

  buscarComuna = (idProvincia) => {
    let comunas = this.state.comunas;
    let nuevasComunas = comunas.filter(
      (comuna) => comuna.Provincia === idProvincia
    );

    this.setState({
      comunasSeleccionadas: nuevasComunas,
    })
  };

  obtenerImagen = (imagenNueva) => {
    this.setState({
      form: {
        ...this.state.form,
        imagen: imagenNueva,
      },
    });
  };

  cambiarEditarCerrado = () => {
    this.setState({ editarCerrado: false })
  }

  obtenerCentroCostoActual = () => {

    let nombreCentroCosto = "No asignado a centro costo";
    let bitacoras = this.props.bitacora;

    // let fechaHoy = moment().format("YYYY-MM-DD");
    let fechaHoy = convertirFechaString(moment.utc(), 1).fechaDDMMYYYY;

    if (bitacoras !== undefined && bitacoras !== null) {
      bitacoras.forEach(bitacora => {
       
        // if (fechaHoy === moment.utc(bitacora.Fecha).format("YYYY-MM-DD")) {
        if (fechaHoy === convertirFechaString(moment.utc(bitacora.Fecha), 1).fechaDDMMYYYY) {
          if (bitacora.Centro_Costos) {
            nombreCentroCosto = bitacora.Centro_Costos.Nombre;
          }
        }
      });
    }
    return nombreCentroCosto;
  }

  render() {
    return (
      <>
        <Layout style={{ height: "100%" }}>
          <Loading cargando={this.state.Loading} />

          <Header style={{ background: "none" }}>
            <HeaderFicha titulo="Trabajador:" subtitulo="Sobre el trabajador" />
          </Header>

          <hr className="division" style={{ marginRight: 35 }} />

          <Content
            className="contenedor-general cont-tabla-celeste"
            style={{ background: "none" }}
          >
            {/* DATOS DEL TRABAJADOR */}
            <Row>
              <Col>
                <h3
                  style={{
                    fontFamily: "MavenPro-Bold",
                    color: "#061FCB",
                    marginTop: "0px",
                  }}
                >
                  Datos del trabajador
                </h3>
              </Col>
            </Row>

            <Row gutter={16}>

              {/* FICHA TRABAJADOR */}
              <Col span={6} style={{ cursor: "pointer" }}>
                <Card
                  className={"dashboard tarjetas-trabajador"}
                  onClick={() => {
                    this.setState({ modalFicha: true });
                  }}
                  hoverable={true}
                >
                  <Row align="middle">
                    <Col span={6} className={'tarjetas-trabajador-col'}>
                      <img style={{ width: 40 }} src={avatar} alt="icono" />
                    </Col>
                    <Col span={18} className={'tarjetas-trabajador-col'}>
                      <h3 className={'tarjetas-trabajador-text'}>
                        Ficha del trabajador
                      </h3>
                    </Col>
                  </Row>
                </Card>
              </Col>

              {/* TÍTULOS, ESPECIALIDADES Y DOCUMENTOS */}
              <Col span={6} style={{ cursor: "pointer" }}>
                <Card
                  className="dashboard tarjetas-trabajador"
                  onClick={() => {
                    this.setState({ modalProfesional: true });
                  }}
                  hoverable={true}
                >
                  {this.estadoFichaProfesional()}
                  <Row align="middle">
                    <Col span={6} className={'tarjetas-trabajador-col'}>
                      <img style={{ width: 40 }} src={Carpeta} alt="icono" />
                    </Col>
                    <Col span={18} className={'tarjetas-trabajador-col'}>
                      <h3
                        className={'tarjetas-trabajador-text'}
                      >
                        Ficha Profesional
                      </h3>
                    </Col>
                  </Row>
                </Card>
              </Col>

              {/* EPPs */}
              <Col span={6} style={{ cursor: "pointer" }}>
                <Card
                  className="dashboard tarjetas-trabajador"
                  hoverable={true}
                  onClick={() => {
                    this.setState({ modalEntregaEpp: true });
                  }}
                  onMouseEnter={() => {
                    this.setState({
                      eppDetalle: true,
                    });
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      eppDetalle: false,
                    });
                  }}
                >
                  <Row align="middle">
                    {this.estadoElementosProteccion()}
                    <Col span={6} className={'tarjetas-trabajador-col'}>
                      <img style={{ width: 40 }} src={Proteccion} alt="icono" />
                    </Col>
                    <Col span={18} className={'tarjetas-trabajador-col'}>
                      <h3
                        className={'tarjetas-trabajador-text'}
                      >
                        Elementos de Protección
                      </h3>
                    </Col>
                  </Row>
                </Card>
              </Col>

              {/* VACACIONES */}
              <Col span={6} style={{ cursor: "pointer" }}>
                <Card
                  hoverable={true}
                  className="dashboard tarjetas-trabajador"
                  onClick={() => {
                    this.setState({ modalDocumentos: true });
                  }}
                >
                  <Row align="middle">
                    {this.estadoRegistroVacaciones()}
                    <Col span={6} className={'tarjetas-trabajador-col'}>
                      <img style={{ width: 40 }} src={vacaciones2} alt="icono" />
                    </Col>
                    <Col span={18} className={'tarjetas-trabajador-col'}>
                      <h3
                        className={'tarjetas-trabajador-text'}
                      >
                        Registro Vacaciones Históricas
                      </h3>
                    </Col>
                  </Row>
                </Card>
              </Col>

              {/* BONIFICACIONES */}
              <Col span={6} style={{ cursor: "pointer", marginTop: '16px' }}>
                <Card
                  className="dashboard tarjetas-trabajador"
                  onClick={() => {
                    this.setState({ modalBonificaciones: true });
                  }}
                  hoverable={true}

                >
                  <Row align="middle">
                    {/* {this.estadoRegistroVacaciones()} */}
                    <Col span={6} className={'tarjetas-trabajador-col'}>
                      <img style={{ width: 40 }} src={Carpeta} alt="icono" />
                    </Col>
                    <Col span={18} className={'tarjetas-trabajador-col'}>
                      <h3
                        className={'tarjetas-trabajador-text'}
                      >
                        Comprobantes de Bonificación
                      </h3>
                    </Col>
                  </Row>
                </Card>
              </Col>

            </Row>

            <Row style={{ marginTop: "30px" }} align="bottom">
              <Col style={{ textAlign: "bottom" }} span={6}>
                <p style={{ fontFamily: "MavenPro-Bold", marginBottom: "3px" }}>
                  Filtrar faenas por meses:
                </p>

                <RangePicker
                  locale={locale}
                  picker="month"
                  disabledDate={this.disabledDate}
                  value={this.state.fechasBusqueda}
                  onChange={(fechas) => {
                    this.filtrarCalendario(fechas);
                  }}
                />
              </Col>
            </Row>

            <Row align="middle">
              <Col span={6}>
                <h3
                  style={{
                    fontFamily: "MavenPro-Bold",
                    color: "#061FCB",
                    marginTop: "30px",
                  }}
                >
                  Perfil
                </h3>
              </Col>
              <Col span={6}>
                <h3
                  style={{
                    fontFamily: "MavenPro-Bold",
                    color: "#061FCB",
                    marginTop: "30px",
                  }}
                >
                  Calendario del trabajador
                </h3>
              </Col>
              <Col span={6}></Col>
              <Col span={6} align="right">
                {/* <Button
                  className="descargar-excel"
                  type="primary"
                  onClick={() => {
                    this.descargarExcel();
                  }}
                  style={{ marginRight: "20px" }}
                >
                  Descargar Excel
                </Button> */}
              </Col>
            </Row>

            {/* PERFIL Y TIMELINE */}
            <Row>

              {/* PERFIL */}
              <Col span={6}>

                <Card
                  style={{
                    height: "260px",
                  }}
                >
                  <Row>
                    <Col span={24} align="middle">
                      <img
                        className="notify-badge"
                        src={Reloj}
                        alt="icono reloj"
                      />
                      <img
                        style={{
                          borderRadius: "50%",
                          width: "167px",
                          height: "167px",
                        }}
                        src={
                          this.props.trabajador === null
                            ? avatar
                            : this.props.trabajador.Imagen
                        }
                        alt="foto trabajador"
                      />
                    </Col>
                    <Col span={24} align="middle">
                      <p
                        style={{ marginBottom: 0, marginTop: 10 }}
                        className="info-trabajador"
                      >
                        {this.props.trabajador === null
                          ? ""
                          : this.props.trabajador.Nombre}
                        &nbsp;
                        {this.props.trabajador === null
                          ? ""
                          : this.props.trabajador.Apellido_Paterno}
                        &nbsp;
                        {this.props.trabajador === null
                          ? ""
                          : this.props.trabajador.Apellido_Materno}
                      </p>
                      <p
                        style={{ marginBottom: 0 }}
                        className="info-trabajador"
                      >
                        {this.props.trabajador === null
                          ? ""
                          : this.props.trabajador.Rut}
                      </p>
                    </Col>
                  </Row>
                </Card>

                <Card
                  style={{
                    backgroundColor: "#f4f4f4",
                  }}
                >
                  <Row
                    style={{ fontFamily: "MavenPro-Regular", color: "black" }}
                  >
                    <Col align="middle" span={24}>
                      <p
                        style={{
                          margin: 0,
                          marginTop: "10px",
                          fontFamily: "MavenPro-Bold",
                          color: "#061FCB",
                        }}
                      >
                        Cargo
                      </p>
                    </Col>
                    <Col align="middle" span={24} className="card-col">
                      {this.props.trabajador === null
                        ? ""
                        : this.props.trabajador.Cargo.Nombre}
                    </Col>
                    <Col align="middle" span={24}>
                      <p
                        style={{
                          margin: 0,
                          marginTop: "10px",
                          marginLeft: "4px",
                          fontFamily: "MavenPro-Bold",
                          color: "#061FCB",
                        }}
                      >
                        Codigo
                      </p>
                    </Col>
                    <Col align="middle" span={24} className="card-col">
                      {this.props.trabajador === null
                        ? ""
                        : (this.props.trabajador.Codigo_Base === undefined ? "(No posee código)" : this.props.trabajador.Codigo_Base)}
                    </Col>
                    <Col align="middle" span={24}>
                      <p
                        style={{
                          margin: 0,
                          marginTop: "10px",
                          fontFamily: "MavenPro-Bold",
                          color: "#061FCB",
                        }}
                      >
                        Regimen de trabajo
                      </p>
                    </Col>
                    <Col align="middle" span={24} className="card-col">
                      {this.props.trabajador === null
                        ? ""
                        : this.props.trabajador.RegimenDeTrabajo.Nombre}
                    </Col>

                    <Col align="middle" span={24}>
                      <p
                        style={{
                          margin: 0,
                          marginTop: "10px",
                          fontFamily: "MavenPro-Bold",
                          color: "#061FCB",
                        }}
                      >
                        Centro de costo actual
                      </p>
                    </Col>
                    <Col align="middle" span={24} className="card-col">
                      {this.obtenerCentroCostoActual()}
                    </Col>
                  </Row>
                </Card>

              </Col>

              {/* TIMELINE */}
              <Col style={{ marginLeft: 10 }} span={17}>
                {this.mostrarCalendario()}
              </Col>

            </Row>

            <p className="m-fix"></p>

            <DashboardDinamicoTrabajador reporteDashboard={this.props.dashboard} />

          </Content>

          {/* Modal Ficha del Trabajador */}
          <Modal
            //propiedades cambiables del modal
            wrapClassName="modalCrear"
            visible={this.state.modalFicha}
            onCancel={this.cerrarModal}
            //propiedades obligatorias para modal mantenedor
            bodyStyle={{ borderRadius: "16px" }}
            width="80%"
            footer={null}
            style={{ top: 20 }}
            closable={false}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.96" }}
          >

            {(() => {

              // DIFERENCIA ENTRE ACCESO DE EDITAR Y SÓLO VISUALIZAR
              if (this.state.Editar) {

                return (
                  // <FormularioTrabajadorEditar
                  <FormularioTrabajadorEditarNuevo
                    Formulario={this.state.trabajadorEditar}
                    editarTrabajador={this.EditarTrabajador}
                    TomarValoresEditar={() => { }}
                    obtenerImagen={this.obtenerImagen}

                    cargosTrabajador={this.state.cargos}
                    regimenes={this.state.regimenes}

                    regiones={this.state.regiones}
                    comunas={this.state.comunas}

                    ciudadesSeleccionadas={this.state.ciudadesSeleccionadas}
                    comunasSeleccionadas={this.state.comunasSeleccionadas}

                    buscarComuna={this.buscarComuna}
                    buscarProvincias={this.buscarProvincias}

                    //select
                    epps={this.state.epps}
                    documentos={this.state.documentos}

                    //titulos y especialidades
                    titulos={this.state.titulos}
                    especialidades={this.state.especialidades}

                    editarCerrado={this.editarCerrado}
                    cambiarEditarCerrado={this.cambiarEditarCerrado}
                  />
                )

              } else {

                return (
                  // <FormularioTrabajadorVisualizar
                  <FormularioTrabajadorVisualizarNuevo
                    Formulario={this.props.trabajador}
                  />
                )
              }

            })()}

          </Modal>

          {/* Modal Ficha Profesional */}
          <Modal
            //propiedades cambiables del modal
            wrapClassName="modalProfesional"
            visible={this.state.modalProfesional}
            onCancel={this.cerrarModal}
            //propiedades obligatorias para modal mantenedor
            bodyStyle={{ borderRadius: "16px" }}
            width="50%"
            footer={null}
            style={{ top: 20 }}
            closable={false}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          >
            <FormularioFichaProfesional
              trabajador={this.state.trabajador === undefined ? [] : this.state.trabajador}
              profesionalesBitacora={this.props.profesionalesBitacora ? this.props.profesionalesBitacora : []}
              activarCargando={this.activarCargando}
              desactivarCargando={this.desactivarCargando}
              actualizarInformacionUsuario={this.actualizarInformacionUsuario}
              Ver={this.state.Ver}
              Editar={this.state.Editar}
              Eliminar={this.state.Eliminar}
            />
          </Modal>

          {/* Modal Elementos de Protección */}
          <Modal
            //propiedades cambiables del modal
            wrapClassName="modalEntregaEPP"
            visible={this.state.modalEntregaEpp}
            onCancel={this.cerrarModal}
            //propiedades obligatorias para modal mantenedor
            bodyStyle={{ borderRadius: "16px" }}
            width="50%"
            footer={null}
            style={{ top: 20 }}
            closable={false}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          >
            <FormularioEntregaEPP
              trabajador={
                this.state.trabajador === undefined ? [] : this.state.trabajador
              }
              eppsBitacora={this.props.eppsBitacora}
              activarCargando={this.activarCargando}
              desactivarCargando={this.desactivarCargando}
              actualizarInformacionUsuario={this.actualizarInformacionUsuario}
              Ver={this.state.Ver}
              Editar={this.state.Editar}
              Eliminar={this.state.Eliminar}
            />
          </Modal>

          {/* Modal Vacaciones */}
          <Modal
            //propiedades cambiables del modal
            wrapClassName="modalDocumentos"
            visible={this.state.modalDocumentos}
            onCancel={this.cerrarModal}
            //propiedades obligatorias para modal mantenedor
            bodyStyle={{ borderRadius: "16px" }}
            width="70%"
            footer={null}
            style={{ top: 20 }}
            closable={false}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          >
            <FormularioRegistroVacaciones
              historico={
                this.props.vacaciones === undefined ? [] : this.props.vacaciones
              }
              trabajador={
                this.state.trabajador === undefined ? [] : this.state.trabajador
              }

              buscarHistorico={this.buscarHistorico}

              activarCargando={this.activarCargando}
              desactivarCargando={this.desactivarCargando}

              fechaInicioSistema={this.state.fechaInicioSistema}
              Ver={this.state.Ver}
              Editar={this.state.Editar}
              Eliminar={this.state.Eliminar}
            />
          </Modal>

          {/* Modal Bonificaciones */}
          <Modal
            //propiedades cambiables del modal
            wrapClassName="modalDocumentos"
            visible={this.state.modalBonificaciones}
            onCancel={this.cerrarModal}
            //propiedades obligatorias para modal mantenedor
            bodyStyle={{ borderRadius: "16px" }}
            width="60%"
            footer={null}
            style={{ top: 20 }}
            closable={false}
            maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
          >
            <FichaBonificaciones
              bonificacionesDescansos={this.state.bonificacionesDescansos}
              bonificaciones={this.state.bonificaciones}
              eliminarBonificacion={this.eliminarBonificacion}
            />
          </Modal>

          <span>
            <style jsx="true">
              {`
              .ant-card-body {
                padding-top: 17px !important;
                padding-bottom: 17px !important;
              }
              `
              }
            </style>
          </span>

        </Layout>
      </>
    );
  }
}

const EstadosAPropiedades = ({
  CalendarioTrabajadorReducer: {
    error,
    loading,
    success,
    trabajador,
    bitacora,
    estadoTrabajador,
    mostrarCalendario,
    dashboard,
    eppsBitacora,
    profesionalesBitacora,
    cargosTrabajador,
    vacaciones
  },
}) => {
  return {
    error,
    loading,
    success,
    trabajador,
    bitacora,
    estadoTrabajador,
    mostrarCalendario,
    dashboard,
    eppsBitacora,
    profesionalesBitacora,
    cargosTrabajador,
    vacaciones
  };
};

export default connect(
  EstadosAPropiedades,
  CalendarioTrabajadorActions
)(CalendarioTrabajador);