export const LOADING = "MANTENEDOR_TIPOEMPRESA_LOADING";
// export const PARAR_LOADING = "MANTENEDOR_TIPOEMPRESA_LOADING_PARAR";
export const ERROR = "MANTENEDOR_TIPOEMPRESA_ERROR";
export const INGRESAR_TIPOEMPRESA = "MANTENEDOR_TIPOEMPRESA_INGRESAR";
export const LIMPIAR_ERROR_SUCCESS =
  "MANTENEDOR_TIPOEMPRESA_LIMPIAR_ERROR_SUCCESS";
export const MOSTRAR_MODAL_TIPOEMPRESA = "MANTENEDOR_TIPOEMPRESA_MOSTRAR_MODAL";
export const OCULTAR_MODAL_TIPOEMPRESA = "MANTENEDOR_TIPOEMPRESA_OCULTAR_MODAL";
export const OCULTAR_MODAL_TIPOEMPRESA_EDITAR =
  "MANTENEDOR_TIPOEMPRESA_OCULTAR_MODAL_EDITAR";
export const OBTENER_TIPOSEMPRESA_MANTENEDOR =
  "MANTENEDOR_TIPOEMPRESA_OBTENER_TIPOSEMPRESA";
export const OBTENER_TIPOEMPRESA_MANTENEDOR =
  "MANTENEDOR_TIPOEMPRESA_OBTENER_TIPOEMPRESA";
export const ELIMINAR_TIPOEMPRESA_MANTENEDOR =
  "MANTENEDOR_TIPOEMPRESA_ELIMINAR_TIPOEMPRESA";
export const EDITAR_TIPOEMPRESA_MANTENEDOR =
  "MANTENEDOR_TIPOEMPRESA_EDITAR_TIPOEMPRESA";
export const TOMAR_VALORES_EDITAR =
  "MANTENEDOR_TIPOEMPRESA_TOMAR_VALORES_EDITAR";
// export const DESCARGAR_EXCEL = "MANTENEDOR_TIPOEMPRESA_DESCARGAR_EXCEL";
