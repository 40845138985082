import React, { useState } from "react";
import "./FormularioLogin.css";
// import Recaptcha from "react-recaptcha";

import { Card, Form, Input, Button, Row, Col } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const FormularioLogin = (props) => {
  const [visiblePass, setVisiblePass] = useState("password");

  const onFinish = (values) => {
    values.Password = values.Contrasena;
    props.inciarSesion(values);
  };

  const onFinishForgotten = (values) => {
    props.restablecer(values);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const redireccionar = () => {
    if (props.redireccionarLogin === true) {
      let usuario = localStorage.getItem("usuario-people-manage");
      if (
        usuario !== null &&
        usuario !== "" &&
        usuario !== undefined &&
        usuario !== "undefined"
      ) {
        usuario = JSON.parse(usuario);

        const superAdmin = "5f21ae92d585cd003f997a6a";

        let redirect
        if(usuario.EmpresaSeleccionada.Rol._id.toString() === superAdmin){
          redirect = "/Empresas";
        }else{
          redirect = "/CentroControl";
        }

        window.location.href = redirect; //relative to domain

        //return <Redirect to="/CentroControl" />;
      }
    }
  };

  const visibleIcon = () => {
    if (!(visiblePass.length > 0)) {
      return (
        <EyeTwoTone
          className={"ojo-pass"}
          onMouseDown={() => {
            setVisiblePass("");
          }}
          onPointerDown={() => {
            setVisiblePass("");
          }}
          onMouseUp={() => {
            setVisiblePass("password");
          }}
          onPointerUp={() => {
            setVisiblePass("password");
          }}
        />
      );
    } else {
      return (
        <EyeInvisibleOutlined
          className={"ojo-pass"}
          onMouseDown={() => {
            setVisiblePass("");
          }}
          onPointerDown={() => {
            setVisiblePass("");
          }}
          onMouseUp={() => {
            setVisiblePass("password");
          }}
          onPointerUp={() => {
            setVisiblePass("password");
          }}
        />
      );
    }
  };

  const [Forgotten, setForgotten] = useState(false);

  return (
    <Row align="middle" className={"login-row"}>
      <Col xs={0} sm={0} md={12} lg={12} xl={12}></Col>

      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        align={"center"}
        className={"login-col"}
      >
        <div className="componente">
          {redireccionar()}

          <Card title="Iniciar Sesión" className="login">
            {Forgotten === false ? (
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="Email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Debe ingresar su email",
                    },
                  ]}
                >
                  <Input placeholder="Email" className="login-campos" />
                </Form.Item>

                <Form.Item
                  name="Contrasena"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                  extra={visibleIcon()}
                >
                  <Input
                    placeholder="Contraseña"
                    type={visiblePass}
                    className={
                      visiblePass
                        ? "login-campos pass-visible"
                        : "login-campos pass-invisible"
                    }
                  />
                </Form.Item>

                {/* <Form.Item
                    name="Password"
                    rules={[
                      {
                        required: true,
                        // message: "Debe ingresar su contraseña",
                        message: " ",
                      },
                    ]}
                  >
                    <Input.Password
                      className="login-campos"
                      placeholder="Contraseña"
                    />
                  </Form.Item> */}

                <p
                  style={{
                    fontFamily: "MavenPro-Regular",
                    color: "#E61958",
                    marginTop: "-4%",
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    setForgotten(true);
                  }}
                >
                  Olvidé mi contraseña
                </p>

                <Form.Item className="cont-button-login">
                  <Button
                    //onClick={props.esRobot}
                    htmlType="submit"
                    className="login-btn"
                  >
                    Ingresar
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinishForgotten}
                onFinishFailed={onFinishFailed}
              >
                <p
                  onClick={async () => {
                    setForgotten(true);
                  }}
                >
                  Escribe tu mail para recibir un correo con los pasos para
                  cambiar tu contraseña
                </p>

                <Form.Item
                  name="Email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Debe ingresar su email",
                    },
                  ]}
                >
                  <Input placeholder="Email" className="login-campos" />
                </Form.Item>

                <Form.Item className="cont-button-login">
                  <Button
                    onClick={props.esRobot}
                    htmlType="submit"
                    className="login-btn"
                  >
                    Enviar
                  </Button>
                </Form.Item>

                <p
                  style={{
                    fontFamily: "MavenPro-Regular",
                    color: "#E61958",
                    marginTop: "-4%",
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    setForgotten(false);
                  }}
                >
                  Volver
                </p>
              </Form>
            )}
          </Card>
        </div>
      </Col>

      <span>
        <style jsx="true">
          {`
            .ant-layout-sider,
            .ant-layout-footer {
              display: none;
            }

            .ant-layout-content {
              margin: 0px !important;
            }

            .ant-input-suffix {
              position: absolute;
              top: 10px;
              right: 25px;
            }

            #basic_Password.ant-input {
              width: 100% !important;
            }

            .ant-form-item-extra {
              min-height: 0px !important;
              max-height: 0px !important;
            }
          `}
        </style>
      </span>
    </Row>
  );
};

export default FormularioLogin;
