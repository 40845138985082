import React from "react";
import "../Empresion.css";
import byLogo from "../../img/logos/logo-by-people-managment.svg";
import { Row, Col, Layout, Card } from "antd";
import Loading from "../../components/loading/Loading";
import API from "../../Redux/Api/api";
import moment from "moment"
import { convertirFechaString, corregirZonaHoraria } from "../../funcionesGenericas/fechas";

const queryString = require('query-string');

class LicenciaImpresion extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      renderDocumento: false,
      loading: true,
      infoDescanso: null,
    };
  }

  componentDidMount = async () => {
    const parsed = queryString.parse(this.props.location.search);
    let idDescanso = parsed.Descanso;

    let respuesta = await this.buscarDescanso(idDescanso);

    console.log('respuesta', respuesta);

    if (respuesta.status === 200) {

      let objDescanso = respuesta.json.data;

      const
        {
          IdTrabajador:
          { Nombre,
            Apellido_Paterno,
            Apellido_Materno,
            Cargo: {
              Nombre: NombreCargo,
              //Razon_Social
            },
            Empresa: {
              Imagen,
            },
          },
          DiasLaborados,
          DiasSugeridos,
          DiasOtorgados,
          DiasHabiles,
          DiasEfectivos,
          ExcesoEmbarque,
          DiasInhabiles,
          SaldoPendiente,
          Fecha_Inicio,
          Fecha_Fin,

          ///////////////////////////////

          DiasCompensados,
          MontoCompensacion,
          Inicio_Trabajo_Unix,
          Fin_Trabajo_Unix,
          LaboradosRegimen,
          FechaFiniquto,
        } = objDescanso

      let informacionDescanso = {
        Nombre_trabajador: `${Nombre} ${Apellido_Paterno} ${Apellido_Materno}`,
        Nombre_Cargo: `${NombreCargo}`,
        // Fecha_Inicio_Hasta: `Desde: ${moment.utc(Fecha_Inicio).format("DD-MM-YYYY")} Hasta: ${moment.utc(Fecha_Fin).format("DD-MM-YYYY")}`,
        Fecha_Inicio_Hasta: `Desde: ${convertirFechaString(moment.utc(Fecha_Inicio), 1).fechaDDMMYYYY} Hasta: ${convertirFechaString(moment.utc(Fecha_Fin), 2).fechaDDMMYYYY}`,
        Imagen_Empresa: Imagen,
        DiasLaborados,
        DiasSugeridos,
        DiasOtorgados,
        DiasHabiles,
        DiasEfectivos,
        ExcesoEmbarque,
        DiasInhabiles,
        SaldoPendiente,
        LaboradosRegimen,
        Inicio_Trabajo_Unix,
        Fin_Trabajo_Unix,
        DiasCompensados,
        MontoCompensacion,
        FechaFiniquto:null,
      }

      let horasRestarInicio = moment.utc(moment.unix(informacionDescanso.Inicio_Trabajo_Unix)).hours();
      let horasRestarFin = moment.utc(moment.unix(informacionDescanso.Fin_Trabajo_Unix)).hours() + 1;
      informacionDescanso.FechaFiniquto = FechaFiniquto === undefined || FechaFiniquto === null ? null : moment.utc(FechaFiniquto);

      informacionDescanso.Inicio_Trabajo_String = convertirFechaString(moment.unix(informacionDescanso.Inicio_Trabajo_Unix).subtract(horasRestarInicio, 'hours'), 1).fechaString;
      informacionDescanso.Fin_Trabajo_String = convertirFechaString(moment.utc(moment.unix(informacionDescanso.Fin_Trabajo_Unix).subtract(horasRestarFin, 'hours')), 2).fechaString;

      this.setState({
        infoDescanso: informacionDescanso,
        renderDocumento: true,
        loading: false,
      });

      setTimeout(function () {
        window.print();
      }, 1000);

    }
  }

  buscarDescanso = async (idDescanso) => {
    let respuesta = {
      status: 0,
      json: null,
    }
    try {

      const url = `${API}/Descansos/${idDescanso}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const status = response.status;
      const data = await response.json();

      respuesta.status = status;
      respuesta.json = data;

      return respuesta;

    } catch (error) {
      respuesta.status = -1;
      respuesta.json = error;

      return respuesta;
    }
  }

  Pintar() {
    if (this.state.renderDocumento === true) {
      return (
        <Layout id="divEmpresion" className="layoutImpresion">
          <Row
            style={{
              paddingTop: "30px",
            }}
          >
            <Col span={17}>

              <Card className="CardMaestra">

                <Card className="CardHeader">
                  <h3 className="ImpresionTitulo">
                    <img
                      src={this.state.infoDescanso.Imagen_Empresa}
                      className="ImpresionLogoEmpresa"
                      alt="Logo empresa"
                    />{"   "}
                    Comprobante Descanso
                  </h3>
                </Card>

                <Row
                  style={{
                    marginTop: "30px",
                    padding: "30px",
                  }}
                >
                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo">Nombre trabajador: </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo">{this.state.infoDescanso.Nombre_trabajador}</h5>
                  </Col>

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo">Cargo: </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo">{this.state.infoDescanso.Nombre_Cargo}</h5>
                  </Col>

                  {(

                    () => {

                      if (this.state.infoDescanso.Inicio_Trabajo_Unix && this.state.infoDescanso.Fin_Trabajo_Unix) {
                        return (
                          <>
                            <Col
                              style={{
                                textAlign: "right",
                              }}
                              span={7}
                            >
                              <h5 className="ImprimirNombreCampo">
                                Periodo trabajado:{" "}
                              </h5>
                            </Col>
                            <Col span={17}>
                              <h5 className="ImprimirValorCampo">
                                {/* Desde: {moment.unix(this.state.infoDescanso.Inicio_Trabajo_Unix).format("DD-MM-YYYY")} Hasta: {moment.unix(this.state.infoDescanso.Fin_Trabajo_Unix).format("DD-MM-YYYY")} */}

                                Desde: {convertirFechaString(moment.utc(this.state.infoDescanso.Inicio_Trabajo_String), 1).fechaDDMMYYYY} Hasta: {convertirFechaString(moment.utc(this.state.infoDescanso.Fin_Trabajo_String), 2).fechaDDMMYYYY}

                              </h5>
                            </Col>
                          </>
                        )
                      } else {
                        return (
                          <>
                            <Col
                              style={{
                                textAlign: "right",
                              }}
                              span={7}
                            >
                              <h5 className="ImprimirNombreCampo">
                                Periodo trabajado:{" "}
                              </h5>
                            </Col>
                            <Col span={17}>
                              <h5 className="ImprimirValorCampo">
                                Desde: 00-00-0000 Hasta: 00-00-0000
                              </h5>
                            </Col>
                          </>
                        )
                      }
                    }

                  )()}

                  {/* <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo">Días laborados por régimen: </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo">{this.state.infoDescanso.LaboradosRegimen}</h5>
                  </Col> */}

                  {/* <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo">Días laborados adicionales al régimen: </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo">{this.state.infoDescanso.ExcesoEmbarque}</h5>
                  </Col> */}

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo">Total días laborados: </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo">
                      {this.state.infoDescanso.DiasLaborados ? this.state.infoDescanso.DiasLaborados : 0}
                    </h5>
                  </Col>

                  {/* <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo">Días de descanso sugeridos: </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo">{this.state.infoDescanso.DiasSugeridos}</h5>
                  </Col> */}

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo">Días de descanso otorgados: </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo">
                      {this.state.infoDescanso.DiasOtorgados ? this.state.infoDescanso.DiasOtorgados : 0}
                    </h5>
                  </Col>

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo">Días de descanso efectivos: </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo">{this.state.infoDescanso.DiasEfectivos}</h5>
                  </Col>

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo">
                      Inicio y Termino del descanso:{" "}
                    </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo">
                      {this.state.infoDescanso.Fecha_Inicio_Hasta ? this.state.infoDescanso.Fecha_Inicio_Hasta : 'Desde: 00-00-0000 Hasta: 00-00-0000'}
                    </h5>
                  </Col>

                  {/* <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo">Recibe compensación?</h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo">{this.state.infoDescanso.SeCompensa ? 'Si' : 'No'}</h5>
                  </Col> */}

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo">
                      Cantidad de días compensados:{" "}
                    </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo">
                      {this.state.infoDescanso.DiasCompensados ? this.state.infoDescanso.DiasCompensados : 0}
                    </h5>
                  </Col>

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo">
                      Monto de compensación: {" "}
                    </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo">
                      $ {this.state.infoDescanso.MontoCompensacion ? this.state.infoDescanso.MontoCompensacion : 0}
                    </h5>
                  </Col>

                  {/* <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo">Días hábiles del periodo: </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo">
                      {this.state.infoDescanso.DiasHabiles ? this.state.infoDescanso.DiasHabiles : 0}
                    </h5>
                  </Col> */}

                  {/* <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo">Domingos e inhábiles: </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo">
                      {this.state.infoDescanso.DiasInhabiles ? this.state.infoDescanso.DiasInhabiles : 0}
                    </h5>
                  </Col> */}

                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo">Días de saldo pendiente: </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo">
                      {this.state.infoDescanso.SaldoPendiente ? this.state.infoDescanso.SaldoPendiente : 0}
                    </h5>
                  </Col>
                  {this.state.infoDescanso.FechaFiniquto !== null ?
                  <>
                  <Col
                    style={{
                      textAlign: "right",
                    }}
                    span={7}
                  >
                    <h5 className="ImprimirNombreCampo" style={{color:"red"}}>
                      <b>
                        Fecha Finiquito: {" "}
                      </b>
                    </h5>
                  </Col>
                  <Col span={17}>
                    <h5 className="ImprimirValorCampo" style={{color:"red"}}>
                      <b>
                        {convertirFechaString(moment.utc(this.state.infoDescanso.FechaFiniquto), 1).fechaDDMMYYYY}
                      </b>
                    </h5>
                  </Col>
                  </>
                  :
                  <></>
                  }

                </Row>

                <Card className="CardFirma">
                  <Row>
                    <Col className="contFirmas" span={12}>
                      <hr />
                      <h3 className="NombresFirma">FIRMA DEL EMPLEADOR</h3>
                    </Col>
                    <Col className="contFirmas" span={12}>
                      <hr />

                      <h3 className="NombresFirma">FIRMA DEL TRABAJADOR</h3>
                    </Col>
                  </Row>
                </Card>

              </Card>

              <Row
                style={{
                  paddingTop: "20px",
                  width: "700px",
                }}
              >
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <img src={byLogo} alt="by people managment" />
                </Col>
              </Row>

            </Col>
          </Row>
        </Layout >
      );
    } else {
      return false;
    }
  }

  render() {
    return (<>
      <Loading cargando={this.state.loading} />
      {this.Pintar()}
    </>);
  }
}

export default LicenciaImpresion;