import React, { Component } from "react";
import HeaderFicha from "../../components/headerFicha/HeaderFicha";
import Loading from "../../components/loading/Loading";
import Swal from "sweetalert2";
import {
    obtenerCentroCostos,
    obtenerCargos,
    obtenerReporteDelDia,
    obtenerReporteDelDiaOtros,
    obtenerReporteDelDiaDetalleRelevo
} from './reporteDelDiaApi';

import "./reporteDelDia.css";
import IconRelevo from "../../img/icons/IconRelevo.svg";
import ImgAlerta from "../../img/icons/alerta-amarilla.svg";
import locale from "antd/es/date-picker/locale/es_ES";


import {
    Layout,
    DatePicker,
    Row,
    Col,
    Select,
    InputNumber,
    Badge,
    Form,
    Button,
    Checkbox,
    Card,
    Empty,
    Tooltip,
    Modal,
    PageHeader
} from "antd";

import moment from "moment";
import { convertirFechaString } from "../../funcionesGenericas/fechas";
const { RangePicker } = DatePicker;
const { Header, Content } = Layout;
class ReporteDelDia extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            idEmpresa: null,
            centroCostos: [],
            cargos: [],
            idCentroCosto: '',
            plazas: [],
            blockDate: false,
            blockPlaza: false,

            reporteDelDia: [],

            descanso: [],
            enTransito: [],
            vacaciones: [],
            licencias: [],
            licenciasMutualidad: [],
            permisos: [],
            diaAusente: [],
            noPlanificado: [],

            dia: '',
            mes: '',

            diaDesde: 7,
            diaHasta: 14,
            diaRelevo: 1,

            otros: false,

            mostrarOtrasCondiciones: false,

            primerCentroCosto: [],

            modalDetalleRelevo: false,
            dataDetalleRelevo: []
        }
    }

    mesALetra = (mes) => {
        if (mes == '01') {
            return 'ENE';
        } else if (mes == '02') {
            return 'FEB';
        } else if (mes == '03') {
            return 'MAR';
        } else if (mes == '04') {
            return 'ABR';
        } else if (mes == '05') {
            return 'MAY';
        } else if (mes == '06') {
            return 'JUN';
        } else if (mes == '07') {
            return 'JUL';
        } else if (mes == '08') {
            return 'AGO';
        } else if (mes == '09') {
            return 'SEP';
        } else if (mes == '10') {
            return 'OCT';
        } else if (mes == '11') {
            return 'NOV';
        } else {
            return 'DIC';
        }
    }

    validarCantidadDias = (fechaFin) => {
        const verde = '#87d068';
        const amarillo = 'yellow';
        const rojo = 'red';
        let fechaF = moment(fechaFin);

        const { fechaReporte } = this.formRef.current.getFieldsValue(['fechaReporte']);
        const diaHasta = this.state.diaHasta;
        const diaDesde = this.state.diaDesde;
        let cantidadDias = (fechaF.diff(fechaReporte, 'days') + 1)
        if (cantidadDias <= diaDesde) {
            return rojo;
        } else if (cantidadDias <= diaHasta) {
            return amarillo;
        } else {
            return verde;
        }
    }

    abreviar = (cargo) => {
        const num = 10;
        if (cargo.length <= num) {
            return cargo
        }
        return cargo.slice(0, num) + '...'
    }

    abreviarNombre = (nombre, apellido) => {
        const nombreCompleto = nombre+' '+apellido;
        const num = 12;
        if (nombreCompleto.length <= num) {
            return nombreCompleto
        }
        return nombre+' '+apellido.slice(0, 1) + '.'
    }

    abreviarOtros = (str, tipo) => {
        if (tipo == 1) {
            const num = 6;
            if (str.length <= num) {
                return str
            }
            return str.slice(0, num) + '...'
        } else {
            const num = 1;
            if (str.length <= num) {
                return str
            }
            return str.slice(0, num) + '.'
        }
    }

    otrasCondiciones = (checked) => {
        this.setState({ otros: checked })
    }


    async componentDidMount() {
        let usuario = localStorage.getItem("usuario-people-manage");
        if (usuario) {
            this.formRef.current.setFieldsValue({ fechaReporte: moment() });
            let zonaHoraria = new Date().getTimezoneOffset();
            usuario = JSON.parse(usuario);
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
            const centroCostos = await obtenerCentroCostos(idEmpresa);
            const cargos = await obtenerCargos(idEmpresa);

            const values = {};
            let dia = moment().clone().subtract(zonaHoraria, 'minute').utc().startOf('day').format("DD");
            let mes = moment().clone().subtract(zonaHoraria, 'minute').utc().startOf('day').format("MM");
            let mesLetra = this.mesALetra(mes);
            let cCosto = [];
            let primerCentroCosto = [];
            if (centroCostos.length > 0) {
                primerCentroCosto.push(centroCostos[0]._id);
                cCosto.push(centroCostos[0]._id);
                values.centroCostos = cCosto;
            }

            // values.fecha = moment().clone().subtract(zonaHoraria, 'minute').utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
            let str = moment().format('yyyy/MM/DD');
            values.fecha = convertirFechaString(moment(str), 1).fechaString;
       
            values.diaRelevo = this.state.diaRelevo; //= 1
 
            const reporteDelDia = await obtenerReporteDelDia(idEmpresa, values);

            this.formRef.current.setFieldsValue({ centroCostos: primerCentroCosto });

            this.setState(
                {
                    idEmpresa,
                    centroCostos,
                    cargos,
                    dia,
                    mes: mesLetra,
                    reporteDelDia: reporteDelDia,
                    loading: false,
                    primerCentroCosto
                }
            );

            //console.log(this.state.reporteDelDia);
        }
    }

    handleUnlockFields = (type, value) => {
        switch (type) {
            case 'centroCosto':
                if (value !== undefined) {
                    return this.setState({ blockDate: false })
                } else {
                    return this.setState({ blockDate: true, blockPlaza: true })
                }

            case 'date':
                if (value !== null) {
                    return this.setState({ blockPlaza: false })
                } else {
                    return this.setState({ blockPlaza: true })
                }

            default:
                return this.setState({ blockDate: true, blockPlaza: true })
        }
    }

    handleDiaRojo = (diaDesde) => {
        const { diaHasta } = this.formRef.current.getFieldsValue(['diaHasta']);

        if (diaDesde >= diaHasta || !diaHasta) {
            this.formRef.current.setFieldsValue({ diaHasta: diaDesde + 1 })
            this.setState({ diaDesde, diaHasta: diaDesde + 1 })
        } else {
            this.setState({ diaDesde })
        }
    }

    handleDiaAmarillo = (diaHasta) => {
        const { diaDesde } = this.formRef.current.getFieldsValue(['diaDesde']);

        if (diaHasta <= diaDesde || !diaDesde) {
            const nuevoValor = diaHasta == 0 ? 0 : diaHasta - 1;
            this.formRef.current.setFieldsValue({ diaDesde: nuevoValor })
            this.setState({ diaHasta, diaDesde: nuevoValor })
        } else {
            this.setState({ diaHasta })
        }
    }

    filter = async (values) => {
        let usuario = localStorage.getItem("usuario-people-manage");
        if (usuario) {

            usuario = JSON.parse(usuario);
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
            let zonaHoraria = new Date().getTimezoneOffset();

            let dia = values.fechaReporte.clone().subtract(zonaHoraria, 'minute').utc().startOf('day').format("DD");
            let mes = values.fechaReporte.clone().subtract(zonaHoraria, 'minute').utc().startOf('day').format("MM");
            let mesLetra = this.mesALetra(mes);
    
            if (values.fechaReporte === undefined) {
                Swal.fire({
                    icon: "error",
                    title: "Uuups...",
                    text: "Debe ingresar una fecha",
                    confirmButtonColor: "#E60AA3",
                    confirmButtonText: "OK",
                });
                return;
            }

            this.setState({
                loading: true,
                dia,
                mes: mesLetra,
                descanso: [],
                enTransito: [],
                vacaciones: [],
                licencias: [],
                licenciasMutualidad: [],
                permisos: [],
                diaAusente: [],
                noPlanificado: [],
                mostrarOtrasCondiciones: false,
                reporteDelDia: []
            });

            values.centroCostos = (values.centroCostos === undefined ? [] : values.centroCostos);
            values.cargoReporte = (values.cargoReporte === undefined ? [] : values.cargoReporte);

            // values.fechaReporte = values.fechaReporte.clone().subtract(zonaHoraria, 'minute').utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");

            values.fechaReporte = convertirFechaString(values.fechaReporte, 1).fechaString;

            values.diaDesde = (values.diaDesde === undefined ? 7 : values.diaDesde);
            values.diaHasta = (values.diaHasta === undefined ? 14 : values.diaHasta);
            values.diaRelevo = (values.diaRelevo === undefined ? 1 : values.diaRelevo);

            const reporteDelDia = await obtenerReporteDelDia(idEmpresa, values);

            // console.log("REPORTE DEL DIA FILTER");
            // console.log(reporteDelDia);

            if (values.otrasCondiciones) {
                const reporteDelDiaOtros = await obtenerReporteDelDiaOtros(idEmpresa, values);

                if (Object.keys(reporteDelDiaOtros).length > 0) {
                    this.setState(
                        {
                            descanso: (reporteDelDiaOtros.descanso == undefined ? [] : reporteDelDiaOtros.descanso),
                            enTransito: (reporteDelDiaOtros.enTransito == undefined ? [] : reporteDelDiaOtros.enTransito),
                            vacaciones: (reporteDelDiaOtros.vacaciones == undefined ? [] : reporteDelDiaOtros.vacaciones),
                            licencias: (reporteDelDiaOtros.licencias == undefined ? [] : reporteDelDiaOtros.licencias),
                            licenciasMutualidad: (reporteDelDiaOtros.licenciasMutualidad == undefined ? [] : reporteDelDiaOtros.licenciasMutualidad),
                            permisos: (reporteDelDiaOtros.permisos == undefined ? [] : reporteDelDiaOtros.permisos),
                            diaAusente: (reporteDelDiaOtros.diaAusente == undefined ? [] : reporteDelDiaOtros.diaAusente),
                            noPlanificado: (reporteDelDiaOtros.noPlanificado == undefined ? [] : reporteDelDiaOtros.noPlanificado),
                            mostrarOtrasCondiciones: true
                        }
                    );
                }
            }
            this.setState({ reporteDelDia, loading: false });
        }
    }
    
    modalDetalleRelevoMostrar = async(dataEnviar) => {

        let usuario = localStorage.getItem("usuario-people-manage");
        if (usuario) {
            usuario = JSON.parse(usuario);
            
            const idEmpresa = usuario.EmpresaSeleccionada.Empresa._id;
          
            const dataDetalleRelevo = await obtenerReporteDelDiaDetalleRelevo(idEmpresa, dataEnviar);
            this.setState({
                modalDetalleRelevo: true,
                dataDetalleRelevo: dataDetalleRelevo,
            });
           
        }
      
    };

    modalDetalleRelevoCancelar = () => {
        this.setState({
            modalDetalleRelevo: false,
        });
    };

    render() {
        return (
            <Layout style={{ height: "100%" }}>
                <Loading cargando={this.state.loading} />
                <Header style={{ background: "none" }}>
                    <HeaderFicha
                        titulo="Centro de costos"
                        subtitulo="Hoy"
                    />
                </Header>

                <hr className="division" />

                {/* CONTENIDO */}
                <Content
                    className="contenedor-general maven-r"
                    style={{ background: "none" }}
                >

                    {/* FILTROS */}
                    <Form
                        className={'maven-r'}
                        name="formReporte"
                        onFinish={this.filter}
                        ref={this.formRef}
                    >
                        <div>
                            <p className={'label-filtros'} style={{ marginBottom: '10px' }}>Filtrar por:</p>
                        </div>

                        <Row gutter={20} style={{ marginBottom: '20px' }}>
                            
                            {/* FILTRO CENTRO DE COSTO */}
                            <Col xs={24} s={24} sm={24} md={24} lg={8} xl={3}
                                style={{ marginBottom: '15px' }}
                            >
                                <Form.Item
                                    name="centroCostos"
                                    noStyle

                                >
                                    <Select
                                        placeholder="Centro de costos"
                                        className={'filtro-desviaciones select-reporte-dia'}
                                        allowClear={true}
                                        mode={"multiple"}
                                        maxTagCount={2}
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    // defaultValue={this.state.primerCentroCosto}
                                    >
                                        {this.state.centroCostos.map((value, index) => (
                                            <Select.Option key={index} value={value._id}>
                                                {value.Nombre}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                            </Col>

                            {/* FILTRO FECHA */}
                            <Col xs={24} s={24} sm={24} md={24} lg={8} xl={3}
                                style={{ marginBottom: '15px'}}
                            >
                                <Form.Item
                                    name="fechaReporte"
                                    noStyle
                                >
                                    <DatePicker
                                        format={'DD-MM-YYYY'}
                                        locale={locale}
                                        className={'select-reporte-dia'}
                                        disabled={this.state.blockDate}
                                        allowClear={false}
                                    // onChange={(value) => this.handleUnlockFields('date', value)}
                                    />

                                </Form.Item>

                            </Col>

                            {/* FILTRO CARGO */}
                            <Col xs={24} s={24} sm={24} md={24} lg={8} xl={3}
                                style={{ marginBottom: '15px' }}
                            >
                                <Form.Item
                                    name="cargoReporte"
                                    noStyle
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Cargo"
                                        className={'select-reporte-dia'}
                                        allowClear={true}
                                        disabled={this.state.blockPlaza}
                                        //mode={"multiple"}
                                        maxTagCount={2}
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            this.state.cargos.map((value, index) => (
                                                <Select.Option key={index} value={value._id}>
                                                    {value.Nombre}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            {/* Filtro hasta */}
                            <Col xs={24} s={24} sm={24} md={24} lg={24} xl={5}
                                style={{ marginBottom: '15px' }}
                            >
                                <Row gutter={20}>
                                    <Col xs={24} s={24} sm={24} md={8} lg={24} xl={6}
                                     //xs={24} s={24} sm={24} md={8} lg={24} xl={6}
                                    >
                                        <p className={'label-filtros'}>Filtro hasta:</p>
                                    </Col>
                                    <Col xs={12} s={12} sm={12} md={5} lg={5} xl={9}
                                    //xs={12} s={12} sm={12} md={5} lg={5} xl={9}
                                    >
                                        {/* <div className={'contenedor-badge'}> */}
                                        <Badge status="error" className={'badge'} style={{ position: 'absolute', left: 15, zIndex: 50, top: 4 }} />
                                        <Form.Item
                                            noStyle
                                            name="diaDesde"
                                            defaultValue={7}
                                        >
                                            <InputNumber
                                                type="number"
                                                placeholder="7 días"
                                                className={'input-filtro-dias'}
                                                min={0}
                                                defaultValue={7}
                                                onBlur={(e) => {
                                                    this.handleDiaRojo(parseInt(e.target.value));
                                                }}
                                                style={{ width: '100%' }}
                                            />

                                        </Form.Item>

                                        {/* </div> */}
                                    </Col>

                                    <Col xs={12} s={12} sm={12} md={5} lg={5} xl={9}>
                                        {/* <div className={'contenedor-badge'}> */}
                                        <Badge status="warning" className={'badge'} style={{ position: 'absolute', left: 15, zIndex: 50, top: 4 }} />
                                        <Form.Item
                                            noStyle
                                            name="diaHasta"
                                            defaultValue={14}
                                        >
                                            <InputNumber
                                                type="number"
                                                placeholder="14 días"
                                                className={'input-filtro-dias'}
                                                min={0}
                                                defaultValue={14}
                                                onBlur={(e) => {
                                                    this.handleDiaAmarillo(parseInt(e.target.value));
                                                }}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                        {/* </div> */}
                                    </Col>
                                </Row>

                            </Col>

                            {/* Filtro relevo */}
                            <Col xs={24} s={24} sm={24} md={24} lg={24} xl={4}
                                style={{ marginBottom: '15px' }}
                            >
                                <Row gutter={20}>
                                    <Col xs={24} s={24} sm={24} md={8} lg={24} xl={8}>
                                        <p className={'label-filtros'}>Filtro relevo:</p>
                                    </Col>
                                    
                                    <Col xs={12} s={12} sm={12} md={5} lg={5} xl={12}>
                                        <Badge status="default" className={'badge-violeta'} style={{ position: 'absolute', left: 15, zIndex: 50, top: 4  }} />
                                        
                                        
                                        <Form.Item
                                            noStyle
                                            name="diaRelevo"
                                            defaultValue={1}
                                        >
                                            <InputNumber
                                                type="number"
                                                placeholder="60 días"
                                                className={'input-filtro-dias'}
                                                defaultValue={1}
                                                min={1}
                                                max={60}
                                                style={{ width: '100%' }}
                                            />

                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            {/* Otras Condiciones */}
                            <Col xs={24} s={24} sm={24} md={24} lg={24} xl={3}
                             //xs={24} s={24} sm={24} md={24} lg={24} xl={2}
                                style={{ marginBottom: '15px' }}
                            >
                                <Form.Item
                                    noStyle
                                    name="otrasCondiciones"
                                    valuePropName="checked"
                                >
                                    <Checkbox defaultChecked={false} onChange={(e) => this.otrasCondiciones(e.target.checked)}>
                                        <p className={'label-filtros'}>Otras condiciones</p>
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            
                            {/* BTN FILTRAR */}
                            <Col xs={24} s={24} sm={24} md={24} lg={24} xl={2}
                                className={'filtrar-contratos-btn-col'}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    form="formReporte"
                                    className={'filtrar-contratos-btn'}
                                >
                                    Filtrar
                                </Button>
                            </Col>

                        </Row>
                    </Form>

                    {/* TARJETAS 1 */}
                    <Row gutter={20} className={'row-naves'}>
                        <Col xs={24} s={24} sm={24} md={24} lg={24} xl={1}>
                            <div className={'circulo'}>
                                <div style={{ paddingTop: '15%' }}>
                                    <p style={{ lineHeight: '20px' }}>{this.state.dia} {this.state.mes}</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} s={24} sm={24} md={24} lg={24} xl={23}>
                            <Row gutter={[15, 15]}>

                                {this.state.reporteDelDia.map((reporte, index) =>
                                (
                                    <Col xs={24} sm={24} md={24} lg={24} xl={8} key={index}>
                                        <Card className={'tarjeta'}>
                                            <div className={'tituloTarjeta'}><label>{reporte.Centro_De_Costo.Nombre} ({reporte.Nombre})</label></div>
                                            {reporte.BitacoraTrabajadores.length > 0 ?
                                                (
                                                    <>
                                                        <div className={'contenidoCard'}>
                                                            <Row className={'subtituloTarjeta'}>
                                                                <Col span={2}><div></div></Col>
                                                                <Col span={6}>Cargo</Col>
                                                                <Col span={7}>Nombre</Col>
                                                                <Col span={6}>Fecha de baja</Col>
                                                                <Col span={2}>Relevo</Col>
                                                            </Row>
                                                            <div className={'contenedorTrabajadores'}>
                                                                {
                                                                    reporte.BitacoraTrabajadores.map((bitacora) =>
                                                                    
                                                                    (
                                                                        <>
                                                                            <Row className={'contenidoTarjeta'}>
                                                                                <Col span={2}><Badge color={this.validarCantidadDias(bitacora.Fin_Planificacion)} className={'badge'} /></Col>
                                                                                <Col span={6}>
                                                                                    {bitacora.NombrePlaza.NuevaPlaza ? 
                                                                                    (
                                                                                        <Tooltip title={bitacora.NombrePlaza.NombreDePlaza + ' (Plaza añadida después de la creación de la actividad)'} color={'geekblue'}>
                                                                                            <b><span>{this.abreviar(bitacora.NombrePlaza.NombreDePlaza)}</span></b>
                                                                                        </Tooltip>
                                                                                    ) : 
                                                                                    (
                                                                                        <Tooltip title={bitacora.NombrePlaza.NombreDePlaza} color={'geekblue'}>
                                                                                            <span>{this.abreviar(bitacora.NombrePlaza.NombreDePlaza)}</span>
                                                                                        </Tooltip>
                                                                                    )}
                                                                                </Col>
                                                                                <Col span={7}>
                                                                                {bitacora.NombrePlaza.NuevaPlaza ? 
                                                                                    (
                                                                                        <Tooltip title={bitacora.Trabajador.Nombre.split(' ')[0] +' '+bitacora.Trabajador.Apellido_Paterno+' '+bitacora.Trabajador.Apellido_Materno} color={'geekblue'}>
                                                                                            <b><span>{this.abreviarNombre(bitacora.Trabajador.Nombre.split(' ')[0], bitacora.Trabajador.Apellido_Paterno)}</span></b>
                                                                                        </Tooltip>   
                                                                                    ) 
                                                                                    : 
                                                                                    (
                                                                                        <Tooltip title={bitacora.Trabajador.Nombre.split(' ')[0] +' '+bitacora.Trabajador.Apellido_Paterno+' '+bitacora.Trabajador.Apellido_Materno} color={'geekblue'}>
                                                                                            <span>{this.abreviarNombre(bitacora.Trabajador.Nombre.split(' ')[0], bitacora.Trabajador.Apellido_Paterno)}</span>
                                                                                        </Tooltip>
                                                                                    )}
                                                                                    
                                                                                </Col>
                                                                                {/* <Col span={7}>{moment(bitacora.Fin_Planificacion).format('DD-MM-YYYY')}</Col> */}
                                                                                <Col span={6}>
                                                                                    {bitacora.NombrePlaza.NuevaPlaza ? (<b><span>{convertirFechaString(moment.utc(bitacora.Fin_Planificacion), 2).fechaDDMMYYYY}</span></b>) : (<span>{convertirFechaString(moment.utc(bitacora.Fin_Planificacion), 2).fechaDDMMYYYY}</span>)}
                                                                                </Col>
                                                                                <Col span={2} style={{textAlign:"center"}}>
                                                                                    {
                                                                        
                                                                                        bitacora.isRelevo === true?
                                                                                            bitacora.isWarning === false?
                                                                                            <img src={IconRelevo} 
                                                                                                // onClick={()=>{
                                                                                                //     const dataEnviar = {
                                                                                                //         IdTrabajador: bitacora.IdTrabajador,
                                                                                                //         CentroCosto: bitacora.Centro_Costos
                                                                                                //     };
                                                                                                //     this.modalDetalleRelevoMostrar(dataEnviar)
                                                                                                // }}
                                                                                                style={{height:"24px",width:"24px",display: "block",margin:"0 auto",cursor:"pointer"}} 
                                                                                            />
                                                                                            :
                                                                                            <div>
                                                                                                <img
                                                                                                    src={IconRelevo}
                                                                                                    // onClick={()=>{
                                                                                                    //     const dataEnviar = {
                                                                                                    //         IdTrabajador: bitacora.IdTrabajador,
                                                                                                    //         CentroCosto: bitacora.Centro_Costos
                                                                                                    //     };
                                                                                                    //     this.modalDetalleRelevoMostrar(dataEnviar)
                                                                                                    // }}
                                                                                                    style={{height:"24px",width:"24px",display: "block",margin:"0 auto",cursor:"pointer"}} 
                                                                                                />
                                                                                                <img
                                                                                                    src={ImgAlerta}
                                                                                                    style={{position: "absolute", marginLeft: "2px", top: "0"}}
                                                                                                    width={15}
                                                                                                />
                                                                                            </div>
                                                                                        :
                                                                                        <>
                                                                                     
                                                                                        </>
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '100%', textAlign: 'right', marginTop: '10%' }}>
                                                            <Button
                                                                type="primary"
                                                                className={'filtrar-contratos-btn'}
                                                                onClick={() => {
                                                                    let win = window.open('/DetalleActividad?idFaena=' + reporte._id, "_blank");
                                                                    win.focus();
                                                                }}
                                                            >
                                                                Detalle Actividad
                                                            </Button>
                                                        </div>
                                                    </>
                                                )
                                                :
                                                (
                                                    <Empty
                                                        imageStyle={{
                                                            height: 200,
                                                            marginTop: "20px",
                                                        }}
                                                        description={
                                                            <b
                                                                className={
                                                                    "seleccion-trabajador-disponible"
                                                                }
                                                            >
                                                                No se encontro información relacionada a
                                                                este filtro.
                                                            </b>
                                                        }
                                                    ></Empty>
                                                )
                                            }
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>

                    {/* TARJETA TRANSBORDO */}
                    {/* <Row gutter={20} className={'row-naves'}>
                        <Col xs={24} s={24} sm={24} md={24} lg={24} xl={1}>
                            <div className={'circulo-transbordo'}>
                                <div style={{ paddingTop: '15%' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 32.17 32.17">
                                        <g className="iconTransbordoCirculo">
                                            <path className="a" d="M86.283,63.142a3.142,3.142,0,1,0-3.142,3.142A3.145,3.145,0,0,0,86.283,63.142Z" transform="translate(-74.973 -56.23)" />
                                            <path className="a" d="M8.168,16.336A8.168,8.168,0,1,0,0,8.168,8.176,8.176,0,0,0,8.168,16.336Zm0-15.08A6.91,6.91,0,0,1,12.477,13.57a4.4,4.4,0,0,0-8.619,0A6.91,6.91,0,0,1,8.168,1.257Z" />
                                            <path className="a" d="M338.283,315.142a3.142,3.142,0,1,0-3.142,3.142A3.145,3.145,0,0,0,338.283,315.142Z" transform="translate(-311.14 -292.397)" />
                                            <path className="a" d="M260.168,252a8.168,8.168,0,1,0,8.168,8.168A8.177,8.177,0,0,0,260.168,252Zm4.309,13.57a4.4,4.4,0,0,0-8.619,0,6.911,6.911,0,1,1,8.619,0Z" transform="translate(-236.166 -236.166)" />
                                            <path className="a" d="M57.938,297.31a4.4,4.4,0,0,1-4.4-4.4v-.628h.628a.628.628,0,0,0,.5-1.005l-3.77-5.027a.628.628,0,0,0-1.005,0l-3.77,5.027a.628.628,0,0,0,.5,1.005h.628v.628a5.661,5.661,0,0,0,5.655,5.655h5.027a.628.628,0,1,0,0-1.257Z" transform="translate(-43.11 -268.03)" />
                                            <path className="a" d="M57.938,297.31a4.4,4.4,0,0,1-4.4-4.4v-.628h.628a.628.628,0,0,0,.5-1.005l-3.77-5.027a.628.628,0,0,0-1.005,0l-3.77,5.027a.628.628,0,0,0,.5,1.005h.628v.628a5.661,5.661,0,0,0,5.655,5.655h5.027a.628.628,0,1,0,0-1.257Z" transform="translate(75.456 300.451) rotate(180)" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} s={24} sm={24} md={24} lg={24} xl={23}>
                            <Row gutter={[15, 15]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Card className={'tarjeta'}>
                                        <div className={'contenidoCard'}>
                                            <Row className={'subtituloTarjeta'}>
                                                <Col span={2}>Nave</Col>
                                                <Col span={2}>Plaza</Col>
                                                <Col span={3}>Nombre</Col>
                                                <Col span={4}>Fechas trabajo efectivo en Origen</Col>
                                                <Col span={3}>Destino</Col>
                                                <Col span={4}>Fechas trabajo efectivo en Destino</Col>
                                                <Col span={3}>Acciones</Col>
                                            </Row>
                                            <div className={'contenedorTrabajadores'}>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                {this.state.reporteDelDia.map((reporte, index) =>
                                (
                                    <Col xs={24} sm={24} md={24} lg={12} xl={8} key={index}>
                                        <Card className={'tarjeta'}>
                                            <div className={'tituloTarjeta'}><label>{reporte.Centro_De_Costo.Nombre} ({reporte.Nombre})</label></div>
                                            {reporte.BitacoraTrabajadores.length > 0 ?
                                                (
                                                    <>
                                                        <div className={'contenidoCard'}>
                                                            <Row className={'subtituloTarjeta'}>
                                                                <Col span={2}><div></div></Col>
                                                                <Col span={7}>Cargo</Col>
                                                                <Col span={8}>Nombre</Col>
                                                                <Col span={7}>Fecha de baja</Col>
                                                            </Row>
                                                            <div className={'contenedorTrabajadores'}>
                                                                {
                                                                    reporte.BitacoraTrabajadores.map((bitacora) =>
                                                                    (
                                                                        <>
                                                                            <Row className={'contenidoTarjeta'}>
                                                                                <Col span={2}><Badge color={this.validarCantidadDias(bitacora.Fin_Planificacion)} className={'badge'} /></Col>
                                                                                <Col span={7}>
                                                                                    <Tooltip title={bitacora.NombrePlaza.NombreDePlaza} color={'geekblue'}>
                                                                                        <span>{this.abreviar(bitacora.NombrePlaza.NombreDePlaza)}</span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                <Col span={8}>
                                                                                    {bitacora.Trabajador.Nombre.split(' ')[0]} {bitacora.Trabajador.Apellido_Paterno}
                                                                                </Col>
                                                                                <Col span={7}>{moment(bitacora.Fin_Planificacion).format('DD-MM-YYYY')}</Col>
                                                                            </Row>
                                                                        </>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '100%', textAlign: 'right', marginTop: '10%' }}>
                                                            <Button
                                                                type="primary"
                                                                className={'filtrar-contratos-btn'}
                                                                onClick={() => {
                                                                    let win = window.open('/DetalleFaena?idFaena=' + reporte._id, "_blank");
                                                                    win.focus();
                                                                }}
                                                            >
                                                                Detalle Actividad
                                                            </Button>
                                                        </div>
                                                    </>
                                                )
                                                :
                                                (
                                                    <Empty
                                                        imageStyle={{
                                                            height: 200,
                                                            marginTop: "20px",
                                                        }}
                                                        description={
                                                            <b
                                                                className={
                                                                    "seleccion-trabajador-disponible"
                                                                }
                                                            >
                                                                No se encontro información relacionada a
                                                                este filtro.
                                                            </b>
                                                        }
                                                    ></Empty>
                                                )
                                            }
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row> */}

                    {/* TARJETAS 2 */}
                    {this.state.otros && this.state.mostrarOtrasCondiciones ?

                        (
                            <Row>
                                <Col xs={24} s={24} sm={24} md={24} lg={24} xl={1}>
                                    <div className={'circulo-otros'}>
                                        <div style={{ paddingTop: '25%' }}>
                                            <label style={{ padding: 0 }}>Otros</label><br></br>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} s={24} sm={24} md={24} lg={24} xl={23}>
                                    <Row gutter={[15, 15]}>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                            <Card className={'tarjeta'}>
                                                <div className={'tituloTarjeta'}><label>Descanso</label></div>
                                                {
                                                    this.state.descanso.length == 0 ?
                                                        (
                                                            <Empty
                                                                imageStyle={{
                                                                    height: 200,
                                                                    marginTop: "20px",
                                                                }}
                                                                description={
                                                                    <b
                                                                        className={
                                                                            "seleccion-trabajador-disponible"
                                                                        }
                                                                    >
                                                                        No se encontro información relacionada a
                                                                        este filtro.
                                                            </b>
                                                                }
                                                            ></Empty>
                                                        )
                                                        :
                                                        (

                                                            <div className={'contenidoCard'}>
                                                                <Row className={'subtituloTarjeta'}>
                                                                    <Col span={2} ><div></div></Col>
                                                                    <Col span={4}>Cargo</Col>
                                                                    <Col span={6}>Nombre</Col>
                                                                    <Col span={5}>Fecha inicio</Col>
                                                                    <Col span={5}>Fecha término</Col>
                                                                    <Col span={2}>Relevo</Col>
                                                               

                                                                    {/* <Col ></Col> */}
                                                                </Row>

                                                                <div className={'contenedorTrabajadores'}>
                                                                    {
                                                                        
                                                                        this.state.descanso.map((trabajador) => (
                                                                            
                                                                            <Row className={'contenidoTarjeta'} key={trabajador._id}>
                                                                                <Col span={2}>
                                                                                    <Badge color={this.validarCantidadDias(trabajador.BitacoraTrabajador.Fecha_Fin)} className={'badge'} />
                                                                                </Col>
                                                                                <Col span={4}>
                                                                                    <Tooltip title={trabajador.CargoTrabajador[0].Nombre} color={'geekblue'}>
                                                                                        <span>
                                                                                            {this.abreviarOtros(trabajador.CargoTrabajador[0].Nombre, 1)}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                <Col span={6}>
                                                                                    <Tooltip title={trabajador.Nombre.split(' ')[0] + ' ' + trabajador.Apellido_Paterno} color={'geekblue'}>
                                                                                        <span>
                                                                                            {trabajador.Nombre.split(' ')[0]} {this.abreviarOtros(trabajador.Apellido_Paterno, 2)}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                {/* <Col span={6}>{moment(trabajador.BitacoraTrabajador.Fecha_Inicio).format('DD-MM-YYYY')}</Col>
                                                                                <Col span={6}>{moment(trabajador.BitacoraTrabajador.Fecha_Fin).format('DD-MM-YYYY')}</Col> */}
                                                                                {
                                                                                    trabajador.BitacoraTrabajador.map((traBit)=>
                                                                                     <>
                                                                                        <Col span={5}>{traBit.Fecha_Inicio == undefined ? 'N/A' : convertirFechaString(moment.utc(traBit.Fecha_Inicio), 1).fechaDDMMYYYY}</Col>
                                                                                        <Col span={5}>{traBit.Fecha_Fin == undefined ? 'N/A' : convertirFechaString(moment.utc(traBit.Fecha_Fin), 2).fechaDDMMYYYY}</Col>
                                                                                        {
                                                                                            traBit.isRelevo === true?
                                                                                            <Col span={2}> 
                                                                                                <img src={IconRelevo} style={{height:"24px",width:"24px",display: "block",margin:"0 auto"}}/>
                                                                                            </Col>
                                                                                            :
                                                                                            <>
                                                                                            </>
                                                                                        }
                                                                                     </>
                                                                                    
                                                                                    )
                                                                                }
                                                                                
                                                                               
                                                                            </Row>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>

                                                        )
                                                }

                                            </Card>
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                            <Card className={'tarjeta'}>
                                                <div className={'tituloTarjeta'}><label>En tránsito</label></div>
                                                {
                                                    this.state.enTransito.length == 0 ?
                                                        (
                                                            <Empty
                                                                imageStyle={{
                                                                    height: 200,
                                                                    marginTop: "20px",
                                                                }}
                                                                description={
                                                                    <b
                                                                        className={
                                                                            "seleccion-trabajador-disponible"
                                                                        }
                                                                    >
                                                                        No se encontro información relacionada a
                                                                        este filtro.
                                                            </b>
                                                                }
                                                            ></Empty>
                                                        )
                                                        :
                                                        (

                                                            <div className={'contenidoCard'}>
                                                                <Row className={'subtituloTarjeta'}>
                                                                    <Col span={2} ><div></div></Col>
                                                                    <Col span={4}>Cargo</Col>
                                                                    <Col span={6}>Nombre</Col>
                                                                    <Col span={5}>Fecha inicio</Col>
                                                                    <Col span={5}>Fecha término</Col>
                                                                    <Col span={2}>Relevo</Col>
                                                                </Row>

                                                                <div className={'contenedorTrabajadores'}>
                                                                    {
                                                                        this.state.enTransito.map((trabajador) => (
                                                                            <Row className={'contenidoTarjeta'} key={trabajador._id}>
                                                                                <Col span={2}>
                                                                                    <Badge color={this.validarCantidadDias(trabajador.BitacoraTrabajador.Fecha_Fin)} className={'badge'} />
                                                                                </Col>
                                                                                <Col span={4}>
                                                                                    <Tooltip title={trabajador.CargoTrabajador[0].Nombre} color={'geekblue'}>
                                                                                        <span>
                                                                                            {this.abreviarOtros(trabajador.CargoTrabajador[0].Nombre, 1)}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                <Col span={6}>
                                                                                    <Tooltip title={trabajador.Nombre.split(' ')[0] + ' ' + trabajador.Apellido_Paterno} color={'geekblue'}>
                                                                                        <span>
                                                                                            {trabajador.Nombre.split(' ')[0]} {this.abreviarOtros(trabajador.Apellido_Paterno, 2)}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                {/* <Col span={6}>{moment(trabajador.BitacoraTrabajador.Fecha_Inicio).format('DD-MM-YYYY')}</Col>
                                                                                <Col span={6}>{moment(trabajador.BitacoraTrabajador.Fecha_Fin).format('DD-MM-YYYY')}</Col> */}

                                                                                {   
                                                                                    trabajador.BitacoraTrabajador.map((traBit)=>
                                                                                     <>
                                                                                        <Col span={5}>{traBit.Fecha_Inicio == undefined ? 'N/A' : convertirFechaString(moment.utc(traBit.Fecha_Inicio), 1).fechaDDMMYYYY}</Col>
                                                                                        <Col span={5}>{traBit.Fecha_Fin == undefined ? 'N/A' : convertirFechaString(moment.utc(traBit.Fecha_Fin), 2).fechaDDMMYYYY}</Col>
                                                                                        {
                                                                                            traBit.isRelevo === true?
                                                                                            <Col span={2}> 
                                                                                                <img src={IconRelevo} style={{height:"24px",width:"24px",display: "block",margin:"0 auto"}}/>
                                                                                            </Col>
                                                                                            :
                                                                                            <>
                                                                                            </>
                                                                                        }
                                                                                     </>
                                                                                    
                                                                                    )
                                                                                }

                                                                            </Row>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>

                                                        )
                                                }
                                            </Card>
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                            <Card className={'tarjeta'}>
                                                <div className={'tituloTarjeta'}><label>Vacaciones</label></div>
                                                {
                                                    this.state.vacaciones.length == 0 ?
                                                        (
                                                            <Empty
                                                                imageStyle={{
                                                                    height: 200,
                                                                    marginTop: "20px",
                                                                }}
                                                                description={
                                                                    <b
                                                                        className={
                                                                            "seleccion-trabajador-disponible"
                                                                        }
                                                                    >
                                                                        No se encontro información relacionada a
                                                                        este filtro.
                                                            </b>
                                                                }
                                                            ></Empty>
                                                        )
                                                        :
                                                        (

                                                            <div className={'contenidoCard'}>
                                                                <Row className={'subtituloTarjeta'}>
                                                                    <Col span={2} ><div></div></Col>
                                                                    <Col span={4}>Cargo</Col>
                                                                    <Col span={6}>Nombre</Col>
                                                                    <Col span={5}>Fecha inicio</Col>
                                                                    <Col span={5}>Fecha término</Col>
                                                                    <Col span={2}>Relevo</Col>
                                                                </Row>

                                                                <div className={'contenedorTrabajadores'}>
                                                                    {
                                                                        this.state.vacaciones.map((trabajador) => (
                                                                            <Row className={'contenidoTarjeta'} key={trabajador._id}>
                                                                                <Col span={2}>
                                                                                    <Badge color={this.validarCantidadDias(trabajador.BitacoraTrabajador.Fecha_Fin)} className={'badge'} />
                                                                                </Col>
                                                                                <Col span={4}>
                                                                                    <Tooltip title={trabajador.CargoTrabajador[0].Nombre} color={'geekblue'}>
                                                                                        <span>
                                                                                            {this.abreviarOtros(trabajador.CargoTrabajador[0].Nombre, 1)}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                <Col span={6}>
                                                                                    <Tooltip title={trabajador.Nombre.split(' ')[0] + ' ' + trabajador.Apellido_Paterno} color={'geekblue'}>
                                                                                        <span>
                                                                                            {trabajador.Nombre.split(' ')[0]} {this.abreviarOtros(trabajador.Apellido_Paterno, 2)}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                {/* <Col span={6}>{moment(trabajador.BitacoraTrabajador.Fecha_Inicio).format('DD-MM-YYYY')}</Col>
                                                                                <Col span={6}>{moment(trabajador.BitacoraTrabajador.Fecha_Fin).format('DD-MM-YYYY')}</Col> */}

                                                                                {
                                                                                    trabajador.BitacoraTrabajador.map((traBit)=>
                                                                                     <>
                                                                                        <Col span={5}>{traBit.Fecha_Inicio == undefined ? 'N/A' : convertirFechaString(moment.utc(traBit.Fecha_Inicio), 1).fechaDDMMYYYY}</Col>
                                                                                        <Col span={5}>{traBit.Fecha_Fin == undefined ? 'N/A' : convertirFechaString(moment.utc(traBit.Fecha_Fin), 2).fechaDDMMYYYY}</Col>
                                                                                        {
                                                                                            traBit.isRelevo === true?
                                                                                            <Col span={2}> 
                                                                                                <img src={IconRelevo} style={{height:"24px",width:"24px",display: "block",margin:"0 auto"}}/>
                                                                                            </Col>
                                                                                            :
                                                                                            <>
                                                                                            </>
                                                                                        }
                                                                                     </>
                                                                                    
                                                                                    )
                                                                                }

                                                                            </Row>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>

                                                        )
                                                }

                                            </Card>
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                            <Card className={'tarjeta'}>
                                                <div className={'tituloTarjeta'}><label>Licencias</label></div>
                                                {
                                                    this.state.licencias.length == 0 ?
                                                        (
                                                            <Empty
                                                                imageStyle={{
                                                                    height: 200,
                                                                    marginTop: "20px",
                                                                }}
                                                                description={
                                                                    <b
                                                                        className={
                                                                            "seleccion-trabajador-disponible"
                                                                        }
                                                                    >
                                                                        No se encontro información relacionada a
                                                                        este filtro.
                                                            </b>
                                                                }
                                                            ></Empty>
                                                        )
                                                        :
                                                        (

                                                            <div className={'contenidoCard'}>
                                                                <Row className={'subtituloTarjeta'}>
                                                                    <Col span={2} ><div></div></Col>
                                                                    <Col span={4}>Cargo</Col>
                                                                    <Col span={6}>Nombre</Col>
                                                                    <Col span={5}>Fecha inicio</Col>
                                                                    <Col span={5}>Fecha término</Col>
                                                                    <Col span={2}>Relevo</Col>
                                                                </Row>

                                                                <div className={'contenedorTrabajadores'}>
                                                                    {
                                                                        this.state.licencias.map((trabajador) => (
                                                                            <Row className={'contenidoTarjeta'} key={trabajador._id}>
                                                                                <Col span={2}>
                                                                                    <Badge color={this.validarCantidadDias(trabajador.BitacoraTrabajador.Fecha_Fin)} className={'badge'} />
                                                                                </Col>
                                                                                <Col span={4}>
                                                                                    <Tooltip title={trabajador.CargoTrabajador[0].Nombre} color={'geekblue'}>
                                                                                        <span>
                                                                                            {this.abreviarOtros(trabajador.CargoTrabajador[0].Nombre, 1)}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                <Col span={6}>
                                                                                    <Tooltip title={trabajador.Nombre.split(' ')[0] + ' ' + trabajador.Apellido_Paterno} color={'geekblue'}>
                                                                                        <span>
                                                                                            {trabajador.Nombre.split(' ')[0]} {this.abreviarOtros(trabajador.Apellido_Paterno, 2)}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                {/* <Col span={6}>{moment(trabajador.BitacoraTrabajador.Fecha_Inicio).format('DD-MM-YYYY')}</Col>
                                                                                <Col span={6}>{moment(trabajador.BitacoraTrabajador.Fecha_Fin).format('DD-MM-YYYY')}</Col> */}

                                                                                {
                                                                                    trabajador.BitacoraTrabajador.map((traBit)=>
                                                                                     <>
                                                                                        <Col span={5}>{traBit.Fecha_Inicio == undefined ? 'N/A' : convertirFechaString(moment.utc(traBit.Fecha_Inicio), 1).fechaDDMMYYYY}</Col>
                                                                                        <Col span={5}>{traBit.Fecha_Fin == undefined ? 'N/A' : convertirFechaString(moment.utc(traBit.Fecha_Fin), 2).fechaDDMMYYYY}</Col>
                                                                                        {
                                                                                            traBit.isRelevo === true?
                                                                                            <Col span={2}> 
                                                                                                <img src={IconRelevo} style={{height:"24px",width:"24px",display: "block",margin:"0 auto"}}/>
                                                                                            </Col>
                                                                                            :
                                                                                            <>
                                                                                            </>
                                                                                        }
                                                                                     </>
                                                                                    
                                                                                    )
                                                                                }
                                                                            </Row>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>

                                                        )
                                                }

                                            </Card>
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                            <Card className={'tarjeta'}>
                                                <div className={'tituloTarjeta'}><label>Licencias Mutualidad</label></div>
                                                {
                                                    this.state.licenciasMutualidad.length == 0 ?
                                                        (
                                                            <Empty
                                                                imageStyle={{
                                                                    height: 200,
                                                                    marginTop: "20px",
                                                                }}
                                                                description={
                                                                    <b
                                                                        className={
                                                                            "seleccion-trabajador-disponible"
                                                                        }
                                                                    >
                                                                        No se encontro información relacionada a
                                                                        este filtro.
                                                                </b>
                                                                }
                                                            ></Empty>
                                                        )
                                                        :
                                                        (

                                                            <div className={'contenidoCard'}>
                                                                <Row className={'subtituloTarjeta'}>
                                                                    <Col span={2} ><div></div></Col>
                                                                    <Col span={4}>Cargo</Col>
                                                                    <Col span={6}>Nombre</Col>
                                                                    <Col span={5}>Fecha inicio</Col>
                                                                    <Col span={5}>Fecha término</Col>
                                                                    <Col span={2}>Relevo</Col>
                                                                </Row>

                                                                <div className={'contenedorTrabajadores'}>
                                                                    {
                                                                        this.state.licenciasMutualidad.map((trabajador) => (
                                                                            <Row className={'contenidoTarjeta'} key={trabajador._id}>
                                                                                <Col span={2}>
                                                                                    <Badge color={this.validarCantidadDias(trabajador.BitacoraTrabajador.Fecha_Fin)} className={'badge'} />
                                                                                </Col>
                                                                                <Col span={4}>
                                                                                    <Tooltip title={trabajador.CargoTrabajador[0].Nombre} color={'geekblue'}>
                                                                                        <span>
                                                                                            {this.abreviarOtros(trabajador.CargoTrabajador[0].Nombre, 1)}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                <Col span={6}>
                                                                                    <Tooltip title={trabajador.Nombre.split(' ')[0] + ' ' + trabajador.Apellido_Paterno} color={'geekblue'}>
                                                                                        <span>
                                                                                            {trabajador.Nombre.split(' ')[0]} {this.abreviarOtros(trabajador.Apellido_Paterno, 2)}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                {/* <Col span={6}>{moment(trabajador.BitacoraTrabajador.Fecha_Inicio).format('DD-MM-YYYY')}</Col>
                                                                                <Col span={6}>{moment(trabajador.BitacoraTrabajador.Fecha_Fin).format('DD-MM-YYYY')}</Col> */}
                                                                                {
                                                                                    trabajador.BitacoraTrabajador.map((traBit)=>
                                                                                     <>
                                                                                        <Col span={5}>{traBit.Fecha_Inicio == undefined ? 'N/A' : convertirFechaString(moment.utc(traBit.Fecha_Inicio), 1).fechaDDMMYYYY}</Col>
                                                                                        <Col span={5}>{traBit.Fecha_Fin == undefined ? 'N/A' : convertirFechaString(moment.utc(traBit.Fecha_Fin), 2).fechaDDMMYYYY}</Col>
                                                                                        {
                                                                                            traBit.isRelevo === true?
                                                                                            <Col span={2}> 
                                                                                                <img src={IconRelevo} style={{height:"24px",width:"24px",display: "block",margin:"0 auto"}}/>
                                                                                            </Col>
                                                                                            :
                                                                                            <>
                                                                                            </>
                                                                                        }
                                                                                     </>
                                                                                    
                                                                                    )
                                                                                }
                                                                            </Row>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>

                                                        )
                                                }
                                            </Card>
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                            <Card className={'tarjeta'}>
                                                <div className={'tituloTarjeta'}><label>Permisos</label></div>
                                                {
                                                    this.state.permisos.length == 0 ?
                                                        (
                                                            <Empty
                                                                imageStyle={{
                                                                    height: 200,
                                                                    marginTop: "20px",
                                                                }}
                                                                description={
                                                                    <b
                                                                        className={
                                                                            "seleccion-trabajador-disponible"
                                                                        }
                                                                    >
                                                                        No se encontro información relacionada a
                                                                        este filtro.
                                                                </b>
                                                                }
                                                            ></Empty>
                                                        )
                                                        :
                                                        (

                                                            <div className={'contenidoCard'}>
                                                                <Row className={'subtituloTarjeta'}>
                                                                    <Col span={2} ><div></div></Col>
                                                                    <Col span={4}>Cargo</Col>
                                                                    <Col span={6}>Nombre</Col>
                                                                    <Col span={5}>Fecha inicio</Col>
                                                                    <Col span={5}>Fecha término</Col>
                                                                    <Col span={2}>Relevo</Col>
                                                                </Row>

                                                                <div className={'contenedorTrabajadores'}>
                                                                    {
                                                                        this.state.permisos.map((trabajador) => (
                                                                            <Row className={'contenidoTarjeta'} key={trabajador._id}>
                                                                                <Col span={2}>
                                                                                    <Badge color={this.validarCantidadDias(trabajador.BitacoraTrabajador.Fecha_Fin)} className={'badge'} />
                                                                                </Col>
                                                                                <Col span={4}>
                                                                                    <Tooltip title={trabajador.CargoTrabajador[0].Nombre} color={'geekblue'}>
                                                                                        <span>
                                                                                            {this.abreviarOtros(trabajador.CargoTrabajador[0].Nombre, 1)}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                <Col span={6}>
                                                                                    <Tooltip title={trabajador.Nombre.split(' ')[0] + ' ' + trabajador.Apellido_Paterno} color={'geekblue'}>
                                                                                        <span>
                                                                                            {trabajador.Nombre.split(' ')[0]} {this.abreviarOtros(trabajador.Apellido_Paterno, 2)}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                {/* <Col span={6}>{moment(trabajador.BitacoraTrabajador.Fecha_Inicio).format('DD-MM-YYYY')}</Col>
                                                                                <Col span={6}>{moment(trabajador.BitacoraTrabajador.Fecha_Fin).format('DD-MM-YYYY')}</Col> */}

                                                                                {
                                                                                    trabajador.BitacoraTrabajador.map((traBit)=>
                                                                                     <>
                                                                                        <Col span={5}>{traBit.Fecha_Inicio == undefined ? 'N/A' : convertirFechaString(moment.utc(traBit.Fecha_Inicio), 1).fechaDDMMYYYY}</Col>
                                                                                        <Col span={5}>{traBit.Fecha_Fin == undefined ? 'N/A' : convertirFechaString(moment.utc(traBit.Fecha_Fin), 2).fechaDDMMYYYY}</Col>
                                                                                        {
                                                                                            traBit.isRelevo === true?
                                                                                            <Col span={2}> 
                                                                                                <img src={IconRelevo} style={{height:"24px",width:"24px",display: "block",margin:"0 auto"}}/>
                                                                                            </Col>
                                                                                            :
                                                                                            <>
                                                                                            </>
                                                                                        }
                                                                                     </>
                                                                                    
                                                                                    )
                                                                                }
                                                                            </Row>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>

                                                        )
                                                }
                                            </Card>
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                            <Card className={'tarjeta'}>
                                                <div className={'tituloTarjeta'}><label>Día ausente</label></div>
                                                {
                                                    this.state.permisos.length == 0 ?
                                                        (
                                                            <Empty
                                                                imageStyle={{
                                                                    height: 200,
                                                                    marginTop: "20px",
                                                                }}
                                                                description={
                                                                    <b
                                                                        className={
                                                                            "seleccion-trabajador-disponible"
                                                                        }
                                                                    >
                                                                        No se encontro información relacionada a
                                                                        este filtro.
                                                                </b>
                                                                }
                                                            ></Empty>
                                                        )
                                                        :
                                                        (

                                                            <div className={'contenidoCard'}>
                                                                <Row className={'subtituloTarjeta'}>
                                                                    <Col span={2} ><div></div></Col>
                                                                    <Col span={4}>Cargo</Col>
                                                                    <Col span={6}>Nombre</Col>
                                                                    <Col span={5}>Fecha inicio</Col>
                                                                    <Col span={5}>Fecha término</Col>
                                                                    <Col span={2}>Relevo</Col>
                                                                </Row>

                                                                <div className={'contenedorTrabajadores'}>
                                                                    {
                                                                        this.state.permisos.map((trabajador) => (
                                                                            <Row className={'contenidoTarjeta'} key={trabajador._id}>
                                                                                <Col span={2}>
                                                                                    <Badge color={this.validarCantidadDias(trabajador.BitacoraTrabajador.Fecha_Fin)} className={'badge'} />
                                                                                </Col>
                                                                                <Col span={4}>
                                                                                    <Tooltip title={trabajador.CargoTrabajador[0].Nombre} color={'geekblue'}>
                                                                                        <span>
                                                                                            {this.abreviarOtros(trabajador.CargoTrabajador[0].Nombre, 1)}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                <Col span={6}>
                                                                                    <Tooltip title={trabajador.Nombre.split(' ')[0] + ' ' + trabajador.Apellido_Paterno} color={'geekblue'}>
                                                                                        <span>
                                                                                            {trabajador.Nombre.split(' ')[0]} {this.abreviarOtros(trabajador.Apellido_Paterno, 2)}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                {/* <Col span={6}>{moment(trabajador.BitacoraTrabajador.Fecha_Inicio).format('DD-MM-YYYY')}</Col>
                                                                                <Col span={6}>{moment(trabajador.BitacoraTrabajador.Fecha_Fin).format('DD-MM-YYYY')}</Col> */}

                                                                                {
                                                                                    trabajador.BitacoraTrabajador.map((traBit)=>
                                                                                     <>
                                                                                        <Col span={5}>{traBit.Fecha_Inicio == undefined ? 'N/A' : convertirFechaString(moment.utc(traBit.Fecha_Inicio), 1).fechaDDMMYYYY}</Col>
                                                                                        <Col span={5}>{traBit.Fecha_Fin == undefined ? 'N/A' : convertirFechaString(moment.utc(traBit.Fecha_Fin), 2).fechaDDMMYYYY}</Col>
                                                                                        {
                                                                                            traBit.isRelevo === true?
                                                                                            <Col span={2}> 
                                                                                                <img src={IconRelevo} style={{height:"24px",width:"24px",display: "block",margin:"0 auto"}}/>
                                                                                            </Col>
                                                                                            :
                                                                                            <>
                                                                                            </>
                                                                                        }
                                                                                     </>
                                                                                    
                                                                                    )
                                                                                }
                                                                            </Row>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>

                                                        )
                                                }
                                            </Card>
                                        </Col>

                                        {/* <Col xs={24} sm={24} md={24} lg={12} xl={8}>

                                            <Card className={'tarjeta-2'}>
                                                <div className={'cardTrabajadoresSinContrato'}>
                                                    <img src={Buscar} alt="Buscar" />
                                                    <p>Buscar trabajadores sin planificación y sin contrato vigente</p>
                                                </div>
                                            </Card>

                                        </Col> */}

                                    </Row>
                                </Col>
                            </Row>
                        )
                        :
                        (<></>)
                    }
                </Content>


                <Modal
                    //propiedades cambiables del modal
                    wrapClassName="modalDetalleRelevo"
                    visible={this.state.modalDetalleRelevo}
                    onCancel={this.modalDetalleRelevoCancelar}
                    //propiedades obligatorias para modal mantenedor
                    //bodyStyle={{ borderRadius: "16px" }}
                    //width="40%"
                    footer={null}
                    //style={{ top: 20, borderRadius: "16px" }}
                    // closable={false}
                    closable={true}
                    maskStyle={{ backgroundColor: "#08103B", opacity: "0.9" }}
                    className={"ant-modal-detalle-relevo"}
                >
                    <Row>
                        <Col span={24} style={{ display: "flex", borderRadius:"16px" }} className="title">
                        <img src={IconRelevo} style={{ width:"60px", height:"60px"}} alt="icono modal" />
                    {
                        this.state.dataDetalleRelevo.map((detalle)=>(
                        <PageHeader
                            style={{ padding: "0px 10px 0px 10px" }}
                            className="site-page-header"
                            title="Detalle Relevo"
                            //subTitle={`Nombre: ${IconRelevo}`}
                            subTitle={ `${detalle.NombreCompleto} | ${detalle.Rut}`}
                        />
                        ))
                    }
                        
                       
                        </Col>
                        {/* <Col span={6}> 
                            <RangePicker
                                format={"YYYY-MM-DD"}
                                //onChange={this.handleFechas}
                                locale={locale}
                                disabled={false}
                             
                            />
                        </Col> */}
                        {
                            this.state.dataDetalleRelevo.map((detalle)=>(
                       
                             detalle.BitPlanificacion.length > 0 ?
                             detalle.BitPlanificacion.map((planificacion)=>(
                             <Col span={24}>
                                <Card
                                    style={{
                                        marginTop:'10px',
                                        background:'#FFFFFF 0% 0% no-repeat padding-box',
                                        boxShadow:'0px 3px 6px #00000029',
                                        border:'1px solid #E2E2E2',
                                        borderRadius:'16px',
                                        opacity:'1'
                                    }}
                                >
                                    <Row>
                                        <Col span={12} style={{fontFamily:"MavenPro-Regular"}}>
                                            {`Desde ${convertirFechaString(moment.utc(planificacion.Inicio_Planificacion), 1).fechaDDMMYYYY} al
                                             ${convertirFechaString(moment.utc(planificacion.Fin_Planificacion),2).fechaDDMMYYYY}` } 
                                            </Col>
                                        <Col span={12} style={{fontFamily:"MavenPro-Bold",textAlign:"right"}}>{`Días de relevo: ${planificacion.DiasDeRelevo}`}</Col>
                                    </Row>       
                                </Card>
                            </Col>
                             ))
                             :
                             <>No hay planificaciones</>
                             
                            ))
                        }
                        
                      
                    </Row>
                </Modal>
            </Layout >

        )
    }

}


export default ReporteDelDia;