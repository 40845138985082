import {
  ERROR,
  LOADING,
  LIMPIAR_ERROR_SUCCESS,
  OBTENER_TRABAJADOR_CALENDARIO,
  OBTENER_BITACORA_TRABAJADOR,
  OBTENER_ESTADOS_TRABAJADOR,
  MOSTRAR_TABLA,
  OBTENER_BITACORAS_EPPS,
  PARAR_LOADING,
  OBTENER_BITACORAS_PROFESIONALES,
  OBTENER_VACACIONES_HISTORICAS,
  OBTENER_CONTRATOS_TRABAJADOR
} from "../types/CalendarioTrabajadorTypes";

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: null,
  trabajador: null,
  estadoTrabajador: [],
  bitacora: [],
  tablaCargando: true,
  mostrarCalendario: false,
  dashboard: [],
  eppsBitacora: null,
  profesionalesBitacora: null,
  contratosTrabajador: []
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: true,
        tablaCargando: true,
      };
    case PARAR_LOADING:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
      }

    case ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        tablaCargando: false,
      };
    case OBTENER_BITACORAS_PROFESIONALES:
      return {
        ...state,
        profesionalesBitacora: payload,
      }
    case OBTENER_BITACORAS_EPPS:
      return {
        ...state,
        eppsBitacora: payload,
      }
    case OBTENER_VACACIONES_HISTORICAS:
      return {
        ...state,
        vacaciones: payload,
      }
    case LIMPIAR_ERROR_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };

    case OBTENER_TRABAJADOR_CALENDARIO:
      return {
        ...state,
        trabajador: payload,
        loading: false,
        tablaCargando: false,
      };

    case OBTENER_CONTRATOS_TRABAJADOR:
      return {
        ...state,
        loading: false,
        tablaCargando: false,
        modalTrabajadorEditar: false,
        contratosTrabajador: payload,
      };

    case OBTENER_BITACORA_TRABAJADOR:
      return {
        ...state,
        bitacora: payload.bitacora,
        dashboard: payload.itemsDashboard,
        detalleDescansoFiniquitado: payload.detalleDescansoFiniquitado,
        loading: false,
        tablaCargando: false,
        mostrarCalendario: true,
        itemsDetalleOtrasCondiciones: payload.itemsDetalleOtrasCondiciones
      };

    case OBTENER_ESTADOS_TRABAJADOR:
      return {
        ...state,
        estadoTrabajador: payload,
        loading: false,
        tablaCargando: false,
      };
    case MOSTRAR_TABLA:
      return {
        ...state,
        mostrarCalendario: true,
      };
    default:
      return state;
  }
};
